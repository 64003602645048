<ng-container *ngIf="initialized">

    <div class="note-wrapper">
        <ul class="list-unstyled d-flex justify-content-start rounded pb-1 ps-2 pe-2 -corner-item left text-small action-panel">
            <li [ngbTooltip]="('_global_edit' | translate)">
                <a class="text-center me-1 ms-1"
                   (click)="appointmentDialog(model)"
                >
                    <i class="fal fa-edit " aria-hidden="true"></i></a>
            </li>
            <li [ngbTooltip]="('_global_delete' | translate)" *ngIf="model && model.id">
                <a class="text-center me-1 ms-1"
                   (click)="deleteAppointmentDialog(model)"
                >
                    <i class="fal fa-trash-alt " aria-hidden="true"></i></a>
            </li>
            <li [ngbTooltip]="(expanded ? ('_ui_show_less' | translate) : ('_ui_expand_lists' | translate)) + '...'" *ngIf="model && model.id">
                <a class="text-center me-1 ms-1"
                   (click)="this.expanded = !this.expanded">
                    <i class="fal "
                       [class.fa-arrows-to-line]="expanded"
                       [class.fa-arrows-from-line]="!expanded"
                       aria-hidden="true"></i>
                </a>
            </li>
        </ul>

    <div #cardContainer
        *ngIf="!listConfiguration || listConfiguration.getListType() == Constants.ListTypes.CardList"
        [class.rich-text-wrapper]="multiline"
        [class.multiline]="multiline"
        [class.m-1]="multiline"
        [class.p-1]="multiline"
        [class.expanded]="expanded"

        class=" d-flex flex-row justify-content-center -cursor-pointer rectangle ">



        <div
            class="d-flex flex-fill flex-grow-1 flex-column flex-wrap justify-content-start align-items-center wrapper rounded input-padding w-100 h-100"
            [class.p-2]="multiline"
            [class.ps-3]="multiline"
            [class.pe-3]="multiline"
            [class.p-2]="!multiline"


        >
            <ng-container>


                <div
                    [class.text-truncate]="!expanded"
                    [innerHTML]="modelHTML"
                    (click)="appointmentDialog(model)"
                    class="flex-fill pt-0 w-100 edit-content"
                    [class.multiline]="multiline"></div>

            </ng-container>

        </div>
    </div>


    <li *ngIf="listConfiguration && listConfiguration.getListType() == Constants.ListTypes.SimpleList"
        class="d-flex align-items-center nav-item align-items-center">
        <a class="nav-link flex-grow-1 d-flex align-items-center card-appointment"
             (click)="appointmentDialog(item.item)">
            <i class="-fa-2x fal fa-sticky-note me-1"></i>
            <span class="ellipsis" [ngbTooltip]="item.item.title">{{item.item.title | slice:0:20}}</span>
        </a>
        <i class="fal fa-trash text-danger ms-auto"
           *ngIf="editable"
           (click)="deleteAppointmentDialog(item.item)"
        ></i>
        <i class="fal fa-users  ms-auto"
           [ngbTooltip]="('_admin_milestone_plan_user_option_0' | translate)"
           *ngIf="!editable"
        ></i>

    </li>
    </div>

</ng-container>
