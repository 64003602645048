import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {TodoPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/TodoPresetType";

export class TodoDisplayPresetGenerator implements CreatePresetGenerator {

    private readonly displayId?: number;
    private readonly displayIdHandler?: () => number;

    constructor(
        displayIdOrHandler: number | (() => number),
    ) {
        if (typeof displayIdOrHandler == "number") {
            this.displayId = displayIdOrHandler;
        } else {
            this.displayIdHandler = displayIdOrHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: TodoPresetTypes.Display,
            payload: {
                displayId: this.displayId ?? this.displayIdHandler(),
            }
        };
    }

}
