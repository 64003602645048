/**
 * Created by ModelParser
 */
import {DepartmentsDisplay} from '../DepartmentsDisplay';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class DisplaySessionDefinition extends BaseModel {
    foreign_id?: number;
    displays_department_id?: number;
    departments_display?: DepartmentsDisplay;
    ip_address?: string;
    browser?: string;
    last_push?: string;
    latency?: number;
    last_settings?: string;
    user_id?: number;
    user?: User;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.foreign_id;
            delete this.displays_department_id;
            delete this.departments_display;
            delete this.ip_address;
            delete this.browser;
            delete this.last_push;
            delete this.latency;
            delete this.last_settings;
            delete this.user_id;
            delete this.user;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.foreign_id != null) {
            this.foreign_id = data.foreign_id;
        }
        if (data.displays_department_id != null) {
            this.displays_department_id = data.displays_department_id;
        }
        if (data.departments_display != null) {
            this.departments_display = new DepartmentsDisplay(data.departments_display);
        }
        if (data.ip_address != null) {
            this.ip_address = data.ip_address;
        }
        if (data.browser != null) {
            this.browser = data.browser;
        }
        if (data.last_push != null) {
            this.last_push = data.last_push;
        }
        if (data.latency != null) {
            this.latency = data.latency;
        }
        if (data.last_settings != null) {
            this.last_settings = data.last_settings;
        }
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
