import {Deadline, User} from "@app/core/models";
import {EnhancedUser} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserItem";

export class WhoWhenEditItem {

    public user?: User;
    public isArchived: boolean;
    public deadline?: Deadline;

    constructor(data: EnhancedUser) {
        this.user = data.user;
        this.deadline = data.deadline;
        this.isArchived = data.isArchived;
    }

}
