import {NgModule} from "@angular/core";
import {PhaseSearchComponent} from "@app/shared/_forms/search/phase-search/phase-search.component";
import {NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";

@NgModule({
    imports: [
        NgbTypeaheadModule,
        TranslateModule,
        CommonModule,
        FormsModule,
        TypedTemplateDirective

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        PhaseSearchComponent,
    ],
    exports: [
        PhaseSearchComponent

    ],
    providers: [

    ],
})
export class PhaseSearchModule {}
