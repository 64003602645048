import {NgModule} from "@angular/core";
import {
    WhoWhenEditorComponent
} from "@app/editor/quick-editor/editors/generic/who-when-editor/who-when-editor.component";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {WhoWhenEditModule} from "@app/shared/_elements/who-when-edit/WhoWhenEdit.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbPopoverModule,
        TranslateModule,
        NgbTooltipModule,
        WhoWhenEditModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        WhoWhenEditorComponent,
    ],
    exports: [
        WhoWhenEditorComponent

    ],
    providers: [

    ],
})
export class WhoWhenEditorModule {}
