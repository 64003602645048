import {PhasesProject} from '@app/core/models/PhasesProject';
import {Color} from '@app/core/models/Color';
import {Phase, PhaseProgressType} from '@app/core/models';

export class PhasesProjectRow {
    phasesProject: PhasesProject;
    phase: Phase;
    name: string;
    color: Color;
    hasDate: boolean;
    date: Date;
    durationInDays: number;
    isCompleted = false;
    hasProgress: boolean;
    progressType: PhaseProgressType

    constructor(phasesProject: PhasesProject) {
        this.phasesProject = phasesProject;
        if(phasesProject && phasesProject.phase) {
            this.phase = phasesProject.phase;
            this.name = phasesProject.phase?.name;
            this.color = phasesProject.phase.color;
        }
        this.hasDate = phasesProject.getStartedDate() !== null;
        this.date = phasesProject.getStartedDate();
        this.hasProgress = phasesProject.current_phase_progress?.exists();
        this.progressType = phasesProject.current_phase_progress?.to_phase_progress_type;
    }

}
