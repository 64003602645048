<div class="column-sorter ellipsis d-flex align-items-center "
     [class.column-resizeable]="column.resizeable"
>
    <ng-container *ngIf="!column.sortItems || column.sortItems.length == 0  || column.sortItems.length == 1">
        <span
            [ngbTooltip]="column.name | translate"
            [translate]="column.name" class="dropdown-wrapper ellipsis"></span>
    </ng-container>

    <ng-container *ngIf="column.sortItems && column.sortItems.length > 1">
        <div ngbDropdown container="body" class="dropdown-wrapper ellipsis d-flex align-items-center link-list">
            <div class="d-flex align-items-center cursor-pointer w-100">
                <i class="fa fal me-1 fa-circle-chevron-down cursor-pointer" aria-hidden="true"
                   [ngbTooltip]="('_ui_sorting' | translate)"
                   ngbDropdownToggle
                ></i>

                <ng-container *ngIf="initialSort">
                    <span
                        [ngbTooltip]="column.name | translate"
                        [translate]="column.name"
                        class="ellipsis flex-shrink-1 flex-grow-1"
                        [class.font-weight-bold]="isSortActive"
                        ngbDropdownToggle>{{ (column.name | translate) }}</span>
                </ng-container>

                <ng-container *ngIf="!initialSort">
                    <span
                        [ngbTooltip]="column.name | translate"
                        [translate]="column.name"
                        [class.font-weight-bold]="isSortActive"
                        class="ellipsis flex-shrink-1 flex-grow-1"
                        ngbDropdownToggle
                        *ngIf="!column.isList"
                    >{{ activeSort ? (activeSort.name | translate) : (column.name | translate) }}</span>

                    <span
                        [ngbTooltip]="column.name | translate"
                        [translate]="column.name"
                        [class.font-weight-bold]="isSortActive"
                        ngbDropdownToggle
                        *ngIf="column.isList"
                        class="ellipsis flex-shrink-1 flex-grow-1"
                    >{{ (column.name | translate) }}{{ activeSort ? ': ' + (activeSort.name | translate) : '' }}</span>

                    <div class="text-small ms-auto d-flex align-items-center ellipsis flex-shrink-1"
                         style="max-width: 50%;" *ngIf="isSortActive && !initialSort">
                        <i class="fa fal me-1 cursor-pointer" aria-hidden="true"
                           [ngbTooltip]="('_ui_sorting' | translate)"
                           [class.fa-sort-amount-up]="sortDirection === 'asc'"
                           [class.fa-sort-amount-down]="sortDirection === 'desc'"
                           *ngIf="isSortActive && !initialSort"
                           (click)="changeSortDirection()"
                        ></i>
                        <span class="ellipsis d-inline-block "
                              [ngbTooltip]="activeSort.name" *ngIf="activeSort"
                              ngbDropdownToggle
                        >{{ activeSort.name }}</span>
                    </div>

                </ng-container>

            </div>

            <div ngbDropdownMenu class="text-small">

                <div class="d-flex align-items-center ps-2 cursor-pointer hover-underline"
                     ngbDropdownItem
                     *ngFor="let sort of activeFilterSortTypes"
                     (click)="triggerClick(sort.id)"
                >


                    <i class="fa fal me-1 cursor-pointer" aria-hidden="true"
                       [class.fa-tv]="sort.sortType === Globals.SortTypes.Display"
                       [class.fa-table-columns]="sort.sortType === Globals.SortTypes.Column"
                       [class.fa-sort]="sort.sortType === Globals.SortTypes.Default"
                       *ngIf="sort.sortType"
                    ></i>


                    <span
                        class="me-2 ellipsis"
                        [ngbTooltip]="(column.name | translate) + ': '+ (sort.name | translate)"
                        [class.font-weight-bold]="sort.id === activeSort?.id && sort.hasOrderDirection">
                        {{ (sort.name | translate) }}
                    </span>

                    <i class="fa fal ms-auto cursor-pointer" aria-hidden="true"
                       [ngbTooltip]="('_ui_sorting' | translate)"
                       [class.fa-sort-amount-up]="sortDirection === 'asc'"
                       [class.fa-sort-amount-down]="sortDirection === 'desc'"
                       *ngIf="sort.id === activeSort?.id && sort.hasOrderDirection"
                    ></i>
                    <i class="fa fal ms-auto fa-bars-sort" aria-hidden="true"
                       *ngIf="!activeSort || sort.id !== activeSort.id || !sort.hasOrderDirection"
                    ></i>
                </div>

            </div>
        </div>
    </ng-container>

</div>
