<div class="d-flex w-100" *ngIf="user">

    <div class="mt-1 mb-1 d-flex bg-light rounded container p-1">
        <!-- Redigering -->
        <div class="p-2 w-100" *ngIf="milestonePlanId !== null">
            <div class="d-flex">
                <div class="d-flex" *ngIf="false">
                    <a class="d-flex flex-row align-items-center -ms-auto ps-2"
                       queryParamsHandling="merge"
                       [routerLink]="'/app/administrator'">
                        <app-toggle-item icon="fa-arrow-left" class="me-1"></app-toggle-item>
                        <span class="flex-fill">{{('_milestone_plans' | translate)}}...</span>
                    </a>
                </div>
                <app-milestone-plan-editor
                    [id]="milestonePlanId"
                    class="w-100"></app-milestone-plan-editor>
            </div>
        </div>

        <!-- Liste -->
        <div class="w-100 p-2" *ngIf="milestonePlanId === null">
            <app-milestone-plan-list (onSelect)="onSelect($event)"
                                     [sortable]="true"
                                     [showEditable]="false"></app-milestone-plan-list>
        </div>
    </div>

</div>
