import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ]
})
export class ConfirmationDialogComponent extends BaseModalComponent implements OnInit {
    @Input() primaryClass: string = 'btn-danger';
    @Input() showTextInput: boolean = false;
    @Input() escClose: boolean = true;
    description: string;

    constructor(private modal: NgbActiveModal) {
        //this._activeModal = activeModal;
        super(modal);
    }

    ngOnInit() {
    }

    public accept() {
        console.log('dialog accepted');
        this.activeModal.close(this.showTextInput ? this.description : true);
    }


}
