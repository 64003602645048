import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {MilestoneListConfiguration} from "@app/shared/_ui/lists/milestone-list/MilestoneListConfiguration";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";

export class FollowingMilestoneListCell extends BaseCell {

    public listConfiguration = new MilestoneListConfiguration();

    public constructor(row: BaseRow, column: BaseColumn) {
        super(row, column);

        this.listConfiguration
            .setLimit(ListConfiguration.SmartLimit)
            .setArchived(false)
            .setShowPhases(true)
            .setShowCreateNew(true);
    }

}
