import {User} from '@app/core/models/User';
import {ProjectType} from '@app/core/models/ProjectType';
import {ListConfiguration} from '@app/shared/_ui/lists/ListConfiguration';
import {Department} from '@app/core/models/Department';
import {Project, ProjectUserTypes} from '@app/core/models/Project';
import * as moment from 'moment';
import {ProjectFetchRequest} from '@app/shared/_ui/lists/project-list/ProjectFetcher';
import {CreateItemSourceConfiguration} from '@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration';
import {CreateItemPreset} from '@app/shared/_ui/create-item-dropdown/CreateItemPreset';
import {ProjectsUser} from '@app/core/models/ProjectsUser';
import {CreateItemInterface} from '@app/shared/_ui/create-item-dropdown/CreateItemInterface';
import Globals, {ProjectDeadlineTypes} from '@app/constants';
import {CreateItemSourceInterface} from '@app/shared/_ui/create-item-dropdown/CreateItemSourceInterface';
import {AppInjector} from '@app/services/app-injector.service';
import {CardInterface, CardItem} from "@app/shared/_ui/cards/CardItem";
import {CardProjectConfiguration} from "@app/shared/_ui/cards/medium/card-project/card-project-configuration";
import {Subscription} from "rxjs";
import {EventService} from "@app/services/event.service";
import {CdkDropEvent} from "@app/interfaces/CdkDropEvent";
import {Deadline, PhasesProject, ProjectsDeadline} from "@app/core/models";
import Helpers from "@app/core/helpers";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import {ProjectsService} from "@app/services/projects.service";
import {UsersService} from "@app/services/users.service";
import {BaseApi} from "@app/core/http/Api/BaseApi";
import {Api} from "@app/core/http/Api/Api";
import {FilterGlobalService} from '@app/services/FilterGlobalService/filter-global.service';
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";

export class ProjectListConfiguration extends ListConfiguration<Project> implements CreateItemInterface<Project>,
    CreateItemSourceInterface, CardInterface<Project>, ListDragInterface<Project> {

    // Filter
    protected modelClass: string = Globals.ItemTypes.Project;
    private projectType: ProjectType;
    private user?: User;
    private archived?: boolean;
    private projectTypeIds?: number[];
    private avoidIds?: number[];
    private phaseIds?: number[];
    private currentPhaseId?: number;
    private currentPhaseProgressTypeId?: number;
    private orArchivedSince: Date;
    private archivedBetween: Date[];
    private department: Department;
    private useGlobalFilter: boolean;
    private projectDeadlineTypeIds: number[];
    private deadlineBetween: Date[];
    private deadlineValidator: (project: Project, deadlines: Deadline[]) => boolean;
    private deadlineValidatorDate: () => Date;
    private open: boolean = null;
    private hasNonDeadline: boolean;
    private categoryTypeId?: number;
    private avoidCategoryTypeId?: number;
    private categoryIds?: number[];
    private avoidCategoryIds?: number[];

    // UI
    public showChangeType =  true;
    public showProjectPhase = true;
    public showProjectPhaseIfHasProgress = true;
    private showMiniCard_NextMilestone = true;
    private cardIsMini= false;
    private search: string;
    private cardProjectConfiguration: CardProjectConfiguration = new CardProjectConfiguration();
    private cardConfiguration_excludedCategoryIds: number[];

    // Data Provider
    private dataSource: ProjectFetchRequest;

    // Limit & Order
    private orderBy: string[][] = [['main_status.status_id', 'desc']];
    // Order requirements (When an order require a filter to be set)
    private sort_CategoryTypeId: number;
    private sort_ProjectDeadlineTypeId: number;
    private sort_DeadlineValidator: (project: Project) => boolean;

    // Hooks
    private customAllowCardItemDropFunction: ((cardItem: CardItem<Project>, fromListConfiguration: ProjectListConfiguration, result: (allow: boolean) => void) => void);
    private customDragAndDropFunction: ((cardItem: CardItem<Project>, configuration: ProjectListConfiguration) => void);

    constructor() {
        super();
        this.createItemConfiguration = CreateItemSourceConfiguration.AllInDepartment(AppInjector.getInjector().get(UsersService).user?.departments?.map(d => d.id) || []);
        this.createItemConfiguration.sourceInterface = this;
        this.createItemConfiguration.showTasks = false;
        this.createItemConfiguration.showMilestone = false;
        this.createItemConfiguration.showTodo = false;
        this.createItemPreset = new CreateItemPreset();
        this.createItemPreset.createProjectInterface = this;
    }

    public validate(project: Project): boolean {
        const debug = false;

        if (this.getProjectType()) {
            if (this.getProjectType().id != project.project_type_id) {
                return false;
            }
        }
        if (this.getUser()) {
            if (project.projects_users == null || project.findProjectsUsersByType(ProjectUserTypes.Responsible).find(item => item.user_id == this.getUser().id) == null) {
                return false;
            }
        }
        if (this.getArchived() != null && !this.getOrArchivedSince()) {
            if (this.getArchived() && project.archived_id == 0) {
                return false;
            } else if (!this.getArchived() && project.archived_id > 0) {
                return false;
            }
        }
        if (this.getOrArchivedSince()) {
            if (project.archived) {
                if (moment(project.archived.created).toDate().getTime() < this.getOrArchivedSince().getTime()) {
                    return false;
                }
            }
        }
        if (this.getAvoidIds()) {
            if (this.getAvoidIds().includes(project.id)) {
                return false;
            }
        }
        if (this.getProjectTypeIds() && this.getProjectTypeIds().length) {
            if (!this.getProjectTypeIds().includes(project.project_type_id)) {
                if (debug) {
                    console.warn('ignored, cause of project type ids : ', project.project_type_id, this.getProjectTypeIds());
                }
                return false;
            }
        }
        if (this.getPhaseIds()) {
            let found = false;
            this.getPhaseIds().forEach(pid => {
                if (project.phases_projects.findIndex(pp => pp.phase_id == pid && pp.is_current) !== -1) {
                    found = true;
                }
            })
            return found;
        }
        if (this.getCurrentPhaseId() !== undefined && this.getCurrentPhaseId() !== null) {
            if (this.getCurrentPhaseId() === 0) {
                if (project.current_phases_project?.exists()) {
                    if (debug) {
                        console.warn('ignored, cause of current phase exists : ', this.getCurrentPhaseId(), project.current_phases_project?.phase_id);
                    }
                    return false;
                }
            } else if (this.getCurrentPhaseId() != project.current_phases_project?.phase_id) {
                if (debug) {
                    console.warn('ignored, cause of current phase id : ', this.getCurrentPhaseId(), project.current_phases_project?.phase_id);
                }
                return false;
            }
        }
        if (this.getCurrentPhaseProgressTypeId()) {
            if (this.getCurrentPhaseProgressTypeId() != project.current_phases_project?.current_phase_progress?.to_phase_progress_type_id) {
                if (debug) {
                    console.warn('ignored, cause of current phase progress type id');
                }
                return false;
            }
        }
        if (this.getProjectDeadlineTypeIds()?.length > 0) {
            const hasProjectDeadlineTypeIds = project.projects_deadlines?.map(projectsDeadline => projectsDeadline.project_deadline_type_id) ?? [];
            if (this.getProjectDeadlineTypeIds().filter(value => hasProjectDeadlineTypeIds.includes(value)).length == 0) {
                if (debug) {
                    console.warn('ignored, cause of project deadline type ids', hasProjectDeadlineTypeIds);
                }
                return false;
            }
        }
        if (this.getDeadlineBetween()) {
            const period = this.getDeadlineBetween();
            let hasDeadlineBetween = false;
            project.projects_deadlines?.forEach(projectsDeadline => {
                if (projectsDeadline.deadline?.isBetween(period[0], period[1])) {
                    hasDeadlineBetween = true;
                }
            });
            if (!hasDeadlineBetween) {
                if (debug) {
                    console.warn('ignored, cause of deadline between');
                }
                return false;
            }
        }
        if (this.getDeadlineValidator()) {
            let deadlines = project.projects_deadlines
                ?.filter(projectsDeadline => projectsDeadline.deadline != null)
                ?.map(projectsDeadline => projectsDeadline.deadline) ?? [];
            if (!this.getDeadlineValidator()(project, deadlines)) {
                return false;
            }
        }
        if (this.getOpen() !== null) {
            if (project.open != this.getOpen()) {
                if (debug) {
                    console.warn('ignored, cause of open');
                }
                return false;
            }
        }
        if (this.getCategoryTypeId()) {
            const categoryTypeId = this.getCategoryTypeId();
            if (project.categories?.find(category => category.category_type_id == categoryTypeId) === undefined) {
                if (debug) {
                    console.warn('ignored, cause of category type id', project.categories, categoryTypeId);
                }
                return false;
            }
        }
        if (this.getAvoidCategoryTypeId()) {
            if (project.categories?.find(category => category.category_type_id == this.getAvoidCategoryTypeId()) !== undefined) {
                if (debug) {
                    console.warn('ignored, cause of avoid category type id', project.categories, this.getAvoidCategoryTypeId());
                }
                return false;
            }
        }
        if (this.getCategoryIds()) {
            const categoryIds = this.getCategoryIds();
            if (project.categories?.find(category => categoryIds.includes(category.id)) === undefined) {
                if (debug) {
                    console.warn('getCategoryIds() ignored, cause of category ids', project, project.categories, categoryIds);
                }
                return false;
            }
        }
        if (this.getAvoidCategoryIds()) {
            const avoidCategoryIds = this.getAvoidCategoryIds();
            if (project.categories?.find(category => avoidCategoryIds.includes(category.id)) !== undefined) {
                if (debug) {
                    console.warn('getAvoidCategoryIds() ignored, cause of avoid category ids', project.categories, avoidCategoryIds);
                }
                return false;
            }
        }


        if (this.getSort_CategoryTypeId()) {
            const sortCategoryTypeId = this.getSort_CategoryTypeId();
            if (project.categories?.find(category => category.category_type_id == sortCategoryTypeId) === undefined) {
                if (debug) {
                    console.warn('ignored, cause of sort category type id', project.categories, sortCategoryTypeId);
                }
                return false;
            }
        }
        if (this.getSort_DeadlineValidator()) {
            if (!this.getSort_DeadlineValidator()(project)) {
                return false;
            }
        }

        // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1529
        // Filtrering sker i ProjectFetcher.ts
        if (this.getUseGlobalFilter() && false) {
            let filters = AppInjector.getInjector().get(FilterGlobalService).getActiveSettings();
            if (filters.isHandUp) {
                if (project.num_hand_ups == 0) {
                    if (debug) {
                        console.warn('ignored, cause of global hand up');
                    }
                    return false;
                }
            }
            if (filters.isStarred) {
                if (project.num_stars == 0) {
                    if (debug) {
                        console.warn('ignored, cause of global starred');
                    }
                    return false;
                }
            }
            if (filters.activeStatuses.length > 0 && filters.activeStatuses.length < 4) {
                if (project.main_status == null || !filters.activeStatuses.includes(project.main_status.status_id)) {
                    if (debug) {
                        console.warn('ignored, cause of global main status');
                    }
                    return false;
                }
            }
            if (filters.activeReactionFilters?.length > 0) {
                const reactionFilterMap = new Map<number, string[]>();
                filters.activeReactionFilters.forEach(reactionFilter => {
                    if (!reactionFilterMap.has(reactionFilter.reaction_type_id)) {
                        reactionFilterMap.set(reactionFilter.reaction_type_id, []);
                    }
                    reactionFilterMap.get(reactionFilter.reaction_type_id).push(reactionFilter.value);
                });
                let validReactionFilter = true;
                reactionFilterMap.forEach((value, key) => {
                    if ((project.reactions?.filter(reaction => value.includes(reaction.value))?.length ?? 0) == 0) {
                        validReactionFilter = false;
                        return;
                    }
                });
                if (!validReactionFilter) {
                    if (debug) {
                        console.warn('ignored, cause of reaction filter');
                    }
                    return false;
                }
            }
            if (filters.activeCategories?.length > 0) {

                const categoriesMap = new Map<number, number[]>();
                filters.activeCategories.forEach(category => {
                    if (!categoriesMap.has(category.category_type_id)) {
                        categoriesMap.set(category.category_type_id, []);
                    }
                    categoriesMap.get(category.category_type_id).push(category.id);
                });
                let validCategories = true;
                categoriesMap.forEach((value, key) => {
                    if ((project.categories?.filter(category => value.includes(category.id))?.length ?? 0) == 0) {
                        validCategories = false;
                        return;
                    }
                });
                console.log('filters : ', filters.activeCategories, 'validCategories : ', validCategories)
                if (!validCategories) {
                    if (debug) {
                        console.warn('ignored, cause of categories : ', filters.activeCategories);
                    }
                    return false;
                }
            }
        }

        return true;
    }

    // <editor-fold desc="Create Item">

    prepareSource(): void {
        if (this.getProjectType()) {
            this.createItemConfiguration.filterProjectsTypesById = [this.getProjectType().id];
        }
        if (this.getProjectTypeIds()) {
            this.createItemConfiguration.filterProjectsTypesById = this.getProjectTypeIds();
        }

        // https://podio.com/klartboard/softwareudvikling/apps/supports/items/355
        this.createItemConfiguration.filterByDepartmentIds = AppInjector.getInjector().get(UsersService).user?.departments?.map(d => d.id) || [];
    }

    public createPresets(typeId?: number): CreatePreset[] {
        return this.createPresetGenerators?.map(generator => generator.generate(typeId)) ?? [];
    }

    // </editor-fold>

    // <editor-fold desc="Data Listeners">

    protected setupDataSource() {
        if (this.getDataSource()) {
            this.getDataSource().subscribeToItems((projects: Project[]) => {
                const items = projects.map(project => this.createCard(project));
                this.onAddItemsEvent.emit({items: items});
                this.setLastItemCount(items.length);
            });
            this.getDataSource().subscribeToCount((count: number) => {
                this.onCountEvent.emit({count: count});
            });
        }
    }

    public createCard(item: Project): CardItem<Project> {
        // const config = new CardProjectConfiguration();
        this.cardProjectConfiguration.showChangeType = this.showChangeType;
        this.cardProjectConfiguration.isDraggable = this.isDraggable();
        this.cardProjectConfiguration.useGlobalFilter = this.useGlobalFilter;
        this.cardProjectConfiguration.showPhase = this.showProjectPhase;
        this.cardProjectConfiguration.showPhaseIfHasProgress = this.showProjectPhaseIfHasProgress;
        this.cardProjectConfiguration.excludedCategoryIds = this.cardConfiguration_excludedCategoryIds;
        this.cardProjectConfiguration.showNextMilestoneMicroCard = this.getShowMiniCard_NextMilestone();
        this.cardProjectConfiguration.displayModeMini = this.getCardDisplayModeMini();

        if (this.getProjectDeadlineTypeIds()?.length > 0) {
            this.cardProjectConfiguration.projectDeadline = item.projects_deadlines?.find(projectsDeadline => this.getProjectDeadlineTypeIds().includes(projectsDeadline.project_deadline_type_id));
        }

        return new CardItem<Project>(item, this.cardProjectConfiguration, this);
    }

    // </editor-fold>

    // <editor-fold desc="Push">

    private pushSubscription: Subscription;

    public pushSubscribe() {
        this.pushSubscription = new Subscription();
        this.pushSubscription.add(AppInjector.getInjector().get(EventService).subscribeToProject(0, event => {
            switch (event.action) {
                case EventService.Created:
                    if (this.validate(event.item)) {
                        this.onAddItemEvent.emit({item: this.createCard(event.item)});
                    }
                    break;
                case EventService.Updated:
                    const itemsToUpdate = this.dataSource?.getItemsById(event.item.id);
                    if (itemsToUpdate?.length > 0) {
                        itemsToUpdate.forEach(item => {
                            let entity = {};
                            if (event.isPatch && event.fields?.length)  {
                                event.fields.forEach(field => entity[field] = event.item[field]);
                            } else {
                                entity = {...event.item};
                            }
                            item.populate(entity, event.isPatch);
                            if (!this.validate(item)) {
                                this.onRemoveItemEvent.emit({item: this.createCard(event.item)});
                            }
                        });
                    } else if (this.validate(event.item)) {
                        this.onAddItemEvent.emit({item: this.createCard(event.item)});
                    }
                    break;
                case EventService.Deleted:
                    this.onRemoveItemEvent.emit({item: this.createCard(event.item)});
                    break;
            }
        }));
    }

    public pushUnsubscribe() {
        this.pushSubscription?.unsubscribe();
    }

    // </editor-fold>

    // <editor-fold desc="Card Interface">

    public onCardItemDragged(cardItem: CardItem<Project>, listConfiguration: ListConfiguration, toContainerId: string, fromContainerId: string, cdkDropEvent: CdkDropEvent<Project>): void {

    }

    public onCardItemUpdated(cardItem: CardItem<Project>): void {
        if (!this.validate(cardItem.item)) {
            this.onRemoveItemEvent.emit({item: cardItem});
        }
    }

    public onCardItemDeleted(cardItem: CardItem<Project>): void {
        this.onRemoveItemEvent.emit({item: cardItem});
    }

    // </editor-fold>

    // <editor-fold desc="ListDragInterface">

    public allowCardItemDrop(cardItem: CardItem<Project>, fromListConfiguration: ListConfiguration<Project>, result: (allow: boolean) => void) {
        if (!(cardItem.item instanceof Project)) {
            result(false);
            return;
        }

        if (this.getCustomAllowCardItemDropFunction()) {
            this.getCustomAllowCardItemDropFunction()(cardItem, fromListConfiguration, result);
        } else {
            result(true);
        }
    }

    public onCardItemDragAdd(cardItem: CardItem<Project>, configuration: ProjectListConfiguration, callback: () => void) {
        if (!(cardItem.item instanceof Project)) {
            return;
        }

        if (this.getCustomDragAndDropFunction()) {
            this.getCustomDragAndDropFunction()(cardItem, configuration);
        } else {
            this.defaultDragAndDropFunction(cardItem, configuration);
        }

        this.onAddItemEvent.emit({item: this.createCard(cardItem.item)});
        callback();
    }

    private defaultDragAndDropFunction(cardItem: CardItem<Project>, fromListConfiguration: ProjectListConfiguration) {
        let project = cardItem.item as Project;
        let projectConfig = (cardItem.configuration as CardProjectConfiguration);

        // Move deadline
        let deadlineDate: Date;
        if (this.getDeadlineBetween()) {
            deadlineDate = this.getDeadlineBetween()[0];
        } else if (this.getDeadlineValidatorDate()) {
            deadlineDate = this.getDeadlineValidatorDate()();
        }
        if (deadlineDate && projectConfig.projectDeadline && project.projects_deadlines) {
            cardItem.item.setDeadline(
                projectConfig.projectDeadline.project_deadline_type_id,
                new Deadline({date: Helpers.serverDate(deadlineDate)})
            );
        }

        // Current phase progress type
        if (this.getCurrentPhaseProgressTypeId()) {
            project.current_phases_project.current_phase_progress.to_phase_progress_type_id = this.getCurrentPhaseProgressTypeId();
            Api.projects().phasesProjectProgressPutByProjectIdByPhasesProjectId(project.id, project.current_phases_project_id)
                .phaseProgressTypeId(this.getCurrentPhaseProgressTypeId())
                .save(null, value => {
                    project.current_phases_project.current_phase_progress = value.current_phases_project.current_phase_progress;
                    project.current_phases_project.current_phase_progress_id = value.current_phases_project.current_phase_progress_id;

                    AppInjector.getInjector().get(EventService).emitProject(project, EventService.Updated, [
                        'current_phases_project',
                        'phases_projects',
                    ]);
                });
        }
    }

    public onCardItemDragRemove(cardItem: CardItem<Project>) {
        if (!(cardItem.item instanceof Project)) {
            return;
        }
        this.onRemoveItemEvent.emit({item: cardItem});
    }

    public onCardItemDragSaved() {

    }

    // </editor-fold>

    // <editor-fold desc="Get / Set">

    public getDataSource(): ProjectFetchRequest {
        return this.dataSource;
    }

    public setDataSource(value: ProjectFetchRequest): ProjectListConfiguration {
        this.dataSource = value;
        this.setupDataSource();
        return this;
    }

    public getOrderBy(): string[][] {
        return this.orderBy;
    }

    public setOrderBy(value: string[][]): ProjectListConfiguration {
        this.orderBy = value;
        return this;
    }

    public getLimit(): number {
        return super.getLimit();
    }

    public setLimit(value: number): ProjectListConfiguration {
        super.setLimit(value);
        return this;
    }

    public getUser(): User {
        return this.user;
    }

    public setUser(value: User): ProjectListConfiguration {
        this.user = value;
        return this;
    }

    public getArchived(): boolean {
        return this.archived;
    }

    public setArchived(value: boolean): ProjectListConfiguration {
        this.archived = value;
        return this;
    }

    public getCurrentPhaseId(): number {
        return this.currentPhaseId;
    }

    public setCurrentPhaseId(value: number): ProjectListConfiguration {
        this.currentPhaseId = value;
        return this;
    }

    public getCurrentPhaseProgressTypeId(): number {
        return this.currentPhaseProgressTypeId;
    }

    public setCurrentPhaseProgressTypeId(value: number): ProjectListConfiguration {
        this.currentPhaseProgressTypeId = value;
        return this;
    }

    public getProjectTypeIds(): number[] {
        return this.projectTypeIds;
    }

    public setProjectTypeIds(value: number[]): ProjectListConfiguration {
        this.projectTypeIds = value;
        return this;
    }

    public getAvoidIds(): number[] {
        return this.avoidIds;
    }

    public setAvoidIds(value: number[]): ProjectListConfiguration {
        this.avoidIds = value;
        return this;
    }

    public getPhaseIds(): number[] {
        return this.phaseIds;
    }

    public setPhaseIds(value: number[]): ProjectListConfiguration {
        this.phaseIds = value;
        return this;
    }

    public getOrArchivedSince(): Date {
        return this.orArchivedSince;
    }

    public setOrArchivedSince(value: Date): ProjectListConfiguration {
        this.orArchivedSince = value;
        return this;
    }

    public getProjectType(): ProjectType {
        return this.projectType;
    }

    public setProjectType(value: ProjectType): ProjectListConfiguration {
        this.projectType = value;
        return this;
    }

    public getDepartment(): Department {
        return this.department
    }

    public setDepartment(value: Department): ProjectListConfiguration {
        this.department = value;
        return this;
    }

    public getUseGlobalFilter(): boolean {
        return this.useGlobalFilter;
    }

    public setUseGlobalFilter(value: boolean): ProjectListConfiguration {
        this.useGlobalFilter = value;
        return this;
    }

    public getSearch(): string {
        return this.search;
    }

    public setSearch(value: string): ProjectListConfiguration {
        this.search = value;
        return this;
    }

    public setUseGlobalSearchFilter(value: boolean): ProjectListConfiguration {
        super.setUseGlobalSearchFilter(value);
        return this;
    }

    public getProjectDeadlineTypeIds(): number[] {
        return this.projectDeadlineTypeIds;
    }

    public setProjectDeadlineTypeIds(value: number[]): ProjectListConfiguration {
        this.projectDeadlineTypeIds = value;
        return this;
    }

    public getDeadlineBetween(): Date[] {
        return this.deadlineBetween;
    }

    public setDeadlineBetween(start: Date, end: Date): ProjectListConfiguration {
        if (start != null && end != null) {
            this.deadlineBetween = [start, end];
        } else {
            this.deadlineBetween = null;
        }
        return this;
    }

    public getDeadlineValidator(): (project: Project, deadlines: Deadline[]) => boolean {
        return this.deadlineValidator;
    }

    public setDeadlineValidator(value: ((project: Project, deadlines: Deadline[]) => boolean)): ProjectListConfiguration {
        this.deadlineValidator = value;
        return this;
    }

    public getDeadlineValidatorDate(): () => Date {
        return this.deadlineValidatorDate;
    }

    public setDeadlineValidatorDate(value: () => Date): ProjectListConfiguration {
        this.deadlineValidatorDate = value;
        return this;
    }

    public getOpen(): boolean {
        return this.open;
    }

    public setOpen(value: boolean): ProjectListConfiguration {
        this.open = value;
        return this;
    }

    public getCustomAllowCardItemDropFunction(): ((cardItem: CardItem<Project>, fromListConfiguration: ListConfiguration<Project>, result: (allow: boolean) => void) => void) {
        return this.customAllowCardItemDropFunction;
    }

    public setCustomAllowCardItemDropFunction(value: ((cardItem: CardItem<Project>, fromListConfiguration: ListConfiguration<Project>, result: (allow: boolean) => void) => void)): ProjectListConfiguration {
        this.customAllowCardItemDropFunction = value;
        return this;
    }

    public getHasNonDeadline(): boolean {
        return this.hasNonDeadline;
    }

    public setHasNonDeadline(value: boolean): ProjectListConfiguration {
        this.hasNonDeadline = value;
        return this;
    }

    public getArchivedBetween(): Date[] {
        return this.archivedBetween;
    }

    public setArchivedBetween(start: Date, end: Date): ProjectListConfiguration {
        if (start != null && end != null) {
            this.archivedBetween = [start, end];
        } else {
            this.archivedBetween = null;
        }
        return this;
    }

    public getCategoryIds(): number[] {
        return this.categoryIds;
    }

    public setCategoryIds(value: number[]): ProjectListConfiguration {
        this.categoryIds = value;
        return this;
    }

    public getCustomDragAndDropFunction(): ((cardItem: CardItem<Project>, configuration: ProjectListConfiguration) => void) {
        return this.customDragAndDropFunction;
    }

    public setCustomDragAndDropFunction(value: ((cardItem: CardItem<Project>, configuration: ProjectListConfiguration) => void)): ProjectListConfiguration {
        this.customDragAndDropFunction = value;
        return this;
    }

    public getCategoryTypeId(): number {
        return this.categoryTypeId;
    }

    public setCategoryTypeId(value: number): ProjectListConfiguration {
        this.categoryTypeId = value;
        return this;
    }

    public getAvoidCategoryTypeId(): number {
        return this.avoidCategoryTypeId
    }

    public setAvoidCategoryTypeId(value: number): ProjectListConfiguration {
        this.avoidCategoryTypeId = value;
        return this;
    }

    public getAvoidCategoryIds(): number[] {
        return this.avoidCategoryIds
    }

    public setAvoidCategoryIds(value: number[]): ProjectListConfiguration {
        this.avoidCategoryIds = value;
        return this;
    }

    public getCardConfiguration_ExcludedCategoryIds(): number[] {
        return this.cardConfiguration_excludedCategoryIds;
    }

    public setCardConfiguration_ExcludedCategoryIds(value: number[]): ProjectListConfiguration {
        this.cardConfiguration_excludedCategoryIds = value;
        return this;
    }

    public getShowMiniCard_NextMilestone(): boolean {
        return this.showMiniCard_NextMilestone;
    }

    public setShowMiniCard_NextMilestone(value: boolean): ProjectListConfiguration {
        this.showMiniCard_NextMilestone = value;
        return this;
    }

    public getCardDisplayModeMini(): boolean {
        return this.cardIsMini;
    }
    public setCardDisplayModeMini(cardIsMini: boolean): ProjectListConfiguration {
        this.cardIsMini = cardIsMini;
        return this;
    }

    // </editor-fold>

    // <editor-fold desc="Sort Filters (When a sort require a filter)">

    public getSort_ProjectDeadlineId(): number {
        return this.sort_ProjectDeadlineTypeId;
    }

    public setSort_ProjectDeadlineId(value: number): ProjectListConfiguration {
        this.sort_ProjectDeadlineTypeId = value;
        return this;
    }

    public getSort_CategoryTypeId(): number {
        return this.sort_CategoryTypeId;
    }

    public setSort_CategoryTypeId(value: number): ProjectListConfiguration {
        this.sort_CategoryTypeId = value;
        return this;
    }

    public getSort_DeadlineValidator(): (project: Project) => boolean {
        return this.sort_DeadlineValidator;
    }

    public setSort_DeadlineValidator(value: ((project: Project) => boolean)): ProjectListConfiguration {
        this.sort_DeadlineValidator = value;
        return this;
    }

    public clearSortFilters() {
        this.setSort_ProjectDeadlineId(null);
        this.setSort_CategoryTypeId(null);
        this.setSort_DeadlineValidator(null);
    }

    public applySortFiltersToApi(api: BaseApi) {
        if (this.getSort_ProjectDeadlineId()) {
            api.where('projects_deadline.project_deadline_type_id', this.getSort_ProjectDeadlineId());
        }
        if (this.getSort_CategoryTypeId()) {
            api.where('category.category_type_id', this.getSort_CategoryTypeId());
        }
    }

    // </editor-fold>

}
