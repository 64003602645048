import {Row} from "@app/pages/displays/display-project-details/subdisplay-milestones/Row";
import {Deadline, Task, TasksDeadline, TaskStatus, TasksUser} from "@app/core/models";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {TaskDeadlineTypes, TaskUserTypes} from "@app/constants";
import Helpers from "@app/core/helpers";
import * as moment from "moment";
import {AppInjector} from "@app/services/app-injector.service";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {EventService} from "@app/services/event.service";
import {tap} from "rxjs/operators";
import * as FileSaver from "file-saver";
import {NgxCaptureService} from "ngx-capture";

export class SubDisplayHelper {

    public static ApplyMilestoneMoveDirections(rows: Row[]) {
        // Reset all rows
        rows.forEach(row => {
            row.allowMilestoneMoveDown = false;
            row.allowMilestoneMoveUp = false;
        });

        if (rows.length > 1) {
            const firstWithoutDeadline = rows.find(row => row.milestoneCard.item.deadline_id == 0);
            if (firstWithoutDeadline && firstWithoutDeadline != rows[rows.length - 1]) {
                // Mark all from "firstWithoutDeadline" to second last as "allowMilestoneMoveDown"
                for (let i = rows.indexOf(firstWithoutDeadline); i < rows.length - 1; i++) {
                    rows[i].allowMilestoneMoveDown = true;
                }
                // Mark all from "firstWithoutDeadline"+1 to last as "allowMilestoneMoveUp"
                for (let i = rows.indexOf(firstWithoutDeadline) + 1; i < rows.length; i++) {
                    rows[i].allowMilestoneMoveUp = true;
                }
            }
        }
    }

    public static DragDropUpdateMilestone(fromListConfiguration: TaskListConfiguration,
                                          toListConfiguration: TaskListConfiguration,
                                          cardItem: CardItem<Task>,
                                          previousIndex?: number, nextIndex?: number) {
        if (fromListConfiguration == toListConfiguration) {

        } else {
            const fromMilestone = fromListConfiguration.getMilestone();
            const toMilestone = toListConfiguration.getMilestone();
            if (fromMilestone != toMilestone) {
                cardItem.item.replaceMilestone(fromMilestone, toMilestone);
            }
        }
    }

    public static DragDropUpdateUser(fromListConfiguration: TaskListConfiguration,
                                     toListConfiguration: TaskListConfiguration,
                                     cardItem: CardItem<Task>) {
        const fromUser = fromListConfiguration.getUser();
        const toUser = toListConfiguration.getUser();
        if (fromUser?.id == toUser?.id) {
            return;
        }

        if (fromUser) {
            cardItem.item.replaceUser(TaskUserTypes.Participant, fromUser, toUser);
        } else {
            cardItem.item.addUser(TaskUserTypes.Participant, toUser);
        }
    }

    public static DragDropUpdateDeadlineDate(fromListConfiguration: TaskListConfiguration,
                                             toListConfiguration: TaskListConfiguration,
                                             cardItem: CardItem<Task>,
                                             date: Date) {
        const fromDeadline = fromListConfiguration.getDeadlineBetween();
        const toDeadline = toListConfiguration.getDeadlineBetween();
        if (!toDeadline) {
            return;
        }

        if (!fromDeadline
            || fromDeadline[0].getTime() != toDeadline[0].getTime()
            || fromDeadline[1].getTime() != toDeadline[1].getTime()) {
            const taskDeadlineTypeId = toListConfiguration.getTaskDeadlineTypes()?.length
                ? toListConfiguration.getTaskDeadlineTypes()[0]
                : TaskDeadlineTypes.Normal;
            cardItem.item.setDeadline(taskDeadlineTypeId, Deadline.Create(date));
        }
    }

    public static DragDropUpdatePlanningDate(fromListConfiguration: TaskListConfiguration,
                                             toListConfiguration: TaskListConfiguration,
                                             cardItem: CardItem<Task>,
                                             date: Date) {
        const fromDeadline = fromListConfiguration.getPlannedBetween();
        const toDeadline = toListConfiguration.getPlannedBetween();
        if (!toDeadline) {
            return;
        }

        const hasChangedDeadline = fromDeadline[0].getTime() != toDeadline[0].getTime() || fromDeadline[1].getTime() != toDeadline[1].getTime();
        const participants = cardItem.item.findUsersByTypeId(TaskUserTypes.Participant);
        if (hasChangedDeadline && participants.length == 1) {
            cardItem.item.setUserDeadline(participants[0], date);
        } else if (hasChangedDeadline && participants.length > 1) {
            // Dialog: "Vil du ændre planlægningsdatoen for alle deltagere?"
            AppInjector.getInjector().get(BaseDialogService).confirm(
                AppInjector.getInjector().get(TranslateService).instant('_ui_dialog_more_participants'),
                AppInjector.getInjector().get(TranslateService).instant(
                    '_ui_dialog_more_participants_description',
                    {date: moment(date).format('DD/MM-YYYY')}
                )
            ).then(confirmed => {
                if (confirmed) {
                    participants.forEach(participant => {
                        cardItem.item.setUserDeadline(participant, date);
                    });
                }
            });
        }
    }

    public static DragDropUpdateStatus(fromListConfiguration: TaskListConfiguration,
                                       toListConfiguration: TaskListConfiguration,
                                       cardItem: CardItem<Task>) {
        const fromStatusId = fromListConfiguration.getMainStatusStatusIds()[0];
        const toStatusId = toListConfiguration.getMainStatusStatusIds()[0];
        if (fromStatusId != toStatusId) {
            cardItem.item.setStatus(toStatusId);
        }
    }

}
