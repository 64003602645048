import {NgModule} from "@angular/core";
import {GhostContainerComponent} from "@app/editor/ghost-container/ghost-container.component";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        LoadingIndicatorModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        GhostContainerComponent,
    ],
    exports: [
        GhostContainerComponent

    ],
    providers: [

    ],
})
export class GhostContainerModule {}
