import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DisplayDashboardComponent} from '@app/pages/displays/dashboard/display-dashboard.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ResizableModule} from 'angular-resizable-element';
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {DashboardHeaderModule} from "@app/pages/displays/dashboard/dashboard-header/DashboardHeader.module";
import {
    DashboardSidebarTaskListModule
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/DashboardSidebarTaskList.module";
import {DashboardCalendarModule} from "@app/pages/displays/dashboard/dashboard-calendar/DashboardCalendar.module";
import {DashboardProjectsModule} from "@app/pages/displays/dashboard/dashboard-projects/DashboardProjects.module";
import {
    DisplayProjectDetailsMilestonesModule
} from "@app/pages/displays/display-project-details/subdisplay-milestones/DisplayProjectDetailsMilestones.module";

@NgModule({
    declarations: [
        DisplayDashboardComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        NgxDatatableModule,
        DragDropModule,
        ResizableModule,
        LoadingIndicatorModule,
        NgbTooltipModule,
        DashboardHeaderModule,
        DashboardSidebarTaskListModule,
        DashboardCalendarModule,
        DashboardProjectsModule,
        DisplayProjectDetailsMilestonesModule,
    ]
})
export class DisplayDashboardModule {

}
