import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {Column, ColumnSetting} from "@app/core/models";
import {ColumnTypes} from "@app/pages/displays/display-kanban-progress/ColumnTypes";
import {PhaseProgressColumn} from "@app/pages/displays/display-kanban-progress/Columns/PhaseProgressColumn";

export class PhaseProgressColumnType extends BaseColumnType {

    public identifier = ColumnTypes.PhaseProgress;

    public isAlwaysVisible = false;
    public customWidth?: number;
    public minWidth = 214;
    public maxWidth?: number;
    public resizeable = true;
    public frozenLeft = false;
    public canAutoResize = true;
    public isList = false;
    public cellClass?: string;

    public createColumn(column: Column, settings: Map<string, ColumnSetting>): BaseColumn {
        return new PhaseProgressColumn(this, column, settings);
    }

}
