<ul class="list-unstyled d-flex flex-row w-100 h-100 position-relative">
    <ng-container
        *ngFor="let editor of enabledEditors; let i = index">
        <li class="editor-child-item align-self-center justify-content-start flex-shrink-0 pseudo-column-wrapper d-flex"
            *ngIf="editor.columnConfiguration.width !== undefined"
            [attr.data-type]="editor.columnName"
            [style.width.px]="editor.columnConfiguration.width">
            <app-column
                class="d-flex w-100  align-items-center align-self-center"
                [editor]="editor"
                [nextEditor]="enabledEditors[i+1]"
                [remainingWidth]="remainingWidth"
            ></app-column>
        </li>
    </ng-container>
</ul>
