/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {SupportRequestDefinition} from './definitions/SupportRequestDefinition';
import {OldApi} from '@app/http/Api';

export class SupportRequest extends SupportRequestDefinition {

    constructor(json?: any) {
        super(json);
    }


    public post(callback?: () => void) {
        OldApi.supportRequests().post(this).subscribe(response => {
            this.populate(response.resource);
            if(callback) callback();
        });
    }
}
