import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {NgProgress, NgProgressRef} from 'ngx-progressbar';

@Injectable({
    providedIn: 'root'
})
export class CoreService {

    public requestCount = 0;
    private pendingRequestCount: BehaviorSubject<number> = <BehaviorSubject<number>>new BehaviorSubject(0);
    public pendingRequestCount$: Observable<number>;
    private requestsPending: BehaviorSubject<boolean> = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    public requestsPending$: Observable<boolean>;
    private progressRef: NgProgressRef;
    private overlayVisible: BehaviorSubject<boolean> = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    public overlayVisible$: Observable<boolean>;
    private resetTimer: number;

    constructor(ngProgress: NgProgress) {
        this.pendingRequestCount$ = this.pendingRequestCount.asObservable();
        this.overlayVisible$ = this.overlayVisible.asObservable();
        this.requestsPending$ = this.overlayVisible.asObservable();
        this.progressRef = ngProgress.ref('root');
    }

    setOverlayVisible(visible: boolean) {
        this.overlayVisible.next(visible);
    }

    addRequest() {
        this.requestCount++;
        this.updatePendingRequests();
        this.resetTimeout();
    }

    updatePendingRequests() {
        clearTimeout(this.requestTimeoutTimer);
        this.requestTimeoutTimer = window.setTimeout(() => {
            this.pendingRequestCount.next(this.requestCount);
            this.requestsPending.next(this.requestCount > 0);
        }, 100)
    }

    private requestTimeoutTimer: number;

    completeRequest() {
        if (this.requestCount > 0) {
            this.requestCount--;
        }
        this.updatePendingRequests();

        if (this.requestCount == 0) {
            this.progressRef.complete();
            clearTimeout(this.resetTimer);
        }
    }

    private resetTimeout() {
        clearTimeout(this.resetTimer);
        this.resetTimer = window.setTimeout(() => {
            this.requestCount = 0;
            this.pendingRequestCount.next(this.requestCount);
            this.requestsPending.next(this.requestCount > 0);
        }, 60000)
    }
}
