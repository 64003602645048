import {NgModule} from "@angular/core";
import {HeaderSpacerComponent} from "@app/shared/_elements/header-spacer/header-spacer.component";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        HeaderSpacerComponent,
    ],
    exports: [
        HeaderSpacerComponent

    ],
    providers: [

    ],
})
export class HeaderSpacerModule {}
