import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";

export class ProjectPhaseCell extends GenericCell {

    public exportCSV(secondary?: any): CSVCellValue {
        return this.row.project.item.current_phases_project?.phase?.name ?? '';
    }

}
