import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {CaseTextTableColumn} from "@app/pages/displays/display-cases/TableColumns/CaseTextTableColumn";
import {CaseTextCell} from "@app/pages/displays/display-cases/Cells/CaseTextCell";
import {ColumnTypeSettings} from "@app/pages/displays/display-cases/ColumnTypeSettings";
import {CaseShelves_CaseTextFieldSettingValue} from "@app/core/http/Api/Api";

export class CaseTextColumn extends BaseColumn<CaseTextCell> {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new CaseTextTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: BaseRow): BaseCell {
        return new CaseTextCell(row, this);
    }

    public getField(): string | undefined {
        return this.settings.get(ColumnTypeSettings.CaseText_Field)
            ?.getObject<CaseShelves_CaseTextFieldSettingValue>()
            ?.fieldName;
    }

}
