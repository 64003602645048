<app-card-preview [mini]="false" *ngIf="!initialized && visible"></app-card-preview>

<div class="card p-1" #cardContainer
     *ngIf="model && initialized && visible && model.task_type"
     (click)="onCardClick($event)"
     [class.grayed-out]="isGrayedOut"
     [class.mini]="mini"
     [class.mb-0]="mini"
     [class.focused]="outline"
>

    <div class="d-flex w-100 font-size-lg  -mb-1">
        <app-color-label class="card-type me-1 flex-shrink-0"
                         *ngIf="isFieldVisible(Fields.Status)"
                         [status]="model.status"
                         [itemWithTypeProperties]="model"
                         [isNew]="item && item.item.acceptance_status == 0"
                         (onStatusChange)="onStatusChange($event)"></app-color-label>

        <div class="title p-0 font-weight-bold col -border-bottom -border-light flex-fill d-flex align-items-start"
             *ngIf="isFieldVisible(Fields.Title)">

            <i class="fa fa-lock-alt me-1 align-self-start" style="padding-top: 2px;" *ngIf="model.is_private"
               [ngbTooltip]="('_task_is_private' | translate)"
            ></i>
            <input class="form-control" [(ngModel)]="model.title" *ngIf="editable"/>
            <div *ngIf="!editable" class="pt-0 h-100 text-capitalize-firstletter"
                 [ngbTooltip]="model.title | placeholder: ('_ui_no_title' | translate)"
            >
                {{model.title | placeholder: ('_ui_no_title' | translate)}}
            </div>
        </div>
    </div>

    <a class="text-danger remove d-flex flex-row align-items-center ms-1 me-1"
       (click)="triggerRemove(model);$event.stopPropagation()"
       [ngbTooltip]="('_ui_emoji_clear' | translate)"
       *ngIf="removable">
        <i class="fal fa-eraser"></i>
    </a>

    <div class="d-flex justify-content-between font-size-md flex-wrap align-items-center card-details mt-1"
         *ngIf="!editable && !simple">

        <ng-template #popoverContent>
            <div class="scale-2x-wrapper">
                <div class="card-wrap">
                    <app-card-task *ngIf="item" [item]="item"></app-card-task>
                </div>
            </div>
        </ng-template>

        <div class="d-flex flex-row w-100 justify-content-start align-items-center scroll-x">
            <!-- Ikoner -->
            <ul class="flex-grow-1 d-flex list-unstyled justify-content-start align-items-center m-0 flex-wrap">

                <!-- https://podio.com/klartboard/softwareudvikling/apps/stories/items/1169 -->
                <li
                    class="pe-1"
                    #toolsPopover="ngbPopover"
                    [ngbPopover]="toolsDropdownContent"
                    [popoverClass]="'popover-tools'"
                    [autoClose]="'outside'"
                    placement="bottom left auto"
                    triggers="manual"
                    container="body"
                    (click)="$event.stopImmediatePropagation(); !toolsPopover.isOpen() ? toolsPopover.open() : toolsPopover.close()"
                >
                    <i class="fal fa-toolbox cursor-pointer" [ngbTooltip]="('_ui_navigation_tools' | translate)"></i>

                    <ng-template #toolsDropdownContent>

                        <span class="close-button d-none d-mobile-flex" (click)="toolsPopover.close()"><span class="fa fa-times"></span></span>

                        <ul class="d-flex list-unstyled justify-content-start align-items-center m-0 flex-wrap "
                            [class.disabled]="model.id == 0"
                        >
                            <li class="pe-1" *ngIf="isFieldVisible(Fields.Archived)">
                                <app-toggle-item [interactive]="true"
                                                 [active]="model.archived_id > 0"
                                                 [class.text-black-50]="!model?.archived_id"
                                                 [ngbTooltip]="!model?.archived_id || model?.archived_id == 0 ? translateService.instant('_ui_complete') : translateService.instant('_ui_reopen')"
                                                 [icon]="'fa-check-circle'"
                                                 [iconInactive]="'fa-circle'"
                                                 (onToggle)="onArchivedToggle($event); toolsPopover.close();"></app-toggle-item>
                            </li>
                            <li class="pe-1 ">
                                <app-hand-up-selector [model]="model"
                                                      [showUsers]="false"
                                                      (onToggle)=" toolsPopover.close();"></app-hand-up-selector>
                            </li>
                            <li class="pe-1  " *ngIf="model && isFieldVisible(Fields.Star)" [attr.data-field]="Fields.Star">
                                <app-star-selector [model]="model"
                                                   [showUsers]="false"
                                                   (onToggle)=" toolsPopover.close();"
                                                   ></app-star-selector>
                            </li>

                            <li *ngIf="item" class="pe-1 " >
                                <app-reactions [reactionsApiInterface]="item?.item"
                                               [reactionsSourceInterface]="item?.item"
                                               [reactionsTypeInterfaces]="reactionsTypeInterfaces"
                                               [reactions]="item?.item?.reactions"
                                               [reactionsEditable]="true"
                                               [reactionsVisible]="false"
                                               (onToggle)=" toolsPopover.close();"
                                               (click)="$event.stopImmediatePropagation()"></app-reactions>
                            </li>
                            <li class="pe-1 "
                                [ngbPopover]="popoverContent"
                                placement="bottom bottom-left left-bottom right-bottom top-left auto"
                                popoverClass="popover-transparent"
                                container="body"
                                [autoClose]="true"
                                triggers="mouseenter:mouseleave"
                            >
                                <a class="cursor-pointer">
                                    <i class="fal fa-search-plus "></i>
                                </a>

                            </li>
                            <li *ngIf="removable && false" class="pe-1"
                                (click)="triggerRemove(model);$event.stopPropagation()">
                                <a class="-text-danger remove d-flex flex-row align-items-center cursor-pointer"
                                >
                                    <i class="fal fa-trash-alt"></i>
                                </a>
                            </li>

                            <li class="pe-1"
                                [ngbTooltip]="translateService.instant('_ui_copy') + '...'"
                                (click)="copyTask(model);$event.stopPropagation();toolsPopover.close()">
                                <a class="-text-danger remove d-flex flex-row align-items-center cursor-pointer"
                                >
                                    <i class="fal fa-copy"></i>
                                </a>
                            </li>

                            <li *ngIf="configuration && configuration.showChangeType">
                                <a class="d-flex flex-row align-items-center pe-1"
                                   [ngbTooltip]="translateService.instant('_ui_change_type')"
                                   (click)="triggerChangeDialog(model); $event.stopImmediatePropagation();">
                                    <i class="fal fa-repeat"></i>
                                </a>
                            </li>

                            <li class="pe-1"
                                (click)="delete(model);$event.stopPropagation();toolsPopover.close();">
                                <a class="-text-danger remove d-flex flex-row align-items-center cursor-pointer text-danger"
                                >
                                    <i class="fal fa-trash-alt"></i>
                                </a>
                            </li>

                        </ul>
                    </ng-template>
                </li>

                <li class="pe-1 d-mini-none" *ngIf="isFieldVisible(Fields.Archived) && model?.archived_id != 0 && model?.archived_id != undefined">
                    <app-toggle-item [interactive]="true"
                                     [active]="model.archived_id > 0"
                                     [class.text-black-50]="!model?.archived_id"
                                     [tooltipLabel]="model?.archived_id == 0 ? translateService.instant('_ui_complete') : translateService.instant('_ui_reopen')"
                                     [icon]="'fa-check-circle'"
                                     [iconInactive]="'fa-circle'"
                                     (onToggle)="onArchivedToggle($event)"></app-toggle-item>
                </li>
                <li class="pe-1 d-mini-none" *ngIf="model && isFieldVisible(Fields.HandUp)  && model.num_hand_ups > 0">
                    <app-hand-up-selector [model]="model"></app-hand-up-selector>
                </li>
                <li class="pe-1 d-mini-none" *ngIf="model && isFieldVisible(Fields.Star) && model.num_stars > 0">
                    <app-star-selector [model]="model"></app-star-selector>
                </li>
                <li class="pe-1 d-mini-none" *ngIf="model && model.purpose">
                    <app-url-link [icon]="true" [title]="false" [maxLinks]="1" [link]="model.purpose"></app-url-link>
                </li>
                <li class="pe-1 d-flex align-items-center d-mini-none" *ngIf="taskEstimate && isFieldVisible(Fields.Estimate)"
                    [ngbTooltip]="taskEstimate.estimate.value + ' ' + (taskEstimate.estimate.estimate_unit?.language_key | translate)"
                >
                    <i class="fal fa-clock me-1 d-mini-none"></i><span>{{taskEstimate.estimate.value}}</span>
                </li>

                <ng-container *ngIf="model.cases_reached > 0 || model.cases_planned > 0">
                    <li class=" me-1" style="transform: translateY(-1px);">
                    <span class="badge rounded-pill badge-primary bg-dark me-1 text-light"
                          *ngIf="isFieldVisible(Fields.Plan)"
                          ngbTooltip="{{('_ui_planned' | translate)}}: {{model.cases_planned}}"
                          container="body">{{model.cases_planned}}</span>

                        <span class="fit-content badge rounded-pill"
                              *ngIf="isFieldVisible(Fields.Reached)"
                              [class.badge-warning]="model.cases_reached < model.cases_planned"
                              [class.bg-success]="model.cases_reached >= (model.cases_planned ? model.cases_planned : 0)"
                              ngbTooltip="{{('_ui_reached' | translate)}}:  {{model.cases_reached}}"
                              container="body">{{model.cases_reached}}</span>
                    </li>
                </ng-container>

                <li class="relation m-0 me-1 user-list" *ngIf="isFieldVisible(Fields.Who) && users">
                    <app-users-with-icon
                        [initials]="!isMobile"
                        [icon]="true"
                        [tasksUsers]="task_users"
                        [users]="users"
                        [userType]="taskUserType"
                        [showAcceptanceStatus]="model.is_order"
                        (onArchived)="onUserArchived($event)"
                    />
                </li>

                <li class="d-flex align-items-center flex-wrap pt-1"
                    [class.d-none]="!hasVisibleReactions"
                    [class.d-flex]="hasVisibleReactions"
                    *ngIf="item"
                >
                    <app-reactions [reactionsApiInterface]="item.item"
                                   [reactionsSourceInterface]="item.item"
                                   [reactionsTypeInterfaces]="reactionsTypeInterfaces"
                                   [reactions]="item?.item?.reactions"
                                   [reactionsPickerVisible]="false"
                                   (onDataSetChanged)="hasVisibleReactions = $event.length > 0;"
                                   (click)="$event.stopImmediatePropagation()"></app-reactions>


                </li>

                <li class="ms-auto align-self-end" *ngIf="model && model.can_copy">
                    <i class="fal fa-file-import me-1" [ngbTooltip]="('_task_can_copy' | translate)"></i>
                </li>

                <ng-container *ngIf="isFieldVisible(Fields.CalculatedFields) && item?.item">
                    <li *ngFor="let calculatedFieldsTask of model.calculated_fields_tasks" class="m-0 me-1">
                        <app-task-calculated-field
                            [task]="item.item"
                            [calculatedField]="calculatedFieldsTask.calculated_field"
                            [calculatedFieldsTask]="calculatedFieldsTask"
                        ></app-task-calculated-field>
                    </li>
                </ng-container>
            </ul>
            <!-- Frist -->
            <ul class="flex-grow-1 d-flex list-unstyled justify-content-end align-items-center m-0 flex-wrap"
                *ngIf="deadline && isFieldVisible(Fields.Deadline)">
                <li class="ms-auto align-self-end" *ngIf="deadline && isFieldVisible(Fields.Deadline)">
                    <app-item-date
                        [showIcon]="!isMobile"
                        [label]="(deadlineName | translate)"
                        [date]="deadline.getDate()"></app-item-date>
                </li>
            </ul>
        </div>

        <ul class="d-flex list-unstyled justify-content-start align-items-center w-100 m-0 flex-wrap border-top text-small phase"
            *ngIf="visibleCategories && visibleCategories.length > 0">
            <li *ngFor="let category of visibleCategories"
                [ngbTooltip]="category.category_type?.name"
                class="-me-1 w-100">
                <app-category
                    class="d-flex flex-row align-items-center -w-100 ellipsis"
                    [category]="category"></app-category>
            </li>
        </ul>

        <div *ngIf="isFieldVisible(Fields.Milestones)"
             class="microcard-list w-100"
             [class.mb-1]="projectCard && isFieldVisible(Fields.Project)"
        >
            <ng-container *ngFor="let milestoneCard of milestoneCards">
                <div class="d-flex project-display w-100">
                    <app-card-milestone
                        [item]="milestoneCard"
                        [model]="milestoneCard.item"
                        [configuration]="$any(milestoneCard.configuration)"
                        [mini]="true" class="w-100"></app-card-milestone>
                </div>
            </ng-container>
        </div>

        <div class="d-flex project-display w-100" *ngIf="projectCard && isFieldVisible(Fields.Project)">
            <app-card-project [item]="projectCard" [model]="projectCard.item"
                              [configuration]="$any(projectCard.configuration)" [mini]="true"
                              class="w-100"></app-card-project>
        </div>

    </div>

</div>
