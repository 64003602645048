/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TagsTaskDefinition} from './definitions/TagsTaskDefinition';

export class TagsTask extends TagsTaskDefinition {

    constructor(json?: any) {
        super(json);
    }

}
