import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AppInjector} from '@app/services/app-injector.service';
import {UsersService} from '@app/services/users.service';
import {
    Department,
    DepartmentsDisplayFilter,
    DisplayFilter, DisplayFiltersColumn,
    PhaseProgressType,
    TaskDeadlineType
} from '@app/core/models';
import {DisplayTypes} from '@app/constants';
import {Status} from '@app/core/models/Status';
import {Sort} from '@app/pages/displays/filtering/Sort';
import {Filter} from '@app/pages/displays/filtering/Filter';
import {Period} from '@app/pages/displays/filtering/Period';
import {Phase} from '@app/core/models/Phase';
import {Display} from '@app/core/models/Display';
import {Filters as ProjectsFilters} from '@app/pages/displays/display-projects/Filters';
import {Filters as KanbanFilters} from '@app/pages/displays/display-kanban/Filters';
import {Filters as KanbanProgressFilters} from '@app/pages/displays/display-kanban-progress/Filters';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {ProjectType} from '@app/core/models/ProjectType';
import {TaskType} from '@app/core/models/TaskType';
import {ProjectDeadlineType} from '@app/core/models/ProjectDeadlineType';
import {CategoryType} from '@app/core/models/CategoryType';
import {ShowDisplayFilterInterface} from "@app/services/editor-panel.service";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

export interface DisplayFilterEditorFormConfigInterface {
    projectTypes?: ProjectType[];
    taskTypes?: TaskType[];
    phaseName?: string;
    projectDeadlineTypeName?: string;
    showYearWheelPeriod?: boolean;
    categoryTypes?: CategoryType[];
    projectDeadlineTypes?: ProjectDeadlineType[];
    taskDeadlineTypes?: TaskDeadlineType[];

    // Global
    departments?: Department[];
}

export interface DisplayEditorFormColumn {
    name: string;
    checked: boolean;
    tableColumn: BaseTableColumn;
    disabled: boolean;
}

export interface DisplayEditorFormColumnGroup {
    isGroup: boolean;
    name: string;
    columns: DisplayEditorFormColumn[];
    checked: boolean;
}

export interface DisplayEditorFormProjectType {
    checked: boolean;
    projectType: ProjectType;
}

export interface DisplayEditorFormTaskType {
    checked: boolean;
    taskType: TaskType;
}

export interface DisplayEditorFormDepartment {
    checked: boolean;
    department: Department;
}

interface DisplayFilterEditorFormInterface {
    id: number;
    user_id: number;
    display: Display;
    name: string;
    filter_type: Filter;
    period_type: Period;
    period_start: NgbDateStruct;
    period_end: NgbDateStruct;
    sort_type: Sort;
    hand_up: boolean;
    starred: boolean;
    statuses: number[];
    sort_direction: boolean;
    show_in_dropdown: boolean;
    phase: Phase;
    project_deadline_type: ProjectDeadlineType;
    task_deadline_type: TaskDeadlineType;
    include_archived_since_type: Period;
    include_archived_since_date: NgbDateStruct;
    year_wheel_period_type: Period;
    year_wheel_period_start: NgbDateStruct;
    year_wheel_period_end: NgbDateStruct;
    override_display_columns: boolean;
    project_types: DisplayEditorFormProjectType[];
    task_types: DisplayEditorFormTaskType[];
    departments: DisplayEditorFormDepartment[];
    phase_progress_type: PhaseProgressType;

    filters: Filter[];
    sorts: Sort[];
    periods: Period[];
    includeArchivedSincePeriods: Period[];
    yearWheelPeriods: Period[];
    columnGroups: DisplayEditorFormColumnGroup[];

    config: DisplayFilterEditorFormConfigInterface;
    categoryTypes: CategoryType[];

    extra_sort1_type: Sort,
    extra_sort1_direction: boolean,
    extraSort1Label: string,
    extraSort1Types: Sort[],
}

export class DisplayFilterEditorForm extends DisplayFilter {

    // Tager imod det der kommer retur fra formularen
    public static create(form: DisplayFilterEditorFormInterface): DisplayFilterEditorForm {
        let item = new DisplayFilterEditorForm(form);
        if (form.statuses) {
            item.statuses = form.statuses.map((value: number) => {
                return new Status({id: value});
            })
        }
        item.name = form.name;
        item.sort_direction = form.sort_direction ? 'asc' : 'desc';
        item.filter_type = form.filter_type.id;
        item.sort_type = form.sort_type ? form.sort_type.id : null;
        item.period_type = form.period_type ? form.period_type.id : null;
        if (form.period_type && form.period_type.showCalendar) {
            if (form.period_start) {
                item.period_start = moment([form.period_start.year, form.period_start.month - 1, form.period_start.day])
                    .format('YYYY-MM-DDTHH:mm:ssZ');
            }
            if (form.period_end) {
                item.period_end = moment([form.period_end.year, form.period_end.month - 1, form.period_end.day])
                    .format('YYYY-MM-DDTHH:mm:ssZ');
            }
        } else {
            item.period_start = null;
            item.period_end = null;
        }

        if (item.display) {
            item.display_id = item.display.id;
        }

        item.include_archived_since_type = form.include_archived_since_type ? form.include_archived_since_type.id : null;
        if (form.include_archived_since_date) {
            item.include_archived_since_date = moment([form.include_archived_since_date.year, form.include_archived_since_date.month - 1, form.include_archived_since_date.day])
                .format('YYYY-MM-DDTHH:mm:ssZ');
        }

        delete item.user;

        // Remove Phase from Projects-display
        if (item.getDisplayTypeId() == DisplayTypes.Projects && item.filter_type != ProjectsFilters.FilterPhase) {
            item.phase_id = 0;
            delete item.phase;
        }

        // Remove ProjectDeadlineType from Projects-display
        if (item.getDisplayTypeId() == DisplayTypes.Projects && item.filter_type != ProjectsFilters.FilterProjectDeadlineType) {
            item.project_deadline_type_id = 0;
            delete item.project_deadline_type;
        }

        // Remove ProjectDeadlineType from Kanban-display
        if (item.getDisplayTypeId() == DisplayTypes.Kanban
            && item.filter_type != KanbanFilters.FilterNonDeadline
            && item.filter_type != KanbanFilters.FilterDeadlineInPeriod) {
            item.project_deadline_type_id = 0;
            delete item.project_deadline_type;
        }

        // Remove PhaseProgressType from Kanban-display
        if (item.getDisplayTypeId() == DisplayTypes.Kanban
            && item.filter_type != KanbanFilters.FilterPhaseProgressType) {
            item.phase_progress_type_id = 0;
            delete item.phase_progress_type;
        }

        // Remove PhaseProgressType from Project-display
        if (item.getDisplayTypeId() == DisplayTypes.Projects
            && item.filter_type != ProjectsFilters.FilterPhaseProgressType) {
            item.phase_progress_type_id = 0;
            delete item.phase_progress_type;
        }

        // Remove ProjectDeadlineType from KanbanProgress-display
        if (item.getDisplayTypeId() == DisplayTypes.KanbanProgress
            && item.filter_type != KanbanProgressFilters.FilterNonDeadline
            && item.filter_type != KanbanProgressFilters.FilterDeadlineInPeriod) {
            item.project_deadline_type_id = 0;
            delete item.project_deadline_type;
        }

        // Remove Phase from KanbanProgress-display
        if (item.getDisplayTypeId() == DisplayTypes.KanbanProgress && item.filter_type != KanbanProgressFilters.FilterPhase) {
            item.phase_id = 0;
            delete item.phase;
        }

        if (item.project_deadline_type) {
            item.project_deadline_type_id = item.project_deadline_type.id;
        }
        if (item.task_deadline_type) {
            item.task_deadline_type_id = item.task_deadline_type.id;
        }

        // Year Wheel
        item.year_wheel_period_type = form.year_wheel_period_type ? form.year_wheel_period_type.id : null;
        if (form.year_wheel_period_start) {
            item.year_wheel_period_start = moment([form.year_wheel_period_start.year, form.year_wheel_period_start.month - 1, form.year_wheel_period_start.day])
                .format('YYYY-MM-DDTHH:mm:ssZ');
        }
        if (form.year_wheel_period_end) {
            item.year_wheel_period_end = moment([form.year_wheel_period_end.year, form.year_wheel_period_end.month - 1, form.year_wheel_period_end.day])
                .format('YYYY-MM-DDTHH:mm:ssZ');
        }

        // Columns
        item.display_filters_columns = [];
        form.columnGroups.forEach(group => group.columns
            .filter(column => column.checked)
            .forEach(column =>
                item.display_filters_columns.push(DisplayFiltersColumn.Create(column.tableColumn)))
        );

        // Project Types
        item.project_types = form.project_types
            .filter(projectType => projectType.checked)
            .map(projectType => new ProjectType({id: projectType.projectType.id}));

        // Tasl Types
        item.task_types = form.task_types
            .filter(taskType => taskType.checked)
            .map(taskType => new TaskType({id: taskType.taskType.id}));

        // Categories
        item.categories = [];
        form.categoryTypes?.forEach(categoryType => {
            categoryType.categories?.forEach(category => item.categories.push(category));
        });

        // Departments
        item.departments_display_filters = form.departments
            .filter(department => department.checked)
            .map(department => DepartmentsDisplayFilter.CreateVisible(department.department.id));

        item.extra_sort1_type = form.extra_sort1_type ? form.extra_sort1_type.id : null;
        item.extra_sort1_direction = form.extra_sort1_direction ? 'asc' : 'desc';

        return item;
    }

    // Laver om til formular i objektform (uden formgroups)
    public toFormObject(showDisplayFiltersInterface: ShowDisplayFilterInterface): DisplayFilterEditorFormInterface {
        const config = showDisplayFiltersInterface.config;
        const settings = showDisplayFiltersInterface.filterPageData.shellFilterGroup.settings;

        const selectedColumns = this.display_filters_columns?.filter(displayFiltersColumn => displayFiltersColumn.is_visible) ?? [];
        const selectedProjectTypes = this.project_types ? this.project_types.map(value => value.id) : [];
        const selectedTaskTypes = this.task_types ? this.task_types.map(value => value.id) : [];
        const selectedDepartments = this.departments_display_filters
            ?.filter(value => value.visible)
            ?.map(value => value.department_id) ?? [];

        const ungroupColumns: BaseTableColumn[] = [];
        showDisplayFiltersInterface.filterPageData.shellColumnGroup.tableColumnGroups
            .filter(tableColumnGroup => tableColumnGroup.columns.length == 1)
            .forEach(tableColumnGroup => ungroupColumns.push(...tableColumnGroup.columns));

        let item: DisplayFilterEditorFormInterface = {
            id: this.id ? this.id : 0,
            user_id: this.user_id,
            display: this.display,
            name: this.name,
            filter_type: null,
            period_type: null,
            period_start: null,
            period_end: null,
            sort_type: null,
            hand_up: this.hand_up,
            starred: this.starred,
            statuses: this.statuses ? this.statusArray() : [],
            sort_direction: this.sort_direction == null || this.sort_direction == 'asc',
            show_in_dropdown: this.show_in_dropdown,
            phase: this.phase ? this.phase : null,
            project_deadline_type: null,
            task_deadline_type: config.taskDeadlineTypes?.find(taskDeadlineType => taskDeadlineType.id == this.task_deadline_type_id),
            include_archived_since_type: null,
            include_archived_since_date: null,
            year_wheel_period_type: null,
            year_wheel_period_start: null,
            year_wheel_period_end: null,
            override_display_columns: this.override_display_columns,
            phase_progress_type: this.phase_progress_type ? this.phase_progress_type : null,

            filters: settings.filters(this.display),
            sorts: settings.sorts(this.display),
            periods: settings.periods(),
            includeArchivedSincePeriods: settings.includeArchivedSincePeriods ? settings.includeArchivedSincePeriods() : [],
            yearWheelPeriods: settings.yearWheelPeriods ? settings.yearWheelPeriods() : [],
            columnGroups: showDisplayFiltersInterface.filterPageData.shellColumnGroup.tableColumnGroups
                .map(group => {
                    return {
                        name: group.name,
                        isGroup: group.columns.length > 1,
                        columns: group.columns.map(tableColumn => {
                            return {
                                name: tableColumn.name,
                                tableColumn: tableColumn,
                                checked: selectedColumns.some(column => column.column_id == tableColumn.column.column.id && column.table_column_identifier == tableColumn.fullIdentifier)
                                    || tableColumn.column.columnType.isAlwaysVisible,
                                disabled: tableColumn.column.columnType.isAlwaysVisible,
                            }
                        }),
                        checked: group.columns.some(tableColumn => selectedColumns.some(column => column.column_id == tableColumn.column.column.id && column.table_column_identifier == tableColumn.fullIdentifier)
                            || tableColumn.column.columnType.isAlwaysVisible),
                    };
                }),

            project_types: config.projectTypes ? config.projectTypes.map(projectType => {
                return {
                    projectType: projectType,
                    checked: selectedProjectTypes.includes(projectType.id) || selectedProjectTypes.length == 0,
                };
            }) : [],
            task_types: config.taskTypes ? config.taskTypes.map(taskType => {
                return {
                    taskType: taskType,
                    checked: selectedTaskTypes.includes(taskType.id) || selectedTaskTypes.length == 0,
                };
            }) : [],

            config: config,
            categoryTypes: [],
            departments: config.departments ? config.departments.map(department => {
                return {
                    department: department,
                    checked: selectedDepartments.includes(department.id) || selectedDepartments.length == 0,
                };
            }) : [],

            extra_sort1_type: null,
            extra_sort1_direction: this.extra_sort1_direction == null || this.extra_sort1_direction == 'asc',

            extraSort1Label: settings.extraSort1Label ? settings.extraSort1Label(this.display) : '',
            extraSort1Types: settings.extraSort1Types ? settings.extraSort1Types(this.display) : [],
        };

        if (this.filter_type) {
            item.filter_type = item.filters.find(filter => filter.id == this.filter_type);
        }
        if (this.sort_type) {
            item.sort_type = item.sorts.find(sort => sort.id == this.sort_type);
        } else if (item.sorts && item.sorts.length > 0) {
            item.sort_type = item.sorts[0];
        }
        if (this.period_type) {
            item.period_type = item.periods.find(sort => sort.id == this.period_type);
        }
        if (!this.user_id) {
            item.user_id = AppInjector.getInjector().get(UsersService).user.id;
        }
        if (this.include_archived_since_type) {
            item.include_archived_since_type = item.includeArchivedSincePeriods.find(sort => sort.id == this.include_archived_since_type);
        }
        if (this.year_wheel_period_type) {
            item.year_wheel_period_type = item.yearWheelPeriods.find(value => value.id == this.year_wheel_period_type);
        }

        let start = new Date(this.period_start);
        item.period_start = {
            year: start.getFullYear(),
            month: start.getMonth() + 1,
            day: start.getDate()
        };
        let end = new Date(this.period_end);
        item.period_end = {
            year: end.getFullYear(),
            month: end.getMonth() + 1,
            day: end.getDate()
        };
        let includeArchivedSince = this.include_archived_since_date ? new Date(this.include_archived_since_date) : new Date();
        item.include_archived_since_date = {
            year: includeArchivedSince.getFullYear(),
            month: includeArchivedSince.getMonth() + 1,
            day: includeArchivedSince.getDate()
        };
        let yearWheelPeriodStart = this.year_wheel_period_start ? new Date(this.year_wheel_period_start) : new Date();
        item.year_wheel_period_start = {
            year: yearWheelPeriodStart.getFullYear(),
            month: yearWheelPeriodStart.getMonth() + 1,
            day: yearWheelPeriodStart.getDate()
        };
        let yearWheelPeriodEnd = this.year_wheel_period_end ? new Date(this.year_wheel_period_end) : new Date();
        item.year_wheel_period_end = {
            year: yearWheelPeriodEnd.getFullYear(),
            month: yearWheelPeriodEnd.getMonth() + 1,
            day: yearWheelPeriodEnd.getDate()
        };

        if (this.project_deadline_type_id && item.config.projectTypes) {
            item.config.projectTypes.forEach(projectType => {
                const projectDeadlineTypesProjectType = projectType.getProjectDeadlineTypesProjectType(this.project_deadline_type_id);
                if (projectDeadlineTypesProjectType) {
                    item.project_deadline_type = projectDeadlineTypesProjectType.project_deadline_type;
                    return;
                }
            });
        }

        // Prepare category types
        CategoryType.GetAll(categoryTypes => {
            let localTypes = categoryTypes.map(ct => new CategoryType(ct));
            const categoryTypeMap = new Map<number, CategoryType>();
            localTypes.forEach(categoryType => {
                if (!categoryTypeMap.has(categoryType.id)) {
                    categoryType.categories = [];
                    categoryTypeMap.set(categoryType.id, categoryType);
                    item.categoryTypes.push(categoryType);
                }
            })
            // Add categories to category types
            this.categories?.forEach(category => {
                categoryTypeMap.get(category.category_type_id)?.categories.push(category);
            });
        });

        if (this.extra_sort1_type) {
            item.extra_sort1_type = item.extraSort1Types.find(sort => sort.id == this.extra_sort1_type);
        } else if (item.extraSort1Types.length > 0) {
            item.extra_sort1_type = item.extraSort1Types[0];
        }

        return item;
    }

    // Laver om til formgroups med validering
    public toFormGroup(formBuilder: UntypedFormBuilder, showDisplayFiltersInterface: ShowDisplayFilterInterface): UntypedFormGroup {
        const item = this.toFormObject(showDisplayFiltersInterface);
        let form: any = item;

        form.name = [item.name, Validators.required];
        form.statuses = [item.statuses];
        form.filters = [item.filters];
        form.sorts = [item.sorts];
        form.periods = [item.periods];
        form.includeArchivedSincePeriods = [item.includeArchivedSincePeriods];
        form.yearWheelPeriods = [item.yearWheelPeriods];
        form.columnGroups = formBuilder.array(
            item.columnGroups.map(columnGroup => {
                const columnGroupGroup: any = columnGroup;
                columnGroupGroup.columns = formBuilder.array(columnGroup.columns.map(column => formBuilder.group(column)));
                return formBuilder.group(columnGroupGroup)
            })
        );
        form.project_types = formBuilder.array(item.project_types.map(projectType => formBuilder.group(projectType)));
        form.task_types = formBuilder.array(item.task_types.map(taskType => formBuilder.group(taskType)));

        // Transform CategoryTypes to FormArray
        form.categoryTypes = formBuilder.array(item.categoryTypes.map(categoryType => {
            const categoryTypeGroup: any = categoryType;
            categoryTypeGroup.categories = [categoryTypeGroup.categories];
            return formBuilder.group(categoryTypeGroup);
        }));

        form.departments = formBuilder.array(item.departments.map(department => formBuilder.group(department)));

        form.extraSort1Types = [item.extraSort1Types];

        let group = formBuilder.group(item);

        // console.warn('toFormGroup', group, 'this:', this);
        return group;
    }

}
