export const DefaultDirections = {

    FollowGlobalSetting: 'follow-global-setting',
    Ascending: 'ascending',
    Descending: 'descending',

};

export type DefaultDirectionType =
    typeof DefaultDirections.FollowGlobalSetting
    | typeof DefaultDirections.Ascending
    | typeof DefaultDirections.Descending;
