import {Component, Input, OnInit} from '@angular/core';
import {PANEL_ANIMATION} from '@app/editor/panel.animation';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BaseEditorComponent} from '@app/editor/base-editor/base-editor.component';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-rich-text-editor-dialog',
  templateUrl: './rich-text-editor-dialog.component.html',
  styleUrls: ['./rich-text-editor-dialog.component.scss'],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation,
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0.5
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ]
})
export class RichTextEditorDialogComponent extends BaseEditorComponent implements OnInit {

    @Input() public userEditable:boolean = true;
    @Input() public title: string;
    @Input() public text: string;

    private saveItem: boolean = false;
    constructor(private activeModal: NgbActiveModal) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();

        this.editForm = new UntypedFormGroup({
            text: new UntypedFormControl(this.text)
        });

        if(!this.title)
            this.title = this.translateService.instant('_global_edit');

        this.isLoading = false;
        this.editable = true;
        this.initialized = true;
        this.isOpen = true;

    }

    closeForm() {
        this.activeModal.close({text: this.text, saveItem: this.saveItem});
    }
    close(){
        this.closeForm();
        super.close();
    }

    saveForm(close: boolean = true) {
        this.saveItem = true;
        this.text = this.editForm.get('text').value;
        if (this.isLoading || !this.validateForm() || !this.userEditable) {
            return;
        }
        this.isLoading = false;
        if (close) {
            this.isOpen = false;
            this.closeForm();
        }
        // const snack = this.snackbar.add(this.translateService.instant('_ui_item_saved', {item: '_display_efforts_appointment'}));
    }

    public get ready():boolean{
        return this.editable && !this.isLoading && this.editForm !== null;
    }

    saveAndClose() {
        this.saveForm(true);
    }

}
