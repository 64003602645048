import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {ProjectEstimateListCell} from "@app/pages/displays/display-projects/Cells/ProjectEstimateListCell";
import {
    ProjectCalculatedFieldListCell
} from "@app/pages/displays/display-projects/Cells/ProjectCalculatedFieldListCell";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";

export class ProjectCalculatedFieldListColumn extends GenericColumn<ProjectEstimateListCell> {

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new ProjectCalculatedFieldListCell(row, this);
    }

    public implementsCSVExport = true;

}
