import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TaskEditorLoaderComponent} from "@app/editor/task-editor-loader/task-editor-loader.component";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {NotFoundModule} from "@app/shared/_ui/not-found/NotFound.module";
import {TaskEditorV3Module} from "@app/editor/task-editor-loader/task-editor-v3/task-editor-v3.module";

@NgModule({
    imports: [
        CommonModule,
        LoadingIndicatorModule,
        NotFoundModule,
        TaskEditorV3Module,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TaskEditorLoaderComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class TaskEditorLoaderModule {}
