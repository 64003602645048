import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {CSVExportOptionInterface} from "@app/export/csv/CSVExportOptionInterface";

export class MultiListSource {

    public listConfiguration: ListConfiguration;
    public items: CardItem[];
    public itemsMap: Map<number, CardItem>;
    public itemCount: number;

    constructor(listConfiguration: ListConfiguration) {
        this.listConfiguration = listConfiguration;
        this.items = [];
        this.itemsMap = new Map();
    }

    protected onAddItemsListener: (items: CardItem[]) => void; // Add all at once, whole scope
    protected onAddItemListener: (item: CardItem) => void; // Add single item to existing scope
    protected onRemoveItemListener: (item: CardItem) => void; // Remove single item from existing scope
    protected onCountListener: (count: number) => void;

    public addDataListeners(onAddItemsListener: (items: CardItem[]) => void,
                            onAddItemListener: (item: CardItem) => void,
                            onRemoveItemListener: (item: CardItem) => void,
                            onCountListener: (count: number) => void) {
        this.onAddItemsListener = onAddItemsListener;
        this.onAddItemListener = onAddItemListener;
        this.onRemoveItemListener = onRemoveItemListener;
        this.onCountListener = onCountListener;
        this.setupDataSource();
    }

    public setupDataSource() {
        // Prepare data listeners
        const onAddItemsListener = (items: CardItem[]) => {
            this.items = items;
            this.itemsMap.clear();
            this.items.forEach(item => this.itemsMap.set(item.item.id, item));
            this.onAddItemsListener(this.items);
        };
        const onAddItemListener = (item: CardItem) => {
            if (!this.itemsMap.has(item.item.id)) {
                this.items.push(item);
                this.itemsMap.set(item.item.id, item);
                this.onAddItemListener(item);
            }
        };
        const onRemoveItemListener = (item: CardItem) => {
            if (this.itemsMap.has(item.item.id)) {
                this.items.splice(this.items.indexOf(this.itemsMap.get(item.item.id)), 1);
                this.itemsMap.delete(item.item.id);
                this.onRemoveItemListener(item);
            }
        };
        const onCountListener = (count: number) => {
            this.itemCount = count;
            this.onCountListener(count);
        };
        this.listConfiguration.addDataListeners(onAddItemsListener, onAddItemListener, onRemoveItemListener, onCountListener);
    }

    public intersect(items: CardItem[]) {
        this.items = this.items.filter(item => items.includes(item));
        this.itemsMap.clear();
        this.items.forEach(item => this.itemsMap.set(item.item.id, item));
    }

    public csvExport(option?: CSVExportOptionInterface): any[] {
        return this.items.map(item => this.listConfiguration.csvExport(item, option));
    }

}
