import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {WampService} from '../../../../services/wamp.service';
import {CalculatedFieldsProject} from '@app/core/models/CalculatedFieldsProject';
import {CalculatedField} from '@app/core/models/CalculatedField';
import {Project} from '@app/core/models';
import {Events} from '@app/constants';

@Component({
    selector: 'app-project-calculated-field',
    templateUrl: './project-calculated-field.component.html',
    styleUrls: ['./project-calculated-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectCalculatedFieldComponent extends BaseComponent implements OnInit, OnChanges {

    // Input: Scope
    @Input() project: Project;
    @Input() calculatedField: CalculatedField;
    // Input: Data
    @Input() calculatedFieldsProject: CalculatedFieldsProject;

    // Bindings to view
    public hasCalculatedFieldsProject: boolean
    public value: number;

    constructor(private cd: ChangeDetectorRef,
                private wampService: WampService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.render();
        this.subscribe(CalculatedFieldsProject.CreateChangeSubscription(
            Events.ProjectCalculatedFieldChanged(this.calculatedField.id, this.project.id),
            this.wampService, event => {
                if (!this.calculatedFieldsProject) {
                    this.calculatedFieldsProject = new CalculatedFieldsProject();
                }
                this.calculatedFieldsProject.populate(event.next, true);
                this.render();
            }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        this.hasCalculatedFieldsProject = this.calculatedFieldsProject != null;
        this.value = this.calculatedFieldsProject?.value;
        this.detectChanges();
    }

}
