/**
 * Created by ModelParser
 * Date: 07-10-2020.
 * Time: 18:47.
 */
import {NotificationDefinition} from './definitions/NotificationDefinition';

export class Notification extends NotificationDefinition {

    constructor(json?: any) {
        super(json);
    }

}
