import {NgModule} from "@angular/core";
import {DatepickerComponent} from "@app/shared/_forms/datepicker/datepicker.component";
import {TranslateModule} from "@ngx-translate/core";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {NgbDatepickerModule, NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {WeekPipeModule} from "@app/pipes/WeekPipe.module";
import {FormsModule} from "@angular/forms";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {CommonModule} from "@angular/common";
import {
    StaticDeadlineRowModule
} from "@app/shared/_forms/datepicker/components/static-deadline-row/StaticDeadlineRow.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ToggleItemModule,
        NgbTooltipModule,
        WeekPipeModule,
        NgbDatepickerModule,
        FormsModule,
        NgbPopoverModule,
        LocalizedDatePipeModule,
        ColorItemModule,
        StaticDeadlineRowModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DatepickerComponent,
    ],
    exports: [
        DatepickerComponent

    ],
    providers: [

    ],
})
export class DatepickerModule {}
