/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TasksUserDefinition} from './definitions/TasksUserDefinition';
import {Deadline} from '@app/core/models/Deadline';
import {User} from "@app/core/models/User";
import {Archived} from "@app/core/models/Archived";

export class TasksUser extends TasksUserDefinition {

    constructor(json?: any) {
        super(json);
    }

    public get isArchived(): boolean {
        return this.archived_id > 0 || this.archived !== undefined;
    }

    public set isArchived(value: boolean) {
        this.archived = value ? Archived.create() : undefined;
    }

    public static Create(typeId: number, userId: number, user?: User): TasksUser {
        const item = new TasksUser();
        item.task_user_type_id = typeId;
        item.user_id = userId;
        item.user = user;
        return item;
    }

    public static createDefault() {
        let item = new TasksUser();
        item.id = 0;
        item.deadline = Deadline.createDefault();
        return item;
    }

    public get typeId(): number {
        return this.task_user_type_id;
    }

}
