import {Inject, Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {UsersService} from '@app/services/users.service';
import {User} from '@app/core/models/User';
import {AppInjector} from '@app/services/app-injector.service';

export interface Credentials {
    // Customize received credentials here
    email: string;
    token?: string;
    first_name?: string;
    id?: number;
    last_name?: string;
    user_type_id?: number;

}

export interface LoginContext {
    email: string;
    password: string;
    remember?: boolean;
}


const credentialsKey = 'credentials';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {

    public storage: Storage = localStorage;
    private http: HttpClient;

    private signinStatus = new BehaviorSubject<boolean>(false);
    private user = new BehaviorSubject<User>(new User({
        id: 0,
        user_group_id: 0
    }));

    constructor(@Inject(Injector)
                private readonly injector: Injector,
                private router: Router,
                private oAuthService: OAuthService) {

        this.http = this.injector.get(HttpClient);
    }

    public init(): void {
        this.signinStatus.next(true);
        this.user.next(this.getUser());
    }

    public signout(): void {
        this.revokeToken();
        this.removeItem('discoveryDocument');
        this.removeItem('redirectUrl');

        // Tells all the subscribers about the new status & data.
        this.signinStatus.next(false);
        this.user.next(new User());

        this.oAuthService.revokeTokenAndLogout();
    }

    public refreshSession(): void {
        this.removeItem('discoveryDocument');
        if (!this.getItem('redirectUrl')) {
            if (this.router.url.indexOf('silent-refresh.html') === -1) {
                this.setItem('redirectUrl', this.router.url);
            }
        }
        this.oAuthService.initImplicitFlow();
    }

    public getItem(key: string): any {
        return JSON.parse(this.storage.getItem(key));
    }

    public setItem(key: string, value: any): void {
        if(value)
            this.storage.setItem(key, JSON.stringify(value));
    }

    public removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    public getAuthorizationHeader(): HttpHeaders {
        // Creates header for the auth requests.
        let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');

        const token: string = this.oAuthService.getAccessToken();
        if (token !== '') {
            const tokenValue: string = 'Bearer ' + token;
            headers = headers.append('Authorization', tokenValue);
        }
        return headers;
    }

    public getAuthorizationToken(): string {
        return this.oAuthService.getAccessToken();
    }

    public isSignedIn(): Observable<boolean> {
        return this.signinStatus.asObservable();
    }

    public userChanged(): Observable<User> {
        return this.user.asObservable();
    }

    public getUser(): User {
        const user: User = new User();
        if (this.oAuthService.hasValidAccessToken()) {
            const userInfo: any = this.oAuthService.getIdentityClaims();
            if (userInfo) {
                AppInjector.getInjector().get(UsersService).init();
                user.username = userInfo.username;
            }
        }
        return user;
    }

    public logout(): void {
        this.oAuthService.revokeTokenAndLogout().then((r) =>{
            console.log('authentication.service.ts  : logout() : ', r)
        });
    }
    public revokeToken(): void {
        const token: string = this.oAuthService.getAccessToken();
        // console.warn('AuthenticationService : revokeToken() : token : ', token);

        if (token !== '') {
            const revocationEndpoint: any = this.getItem('discoveryDocument');
            if (revocationEndpoint) {

                const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
                const params: any = {
                    token: token,
                    token_type_hint: 'access_token'
                };

                const body: string = AuthenticationService.encodeParams(params);
                this.http.post(revocationEndpoint.revocation_endpoint, body, {headers: headers}).subscribe();
            }
        }
    }

    private static encodeParams(params: any): string {
        let body = '';
        for (const key of Object.keys(params)) {
            if (body.length) {
                body += '&';
            }
            body += key + '=';
            body += encodeURIComponent(params[key]);
        }
        return body;
    }

}
