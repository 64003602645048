/**
 * Created by ModelParser
 */
import {Notification} from '../Notification';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class EmailNotificationDefinition extends BaseModel {
    notification_id?: number;
    notification?: Notification;
    receiver_name?: string;
    receiver_email?: string;
    sent?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.notification_id;
            delete this.notification;
            delete this.receiver_name;
            delete this.receiver_email;
            delete this.sent;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.notification_id != null) {
            this.notification_id = data.notification_id;
        }
        if (data.notification != null) {
            this.notification = new Notification(data.notification);
        }
        if (data.receiver_name != null) {
            this.receiver_name = data.receiver_name;
        }
        if (data.receiver_email != null) {
            this.receiver_email = data.receiver_email;
        }
        if (data.sent != null) {
            this.sent = data.sent;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
