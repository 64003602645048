import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    ViewChild
} from '@angular/core';
import {PageComponent} from "@app/pages/page.component";
import {Category, Deadline, Project} from "@app/core/models";
import {Api} from "@app/core/http/Api/Api";
import {Settings} from "@app/pages/displays/display-category/Settings";
import {
    ProjectStatusTypes,
    StatusTypes,
    TaskStatusTypes,
    TaskUserTypes
} from "@app/constants";
import {CdkDragEnd, CdkDragStart} from "@angular/cdk/drag-drop";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters} from "@app/pages/displays/display-category/Filters";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {ProjectFetcher, ProjectFetchRequest} from "@app/shared/_ui/lists/project-list/ProjectFetcher";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";
import {NonArchivedFilter} from "@app/pages/displays/display-category/OnScreenFilters/NonArchivedFilter";
import {ScreenshotHelper} from "@app/core/ScreenshotHelper/ScreenshotHelper";
import {MilestoneFetcher, MilestoneFetchRequest} from '@app/shared/_ui/lists/milestone-list/MilestoneFetcher';
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {
    TaskCategoryPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskCategoryPresetGenerator";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    ProjectStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectStatusPresetGenerator";
import {
    ProjectUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUseStatusRulesPresetGenerator";
import {
    ProjectDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectDepartmentPresetGenerator";
import {
    ProjectUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUserPresetGenerator";
import {ProjectUserTypes} from "@app/core/models/Project";
import {
    ProjectCategoryPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectCategoryPresetGenerator";
import {
    MilestoneStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneStatusPresetGenerator";
import {
    MilestoneUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneUseStatusRulesPresetGenerator";
import {
    MilestoneCategoryPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneCategoryPresetGenerator";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {CategoryColumnType} from "@app/pages/displays/display-category/ColumnTypes/CategoryColumnType";
import {ColumnTypes} from "@app/pages/displays/display-category/ColumnTypes";
import {CategoryColumn} from "@app/pages/displays/display-category/Columns/CategoryColumn";
import {CategoryTableColumn} from "@app/pages/displays/display-category/TableColumns/CategoryTableColumn";

@Component({
    selector: 'app-display-category',
    templateUrl: './display-category.component.html',
    styleUrls: ['./display-category.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayCategoryComponent extends PageComponent implements OnInit {
    protected filtersSettings = new Filters();
    protected columnController = new ColumnController();

    // View bindings
    @ViewChild('contentContainer', {static: false}) contentContainer: ElementRef;
    public tableColumns: CategoryTableColumn[] = [];
    public onColumnSortRenderEventEmitter = new EventEmitter();
    public onScreenFilters = [new NonArchivedFilter(false)];

    // Data
    private categories: Map<number, Category>;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
        this.shellService.setHeaderTitle(this.Constants.System.Loading);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.buildColumns();
        this.filterGlobalService.setShowSearch(true);
        this.filterGlobalService.setShowUserSearch(true);
    }

    // <editor-fold desc="View Actions">

    public onDragStartedEvent($event: CdkDragStart<CardItem<Project>>) {
        const item = $event.source.data;
        this.tableColumns.forEach(column => {
            column.multiListConfiguration.allowCardItemDrop(item, null, (allow) => {
                column.multiListConfiguration.dropDisabled = !allow;
            });
        });
        this.detectChanges();
    }

    public onDragEndedEvent($event: CdkDragEnd) {
        this.tableColumns.forEach(column => {
            column.multiListConfiguration.dropDisabled = false;
        });
        this.detectChanges();
    }

    public onCaptureScreenshotBtnClicked() {
        if (this.isCapturingScreenshot) {
            return;
        }
        this.isCapturingScreenshot = true;
        ScreenshotHelper.Capture(this.contentContainer?.nativeElement, () => {
            this.isCapturingScreenshot = false;
            this.detectChanges();
        }, {selector: document.getElementById('klartboard')});
    }

    // </editor-fold>

    // <editor-fold desc="Load data">

    private taskFetcher: TaskFetcher;
    private projectFetcher: ProjectFetcher;
    private milestoneFetcher: MilestoneFetcher;

    public loadData() {
        // Validate method executable
        if (!this.categories) {
            return;
        }

        const categories = Array.from(this.categories, ([id, category]) => category);

        // Cancel running fetchers
        this.taskFetcher.setCategories(categories).cancel();
        this.projectFetcher.setCategories(categories).cancel();
        this.milestoneFetcher.setCategories(categories).cancel();

        // Apply the latest filter and sort to columns
        this.tableColumns.forEach(column => {
            if (this.shellPageData.activePageColumnSort) {
                column.applySortItem(
                    this.shellPageData.activePageColumnSort,
                    this.filterGlobalService.getActiveSettings().activeSortDirection
                );
            } else if (this.shellPageData.shellFilterGroup?.activeFilter?.sort_type) {
                column.applySortItem(
                    new PageColumnSort(this.shellPageData.shellFilterGroup.activeFilter.sort_type),
                    this.shellPageData.shellFilterGroup?.activeFilter?.sort_direction
                );
            }

            column.applyFilter(this.shellPageData.shellFilterGroup.activeFilter, this.settingsMap, this.filtersSettings);
        })

        // Execute fetchers
        this.taskFetcher.execute();
        this.projectFetcher.execute();
        this.milestoneFetcher.execute();
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    private buildColumns() {
        this.columnController.addColumnTypes([
            new CategoryColumnType(),
        ]);
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();
        this.columnController.loadColumns(this.displayId, () => {

            this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
                this.tableColumns = this.columnController.getVisibleTableColumns() as CategoryTableColumn[];
                this.setupColumnLists();
                this.loadData();
            }));

            const columnCategoryIds = this.columnController
                .getColumns<CategoryColumn>(ColumnTypes.Category)
                .map(column => column.getFilterCategory());

            Api.categories().get()
                .include('color')
                .whereIn('id', columnCategoryIds)
                .find((categories) => {

                    // Save categories to class
                    this.categories = new Map();
                    categories.forEach(category => this.categories.set(category.id, category));

                    // Remove columns without category
                    this.columnController.getColumns<CategoryColumn>(ColumnTypes.Category)
                        .filter(column => !this.categories.has(column.getFilterCategory()))
                        .forEach(column => this.columnController.removeColumn(column));

                    this.initialize();
                });

        });
    }

    private initialize() {
        // Use filter editor config to find category types for sorting
        this.filtersSettings.getEditorConfig(this.display, config => {
            // Convert category types to page column sort
            const categoryTypeColumnSorts = config.categoryTypes?.map(categoryType => {
                return PageColumnSort.CreateWithSortId(Filters.SortCategoryTypeGenerator(categoryType));
            });

            this.columnController.getColumns<CategoryColumn>(ColumnTypes.Category)
                .forEach(column => {
                    column.setCategory(this.categories.get(column.getFilterCategory()))

                    // Append column sorts to columns
                    column
                        .getTableColumns()
                        .forEach(tableColumn => {
                            tableColumn.sortItems.push(...categoryTypeColumnSorts);
                        })
                });

            // Load data
            this.setupColumnLists();
            this.loadData();
            this.initialized = true;

            this.shellService.setPageSettingsTaskTypeIds(Settings.GetTaskTypeIds(this.settingsMap));
            this.shellService.setPageSettingsProjectTypeIds(Settings.GetProjectTypeIds(this.settingsMap));

            // Subscribe to global listeners
            this.setupSubscriptions();
        });

        this.loadData();
    }

    private setupColumnLists() {
        // Prepare fetchers
        this.taskFetcher = new TaskFetcher();
        this.taskFetcher.showAll = true; // MultiLists understøtter ikke limits
        this.subscribe(this.taskFetcher.onFinishEvent.subscribe(() => this.detectChanges()));

        this.projectFetcher = new ProjectFetcher();
        this.projectFetcher.showAll = true; // MultiLists understøtter ikke limits
        this.subscribe(this.projectFetcher.onFinishEvent.subscribe(() => this.detectChanges()));

        this.milestoneFetcher = new MilestoneFetcher();
        this.milestoneFetcher.showAll = true; // MultiLists understøtter ikke limits
        this.subscribe(this.milestoneFetcher.onFinishEvent.subscribe(() => this.detectChanges()));

        const projectTypeIds = Settings.GetProjectTypeIds(this.settingsMap);
        const taskTypeIds = Settings.GetTaskTypeIds(this.settingsMap);
        const myDepartmentsIds = AppInjector.getInjector().get(UsersService).user?.departments?.map(d => d.id) || [];

        this.tableColumns.forEach(tableColumn => {
            tableColumn.multiListConfiguration
                .setOnScreenFilters(this.onScreenFilters);

            tableColumn.projectListConfiguration
                .setProjectTypeIds(projectTypeIds)
                .setDepartment(this.department)
                .setCustomDragAndDropFunction((cardItem, fromListConfiguration) => {
                    cardItem.item.replaceCategories(Array.from(this.categories.values()), [tableColumn.column.category]);
                    this.detectChanges();
                })
                .setCreatePresetGenerators([
                    // Defaults
                    new ProjectStatusPresetGenerator(ProjectStatusTypes.Normal, StatusTypes.GREEN),
                    new ProjectUseStatusRulesPresetGenerator(true),
                    new ProjectDepartmentPresetGenerator(this.department.id),
                    new ProjectUserPresetGenerator(ProjectUserTypes.Responsible, this.user.id),

                    new ProjectCategoryPresetGenerator(tableColumn.column.getFilterCategory()),
                ]);

            tableColumn.taskListConfiguration
                .setTaskTypeIds(taskTypeIds)
                .setDepartment(this.department)
                .setCustomOnCardItemDragAddFunction((cardItem, fromListConfiguration) => {
                    cardItem.item.replaceCategories(Array.from(this.categories.values()), [tableColumn.column.category]);
                    this.detectChanges();
                })
                .setCreatePresetGenerators([
                    // Defaults
                    new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                    new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                    new TaskUseStatusRulesPresetGenerator(true),
                    new TaskDepartmentPresetGenerator(this.department.id),

                    new TaskCategoryPresetGenerator(tableColumn.column.getFilterCategory()),
                    new TaskUserPresetGenerator(TaskUserTypes.Participant, this.user.id, Deadline.Today()),
                ]);

            tableColumn.milestoneListConfiguration
                .setDepartment(this.department)
                .setCustomOnCardItemDragAddFunction((cardItem, fromListConfiguration) => {
                    const categoryIdsToRemove = Array.from(this.categories.keys());

                    // Remove all other categories (filtered by categories visible in this display)
                    const categoriesToRemove = cardItem.item.categories
                        ?.filter(category => categoryIdsToRemove.includes(category.id))
                        ?.filter(category => category.id != tableColumn.column.category.id) ?? [];
                    categoriesToRemove.forEach(category => cardItem.item.removeCategory(category));

                    // Add this category if no already present
                    const hasCategory = cardItem.item.categories?.find(category => category.id == tableColumn.column.category.id);
                    if (!hasCategory) {
                        cardItem.item.addCategory(tableColumn.column.category);
                    }

                    this.detectChanges();
                })
                .setCreatePresetGenerators([
                    // Defaults
                    new MilestoneStatusPresetGenerator(StatusTypes.GREEN),
                    new MilestoneUseStatusRulesPresetGenerator(true),

                    new MilestoneCategoryPresetGenerator(tableColumn.column.getFilterCategory()),
                ]);

            // Add sources to individual data fetchers
            this.projectFetcher.addRequest(new ProjectFetchRequest(tableColumn.projectListConfiguration));
            this.taskFetcher.addRequest(new TaskFetchRequest(tableColumn.taskListConfiguration));
            this.milestoneFetcher.addRequest(new MilestoneFetchRequest(tableColumn.milestoneListConfiguration));

            // Setup CreateItem Configuration
            const createItemConfiguration = tableColumn.multiListConfiguration.createItemConfiguration;
            createItemConfiguration.showMilestone = Settings.GetShowMilestones(this.settingsMap);
            createItemConfiguration.filterTaskTypesById = taskTypeIds;
            createItemConfiguration.filterProjectsTypesById = projectTypeIds;
            createItemConfiguration.filterByDepartmentIds = myDepartmentsIds;
        });
    }

    private setupSubscriptions() {
        this.subscribe(this.shellFilterGroup.onActiveFilterChangeEventSubscribe(activeDisplayFilter => {
            // Ensure all columns has synchronized active sort item
            this.columnController.getColumns<CategoryColumn>(ColumnTypes.Category)
                .forEach(column => {
                    column.getTableColumns<CategoryTableColumn>().forEach(tableColumn => {
                        tableColumn.activeSortItem = this.shellPageData.activePageColumnSort;
                    })
                });
            this.onColumnSortRenderEventEmitter.emit();
            this.loadData();
        }));
        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(() => this.loadData()));
    }

    // </editor-fold>
}
