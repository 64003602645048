import {NgModule} from "@angular/core";
import {DashboardHeaderComponent} from "@app/pages/displays/dashboard/dashboard-header/dashboard-header.component";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {WeekPipeModule} from "@app/pipes/WeekPipe.module";
import {TranslateModule} from "@ngx-translate/core";
import {TimeCalculatorModule} from "@app/shared/_elements/time-calculator/TimeCalculator.module";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        LocalizedDatePipeModule,
        WeekPipeModule,
        TranslateModule,
        TimeCalculatorModule,
        ColorLabelModule,
        CardsModule,
        NgbTooltipModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DashboardHeaderComponent,
    ],
    exports: [
        DashboardHeaderComponent

    ],
    providers: [

    ],
})
export class DashboardHeaderModule {}
