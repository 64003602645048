import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeTaskTypeDialogComponent } from './change-task-type-dialog.component';
import {NgxModalDraggableDirectiveModule} from '@app/directives/NgxModalDraggableDirective.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ColorItemModule} from '@app/shared/_ui/color-item/ColorItem.module';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {LoadingIndicatorModule} from '@app/shared/_ui/loading-indicator/LoadingIndicator.module';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    ChangeTaskTypeDialogComponent
  ],
  imports: [
      CommonModule,
      NgxModalDraggableDirectiveModule,
      DragDropModule,
      ColorItemModule,
      NgbTooltipModule,
      TranslateModule,
      LoadingIndicatorModule,
      FormsModule
  ]
})
export class ChangeTaskTypeDialogModule { }
