/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {LogDefinition} from './definitions/LogDefinition';

export class Log extends LogDefinition {

    constructor(json?: any) {
        super(json);
    }



}
