<div class="modal-inner-content p-0 overflow-visible d-flex align-items-center flex-column modal-content"
     [@openClose]="isOpen ? 'open' : 'closed'"
>
    <div class="modal-header d-flex align-items-center w-100" ngxModalDraggable>
        <h4 class="modal-description">{{title}}</h4>

        <div class="ms-auto  d-flex align-content-center d-flex me-1">
            <app-color-item class="cursor-pointer me-2"
                            [className]="'medium'"
                            [colorValue]="'#ff5f57'"
                            [iconClass]="'fa-times'"
                            container="body" [ngbTooltip]="('_ui_close' | translate)"
                            (click)="decline();"></app-color-item>
        </div>

    </div>
    <div class="modal-body p-0 ">
        <div class="container p-0 rounded">

            <div class="d-flex flex-column h-100" style="padding-bottom: 61px;">

                <app-card-project *ngIf="card" [item]="card"></app-card-project>

                <div class="scroll-y align-self-stretch card-content">
                    <div class="container p-4" *ngIf="model">
                        <pre>TODO: Alt</pre>
                        <app-project-edit-collection
                            [item]="model"
                            [availableEditors]="editorTypes"
                            [editorEvents]="editorEvents"
                        ></app-project-edit-collection>
                    </div>
                </div>
            </div>

            <ul class="nav nav-pills nav-fill position-absolute bg-light border-top w-100 p-2 d-flex justify-content-between"
                style="bottom: 0;">

                <li class="nav-item -px-2">
                    <button type="button" class="btn btn-sm btn-secondary  panel-button max-width-unset"
                            (click)="decline()">
                        <span>{{'_ui_close' | translate}}</span>
                    </button>
                </li>
            </ul>

        </div>
    </div>
</div>
