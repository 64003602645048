/**
 * Created by ModelParser
 */
import {DynamicMenuItem} from '../DynamicMenuItem';
import {User} from '../User';
import {AppointmentsUser} from '../AppointmentsUser';
import {Department} from '../Department';
import {AppointmentsDepartment} from '../AppointmentsDepartment';
import {Project} from '../Project';
import {Display} from '../Display';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class AppointmentDefinition extends BaseModel {
    description?: string;
    dynamic_menu_item_id?: number;
    dynamic_menu_item?: DynamicMenuItem;
    users?: User[];
    appointments_users?: AppointmentsUser[];
    departments?: Department[];
    appointments_departments?: AppointmentsDepartment[];
    projects?: Project[];
    displays?: Display[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.description;
            delete this.dynamic_menu_item_id;
            delete this.dynamic_menu_item;
            delete this.users;
            delete this.appointments_users;
            delete this.departments;
            delete this.appointments_departments;
            delete this.projects;
            delete this.displays;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.description != null) {
            this.description = data.description;
        }
        if (data.dynamic_menu_item_id != null) {
            this.dynamic_menu_item_id = data.dynamic_menu_item_id;
        }
        if (data.dynamic_menu_item != null) {
            this.dynamic_menu_item = new DynamicMenuItem(data.dynamic_menu_item);
        }
        if (data.users != null) {
            this.users = data.users.map((i: any) => new User(i));
        }
        if (data.appointments_users != null) {
            this.appointments_users = data.appointments_users.map((i: any) => new AppointmentsUser(i));
        }
        if (data.departments != null) {
            this.departments = data.departments.map((i: any) => new Department(i));
        }
        if (data.appointments_departments != null) {
            this.appointments_departments = data.appointments_departments.map((i: any) => new AppointmentsDepartment(i));
        }
        if (data.projects != null) {
            this.projects = data.projects.map((i: any) => new Project(i));
        }
        if (data.displays != null) {
            this.displays = data.displays.map((i: any) => new Display(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
