export class ColumnTypes {

    public static UserCard = 'user-card';
    public static MilestoneListUser = 'milestone-list-user';

    public static TaskEdit = 'task-edit';

    public static TaskList_Status = 'task-list-status';
    public static TaskList_Period = 'task-list-period';
    public static TaskList_All = 'task-list-all'

}
