import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";

export class EditReactionListConfiguration extends BaseEditorConfiguration {

    public showHands: boolean;
    public showStars: boolean;
    public showReactionTypes: boolean;

    public isReactionsVisible = false;
    public showReactionsBadge = false;

    constructor(showHands: boolean, showStars: boolean, showReactionTypes: boolean,
                isReactionsVisible: boolean, showReactionsBadge: boolean) {
        super();
        this.showHands = showHands;
        this.showStars = showStars;
        this.showReactionTypes = showReactionTypes;
        this.isReactionsVisible = isReactionsVisible;
        this.showReactionsBadge = showReactionsBadge;
    }

}
