import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters} from "@app/pages/displays/display-team/Helpers/Filters";
import {GenericColumn} from "@app/pages/displays/display-team/Columns/GenericColumn";
import {TaskListTableColumn} from "@app/pages/displays/display-team/TableColumns/TaskListTableColumn";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {ColumnTypeSettings} from "@app/pages/displays/display-team/Helpers/ColumnTypeSettings";
import {Team_TaskListTaskTypesSettingValue} from "@app/core/http/Api/Api";
import {DisplayTeamRow} from "@app/pages/displays/display-team/DisplayTeamRow";
import {TaskListCell} from "@app/pages/displays/display-team/Cells/TaskListCell";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import * as moment from "moment";

export class TaskListColumn extends GenericColumn<TaskListCell>
    implements ColumnDataFetcherInterface, SortableColumnInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TaskFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [this.dataFetcher];
    }

    // </editor-fold>

    // <editor-fold desc="Sorting">

    public implementsSorting: true = true;

    public applyRowSort(row: DisplayTeamRow, tableColumn: BaseTableColumn) {
        row.getCell<TaskListCell>(this)
            .listConfiguration
            .setOrderBy(
                this.getOrderBy(tableColumn.activeSortTypeId, tableColumn.activeSortDirection)
            );
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new TaskFetcher(
            undefined, undefined, undefined,
            column.name
        );
        this.dataFetcher.setTaskTypeIds(this.getTaskTypeIds());
    }

    public createTableColumns(): TaskListTableColumn[] {
        const item = new TaskListTableColumn(this);
        this.setTableColumnDefaults(item);

        item.sortItems = [
            PageColumnSort.CreateWithSortId(Filters.TaskColumn_Sort_Title),
            PageColumnSort.CreateWithSortId(Filters.TaskColumn_Sort_Status),
            PageColumnSort.CreateWithSortId(Filters.TaskColumn_Sort_Deadline),
            PageColumnSort.CreateWithSortId(Filters.TaskColumn_Sort_PlanningDate),
        ];
        return [item];
    }

    public createCell(row: DisplayTeamRow): TaskListCell {
        const cell = new TaskListCell(row, this);

        cell.listConfiguration
            .setTaskTypeIds(this.getTaskTypeIds())
            .addOnScreenFilters(this.columnType.onScreenFilters);

        this.dataFetcher.addRequest(new TaskFetchRequest(cell.listConfiguration));
        return cell;
    }

    public getOrderBy(type: string, direction: string): any[] {
        let orderBy: any[][] = [];
        switch (type) {
            case Filters.TaskColumn_Sort_Title:
                orderBy.push(['title', direction]);
                break;
            case Filters.TaskColumn_Sort_Status:
                orderBy.push(['main_status.status_id', direction]);
                break;
            case Filters.TaskColumn_Sort_Deadline:
                orderBy.push(['tasks_deadline.deadline.date', 'null'], ['tasks_deadline.deadline.date', direction]);
                break;
            case Filters.TaskColumn_Sort_PlanningDate:
                orderBy.push(['tasks_user.deadline.date', direction]);
                break;
        }
        return orderBy;
    }

    public getTaskTypeIds(): number[] {
        return this.settings.get(ColumnTypeSettings.TaskList_TaskTypes)
            ?.getObject<Team_TaskListTaskTypesSettingValue>()
            ?.taskTypeIds ?? [];
    }

    public setPeriod(start: Date, end: Date): void {
        this.dataFetcher.setPeriod(
            moment(start).startOf('isoWeek').toDate(),
            moment(end).endOf('isoWeek').toDate()
        );
    }

}
