import {NgModule} from "@angular/core";
import {
    HeaderTitleFilterGroupComponent
} from "@app/shell/header/header-title-filter-group/header-title-filter-group.component";
import {TranslateModule} from "@ngx-translate/core";
import {HeaderTitleFilterItemModule} from "@app/shell/header/header-title-filter-item/HeaderTitleFilterItem.module";
import {CommonModule} from "@angular/common";
import {CdkDrag, CdkDropList} from "@angular/cdk/drag-drop";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        HeaderTitleFilterItemModule,
        CdkDropList,
        CdkDrag,
        ToggleItemModule,
        NgbTooltip

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        HeaderTitleFilterGroupComponent,
    ],
    exports: [
        HeaderTitleFilterGroupComponent

    ],
    providers: [

    ],
})
export class HeaderTitleFilterGroupModule {}
