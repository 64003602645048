import {NgModule} from "@angular/core";
import {NgxModalDraggableDirective} from "@app/directives/ngx-modal-draggable.directive";

@NgModule({
    imports: [

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        NgxModalDraggableDirective,
    ],
    exports: [
        NgxModalDraggableDirective

    ],
    providers: [

    ],
})
export class NgxModalDraggableDirectiveModule {}
