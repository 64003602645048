import {Milestone} from "@app/core/models";

export class MilestoneListItem {

    public milestone: Milestone;

    constructor(milestone: Milestone) {
        this.milestone = milestone;
    }

}
