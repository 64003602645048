/**
 * Created by ModelParser
 */
import {DisplayType} from '../DisplayType';
import {ColumnTypeSetting} from '../ColumnTypeSetting';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class ColumnTypeDefinition extends BaseModel {
    identifier?: string;
    language_key?: string;
    display_type_id?: number;
    display_type?: DisplayType;
    is_required?: boolean;
    allow_multiple?: boolean;
    column_type_settings?: ColumnTypeSetting[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.identifier;
            delete this.language_key;
            delete this.display_type_id;
            delete this.display_type;
            delete this.is_required;
            delete this.allow_multiple;
            delete this.column_type_settings;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.identifier != null) {
            this.identifier = data.identifier;
        }
        if (data.language_key != null) {
            this.language_key = data.language_key;
        }
        if (data.display_type_id != null) {
            this.display_type_id = data.display_type_id;
        }
        if (data.display_type != null) {
            this.display_type = new DisplayType(data.display_type);
        }
        if (data.is_required != null) {
            this.is_required = data.is_required;
        }
        if (data.allow_multiple != null) {
            this.allow_multiple = data.allow_multiple;
        }
        if (data.column_type_settings != null) {
            this.column_type_settings = data.column_type_settings.map((i: any) => new ColumnTypeSetting(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
