/**
 * Created by ModelParser
 */
import {MilestoneStatus} from '../MilestoneStatus';
import {Deadline} from '../Deadline';
import {Archived} from '../Archived';
import {User} from '../User';
import {Project} from '../Project';
import {Task} from '../Task';
import {MilestonesProject} from '../MilestonesProject';
import {Favorite} from '../Favorite';
import {HandUp} from '../HandUp';
import {MilestonesStatusRule} from '../MilestonesStatusRule';
import {StatusRule} from '../StatusRule';
import {Reaction} from '../Reaction';
import {Category} from '../Category';
import {CalculatedFieldsMilestone} from '../CalculatedFieldsMilestone';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class MilestoneDefinition extends BaseModel {
    name?: string;
    main_status_id?: number;
    main_status?: MilestoneStatus;
    risk?: string;
    deadline_id?: number;
    deadline?: Deadline;
    archived_id?: number;
    archived?: Archived;
    notes?: string;
    responsible_id?: number;
    responsible?: User;
    num_stars?: number;
    num_hand_ups?: number;
    use_status_rules?: boolean;
    projects?: Project[];
    tasks?: Task[];
    milestone_status?: MilestoneStatus[];
    milestones_projects?: MilestonesProject[];
    favorites?: Favorite[];
    hand_ups?: HandUp[];
    milestones_status_rules?: MilestonesStatusRule[];
    status_rules?: StatusRule[];
    reactions?: Reaction[];
    categories?: Category[];
    calculated_fields_milestones?: CalculatedFieldsMilestone[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.main_status_id;
            delete this.main_status;
            delete this.risk;
            delete this.deadline_id;
            delete this.deadline;
            delete this.archived_id;
            delete this.archived;
            delete this.notes;
            delete this.responsible_id;
            delete this.responsible;
            delete this.num_stars;
            delete this.num_hand_ups;
            delete this.use_status_rules;
            delete this.projects;
            delete this.tasks;
            delete this.milestone_status;
            delete this.milestones_projects;
            delete this.favorites;
            delete this.hand_ups;
            delete this.milestones_status_rules;
            delete this.status_rules;
            delete this.reactions;
            delete this.categories;
            delete this.calculated_fields_milestones;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.main_status_id != null) {
            this.main_status_id = data.main_status_id;
        }
        if (data.main_status != null) {
            this.main_status = new MilestoneStatus(data.main_status);
        }
        if (data.risk != null) {
            this.risk = data.risk;
        }
        if (data.deadline_id != null) {
            this.deadline_id = data.deadline_id;
        }
        if (data.deadline != null) {
            this.deadline = new Deadline(data.deadline);
        }
        if (data.archived_id != null) {
            this.archived_id = data.archived_id;
        }
        if (data.archived != null) {
            this.archived = new Archived(data.archived);
        }
        if (data.notes != null) {
            this.notes = data.notes;
        }
        if (data.responsible_id != null) {
            this.responsible_id = data.responsible_id;
        }
        if (data.responsible != null) {
            this.responsible = new User(data.responsible);
        }
        if (data.num_stars != null) {
            this.num_stars = data.num_stars;
        }
        if (data.num_hand_ups != null) {
            this.num_hand_ups = data.num_hand_ups;
        }
        if (data.use_status_rules != null) {
            this.use_status_rules = data.use_status_rules;
        }
        if (data.projects != null) {
            this.projects = data.projects.map((i: any) => new Project(i));
        }
        if (data.tasks != null) {
            this.tasks = data.tasks.map((i: any) => new Task(i));
        }
        if (data.milestone_status != null) {
            this.milestone_status = data.milestone_status.map((i: any) => new MilestoneStatus(i));
        }
        if (data.milestones_projects != null) {
            this.milestones_projects = data.milestones_projects.map((i: any) => new MilestonesProject(i));
        }
        if (data.favorites != null) {
            this.favorites = data.favorites.map((i: any) => new Favorite(i));
        }
        if (data.hand_ups != null) {
            this.hand_ups = data.hand_ups.map((i: any) => new HandUp(i));
        }
        if (data.milestones_status_rules != null) {
            this.milestones_status_rules = data.milestones_status_rules.map((i: any) => new MilestonesStatusRule(i));
        }
        if (data.status_rules != null) {
            this.status_rules = data.status_rules.map((i: any) => new StatusRule(i));
        }
        if (data.reactions != null) {
            this.reactions = data.reactions.map((i: any) => new Reaction(i));
        }
        if (data.categories != null) {
            this.categories = data.categories.map((i: any) => new Category(i));
        }
        if (data.calculated_fields_milestones != null) {
            this.calculated_fields_milestones = data.calculated_fields_milestones.map((i: any) => new CalculatedFieldsMilestone(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
