<div class="d-flex flex-row">
    <ul class="list-unstyled d-flex align-items-center -flex-wrap emoji-items m-0">

        <li>
            <a class="nav-link header-icon">
                <app-toggle-item [interactive]="true" [active]="settings.isHandUp" [icon]="'fa-hand-paper'"
                                 [ngbTooltip]="('_ui_filter_on' | translate)+ ': '+('_ui_hand_up' | translate)"
                                 container="body"
                                 placement="bottom"
                                 (onToggle)="setHandUp(!settings.isHandUp)"></app-toggle-item>
            </a>
        </li>
        <li>
            <a class="nav-link header-icon">
                <app-toggle-item [interactive]="true" [active]="settings.isStarred" [icon]="'fa-star'"
                                 [ngbTooltip]="('_ui_filter_on' | translate)+ ': '+('_ui_starred' | translate)"
                                 container="body"
                                 placement="bottom"
                                 (onToggle)="setStarred(!settings.isStarred)"></app-toggle-item>
            </a>
        </li>
        <li>
            <a class="nav-link header-icon" *ngIf="visibility.showSorting">
                <app-toggle-item [interactive]="true"
                                 [ngbTooltip]="('Sortér stigende / faldende' | translate)"
                                 container="body"
                                 placement="bottom"
                                 [active]="isSortAsc"
                                 [icon]="'fa-sort-amount-up'"
                                 [iconInactive]="'fa-sort-amount-down'"
                                 [iconWeightActive]="'fal'"
                                 (onToggle)="setSorting(!isSortAsc)"></app-toggle-item>
            </a>
        </li>
        <li class="me-1"
            [ngbTooltip]="popover && popover.isOpen() ? null : ('_ui_filter_on' | translate)"
            container="body"
            placement="bottom"
        >
            <a class="nav-link no-caret d-flex flex-row justify-content-center align-items-center header-icon"
               [ngbTooltip]="('_ui_filter_on' | translate)+ ': '+('Status' | translate)"
               placement="auto"

               [ngbPopover]="popoverContent"
               [autoClose]="'outside'"
               triggers="manual"
               container="body"
               (click)="popover? (popover.isOpen()? popover.close(): popover.open()) : null"
               #popover="ngbPopover"
            >
                    <span class="status-circle main">
                    <span class="d-flex flex-wrap flex-row align-items-stretch justify-content-center w-100 h-100">
                    <span *ngFor="let status of statuses"
                          class="{{Globals.StatusTypeColors[status]}} flex-fill flex-grow-1" style="min-width: 50%;"
                    ></span>
                </span>

                </span>
            </a>

        </li>
    </ul>
</div>

<ng-template #popoverContent>

    <div class="items-container flex-column d-flex">
        <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>


        <div class="d-flex flex-row align-items-center w-100 pb-1 " *ngIf="statuses" >

            <h4 class="p-0 m-0 popover-title flex-grow-1">{{'_ui_filter_on' | translate}}: {{'Status' | translate}}</h4>


            <div class="d-flex ms-auto reset-panel-buttons" >

                  <span class="cursor-pointer  btn btn-sm btn" (click)="toggleAllStatuses()">
                        <span class="flex-fill  align-items-center"
                              *ngIf="statuses.length != Globals.StatusTypesAll.length">
                            <i class="fa-duotone fa-circle-check me-1"></i>
                            <span>
                            {{'_ui_select_all' | translate}}
                                </span>
                            </span>
                        <span class="flex-fill align-items-center"
                              *ngIf="!(statuses.length != Globals.StatusTypesAll.length)">

                            <i class="fa-duotone fa-circle-xmark me-1"></i>
                            <span>
                            {{'_ui_deselect_all' | translate}}
                                </span>
                            </span>
                    </span>

                    <span (click)="popover.close();reset()"
                          [class.disabled]="statuses.length == Globals.StatusTypesAll.length"
                          class="cursor-pointer  btn btn-sm btn-danger">
                        <i class="fal fa-eraser" aria-hidden="true"></i>
                        {{'_ui_emoji_clear' | translate}}
                    </span>

                <span (click)="popover.close();activateFilters();"
                      [class.disabled]="statuses.length == 0"
                      class="cursor-pointer btn btn-sm btn-success">
                        <i class="fa-duotone fa-filters"></i>
                    {{'_ui_filter' | translate}}</span>


            </div>
        </div>


        <div aria-labelledby="filter-color" class="p-0 filter-color-popover bg-light border rounded">


            <span class="dropdown-item d-flex flex-row align-items-center w-100"
                  (click)="toggleStatus(Globals.StatusTypes.GREY)">
            <app-toggle-item class="me-1" [interactive]="false"
                             [active]="statusActive(Globals.StatusTypes.GREY)"
                             [icon]="'fa-check-circle'"
                             [iconInactive]="'fa-ban'"
                             [iconWeightActive]="'fal'"
                             [iconWeightInactive]="'fal'"
                             [iconColorActive]="'text-success'"
                             [iconColorInactive]="'text-danger'"
            ></app-toggle-item>
            <span class="flex-fill">{{'grey' | translate}}</span>
            <span class="status-circle bg-secondary justify-content-end"></span>
        </span>
            <span class="dropdown-item d-flex flex-row align-items-center w-100"
                  (click)="toggleStatus(Globals.StatusTypes.GREEN)">
            <app-toggle-item class="me-1" [interactive]="false"
                             [active]="statusActive(Globals.StatusTypes.GREEN)"
                             [icon]="'fa-check-circle'"
                             [iconInactive]="'fa-ban'"
                             [iconWeightActive]="'fal'"
                             [iconWeightInactive]="'fal'"
                             [iconColorActive]="'text-success'"
                             [iconColorInactive]="'text-danger'"
            ></app-toggle-item>
            <span class="flex-fill">{{'green' | translate}}</span>
            <span class="status-circle bg-success"></span>
        </span>
            <span class="dropdown-item d-flex flex-row align-items-center w-100"
                  (click)="toggleStatus(Globals.StatusTypes.YELLOW)">
            <app-toggle-item class="me-1" [interactive]="false"
                             [active]="statusActive(Globals.StatusTypes.YELLOW)"
                             [icon]="'fa-check-circle'"
                             [iconInactive]="'fa-ban'"
                             [iconWeightActive]="'fal'"
                             [iconWeightInactive]="'fal'"
                             [iconColorActive]="'text-success'"
                             [iconColorInactive]="'text-danger'"
            ></app-toggle-item>
            <span class="flex-fill">{{'yellow' | translate}}</span>
            <span class="status-circle bg-warning"></span>
        </span>
            <span class="dropdown-item d-flex flex-row align-items-center w-100"
                  (click)="toggleStatus(Globals.StatusTypes.RED)">
            <app-toggle-item class="me-1" [interactive]="false"
                             [active]="statusActive(Globals.StatusTypes.RED)"
                             [icon]="'fa-check-circle'"
                             [iconInactive]="'fa-ban'"
                             [iconWeightActive]="'fal'"
                             [iconWeightInactive]="'fal'"
                             [iconColorActive]="'text-success'"
                             [iconColorInactive]="'text-danger'"
            ></app-toggle-item>
            <span class="flex-fill">{{'red' | translate}}</span>
            <span class="status-circle bg-danger"></span>
        </span>
        </div>
    </div>
</ng-template>
