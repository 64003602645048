import {NgModule} from "@angular/core";
import {UpdatesNotificationComponent} from "@app/shell/updates-notification/updates-notification.component";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {FormatTimePipeModule} from "@app/pipes/FormatTimePipe.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormatTimePipeModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        UpdatesNotificationComponent,
    ],
    exports: [
        UpdatesNotificationComponent

    ],
    providers: [

    ],
})
export class UpdatesNotificationModule {}
