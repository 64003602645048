import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {Widths} from "@app/constants";
import {TemplateRef} from "@angular/core";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import { ColumnTypes } from "@app/pages/displays/display-project-details/subdisplay-milestones/ColumnTypes";
import {
    MilestoneCardColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/MilestoneCardColumn";

export class MilestoneCardColumnType extends BaseColumnType {

    public identifier = ColumnTypes.MilestoneCard;

    public isAlwaysVisible = true;
    public customWidth?: number;
    public minWidth = Widths.CardColumn;
    public maxWidth = Widths.CardColumn;
    public resizeable = false;
    public frozenLeft = false;
    public canAutoResize = false;
    public isList = false;
    public cellClass?: string;

    public constructor(cellTemplate?: TemplateRef<any>, headerTemplate?: TemplateRef<any>) {
        super();
        this.cellTemplate = cellTemplate;
        this.headerTemplate = headerTemplate;
    }

    public createColumn(column: Column, settings: Map<string, ColumnSetting>): BaseColumn {
        return new MilestoneCardColumn(this, column, settings);
    }

}
