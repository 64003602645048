<div class="editor-wrapper "
     [class.loading]="isLoading"
     [class.container]="isLoading"
>
    <app-loading-indicator
        *ngIf="isLoading"
        [scale]="2"
        [showLogo]="false"
        (click)="onLoadingIndicatorClicked()"></app-loading-indicator>
    <app-not-found
        *ngIf="showNotFound"
        (click)="onNotFoundClicked()">
        <code class="mt-2">{{itemToLoad | json}}</code>
    </app-not-found>
    <app-project-editor-v3
        *ngIf="showEditor"

        [item]="item"
        [configuration]="configuration"
        [options]="options"
        [fields]="fields"

        (onSavedEventEmitter)="onEditorItemSaved($event)"
        (onReloadEventEmitter)="onEditorReloadEvent()"
        (onCloseEventEmitter)="onEditorCloseEvent()"

    />
</div>
