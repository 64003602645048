import {NgModule} from "@angular/core";
import {TextEditorComponent} from "@app/editor/quick-editor/editors/generic/text-editor/text-editor.component";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {QuillModule} from "ngx-quill";
import {FormsModule} from "@angular/forms";
import {
    ValidationWarningListModule
} from '@app/editor/base-editor-v3/components/validation-warning-list/validation-warning-list.module';

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule,
        QuillModule,
        FormsModule,
        ValidationWarningListModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TextEditorComponent,
    ],
    exports: [
        TextEditorComponent

    ],
    providers: [

    ],
})
export class TextEditorModule {}
