import {EventEmitter, Injectable} from "@angular/core";
import {ShellService} from "@app/services/ShellService/shell.service";
import {MouseEvent} from "ngx-bootstrap/utils/facade/browser";

@Injectable({
    providedIn: 'root'
})
export class ExportService {

    // Events
    public onShowExportButtonChangeEvent = new EventEmitter<boolean>();
    public onExportButtonClickEvent = new EventEmitter<MouseEvent>();

    constructor(private shellService: ShellService) {
        // Hide button on page destroy
        shellService.onPrimaryPageDataChangeEvent.subscribe(value => {
            if (value == undefined) {
                this.setShowExportButton(false);
            }
        });
    }

    public setShowExportButton(value: boolean) {
        this.onShowExportButtonChangeEvent.emit(value);
    }

}
