<div class="d-flex mb-1 align-items-center justify-content-center">
    <strong class="ps-1 mb-0 pt-1" *ngIf="!showEditable">{{'_ui_milestoneplan_page_title' | translate}}</strong>
    <div class="ms-auto  "
         [class.text-warning]="departments.length > 0"
         [class.flex-grow-1]="showEditable"
         [ngbTooltip]="('_ui_filter' | translate)"
         (click)="popover.open()"
    >

        <div class="d-flex align-items-center cursor-pointer position-relative btn btn-sm w-100 "
             [ngbPopover]="popoverContent"
             [autoClose]="'outside'"
             [placement]="['right-top']"
             [class.btn-outline-secondary]="departments.length == 0"
             [class.btn-outline-warning]="departments.length > 0"
             triggers="manual"
             container="body"

             #popover="ngbPopover">
            <span class="fal fa-filter me-1"></span>
            <span>{{'_ui_filter' | translate}}</span>
        </div>

        <ng-template #popoverContent>
            <app-department-picker [showAll]="true"
                                   [selectedDepartments]="departments"
                                   [multiple]="true"
                                   [showDepartmentGroups]="true"
                                   (onChangeEvent)="filterByDepartment($event)"></app-department-picker>
        </ng-template>
    </div>
</div>


<ul *ngIf="visibleMilestoneplans"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    class="example-list  m-0"
    [class.rounded]="sortable"

>
    <li routerLink="/app/administrator"
        *ngIf="!sortable"
        (click)="emitClose()"
        [class.list-group-item]="sortable"
        [class.cursor-pointer]="!sortable"
        [class.p-1]="!sortable"
    >
        <div class="d-flex align-items-center cursor-pointer">
            <span>{{'_milestone_plans' | translate}}</span>
            <span class="fal fa-arrow-circle-right ms-auto"></span>
        </div>
    </li>
    <li routerLink="/app/administrator/templates/0"
        (click)="emitClose()"
        [class.list-group-item]="sortable"
        [class.cursor-pointer]="!sortable"
        [class.border-bottom]="!sortable"
        [class.p-1]="!sortable"
    >
        <div class="d-flex align-items-center cursor-pointer">
            <span>{{'_ui_milestoneplan_create_new_template' | translate}}</span>
            <span class="fal fa-plus-circle ms-auto"></span>
        </div>
    </li>
    <li *ngFor="let milestonePlan of visibleMilestoneplans"

        cdkDrag
        [cdkDragDisabled]="!sortable"
        [class.list-group-item]="sortable"
        [class.p-1]="!sortable"
    >
        <div class="d-flex align-items-center">
            <span (click)="deletePlan(milestonePlan)"
                  *ngIf="sortable && false"
                  [ngbTooltip]="('_global_delete' | translate)"
                  class="fal text-danger fa-trash-alt cursor-pointer me-1"></span>


            <span class="flex-grow-1 pe-1">
                <app-milestone-plan-select-item
                    [milestonePlan]="milestonePlan"
                    [interactive]="interactive"
                    [showEditable]="showEditable"
                    (onClickEvent)="milestonePlan.isEditableForUser() ? selectPlan(milestonePlan) : null"

                ></app-milestone-plan-select-item>
            </span>

            <div class="ms-auto d-flex align-items-center">

                <span cdkDragHandle
                      *ngIf="sortable" class="fal fa-bars me-1 cursor-drag"
                      [class.disabled]="!milestonePlan.isEditableForUser()"
                      [ngbTooltip]="('_ui_drag_to_change_order' | translate)"
                ></span>
                <span
                    (click)="milestonePlan.isEditableForUser() ? selectPlan(milestonePlan) : null"
                    [class.disabled]="!milestonePlan.isEditableForUser()"
                    class="fal fa-arrow-circle-right cursor-pointer ms-1"></span>
            </div>
        </div>
    </li>
</ul>

