import {NgModule} from "@angular/core";
import {StatusRuleItemComponent} from "@app/shared/_forms/status-rule-item/status-rule-item.component";
import {StatusSelectorModule} from "@app/shared/_forms/status-selector/StatusSelector.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        StatusSelectorModule,
        TranslateModule,
        NgbTooltipModule,
        FormsModule,
        CommonModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        StatusRuleItemComponent,
    ],
    exports: [
        StatusRuleItemComponent

    ],
    providers: [

    ],
})
export class StatusRuleItemModule {}
