/**
 * Created by ModelParser
 */
import {ProjectFieldsProjectType} from '../ProjectFieldsProjectType';
import {ProjectType} from '../ProjectType';
import {Setting} from '../Setting';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class ProjectFieldDefinition extends BaseModel {
    language_key?: string;
    parent_id?: number;
    type?: string;
    index_?: number;
    is_requireable?: boolean;
    project_fields_project_types?: ProjectFieldsProjectType[];
    project_types?: ProjectType[];
    settings?: Setting[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.language_key;
            delete this.parent_id;
            delete this.type;
            delete this.index_;
            delete this.is_requireable;
            delete this.project_fields_project_types;
            delete this.project_types;
            delete this.settings;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.language_key != null) {
            this.language_key = data.language_key;
        }
        if (data.parent_id != null) {
            this.parent_id = data.parent_id;
        }
        if (data.type != null) {
            this.type = data.type;
        }
        if (data.index_ != null) {
            this.index_ = data.index_;
        }
        if (data.is_requireable != null) {
            this.is_requireable = data.is_requireable;
        }
        if (data.project_fields_project_types != null) {
            this.project_fields_project_types = data.project_fields_project_types.map((i: any) => new ProjectFieldsProjectType(i));
        }
        if (data.project_types != null) {
            this.project_types = data.project_types.map((i: any) => new ProjectType(i));
        }
        if (data.settings != null) {
            this.settings = data.settings.map((i: any) => new Setting(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
