<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title" [innerHTML]="title"></h4>
        <a (click)="decline()">
            <i class="fal fa-times"></i>
        </a>
    </div>
    <div class="modal-body" [innerHTML]="message" *ngIf="message"></div>
    <div class="container">
        <div class="w-100 d-flex justify-content-center  align-items-center" style="height: 200px;" *ngIf="isLoading">
            <app-loading-indicator [showLogo]="false" class="text-large "></app-loading-indicator>
        </div>
        <ng-container *ngIf="!isLoading">
            <ul class="list-unstyled list-group mb-3 mt-3">

                <li (click)="subtractTaskEstimates = !subtractTaskEstimates" class="cursor-pointer">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <i class="fa fa-check-circle text-success" *ngIf="subtractTaskEstimates"></i>
                        <i class="fal fa-circle text-black-50" *ngIf="!subtractTaskEstimates"></i>
                        <span class="ms-1">
                            <span>{{'_ui_subtract_task_estimates' | translate}}</span>
                        </span>
                    </div>
                </li>

                <li class="border-bottom pb-1 mb-2 mt-1">

                </li>


                <ng-container *ngFor="let row of selectionRows">
                    <li (click)="row.selected = !row.selected" class="cursor-pointer">
                        <div class="d-flex flex-row justify-content-start align-items-center">
                            <i class="fa fa-check-circle text-success" *ngIf="row.selected"></i>
                            <i class="fal fa-circle text-black-50" *ngIf="!row.selected"></i>
                            <span class="ms-1 d-flex flex-grow-1 " [class.disabled]="!row.selected">
                            <span>{{row.row.projectCard.item.title}}</span>
                            <span class="ms-auto d-flex align-items-center" *ngIf="row.copyResponseItem">

                                <span class="badge rounded-pill badge-dark disabled"
                                      [ngbTooltip]="('_ui_cases_dialog_tooltip_1' | translate)">{{row.row.projectCase.cases}}</span>

                                <ng-container *ngIf="row.selected">
                                <span class="fal fa-arrow-right ms-1 me-1"></span>

                                <span class="badge rounded-pill badge-dark " [class.disabled]="subtractTaskEstimates"
                                      [ngbTooltip]="('_ui_cases_dialog_tooltip_2' | translate)">{{row.copyResponseItem.from_cases}}</span>

                                <ng-container *ngIf="subtractTaskEstimates ">
                                    <ng-container *ngIf="row.copyResponseItem.from_reached > 0 || true">
                                        <span class="fal fa-minus ms-1 me-1"></span>
                                        <span class="badge rounded-pill"
                                              [class.bg-success]="row.copyResponseItem.from_reached >= row.copyResponseItem.goal && row.copyResponseItem.from_reached != 0"
                                              [class.bg-danger]="row.copyResponseItem.from_reached < row.copyResponseItem.goal && row.copyResponseItem.from_reached != 0"
                                              [class.disabled]="row.copyResponseItem.from_reached <= 0"
                                              [class.bg-dark]="row.copyResponseItem.from_reached <= 0"
                                              [ngbTooltip]="('_ui_cases_dialog_tooltip_3' | translate)">{{row.copyResponseItem.from_reached}}</span>
                                    </ng-container>

                                    <span class="fal fa-arrow-right ms-1 me-1"></span>
                                    <span class="badge rounded-pill badge-dark" *ngIf="subtractTaskEstimates"
                                          [ngbTooltip]="('_ui_cases_dialog_tooltip_4' | translate)">{{row.copyResponseItem.to_cases > 0 ? row.copyResponseItem.to_cases : 0 }}</span>
                                </ng-container>
                                </ng-container>

                            </span>
                        </span>
                        </div>
                    </li>
                </ng-container>


            </ul>

        </ng-container>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary ellipsis" (click)="decline()"
                [disabled]="isLoading">{{ btnCancelText }}
        </button>
        <button type="button" class="btn btn-success ellipsis" ngbAutofocus (click)="accept()"
                [disabled]="isLoading">{{ btnOkText }}</button>
    </div>
</div>
