<ng-template #rt let-r="result" let-t="term" class="w-100"
             [typedTemplate]="resultTemplateType"
>
    <div class="d-flex flex-row align-items-center">
        <app-color-label *ngIf="r.main_status" [status]="r.main_status.status_id"
                         class="circle m-0 me-1"></app-color-label>
        <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
    </div>
</ng-template>

<ul class="list-group">
    <li class="list-group-item">
        <div class="form-group mb-0">
            <div class="search-wrap">
                <div class="input-group">

                    <input type="search" class="form-control placeholder-sm"
                           autocomplete="off"
                           [disabled]="milestoneRequiredOnProject && !projectId"
                           #instance="ngbTypeahead"
                           container="body"
                           [class.is-invalid]="searchFailed"
                           [(ngModel)]="model"
                           [ngbTypeahead]="search"
                           popupClass="result-list"
                           [resultTemplate]="rt"
                           [inputFormatter]="formatter"
                           (focus)="focus$.next($any($event.target).value)"
                           (click)="reset(); click$.next($any($event.target).value)"
                           (selectItem)="triggerSelection($event, instance)"
                           [placeholder]="('_ui_search_placeholder' | translate: {type: ('_milestone' | translate | lowercase)})"
                    />
                </div>

                <app-loading-indicator [showLogo]="false" *ngIf="searching && result.length == 0 && !searchFailed" class="searching"></app-loading-indicator>
<!--                <span class="searching" *ngIf="searching" [@fadeAnimation]="'in'">{{('_ui_searching' | translate)}}...</span>-->
            </div>
            <div class="invalid-feedback" *ngIf="searchFailed">{{('_ui_search_no_results' | translate)}}.</div>
        </div>

    </li>
    <li class="list-group-item" *ngIf="_internalValue" [@fadeAnimation]="'in'">
        <app-card-milestone
                [model]="_internalValue"
                [mini]="true"
                [configuration]="milestoneConfiguration"
                [removable]="true"
                (onRemove)="removeItem($event)"
                [editable]="_internalValue.id == 0"
                [interactive]="_internalValue.id != 0"></app-card-milestone>
    </li>
</ul>
