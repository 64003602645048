import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {MilestoneListConfiguration} from "@app/shared/_ui/lists/milestone-list/MilestoneListConfiguration";
import {FollowingMilestoneListColumn} from "@app/pages/displays/display-projects/Columns/FollowingMilestoneListColumn";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {
    MilestoneStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneStatusPresetGenerator";
import {StatusTypes} from "@app/constants";
import {
    MilestoneUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneUseStatusRulesPresetGenerator";
import {
    MilestoneProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneProjectPresetGenerator";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVListBinding} from "@app/export/csv/CSVListBinding";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";
import {CSVExportOptions} from "@app/shared/_ui/lists/milestone-list/CSVExportOptions";

export class FollowingMilestoneListCell extends GenericCell {

    public listConfiguration = new MilestoneListConfiguration();
    public csvListBinding = new CSVListBinding();

    constructor(row: ProjectsDisplayRow, column: FollowingMilestoneListColumn) {
        super(row, column);

        this.listConfiguration
            //.setLimit(2)
            .setShowPhases(true)
            .setLimit(ListConfiguration.SmartLimit)
            .setArchived(false)
            .setProject(row.project.item)
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                // Defaults
                new MilestoneStatusPresetGenerator(StatusTypes.GREEN),
                new MilestoneUseStatusRulesPresetGenerator(true),

                new MilestoneProjectPresetGenerator(row.project.item.id),
            ]);

        if (row.nextMilestone) {
            this.listConfiguration.setAvoidIds([row.nextMilestone.item.id]);
            if (row.nextMilestone.item.deadline) {
                this.listConfiguration.setDeadlineDateMinOrNull(row.nextMilestone.item.deadline.getDate());
            }
        }
    }

    public exportCSV(secondary?: any): CSVCellValue {
        switch (secondary) {
            case 'name':
                return this.csvListBinding.export(CSVExportOptions.createNameOption());
            case 'deadline':
                return this.csvListBinding.export(CSVExportOptions.createDeadlineOption());
        }
    }

}
