import {EventEmitter, Injectable} from "@angular/core";
import {ShellFilterGroup} from "@app/services/ShellFilterService/ShellFilterGroup";

@Injectable({
    providedIn: 'root'
})
export class ShellFilterService {

    // Data
    private groups: ShellFilterGroup[] = [];

    // Events
    public onGroupsChangeEvent = new EventEmitter<ShellFilterGroup[]>();

    // Public methods

    public attachGroup(group: ShellFilterGroup) {
        this.groups.push(group);
        this.onGroupsChangeEvent.emit(this.groups);
    }

    public detachGroup(group: ShellFilterGroup) {
        this.groups.splice(this.groups.indexOf(group), 1);
        this.onGroupsChangeEvent.emit(this.groups);
    }

    public getAllGroups(): ShellFilterGroup[] {
        return this.groups;
    }

}
