export class TableColumns {

    public static ProjectCard = 'project-card';
    public static ProjectNote = 'project-note';
    public static ProjectUserList = 'project-user-list';
    public static ProjectDeadlineList = 'project-deadline-list';
    public static ProjectStatusList = 'project-status-list';
    public static ProjectEstimateList = 'project-estimate-list';
    public static ProjectDeadlineDifference = 'project-deadline-difference';

    public static TaskListAll = 'task-list-all';
    public static TaskListWithoutDeadline = 'task-list-without-deadline';
    public static TaskListOpen = 'task-list-open';
    public static TaskListWithoutMilestone = 'task-list-without-milestone';

    public static TodoList = 'todo-list';

    public static AppointmentList = 'appointment-list';

    public static YearWheel = 'year-wheel'

}
