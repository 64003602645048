/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {LoadDefinition} from './definitions/LoadDefinition';

export class Load extends LoadDefinition {

    constructor(json?: any) {
        super(json);
    }

}
