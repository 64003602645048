import {ComponentResizeObserver} from "@app/core/ResizeObserver/ComponentResizeObserver";
import {ElementRef, EventEmitter} from "@angular/core";
import {Subscription} from "rxjs";

export class SharedComponentResizeObserver {

    private initialized = false;
    private observer: ComponentResizeObserver;

    private onWidthChangeEventEmitter = new EventEmitter<number>();
    private onHeightChangeEventEmitter = new EventEmitter<number>();

    public constructor() {
        // Empty constructor
    }

    public initialize(componentRef: ElementRef) {
        if (!this.initialized) {
            this.initialized = true;
            this.observer = new ComponentResizeObserver(componentRef);
            this.observer.subscribeToWidth(value => this.onWidthChangeEventEmitter.emit(value));
            this.observer.subscribeToHeight(value => this.onHeightChangeEventEmitter.emit(value));
        }
    }

    public subscribeToWidth(componentRef: ElementRef, next: (value: number) => void): Subscription {
        this.initialize(componentRef);
        return this.onWidthChangeEventEmitter.subscribe(next);
    }

    public subscribeToHeight(componentRef: ElementRef, next: (value: number) => void): Subscription {
        this.initialize(componentRef);
        return this.onHeightChangeEventEmitter.subscribe(next);
    }

}
