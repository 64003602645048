import {NgModule} from "@angular/core";
import {ColorPickerComponent} from "@app/shared/_elements/color-picker/color-picker.component";
import {TranslateModule} from "@ngx-translate/core";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbTooltipModule,
        NgbPopoverModule,
        ColorItemModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ColorPickerComponent,
    ],
    exports: [
        ColorPickerComponent

    ],
    providers: [

    ],
})
export class ColorPickerModule {}
