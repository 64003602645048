import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-tasks/Columns/TaskEditColumn";
import {
    BaseEditorTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/EditorTableColumns/BaseEditorTableColumn";
import {
    TemplateColumnConfiguration
} from "@app/editor/quick-editor/columns/generic/template-column/TemplateColumnConfiguration";
import {TemplateRef} from "@angular/core";
import {
    EditorWithColumnInterface
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/EditorWithColumnInterface";
import {
    EditWhoWhenConfiguration
} from "@app/editor/quick-editor/editors/generic/who-when-editor/EditWhoWhenConfiguration";
import {
    WhoWhenEditorComponent
} from "@app/editor/quick-editor/editors/generic/who-when-editor/who-when-editor.component";
import {
    TemplateColumnComponent
} from "@app/editor/quick-editor/columns/generic/template-column/template-column.component";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns";

export class TaskEdit_Who_TableColumn extends BaseEditorTableColumn {

    public identifier = TableColumns.TaskEdit_Who;

    public column: TaskEditColumn;
    public minWidth = 100;
    public maxWidth = 100;
    public columnConfiguration: TemplateColumnConfiguration;
    public editorWithColumn: EditorWithColumnInterface;

    constructor(column: TaskEditColumn, cellTaskEditListWhoWhenHeaderTemplate: TemplateRef<any>, isMinimized: boolean) {
        super(column);
        this.name = '_displays_project_details_tasks_column_task_edit_who';

        this.columnConfiguration = new TemplateColumnConfiguration(
            cellTaskEditListWhoWhenHeaderTemplate,
            false,
            false
        );
        this.columnConfiguration.savedWidth = this.width > 0 ? this.width : undefined;
        this.columnConfiguration.minWidth = this.minWidth;
        this.columnConfiguration.maxWidth = this.maxWidth;
        this.columnConfiguration.canAutoResize = false;
        this.columnConfiguration.onResizeEndEvent.subscribe(() => {
            this.width = this.columnConfiguration.width;
        });
        this.columnConfiguration.context = {
            column: this,
        };

        const editorConfiguration = new EditWhoWhenConfiguration();
        editorConfiguration.widthSynchronizer = this.columnConfiguration.widthSynchronizer;
        editorConfiguration.isExpanded = !isMinimized;

        this.editorWithColumn = {
            name: WhoWhenEditorComponent.name,
            columnName: TemplateColumnComponent.name,
            columnConfiguration: this.columnConfiguration,
            configuration: editorConfiguration,
        };
    }

}
