<div class="modal-inner-content window-size d-flex flex-column overflow-hidden" [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-description">{{'_status_rule_singular' | translate}}</h4>
        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <div class="modal-body d-flex align-items-start">
        <div class="container p-0 flex-grow-1 flex-shrink-1">

            <app-template-status-rule-row [statusRule]="statusRule"
                                         [showRemove]="false"

            ></app-template-status-rule-row>

        </div>
    </div>

    <div class="modal-footer">
        <button type="button" role="button" class="btn btn-secondary ellipsis" (click)="dismiss()" *ngIf="btnCancelText">{{ btnCancelText }}</button>
        <button type="button" role="button" class="btn btn-success ellipsis" [disabled]="!statusRule.date_difference" ngbAutofocus
                (click)="accept()">{{ btnOkText }}</button>
    </div>
</div>
