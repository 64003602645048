import {Inject, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {environment} from '@env/environment';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "@app/services/snackbar.service";


/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

    // private authenticationService: AuthenticationService;
    // private oAuthService: OAuthService;

    // https://alligator.io/angular/service-worker-updates/
    constructor(@Inject(Injector)
                private readonly injector: Injector,
                private router: Router,
                private snackbar: SnackbarService,

                private translationService: TranslateService
                ){

        // this.authenticationService = injector.get(AuthenticationService);
        // this.oAuthService = injector.get(OAuthService);

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
    }

    // Customize the default error handler here if needed
    private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<any>> {
        if (!environment.production) {
            // Do something with the error
            console.error('Request error', response);
        }

        console.warn('AuthInterceptor :: errorHandler: ', response);
        switch (response.status){
            case 404:
                console.warn('Not found : ', response);
                this.snackbar.add(`(${response.status}) Ups! Vi kunne ikke finde siden. (${response.message})`, null, {duration: 5000, panelClass: 'alert-info'});
                break;
            case 401:
                console.log('AuthInterceptor :: redirect')
                // AppInjector.getInjector().get(Router).navigate(['/login']);
                // AppInjector.getInjector().get(AuthenticationService).logout().subscribe(() => this.router.navigate(['/login']));

                console.warn('Server response 401 :: User logged out : ', response);
                if(this.router.url  != '/unauthorized') {
                    this.router.navigate(['/unauthorized']);
                    // this.injector.get(OAuthService).revokeTokenAndLogout();
                    // this.snackbar.add(
                    //     this.translationService.instant('Din bruger er blevet logget ud'),
                    //     'OK',
                    //     null,
                    //     ()=>{
                    //         AppInjector.getInjector().get(Router).navigate(['/login']);
                    //     }
                    // );

                }


                //this.authenticationService.refreshSession();
                //this.authenticationService.signout();
                //this.router.navigate(['/unauthorized']);

                break;

            case 500:
            case 503:
                // console.error('500: Server fejl: ', event);
                this.snackbar.add(`(${response.status}) Ups! Der skete en fejl i vores ende. (${response.message})`, 'Luk', {duration: 8000, panelClass: 'alert-danger'});

                break;
            default:
                console.log('unknown status : ', event);
                break;
        }



        throw response;
    }

}
