import {Directive, Input} from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'ng-template[typedTemplate]',
    standalone: true
})
export class TypedTemplateDirective<TypeToken> {

    @Input('typedTemplate')
    typeToken: TypeToken;

    static ngTemplateContextGuard<TypeToken>(
        _dir: TypedTemplateDirective<TypeToken>,
        _ctx: unknown
    ): _ctx is TypeToken {
        return true;
    }

}
