<div class="editor-wrapper "
     [class.loading]="isLoading"
     [class.container]="isLoading"
>
    <app-loading-indicator
        *ngIf="isLoading"
        [scale]="2"
        [showLogo]="false"
        (click)="onLoadingIndicatorClicked()"></app-loading-indicator>
    <app-not-found
        *ngIf="showNotFound"
        (click)="onNotFoundClicked()">
        <code class="mt-2">{{itemToLoad | json}}</code>
    </app-not-found>
    <app-task-editor-v3
        *ngIf="showEditor"
        [item]="item"
        [configuration]="configuration"
        [projectTypes]="projectTypes"
        [fields]="fields"
        [options]="options"
        (onSavedEventEmitter)="onEditorItemSaved($event)"
        (onCloseEventEmitter)="onEditorCloseEvent()"
    ></app-task-editor-v3>
</div>
