import { BaseColumn } from "@app/core/ColumnControl/BaseColumn";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {ColumnTypes} from "@app/pages/displays/dashboard/dashboard-calendar/ColumnTypes";
import {SoftThisWeekColumn} from "@app/pages/displays/dashboard/dashboard-calendar/Columns/SoftThisWeekColumn";

export class SoftThisWeekColumnType extends BaseColumnType {

    public identifier = ColumnTypes.SoftThisWeek;

    public isAlwaysVisible = false;

    public customWidth?: number;
    public minWidth: number;
    public maxWidth?: number;
    public resizeable: boolean;
    public frozenLeft: boolean;
    public canAutoResize: boolean;
    public isList: boolean;
    public cellClass?: string;

    public createColumn(column: Column, settings: Map<string, ColumnSetting>): BaseColumn {
        return new SoftThisWeekColumn(this, column, settings);
    }

}
