import {NgModule} from "@angular/core";
import {
    CardVerticalEditColumnsComponent
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/card-vertical-edit-columns/card-vertical-edit-columns.component";
import {ColumnModule} from "@app/editor/quick-editor/columns/column/Column.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ColumnModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CardVerticalEditColumnsComponent,
    ],
    exports: [
        CardVerticalEditColumnsComponent

    ],
    providers: [

    ],
})
export class CardVerticalEditColumnsModule {}
