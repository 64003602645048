import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {WampService} from '../../../../services/wamp.service';
import {CalculatedFieldsMilestone} from '@app/core/models/CalculatedFieldsMilestone';
import {CalculatedField} from '@app/core/models/CalculatedField';
import {Milestone} from '@app/core/models';
import {Events} from '@app/constants';

@Component({
    selector: 'app-milestone-calculated-field',
    templateUrl: './milestone-calculated-field.component.html',
    styleUrls: ['./milestone-calculated-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MilestoneCalculatedFieldComponent extends BaseComponent implements OnInit, OnChanges {

    // Input: Scope
    @Input() milestone: Milestone;
    @Input() calculatedField: CalculatedField;
    @Input() calculatedFieldType: string;
    // Input: Data
    @Input() calculatedFieldsMilestone: CalculatedFieldsMilestone;

    // Bindings to view
    public hasCalculatedFieldsMilestone: boolean
    public value: number;

    constructor(private cd: ChangeDetectorRef, private wampService: WampService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.render();
        this.subscribe(CalculatedFieldsMilestone.CreateChangeSubscription(
            Events.MilestoneCalculatedFieldChanged(this.calculatedField.id, this.milestone.id),
            this.wampService, event => {
                if (!this.calculatedFieldsMilestone) {
                    this.calculatedFieldsMilestone = new CalculatedFieldsMilestone();
                }
                this.calculatedFieldsMilestone.populate(event.next, true);
                this.render();
            }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        this.hasCalculatedFieldsMilestone = this.calculatedFieldsMilestone != null;
        this.value = this.calculatedFieldsMilestone?.value;
        this.detectChanges();
    }

}
