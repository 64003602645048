import {Widths} from "@app/constants";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

export class GenericTableColumn extends BaseTableColumn {

    public identifier = '';

    minWidth: number = Widths.CardColumn;
    disableCreate?: boolean;
}
