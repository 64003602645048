/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ProjectTypeDefinition} from './definitions/ProjectTypeDefinition';
import {ProjectField} from '@app/core/models/ProjectField';
import {ProjectTypesProjectUserType} from '@app/core/models/ProjectTypesProjectUserType';
import {ProjectDeadlineTypesProjectType} from '@app/core/models/ProjectDeadlineTypesProjectType';
import {ProjectEstimateTypesProjectType} from '@app/core/models/ProjectEstimateTypesProjectType';
import {ProjectStatusTypesProjectType} from '@app/core/models/ProjectStatusTypesProjectType';
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";

export class ProjectType extends ProjectTypeDefinition {

    constructor(json: any) {
        super(json);
    }

    public get smartName(): string {
        return this.name ?? '';
    }

    public getPlural(): string {
        return this.plural ? this.plural : this.name;
    }

    // <editor-fold desc="Project Fields">

    private projectFieldsMap: Map<number, ProjectField>;
    private setupProjectFields() {
        this.projectFieldsMap = new Map();
        if(!this.project_fields) return;
        for(let projectField of this.project_fields) {
            this.projectFieldsMap.set(projectField.id, projectField);
        }
    }
    public getProjectField(id: number): ProjectField {
        if(!this.projectFieldsMap) this.setupProjectFields();
        return this.projectFieldsMap.get(id);
    }

    // </editor-fold>

    // <editor-fold desc="Project User Types">

    private projectTypesProjectUserTypeMap: Map<number, ProjectTypesProjectUserType>;
    private setupProjectTypesProjectUserType() {
        this.projectTypesProjectUserTypeMap = new Map();
        if(!this.project_types_project_user_types) return;
        for(let projectTypesProjectUserType of this.project_types_project_user_types) {
            this.projectTypesProjectUserTypeMap.set(projectTypesProjectUserType.project_user_type_id, projectTypesProjectUserType);
        }
    }
    public getProjectTypesProjectUserType(id: number): ProjectTypesProjectUserType {
        if(!this.projectTypesProjectUserTypeMap) this.setupProjectTypesProjectUserType();
        return this.projectTypesProjectUserTypeMap.get(id);
    }

    // </editor-fold>

    // <editor-fold desc="Project Deadline Types">

    private projectDeadlineTypesProjectTypeMap: Map<number, ProjectDeadlineTypesProjectType>;
    private setupProjectDeadlineTypesProjectType() {
        this.projectDeadlineTypesProjectTypeMap = new Map();
        if(!this.project_deadline_types_project_types) return;
        for(let projectDeadlineTypesProjectType of this.project_deadline_types_project_types) {
            this.projectDeadlineTypesProjectTypeMap.set(projectDeadlineTypesProjectType.project_deadline_type_id, projectDeadlineTypesProjectType);
        }
    }
    public getProjectDeadlineTypesProjectType(id: number): ProjectDeadlineTypesProjectType {
        if(!this.projectDeadlineTypesProjectTypeMap) this.setupProjectDeadlineTypesProjectType();
        return this.projectDeadlineTypesProjectTypeMap.get(id);
    }

    // </editor-fold>

    // <editor-fold desc="Project Estimate Types">

    private projectEstimateTypesProjectTypeMap: Map<number, ProjectEstimateTypesProjectType>;
    private setupProjectEstimateTypesProjectType() {
        this.projectEstimateTypesProjectTypeMap = new Map();
        if(!this.project_estimate_types_project_types) return;
        for(let projectEstimateTypesProjectType of this.project_estimate_types_project_types) {
            this.projectEstimateTypesProjectTypeMap.set(projectEstimateTypesProjectType.project_estimate_type_id, projectEstimateTypesProjectType);
        }
    }
    public getProjectEstimateTypesProjectType(id: number): ProjectDeadlineTypesProjectType {
        if(!this.projectEstimateTypesProjectTypeMap) this.setupProjectEstimateTypesProjectType();
        return this.projectEstimateTypesProjectTypeMap.get(id);
    }

    // </editor-fold>

    // <editor-fold desc="Project Status Types">

    private projectStatusTypesProjectTypeMap: Map<number, ProjectStatusTypesProjectType>;
    private setupProjectStatusTypesProjectType() {
        this.projectStatusTypesProjectTypeMap = new Map();
        if(!this.project_status_types_project_types) return;
        for(let projectStatusTypesProjectType of this.project_status_types_project_types) {
            this.projectStatusTypesProjectTypeMap.set(projectStatusTypesProjectType.project_status_type_id, projectStatusTypesProjectType);
        }
    }
    public getProjectStatusTypesProjectType(id: number): ProjectStatusTypesProjectType {
        if(!this.projectStatusTypesProjectTypeMap) this.setupProjectStatusTypesProjectType();
        return this.projectStatusTypesProjectTypeMap.get(id);
    }

    // </editor-fold>

    public getUpperProjectTypeIds(): number[] {
        return this.ups ? this.ups.map(item => item.id) : [];
    }

    public getLowerProjectTypeIds(): number[] {
        return this.downs ? this.downs.map(item => item.id) : [];
    }

}
