import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[autofocusAfterInit]'
})
export class AutofocusDirective implements AfterViewInit {

    @Input() autofocusAfterInit: boolean = true;

    constructor(private el: ElementRef) {
      //console.log('AutofocusDirective');
    }

    ngAfterViewInit(): void {
        if(this.autofocusAfterInit) {
            // console.log('AutofocusDirective : ngAfterViewInit()');
            setTimeout(() => {
                this.el.nativeElement.focus();
            }, 0)
        }
    }
}
