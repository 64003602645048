import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {
    NgbNav, NgbNavContent,
    NgbNavItem,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavOutlet, NgbPopover,
    NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";
import {
    MilestoneEditorV3Component
} from "@app/editor/milestone-editor-loader/milestone-editor-v3/milestone-editor-v3.component";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";
import {ResizableModule} from "angular-resizable-element";
import {ReactiveFormsModule} from "@angular/forms";
import {BaseEditorV3Module} from "@app/editor/base-editor-v3/base-editor-v3.module";
import {
    MilestoneEditorFooterComponent
} from "@app/editor/milestone-editor-loader/milestone-editor-v3/components/milestone-editor-footer/milestone-editor-footer-component";
import {
    MilestoneEditorHeaderComponent
} from "@app/editor/milestone-editor-loader/milestone-editor-v3/components/milestone-editor-header/milestone-editor-header-component";
import {
    GenericVerticalEditCollectionModule
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/generic-vertical-edit-collection/GenericVerticalEditCollection.module";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {
    MilestoneEditorTitleComponent
} from "@app/editor/milestone-editor-loader/milestone-editor-v3/components/milestone-editor-title/milestone-editor-title-component";
import {TitleEditorModule} from "@app/editor/quick-editor/editors/generic/title-editor/TitleEditor.module";
import {
    MilestoneEditorTabsBasicComponent
} from "@app/editor/milestone-editor-loader/milestone-editor-v3/components/milestone-editor-tabs/milestone-editor-tabs-basic/milestone-editor-tabs-basic-component";
import {
    MilestoneCalculatedFieldModule
} from "@app/shared/_elements/milestone-calculated-field/MilestoneCalculatedField.module";
import {EditorSwitcherModule} from "@app/editor/quick-editor/editors/editor-switcher/EditorSwitcher.module";
import {UserListEditorModule} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserListEditor.module";
import {
    DeadlineListEditorModule
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/DeadlineListEditor.module";
import {StatusRuleAppenderModule} from "@app/shared/_forms/status-rule-appender/StatusRuleAppender.module";
import {
    UseStatusRulesEditorModule
} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/UseStatusRulesEditor.module";
import {
    ProjectListEditorModule
} from "@app/editor/quick-editor/editors/generic/project-list-editor/ProjectListEditor.module";
import {TaskListModule} from "@app/shared/_ui/lists/task-list/TaskList.module";
import {TextEditorModule} from "@app/editor/quick-editor/editors/generic/text-editor/TextEditor.module";
import {
    ReactionListEditorModule
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/ReactionListEditor.module";
import {
    CategoryPickerListEditorModule
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/CategoryPickerListEditor.module";
import {
    ValidationWarningListModule
} from '@app/editor/base-editor-v3/components/validation-warning-list/validation-warning-list.module';
import {
    FindAndFocusBtnModule
} from "@app/editor/base-editor-v3/components/find-and-focus-btn/find-and-focus-btn.module";
import {
    MilestoneEditorTabsLogComponent
} from "@app/editor/milestone-editor-loader/milestone-editor-v3/components/milestone-editor-tabs/milestone-editor-tab-log/milestone-editor-tabs-log-component";
import {LogsModule} from "@app/shared/_ui/logs/Logs.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbTooltipModule,
        CdkDrag,
        ResizableModule,
        ReactiveFormsModule,
        CdkDragHandle,
        BaseEditorV3Module,
        GenericVerticalEditCollectionModule,
        ToggleItemModule,
        TitleEditorModule,
        NgbNav,
        NgbNavItem,
        NgbNavLink,
        NgbNavLinkBase,
        NgbNavOutlet,
        NgbNavContent,
        MilestoneCalculatedFieldModule,
        EditorSwitcherModule,
        UserListEditorModule,
        DeadlineListEditorModule,
        StatusRuleAppenderModule,
        NgbPopover,
        UseStatusRulesEditorModule,
        ProjectListEditorModule,
        TaskListModule,
        TextEditorModule,
        ReactionListEditorModule,
        CategoryPickerListEditorModule,
        ValidationWarningListModule,
        FindAndFocusBtnModule,
        LogsModule,

    ],
    declarations: [
        MilestoneEditorV3Component,
        MilestoneEditorFooterComponent,
        MilestoneEditorHeaderComponent,
        MilestoneEditorTitleComponent,
        MilestoneEditorTabsBasicComponent,
        MilestoneEditorTabsLogComponent,
    ],
    exports: [
        MilestoneEditorV3Component,  // export hvis det skal være tilgængligt i import af modul
    ]
})
export class MilestoneEditorV3Module {
}
