import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {
    ProjectEstimateListColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/ProjectEstimateListColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns";

export class ProjectEstimateListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.ProjectEstimateList;

    public column: ProjectEstimateListColumn;

}
