/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TaskTypeDefinition} from '@app/core/models/definitions/TaskTypeDefinition';
import {TaskTypesTaskUserType} from '@app/core/models/TaskTypesTaskUserType';
import {TaskDeadlineTypesTaskType} from '@app/core/models/TaskDeadlineTypesTaskType';
import {TaskEstimateTypesTaskType} from '@app/core/models/TaskEstimateTypesTaskType';
import {TaskStatusTypesTaskType} from '@app/core/models/TaskStatusTypesTaskType';
import {ProjectTypesTaskType} from '@app/core/models/ProjectTypesTaskType';

export class TaskType extends TaskTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

    // <editor-fold desc="Task User Types">

    private taskTypesTaskUserTypeMap: Map<number, TaskTypesTaskUserType>;
    private setupTaskTypesTaskUserType() {
        this.taskTypesTaskUserTypeMap = new Map();
        if(!this.task_types_task_user_types) return;
        for(let taskTypesTaskUserType of this.task_types_task_user_types) {
            this.taskTypesTaskUserTypeMap.set(taskTypesTaskUserType.task_user_type_id, taskTypesTaskUserType);
        }
    }
    public getTaskTypesTaskUserType(id: number): TaskTypesTaskUserType {
        if(!this.taskTypesTaskUserTypeMap) this.setupTaskTypesTaskUserType();
        return this.taskTypesTaskUserTypeMap.get(id);
    }

    // </editor-fold>

    // <editor-fold desc="Task User Types">

    private taskDeadlineTypesTaskTypeMap: Map<number, TaskDeadlineTypesTaskType>;
    private setupTaskDeadlineTypesTaskType() {
        this.taskDeadlineTypesTaskTypeMap = new Map();
        if(!this.task_deadline_types_task_types) return;
        for(let taskDeadlineTypesTaskType of this.task_deadline_types_task_types) {
            this.taskDeadlineTypesTaskTypeMap.set(taskDeadlineTypesTaskType.task_deadline_type_id, taskDeadlineTypesTaskType);
        }
    }
    public getTaskDeadlineTypesTaskType(id: number): TaskDeadlineTypesTaskType {
        if(!this.taskDeadlineTypesTaskTypeMap) this.setupTaskDeadlineTypesTaskType();
        return this.taskDeadlineTypesTaskTypeMap.get(id);
    }

    // </editor-fold>

    // <editor-fold desc="Task Estimate Types">

    private taskEstimateTypesTaskTypeMap: Map<number, TaskEstimateTypesTaskType>;
    private setupTaskEstimateTypesTaskType() {
        this.taskEstimateTypesTaskTypeMap = new Map();
        if(!this.task_estimate_types_task_types) return;
        for(let taskEstimateTypesTaskType of this.task_estimate_types_task_types) {
            this.taskEstimateTypesTaskTypeMap.set(taskEstimateTypesTaskType.task_estimate_type_id, taskEstimateTypesTaskType);
        }
    }
    public getTaskEstimateTypesTaskType(id: number): TaskDeadlineTypesTaskType {
        if(!this.taskEstimateTypesTaskTypeMap) this.setupTaskEstimateTypesTaskType();
        return this.taskEstimateTypesTaskTypeMap.get(id);
    }

    // </editor-fold>

    // <editor-fold desc="Task Status Types">

    private taskStatusTypesTaskTypeMap: Map<number, TaskStatusTypesTaskType>;
    private setupTaskStatusTypesTaskType() {
        this.taskStatusTypesTaskTypeMap = new Map();
        if(!this.task_status_types_task_types) return;
        for(let taskStatusTypesTaskType of this.task_status_types_task_types) {
            this.taskStatusTypesTaskTypeMap.set(taskStatusTypesTaskType.task_status_type_id, taskStatusTypesTaskType);
        }
    }
    public getTaskStatusTypesTaskType(id: number): TaskDeadlineTypesTaskType {
        if(!this.taskStatusTypesTaskTypeMap) this.setupTaskStatusTypesTaskType();
        return this.taskStatusTypesTaskTypeMap.get(id);
    }

    // </editor-fold>

    // <editor-fold desc="Project Types Task Types">

    private projectTypesTaskTypeMap: Map<number, ProjectTypesTaskType>;
    private setupProjectTypesTaskType() {
        this.projectTypesTaskTypeMap = new Map();
        if (!this.project_types_task_types) return;
        for (const projectTypesTaskType of this.project_types_task_types) {
            this.projectTypesTaskTypeMap.set(projectTypesTaskType.project_type_id, projectTypesTaskType);
        }
    }
    public getProjectTypesTaskType(projectTypeId: number): ProjectTypesTaskType {
        if(!this.projectTypesTaskTypeMap) this.setupProjectTypesTaskType();
        // console.log('getProjectTypesTaskType() : ', this.projectTypesTaskTypeMap, this.projectTypesTaskTypeMap.get(projectTypeId), projectTypeId);
        return this.projectTypesTaskTypeMap.get(projectTypeId);
    }

    // </editor-fold>

}
