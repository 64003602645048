import {NgModule} from "@angular/core";
import {CategoryComponent} from "@app/shared/_elements/category/category.component";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ColorItemModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CategoryComponent,
    ],
    exports: [
        CategoryComponent

    ],
    providers: [

    ],
})
export class CategoryModule {}
