import {BaseComparison} from "@app/shared/_ui/lists/BaseComparison";
import {HasMainStatus} from "@app/interfaces/HasMainStatus";

export class StatusComparison extends BaseComparison {

    public compare(a: HasMainStatus, b: HasMainStatus): number {
        const aValue = a.main_status != null ? a.main_status.status_id : 0;
        const bValue = b.main_status != null ? b.main_status.status_id : 0;
        return bValue - aValue; // Med vilje vendt om (A-Z = Rød, gul, grøn, grå)
    }

}
