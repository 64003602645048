import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Project} from '@app/core/models/Project';
import {ProjectsService} from '@app/services/projects.service';
import {Api} from '@app/core/http/Api/Api';

@Component({
    selector: 'app-project-delete-dialog',
    templateUrl: './project-delete-dialog.component.html',
    styleUrls: ['./project-delete-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ]
})
export class ProjectDeleteDialogComponent extends BaseModalComponent implements OnInit {

    @Input() primaryClass: string = 'btn-danger';
    @Input() item: Project;
    public project: Project;
    cascade: boolean = true;

    constructor(private modal: NgbActiveModal, private projectsService: ProjectsService) {
        super(modal);
    }

    ngOnInit() {
        if(this.item){
            Api.projects()
                .getById(this.item.id)
                .include('milestone')
                .include('task')
                .find((project)=>{
                    this.project = project[0];
                });
        }
    }

    public accept() {
        this.activeModal.close({cascade: this.cascade});
    }

}
