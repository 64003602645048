import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {isArray} from "ngx-bootstrap/chronos";

export class ColumnVisibilityChangeEvent {

    public addedColumns: BaseTableColumn[];
    public removedColumns: BaseTableColumn[];

    constructor(addedColumns: BaseTableColumn[], removedColumns: BaseTableColumn[]) {
        this.addedColumns = addedColumns;
        this.removedColumns = removedColumns;
    }

    public hasAddedColumn(identifier: string | string[]): boolean {
        return this.hasColumn(identifier, this.addedColumns);
    }

    public hasRemovedColumn(identifier: string | string[]): boolean {
        return this.hasColumn(identifier, this.removedColumns);
    }

    private hasColumn(identifier: string | string[], columns: BaseTableColumn[]): boolean {
        const identifiers = isArray(identifier) ? identifier : [identifier];
        for (let i = 0; i < identifiers.length; i++) {
            if (this.addedColumns.some(column => column.column.columnType.identifier == identifiers[i])) {
                return true;
            }
        }
        return false;
    }

}
