/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TaskTemplateDefinition} from './definitions/TaskTemplateDefinition';

export class TaskTemplate extends TaskTemplateDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static createDefault(): TaskTemplate {
        let item = new TaskTemplate();
        item.id = 0;
        item.users = [];
        return item;
    }
}
