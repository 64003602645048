export const TodoPresetTypes = {
    Title:  'title',
    Project:  'project',
    Task:  'task',
    User:  'user',
    Deadline:  'deadline',
    Status:  'status',
    ShowOnDisplay: 'show-on-display',
    Display: 'display',
};

export type TodoPresetType =
    typeof TodoPresetTypes.Title
    | typeof TodoPresetTypes.Project
    | typeof TodoPresetTypes.Task
    | typeof TodoPresetTypes.User
    | typeof TodoPresetTypes.Deadline
    | typeof TodoPresetTypes.Status
    | typeof TodoPresetTypes.ShowOnDisplay
    | typeof TodoPresetTypes.Display
;
