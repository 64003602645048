<ul class="card-edit-expander align-items-stretch list-unstyled d-flex flex-column m-0 h-100"
    cdkDropList
    id="{{dragListId}}"
    [cdkDropListConnectedTo]="dragDropList"
    [cdkDropListData]="listDragInterface"
    (cdkDropListDropped)="dragItemDropped($event)"
    [cdkDropListEnterPredicate]="dragItemEnterPredicate"
    [cdkDropListSortingDisabled]="!listDragInterface?.isSortingEnabled"
    (cdkDropListEntered)="dropListEntered($event)"
    (cdkDropListExited)="dropListExited($event)"
    [class.cdk-drop-list-disabled]="listDragInterface?.dropDisabled"
    [attr.data-length]="items?.length || 0">
    <li *ngFor="let item of items"
        class="editor-item flex-grow-1 cdkDragRootElement-class">
        <app-card-vertical-edit-collection-switcher
            [cardItem]="item"
            [enabledEditors]="listConfiguration.cardEditExpander_enabledEditors"
            [listDragInterface]="listDragInterface"
            [listConfiguration]="listConfiguration"
            [showCreateNew]="showCreateNew"
        ></app-card-vertical-edit-collection-switcher>
    </li>

    <li *ngIf="showCreateNew && listConfiguration?.createItemConfiguration" class="d-flex align-items-end justify-content-start p-1">
        <app-create-item-dropdown
            class="position-relative "
            [buttonMode]="true"
            [source]="listConfiguration?.createItemConfiguration"
            [createItemPreset]="listConfiguration?.createItemPreset"
            [placement]="'auto'"
            [container]="'body'"
            [label]="('_global_create' | translate) + (typeName) + '...' "
            style=""
        ></app-create-item-dropdown>

    </li>

</ul>
