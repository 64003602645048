/**
 * Created by ModelParser
 * Date: 04-12-2019.
 * Time: 13:20.
 */
import {MilestoneTemplatesStatusRuleDefinition,} from './definitions/MilestoneTemplatesStatusRuleDefinition';

export class MilestoneTemplatesStatusRule extends MilestoneTemplatesStatusRuleDefinition {

    constructor(json?: any) {
        super(json);
    }

}
