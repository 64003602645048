export class Period {
    id: string;
    name: string;
    showCalendar: boolean;

    public constructor(id: string, name: string, showCalendar: boolean = false) {
        this.id = id;
        this.name = name;
        this.showCalendar = showCalendar;
    }
}