/**
 * Created by ModelParser
 */
import {TaskStatusType} from '../TaskStatusType';
import {ProjectStatusType} from '../ProjectStatusType';
import {TaskStatus} from '../TaskStatus';
import {ProjectStatus} from '../ProjectStatus';
import {PeriodRating} from '../PeriodRating';
import {StatusRule} from '../StatusRule';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class StatusDefinition extends BaseModel {
    description?: string;
    css_class?: string;
    task_status_types?: TaskStatusType[];
    project_status_types?: ProjectStatusType[];
    task_status?: TaskStatus[];
    project_status?: ProjectStatus[];
    period_ratings?: PeriodRating[];
    status_rule_to_status?: StatusRule[];
    status_rule_from_status?: StatusRule[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.description;
            delete this.css_class;
            delete this.task_status_types;
            delete this.project_status_types;
            delete this.task_status;
            delete this.project_status;
            delete this.period_ratings;
            delete this.status_rule_to_status;
            delete this.status_rule_from_status;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.description != null) {
            this.description = data.description;
        }
        if (data.css_class != null) {
            this.css_class = data.css_class;
        }
        if (data.task_status_types != null) {
            this.task_status_types = data.task_status_types.map((i: any) => new TaskStatusType(i));
        }
        if (data.project_status_types != null) {
            this.project_status_types = data.project_status_types.map((i: any) => new ProjectStatusType(i));
        }
        if (data.task_status != null) {
            this.task_status = data.task_status.map((i: any) => new TaskStatus(i));
        }
        if (data.project_status != null) {
            this.project_status = data.project_status.map((i: any) => new ProjectStatus(i));
        }
        if (data.period_ratings != null) {
            this.period_ratings = data.period_ratings.map((i: any) => new PeriodRating(i));
        }
        if (data.status_rule_to_status != null) {
            this.status_rule_to_status = data.status_rule_to_status.map((i: any) => new StatusRule(i));
        }
        if (data.status_rule_from_status != null) {
            this.status_rule_from_status = data.status_rule_from_status.map((i: any) => new StatusRule(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
