import {Component, Input, OnInit} from '@angular/core';
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

@Component({
    selector: 'app-column-debugger',
    templateUrl: './column-debugger.component.html',
    styleUrls: ['./column-debugger.component.scss']
})
export class ColumnDebuggerComponent implements OnInit {

    @Input() column: BaseTableColumn;

    constructor() {
    }

    ngOnInit(): void {
    }

}
