import {ProjectListConfiguration} from "@app/shared/_ui/lists/project-list/ProjectListConfiguration";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {MilestoneListConfiguration} from "@app/shared/_ui/lists/milestone-list/MilestoneListConfiguration";
import {CategoryColumn} from "@app/pages/displays/display-category/Columns/CategoryColumn";
import {MultiListConfiguration} from "@app/shared/_ui/lists/multi-list/MultiListConfiguration";
import {CreateItemSourceInterface} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceInterface";
import {ResizeEvent} from "angular-resizable-element";
import {EventEmitter} from "@angular/core";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {SortItem} from "@app/shared/_ui/lists/SortItem";
import {Filters} from "@app/pages/displays/display-category/Filters";
import {DeadlineComparison} from "@app/shared/_ui/lists/multi-list/Comparisons/DeadlineComparison";
import {TitleComparison} from "@app/shared/_ui/lists/multi-list/Comparisons/TitleComparison";
import {StatusComparison} from "@app/shared/_ui/lists/multi-list/Comparisons/StatusComparison";
import {UsersComparison} from "@app/shared/_ui/lists/multi-list/Comparisons/UsersComparison";
import {StarredComparison} from "@app/shared/_ui/lists/multi-list/Comparisons/StarredComparison";
import {HandUpsComparison} from "@app/shared/_ui/lists/multi-list/Comparisons/HandUpsComparison";
import {CategoryComparison} from "@app/shared/_ui/lists/multi-list/Comparisons/CategoryComparison";
import {DisplayFilter} from "@app/core/models";
import {PageDisplaySetting} from "@app/pages/PageDisplaySetting";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {Settings} from "@app/pages/displays/display-category/Settings";
import {TableColumns} from "@app/pages/displays/display-category/TableColumns";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

export class CategoryTableColumn extends BaseTableColumn {

    public identifier = TableColumns.Category;
    public column: CategoryColumn;

    public multiListConfiguration = new MultiListConfiguration();
    public projectListConfiguration = new ProjectListConfiguration();
    public taskListConfiguration = new TaskListConfiguration();
    public milestoneListConfiguration = new MilestoneListConfiguration();

    public reloadEvent = new EventEmitter();

    constructor(column: CategoryColumn) {
        super(column);

        this.multiListConfiguration
            .addSource(this.projectListConfiguration)
            .addSource(this.taskListConfiguration)
            .addSource(this.milestoneListConfiguration)
            .setDraggable(true)
            .setAllowDragEnter(true)
            .setShowCreateNew(true);

        this.projectListConfiguration
            .setCategoryIds([column.getFilterCategory()])
            .setCardConfiguration_ExcludedCategoryIds([column.getFilterCategory()])
            .setUseGlobalFilter(true)
            .setDraggable(true)
            .setShowCreateNew(true);

        this.taskListConfiguration
            .setCategoryIds([column.getFilterCategory()])
            .setCardConfiguration_ExcludedCategoryIds([column.getFilterCategory()])
            .setShowMilestoneMiniCard(true)
            .setUseGlobalFilter(true)
            .setDraggable(true)
            .setShowCreateNew(true);

        this.milestoneListConfiguration
            .setCategoryIds([column.getFilterCategory()])
            .setCardConfiguration_ExcludedCategoryIds([column.getFilterCategory()])
            .setShowProjectMiniCard(true)
            .setUseGlobalFilter(true)
            .setDraggable(true)
            .setShowCreateNew(true);


        // Setup CreateItem Configuration
        const multiListConfiguration = this.multiListConfiguration;
        const createItemConfiguration = this.multiListConfiguration.createItemConfiguration;
        createItemConfiguration.showProjects = true;
        createItemConfiguration.showTasks = true;
        createItemConfiguration.sourceInterface = new class implements CreateItemSourceInterface {
            prepareSource(): void {
                multiListConfiguration.getSources().forEach(source => {
                    source.listConfiguration.prepareSource();
                });
            }
        };

        // Setup CreateItem Preset
        const createItemPreset = this.multiListConfiguration.createItemPreset;
        createItemPreset.createProjectInterface = this.projectListConfiguration;
        createItemPreset.createTaskInterface = this.taskListConfiguration;
        createItemPreset.createMilestoneInterface = this.milestoneListConfiguration;
    }

    public validateResize(event: ResizeEvent): boolean {
        return !(event.rectangle.width && (event.rectangle.width < this.minWidth));
    }

    public resize(width: number) {
        this.width = Math.max(this.minWidth, width);
    }

    public applySortItem(sortItem: PageColumnSort, sortDirection: string) {
        this.multiListConfiguration.getSources().forEach(source => {
            switch (source.listConfiguration.constructor.name) {
                case TaskListConfiguration.name:
                    CategoryTableColumn.ApplyTaskListConfigurationSortItem(source.listConfiguration as TaskListConfiguration, sortItem, sortDirection);
                    break;
                case ProjectListConfiguration.name:
                    CategoryTableColumn.ApplyProjectListConfigurationSortItem(source.listConfiguration as ProjectListConfiguration, sortItem, sortDirection);
                    break;
            }
        });

        // Convert display sort items to multi list sorts
        const sortItems: SortItem[] = [];
        switch (Filters.GetBaseSort(sortItem.sortId)) {
            case Filters.SortDeadline:
                sortItems.push(new SortItem(new DeadlineComparison(), sortDirection));
                break;
            case Filters.SortTitle:
                sortItems.push(new SortItem(new TitleComparison(), sortDirection));
                break;
            case Filters.SortStatus:
                sortItems.push(new SortItem(new StatusComparison(), sortDirection));
                break;
            case Filters.SortUsers:
                sortItems.push(new SortItem(new UsersComparison(), sortDirection));
                break;
            case Filters.SortStarred:
                sortItems.push(new SortItem(new StarredComparison(), sortDirection));
                break;
            case Filters.SortHandUp:
                sortItems.push(new SortItem(new HandUpsComparison(), sortDirection));
                break;
            case Filters.SortCategoryType:
                sortItems.push(new SortItem(new CategoryComparison(Filters.ParseSortCategoryType(sortItem.sortId)), sortDirection));
                break;
        }
        this.multiListConfiguration.setSortItems(sortItems);
    }

    private static ApplyTaskListConfigurationSortItem(listConfiguration: TaskListConfiguration, sortItem: PageColumnSort, sortDirection: string) {
        listConfiguration.clearSortFilters();
        listConfiguration.setDeadlineValidator(null);
        switch (Filters.GetBaseSort(sortItem.sortId)) {
            case Filters.SortDeadline:
                listConfiguration.setDeadlineValidator((task, deadlines) => {
                    return task.findTasksDeadlineByType(task.getMiniCardDeadlineTypeId())?.exists();
                });
                break;
            case Filters.SortCategoryType:
                listConfiguration.setSort_CategoryTypeId(Filters.ParseSortCategoryType(sortItem.sortId));
                break;
        }
    }

    private static ApplyProjectListConfigurationSortItem(listConfiguration: ProjectListConfiguration, sortItem: PageColumnSort, sortDirection: string) {
        listConfiguration.clearSortFilters();
        switch (Filters.GetBaseSort(sortItem.sortId)) {
            case Filters.SortDeadline:
                listConfiguration.setSort_DeadlineValidator(project => {
                    return project.findProjectsDeadlineByType(project.getMiniCardDeadlineTypeId())?.exists();
                });
                break;
            case Filters.SortCategoryType:
                listConfiguration.setSort_CategoryTypeId(Filters.ParseSortCategoryType(sortItem.sortId));
                break;
        }
    }

    public applyFilter(displayFilter: DisplayFilter, settingsMap: Map<number, PageDisplaySetting>, filterSettings: FiltersInterface) {
        if (!displayFilter) {
            return;
        }
        this.multiListConfiguration.getSources().forEach(source => {
            switch (source.listConfiguration.constructor.name) {
                case TaskListConfiguration.name:
                    CategoryTableColumn.ApplyTaskListConfigurationFilter(source.listConfiguration as TaskListConfiguration, displayFilter, settingsMap, filterSettings);
                    break;
                case ProjectListConfiguration.name:
                    CategoryTableColumn.ApplyProjectListConfigurationFilter(source.listConfiguration as ProjectListConfiguration, displayFilter, settingsMap, filterSettings);
                    break;
                case MilestoneListConfiguration.name:
                    CategoryTableColumn.ApplyMilestoneListConfigurationFilter(source.listConfiguration as MilestoneListConfiguration, displayFilter, settingsMap, filterSettings);
                    break;
            }
        });
    }

    private static ApplyTaskListConfigurationFilter(listConfiguration: TaskListConfiguration, displayFilter: DisplayFilter, settingsMap: Map<number, PageDisplaySetting>, filterSettings: FiltersInterface) {
        // Clear whatever the filter types had set
        listConfiguration.setDeadlineValidator(null);
        listConfiguration.setHasNonDeadline(null);
        listConfiguration.setOpen(null);
        listConfiguration.setArchivedBetween(null, null);

        // Apply filter type
        switch (displayFilter?.filter_type) {
            case Filters.FilterAll:
                break;
            case Filters.FilterDeadlineInPeriod:
                listConfiguration.setDeadlineValidator(((task, deadlines) => {
                    const deadline = task.findTasksDeadlineByType(task.getMiniCardDeadlineTypeId());
                    return deadline?.deadline?.isBetween(displayFilter.getPeriodStart(filterSettings), displayFilter.getPeriodEnd(filterSettings));
                }));
                break;
            case Filters.FilterNonDeadline:
                listConfiguration.setHasNonDeadline(true);
                break;
            case Filters.FilterOpen:
                listConfiguration.setOpen(true);
                break;
            case Filters.FilterArchivedInPeriod:
                listConfiguration.setArchivedBetween(displayFilter.getPeriodStart(filterSettings), displayFilter.getPeriodEnd(filterSettings));
                break;
        }

        // Default for all filter types
        if ((displayFilter?.task_types?.length ?? 0) + (displayFilter.project_types?.length ?? 0) > 0) {
            const filteredTaskTypeIds = displayFilter.task_types?.map(type => type.id) ?? [];
            const taskTypeIds = Settings.GetTaskTypeIds(settingsMap).filter(typeId => filteredTaskTypeIds.includes(typeId));
            listConfiguration.setTaskTypeIds(taskTypeIds);
        } else {
            listConfiguration.setTaskTypeIds(Settings.GetTaskTypeIds(settingsMap));
        }

        if (displayFilter?.filter_type != Filters.FilterArchivedInPeriod) {
            listConfiguration.setOrArchivedSince(displayFilter.getIncludeArchivedSinceDate(filterSettings));
        }
    }

    private static ApplyProjectListConfigurationFilter(listConfiguration: ProjectListConfiguration, displayFilter: DisplayFilter, settingsMap: Map<number, PageDisplaySetting>, filterSettings: FiltersInterface) {
        // Clear whatever the filter types had set
        listConfiguration.setDeadlineValidator(null);
        listConfiguration.setHasNonDeadline(null);
        listConfiguration.setOpen(null);
        listConfiguration.setArchivedBetween(null, null);

        // Apply filter type
        switch (displayFilter?.filter_type) {
            case Filters.FilterAll:
                break;
            case Filters.FilterDeadlineInPeriod:
                listConfiguration.setDeadlineValidator(((project, deadlines) => {
                    const deadline = project.findProjectsDeadlineByType(project.getMiniCardDeadlineTypeId());
                    return deadline?.deadline?.isBetween(displayFilter.getPeriodStart(filterSettings), displayFilter.getPeriodEnd(filterSettings));
                }));
                break;
            case Filters.FilterNonDeadline:
                listConfiguration.setHasNonDeadline(true);
                break;
            case Filters.FilterOpen:
                listConfiguration.setOpen(true);
                break;
            case Filters.FilterArchivedInPeriod:
                listConfiguration.setArchivedBetween(displayFilter.getPeriodStart(filterSettings), displayFilter.getPeriodEnd(filterSettings));
                break;
        }

        // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1558
        // Default for all filter types
        if ((displayFilter?.task_types?.length ?? 0) + (displayFilter?.project_types?.length ?? 0) > 0) {
            const filteredProjectTypeIds = displayFilter.project_types?.map(type => type.id) ?? [];
            const projectTypeIds = Settings.GetProjectTypeIds(settingsMap).filter(typeId => filteredProjectTypeIds.includes(typeId));
            listConfiguration.setProjectTypeIds(projectTypeIds);
        } else {
            listConfiguration.setProjectTypeIds(Settings.GetProjectTypeIds(settingsMap));
        }


        if (displayFilter?.filter_type != Filters.FilterArchivedInPeriod) {
            listConfiguration.setOrArchivedSince(displayFilter.getIncludeArchivedSinceDate(filterSettings));
        }
    }

    private static ApplyMilestoneListConfigurationFilter(listConfiguration: MilestoneListConfiguration, displayFilter: DisplayFilter, settingsMap: Map<number, PageDisplaySetting>, filterSettings: FiltersInterface) {
        // Clear whatever the filter types had set
        listConfiguration.setDeadlineValidator(null);
    }

}
