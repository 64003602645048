import {GenericTableColumn} from "@app/pages/displays/dashboard/dashboard-projects/TableColumns/GenericTableColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {
    FollowingMilestoneListCell
} from "@app/pages/displays/dashboard/dashboard-projects/Cells/FollowingMilestoneListCell";
import {MilestoneListConfiguration} from "@app/shared/_ui/lists/milestone-list/MilestoneListConfiguration";
import {TableColumns} from "@app/pages/displays/dashboard/dashboard-projects/TableColumns";

export class FollowingMilestoneListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.FollowingMilestoneList;

    public getListConfiguration(row: BaseRow): MilestoneListConfiguration {
        return row.getCell<FollowingMilestoneListCell>(this.column).listConfiguration;
    }

}
