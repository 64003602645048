import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {EstimateItem} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EstimateItem";

export class EditEstimateListConfiguration extends BaseEditorConfiguration {

    public labelName: string;
    public types: EstimateItem[];

    constructor(labelName: string, types: EstimateItem[]) {
        super();
        this.labelName = labelName;
        // this.types = types.map(et => new EstimateItem(et.name, et.id, et.required));
        this.types = types;
    }

}
