import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    TodoListTableColumn
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/TableColumns/TodoListTableColumn";

export class TodoListColumn extends BaseColumn {

    public createTableColumns(): BaseTableColumn[] {
        return [new TodoListTableColumn(this)];
    }

    public createCell(row: BaseRow): BaseCell {
        throw new Error("Method not implemented.");
    }

}
