import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {BaseDisplayComponent} from '../../_system/base-display/base-display.component';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {Subscription} from 'rxjs';
import {OldApi} from '@app/http/Api';
import {Project} from '@app/core/models/Project';
import {Roster, Task} from '@app/core/models/Task';
import {EventService} from '@app/services/event.service';
import {User} from '@app/core/models/User';
import {AppInjector} from '@app/services/app-injector.service';
import {UsersService} from '@app/services/users.service';
import {Favorite} from '@app/core/models/Favorite';
import {Milestone} from '@app/core/models/Milestone';
import {Todo} from '@app/core/models/Todo';
import {PlacementArray} from '@app/directives/positioning';


@Component({
    selector: 'app-star-selector',
    templateUrl: './star-selector.component.html',
    styleUrls: ['./star-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StarSelectorComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @Input() model: AnyItem; // TO-DO Refactor til EditStarred (Interface)
    @Input() showUsers = true;
    @Input() placement: PlacementArray = ['bottom', 'right', 'left', 'auto'];
    @Input() tooltipPlacement: PlacementArray = ['left','bottom', 'right', 'auto'];
    @Output() onToggle: EventEmitter<any> = new EventEmitter<any>();

    public stars: Favorite[] = [];
    private activated:boolean = false;
    public active:boolean = false;
    // Hvilke brugere skal kunne vælges?
    public user:User;

    constructor(private eventService: EventService,
                private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.subscribe(this.eventService.subscribe(this.model, (event) =>{
            switch (event.action) {
                case EventService.Created:
                case EventService.Updated:
                case EventService.Deleted:
                    // this.stars = [];

                    if(event.action == EventService.Deleted && this.stars){
                        this.stars = this.stars.filter(s => s.id != event.item.id);
                    }
                    this.detectChanges();
                    this.updateActive();
                break;
            }
        }));
        this.user = AppInjector.getInjector().get(UsersService).user;
    }

    public clickEvent(){
        if(!this.showUsers && this.user){
            console.log('Toggle user : ', this.activated, this.user);
            this.toggleActive(!(this.stars.filter(s => s.user_id = this.user.id).length > 0), this.stars.find(s => s.user_id = this.user.id));
        }

    }

    private updateActive(){
        if(this.stars) {
            const found: Favorite = this.stars.find((hu: Favorite) => {
                return hu.user.id == this.user.id;
            });
            this.active = !!found;
        }else{
            this.active = false;
        }
        this.cd.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['model']){
        }
    }

    private apiLoader: Subscription;
    private loadData() {

        if(this.isLoading) return;
        this.isLoading = true;

        if(this.apiLoader) this.apiLoader.unsubscribe();

        let api: OldApi;
        switch(this.model.constructor) {
            case Project:
                api = OldApi.projects(this.model.id).stars();
                break;
            case Task:
            case Roster:
                api = OldApi.tasks(this.model.id).stars();
                break;
            case Milestone:
                api = OldApi.milestones(this.model.id).stars();
                break;
            case Todo:
                api = OldApi.todos(this.model.id).stars();
                break;
        }

        if(api) {
            this.apiLoader = api.orderBy('created', 'desc')
                .get(Favorite.name)
                .subscribe((stars: Favorite[]) => {
                    this.stars = this.stars.concat(stars.filter((ha:Favorite) => {return ha.user_id != 0}));
                    this.isLoading = false;
                    const found: Favorite = this.stars.find((hu:Favorite) => {
                        return hu.user.id == this.user.id;
                    });
                    this.active = !!found;
                    this.cd.detectChanges();
                    this.clickEvent();
                });


        } else {
            //console.warn('Unknown item type', this.item.constructor.name);
            this.isLoading = false;
        }

    }

    public preventPropagation(mouseEvent: MouseEvent) {
        this.activated = !this.activated;
        if(this.activated && this.stars.length == 0){
            this.loadData();
        }else{
            this.clickEvent();
        }
        mouseEvent.stopPropagation();
    }

    toggleActive(active: boolean, fav: Favorite) {
        this.onToggle.emit({active, fav});
        this.cd.markForCheck();
        if(!fav){
            fav = new Favorite({user_id: this.user.id});
        }

        let api: OldApi;
        switch(this.model.constructor) {
            case Project:
                api = OldApi.projects(this.model.id).stars();
                api.setParam('user_id', fav.user_id);
                // Opret
                if(!active){
                    api.delete().subscribe((ha:Favorite) => {
                        if(!(this.model as Task).num_stars)
                            (this.model as Task).num_stars = 0;
                        else
                            (this.model as Project).num_stars --;

                        this.eventService.emitProject(this.model as Project, EventService.Updated, ['num_stars']);
                        this.cd.markForCheck();
                    })

                }else{
                    fav.project_id = this.model.id;
                    api.put().subscribe((ha:Favorite) => {
                        if(!(this.model as Task).num_stars)
                            (this.model as Task).num_stars = 0;

                        (this.model as Project).num_stars ++;

                        this.eventService.emitProject(this.model as Project, EventService.Updated, ['num_stars']);
                        this.cd.markForCheck();
                    })

                }
                break;
            case Task:
            case Roster:
                api = OldApi.tasks(this.model.id).stars();
                api.setParam('user_id', fav.user_id);
                if(!active){
                    api.delete().subscribe((ha:Favorite) => {
                        if(!(this.model as Task).num_stars)
                            (this.model as Task).num_stars = 0;
                        else
                            (this.model as Task).num_stars --;


                        this.eventService.emitTask(this.model as Task, EventService.Updated, ['num_stars']);
                        this.cd.markForCheck();
                    })

                }else{
                    fav.task_id = this.model.id;
                    api.put().subscribe((ha:Favorite) => {
                        if(!(this.model as Task).num_stars)
                            (this.model as Task).num_stars = 0;

                        (this.model as Task).num_stars ++;

                        this.eventService.emitTask(this.model as Task, EventService.Updated, ['num_stars']);
                        this.cd.markForCheck();
                    });
                }
                break;
            case Milestone:
                api = OldApi.milestones(this.model.id).stars();
                api.setParam('user_id', fav.user_id);
                if(!active){
                    api.delete().subscribe((ha:Favorite) => {
                        if(!(this.model as Task).num_stars)
                            (this.model as Task).num_stars = 0;
                        else
                            (this.model as Milestone).num_stars --;

                        this.eventService.emitMilestone(this.model as Milestone, EventService.Updated, ['num_stars']);
                        this.cd.markForCheck();
                    })

                }else{
                    fav.task_id = this.model.id;
                    api.put().subscribe((ha:Favorite) => {
                        if(!(this.model as Task).num_stars)
                            (this.model as Task).num_stars = 0;

                        (this.model as Milestone).num_stars ++;

                        this.eventService.emitMilestone(this.model as Milestone, EventService.Updated, ['num_stars']);
                        this.cd.markForCheck();
                    });
                }
                break;
            case Todo:
                api = OldApi.todos(this.model.id).stars();
                api.setParam('user_id', fav.user_id);
                if(!active){
                    api.delete().subscribe((ha:Favorite) => {
                        if(!(this.model as Task).num_stars)
                            (this.model as Task).num_stars = 0;
                        else
                            (this.model as Todo).num_stars --;

                        this.eventService.emitTodo(new Todo(this.model as Todo), EventService.Updated, ['num_stars']);
                        this.cd.markForCheck();
                    })

                }else{
                    fav.task_id = this.model.id;
                    api.put().subscribe((ha:Favorite) => {
                        if(!(this.model as Task).num_stars)
                            (this.model as Task).num_stars = 0;

                        (this.model as Todo).num_stars ++;

                        this.eventService.emitTodo(new Todo(this.model as Todo), EventService.Updated, ['num_stars']);
                        this.cd.markForCheck();
                    });
                }
                break;
        }

    }
}
