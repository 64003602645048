import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {TranslateModule} from "@ngx-translate/core";
import {
    ProjectDeadlineListCellComponent
} from "@app/pages/displays/display-projects/CellComponents/project-deadline-list-cell/project-deadline-list-cell.component";
import {ItemDateModule} from "@app/shared/_elements/item-date/ItemDate.module";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";

@NgModule({
    imports: [
        CommonModule,
        UserWithIconModule,
        TranslateModule,
        ItemDateModule,
        TypedTemplateDirective,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectDeadlineListCellComponent,
    ],
    exports: [
        ProjectDeadlineListCellComponent

    ],
    providers: [

    ],
})
export class ProjectDeadlineListCellModule {}
