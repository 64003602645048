import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {TodoListTableColumn} from "@app/pages/displays/dashboard/dashboard-projects/TableColumns/TodoListTableColumn";
import {TodoListCell} from "@app/pages/displays/dashboard/dashboard-projects/Cells/TodoListCell";

export class TodoListColumn extends BaseColumn<TodoListCell> {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new TodoListTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: BaseRow): BaseCell {
        return new TodoListCell(row, this);
    }

}
