import {Subscription} from 'rxjs';
import {EventEmitter} from '@angular/core';
import Helpers from '@app/core/helpers';
import {
    Office365EventListConfiguration
} from "@app/shared/_ui/lists/office365event-list/Office365EventListConfiguration";
import {Api} from "@app/core/http/Api/Api";
import {Office365Event} from "@app/core/models";

export class Office365FetcherRequest {

    public configuration: Office365EventListConfiguration;
    public callback: (items: Office365Event[], count: number) => void;
    public result: Office365Event[] = [];
    public count: number = 0;
    public finished = false;

    public constructor(configuration: Office365EventListConfiguration) {
        this.configuration = configuration;
        this.configuration.setDataSource(this);
    }

    public reset() {
        this.result = [];
        this.count = 0;
    }

    public addItem(item: Office365Event) {
        this.result.push(item);
        this.count++;
    }

    public cleanupAndEmitWhenReady(showAll: boolean) : any {
        this.applyOrdering();

        this.configuration.onScreenFilterChange.subscribe(() => this.emitSlice(showAll));

        if (this.configuration.hasSmartLimit()) {
            // Wait for SmartLimit. The limit can change over time (cause of screen resize). Keep listening for new limits and return slices.
            this.configuration.onLimitSetEvent.subscribe((limit: number) => this.emitSlice(showAll));
        } else {
            this.emitSlice(showAll);
        }
    }

    private applyOrdering() {

    }

    private emitSlice(showAll: boolean) {
        // Apply on screen filters
        const filteredItems = this.result.filter(item => {
            return this.configuration.onScreenFilters.find(filter => !filter.isValid(item)) === undefined;
        });

        // Slice according to requested limit
        const sliceOfItems = this.getSlicedResult(filteredItems, showAll);

        // Emit to ListConfiguration
        this.finished = true;
        if (this.callback) {
            this.callback(sliceOfItems, filteredItems.length);
        }
    }

    private getSlicedResult(items: Office365Event[], showAll: boolean) {
        if (showAll) {
            return items;
        } else {
            const limit = Math.max(this.configuration.getLastItemCount() ?? 0, this.configuration.getLimit() ?? 0);
            if (limit > 0) {
                return items.slice(0, limit);
            } else {
                return items;
            }
        }
    }

    public clear() {
        this.configuration.setLastItemCount(0);
        this.result = [];
        this.count = 0;
    }

    // Called from TaskList to request "ShowMore/ShowAll"
    public requestSlice(offset: number, limit: number) {
        // Apply on screen filters
        const filteredItems = this.result.filter(item => {
            return this.configuration.onScreenFilters.find(filter => !filter.isValid(item)) === undefined;
        });

        // Slice according to requested offset and limit
        const sliceOfItems = filteredItems.slice(offset, limit);

        // Emit to ListConfiguration
        this.finished = true;
        if (this.callback) {
            this.callback(sliceOfItems, filteredItems.length);
        }
    }

    public subscribe(callback: (items: Office365Event[], count: number) => void) {
        this.callback = callback;
        if (this.finished) {
            callback(this.result, this.count);
        }
    }

}

export class Office365Fetcher {

    private requests: Office365FetcherRequest[] = [];
    private api: Subscription;
    public onFinishEvent: EventEmitter<boolean> = new EventEmitter();
    public showAll = false;

    // Global scope
    private start: Date;
    private end: Date;

    public constructor() {

    }

    public addRequest(request: Office365FetcherRequest) {
        this.requests.push(request);
        request.reset();
    }

    public executeForPeriod(start: Date, end: Date) {
        // Set global scope
        this.start = start;
        this.end = end;

        // Execute!
        this.execute();
    }

    public execute() {
        if(this.requests.length == 0) {
            this.onFinishEvent.emit(true);
            return;
        }

        const configuration = this.requests[0].configuration;

        const api = Api.office365Events().get();

        if (configuration.getUser()) {
            api.where('user_id', configuration.getUser().id);
        }

        if (configuration.getDeadlineDateMin() || configuration.getDeadlineValidator()) {
            api.whereGreaterThanOrEqual('start', Helpers.serverDate(this.start ?? configuration.getDeadlineDateMin()));
        }

        if (configuration.getDeadlineDateMax() || configuration.getDeadlineValidator()) {
            api.whereLessThanOrEqual('end', Helpers.serverDate(this.end ?? configuration.getDeadlineDateMax()));
        }

        api
            .orderAsc('start')
            .find(items => {

                // Clear all requests for earlier data
                this.requests.forEach(request => request.clear());

                items.forEach(item => {

                    // Apply projects to fetch requests
                    this.requests.forEach(request => {
                        if (request.configuration.validate(item)) {
                            request.addItem(item);
                        }
                    });

                });

                // Emit each fetch request
                this.requests.forEach(request => {
                    request.cleanupAndEmitWhenReady(this.showAll)
                });

                // Emit all done
                this.onFinishEvent.emit(true);

            });
    }


    public cancel() {
        if(this.api) {
            this.api.unsubscribe();
        }
    }
}
