<ng-container *ngFor="let item of items">
    <app-color-label
        class="card-type mb-2 flex-grow-1 flex-shrink-1 ellipsis mb-1  w-100"
        style="height: 32px;"
        container="body"
        [ngbTooltip]="item.name | translate"
        [status]="item.statusId"
        [items]="item.statusTypes"
        [label]="item.name | translate"
        (onStatusChange)="item.onChange($event)"></app-color-label>
</ng-container>
