import {BaseYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/BaseYearWheelItem";
import {Milestone} from "@app/core/models";
import {YearWheelColumnConfiguration} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration";
import {TaskYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/TaskYearWheelItem";

export class MilestoneYearWheelItem extends BaseYearWheelItem<Milestone> {

    relatedMilestones: MilestoneYearWheelItem[];
    sortedMilestones: MilestoneYearWheelItem[];
    relatedTasks: TaskYearWheelItem[];
    sortedTasks: TaskYearWheelItem[];

    constructor(configuration: YearWheelColumnConfiguration,
                item: Milestone,

                xPosInPixels: number,

                width: number,
                startDate: Date
    ) {
        super();

        this.visible = true;
        this.relatedMilestones = [];
        this.sortedMilestones = [];
        this.relatedTasks = [];
        this.sortedTasks = [];

        this.configuration = configuration;
        this.item = item;

        this.left = xPosInPixels;

        this.width = width;
        this.startDate = startDate;
    }

}
