import {User} from '@app/core/models/User';
import {Office365Event} from '@app/core/models';
import {ListConfiguration} from '@app/shared/_ui/lists/ListConfiguration';
import {Office365FetcherRequest} from "@app/shared/_ui/lists/office365event-list/Office365Fetcher";
import {CardItem} from "@app/shared/_ui/cards/CardItem";

export class Office365EventListConfiguration extends ListConfiguration {

    // Filter
    private user?: User;
    private deadlineDateMin?: Date;
    private deadlineDateMax?: Date;
    private deadlineValidator: (start: Date, end: Date) => boolean;

    // Limit & Order
    private orderBy: string[][] = [['created', 'desc'], ['updated', 'desc']];

    private dataSource: Office365FetcherRequest;

    constructor() {
        super();
    }

    public validate(item: Office365Event): boolean {
        const debug = false;
        if (this.getUser()) {
            if (item.user_id != this.user.id) {
                return false;
            }
        }
        if (this.getDeadlineDateMin()) {
            if (item.getStartDate().getTime() < this.getDeadlineDateMin().getTime()) {
                return false;
            }
        }
        if (this.getDeadlineDateMax()) {
            if (item.getEndDate().getTime() > this.getDeadlineDateMax().getTime()) {
                return false;
            }
        }
        if (this.getDeadlineValidator()) {
            if (item.start == null || item.end == null || !this.getDeadlineValidator()(item.getStartDate(), item.getEndDate())) {
                if (debug) {
                    console.warn('ignored, cause of deadline validator');
                }
                return false;
            }
        }
        return true;
    }

    // <editor-fold desc="Data Listeners">

    protected setupDataSource() {
        if (this.getDataSource()) {
            this.getDataSource().subscribe((office365Events: Office365Event[], count: number) => {
                const items = office365Events.map(office365Event => this.createCard(office365Event));
                this.onAddItemsEvent.emit({items: items});
                this.setLastItemCount(items.length);
                this.onCountEvent.emit({count: count});
            });
        }
    }

    private createCard(item: Office365Event): CardItem<Office365Event> {
        return new CardItem<Office365Event>(item);
    }

    // </editor-fold>

    // <editor-fold desc="Get / Set">

    public getOrderBy(): string[][] {
        return this.orderBy;
    }

    public setOrderBy(value: string[][]): Office365EventListConfiguration {
        this.orderBy = value;
        return this;
    }

    public getLimit(): number {
        return super.getLimit();
    }

    public setLimit(value: number): Office365EventListConfiguration {
        super.setLimit(value);
        return this;
    }

    public getUser(): User {
        return this.user;
    }

    public setUser(value: User): Office365EventListConfiguration {
        this.user = value;
        return this;
    }

    public getDeadlineDateMin(): Date {
        return this.deadlineDateMin;
    }

    public setDeadlineDateMin(value: Date): Office365EventListConfiguration {
        this.deadlineDateMin = value;
        return this;
    }

    public getDeadlineDateMax(): Date {
        return this.deadlineDateMax;
    }

    public setDeadlineDateMax(value: Date): Office365EventListConfiguration {
        this.deadlineDateMax = value;
        return this;
    }

    public getDataSource(): Office365FetcherRequest {
        return this.dataSource;
    }

    public setDataSource(value: Office365FetcherRequest): Office365EventListConfiguration {
        this.dataSource = value;
        this.setupDataSource();
        return this;
    }

    public setUseGlobalSearchFilter(value: boolean): Office365EventListConfiguration {
        super.setUseGlobalSearchFilter(value);
        return this;
    }

    public getDeadlineValidator(): (start: Date, end: Date) => boolean {
        return this.deadlineValidator;
    }

    public setDeadlineValidator(value: ((start: Date, end: Date) => boolean)): Office365EventListConfiguration {
        this.deadlineValidator = value;
        return this;
    }

    // </editor-fold>

}
