/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ProjectStatusTypeDefinition,} from './definitions/ProjectStatusTypeDefinition';

export class ProjectStatusType extends ProjectStatusTypeDefinition {

    constructor(json?: any) {

        super(json);
    }

}
