import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {
    TaskListWithoutMilestoneColumn
} from "@app/pages/displays/display-projects/Columns/TaskListWithoutMilestoneColumn";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {StatusTypes, TaskStatusTypes, TaskUserTypes} from "@app/constants";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {Deadline} from "@app/core/models";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVListBinding} from "@app/export/csv/CSVListBinding";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";

export class TaskListWithoutMilestoneCell extends GenericCell {

    public listConfiguration = new TaskListConfiguration();
    public csvListBinding = new CSVListBinding();

    constructor(row: ProjectsDisplayRow, column: TaskListWithoutMilestoneColumn) {
        super(row, column);

        this.listConfiguration
            //.setLimit(2)
            .setLimit(ListConfiguration.SmartLimit)
            .setDepartment(row.department)
            // .setOrderBy([['main_status.status_id', 'desc'], ['tasks_deadline.deadline.date', 'null'], ['tasks_deadline.deadline.date', 'asc'], ['title', 'asc']])
            // .setArchived(false) https://podio.com/klartboard/softwareudvikling/apps/stories/items/695
            .setShowProjectMiniCard(false)
            .setAcceptanceStatus(null)
            .setProject(row.project.item)
            .setTaskTypeIds(column.getTaskTypes())
            .setHasMilestones(false)
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                // Defaults
                new TaskUserPresetGenerator(TaskUserTypes.Creator, AppInjector.getInjector().get(UsersService).user.id),
                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                new TaskUseStatusRulesPresetGenerator(true),

                new TaskProjectPresetGenerator(row.project.item.id),
                ...row.project.item.departments?.map(department => new TaskDepartmentPresetGenerator(department.id)) ?? [],
                new TaskUserPresetGenerator(TaskUserTypes.Participant, AppInjector.getInjector().get(UsersService).user.id, Deadline.Today()),
            ]);
    }

    public exportCSV(secondary?: any): CSVCellValue {
        return this.csvListBinding.export();
    }

}
