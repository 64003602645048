import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {ProjectsService} from '@app/services/projects.service';
import {ApiFilter} from '@app/http/APIFilter';
import {TasksService} from '@app/services/tasks.service';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {MilestonesService} from '@app/services/milestones.service';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {CardConfiguration} from '@app/shared/_ui/cards/CardConfiguration';
import {TodosService} from '@app/services/todos.service';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {ItemTypes, ProjectDeadlineTypes, TaskDeadlineTypes} from '@app/constants';
import {CardTaskConfiguration} from '@app/shared/_ui/cards/medium/card-task/card-task-configuration';
import {CardProjectConfiguration} from '@app/shared/_ui/cards/medium/card-project/card-project-configuration';
import {Project} from '@app/core/models/Project';
import {Task} from '@app/core/models';
import {Milestone} from '@app/core/models/Milestone';
import {CardMilestoneConfiguration} from '@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration';

@Component({
    selector: 'app-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSearchComponent extends BaseDisplayComponent implements OnInit {

    @Input() listMode = false;

    placeholder: string = '';
    result: CardItem[] = [];
    public searchValue: string;
    txtQueryChanged: Subject<string> = new Subject<string>();

    constructor(private projectsService: ProjectsService,
                private taskService: TasksService,
                private milestoneService: MilestonesService,
                private todosService: TodosService,
                private _cdr: ChangeDetectorRef,
    ) {
        super();
        this._cdr = _cdr;
    }

    ngOnInit() {
        this.placeholder = this.translateService.instant('_ui_search') + '...';
        if(navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1)
            this.placeholder = 'Find...';

        this.isLoading = false;

        this.txtQueryChanged
            .pipe(debounceTime(1000), distinctUntilChanged()) // wait 1 sec after the last event before emitting last event &&  only emit if value is different from previous value
            .subscribe((value: string) => {
                this.search();
            });
    }

    reset() {
        this.searchValue = '';
    }

    changed(query: string) {
        if(query !== ''){
            this.isLoading = true;
        }
        this._cdr.markForCheck();
        this._cdr.detectChanges();
        this.txtQueryChanged.next(query);

    }

    search(): any[] {
        if(this.searchValue == '') return [];
        this.result = null;
        this.isLoading = true;

        let apiFilter = new ApiFilter();
        apiFilter.whereNot('title', '');
        let projectsSearch = this.projectsService.search(this.searchValue, apiFilter, false);

        apiFilter = new ApiFilter();
        apiFilter.whereNot('title', '');
        let tasksSearch = this.taskService.search(this.searchValue, apiFilter);

        let milestoneSearch = this.milestoneService.search(this.searchValue);
        let todoSearch = this.todosService.search(this.searchValue);

        forkJoin([projectsSearch, tasksSearch, milestoneSearch, todoSearch]).pipe(
            map(([projectsSearch, tasksSearch, milestoneSearch, todoSearch]) => {

                let mergedArray: AnyItem[] = []
                    .concat(tasksSearch)
                    .concat(projectsSearch)
                    .concat(milestoneSearch)
                    .concat(todoSearch)
                    .map((value, index) => {
                        if(!value['title'] && value['name']) value['title'] = value['name'];
                        return value;
                    })
                    .filter(value => {
                        return value.title != '';
                    })
                    .sort((a, b) => {
                        const valA = this.Helpers.getDeadline(a);
                        const valB = this.Helpers.getDeadline(b);

                        if(valA && valB && valA.getDate() && valB.getDate()) {
                            const timeA:number = valA.getDate().getTime();
                            const timeB:number = valB.getDate().getTime();
                            return timeA < timeB ? 1 : timeB < timeA ? -1 : 0;
                        }
                    });

                return mergedArray.map(anyItem =>{

                        let config: CardConfiguration;
                        switch(anyItem.constructor.name) {
                            case ItemTypes.Task:
                                const task:Task = anyItem as Task;
                                config = new CardTaskConfiguration(null, task.findTasksDeadlineByType(TaskDeadlineTypes.Normal), null);
                                break;
                            case ItemTypes.Milestone:
                                const milestone: Milestone = anyItem as Milestone;
                                config = new CardMilestoneConfiguration(true);
                                break;
                            case ItemTypes.Project:
                                const project:Project = anyItem as Project;
                                config = new CardProjectConfiguration(null, project.findProjectsDeadlineByType(ProjectDeadlineTypes.Normal), null, true);

                                break;
                            default:
                                config = new CardConfiguration();
                                break;
                        }

                        return new CardItem(anyItem, config);
                    })




                return this.result;
            })).subscribe(response => {
                console.log('complete : ', response);
                this.result = [... response];
                this.isLoading = false;
                this._cdr.markForCheck();
                this._cdr.detectChanges();

        });

    }
}
