import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {DatepickerModule} from "@app/shared/_forms/datepicker/Datepicker.module";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {
    StatusListEditorComponent
} from "@app/editor/quick-editor/editors/generic/status-list-editor/status-list-editor.component";
import {StatusSelectorModule} from "@app/shared/_forms/status-selector/StatusSelector.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        DatepickerModule,
        FormsModule,
        StatusSelectorModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        StatusListEditorComponent,
    ],
    exports: [
        StatusListEditorComponent

    ],
    providers: [

    ],
})
export class StatusListEditorModule {}
