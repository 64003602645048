import {PageComponent} from "@app/pages/page.component";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from "@angular/core";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-top/Filters";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {Project} from "@app/core/models";
import {Api} from "@app/core/http/Api/Api";
import {Subscription} from "rxjs";
import {ProjectsService} from "@app/services/projects.service";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {
    AppointmentListColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/AppointmentListColumnType";
import {
    ProjectCalculatedFieldListColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/ProjectCalculatedFieldListColumnType";
import {
    ProjectCardColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/ProjectCardColumnType";
import {
    ProjectCategoryListColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/ProjectCategoryListColumnType";
import {
    ProjectDeadlineDifferenceColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/ProjectDeadlineDifferenceColumnType";
import {
    ProjectDeadlineListColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/ProjectDeadlineListColumnType";
import {
    ProjectEstimateListColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/ProjectEstimateListColumnType";
import {
    ProjectNoteColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/ProjectNoteColumnType";
import {
    ProjectPhaseColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/ProjectPhaseColumnType";
import {
    ProjectPhaseDateColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/ProjectPhaseDateColumnType";
import {
    ProjectPhaseListColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/ProjectPhaseListColumnType";
import {
    ProjectResponsibleColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/ProjectResponsibleColumnType";
import {
    ProjectStatusListColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/ProjectStatusListColumnType";
import {
    ProjectUserListColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/ProjectUserListColumnType";
import {
    TaskListAllColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/TaskListAllColumnType";
import {
    TaskListOpenColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/TaskListOpenColumnType";
import {
    TaskListWithoutDeadlineColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/TaskListWithoutDeadlineColumnType";
import {
    TaskListWithoutMilestoneColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/TaskListWithoutMilestoneColumnType";
import {
    TodoListColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/TodoListColumnType";
import {
    YearWheelColumnType
} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes/YearWheelColumnType";
import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {TemplateTypes} from "@app/pages/displays/display-project-details/subdisplay-top/TemplateTypes";
import {
    TaskListOpenColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/TaskListOpenColumn";
import {ColumnTypes} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypes";
import {TaskListAllColumn} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/TaskListAllColumn";
import {
    TaskListWithoutMilestoneColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/TaskListWithoutMilestoneColumn";
import {
    TaskListWithoutDeadlineColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/TaskListWithoutDeadlineColumn";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {DataFetcherCollection} from "@app/core/DataFetchers/DataFetcherCollection";
import {YearWheelDisplayOptions} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration";
import {YearWheelColumn} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/YearWheelColumn";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

@Component({
    selector: 'app-display-project-details-top',
    templateUrl: './display-project-details-top.component.html',
    styleUrls: ['./display-project-details-top.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayProjectDetailsTopComponent extends PageComponent implements OnInit, OnChanges {
    protected filtersSettings = new Filters();
    protected columnController = new ColumnController();

    // Bindings to parent
    @Input() displayId: number;
    @Input() projectId: number;
    @Input() isOpenTasksDragEnabled: boolean;
    @Input() isTaskWithoutDeadlineListDragEnabled: boolean;
    @Input() onReloadEventEmitter: EventEmitter<any>;
    @Output() onShellPageDataChangeEvent = new EventEmitter<ShellPageData>();

    // Bindings to view
    public templateTypes = new TemplateTypes();
    public tableColumns: GenericTableColumn[] = [];
    public rows: Row[];
    public dataFetcherCollection = new DataFetcherCollection();

    // UI: DataTable
    @ViewChild('headerTemplate', {static: true}) headerTemplate: TemplateRef<any>;
    @ViewChild('headerTemplateYearWheel', {static: true}) headerTemplateYearWheel: TemplateRef<any>;
    @ViewChild('cellProjectCardTemplate', {static: true}) cellProjectCardTemplate: TemplateRef<any>;
    @ViewChild('cellResponsibleTemplate', {static: true}) cellResponsibleTemplate: TemplateRef<any>;
    @ViewChild('cellUsersTemplate', {static: true}) cellUsersTemplate: TemplateRef<any>;
    @ViewChild('cellDeadlinesTemplate', {static: true}) cellDeadlinesTemplate: TemplateRef<any>;
    @ViewChild('cellEstimatesTemplate', {static: true}) cellEstimatesTemplate: TemplateRef<any>;
    @ViewChild('cellCalculatedFieldsTemplate', {static: true}) cellCalculatedFieldsTemplate: TemplateRef<any>;
    @ViewChild('cellDateDifferenceTemplate', {static: true}) cellDateDifferenceTemplate: TemplateRef<any>;
    @ViewChild('cellNotesTemplate', {static: true}) cellNotesTemplate: TemplateRef<any>;
    @ViewChild('cellCategoriesTemplate', {static: true}) cellCategoriesTemplate: TemplateRef<any>;
    @ViewChild('cellCurrentPhaseTemplate', {static: true}) cellCurrentPhaseTemplate: TemplateRef<any>;
    @ViewChild('cellCurrentPhaseDateTemplate', {static: true}) cellCurrentPhaseDateTemplate: TemplateRef<any>;
    @ViewChild('cellPhasesTemplate', {static: true}) cellPhasesTemplate: TemplateRef<any>;
    @ViewChild('cellYearWheelTemplate', {static: true}) cellYearWheelTemplate: TemplateRef<any>;
    @ViewChild('cellTodosTemplate', {static: true}) cellTodosTemplate: TemplateRef<any>;
    @ViewChild('cellAppointmentsTemplate', {static: true}) cellAppointmentsTemplate: TemplateRef<any>;
    @ViewChild('cellStatusesTemplate', {static: true}) cellStatusesTemplate: TemplateRef<any>;
    @ViewChild('cellTaskWithoutMilestoneListTemplate', {static: true}) cellTaskWithoutMilestoneListTemplate: TemplateRef<any>;
    @ViewChild('cellTaskListTemplate', {static: true}) cellTaskListTemplate: TemplateRef<any>;
    @ViewChild('cellOpenTaskListTemplate', {static: true}) cellOpenTaskListTemplate: TemplateRef<any>;
    @ViewChild('cellTaskWithoutDeadlineListTemplate', {static: true}) cellTaskWithoutDeadlineListTemplate: TemplateRef<any>;

    constructor(private cd: ChangeDetectorRef,
                private projectsService: ProjectsService) {
        super();
        this.cdr = cd;
        this.isMainDisplay = false;
    }

    ngOnInit() {
        this.buildColumns();
        super.ngOnInit();

        if (this.onReloadEventEmitter) {
            this.subscribe(this.onReloadEventEmitter.subscribe(() => this.reload()));
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        if (changes['projectId']) {
            if (!this.initialized) {
                this.initialize();
            }
        }
        if (changes['isOpenTasksDragEnabled']) {
            this.rows?.forEach(row => {
                this.columnController.getColumns<TaskListOpenColumn>(ColumnTypes.TaskListOpen).forEach(column => {
                    column.getCell(row).listConfiguration.setDraggable(this.isOpenTasksDragEnabled);
                });
                this.detectChanges();
            });
        }
    }

    // <editor-fold desc="View Actions">

    public reload() {
        this.loadData();
    }

    public columnSortChange(tableColumn: BaseTableColumn) {
        if (tableColumn.column.implementsSorting) {
            const sortableColumn = tableColumn.column as unknown as SortableColumnInterface;
            this.rows.forEach(row => {
                sortableColumn.applyRowSort(row, tableColumn);
            });
        }

        if (tableColumn.column.implementsDataFetching) {
            const column = tableColumn.column as unknown as ColumnDataFetcherInterface;
            column.getDataFetchers().forEach(fetcher => fetcher.execute());
        }
    }

    public onYearWheelUnitChanged(unit: string) {
        this.rows?.forEach(row => {
            this.columnController.getColumns<YearWheelColumn>(ColumnTypes.YearWheel).forEach(column => {
                const yearWheelConfiguration = column.getCell(row).yearWheelConfiguration;
                if (yearWheelConfiguration.validate()) {
                    yearWheelConfiguration.setPeriodUnit(unit);
                }
                column.dataFetcher.execute();
            });
        });
    }

    public onYearWheelDateChanged(period: { start: Date; end: Date }) {
        this.rows?.forEach(row => {
            this.columnController.getColumns<YearWheelColumn>(ColumnTypes.YearWheel).forEach(column => {
                const yearWheelConfiguration = column.getCell(row).yearWheelConfiguration;
                yearWheelConfiguration
                    .setPeriodUnit(column.yearWheelDisplayOptions.unit)
                    .setStart(period.start)
                    .setEnd(period.end);
                column.dataFetcher.execute();
            });
        });
    }

    public onYearWheelOptionsChanged(value: YearWheelDisplayOptions) {
        this.rows?.forEach(row => {
            this.columnController.getColumns<YearWheelColumn>(ColumnTypes.YearWheel).forEach(column => {
                const yearWheelConfiguration = column.getCell(row).yearWheelConfiguration;
                yearWheelConfiguration.setDisplayOptions(value);
                column.dataFetcher.execute();
            });
        });
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    private apiSubscription: Subscription;

    private loadData() {
        if (!this.projectId) {
            console.warn('missing project : ', this.projectId);
            return;
        }
        this.apiSubscription?.unsubscribe();
        this.dataFetcherCollection.cancel();

        this.apiSubscription = Api.projects().getById(this.projectId)
            .find(projects => {
                if (projects.length == 1) {
                    const project = projects[0];

                    this.projectsService.getProjectType(project.project_type_id, projectType => {
                        project.project_type = projectType;

                        this.columnController.getVisibleColumns()
                            .filter(column => column.implementsDataFetching)
                            .forEach(column => {
                                this.dataFetcherCollection.add((column as unknown as ColumnDataFetcherInterface).getDataFetchers());
                            });
                        this.dataFetcherCollection.clearFetchers();

                        const rows = [this.createRow(project)];

                        this.dataFetcherCollection.execute(() => {
                            this.rows = rows;
                            this.detectChanges();
                        });
                    });
                }
            });
    }

    private createRow(project: Project): Row {
        const row = new Row(project);

        this.columnController.getAllColumns().forEach(column => {
            row.addCell(column.createCell(row));
        });

        this.columnController.getColumns<TaskListOpenColumn>(ColumnTypes.TaskListOpen).forEach(column => {
            column.getCell(row).listConfiguration.setDraggable(this.isOpenTasksDragEnabled);
        });

        return row;
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    protected initializeShellPageData() {
        this.shellPageData.displayFilterExtra = JSON.stringify({
            projectId: this.projectId
        });
    }

    private buildColumns() {
        this.columnController.addColumnTypes([
            new AppointmentListColumnType(this.cellAppointmentsTemplate, this.headerTemplate),
            new ProjectCalculatedFieldListColumnType(this.cellCalculatedFieldsTemplate, this.headerTemplate),
            new ProjectCardColumnType(this.cellProjectCardTemplate, this.headerTemplate),
            new ProjectCategoryListColumnType(this.cellCategoriesTemplate, this.headerTemplate),
            new ProjectDeadlineDifferenceColumnType(this.cellDateDifferenceTemplate, this.headerTemplate),
            new ProjectDeadlineListColumnType(this.cellDeadlinesTemplate, this.headerTemplate),
            new ProjectEstimateListColumnType(this.cellEstimatesTemplate, this.headerTemplate),
            new ProjectNoteColumnType(this.cellNotesTemplate, this.headerTemplate),
            new ProjectPhaseColumnType(this.cellCurrentPhaseTemplate, this.headerTemplate),
            new ProjectPhaseDateColumnType(this.cellCurrentPhaseDateTemplate, this.headerTemplate),
            new ProjectPhaseListColumnType(this.cellPhasesTemplate, this.headerTemplate),
            new ProjectResponsibleColumnType(this.cellResponsibleTemplate, this.headerTemplate),
            new ProjectStatusListColumnType(this.cellStatusesTemplate, this.headerTemplate),
            new ProjectUserListColumnType(this.cellUsersTemplate, this.headerTemplate),
            new TaskListAllColumnType(this.cellTaskListTemplate, this.headerTemplate),
            new TaskListOpenColumnType(this.cellOpenTaskListTemplate, this.headerTemplate),
            new TaskListWithoutDeadlineColumnType(this.cellTaskWithoutDeadlineListTemplate, this.headerTemplate),
            new TaskListWithoutMilestoneColumnType(this.cellTaskWithoutMilestoneListTemplate, this.headerTemplate),
            new TodoListColumnType(this.cellTodosTemplate, this.headerTemplate),
            new YearWheelColumnType(this.cellYearWheelTemplate, this.headerTemplateYearWheel),
        ]);
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();

        this.columnController.loadColumns(this.displayId, () => {
            this.onShellPageDataChangeEvent.emit(this.shellPageData);
            this.initialize();
        });
    }

    private initialize() {
        if (!this.projectId) {
            return;
        }

        this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
            this.tableColumns = this.columnController.getVisibleTableColumns() as GenericTableColumn[];
            this.loadData();
        }));

        this.subscribe(this.shellFilterGroup.onActiveFilterChangeEventSubscribe(filter => {
            this.columnController.getColumns<YearWheelColumn>(ColumnTypes.YearWheel)
                .forEach(column => column.setPeriod(
                    filter.getYearWheelPeriodStart(this.filtersSettings),
                    filter.getYearWheelPeriodEnd(this.filtersSettings)
                ));
            this.loadData();
        }));

        this.setupColumnSortItems();

        this.initialized = true;
    }

    private setupColumnSortItems() {
        this.filtersSettings.getEditorConfig(this.display, config => {

            this.columnController.getColumns<TaskListAllColumn>(ColumnTypes.TaskListAll).forEach(column => {
                column.getTableColumns().forEach(tableColumn => {
                    tableColumn.sortItems = [
                        PageColumnSort.CreateWithSortId(Filters.SortTasksTitle),
                        ...config.taskDeadlineTypes.map(deadlineType => PageColumnSort.CreateWithSortId(
                            Filters.SortTasksDeadlineGenerator(deadlineType)
                        )),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksPlanningDate),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksStatus),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksStars),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksHands),
                        ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                            Filters.SortTasksCategoryTypeGenerator(categoryType)
                        )),
                    ];
                });
            });

            this.columnController.getColumns<TaskListWithoutMilestoneColumn>(ColumnTypes.TaskListWithoutMilestone).forEach(column => {
                column.getTableColumns().forEach(tableColumn => {
                    tableColumn.sortItems = [
                        PageColumnSort.CreateWithSortId(Filters.SortTasksTitle),
                        ...config.taskDeadlineTypes.map(deadlineType => PageColumnSort.CreateWithSortId(
                            Filters.SortTasksDeadlineGenerator(deadlineType)
                        )),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksPlanningDate),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksStatus),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksStars),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksHands),
                        ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                            Filters.SortTasksCategoryTypeGenerator(categoryType)
                        )),
                    ];
                });
            });

            this.columnController.getColumns<TaskListOpenColumn>(ColumnTypes.TaskListOpen).forEach(column => {
                column.getTableColumns().forEach(tableColumn => {
                    tableColumn.sortItems = [
                        PageColumnSort.CreateWithSortId(Filters.SortTasksTitle),
                        ...config.taskDeadlineTypes.map(deadlineType => PageColumnSort.CreateWithSortId(
                            Filters.SortTasksDeadlineGenerator(deadlineType)
                        )),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksStatus),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksStars),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksHands),
                        ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                            Filters.SortTasksCategoryTypeGenerator(categoryType)
                        )),
                    ];
                });
            });

            this.columnController.getColumns<TaskListWithoutDeadlineColumn>(ColumnTypes.TaskListWithoutDeadline).forEach(column => {
                column.getTableColumns().forEach(tableColumn => {
                    tableColumn.sortItems = [
                        PageColumnSort.CreateWithSortId(Filters.SortTasksTitle),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksPlanningDate),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksStatus),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksStars),
                        PageColumnSort.CreateWithSortId(Filters.SortTasksHands),
                        ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                            Filters.SortTasksCategoryTypeGenerator(categoryType)
                        )),
                    ];
                });
            });
        });
    }

    // </editor-fold>

}
