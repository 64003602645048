<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title" [innerHTML]="title"></h4>

        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <div class="modal-body" [innerHTML]="message" *ngIf="false"></div>

    <ng-container *ngIf="milestone">
        <div class="p-2" *ngIf="milestone">

            <ng-container *ngIf="!milestone.tasks">
                <div class="p-2" *ngIf="true">
                    <p class="mb-0"
                       [innerHTML]="('_ui_copy_item' | translate: {item: milestone.title})"
                    ></p>
                </div>
            </ng-container>

            <ng-container *ngIf="milestone.tasks?.length > 0">
                <div class="p-2 pb-0" *ngIf="true">
                    <p class="mb-0" *ngIf="false">{{'_ui_copy_project_description' | translate}}</p>
                    <p class="mb-0"
                       [innerHTML]="('_ui_copy_item' | translate: {item: milestone.title})"
                    ></p>
                </div>
                <div class="ps-3 pe-3 border-top pt-3" *ngIf="milestone?.tasks?.length">
                <div class="d-flex align-items-center justify-content-start no-wrap w-100  text-black-50 text-small">
                    <i class="fal fa-info-circle me-1" aria-hidden="true"></i>
                    <p class="m-0 p-0" [innerHTML]="'_ui_has_related_content' | translate:{item: milestone.title} "></p>
                </div>

                <ul class="list-unstyled mt-2">
                    <ng-container *ngIf="milestone.tasks && tasksTaskTypes">
                        <ng-container *ngFor="let taskType of tasksTaskTypes">
                            <li *ngIf="taskType.tasks.length > 0" class="d-flex align-items-center">
                        <span
                            [class.text-success]="copyTasks"
                            [class.text-muted]="!copyTasks">
                            <i class="fal trash me-1 fa-copy"></i>
                            {{taskType.taskType.name}}</span>
                                <span class="badge rounded-pill badge-primary ms-auto"
                                      [class.bg-secondary]="!copyTasks"
                                      [class.bg-success]="copyTasks"

                                >{{milestone.tasks?.length}}</span>
                            </li>

                        </ng-container>
                    </ng-container>
                </ul>

                <ul class="list-unstyled list-group mb-3 mt-3">


                    <li (click)="copyTasks = !copyTasks" class="cursor-pointer"
                        *ngIf="milestone.tasks">
                        <div class="d-flex flex-row justify-content-start align-items-center">
                            <i class="fa fa-check-circle text-success" *ngIf="copyTasks "></i>
                            <i class="fal fa-circle text-black-50" *ngIf="!copyTasks"></i>
                            <span class="ms-1">
                                <span [innerHTML]="('_ui_sub_related_content' | translate)"></span>
                        </span>
                        </div>
                    </li>
                </ul>

            </div>
            </ng-container>

        </div>
    </ng-container>

    <div class="d-flex align-items-center justify-content-center pt-2 pb-2" *ngIf="!milestone">
        <app-loading-indicator [showLogo]="false"></app-loading-indicator>
    </div>

    <div class="modal-footer">
        <button type="button" role="button" class="btn btn-secondary ellipsis flex-shrink-0" (click)="decline()"
                *ngIf="btnCancelText">{{ btnCancelText }}</button>
        <button type="button" role="button" class="btn ellipsis {{primaryClass}}" ngbAutofocus
                (click)="accept()">{{ title }}</button>
    </div>
</div>
