<div class="d-flex justify-content-start align-items-center cursor-pointer -debug-wrap ellipsis"
     container="body"
     [autoClose]="'outside'"
     (click)="popover.toggle(); render(); $event.stopImmediatePropagation()"

     *ngIf="users && users.length > 0"
     [openDelay]="0"
     [animation]="true"
     [popoverClass]="'zindex-top'"
     [ngbPopover]="popoverContent"
     #popover="ngbPopover"
     (shown)="recordShown()"
     (hidden)="recordHidden()"
     [placement]="['auto']"
>

    <i class="fal fa-user-circle me-1" aria-hidden="true"
       *ngIf="icon && !acceptanceStatus && !showAcceptanceStatus"></i>

    <span class="badge me-1" *ngIf="showAcceptanceStatus"
          [ngbTooltip]="('_ui_acceptance_status' | translate)"
          [class.bg-info]="!acceptanceStatus || acceptanceStatus == Constants.TasksUserAcceptanceStatus.New"
          [class.bg-success]="acceptanceStatus == Constants.TasksUserAcceptanceStatus.Approved"
          [class.bg-danger]="acceptanceStatus == Constants.TasksUserAcceptanceStatus.Declined"
    >
                            <span class="fa fa-bell -me-1"></span>
        <!--                            {{tu.acceptance_status}}-->
                        </span>


    <span *ngIf="initials" class="ellipsis flex-fill names d-mobile-none">{{initialsString}}</span>
    <span *ngIf="!initials"
          class="ellipsis flex-fill  names d-mobile-none">{{fullName ? fullNameString : firstNameString }}</span>

    <span *ngIf="users.length > limit" class=" d-mobile-none"> +{{users.length - limit}}</span>


    <ng-template #popoverContent>
        <!-- standard user list -->
        <div class="popover-container emoji-popover"
             *ngIf="(!showAcceptanceStatus || showAcceptanceStatus && !tasksUsers) && !(tasksUsers)"
        >
            <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>
            <ul *ngIf="users && users.length > 0" class="list-unstyled d-flex flex-column m-0 user-list scroll-y">
                <li *ngIf="userType?.language_key"><strong>{{userType.language_key | translate}}</strong></li>
                <li *ngFor="let u of users" class="d-flex align-items-center">

                    <app-user-with-icon [navigateToDashboard]="true" [model]="u" [showTooltip]="false"
                                        class="me-1"></app-user-with-icon>
                    <app-user-with-icon [navigateToDashboard]="true" [model]="u" [showInitials]="true"
                                        [showIcon]="false" [displayStrong]="true" class="ms-auto"></app-user-with-icon>
                </li>
            </ul>
        </div>

        <!-- tasks-users -->
        <ng-container *ngIf="tasksUsers">
            <div class="popover-container">
                <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>

                <ul class="list-unstyled d-flex flex-column m-0 user-list scroll-y">
                    <!--                        <li>{{'_ui_partially_completed' | translate}}</li>-->
                    <li *ngIf="userType?.language_key"><strong>{{userType.language_key | translate}}</strong></li>
                    <li *ngFor="let tu of tasksUsers">
                        <div class="ps-1 pe-1 d-flex align-items-center justify-content-start">

                            <app-toggle-item
                                [interactive]="true"
                                class="me-1"
                                [class.text-success]="tu.isArchived"
                                [class.text-muted]="!tu.isArchived"
                                [active]="tu.isArchived"
                                [icon]="'fa-check-circle'"
                                [iconInactive]="'fa-circle'"
                                [ngbTooltip]="tu.isArchived ? translateService.instant('_ui_reopen') : translateService.instant('_ui_partially_completed')"
                                (onToggle)="toggleCompleted(tu)"
                            />

                            <ng-container *ngIf="showAcceptanceStatus">
                                    <span class="badge  me-1"
                                          [ngbTooltip]="('_ui_acceptance_status' | translate)"
                                          [class.bg-info]="!tu.acceptance_status || tu.acceptance_status == Constants.TasksUserAcceptanceStatus.New"
                                          [class.bg-success]="tu.acceptance_status == Constants.TasksUserAcceptanceStatus.Approved"
                                          [class.bg-danger]="tu.acceptance_status == Constants.TasksUserAcceptanceStatus.Declined"
                                    >
                                        <span class="fa fa-bell -me-1"></span><!-- {{tu.acceptance_status}}--></span>
                                <div class="fa fa-arrow-right me-1"></div>
                            </ng-container>

                            <app-user-with-icon
                                [showTooltip]="false"
                                [navigateToDashboard]="true"
                                [modelId]="tu.user_id" class="me-1"></app-user-with-icon>

                            <app-user-with-icon [navigateToDashboard]="true" [modelId]="tu.user_id"
                                                [showInitials]="true" [showIcon]="false" [displayStrong]="true"
                                                class="ms-auto"></app-user-with-icon>

                        </div>
                    </li>
                </ul>

            </div>
        </ng-container>
    </ng-template>

</div>
