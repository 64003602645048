/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TodoSettingDefinition} from './definitions/TodoSettingDefinition';

export class TodoSetting extends TodoSettingDefinition {

    constructor(json?: any) {
        super(json);
    }

}
