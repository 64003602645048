import {NgModule} from "@angular/core";
import {ProcessStepsComponent} from "@app/shared/_elements/process-steps/process-steps.component";
import {ProjectPhaseSearchModule} from "@app/shared/_elements/project-phase-search/ProjectPhaseSearch.module";
import {TranslateModule} from "@ngx-translate/core";
import {ProjectPhaseModule} from "@app/shared/_elements/process-steps/project-phase/ProjectPhase.module";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ProjectPhaseSearchModule,
        TranslateModule,
        ProjectPhaseModule,
        LoadingIndicatorModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProcessStepsComponent,
    ],
    exports: [
        ProcessStepsComponent

    ],
    providers: [

    ],
})
export class ProcessStepsModule {}
