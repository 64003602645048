<div class="d-flex align-items-center w-100"
     [class.rm-height]="ellipsis"
>
    <app-color-item [color]="color" class="me-1"></app-color-item>
    <div class="text-capitalize-firstletter ellipsis flex-shrink-1"
         [class.text-wrap]="!ellipsis"
         [ngbTooltip]="ellipsis? name : null"
    >
        {{name}}
    </div>
</div>
