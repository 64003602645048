import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {
    YearWheelColumnComponent
} from "@app/shared/_ui/columns/year-wheel/year-wheel-column/year-wheel-column.component";
import {ColumnSorterModule} from "@app/shared/_ui/column-sorter/ColumnSorter.module";
import {DateRangePickerModule} from "@app/shared/_elements/date-range-picker/DateRangePicker.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [
        CommonModule,
        ColumnSorterModule,
        DateRangePickerModule,
        NgxDatatableModule,
        ToggleItemModule,
        TranslateModule,
        NgbTooltip,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        YearWheelColumnComponent,
    ],
    exports: [
        YearWheelColumnComponent,
    ],
    providers: [

    ],
})
export class YearWheelColumnModule {}
