<p>
    {{'_ui_template_closing_days_description' | translate}}
</p>
<div class="d-flex  flex-row justify-content-between">
    <div class="me-1 flex-grow-1">
        <app-template-holiday-picker
            (removeItemEvent)="holidayRemove($event)"
            (addItemEvent)="holidayAdd($event)"
            (addAll)="holidayAddAll($event)"
            (removeAll)="holidayRemoveAll()"
            [selectedHolidays]="selectedHolidays"
        ></app-template-holiday-picker>
    </div>
    <div class=" flex-grow-1">
        <app-template-blocked-period-picker
            [milestonePlanId]="milestonePlan.id"
            (addItemEvent)="blockedPeriodAdd($event)"
            (removeItemEvent)="blockedPeriodRemove($event)"
        ></app-template-blocked-period-picker>

        <div class="div mt-1">
            <h5>{{'_admin_milestone_plan_weekends' | translate}}</h5>

            <ul class="list-unstyled list-group">
            <li class="list-group-item  d-flex align-items-center justify-content-start cursor-pointer"
                (click)="toggleWeekends()">

                <i class="fa fa-check-circle text-success" *ngIf="!milestonePlan.block_weekends"></i>
                <i class="fal fa-circle text-black-50" *ngIf="milestonePlan.block_weekends"></i>
                <span class="ms-1">{{('_admin_milestone_plan_weekends' | translate)}}</span>
            </li>
            </ul>
        </div>


    </div>
</div>

