<header class="shadow-sm header-element position-fixed w-100 d-flex" style="top: 0; left: 0; z-index: 1; height: 66px;">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark m-0 ps-1 pe-1 flex-grow-1">
        <div class="d-flex flex-row justify-content-between align-items-center w-100">
            <div class="image-wrap " style="margin: 0 auto; height: 48px;" >
                <img src="/assets/logo-light.svg" class="img-fluid" style=" height: 48px;"
                     alt="Klartboard">
            </div>
        </div>
    </nav>
</header>

<div class="default-loader justify-content-center"
     *ngIf="!isMobileVersion && !initialized && !displayUser || !isMobileVersion && (!activeDisplay && displayUser && displayUser.default_displays_department_id) && !showList">


    <div class="d-flex align-items-center flex-column border align-self-center">


        <h4 class="card-title text-center ">
            <div class="image-wrap" >

            </div>
        </h4>

        <div class="d-flex flex-row align-items-center mb-0 rounded p-2 mb-2">
            <app-loading-indicator class="me-2" [showLogo]="false"></app-loading-indicator>
            <span>{{'_ui_dashboard_finding_default_board' | translate}}</span>
        </div>

    </div>

</div>

<div class="container pt-2">

    <div class="container bg-light p-2 mb-2 rounded justify-content-center" *ngIf="activeDisplay"
         (click)="gotoDisplayId(displayUser.default_displays_department_id)">
        <div class="d-flex flex-row align-items-center mb-0  cursor-pointer">
            <div class="disabled d-flex w-100 align-items-center">
                <span class="fal ms-2 fa-check me-2 "></span>
                <span>{{activeDisplay.name}}
                    <ng-container
                        *ngIf="activeDepartmentDisplay">, {{activeDepartmentDisplay?.department?.name}}</ng-container></span>
                <span class="fal ms-auto fa-arrow-right "></span>
            </div>
        </div>
    </div>


    <div class="container mb-2 p-0 mt-2" *ngIf="isMobileVersion">
        <ul class="nav flex-column bg-light rounded">

            <li class="nav-item d-flex align-items-center border-bottom">
                <a class="nav-link align-items-center w-100 d-flex"
                   routerLink="/app/tasks/list/personal"
                   [routerLinkActive]="['is-active']"
                >
                    <i class="fal fa-rectangle-list me-1"></i>
                    <span> {{'_tasks' | translate}}</span>
                    <i class="fal fa-angle-right ms-auto"></i>
                </a>
            </li>
            <li class="nav-item d-flex align-items-center border-bottom">
                <a class="nav-link align-items-center w-100 d-flex"
                   routerLink="/app/projects/list/personal"
                   [routerLinkActive]="['is-active']"
                >
                    <i class="fal fa-rectangle-list me-1"></i>
                    <span> {{'_projects' | translate}}</span>

                    <i class="fal fa-angle-right ms-auto"></i>
                </a>
            </li>
            <li class="nav-item d-flex align-items-center">
                <a class="nav-link align-items-center w-100 d-flex"
                   routerLink="/app/milestones/list/personal"
                   [routerLinkActive]="['is-active']"
                >
                    <i class="fal fa-rectangle-list me-1"></i>
                    <span> {{'_milestones' | translate}}</span>
                    <i class="fal fa-angle-right ms-auto"></i>
                </a>
            </li>
        </ul>
    </div>


    <div class="p-0"
         *ngIf="!isMobileVersion && displayUser && initialized && displayReady && showDisplay || showList">

            <p *ngIf="false">{{'_ui_dashboard_welcome' | translate}}</p>

            <div class="alert"
                 *ngIf="!displays && initialized && displayUser">{{'_ui_dashboard_no_default_board' | translate}}
            </div>
            <div class="alert alert-info"
                 *ngIf="displayUser && displays && !displayUser.default_displays_department_id">
                <i class="fa fa-info-circle me-1" aria-hidden="true"></i> <span
            >{{'_ui_dashboard_default_setting' | translate}}</span>
            </div>

            <div class="alert alert-warning mt-2" *ngIf="displayUser && !displayUser.departments && displays">
                <i class="fa fa-exclamation-triangle me-1" aria-hidden="true"></i>
                <span>{{'_ui_dashboard_user_missing_department' | translate}}</span>
            </div>

            <div class="alert alert-info mt-2 d-flex flex-row justify-content-center align-items-center "
                 *ngIf="displayUser && displayUser.default_displays_department_id && !displays">
                <!--<i class="fa fa-info-circle me-1" aria-hidden="true"></i>-->
                <app-loading-indicator [showLogo]="false" class="me-1"></app-loading-indicator>
                <span>{{'_ui_loading' | translate}}</span>
            </div>

            <div class="alert alert-warning mt-2 d-flex align-items-center"
                 *ngIf="displayUser && displayUser.departments && (!displays || displays.length == 0) ">
                <div class="d-flex align-items-center">
                    <i class="fa fa-exclamation-triangle me-1" aria-hidden="true"></i>
                    <span>{{'_ui_dashboard_department_missing_settings' | translate}}</span>
                </div>

                <div class="d-flex ms-auto">
                    <button type="button" (click)="updateVersion()" class="btn btn-outline-secondary me-1"><span
                        class="fa fa-cookie me-1"></span>{{'_ui_reset_cookies' | translate}}</button>
                    <button type="button" class="btn btn-outline-warning"
                            (click)="logout()">{{'_admin_login' | translate}}</button>
                </div>
            </div>

        <div class="p-2 rounded bg-light scroll-y max-height-screen"
             *ngIf="displayUser && displays && displays.length > 0">
            <app-display-list
                [activeDisplay]="activeDisplay"
                [activeDepartment]="activeDepartmentDisplay?.department"
                [department]="null"
                [departmentSelection]="true"
                [displayHorizontal]="true"
                [limitToDepartment]="true"
                [navigate]="navigate"
                (itemSelected)="clickDisplayListener($event)"
                *ngIf="displayUser && displays"
                [displayUser]="displayUser"
                [showDepartment]="true" className="flex-column light no-wrap"></app-display-list>
        </div>


    </div>

</div>
