import {EventEmitter} from "@angular/core";
import {Estimate} from "@app/core/models";
import {debounce} from "lodash";
import {EditEstimateList} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EditEstimateList";

export class EstimateItem {
    public static TypeDefault = -1;

    public name: string;
    public id: number;
    public previousValue: number;

    public onRequiredChangeEvent = new EventEmitter<boolean>();
    public onValueChangedEvent = new EventEmitter<boolean>();

    public estimate?: Estimate;

    private debouncedSaved = debounce(() => this.onValueChangedEvent.emit(), 300);

    constructor(name: string, id: number) {
        this.name = name;
        this.id = id;
    }

    public onValueChanged() {
        this.debouncedSaved();
    }

    public getId(item: EditEstimateList, result: (typeId?: number) => void): void {
        if (this.id == EstimateItem.TypeDefault) {
            item.getDefaultEstimateTypeId(result);
        } else {
            result(this.id);
        }
    }

}
