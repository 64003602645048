import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {TaskPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/TaskPresetType";

export class TaskEstimatePresetGenerator implements CreatePresetGenerator {

    private readonly typeId?: number;
    private readonly typeIdHandler?: (typeId?: number) => number;

    private readonly value?: number;
    private readonly valueHandler?: (typeId?: number) => number;

    private readonly unitId?: number;
    private readonly unitIdHandler?: (typeId?: number) => number;

    constructor(
        typeIdOrHandler: number | ((typeId?: number) => number),
        valueOrHandler: number | ((typeId?: number) => number),
        unitIdOrHandler: number | ((typeId?: number) => number),
    ) {
        if (typeof typeIdOrHandler == "number") {
            this.typeId = typeIdOrHandler;
        } else {
            this.typeIdHandler = typeIdOrHandler;
        }
        if (typeof valueOrHandler == "number") {
            this.value = valueOrHandler;
        } else {
            this.valueHandler = valueOrHandler;
        }
        if (typeof unitIdOrHandler == "number") {
            this.unitId = unitIdOrHandler;
        } else {
            this.unitIdHandler = unitIdOrHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: TaskPresetTypes.Estimate,
            payload: {
                typeId: this.typeId ?? this.typeIdHandler(typeId),
                value: this.value ?? this.valueHandler(typeId),
                unitId: this.unitId ?? this.unitIdHandler(typeId),
            }
        };
    }

}
