import {NgModule} from "@angular/core";
import {DisplayKanbanComponent} from "@app/pages/displays/display-kanban/display-kanban.component";
import {ResizableModule} from "angular-resizable-element";
import {ColumnSorterModule} from "@app/shared/_ui/column-sorter/ColumnSorter.module";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {OnScreenFilterSelectorModule} from "@app/shared/_ui/on-screen-filter-selector/OnScreenFilterSelector.module";
import {TranslateModule} from "@ngx-translate/core";
import {ProjectListModule} from "@app/shared/_ui/lists/project-list/ProjectList.module";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {PriorityMatrixModule} from "@app/shared/_elements/priority-matrix/PriorityMatrix.module";
import {CommonModule} from "@angular/common";
import {ColorItemModule} from '@app/shared/_ui/color-item/ColorItem.module';

@NgModule({
    imports: [
        CommonModule,
        ResizableModule,
        ColumnSorterModule,
        NgbPopoverModule,
        OnScreenFilterSelectorModule,
        TranslateModule,
        NgbTooltipModule,
        ProjectListModule,
        LoadingIndicatorModule,
        PriorityMatrixModule,
        ColorItemModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DisplayKanbanComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class DisplayKanbanModule {}
