import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {ProjectEstimateListCell} from "@app/pages/displays/display-projects/Cells/ProjectEstimateListCell";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {Projects_ProjectEstimateTypesSettingValue, Projects_ProjectUserTypesSettingValue} from "@app/core/http/Api/Api";

export class ProjectEstimateListColumn extends GenericColumn<ProjectEstimateListCell> {

    public createCell(row: ProjectsDisplayRow): ProjectEstimateListCell {
        return new ProjectEstimateListCell(row, this);
    }

    public getProjectEstimateTypeIds(): number[] {
        return this.settings.get(ColumnTypeSettings.ProjectEstimateTypes)
            ?.getObject<Projects_ProjectEstimateTypesSettingValue>()
            ?.projectEstimateTypeIds ?? [];
    }

    public implementsCSVExport = true;

}
