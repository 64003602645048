import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {MilestonePresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/MilestonePresetType";

export class MilestoneUseStatusRulesPresetGenerator implements CreatePresetGenerator {

    private readonly useStatusRules?: boolean;
    private readonly useStatusRulesHandler?: () => boolean;

    constructor(useStatusRulesHandler: boolean | (() => boolean)) {
        if (typeof useStatusRulesHandler == "boolean") {
            this.useStatusRules = useStatusRulesHandler;
        } else {
            this.useStatusRulesHandler = useStatusRulesHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: MilestonePresetTypes.UseStatusRules,
            payload: {
                useStatusRules: this.useStatusRules ?? this.useStatusRulesHandler(),
            }
        };
    }

}
