<ul class="list-unstyled w-100 p-2">
    <!-- Tab: Settings -->
    <li *ngIf="view == Constants.MilestoneRowViews.Basic">

        <div class="form-group p-0 w-100 mb-0">
            <!--            <pre class="debug">{{milestoneTemplate.id}}</pre>-->
            <!--            <pre class="debug">template-milestone-row.compoenent.html : Constants.MilestoneRowViews.Basic</pre>-->

            <label for="title" class="d-flex align-items-center">
                <span >{{'_ui_milestoneplan_milestone_title' | translate}}</span>

                <i class="fal cursor-pointer ms-auto"
                   [ngbTooltip]="('_ui_add_deadline_rules' | translate)"
                   (click)="expanded = !expanded"
                   [class.fa-circle-arrow-up]="expanded"
                   [class.fa-circle-arrow-down]="!expanded"></i>
            </label>
            <input
                [(ngModel)]="milestoneTemplate.title"
                placeholder=""
                class="form-control"
                name="title"
                id="title"
                autocomplete="on"
                [autofocusAfterInit]="!milestoneTemplate.title"
                (blur)="saveTitle()"
            >
        </div>

        <ng-container *ngIf="expanded">
            <div>
                <div class="d-flex">
                    <label for="deadline_value">
                        <span >{{'_ui_milestoneplan_milestone_deadline' | translate}}</span>
                    </label>
                    <label>
                        <span >{{'_ui_milestoneplan_milestone_deadline_logic' | translate}}</span>
                    </label>
                </div>


                <div class="d-flex flex-row justify-content-between mb-0 align-items-center">
                    <input
                        [(ngModel)]="milestoneTemplate.deadline_value"
                        placeholder=""
                        class="form-control"
                        name="deadline_value"
                        id="deadline_value"
                        type="number"
                        (blur)="saveMilestoneTemplateDeadlineValue()"
                    >

                    <select
                        class="form-control ms-1 mb-0"
                        [(ngModel)]="milestoneTemplate.deadline_type"
                        (ngModelChange)="saveMilestoneTemplateDeadlineType()"
                    >
                        <option
                            *ngFor="let deadlineType of milestoneDeadlineTypes"
                            [value]="deadlineType.id">{{deadlineType.name | translate}}</option>
                    </select>

                </div>

            </div>

            <div>
                <label for="deadline_value">
                    <span >{{'_ui_milestoneplan_milestone_phase' | translate}}</span>
                </label>

                <div class="d-flex flex-row justify-content-between mb-0 align-items-center">
                    <select class="form-control ms-1 mb-0"
                            [(ngModel)]="milestoneTemplate.phase_id"
                            (ngModelChange)="saveMilestoneTemplatePhase()"
                    >
                        <option
                            [value]="null">-
                        </option>
                        <option
                            *ngFor="let phase of phases"
                            [value]="phase.id">{{phase.name | translate}}</option>
                    </select>

                </div>

            </div>
        </ng-container>


    </li>

    <li *ngIf="view != Constants.MilestoneRowViews.Basic" class="d-flex align-items-center -border-bottom pb-1">
        <div class="d-flex align-items-center w-100">


            <div class="d-flex flex-row align-items-center flex-grow-1">
                <div class="d-flex flex-grow-1 d-flex align-items-center">
                    <span [ngbTooltip]="('_milestone' | translate)">
                        <i class="fal fa-diamond me-1 flex-grow-1"></i>{{milestoneTemplate.title}}
                    </span>

                    <span class="ms-auto"
                          *ngIf="milestoneTemplate.phase">
                            <app-phase [phase]="milestoneTemplate.phase"
                                       class="d-flex align-items-center text-small"></app-phase>
                        </span>
                </div>

                <div class="d-flex flex-row text-small align-items-start justify-content-start ms-auto">
                    <span class="ms-1 -border p-1 -rounded"
                          *ngIf="milestoneTemplate.deadline_value && milestoneTemplate.deadline_type && this.milestoneDeadlineTypeMap">
                        {{milestoneTemplate.deadline_value}} {{getMappedMilestoneDeadlineType(milestoneTemplate)?.name | translate}}
                    </span>
                </div>
            </div>


            <span class="ms-auto pe-2 d-flex ps-2">
                <a
                    class="me-1"
                    *ngIf="view == Constants.MilestoneRowViews.Tasks && milestoneTemplate.task_templates && milestoneTemplate.task_templates.length > 0"
                    (click)="allExpanded = !allExpanded"
                >
                        <span>{{'_ui_add_deadline_rules' | translate}}</span>
                                    <i class="fal cursor-pointer ms-1"
                                       [class.fa-circle-arrow-up]="allExpanded"
                                       [class.fa-circle-arrow-down]="!allExpanded"></i>
                </a>

                <a (click)="createTask()" class="d-flex align-items-center"
                      *ngIf="view == Constants.MilestoneRowViews.Tasks"
                      [ngbTooltip]="('_global_create' | translate)+': '+('_task' | translate)">
                    <span>{{'_global_create' | translate}}</span>
                    <span class="ms-1 fal fa-plus-circle"></span>
                </a>

                <a (click)="createStatusRule()"
                      class="d-flex align-items-center" *ngIf="view == Constants.MilestoneRowViews.StatusRules"
                      [ngbTooltip]="('_global_create' | translate)+': '+('_status_rule_singular' | translate)">
                    <span>{{'_global_create' | translate}}</span>
                    <span class="ms-1 fal fa-plus-circle"></span>
                </a>
            </span>
        </div>
    </li>

    <!-- Tab: Tasks -->

    <li *ngIf="view == Constants.MilestoneRowViews.Tasks && milestoneTemplate.task_templates && milestoneTemplate.task_templates.length > 0"
        class=" pt-2">
        <!--        <pre class="debug">template-milestone-row.compoenent.html : Constants.MilestoneRowViews.Tasks</pre>-->
        <ul class=" list-unstyled">
            <li *ngFor="let taskTemplate of milestoneTemplate.task_templates" class="zebra bg-light">
                <app-template-task-row
                    [taskTemplate]="taskTemplate"
                    [expanded]="allExpanded"
                    (removeEvent)="removeTaskTemplate($event)"></app-template-task-row>
            </li>
        </ul>
    </li>


    <!-- extra opret række -->
    <li *ngIf="view != Constants.MilestoneRowViews.Basic && milestoneTemplate.task_templates && milestoneTemplate.task_templates.length > 0 " class="d-flex align-items-center -border-bottom pb-1">
        <div class="d-flex align-items-center w-100">

            <span class="ms-auto pe-2 d-flex ps-2">

                <a (click)="createTask()" class="d-flex align-items-center"
                      *ngIf="view == Constants.MilestoneRowViews.Tasks"
                      [ngbTooltip]="('_global_create' | translate)+': '+('_task' | translate)">
                    <span>{{'_global_create' | translate}}</span>
                    <span class="ms-1 fal fa-plus-circle"></span>
                </a>


            </span>
        </div>
    </li>


    <!-- Tab: Status rules -->
    <li *ngIf="view == Constants.MilestoneRowViews.StatusRules && milestoneTemplate.status_rules && milestoneTemplate.status_rules.length > 0"
        class=" pt-2">
        <!--        <pre class="debug">template-milestone-row.compoenent.html : Constants.MilestoneRowViews.StatusRules</pre>-->
        <ul class=" list-unstyled">
            <li *ngFor="let statusRule of milestoneTemplate.status_rules">
                <app-template-status-rule-row [statusRule]="statusRule"
                                             (removeEvent)="removeStatusRuleTemplate($event)"
                ></app-template-status-rule-row>
            </li>

        </ul>

    </li>

    <li *ngIf="view == Constants.MilestoneRowViews.Overview" class=" pt-2">

        <div
            class="w-100 d-flex align-items-center pb-2 mb-1 text-small"
        >
            <span class=" fal fa-tasks  me-1"></span>
            <span>{{'_ui_tasks' | translate}}</span>
        </div>

        <ul class="list-unstyled rounded list-group"
            cdkDropList
            [cdkDropListData]="milestoneTemplate"
            (cdkDropListDropped)="milestoneTemplateTaskDrop($event)"
        >
            <li *ngIf="milestoneTemplate?.task_templates?.length == 0"
                class="list-group-item d-flex align-items-center justify-content-center">
                <span
                    class="text-small text-black-50 text-uppercase">{{'_ui_milestoneplan_drag_tasks_description' | translate}}</span>
            </li>

            <li cdkDrag
                *ngFor="let taskTemplate of milestoneTemplate.task_templates"
                [cdkDragData]="taskTemplate"

                class="list-group-item d-flex align-items-start ">
                <span class=" fal fa-bars curser-drag me-1 pt-1"
                      [ngbTooltip]="('_ui_drag_to_change_order' | translate)"
                ></span>

                <div class="d-flex flex-row align-items-start flex-grow-1">

                    <div class="flex-grow-1">{{taskTemplate.title}}</div>

                    <div class="d-flex flex-row text-small align-items-start justify-content-start ms-auto">
                        <span class="ms-1 -border p-1 -rounded" *ngIf="taskTemplate.users">
                            <ul class="list-unstyled">
                                <li *ngFor="let user of taskTemplate.users">
                                    <app-user-with-icon [model]="user"></app-user-with-icon>
                                </li>
                            </ul>
                        </span>
                        <span class="ms-1 -border p-1 -rounded"
                              *ngIf="taskTemplate.deadline_value && taskTemplate.deadline_type && this.taskDeadlineTypeMap">
                            {{taskTemplate.deadline_value}} {{getMappedTaskDeadlineType(taskTemplate)?.name | translate}}
                        </span>


                    </div>
                </div>
            </li>
        </ul>
    </li>

</ul>


