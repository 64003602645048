/**
 * Created by ModelParser
 * Date: 17-05-2021.
 * Time: 12:30.
 */
import {CategoriesDisplayFilterDefinition} from './definitions/CategoriesDisplayFilterDefinition';

export class CategoriesDisplayFilter extends CategoriesDisplayFilterDefinition {

    constructor(json?: any) {
        super(json);
    }

}
