import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {EventEmitter} from "@angular/core";
import {MilestonePlan} from "@app/core/models";

export class EditMilestonePlanSelectConfiguration extends BaseEditorConfiguration {

    public onMilestonePlanSelectChangeEvent = new EventEmitter<MilestonePlan>();

    constructor() {
        super();
    }

}
