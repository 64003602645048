export class ColumnTypeSettings {

    public static ProjectUserTypes = 'project-user-types';
    public static ProjectEstimateTypes = 'project-estimate-types';
    public static ProjectDeadlineTypes = 'project-deadline-types';
    public static ProjectDeadlineTypeFromAndTo = 'project-deadline-type-from-and-to';
    public static ProjectNoteField = 'project-note-field';
    public static ProjectStatusTypes = 'project-status-types';
    public static FollowingMilestoneListDefaultSort = 'following-milestone-list-default-sort';
    public static TaskListNextMilestoneDefaultSort = 'task-list-next-milestone-default-sort';
    public static TaskListNextMilestone_TaskTypes = 'task-list-next-milestone-task-types';
    public static TaskListDefaultSort = 'task-list-default-sort';
    public static TaskList_TaskTypes = 'task-list-task-types';
    public static TaskListWithoutMilestoneDefaultSort = 'task-list-without-milestone-default-sort';
    public static TaskListWithoutMilestone_TaskTypes = 'task-list-without-milestone-task-types';
    public static TaskTypes = 'task-types';
    public static RosterDays = 'roster-days';
    public static TodoListIncludeArchivedSince = 'todo-list-include-archived-since';
    public static YearWheelProjectDeadlineTypes = 'year-wheel-project-deadline-types';

}
