import {CardConfiguration} from '@app/shared/_ui/cards/CardConfiguration';
import {Milestone} from '@app/core/models/Milestone';

export class SmallCardMilestoneConfiguration extends CardConfiguration<Milestone> {

    constructor() {
        super();
    }

    get exists(): boolean {
        return true;
    }

    public compare(config: SmallCardMilestoneConfiguration): boolean {
        return true;
    }
}
