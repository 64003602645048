import {Injector} from '@angular/core';

export class AppInjector {
    private static instance: AppInjector;
    private injector: Injector;

    constructor() {
        //throw new Error("Singleton - can't instantiate.");
    }

    public static getInstance() {
        if (!AppInjector.instance) {
            AppInjector.instance = new AppInjector();
        }

        return AppInjector.instance;
    }

    public static setInjector(injector: Injector) {
        this.getInstance().injector = injector;
    }

    public static getInjector(): Injector {
        return this.getInstance().injector;
    }
}