import {NgModule} from "@angular/core";
import {
    MoveProjectDeadlineDialogComponent
} from "@app/shared/_modals/move-project-deadline-dialog/move-project-deadline-dialog.component";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {CommonModule} from "@angular/common";
import {NgxModalDraggableDirectiveModule} from "@app/directives/NgxModalDraggableDirective.module";
import {ColorLabelModule} from '@app/shared/_elements/color-label/ColorLabel.module';

@NgModule({
    imports: [
        CommonModule,
        ColorItemModule,
        NgbTooltipModule,
        TranslateModule,
        LoadingIndicatorModule,
        LocalizedDatePipeModule,
        NgxModalDraggableDirectiveModule,
        ColorLabelModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MoveProjectDeadlineDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class MoveProjectDeadlineDialogModule {}
