<ul class="list-unstyled d-flex align-items-center gap-2">
    <li *ngIf="configuration.showStars" >
        <app-star-selector *ngIf="configuration.showStars"
                           [model]="model"
                           placement="left"
        ></app-star-selector>
    </li>
    <li *ngIf="configuration.showHands" >
        <app-hand-up-selector *ngIf="configuration.showHands"
                              [model]="$any(model)"
                              placement="left"
        ></app-hand-up-selector>
    </li>
    <li *ngIf="configuration.showReactionTypes" style="transform: translateY(-1px);">
        <app-reactions
            *ngIf="configuration.showReactionTypes"
            [reactionsApiInterface]="model"
            [reactionsSourceInterface]="model"
            [reactions]="model?.reactions"
            [reactionsEditable]="true"
            [reactionsVisible]="configuration.isReactionsVisible"
            [reactionShowBadge]="configuration.showReactionsBadge"
            [reactionsTypeInterfaces]="reactionsTypeInterfaces"
            (click)="$event.stopImmediatePropagation()"
        ></app-reactions>
    </li>
</ul>
