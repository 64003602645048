import {EventEmitter} from "@angular/core";
import {Subscription} from "rxjs";

export class MultiDisplayVisibilityObserver {

    private value = 0;
    private onChangeEvent = new EventEmitter<number>();

    public subscribeAsObserver(next: (value: number) => void): Subscription {
        return this.onChangeEvent.subscribe(next);
    }

    public subscribeAsDisplay(next?: (value: number) => void): Subscription {
        const subscription = this.onChangeEvent.subscribe(next);

        // Add a TearDownLogic for when the subscription is unsubscribed
        subscription.add(() => this.decrease());

        // Increase now, cause subscriber is a display
        this.increase();

        return subscription;
    }

    public increase() {
        this.onChangeEvent.emit(++this.value);
    }

    public decrease() {
        this.onChangeEvent.emit(--this.value);
    }

    public getValue(): number {
        return this.value;
    }

}
