/**
 * Created by ModelParser
 */
import {MilestonePlan} from '../MilestonePlan';
import {Phase} from '../Phase';
import {TaskTemplate} from '../TaskTemplate';
import {MilestoneTemplatesStatusRule} from '../MilestoneTemplatesStatusRule';
import {StatusRule} from '../StatusRule';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class MilestoneTemplateDefinition extends BaseModel {
    milestone_plan_id?: number;
    milestone_plan?: MilestonePlan;
    title?: string;
    deadline_value?: number;
    deadline_type?: number;
    index_?: number;
    phase_id?: number;
    phase?: Phase;
    task_templates?: TaskTemplate[];
    milestone_templates_status_rules?: MilestoneTemplatesStatusRule[];
    status_rules?: StatusRule[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.milestone_plan_id;
            delete this.milestone_plan;
            delete this.title;
            delete this.deadline_value;
            delete this.deadline_type;
            delete this.index_;
            delete this.phase_id;
            delete this.phase;
            delete this.task_templates;
            delete this.milestone_templates_status_rules;
            delete this.status_rules;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.milestone_plan_id != null) {
            this.milestone_plan_id = data.milestone_plan_id;
        }
        if (data.milestone_plan != null) {
            this.milestone_plan = new MilestonePlan(data.milestone_plan);
        }
        if (data.title != null) {
            this.title = data.title;
        }
        if (data.deadline_value != null) {
            this.deadline_value = data.deadline_value;
        }
        if (data.deadline_type != null) {
            this.deadline_type = data.deadline_type;
        }
        if (data.index_ != null) {
            this.index_ = data.index_;
        }
        if (data.phase_id != null) {
            this.phase_id = data.phase_id;
        }
        if (data.phase != null) {
            this.phase = new Phase(data.phase);
        }
        if (data.task_templates != null) {
            this.task_templates = data.task_templates.map((i: any) => new TaskTemplate(i));
        }
        if (data.milestone_templates_status_rules != null) {
            this.milestone_templates_status_rules = data.milestone_templates_status_rules.map((i: any) => new MilestoneTemplatesStatusRule(i));
        }
        if (data.status_rules != null) {
            this.status_rules = data.status_rules.map((i: any) => new StatusRule(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
