import {EventEmitter} from "@angular/core";

export abstract class BaseFetcher {

    public name: string;

    public onFinishEvent = new EventEmitter();

    protected constructor(name: string) {
        this.name = name;
    }

    public abstract execute(): void;
    public abstract cancel(): void;
    public abstract clear(): void;

}
