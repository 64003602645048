<ng-container [ngSwitch]="model.constructor.name">

    <app-card-task *ngSwitchCase="'Task'"
                   [attr.data-item-id]="cardItem.item.id"
                   [item]="$any(cardItem)"
                   [model]="$any(model)"
                   [configuration]="$any(configuration)"
                   [listConfiguration]="listConfiguration"
                   [mini]="configuration.displayModeMini"
                   [interactive]="interactive"></app-card-task>

    <app-card-task *ngSwitchCase="'Roster'"
                   [item]="$any(cardItem)"
                   [model]="$any(model)"
                   [configuration]="$any(configuration)"
                   [listConfiguration]="listConfiguration"
                   [mini]="configuration.displayModeMini"
                   [interactive]="interactive"></app-card-task>

    <app-card-task *ngSwitchCase="'CaseUser'"
                   [item]="$any(cardItem)"
                   [model]="$any(model)"
                   [configuration]="$any(configuration)"
                   [listConfiguration]="listConfiguration"
                   [mini]="configuration.displayModeMini"
                   [interactive]="interactive"></app-card-task>

    <app-card-project *ngSwitchCase="'Project'"
                      [item]="$any(cardItem)"
                      [model]="$any(model)"
                      [configuration]="$any(configuration)"
                      [listConfiguration]="listConfiguration"
                      [mini]="configuration.displayModeMini"
                      [interactive]="interactive"></app-card-project>

    <app-card-todo *ngSwitchCase="'Todo'"
                   [item]="$any(cardItem)"
                   [model]="$any(model)"
                   [configuration]="$any(configuration)"
                   [listConfiguration]="listConfiguration"
                   [interactive]="interactive"></app-card-todo>

    <app-card-office-365-event
        *ngSwitchCase="Constants.ItemTypes.Office365Event"
        [item]="cardItem" [model]="model"
        [configuration]="configuration"
        [listConfiguration]="listConfiguration"
        [interactive]="interactive"
    />

    <app-card-milestone
        *ngSwitchCase="Constants.ItemTypes.Milestone"
        [item]="$any(cardItem)"
        [model]="$any(model)"
        [configuration]="$any(configuration)"
        [listConfiguration]="listConfiguration"
        [mini]="configuration.displayModeMini"
        [interactive]="interactive"
    />

    <app-card-appointment
        *ngSwitchCase="'Appointment'"
        [item]="$any(cardItem)"
        [model]="model"
        [configuration]="configuration"
        [listConfiguration]="listConfiguration"
        [interactive]="interactive"></app-card-appointment>

    <app-card-appointment *ngSwitchCase="'AppointmentForm'"
                          [item]="$any(cardItem)"
                          [model]="model"
                          [configuration]="configuration"
                          [listConfiguration]="listConfiguration"
                          [interactive]="interactive" ></app-card-appointment>
</ng-container>
