/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TaskStatusDefinition} from './definitions/TaskStatusDefinition';
import {StatusTypes} from '@app/constants';

export class TaskStatus extends TaskStatusDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static Create(typeId: number, statusId?: number): TaskStatus {
        const item = new TaskStatus();
        item.id = 0;
        item.status_id = statusId ?? StatusTypes.GREEN;
        item.task_status_type_id = typeId;
        return item;
    }

    public static CreateDefault(status?: number) {
        let item = new TaskStatus();
        item.id = 0;
        item.status_id = status ? status : StatusTypes.GREEN;
        return item;
    }

}
