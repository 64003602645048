import {NgModule} from "@angular/core";
import {UserDashboardMenuItemComponent} from "@app/shell/sidebar/user-dashboard-menu-item.component";
import {RouterModule} from "@angular/router";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        LoadingIndicatorModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        UserDashboardMenuItemComponent,
    ],
    exports: [
        UserDashboardMenuItemComponent

    ],
    providers: [

    ],
})
export class UserDashboardMenuItemModule {}
