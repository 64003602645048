<ul class="list-unstyled w-100 p-2 border rounded mb-2">

    <!-- Titel -->
    <li>
        <div class="form-group p-0 w-100 mb-0">
            <label [for]="'task-title'+taskTemplate.id" class="d-flex">
                <span >{{'_ui_milestoneplan_task_title' | translate}}</span>
                <span class="ms-auto">
                <span class="fal fa-trash-alt text-danger ms-auto cursor-pointer me-1"
                      [ngbTooltip]="('_global_delete' | translate)"
                      (click)="removeTask()"></span>

                <i class="fal cursor-pointer "
                   [ngbTooltip]="('_ui_add_deadline_rules' | translate)"
                   (click)="expanded = !expanded"
                   [class.fa-circle-arrow-up]="expanded"
                   [class.fa-circle-arrow-down]="!expanded"></i>
                    </span>

            </label>
            <input
                [(ngModel)]="taskTemplate.title"
                placeholder=""
                class="form-control"
                name="title"
                [autofocusAfterInit]="!taskTemplate.title"
                [id]="'task-title'+taskTemplate.id"
                (blur)="taskTemplate.id && taskTemplate.id !== 0 ? saveTaskTemplateTitle() : createTaskTemplate()"
            >
        </div>
    </li>

    <ng-container *ngIf="expanded">
        <!-- Frist -->
        <li [class.disabled]="taskTemplate?.id == 0">


            <div class="d-flex">
                <label [for]="'deadline_value_'+taskTemplate.id">
                    <span >{{'_ui_milestoneplan_task_deadline' | translate}}</span>
                </label>
                <label>
                    <span >{{'_ui_milestoneplan_task_deadline_logic' | translate}}</span>
                </label>
            </div>

            <div class="d-flex flex-row justify-content-between mb-0 align-items-center">
                <input
                    [(ngModel)]="taskTemplate.deadline_value"
                    type="number"
                    (blur)="saveTaskTemplateDeadlineValue()"
                    placeholder=""
                    type="number"
                    class="form-control"
                    name="deadline_value_{{taskTemplate.id}}"
                    id="deadline_value"
                >

                <select class="form-control ms-1 mb-0"
                        [(ngModel)]="taskTemplate.deadline_type"
                        (ngModelChange)="saveTaskTemplateDeadlineType()"
                >
                    <option
                        *ngFor="let deadlineType of taskDeadlineTypes"
                        [value]="deadlineType.id">{{deadlineType.name | translate}}</option>
                </select>
            </div>
        </li>

        <!-- Estimeret tid -->
        <li [class.disabled]="taskTemplate?.id == 0">
            <label
                [for]="'estimate_value_'+taskTemplate.id"
            >
                <span >{{'_admin_milestone_plan_estimated_time' | translate}}</span>
            </label>

            <div class="d-flex flex-row justify-content-between mb-0 align-items-center"
                 *ngIf="estimateUnits && estimateUnits.length > 0">
                <input
                    [(ngModel)]="taskTemplate.estimate_value"
                    type="number"
                    (blur)="saveTaskTemplateEstimateValue()"
                    placeholder=""
                    class="form-control"
                    name="estimate_value_{{taskTemplate.id}}"
                    id="estimate_value"
                >

                <select class="form-control ms-1 mb-0"
                        [(ngModel)]="taskTemplate.estimate_unit_id"
                        (change)="saveTaskTemplateEstimateUnit()"
                        readonly
                >
                    <option
                        *ngFor="let estimateUnit of estimateUnits"
                        [value]="estimateUnit.id">{{estimateUnit.name | translate}}</option>
                </select>
            </div>
            <div class="d-flex" *ngIf="estimateUnits && estimateUnits.length == 0">
                <div class="alert alert-danger d-flex align-items-center">
                    <span class="me-1 fal fa-exclamation-triangle"></span>
                    <span >{{'_ui_task_row_misconfigured' | translate}}</span>
                </div>
            </div>
        </li>

        <li [class.disabled]="taskTemplate?.id == 0">
            <ul class="list-unstyled border rounded mt-2">
                <li class="p-2">
                    <app-user-search [selectedUsers]="taskTemplate.users"
                                     (itemSelected)="userSelected($event)"
                                     [placeholder]="('_ui_milestoneplan_task_users')"
                                     [multiple]="false"
                                     [autoReset]="true"
                                     [removeOnBlur]="true"

                                     class="flex-fill align-self-start w-100 flex-grow-1"
                    ></app-user-search>
                </li>
                <li *ngFor="let u of taskTemplate.users" class="d-flex p-2 border-top align-items-center">
                    <app-user-with-icon [model]="u"></app-user-with-icon>
                    <span class="fal fa-trash-alt text-danger ms-auto cursor-pointer"
                          [ngbTooltip]="('_global_delete' | translate)"
                          (click)="removeUser(u)"></span>
                </li>
            </ul>

        </li>

        <!-- // Task.delivery_description = URL -->
        <li [class.disabled]="taskTemplate?.id == 0">
            <app-rich-text-editor
                [label]="('_admin_milestone_plan_note' | translate)"
                [(ngModel)]="taskTemplate.delivery_description"
                (changeEvent)="saveTaskTemplateDeliveryDescription($event)"
            ></app-rich-text-editor>
        </li>

        <!-- // Task.purpose = URL -->
        <li [class.disabled]="taskTemplate?.id == 0">
            <label [for]="'link_'+taskTemplate.id" class="d-flex">
                <span >{{'_admin_milestone_plan_reference' | translate}}</span>
            </label>
            <input
                [(ngModel)]="taskTemplate.purpose"
                placeholder=""
                class="form-control"
                name="link"
                [id]="'link_'+taskTemplate.id"
                (blur)="saveTaskTemplatePurpose($event)"
            >
            <app-url-link [link]="taskTemplate.purpose" [title]="true" [icon]="true"></app-url-link>
        </li>

    </ng-container>

</ul>
