import {NgModule} from "@angular/core";
import {RosterExpanderComponent} from "@app/shared/_ui/displays/roster-expander/roster-expander.component";
import {UserSearchModule} from "@app/shared/_forms/search/user-search/UserSearch.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        UserSearchModule,
        CardsModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        RosterExpanderComponent,
    ],
    exports: [
        RosterExpanderComponent

    ],
    providers: [

    ],
})
export class RosterExpanderModule {}
