import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";

export class NextMilestoneRiskCell extends GenericCell {

    public exportCSV(secondary?: any): CSVCellValue {
        return this.row.project.item.next_milestone?.risk
            ?.replace('<br>', "\r\n\r\n")
            ?.replace(/<[^>]*>/g, '')
            ?? '';
    }

}
