import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {StatusItem} from "@app/editor/quick-editor/editors/generic/status-list-editor/StatusItem";

export class EditStatusListConfiguration extends BaseEditorConfiguration {

    public labelName: string;
    public types: StatusItem[];

    constructor(labelName: string, types: StatusItem[]) {
        super();
        this.labelName = labelName;
        this.types = types;
    }

}
