import {BaseColumnConfiguration} from "@app/editor/quick-editor/columns/BaseColumnConfiguration";
import {Widths} from "@app/constants";

export class TextColumnConfiguration extends BaseColumnConfiguration {

    public value: string;

    constructor(value: string, canResize: boolean, canAutoResize: boolean) {
        super(Widths.TextColumn, canResize, canAutoResize);
        this.value = value;
    }

}
