import {NgModule} from "@angular/core";
import {ColumnComponent} from "@app/editor/quick-editor/columns/column/column.component";
import {ResizableModule} from "angular-resizable-element";
import {ColumnSwitcherModule} from "@app/editor/quick-editor/columns/column-switcher/ColumnSwitcher.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ResizableModule,
        ColumnSwitcherModule,
        NgbTooltipModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ColumnComponent,
    ],
    exports: [
        ColumnComponent

    ],
    providers: [

    ],
})
export class ColumnModule {}
