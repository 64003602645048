<div class="d-flex align-content-center">

    <app-color-item
            class="cursor-pointer me-2"
            className="medium"
            colorValue="#ff5f57"
            iconClass="fa-times"
            container="body"
            [class.disabled]="!validator.isValid()"
            [ngbTooltip]="('_ui_close' | translate)"
            (click)="onCloseBtnClicked();"
    />
    <app-color-item
            class="cursor-pointer me-2"
            className="medium"
            colorValue="#febc2e"
            iconClass="fa-minus"
            container="body"
            [ngbTooltip]="('_ui_minimize' | translate)"
            (click)="onMinimizeBtnClicked();"
    />
    <app-color-item
            class="cursor-pointer"
            className="medium"
            colorValue="#28c840"
            iconClass="fa-sort"
            style="transform: rotate(45deg);"
            container="body"
            [ngbTooltip]="('_ui_maximize' | translate)"
            (click)="onMaximizeBtnClicked();"
    />

</div>
