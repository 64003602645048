import {GenericColumn} from "@app/pages/displays/display-team/Columns/GenericColumn";
import {DisplayTeamRow} from "@app/pages/displays/display-team/DisplayTeamRow";
import {
    UserPeriodRatingFetcher,
    UserPeriodRatingFetchRequest
} from "@app/shared/_ui/cards/medium/card-user/UserPeriodRatingFetcher";
import {
    UserLoadCalculationFetcher,
    UserLoadCalculationFetchRequest
} from "@app/shared/_ui/cards/medium/card-user/LoadCalculationFetcher";
import {UserCardCell} from "@app/pages/displays/display-team/Cells/UserCardCell";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting, User} from "@app/core/models";
import {
    ProjectCountFetcher,
    ProjectCountFetchRequest
} from "@app/shared/_ui/cards/medium/card-user/ProjectCountFetcher";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {ColumnTypeSettings} from "@app/pages/displays/display-team/Helpers/ColumnTypeSettings";
import {Team_UserCardLoadProfileSettingValue} from "@app/core/http/Api/Api";
import {CardUserConfiguration} from "@app/shared/_ui/cards/medium/card-user/card-user-configuration";

export class UserCardColumn extends GenericColumn implements ColumnDataFetcherInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public userPeriodRatingFetcher: UserPeriodRatingFetcher;
    public userLoadCalculationFetcher: UserLoadCalculationFetcher;
    public projectCountFetcher: ProjectCountFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.userPeriodRatingFetcher,
            this.userLoadCalculationFetcher,
            this.projectCountFetcher,
        ]
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.userPeriodRatingFetcher = new UserPeriodRatingFetcher(column.name);
        this.userLoadCalculationFetcher = new UserLoadCalculationFetcher(column.name);
        this.projectCountFetcher = new ProjectCountFetcher(column.name);
    }

    public createCell(row: DisplayTeamRow, userCard?: CardItem<User>): UserCardCell {
        const cell = new UserCardCell(row, this);

        (userCard.configuration as CardUserConfiguration).setLoadId(this.getLoadProfileId());

        this.userPeriodRatingFetcher.addRequest(new UserPeriodRatingFetchRequest(userCard));
        this.userLoadCalculationFetcher.addRequest(new UserLoadCalculationFetchRequest(userCard));
        this.projectCountFetcher.addRequest(new ProjectCountFetchRequest(userCard));

        return cell;
    }

    public getLoadProfileId(): number {
        return this.settings.get(ColumnTypeSettings.UserCard_LoadProfile)
            ?.getObject<Team_UserCardLoadProfileSettingValue>()
            ?.loadProfileId ?? 0;
    }

}
