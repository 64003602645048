import {NgModule} from "@angular/core";
import {
    MilestonePlanEditorComponent
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-editor/milestone-plan-editor.component";
import {FormsModule} from "@angular/forms";
import {AutofocusDirectiveModule} from "@app/directives/AutofocusDirective.module";
import {NgbNavModule, NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {DepartmentPickerModule} from "@app/shared/_forms/department-picker/DepartmentPicker.module";
import {UserSearchModule} from "@app/shared/_forms/search/user-search/UserSearch.module";
import {RichTextEditorModule} from "@app/shared/_forms/rich-text-editor/RichTextEditor.module";
import {
    MilestonePlanPeriodSelectionModule
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-editor/milestone-plan-period-selection/MilestonePlanPeriodSelection.module";
import {
    MilestonePlanTimelineModule
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-timeline/MilestonePlanTimeline.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {
    TemplateMilestoneRowModule
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-editor/partials/TemplateMilestoneRow.module";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AutofocusDirectiveModule,
        NgbNavModule,
        NgbTooltipModule,
        TranslateModule,
        DepartmentPickerModule,
        UserSearchModule,
        RichTextEditorModule,
        MilestonePlanPeriodSelectionModule,
        MilestonePlanTimelineModule,
        NgbPopoverModule,
        DragDropModule,
        TemplateMilestoneRowModule,
        LoadingIndicatorModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MilestonePlanEditorComponent,
    ],
    exports: [
        MilestonePlanEditorComponent

    ],
    providers: [

    ],
})
export class MilestonePlanEditorModule {}
