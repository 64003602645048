export class ColumnTypes {

    public static MilestoneCard = 'milestone-card';

    public static TaskEdit = 'task-edit';

    public static TaskList_Status = 'task-list-status';
    public static TaskList_Period = 'task-list-period';
    public static TaskList_All = 'task-list-all';

    public static MilestoneEdit = 'milestone-edit';
    public static MilestoneYearWheel = 'milestone-year-wheel'

}
