import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {EventEmitter} from "@angular/core";
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';
import {
    AttachedProjectType
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-tabs/task-editor-tab-attach-to/attached-project-type/AttachedProjectType";

export class AttachedProjectTypeValidator implements ValidationErrorInterface {

    public readonly requireMilestone: boolean;

    private rules: ((value: AttachedProjectType) => string | true)[];

    constructor(requireMilestone: boolean) {
        this.requireMilestone = requireMilestone;

        // Build rules
        const rules = [];

        if (requireMilestone) {
            rules.push(
                (input: AttachedProjectType) => {
                    if (!input.hasProject || (input.hasProject && input.hasMilestone)) {
                        return true;
                    } else {
                        return AppInjector.getInjector()
                            .get(TranslateService)
                            .instant(
                                '_ui_task_validation_project_require_milestone',
                                {projectType: input.projectType.smartName}
                            );
                    }
                }
            )
        }

        this.rules = rules;
    }

    private lastValidation: true | string[] = true; // TRUE until change

    public validate(value: AttachedProjectType) {
        let isValid = true;
        const errors: string[] = [];
        this.rules.forEach(rule => {
            const result = rule(value);
            if (result !== true) {
                errors.push(result);
                isValid = false;
            }
        });

        const newValidation = isValid ? true: errors;
        if (this.lastValidation !== newValidation) {
            this.lastValidation = newValidation;
            this.onIsValidChanged.emit(isValid);
        }
    }

    // <editor-fold desc="Validator interface">

    public onIsValidChanged = new EventEmitter<boolean>();

    public isValid(): boolean {
        return this.lastValidation === true;
    }

    public getErrorDescriptions(): string[] {
        return this.lastValidation === true ? [] : this.lastValidation;
    }

    // </editor-fold>

}
