import {EventEmitter} from "@angular/core";
import {Display, DisplayFiltersColumn, DisplaysSetting} from "@app/core/models";
import {ColumnGroup} from "@app/services/ShellColumnService/ColumnGroup";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

export class ShellColumnGroup {

    // Data
    public name: string;
    public display: Display;
    public columnController: ColumnController;
    public tableColumnGroups?: ColumnGroup[];
    public tableColumns?: BaseTableColumn[];
    public changed = false;
    private displayFilterColumns?: DisplayFiltersColumn[];

    // Events
    public onColumnChangeEvent = new EventEmitter<BaseTableColumn[]>();
    public onColumnSaveEvent = new EventEmitter<DisplaysSetting[]>();
    public onColumnResetEvent = new EventEmitter<BaseTableColumn[]>();

    public constructor(name: string, display: Display, columnController: ColumnController) {
        this.name = name;
        this.display = display;
        this.columnController = columnController;

        this.updateColumns();
        columnController.onColumnsChanged.subscribe(() => {
            this.updateColumns();
            this.onColumnChangeEvent.emit();
        });
    }

    public updateColumns() {
        const tableColumns: BaseTableColumn[] = [];
        const tableColumnGroups: ColumnGroup[] = [];
        this.columnController.getAllColumns().forEach(column => {
            tableColumns.push(...column.getTableColumns<BaseTableColumn>());
            tableColumnGroups.push(new ColumnGroup(column.column.name, column.getTableColumns<BaseTableColumn>()));
        });
        this.tableColumns = tableColumns;
        this.tableColumnGroups = tableColumnGroups;

        if (this.displayFilterColumns) {
            this.applyDisplayFilterColumns(this.displayFilterColumns);
            this.displayFilterColumns = undefined;
        }

        this.changed = true;
        this.triggerUpdateEvent();
    }

    public applyDisplayFilterColumns(displayFilterColumns: DisplayFiltersColumn[]) {
        this.displayFilterColumns = displayFilterColumns;
        this.tableColumns?.forEach(tableColumn => {
            const visible = tableColumn.column.columnType.isAlwaysVisible
                || displayFilterColumns.some(displayFilterColumn => displayFilterColumn.column_id == tableColumn.column.column.id && displayFilterColumn.table_column_identifier == tableColumn.fullIdentifier);
            if (tableColumn.isVisible != visible) {
                tableColumn.isVisible = visible;
                this.markChanged();
            }
        });
    }

    public markChanged() {
       this.changed = true;
    }

    public triggerUpdateEvent() {
        if (this.changed) {
            this.tableColumns.forEach(c => c.setWidth(0));
            this.onColumnChangeEvent.emit(this.tableColumns);
            this.columnController.emitColumnVisibilityChange(
                this.tableColumns.filter(tableColumn => tableColumn.isVisible),
                []
            );
        }
        this.changed = false;
    }

    public triggerResetEvent() {
        if (this.changed) {
            this.onColumnResetEvent.emit(this.tableColumns);
        }
        this.changed = false;
    }

}
