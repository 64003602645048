import {NgModule} from "@angular/core";
import {
    TemplateHolidayPickerComponent
} from "@app/shared/_ui/displays/milestone-plan/template-holiday-picker/template-holiday-picker.component";
import {TranslateModule} from "@ngx-translate/core";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {ItemDateModule} from "@app/shared/_elements/item-date/ItemDate.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        LoadingIndicatorModule,
        ItemDateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TemplateHolidayPickerComponent,
    ],
    exports: [
        TemplateHolidayPickerComponent

    ],
    providers: [

    ],
})
export class TemplateHolidayPickerModule {}
