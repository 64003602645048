/**
 * Created by ModelParser
 * Date: 23-01-2024.
 * Time: 14:49.
 */
import {TeamDisplayUserPositionDefinition} from './definitions/TeamDisplayUserPositionDefinition';

export class TeamDisplayUserPosition extends TeamDisplayUserPositionDefinition {

    constructor(json?: any) {
        super(json);
    }

}
