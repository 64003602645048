export class ColumnTypes {

    public static ProjectCard = 'project-card';
    public static NextMilestoneCard = 'next-milestone-card';
    public static FollowingMilestoneList = 'following-milestone-list';
    public static TaskListNextMilestone = 'task-list-next-milestone';
    public static TodoList = 'todo-list';
    public static AppointmentList = 'appointment-list';

}
