import {NgModule} from "@angular/core";
import {
    MilestoneListEditorComponent
} from "@app/editor/quick-editor/editors/generic/milestone-list-editor/milestone-list-editor.component";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule,
        NgbPopoverModule,
        ColorLabelModule,
        CardsModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MilestoneListEditorComponent,
    ],
    exports: [
        MilestoneListEditorComponent

    ],
    providers: [

    ],
})
export class MilestoneListEditorModule {}
