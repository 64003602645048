export class DataCacheHit<T> {

    public data: T;
    public storeDate: Date;

    constructor(data: T, storeDate: Date) {
        this.data = data;
        this.storeDate = storeDate;
    }

}
