<i class="fal fa-moon cursor-pointer"
   [ngbPopover]="popoverContent"
   [autoClose]="false"
   [ngbTooltip]="('_phases' | translate)"
   [placement]="['auto']"
   [popoverClass]="'popover-with-dialog'"
   container="body"
   #popover="ngbPopover"
   (click)="loadPhases()"
></i>

<!-- Popover dialog content -->
<ng-template #popoverContent>

    <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>

    <ng-container *ngIf="detailView">

        <label class="d-flex phases-project-picker mt-1">
            <span >{{'_ui_process_steps_phase_title' | translate}}</span>
        </label>
        <div class="-border-bottom w-100 mb-2 pb-2 " style="position: sticky;">
            <app-project-phase-search
                [project]="projects[0]"
                [autoSave]="true"
                [showPhases]="false"
                [allowCreation]="true"
            ></app-project-phase-search>
        </div>

        <label class="d-flex ">
            <span >{{'_ui_process_steps_process_step_title' | translate}}</span>
        </label>
        <div class="popover-content-wrapper scroll-y border-top">
            <app-process-steps [projectId]="projects[0].id"></app-process-steps>
        </div>
    </ng-container>

    <ng-container *ngIf="!detailView">
        <ul class="list-unstyled scroll-y p-2"
            style="max-height: 200px;">

            <li *ngIf="!phasesProjects">
                <app-loading-indicator [showLogo]="false"></app-loading-indicator>
            </li>
            <li *ngIf="phasesProjects && phasesProjects.length == 0">
                <div class="text-center text-black-50">{{'_ui_project_has_no_phases' | translate}}</div>
            </li>

            <li
                *ngIf="projectsWithPhases && projectsWithPhases.length > 0"
                class="d-flex align-items-center pb-1 border-bottom"
            >
                <app-small-card-project [model]="projectsWithPhases[0]" class="-me-1"></app-small-card-project>
            </li>

            <li *ngFor="let phasesProject of phasesProjects"
                class="border-bottom pt-1 pb-1"
            >
                <div class="d-flex align-items-center">
                    <app-color-item *ngIf="phasesProject?.phase?.color" [color]="phasesProject?.phase?.color"
                                    class="me-1"></app-color-item>
                    <div class="text-capitalize-firstletter ellipsis me-1" style="max-width: 120px;"
                         [ngbTooltip]="phasesProject.phase.name">{{phasesProject.phase.name}}</div>
                    <div class="ms-auto">{{phasesProject.getStartedDate() | localizedDate}}</div>
                </div>
            </li>
        </ul>

    </ng-container>
</ng-template>


