import {AfterContentChecked, ChangeDetectorRef, Injectable, OnDestroy, OnInit, ViewRef} from '@angular/core';
import Globals from '@app/constants';
import Helpers from '@app/core/helpers';
import {User} from '@app/core/models/User';
import {AppInjector} from '@app/services/app-injector.service';
import {UsersService} from '@app/services/users.service';
import {Subscription} from 'rxjs';
import {ComponentVisibilityObserver} from "@app/shared/_system/base/ComponentVisibilityObserver";

@Injectable()
export class BaseComponent implements OnInit, AfterContentChecked, OnDestroy {

    private subscriptions_: Subscription;
    protected cdr: ChangeDetectorRef;
    // protected componentIdentifier = Math.round((Math.random() * 36 ** 12)).toString(36);

    ngOnInit() {

    }

    ngOnDestroy(): void {
        try {
            if (this.subscriptions_) {
                this.subscriptions_.unsubscribe();
            }
        } catch (e) {
            console.error('ngOnDestroy() : unsubscribe : ', e);
        }
    }

    // <editor-fold desc="Component visibility tracking">

    private componentVisibilityObservers: ComponentVisibilityObserver[] = [];

    ngAfterContentChecked() {
        this.componentVisibilityObservers?.forEach(value => value.check());
    }

    public addComponentVisibilityObserver(value: ComponentVisibilityObserver) {
        this.componentVisibilityObservers.push(value);
    }

    // </editor-fold>

    getUser(): User {
        return AppInjector.getInjector().get(UsersService).user;
    }

    public subscribe(subscription: Subscription): Subscription {
        if (!this.subscriptions_) {
            this.subscriptions_ = new Subscription();
        }
        this.subscriptions_.add(subscription);
        return subscription;
    }

    public get Globals() {
        return Globals;
    }

    public get Constants() {
        return this.Globals;
    }

    public get Helpers() {
        return Helpers;
    }

    public lastChangeDetection: Date;
    protected cdTimeOut: number;

    protected markChangeDetectionDirty() {
        if (!this.cdr) {
            return;
        }
        // https://github.com/akserg/ng2-dnd/issues/177
        this.cdr.markForCheck();
        if (this.cdTimeOut) {
            clearTimeout(this.cdTimeOut);
        }
        this.cdTimeOut = window.setTimeout(() => {
            try {
                this.cdr.detectChanges();
                this.cdTimeOut = null;
                this.lastChangeDetection = new Date();
            } catch (e) {

            }
        }, 251)
    }

    protected detectChanges() {

        // Programmatically run change detection to fix issue in Safari
        if (this.cdr !== null && this.cdr !== undefined) {
            if(!(this.cdr as ViewRef).destroyed) {
                this.cdr.detectChanges();
            }
        } else {
            console.warn('Missing this.cdr in constructor :: detectChanges() : ', this);
        }
    }

}
