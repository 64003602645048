import {PageDisplaySetting} from "@app/pages/PageDisplaySetting";

export class Settings {
    public static ProjectTypeIds        = 341;
    public static TaskTypeIds           = 342;
    public static ShowMilestones        = 509;

    public static GetShowMilestones(settingsMap: Map<number, PageDisplaySetting>): boolean {
        return settingsMap.get(Settings.ShowMilestones)?.getBoolean() ?? false;
    }

    public static GetProjectTypeIds(settingsMap: Map<number, PageDisplaySetting>): number[] {
        return settingsMap.get(Settings.ProjectTypeIds)?.getIntValues() ?? [];
    }

    public static GetTaskTypeIds(settingsMap: Map<number, PageDisplaySetting>): number[] {
        return settingsMap.get(Settings.TaskTypeIds)?.getIntValues() ?? [];
    }
}
