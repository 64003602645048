import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {
    EditWhoWhenConfiguration
} from "@app/editor/quick-editor/editors/generic/who-when-editor/EditWhoWhenConfiguration";
import {WhoWhenItem} from "@app/editor/quick-editor/editors/generic/who-when-editor/WhoWhenItem";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {
    EditEnhancedUserList,
} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserList";
import {TaskUserTypes} from "@app/constants";

@Component({
    selector: 'app-who-when-editor',
    templateUrl: './who-when-editor.component.html',
    styleUrls: ['./who-when-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhoWhenEditorComponent extends BaseEditor<EditEnhancedUserList> implements OnChanges {
    protected eventFieldName = 'users';

    // Bindings to parent
    @Input() model: EditEnhancedUserList & HasEventGenerator & BaseModel;
    @Input() configuration: EditWhoWhenConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public description: string;
    public isExpanded = false;
    public itemCount = 0;

    // Data
    private items: WhoWhenItem[];

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscribe(this.configuration?.onChangeEvent.subscribe(() => {
            this.render();
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }

    protected setup() {
    }

    public render() {
        const allItems = this.model.findEnhancedUsersByTypeId(TaskUserTypes.Participant);
        this.isExpanded = this.configuration?.isExpanded;
        this.itemCount = allItems.length;
        this.items = allItems.slice(0, this.isExpanded ? this.itemCount : 1).map(item => new WhoWhenItem(item));
        this.description = this.items.map(item => '<li class="d-flex"><span class="ellipsis">' + item.describe() + '</span></li>').join('');
        this.detectChanges();
    }

    public toggleExpanded() {
        this.configuration?.setIsExpanded(!this.isExpanded);
    }
}
