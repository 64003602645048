import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {Tag} from "@app/core/models";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {
    EditTagListConfiguration
} from "@app/editor/quick-editor/editors/generic/tag-list-editor/EditTagListConfiguration";
import {EditTagList} from "@app/editor/quick-editor/editors/generic/tag-list-editor/EditTagList";

@Component({
    selector: 'app-tag-list-editor',
    templateUrl: './tag-list-editor.component.html',
    styleUrls: ['./tag-list-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagListEditorComponent extends BaseEditor<EditTagList> {
    protected eventFieldName = 'tags';

    @Input() model: EditTagList & HasEventGenerator & BaseModel;
    @Input() configuration: EditTagListConfiguration;
    @Input() editorEvents: EditorEvents;

    public values: Tag[];

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    protected render() {
        this.values = [...this.model.tags ?? []];
        this.detectChanges();
    }

    public onItemAdded(value: Tag) {
        this.model.addTag(value);
        this.render();
        this.onItemUpdated();
    }

    public onItemRemoved(value: Tag) {
        this.model.removeTag(value);
        this.render();
        this.onItemUpdated();
    }

}
