import {Archived, Deadline, User} from "@app/core/models";
import {HasUser} from "@app/interfaces/HasUser";
import {HasDeadline} from "@app/interfaces/HasDeadline";
import {HasIsArchived} from "@app/interfaces/HasIsArchived";

export class UserItem implements HasUser, HasDeadline, HasIsArchived {

    public user: User;
    public acceptanceStatus?: number;
    public deadline?: Deadline;
    public archived?: Archived;

    constructor(user: User, acceptanceStatus?: number, deadline?: Deadline, archived?: Archived) {
        this.user = user;
        this.acceptanceStatus = acceptanceStatus;
        this.deadline = deadline;
        this.archived = archived;
    }

    public get isArchived(): boolean {
        return this.archived !== undefined;
    }

}

export class EnhancedUser extends UserItem {

}
