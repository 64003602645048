<app-data-fetcher-collection-loader
    [dataFetcherCollection]="dataFetcherCollection"
/>

<div class="d-flex flex-fill h-100 position-relative flex-column  justify-content-between"
     [attr.data-isCapturingScreenshot]="isCapturingScreenshot"
>

    <!-- Top-tavle -->
    <app-sub-display-wrapper
        placement="top"
        class=" w-100 sub-display sub-display-top"
        [class.h-100]="isTopDisplayExpanded"
        [class.d-none]="isMainDisplayExpanded"
        [isExpanded]="isTopDisplayExpanded"
        [multiDisplayVisibilityObserver]="multiDisplayVisibilityObserver"
        (isExpandedChangeEvent)="onIsTopDisplayExpandedChanged($event)"
    ></app-sub-display-wrapper>

    <!-- Main display -->
    <div class="d-flex flex-fill -h-100 rounded position-relative flex-grow-1" #dataTableContainer
         [class.d-none]="isTopDisplayExpanded">


        <!-- Header template -->
        <ng-template #headerTemplate let-column="column" ngx-datatable-header-template
                     [typedTemplate]="templateTypes.headerTemplateType"
        >
            <ngx-datatable-column
                [name]="column.name"
                [width]="column.width"
                [maxWidth]="column.maxWidth"
                [minWidth]="column.minWidth"
                [resizeable]="column.resizeable">
                <app-column-sorter class="column-title "
                                   [display]="display"
                                   [filterPageData]="shellPageData"
                                   [column]="column"
                                   (columnSortChange)="onColumnSortChanged($event)"
                />
            </ngx-datatable-column>
        </ng-template>

        <ng-template #headerTemplateNavigation let-column="column" ngx-datatable-header-template
                     [typedTemplate]="templateTypes.headerTemplateType"
        >
            <ngx-datatable-column
                [name]="column.name"
                [width]="column.width"
                [maxWidth]="column.maxWidth"
                [minWidth]="column.minWidth"
                [resizeable]="column.resizeable">
                <div class="column-title ps-1 pe-1 d-flex align-items-center justify-content-start">
                    <div class="d-flex align-items-center justify-content-between flex-fill flex-shrink-1 w-100">
                        <app-display-expand-button
                            [isExpanded]="isMainDisplayExpanded"
                            [multiDisplayVisibilityObserver]="multiDisplayVisibilityObserver"
                            (onExpandChangeEvent)="onIsMainDisplayExpandedChanged($event)"
                        ></app-display-expand-button>
                        <div class="d-flex flex-shrink-1  flex-grow-1">
                            <app-column-sorter [display]="display"
                                               [filterPageData]="shellPageData"
                                               [column]="column"
                                               class="flex-shrink-1"
                                               (columnSortChange)="onColumnSortChanged($event)"
                            />
                        </div>
                    </div>
                </div>
            </ngx-datatable-column>
        </ng-template>

        <ng-template #headerDateTemplate let-column="column"
                     ngx-datatable-header-template
                     [typedTemplate]="templateTypes.daysHeaderTemplateType"
        >
            <ngx-datatable-column
                [name]="column.name"
                [width]="column.width"
                [maxWidth]="column.maxWidth"
                [minWidth]="column.minWidth"
                [resizeable]="column.resizeable">
                <div class="d-flex column-title">
                    <app-column-sorter
                        [display]="display"
                        [filterPageData]="shellPageData"
                        [column]="column"
                        (columnSortChange)="onColumnSortChanged($event)"
                    />
                    <span class="ms-1">{{column.weekDate | localizedDate: 'microDate'}}</span>
                </div>
            </ngx-datatable-column>
        </ng-template>

        <!-- User template -->
        <ng-template #userTemplate
                     let-column="column"
                     let-row="row"
                     let-rowIndex="rowIndex"
                     ngx-datatable-cell-template
                     [typedTemplate]="templateTypes.cellTemplateType"
        >
            <div class="wrap d-flex flex-column position-relative h-100">
                <app-card-user
                    [item]="row.userCard"
                    (projectTypeClicked)="projectTypeClicked($event, row)"></app-card-user>

                <div class="position-sorter d-flex gap-1"
                     *ngIf="enableRowPositioning"
                >
                    <div class="fa fal fa-arrow-up cursor-pointer"
                         [class.disabled]="rowIndex === 0"
                         (click)="onMoveUpBtnClicked(row)"></div>
                    <div class="fa fal fa-arrow-down  cursor-pointer"
                         [class.disabled]="rowIndex === rows.length - 1"
                         (click)="onMoveDownBtnClicked(row)"></div>
                </div>
            </div>
        </ng-template>

        <!-- Projects template -->
        <ng-template #projectsTemplate
                     let-row="row"
                     let-column="column"
                     let-rowIndex="rowIndex"
                     ngx-datatable-cell-template
                     [typedTemplate]="templateTypes.projectListCellTemplateType"
        >
            <app-project-list
                class="w-100"
                [minimizeEvent]="row.minimizeEvent"
                [reloadEvent]="row.reloadProjectListEvent"
                [listClass]="'width-150px grid-3 flex-row justify-content-start'"
                [scrollContainer]="dataTableContainer"
                [loadAllEvent]="row.loadAllEvent"
                [configuration]="column.getListConfiguration(row)">
            </app-project-list>
        </ng-template>

        <!-- Milestones template -->
        <ng-template #milestonesTemplate
                     let-column="column"
                     let-row="row"
                     ngx-datatable-cell-template
                     [typedTemplate]="templateTypes.milestoneListCellTemplateType"
        >
            <app-milestone-list
                class="w-100"
                [listClass]="'width-150px grid-3 flex-row justify-content-start'"
                [reloadEvent]="row.reloadMilestonesEvent"
                [configuration]="column.getListConfiguration(row)"
                [scrollContainer]="dataTableContainer"
                [loadAllEvent]="row.loadAllEvent"
                [minimizeEvent]="row.minimizeEvent">
            </app-milestone-list>
        </ng-template>

        <!-- Case template -->
        <ng-template #casesTemplate
                     let-column="column"
                     let-row="row"
                     ngx-datatable-cell-template
                     [typedTemplate]="templateTypes.caseListCellTemplateType"
        >
            <app-task-list
                class="w-100"
                [listClass]="'width-150px grid-3 flex-row justify-content-start'"
                [reloadEvent]="row.reloadCasesEvent"
                [configuration]="column.getListConfiguration(row)"
                [scrollContainer]="dataTableContainer"
                [loadAllEvent]="row.loadAllEvent"
                [minimizeEvent]="row.minimizeEvent">
            </app-task-list>
        </ng-template>

        <!-- Task template -->
        <ng-template #taskListSoftThisWeekTemplate
                     let-tableColumn="column"
                     let-row="row"
                     ngx-datatable-cell-template
                     [typedTemplate]="templateTypes.taskListSoftThisWeekCellTemplateType"
        >
            <app-task-list
                class="w-100"
                [listClass]="'width-150px grid-3 flex-row justify-content-start'"
                [reloadEvent]="row.reloadTasksEvent"
                [configuration]="tableColumn.column.getCell(row).listConfiguration"
                [scrollContainer]="dataTableContainer"
                [loadAllEvent]="row.loadAllEvent"
                [minimizeEvent]="row.minimizeEvent">
            </app-task-list>
        </ng-template>

        <!-- Task template -->
        <ng-template #tasksTemplate
                     let-tableColumn="column"
                     let-row="row"
                     ngx-datatable-cell-template
                     [typedTemplate]="templateTypes.taskListCellTemplateType"
        >
            <app-task-list
                class="w-100"
                [listClass]="'width-150px grid-3 flex-row justify-content-start'"
                [reloadEvent]="row.reloadTasksEvent"
                [configuration]="tableColumn.column.getCell(row).listConfiguration"
                [scrollContainer]="dataTableContainer"
                [loadAllEvent]="row.loadAllEvent"
                [minimizeEvent]="row.minimizeEvent">
            </app-task-list>
        </ng-template>

        <!-- Task template -->
        <ng-template #taskListSoftNextWeekTemplate
                     let-tableColumn="column"
                     let-row="row"
                     ngx-datatable-cell-template
                     [typedTemplate]="templateTypes.taskListSoftNextWeekCellTemplateType"
        >
            <app-task-list
                class="w-100"
                [listClass]="'width-150px grid-3 flex-row justify-content-start'"
                [reloadEvent]="row.reloadTasksEvent"
                [configuration]="tableColumn.column.getCell(row).listConfiguration"
                [scrollContainer]="dataTableContainer"
                [loadAllEvent]="row.loadAllEvent"
                [minimizeEvent]="row.minimizeEvent">
            </app-task-list>
        </ng-template>

        <!-- Days template -->
        <ng-template #daysTemplate
                     let-row="row"
                     let-tableColumn="column"
                     let-rowIndex="rowIndex"
                     ngx-datatable-cell-template
                     [typedTemplate]="templateTypes.daysCellTemplateType"
        >
            <app-task-list
                [listClass]="'flex-row justify-content-start'"
                [reloadEvent]="row.reloadTasksEvent"
                [configuration]="tableColumn.column.getCell(row).listConfigurations.get(tableColumn.weekDay)"
                [scrollContainer]="dataTableContainer"
                [loadAllEvent]="row.loadAllEvent"
                [minimizeEvent]="row.minimizeEvent">
            </app-task-list>
        </ng-template>

        <!-- Appointment template -->
        <ng-template #appointmentsTemplate
                     let-row="row"
                     let-column="column"
                     let-rowIndex="rowIndex"
                     ngx-datatable-cell-template
                     [typedTemplate]="templateTypes.appointmentListCellTemplateType"
        >
            <app-appointment-list
                [listClass]="'flex-row justify-content-start'"
                [configuration]="column.getListConfiguration(row)"
                [minimizeEvent]="row.minimizeEvent"
                [reloadEvent]="row.reloadAppointmentsEvent"
                [loadAllEvent]="row.loadAllEvent"
                [scrollContainer]="dataTableContainer">
            </app-appointment-list>
        </ng-template>

        <!-- To do template -->
        <ng-template #todosTemplate
                     let-row="row"
                     let-column="column"
                     let-rowIndex="rowIndex"
                     ngx-datatable-cell-template
                     [typedTemplate]="templateTypes.todoListCellTemplateType"
        >
            <app-todo-list
                class="w-100"
                [listClass]="'width-150px grid-3 flex-row justify-content-start'"
                [reloadEvent]="row.reloadTodosEvent"
                [configuration]="column.getListConfiguration(row)"
                [scrollContainer]="dataTableContainer"
                [loadAllEvent]="row.loadAllEvent"
                [minimizeEvent]="row.minimizeEvent">
            </app-todo-list>
        </ng-template>


        <div class="top">
            <ngx-datatable

                class="material bootstrap min-row-height"
                id="dataTable"
                #dataTable
                ngxDatatableExtension [table]="dataTable"

                *ngIf="rows && initialized && displayFilter"
                [rows]="rows"
                [columns]="tableColumns"
                [columnMode]="isDataTableResizeEnabled ? 'force':'force'"

                [headerHeight]="30"
                [footerHeight]="30"

                [scrollbarV]="true"
                [scrollbarH]="false"

                [loadingIndicator]="true"
                [externalSorting]="true"
                [virtualization]="false"

                appScrollDetector
                [offsetBottom]="200"
            >
                <ngx-datatable-footer>
                    <ng-template
                        ngx-datatable-footer-template
                        let-rowCount="rowCount"
                        let-pageSize="pageSize"
                        let-selectedCount="selectedCount"
                        let-curPage="curPage"
                        let-offset="offset"
                        let-isVisible="isVisible">

                        <!-- _ui_show_all -->
                        <div class="-ms-auto d-flex align-items-center w-100 ">
                            <div class="d-flex align-items-center footer-actions -ms-auto pe-2 ps-2">
                                <a class="me-2  d-none"
                                   (click)="onCaptureScreenshotBtnClicked();"
                                   [ngbTooltip]="('_ui_screenshot' | translate)"
                                >
                                    <i class="fal fa-camera-viewfinder" *ngIf="!isCapturingScreenshot"></i>
                                    <app-loading-indicator [showLogo]="false"
                                                           *ngIf="isCapturingScreenshot"></app-loading-indicator>
                                </a>

                                <a class="text-center me-2 " (click)="loadData()"
                                   style="transform: translateY(-1px);"
                                   [ngbTooltip]="('_ui_reload' | translate) + '...'"
                                   container="body"
                                   placement="right"><i class="fal fa-light fa-arrow-rotate-right"></i></a>
                                <a class="text-center me-2"
                                   [class.disabled]="isPendingRequests"
                                   (click)="listsExpanded ? limitAll() : expandLists()"
                                   style="transform: translateY(-1px);"
                                   [ngbTooltip]="(listsExpanded ? ('_ui_show_less' | translate) : ('_ui_expand_lists' | translate)) + '...'"
                                   container="body"
                                   placement="right">
                                    <i class="fa-duotone "
                                                        [class.fa-circle-chevron-up]="listsExpanded"
                                                        [class.fa-circle-chevron-down]="!listsExpanded"
                                ></i></a>

                                <app-on-screen-filter-selector
                                    [onScreenFilters]="onScreenFilters"
                                ></app-on-screen-filter-selector>

                            </div>

                            <div class="ms-auto pe-0 d-flex align-items-center">
                                <span class="ms-auto cursor-pointer pe-2">
                                    <app-item-counter [total]="usersCount" [amount]="rows.length"></app-item-counter>
                                </span>

                                <button style="border-color: #fff;"
                                        *ngIf="rows && usersCount - rows.length != 0 && rows.length != 0"
                                        (click)="expandUsers()"
                                        class="btn btn-outline-primary btn-micro mt-1 text-light"
                                        >{{'_ui_show_all' | translate  }}</button>
                            </div>
                        </div>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
        </div>

    </div>

    <!-- Bund-tavle -->
    <app-sub-display-wrapper
        placement="bottom"
        [multiDisplayVisibilityObserver]="multiDisplayVisibilityObserver"
        class=" w-100 sub-display sub-display-bottom"
    ></app-sub-display-wrapper>

</div>
