import {BaseComparison} from "@app/shared/_ui/lists/BaseComparison";
import {HasDeadlines} from "@app/interfaces/HasDeadlines";
import {HasConfigurations} from "@app/interfaces/HasConfigurations";

export class NullDeadlineComparison extends BaseComparison {

    private readonly typeId?: number;

    constructor(typeId?: number) {
        super();
        this.typeId = typeId;
    }

    public compare(a: HasDeadlines&HasConfigurations, b: HasDeadlines&HasConfigurations): number {
        const aDeadline = a.findDeadlineByTypeId(this.typeId ?? a.getMiniCardDeadlineTypeId());
        const bDeadline = b.findDeadlineByTypeId(this.typeId ?? b.getMiniCardDeadlineTypeId());
        if (aDeadline == undefined) {
            return -1; // a before b
        } else if (bDeadline == undefined) {
            return 1; // b before a
        } else {
            return 0; // Don't care
        }
    }

}
