import {CardConfiguration} from '@app/shared/_ui/cards/CardConfiguration';
import {TasksUser} from '@app/core/models/TasksUser';
import {TasksDeadline} from '@app/core/models/TasksDeadline';
import {TaskEstimate} from '@app/core/models/TaskEstimate';

export class CardTaskConfiguration extends CardConfiguration {
    public taskEstimate: TaskEstimate;
    public taskDeadline: TasksDeadline;
    public tasksUser: TasksUser;
    public tasksUserDeadline: TasksUser;
    public shownEstimateTypeId?: number;
    public showProjectMiniCard = true;
    public showMilestoneMiniCards = false;
    public excludedCategoryIds: number[];
    public showChangeType = true;              // Vis knap til at skifte type

    // Bestilling
    public tasksUsers: TasksUser[];

    constructor(taskEstimate?: TaskEstimate,
                taskDeadline?: TasksDeadline,
                tasksUserDeadline?: TasksUser,
                tasksUser?: TasksUser
                ) {
        super();
        this.taskEstimate = taskEstimate;
        this.taskDeadline = taskDeadline;
        this.tasksUserDeadline = tasksUserDeadline;
        this.tasksUser = tasksUser;
    }

    get exists(): boolean {
        if (this.tasksUserDeadline) {
            return true;
        }
        if (this.taskDeadline) {
            return true;
        }
        if (this.tasksUsers && this.tasksUsers.length) {
            return true;
        }
        return !!this.tasksUser;
    }

    public compare(config: CardTaskConfiguration): boolean {
        /*let validTaskEstimate = (this.taskEstimate == null && config.taskEstimate == null)
            || (this.taskEstimate != null && config.taskEstimate != null
                && this.taskEstimate.id == config.taskEstimate.id);*/
        let validTaskDeadline = (this.taskDeadline == null && config.taskDeadline == null)
            || (this.taskDeadline != null && config.taskDeadline != null
                && this.taskDeadline.id == config.taskDeadline.id);
        let validTasksUser = (this.tasksUser == null && config.tasksUser == null)
            || (this.tasksUser != null && config.tasksUser != null
                && this.tasksUser.id == config.tasksUser.id);
        let validTasksUserDeadline = (this.tasksUserDeadline == null && config.tasksUserDeadline == null)
            || (this.tasksUserDeadline != null && config.tasksUserDeadline != null
                && this.tasksUserDeadline.user_id == config.tasksUserDeadline.user_id
                && this.tasksUserDeadline.task_user_type_id == config.tasksUserDeadline.task_user_type_id);

        let validTasksUsers = true;
        if (this.tasksUsers != null && config.tasksUsers != null) {
            if (this.tasksUsers.length === config.tasksUsers.length) {

                const aUserIds = this.tasksUsers.map(tasksUser => tasksUser.user_id);
                const bUserIds = config.tasksUsers.map(tasksUser => tasksUser.user_id);
                validTasksUsers = JSON.stringify(aUserIds.sort()) === JSON.stringify(bUserIds.sort());

            } else {
                validTasksUsers = false;
            }
        } else if (this.tasksUsers != null || config.tasksUsers != null) {
            validTasksUsers = false;
        }

        // console.warn(validTaskEstimate , validTaskDeadline , validTasksUser , validTasksUserDeadline);
        return validTaskDeadline && validTasksUser && validTasksUserDeadline && validTasksUsers;
    }
}
