import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns/GenericTableColumn";
import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-users/Columns/TaskEditColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns";
import {TemplateRef} from "@angular/core";
import {Widths} from "@app/constants";

export class TaskEditTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TaskEdit;

    public column: TaskEditColumn;
    public minWidth = Widths.CardColumn * 4;
    public isList = true;

    constructor(column: TaskEditColumn, cellTemplate: TemplateRef<any>, headerTemplate: TemplateRef<any>) {
        super(column);
        this.cellTemplate = cellTemplate;
        this.headerTemplate = headerTemplate;
    }

}
