/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TaskUserTypeDefinition} from './definitions/TaskUserTypeDefinition';
import {AnyUserType} from '@app/interfaces/AnyUserType';

export class TaskUserType extends TaskUserTypeDefinition implements AnyUserType {

    // OTF
    required?: boolean;
    multiple?: boolean;
    visible?: boolean;
    index_?: number;

    constructor(json?: any) {
        super(json);
    }

    get title(): string {
        if (this.name != '') {
            return this.name;
        }
        if(this.language_key){
            return this.language_key;
        }
    }

}
