import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {ColumnTypes} from "@app/pages/displays/display-kanban/ColumnTypes";
import {PrioritizationColumn} from "@app/pages/displays/display-kanban/Columns/PrioritizationColumn";
import {Column, ColumnSetting} from "@app/core/models";

export class PrioritizationColumnType extends BaseColumnType {

    public identifier = ColumnTypes.Prioritization;

    public isAlwaysVisible = false;
    public customWidth?: number;
    public minWidth = 214;
    public maxWidth?: number;
    public resizeable = true;
    public frozenLeft = false;
    public canAutoResize = true;
    public isList = false;
    public cellClass?: string;

    public createColumn(column: Column, settings: Map<string, ColumnSetting>): BaseColumn {
        return new PrioritizationColumn(this, column, settings);
    }

}
