import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {Milestone} from '@app/core/models/Milestone';
import {Subscription} from 'rxjs';
import {OldApi} from '@app/http/Api';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {MilestoneListConfiguration} from '@app/shared/_ui/lists/milestone-list/MilestoneListConfiguration';
import Helpers from '@app/core/helpers';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {CardMilestoneConfiguration} from '@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration';
import {EventService} from '@app/services/event.service';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {CSVListBinding} from '@app/export/csv/CSVListBinding';
import {CSVExportOptions} from '@app/shared/_ui/lists/milestone-list/CSVExportOptions';
import {CSVExporter} from '@app/export/csv/CSVExporter';
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {MilestoneFetcher} from "@app/shared/_ui/lists/milestone-list/MilestoneFetcher";

@Component({
    selector: 'app-milestone-list',
    // standalone: true,
    templateUrl: './milestone-list.component.html',
    styleUrls: ['./milestone-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // imports: [CommonModule],
})
export class MilestoneListComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @Output() dataSetChanged = new EventEmitter<CardItem<Milestone>[]>();
    @Input() configuration: MilestoneListConfiguration;
    @Input() listClass: string = 'grid-3';
    @Input() minimizeEvent: EventEmitter<any>;
    @Input() loadAllEvent: EventEmitter<any>;
    @Input() reloadEvent: EventEmitter<any>;
    @Input() scrollContainer: HTMLElement;
    @Input() csvListBinding: CSVListBinding;

    // Data
    public items: CardItem<Milestone>[];
    private itemsMap: Map<number, CardItem<Milestone>>;
    public offset = 0;
    public itemCount = 0;
    private loadAllEventSubscription?: Subscription;

    constructor(private eventService: EventService,
                private cd: ChangeDetectorRef,
                private filterGlobalService: FilterGlobalService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        if (!this.configuration) {
            this.configuration = new MilestoneListConfiguration();
        }

        if (this.minimizeEvent) {
            this.minimizeEvent.subscribe(() => {
                this.items.splice(this.configuration.getLimit());
                this.itemsMap.clear();
                this.items.forEach(item => this.itemsMap.set(item.item.id, item));
                this.offset = 0;
                this.items = [...this.items];
                this.detectChanges();
            });
        }

        if (this.reloadEvent) {
            this.reloadEvent.subscribe(() => {
                if (this.configuration.hasSmartLimit()) {
                    return;
                }
                this.loadData();
            });
        }

        // Push
        this.eventService.subscribeToMilestone(0, event => {
            switch (event.action) {
                case EventService.Created:
                    this.milestoneCreated(event.item, event.fields);
                    break;
                case EventService.Updated:
                    this.milestoneUpdated(event.item, event.fields);
                    break;
            }
        });
    }

    private smartLimitSubscription: Subscription;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['loadAllEvent']) {
            if (this.loadAllEvent) {
                this.loadAllEventSubscription?.unsubscribe();
                this.loadAllEventSubscription = this.loadAllEvent.subscribe(() => {
                    this.loadAll();
                });
            }
        }
        if (changes['configuration'] != null && this.configuration) {
            changes['configuration'].previousValue?.pushUnsubscribe();

            const onAddItemsListener = (items: CardItem<Milestone>[]) => {
                // Reset
                this.offset = 0;
                this.itemCount = 0;
                this.itemsMap = new Map();

                // Add items
                this.items = items;
                items.forEach(item => this.itemsMap.set(item.item.id, item));
                this.configuration.setLastItemCount(items.length);

                this.isLoading = false;
                this.detectChanges();
                this.dataSetChanged.emit(this.items);
            };
            const onAddItemListener = (item: CardItem<Milestone>, index?: number) => {
                if (!this.itemsMap?.has(item.item.id)) { // Add if not found
                    if (!this.items) {
                        this.items = [];
                    }
                    if (index !== undefined) {
                        this.items.splice(index, 0, item);
                    } else {
                        this.items.push(item);
                    }
                    this.itemCount++;
                } else { // Replace existing card
                    this.items[this.items.indexOf(this.itemsMap.get(item.item.id))] = item;
                }
                if (!this.itemsMap) {
                    this.itemsMap = new Map();
                }
                this.itemsMap.set(item.item.id, item);
                this.configuration.setLastItemCount(this.items.length);
                this.detectChanges();
                this.dataSetChanged.emit(this.items);
            };
            const onRemoveItemListener = (item: CardItem<Milestone>) => {
                this.removeItem(item.item);
                this.detectChanges();
            };
            const onCountListener = (count: number) => {
                this.itemCount = count;
                this.detectChanges();
            };
            this.configuration.addDataListeners(onAddItemsListener, onAddItemListener, onRemoveItemListener, onCountListener);

            this.configuration.pushUnsubscribe();
            this.smartLimitSubscription?.unsubscribe();
            this.configuration.pushSubscribe();

            if (this.configuration.getDataSource()) {
                this.apiRequest?.unsubscribe();
            } else if (this.configuration.hasSmartLimit()) { // Wait for SmartLimit
                this.smartLimitSubscription = this.configuration.onLimitSetEvent.subscribe((limit: number) => this.loadData());
            } else {
                this.loadData();
            }
        }

        // CSV Export
        if (this.csvListBinding) {
            this.csvListBinding.setExportFunction(option => {
                switch (option.type) {
                    case CSVExportOptions.Name:
                        return [
                            '',
                            ...this.items.map(milestone => milestone.item.name ?? '')
                        ];
                    case CSVExportOptions.Deadline:
                        return [
                            '',
                            ...this.items.map(milestone => {
                                if (milestone.item.deadline) {
                                    return CSVExporter.convertToDate(milestone.item.deadline.getDate());
                                } else {
                                    return '';
                                }
                            })
                        ];
                    default:
                        return '';
                }

            });
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.configuration?.pushUnsubscribe();
    }

    // <editor-fold desc="View Actions">

    loadMore(limit?: number) {
        if (!limit) {
            limit = this.configuration.getLimit();
        }
        if (this.configuration.getDataSource()) {
            this.configuration.getDataSource().requestSlice(0, this.offset + limit);
        } else {
            this.loadItems(this.offset + limit);
        }
    }

    loadLess() {
        this.offset = 0;
        this.items = this.items.slice(0, this.configuration.getLimit());
        this.dataSetChanged.emit(this.items);
        this.detectChanges();
    }

    loadAll() {
        if (this.configuration.getDataSource()) {
            this.configuration.getDataSource().requestSlice(0, this.itemCount);
        } else {
            this.loadItems(this.offset + this.configuration.getLimit(), false, true);
        }
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    private loadData() {
        this.items = [];
        this.itemsMap = new Map();
        this.itemCount = 0;

        if (this.configuration?.getDataSource()) {
            const fetcher = new MilestoneFetcher();
            fetcher.addRequest(this.configuration.getDataSource());
            fetcher.execute();
        } else {
            this.loadItems(0, true);
        }
    }

    private apiRequest: Subscription;

    private loadItems(offset: number, doCount: boolean = false, loadAll: boolean = false) {
        if (this.apiRequest) {
            this.apiRequest.unsubscribe();
        }
        this.isLoading = true;
        let api = OldApi.milestones()
            .include('responsible');

        // if(this.items) api.whereNotIn('id', this.items.map(i => i.item.id));

        if (this.configuration.getProject() || this.configuration.getShowProjectMiniCard())
            api.include('project');

        if (this.configuration.getIncludeRelatedTasks())
            api.include('task');

        if (this.configuration.getProject()) {
            api.where('project.id', this.configuration.getProject().id);
        }
        if (this.configuration.getResponsible()) {
            api.where('responsible_id', this.configuration.getResponsible().id);
        }
        if (this.configuration.getDeadlineDateMin()) {
            api.whereGreaterThanOrEqual('deadline.date', Helpers.serverDate(this.configuration.getDeadlineDateMin()));
        }
        if (this.configuration.getDeadlineDateMinOrNull()) {
            api.whereGreaterThanOrEqual('deadline.date_or_null', Helpers.serverDate(this.configuration.getDeadlineDateMinOrNull()));
        }
        if (this.configuration.getDeadlineDateMax()) {
            api.whereLessThanOrEqual('deadline.date', Helpers.serverDate(this.configuration.getDeadlineDateMax()));
        }
        if (this.configuration.getArchived() != null) {
            if (this.configuration.getArchived() === true) {
                api.whereGreaterThan('archived_id', 0);
            } else {
                api.where('archived_id', 0);
            }
        }
        if (this.configuration.getAvoidIds()) {
            api.whereNotIn('id', this.configuration.getAvoidIds());
        }
        if (this.configuration.getProjectTypeIds()) {
            api.whereIn('project.project_type_id_or_null', this.configuration.getProjectTypeIds());
        }
        if (this.configuration.getUseGlobalFilter()) {
            let filter = this.filterGlobalService.getActiveSettings();
            if (this.configuration.getUseGlobalSearchFilter() && filter.search?.length > 0) {
                api.search('name', filter.search);
            }
            if (filter.isStarred) {
                api.whereGreaterThan('num_stars', 0);
            }
            if (filter.activeStatuses.length < 4 && filter.activeStatuses.length > 0) {
                api.whereIn('main_status.status_id', filter.activeStatuses);
            }
            if (filter.activeReactionFilters?.length > 0) {
                filter.activeReactionFilters.forEach(reactionFilter => {
                    api.whereIn('reaction_filters', [reactionFilter.reaction_type_id, reactionFilter.value]);
                });
            }
        }
        if (this.configuration.getSearch() && this.configuration.getSearch().length > 0) {
            api.search('name', this.configuration.getSearch());
        }

        this.configuration.getOrderBy().forEach(orderBy => api.orderBy(orderBy[0], orderBy[1]));
        this.apiRequest = api
            .setOffset(offset)
            .setLimit(loadAll ? 100 : this.configuration.getLimit())
            .get(Milestone.name)
            .subscribe((items: Milestone[]) => {
                this.offset = offset;
                items.forEach(item => this.addItem(item));
                this.items = [...this.items]; // Force change detection
                this.dataSetChanged.emit(this.items);
                this.isLoading = false;
                this.detectChanges();
            });

        if (doCount) {
            api.count(count => {
                this.itemCount = count;
                this.detectChanges();
            });
        }
    }

    private addItem(item: Milestone) {
        if (this.items.findIndex(ci => ci.item.id == item.id) === -1) {
            let cardItem = new CardItem<Milestone>(item, new CardMilestoneConfiguration(this.configuration.getShowProjectMiniCard()));
            this.items.push(cardItem);
            this.itemsMap.set(item.id, cardItem);
            this.configuration.setLastItemCount(this.items.length);
        }
    }

    private removeItem(item: Milestone) {
        if (this.itemsMap?.has(item.id)) {
            this.items.splice(this.items.indexOf(this.itemsMap.get(item.id)), 1);
            this.itemsMap.delete(item.id);
            this.itemCount--;
            this.dataSetChanged.emit(this.items);
            this.configuration.setLastItemCount(this.items.length);
            this.offset = Math.max(0, this.offset - 1);
            if (this.itemCount > this.items.length) {
                this.loadMore(this.items.length + 1);
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="Push">

    private milestoneCreated(milestone: Milestone, fields: string[]) {
        if (this.configuration.validate(milestone)) {        // Milestone is OK
            if (this.items.length == this.itemCount) {       // All milestone is shown, append list now
                this.addItem(milestone);
                this.dataSetChanged.emit(this.items);
            }
            this.itemCount++;                               // Increment count
            this.detectChanges();
        }
    }

    private milestoneUpdated(milestone: Milestone, fields: string[]) {
        if (!this.itemsMap.has(milestone.id)) {
            this.milestoneCreated(milestone, fields);
        } else {
            // Fjernet: https://podio.com/klartboard/softwareudvikling/apps/stories/items/1511


            // Ved push fra anden kilde, patch data for item i denne liste og valider
            // let oldItem = this.itemsMap.get(milestone.id).item;
            // oldItem.populate(milestone, (fields != null && fields.length > 0));

            // if (!this.configuration.validate(oldItem)) {
            //     this.removeItem(oldItem);
            //     this.detectChanges();
            // }
            this.detectChanges();
        }
    }

    // </editor-fold>

}
