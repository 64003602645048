/**
 * Created by ModelParser
 */
import {Display} from '../Display';
import {Role} from '../Role';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class DisplaysRoleDefinition extends BaseModel {
    display_id?: number;
    display?: Display;
    role_id?: number;
    role?: Role;
    type?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.display_id;
            delete this.display;
            delete this.role_id;
            delete this.role;
            delete this.type;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.display_id != null) {
            this.display_id = data.display_id;
        }
        if (data.display != null) {
            this.display = new Display(data.display);
        }
        if (data.role_id != null) {
            this.role_id = data.role_id;
        }
        if (data.role != null) {
            this.role = new Role(data.role);
        }
        if (data.type != null) {
            this.type = data.type;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
