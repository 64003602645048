<div class="form-group flex-grow-1 -align-items-center m-0 p-0" *ngIf="types && types.length > 0">
    <label *ngIf="configuration && configuration?.labelName">{{ configuration.labelName | translate }}</label>
    <ul class="list-group" >
        <li class="list-group-item" *ngFor="let type of types">
            <div class="form-group mb-0 flex-grow-1 align-items-center d-flex">
                <label>
                    <span>{{ type.name | translate }}</span>
                </label>
                <div class="rounded">
                    <app-status-selector
                        [item]="model"
                        [status_id]="type.status"
                        [items]="type.optionsAsObjects"
                        (valueChanged)="onValueChanged(type, $event)"
                    />
                </div>
            </div>
        </li>
    </ul>
</div>
