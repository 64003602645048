<div class="task-wrap">
    <app-color-label class="card-type me-1"
                     [status]="item.item.status"
                     [itemWithTypeProperties]="item.item"
                     [interactive]="false"
                     [tooltip]="title ? title : null"
                     [style.width.px]="item.width ? item.width : null"

                     (click)="popover.toggle()"
                     [ngbPopover]="popoverContentTask"
                     [autoClose]="false"

                     triggers="manual"
                     container="body"
                     #popover="ngbPopover"
                     [popoverClass]="'popover-sub-header'"
                     [placement]="['bottom','auto']"
    ></app-color-label>
    <div class="extra-badge"
         *ngIf="item.relatedTasks.length > 0 ">{{ (item.relatedTasks.length + 1) }}
    </div>
</div>

<ng-template #popoverContentTask>
    <div class="popover-container sub-header">

        <div class="rounded mb-1 p-1 milestone-and-tasks"
             [class.mb-1]="item.relatedTasks?.length > 0"
        >

            <div class="d-flex align-items-center item-wrapper">
                <app-color-label class="card-type me-1 flex-shrink-0"
                                 [status]="item.item.status"
                                 [itemWithTypeProperties]="item.item"
                                 [interactive]="false"
                                 [style.width.px]="24"
                ></app-color-label>
                <span class="ellipsis">{{ item.item.title }}</span>
                <span class="ms-auto"
                      *ngIf="item.item.findDeadlineByTypeId(item.item.getMiniCardDeadlineTypeId())">{{ item.item.findDeadlineByTypeId(item.item.getMiniCardDeadlineTypeId())?.getDate() | localizedDate: 'microDate' }}</span>
            </div>

            <ng-container *ngFor="let related of item.relatedTasks">
                <div class="d-flex align-items-center item-wrapper">
                    <app-color-label class="card-type me-1 flex-shrink-0"
                                     [status]="related.item.status"
                                     [itemWithTypeProperties]="related.item"
                                     [interactive]="false"
                                     [style.width.px]="24"
                    ></app-color-label>
                    <span class="ellipsis">{{ related.item.title }}</span>
                    <span class="ms-auto"
                          *ngIf="related.item.findDeadlineByTypeId(related.item.getMiniCardDeadlineTypeId())">{{ related.item.findDeadlineByTypeId(related.item.getMiniCardDeadlineTypeId())?.getDate() | localizedDate: 'microDate' }}</span>
                </div>
            </ng-container>
        </div>

    </div>
</ng-template>
