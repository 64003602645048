import {Project, Task} from '@app/core/models';
import {Milestone} from '@app/core/models/Milestone';
import {CreateItemInterface} from '@app/shared/_ui/create-item-dropdown/CreateItemInterface';
import {Todo} from '@app/core/models/Todo';
import {Appointment} from '@app/core/models/Appointment';

export class CreateItemPreset {
    public createTaskInterface: CreateItemInterface<Task>;
    public createProjectInterface: CreateItemInterface<Project>;
    public createMilestoneInterface: CreateItemInterface<Milestone>;
    public createTodoInterface: CreateItemInterface<Todo>;
    public createAppointmentInterface: CreateItemInterface<Appointment>;
}
