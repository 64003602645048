import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NonPlannedItemListComponent} from '@app/editor/non-planned-itemlist/non-planned-item-list.component';
import {NgbNavModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TaskListModule} from "@app/shared/_ui/lists/task-list/TaskList.module";
import {ProjectListModule} from "@app/shared/_ui/lists/project-list/ProjectList.module";
import {GhostContainerModule} from "@app/editor/ghost-container/GhostContainer.module";
import {ColorItemModule} from '@app/shared/_ui/color-item/ColorItem.module';

@NgModule({

    imports: [
        CommonModule,
        TranslateModule,
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        BrowserAnimationsModule,
        NgbNavModule,
        TaskListModule,
        ProjectListModule,
        GhostContainerModule,
        ColorItemModule,
        NgbTooltipModule,
    ],
    declarations: [
        NonPlannedItemListComponent,
    ],
    exports: [
        NonPlannedItemListComponent

    ]
})
export class DisplayNonPlannedModule {
}
