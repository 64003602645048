<div class="d-flex flex-column">



    <div class="d-flex border-bottom gap-1 flex-row align-items-center pb-2 mb-2">

        <div
            class="ps-2 d-flex flex-row justify-content-between align-items-center  cursor-pointer gap-1"
            (click)="editFilter(null); $event.stopPropagation(); onCloseEvent.emit(); ">
            <i class="fal fa-plus-square "></i>
            <span class="flex-fill ps-1 ellipsis text-small" [translate]="'_ui_header_define_new_filter'"></span>
        </div>

        <div class="ms-auto text-small">
        <app-toggle-item [interactive]="true"
                         [active]="editMode"
                         [iconColorActive]="'text-success'"
                         [icon]="'fa-check-circle'"
                         [iconInactive]="'fa-circle'"
                         [label]="'_ui_expanded_view' | translate"
                         (onToggle)="toggleEditMode()"></app-toggle-item>
        </div>
    </div>



    <ng-container *ngIf="filterFavorites?.length > 0">
        <h4 class="w-100 mb-0 popover-title">
            <i class="fal fa-star"></i>
            {{'_ui_header_define_filter_favorites' | translate}}</h4>


        <div class="rounded border mb-1 d-flex  flex-column bg-light scroll-y"

             cdkDropList
             (cdkDropListDropped)="dropFavorite($event)"
        >

            <ng-container
                *ngFor="let filter of filterFavorites; let i = index"
            >
                <app-header-title-filter-item
                    [item]="filter"
                    cdkDrag
                    [filterPageData]="shellPageData"
                    [canMoveUp]="i != 0"
                    [canMoveDown]="i + 1 !== filterFavorites.length"
                    [dragAndDrop]="true"
                    [actionsVisible]="editMode"

                    (moveFilterUpClickEvent)="moveFavoriteUp($event)"
                    (moveFilterDownClickEvent)="moveFavoriteDown($event)"
                    (deleteClickEvent)="deleteFilter($event)"
                    (removeDepartmentFromFilterClickEvent)="removeDepartmentFromFilter($event)"
                    (setFavoriteClickEvent)="setFavorite($event)"
                    (removeFavoriteClickEvent)="removeFavorite($event)"
                    (setDefaultClickEvent)="setDefault($event)"
                    (setVisibilityClickEvent)="setVisibility(filter, $event)"
                    (closeClickEvent)="onCloseEvent.emit()"
                ></app-header-title-filter-item>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="filterNonFavorites?.length > 0">
        <h4 class="w-100 mb-0 popover-title">{{'_ui_header_filters_non_favorites' | translate}}</h4>
        <div class="rounded border mb-1 d-flex flex-column bg-light scroll-y"
                cdkDropList
                (cdkDropListDropped)="dropNonFavorite($event)"
        >
            <ng-container
                *ngFor="let filter of filterNonFavorites; let i = index"
            >
                <app-header-title-filter-item
                    [item]="filter"
                    [filterPageData]="shellPageData"
                    [canMoveUp]="i != 0"
                    [canMoveDown]="i + 1 !== filterNonFavorites.length"
                    [dragAndDrop]="true"
                    cdkDrag
                    [actionsVisible]="editMode"
                    (moveFilterUpClickEvent)="moveFilterUp($event)"
                    (moveFilterDownClickEvent)="moveFilterDown($event)"
                    (deleteClickEvent)="deleteFilter($event)"
                    (removeDepartmentFromFilterClickEvent)="removeDepartmentFromFilter($event)"
                    (setFavoriteClickEvent)="setFavorite($event)"
                    (removeFavoriteClickEvent)="removeFavorite($event)"
                    (setDefaultClickEvent)="setDefault($event)"
                    (setVisibilityClickEvent)="setVisibility(filter, $event)"
                    (closeClickEvent)="onCloseEvent.emit()"
                ></app-header-title-filter-item>
            </ng-container>
        </div>
    </ng-container>

</div>
