import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {fadeAnimation} from '@app/animations';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {MilestonePlan, Project} from "@app/core/models";
import {Api} from "@app/core/http/Api/Api";
import {Router} from "@angular/router";
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-milestone-plan-select-item',
    templateUrl: './milestone-plan-select-item.component.html',
    styleUrls: ['./milestone-plan-select-item.component.scss'],
    animations: [fadeAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestonePlanSelectItemComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() milestonePlan: MilestonePlan;
    @Input() project: Project;
    @Input() disabled = false;
    @Input() interactive = true;
    @Input() showTitle = true;
    @Input() showEditable = true;
    @Input() showDelete = true;

    // Events
    @Output() onClickEvent: EventEmitter<MilestonePlan> = new EventEmitter<MilestonePlan>();

    // UI
    public isPersonal = false;
    public isTeam = false;
    public isGlobal = false;
    public isEditable = false;
    public isDeletable = false;
    public warningTooltip: string;

    constructor(
        private cd: ChangeDetectorRef,
        private router: Router,
        private dialogService: BaseDialogService,
        private translateService: TranslateService
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.render();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.milestonePlan) {
            // console.log('changes : MilestonePlanSelectItemComponent : ', changes.milestonePlan)
            this.render();
        }
    }

    public onClick() {
        if(this.interactive){
            if (this.disabled || this.warningTooltip)
                return;

            this.onClickEvent.emit(this.milestonePlan);
        }else {
            if(this.showEditable) {
                this.onEditClick();
            }
        }
    }

    public onEditClick() {
        // this.dialogService.editMilestonePlanDialog(this.milestonePlan.id);
        this.router.navigate(['/app', 'administrator', 'templates', this.milestonePlan.id]); // Change route
    }

    public onDeleteClick() {
        this.dialogService.confirm(
            this.translateService.instant('_global_delete'),
            this.translateService.instant(`_ui_delete_item`, {name: this.milestonePlan.title}),
            this.translateService.instant('_global_delete'),
        )
            .then((confirmed) => {
                if (confirmed) {
                    Api.milestonePlans().deleteById(this.milestonePlan.id).delete(value => {
                        MilestonePlan.InvalidateMilestonePlansCache();
                    });
                }
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    private render() {
        this.isPersonal = this.milestonePlan?.isPersonal();
        this.isTeam = this.milestonePlan?.isTeam();
        this.isGlobal = this.milestonePlan?.isGlobal();
        this.isEditable = this.milestonePlan?.isEditableForUser();
        this.isDeletable = this.milestonePlan?.isEditableForUser();
        if (this.milestonePlan && this.milestonePlan.start_project_deadline_type_id == 0 || this.milestonePlan.end_project_deadline_type_id == 0) {
            this.warningTooltip = this.milestonePlan.start_project_deadline_type_id == 0 ? this.translateService.instant('_ui_milestone_plan_missing_deadline_type_start') : this.translateService.instant('_ui_milestone_plan_missing_deadline_type_end');
        } else {
            this.warningTooltip = null;
        }
        this.detectChanges();
    }

}
