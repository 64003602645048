/**
 * Created by ModelParser
 */
import {MilestonePlan} from '../MilestonePlan';
import {MilestoneTemplate} from '../MilestoneTemplate';
import {TaskTemplatesUser} from '../TaskTemplatesUser';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class TaskTemplateDefinition extends BaseModel {
    title?: string;
    deadline_value?: number;
    deadline_type?: number;
    time?: string;
    milestone_plan_id?: number;
    milestone_plan?: MilestonePlan;
    milestone_template_id?: number;
    milestone_template?: MilestoneTemplate;
    estimate_value?: number;
    estimate_unit_id?: number;
    delivery_description?: string;
    purpose?: string;
    index_?: number;
    task_template_users?: TaskTemplatesUser[];
    users?: User[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.title;
            delete this.deadline_value;
            delete this.deadline_type;
            delete this.time;
            delete this.milestone_plan_id;
            delete this.milestone_plan;
            delete this.milestone_template_id;
            delete this.milestone_template;
            delete this.estimate_value;
            delete this.estimate_unit_id;
            delete this.delivery_description;
            delete this.purpose;
            delete this.index_;
            delete this.task_template_users;
            delete this.users;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.title != null) {
            this.title = data.title;
        }
        if (data.deadline_value != null) {
            this.deadline_value = data.deadline_value;
        }
        if (data.deadline_type != null) {
            this.deadline_type = data.deadline_type;
        }
        if (data.time != null) {
            this.time = data.time;
        }
        if (data.milestone_plan_id != null) {
            this.milestone_plan_id = data.milestone_plan_id;
        }
        if (data.milestone_plan != null) {
            this.milestone_plan = new MilestonePlan(data.milestone_plan);
        }
        if (data.milestone_template_id != null) {
            this.milestone_template_id = data.milestone_template_id;
        }
        if (data.milestone_template != null) {
            this.milestone_template = new MilestoneTemplate(data.milestone_template);
        }
        if (data.estimate_value != null) {
            this.estimate_value = data.estimate_value;
        }
        if (data.estimate_unit_id != null) {
            this.estimate_unit_id = data.estimate_unit_id;
        }
        if (data.delivery_description != null) {
            this.delivery_description = data.delivery_description;
        }
        if (data.purpose != null) {
            this.purpose = data.purpose;
        }
        if (data.index_ != null) {
            this.index_ = data.index_;
        }
        if (data.task_template_users != null) {
            this.task_template_users = data.task_template_users.map((i: any) => new TaskTemplatesUser(i));
        }
        if (data.users != null) {
            this.users = data.users.map((i: any) => new User(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
