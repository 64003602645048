/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {PeriodRatingDefinition} from './definitions/PeriodRatingDefinition';
import {OldApi} from '@app/http/Api';

export class PeriodRating extends PeriodRatingDefinition {

    constructor(json?: any) {
        super(json);
    }

    public patch(fields: string[]) {
        let data: {} = {};
        for(let field of fields) data[field] = this[field];
        OldApi.periodRatings(this.id).patch(data).subscribe();
    }

    public get loadInSeconds(): number {
        return (this.load_ ?? 0) / 60 / 60;
    }

}
