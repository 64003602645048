<ul class="list-group">

    <li class="list-group-item d-flex justify-content-between flex-row align-items-center"
        *ngIf="items?.length > 0">
        <div class="d-flex flex-row flex-fill align-items-center w-100">
            <div class="flex-grow-1 flex-shrink-1 me-1">
                <label class="mb-0" >{{'_ui_change_task_user_deadline' | translate}}</label>
            </div>
            <app-datepicker
                [autoReset]="true"
                (valueChanged)="setDeadlineForAll($event)"
                class="mb-0 align-self-end flex-grow-1 flex-shrink-1"
                style="max-width: 263px; min-width: 220px; padding-right: 39px;"></app-datepicker>
        </div>
    </li>

    <li *ngFor="let item of (items ?? [])" class="list-group-item">
        <app-who-when-edit-item
            [item]="item"
            [selectedUsers]="selectedUsers"
            (onSetDeadlineEvent)="setDeadlineForItem(item, $event)"
            (onSetArchived)="setArchivedForItem(item, $event)"
            (onSetUserEvent)="setUserForItem(item, $event)"
            (onRemoveEvent)="removeItem(item)"
        ></app-who-when-edit-item>
    </li>

    <li class="list-group-item d-flex justify-content-between flex-row align-items-center">
        <div class="d-flex flex-column flex-fill align-items-start w-100">
            <div class="d-flex flex-column justify-content-between align-items-center w-100 ">
                <app-user-search
                    [selectedUsers]="selectedUsers"
                    [autoReset]="true"
                    (itemSelected)="addItem($event)"
                    class="w-100 mb-1"
                    style="min-width: 246px;"
                ></app-user-search>
                <app-department-users-picker
                    class="w-100"
                    (valueChanged)="addAllFromDepartment($event)"
                ></app-department-users-picker>
            </div>
        </div>
    </li>
</ul>
