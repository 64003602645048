import {NgModule} from "@angular/core";
import {TaskListComponent} from "@app/shared/_ui/lists/task-list/task-list.component";
import {CardExpanderModule} from "@app/shared/_ui/displays/card-expander/CardExpander.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        CardExpanderModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TaskListComponent,
    ],
    exports: [
        TaskListComponent

    ],
    providers: [

    ],
})
export class TaskListModule {}
