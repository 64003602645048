import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {
    ProjectDeadlineDifferenceColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/ProjectDeadlineDifferenceColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns";

export class ProjectDeadlineDifferenceTableColumn extends GenericTableColumn {

    public identifier = TableColumns.ProjectDeadlineDifference;

    public column: ProjectDeadlineDifferenceColumn;

}
