import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {EventEmitter} from "@angular/core";
import {EditTitleValidator} from '@app/editor/quick-editor/editors/generic/title-editor/EditTitleValidator';

export class EditTitleConfiguration extends BaseEditorConfiguration {

    public labelName: string;
    public isExpanded: boolean = false;
    public autofocus: boolean;

    public onChangeEvent = new EventEmitter();
    public validator: EditTitleValidator;

    constructor(labelName: string, autofocus: boolean, validator: EditTitleValidator) {
        super();
        this.labelName = labelName;
        this.autofocus = autofocus;
        this.validator = validator;
    }

    public setIsExpanded(value: boolean) {
        this.isExpanded = value;
        this.onChangeEvent.emit();
    }

}
