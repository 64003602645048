import {AnyItem} from '@app/interfaces/CustomTypes';
import {EventEmitter} from "@angular/core";

export class CardConfiguration<T = AnyItem> {

    public isGrayedOut = false;
    public useGlobalFilter = false;
    public isDraggable = false;
    public wasDragged = false;
    public inline: boolean = false; // Show as inline element
    public displayModeMini?: boolean; // Show as inline element

    public onChangeEvent = new EventEmitter();

    constructor() {

    }

    public emitChange() {
        this.onChangeEvent.emit();
    }

}
