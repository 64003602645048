<div
    *ngIf="isReady"
    class="container p-2 d-flex gap-1 w-100 flex-wrap editor-tab"
>
    <div
        *ngIf="item.created_by"
        class="form-group"
    >
        <label><span>{{'_project_created_by' | translate}}</span></label>
        <p>{{item.created_by.name}}</p>
    </div>

    <app-user-list-editor
        [model]="item"
        [configuration]="userListEditorConfiguration"
    />
</div>
