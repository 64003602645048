import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import Helpers from '@app/core/helpers';
import * as moment from 'moment';
import {Api} from "@app/core/http/Api/Api";
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {
    BlockedPeriodItem
} from "@app/shared/_ui/displays/milestone-plan/template-blocked-period-picker/blocked-period-item";

@Component({
    selector: 'app-template-blocked-period-picker',
    templateUrl: './template-blocked-period-picker.component.html',
    styleUrls: ['./template-blocked-period-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateBlockedPeriodPickerComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    // Input
    @Input() milestonePlanId: number;

    // Output
    @Output() addItemEvent = new EventEmitter<BlockedPeriodItem>();
    @Output() removeItemEvent = new EventEmitter<BlockedPeriodItem>();

    // View bindings
    public blockedPeriodItems: BlockedPeriodItem[] = [];

    constructor(private _cdr: ChangeDetectorRef) {
        super();
        this.cdr = _cdr;

    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes && changes['milestonePlanId'] && changes['milestonePlanId'].previousValue != changes['milestonePlanId'].currentValue){
            this.loadBlockedPeriods();
        }
    }

    ngOnInit(): void {

    }

    private loadBlockedPeriods() {
        Api.milestonePlans().blockedPeriodsGetById(this.milestonePlanId).find(blockedPeriods => {
            blockedPeriods.forEach(bp => {
                const bpi = new BlockedPeriodItem(bp);
                this.subscribe(bpi.changeEmitter.subscribe(()=>{
                    this.detectChanges();
                }));
                this.blockedPeriodItems.push(bpi);
            })
            this.detectChanges();
        });
    }

    createItem() {
        const bi = new BlockedPeriodItem();
        this.blockedPeriodItems.push(bi);
        this.subscribe(bi.changeEmitter.subscribe(()=>{
            this.detectChanges();
        }));
        this.detectChanges();
    }

    onDateChange($event: { start: Date; end: Date }, blockedPeriodItem: BlockedPeriodItem) {
        blockedPeriodItem.start = $event.start;
        blockedPeriodItem.end = $event.end;
        blockedPeriodItem.blockedPeriod.start = Helpers.serverDate(moment($event.start).toDate());
        blockedPeriodItem.blockedPeriod.end = Helpers.serverDate(moment($event.end).toDate());
        this.addItemEvent.emit(blockedPeriodItem);
        this.detectChanges();
    }

    removeItem(blockedPeriodItem: BlockedPeriodItem) {
        this.blockedPeriodItems = this.blockedPeriodItems.filter(bpi => bpi !== blockedPeriodItem);
        this.removeItemEvent.emit(blockedPeriodItem);
        blockedPeriodItem.changeEmitter.unsubscribe();
        this.detectChanges();
    }


}
