import {NgModule} from "@angular/core";
import {ProjectSearchComponent} from "@app/shared/_forms/search/project-search/project-search.component";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {NgbPopoverModule, NgbTooltipModule, NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {DepartmentPickerModule} from "@app/shared/_forms/department-picker/DepartmentPicker.module";
import {FormsModule} from "@angular/forms";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";
import {LoadingIndicatorModule} from '@app/shared/_ui/loading-indicator/LoadingIndicator.module';
import {
    ValidationWarningListModule
} from '@app/editor/base-editor-v3/components/validation-warning-list/validation-warning-list.module';
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";

@NgModule({
    imports: [
        CommonModule,
        ColorLabelModule,
        NgbTypeaheadModule,
        NgbTooltipModule,
        TranslateModule,
        NgbPopoverModule,
        DepartmentPickerModule,
        FormsModule,
        CardsModule,
        LoadingIndicatorModule,
        ValidationWarningListModule,
        TypedTemplateDirective

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectSearchComponent,
    ],
    exports: [
        ProjectSearchComponent

    ],
    providers: [

    ],
})
export class ProjectSearchModule {}
