/**
 * Created by ModelParser
 * Date: 17-08-2020.
 * Time: 09:10.
 */
import {DisplayFiltersSettingDefinition} from './definitions/DisplayFiltersSettingDefinition';

export class DisplayFiltersSetting extends DisplayFiltersSettingDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static Create(settingId: number): DisplayFiltersSetting {
        const item = new DisplayFiltersSetting();
        item.setting_id = settingId;
        return item;
    }

}
