import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {
    ProjectStatusListColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/ProjectStatusListColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns";

export class ProjectStatusListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.ProjectStatusList;

    public column: ProjectStatusListColumn;

}
