<ng-container *ngIf="loadCalculateResponse">
    <ng-template #popContent>
        <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>
        <div class="scroll-y popover-wrapper">

                <div class="w-100 font-weight-bold pb-2 mb-1 border-bottom">{{periodRating.start | localizedDate}} - {{periodRating.end | localizedDate}}</div>

                <ul class="list-unstyled d-flex flex-column m-0 w-100 ">

                    <li class="d-flex flex-row justify-content-start mb-1 pe-1"
                        *ngIf="showLoad"
                    >
                        <span class="w-150px">{{'_ui_planned_time' | translate}}:</span>
                        <span class="ms-auto mw-50px text-center ps-2 pe-2">{{((totalLoad ? totalLoad : 0) / 60 / 60) | number : '1.0-0'}}</span>
                    </li>

                    <li class="d-flex flex-row justify-content-start mb-1 pe-1">
                        <span class="w-150px">{{'_ui_tasks' | translate}}:</span>
                        <span class="ms-auto mw-50px text-center  ps-2 pe-2">{{(loadCalculateResponse?.load.tasks / 60 / 60) | number : '1.0-0'}}</span>
                    </li>

                    <li class="d-flex flex-row justify-content-start mb-1">
                        <span class="w-150px">{{'_ui_ad_hoc' | translate}}:</span>
                        <app-numeric-spinner (onChange)="loadChanged($event)"
                                             (blur)="loadChanged($any($event))"
                                             [fullWidth]="true"
                                             [value]="periodRating.loadInSeconds"
                                             class="ps-1 mw-50px align-self-end ms-auto"></app-numeric-spinner>
                    </li>

                    <li class="d-flex flex-row justify-content-start mb-1 pe-1" *ngIf="!meetingInteractive">
                        <span class="w-150px">{{'_ui_meetings' | translate}}:</span>
                        <span class="ms-auto mw-50px text-center  ps-2 pe-2">{{(loadCalculateResponse?.load.events / 60 / 60) | number : '1.0-0'}}</span>
                    </li>
                    <li class="d-flex flex-row justify-content-start mb-1" *ngIf="meetingInteractive">
                        <span class="w-150px">{{'_ui_meetings' | translate}}:</span>
                        <app-numeric-spinner (onChange)="customLoadChanged($event)"
                                             (blur)="customLoadChanged($any($event))"
                                             [fullWidth]="true"
                                             [value]="(periodRating.custom_meeting_load) / 60 / 60"
                                             class="ps-1 mw-50px  align-self-end ms-auto"></app-numeric-spinner>
                    </li>

                    <li class="d-flex flex-row justify-content-start mb-1 "
                        *ngIf="showLoadWorkHours">
                        <span class="w-150px">{{'_ui_work_time' | translate}}:</span>
                        <app-numeric-spinner (onChange)="workHoursChanged($event)"
                                             (blur)="workHoursChanged($any($event))"
                                             [fullWidth]="true"
                                             [value]="(periodRating.work_hours / 60 / 60)"
                                             class="ps-1 mw-50px  align-self-end ms-auto"></app-numeric-spinner>
                    </li>

                    <li class="d-flex flex-row justify-content-start mb-1 pe-1"
                        *ngIf="showLoadFreeHours"
                    >
                        <span class="w-150px">{{'_ui_available_time' | translate}}:</span>
                        <span class="ms-auto mw-50px text-center ps-2 pe-2">{{(totalFreeHours ? totalFreeHours : 0) | number : '1.0-0'}}</span>
                    </li>


                    <li class="d-none">
                        <button class="btn btn-sm btn-success w-100" type="button" (click)="popover.close()" >{{'_ui_close' | translate}}</button>
                    </li>
                </ul>

        </div>
    </ng-template>

    <ng-container>
        <div [ngbPopover]="popContent"
             [autoClose]="false"

             triggers="manual"
             container="body"
             [popoverClass]="'popover-sub-dialog'"
             #popover="ngbPopover"
             (click)="popover.open()"

             placement="auto"

             class="main d-flex justify-content-center align-items-start w-100 -h-100 cursor-pointer "
             [class.flex-column]="displayColumn"
        >

            <span  *ngIf="showLoad" [ngbTooltip]="('_ui_planned_time' | translate )+': '+(((totalLoad ? totalLoad : 0) / 60 / 60) | number : '1.0-2')+ ' '+('timer' | translate)"
                   class="timeindicator ellipsis rounded  text-center text-secondary">{{((totalLoad ? totalLoad : 0) / 60 / 60) | number : '1.0-0'}}</span>

            <span *ngIf="showLoadFreeHours"  [ngbTooltip]="('_ui_available_time' | translate )+': '+((totalFreeHours? totalFreeHours : 0) | number : '1.0-2') + ' '+('timer' | translate)"
                  class="timeindicator ellipsis  ms-1 rounded  text-center text-secondary -text-dark-gray">{{(totalFreeHours ? totalFreeHours : 0) | number : '1.0-0'}}</span>

            <span *ngIf="showLoadWorkHours" [ngbTooltip]="('_ui_work_time' | translate )+': '+((totalWorkHours ? totalWorkHours : 0) | number : '1.0-2')+ ' '+('timer' | translate)"
                  class="timeindicator ellipsis ms-1 rounded  text-center text-secondary -text-primary">{{(totalWorkHours ? totalWorkHours : 0) | number : '1.0-0'}}</span>


        </div>


    </ng-container>
</ng-container>
