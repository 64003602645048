import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {EventEmitter} from "@angular/core";
import {Subscription} from "rxjs";
import * as _ from "lodash";

export class DataFetcherCollection {

    private items: BaseFetcher[] = [];

    public onChangeEvent = new EventEmitter();
    public onFinishEvent = new EventEmitter();

    public executingFetchers?: BaseFetcher[];
    public executingFetcherCount?: number;
    private onFinishCallback: () => void;

    private subscriptions: Subscription[] = [];

    public add(fetchers: BaseFetcher | BaseFetcher[]) {
        (_.isArray(fetchers) ? fetchers : [fetchers]).forEach(fetcher => {
            this.items.push(fetcher);

            this.subscriptions.push(
                fetcher.onFinishEvent.subscribe(() => this.onFetcherFinished(fetcher))
            );
        });
    }

    private onFetcherFinished(item: BaseFetcher) {
        if (!this.executingFetchers?.includes(item)) {
            return;
        }

        this.executingFetchers.splice(this.executingFetchers.indexOf(item), 1);
        this.updateExecutingFetcherCount();

        if (this.executingFetcherCount == 0) {
            this.onFinishEvent.emit();
            this.onFinishCallback();
        }
    }

    private updateExecutingFetcherCount() {
        this.executingFetcherCount = this.executingFetchers?.length ?? 0;
        this.onChangeEvent.emit();
    }

    public execute(onFinish: () => void) {
        this.onFinishCallback = onFinish;

        if (this.items.length == 0) { // Finish now
            this.onFinishEvent.emit();
            this.onFinishCallback();
        } else {
            this.executingFetchers = [...this.items];
            this.updateExecutingFetcherCount();
            this.items.forEach(item => item.execute());
        }
    }

    public cancel() {
        this.executingFetchers?.forEach(fetcher => fetcher.cancel());
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.items = [];
        this.executingFetchers = undefined;
        this.updateExecutingFetcherCount();
    }

    public clearFetchers() {
        this.items.forEach(item => item.clear());
    }

}
