<div
    *ngIf="isReady"
    class="container p-2 d-flex gap-1 w-100 flex-wrap editor-tab flex-column"
>

    <div
        *ngIf="showAcceptanceButtons"
        class="list-group-item p-2 rounded d-flex flex-column"
     >
        <div class="wrap">
            <div class="d-flex flex-row align-items-center justify-content-start w-100">
                <span class="bg-info d-flex align-items-center justify-content-center me-2" style="width: 38px; height: 38px; border-radius: .5rem;">
                    <i class="fa fa-bell text-white" aria-hidden="true"></i>
                </span>
                <span>{{'_ui_waiting_for_approval' | translate}}</span>
            </div>
            <div class="d-flex justify-content-end border-top pt-2 mt-2 border-color-dark">
                <a class="btn btn-sm btn-danger me-2" (click)="onRejectBtnClicked()"><span
                    class="fal fa-times-circle me-1"></span><span
                    [translate]="'_ui_reject'"></span></a>
                <a class="btn btn-sm btn-success" (click)="onAcceptBtnClicked()"><span
                    class=" fal fa-check-circle me-1"></span> <span
                    [translate]="'_ui_accept'"></span></a>
            </div>
        </div>
    </div>


    <app-text-editor
        *ngIf="deliveryDescriptionTextEditorField.visible"
        [style.order]="deliveryDescriptionTextEditorField.index"
        [model]="item"
        [configuration]="deliveryDescriptionTextEditorConfiguration"
    />

    <app-text-editor
        *ngIf="successCriteriaTextEditorField.visible"
        [style.order]="successCriteriaTextEditorField.index"
        [model]="item"
        [configuration]="successCriteriaTextEditorConfiguration"
    />

    <app-link-editor
        *ngIf="linkFieldItem.visible"
        [style.order]="linkFieldItem.index"
        [model]="item"
        [configuration]="linkEditorConfiguration"
    />

    <app-estimate-list-editor
        *ngIf="estimateListFieldItem.visible"
        [style.order]="estimateListFieldItem.index"
        [model]="item"
        [configuration]="estimateListEditorConfiguration"
    />

    <app-status-list-editor
        *ngIf="statusListFieldItem.visible"
        [style.order]="statusListFieldItem.index"
        [model]="item"
        [configuration]="statusListEditorConfiguration"
    />

    <app-text-editor
        *ngIf="obsTextEditorField.visible"
        [style.order]="obsTextEditorField.index"
        [model]="item"
        [configuration]="obsTextEditorConfiguration"
    />

    <app-number-editor
        *ngIf="caseValuesNumberField.visible"
        [style.order]="caseValuesNumberField.index"
        [model]="item"
        [configuration]="casesPlannedNumberEditorConfiguration"
    />

    <app-number-editor
        *ngIf="caseValuesNumberField.visible"
        [style.order]="caseValuesNumberField.index"
        [model]="item"
        [configuration]="casesReachedNumberEditorConfiguration"
    />

    <app-reaction-list-editor
        *ngIf="showEmojiReactionListEditor"
        [model]="item"
        [configuration]="emojiReactionListEditorConfiguration"
    />

    <app-category-picker-list-editor
        *ngIf="showCategoryPickerListEditor"
        [model]="item"
        [configuration]="categoryPickerListEditorConfiguration"
    />

</div>
