<app-card-expander
    *ngIf="configuration"
    class="w-100 d-block"
    [attr.data-item-count]="itemCount"
    [items]="items"
    [itemCount]="itemCount"
    [isLoadingEvent]="isLoadingEventEmitter"
    [offset]="offset"
    (onShowMore)="loadMore()"
    (onShowAll)="loadAll()"
    (onShowLess)="loadLess()"
    [showCreateNew]="configuration?.getShowCreateNewButton()"
    [draggable]="configuration?.isDraggable()"
    [listDragInterface]="configuration"
    [allowDragEnter]="configuration ? configuration?.getAllowDragEnter() : true"
    [listConfiguration]="configuration"
    [modelClass]="'Task'"
    [scrollContainer]="scrollContainer"
    [dataSetChanged]="dataSetChanged"
    [listClass]="listClass"
/>
