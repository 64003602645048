/**
 * Created by ModelParser
 */
import {Display} from '../Display';
import {Setting} from '../Setting';
import {DisplayFilter} from '../DisplayFilter';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class DisplayTypeDefinition extends BaseModel {
    name?: string;
    visible?: boolean;
    view?: string;
    bottom?: boolean;
    right?: boolean;
    allow_sub_displays?: boolean;
    top?: boolean;
    displays?: Display[];
    settings?: Setting[];
    display_filters?: DisplayFilter[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.visible;
            delete this.view;
            delete this.bottom;
            delete this.right;
            delete this.allow_sub_displays;
            delete this.top;
            delete this.displays;
            delete this.settings;
            delete this.display_filters;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.visible != null) {
            this.visible = data.visible;
        }
        if (data.view != null) {
            this.view = data.view;
        }
        if (data.bottom != null) {
            this.bottom = data.bottom;
        }
        if (data.right != null) {
            this.right = data.right;
        }
        if (data.allow_sub_displays != null) {
            this.allow_sub_displays = data.allow_sub_displays;
        }
        if (data.top != null) {
            this.top = data.top;
        }
        if (data.displays != null) {
            this.displays = data.displays.map((i: any) => new Display(i));
        }
        if (data.settings != null) {
            this.settings = data.settings.map((i: any) => new Setting(i));
        }
        if (data.display_filters != null) {
            this.display_filters = data.display_filters.map((i: any) => new DisplayFilter(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
