import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {EventEmitter} from "@angular/core";
import {isNumber} from "lodash";
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';

export class EditNumberValidator implements ValidationErrorInterface {

    private rules: ((value: number | null, label: string) => string | true)[];
    public readonly required: boolean;

    constructor(required: boolean) {
        const rules = [];
        this.required = required;
        if (required) {
            rules.push(
                (input: number | null, label: string) => {
                    if (isNumber(input)) {
                        return true;
                    } else {
                        const translatedLabel = AppInjector.getInjector().get(TranslateService).instant(label);
                        return AppInjector.getInjector().get(TranslateService).instant('_ui_field_is_required', {field: translatedLabel})
                    }
                }
            )
        }

        this.rules = rules;
    }

    public static CreateNoValidator(): EditNumberValidator {
        return new EditNumberValidator(false);
    }

    private lastValidation: true | string[] = true; // TRUE until change

    public validate(value: number | null, label: string) {
        let isValid = true;
        const errors: string[] = [];
        this.rules.forEach(rule => {
            const result = rule(value, label);
            if (result !== true) {
                errors.push(result);
                isValid = false;
            }
        });

        const newValidation = isValid ? true: errors;
        if (this.lastValidation !== newValidation) {
            this.lastValidation = newValidation;
            this.onIsValidChanged.emit(isValid);
        }
    }

    // <editor-fold desc="Validator interface">

    public onIsValidChanged = new EventEmitter<boolean>();

    public isValid(): boolean {
        return this.lastValidation === true;
    }

    public getErrorDescriptions(): string[] {
        return this.lastValidation === true ? [] : this.lastValidation;
    }

    // </editor-fold>

}
