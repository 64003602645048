import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {LocalizedDatePipe} from '@app/pipes/localized-date.pipe';
import {MilestoneYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/MilestoneYearWheelItem";
import {Milestone} from "@app/core/models";
import {EditorPanelService} from "@app/services/editor-panel.service";
import {Task} from '@app/core/models';
import {YearWheelService} from "@app/services/year-wheel.service";

@Component({
    selector: 'app-year-wheel-item-display-milestone',
    templateUrl: './year-wheel-item-display-milestone.component.html',
    styleUrls: ['./year-wheel-item-display-milestone.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalizedDatePipe,
    ],
})
export class YearWheelItemDisplayMilestoneComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() item: MilestoneYearWheelItem;

    // Bindings to view
    @ViewChild(NgbPopover, {static: false}) popover: NgbPopover;
    public title: string;
    public color: string;
    public className: string;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private localizedDate: LocalizedDatePipe,
                private editorService: EditorPanelService,
                private yearWheelService: YearWheelService
    ) {
        super();
        this.cdr = this.changeDetectorRef;
    }

    ngOnInit(): void {
        this.subscribe(this.yearWheelService.expandAllMilestones$.subscribe((expand) => {
            if (this.popover) {
                if (expand) {
                    if (!this.popover.isOpen()) {
                        this.popover.open();
                    }
                } else {
                    if (this.popover.isOpen()) {
                        this.popover.close();
                    }
                }
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['item'] != null) {
            let dateExtras = '';
            const ms = this.item.item as Milestone;
            if (ms.deadline && ms.deadline.date) {
                dateExtras += ': ' + (this.localizedDate.transform(ms.deadline.date));
            }
            this.title = ms.name + dateExtras;
            this.className = 'milestone';
            this.detectChanges();
        }
    }

    onOpenBtnClicked(model: Task | Milestone) {
        this.editorService.open(model);
    }

}
