<!--  https://www.npmjs.com/package/@ctrl/ngx-emoji-mart#emoji -->

<div class="d-flex flex-row">
    <ul class="list-unstyled d-flex align-items-center -flex-wrap emoji-items m-0">

        <li class="me-1"
            [ngbTooltip]="popover && popover.isOpen() ? null : (tooltipTitle | translate)"
            container="body"
            placement="bottom auto"
            [class.d-none]="!showPicker"
        >
            <span class="fal fa-smile-wink cursor-pointer position-relative"
                  [ngbPopover]="popoverContent"
                  [autoClose]="false"
                  triggers="manual"
                  container="body"
                  (click)="editable && popover? (popover.isOpen()? popover.close(): popover.open()) : null"
                  #popover="ngbPopover"
            >
                <span class="badge rounded-pill badge-notification badge-danger badge-counter"
                      *ngIf="showBadge && emojiItems.length > 0">{{emojiItems.length}}</span>

            </span>
        </li>

        <ng-container *ngIf="visible">
            <li *ngFor="let emojiItem of emojiItems"
                (click)="toggleEmoji(emojiItem)"
                class="emoji-badge d-flex align-items-center justify-content-center text-light me-1 mb-1 pe-1"
                (mouseenter)="activeEmojiItem = emojiItem"
                (mouseleave)="activeEmojiItem = null"

                [ngbPopover]="toggleUsers ? userPopoverContent : null"
                triggers="mouseenter:mouseleave"
                container="body"

            >
                <ngx-emoji [emoji]="emojiItem.emojiId" [size]="16"
                           [backgroundImageFn]="backgroundImage"
                           [class.me-1]="emojiItem.count > minimumUserCount && emojiItem.userIds.length > minimumUserCount && showUserCounter"></ngx-emoji>
                <div class="badge"
                     *ngIf="emojiItem.count > 0 && emojiItem.userIds.length > minimumUserCount && showUserCounter">{{emojiItem.count}}</div>
            </li>
            <li *ngIf="!emojiItems || emojiItems.length == 0"
                (click)="editable && popover? (popover.isOpen()? popover.close(): popover.open()) : null"
            >
                <span class="placeholder">{{'_ui_select_emoji' | translate}}...</span>
            </li>
        </ng-container>

    </ul>
</div>


<!-- popover -->
<ng-template #popoverContent>

    <div class="items-container flex-column d-flex">
        <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>


        <div class="d-flex flex-row align-items-center w-100 pb-1 " *ngIf="!autoClose">

            <h4 class="p-0 m-0 popover-title flex-grow-1" *ngIf="tooltipTitle">{{tooltipTitle | translate}}</h4>

            <div class="d-flex ms-auto reset-panel-buttons" >

                    <span (click)="popover.close();removeAllItems();emitItems();"
                          [class.disabled]="filterIsDefault && !(emojiItems.length > 0)"
                          class="cursor-pointer  btn btn-sm btn-danger">
                        <i class="fal fa-eraser" aria-hidden="true"></i>
                        {{'_ui_emoji_clear' | translate}}
                    </span>

                <span (click)="popover.close();emitItems();"
                      [class.disabled]="filterEmittet"
                      class="cursor-pointer btn btn-sm btn-success">
                        <i class="fa-duotone fa-filters"></i>
                    {{'_ui_filter' | translate}}</span>


            </div>
        </div>


        <div class="d-flex flex-column emoji-popover">

            <ul class="list-unstyled pb-1 pt-1 border-bottom mb-2 d-flex flex-wrap flex-row justify-content-start w-100"
                *ngIf="showPopoverEmoji && emojiItems.length > 0">
                <li *ngFor="let emojiItem of emojiItems"
                    (click)="toggleEmoji(emojiItem);"
                    class="emoji-badge d-flex align-items-center justify-content-center text-light me-1 mb-1 align-items-center pe-1 cursor-pointer">
                    <ngx-emoji [emoji]="emojiItem.emojiId" [size]="32"
                               [backgroundImageFn]="backgroundImage"
                               [class.me-1]="emojiItem.count > 1 && emojiItem.userIds.length > minimumUserCount && showUserCounter"
                               class="d-flex align-items-center justify-content-center"></ngx-emoji>
                    <div class="badge text-dark-gray"
                         *ngIf="emojiItem.count > 0 && emojiItem.userIds.length > minimumUserCount && showUserCounter">{{emojiItem.count}}</div>
                </li>


            </ul>

            <emoji-mart
                *ngIf="popover.isOpen()"
                (emojiClick)="clickEmoji($event);"
                [i18n]="translations"
                [skin]="1"
                [showPreview]="false"
                [backgroundImageFn]="backgroundImage"
                title="Pick your emoji…"
                class="d-block"
            ></emoji-mart>

        </div>
    </div>
</ng-template>
<!-- (clickOutside)="popover.isOpen() ? popover.close() : null" -->


<ng-template #userPopoverContent>
    <div class="popover-container emoji-popover">
        <ul *ngIf="activeEmojiItem && activeEmojiItem.userIds?.length > 0" class="list-unstyled d-flex flex-column m-0">
            <li *ngFor="let uid of activeEmojiItem.userIds">
                <app-user-with-icon [modelId]="uid" class="pb-1 mb-1"></app-user-with-icon>
            </li>
        </ul>
    </div>
</ng-template>
