import {NgModule} from "@angular/core";
import {ItemCounterComponent} from "@app/shared/_elements/item-counter/item-counter.component";
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ItemCounterComponent,
    ],
    exports: [
        ItemCounterComponent

    ],
    providers: [

    ],
})
export class ItemCounterModule {}
