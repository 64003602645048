<div
    *ngIf="isReady"
    class="container p-2 d-flex gap-1 w-100 flex-wrap editor-tab"
>
    <div
        *ngIf="showTaskList"
        class="form-group hide-if-card-expander-empty"
    >
        <label>
            <span>{{ fields.get(Fields.AttachTo.Tasks).smartName | translate }}</span>
        </label>
        <div class="p-2 list-group-item rounded mb-2">
            <app-task-list
                *ngIf="isSelectedTab"
                [configuration]="taskListConfiguration"
                [listClass]="'grid-list'"
            />
        </div>
    </div>

    <div
        *ngIf="showMilestoneList"
        class="form-group hide-if-card-expander-empty"
    >
        <label>
            <span>{{'_displays_projects_project_milestones' | translate}}</span>
        </label>
        <div class="p-2 list-group-item rounded mb-2">
            <app-milestone-list
                *ngIf="isSelectedTab"
                [configuration]="milestoneListConfiguration"
                [listClass]="'grid-list'"
            />
        </div>
    </div>

    <div
        *ngIf="showRelatedProjects"
        class="ignore-resize"
    >
        <app-attached-types
            [item]="item"
            [types]="relatedProjectsAttachedTypes"
        />
    </div>

    <app-department-picker-editor
        *ngIf="showDepartmentPickerEditor"
        [model]="item"
        [configuration]="departmentPickerEditorConfiguration"
    />

    <app-tag-list-editor
        *ngIf="showTagListEditor"
        [model]="item"
        [configuration]="tagListEditorConfiguration"
    />

</div>
