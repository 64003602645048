import {EventEmitter} from '@angular/core';
import {Subscription} from 'rxjs';
import Helpers from '@app/core/helpers';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {User} from '@app/core/models/User';
import {CardUserConfiguration} from '@app/shared/_ui/cards/medium/card-user/card-user-configuration';
import {
    MultiLoaderFetcherInterface
} from "@app/shared/_ui/displays/display-multi-loader/Helpers/MultiLoaderFetcherInterface";
import {Api} from "@app/core/http/Api/Api";
import {LoadCalculateResponse} from "@app/http/responses";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";

export class UserLoadCalculationFetchRequest {

    public cardItem: CardItem<User>;
    public onLoadCalculationFetchedEvent = new EventEmitter<LoadCalculateResponse>();
    private result: LoadCalculateResponse;

    public constructor(cardItem: CardItem<User>) {
        this.cardItem = cardItem;
        (<CardUserConfiguration>cardItem.configuration).setLoadCalculationDataSource(this);
    }

    public setResult(result: LoadCalculateResponse) {
        this.result = result;
    }

    public getResult(): LoadCalculateResponse | undefined {
        return this.result;
    }

    public done() {
        this.onLoadCalculationFetchedEvent.emit(this.result);
    }

    public hasResult(): boolean {
        return this.result != null;
    }

}

export class UserLoadCalculationFetcher extends BaseFetcher implements MultiLoaderFetcherInterface {

    private requests: Map<number, UserLoadCalculationFetchRequest> = new Map();
    private api: Subscription;
    public onFinishEvent = new EventEmitter();

    constructor(name?: string) {
        super(name ?? '');
    }

    public clear(): void {
        this.requests.clear();
    }

    public addRequest(request: UserLoadCalculationFetchRequest) {
        this.requests.set(request.cardItem.item.id, request);
    }

    public execute(showGlobalLoadingIndicator: boolean = true) {
        let userIds: number[] = [];
        let configuration: CardUserConfiguration;
        this.requests.forEach(((value, key) => {
            userIds.push(key);
            if (!configuration) {
                configuration = value.cardItem.configuration as CardUserConfiguration;
            }
        }));

        if (configuration?.getLoadId()) {
            const api = Api.loads().calculateGetByLoadId(configuration.getLoadId())
                .start(Helpers.serverDate(configuration.getPeriod()[0]))
                .end(Helpers.serverDate(configuration.getPeriod()[1]))
                .user_ids(userIds);
            api.setShowProgressBar(showGlobalLoadingIndicator);
            this.api = api.find(loadCalculationResponses => {
                loadCalculationResponses?.forEach(loadCalculationResponse => {
                    if (this.requests.has(loadCalculationResponse.user.id)) {
                        this.requests.get(loadCalculationResponse.user.id).setResult(new LoadCalculateResponse(loadCalculationResponse));
                        this.requests.get(loadCalculationResponse.user.id).done();
                    }
                });
                this.onFinishEvent.emit(true);
            });
        } else {
            this.onFinishEvent.emit(true);
        }
    }

    public cancel() {
        this.api?.unsubscribe();
    }

}
