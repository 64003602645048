/**
 * Created by ModelParser
 * Date: 04-05-2022.
 * Time: 08:29.
 */
import {RolesUserGroupDefinition} from './definitions/RolesUserGroupDefinition';

export class RolesUserGroup extends RolesUserGroupDefinition {

    constructor(json?: any) {
        super(json);
    }

}
