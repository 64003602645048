import {NgModule} from "@angular/core";
import {
    DisplayProjectDetailsTopComponent
} from "@app/pages/displays/display-project-details/subdisplay-top/display-project-details-top.component";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {ColumnSorterModule} from "@app/shared/_ui/column-sorter/ColumnSorter.module";
import {DateRangePickerModule} from "@app/shared/_elements/date-range-picker/DateRangePicker.module";
import {TranslateModule} from "@ngx-translate/core";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {HeaderSpacerModule} from "@app/shared/_elements/header-spacer/HeaderSpacer.module";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {ItemDateModule} from "@app/shared/_elements/item-date/ItemDate.module";
import {
    ProjectCalculatedFieldModule
} from "@app/shared/_elements/project-calculated-field/ProjectCalculatedField.module";
import {DeadlineDifferenceModule} from "@app/shared/_elements/deadline-difference/DeadlineDifference.module";
import {ItemNoteModule} from "@app/shared/_elements/item-note/ItemNote.module";
import {CategoriesModule} from "@app/shared/_ui/columns/categories/Categories.module";
import {CurrentPhasesProjectModule} from "@app/shared/_elements/current-phases-project/CurrentPhasesProject.module";
import {PhasesProjectsModule} from "@app/shared/_ui/columns/phases-projects/PhasesProjects.module";
import {YearWheelModule} from "@app/shared/_ui/columns/year-wheel/YearWheel.module";
import {TodoListModule} from "@app/shared/_ui/lists/todo-list/TodoList.module";
import {AppointmentListModule} from "@app/shared/_ui/lists/appointment-list/AppointmentList.module";
import {ProjectStatusesModule} from "@app/shared/_ui/columns/project-statuses/ProjectStatuses.module";
import {TaskListModule} from "@app/shared/_ui/lists/task-list/TaskList.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";
import {NgxDatatableExtensionModule} from "@app/directives/NgxDatatableExtension.module";
import {YearWheelColumnModule} from "@app/shared/_ui/columns/year-wheel/year-wheel-column/year-wheel-column.module";

@NgModule({
    imports: [
        CommonModule,
        NgxDatatableModule,
        ColumnSorterModule,
        DateRangePickerModule,
        TranslateModule,
        ToggleItemModule,
        NgbTooltipModule,
        CardsModule,
        HeaderSpacerModule,
        UserWithIconModule,
        ItemDateModule,
        ProjectCalculatedFieldModule,
        DeadlineDifferenceModule,
        ItemNoteModule,
        CategoriesModule,
        CurrentPhasesProjectModule,
        PhasesProjectsModule,
        YearWheelModule,
        TodoListModule,
        AppointmentListModule,
        ProjectStatusesModule,
        TaskListModule,
        TypedTemplateDirective,
        NgxDatatableExtensionModule,
        YearWheelColumnModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DisplayProjectDetailsTopComponent,
    ],
    exports: [
        DisplayProjectDetailsTopComponent

    ],
    providers: [

    ],
})
export class DisplayProjectDetailsTopModule {}
