<app-card-expander
        [showCreateNew]="configuration ? configuration.getShowCreateNewButton() : true"
        [items]="items"
        [attr.data-item-count]="itemCount"
        [itemCount]="itemCount"
        [isLoadingEvent]="isLoadingEventEmitter"
        [offset]="offset"
        (onShowMore)="loadMore()"
        (onShowAll)="loadAll()"
        (onShowLess)="loadLess()"
        [listConfiguration]="configuration"
        [scrollContainer]="scrollContainer"
        [listClass]="listClass"
></app-card-expander>
