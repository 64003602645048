import {NgModule} from "@angular/core";
import {WeekPipe} from "@app/pipes/week.pipe";

@NgModule({
    imports: [

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        WeekPipe,
    ],
    exports: [
        WeekPipe

    ],
    providers: [

    ],
})
export class WeekPipeModule {}
