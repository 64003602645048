import {ApiCall} from '@app/http/APICall';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiFilter, FilterItem, FilterOperators, OrderDirection, OrderingItem} from '@app/http/APIFilter';
import * as models from '@app/core/models/';
import * as customs from '@app/http/responses';
import Helpers from '@app/core/helpers';
import {CaseUser, Roster} from '@app/core/models/Task';

export class BaseApi {

    protected url: string = '';
    protected onBehalfOf: number;
    protected filter: ApiFilter = new ApiFilter();
    protected limit: number = null;
    protected offset: number = null;
    protected isCount: boolean = false;
    protected includes: string[] = [];
    protected params: Map<string, any> = new Map();
    protected showProgressBar = true;

    public constructor(url: string, idToAppend?: number) {
        this.append(url, idToAppend);
    }

    public append(text: string, id?: number) {
        if(this.url.length > 0) this.url += '/';
        this.url += text;
        if(id) this.url += '/' + id;
        return this;
    }

    public toString(): string {
        return (this.url);
    }

    public setOnBehalfOf(userId: number): BaseApi {
        this.onBehalfOf = userId;
        return this;
    }

    public setFilter(filter: ApiFilter): BaseApi {
        this.filter = filter;
        return this;
    }

    public setLimit(limit: number): BaseApi {
        this.limit = limit;
        return this;
    }

    public setOffset(offset: number): BaseApi {
        this.offset = offset;
        return this;
    }

    public setCount(count: boolean): BaseApi {
        this.isCount = count;
        return this;
    }

    public setParam(name: string, value: any): BaseApi {
        this.params.set(name, value);
        return this;
    }

    public getParams(): HttpParams {
        let params = new HttpParams();
        if (this.filter.filterItems.length > 0) {
            params = params.append('filter', this.filter.filtersString());
        }
        if (this.filter.orderItems.length > 0) {
            params = params.append('ordering', this.filter.orderingString());
        }
        if(this.limit != null) params = params.append('limit', this.limit.toString());
        if(this.offset != null) params = params.append('offset', this.offset.toString());
        if(this.includes.length > 0)
            params = params.append('include', this.includes.join(','));
        if(this.isCount) params = params.append('count', this.isCount ? "1" : "0");
        this.params.forEach(((value, key) => params = params.append(key, value)));
        return params;
    }

    // <editor-fold desc="Filter Methods">

    public orderBy(name: string, direction: string): BaseApi {
        this.filter.orderItems.push(new OrderingItem(name, direction));
        return this;
    }

    public orderAsc(name: string): BaseApi {
        this.filter.orderItems.push(new OrderingItem(name, OrderDirection.Ascending));
        return this;
    }

    public orderDesc(name: string): BaseApi {
        this.filter.orderItems.push(new OrderingItem(name, OrderDirection.Descending));
        return this;
    }

    public where(name: string, value: any): BaseApi {
        return this.whereEquals(name, value);
    }

    public whereEquals(name: string, value: any): BaseApi {
        if(value instanceof Boolean) value = value ? 1: 0;
        this.filter.filterItems.push(new FilterItem(name, FilterOperators.Equals, value));
        return this;
    }

    public whereIn(name: string, value: any[]): BaseApi {
        return this.whereInArray(name, value);
    }

    public whereInArray(name: string, value: any[]): BaseApi {
        this.filter.filterItems.push(new FilterItem(name, null, `[${value.join(',')}]`));
        return this;
    }

    public whereNot(name: string, value: any): BaseApi {
        this.filter.filterItems.push(new FilterItem(name, FilterOperators.Not, value));
        return this;
    }

    public whereNotIn(name: string, value: any[]): BaseApi {
        this.filter.filterItems.push(new FilterItem(name, FilterOperators.Not, `[${value.join(',')}]`));
        return this;
    }

    public whereGreaterThan(name: string, value: any): BaseApi {
        this.filter.filterItems.push(new FilterItem(name, FilterOperators.GreaterThan, value));
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): BaseApi {
        this.filter.filterItems.push(new FilterItem(name, FilterOperators.GreaterThanOrEqual, value));
        return this;
    }

    public whereLessThan(name: string, value: any): BaseApi {
        this.filter.filterItems.push(new FilterItem(name, FilterOperators.LessThan, value));
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): BaseApi {
        this.filter.filterItems.push(new FilterItem(name, FilterOperators.LessThanOrEqual, value));
        return this;
    }

    public search(name: string, value: any): BaseApi {
        if(value != null)
            this.filter.filterItems.push(new FilterItem(name, FilterOperators.Search, `"${value}"`));
        return this;
    }

    public include(value: string): BaseApi {
        this.includes.push((value));
        return this;
    }

    public ignoreProgressBar(): BaseApi {
        this.showProgressBar = false;
        return this;
    }

    // </editor-fold>


    // <editor-fold desc="HTTP Methods">

    public count(callback?: (count: number) => void) {
        this.setCount(true);
        this.get().subscribe(response => {
            callback(response.count);
        });
    }

    public get(callback?: ((response: any) => void) | string): Observable<any> {
        if(typeof callback == "string") {

            let smartObjectConstructor = (item?: any) => {
                if(models[callback as string])
                    return new models[callback as string](item);
                else if(customs[callback as string])
                    return new customs[callback as string](item);
                else if((callback as string) == CaseUser.name)
                    return new CaseUser(item);
                else if((callback as string) == Roster.name)
                    return new Roster(item);
            };

            return new ApiCall(this.toString(), this.getParams(), response => {
                if(response.resources) {
                    let resources = response.resources.map((item: any) => {
                        return smartObjectConstructor(item);
                    });
                    return resources;
                } else if(response.resource) {
                    return smartObjectConstructor(response.resource);
                } else
                    return smartObjectConstructor();
            }).getHttp();
        } else {
            return new ApiCall(this.toString(), this.getParams(), callback).getHttp(!this.showProgressBar);
        }
    }

    public post(data: any, callback?: (response: any) => void): Observable<any> {
        return new ApiCall(this.toString(), this.getParams(), callback).post(data, !this.showProgressBar);
    }

    public put(data?: any, callback?: (response: any) => void): Observable<any> {
        return new ApiCall(this.toString(), this.getParams(), callback).put(data, !this.showProgressBar);
    }

    public patch(data: any, callback?: (response: any) => void): Observable<any> {
        return new ApiCall(this.toString(), this.getParams(), callback).patch(data, !this.showProgressBar);
    }

    public delete(callback?: (response: any) => void): Observable<any> {
        return new ApiCall(this.toString(), this.getParams(), callback).delete(!this.showProgressBar);
    }

    // </editor-fold>

}

export class OldApi extends BaseApi { // Alphabetic order, Please

    public static appointments(appointmentId?: number): OldApi {
        return new OldApi('appointments', appointmentId);
    }

    public static displayFilters(displayFilterId?: number): OldApi {
        return new OldApi('display_filters', displayFilterId);
    }

    public static displaysSettings(displaysSettingId?: number): OldApi {
        return new OldApi('displays_settings', displaysSettingId);
    }

    public static loads(loadId?: number): Loads {
        return new Loads(loadId);
    }

    public static milestones(milestoneId?: number): Milestones {
        return new Milestones(milestoneId);
    }

    public static periodRatings(periodRatingId?: number): PeriodRatings {
        return new PeriodRatings(periodRatingId);
    }

    public static projects(projectId?: number): Projects {
        return new Projects(projectId);
    }

    public static phases(): OldApi {
        return new OldApi('phases');
    }

    public static supportRequests(supportRequestId?: number): OldApi {
        return new OldApi('support_requests', supportRequestId);
    }

    public static tasks(taskId?: number): Tasks {
        return new Tasks(taskId);
    }

    public static todoFields(): OldApi {
        return new OldApi('todo_fields');
    }

    public static todos(todoId?: number): Todos {
        return new Todos(todoId);
    }

    public static users(userId?: number): Users {
        return new Users(userId);
    }

}

class Loads extends BaseApi {

    public constructor(loadId?: number) {
        super('loads', loadId);
    }

    public calculate(): Loads {
        return this.append('calculate');
    }

}

class Milestones extends BaseApi {

    public constructor(milestoneId?: number) {
        super('milestones', milestoneId);
    }

    public stars(): Milestones {
        return this.append('star');
    }

    public hand_ups(): Milestones {
        return this.append('hand_up');
    }

}

class PeriodRatings extends BaseApi {

    public constructor(periodRatingId?: number) {
        super('period_ratings', periodRatingId);
    }

    public smart(): PeriodRatings {
        return this.append('smart');
    }

}

class Projects extends BaseApi {

    public constructor(projectId?: number) {
        super('projects', projectId);
    }

    public hand_ups(): Projects {
        return this.append('hand_up');
    }

    public stars(): Projects {
        return this.append('star');
    }

}

class Tasks extends BaseApi {

    public constructor(taskId?: number) {
        super('tasks', taskId);
    }

    public hand_ups(): Tasks {
        return this.append('hand_up');
    }

    public stars(): Tasks {
        return this.append('star');
    }

}

class Todos extends BaseApi {

    public constructor(todoId?: number) {
        super('todos', todoId);
    }

    public stars(): Todos {
        return this.append('star');
    }

}

class Users extends BaseApi {

    public constructor(userId?: number) {
        super('users', userId);
    }

    public me(): Users {
        return this.append('me');
    }

}
