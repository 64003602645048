<ul class="list-group">
    <li class="list-group-item" *ngIf="!readonly">
        <div class="d-flex flex-column">
            <div class="alert alert-info mt-1 mb-1 text-small text-center" style="">
                <i class="fal fa-info-circle me-1" aria-hidden="true"></i><span ></span>
            </div>
            <button class="btn btn-sm btn-outline-light" type="button" (click)="createRule()">
                <i class="fa fa-plus me-1" aria-hidden="true"></i> {{('_ui_create_status_rule' | translate)}}
            </button>
        </div>
    </li>
    <li class="list-group-item" *ngIf="readonly && internalValues && internalValues.length > 0">
        <div class="d-flex flex-column">
            <div class="-alert -alert-info mt-1 mb-1 text-small text-center" style="">
                <i class="fal fa-info-circle me-1" aria-hidden="true"></i><span >{{'_ui_your_status_rules' | translate}}</span>
            </div>
        </div>
    </li>
    <ng-container *ngFor="let statusRule of internalValues">
        <li class="list-group-item">
            <app-status-rule-item [model]="statusRule" (removeItemEvent)="removeItem($event)"
                                  [readonly]="readonly"></app-status-rule-item>
        </li>
    </ng-container>
</ul>
