import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {
    ProjectDeadlineListColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/ProjectDeadlineListColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns";

export class ProjectDeadlineListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.ProjectDeadlineList;

    public column: ProjectDeadlineListColumn;

}
