<div
     class="card preview p-1" *ngIf="!initialized && visible"
>
    <div class="d-flex w-100 font-size-lg  mb-1">
        <app-color-label class="card-type me-1 flex-shrink-0" [label]="''"></app-color-label>
        <div class="title p-0 font-weight-bold col -border-bottom -border-light flex-fill">
            <div  class="pt-0 h-100 text-capitalize-firstletter w-100 item-bg"></div>
        </div>
    </div>
    <div class="d-flex justify-content-between font-size-md flex-wrap align-items-center card-details">
        <ul class="d-flex list-unstyled justify-content-start align-items-center w-100 m-0 flex-wrap item-bg">
            <li class="w-100 "></li>
        </ul>
    </div>
</div>

<div #cardContainer
     class="card p-1" (click)="onCardClick($event)" *ngIf="model && initialized && visible"
     [class.grayed-out]="configuration && configuration.isGrayedOut"
     [class.focused]="outline"
>

    <div class="d-flex w-100 font-size-lg  mb-1 align-items-center justify-content-center">

        <app-color-label class="card-type me-1 "
                         *ngIf="isFieldVisible(Fields.Status)"
                         [status]="model.status"
                         [itemWithTypeProperties]="model"
                         (onStatusChange)="onStatusChange($event)"></app-color-label>

        <div class="title p-0 font-weight-bold col -border-bottom -border-light flex-fill me-1"
             *ngIf="isFieldVisible(Fields.Title)">
            <input class="form-control" [(ngModel)]="model.title" *ngIf="editable"/>
            <div *ngIf="!editable" class="pt-0 h-100 text-capitalize-firstletter" [ngbTooltip]="model.title | placeholder: ('_ui_no_title' | translate)">
                {{model.title | placeholder: ('_ui_no_title' | translate)}}
            </div>
        </div>



    </div>
    <div class="d-flex justify-content-between font-size-md pb-1" *ngIf="!editable">

        <ng-template #popoverContent>
            <div class="scale-2x-wrapper">
                <div class="card-wrap">
                <app-card-todo *ngIf="item" [item]="item"></app-card-todo>
                </div>
            </div>
        </ng-template>



        <!-- Items -->
        <div class="d-flex flex-row w-100 justify-content-start align-items-center">
        <ul class="d-flex list-unstyled m-0 justify-content-start align-items-center w-100">

            <!-- https://podio.com/klartboard/softwareudvikling/apps/stories/items/1169 -->
            <li
                class="pe-1"
                #toolsPopover="ngbPopover"
                [ngbPopover]="toolsDropdownContent"
                [autoClose]="'outside'"
                placement="bottom left auto"
                triggers="manual"
                container="body"
                (click)="$event.stopImmediatePropagation(); !toolsPopover.isOpen() ? toolsPopover.open() : toolsPopover.close()"
            >
                <i class="fal fa-toolbox cursor-pointer" [ngbTooltip]="('_ui_navigation_tools' | translate)"></i>

                <ng-template #toolsDropdownContent>
                    <ul class="d-flex list-unstyled justify-content-start align-items-center m-0 flex-wrap "
                        [class.disabled]="model.id == 0"
                    >

                        <li class="pe-1" *ngIf="isFieldVisible(Fields.Archived)">
                            <app-toggle-item
                                [interactive]="true"
                                [active]="model.archived_id > 0"
                                [class.text-black-50]="!model?.archived_id"
                                [ngbTooltip]="model?.archived_id == 0 ? translateService.instant('_ui_complete') : translateService.instant('_ui_reopen')"
                                [icon]="'fa-check-circle'"
                                [iconInactive]="'fa-circle'"
                                (onToggle)="onArchivedToggle($event); toolsPopover.close();"></app-toggle-item>
                        </li>
                        <li class="pe-1" *ngIf="isFieldVisible(Fields.Star)">
                            <app-star-selector [model]="model"
                                               [showUsers]="false"
                                               (onToggle)=" toolsPopover.close();"
                            ></app-star-selector>
                        </li>

                        <li *ngIf="item" class="pe-1">
                            <app-reactions [reactionsApiInterface]="item?.item"
                                           [reactionsSourceInterface]="item?.item"
                                           [reactionsTypeInterfaces]="reactionsTypeInterfaces"
                                           [reactions]="item?.item?.reactions"
                                           [reactionsEditable]="true"
                                           [reactionsVisible]="false"
                                           (onToggle)=" toolsPopover.close();"
                                           (click)="$event.stopImmediatePropagation()"></app-reactions>
                        </li>
                        <li class="pe-1"
                            [ngbPopover]="popoverContent"
                            placement="bottom bottom-left left-bottom right-bottom top-left auto"
                            popoverClass="popover-transparent"
                            container="body"
                            [autoClose]="true"
                            triggers="mouseenter:mouseleave"
                        >
                            <a class="cursor-pointer">
                                <i class="fal fa-search-plus "></i>
                            </a>

                        </li>
                        <li *ngIf="removable" class="pe-1" (click)="triggerRemove(model);$event.stopPropagation()">
                            <a class="-text-danger remove d-flex flex-row align-items-center cursor-pointer "
                            >
                                <i class="fal fa-trash-alt"></i>
                            </a>
                        </li>

                    </ul>
                </ng-template>
            </li>

            <li class="pe-1" *ngIf="isFieldVisible(Fields.Archived) && model?.archived_id != 0">
                <app-toggle-item

                        [interactive]="true"
                        [active]="model.archived_id > 0"
                        [class.text-black-50]="!model?.archived_id"
                        [tooltipLabel]="model?.archived_id == 0 ? translateService.instant('_ui_complete') : translateService.instant('_ui_reopen')"
                        [icon]="'fa-check-circle'"
                        [iconInactive]="'fa-circle'"
                        (onToggle)="onArchivedToggle($event)"></app-toggle-item>
            </li>
            <li class="pe-1" *ngIf="isFieldVisible(Fields.Star)  && model.num_stars > 0">
                <app-star-selector
                    [model]="model"></app-star-selector>
            </li>


            <li class="d-flex align-items-center flex-wrap pt-1"
                 [class.d-none]="!hasVisibleReactions"
                 [class.d-flex]="hasVisibleReactions"
                 *ngIf="item"
            >
                <app-reactions [reactionsApiInterface]="item.item"
                               [reactionsSourceInterface]="item.item"
                               [reactionsTypeInterfaces]="reactionsTypeInterfaces"
                               [reactions]="item?.item?.reactions"
                               [reactionsPickerVisible]="false"
                               (onDataSetChanged)="hasVisibleReactions = $event.length > 0;"
                               (click)="$event.stopImmediatePropagation()"></app-reactions>

            </li>


        </ul>
        <ul class="flex-grow-1 d-flex list-unstyled justify-content-end align-items-center m-0 flex-wrap" *ngIf="model.deadline && isFieldVisible(Fields.Deadline)">
            <li class="date m-0 ms-auto" *ngIf="model.deadline && isFieldVisible(Fields.Deadline)">
                <app-item-date [label]=""
                               [date]="model.deadline.getDate()"></app-item-date>
            </li>
        </ul>
        </div>

        <!-- items end -->
    </div>



    <ul class="d-flex list-unstyled justify-content-between align-items-center w-100 m-0 border-top phase flex-wrap text-small"
        *ngIf="model.todo_category && isFieldVisible(Fields.Category)">
        <li *ngIf="model.todo_category" class="d-flex flex-row align-items-center -w-100" [ngbTooltip]="(editorFields?.get(EditorFields.Category).smartName | translate)">
            <i class="fal fa-layer-group -me-1 text-center" aria-hidden="true" style="width: 1rem;"></i>
            <div class="text-capitalize-firstletter ellipsis">{{model.todo_category.name}}</div>
        </li>
    </ul>

    <div class="d-flex project-display w-100" *ngIf="projectCard && isFieldVisible(Fields.Project)">
        <app-card-project [item]="projectCard" [model]="projectCard.item" [configuration]="$any(projectCard.configuration)"
                          [mini]="true" class="w-100"></app-card-project>
    </div>

</div>
