import {GenericCell} from "@app/pages/displays/display-project-details/subdisplay-tasks/Cells/GenericCell";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-tasks/Row";
import {
    TaskListPeriodColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/Columns/TaskListPeriodColumn";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {
    TaskListPeriodTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns/TaskListPeriodTableColumn";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {StatusTypes, TaskStatusTypes, TaskUserTypes} from "@app/constants";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";

export class TaskListPeriodCell extends GenericCell {

    public listConfigurations = new Map<string, TaskListConfiguration>();

    constructor(row: Row, column: TaskListPeriodColumn) {
        super(row, column);

        const taskTypeIdsFilteredByProjectType = row.project.project_type.project_types_task_types
            ?.filter(projectTypesTaskType => projectTypesTaskType.visible)
            ?.map(projectTypesTaskType => projectTypesTaskType.task_type_id) ?? [];

        column
            .getTableColumns<TaskListPeriodTableColumn>()
            .forEach(tableColumn => {
                const listConfiguration = new TaskListConfiguration();

                listConfiguration.setTaskTypeIds(taskTypeIdsFilteredByProjectType);

                if (tableColumn.periodType == TaskListPeriodColumn.Period_WithoutDeadline) {
                    this.setupWithoutPeriodListConfiguration(row, tableColumn, listConfiguration);
                } else {
                    this.setupWithPeriodListConfiguration(row, tableColumn, listConfiguration);
                }

                this.listConfigurations.set(tableColumn.periodType, listConfiguration);
            });
    }

    private setupWithPeriodListConfiguration(row: Row, tableColumn: TaskListPeriodTableColumn, listConfiguration: TaskListConfiguration) {
        listConfiguration
            .setLimit(ListConfiguration.SmartLimit)
            .setProject(row.project)
            .setShowProjectMiniCard(false)
            .setAcceptanceStatus(null)
            .setUseGlobalFilter(true)
            .setCreatePresetGenerators([
                // Defaults
                new TaskUserPresetGenerator(TaskUserTypes.Creator, AppInjector.getInjector().get(UsersService).user.id),
                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                new TaskUseStatusRulesPresetGenerator(true),

                new TaskProjectPresetGenerator(row.project.id),
                ...row.project.departments?.map(department => new TaskDepartmentPresetGenerator(department.id)) ?? [],
            ]);
    }

    private setupWithoutPeriodListConfiguration(row: Row, tableColumn: TaskListPeriodTableColumn, listConfiguration: TaskListConfiguration) {
        listConfiguration
            .setLimit(ListConfiguration.SmartLimit)
            .setShowProjectMiniCard(false)
            .setAcceptanceStatus(null)
            .setProject(row.project)
            .setHasNonDeadline(true)
            .setShowMilestoneMiniCard(true)
            .setUseGlobalFilter(true)
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                // Defaults
                new TaskUserPresetGenerator(TaskUserTypes.Creator, AppInjector.getInjector().get(UsersService).user.id),
                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                new TaskUseStatusRulesPresetGenerator(true),

                new TaskProjectPresetGenerator(row.project.id),
                ...row.project.departments?.map(department => new TaskDepartmentPresetGenerator(department.id)) ?? [],
            ]);
    }

}
