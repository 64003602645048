import {ColumnGroup} from "@app/services/ShellColumnService/ColumnGroup";

export class FilterColumnRow {

    public isGroup: boolean;
    public columnGroup: ColumnGroup;

    constructor(columnGroup: ColumnGroup) {
        this.isGroup = columnGroup.columns.length > 1;
        this.columnGroup = columnGroup;
    }

    public checkIsActive() {
        this.columnGroup.checkActive();
    }

}
