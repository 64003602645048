import {NgModule} from "@angular/core";
import {CalculatedFieldComponent} from "@app/shared/_elements/calculated-field/calculated-field.component";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CalculatedFieldComponent,
    ],
    exports: [
        CalculatedFieldComponent

    ],
    providers: [

    ],
})
export class CalculatedFieldModule {}
