/**
 * Created by ModelParser
 * Date: 20-12-2018.
 * Time: 11:42.
 */
import {DepartmentsProjectTypeDefinition,} from './definitions/DepartmentsProjectTypeDefinition';

export class DepartmentsProjectType extends DepartmentsProjectTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
