import {BaseComparison} from "@app/shared/_ui/lists/BaseComparison";
import {Task} from "@app/core/models";

export class MilestoneTaskComparison extends BaseComparison {

    public compare(a: Task, b: Task): number {
        const aIndex = a.milestones_tasks ? a.milestones_tasks[0]?.index_ ?? 0 : 0;
        const bIndex = b.milestones_tasks ? b.milestones_tasks[0]?.index_ ?? 0 : 0;
        return aIndex - bIndex;
    }

}
