<div class="d-flex flex-row justify-content-between mb-0 align-items-center">

    <div class="d-flex flex-row flex-fill align-items-start flex-wrap-mobile">

        <div class="d-flex align-items-center justify-content-between flex-grow-1" style="min-width: 246px;">

            <span
                *ngIf="showAcceptanceStatus && acceptanceStatus !== undefined"
                class="badge me-1 d-flex align-items-center justify-content-center"
                style="width: 36px; height: 36px;"
                [class.bg-info]="acceptanceStatus == TasksUserAcceptanceStatus.New"
                [class.bg-success]="acceptanceStatus == TasksUserAcceptanceStatus.Approved"
                [class.bg-danger]="acceptanceStatus == TasksUserAcceptanceStatus.Declined"
            >
                <span class="fa fa-bell"></span>
            </span>

            <div
                *ngIf="showArchivedToggle"
                class="d-flex align-items-stretch d-flex justify-content-between flex-grow-1 input-group flex-nowrap w-auto toggle-archived"
            >
                <div class="input-group-text d-flex align-items-center">
                    <app-toggle-item
                        [interactive]="true"
                        [class.text-success]="isArchived"
                        [class.text-muted]="!isArchived"
                        [active]="isArchived"
                        [icon]="'fa-check-circle'"
                        [iconInactive]="'fa-circle'"
                        [ngbTooltip]="(isArchived ? '_ui_reopen' : '_ui_partially_completed') | translate"
                        (onToggle)="onArchivedToggled()"
                    />
                </div>
            </div>

            <app-user-search
                class="flex-fill align-self-start w-100 flex-grow-1"
                [internalValue]="user"
                [multiple]="false"
                [selectedUsers]="type.selectedUsers"
                [removeOnBlur]="true"
                [showFilterButton]="false"
                [class.me-1]="showDatePicker"
                (itemSelected)="onUserSelected($event)"
            />
        </div>

        <app-datepicker
            *ngIf="showDatePicker"
            class="mb-0 align-self-end flex-grow-1" style="max-width: 230px;"
            [internalValue]="deadline"
            (valueChanged)="onDeadlineValueChanged($event)"
        />

    </div>

    <a
        class="ms-1 btn btn-outline-danger"
        (click)="onDeleteBtnClicked()"
    >
        <i class="fal fa-trash-alt"></i>
    </a>
</div>
