import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SettingsEditorComponent} from "@app/editor/settings-editor/settings-editor.component";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {GhostContainerModule} from "@app/editor/ghost-container/GhostContainer.module";

@NgModule({

    imports: [
        CommonModule,
        TranslateModule,
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        BrowserAnimationsModule,
        ToggleItemModule,
        GhostContainerModule,
    ],
    declarations: [
        SettingsEditorComponent,
    ],
    exports: [
        SettingsEditorComponent,  // export hvis det skal være tilgængligt i import af modul
    ]
})
export class SettingsEditorModule {
}
