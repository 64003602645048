import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {CaseRow} from "@app/pages/displays/display-cases/CaseRow";
import {GoalTextColumn} from "@app/pages/displays/display-cases/Columns/GoalTextColumn";

export class GoalTextCell extends BaseCell {

    public row: CaseRow;
    public column: GoalTextColumn;

    public constructor(row: BaseRow, column: BaseColumn) {
        super(row, column);
    }

    public get value(): string {
        switch (this.column.getField()) {
            case 'goalText1':
                return this.row.projectCase.goal_text1;
            case 'goalText2':
                return this.row.projectCase.goal_text2;
            case 'goalText3':
                return this.row.projectCase.goal_text3;
            default:
                return '';
        }
    }

    public setValue(value: string): void {
        switch (this.column.getField()) {
            case 'goalText1':
                this.row.projectCase.setGoalText1(value);
                break;
            case 'goalText2':
                this.row.projectCase.setGoalText2(value);
                break;
            case 'goalText3':
                this.row.projectCase.setGoalText3(value);
                break;
        }
    }

}
