import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-milestones/Row";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {
    MilestoneYearWheelCell
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Cells/MilestoneYearWheelCell";
import {
    MilestoneYearWheelTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/MilestoneYearWheelTableColumn";
import {YearWheelOptions, YearWheelService} from "@app/services/year-wheel.service";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {YearWheelFetcher, YearWheelFetcherRequest} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelFetcher";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {AppInjector} from "@app/services/app-injector.service";

export class MilestoneYearWheelColumn extends BaseColumn<MilestoneYearWheelCell> implements ColumnDataFetcherInterface {

    public yearWheelPeriod: { start: Date, end: Date };
    public yearWheelDisplayOptions: YearWheelOptions;

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: YearWheelFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new YearWheelFetcher(column.name);

        this.yearWheelDisplayOptions = AppInjector.getInjector().get(YearWheelService).getSettings();
    }

    createTableColumns(): BaseTableColumn[] {
        const tableColumn = new MilestoneYearWheelTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    createCell(row: Row): BaseCell {
        const cell = new MilestoneYearWheelCell(row, this);
        this.dataFetcher.addRequest(new YearWheelFetcherRequest(cell.yearWheelConfiguration));
        return cell;
    }

    public setPeriod(start: Date, end: Date) {
        this.yearWheelPeriod = {
            start: start,
            end: end,
        };
    }

}
