import {
    AfterContentChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges, ViewChild
} from '@angular/core';
import {CardComponent} from '@app/shared/_ui/cards/card.component';
import {ProjectsService} from '@app/services/projects.service';
import {Project} from '@app/core/models/Project';
import {Router} from '@angular/router';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {DisplayTypes} from '@app/constants';
import {Display} from '@app/core/models/Display';
import {Field, ProjectEditorService} from '@app/editor/project-editor-loader/project-editor.service';
import {MiniCardFields} from '@app/editor/project-editor-loader/Fields';
import {Settings} from '@app/pages/displays/display-projects/Settings';
import {DisplayService} from '@app/services/display.service';
import {Api} from "@app/core/http/Api/Api";
import {ListConfiguration} from '@app/shared/_ui/lists/ListConfiguration';
import {
    SmallCardProjectConfiguration
} from "@app/shared/_ui/cards/small/small-card-project/small-card-project-configuration";

@Component({
    selector: 'app-small-card-project',
    templateUrl: './small-card-project.component.html',
    styleUrls: ['../../card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmallCardProjectComponent extends CardComponent implements OnInit, OnChanges, AfterContentChecked {

    @Input() item: CardItem<Project>;
    @Input() showNavigationPlanning = true;
    @Input() model: Project;
    @Input() loadDetails = false;
    @Input() configuration = new SmallCardProjectConfiguration();
    @Input() listConfiguration: ListConfiguration;
    @Input() removable = false;
    @Input() tooltip: string;
    @Input() editable = false;
    @Input() interactive = true;
    @Output() contentClassChange = new EventEmitter<Project>();
    @Output() onCardClickEvent = new EventEmitter();
    @Output() onRemove = new EventEmitter();

    // UI
    @ViewChild('cardContainer') cardContainer: ElementRef;
    public fields: Map<number, Field>;
    public planningDisplays: Display[];
    public planningDisplayName: string;
    public programDisplays: Display[];
    public programDisplaysName: string;

    constructor(private projectService: ProjectsService,
                private router: Router,
                private editorService: ProjectEditorService,
                private cd: ChangeDetectorRef,
                private displayService: DisplayService
    ) {
        super();
        this._isLoading = true;
        this.initialized = false;
        this.cdr = cd;
    }

    private detailsLoaded = false;

    ngOnInit() {
        super.ngOnInit();

        if (this.loadDetails && this.item?.item && !this.detailsLoaded) {
            this.detailsLoaded = true;
            let project = this.item.item;
            Api.projects().getById(this.item?.item.id).find((p) => {
                this.detailsLoaded = true;
                this.model = project = p[0];
                this.onItemUpdated(project);
            })
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    onItemUpdated(item: Project) {
        super.onItemUpdated(item);
        this.render();
    }

    // <editor-fold desc="View Actions">

    onStatusChange($event: number) {
        if (this.model.id !== 0 && !this.editable) {
            this.model.setStatus($event);
        }
    }

    ngAfterContentChecked() {
        // AfterContentChecked
    }

    openDoor($event: any, display: Display) {
        $event.stopImmediatePropagation();
        switch (display.display_type_id) {
            case DisplayTypes.ProjectDetails:
                this.router.navigate(
                    [
                        '/app/displays/project-details/',
                        display.id,
                        this.shellService.getPageSettings()?.departmentId,
                        0,
                        this.model.id,
                        this.shellService.getPageSettings()?.primaryDisplayId,
                    ], {queryParamsHandling: "merge"}
                );
                break;
        }
    }

    openDisplay($event: any, display: Display) {
        $event.stopImmediatePropagation();
        this.router.navigate([
                '/app/displays/projects/',
                display.id,
                this.shellService.getPageSettings()?.departmentId,
                0,
                0,
                this.model.id,
            ],
            {queryParamsHandling: "merge"}
        );

        this.configuration.onGoToProgramClickEvent.emit(display);
    }

    public isFieldVisible(fieldId: number): boolean {
        if (this.fields && this.fields.get(fieldId)) {
            return this.fields.get(fieldId).visible;
        } else {
            return false;
        }
    }

    get Fields() {
        return MiniCardFields;
    }

    // </editor-fold>

    protected render() {
        super.render();

        // Fields til oversættelser
        this.editorService.getMiniCardFieldsForType(this.model.project_type_id, fields => {
            this.fields = fields;
            this.markChangeDetectionDirty();
        });

        this.projectService.getProjectType(this.model.project_type_id, projectType => {
            this.model.project_type = projectType;
            if (!projectType) {
                // Unknown project type. Ex project list can do this
                return;
            }

            this.displayService.getDisplaysWithSettings(displays => {
                this.planningDisplays = displays.filter(display => {
                    const checkDisplayType = [DisplayTypes.ProjectDetails].includes(display.display_type_id);
                    const checkDepartment = display.departments_displays?.find(departmentsDisplays =>
                        departmentsDisplays.visible &&
                        departmentsDisplays.department_id === this.shellService.getPageSettings()?.departmentId) !== undefined;
                    return checkDisplayType && checkDepartment;
                });

                const lowerProjectTypeIds = projectType.getLowerProjectTypeIds();
                this.programDisplays = displays.filter(display => {
                    let checkProjectTypeIdSetting = false;
                    let checkDepartment = false;

                    const excludedByConfig = this.configuration.excludedProgramDisplays.map(item => item.id).includes(display.id);

                    if (display.displays_settings) {
                        display.displays_settings
                            .filter(displaysSetting => {
                                return displaysSetting.setting_id === Settings.ProjectTypeIds;
                            })
                            .map(displaysSetting => Number(displaysSetting.value))
                            .forEach(projectTypeId => {
                                if (lowerProjectTypeIds.includes(projectTypeId)) {
                                    checkProjectTypeIdSetting = true;
                                }
                            });
                    }

                    if (display.departments_displays) {
                        display.departments_displays.forEach(departmentsDisplays => {
                            if (departmentsDisplays.department_id === this.shellService.getPageSettings()?.departmentId) {
                                if (departmentsDisplays.visible) {
                                    checkDepartment = true;
                                }
                            }
                        });
                    }
                    return checkDepartment && checkProjectTypeIdSetting && !excludedByConfig;
                });
            });

            this.planningDisplayName = this.translateService.instant('_ui_display_planning') + ' - ' + this.model.title;
            this.programDisplaysName = this.translateService.instant('_ui_overview') + ' - ' + this.model.title;

            this.initialized = true;
            this.markChangeDetectionDirty();
        });
    }

}
