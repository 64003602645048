<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title" [innerHTML]="'_ui_dialog_select_project_milestone_title' | translate"></h4>
        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <div class="modal-body">
        <div class="d-flex flex-row align-items-start justify-content-center">
            <ul *ngIf="items" class="list-unstyled card-list d-flex flex-wrap">
                <li *ngFor="let item of items">
                    <app-card-milestone
                        [item]="item"
                        [interactive]="false"
                        (onCardClickEvent)="onItemClick(item)"
                    ></app-card-milestone>
                </li>

            </ul>
            <app-loading-indicator *ngIf="isLoading && !items" [showLogo]="false" class="align-self-center"></app-loading-indicator>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary ellipsis" (click)="decline()">{{ '_global_cancel' | translate }}</button>
        <button type="button" class="btn btn-success ellipsis" ngbAutofocus (click)="accept()">{{ '_global_ok' | translate }}</button>
    </div>
</div>
