import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {fadeAnimation} from '@app/animations';
import {CategoryType} from '@app/core/models/CategoryType';
import {Category} from '@app/core/models/Category';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseSearchComponent} from '@app/shared/_forms/search/base-search/base-search.component';
import {merge, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {Color, Phase} from "@app/core/models";
import {
    CategoryPickerItem
} from '@app/editor/quick-editor/editors/generic/category-picker-list-editor/CategoryPickerItem';

@Component({
    selector: 'app-category-picker',
    templateUrl: './category-picker.component.html',
    styleUrls: ['./category-picker.component.scss'],
    animations: [fadeAnimation],
    providers: [

        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CategoryPickerComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CategoryPickerComponent),
            multi: true,
        }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryPickerComponent extends BaseSearchComponent implements OnInit {

    @Input() type: CategoryPickerItem;
    @Input() categoryType: CategoryType; // Used to filter items
    @Input() _internalValue: Category[] = [];
    @Input() departmentId: number;
    @Output() itemSelected = new EventEmitter<Category>();
    @Output() itemRemoved = new EventEmitter<Category | undefined>();
    @Output() valueChanged = new EventEmitter<any>();

    // UI
    public formatter = (x: Category) => x.name;
    public sort = (a: Category, b: Category) => a.index_ - b.index_;

    public colors: Color[];
    public selectedColors: Color[] = [];

    // Data
    public items: Category[];

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this._internalValue = this._internalValue?.sort(this.sort);

        Category.GetByCategoryTypeId(this.categoryType.id, (items) => {
            this.items = this.departmentId ? items.filter(item => {
                return !item.departments || item.departments?.findIndex(d => d.id == this.departmentId) !== -1;
            }) : items;
            this.colors = [];
            this.items.map(c => {
                if (c.color) {
                    this.colors.push(c.color);
                }
            });
            this.mapColors();
        });
    }

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
        );
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            tap(() => this.searching = true),
            map(term => {
                if (term == '') {
                    this.applyResult(this.items ?? []);
                    this.searchFailed = null;
                } else {
                    this.applyResult(this.items.filter(v => String(v.name).toLowerCase().indexOf(String(term).toLowerCase()) > -1));
                    this.searchFailed = this.items.length > 0;
                }
                return this.result;
            }),
            tap(() => this.searching = false),
        );
    };

    internalValueUpdated() {
        this.mapColors();
    }

    private mapColors() {
        this.selectedColors = [];
        if (this._internalValue && this._internalValue.length > 0 && this.items) {
            this._internalValue.forEach(c => {
                if (c.color_id && !c.color) {
                    c.color = this.items?.find(ic => ic.id == c.id)?.color;
                }
                if (c.color) {
                    this.selectedColors.push(c.color);
                }
            });
        }
        this.detectChanges();
    }

    private applyResult(result: Category[]) {
        result = result.filter(item => !this.Helpers.itemExists(this.internalValue ? this.internalValue : [], item.id));
        this.result = result.length ? result : null;
    }

    triggerSelection($event: NgbTypeaheadSelectItemEvent, input: any) {
        $event.preventDefault();
        input.value = '';
        this.addItem($event.item)
    }

    toggleColor(color: Color) {
        const category: Category = this.items.find(i => i.color_id == color.id);
        if (category) {
            if (this._internalValue.findIndex(p => p.id == category.id) !== -1) {
                this.removeItem(category);
            } else {
                this.addItem(category);
            }
        }
    }

    addItem(item: Category) {
        if (!this._internalValue || !this.multiple) {
            this._internalValue = [];
        }

        if (item.color) {
            this.selectedColors.push(item.color);
        }

        this._internalValue.push(item);
        this._internalValue = this._internalValue?.sort(this.sort);
        this.onChange(this._internalValue); // Value is good!
        this.propagateChange(this.internalValue);
        this.itemSelected.emit(item);
        this.valueChanged.emit();
    }

    removeItem(item: Category) {
        this._internalValue = this._internalValue.filter(p => p.id != item.id);
        if (item.color) {
            this.selectedColors.splice(this.selectedColors.findIndex(c => c.id == item.color_id), 1);
        }
        this.onChange(this._internalValue); // Value is good!
        this.propagateChange(this.internalValue);
        this.itemRemoved.emit(item);
        this.valueChanged.emit();
    }

    removeAllColors() {
        this.itemRemoved.emit();
        this._internalValue = [];
        this.selectedColors = [];
        this.onChange(this._internalValue); // Value is good!
        this.propagateChange(this.internalValue);
        this.valueChanged.emit();
    }

    resultTemplateType: {
        result: Category,
        term: string,
    }

}
