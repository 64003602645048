import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {PANEL_ANIMATION} from '@app/editor/panel.animation';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {EditorEvents} from '@app/editor/quick-editor/EditorEvents';
import {Color, Department, Phase, PhasesProjectType, Project} from '@app/core/models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-create-phase-dialog',
    templateUrl: './create-phase-dialog.component.html',
    styleUrls: ['./create-phase-dialog.component.scss'],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation,
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0.5
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePhaseDialogComponent extends BaseModalComponent implements OnInit, EditorEvents<Project> {

    // Bindings to parent
    public model: Phase = Phase.createDefault();
    @Input() title: string;

    @Input() departments: Department[] = [];
    @Input() projectTypeIDs: number[] = [];

    constructor(private modal: NgbActiveModal,
                private cd: ChangeDetectorRef) {
        super(modal);
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.isOpen = true;

        this.model.changeable_date = true;
        if(this.departments){
            this.model.departments = this.departments;
        }
        if(this.projectTypeIDs){
            this.model.phases_project_types = [];
            this.projectTypeIDs.forEach((pti) => {
                // const phasesProjectType = new PhasesProjectType();
                const phasesProjectType = PhasesProjectType.createDefault();
                phasesProjectType.project_type_id = pti;
                phasesProjectType.visible = true;
                this.model.phases_project_types.push(phasesProjectType);
            })
        }
    }

    public decline() {
        // this.model?.delete();
        this.activeModal.close(false);
    }

    public accept() {
        console.log('save model : ', this.model)
        this.model.save((phase: any)=>{
            // console.log('model saved : ', 'response : ', phase)
            Phase.ClearCache();
            this.activeModal.close({ phase: phase });
        })
    }

    public dismiss() {
        this.activeModal.dismiss();
        this.activeModal.close(false);
    }

    public onItemUpdated(item: Project): void {
        // this.card = new CardItem<Project>(item, new CardProjectConfiguration());
        // this.detectChanges();
        // if(this.waitForProjectSave && this.model.id){
        //     this.openDoor();
        //     this.activeModal.close({project: this.model});
        // }
    }

    setColor($event: Color) {
        if($event) {
            this.model.color_id = $event.id;
            this.model.color = $event;
        }
        this.detectChanges();

    }

    setDepartments($event: Department[]) {
        if($event) {
            this.model.departments = $event;
        }
        this.detectChanges();
    }
}
