import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditStatusList} from "@app/editor/quick-editor/editors/generic/status-list-editor/EditStatusList";
import {
    EditStatusListConfiguration
} from "@app/editor/quick-editor/editors/generic/status-list-editor/EditStatusListConfiguration";
import {Status} from "@app/core/models";
import {StatusItem} from "@app/editor/quick-editor/editors/generic/status-list-editor/StatusItem";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {StatusTypes} from "@app/constants";

@Component({
    selector: 'app-status-list-editor',
    templateUrl: './status-list-editor.component.html',
    styleUrls: ['./status-list-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusListEditorComponent extends BaseEditor<EditStatusList> {
    protected eventFieldName = 'status';

    @Input() model!: EditStatusList & HasEventGenerator & BaseModel;
    @Input() configuration!: EditStatusListConfiguration;
    @Input() editorEvents: EditorEvents;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    public types: StatusItem[];

    protected render() {
        this.configuration.types.forEach(type => {
            type.status = this.model.findStatusByTypeId(type.id) ?? StatusTypes.GREY;
        });
        this.types = this.configuration.types;
        this.detectChanges();
    }

    public onValueChanged(type: StatusItem, status: number) {
        type.setStatus(status);
        this.save(type.id, status);
    }

    private save(typeId: number, status: number) {
        this.model.setStatusByType(typeId, status);
        this.render();
        this.onItemUpdated();
        this.detectChanges();
    }

}
