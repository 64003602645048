<div class="container-fluid">

    <div class="row pt-4">
        <div class="-col-sm-6">
            <div class="card w-100">
                <div class="card-body">
                    <h5 class="card-title"><i class="fal fa-info-circle me-1"
                                              aria-hidden="true"></i>{{'_ui_system_info' | translate}}</h5>
                    <span>KlartBoard</span>
                    <div class="card-text">
                        <div class="d-inline-block d-flex flex-row"><!--v{{version}}-->
                            <div class="d-flex flex-column">
                                <div class="text-danger small me-2" *ngIf="!environment.production" >{{'_ui_development_version' | translate}}</div>
                                <div class="d-flex flex-row justify-content-start small flex-wrap "
                                 style="opacity: 0.5;">
                                <div class="small text-black-50  me-2"><i
                                    class="fal fa-code-branch"></i> {{environment.version}}
                                </div>
                                <div class="small text-black-50  me-2" *ngIf="gitVersions"><i
                                    class="fab fa-github"></i> {{gitVersions.npm_version}}, {{gitVersions.revision}}
                                    , {{gitVersions.branch}}</div>
                                <div class="small text-black-50 " *ngIf="gitVersions"><i
                                    class="fab fa-google"></i> {{gitVersions.version}}
                                    <ng-container *ngIf="gitVersions.project_id !== '_PROJECT_ID_'">
                                        , {{gitVersions.project_id}}</ng-container>
                                </div>
                            </div>
                            </div>
                        </div>


                        <div class="rounded p-1 mt-2">
                        <div class="d-flex align-items-center" (click)="generateStorageDescription()">
                            <h6>Localstorage</h6>
                            <span class="ms-auto d-flex align-items-center text-small">
                                <span class="me-1">{{totalSotrageBytes | number : '1.0-0'}} KB</span>
                                <i class="fal fa-arrows-rotate"></i>
                            </span>

                        </div>

                        <ul *ngIf="localStorageItems && localStorageItems.length > 0" class="list-unstyled text-small scroll-y border-top border-bottom pe-4 ps-2 pt-2 pb-2" style="max-height: 500px" >
                            <li *ngFor="let item of localStorageItems"
                                (click)="logItem(item)"
                                class="d-flex align-items-start"
                                [class.text-danger]="(item.bytes ) > 200"
                                [class.text-black-50]="(item.bytes )  < 1"


                            >
                                <span class="fal fa-cookie me-1"></span>

                                <span class="badge badge-counter me-1" >{{item.version}}</span>
                                <div class="d-flex flex-column " >
                                    <span [ngbTooltip]="item.key" class="ellipsis" style="width: 250px">{{item.name}}</span>
                                </div>
                                <span class="ms-auto d-flex align-items-center">

                                        <span class="me-1"
                                        [ngbTooltip]="(((item.bytes / totalSotrageBytes) * 100) | number : '1.0-0' ) + '%'"
                                        >{{(item?.bytes | number : '1.0-0') }} KB</span>
<!--                                        <span class="">{{((item.bytes / totalSotrageBytes) * 100) | number : '1.0-0' }}%</span>-->
                                    <span class="fal fa-trash text-danger" (click)="clearCookieKey(item)"></span>
                                </span>

                            </li>
                        </ul>
                        </div>


                        <div class="rounded p-1">
                            <div class="d-flex align-items-center" (click)="refreshDBEntries()">
                                <h6>Database</h6>
                                <span class="ms-auto d-flex align-items-center  text-small">
                                <i class="fal fa-arrows-rotate"></i>
                            </span>

                            </div>
                            <ul *ngIf="localDBItems && localDBItems.length > 0" class="list-unstyled text-small scroll-y border-top border-bottom pe-4 ps-2 pt-2 pb-2" style="max-height: 500px" >
                                <li *ngFor="let item of localDBItems"
                                    class="d-flex align-items-center"
                                    (click)="logDbItem(item)"
                                >
                                    <span class="fal fa-database me-1" [ngbTooltip]="item.created | date"></span>
                                    <span class="badge badge-counter me-1" >{{item.version}}</span>


                                    <div class="d-flex flex-column " >
                                        <span [ngbTooltip]="item.key" class="ellipsis" style="width: 250px">{{item.key}}</span>
                                    </div>
                                    <span class="ms-1 me-1 text-black-50 "  [ngbTooltip]="'_ui_created' | translate"><i class="fa-light fa-calendar me-1"></i>{{item.created | localizedDate}}, {{item.created | date: 'HH:mm'}}</span>
                                    <span class="ms-1 me-1 text-black-50 d-flex align-items-center" [ngbTooltip]="('_ui_expires' | translate)+': '+(item.timeToLiveInSeconds / 60 / 60)+ ' '+('_ui_hours' | translate)" *ngIf="item.timeToLiveInSeconds"><i class="fa-light fa-calendar-clock me-1"></i>{{(item.created.getTime() + (item.timeToLiveInSeconds * 1000)) | localizedDate}}, {{(item.created.getTime() + (item.timeToLiveInSeconds * 1000)) | date: 'HH:mm'}}
</span>

                                    <span class="ms-auto d-flex align-items-center">

                                        <span class="badge badge-info me-1 ps-1 pe-1" *ngIf="item?.userId">
                                            <app-user-with-icon [showInitials]="true" [modelId]="item.userId" [navigateToDashboard]="true"></app-user-with-icon>
                                        </span>
                                        <span class="me-1 text-end" style="width: 100px"
                                        >{{(item?.data.length | number : '1.0-0') }} KB</span>

                                        <span class="fal fa-trash text-danger" (click)="clearDBKey(item)"></span>

                                    </span>
                                </li>
                            </ul>
                        </div>


                        <p class="text-small mb-0"><i class="fal fa-code me-1 d-none" aria-hidden="true"></i> <span class="me-1">{{'_ui_developed_by' | translate}}</span> <a
                                href="https://www.4spaces.dk" class="me-1">4 Spaces</a> <span >{{'_ui_for_klartboard' | translate}}</span></p>

                        <div class="mt-1">
                            <button type="button" (click)="updateVersion()" class="btn btn-outline-secondary me-1"><span
                                class="fa fa-cookie me-1"></span>{{'_ui_reset_cookies' | translate}}</button>

                            <button type="button" (click)="openSettingsPanel()" class="btn btn-outline-secondary me-1"><span
                                class="fa fa-user-circle me-1"></span>{{'_ui_navigation_settings' | translate}}</button>

                            <a href="/ngsw/state" class="btn btn-outline-secondary" *ngIf="environment.production"><span class="fal fa-hard-hat me-1"></span>
                                Serviceworker</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 d-none">
            <div class="card w-100">
                <app-settings-editor [inline]="true"></app-settings-editor>
            </div>
        </div>

        <div class="col-sm-6" *ngIf="user">
            <div class="card w-100">
                <div class="card-body text-small">
                    <h5 class="card-title"><i class="fal fa-user-circle me-1" aria-hidden="true"></i><span>{{'_user' | translate}}</span></h5>
                    <div class="card-text"><strong><span >{{'_ui_name' | translate}}</span></strong> <span>{{user.name}}</span></div>
                    <div class="card-text"><strong><span >{{'_ui_username' | translate}}</span></strong> <span>{{user.username}}</span></div>
                    <div class="card-text"><strong><span >{{'_ui_id' | translate}}</span></strong> <span>{{user.id}}</span></div>
                    <div class="card-text"><strong><span >{{'_ui_usergroup' | translate}}</span></strong> <span>{{user.user_group_id}}</span></div>
                </div>
            </div>
        </div>
    </div>
</div>
