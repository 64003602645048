/**
 * Created by ModelParser
 */
import {Project} from '../Project';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class ProjectCaseDefinition extends BaseModel {
    cases?: number;
    goal?: number;
    date?: string;
    project_id?: number;
    project?: Project;
    cases_text1?: string;
    goal_text1?: string;
    cases_text2?: string;
    goal_text2?: string;
    cases_text3?: string;
    goal_text3?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.cases;
            delete this.goal;
            delete this.date;
            delete this.project_id;
            delete this.project;
            delete this.cases_text1;
            delete this.goal_text1;
            delete this.cases_text2;
            delete this.goal_text2;
            delete this.cases_text3;
            delete this.goal_text3;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.cases != null) {
            this.cases = data.cases;
        }
        if (data.goal != null) {
            this.goal = data.goal;
        }
        if (data.date != null) {
            this.date = data.date;
        }
        if (data.project_id != null) {
            this.project_id = data.project_id;
        }
        if (data.project != null) {
            this.project = new Project(data.project);
        }
        if (data.cases_text1 != null) {
            this.cases_text1 = data.cases_text1;
        }
        if (data.goal_text1 != null) {
            this.goal_text1 = data.goal_text1;
        }
        if (data.cases_text2 != null) {
            this.cases_text2 = data.cases_text2;
        }
        if (data.goal_text2 != null) {
            this.goal_text2 = data.goal_text2;
        }
        if (data.cases_text3 != null) {
            this.cases_text3 = data.cases_text3;
        }
        if (data.goal_text3 != null) {
            this.goal_text3 = data.goal_text3;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
