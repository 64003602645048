import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {environment} from '@env/environment';
import {AuthenticationService, I18nService} from '@app/core';
import {Observable} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Project} from '@app/core/models/Project';
import {User} from '@app/core/models/User';

@Component({
    selector: 'app-reference',
    templateUrl: './reference.component.html',
    styleUrls: ['./reference.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default, // TO-DO: Changedetection
})
export class ReferenceComponent implements OnInit {
    version: string = environment.version;
    environment: string = environment.environment;
    error: any;
    loginForm: UntypedFormGroup;
    isLoading = false;

    signedIn: Observable<boolean>;
    email: string;

    projects: Project[];


    constructor(private router: Router,
                private route: ActivatedRoute,
                private formBuilder: UntypedFormBuilder,
                private i18nService: I18nService,
                private authService: AuthenticationService,
                private oAuthService: OAuthService
    ) {

        this.projects = [
            new Project(
                {title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. At beatae ea eum facilis, illo ipsam maiores nulla obcaecati omnis quaerat quasi quibusdam recusandae saepe, soluta unde. Consequuntur nam nesciunt ut!'}), //
            new Project()
        ];
    }

    ngOnInit() {

        const claims = this.oAuthService.getIdentityClaims();
        console.log('claims :: ', claims);

        this.signedIn = this.authService.isSignedIn();
        this.authService.userChanged().subscribe(
            (user: User) => {
                console.log('this.authService.userChanged : ', user);
                this.email = user.username;

            });

        /*
        this.userService.me().subscribe(response => {
            console.log('userservice response : ', response);
        });
        */

    }


    setLanguage(language: string) {
        this.i18nService.language = language;
    }

    get currentLanguage(): string {
        return this.i18nService.language;
    }

    get languages(): string[] {
        return this.i18nService.supportedLanguages;
    }


    drop(event: CdkDragDrop<Project[]>) {
        // console.log('DRAG : ', event)
        moveItemInArray(this.projects, event.previousIndex, event.currentIndex);
    }
}
