import {AppInjector} from "@app/services/app-injector.service";
import {LocalStorageService} from "@app/services/local-storage.service";

export class LocalStorage {

    public isTopDisplayExpanded: boolean;
    public isMainDisplayExpanded: boolean;

    constructor(key: string) {
        const data = AppInjector.getInjector().get(LocalStorageService).get(key) ?? {};
        this.isTopDisplayExpanded = data.isTopDisplayExpanded ?? false;
        this.isMainDisplayExpanded = data.isMainDisplayExpanded ?? false;
    }

    public setIsTopDisplayExpanded(value: boolean) {
        this.isTopDisplayExpanded = value;
        this.save();
    }

    public setIsMainDisplayExpanded(value: boolean) {
        this.isMainDisplayExpanded = value;
        this.save();
    }

    private save() {
        AppInjector.getInjector().get(LocalStorageService).set(window.location.pathname, this);
    }

}
