import {NgModule} from "@angular/core";
import {
    TemplateColumnComponent
} from "@app/editor/quick-editor/columns/generic/template-column/template-column.component";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TemplateColumnComponent,
    ],
    exports: [
        TemplateColumnComponent

    ],
    providers: [

    ],
})
export class TemplateColumnModule {}
