export class EmitDebounce {

    private readonly waitInMs: number;
    private timeout?: number;
    private calls: (() => void)[] = [];

    public constructor(waitInMs: number) {
        this.waitInMs = waitInMs;
    }

    public emit(call: () => void) {
        this.calls.push(call);
        this.reset();
    }

    private reset() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(() => this.execute(), this.waitInMs);
    }

    private execute() {
        const calls = this.calls;
        this.calls = [];
        calls.forEach(call => call());
    }

}
