/**
 * Created by ModelParser
 * Date: 07-10-2020.
 * Time: 18:47.
 */
import {EmailNotificationDefinition} from './definitions/EmailNotificationDefinition';

export class EmailNotification extends EmailNotificationDefinition {

    constructor(json?: any) {
        super(json);
    }

}
