import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";

export class EditCheckboxConfiguration extends BaseEditorConfiguration {

    public prop: string;
    public label: string;

    constructor(
        prop: string,
        label: string,
    ) {
        super();
        this.prop = prop;
        this.label = label;
    }

}
