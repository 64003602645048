/**
 * Created by ModelParser
 * Date: 07-10-2020.
 * Time: 18:47.
 */
import {NotificationSubscriptionDefinition} from './definitions/NotificationSubscriptionDefinition';

export class NotificationSubscription extends NotificationSubscriptionDefinition {

    constructor(json?: any) {
        super(json);
    }

}
