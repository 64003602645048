/**
 * Created by ModelParser
 * Date: 21-02-2022.
 * Time: 05:19.
 */
import {RolesTaskTypeDefinition} from './definitions/RolesTaskTypeDefinition';

export class RolesTaskType extends RolesTaskTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
