/**
 * Created by ModelParser
 */
import {TaskEstimateType} from '../TaskEstimateType';
import {Task} from '../Task';
import {Load} from '../Load';
import {LoadsTaskEstimateType} from '../LoadsTaskEstimateType';
import {TaskFieldsTaskType} from '../TaskFieldsTaskType';
import {TaskField} from '../TaskField';
import {TaskStatusTypesTaskType} from '../TaskStatusTypesTaskType';
import {TaskStatusType} from '../TaskStatusType';
import {TaskDeadlineTypesTaskType} from '../TaskDeadlineTypesTaskType';
import {TaskDeadlineType} from '../TaskDeadlineType';
import {TaskTypesTaskUserType} from '../TaskTypesTaskUserType';
import {TaskUserType} from '../TaskUserType';
import {TaskEstimateTypesTaskType} from '../TaskEstimateTypesTaskType';
import {TaskTypesStatusRule} from '../TaskTypesStatusRule';
import {ProjectTypesTaskType} from '../ProjectTypesTaskType';
import {Department} from '../Department';
import {ReactionTypesTaskType} from '../ReactionTypesTaskType';
import {CalculatedField} from '../CalculatedField';
import {CategoryType} from '../CategoryType';
import {CategoryTypesTaskType} from '../CategoryTypesTaskType';
import {DisplayFiltersTaskType} from '../DisplayFiltersTaskType';
import {DisplayFilter} from '../DisplayFilter';
import {Role} from '../Role';
import {RolesTaskType} from '../RolesTaskType';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class TaskTypeDefinition extends BaseModel {
    description?: string;
    name?: string;
    definition?: string;
    default_task_estimate_type_id?: number;
    default_task_estimate_type?: TaskEstimateType;
    change_type_available?: boolean;
    tasks?: Task[];
    loads?: Load[];
    loads_task_estimate_types?: LoadsTaskEstimateType[];
    task_fields_task_types?: TaskFieldsTaskType[];
    task_fields?: TaskField[];
    task_status_types_task_types?: TaskStatusTypesTaskType[];
    task_status_types?: TaskStatusType[];
    task_deadline_types_task_types?: TaskDeadlineTypesTaskType[];
    task_deadline_types?: TaskDeadlineType[];
    task_types_task_user_types?: TaskTypesTaskUserType[];
    task_user_types?: TaskUserType[];
    task_estimate_types_task_types?: TaskEstimateTypesTaskType[];
    task_estimate_types?: TaskEstimateType[];
    task_types_status_rules?: TaskTypesStatusRule[];
    project_types_task_types?: ProjectTypesTaskType[];
    departments?: Department[];
    reaction_types_task_types?: ReactionTypesTaskType[];
    calculated_fields?: CalculatedField[];
    category_types?: CategoryType[];
    category_types_task_types?: CategoryTypesTaskType[];
    display_filters_task_types?: DisplayFiltersTaskType[];
    display_filters?: DisplayFilter[];
    roles?: Role[];
    roles_task_types?: RolesTaskType[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.description;
            delete this.name;
            delete this.definition;
            delete this.default_task_estimate_type_id;
            delete this.default_task_estimate_type;
            delete this.change_type_available;
            delete this.tasks;
            delete this.loads;
            delete this.loads_task_estimate_types;
            delete this.task_fields_task_types;
            delete this.task_fields;
            delete this.task_status_types_task_types;
            delete this.task_status_types;
            delete this.task_deadline_types_task_types;
            delete this.task_deadline_types;
            delete this.task_types_task_user_types;
            delete this.task_user_types;
            delete this.task_estimate_types_task_types;
            delete this.task_estimate_types;
            delete this.task_types_status_rules;
            delete this.project_types_task_types;
            delete this.departments;
            delete this.reaction_types_task_types;
            delete this.calculated_fields;
            delete this.category_types;
            delete this.category_types_task_types;
            delete this.display_filters_task_types;
            delete this.display_filters;
            delete this.roles;
            delete this.roles_task_types;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.description != null) {
            this.description = data.description;
        }
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.definition != null) {
            this.definition = data.definition;
        }
        if (data.default_task_estimate_type_id != null) {
            this.default_task_estimate_type_id = data.default_task_estimate_type_id;
        }
        if (data.default_task_estimate_type != null) {
            this.default_task_estimate_type = new TaskEstimateType(data.default_task_estimate_type);
        }
        if (data.change_type_available != null) {
            this.change_type_available = data.change_type_available;
        }
        if (data.tasks != null) {
            this.tasks = data.tasks.map((i: any) => new Task(i));
        }
        if (data.loads != null) {
            this.loads = data.loads.map((i: any) => new Load(i));
        }
        if (data.loads_task_estimate_types != null) {
            this.loads_task_estimate_types = data.loads_task_estimate_types.map((i: any) => new LoadsTaskEstimateType(i));
        }
        if (data.task_fields_task_types != null) {
            this.task_fields_task_types = data.task_fields_task_types.map((i: any) => new TaskFieldsTaskType(i));
        }
        if (data.task_fields != null) {
            this.task_fields = data.task_fields.map((i: any) => new TaskField(i));
        }
        if (data.task_status_types_task_types != null) {
            this.task_status_types_task_types = data.task_status_types_task_types.map((i: any) => new TaskStatusTypesTaskType(i));
        }
        if (data.task_status_types != null) {
            this.task_status_types = data.task_status_types.map((i: any) => new TaskStatusType(i));
        }
        if (data.task_deadline_types_task_types != null) {
            this.task_deadline_types_task_types = data.task_deadline_types_task_types.map((i: any) => new TaskDeadlineTypesTaskType(i));
        }
        if (data.task_deadline_types != null) {
            this.task_deadline_types = data.task_deadline_types.map((i: any) => new TaskDeadlineType(i));
        }
        if (data.task_types_task_user_types != null) {
            this.task_types_task_user_types = data.task_types_task_user_types.map((i: any) => new TaskTypesTaskUserType(i));
        }
        if (data.task_user_types != null) {
            this.task_user_types = data.task_user_types.map((i: any) => new TaskUserType(i));
        }
        if (data.task_estimate_types_task_types != null) {
            this.task_estimate_types_task_types = data.task_estimate_types_task_types.map((i: any) => new TaskEstimateTypesTaskType(i));
        }
        if (data.task_estimate_types != null) {
            this.task_estimate_types = data.task_estimate_types.map((i: any) => new TaskEstimateType(i));
        }
        if (data.task_types_status_rules != null) {
            this.task_types_status_rules = data.task_types_status_rules.map((i: any) => new TaskTypesStatusRule(i));
        }
        if (data.project_types_task_types != null) {
            this.project_types_task_types = data.project_types_task_types.map((i: any) => new ProjectTypesTaskType(i));
        }
        if (data.departments != null) {
            this.departments = data.departments.map((i: any) => new Department(i));
        }
        if (data.reaction_types_task_types != null) {
            this.reaction_types_task_types = data.reaction_types_task_types.map((i: any) => new ReactionTypesTaskType(i));
        }
        if (data.calculated_fields != null) {
            this.calculated_fields = data.calculated_fields.map((i: any) => new CalculatedField(i));
        }
        if (data.category_types != null) {
            this.category_types = data.category_types.map((i: any) => new CategoryType(i));
        }
        if (data.category_types_task_types != null) {
            this.category_types_task_types = data.category_types_task_types.map((i: any) => new CategoryTypesTaskType(i));
        }
        if (data.display_filters_task_types != null) {
            this.display_filters_task_types = data.display_filters_task_types.map((i: any) => new DisplayFiltersTaskType(i));
        }
        if (data.display_filters != null) {
            this.display_filters = data.display_filters.map((i: any) => new DisplayFilter(i));
        }
        if (data.roles != null) {
            this.roles = data.roles.map((i: any) => new Role(i));
        }
        if (data.roles_task_types != null) {
            this.roles_task_types = data.roles_task_types.map((i: any) => new RolesTaskType(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
