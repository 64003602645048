import {NgModule} from "@angular/core";
import {
    WhoWhenEditItemComponent
} from "@app/shared/_elements/who-when-edit/who-when-edit-item/who-when-edit-item.component";
import {UserSearchModule} from "@app/shared/_forms/search/user-search/UserSearch.module";
import {DatepickerModule} from "@app/shared/_forms/datepicker/Datepicker.module";
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';
import {ToggleItemModule} from '@app/shared/_elements/toggle-item/ToggleItem.module';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        UserSearchModule,
        DatepickerModule,
        TranslateModule,
        ToggleItemModule,
        NgbTooltipModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        WhoWhenEditItemComponent,
    ],
    exports: [
        WhoWhenEditItemComponent

    ],
    providers: [

    ],
})
export class WhoWhenEditItemModule {}
