/**
 * Created by ModelParser
 */
import {User} from '../User';
import {Deadline} from '../Deadline';
import {Archived} from '../Archived';
import {TodoCategory} from '../TodoCategory';
import {TodoStatus} from '../TodoStatus';
import {Project} from '../Project';
import {Display} from '../Display';
import {Favorite} from '../Favorite';
import {Reaction} from '../Reaction';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class TodoDefinition extends BaseModel {
    user_id?: number;
    user?: User;
    deadline_id?: number;
    deadline?: Deadline;
    title?: string;
    description?: string;
    archived_id?: number;
    archived?: Archived;
    todo_category_id?: number;
    todo_category?: TodoCategory;
    acceptance_status?: number;
    main_status_id?: number;
    main_status?: TodoStatus;
    is_private?: boolean;
    project_id?: number;
    project?: Project;
    display_id?: number;
    display?: Display;
    show_on_display?: boolean;
    num_stars?: number;
    todo_status?: TodoStatus[];
    favorites?: Favorite[];
    reactions?: Reaction[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.user_id;
            delete this.user;
            delete this.deadline_id;
            delete this.deadline;
            delete this.title;
            delete this.description;
            delete this.archived_id;
            delete this.archived;
            delete this.todo_category_id;
            delete this.todo_category;
            delete this.acceptance_status;
            delete this.main_status_id;
            delete this.main_status;
            delete this.is_private;
            delete this.project_id;
            delete this.project;
            delete this.display_id;
            delete this.display;
            delete this.show_on_display;
            delete this.num_stars;
            delete this.todo_status;
            delete this.favorites;
            delete this.reactions;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.deadline_id != null) {
            this.deadline_id = data.deadline_id;
        }
        if (data.deadline != null) {
            this.deadline = new Deadline(data.deadline);
        }
        if (data.title != null) {
            this.title = data.title;
        }
        if (data.description != null) {
            this.description = data.description;
        }
        if (data.archived_id != null) {
            this.archived_id = data.archived_id;
        }
        if (data.archived != null) {
            this.archived = new Archived(data.archived);
        }
        if (data.todo_category_id != null) {
            this.todo_category_id = data.todo_category_id;
        }
        if (data.todo_category != null) {
            this.todo_category = new TodoCategory(data.todo_category);
        }
        if (data.acceptance_status != null) {
            this.acceptance_status = data.acceptance_status;
        }
        if (data.main_status_id != null) {
            this.main_status_id = data.main_status_id;
        }
        if (data.main_status != null) {
            this.main_status = new TodoStatus(data.main_status);
        }
        if (data.is_private != null) {
            this.is_private = data.is_private;
        }
        if (data.project_id != null) {
            this.project_id = data.project_id;
        }
        if (data.project != null) {
            this.project = new Project(data.project);
        }
        if (data.display_id != null) {
            this.display_id = data.display_id;
        }
        if (data.display != null) {
            this.display = new Display(data.display);
        }
        if (data.show_on_display != null) {
            this.show_on_display = data.show_on_display;
        }
        if (data.num_stars != null) {
            this.num_stars = data.num_stars;
        }
        if (data.todo_status != null) {
            this.todo_status = data.todo_status.map((i: any) => new TodoStatus(i));
        }
        if (data.favorites != null) {
            this.favorites = data.favorites.map((i: any) => new Favorite(i));
        }
        if (data.reactions != null) {
            this.reactions = data.reactions.map((i: any) => new Reaction(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
