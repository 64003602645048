<div class="d-flex h-100 flex-fill position-relative toggle-header bg-dark text-light align-items-center">
    <div class="d-flex justify-content-start p-2 align-items-center w-100 ">

        <div class="d-flex align-items-center" *ngIf="loads && periodRatings">
            <span class="me-2">
                <i class="fal fa-light fa-arrow-rotate-right cursor-pointer me-1" (click)="reload()"
                   [ngbTooltip]="('_ui_reload' | translate) + '...'"></i>{{('_ui_load_team_total' | translate)}}:
            </span>

            <span *ngIf="periodRatings" class=" me-2">
                <i class="fal fa-business-time me-1"
                   aria-hidden="true"></i><span>{{('_user_load_work_hours' | translate)}}: </span><span>{{(workTimeTotal / 60 / 60) | number : '1.0-0'}}
                {{('_ui_hours' | translate | lowercase)}}</span></span>


            <span *ngIf="periodRatings" class=" me-2">
                <i class="fal fa-business-time me-1"
                   aria-hidden="true"></i><span>{{('_user_load_work_planned' | translate)}}: </span><span>{{(loadTimeTotal / 60 / 60) | number : '1.0-0'}}
                {{('_ui_hours' | translate | lowercase)}}</span></span>

            <span *ngIf="periodRatings" class=" me-2">
                <i class="fal fa-calendar-week me-1"
                   aria-hidden="true"></i><span>{{('_user_load_free_hours' | translate)}}: </span><span>{{(freeTimeTotal / 60 / 60) | number : '1.0-0'}}
                {{('_ui_hours' | translate | lowercase)}}</span></span>

        </div>

        <app-loading-indicator [showLogo]="false" *ngIf="!loads && !periodRatings"></app-loading-indicator>
    </div>


</div>
