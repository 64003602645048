import {Filter} from '@app/pages/displays/filtering/Filter';
import {Sort} from '@app/pages/displays/filtering/Sort';
import {Period} from '@app/pages/displays/filtering/Period';
import {DisplayFilter} from '@app/core/models/DisplayFilter';
import {Display} from '@app/core/models/Display';
import * as moment from 'moment';
import {DisplayFilterEditorFormConfigInterface} from '@app/editor/display-filter-editor/DisplayFilterEditorForm';
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";

export class Filters implements FiltersInterface {

    public applyDefaults(displayFilter: DisplayFilter) {
        displayFilter.filter_type = Filters.FilterPlannedInPeriod;
        displayFilter.period_type = Filters.PeriodThisWeek;
        displayFilter.sort_type = Filters.SortName;
        displayFilter.extra_sort1_type = Filters.TaskColumn_Sort_Status;
        displayFilter.extra_sort1_direction = 'asc';
    }

    // Filters
    public static FilterPlannedInPeriod = 'filter-planned-in-period';
    public static FilterDeadlineInPeriod = 'filter-deadline-in-period';
    public static FilterArchivedInPeriod = 'filter-archived-in-period';
    public static FilterCreatedInPeriod = 'filter-created-in-period';

    // Sorts
    public static SortName = 'sort-name';
    public static SortUserDefined = 'sort-user-defined';
    // Sorts: Task-column
    public static TaskColumn_Sort_Title = 'sort-title';
    public static TaskColumn_Sort_Status = 'sort-status';
    public static TaskColumn_Sort_Deadline = 'sort-deadline';
    public static TaskColumn_Sort_PlanningDate = 'sort-planning-date';

    // Periods
    public static PeriodUserDefined = 'user-defined';
    public static PeriodLast14Days = 'last-14-days';   // -14 dage
    public static PeriodLast7Days = 'last-7-days';    // -7 dage
    public static PeriodToday = 'today';          // I dag
    public static PeriodTomorrow = 'tomorrow';       // +1 dag
    public static PeriodTodayTomorrow = 'today-tomorrow'; // I dag til +1 dag
    public static PeriodThisWeek = 'this-week';      // Denne uge
    public static PeriodNextWeek = 'next-week';      // Næste uge
    public static PeriodNext2Week = 'next-2-week';    // Om 2 uger
    public static PeriodNext3Week = 'next-3-week';    // Om 3 uger
    public static PeriodNext7Days = 'next-7-days';    // I dag til +7 dage
    public static PeriodNext14Days = 'next-14-days';   // I dag til +14 dage
    public static PeriodNextMonth = 'next-month';     // I dag til +30 dage

    public filters(display: Display): Filter[] {
        const taskTranslation = this.getTranslation('_tasks')
        let planned = new Filter(Filters.FilterPlannedInPeriod, this.getTranslation('_ui_filter_planned_items_in_period', {item: taskTranslation}), true);
        let deadline = new Filter(Filters.FilterDeadlineInPeriod, this.getTranslation('_ui_filter_with_deadline_in_period', {item: taskTranslation}), true); // `Opgaver med frist i periode`
        let archived = new Filter(Filters.FilterArchivedInPeriod, this.getTranslation('_ui_filter_completed_in_period', {item: taskTranslation}), true); // `Opgaver fuldført i periode`
        let created = new Filter(Filters.FilterCreatedInPeriod, this.getTranslation('_ui_filter_created_in_period', {item: taskTranslation}), true);

        const filters = [
            planned, deadline, archived, created
        ];
        filters.forEach(filter => {
            // filter.showCategoryTypes = false;
        });
        return filters;
    }

    public sorts(display?: Display, includePseudoTypes?: boolean): Sort[] {
        const name= new Sort(Filters.SortName, this.getTranslation('_ui_name'), true, null, Sort.SortTypeDisplay);
        const userDefined = new Sort(
            Filters.SortUserDefined,
            this.getTranslation('_ui_filter_user_defined_with_adjust_order'),
            false,
            null,
            Sort.SortTypeDisplay
        );

        return [
            name,
            userDefined,
            ...includePseudoTypes ? this.extraSort1Types(display) : [],
        ];
    }

    public extraSort1Label(display?: Display): string {
        return this.getTranslation('_ui_display_team_filter_sort_tasks');
    }

    public extraSort1Types(display?: Display): Sort[] {
        const status = new Sort(Filters.TaskColumn_Sort_Status, this.getTranslation('_ui_status'), false);
        status.sortDirectionTranslation = '_ui_order_direction_status';
        return [
            new Sort(Filters.TaskColumn_Sort_Title, this.getTranslation('_ui_title'), false),
            status,
            new Sort(Filters.TaskColumn_Sort_Deadline, this.getTranslation('_ui_filter_sort_deadlines'), false),
            new Sort(Filters.TaskColumn_Sort_PlanningDate, this.getTranslation('_ui_filter_sort_planning_date'), false),
        ];
    }

    private getTranslation(key: string, interpolateParams?: Object): string {
        return AppInjector.getInjector().get(TranslateService).instant(key, interpolateParams);
    }

    public periods(): Period[] {
        return [
            new Period(Filters.PeriodUserDefined, this.getTranslation('_ui_date_userdefined_start'), true),
            new Period(Filters.PeriodLast14Days, this.getTranslation('_ui_date_past_2_weeks')),
            new Period(Filters.PeriodLast7Days, this.getTranslation('_ui_date_past_week')),
            new Period(Filters.PeriodToday, this.getTranslation('_ui_date_today')),
            new Period(Filters.PeriodTomorrow, this.getTranslation('_ui_date_tomorrow')),
            new Period(Filters.PeriodTodayTomorrow, this.getTranslation('_ui_date_today_tomorrow')),
            new Period(Filters.PeriodThisWeek, this.getTranslation('_ui_date_this_week')),
            new Period(Filters.PeriodNextWeek, this.getTranslation('_ui_next_week')),
            new Period(Filters.PeriodNext2Week, this.getTranslation('_ui_date_in_2_weeks')),
            new Period(Filters.PeriodNext3Week, this.getTranslation('_ui_date_in_3_weeks')),
            new Period(Filters.PeriodNext7Days, this.getTranslation('_ui_date_next_7_days')),
            new Period(Filters.PeriodNext14Days, this.getTranslation('_ui_date_next_14_days')),
            new Period(Filters.PeriodNextMonth, this.getTranslation('_ui_date_next_month'))
        ];
    }

    public getPeriodStart(period: string, sourceDate?: Date): Date {
        let date = moment(sourceDate);
        switch (period) {
            case Filters.PeriodLast14Days:
                date.startOf('day').subtract(14, 'days');
                break;
            case Filters.PeriodLast7Days:
                date.startOf('day').subtract(7, 'days');
                break;
            case Filters.PeriodToday:
                date.startOf('day');
                break;
            case Filters.PeriodTomorrow:
                date.startOf('day').add(1, 'day');
                break;
            case Filters.PeriodTodayTomorrow:
                date.startOf('day');
                break;
            case Filters.PeriodThisWeek:
                date.startOf('isoWeek');
                break;
            case Filters.PeriodNextWeek:
                date.startOf('isoWeek').add(1, 'week');
                break;
            case Filters.PeriodNext2Week:
                date.startOf('isoWeek').add(2, 'weeks');
                break;
            case Filters.PeriodNext3Week:
                date.startOf('isoWeek').add(3, 'weeks');
                break;
            case Filters.PeriodNext7Days:
                date.startOf('day');
                break;
            case Filters.PeriodNext14Days:
                date.startOf('day');
                break;
            case Filters.PeriodNextMonth:
                date.startOf('day');
                break;
        }
        return date.toDate();
    }

    public getPeriodEnd(period: string, sourceDate?: Date, periodStart?: Date): Date {
        let date = moment(periodStart ? periodStart : sourceDate);
        switch (period) {
            case Filters.PeriodLast14Days:
                date.endOf('day');
                break;
            case Filters.PeriodLast7Days:
                date.endOf('day');
                break;
            case Filters.PeriodToday:
                date.endOf('day');
                break;
            case Filters.PeriodTomorrow:
                date.endOf('day').add(1, 'day');
                break;
            case Filters.PeriodTodayTomorrow:
                date.endOf('day').add(1, 'day');
                break;
            case Filters.PeriodThisWeek:
                date.endOf('isoWeek');
                break;
            case Filters.PeriodNextWeek:
                date.endOf('isoWeek').add(1, 'week');
                break;
            case Filters.PeriodNext2Week:
                date.endOf('isoWeek').add(2, 'weeks');
                break;
            case Filters.PeriodNext3Week:
                date.endOf('isoWeek').add(3, 'weeks');
                break;
            case Filters.PeriodNext7Days:
                date.endOf('day').add(7, 'days');
                break;
            case Filters.PeriodNext14Days:
                date.endOf('day').add(14, 'days');
                break;
            case Filters.PeriodNextMonth:
                date.endOf('day').add(1, 'month');
                break;
        }
        return date.toDate();
    }

    public standards(display: Display): DisplayFilter[] {
        let standards: DisplayFilter[] = [];

        let plannedThisWeek = new DisplayFilter();
        plannedThisWeek.display_id = display.id;
        plannedThisWeek.display = display;
        plannedThisWeek.filter_type = Filters.FilterPlannedInPeriod;
        plannedThisWeek.period_type = Filters.PeriodThisWeek;
        plannedThisWeek.statuses = [];
        plannedThisWeek.name = plannedThisWeek.generateName(this);
        standards.push(plannedThisWeek);

        let plannedToday = new DisplayFilter();
        plannedToday.display_id = display.id;
        plannedToday.display = display;
        plannedToday.filter_type = Filters.FilterPlannedInPeriod;
        plannedToday.period_type = Filters.PeriodToday;
        plannedToday.statuses = [];
        plannedToday.name = plannedToday.generateName(this);
        standards.push(plannedToday);

        let plannedTomorrow = new DisplayFilter();
        plannedTomorrow.display_id = display.id;
        plannedTomorrow.display = display;
        plannedTomorrow.filter_type = Filters.FilterPlannedInPeriod;
        plannedTomorrow.period_type = Filters.PeriodTomorrow;
        plannedTomorrow.statuses = [];
        plannedTomorrow.name = plannedTomorrow.generateName(this);
        standards.push(plannedTomorrow);

        let plannedTodayTomorrow = new DisplayFilter();
        plannedTodayTomorrow.display_id = display.id;
        plannedTodayTomorrow.display = display;
        plannedTodayTomorrow.filter_type = Filters.FilterPlannedInPeriod;
        plannedTodayTomorrow.period_type = Filters.PeriodTodayTomorrow;
        plannedTodayTomorrow.statuses = [];
        plannedTodayTomorrow.name = plannedTodayTomorrow.generateName(this);
        standards.push(plannedTodayTomorrow);

        let plannedNextWeek = new DisplayFilter();
        plannedNextWeek.display_id = display.id;
        plannedNextWeek.display = display;
        plannedNextWeek.filter_type = Filters.FilterPlannedInPeriod;
        plannedNextWeek.period_type = Filters.PeriodNextWeek;
        plannedNextWeek.statuses = [];
        plannedNextWeek.name = plannedNextWeek.generateName(this);
        standards.push(plannedNextWeek);

        let plannedNext2Week = new DisplayFilter();
        plannedNext2Week.display_id = display.id;
        plannedNext2Week.display = display;
        plannedNext2Week.filter_type = Filters.FilterPlannedInPeriod;
        plannedNext2Week.period_type = Filters.PeriodNext2Week;
        plannedNext2Week.statuses = [];
        plannedNext2Week.name = plannedNext2Week.generateName(this);
        standards.push(plannedNext2Week);

        let plannedNext3Week = new DisplayFilter();
        plannedNext3Week.display_id = display.id;
        plannedNext3Week.display = display;
        plannedNext3Week.filter_type = Filters.FilterPlannedInPeriod;
        plannedNext3Week.period_type = Filters.PeriodNext3Week;
        plannedNext3Week.statuses = [];
        plannedNext3Week.name = plannedNext3Week.generateName(this);
        standards.push(plannedNext3Week);

        let plannedNext7Days = new DisplayFilter();
        plannedNext7Days.display_id = display.id;
        plannedNext7Days.display = display;
        plannedNext7Days.filter_type = Filters.FilterPlannedInPeriod;
        plannedNext7Days.period_type = Filters.PeriodNext7Days;
        plannedNext7Days.statuses = [];
        plannedNext7Days.name = plannedNext7Days.generateName(this);
        standards.push(plannedNext7Days);

        let plannedNext14Days = new DisplayFilter();
        plannedNext14Days.display_id = display.id;
        plannedNext14Days.display = display;
        plannedNext14Days.filter_type = Filters.FilterPlannedInPeriod;
        plannedNext14Days.period_type = Filters.PeriodNext14Days;
        plannedNext14Days.statuses = [];
        plannedNext14Days.name = plannedNext14Days.generateName(this);
        standards.push(plannedNext14Days);

        let plannedLast7Days = new DisplayFilter();
        plannedLast7Days.display_id = display.id;
        plannedLast7Days.display = display;
        plannedLast7Days.filter_type = Filters.FilterPlannedInPeriod;
        plannedLast7Days.period_type = Filters.PeriodLast7Days;
        plannedLast7Days.statuses = [];
        plannedLast7Days.name = plannedLast7Days.generateName(this);
        standards.push(plannedLast7Days);

        let plannedLast14Days = new DisplayFilter();
        plannedLast14Days.display_id = display.id;
        plannedLast14Days.display = display;
        plannedLast14Days.filter_type = Filters.FilterPlannedInPeriod;
        plannedLast14Days.period_type = Filters.PeriodLast14Days;
        plannedLast14Days.statuses = [];
        plannedLast14Days.name = plannedLast14Days.generateName(this);
        standards.push(plannedLast14Days);


        let deadlineToday = new DisplayFilter();
        deadlineToday.display_id = display.id;
        deadlineToday.display = display;
        deadlineToday.filter_type = Filters.FilterDeadlineInPeriod;
        deadlineToday.period_type = Filters.PeriodToday;
        deadlineToday.statuses = [];
        deadlineToday.name = deadlineToday.generateName(this);
        standards.push(deadlineToday);

        let deadlineThisWeek = new DisplayFilter();
        deadlineThisWeek.display_id = display.id;
        deadlineThisWeek.display = display;
        deadlineThisWeek.filter_type = Filters.FilterDeadlineInPeriod;
        deadlineThisWeek.period_type = Filters.PeriodThisWeek;
        deadlineThisWeek.statuses = [];
        deadlineThisWeek.name = deadlineThisWeek.generateName(this);
        standards.push(deadlineThisWeek);

        let deadlineNextWeek = new DisplayFilter();
        deadlineNextWeek.display_id = display.id;
        deadlineNextWeek.display = display;
        deadlineNextWeek.filter_type = Filters.FilterDeadlineInPeriod;
        deadlineNextWeek.period_type = Filters.PeriodNextWeek;
        deadlineNextWeek.statuses = [];
        deadlineNextWeek.name = deadlineNextWeek.generateName(this);
        standards.push(deadlineNextWeek);

        return standards;
    }

    public generateName(displayFilter: DisplayFilter): string {
        // <start> <period> <status>

        let start;
        let status;
        let period;

        const taskTranslation = this.getTranslation('_tasks').toLocaleLowerCase();
        switch (displayFilter.filter_type) {
            case Filters.FilterPlannedInPeriod:
                start = this.getTranslation('_ui_filter_planned_items', {item: taskTranslation});
                break;
            case Filters.FilterDeadlineInPeriod:
                start = this.getTranslation('_ui_filter_items_with_deadline', {item: taskTranslation});
                break;
            case Filters.FilterArchivedInPeriod:
                start = this.getTranslation('_ui_filter_completed', {item: taskTranslation});
                break;
            case Filters.FilterCreatedInPeriod:
                start = this.getTranslation('_ui_filter_created', {item: taskTranslation});
                break;
        }

        let result = start;

        // Period
        switch (displayFilter.period_type) {
            case Filters.PeriodUserDefined:
                period = 'fra ' + displayFilter.getPeriodStartString() + ' til ' + displayFilter.getPeriodEndString();
                break;
            case Filters.PeriodLast14Days:
                period = this.getTranslation('_ui_date_past_2_weeks').toLocaleLowerCase(); //'sidste 14 dage';
                break;
            case Filters.PeriodLast7Days:
                period = this.getTranslation('_ui_date_past_2_weeks').toLocaleLowerCase(); // 'sidste 7 dage';
                break;
            case Filters.PeriodToday:
                period = this.getTranslation('_ui_date_today').toLocaleLowerCase(); //'i dag';
                break;
            case Filters.PeriodTomorrow:
                period = this.getTranslation('_ui_date_tomorrow').toLocaleLowerCase();
                break;
            case Filters.PeriodTodayTomorrow:
                period = this.getTranslation('_ui_date_today_tomorrow').toLocaleLowerCase();
                break;
            case Filters.PeriodThisWeek:
                period = this.getTranslation('_ui_date_this_week').toLocaleLowerCase();
                break;
            case Filters.PeriodNextWeek:
                period =  this.getTranslation('_ui_date_next_week').toLocaleLowerCase();
                break;
            case Filters.PeriodNext2Week:
                period = this.getTranslation('_ui_date_in_2_weeks').toLocaleLowerCase();//'om 2 uger';
                break;
            case Filters.PeriodNext3Week:
                period = this.getTranslation('_ui_date_in_3_weeks').toLocaleLowerCase();//'om 3 uger';
                break;
            case Filters.PeriodNext7Days:
                period = this.getTranslation('_ui_date_next_7_days').toLocaleLowerCase();//'næste 7 dage';
                break;
            case Filters.PeriodNext14Days:
                period = this.getTranslation('_ui_date_next_14_days').toLocaleLowerCase();//'næste 14 dage';
                break;
            case Filters.PeriodNextMonth:
                period = this.getTranslation('_ui_date_next_month').toLocaleLowerCase()
                break;
        }

        result += ' ' + period;

        // Status
        if (!displayFilter.statuses) displayFilter.statuses = [];
        let statuses = displayFilter.statuses.map(status => status.name().toLocaleLowerCase());
        if (statuses.length < 4 && statuses.length > 0) {
            if (statuses.length > 1) {
                let lastStatus = statuses.pop();
                status = 'i ' + statuses.join(', ') + ' og ' + lastStatus;
            } else if (statuses.length == 1)
                status = 'i ' + statuses[0];
            else
                status = 'uden status';
            result += ' ' + status;
        }

        // Sort

        if (displayFilter.starred) result += ', ' + this.getTranslation('_ui_starred').toLocaleLowerCase();
        if (displayFilter.hand_up) result += ', '+ this.getTranslation('_ui_hand_up').toLocaleLowerCase();

        return result;
    }

    public getEditorConfig(display: Display, result?: (config: DisplayFilterEditorFormConfigInterface) => void): DisplayFilterEditorFormConfigInterface {
        let config: DisplayFilterEditorFormConfigInterface = {};
        if (result) {
            result(config);
        }
        return config;
    }

}
