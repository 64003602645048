import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {
    TaskListOpenColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/TaskListOpenColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns";

export class TaskListOpenTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TaskListOpen;

    public column: TaskListOpenColumn;

}
