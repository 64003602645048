import {BaseComponent} from "@app/shared/_system/base/base.component";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-nav-filter-selector',
    templateUrl: './nav-filter-selector.component.html',
    styleUrls: ['./nav-filter-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavFilterSelectorComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings: Input
    @Input() shellPageData: ShellPageData;
    @Input() titlePrefix: string;

    // Bindings: View
    public title: string;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.render();
    }

    private subscription: Subscription;
    private render() {
        this.subscription?.unsubscribe();
        this.subscription = this.shellPageData?.shellFilterGroup?.onActiveFilterChangeEventSubscribe(filter => {
            this.title = filter.name;
            this.detectChanges();
        });
    }

}
