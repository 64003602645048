import {PhasesProject} from "@app/core/models";

export class Row {

    public phasesProject: PhasesProject;
    public isCurrent: boolean;

    constructor(phasesProject: PhasesProject, isCurrent: boolean) {
        this.phasesProject = phasesProject;
        this.isCurrent = isCurrent;
    }

}
