import {NgModule} from "@angular/core";
import {EmojiPickerComponent} from "@app/shared/_elements/emoji-picker/emoji-picker.component";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {EmojiModule} from "@ctrl/ngx-emoji-mart/ngx-emoji";
import {PickerModule} from "@ctrl/ngx-emoji-mart";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule,
        NgbPopoverModule,
        EmojiModule,
        PickerModule,
        UserWithIconModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        EmojiPickerComponent,
    ],
    exports: [
        EmojiPickerComponent

    ],
    providers: [

    ],
})
export class EmojiPickerModule {}
