/**
 * Created by ModelParser
 * Date: 12-11-2020.
 * Time: 09:13.
 */
import {DynamicMenuItemDefinition} from './definitions/DynamicMenuItemDefinition';

export class DynamicMenuItem extends DynamicMenuItemDefinition {

    constructor(json?: any) {
        super(json);
    }

}
