import {NgModule} from "@angular/core";
import {
    DashboardProjectsComponent
} from "@app/pages/displays/dashboard/dashboard-projects/dashboard-projects.component";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {NgbDropdownModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ItemCounterModule} from "@app/shared/_elements/item-counter/ItemCounter.module";
import {TaskListModule} from "@app/shared/_ui/lists/task-list/TaskList.module";
import {MilestoneListModule} from "@app/shared/_ui/lists/milestone-list/MilestoneList.module";
import {AppointmentListModule} from "@app/shared/_ui/lists/appointment-list/AppointmentList.module";
import {TodoListModule} from "@app/shared/_ui/lists/todo-list/TodoList.module";
import {ScrollDetectorDirectiveModule} from "@app/directives/ScrollDetectorDirective.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";
import {NgxDatatableExtensionModule} from "@app/directives/NgxDatatableExtension.module";

@NgModule({
    imports: [
        CommonModule,
        NgxDatatableModule,
        NgbTooltipModule,
        TranslateModule,
        NgbDropdownModule,
        ItemCounterModule,
        CardsModule,
        TaskListModule,
        MilestoneListModule,
        AppointmentListModule,
        TodoListModule,
        ScrollDetectorDirectiveModule,
        TypedTemplateDirective,
        NgxDatatableExtensionModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DashboardProjectsComponent,
    ],
    exports: [
        DashboardProjectsComponent

    ],
    providers: [

    ],
})
export class DashboardProjectsModule {}
