import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {BaseDisplayComponent} from '../../_system/base-display/base-display.component';
import {Subscription} from 'rxjs';
import {OldApi} from '@app/http/Api';
import {HandUp, Project} from '@app/core/models';
import {Roster, Task} from '@app/core/models/Task';
import {EventService} from '@app/services/event.service';
import {User} from '@app/core/models/User';
import {AppInjector} from '@app/services/app-injector.service';
import {UsersService} from '@app/services/users.service';
import {Milestone} from '@app/core/models/Milestone';
import {PlacementArray} from '@app/directives/positioning';

@Component({
    selector: 'app-hand-up-selector',
    templateUrl: './hand-up-selector.component.html',
    styleUrls: ['./hand-up-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HandUpSelectorComponent extends BaseDisplayComponent implements OnInit {

    @Input() model: Task | Project | Milestone; // TO-DO Refactor til EditHandUp (Interface)
    @Input() showUsers = true;
    @Input() placement: PlacementArray = ['bottom', 'right', 'left', 'auto'];
    @Input() tooltipPlacement: PlacementArray = ['left', 'bottom', 'right', 'auto'];
    @Output() onToggle: EventEmitter<any> = new EventEmitter<any>();

    public handsUp: HandUp[] = [];
    public active = false;
    // Hvilke brugere skal kunne vælges?
    public user: User;
    private activated = false;

    private apiLoader: Subscription;

    constructor(private eventService: EventService,
                private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.subscribe(this.eventService.subscribe(this.model, (event) => {
            // console.log('hand-up-selector.component : ', event.action)
            switch (event.action) {
                case EventService.Created:
                case EventService.Updated:
                case EventService.Deleted:
                    if (event.action == EventService.Deleted && this.handsUp) {
                        this.handsUp = this.handsUp.filter(s => s.id != event.item.id);
                    }
                    // this.handsUp = [];
                    this.detectChanges();
                    this.updateActive();
                    break;
            }
        }));
        this.user = AppInjector.getInjector().get(UsersService).user;
    }

    public preventPropagation(mouseEvent: MouseEvent) {
        this.activated = !this.activated;
        if (this.activated && this.handsUp.length == 0) {
            this.loadData();
        } else {
            this.clickEvent();
        }
        mouseEvent.stopPropagation();
    }

    public clickEvent() {
        if (!this.showUsers && this.user) {
            const item = this.handsUp.filter(s => s.user_id = this.user.id);
            this.toggleActive(!(item.length > 0), null);
        }
    }

    toggleActive(active: boolean, handUp: HandUp) {
        this.onToggle.emit({active, handUp});
        this.cd.markForCheck();
        if (!handUp) {
            handUp = new HandUp({user_id: this.user.id});
        }

        let api: OldApi;
        switch (this.model.constructor) {
            case Project:
                api = OldApi.projects(this.model.id).hand_ups();
                api.setParam('user_id', handUp.user_id);
                // Opret
                if (!active) {
                    api.delete().subscribe((ha: HandUp) => {
                        (this.model as Project).num_hand_ups--;
                        this.eventService.emitProject(this.model as Project, EventService.Updated, ['num_hand_ups']);
                        this.cd.markForCheck();
                    });
                } else {
                    handUp.project_id = this.model.id;
                    api.put().subscribe((ha: HandUp) => {
                        (this.model as Project).num_hand_ups++;

                        this.eventService.emitProject(this.model as Project, EventService.Updated, ['num_hand_ups']);

                        this.cd.markForCheck();
                    });
                }
                break;
            case Task:
            case Roster:
                api = OldApi.tasks(this.model.id).hand_ups();
                api.setParam('user_id', handUp.user_id);
                if (!active) {
                    api.delete().subscribe((ha: HandUp) => {
                        if (!this.model.num_hand_ups) {
                            this.model.num_hand_ups = 0;
                        }
                        else {
                            this.model.num_hand_ups--;
                        }

                        this.eventService.emitTask(this.model as Task, EventService.Updated, ['num_hand_ups']);
                        this.cd.markForCheck();
                    });
                } else {
                    handUp.task_id = this.model.id;
                    api.put().subscribe((ha: HandUp) => {
                        const task = (this.model as Task);
                        if (!task.num_hand_ups) { task.num_hand_ups = 0; }

                        task.num_hand_ups++;

                        this.eventService.emitTask(this.model as Task, EventService.Updated, ['num_hand_ups']);
                        this.cd.markForCheck();
                    });
                }
                break;
            case Milestone:
                api = OldApi.milestones(this.model.id).hand_ups();
                api.setParam('user_id', handUp.user_id);
                if (!active) {
                    api.delete().subscribe((ha: HandUp) => {
                        (this.model as Milestone).num_hand_ups--;

                        this.eventService.emitMilestone(this.model as Milestone, EventService.Updated, ['num_hand_ups']);
                        this.cd.markForCheck();
                    });
                } else {
                    handUp.task_id = this.model.id;
                    api.put().subscribe((ha: HandUp) => {
                        (this.model as Milestone).num_hand_ups++;
                        this.eventService.emitMilestone(this.model as Milestone, EventService.Updated, ['num_hand_ups']);
                        this.cd.markForCheck();
                    });
                }
                break;
        }

    }

    private loadData() {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;

        if (this.apiLoader) {
            this.apiLoader.unsubscribe();
        }

        let api: OldApi;
        switch (this.model.constructor) {
            case Project:
                api = OldApi.projects(this.model.id).hand_ups();
                break;
            case Task:
            case Roster:
                api = OldApi.tasks(this.model.id).hand_ups();
                break;
            case Milestone:
                api = OldApi.milestones(this.model.id).hand_ups();
                break;
        }

        if (api) {
            this.apiLoader = api.orderBy('created', 'desc')
                .get(HandUp.name)
                .subscribe((handsUp: HandUp[]) => {
                    this.handsUp = this.handsUp.concat(handsUp.filter((ha: HandUp) => {
                        return ha.user_id != 0;
                    }));
                    this.isLoading = false;
                    this.updateActive();
                    this.clickEvent();
                });
        } else {
            this.isLoading = false;
        }
    }

    private updateActive() {
        if (this.handsUp) {
            const found: HandUp = this.handsUp.find((hu: HandUp) => {
                return hu.user.id == this.user.id;
            });
            this.active = !!found;
        } else {
            this.active = false;
        }
        this.cd.detectChanges();
    }

}
