import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {NotFoundModule} from "@app/shared/_ui/not-found/NotFound.module";
import {ProjectEditorLoaderComponent} from "@app/editor/project-editor-loader/project-editor-loader.component";
import {ProjectEditorV3Module} from "@app/editor/project-editor-loader/project-editor-v3/project-editor-v3.module";
import {
    MilestoneEditorV3Module
} from "@app/editor/milestone-editor-loader/milestone-editor-v3/milestone-editor-v3.module";

@NgModule({
    imports: [
        CommonModule,
        LoadingIndicatorModule,
        NotFoundModule,
        ProjectEditorV3Module,
        MilestoneEditorV3Module,
    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectEditorLoaderComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class ProjectEditorLoaderModule {}
