import {UntypedFormBuilder, Validators} from '@angular/forms';
import {Appointment} from '@app/core/models/Appointment';
import {Milestone, Project} from '@app/core/models';

interface AppointmentFormInterface {
    id: number;
    description: string;
    projects?: Project[];
    milestones?: Milestone[];
}

export class AppointmentForm extends Appointment {

    public static create(form: AppointmentFormInterface): AppointmentForm {
        let item = new AppointmentForm(form);

        if(item.projects) {
            item.projects = item.projects.map(project => new Project({id: project.id, project_type_id: project.project_type_id}));
        }

        return item;
    }

    public toFormObject(): AppointmentFormInterface {
        let item: AppointmentFormInterface = {
            id: this.id ? this.id: 0,
            description: this.description ? this.description : '',
            projects: this.projects ? this.projects : null
        };
        return item;
    }

    public toFormGroup(formBuilder: UntypedFormBuilder) {
        let item = this.toFormObject();
        let form: any = item;
        form.description = [item.description, Validators.required];
        form.projects = [form.projects];
        let formGroup = formBuilder.group(item);
        //console.warn('toFormGroup', formGroup);
        return formGroup;
    }

}
