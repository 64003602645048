import {NgModule} from "@angular/core";
import {DisplayCasesComponent} from "@app/pages/displays/display-cases/display-cases.component";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {ColumnSorterModule} from "@app/shared/_ui/column-sorter/ColumnSorter.module";
import {TextAreaModule} from "@app/shared/_forms/text-area/TextArea.module";
import {NumericSpinnerModule} from "@app/shared/_elements/numeric-spinner/NumericSpinner.module";
import {ValueEvaluatorModule} from "@app/shared/_elements/value-evaluator/ValueEvaluator.module";
import {TaskListModule} from "@app/shared/_ui/lists/task-list/TaskList.module";
import {AppointmentListModule} from "@app/shared/_ui/lists/appointment-list/AppointmentList.module";
import {CommonModule} from "@angular/common";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {CardsModule} from "@app/modules/Cards.module";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";
import {
    NavFilterSelectorModule
} from "@app/pages/displays/display-project-details/components/nav-filter-selector/NavFilterSelector.module";
import {NgxDatatableExtensionModule} from "@app/directives/NgxDatatableExtension.module";

@NgModule({
    imports: [
        NgxDatatableModule,
        TranslateModule,
        NgbTooltipModule,
        LocalizedDatePipeModule,
        ColumnSorterModule,
        CardsModule,
        TextAreaModule,
        NumericSpinnerModule,
        ValueEvaluatorModule,
        TaskListModule,
        AppointmentListModule,
        CommonModule,
        LoadingIndicatorModule,
        TypedTemplateDirective,
        NavFilterSelectorModule,
        NgxDatatableExtensionModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DisplayCasesComponent,
    ],
    exports: [
        DisplayCasesComponent

    ],
    providers: [

    ],
})
export class DisplayCasesModule {}
