import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {GenericTableColumn} from "@app/pages/displays/display-team/TableColumns/GenericTableColumn";
import {MilestoneListConfiguration} from "@app/shared/_ui/lists/milestone-list/MilestoneListConfiguration";
import {MilestoneListCell} from "@app/pages/displays/display-team/Cells/MilestoneListCell";
import {TableColumns} from "@app/pages/displays/display-team/Helpers/TableColumns";

export class MilestoneListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.MilestoneList;

    public getListConfiguration(row: BaseRow): MilestoneListConfiguration {
        return row.getCell<MilestoneListCell>(this.column).milestoneListConfiguration;
    }

}
