import {NgModule} from "@angular/core";
import {ScrollDirective} from "@app/directives/scroll.directive";

@NgModule({
    imports: [

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ScrollDirective,
    ],
    exports: [
        ScrollDirective

    ],
    providers: [

    ],
})
export class ScrollDirectiveModule {}
