/**
 * Created by ModelParser
 * Date: 12-05-2021.
 * Time: 14:46.
 */
import {MilestoneReactionTypeDefinition} from './definitions/MilestoneReactionTypeDefinition';
import {ReactionsTypeInterface} from "@app/shared/_ui/reactions/ReactionsTypeInterface";

export class MilestoneReactionType extends MilestoneReactionTypeDefinition implements ReactionsTypeInterface {

    constructor(json?: any) {
        super(json);
    }

}
