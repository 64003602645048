<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title" [innerHTML]="title"></h4>

        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <div class="modal-body" [innerHTML]="message" *ngIf="message"></div>
    <div class="container">

        <div class="w-100 d-flex justify-content-center  align-items-center" style="height: 200px;" *ngIf="isLoading">
            <app-loading-indicator [showLogo]="false" class="text-large "></app-loading-indicator>
        </div>
        <ng-container *ngIf="!isLoading">
            <ul class="list-unstyled list-group mb-3 mt-3">

                <li (click)="toggleAll()" class="cursor-pointer">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <i class="fa fa-check-circle text-success" *ngIf="allActive"></i>
                        <i class="fal fa-circle text-black-50" *ngIf="!allActive"></i>
                        <span class="ms-1">
                            <span [innerHTML]="(allActive ? '_ui_deselect_all' : '_ui_select_all') | translate"></span>
                        </span>
                    </div>
                </li>

                <li (click)="moveProjectPhases = !moveProjectPhases" class="cursor-pointer"
                    *ngIf="phasesProjects.length > 0">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <i class="fa fa-check-circle text-success" *ngIf="moveProjectPhases"></i>
                        <i class="fal fa-circle text-black-50" *ngIf="!moveProjectPhases"></i>
                        <span class="ms-1">
                            <span [innerHTML]="('_ui_move_project_phases' | translate)"></span>
                            <strong
                                class="text-success">{{dateChangeDifference.difference}}</strong> {{('_ui_days' | translate)}}
                        </span>
                    </div>
                </li>


                <li (click)="moveMilestones = !moveMilestones" class="cursor-pointer">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <i class="fa fa-check-circle text-success" *ngIf="moveMilestones"></i>
                        <i class="fal fa-circle text-black-50" *ngIf="!moveMilestones"></i>
                        <span class="ms-1">
                            <span [innerHTML]="('_ui_move_all_following_milestones_with' | translate)"></span>
                            <strong
                                class="text-success">{{dateChangeDifference.difference}}</strong> {{('_ui_days' | translate)}}
                        </span>
                    </div>
                </li>
                <li (click)="moveMilestoneTasks = !moveMilestoneTasks" class="cursor-pointer">
                    <div class="d-flex flex-row justify-content-start  align-items-center">
                        <i class="fa fa-check-circle text-success" *ngIf="moveMilestoneTasks"></i>
                        <i class="fal fa-circle text-black-50" *ngIf="!moveMilestoneTasks"></i>
                        <span class="ms-1">
                            <span [innerHTML]="('_ui_move_all_milestone_tasks' | translate)"></span>
                            <strong
                                class="text-success">{{dateChangeDifference.difference}}</strong> {{('_ui_days' | translate)}}
                        </span>
                    </div>
                </li>

                <li (click)="moveMilestoneTasksUsers = !moveMilestoneTasksUsers"
                    class="cursor-pointer ps-2 d-flex align-items-center"
                    [class.disabled]="!moveMilestoneTasks">
                    <i class="fal fa-arrow-turn-down-right me-1"></i>
                    <div class="d-flex flex-row justify-content-start  align-items-center">
                        <i class="fa fa-check-circle text-success" *ngIf="moveMilestoneTasksUsers"></i>
                        <i class="fal fa-circle text-black-50" *ngIf="!moveMilestoneTasksUsers"></i>
                        <span class="ms-1">
                            <span [innerHTML]="('_ui_move_all_milestone_task_participants' | translate)"></span>
                            <strong
                                class="text-success">{{dateChangeDifference.difference}}</strong> {{('_ui_days' | translate)}}
                        </span>
                    </div>
                </li>
            </ul>


            <div class="scroll-container pt-1 pb-1 border-top mb-2 show-scroll">
                <ul class="list-unstyled list-group -text-small">

                    <ng-container *ngIf="phasesProjects && phasesProjects.length > 0">
                        <li *ngFor="let pp of phasesProjects"
                            class="pb-1 pt-1">

                            <div class="d-flex flex-row justify-content-start flex-grow-1">
                                <div class="d-flex align-items-center">
                                    <!--                                    <i class="fal fa-moon me-1"></i> -->
                                    <app-color-item [color]="pp.phase?.color" class="me-1 ms-1"></app-color-item>
                                    <span>{{pp.phase?.name}}</span>
                                </div>
                                <span class="ms-auto right-wrap pe-1">
                                    <span
                                        class="d-inline-block cell-item text-end ps-1">{{pp.getStartedDate() | localizedDate: 'microDate'}}</span>
                                    <i class="fal fa-arrow-right ms-1 me-1"></i>
                                    <span class="d-inline-block cell-item"
                                          [class.text-danger]="!moveProjectPhases "
                                          [class.text-success]="moveProjectPhases "
                                    >{{addDifference(pp.getStartedDate()) | localizedDate: 'microDate'}}</span>
                                </span>

                            </div>
                        </li>
                    </ng-container>


                    <li *ngFor="let milestone of milestones" class="-border-top pb-1 mb-1 pt-1"
                        [class.disabled]="milestone.archived_id != 0 "
                    >
                        <ng-container
                            >
                            <div class="d-flex flex-row justify-content-start">


                                <div class="d-flex align-items-center">
                                    <app-color-label [interactive]="false"
                                                     [status]="milestone.status"
                                                     [itemWithTypeProperties]="milestone"
                                                     class="me-1"
                                    ></app-color-label>
                                    <span>{{milestone.title}} <span *ngIf="milestone.archived_id != 0 ">({{'_ui_completed' | translate}})</span></span>

                                </div>

                                <span class="ms-auto right-wrap pe-1">
                                    <span class="d-inline-block cell-item text-end ps-1"
                                          *ngIf="milestone.deadline"
                                          [class.text-black-50]="item.id == milestone.id"
                                          [class.text-danger]="item.id != milestone.id"
                                    >{{milestone.deadline?.date | localizedDate:'microDate'}}</span>
                                    <ng-container
                                        *ngIf="milestone.archived_id == 0">
                                        <i class="fal fa-arrow-right ms-1 me-1"></i>
                                        <span class="d-inline-block cell-item "
                                              [class.text-success]="milestone.archived_id == 0 && (moveMilestones)"
                                              [class.text-danger]="milestone.archived_id == 0 && (!moveMilestones)"
                                        >{{addDifference(milestone.deadline?.getDate()) | localizedDate: 'microDate'}}</span>
                                    </ng-container>
                                </span>
                            </div>
                            <ul class="list-unstyled list-group ps-2 -text-small" *ngIf="milestone.tasks">
                                <li *ngFor="let task of milestone.tasks" class="d-flex align-items-center ps-2">
                                    <i class="fal fa-arrow-turn-down-right me-1"></i>
                                    <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1 ">
                                        <div class="d-flex align-items-center">
                                            <app-color-label [interactive]="false"
                                                             [status]="task.status"
                                                             [itemWithTypeProperties]="task"
                                                             class="me-1"
                                            ></app-color-label>
                                            <span>{{task.title}}</span>
                                        </div>

                                        <span class="ms-auto right-wrap pe-1" *ngIf="task.findTasksDeadlineByType(1)?.deadline?.getDate(); else noDeadline">
                                            <span
                                                class="d-inline-block cell-item text-end">{{task.findTasksDeadlineByType(1).deadline?.getDate() | localizedDate: 'microDate'}}</span>

                                                    <ng-container
                                                        *ngIf="milestone.archived_id == 0 && task.archived_id == 0">
                                                    <i class="fal fa-arrow-right  ms-1 me-1"></i>
                                            <span class="d-inline-block cell-item"
                                                  [class.text-danger]="milestone.archived_id == 0 && task.archived_id != 0 && !moveMilestoneTasks "
                                                  [class.text-success]="milestone.archived_id == 0 && task.archived_id != 0 && moveMilestoneTasks"

                                            >{{addDifference(task.findTasksDeadlineByType(1).deadline?.getDate()) | localizedDate: 'microDate'}}</span>
                                                        </ng-container>

                                        </span>

                                        <ng-template #noDeadline>
                                            <span class="ms-auto text-danger pe-4 " >{{'_ui_deadline_missing' | translate}}</span>
                                        </ng-template>

                                    </div>
                                </li>
                            </ul>
                        </ng-container>
                    </li>
                </ul>
            </div>

        </ng-container>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary ellipsis" (click)="decline()" [disabled]="isLoading">{{ btnCancelText }}
        </button>
        <button type="button" class="btn btn-success ellipsis" ngbAutofocus (click)="accept()"
                [disabled]="isLoading">{{ btnOkText }}</button>
    </div>
</div>
