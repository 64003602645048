import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters as ProjectsFilters} from "@app/pages/displays/display-projects/Filters";
import {DefaultDirections} from "@app/core/ColumnControl/DefaultDirections";
import {CSVColumn} from "@app/export/csv/CSVColumn";
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {NextMilestoneCardCell} from "@app/pages/displays/display-projects/Cells/NextMilestoneCardCell";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";

export class NextMilestoneCardColumn extends GenericColumn<NextMilestoneCardCell> {

    public implementsCSVExport = true;

    public createTableColumns(): GenericTableColumn[] {
        const item = new GenericTableColumn(this);
        this.setTableColumnDefaults(item);

        item.sortItems = [
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortNextMilestoneDeadline, DefaultDirections.Ascending),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortNextMilestoneStatus, DefaultDirections.Ascending),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortNextMilestoneTitle, DefaultDirections.Ascending),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortNextMilestoneHandUp, DefaultDirections.Descending),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortNextMilestoneResponsible, DefaultDirections.Ascending),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortNextMilestoneStarred, DefaultDirections.Descending),
        ];
        return [item];
    }

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new NextMilestoneCardCell(row, this);
    }

    public toCSVColumn(result: (column: CSVColumn) => void): void {
        result(CSVColumn.CreatePrimary(this, [
            CSVColumn.CreateSecondary('name', this.column.name),
            CSVColumn.CreateSecondary(
                'deadline',
                `${(AppInjector.getInjector().get(TranslateService).instant('Frist'))} ${this.column.name.toLocaleLowerCase()}`
            )
        ]));
    }

}
