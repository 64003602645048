<div class="d-flex align-items-end -text-dark-gray align-items-end">

    <div class="d-flex align-items-end">

        <div class="d-flex flex-column align-items-start me-2">
            <span  class="me-1 label">{{'_admin_status_rule_from_status' | translate}}</span>
            <app-status-selector [status_id]="model.from_status_id"
                                 [ngbTooltip]="('_ui_status_rule_from_tooltip' | translate)"
                                 [readonly]="readonly"
                                 (valueChanged)="changeValue($event, 'from_status_id')"></app-status-selector>
        </div>


        <i class="fa fa-arrow-right me-2 mb-1" aria-hidden="true"></i>

        <div class="d-flex flex-column align-items-start">
            <span  class="me-1 label">{{'_admin_status_rule_to_status' | translate}}</span>
            <app-status-selector [status_id]="model.to_status_id"
                                 [ngbTooltip]="('_ui_status_rule_to_tooltip' | translate)"
                                 [readonly]="readonly"
                                 (valueChanged)="changeValue($event, 'to_status_id')"></app-status-selector>
        </div>

    </div>

    <div class="ms-auto d-flex flex-column">
        <label>
            <span  class="d-none">{{'_ui_status_rule_plan_label' | translate}}</span>
        </label>
        <div class="d-flex ms-auto">
        <span class="me-1 d-flex flex-column" *ngIf="!readonly">
            <input type="number" [readonly]="readonly" [(ngModel)]="days" (ngModelChange)="daysChange($event)"
                   required
                   [step]="1"
                   class="form-control"
                   [ngbTooltip]="days + ' ' + (days > 1 ? ('_ui_days' | translate) : ('_ui_day' | translate)) + ' - '+ ('_ui_status_rule_plan_description' | translate | lowercase)"/>
        </span>

            <div class="d-flex align-items-center ms-auto" *ngIf="readonly">
            <span class="me-1">{{days}} <span
                *ngIf="days != 0 || true">{{days < 0 ? ' ' + ('_ui_days_before'| translate) + ' ' + (model.defaultDeadlineName ? (model.defaultDeadlineName | lowercase) : ('_static_deadline_deadline' | translate | lowercase)) : ' ' + ('_ui_days_after' | translate) + ' ' + (model.defaultDeadlineName ? (model.defaultDeadlineName | lowercase) : ('_static_deadline_deadline' | translate | lowercase)) }}</span></span>
            </div>

            <button (click)="removeItem()" class="btn btn-outline-danger" *ngIf="!readonly && showRemove">
                <i class="fal fa-trash-alt" aria-hidden="true"></i>
            </button>
        </div>
    </div>

</div>
