<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title" [innerHTML]="title"></h4>

        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <div class="modal-body ps-4" [innerHTML]="message" *ngIf="message"></div>

    <div class="p-2">

        <ul class="list-unstyled list-group mb-3 mt-0 ps-3 gap-1">
            <li class="pb-2 border-bottom mb-1 d-flex flex-wrap flex-column gap-1">
                <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <app-color-label [interactive]="false"
                                         [status]="milestone.status"
                                         [itemWithTypeProperties]="milestone"
                                         class="me-1"
                        ></app-color-label>
                        <span class="ellipsis">{{milestone.title}}</span>
                    </div>

                    <i class="fa-duotone fa-plus ms-1 me-1 text-success" *ngIf="action == Constants.Actions.Append"></i>
                    <i class="fa-duotone fa-trash-can ms-1 me-1  text-danger"
                       *ngIf="action == Constants.Actions.Delete"></i>

                    <div class="d-flex align-items-center">
                        <app-color-label [interactive]="false" [status]="targetProject.status"
                                         [itemWithTypeProperties]="targetProject" class="me-1"></app-color-label>
                        <span class="ellipsis">{{targetProject.title}}</span>
                    </div>
                </div>

                <ul class="list-unstyled border-top">
                    <li *ngFor="let p of availableProjects">
                        <div class="d-flex align-items-center">
                            <app-color-label [interactive]="false" [status]="p.status" [itemWithTypeProperties]="p"
                                             class="me-1"></app-color-label>
                            <span class="ellipsis">{{p.title}}</span>
                        </div>
                    </li>
                    <li *ngIf="!availableProjects || availableProjects.length == 0">
                        <div class="d-flex align-items-center ellipsis">
                            <i class="fa-duotone fa-triangle-exclamation me-1 text-warning"></i>
                            <span class="ellipsis">{{'_ui_milestone_missing_project' | translate}}</span>
                        </div>
                    </li>
                </ul>

                <p class="border-top  m-0 pt-3 pb-2">{{'_ui_following_tasks_move_description' | translate}}</p>

            </li>
            <li (click)="toggleAll()" class="cursor-pointer"
                *ngIf="tasks?.length > 0">
                <div class="d-flex flex-row justify-content-start align-items-center">
                    <i class="fa fa-circle-check text-success"
                       *ngIf="moveAllTasks"></i>
                    <i class="fal fa-circle text-black-50"
                       *ngIf="!moveAllTasks"></i>
                    <span class="ms-1">
                        <span [innerHTML]="('_ui_deselect_all' | translate)"
                              *ngIf="moveAllTasks"></span>
                        <span [innerHTML]="('_ui_select_all' | translate)"
                              *ngIf="!moveAllTasks"></span>
                    </span>
                </div>
            </li>


            <li *ngFor="let row of taskRows" class="d-flex align-items-center ps-2">
                <i class="fal fa-arrow-turn-down-right me-2"></i>

                <div class="d-flex flex-row justify-content-start align-items-center me-2 cursor-pointer"
                     (click)="toggleTask(row)"
                     [class.disabled]="!row.hasNextProject"
                >
                    <i class="fa fa-circle-check text-success" *ngIf="row.isSelectedForMove"></i>
                    <i class="fal fa-circle text-black-50"
                       *ngIf="!row.isSelectedForMove"></i>
                </div>

                <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1 ">
                    <div class="d-flex align-items-center" (click)="toggleTask(row)">
                        <app-color-label [interactive]="false"
                                         [status]="row.task.status"
                                         [itemWithTypeProperties]="row.task"
                                         class="me-1"
                        ></app-color-label>
                        <span class="ellipsis">{{row.task.title}}</span>
                    </div>

                    <div clasS="ms-auto right-wrap pe-1 no-wrap d-flex flex-row align-items-center "
                         [class.warning]="!row.isSelectedForMove"
                         style="width: 420px;">
                        <div
                            *ngIf="row.prevProject"
                            class="d-flex align-items-center flex-shrink-1 ellipsis" style="width: 150px;"
                        >
                            <app-color-label [interactive]="false"
                                             [status]="row.prevProject.status"
                                             [itemWithTypeProperties]="row.prevProject"
                                             class="me-1"
                            ></app-color-label>
                            <span class="ellipsis">{{row.prevProject.title}}</span>
                        </div>

                        <i class="fal fa-arrow-right ms-1 me-1"></i>

                        <div class="d-flex align-items-center flex-shrink-1 ellipsis" style="width: 150px;">

                            <div ngbDropdown class="d-flex flex-grow-1 ellipsis" [container]="'body'">
                                <button type="button" class="btn btn-outline-primary d-flex align-items-center ellipsis"
                                        id="dropdownBasic1"
                                        ngbDropdownToggle>
                                    <ng-container *ngIf="row.hasNextProject">
                                        <div class="d-flex align-items-center ellipsis">
                                            <app-color-label [interactive]="false"
                                                             [status]="row.nextProject.status"
                                                             [itemWithTypeProperties]="row.nextProject"
                                                             class="me-1"
                                            ></app-color-label>
                                            <span class="ellipsis">{{row.nextProject.title}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!row.hasNextProject">
                                        <div class="d-flex align-items-center ellipsis text-small"
                                             [ngbTooltip]="('_ui_missing_task_project_warning' | translate)">
                                            <i class="fa-duotone fa-triangle-exclamation me-1 text-warning"></i>
                                            <span class="ellipsis">{{'_ui_is_required' | translate}}</span>
                                        </div>
                                    </ng-container>
                                </button>

                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                     *ngIf="availableProjects && availableProjects.length > 0">
                                    <ng-container *ngFor="let p of availableProjects">
                                        <button ngbDropdownItem (click)="selectProject(row, p)"
                                                class="d-flex align-items-center">
                                            <app-color-label [interactive]="false"
                                                             [status]="p.status"
                                                             [itemWithTypeProperties]="p"
                                                             class="me-1"
                                            ></app-color-label>
                                            <span class="ellipsis">{{p.title}}</span>
                                        </button>
                                    </ng-container>
                                </div>
                            </div>


                        </div>
                    </div>


                </div>
            </li>

        </ul>

        <div class="w-100 d-flex align-items-center justify-content-center">
            <app-loading-indicator [showLogo]="false" *ngIf="!tasks"></app-loading-indicator>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" role="button" class="btn btn-secondary ellipsis" (click)="decline()"
                *ngIf="btnCancelText">{{ btnCancelText }}</button>
        <button type="button" role="button" class="btn ellipsis {{primaryClass}}" ngbAutofocus
                [disabled]="moveDisabled"
                (click)="accept()">{{ btnOkText }}</button>
    </div>
</div>
