import {NgModule} from "@angular/core";
import {DateRangePickerComponent} from "@app/shared/_elements/date-range-picker/date-range-picker.component";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";

@NgModule({
    imports: [
        CommonModule,
        LocalizedDatePipeModule,
        NgbDatepickerModule,
        TranslateModule,
        TypedTemplateDirective

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DateRangePickerComponent,
    ],
    exports: [
        DateRangePickerComponent

    ],
    providers: [

    ],
})
export class DateRangePickerModule {}
