<div
    class="page-container "
    [class.sidebarVisible]="sidebarOpen"
>

    <app-updates-notification></app-updates-notification>
    <div class="header-wrap d-flex align-items-center" [class.d-none]="!getUser() || getUser() && !getUser().default_displays_department_id">
        <app-header
            (toggleSidenav)="navigationSidenav.toggle()"
        ></app-header>
    </div>

    <mat-sidenav-container autosize class="navigation-sidenav-container d-flex flex-row"
                           style="height: calc(100% - 66px);"
    >
        <mat-sidenav #navigationSidenav
                     [disableClose]="true"
                     mode="push"
                     align="begin"
                     [fixedInViewport]="true"
                     fixedTopGap="0"
                     role="navigation"
                     (opened)="sidebarOpen = true; menuHidden = false;"
                     (closed)="sidebarOpen = false; menuHidden = true;"
                     (toggle)="onToggle()"
        >
            <mat-nav-list class="d-flex justify-content-start flex-column p-0">
                <div class="scroll scroll-y flex-shrink-1 border-bottom text-small">
                    <app-sidebar #mainNav class=""
                                 (navToggle)="onToggle()"
                                 (navClose)="onClose()"
                                 [menuHidden]="menuHidden"></app-sidebar>
                </div>
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content role="main"
                             class="content-container -d-flex flex-column flex-shrink-1"
                             app-scroll-directive
        >
            <div class="router-wrap h-100" [style.minHeight.px]="0">
                <section class="main-wrapper -flex-fill h-100">
                    <router-outlet #outlet="outlet" class="position-relative"
                                   (activate)="onRouteActivateEvent()"
                    ></router-outlet>
                </section>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>

    <ng-template dynamicChildLoader=""></ng-template>


</div>
