import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {TaskPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/TaskPresetType";
import {Deadline} from "@app/core/models";

export class TaskUserPresetGenerator implements CreatePresetGenerator {

    private readonly typeId?: number;
    private readonly typeIdHandler?: (options?: any) => number;

    private readonly userId?: number;
    private readonly userIdHandler?: (options?: any) => number;

    private readonly deadline?: Deadline;
    private readonly deadlineHandler?: (options?: any) => Deadline;

    constructor(
        typeIdOrHandler: number | ((options?: any) => number),
        userIdOrHandler: number | ((options?: any) => number),
        deadlineOrHandler?: Deadline | ((options?: any) => Deadline),
    ) {
        if (typeof typeIdOrHandler == "number") {
            this.typeId = typeIdOrHandler;
        } else {
            this.typeIdHandler = typeIdOrHandler;
        }
        if (typeof userIdOrHandler == "number") {
            this.userId = userIdOrHandler;
        } else {
            this.userIdHandler = userIdOrHandler;
        }
        if (deadlineOrHandler instanceof Deadline) {
            this.deadline = deadlineOrHandler;
        } else {
            this.deadlineHandler = deadlineOrHandler;
        }
    }

    public generate(typeId?: number, options?: any): CreatePreset {
        const deadline = this.deadline ?? (this.deadlineHandler ? this.deadlineHandler(options) : undefined);
        return {
            type: TaskPresetTypes.User,
            payload: {
                typeId: this.typeId ?? this.typeIdHandler(options),
                userId: this.userId ?? this.userIdHandler(options),
                deadline: {
                    date: deadline?.getServerDate() ?? null,
                    isSoft: deadline?.is_soft ?? false
                },
            }
        };
    }

}
