import {NgModule} from "@angular/core";
import {DisplayCategoryComponent} from "@app/pages/displays/display-category/display-category.component";
import {ResizableModule} from "angular-resizable-element";
import {ColumnSorterModule} from "@app/shared/_ui/column-sorter/ColumnSorter.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {MultiListModule} from "@app/shared/_ui/lists/multi-list/MultiList.module";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {OnScreenFilterSelectorModule} from "@app/shared/_ui/on-screen-filter-selector/OnScreenFilterSelector.module";
import {CommonModule} from "@angular/common";
import {ColorItemModule} from '@app/shared/_ui/color-item/ColorItem.module';

@NgModule({
    imports: [
        CommonModule,
        ResizableModule,
        ColumnSorterModule,
        NgbTooltipModule,
        TranslateModule,
        MultiListModule,
        LoadingIndicatorModule,
        OnScreenFilterSelectorModule,
        ColorItemModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DisplayCategoryComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class DisplayCategoryModule {}
