import {NgModule} from "@angular/core";
import {
    MilestonePlanSelectItemComponent
} from "@app/shared/_elements/milestone-plan-select-item/milestone-plan-select-item.component";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {
    MilestonePlanTimelineModule
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-timeline/MilestonePlanTimeline.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule,
        NgbPopoverModule,
        MilestonePlanTimelineModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MilestonePlanSelectItemComponent,
    ],
    exports: [
        MilestonePlanSelectItemComponent

    ],
    providers: [

    ],
})
export class MilestonePlanSelectItemModule {}
