import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {Milestone} from '@app/core/models/Milestone';
import {environment} from '@env/environment';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BaseService} from '@app/services/base.service';
import {ApiCall} from '@app/http/APICall';
import {ApiFilter, OrderDirection} from '@app/http/APIFilter';
import {OldApi} from '@app/http/Api';
import {EventService} from '@app/services/event.service';
import {MilestonePlan} from '@app/core/models/MilestonePlan';
import {MilestoneField} from "@app/core/models/MilestoneField";
import {FieldTypes, System} from "@app/constants";
import {Api} from '@app/core/http/Api/Api';
import {CategoryType, CategoryTypesMilestone, MilestoneReactionType, Project} from "@app/core/models";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
    MilestoneArchiveDialogComponent
} from "@app/shared/_modals/milestone-archive-dialog/milestone-archive-dialog.component";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";

@Injectable({
    providedIn: 'root'
})
export class MilestonesService extends BaseService {

    // Updated in MilestonePlan.ts
    public milestonePlans$: Observable<MilestonePlan[]>; // Make observables so we can subscribe
    public _milestonePlans: BehaviorSubject<MilestonePlan[]>;

    constructor(private filterGlobalService: FilterGlobalService,
                private eventService: EventService,
                private modalService: NgbModal,) {
        super();

        this._milestonePlans = <BehaviorSubject<MilestonePlan[]>>new BehaviorSubject(null);
        this.milestonePlans$ = this._milestonePlans.asObservable();

        // Listen for archived patch and check for related project phase completion https://podio.com/klartboard/softwareudvikling/apps/stories/items/929
        this.eventService.subscribeToMilestone(0, event => {
            if (event.fields?.includes('archived') && (event.item.archived?.exists() ?? false)) {
                const milestone = event.item;
                // console.log('this.eventService.subscribeToMilestone : ', milestone);

                if (milestone.archived_id && milestone.archived_id != 0) {
                    const milestoneDeadline = event.item.deadline?.getDate();
                    // Get milestone, with tasks
                    Api.milestones().getById(event.item.id)
                        .find(milestones => {
                            const milestoneItem = milestones[0];

                            if (milestoneItem.deadline) {
                                // Find ud af om vi skal vise faser da vi har en frist, den skal samle alle faser og opgaver
                                Api.projects().get()
                                    .include('phases_project')
                                    .where('milestone.id', event.item.id)
                                    .find(projects => {
                                        const archiveProjects = projects.filter(p => p.phases_projects?.length > 0).filter(p => {
                                            const phasesProject = p.getPhasesProjectByDate(milestoneDeadline);
                                            if (phasesProject) {
                                                return true;
                                            }
                                        });
                                        if (archiveProjects && archiveProjects.length > 0 || milestoneItem.tasks && milestoneItem.tasks.filter(t => t.archived_id == 0).length > 0) {
                                            this.showDialog(milestoneItem, archiveProjects);
                                        }
                                    });
                            } else {
                                if (milestoneItem.tasks && milestoneItem.tasks.filter(t => t.archived_id == 0).length > 0) {
                                    this.showDialog(milestoneItem, null);
                                }
                            }
                        });
                }
            }
        });
    }

    private showDialog(milestoneItem: Milestone, projects: Project[]) {
        const modalRef = this.modalService.open(
            MilestoneArchiveDialogComponent,
            {
                size: 'md',
                windowClass: 'modal-holder',
                centered: true,
                backdrop: false
            });
        const componentRef = (modalRef.componentInstance as MilestoneArchiveDialogComponent);
        componentRef.item = milestoneItem;
        // componentRef.phasesProjects = phasesProjects;
        componentRef.projects = projects;
    }

    // <editor-fold desc="Fields (Queue & Cache)">

    private fieldsDataCache = new ApiDataCache<MilestoneField>(
        System.MilestoneFields,
        2 * 60 * 60, // 2 hours
        Api.milestoneFields().get()
    );

    private getFields(callback: (fields: MilestoneField[]) => void) {
        this.fieldsDataCache.get(callback);
    }

    public getEditorFields(callback: (fields: MilestoneField[]) => void) {
        this.getFields(fields => {
            callback(fields.filter(field => field.type == FieldTypes.Editor))
        });
    }

    public getMiniCardFields(callback: (fields: MilestoneField[]) => void) {
        this.getFields(fields => callback(fields.filter(field => field.type == FieldTypes.MiniCard)));
    }

    // </editor-fold>

    // <editor-fold desc="Reaction types (Queue & Cache)">

    private reactionTypesDataCache = new ApiDataCache<MilestoneReactionType>(
        System.MilestoneReactionTypes,
        2 * 60 * 60, // 2 hours
        Api.milestoneReactionTypes().get()
    );

    // Entry point
    public getReactionTypes(callback: (fields: MilestoneReactionType[]) => void) {
        this.reactionTypesDataCache.get(callback);
    }

    // </editor-fold>

    // <editor-fold desc="Category types (Queue & Cache)">

    private categoryTypesDataCache = new ApiDataCache<CategoryType>(
        System.MilestoneCategoryTypes,
        2 * 60 * 60, // 2 hours
        Api.categoryTypesMilestones().get()
            .include('category_type')
    );

    // Entry point
    public getCategoryTypesMilestones(callback: (categoryTypesMilestones: CategoryTypesMilestone[]) => void) {
        this.categoryTypesDataCache.get(callback);
    }

    // </editor-fold>

    search(term: string, filters?: ApiFilter): Observable<Milestone[]> {
        const uri = OldApi.milestones().toString();

        let httpParams = new HttpParams();
        if (!filters) filters = new ApiFilter();

        if (term !== '') {
            filters.search('name', term);
            filters.search('smart_search', ['responsible']);
            filters.orderBy('created', OrderDirection.Descending);
            filters.orderBy('name', this.filterGlobalService.getActiveSettings().activeSortDirection);
            httpParams = httpParams.append('limit', '25');
        } else {
            httpParams = httpParams.append('limit', '25');
            filters.orderBy('name', this.filterGlobalService.getActiveSettings().activeSortDirection);
        }


        httpParams = httpParams.append('filter', filters.filtersString());
        httpParams = httpParams.append('ordering', filters.orderingString());
        httpParams = httpParams.append('include', 'project');

        const api: ApiCall = new ApiCall(uri, httpParams, (response) => {
            return response.resources.map((item: any) => {
                return new Milestone(item);
            });
        });

        return api.getHttp();

    }

}
