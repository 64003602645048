/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {UserDefinition} from './definitions/UserDefinition';
import {OldApi} from '@app/http/Api';
import {UserGroups} from '@app/constants';

export class User extends UserDefinition {

    constructor(json?: any) {
        super(json);
    }

    get name(): string | null {
        if (this.first_name && this.last_name) {
            return `${this.first_name} ${this.last_name}`;
        } else {
            if(this.first_name){
                return this.first_name;
            }
            if(this.last_name){
                return this.last_name;
            }
            return '???';
        }
    }
    get shortName(): string | null {
        if (this.first_name && this.last_name) {
            return `${this.first_name} ${this.last_name.substring(0,1)}.`;
        } else {
            return '';
        }
    }

    get sysadmin(): boolean {
        return this.user_group_id && this.user_group_id <= UserGroups.SysAdmin || this.uber;
    }
    get uber(): boolean {
        return this.user_group_id && this.user_group_id === UserGroups.Uber;
    }
    get administrator(): boolean {
        return this.user_group_id === UserGroups.Admin || this.sysadmin || this.uber;
    }

    get initialsConverter(): string {
        if(this.initials && this.initials.trim().length > 0){
            return this.initials;
        }
        if(!this.first_name && !this.last_name){
            return '';
        }
        return `${this.first_name.substring(0,1)} ${this.last_name.substring(0,1)}`;
    }



    public patch(fields?: string[], callback?: () => void) {
        let data: {} = {};
        if(fields) {
            for (let field of fields) data[field] = this[field];
        } else {
            data = this;
        }
        OldApi.users(this.id).patch(data).subscribe(response => {
            this.populate(response.resource, !!fields);
            if(callback) callback();
        });
    }

    /*
    public getDisplays(){

        if(this.departments){
            if(!this.displaySub) {
                const flatDepartmentIds: number[] = this.departments.map(value => value.id);
                this._displays = [];
                this.displaySub = AppInjector.getInjector().get(SystemService).displays$.subscribe(displays => {
                    displays.find(value => {
                        if(value.departments_displays) {
                            if (value.departments_displays.findIndex(value1 => flatDepartmentIds.includes(value1.department_id)) !== -1) {
                                this._displays.push(value);
                                return true;
                            }
                        }
                    })
                });
            }
        }
    }
    */



}
