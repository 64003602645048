export const Fields = {
    Title: 1, // Requireable
    Risk: 2,
    Note: 3, // Requireable
    Projects: 4, // Requireable
    StrategyGoals: 5, // No longer in use
    Deadline: 6, // Requireable
    Status: 7,
    Responsible: 8, // Requireable
    StatusRules: 17,
    CalculatedFields: 18,
    Log: 20,
};

export const MiniCardFields = {
    Status: 9,
    Title: 10,
    Archived: 11,
    HandUp: 12,
    Star: 13,
    Deadline: 14,
    Responsible: 15,
    Project: 16,
    CalculatedFields: 19,
};
