import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProjectsService} from '@app/services/projects.service';
import {TasksService} from '@app/services/tasks.service';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {Milestone} from '@app/core/models/Milestone';
import * as moment from 'moment';
import {Task} from '@app/core/models/Task';
import {TaskDeadlineTypes, TaskUserTypes} from '@app/constants';
import {TasksDeadline} from '@app/core/models/TasksDeadline';
import {Deadline} from '@app/core/models/Deadline';
import {animate, state, style, transition, trigger} from '@angular/animations';
import 'jqueryui';
import Helpers from "@app/core/helpers";
import {EventService} from "@app/services/event.service";
import {PhasesProject} from "@app/core/models/PhasesProject";
import {CardMilestoneConfiguration} from '@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration';
import {CardTaskConfiguration} from '@app/shared/_ui/cards/medium/card-task/card-task-configuration';
import {Api} from '@app/core/http/Api/Api';

@Component({
    selector: 'app-move-milestone-deadline-dialog',
    templateUrl: './move-milestone-deadline-dialog.component.html',
    styleUrls: ['./move-milestone-deadline-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ]
})
export class MoveMilestoneDeadlineDialogComponent extends BaseModalComponent implements OnInit {

    @Input() item: Milestone;
    @Input() difference: number;
    // @Input() dateChangeDifference: DateChangeDifference; // KA: TO-DO: Implement like move-project-deadline-dialog
    @Input() projectIds: number[] = [];
    @Input() phasesProjectItem: PhasesProject;

    private milestone: Milestone;
    public milestones: Milestone[];
    public milestoneTasks: Task[] = [];
    public phasesProjects: PhasesProject[] = [];
    public moveProjectPhases: boolean = false;
    public moveMilestones: boolean = false;
    public moveMilestoneTasks: boolean = false;
    public moveMilestoneTasksUsers: boolean = false;
    public isLoading: boolean = true;
    public allActive: boolean = false;
    public milestoneConfiguration: CardMilestoneConfiguration = new CardMilestoneConfiguration();
    public taskConfiguration: CardTaskConfiguration = new CardTaskConfiguration();


    constructor(private modal: NgbActiveModal,
                private projectsService: ProjectsService,
                private tasksService: TasksService,
                private eventsService: EventService
    ) {
        super(modal);
        this.taskConfiguration.showMilestoneMiniCards = false;
        this.milestoneConfiguration.showDate = false;
        this.milestoneConfiguration.showReactions = false;

    }

    public toggleAll() {
        if (this.moveProjectPhases ||
            this.moveMilestones ||
            this.moveProjectPhases ||
            this.moveMilestoneTasksUsers ||
            this.moveMilestoneTasks
        ) {
            this.allActive =
                this.moveProjectPhases =
                    this.moveMilestones =
                        this.moveProjectPhases =
                            this.moveMilestoneTasksUsers =
                                this.moveMilestoneTasks = false;

        } else {
            this.allActive =
                this.moveProjectPhases =
                    this.moveMilestones =
                        this.moveProjectPhases =
                            this.moveMilestoneTasksUsers =
                                this.moveMilestoneTasks = true;
        }
    }

    private loadRelatedMilestoneDetails() {
        if (!this.item) {
            return;
        }

        Api.milestones()
            .get()
            .where('id', this.item.id)
            .include('project.phases_project?include=phase.color')
            .find(milestone => {
                this.milestone = milestone[0];
                this.projectIds = this.milestone.projects?.map(value => value.id) ?? [];

                this.milestone.projects?.forEach(project => {
                    project.phases_projects?.forEach(phasesProject => {
                        if (phasesProject.getStartedDate() >= this.item.deadline.getDate()
                            && phasesProject.id !== this.phasesProjectItem?.id) {
                            this.phasesProjects.push(phasesProject);
                        }
                    });
                })

                this.phasesProjects = this.phasesProjects.sort((a, b) => {
                    return a.getStartedDate().getTime() - b.getStartedDate().getTime();
                });

                this.moveProjectPhases = this.moveProjectPhases && this.phasesProjects.length > 0;
                this.loadRelatedMilestones();
            });
    }

    private loadRelatedMilestones() {
        /*
         1. find milepæle og deres opgaver (ikke model.id milepæl)
         2. læg dato til milepæl
         3. flyt opgaver x dage ift. milepæl

        */
        //const projectIds: number[] = this.item.projects.map(value => value.id);
        Api.milestones().get()
            .whereInArray('project.id', this.projectIds)
            .include('task')
            // .include('task?include=tasks_user')
            .orderBy('deadline.date', 'ASC')
            .find((milestones: Milestone[]) => {
                milestones.map((milestone, index) => {
                    if (!milestone.tasks) milestone.tasks = [];
                    milestone.tasks.sort((a, b) => {
                        const dateA = a.findTasksDeadlineByType(TaskDeadlineTypes.Normal) || new TasksDeadline();
                        const dateB = b.findTasksDeadlineByType(TaskDeadlineTypes.Normal) || new TasksDeadline();
                        const valA: number = dateA.deadline ? dateA.deadline.getDate().getTime() : 0;
                        const valB: number = dateB.deadline ? dateB.deadline.getDate().getTime() : 0;
                        return (valA - valB);
                    });
                    return milestone;
                });
                this.milestones = milestones.filter((_milestone, index) => {
                    if (this.phasesProjectItem) {
                        return _milestone.deadline && _milestone.deadline.getDate() >= this.phasesProjectItem.getStartedDate();
                    } else {
                        return _milestone.deadline && _milestone.deadline.getDate() >= this.milestone.deadline.getDate() || _milestone.id == this.item.id;
                    }
                });

                this.milestones.forEach(m => {
                    this.milestoneTasks = [...this.milestoneTasks, ...m.tasks];
                })

                if (this.milestones.length == 0 && this.milestoneTasks.length == 0) {
                    this.activeModal.close(false);
                } else {
                    this.isLoading = false;
                }
            });
    }

    public addDifference(date: Date): Date {
        if (date) {
            return moment(date).add(this.difference, 'days').toDate();
        } else {
            return null;
        }
    }

    ngOnInit() {
        this.loadRelatedMilestoneDetails();
    }

    public accept() {
        this.activeModal.close(true);

        if (this.moveProjectPhases && this.phasesProjects) {
            this.phasesProjects.forEach(displayPhasesProjects => {
                this.milestone.projects.forEach(project => {
                    project.phases_projects.forEach(projectPhasesProject => {
                        if (projectPhasesProject.id == displayPhasesProjects.id) {
                            project.updatePhasesProjectDate(
                                projectPhasesProject,
                                new Date(Helpers.updateWithDifference(this.difference, displayPhasesProjects.start))
                            );
                        }
                    });
                })
            })
        }

        let movedTasks: Task[] = [];

        this.milestones.filter(milestone => {
            if (this.moveMilestones
                && ((milestone.deadline && milestone.deadline.getDate() >= this.milestone.deadline.getDate() && (!milestone.archived_id || milestone.archived_id == 0))
                    || (milestone.id == this.milestone.id && !this.phasesProjectItem)
                    || (this.phasesProjectItem && (!milestone.archived_id || milestone.archived_id == 0)))
            ) {
                if (this.moveMilestoneTasks) {
                    movedTasks = movedTasks.concat(milestone.tasks).filter(t => !t.archived);
                }

                const oldDate: Date = milestone.deadline ? milestone.deadline.getDate() : null;
                if (oldDate && (milestone.id != this.milestone.id || this.phasesProjectItem)) {
                    const deadline = new Deadline();
                    deadline.id = 0;
                    deadline.date = Helpers.updateWithDifference(this.difference, oldDate);
                    milestone.setDeadline(0, deadline);
                    this.eventsService.emitMilestone(milestone, EventService.Updated, ['deadline']);
                }
                return milestone;
            }
        });

        movedTasks
            // Only consider tasks with deadline set
            .filter(task => task.findDeadlineByTypeId(TaskDeadlineTypes.Normal) !== undefined)
            .forEach(task => {

                task.setDeadline(
                    TaskDeadlineTypes.Normal,
                    Deadline.Create(
                        new Date(Helpers.updateWithDifference(
                            this.difference, task.findDeadlineByTypeId(TaskDeadlineTypes.Normal).getDate()
                        ))
                    )
                );

                if (this.moveMilestoneTasksUsers) {
                    task.findEnhancedUsersByTypeId(TaskUserTypes.Participant)
                        // Only consider users with deadline set
                        .filter(enhancedUser => enhancedUser.deadline !== undefined)
                        .forEach(enhancedUser => {
                            task.setUserDeadline(
                                enhancedUser.user,
                                new Date(Helpers.updateWithDifference(this.difference, enhancedUser.deadline.getDate()))
                            );
                        });
                }
            });
    }

}
