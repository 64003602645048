import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-users/Columns/TaskEditColumn";
import {
    SortableTextColumnConfiguration
} from "@app/editor/quick-editor/columns/generic/sortable-text-column/SortableTextColumnConfiguration";
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";
import {
    BaseEditorTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/BaseEditorTableColumn";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {
    EditorWithColumnInterface
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/EditorWithColumnInterface";
import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {CardEditorComponent} from "@app/editor/quick-editor/editors/generic/card-editor/card-editor.component";
import {
    SortableTextColumnComponent
} from "@app/editor/quick-editor/columns/generic/sortable-text-column/sortable-text-column.component";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-users/Filters";
import {DisplayFilterEditorFormConfigInterface} from "@app/editor/display-filter-editor/DisplayFilterEditorForm";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

export class TaskEdit_Card_TableColumn extends BaseEditorTableColumn {

    public identifier = TableColumns.TaskEdit_Card;

    public column: TaskEditColumn;
    public minWidth = 210;
    public maxWidth = 210;
    public isList = true;

    public columnConfiguration: SortableTextColumnConfiguration;
    public editorWithColumn: EditorWithColumnInterface;

    constructor(column: TaskEditColumn, shellPageData: ShellPageData,
                onColumnSortChange: (column: BaseTableColumn) => void,
                config: DisplayFilterEditorFormConfigInterface) {
        super(column);
        this.name = AppInjector.getInjector().get(TranslateService).instant('_displays_project_details_subdisplay_task_edit_minicard_title');

        this.setSortItems([
            PageColumnSort.CreateWithSortId(Filters.SortUserDefined),
            PageColumnSort.CreateWithSortId(Filters.SortTitle),
            PageColumnSort.CreateWithSortId(Filters.SortStatus),
            PageColumnSort.CreateWithSortId(Filters.SortDeadlines),
            PageColumnSort.CreateWithSortId(Filters.SortPlanningDate),
            PageColumnSort.CreateWithSortId(Filters.SortStars),
            PageColumnSort.CreateWithSortId(Filters.SortHands),
            ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                Filters.SortCategoryTypeGenerator(categoryType)
            )),
            ...config.taskDeadlineTypes.map(deadlineType => PageColumnSort.CreateWithSortId(
                Filters.SortDeadlineWithGenerator(deadlineType)
            )),
        ]);

        this.columnConfiguration = new SortableTextColumnConfiguration(
            AppInjector.getInjector().get(TranslateService).instant('_displays_project_details_subdisplay_task_edit_minicard_title'),
            this,
            shellPageData,
            onColumnSortChange,
            false,
            false
        );
        this.columnConfiguration.savedWidth = this.width > 0 ? this.width : undefined;
        this.columnConfiguration.minWidth = this.minWidth;
        this.columnConfiguration.maxWidth = this.maxWidth;
        this.columnConfiguration.canAutoResize = false;
        this.columnConfiguration.onResizeEndEvent.subscribe(() => {
            this.width = this.columnConfiguration.width;
        });

        const editorConfiguration = new BaseEditorConfiguration();
        editorConfiguration.widthSynchronizer = this.columnConfiguration.widthSynchronizer;

        this.editorWithColumn = {
            name: CardEditorComponent.name,
            columnName: SortableTextColumnComponent.name,
            columnConfiguration: this.columnConfiguration,
            configuration: editorConfiguration,
        };
    }

}
