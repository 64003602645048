import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {User} from '@app/core/models/User';
import {EventEmitter} from '@angular/core';
import {BaseRow} from "@app/core/ColumnControl/BaseRow";

export class DisplayTeamRow extends BaseRow {
    userCard: CardItem<User>;

    minimizeEvent: EventEmitter<any> = new EventEmitter();
    loadAllEvent = new EventEmitter();

    reloadProjectListEvent: EventEmitter<any> = new EventEmitter();
    reloadMilestonesEvent: EventEmitter<any> = new EventEmitter();
    reloadCasesEvent: EventEmitter<any> = new EventEmitter();
    reloadTasksEvent: EventEmitter<any> = new EventEmitter();
    reloadAppointmentsEvent: EventEmitter<any> = new EventEmitter();
    reloadTodosEvent: EventEmitter<any> = new EventEmitter();

    public reload() {
        this.reloadProjectListEvent.emit();
        this.reloadMilestonesEvent.emit();
        this.reloadCasesEvent.emit();
        this.reloadTasksEvent.emit();
        this.reloadAppointmentsEvent.emit();
        this.reloadTodosEvent.emit();
    }

    public emitMinimizeEvent() {
        this.minimizeEvent.emit();
    }

    public emitLoadAllEvent() {
        this.loadAllEvent.emit();
    }

}
