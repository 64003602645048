import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {MilestonePresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/MilestonePresetType";

export class MilestoneTaskPresetGenerator implements CreatePresetGenerator {

    private readonly taskId?: number;
    private readonly taskIdHandler?: () => number;

    constructor(taskIdOrHandler: number | (() => number)) {
        if (typeof taskIdOrHandler == "number") {
            this.taskId = taskIdOrHandler;
        } else {
            this.taskIdHandler = taskIdOrHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: MilestonePresetTypes.Task,
            payload: {
                taskId: this.taskId ?? this.taskIdHandler(),
            }
        };
    }

}
