import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {Project} from '@app/core/models/Project';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProjectsService} from '@app/services/projects.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ProjectType} from '@app/core/models/ProjectType';

@Component({
    selector: 'app-change-project-type-dialog',
    templateUrl: './change-project-type-dialog.component.html',
    styleUrls: ['./change-project-type-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ]
})
export class ChangeProjectTypeDialogComponent extends BaseModalComponent implements OnInit {

    @Input() item: Project;

    public isLoading: boolean = true;
    public projectTypes: ProjectType[] = [];

    constructor(private modal: NgbActiveModal,
                private projectsService: ProjectsService
    ) {
        super(modal);
    }

    ngOnInit() {
        this.projectsService.getProjectTypes(projectTypes => {
            this.projectTypes = projectTypes;
        });
        this.isLoading = false;
    }

    public accept() {
        this.item.setType(this.item.project_type_id);
        this.activeModal.close(true);
    }

}
