import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Milestone} from "@app/core/models";
import {Field} from "@app/editor/milestone-editor-loader/milestone-editor.service";
import {EditTitleConfiguration} from "@app/editor/quick-editor/editors/generic/title-editor/EditTitleConfiguration";
import {Fields} from "@app/editor/milestone-editor-loader/Fields";
import {EditTitleValidator} from '@app/editor/quick-editor/editors/generic/title-editor/EditTitleValidator';
import {ValidationErrorInterface} from '@app/editor/Validation/ValidationErrorInterface';

@Component({
    selector: 'app-milestone-editor-title',
    templateUrl: './milestone-editor-title-component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneEditorTitleComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item: Milestone;
    @Input() fields: Map<number, Field>;
    @Output() onValidatorChange = new EventEmitter<ValidationErrorInterface>();

    // Bindings to view
    public isReady = false;
    public showEditor = false;
    public editorConfiguration: EditTitleConfiguration;

    // Data

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.render();
    }

    private render() {
        this.showEditor = this.fields.has(Fields.Title) && this.fields.get(Fields.Title).visible;

        if (this.showEditor) {
            this.editorConfiguration = new EditTitleConfiguration(
                this.fields.get(Fields.Title).smartName,
                true,
                new EditTitleValidator(this.fields.get(Fields.Title).required)
            );
            this.onValidatorChange.emit(this.editorConfiguration.validator);
        }

        this.isReady = true;
    }

    // <editor-fold desc="View actions">

    // </editor-fold>

}
