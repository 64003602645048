<ng-container [ngSwitch]="name">
    <app-title-editor
        class="flex-shrink-1"
        *ngSwitchCase="'TitleEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-deadline-list-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="'DeadlineListEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-milestone-plan-select-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="'MilestonePlanSelectEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-card-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="'CardEditorComponent'"
        [model]="$any(card)"
        [cardItem]="card"
        [configuration]="$any(configuration)"
        [listDragInterface]="listDragInterface"
        [editorEvents]="editorEvents"
    />
    <app-status-editor
        class="flex-grow-0 flex-shrink-0"
        *ngSwitchCase="'StatusEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-estimate-list-editor
        *ngSwitchCase="'EstimateListEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-category-list-editor
        *ngSwitchCase="'CategoryListEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-reaction-list-editor
        *ngSwitchCase="'ReactionListEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-text-editor
        *ngSwitchCase="'TextEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-link-editor
        *ngSwitchCase="'LinkEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-who-when-editor
        *ngSwitchCase="'WhoWhenEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-action-editor
        *ngSwitchCase="'ActionEditorComponent'"
        [model]="model"
        [cardItem]="card"
        [configuration]="$any(configuration)"
        [listDragInterface]="listDragInterface"
        [editorEvents]="editorEvents"
    />
    <app-milestone-list-editor
        *ngSwitchCase="'MilestoneListEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-archived-editor
        *ngSwitchCase="'ArchivedEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-user-list-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="'UserListEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-use-status-rules-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="'UseStatusRulesEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-category-picker-list-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="'CategoryPickerListEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-department-picker-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="'DepartmentPickerEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-tag-list-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="'TagListEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-can-copy-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="'CanCopyEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-is-private-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="'IsPrivateEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-number-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="'NumberEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-todo-category-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="'TodoCategoryEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-checkbox-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="'CheckboxEditorComponent'"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
</ng-container>
