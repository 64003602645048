import {CSVExportOptionInterface} from "@app/export/csv/CSVExportOptionInterface";

export class CSVCustomBinding {

    private exportFunction: ((option?: CSVExportOptionInterface) => string);

    public setExportFunction(func: (option?: CSVExportOptionInterface) => string) {
        this.exportFunction = func;
    }

    public export(option?: CSVExportOptionInterface): string {
        return this.exportFunction ? this.exportFunction(option) : null;
    }

}
