import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditDeadlineList} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineList";
import {
    EditDeadlineListConfiguration
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineListConfiguration";
import {Deadline} from "@app/core/models";
import {DeadlineItem} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/DeadlineItem";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";

@Component({
    selector: 'app-deadline-list-editor',
    templateUrl: './deadline-list-editor.component.html',
    styleUrls: ['./deadline-list-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeadlineListEditorComponent extends BaseEditor<EditDeadlineList> {
    protected eventFieldName = 'deadlines';

    @Input() model: EditDeadlineList & HasEventGenerator & BaseModel;
    @Input() configuration: EditDeadlineListConfiguration;
    @Input() editorEvents: EditorEvents;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    public types: DeadlineItem[];

    protected render() {
        if (this.model) {

            const types = this.configuration.types?.map(type => type.clone()) ?? [];

            types.forEach((type, index) => {
                type.setDeadline(this.model.findDeadlineByTypeId(type.id));

                if (type.deadline) {
                    type.onRequiredChangeEvent.subscribe(() => {
                        this.types = [...this.configuration.types];
                        this.detectChanges();
                    });
                }

                if (this.model.getStaticDeadlines) {
                    this.model.getStaticDeadlines(type.id, staticDeadlines => {
                        type.staticDeadlines = staticDeadlines;
                        this.detectChanges();
                    });
                }

                if (index > 0) {
                    type.previousItem = types[index - 1];
                }

                type.validate();
            });

            this.types = types;
            this.detectChanges();
        }
    }

    public onValueChanged(type: DeadlineItem, deadline: Deadline) {
        type.onDateChangedEvent.emit({
            before: type.previousDeadline?.getDate(),
            after: deadline?.getDate()
        });
        type.setDeadline(deadline);
        this.save(type.id, deadline);
    }

    private save(typeId: number, deadline: Deadline) {
        this.model.setDeadline(typeId, deadline.date ? deadline : undefined);
        this.detectChanges();
        this.render();
        this.onItemUpdated();
    }

}
