/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {MilestoneTemplateDefinition,} from './definitions/MilestoneTemplateDefinition';

export class MilestoneTemplate extends MilestoneTemplateDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static createDefault() {
        let item = new MilestoneTemplate();
        item.id = 0;
        // item.estimate_unit_id = EstimateUnits.Hours;
        return item;
    }
}
