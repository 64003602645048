import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Project, ProjectType} from "@app/core/models";
import {Field} from "@app/editor/project-editor-loader/project-editor.service";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {ValidatorCollection} from "@app/editor/Validation/ValidatorCollection";

@Component({
    selector: 'app-project-editor-tabs-log',
    templateUrl: './project-editor-tabs-log-component.html',
    styleUrls: ['./project-editor-tabs-log.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectEditorTabsLogComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item: Project;
    @Input() projectType: ProjectType;
    @Input() fields: Map<number, Field>;
    @Input() openEventEmitter: EventEmitter<boolean>;
    @Output() validatorChange = new EventEmitter<ValidationErrorInterface>();

    // Bindings to view
    public isReady = false;

    // Data
    private validator: ValidationErrorInterface;

    constructor(
        private cd: ChangeDetectorRef,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.setupEditors();
        this.setupValidator();
    }

    private setupEditors() {
    }

    private setupValidator() {
        // Collect validators
        this.validator = new ValidatorCollection([
        ]);
        this.validatorChange.emit(this.validator);

        this.isReady = true;
    }

    private isFieldVisible(fieldId: number): boolean {
        return this.fields.has(fieldId) && this.fields.get(fieldId).visible;
    }

    // <editor-fold desc="View actions">

    // </editor-fold>

    // <editor-fold desc="Dialogs">

    // </editor-fold>

}
