import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import * as moment from 'moment';

@Component({
    selector: 'app-date-difference',
    templateUrl: './date-difference.component.html',
    styleUrls: ['./date-difference.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateDifferenceComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() dateA: Date;
    @Input() dateB: Date;

    public dateDifference: string;
    public dateDifferenceHuman: string;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.render();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        if (this.dateA && this.dateB) {
            moment.locale('da');
            const difference: moment.Duration = moment.duration(moment(this.dateA).diff(moment(this.dateB))).abs();

            // To deal with summer/winter time
            if (difference.get('hour') == 23) {
                difference.add(1, 'hour');
            }

            this.dateDifference = Math.floor(difference.asDays()).toString();
            this.dateDifferenceHuman = difference.humanize();
        } else
            this.dateDifference = null;
    }
}
