import {NgModule} from "@angular/core";
import {AutofocusDirective} from "@app/directives/autofocus.directive";

@NgModule({
    imports: [

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        AutofocusDirective,
    ],
    exports: [
        AutofocusDirective

    ],
    providers: [

    ],
})
export class AutofocusDirectiveModule {}
