import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {YearWheelColumnConfiguration} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration";
import {YearWheelColumn} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/YearWheelColumn";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";

export class YearWheelCell extends BaseCell {

    public yearWheelConfiguration = new YearWheelColumnConfiguration();

    constructor(row: Row, column: YearWheelColumn) {
        super(row, column);

        this.yearWheelConfiguration
            .setProject(row.projectCard.item)
            .setProjectStart(row.projectCard.item.findProjectsDeadlineByType(column.getProjectDeadlineTypeStartSetting()))
            .setProjectEnd(row.projectCard.item.findProjectsDeadlineByType(column.getProjectDeadlineTypeEndSetting()))
            .setPeriodUnit(column.yearWheelDisplayOptions.unit)
            .setDisplayOptions(column.yearWheelDisplayOptions);
        if (column.yearWheelPeriod) {
            this.yearWheelConfiguration
                .setStart(column.yearWheelPeriod.start)
                .setEnd(column.yearWheelPeriod.end);
        }
    }

}
