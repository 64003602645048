import {ProjectDisplayRow} from "@app/pages/displays/dashboard/dashboard-projects/ProjectDisplayRow";
import {GenericTableColumn} from "@app/pages/displays/dashboard/dashboard-projects/TableColumns/GenericTableColumn";
import {
    AppointmentListTableColumn
} from "@app/pages/displays/dashboard/dashboard-projects/TableColumns/AppointmentListTableColumn";
import {
    TaskListNextMilestoneTableColumn
} from "@app/pages/displays/dashboard/dashboard-projects/TableColumns/TaskListNextMilestoneTableColumn";
import {
    FollowingMilestoneListTableColumn
} from "@app/pages/displays/dashboard/dashboard-projects/TableColumns/FollowingMilestoneListTableColumn";
import {TodoListTableColumn} from "@app/pages/displays/dashboard/dashboard-projects/TableColumns/TodoListTableColumn";

export class TemplateTypes {

    headerTemplateType: {
        column: GenericTableColumn
    }

    cellTemplateType: {
        row: ProjectDisplayRow,
        rowIndex: number,
        column: GenericTableColumn
    }

    taskListNextMilestoneCellTemplateType: {
        row: ProjectDisplayRow,
        rowIndex: number,
        column: TaskListNextMilestoneTableColumn
    }

    followingMilestoneListCellTemplateType: {
        row: ProjectDisplayRow,
        rowIndex: number,
        column: FollowingMilestoneListTableColumn
    }

    appointmentListCellTemplateType: {
        row: ProjectDisplayRow,
        rowIndex: number,
        column: AppointmentListTableColumn
    }

    todoListCellTemplateType: {
        row: ProjectDisplayRow,
        rowIndex: number,
        column: TodoListTableColumn
    }

}
