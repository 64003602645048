import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {CSVColumn} from "@app/export/csv/CSVColumn";

export class GenericColumn<T extends GenericCell = GenericCell> extends BaseColumn<T> {

    public implementsCSVExport = false;

    public createTableColumns(): GenericTableColumn[] {
        const item = new GenericTableColumn(this);
        this.setTableColumnDefaults(item);
        return [item];
    }

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new GenericCell(row, this);
    }

    public toCSVColumn(result: (column: CSVColumn) => void, rows: ProjectsDisplayRow[]): void {
        result(CSVColumn.CreatePrimary(this));
    }

}
