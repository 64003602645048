import {NgModule} from "@angular/core";
import {
    TemplateTaskRowComponent
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-editor/partials/template-task-row.component";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {AutofocusDirectiveModule} from "@app/directives/AutofocusDirective.module";
import {UserSearchModule} from "@app/shared/_forms/search/user-search/UserSearch.module";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {RichTextEditorModule} from "@app/shared/_forms/rich-text-editor/RichTextEditor.module";
import {UrlLinkModule} from "@app/shared/_ui/url-link/UrlLink.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule,
        FormsModule,
        AutofocusDirectiveModule,
        UserSearchModule,
        UserWithIconModule,
        RichTextEditorModule,
        UrlLinkModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TemplateTaskRowComponent,
    ],
    exports: [
        TemplateTaskRowComponent

    ],
    providers: [

    ],
})
export class TemplateTaskRowModule {}
