import {NgModule} from "@angular/core";
import {
    DepartmentUsersPickerComponent
} from "@app/shared/_forms/department-users-picker/department-users-picker.component";
import {DepartmentSearchModule} from "@app/shared/department-search/DepartmentSearch.module";
import {NgbDropdownModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        DepartmentSearchModule,
        NgbTooltipModule,
        TranslateModule,
        NgbDropdownModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DepartmentUsersPickerComponent,
    ],
    exports: [
        DepartmentUsersPickerComponent

    ],
    providers: [

    ],
})
export class DepartmentUsersPickerModule {}
