/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {EstimateUnitDefinition} from './definitions/EstimateUnitDefinition';
import {Api} from "@app/core/http/Api/Api";
import {System} from '@app/constants';
import {AppInjector} from '@app/services/app-injector.service';
import {AuthenticationService} from '@app/core';
import {CacheService} from '@app/services/cache.service';
import {ProjectType} from '@app/core/models/ProjectType';
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";

export class EstimateUnit extends EstimateUnitDefinition {

    constructor(json?: any) {
        super(json);
    }

    // <editor-fold desc="Cache">

    private static DataCache = new ApiDataCache<EstimateUnit>(
        System.EstimateUnitTypes,
        2 * 60 * 60, // 2 hours
        Api.estimateUnits().get()
    );

    public static GetAll(callback: (items: EstimateUnit[]) => void) {
        EstimateUnit.DataCache.get(callback);
    }

    // </editor-fold>

}
