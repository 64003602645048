/**
 * Created by ModelParser
 */
import {Color} from '../Color';
import {Department} from '../Department';
import {PhasesProject} from '../PhasesProject';
import {PhasesProjectType} from '../PhasesProjectType';
import {ProjectType} from '../ProjectType';
import {MilestoneTemplate} from '../MilestoneTemplate';
import {PhaseProgressType} from '../PhaseProgressType';
import {PhaseProgressTypesPhase} from '../PhaseProgressTypesPhase';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class PhaseDefinition extends BaseModel {
    name?: string;
    changeable_date?: boolean;
    index_?: number;
    color_id?: number;
    color?: Color;
    departments?: Department[];
    phases_projects?: PhasesProject[];
    phases_project_types?: PhasesProjectType[];
    project_types?: ProjectType[];
    milestone_templates?: MilestoneTemplate[];
    phase_progress_types?: PhaseProgressType[];
    phase_progress_types_phases?: PhaseProgressTypesPhase[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.changeable_date;
            delete this.index_;
            delete this.color_id;
            delete this.color;
            delete this.departments;
            delete this.phases_projects;
            delete this.phases_project_types;
            delete this.project_types;
            delete this.milestone_templates;
            delete this.phase_progress_types;
            delete this.phase_progress_types_phases;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.changeable_date != null) {
            this.changeable_date = data.changeable_date;
        }
        if (data.index_ != null) {
            this.index_ = data.index_;
        }
        if (data.color_id != null) {
            this.color_id = data.color_id;
        }
        if (data.color != null) {
            this.color = new Color(data.color);
        }
        if (data.departments != null) {
            this.departments = data.departments.map((i: any) => new Department(i));
        }
        if (data.phases_projects != null) {
            this.phases_projects = data.phases_projects.map((i: any) => new PhasesProject(i));
        }
        if (data.phases_project_types != null) {
            this.phases_project_types = data.phases_project_types.map((i: any) => new PhasesProjectType(i));
        }
        if (data.project_types != null) {
            this.project_types = data.project_types.map((i: any) => new ProjectType(i));
        }
        if (data.milestone_templates != null) {
            this.milestone_templates = data.milestone_templates.map((i: any) => new MilestoneTemplate(i));
        }
        if (data.phase_progress_types != null) {
            this.phase_progress_types = data.phase_progress_types.map((i: any) => new PhaseProgressType(i));
        }
        if (data.phase_progress_types_phases != null) {
            this.phase_progress_types_phases = data.phase_progress_types_phases.map((i: any) => new PhaseProgressTypesPhase(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
