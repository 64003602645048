import {CardConfiguration} from '@app/shared/_ui/cards/CardConfiguration';
import {UserPeriodRatingFetchRequest} from "@app/shared/_ui/cards/medium/card-user/UserPeriodRatingFetcher";
import {UserLoadCalculationFetchRequest} from "@app/shared/_ui/cards/medium/card-user/LoadCalculationFetcher";
import {ProjectCountFetchRequest} from "@app/shared/_ui/cards/medium/card-user/ProjectCountFetcher";

export class CardUserConfiguration extends CardConfiguration {

    private loadId: number;
    private period: Date[]; // [<start>, <end>]
    public showPeriodRating = true;
    public showLoads = true;
    public showProjectCount = true;

    // Data Providers
    private periodRatingDataSource: UserPeriodRatingFetchRequest;
    private loadCalculationDataSource: UserLoadCalculationFetchRequest;
    private projectCountDataSource: ProjectCountFetchRequest;

    public getLoadId(): number {
        return this.loadId;
    }

    public setLoadId(value: number): CardUserConfiguration {
        this.loadId = value;
        return this;
    }

    public getPeriod(): Date[] {
        return this.period;
    }

    public setPeriod(start: Date, end: Date): CardUserConfiguration {
        this.period = [start, end];
        return this;
    }

    public getPeriodRatingDataSource(): UserPeriodRatingFetchRequest {
        return this.periodRatingDataSource;
    }

    public setPeriodRatingDataSource(value: UserPeriodRatingFetchRequest): CardUserConfiguration {
        this.periodRatingDataSource = value;
        return this;
    }

    public getLoadCalculationDataSource(): UserLoadCalculationFetchRequest {
        return this.loadCalculationDataSource;
    }

    public setLoadCalculationDataSource(value: UserLoadCalculationFetchRequest): CardUserConfiguration {
        this.loadCalculationDataSource = value;
        return this;
    }

    public getProjectCountFetchRequest(): ProjectCountFetchRequest {
        return this.projectCountDataSource;
    }

    public setProjectCountFetchRequest(value: ProjectCountFetchRequest): CardUserConfiguration {
        this.projectCountDataSource = value;
        return this;
    }

}
