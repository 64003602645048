/**
 * Created by ModelParser
 * Date: 20-12-2018.
 * Time: 11:42.
 */
import {ProjectStatusTypesStatusDefinition,} from './definitions/ProjectStatusTypesStatusDefinition';

export class ProjectStatusTypesStatus extends ProjectStatusTypesStatusDefinition {

    constructor(json?: any) {
        super(json);
    }

}
