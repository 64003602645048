import {NgModule} from "@angular/core";
import {DashboardCalendar} from "@app/pages/displays/dashboard/dashboard-calendar/dashboard-calendar.component";
import {OnScreenFilterSelectorModule} from "@app/shared/_ui/on-screen-filter-selector/OnScreenFilterSelector.module";
import {TranslateModule} from "@ngx-translate/core";
import {WeekPipeModule} from "@app/pipes/WeekPipe.module";
import {CommonModule} from "@angular/common";
import {MultiListModule} from "@app/shared/_ui/lists/multi-list/MultiList.module";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {DragDropModule} from "@angular/cdk/drag-drop";

@NgModule({
    imports: [
        OnScreenFilterSelectorModule,
        TranslateModule,
        WeekPipeModule,
        CommonModule,
        MultiListModule,
        LocalizedDatePipeModule,
        DragDropModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DashboardCalendar,
    ],
    exports: [
        DashboardCalendar

    ],
    providers: [

    ],
})
export class DashboardCalendarModule {}
