<div class="container mt-2 mb-2">
    <div class="list-group w-100">
    <div class="list-group-item ">
        <div class="input-group">
            <input type="search"
                   autocomplete="off"
                   class="form-control form-control-sm"
                   [placeholder]="(placeholder | translate)"
                   [(ngModel)]="searchValue"
                   (search)="search()"
                   (ngModelChange)="changed($event)"
            />
                <button role="button" (click)="reset()" class="btn -btn-danger btn-sm btn-outline-danger"><i class="fal fa-times me-1" aria-hidden="true"></i>{{'_ui_reset' | translate}}</button>
                <button role="button" (click)="search()" class="btn -btn-success btn-sm btn-outline-primary"><i class="fal fa-search me-1" aria-hidden="true"></i>{{'_ui_search' | translate}}...</button>
        </div>
    </div>
    <app-todo-list [configuration]="configuration" [reloadEvent]="reloadEmitter" class="list-group-item p-0  w-100"></app-todo-list>
    </div>
</div>
