import {NgModule} from "@angular/core";
import {CardEditExpanderComponent} from "@app/shared/_ui/displays/card-edit-expander/card-edit-expander.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {
    CardVerticalEditCollectionsModule
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/CardVerticalEditCollections.module";
import {TranslateModule} from "@ngx-translate/core";
import {CreateItemDropdownModule} from "@app/shared/_ui/create-item-dropdown/CreateItemDropdown.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        DragDropModule,
        CardVerticalEditCollectionsModule,
        TranslateModule,
        CreateItemDropdownModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CardEditExpanderComponent,
    ],
    exports: [
        CardEditExpanderComponent

    ],
    providers: [

    ],
})
export class CardEditExpanderModule {}
