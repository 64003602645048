import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseSearchComponent} from '@app/shared/_forms/search/base-search/base-search.component';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {MilestonesService} from '@app/services/milestones.service';
import {Milestone} from '@app/core/models/Milestone';
import {merge, Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import {ApiFilter} from '@app/http/APIFilter';
import {MilestonesProject} from '@app/core/models/MilestonesProject';
import {CardMilestoneConfiguration} from '@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration';

@Component({
    selector: 'app-milestone-search',
    templateUrl: './milestone-search.component.html',
    styleUrls: ['../base-search/base-search.component.scss'],
    animations: [
        fadeAnimation,
        slideDownAnimation
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MilestoneSearchComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MilestoneSearchComponent),
            multi: true,
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneSearchComponent extends BaseSearchComponent implements OnInit, OnChanges {

    @Input() _internalValue: Milestone;

    // Filtering
    @Input() projectId: number;
    @Input() milestoneRequiredOnProject: boolean = false; // vis kun milepæle såfremt der er valgt et project
    private apiFilter: ApiFilter;

    result: Milestone[] = [];
    milestoneConfiguration: CardMilestoneConfiguration = new CardMilestoneConfiguration();

    constructor(private milestonesService: MilestonesService,
                private _cdr:ChangeDetectorRef) {
        super();
        this.cdr = _cdr;
        this.milestoneConfiguration.useGlobalFilter = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.projectId) {
            this.loadMilestones();
        }
    }

    ngOnInit() {
        this.loadMilestones();
    }

    private loadMilestones(){
        // Has filter
        if(this.projectId) {
            this.apiFilter = new ApiFilter();
            this.apiFilter.whereEquals('project.id', this.projectId);
        }
    }

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            tap(() => this.searching = true),
            switchMap(term => {
                    this.result = [];
                    return this.milestonesService.search(term, this.apiFilter).pipe(
                        map((items: Milestone[]) => {
                            this.searching = false;
                            let itemResult = items;
                            console.log('milestonesService.search : ',items);
                            if(this.internalValue){
                                itemResult = itemResult.filter(m => m.id != this.internalValue?.id);
                            }


                            this.result = itemResult;
                            return itemResult;

                        }),
                        tap(() => {
                            this.searching = false;
                            this.searchFailed = false;
                        }),
                        catchError(() => {
                            this.searchFailed = true;
                            return of([]);
                        }));
                }
            ),
            tap(() => {
                this.searching = false;
                if(this.result.length == 0){
                    this.searchFailed = true;
                }
                this.cdr.detectChanges();
            })
        );

    };

    formatter = (x: Milestone) => x.name;

    addItem(c: Milestone) {
        this.itemAdded.emit(c);
        this._internalValue = c;
        this.onChange(this._internalValue);
        this.propagateChange(this._internalValue);
        this.model = null;
    }

    removeItem(c: Milestone) {
        this._internalValue = null;
        this.itemRemoved.emit(c);
        this.onChange(this._internalValue);
        this.propagateChange(this._internalValue);
    }

    public validate(c: UntypedFormControl): any {
        return null;
    }

    public reset() {
        if(this.internalValue && !this.multiple){
            this.removeItem(this.internalValue);
        }
    }
    resultTemplateType: {
        result: Milestone,
        term: string,
    }

}
