/**
 * Created by ModelParser
 */
import {Status} from '../Status';
import {ProjectTypesStatusRule} from '../ProjectTypesStatusRule';
import {TaskTypesStatusRule} from '../TaskTypesStatusRule';
import {MilestonesStatusRule} from '../MilestonesStatusRule';
import {Milestone} from '../Milestone';
import {MilestoneTemplatesStatusRule} from '../MilestoneTemplatesStatusRule';
import {MilestoneTemplate} from '../MilestoneTemplate';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class StatusRuleDefinition extends BaseModel {
    from_status_id?: number;
    from_status?: Status;
    to_status_id?: number;
    to_status?: Status;
    date_difference?: number;
    project_types_status_rule?: ProjectTypesStatusRule;
    task_types_status_rule?: TaskTypesStatusRule;
    type?: string;
    milestones_status_rule?: MilestonesStatusRule;
    milestone?: Milestone;
    milestone_templates_status_rule?: MilestoneTemplatesStatusRule;
    milestone_template?: MilestoneTemplate;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.from_status_id;
            delete this.from_status;
            delete this.to_status_id;
            delete this.to_status;
            delete this.date_difference;
            delete this.project_types_status_rule;
            delete this.task_types_status_rule;
            delete this.type;
            delete this.milestones_status_rule;
            delete this.milestone;
            delete this.milestone_templates_status_rule;
            delete this.milestone_template;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.from_status_id != null) {
            this.from_status_id = data.from_status_id;
        }
        if (data.from_status != null) {
            this.from_status = new Status(data.from_status);
        }
        if (data.to_status_id != null) {
            this.to_status_id = data.to_status_id;
        }
        if (data.to_status != null) {
            this.to_status = new Status(data.to_status);
        }
        if (data.date_difference != null) {
            this.date_difference = data.date_difference;
        }
        if (data.project_types_status_rule != null) {
            this.project_types_status_rule = new ProjectTypesStatusRule(data.project_types_status_rule);
        }
        if (data.task_types_status_rule != null) {
            this.task_types_status_rule = new TaskTypesStatusRule(data.task_types_status_rule);
        }
        if (data.type != null) {
            this.type = data.type;
        }
        if (data.milestones_status_rule != null) {
            this.milestones_status_rule = new MilestonesStatusRule(data.milestones_status_rule);
        }
        if (data.milestone != null) {
            this.milestone = new Milestone(data.milestone);
        }
        if (data.milestone_templates_status_rule != null) {
            this.milestone_templates_status_rule = new MilestoneTemplatesStatusRule(data.milestone_templates_status_rule);
        }
        if (data.milestone_template != null) {
            this.milestone_template = new MilestoneTemplate(data.milestone_template);
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
