import {Project} from '@app/core/models/Project';
import {User} from '@app/core/models/User';
import {ListConfiguration} from '@app/shared/_ui/lists/ListConfiguration';
import {MilestoneFetchRequest} from '@app/shared/_ui/lists/milestone-list/MilestoneFetcher';
import {Milestone} from '@app/core/models/Milestone';
import {CreateItemInterface} from '@app/shared/_ui/create-item-dropdown/CreateItemInterface';
import {CreateItemSourceConfiguration} from '@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration';
import {CreateItemPreset} from '@app/shared/_ui/create-item-dropdown/CreateItemPreset';
import {CardInterface, CardItem} from "@app/shared/_ui/cards/CardItem";
import {CdkDropEvent} from "@app/interfaces/CdkDropEvent";
import {Subscription} from "rxjs";
import {AppInjector} from "@app/services/app-injector.service";
import {EventService} from "@app/services/event.service";
import {CardMilestoneConfiguration} from "@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration";
import {Deadline, Department, Task, TasksDeadline} from "@app/core/models";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import * as moment from 'moment';
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {EventEmitter} from '@angular/core';
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";

export class MilestoneListConfiguration<T = Milestone> extends ListConfiguration<T> implements CreateItemInterface<Milestone>,
    CardInterface<Milestone>, ListDragInterface<T> {

    // Filter
    private project?: Project;
    private program?: Project;
    private responsible?: User;
    private deadlineDateMin?: Date;
    private deadlineDateMinOrNull: Date;
    private deadlineDateMax?: Date;
    private deadlineValidator: (deadline: Deadline) => boolean;
    private plannedValidatorDate: () => Date;
    private archived?: boolean;
    private avoidIds?: number[];
    private useGlobalFilter: boolean;
    private search: string;
    private projectTypeIds?: number[];
    private categoryIds?: number[];
    private cardConfiguration_excludedCategoryIds: number[];
    private department: Department;

    // Data Provider
    private dataSource: MilestoneFetchRequest;

    // Hooks
    private beforeAddHook: ((item: CardItem<Task>) => void);
    private customAllowCardItemDropFunction: (cardItem: CardItem<T>, fromListConfiguration: MilestoneListConfiguration<T>, result: (allow: boolean) => void) => void;
    private customAllowCardItemEnterFunction: (cardItem: CardItem<T>, fromListConfiguration: MilestoneListConfiguration<T>) => boolean;
    private customOnCardItemDragAddFunction: (cardItem: CardItem<Milestone>, configuration: MilestoneListConfiguration, previousIndex?: number, nextIndex?: number) => void;
    private customOnCardItemDragSavedFunction: () => void;
    private prepareCreateItemSourceHook: (createItemConfiguration: CreateItemSourceConfiguration) => void;

    // UI
    private cardIsMini = false;

    // Events
    public onItemAddedEvent = new EventEmitter<CardItem<Milestone>>();
    public onItemUpdatedEvent = new EventEmitter<CardItem<Milestone>>();
    public onItemRemovedEvent = new EventEmitter<CardItem<Milestone>>();

    // Limit & Order
    private orderBy: string[][] = [['deadline.id', 'null'], ['deadline.date', 'asc'], ['milestones_project.index_', 'asc']];

    // Drag Drop
    private enableDragDropAutoMove: boolean = false;
    public isSortingEnabled: boolean;

    // Show
    private showProjectMiniCard = false;
    private showPhases = false;
    private includeRelatedTasks = false;

    constructor() {
        super();

        this.modelClass = Milestone.name;

        this.createItemConfiguration = new CreateItemSourceConfiguration();
        this.createItemConfiguration.showTasks = false;
        this.createItemConfiguration.showProjects = false;
        this.createItemConfiguration.showMilestone = true;
        this.createItemConfiguration.showTodo = false;
        this.createItemConfiguration.showAppointments = false;
        this.createItemPreset = new CreateItemPreset();
        this.createItemPreset.createMilestoneInterface = this;
    }

    public getDataSource(): MilestoneFetchRequest {
        return this.dataSource;
    }

    public setDataSource(value: MilestoneFetchRequest): MilestoneListConfiguration<T> {
        this.dataSource = value;
        this.setupDataSource();
        return this;
    }

    public getOrderBy(): string[][] {
        return this.orderBy;
    }

    public setOrderBy(value: string[][]): MilestoneListConfiguration<T> {
        this.orderBy = value;
        return this;
    }

    public getLimit(): number {
        return super.getLimit();
    }

    public setLimit(value: number): MilestoneListConfiguration<T> {
        super.setLimit(value);
        return this;
    }

    public getCategoryIds(): number[] {
        return this.categoryIds;
    }

    public setCategoryIds(value: number[]): MilestoneListConfiguration<T> {
        this.categoryIds = value;
        return this;
    }

    public getCardDisplayModeMini(): boolean {
        return this.cardIsMini;
    }

    public setCardDisplayModeMini(cardIsMini: boolean): MilestoneListConfiguration<T> {
        this.cardIsMini = cardIsMini;
        return this;
    }

    public getCardConfiguration_ExcludedCategoryIds(): number[] {
        return this.cardConfiguration_excludedCategoryIds;
    }

    public setCardConfiguration_ExcludedCategoryIds(value: number[]): MilestoneListConfiguration<T> {
        this.cardConfiguration_excludedCategoryIds = value;
        return this;
    }

    public getDepartment(): Department {
        return this.department;
    }

    public setDepartment(value: Department): MilestoneListConfiguration<T> {
        this.department = value;
        return this;
    }

    public getArchived(): boolean {
        return this.archived;
    }

    public setArchived(value: boolean): MilestoneListConfiguration<T> {
        this.archived = value;
        return this;
    }

    public getPlannedValidatorDate(): () => Date {
        return this.plannedValidatorDate;
    }

    public setPlannedValidatorDate(value: () => Date): MilestoneListConfiguration<T> {
        this.plannedValidatorDate = value;
        return this;
    }

    public getDeadlineDateMin(): Date {
        return this.deadlineDateMin;
    }

    public setDeadlineDateMin(value: Date): MilestoneListConfiguration<T> {
        this.deadlineDateMin = value;
        return this;
    }

    public getDeadlineDateMinOrNull(): Date {
        return this.deadlineDateMinOrNull;
    }

    public setDeadlineDateMinOrNull(value: Date): MilestoneListConfiguration<T> {
        this.deadlineDateMinOrNull = value;
        return this;
    }

    public getDeadlineDateMax(): Date {
        return this.deadlineDateMax;
    }

    public setDeadlineDateMax(value: Date): MilestoneListConfiguration<T> {
        this.deadlineDateMax = value;
        return this;
    }

    public getResponsible(): User {
        return this.responsible;
    }

    public setResponsible(value: User): MilestoneListConfiguration<T> {
        this.responsible = value;
        return this;
    }

    public getProject(): Project {
        return this.project;
    }

    public setProject(value: Project): MilestoneListConfiguration<T> {
        this.project = value;
        return this;
    }

    public getProgram(): Project {
        return this.program;
    }

    public setProgram(value: Project): MilestoneListConfiguration<T> {
        this.program = value;
        return this;
    }

    public getIncludeRelatedTasks(): boolean {
        return this.includeRelatedTasks;
    }

    public setIncludeRelatedTasks(value: boolean): MilestoneListConfiguration<T> {
        this.includeRelatedTasks = value;
        return this;
    }

    public getAvoidIds(): number[] {
        return this.avoidIds;
    }

    public setAvoidIds(value: number[]): MilestoneListConfiguration<T> {
        this.avoidIds = value;
        return this;
    }

    public getProjectTypeIds(): number[] {
        return this.projectTypeIds;
    }

    public setProjectTypeIds(value: number[]): MilestoneListConfiguration<T> {
        this.projectTypeIds = value;
        return this;
    }

    public getUseGlobalFilter(): boolean {
        return this.useGlobalFilter;
    }

    public setUseGlobalFilter(value: boolean): MilestoneListConfiguration<T> {
        this.useGlobalFilter = value;
        return this;
    }

    public getSearch(): string {
        return this.search;
    }

    public setSearch(value: string): MilestoneListConfiguration<T> {
        this.search = value;
        return this;
    }

    public getShowProjectMiniCard(): boolean {
        return this.showProjectMiniCard;
    }

    public setShowProjectMiniCard(value: boolean): MilestoneListConfiguration<T> {
        this.showProjectMiniCard = value;
        return this;
    }

    public getShowPhases(): boolean {
        return this.showPhases;
    }

    public setShowPhases(value: boolean): MilestoneListConfiguration<T> {
        this.showPhases = value;
        return this;
    }

    public getDeadlineValidator(): (deadline: Deadline) => boolean {
        return this.deadlineValidator;
    }

    public setDeadlineValidator(value: ((deadline: Deadline) => boolean)): MilestoneListConfiguration<T> {
        this.deadlineValidator = value;
        return this;
    }

    public getCustomOnCardItemDragAddFunction(): ((cardItem: CardItem<Milestone>, fromConfiguration: MilestoneListConfiguration, previousIndex?: number, nextIndex?: number) => void) {
        return this.customOnCardItemDragAddFunction;
    }

    public setCustomOnCardItemDragAddFunction(value: ((cardItem: CardItem<Milestone>, fromConfiguration: MilestoneListConfiguration, previousIndex?: number, nextIndex?: number) => void)): MilestoneListConfiguration<T> {
        this.customOnCardItemDragAddFunction = value;
        return this;
    }

    public validate(milestone: Milestone): boolean {
        const debug = false;
        if (this.getProject()) {
            if (milestone.projects == null || milestone.projects.find(item => item.id == this.getProject().id) == null) {
                if (debug) console.warn('validateMilestone', 'project ik god nok');
                return false;
            }
        }
        if (this.getResponsible()) {
            if (milestone.responsible_id != this.getResponsible().id) {
                if (debug) console.warn('validateMilestone', 'getResponsible ik god nok');
                return false;
            }
        }
        if (this.getDeadlineDateMin()) {
            if (milestone.deadline == null || milestone.deadline.getDate().getTime() < this.getDeadlineDateMin().getTime()) {
                if (debug) {
                    console.warn('validateMilestone', 'getDeadlineDateMin ik god nok');
                }
                return false;
            }
        }
        if (this.getDeadlineDateMinOrNull()) {
            if (milestone.deadline != null && milestone.deadline.getDate().getTime() < this.getDeadlineDateMinOrNull().getTime()) {
                if (debug) console.warn('validateMilestone', 'getDeadlineDateMin ik god nok');
                return false;
            }
        }
        if (this.getDeadlineDateMax()) {
            if (milestone.deadline == null || milestone.deadline.getDate().getTime() > this.getDeadlineDateMax().getTime()) {
                if (debug) {
                    console.warn('validateMilestone', 'getDeadlineDateMax ik god nok');
                }
                return false;
            }
        }
        if (this.getArchived() != null) {
            if (this.getArchived() && milestone.archived_id == 0) {
                if (debug) console.warn('validateMilestone', 'getArchived ik god nok');
                return false;
            } else if (!this.getArchived() && milestone.archived_id > 0) {
                if (debug) console.warn('validateMilestone', 'getArchived ik god nok');
                return false;
            }
        }
        if (this.getAvoidIds()) {
            if (this.getAvoidIds().includes(milestone.id)) {
                if (debug) console.warn('validateMilestone', 'getAvoidIds ik god nok');
                return false;
            }
        }
        if (this.getProjectTypeIds()) {
            const projectTypeIds = this.getProjectTypeIds();
            const allowNull = projectTypeIds.includes(null);
            const nullOkay = allowNull && (milestone.projects?.length ?? 0) == 0;
            const projectFound = milestone.projects?.find(project => projectTypeIds.includes(project.project_type_id));
            if (!nullOkay && !projectFound) {
                if (debug) {
                    console.warn('ignored, cause of projectTypeIds', projectTypeIds);
                }
                return false;
            }
        }
        if (this.getCategoryIds()) {
            const categoryIds = this.getCategoryIds();
            if (milestone.categories?.find(category => categoryIds.includes(category.id)) === undefined) {
                if (debug) {
                    console.warn('ignored, cause of category ids', milestone.categories, categoryIds);
                }
                return false;
            }
        }
        if (this.getDeadlineValidator()) {
            if (milestone.deadline == null || !this.getDeadlineValidator()(milestone.deadline)) {
                if (debug) {
                    console.warn('ignored, cause of deadline validator');
                }
                return false;
            }
        }
        return true;
    }

    // <editor-fold desc="Create Item">

    public createPresets(typeId?: number): CreatePreset[] {
        return this.createPresetGenerators?.map(generator => generator.generate(typeId)) ?? [];
    }

    // </editor-fold>

    // <editor-fold desc="Data Listeners">

    protected setupDataSource() {
        if (this.getDataSource()) {
            this.getDataSource().subscribeToItems((milestones: Milestone[]) => {
                const items = milestones.map(milestone => this.createCard(milestone));
                this.onAddItemsEvent.emit({items: items});
                this.setLastItemCount(items.length);
            });
            this.getDataSource().subscribeToCount((count: number) => {
                this.onCountEvent.emit({count: count});
            });
        }
    }

    private createCard(item: Milestone): CardItem<Milestone> {
        const config = new CardMilestoneConfiguration(this.configuration.getShowProjectMiniCard(), this.configuration.getShowPhases());
        config.isDraggable = this.isDraggable();
        config.useGlobalFilter = true;
        return new CardItem<Milestone>(item, config, this);
    }

    // </editor-fold>

    // <editor-fold desc="Push">

    private pushSubscription: Subscription;

    public pushSubscribe() {
        this.pushSubscription = new Subscription();
        this.pushSubscription.add(AppInjector.getInjector().get(EventService).subscribeToMilestone(0, event => {
            switch (event.action) {
                case EventService.Created:
                    if (this.validate(event.item)) {
                        const card = this.createCard(event.item);
                        this.onAddItemEvent.emit({item: card});
                        this.onItemAddedEvent.emit(card);
                    }
                    break;
                case EventService.Updated:
                    const itemsToUpdate = this.dataSource?.getItemsById(event.item.id);
                    if (itemsToUpdate?.length > 0) {
                        itemsToUpdate.forEach(item => {
                            item.populate({...event.item}, event.isPatch);
                            const card = this.createCard(item);
                            if (!this.validate(item)) {
                                this.onRemoveItemEvent.emit({item: card});
                                this.onItemRemovedEvent.emit(card);
                            } else {
                                this.onItemUpdatedEvent.emit(card);
                            }
                        });
                    } else if (this.validate(event.item)) {
                        const card = this.createCard(event.item);
                        this.onAddItemEvent.emit({item: card});
                        this.onItemAddedEvent.emit(card);
                    }
                    break;
                case EventService.Deleted:
                    const card = this.createCard(event.item);
                    this.onRemoveItemEvent.emit({item: card});
                    this.onItemRemovedEvent.emit(card);
                    break;
            }
        }));
    }

    public pushUnsubscribe() {
        this.pushSubscription?.unsubscribe();
    }

    // </editor-fold>

    // <editor-fold desc="Card Interface">

    public onCardItemDragged(cardItem: CardItem<Milestone>, listConfiguration: ListConfiguration, toContainerId: string, fromContainerId: string, cdkDropEvent: CdkDropEvent<Milestone>): void {

    }

    public onCardItemUpdated(cardItem: CardItem<Milestone>): void {
        if (!this.validate(cardItem.item)) {
            this.onRemoveItemEvent.emit({item: cardItem});
        }
    }

    public onCardItemDeleted(cardItem: CardItem<Milestone>): void {

    }

    // </editor-fold>

    // <editor-fold desc="ListDragInterface">

    public getCustomAllowCardItemDropFunction(): ((cardItem: CardItem<T>, fromListConfiguration: MilestoneListConfiguration<T>, result: (allow: boolean) => void) => void) {
        return this.customAllowCardItemDropFunction;
    }

    public setCustomAllowCardItemDropFunction(value: ((cardItem: CardItem<T>, fromListConfiguration: MilestoneListConfiguration<T>, result: (allow: boolean) => void) => void)): MilestoneListConfiguration<T> {
        this.customAllowCardItemDropFunction = value;
        return this;
    }

    public getCustomAllowCardItemEnterFunction(): ((cardItem: CardItem<T>, fromListConfiguration: MilestoneListConfiguration<T>) => boolean) {
        return this.customAllowCardItemEnterFunction;
    }

    public setCustomAllowCardItemEnterFunction(value: ((cardItem: CardItem<T>, fromListConfiguration: MilestoneListConfiguration<T>) => boolean)): MilestoneListConfiguration<T> {
        this.customAllowCardItemEnterFunction = value;
        return this;
    }

    public getCustomOnCardDragSavedFunction(): () => void {
        return this.customOnCardItemDragSavedFunction;
    }

    public setCustomOnCardDragSavedFunction(value: () => void): MilestoneListConfiguration<T> {
        this.customOnCardItemDragSavedFunction = value;
        return this;
    }


    public allowCardItemEnter(cardItem: CardItem<T>, fromListConfiguration: MilestoneListConfiguration<T>): boolean {
        if (!(cardItem.item instanceof Milestone)) {
            return false;
        }
        if (this.getCustomAllowCardItemEnterFunction()) {
            return this.getCustomAllowCardItemEnterFunction()(cardItem, fromListConfiguration);
        } else {
            return super.allowCardItemEnter(cardItem, fromListConfiguration);
        }
    }

    public allowCardItemDrop(cardItem: CardItem<T>, fromListConfiguration: MilestoneListConfiguration<T>, result: (allow: boolean) => void) {
        if (!(cardItem.item instanceof Milestone)) {
            result(false);
            return;
        }
        if (this.getCustomAllowCardItemDropFunction()) {
            this.getCustomAllowCardItemDropFunction()(cardItem, fromListConfiguration, result);
        } else {
            result(true);
        }
    }

    public defaultDragAndDropFunction(cardItem: CardItem<Milestone>, fromListConfiguration: MilestoneListConfiguration,
                                      callback: () => void, previousIndex?: number, nextIndex?: number) {

        const cardMilestone = cardItem as unknown as CardItem<Milestone>;
        const milestone = cardMilestone.item as Milestone;

        if (this.getPlannedValidatorDate()) {
            const date = this.getPlannedValidatorDate()();
            const milestoneDate = moment(milestone.deadline?.date).toDate();
            let days: number = date ? moment(date).diff(moment(milestoneDate), 'days') : null;
            if (date && !isNaN(days) && days != 0) {
                // Udlæs "følgende milepæle" - og spørg om de også skal skifte dato
                AppInjector.getInjector().get(BaseDialogService).moveMilestoneDeadline(
                    AppInjector.getInjector().get(TranslateService).instant(`_milestone_move_deadlines`),
                    ``,
                    AppInjector.getInjector().get(TranslateService).instant('_global_ok'),
                    AppInjector.getInjector().get(TranslateService).instant('_global_cancel'),
                    'lg',
                    milestone,
                    days
                )
                    .then((confirmed) => {
                        // Sætter deadline for alle opgaver (sættes i move-milestone-deadline-dialog.component.ts)
                        if (confirmed) {
                        }
                    })
                    .catch(() => {
                        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
                    });
            }

            milestone.setDeadline(0, Deadline.Create(date));
        }

        if (this.getResponsible()) {
            milestone.setResponsible(this.getResponsible());
        }
    }

    public onCardItemDragAdd(cardItem: CardItem<T>, fromListConfiguration: MilestoneListConfiguration<T>,
                             callback: () => void, previousIndex?: number, nextIndex?: number) {
        if (!(cardItem.item instanceof Milestone)) {
            return;
        }

        const card = cardItem as unknown as CardItem<Milestone>;
        const configuration = fromListConfiguration as unknown as MilestoneListConfiguration;

        if (this.getCustomOnCardItemDragAddFunction()) {
            this.getCustomOnCardItemDragAddFunction()(card, configuration, previousIndex, nextIndex);
        } else {
            this.defaultDragAndDropFunction(card, configuration, callback, nextIndex);
        }

        this.onAddItemEvent.emit({item: this.createCard(card.item), index: nextIndex});
        callback();
    }

    public onCardItemDragRemove(cardItem: CardItem<T>) {
        const card = cardItem as unknown as CardItem<Milestone>;
        this.onRemoveItemEvent.emit({item: card});
    }

    public onCardItemDragSaved() {
        if (this.getCustomOnCardDragSavedFunction()) {
            this.getCustomOnCardDragSavedFunction()();
        }
    }

    // </editor-fold>

}
