<div class="create-item-wrapper p-0"
     [container]="container"
     ngbDropdown
     [dropdownClass]="'create-item-dropdown'"
     #dropdown
     [autoClose]="false"
     placement="{{placement}}"
     display="dynamic">


    <a class="no-caret pt-0 pb-0"
       ngbDropdownToggle
       *ngIf="dropdown && !buttonMode"
       (click)="onOpenEvent($event)"
       container="body"
       [class.disabled]="disabled"
       [ngbTooltip]="label"
       [placement]="['bottom', 'auto']">
        <i class="{{iconClass}}" *ngIf="!(typesLoading && typesLoading > 0)"></i>

        <app-loading-indicator [showLogo]="false" class="me-1"
                               *ngIf="typesLoading && typesLoading > 0"></app-loading-indicator>
    </a>

    <a class="no-caret pt-0 pb-0 btn btn-outline-dark w-100 d-flex align-items-center btn-sm "
       ngbDropdownToggle
       *ngIf="dropdown && buttonMode"
       (click)="onOpenEvent($event)"
       container="body"
       [class.disabled]="disabled"
       [ngbTooltip]="label"
       [placement]="['bottom', 'auto']">
        <i class="{{iconClass}} me-1" *ngIf="!(typesLoading && typesLoading > 0)"></i>
        <app-loading-indicator [showLogo]="false" class="me-1"
                               *ngIf="typesLoading && typesLoading > 0"></app-loading-indicator>
        <span>{{label}}</span>
    </a>

    <div ngbDropdownMenu

         class="dropdown-wrapper shadow-sm mobile-fullscreen border-none"
         style="overflow: visible;"
         [class.d-none]="!enableDropdown"
         [attr.data-tasktypes]="taskTypes ? taskTypes.length : 0 "
         [attr.data-projecttypes]="projectTypes ? projectTypes.length : 0"
         [attr.data-milestones]="department?.milestones_visible && source && source.showMilestone ? 1 : 0"
         [attr.data-todos]="department?.todos_visible && source && source.showTodo ? 1 : 0"
    >
        <span class="close-button" (click)="closeMenu()"><span class="fa fa-times"></span></span>

        <div class="d-flex flex-column"
             *ngIf="enableDropdown && (taskTypes || projectTypes || (source.showMilestone && department?.milestones_visible) || (department?.todos_visible && source.showTodo) )">
            <h5 class="w-100 p-2 ps-3 pe-3 bg-dark m-0 border-bottom text-small text-light"
                style="border-radius: 0.25rem 0.25rem 0 0;">{{('_global_create') | translate}} ...</h5>


            <div class="scroll-y ">
                <ul class="list-unstyled menu-list mb-0">
                    <ng-container *ngFor="let type of taskTypes;">
                        <li>
                            <!-- https://podio.com/klartboard/softwareudvikling/apps/stories/items/388 -->
                            <app-dropdown-list-item (click)="createItem(Globals.ItemTypes.Task, type.id)"
                                                    [iconClass]="'fa-edit'"
                                                    [badgeLabel]="type?.definition"
                                                    [label]="type.name ? type.name : ('_task_type_' + type?.id | translate)"></app-dropdown-list-item>
                        </li>
                    </ng-container>
                    <li *ngIf="taskTypes">
                        <hr/>
                    </li>

                    <ng-container *ngIf="projectTypes && projectTypesFieldsMap.size > 0">
                        <ng-container *ngFor="let type of projectTypes">
                            <li *ngIf="type && !projectTypeFieldVisible(type, 52) || isMobile">
                                <app-dropdown-list-item

                                    (click)="createItem(Globals.ItemTypes.Project, type.id)"
                                    [iconClass]="'fa-edit'"
                                    [badgeLabel]="type?.definition"
                                    [label]="type.name ? type.name : ('_project_type_' + type?.id | translate)"></app-dropdown-list-item>
                            </li>

                            <li *ngIf=" type && projectTypeFieldVisible(type, 52) &&  !isMobile">
                                <!-- Opret igennem : https://podio.com/klartboard/softwareudvikling/apps/stories/items/1164 + https://podio.com/klartboard/softwareudvikling/apps/stories/items/1244 -->


                                <app-dropdown-list-item
                                    [ngbPopover]="popoverContent"
                                    [popoverClass]="'popover-menu'"
                                    [autoClose]="true"
                                    [placement]="['left', 'right','bottom', 'auto']"
                                    triggers="manual"
                                    container="body"
                                    #popover="ngbPopover"
                                    (click)="$event.stopImmediatePropagation(); !popover.isOpen() ? popover.open() : popover.close()"

                                    class="position-relative"
                                    [iconClass]="'fa-ellipsis-v'"
                                    [badgeLabel]="type?.definition"
                                    [label]="type.name ? type.name : ('_project_type_' + type?.id | translate)"></app-dropdown-list-item>


                                <ng-template #popoverContent>
                                    <ul class="list-unstyled m-0 p-0 menu-list dropdown-wrapper"
                                        [class.d-none]="!popover.isOpen()">
                                        <li
                                            class="dropdown-item"
                                            (click)="popover.close(); createItem(Globals.ItemTypes.Project, type.id)">
                                            <div class=" cursor-pointer d-flex align-items-center app-toggle-item ">
                                                <i class="fal fa-edit me-1"></i>
                                                <span>{{'_global_create' | translate}}...</span>
                                            </div>
                                        </li>
                                        <li *ngIf="false"
                                            class="dropdown-item"
                                            (click)="popover.close(); createItem(Globals.ItemTypes.Project, type.id, 'template')">
                                            <div class=" cursor-pointer d-flex align-items-center app-toggle-item">
                                                <i class="fal fa-pencil-ruler me-1"></i>
                                                <span>{{'_ui_create_project_dialog_template' | translate}}...</span>
                                            </div>
                                        </li>

                                        <li *ngIf="projectTypeFieldVisible(type, 52)"
                                            class="dropdown-item"

                                            (click)="popover.close(); createItem(Globals.ItemTypes.Project, type.id, 'planning')">
                                            <div class=" cursor-pointer d-flex align-items-center app-toggle-item">
                                                <i class="fal fa-door-open me-1"></i>
                                                <span>{{'_ui_create_project_dialog_planning' | translate}}...</span>
                                            </div>
                                        </li>
                                    </ul>
                                </ng-template>

                            </li>
                        </ng-container>
                    </ng-container>

                    <li *ngIf="projectTypes && ((department?.milestones_visible && source.showMilestone) || (department?.todos_visible && source.showTodo))">
                        <hr/>
                    </li>

                    <li *ngIf="department?.milestones_visible && source.showMilestone">
                        <app-dropdown-list-item

                            (click)="createItem(Globals.ItemTypes.Milestone)"
                            [iconClass]="'fa-edit'"
                            [badgeLabel]="'M'"
                            [label]="('_milestone' | translate)"></app-dropdown-list-item>
                    </li>

                    <li *ngIf="department?.todos_visible && source.showTodo">
                        <hr/>
                    </li>

                    <li *ngIf="department?.todos_visible && source.showTodo">
                        <app-dropdown-list-item
                            (click)="createItem(Globals.ItemTypes.Todo)"
                            [iconClass]="'fa-edit'"
                            [badgeLabel]="'T'"
                            [label]="('_todo' | translate)"></app-dropdown-list-item>
                    </li>

                </ul>
            </div>

        </div>
    </div>
</div>
