import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {TodoListConfiguration} from "@app/shared/_ui/lists/todo-list/TodoListConfiguration";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import * as moment from "moment/moment";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {
    TodoUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoUserPresetGenerator";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";
import {
    TodoProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoProjectPresetGenerator";
import {
    TodoDisplayPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoDisplayPresetGenerator";
import {ShellService} from "@app/services/ShellService/shell.service";
import {
    TodoShowOnDisplayPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoShowOnDisplayPresetGenerator";

export class TodoListCell extends BaseCell {

    public listConfiguration = new TodoListConfiguration();

    public constructor(row: Row, column: BaseColumn) {
        super(row, column);

        this.listConfiguration
            .setProject(row.projectCard.item)
            .setLimit(2)
            .setArchived(false)
            .setOrArchivedSince(moment(new Date()).subtract(14, 'days').startOf('day').toDate())
            .setShowProjectMiniCard(false)
            .setShowOnDisplay(true)
            .setHasDisplay(true)
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                // Defaults
                new TodoUserPresetGenerator(AppInjector.getInjector().get(UsersService).user.id),

                new TodoProjectPresetGenerator(row.projectCard.item.id),
                new TodoDisplayPresetGenerator(AppInjector.getInjector().get(ShellService).getPageSettings().primaryDisplayId),
                new TodoShowOnDisplayPresetGenerator(true),
            ]);
    }

}
