import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ProjectsComponent} from "@app/pages/lists/projects/projects.component";
import {FormsModule} from "@angular/forms";
import {ProjectListModule} from "@app/shared/_ui/lists/project-list/ProjectList.module";
import {LocalizedDatePipeModule} from '@app/pipes/LocalizedDatePipe.module';
import {UserWithIconModule} from '@app/shared/_elements/user-with-icon/UserWithIcon.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgxDatatableModule,
        FormsModule,
        ProjectListModule,
        LocalizedDatePipeModule,
        UserWithIconModule
    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectsComponent
    ],
    exports: [

    ],
    providers: [

    ]
})
export class ProjectsModule {
}
