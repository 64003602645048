import {NgModule} from "@angular/core";
import {CaseUserExpanderComponent} from "@app/shared/_ui/displays/case-user-expander/case-user-expander.component";
import {UserSearchModule} from "@app/shared/_forms/search/user-search/UserSearch.module";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {NumericSpinnerModule} from "@app/shared/_elements/numeric-spinner/NumericSpinner.module";
import {TranslateModule} from "@ngx-translate/core";
import {ValueEvaluatorModule} from "@app/shared/_elements/value-evaluator/ValueEvaluator.module";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        UserSearchModule,
        UserWithIconModule,
        NumericSpinnerModule,
        TranslateModule,
        ValueEvaluatorModule,
        NgbTooltipModule,
        NgbPopoverModule,
        CardsModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CaseUserExpanderComponent,
    ],
    exports: [
        CaseUserExpanderComponent

    ],
    providers: [

    ],
})
export class CaseUserExpanderModule {}
