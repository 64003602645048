<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title" [innerHTML]="title"></h4>

        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="onCloseBtnClicked();"></app-color-item>
    </div>
    <div class="modal-body" [innerHTML]="message" *ngIf="message"></div>
    <div class="container">

        <div class="w-100 d-flex justify-content-center  align-items-center" style="height: 200px;" *ngIf="isLoading">
            <app-loading-indicator [showLogo]="false" class="text-large "></app-loading-indicator>
        </div>
        <ng-container *ngIf="!isLoading">
            <ul class="list-unstyled list-group mb-3 mt-1">

                <li (click)="onToggleAllBtnClicked()" class="cursor-pointer">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <i class="fa fa-check-circle text-success" *ngIf="isAllSelected"></i>
                        <i class="fal fa-circle text-black-50" *ngIf="!isAllSelected"></i>
                        <span class="ms-1">
                            <span [innerHTML]="(isAllSelected ? '_ui_deselect_all' : '_ui_select_all') | translate"></span>
                        </span>
                    </div>
                </li>

                <li
                    *ngFor="let user of users"
                    (click)="user.isSelected = !user.isSelected" class="cursor-pointer"
                >
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <i class="fa fa-check-circle text-success" *ngIf="user.isSelected"></i>
                        <i class="fal fa-circle text-black-50" *ngIf="!user.isSelected"></i>
                        <span class="ms-1">
                            <span>{{ user.user.name }}</span>
                        </span>
                    </div>
                </li>

            </ul>
        </ng-container>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary ellipsis"
                (click)="onCloseBtnClicked()"
                [disabled]="isLoading">{{ '_global_cancel' | translate }}
        </button>
        <button type="button" class="btn btn-success ellipsis" ngbAutofocus (click)="onSaveBtnClicked()"
                [disabled]="isLoading">{{ '_global_ok' | translate }}</button>
    </div>
</div>
