import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {TableColumns} from "@app/pages/displays/display-projects/TableColumns";
import {ProjectCategoryListColumn} from "@app/pages/displays/display-projects/Columns/ProjectCategoryListColumn";

export class ProjectCategoryListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.ProjectCategoryList;
    public column: ProjectCategoryListColumn;

}
