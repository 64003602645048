/**
 * Created by ModelParser
 */
import {Display} from '../Display';
import {Setting} from '../Setting';
import {Department} from '../Department';
import {User} from '../User';
import {ProjectStatusType} from '../ProjectStatusType';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class DisplaysSettingDefinition extends BaseModel {
    display_id?: number;
    display?: Display;
    setting_id?: number;
    setting?: Setting;
    value?: string;
    name?: string;
    index_?: number;
    department_id?: number;
    department?: Department;
    user_id?: number;
    user?: User;
    width?: number;
    is_shown_as_default?: boolean;
    project_status_type?: ProjectStatusType;
    selected?: boolean;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.display_id;
            delete this.display;
            delete this.setting_id;
            delete this.setting;
            delete this.value;
            delete this.name;
            delete this.index_;
            delete this.department_id;
            delete this.department;
            delete this.user_id;
            delete this.user;
            delete this.width;
            delete this.is_shown_as_default;
            delete this.project_status_type;
            delete this.selected;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.display_id != null) {
            this.display_id = data.display_id;
        }
        if (data.display != null) {
            this.display = new Display(data.display);
        }
        if (data.setting_id != null) {
            this.setting_id = data.setting_id;
        }
        if (data.setting != null) {
            this.setting = new Setting(data.setting);
        }
        if (data.value != null) {
            this.value = data.value;
        }
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.index_ != null) {
            this.index_ = data.index_;
        }
        if (data.department_id != null) {
            this.department_id = data.department_id;
        }
        if (data.department != null) {
            this.department = new Department(data.department);
        }
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.width != null) {
            this.width = data.width;
        }
        if (data.is_shown_as_default != null) {
            this.is_shown_as_default = data.is_shown_as_default;
        }
        if (data.project_status_type != null) {
            this.project_status_type = new ProjectStatusType(data.project_status_type);
        }
        if (data.selected != null) {
            this.selected = data.selected;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
