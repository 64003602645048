import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Project} from '@app/core/models/Project';
import {Milestone} from '@app/core/models/Milestone';
import {Task} from "@app/core/models";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {
    AttachedProjectType
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-tabs/task-editor-tab-attach-to/attached-project-type/AttachedProjectType";

@Component({
    selector: 'app-attached-project-type',
    templateUrl: './attached-project-type.component.html',
    styleUrls: ['./attached-project-type.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachedProjectTypeComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() task: Task;
    @Input() attachedProjectType: AttachedProjectType;
    @Input() showProjectField = true;
    @Input() showMilestoneField = true;

    // Bindings to view
    public showMilestoneFieldForProject: boolean = true;
    public hasProject = false;

    constructor() {
        super();
    }

    ngOnInit() {
        this.render();
    }

    public render() {
        this.hasProject = this.attachedProjectType.project !== undefined;

        this.attachedProjectType.validator.validate(this.attachedProjectType);
    }

    public setProject(project?: Project) {
        if (project) {
            this.task.addProject(project);
        } else {
            if (this.attachedProjectType.milestone) {
                this.setMilestone();
            }
            this.task.removeProject(this.attachedProjectType.project);
        }
        this.attachedProjectType.project = project;

        this.render();
    }

    public setMilestone(milestone?: Milestone) {
        if (milestone) {
            this.task.addMilestone(milestone);
        } else {
            this.task.removeMilestone(this.attachedProjectType.milestone);
        }
        this.attachedProjectType.milestone = milestone;

        this.render();
    }

}
