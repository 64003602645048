import {
    animate,
    animateChild,
    group,
    keyframes,
    query,
    stagger,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';

// Transition examples: https://angular.io/api/animations/transition#syntax-examples
// Animations : https://medium.com/@aleixsuau/angular-animations-bring-life-to-your-apps-ca122e5db647

export const fadeAnimation =
    trigger('fadeAnimation', [
        // the "in" style determines the "resting" state of the element when it is visible.
        state('in', style({opacity: 1})),
        // fade in when created. this could also be written as transition('void => *')
        transition(':enter', [
            style({opacity: 0}),
            animate(250)
        ]),
        // fade out when destroyed. this could also be written as transition('void => *')
        transition(':leave',
            animate(150, style({opacity: 0})))
    ]);

export const fadeInPlaceAnimation =
    trigger('fadeInPlaceAnimation', [
        // the "in" style determines the "resting" state of the element when it is visible.
        state('in', style({opacity: 1, position:'relative'})),
        // fade in when created. this could also be written as transition('void => *')
        transition(':enter', [
            style({opacity: 0, position:'absolute'}),
            animate(250)
        ]),
        // fade out when destroyed. this could also be written as transition('void => *')
        transition(':leave',
            animate(150, style({opacity: 0, position:'absolute'})))
    ]);

export const slideDownAnimation =
    trigger('slideDownAnimation', [
        // the "in" style determines the "resting" state of the element when it is visible.
        state('in', style({opacity: 1, transformOrigin: 'top center'})),
        // fade in when created. this could also be written as transition('void => *')
        transition(':enter', [
            style({opacity: 0, transform: 'translateY(-5px)'}),
            animate(150)
        ]),
        // fade out when destroyed. this could also be written as transition('void => *')
        transition(':leave',
            animate(150, style({opacity: 0, transform: 'scaleY(0)'})))
    ]);

export const listAnimation = trigger('listAnimation', [
    transition('* => *', [

        query(':enter', style({ opacity: 0 }), {optional: true}),

        query(':enter', stagger('200ms', [
            animate('.3s ease-in', keyframes([
                style({opacity: 0, transform: 'translateY(-25%)', offset: 0}),
                //style({opacity: .5, transform: 'translateY(15px)',  offset: 0.3}),
                style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
            ]))]), {optional: true})
    ])
]);

export const slideInAnimation =
    trigger('routeAnimations', [
        state('void', style({ opacity: 0, })),
        state('*', style({ opacity: 1, })),
        transition('* <=> *', [
            style({position: 'relative'}),
            query(':enter, :leave', [
                style({
                    transform: 'translateX(0%)',
                })
            ], { optional: true }),
            query(':enter', [
                animate('1500ms ease-out',
                    style({
                        transform: 'translateX(0%)'
                    })
                )
            ], { optional: true }),
            query(':leave', animateChild(), {optional: true}),
            group([
                query(':leave', [
                    animate('500ms ease-out', style({
                        transform: 'translateX(0%)',
                    }))
                ], {optional: true}),
                query(':enter', [
                    animate('500ms ease-out', style({
                        transform: 'translateX(0%)',
                    }))
                ], { optional: true })
            ]),
            query(':enter', animateChild(), { optional: true }),

        ]),

        /*
        transition('Login <=> *', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    position: 'fixed', width:'100%'
                })
            ]),
            query(':enter', [
                style({ transform: 'translateY(100%)' })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('500ms ease-out', style({ transform: 'translateY(-100%)' }))
                ]),
                query(':enter', [
                    animate('500ms ease-out', style({ transform: 'translateY(0%)' }))
                ])
            ]),
            query(':enter', animateChild()),
        ]),
        transition('Login => Page', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    position: 'fixed', width:'100%'
                })
            ]),
            query(':enter', [
                style({ transform: 'translateY(100%)' })
            ]),
            query(':leave', animateChild()),
            group([
                query(':leave', [
                    animate('500ms ease-out', style({ transform: 'translateY(-100%)' }))
                ]),
                query(':enter', [
                    animate('500ms ease-out', style({ transform: 'translateY(0%)' }))
                ])
            ]),
            query(':enter', animateChild()),
        ])
        */

    ]);

export function fadeIn(selector = ':enter', duration = '400ms ease-out') {
    return [
        transition('* => *', [
            query(selector, [
                style({opacity: 0, transform: 'translateY(-5px)'}),
                stagger('500ms', [
                    animate(duration, style({
                        opacity: 1,
                        transform: 'translateY(0px)'
                    }))
                ])
            ], {optional: true})
        ])
    ];
}

export function fadeOut(selector = ':enter', duration = '400ms ease-out') {
    return [
        transition('* => *', [
            query(selector, [
                style({opacity: 0, transform: 'translateY(0px)'}),
                stagger('500ms', [
                    animate(duration, style({
                        opacity: 0,
                        transform: 'translateY(0px)'
                    }))
                ])
            ], {optional: true})
        ])
    ];
}