import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";
import {CSVExporter} from "@app/export/csv/CSVExporter";

export class NextMilestoneCardCell extends GenericCell {

    public exportCSV(secondary?: any): CSVCellValue {
        switch (secondary) {
            case 'name':
                return this.row.nextMilestone?.item?.title ?? '';
            case 'deadline':
                return this.row.nextMilestone?.item?.deadline
                    ? CSVExporter.convertToDate(this.row.nextMilestone.item.deadline.getDate())
                    : '';
        }
    }

}
