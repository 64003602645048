/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ProjectDeadlineTypeDefinition,} from './definitions/ProjectDeadlineTypeDefinition';
import {TranslateService} from '@ngx-translate/core';
import {AppInjector} from '@app/services/app-injector.service';
import {HasSmartName} from "@app/interfaces/HasSmartName";

export class ProjectDeadlineType extends ProjectDeadlineTypeDefinition implements HasSmartName {

    // OTF
    required?: boolean;
    visible?: boolean;
    index_?: number;

    constructor(json?: any) {
        super(json);
    }

    public getSmartName(): string {
        if(this.name) return this.name;
        else return AppInjector.getInjector().get(TranslateService).instant(this.language_key);
    }

}
