import {NgModule} from "@angular/core";
import {ProjectPhaseComponent} from "@app/shared/_elements/process-steps/project-phase/project-phase.component";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {PhaseModule} from "@app/shared/_elements/phase/Phase.module";
import {ItemDateModule} from "@app/shared/_elements/item-date/ItemDate.module";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {SmallCardMilestoneModule} from "@app/shared/_ui/cards/small/small-card-milestone/SmallCardMilestone.module";
import {CommonModule} from "@angular/common";
import {CreateItemDropdownModule} from "@app/shared/_ui/create-item-dropdown/CreateItemDropdown.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbTooltipModule,
        PhaseModule,
        ItemDateModule,
        ToggleItemModule,
        UserWithIconModule,
        SmallCardMilestoneModule,
        CreateItemDropdownModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectPhaseComponent,
    ],
    exports: [
        ProjectPhaseComponent

    ],
    providers: [

    ],
})
export class ProjectPhaseModule {}
