import {NgModule} from "@angular/core";
import {
    DisplayProjectDetailsUsersComponent
} from "@app/pages/displays/display-project-details/subdisplay-users/display-project-details-users.component";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {TranslateModule} from "@ngx-translate/core";
import {OnScreenFilterSelectorModule} from "@app/shared/_ui/on-screen-filter-selector/OnScreenFilterSelector.module";
import {ColumnSorterModule} from "@app/shared/_ui/column-sorter/ColumnSorter.module";
import {
    ProjectParticipantAddPopoverModule
} from "@app/pages/displays/display-project-details/components/project-participant-add-popover/ProjectParticipantAddPopover.module";
import {
    CardVerticalEditColumnsModule
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/card-vertical-edit-columns/CardVerticalEditColumns.module";
import {TaskListModule} from "@app/shared/_ui/lists/task-list/TaskList.module";
import {
    MilestoneTaskEditWhoSelectorPopoverModule
} from "@app/pages/displays/display-project-details/components/milestone-task-edit-who-selector-popover/MilestoneTaskEditWhoSelectorPopover.module";
import {
    WarningLabelModule
} from "@app/pages/displays/display-project-details/components/warning-label/WarningLabel.module";
import {MilestoneListModule} from "@app/shared/_ui/lists/milestone-list/MilestoneList.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";
import {NgxDatatableExtensionModule} from "@app/directives/NgxDatatableExtension.module";

@NgModule({
    imports: [
        CommonModule,
        NgxDatatableModule,
        NgbTooltipModule,
        LoadingIndicatorModule,
        TranslateModule,
        OnScreenFilterSelectorModule,
        ColumnSorterModule,
        ProjectParticipantAddPopoverModule,
        CardVerticalEditColumnsModule,
        CardsModule,
        TaskListModule,
        MilestoneTaskEditWhoSelectorPopoverModule,
        WarningLabelModule,
        MilestoneListModule,
        TypedTemplateDirective,
        NgxDatatableExtensionModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DisplayProjectDetailsUsersComponent,
    ],
    exports: [
        DisplayProjectDetailsUsersComponent

    ],
    providers: [

    ],
})
export class DisplayProjectDetailsUsersModule {}
