import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges
} from '@angular/core';
import Globals from '@app/constants';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {HasTypeProperties} from '@app/interfaces/HasTypeProperties';
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-color-label',
    templateUrl: './color-label.component.html',
    styleUrls: ['./color-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorLabelComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() tooltip: string;
    @Input() showTooltip = true;
    @Input() status: number;
    @Input() isNew = false;
    @Input() interactive = true;
    @Input() label: string;
    @Input() icon: string;
    @Input() emoji = false;
    @Input() items?: { id: number }[];
    @Input() itemWithTypeProperties?: HasTypeProperties;

    @Output() onStatusChange = new EventEmitter<number>();
    public staticEmojiIcon: string;
    public staticClassName: string;
    public displayName = '';
    public statusTypes: { id: number; active: boolean; className: string }[] = [
        {
            id: Globals.StatusTypes.GREEN,
            active: false,
            className: Globals.StatusTypeClasses[Globals.StatusTypes.GREEN]
        },
        {
            id: Globals.StatusTypes.YELLOW,
            active: false,
            className: Globals.StatusTypeClasses[Globals.StatusTypes.YELLOW]
        },
        {
            id: Globals.StatusTypes.RED,
            active: false,
            className: Globals.StatusTypeClasses[Globals.StatusTypes.RED]
        },
        {
            id: Globals.StatusTypes.GREY,
            active: false,
            className: Globals.StatusTypeClasses[Globals.StatusTypes.GREY]
        },
    ];

    private index = 0;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        if (this.icon) {
            this.label = `<i class="fas ${this.icon} -fa-2x" aria-hidden="true"></i>`;
        }
        if (this.items) {
            const _statusTypes: any[] = [];
            this.items.forEach((i) => {
                const item = this.statusTypes.find((statusItem) => {
                    return statusItem.id === i.id;
                });
                _statusTypes.push(item);

            });
            this.statusTypes = _statusTypes;
        }

        this.index = this.statusTypes.findIndex(i => i.id === this.status);
        if (this.index === -1) { this.index = 0; }
        this.staticEmojiIcon = this.emojiIcon();
        this.staticClassName = this.className();
        this.render();
    }

    ngOnChanges(changes: SimpleChanges) {
        const status: SimpleChange = changes.status;
        if (status && status.currentValue) {
            this.status = status.currentValue;
            this.staticEmojiIcon = this.emojiIcon();
            this.staticClassName = this.className();
        }
        this.render();
    }

    toggle(mouseEvent: MouseEvent) {
        if (this.interactive) {
            if (this.index + 1 < this.statusTypes.length) {
                this.index++;
            } else {
                this.index = 0;
            }
            this.status = this.statusTypes[this.index].id;

            mouseEvent.stopPropagation();
            this.onStatusChange.emit(this.status);
            this.cdr.markForCheck();
            this.staticClassName = this.className();
        }
    }

    private render() {
        if (this.itemWithTypeProperties) {
            this.itemWithTypeProperties.getTypeDefinitionAsync(definition => {
                this.label = definition;
                this.detectChanges();
            });
            this.itemWithTypeProperties.getTypeNameAsync(name => {
                if (!this.tooltip) {
                    this.tooltip = name;
                }
                this.detectChanges();
            });
        }
    }

    private className(): string {
        return Globals.StatusTypeClasses[this.status];
    }

    private emojiIcon(): string {
        // https://fontawesome.com/icons?d=gallery&q=Emoji
        const translateService = AppInjector.getInjector().get(TranslateService);
        switch (this.status) {
            case Globals.StatusTypes.GREEN:
                this.displayName = translateService.instant('green');
                return 'fas fa-smile';
            case Globals.StatusTypes.YELLOW:
                this.displayName = translateService.instant('yellow');
                return 'far fa-meh-blank';
            case Globals.StatusTypes.RED:
                this.displayName = translateService.instant('red');
                return 'fas fa-frown';
            case Globals.StatusTypes.GREY:
                this.displayName = translateService.instant('grey');
                return 'fas fa-meh-blank';
            default:
                return 'fas fa-smile-wink';
        }
    }

}
