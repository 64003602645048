import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    ProjectNoteTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectNoteTableColumn";
import {ProjectNoteCell} from "@app/pages/displays/display-project-details/subdisplay-top/Cells/ProjectNoteCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {ColumnTypeSettings} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypeSettings";
import {ProjectDetails_Top_ProjectNoteFieldSettingValue} from "@app/core/http/Api/Api";

export class ProjectNoteColumn extends BaseColumn<ProjectNoteCell> {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new ProjectNoteTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: Row): BaseCell {
        return new ProjectNoteCell(row, this);
    }

    public getNoteField(): string {
        return this.settings.get(ColumnTypeSettings.ProjectNoteField)
            ?.getObject<ProjectDetails_Top_ProjectNoteFieldSettingValue>()
            ?.fieldName ?? '';
    }

}
