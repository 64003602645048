import {Site} from "@app/core/models";

export class ConfigHelper {

    private static Cache: Map<string, string>;

    private static GetCache(callback: (cache: Map<string, string>) => void) {
        if (!ConfigHelper.Cache) {
            Site.Get(site => {
                ConfigHelper.Cache = new Map();
                const configs = JSON.parse(site.config ?? '[]');
                configs.forEach((config: any) => ConfigHelper.Cache.set(config.identifier, config.value));
                callback(ConfigHelper.Cache);
            });
        } else {
            callback(ConfigHelper.Cache);
        }
    }

    public static Get(identifier: string, callback: (value: string | undefined) => void) {
        ConfigHelper.GetCache(cache => callback(cache.get(identifier)));
    }

    public static GetBool(identifier: string, callback: (value: boolean) => void) {
        ConfigHelper.Get(identifier, value => callback(value === '1'));
    }

}
