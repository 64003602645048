<div class="form-group flex-grow-1 -align-items-center m-0 p-0">
    <label *ngIf="configuration && configuration?.labelName">{{ configuration.labelName | translate }}</label>
    <ul class="list-group" *ngIf="estimateUnits">
        <li class="list-group-item" *ngFor="let estimateItem of estimateItems">

            <div class="form-group mb-0 flex-grow-1 align-items-center">
                <div class="d-flex flex-row align-items-center" >

                    <label *ngIf="estimateItem?.name">
                        {{ estimateItem.name }}
                    </label>

                    <div class="d-flex flex-row" *ngIf="estimateItem.estimate">
                        <input class="form-control" type="number" id="value"
                               [min]="0"
                               [(ngModel)]="estimateItem.estimate.value"
                               (change)="estimateItem.onValueChanged()"/>
                        <select class="form-control ms-1 mb-0"
                                *ngIf="estimateUnits"
                                [class.d-none]="estimateUnits?.length <= 1"
                                [(ngModel)]="estimateItem.estimate.estimate_unit_id"
                                (change)="save(estimateItem)"
                        >
                            <option *ngFor="let estimateUnit of estimateUnits" [ngValue]="estimateUnit.id"
                            >{{estimateUnit.name | translate}}</option>
                        </select>

                    </div>

                </div>
            </div>
        </li>
    </ul>
</div>
