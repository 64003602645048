import {Color} from '@app/core/models/Color';
import {Category} from '@app/core/models/Category';

export class CategoryRow {
    category: Category;
    name: string;
    toolTip: string;
    color: Color;

    constructor(category: Category) {
        this.category = category;
        this.name = category.name;
        this.color = category.color;
        this.toolTip = category.category_type?.name;
    }

}
