import {NgModule} from "@angular/core";
import {CardPreviewComponent} from "@app/shared/_ui/cards/card-preview/card-preview.component";
import {CommonModule} from "@angular/common";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";

@NgModule({
    imports: [
        CommonModule,
        ColorLabelModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CardPreviewComponent,
    ],
    exports: [
        CardPreviewComponent

    ],
    providers: [

    ],
})
export class CardPreviewModule {}
