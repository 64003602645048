<div id="navbar-menu-hamburger" class="collapse navbar-collapse">

    <div class="d-flex -flex-row h-100" >
        <div class="d-flex navbar-nav flex-column h-100 align-items-end pt-0 dark" id="navigation-list">
            <div class="d-flex flex-column mb-auto w-100">
                <a class="nav-item nav-link yellow" (click)="closeMenu()">
                    <i class="fal fa-arrow-from-right"></i>
                    <span> {{'_ui_close' | translate}}</span>
                </a>


                <a class="d-none d-mobile-flex nav-item nav-link "
                   [class.d-uber-flex]="user && user.uber"
                   (click)="setNavigation(NavigationItems.Mobile)"
                >
                    <i class="fal fa-house"></i>
                    <span> {{'_ui_homepage' | translate}}</span>
                </a>


                <a class="nav-item nav-link " *ngFor="let d of getDisplays(selectedDepartment, this.Globals.DisplayTypes.UserOverview)"
                   [class.active]="navigationItem == NavigationItems.Dashboard"
                   (click)="setNavigation(NavigationItems.Dashboard, d)">
                    <i class="fal fa-chalkboard-teacher"></i>
                    <span >{{d.name | translate}}</span>
                </a>

                <!-- Foretrukne tavler, mobil, Fjernet https://podio.com/klartboard/softwareudvikling/apps/stories/items/1508 -->
                <app-display-list
                    *ngIf="user && false"
                    [favorites]="true"
                    [department]="selectedDepartment"
                    [displayUser]="user"
                    (click)="closeMenu()"
                    class="d-none d-mobile-flex pt-1 pb-1 border-bottom border-top border-color-dark"
                    [displayHorizontal]="false"
                    className="flex-column dark mobile-sidebar"></app-display-list>


                <a class="nav-item nav-link"
                   *ngIf="selectedDepartment && getDisplays(selectedDepartment).length > 0"
                   [class.active]="navigationItem == NavigationItems.Displays"
                   (click)="setNavigation(NavigationItems.Displays)">
                    <i class="fal fa-th-large"></i>
                    <span translate="_ui_navigation_boards"></span>
                </a>

                <a class="nav-item nav-link"
                   [class.active]="navigationItem == NavigationItems.DepartmentGroups || navigationItem == NavigationItems.Departments"
                   (click)="changeDepartmentGroup($event, null); changeNavigationItem(NavigationItems.DepartmentGroups);"
                   *ngIf="selectedDepartment && departments.length > 0">
                    <i class="fal fa-users"></i>
                    <span> {{'_admin_dynamic_menu_item_copy_to_team_pick_team' | translate}}
                        <!--{{selectedDepartment.name}}-->
                    </span>
                </a>

                <a class="nav-item nav-link"
                   [class.active]="navigationItem == NavigationItems.Search"
                   (click)="setNavigation(NavigationItems.Search)">
                    <i class="fal fa-search"></i>
                    <span> {{'_ui_search' | translate}}</span>
                </a>

                <a class="nav-item nav-link d-none d-lg-block"
                   *ngIf="selectedDepartment && getDisplays(selectedDepartment).length > 0"
                   [class.active]="navigationItem == NavigationItems.TeamRules"
                   (click)="setNavigation(NavigationItems.TeamRules)"
                >
                    <i class="fal fa-chess-queen-alt"></i>
                    <span translate="_ui_team_room"></span>
                </a>

                <a class="nav-item nav-link d-none d-lg-block"
                   (click)="changeShowNonPlanned(true)">
                    <i class="fal fa-ballot-check"></i>
                    <span translate="_ui_non_planned"></span>
                </a>
            </div>

            <div class="d-flex flex-column  w-100" ngbDropdown placement="top-left" appDropdownAppendToBody>
                <a class="nav-link nav-item d-none d-lg-block"
                   [class.active]="navigationItem == NavigationItems.MilestonePlans"
                   *ngIf="(user && user.user_group_id <= Globals.UserGroups.Admin)"
                   (click)="setNavigation(NavigationItems.MilestonePlans)">
                    <i class="fal fa-pencil-ruler "></i>
                    <span translate="_milestone_plans"></span>
                </a>
                <a class="nav-item nav-link"
                   [class.active]="navigationItem == NavigationItems.Tools"
                   (click)="setNavigation(NavigationItems.Tools)">
                    <i class="fal fa-toolbox"></i>
                    <span translate="_ui_navigation_tools"></span>
                </a>
<!--                <a class="nav-item nav-link" (click)="openSupportTicketEditor()">-->
<!--                    <i class="fal fa-question-circle"></i>-->
<!--                    <span translate>Hjælp</span>-->
<!--                </a>-->
                <a class="nav-item nav-link red mt-2"
                   (click)="logout()">
                    <i class="fal fa-sign-out"></i>
                    <span translate="_ui_navigation_log_out"></span>
                </a>


            </div>
        </div>
        <div class="d-flex bg-white h-100 flex-grow-1 p-2 flex-column" id="navigation-submenu">

            <div class="d-flex scroll-y flex-grow-1">
                <div class="team-list w-100 light p-2 -pt-5 d-flex flex-column h-100" *ngIf="navigationItem == NavigationItems.DepartmentGroups && departmentGroups">
                    <h6><span>{{'_admin_dynamic_menu_item_pick_department' | translate}}</span></h6>
                    <ul class="list-unstyled scroll-y">
                        <ng-container *ngFor="let group of departmentGroups">
                            <li (click)="changeDepartmentGroup($event, group)"
                                [class.active]="selectedDepartmentGroup?.id == group.id"
                                [class.disabled]="!group.department || group.department?.length == 0"
                                [ngbTooltip]="group.name"
                                class="-p-1 pt-1 pb-1 cursor-pointer">
                                <a [class.active]="selectedDepartmentGroup?.id == group.id" class="d-flex flex-row p-1 w-100 nav-link">
                                    <i class="fal fa-users me-1"></i>
                                    <span class="ellipsis flex-grow-1" >
                                        {{group.name}}
                                    </span>

                                    <div class="ms-auto  d-flex align-items-center justify-content-end">
                                        <span class="me-1 badge-circle">{{group.department?.length}}</span>
                                        <i class="fal fa-arrow-circle-right  text-small"></i>
                                    </div>
                                </a>
                            </li>
                        </ng-container>

                        <ng-container *ngFor="let department of visibleDepartments">
                            <li *ngIf="getDisplays(department).length > 0"
                                (click)="changeDepartment($event, department)"
                                [ngbTooltip]="department.name"
                                [class.active]="selectedDepartment?.id == department.id"
                                class="-p-1 pt-1 pb-1 cursor-pointer">
                                <a [class.active]="selectedDepartment?.id == department.id" class="d-flex flex-row p-1 w-100 nav-link">
                                    <i class="fal fa-users me-1"></i>
                                    <span class="ellipsis flex-grow-1" >{{department.name}}</span>

                                    <i class="fal fa-arrow-circle-right ms-auto text-small"></i>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>

                <div class="team-list w-100 light p-2 d-flex flex-column h-100" *ngIf="navigationItem == NavigationItems.Mobile">

                    <h6  class="cursor-pointer d-flex align-items-center flex-wrap">
                        <div class="d-block align-items-center w-100">
                            <span>{{'_ui_homepage' | translate}}</span>
                        </div>
                    </h6>

                    <ul class="list-unstyled scroll-y" >
                        <li class="nav-item">
                        <a class="d-none d-mobile-flex  nav-link "
                           [class.d-uber-flex]="user && user.uber"
                           routerLink="/app/tasks/list/personal"
                           [routerLinkActive]="['is-active']"
                           (click)="closeMenu()">
                            <i class="fal fa-rectangle-list"></i>
                            <span> {{'_tasks' | translate}}</span>
                        </a>
                        </li>
                        <li class="nav-item">
                        <a class="d-none d-mobile-flex nav-link "
                           [class.d-uber-flex]="user && user.uber"
                           routerLink="/app/projects/list/personal"
                           [routerLinkActive]="['is-active']"
                           (click)="closeMenu()">
                            <i class="fal fa-rectangle-list"></i>
                            <span> {{'_projects' | translate}}</span>
                        </a>
                        </li>
                        <li class="nav-item">
                        <a class="d-none d-mobile-flex nav-link"
                           [class.d-uber-flex]="user && user.uber"
                           routerLink="/app/milestones/list/personal"
                           [routerLinkActive]="['is-active']"
                           (click)="closeMenu()">
                            <i class="fal fa-rectangle-list"></i>
                            <span> {{'_milestones' | translate}}</span>
                        </a>
                        </li>
                    </ul>
                </div>
                <div class="team-list w-100 light p-2 d-flex flex-column h-100" *ngIf="navigationItem == NavigationItems.Departments && visibleDepartments">
                    <h6 *ngIf="selectedDepartment" (click)="changeDepartmentGroup($event, null); changeNavigationItem(NavigationItems.DepartmentGroups);" class="cursor-pointer d-flex align-items-center flex-wrap">
                        <div class="d-block align-items-center w-100">
                            <span>{{'_admin_dynamic_menu_item_copy_to_team_pick_team' | translate}}</span>
                        </div>

                        <small class="sub-nav ellipsis mt-1 text-black-50 w-100"
                               [ngbTooltip]="('_admin_dynamic_menu_item_pick_department' | translate)"
                               (click)="changeDepartmentGroup($event, null); changeNavigationItem(NavigationItems.DepartmentGroups);">{{selectedDepartmentGroup.name}}</small>
                    </h6>
                    <div class="text-black-50 d-flex align-items-center" *ngIf="!visibleDepartments || visibleDepartments.length == 0">
                        <i class="fal fa-face-thinking me-1"></i>
                        <span>{{'_ui_group_no_teams_description' | translate}}</span>
                    </div>

                    <ul class="list-unstyled scroll-y" *ngIf="visibleDepartments && visibleDepartments.length > 0">
                        <ng-container *ngFor="let department of visibleDepartments">
                            <li *ngIf="getDisplays(department).length > 0"
                                [ngbTooltip]="department.name"
                                (click)="changeDepartment($event, department)"
                                [class.active]="selectedDepartment.id == department.id"
                                class="-p-1 pt-1 pb-1 cursor-pointer">
                                <a [class.active]="selectedDepartment.id == department.id" class="d-flex flex-row p-1 w-100 nav-link">
                                    <i class="fal fa-users me-1"></i>
                                    <span class="ellipsis flex-grow-1" >{{department.name}}</span>

                                    <i class="fal fa-arrow-circle-right ms-auto text-small"></i>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>

                <ng-container *ngFor="let display of getDisplays(selectedDepartment, this.Globals.DisplayTypes.UserOverview)">
                    <app-user-dashboard-menu-item
                        class="team-list dashboard light p-2 -pt-5 d-flex flex-column -debug navigation-wrapper w-100"
                        *ngIf="navigationItem == NavigationItems.Dashboard && selectedDisplay?.id == display.id"
                        [selectedDepartment]="selectedDepartment"
                        [(selectedUser)]="selectedUser"
                        (toggleMenuEvent)="toggleMenu()"
                        [display]="display"></app-user-dashboard-menu-item>
                </ng-container>

                <div class="displays-list light p-2 -pt-5 w-100" *ngIf="navigationItem == NavigationItems.Displays">
                    <ng-container *ngIf="selectedDepartment">
                    <h6 [routerLink]="['/app/home/dashboard']" class="d-flex flex-wrap">
                        <span class="w-100 d-block">{{'_department_displays' | translate}}</span>
<!--                        <i class="fal fa-arrow-circle-right ms-auto text-small"></i>-->

                        <div class="d-flex mt-1 w-100 align-items-center">
                            <small class="text-black-50 me-1 d-flex align-items-center  flex-shrink-1"
                                   [ngbTooltip]="('_admin_dynamic_menu_item_pick_department' | translate)"
                                   (click)="changeDepartmentGroup($event, null); changeNavigationItem(NavigationItems.DepartmentGroups)"
                                   *ngIf="selectedDepartmentGroup">
                                <span class="ellipsis sub-nav  ">
                                {{selectedDepartmentGroup.name}}</span>
                                <i class="fal fa-chevron-right me-1 text-small ms-1"></i></small>
                            <small class="sub-nav ellipsis text-black-50 flex-shrink-1"
                                   [ngbTooltip]="selectedDepartmentGroup ? ('_admin_dynamic_menu_item_copy_to_team_pick_team' | translate) : ('_admin_dynamic_menu_item_pick_department' | translate)"
                                   *ngIf="selectedDepartment"  (click)="changeDepartmentGroup($event, selectedDepartmentGroup ? selectedDepartmentGroup : null); changeNavigationItem(selectedDepartmentGroup ? NavigationItems.Departments : NavigationItems.DepartmentGroups); " >{{selectedDepartment.name}}</small>
                        </div>
                    </h6>

                    <app-display-list
                            [navigateDashboard]="false"
                            [favorites]="false"
                            [department]="selectedDepartment"
                            [limit]="20"
                            [displayUser]="selectedUser"
                            [displayHorizontal]="true"
                            (itemSelected)="itemSelected($event)"
                            className="flex-column light"></app-display-list>
                    </ng-container>
                </div>

                <div class="displays-list light -p-2 -pt-5 w-100 d-flex flex-column" *ngIf="navigationItem == NavigationItems.Search">
                    <h6>{{'_ui_search' | translate}}</h6>
                    <app-global-search [listMode]="true" class="h-100 d-block flex-grow-1 scroll-y"></app-global-search>
                </div>

                <div class="displays-list light p-2 -pt-5 w-100" *ngIf="navigationItem == NavigationItems.MilestonePlans">
                    <h6>{{'_global_edit' | translate}}: {{'_milestone_plans' | translate}}</h6>
                    <app-milestone-plan-list
                        (onSelect)="onSelectMilestonePlan($event);"
                        [sortable]="false"
                        [showEditable]="false"
                        [interactive]="true"
                        class=""></app-milestone-plan-list>

                </div>

                <div class="displays-list  actions-list light p-2 -pt-5 w-100"
                     *ngIf="navigationItem == NavigationItems.Tools">
                    <h6>{{'_ui_navigation_tools' | translate}}</h6>


                    <ul class="list-unstyled d-flex align-items-center d-mobile-none">
                        <li><app-fullscreen-toggle class="header-icon"></app-fullscreen-toggle></li>
                        <li *ngIf="showPurgeHands">
                            <span  class="d-flex align-items-center nav-link header-icon">
                            <i
                                class="fal fa-hand-rock cursor-pointer"
                                [ngbTooltip]="('_ui_remove_hand_op_department' | translate)+' '+selectedDepartment?.name+'...'"
                                container="body"
                                placement="auto"
                                aria-hidden="true"
                                (click)="removeAllHandUps()" ></i>
                            </span>
                        </li>
                    </ul>

                    <hr class="d-mobile-none" />

                    <a id="csv-export-download" class="nav-item nav-link mb-1"
                       [ngbTooltip]="('_ui_csv_export_description' | translate)"
                       container="body"
                       *ngIf="showExportButton"
                       placement="right"
                       (click)="csvExport($event)"
                    >
                        <i class="fal fa-file-spreadsheet"></i>
                        <span >{{'_ui_csv_export' | translate}}</span>
                    </a>

                    <a class="nav-item nav-link mb-1 d-mobile-none" target="_top" href="mailto:support@klartboard.dk?subject=KlartBoard" (click)="sendMail()">
                        <i class="fal fa-envelope"></i>
                        <span >{{'_ui_help' | translate}}</span>
                    </a>

                    <a class="nav-link nav-item mb-1 d-mobile-none" target="_blank" href="{{Helpers.serverUrl(true)}}" *ngIf="user && user.user_group_id <= Globals.UserGroups.Admin">
                        <i class="fal fa-tools "></i>
                        <span translate="_ui_navigation_administration"></span>
                    </a>


                    <a class="nav-link nav-item mb-1" (click)="openSettingsPanel()">
                        <i class="fal fa-user-circle"></i>
                        <span translate="_ui_navigation_settings"></span>
                    </a>

                    <a class="nav-item nav-link mb-1" routerLink="/app/home/dashboard/list"  (click)="closeMenu()">
                        <i class="fal fa-th-large"></i>
                        <span >{{'_ui_select_default_display' | translate}}</span>
                    </a>

                    <a class="nav-item nav-link mb-1" routerLink="/app/about" (click)="closeMenu()">
                        <i class="fal fa-database"></i>
                        <span >{{'_ui_system_info' | translate}}</span>
                    </a>

                    <a class="nav-item nav-link mb-1" (click)="clearStorage()">
                        <i class="fal fa-cookie"></i>
                        <span >{{'_ui_reset_cookies' | translate}}</span>
                    </a>

                    <a class="nav-item nav-link mb-1 d-none"
                       *ngIf="user && (user?.administrator || user.uber || user.sysadmin)"
                       routerLink="/app/projects/list/deleted"
                       (click)="closeMenu()"
                    >
                        <i class="fal fa-trash-can-undo mb-1"></i>
                        <span>{{'_ui_deleted_projects' | translate}}</span>
                    </a>

                    <a class="nav-item nav-link mb-1"
                       *ngIf="user && (user?.administrator || user.uber || user.sysadmin)"
                       routerLink="/app/restore/list"
                       (click)="closeMenu()"
                    >
                        <i class="fal fa-trash-can-undo mb-1"></i>
                        <span>{{'_ui_deleted_items' | translate}}</span>
                    </a>

                    <a class="nav-item nav-link mb-1 d-mobile-none"
                       routerLink="/app/projects/list"
                       (click)="closeMenu()"
                    >
                        <i class="fal fa-list-alt mb-1"></i>
                        <span translate="_projects"></span>
                    </a>

                    <a class="nav-item nav-link mb-1 d-mobile-none"
                       routerLink="/app/milestones/list"
                       (click)="closeMenu()"
                    >
                        <i class="fal fa-list-alt"></i>
                        <span translate="_milestones"></span>
                    </a>

                    <a class="nav-item nav-link mb-1 d-mobile-none"
                       routerLink="/app/tasks/list"
                       (click)="closeMenu()"
                    >
                        <i class="fal fa-list-alt"></i>
                        <span translate="_tasks"></span>
                    </a>

                </div>

                <div class="displays-list light p-2 -pt-5 w-100" *ngIf="navigationItem == NavigationItems.TeamRules && selectedDepartment">
                    <h6>{{'_ui_team_room' | translate}} {{selectedDepartment.name}}</h6>
                    <app-dynamic-menu-items
                        [department]="selectedDepartment"
                    ></app-dynamic-menu-items>

                </div>

            </div>


            <div class="text-black-50 mt-auto pt-1 mt-1 border-top -hover-show">

                <!-- language / sprog / dansk -->
                <ul class="list-unstyled d-flex flex-row justify-content-center" *ngIf="true">
                    <!--<li>{{currentLanguage}}</li>-->
                    <li *ngFor="let language of languages"
                        (click)="setLanguage(language)"
                        [class.text-black-50]="language !== currentLanguage"
                        class="cursor-pointer me-1">
                        {{language | translate}}
                    </li>
                    <li>
                        <a class="small text-black-50  me-2 nav-link p-0" (click)="toggleDarkMode()">
                            <i class="fal " [class.fa-lightbulb-slash]="prefersDark" [class.fa-lightbulb]="!prefersDark"></i> </a>
                    </li>

                    <li *ngIf="user" class="d-flex align-items-center">
                        <a class="small  me-2 nav-link p-0" (click)="setMobile(true)">
                            <i class="fal fa-mobile-notch" [class.text-black-50]="isMobile"></i> </a>

                        <a class="small  me-2 nav-link p-0" (click)="setMobile(false)">
                            <i class="fal fa-computer-mouse" [class.text-black-50]="isMobile"></i> </a>
                    </li>
                </ul>

                <div class="d-inline-block text-center d-flex flex-column cursor-pointer" (click)="clearStorage()"><!--v{{version}}-->
                    <div class="text-danger small me-2" *ngIf="!environment.production">{{'development-server' | translate}}</div>
                    <div class="d-flex flex-row justify-content-center text-center small flex-wrap p-2" style="opacity: 0.5;">


                        <a class="small text-black-50  me-2 nav-link p-0"><i class="fal fa-cookie me-1"></i> <span >{{'_ui_reset_cookies' | translate}}</span></a>
                        <div class="small text-black-50  me-2 d-mobile-none"><i class="fal fa-code-branch"></i> {{environment.version}}</div>
                        <div class="small text-black-50  me-2 d-mobile-none" *ngIf="gitVersions"> <i class="fab fa-github"></i> {{gitVersions.npm_version}}, {{gitVersions.revision}}, {{gitVersions.branch}}</div>
                        <div class="small text-black-50  d-mobile-none" *ngIf="gitVersions"><i class="fab fa-google"></i> {{gitVersions.version}}<ng-container *ngIf="gitVersions.project_id !== '_PROJECT_ID_'">, {{gitVersions.project_id}}</ng-container></div>
                    </div>
                </div>


            </div>

        </div>

    </div>
</div>

<div class="navbar-overlay-click" (click)="closeMenu();"></div>
