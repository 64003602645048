import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";
import {
    TodoCategoryEditorComponent
} from "@app/editor/quick-editor/editors/todo/todo-category-editor/todo-category-editor.component";
import {TodoCategoryPickerModule} from "@app/shared/_forms/todo-category-picker/TodoCategoryPicker.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbTooltipModule,
        TodoCategoryPickerModule,
    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TodoCategoryEditorComponent,
    ],
    exports: [
        TodoCategoryEditorComponent

    ],
    providers: [

    ],
})
export class TodoCategoryEditorModule {}
