import * as moment from 'moment';
import {unitOfTime} from 'moment';

export class PeriodRange {
    end: Date;
    start: Date;
    days: number = 6;

    constructor(start?: Date, end?: Date) {
        this.start = start ? start : moment()
            .startOf('isoWeek' as unitOfTime.StartOf)
            .toDate();

        this.end = end ? end : moment()
            .startOf('isoWeek' as unitOfTime.StartOf)
            .add(this.days, 'days')
            .endOf('day')
            .toDate();
    }

    public changeDate(days: number) {
        if(days < 0) {
            this.start = moment(this.start).subtract(Math.abs(days), 'days').toDate();
            this.end = moment(this.end).subtract(Math.abs(days), 'days').toDate();
        }
        if(days > 0) {
            this.start = moment(this.start).add(days, 'days').toDate();
            this.end = moment(this.end).add(days, 'days').toDate();
        }
    }

    public setPeriodStartOfWeek(date: Date) {
        this.start = moment(date).startOf('isoWeek' as unitOfTime.StartOf).toDate();
        this.end = moment(this.start)
            .add(this.days, 'days')
            .endOf('day')
            .toDate();
    }

    public setPeriod(start: Date, end: Date) {
        this.start = start;
        this.end = end;
    }

}
