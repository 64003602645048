<div class="column-title d-flex align-items-center">
    <div class="d-flex flex-row align-items-center text-white w-100">

        <div
            *ngIf="yearWheelPeriod"
            class="d-flex align-items-center"
        >
            <app-date-range-picker class="ms-2"
                                   (onDateChanged)="yearWheelDateChange($event)"
                                   [ngbTooltip]="('_ui_filter_label_select_period' | translate) + '...'"
                                   [start]="yearWheelPeriod.start"
                                   [end]="yearWheelPeriod.end"></app-date-range-picker>
        </div>


        <div class="d-flex ms-auto align-items-center pe-2">

            <div class="d-flex p-1 ms-2 border rounded align-items-center ps-1 pe-1">

                <app-date-range-picker
                    *ngIf="yearWheelPeriod"
                    class="ms-1"
                    [showDateText]="false"
                    (onDateChanged)="yearWheelDateChange($event)"
                    [ngbTooltip]="('_ui_filter_label_select_period' | translate) + '...'"
                    [start]="yearWheelPeriod.start"
                    [end]="yearWheelPeriod.end"></app-date-range-picker>
                <app-toggle-item [icon]="'fa-folder-open'"
                                 [interactive]="false"
                                 [active]="yearWheelMilestonesExpanded"
                                 [iconInactive]="'fa-folder'"
                                 (click)="toggleYearWheelMilestones()"
                                 class="me-1 cursor-pointer"
                                 [ngbTooltip]="!yearWheelMilestonesExpanded ? ('_ui_expand_type' | translate: {type: ('_milestones' | translate)}) : ('_ui_collapse_type' | translate: {type: ('_milestones' | translate)})"
                ></app-toggle-item>
                <app-toggle-item [icon]="'fa-list-check'"
                                 [interactive]="false"
                                 [active]="yearWheelTasksExpanded"
                                 [iconInactive]="'fa-list-check'"
                                 (click)="toggleYearWheelTasks()"
                                 class="me-1 cursor-pointer"
                                 [ngbTooltip]="!yearWheelTasksExpanded ? ('_ui_expand_type' | translate: {type: ('_tasks' | translate)}) : ('_ui_collapse_type' | translate: {type: ('_tasks' | translate)})"
                ></app-toggle-item>

                <!-- refresh -->
                <i
                    *ngIf="yearWheelPeriod"
                    class="text-small fal fa-light fa-arrow-rotate-right cursor-pointer me-1"
                    [ngbTooltip]="('_ui_reload' | translate) + '...'"
                    (click)="yearWheelDateChange(yearWheelPeriod)"></i>

            </div>

            <!-- day, week, alt -->
            <div class="d-flex p-1 ms-2 border rounded">
                <app-toggle-item [icon]="'fa-calendar-day'"
                                 [interactive]="yearWheelDisplayOptions.unit != Constants.PeriodUnits.Days"
                                 [active]="yearWheelDisplayOptions.unit == Constants.PeriodUnits.Days"
                                 (onToggle)="yearWheelUnitChange(Constants.PeriodUnits.Days)"
                                 class="me-1"
                                 [ngbTooltip]="('_ui_period_day' | translate)"
                                 *ngIf="yearWheelDaysRange < 70"
                ></app-toggle-item>

                <app-toggle-item [icon]="'fa-calendar-week'"
                                 [interactive]="yearWheelDisplayOptions.unit != Constants.PeriodUnits.Weeks"
                                 [active]="yearWheelDisplayOptions.unit == Constants.PeriodUnits.Weeks"
                                 (onToggle)="yearWheelUnitChange(Constants.PeriodUnits.Weeks)"
                                 *ngIf="yearWheelDaysRange < 250"
                                 [ngbTooltip]="('_ui_period_week' | translate)"
                                 class="me-1"
                ></app-toggle-item>

                <app-toggle-item [icon]="'fa-calendar-alt'"
                                 [interactive]="yearWheelDisplayOptions.unit != Constants.PeriodUnits.Months"
                                 [active]="yearWheelDisplayOptions.unit == Constants.PeriodUnits.Months"
                                 (onToggle)="yearWheelUnitChange(Constants.PeriodUnits.Months)"
                                 [ngbTooltip]="('_ui_period_month' | translate)"
                ></app-toggle-item>
            </div>

            <div class="d-flex p-1 ms-2 border rounded me-2">
                <app-toggle-item [icon]="'fa-copy'"
                                 [interactive]="true"
                                 [ngbTooltip]="('_project' | translate)"
                                 [active]="yearWheelDisplayOptions.showProject"
                                 (onToggle)="yearWheelDisplayOptions.showProject = !yearWheelDisplayOptions.showProject; yearWheelOptionsChange()"
                                 class="me-1"
                ></app-toggle-item>

                <app-toggle-item [icon]="'fa-moon'"
                                 [interactive]="true"
                                 [active]="yearWheelDisplayOptions.showPhase"
                                 [ngbTooltip]="('_phase_singular' | translate)"
                                 (onToggle)="yearWheelDisplayOptions.showPhase = !yearWheelDisplayOptions.showPhase; yearWheelOptionsChange()"
                                 class="me-1"
                ></app-toggle-item>

                <app-toggle-item [icon]="'fa-diamond'"
                                 [interactive]="true"
                                 [ngbTooltip]="('_milestone_singular' | translate)"
                                 [active]="yearWheelDisplayOptions.showMilestone"
                                 (onToggle)="yearWheelDisplayOptions.showMilestone = !yearWheelDisplayOptions.showMilestone; yearWheelOptionsChange()"
                ></app-toggle-item>

                <app-toggle-item [icon]="'fa-square-check'"
                                 [interactive]="true"
                                 [ngbTooltip]="('_ui_tasks' | translate)"
                                 [active]="yearWheelDisplayOptions.showTask"
                                 (onToggle)="yearWheelDisplayOptions.showTask = !yearWheelDisplayOptions.showTask; yearWheelOptionsChange()"
                ></app-toggle-item>
            </div>

        </div>

    </div>
</div>
