import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {PANEL_ANIMATION} from "@app/editor/panel.animation";
import {fadeAnimation, slideDownAnimation} from "@app/animations";
import {EditorPanelService} from "@app/services/editor-panel.service";
import {Department} from '@app/core/models';
import {CaseRow} from '@app/pages/displays/display-cases/CaseRow';
import {Api, ProjectCasesCopyResponseItem} from '@app/core/http/Api/Api';

@Component({
    selector: 'app-copy-project-cases-dialog',
    templateUrl: './copy-project-cases-dialog.component.html',
    styleUrls: ['./copy-project-cases-dialog.component.scss'],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation,
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ]
})
export class CopyProjectCasesDialogComponent extends BaseModalComponent implements OnInit {

    public department: Department;
    public caseRows: CaseRow[];
    public start: string;
    public end: string;
    // public selectionRows: CaseRow[];
    public isLoading: boolean = true;


    /*
    1) Tjekboks: Overfør for alle sager
    2) Tjekboks: Overfør bunken fra sidste uge (Vis antal) - pr. forløb
    3) Tjekboks: Overfør bunken fra sidste uge fratrukket sidste uges antal fuldførte sager (Nået) (Vis antal)
    4) Knap til at lukke / annulere
    5) Knap til at godkende valg
     */
    selectionRows: { row: CaseRow, selected: boolean, copyResponseItem: ProjectCasesCopyResponseItem }[] = [];
    subtractTaskEstimates: boolean = true;

    constructor(private modal: NgbActiveModal,
                private editorPanelService: EditorPanelService,
    ) {
        super(modal);
    }

    ngOnInit(): void {
        super.ngOnInit();

        const projectIds = this.caseRows.map(r => r.projectCard.item.id);


        Api.projectCases().copyGet()
            .from_date((this.start))
            .to_date((this.end))
            .project_ids(projectIds)
            .department_id(this.department.id)
            .subtract_task_estimates(true)
            .readonly(true)
            .find(projectCasesCopyResponse => {
                this.caseRows.forEach(cr => {
                    this.selectionRows.push({
                        row: cr,
                        copyResponseItem: projectCasesCopyResponse[0].items?.find(pr => pr.project_id == cr.projectCard.item.id),
                        selected: true
                    })
                })
                this.isLoading = false;
            });
    }



    public accept() {
        this.isLoading = true;
        Api.projectCases().copyGet()
            .from_date((this.start))
            .to_date((this.end))
            .project_ids(this.selectionRows.filter(sr => sr.selected).map(sr => sr.row).map(r => r.projectCard.item.id))
            .department_id(this.department.id)
            .subtract_task_estimates(this.subtractTaskEstimates)
            .readonly(false)
            .find(copyResponse => {
                this.activeModal.close({
                    caseRows: this.selectionRows.filter(sr => sr.selected).map(sr => sr.row),
                    copyResponse: copyResponse[0]
                });
            });


    }


    public toggleAll() {

    }
}
