import {NgModule} from "@angular/core";
import {TitleEditorComponent} from "@app/editor/quick-editor/editors/generic/title-editor/title-editor.component";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {AutofocusDirectiveModule} from '@app/directives/AutofocusDirective.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        NgbTooltip,
        AutofocusDirectiveModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TitleEditorComponent,
    ],
    exports: [
        TitleEditorComponent

    ],
    providers: [

    ],
})
export class TitleEditorModule {}
