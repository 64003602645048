<div
    *ngIf="isReady"
    class="container p-2 d-flex gap-1 w-100 flex-wrap editor-tab flex-column"
>

    <div
        *ngIf="deadlineListFieldItem.visible || statusRulesFieldItem.visible"

        [style.order]="deadlineListFieldItem.index"
    >

        <label>{{ deadlineListEditorConfiguration.labelName | translate}}</label>

        <div
            class="list-group-item rounded"
        >
            <app-deadline-list-editor
                *ngIf="deadlineListFieldItem.visible"
                [model]="item"
                [configuration]="deadlineListEditorConfiguration"
            />
            <div
                *ngIf="statusRulesFieldItem.visible"
            >
                <div class="mt-1">
                    <div
                        class="custom-control custom-checkbox list-group-item d-flex justify-content-start rounded"
                        [ngbPopover]="(item.use_status_rules && statusRules) ? popoverContent : null"
                        [autoClose]="'inside'"
                        triggers="mouseenter:mouseleave"
                        container="body"
                        #popover="ngbPopover"
                    >
                        <app-use-status-rules-editor
                            [model]="item"
                            [configuration]="useStatusRulesEditorConfiguration"
                        />
                    </div>
                </div>
                <!-- Popover -->
                <ng-template #popoverContent>
                    <div class="text-dark">
                        <app-status-rule-appender
                            [_internalValue]="statusRules"
                            [readonly]="true"
                        />
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <app-estimate-list-editor
        *ngIf="estimateListFieldItem.visible"
        [style.order]="estimateListFieldItem.index"
        [model]="item"
        [configuration]="estimateListEditorConfiguration"
    />

    <div
        *ngIf="calculatedFieldsFieldItem.visible"
        [style.order]="calculatedFieldsFieldItem.index"
        class="mb-2"
    >
        <label>
            <span>{{'_project_calculated_fields' | translate}}</span>
        </label>
        <div class="list-group-item rounded d-flex flex-column">
            <div *ngFor="let calculatedFieldsProject of calculatedFields" class="d-flex align-items-center">
                <label>
                    <span>{{calculatedFieldsProject.calculated_field?.name | translate}}</span>
                </label>
                <app-project-calculated-field
                    [project]="item"
                    [calculatedField]="calculatedFieldsProject.calculated_field"
                    [calculatedFieldsProject]="calculatedFieldsProject"
                />
            </div>
        </div>
    </div>

    <app-link-editor
        *ngIf="linkFieldItem.visible"
        [style.order]="linkFieldItem.index"
        [model]="item"
        [configuration]="linkEditorConfiguration"
    />

    <app-status-list-editor
        *ngIf="statusListFieldItem.visible"
        [style.order]="statusListFieldItem.index"
        [model]="item"
        [configuration]="statusListEditorConfiguration"
    />

    <ng-container
        *ngFor="let noteTextEditorConfiguration of noteTextEditorFields"
    >
        <app-text-editor
            *ngIf="noteTextEditorConfiguration.fieldItem.visible"
            [style.order]="noteTextEditorConfiguration.fieldItem.index"
            [model]="item"
            [configuration]="noteTextEditorConfiguration.configuration"
        />
    </ng-container>

    <app-phases-project-editor
        *ngIf="phaseFieldItem.visible"
        [style.order]="phaseFieldItem.index"
        [model]="item"
        [configuration]="phasesProjectEditorConfiguration"
    />

    <app-reaction-list-editor
        *ngIf="showEmojiReactionListEditor"
        [model]="item"
        [configuration]="emojiReactionListEditorConfiguration"
    />

    <app-category-picker-list-editor
        *ngIf="showCategoryPickerListEditor"
        [model]="item"
        [configuration]="categoryPickerListEditorConfiguration"
    />

</div>
