import {NgModule} from "@angular/core";
import {CreatePhaseDialogComponent} from "@app/shared/_modals/create-phase-dialog/create-phase-dialog.component";
import {NgxModalDraggableDirectiveModule} from "@app/directives/NgxModalDraggableDirective.module";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {ColorPickerModule} from "@app/shared/_elements/color-picker/ColorPicker.module";
import {DepartmentPickerModule} from "@app/shared/_forms/department-picker/DepartmentPicker.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgxModalDraggableDirectiveModule,
        ColorItemModule,
        TranslateModule,
        NgbTooltipModule,
        FormsModule,
        ColorPickerModule,
        DepartmentPickerModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CreatePhaseDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class CreatePhaseDialogModule {}
