import {
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    Input,
    NgZone,
    OnInit,
    ViewChild,
} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {Params, Router, RouterOutlet} from '@angular/router';
import {slideInAnimation} from '@app/animations';
import {EditorObj, EditorPanelService} from '@app/services/editor-panel.service';
import {CaseUser, Roster, Task} from '@app/core/models/Task';
import {Project} from '@app/core/models/Project';
import {Milestone} from '@app/core/models/Milestone';
import {Todo} from '@app/core/models/Todo';
import {MatSidenav} from "@angular/material/sidenav";
import {WampService} from '../../services/wamp.service';
import {ShellService} from "@app/services/ShellService/shell.service";
import Helpers from "@app/core/helpers";
import {Location} from "@angular/common";
import Globals from "@app/constants";
import {DisplayService} from "@app/services/display.service";
import {AppInjector} from "@app/services/app-injector.service";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {AnyItem} from '@app/interfaces/CustomTypes';
import {DynamicChildLoaderDirective} from "@app/directives/dynamic-child-loader.directive";
import {DisplayFilterEditorComponent} from "@app/editor/display-filter-editor/display-filter-editor.component";
import {NonPlannedItemListComponent} from "@app/editor/non-planned-itemlist/non-planned-item-list.component";
import {TaskEditorLoaderComponent} from "@app/editor/task-editor-loader/task-editor-loader.component";
import {ProjectEditorLoaderComponent} from "@app/editor/project-editor-loader/project-editor-loader.component";
import {MilestoneEditorLoaderComponent} from "@app/editor/milestone-editor-loader/milestone-editor-loader.component";
import {TodoEditorLoaderComponent} from "@app/editor/todo-editor-loader/todo-editor-loader.component";
import {FocusableService} from "@app/core/Focusable/Focusable.service";

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
    animations: [
        slideInAnimation,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent extends BaseComponent implements OnInit {

    @Input() parentSubject: Subject<any>;
    @ViewChild('navigationSidenav', {static: true}) public matSideNav: MatSidenav;
    @ViewChild('mainNav', {static: true}) public mainNav: any;
    @ViewChild('outlet', {static: true}) public routerOutlet: RouterOutlet;
    @ViewChild(DynamicChildLoaderDirective, {static: true}) dynamicChild!: DynamicChildLoaderDirective;

    // Bindings to view
    public menuHidden = true;
    public sidebarOpen: boolean = false;

    // Data
    private activeItems: EditorObj[] = [];
    private nonPlannedListComponent?: ComponentRef<NonPlannedItemListComponent>;

    constructor(
        public router: Router,
        private editorPanelService: EditorPanelService,
        private shellService: ShellService,
        private location: Location,
        private displayService: DisplayService,
        private ngZone: NgZone,
        private wampService: WampService, // Keep it to initialize
        private focusableService: FocusableService
    ) {
        super();
        this.subscribe(this.editorPanelService.editor$.subscribe((item: EditorObj) => {
            this.createComponent(item);
        }));

        this.subscribe(this.editorPanelService.onOpenDisplayFilterEditor.subscribe(showDisplayFilterInterface => {
            const component = this.dynamicChild.viewContainerRef.createComponent(
                DisplayFilterEditorComponent,
            );
            component.instance.showDisplayFilterInterface = showDisplayFilterInterface;
            component.instance.closeWindow$.subscribe((e: boolean) => {
                component.destroy();
            });
        }));

        this.subscribe(this.shellService.onRemoveFromURIEvent.subscribe((item) => {
            this.removeFromURI(item);
        }));

        // Hide non-planned-sidebar when changing page
        this.subscribe(this.shellService.onPrimaryPageDataChangeEvent.subscribe(value => {
            if (value === undefined) {
                this.nonPlannedListComponent?.destroy();
                this.nonPlannedListComponent = undefined;
            }
        }));
        this.subscribe(this.shellService.onShowNonPlannedSidebarChangeEvent.subscribe(open => {
            this.nonPlannedListComponent?.destroy();
            this.nonPlannedListComponent = undefined;
            if (open) {
                this.nonPlannedListComponent = this.dynamicChild.viewContainerRef.createComponent(
                    NonPlannedItemListComponent,
                );
                this.nonPlannedListComponent.instance.closeWindow$.subscribe((e: boolean) => {
                    this.nonPlannedListComponent?.destroy();
                });
            }
        }));
    }

    ngOnInit() {
        this.menuHidden = this.mainNav?.menuHidden;
        this.setupRoute();
    }

    createComponent(item: EditorObj) {
        // Den samme skal ikke åbnes flere gange, placer i stedet øverst
        const foundItemIndex = this.activeItems.findIndex(i => i.item.constructor == item.item.constructor && i.item.id == item.item.id);
        if (foundItemIndex !== -1) {
            const activeItem = this.activeItems[foundItemIndex];
            if (activeItem) {
                return;
            }
        }
        this.activeItems.push(item);

        switch (item.item.constructor) {
            case CaseUser:
            case Roster:
            case Task:
                const taskComponent = this.dynamicChild.viewContainerRef.createComponent(
                    TaskEditorLoaderComponent,
                );
                taskComponent.instance.itemToLoad = item.item as Task;
                taskComponent.instance.options = item.options;
                item.component = taskComponent;
                taskComponent.instance.onCloseEventEmitter.subscribe((e: boolean) => {
                    this.activeItems.splice(this.activeItems.findIndex(i => i === item), 1);
                    taskComponent.destroy();
                });
                break;
            case Project:
                const projectComponent = this.dynamicChild.viewContainerRef.createComponent(
                    ProjectEditorLoaderComponent
                );
                projectComponent.instance.itemToLoad = item.item as Project;
                projectComponent.instance.options = item.options;
                item.component = projectComponent;
                projectComponent.instance.onCloseEventEmitter.subscribe((e: boolean) => {
                    this.activeItems.splice(this.activeItems.findIndex(i => i === item), 1);
                    projectComponent.destroy();
                });
                break;
            case Milestone:
                const milestoneComponent = this.dynamicChild.viewContainerRef.createComponent(
                    MilestoneEditorLoaderComponent
                );
                milestoneComponent.instance.itemToLoad = (item.item as Milestone);
                milestoneComponent.instance.options = item.options;
                item.component = milestoneComponent;
                milestoneComponent.instance.onCloseEventEmitter.subscribe((e: boolean) => {
                    this.activeItems.splice(this.activeItems.findIndex(i => i === item), 1);
                    milestoneComponent.destroy();
                });
                break;
            case Todo:
                const todoComponent = this.dynamicChild.viewContainerRef.createComponent(
                    TodoEditorLoaderComponent
                );
                todoComponent.instance.itemToLoad = (item.item as Todo);
                todoComponent.instance.options = item.options;
                item.component = todoComponent;
                todoComponent.instance.onCloseEventEmitter.subscribe((e: boolean) => {
                    this.activeItems.splice(this.activeItems.findIndex(i => i === item), 1);
                    todoComponent.destroy();
                });
                break;
            default:
                console.warn('createComponent() : Unknown type : ', item);
                break;
        }

        this.editorPanelService.addItem(item);
    }

    onToggle() {
        this.matSideNav.toggle(this.menuHidden);
    }

    onClose() {
        this.matSideNav.close();
    }

    // <editor-fold desc="Route">

    private activatedRouteQueryParamsSubscription?: Subscription;
    private lastActivatedRouteQueryParams?: Params;
    private projectIds: string[] = [];
    private milestoneIds: string[] = [];
    private taskIds: string[] = [];

    public onRouteActivateEvent() {
        const params = this.routerOutlet.activatedRoute?.snapshot?.params ?? {};

        // Tell shell about new settings
        this.shellService.setPageSettings(
            params.departmentId ? parseInt(params.departmentId) : undefined,
            params.userId ? parseInt(params.userId) : undefined,
            params.displayId ? parseInt(params.displayId) : undefined,
            params.programId ? parseInt(params.programId) : undefined,
            params.projectId ? parseInt(params.projectId) : undefined,
            Helpers.encodeUri(this.location.path())
        );

        // Subscribe to milestone, task and project ids in query params. Open EditorPanel.
        this.activatedRouteQueryParamsSubscription?.unsubscribe();
        this.activatedRouteQueryParamsSubscription = this.routerOutlet.activatedRoute.queryParams
            .subscribe(params => {
                if (this.lastActivatedRouteQueryParams !== params) {
                    this.lastActivatedRouteQueryParams = params;

                    // http://localhost:4300/app/displays/projects/8/1/0?displayFilterId=37&projectIds=[36,37]
                    const projectIds = Helpers.convertParamsArray(this.lastActivatedRouteQueryParams.projectIds);
                    if (projectIds && ShellComponent.IsParamsDifferent(projectIds, this.projectIds)) {
                        projectIds.filter(this.Helpers.onlyUnique)
                            .forEach(projectId => {
                                this.projectIds.push(projectId);
                                AppInjector.getInjector().get(EditorPanelService).openUri(new Project({id: projectId}));
                            });
                    }
                    const taskIds = Helpers.convertParamsArray(this.lastActivatedRouteQueryParams.taskIds);
                    if (taskIds && ShellComponent.IsParamsDifferent(taskIds, this.taskIds)) {
                        taskIds.filter(this.Helpers.onlyUnique)
                            .forEach(taskId => {
                                this.taskIds.push(taskId);
                                AppInjector.getInjector().get(EditorPanelService).openUri(new Task({id: taskId}));
                            });
                    }

                    const milestoneIds = Helpers.convertParamsArray(this.lastActivatedRouteQueryParams.milestoneIds);
                    if (milestoneIds && ShellComponent.IsParamsDifferent(milestoneIds, this.milestoneIds)) {
                        milestoneIds.filter(this.Helpers.onlyUnique)
                            .forEach(milestoneId => {
                                this.milestoneIds.push(milestoneId);
                                AppInjector.getInjector().get(EditorPanelService).openUri(new Milestone({id: milestoneId}));
                            });
                    }
                }
            });
    }

    private static IsParamsDifferent(a: any[], b: any[]) {
        return !(a.length === b.length &&
            a.every((v, i) => v === b[i]));
    }

    private setupRoute() {
        this.subscribe(AppInjector.getInjector().get(EditorPanelService).closeItem$.subscribe((item) => {
            switch (item?.constructor?.name) {
                case Globals.ItemTypes.CaseUser:
                case Globals.ItemTypes.Roster:
                case Task.name:
                    this.taskIds = this.taskIds.filter(value => value != item.id.toString()).filter(this.Helpers.onlyUnique);
                    this.updateUrlParams();
                    break;

                case Project.name:
                    this.projectIds = this.projectIds.filter(value => value != item.id.toString()).filter(this.Helpers.onlyUnique);
                    this.updateUrlParams();
                    break;

                case Milestone.name:
                    this.milestoneIds = this.milestoneIds.filter(value => value != item.id.toString()).filter(this.Helpers.onlyUnique);
                    this.updateUrlParams();
                    break;
            }
        }));

        this.subscribe(AppInjector.getInjector().get(EditorPanelService).item$.subscribe((item) => {
            const queryParams = {...this.routerOutlet.activatedRoute.snapshot.queryParams};
            const itemId = item?.id?.toString();
            if (item && itemId) {
                switch (item.constructor) {
                    case CaseUser:
                    case Roster:
                    case Task:
                        const currentTaskIds = Helpers.convertParamsArray(queryParams.taskIds);
                        if (currentTaskIds && currentTaskIds.indexOf(itemId) !== -1) {
                            return;
                        }
                        if (this.taskIds.indexOf(itemId) !== -1) {
                            return;
                        }
                        this.taskIds = currentTaskIds ? [...[itemId], ...currentTaskIds] : [itemId];
                        this.updateUrlParams();
                        break;

                    case Project:
                        const currentProjectIds = Helpers.convertParamsArray(queryParams.projectIds);
                        if (currentProjectIds && currentProjectIds.indexOf(itemId) !== -1) return;
                        if (this.projectIds.indexOf(itemId) !== -1) return;
                        this.projectIds = currentProjectIds ? [...[itemId], ...currentProjectIds] : [itemId];
                        this.updateUrlParams();
                        break;

                    case Milestone:
                        const currentMilestoneIds = Helpers.convertParamsArray(queryParams.milestoneIds);
                        if (currentMilestoneIds && currentMilestoneIds.indexOf(itemId) !== -1) return;
                        if (this.milestoneIds.indexOf(itemId) !== -1) return;
                        this.milestoneIds = currentMilestoneIds ? [...[itemId], ...currentMilestoneIds] : [itemId];
                        this.updateUrlParams();
                        break;
                }
            }
        }));
    }

    private updateUrlParams() {
        const queryParams = {...this.routerOutlet.activatedRoute.snapshot.queryParams};

        if (this.taskIds && this.taskIds.length > 0) {
            queryParams['taskIds'] = this.taskIds.filter(this.Helpers.onlyUnique).filter(d => parseInt(d) != 0);
        } else {
            delete queryParams.taskIds;
        }

        if (this.projectIds && this.projectIds.length > 0) {
            queryParams['projectIds'] = this.projectIds.filter(this.Helpers.onlyUnique).filter(d => parseInt(d) != 0);
        } else {
            delete queryParams.projectIds;
        }

        if (this.milestoneIds && this.milestoneIds.length > 0) {
            queryParams['milestoneIds'] = this.milestoneIds.filter(this.Helpers.onlyUnique).filter(d => parseInt(d) != 0);
        } else {
            delete queryParams.milestoneIds;
        }

        this.ngZone.run(() => {
            this.router.navigate(
                [],
                {
                    relativeTo: this.routerOutlet.activatedRoute,
                    skipLocationChange: false,
                    replaceUrl: true,
                    queryParams: queryParams,
                    queryParamsHandling: ''
                });
        });
    }

    public removeFromURI(item: AnyItem) {
        switch (item?.constructor) {
            case Task:
                this.taskIds = this.taskIds.filter(this.Helpers.onlyUnique).filter(d => parseInt(d) != item.id);
                this.updateUrlParams();
                break;
            case Project:
                this.projectIds = this.projectIds.filter(this.Helpers.onlyUnique).filter(d => parseInt(d) != item.id);
                this.updateUrlParams();
                break;
            case Milestone:
                this.milestoneIds = this.milestoneIds.filter(this.Helpers.onlyUnique).filter(d => parseInt(d) != item.id);
                this.updateUrlParams();
                break;

        }
    }

    // </editor-fold>

}
