/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {SettingOptionDefinition} from './definitions/SettingOptionDefinition';

export class SettingOption extends SettingOptionDefinition {

    constructor(json?: any) {
        super(json);
    }

}
