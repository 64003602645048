import {NgModule} from "@angular/core";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";
import {
    IsPrivateEditorComponent
} from "@app/editor/quick-editor/editors/generic/is-private-editor/is-private-editor.component";

@NgModule({
    imports: [
        CommonModule,
        ToggleItemModule,
        TranslateModule,
        NgbTooltipModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        IsPrivateEditorComponent,
    ],
    exports: [
        IsPrivateEditorComponent

    ],
    providers: [

    ],
})
export class IsPrivateEditorModule {}
