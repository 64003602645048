import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";

export class GenericCell extends BaseCell {

    public row: ProjectsDisplayRow;

    constructor(row: ProjectsDisplayRow, column: GenericColumn) {
        super(row, column);
    }

    public exportCSV(secondary?: any): CSVCellValue {
        return '';
    }

}
