/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TodoStatusTypesStatusDefinition,} from './definitions/TodoStatusTypesStatusDefinition';

export class TodoStatusTypesStatus extends TodoStatusTypesStatusDefinition {

    constructor(json?: any) {
        super(json);
    }

}
