<ul class="list-unstyled d-flex flex-row justify-content-end m-0 align-items-center w-100">

    <li class="me-3"
        *ngIf="configuration.showArchive">
        <app-toggle-item [interactive]="true"
                         [active]="isArchived"
                         [icon]="'fa-check-circle'"
                         [iconInactive]="'fa-circle'"
                         [ngbTooltip]="(isArchived ? ('_ui_reopen' | translate):  ('_ui_complete' | translate)) + typeName"
                         (onToggle)="archiveToggle()"></app-toggle-item>
    </li>

    <li class="me-3 d-flex align-items-start"
        *ngIf="configuration.showDragDrop">
        <div role="menuitem" cdkDragHandle
             [ngbTooltip]="('_ui_drag_and_drop' | translate) + typeName"
             [class.disabled]="!isDragEnabled"
             class="fal fa-bars cursor-drag">
            <app-card-switcher [cardItem]="cardItem" *cdkDragPreview></app-card-switcher>
        </div>
    </li>

    <li class=""
        *ngIf="configuration.showDelete">
        <button role="button" class="btn btn-sm btn-danger "
                [ngbTooltip]="('_global_delete' | translate) + typeName"
                (click)="delete()">
            <i class="fal fa-trash-alt" aria-hidden="true"></i>
        </button>
    </li>
</ul>
