import {NgModule} from "@angular/core";
import {ErrorDialogComponent} from "@app/shared/_modals/error-dialog/error-dialog.component";
import {NgxModalDraggableDirectiveModule} from "@app/directives/NgxModalDraggableDirective.module";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        NgxModalDraggableDirectiveModule,
        ColorItemModule,
        TranslateModule,
        NgbTooltipModule,
        CommonModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ErrorDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class ErrorDialogModule {}
