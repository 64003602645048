import {EventEmitter, Injectable} from '@angular/core';
import {ShellPageData} from './ShellPageData';
import {PageSettings} from "@app/services/ShellService/PageSettings";
import {AnyItem} from '@app/interfaces/CustomTypes';
import {Display, ProjectType, TaskType} from '@app/core/models';
import {AppInjector} from '@app/services/app-injector.service';
import {TasksService} from '@app/services/tasks.service';
import {ProjectsService} from '@app/services/projects.service';

@Injectable({
    providedIn: 'root'
})
export class ShellService {

    // Data
    private pageDataList: ShellPageData[] = []; // Wrapper for Display, DisplayFilterGroup, DisplayColumnGroup (a display containing filters and columns)
    private primaryPageData?: ShellPageData;
    private pageSettings?: PageSettings;

    // Events
    public onPageDataListChangeEvent = new EventEmitter<ShellPageData[]>(); // Triggered when display appear / disappear
    public onPrimaryPageDataChangeEvent = new EventEmitter<ShellPageData>();
    public onHeaderTitleChangeEvent = new EventEmitter<{title: string, tooltip: string, showDepartment: boolean, showCreateNew: boolean, showColumnPicker: boolean}>();
    public onShowNonPlannedSidebarChangeEvent = new EventEmitter<boolean>();
    public onReloadAllClickEvent = new EventEmitter();
    public onPageSettingsChangeEvent = new EventEmitter<PageSettings>();
    public isCapturingScreenshotEventEmitter = new EventEmitter<boolean>();

    public onRemoveFromURIEvent = new EventEmitter<AnyItem>();

    public setHeaderTitle(value: string, toolTip?: string, showDepartment?: boolean, showCreateNew?: boolean, showColumnPicker?: boolean) {
        this.onHeaderTitleChangeEvent.emit({title: value, tooltip: toolTip ?? value, showDepartment: showDepartment, showCreateNew: showCreateNew, showColumnPicker: showColumnPicker});
    }

    public removeFromURI(item: AnyItem) {
        this.onRemoveFromURIEvent.emit( item); // Vi lytter i shell.component.ts
    }

    // <editor-fold desc="Page Settings">

    public setPageSettings(departmentId: number, userId: number, primaryDisplayId: number, programId: number,
                           projectId: number, url: string) {
        this.pageSettings = new PageSettings(departmentId, userId, primaryDisplayId, programId, projectId, url);
        this.onPageSettingsChangeEvent.emit(this.pageSettings);
    }

    public setPageSettingsDisplay(display: Display) {
        this.pageSettings.display = display;
        this.onPageSettingsChangeEvent.emit(this.pageSettings);
    }

    public getPageSettings(): PageSettings {
        return this.pageSettings;
    }

    // </editor-fold>

    // <editor-fold desc="Page Data">

    public attachFilterPageData(filterPageData: ShellPageData, isPrimary: boolean) {
        this.pageDataList.push(filterPageData);
        this.onPageDataListChangeEvent.emit(this.pageDataList);
        if (isPrimary) {
            this.setPrimaryPageData(filterPageData);
        }
    }

    public detachFilterPageData(filterPageData: ShellPageData, isPrimary: boolean) {
        this.pageDataList.splice(this.pageDataList.indexOf(filterPageData), 1);
        this.onPageDataListChangeEvent.emit(this.pageDataList);
        if (isPrimary) {
            this.setPrimaryPageData(undefined);
        }
    }

    private setPrimaryPageData(value: ShellPageData|undefined) {
        this.primaryPageData = value;
        this.onPrimaryPageDataChangeEvent.emit(this.primaryPageData);
    }

    public getPageDataList(): ShellPageData[] {
        return this.pageDataList;
    }

    public getPrimaryPageData(): ShellPageData|undefined {
        return this.primaryPageData;
    }

    // </editor-fold>

    setPageSettingsProjectTypes(projectTypes: ProjectType[]) {
        this.pageSettings.projectTypes = projectTypes;
        this.onPageSettingsChangeEvent.emit(this.pageSettings);
    }
    setPageSettingsTaskTypes(taskTypes: TaskType[]) {
        this.pageSettings.taskTypes = taskTypes;
        this.onPageSettingsChangeEvent.emit(this.pageSettings);
    }

    setPageSettingsTaskTypeIds(taskTypeIds: number[]) {
        if(taskTypeIds.length){
            let taskTypes:TaskType[] = [];
            taskTypeIds.forEach(tId => {
                AppInjector.getInjector().get(TasksService).getTaskType(tId, (taskType)=>{
                    taskTypes.push(taskType)
                });
            })
            this.setPageSettingsTaskTypes(taskTypes);

        }
    }

    setPageSettingsProjectTypeIds(projectTypeIds: number[]) {
        const projectTypes: ProjectType[] = [];

        let callbackCounter = projectTypeIds.length;
        const projectTypeCallback = (projectType: ProjectType) => {
            projectTypes.push(projectType);
            callbackCounter--;
            if (callbackCounter === 0) {
                this.setPageSettingsProjectTypes(projectTypes);
            }
        };

        projectTypeIds.forEach(projectTypeId => {
            AppInjector.getInjector().get(ProjectsService).getProjectType(projectTypeId, projectTypeCallback);
        });
    }

    setIsCapturingScreenshot(b: boolean) {
        this.isCapturingScreenshotEventEmitter.emit(b);
    }
}
