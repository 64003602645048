/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {SiteDefinition} from './definitions/SiteDefinition';
import {Api} from "@app/core/http/Api/Api";
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";
import {System} from "@app/constants";

export class Site extends SiteDefinition {

    constructor(json?: any) {
        super(json);
    }

    private static DataCache?: ApiDataCache<Site>;

    private static GetDataCache(): ApiDataCache<Site> {
        if (!this.DataCache) {
            this.DataCache = new ApiDataCache<Site>(
                System.Site,
                2 * 60 * 60, // 2 hours
                Api.sites().getById(1)
            );
        }
        return this.DataCache;
    }

    public static Get(callback: ((site: Site) => void)) {
        Site.GetDataCache().getFirst(callback);
    }

}
