import {NgModule} from "@angular/core";
import {
    HeaderTitleFilterSelectorComponent
} from "@app/shell/header/header-title-filter-selector/header-title-filter-selector.component";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {NgbNavModule, NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {HeaderTitleFilterGroupModule} from "@app/shell/header/header-title-filter-group/HeaderTitleFilterGroup.module";
import {CommonModule, NgOptimizedImage} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        LoadingIndicatorModule,
        NgbPopoverModule,
        TranslateModule,
        NgbTooltipModule,
        NgbNavModule,
        HeaderTitleFilterGroupModule,
        NgOptimizedImage,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        HeaderTitleFilterSelectorComponent,
    ],
    exports: [
        HeaderTitleFilterSelectorComponent

    ],
    providers: [

    ],
})
export class HeaderTitleFilterSelectorModule {}
