/**
 * Created by ModelParser
 * Date: 17-05-2021.
 * Time: 07:40.
 */
import {CategoryTypesMilestoneDefinition} from './definitions/CategoryTypesMilestoneDefinition';

export class CategoryTypesMilestone extends CategoryTypesMilestoneDefinition {

    constructor(json?: any) {
        super(json);
    }

}
