import {Project} from '@app/core/models/Project';
import {User} from '@app/core/models/User';
import {Display} from '@app/core/models/Display';
import {ListConfiguration} from '@app/shared/_ui/lists/ListConfiguration';
import {AppointmentFetcherRequest} from '@app/shared/_ui/lists/appointment-list/AppointmentFetcher';
import {Appointment} from '@app/core/models/Appointment';
import {DynamicMenuItem} from "@app/core/models/DynamicMenuItem";
import {CreateItemInterface} from '@app/shared/_ui/create-item-dropdown/CreateItemInterface';
import {CreateItemSourceConfiguration} from '@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration';
import {CreateItemPreset} from '@app/shared/_ui/create-item-dropdown/CreateItemPreset';
import {AppointmentsUser} from '@app/core/models/AppointmentsUser';
import Helpers from '@app/core/helpers';
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";

export class AppointmentListConfiguration extends ListConfiguration implements CreateItemInterface<Appointment> {

    // Filter
    private dynamicMenuItem: DynamicMenuItem;
    private project?: Project;
    private user?: User;
    private archived?: boolean;
    private avoidIds?: number[];
    private display?: Display;
    private hasDisplay?: boolean;
    private orArchivedSince: Date;
    private userPeriod: Date[];

    // Limit & Order
    private orderBy: string[][] = [['created', 'desc'], ['updated', 'desc']]; // MH: TO-DO: Lav smart server ordering på max(created, updated)

    private dataSource: AppointmentFetcherRequest;

    constructor() {
        super();

        this.createItemConfiguration = new CreateItemSourceConfiguration();
        this.createItemConfiguration.showTasks = false;
        this.createItemConfiguration.showProjects = false;
        this.createItemConfiguration.showMilestone = false;
        this.createItemConfiguration.showTodo = false;
        this.createItemConfiguration.showAppointments = true;
        this.createItemPreset = new CreateItemPreset();
        this.createItemPreset.createAppointmentInterface = this;
    }

    public validate(appointment: Appointment): boolean {
        if (this.getDynamicMenuItem()) {
            if (appointment.dynamic_menu_item_id == null || appointment.dynamic_menu_item_id == 0 || appointment.dynamic_menu_item_id && appointment.dynamic_menu_item_id != this.getDynamicMenuItem().id)
                return false;
        }
        if (this.getProject()) {
            if (appointment.projects == null || appointment.projects.findIndex(i => i.id == this.getProject().id) == -1)
                return false;
        }
        if (this.getUser()) {
            if (appointment.appointments_users == null || appointment.appointments_users.findIndex(i => i.user_id == this.getUser().id) === -1)
                return false;
        }
        if (this.getAvoidIds()) {
            if (this.getAvoidIds().includes(appointment.id))
                return false;
        }
        return true;
    }

    // <editor-fold desc="Create Item">

    createItem(): Appointment {  // TODO @martin convert to Presets
        const item = new Appointment();
        if (this.getProject()) {
            item.projects = [this.getProject()];
        }
        if (this.getUser()) {
            const appointmentsUser = new AppointmentsUser();
            appointmentsUser.user_id = this.getUser().id;
            if (this.getUserPeriod()) {
                appointmentsUser.start = Helpers.serverDate(this.getUserPeriod()[0]);
                appointmentsUser.end = Helpers.serverDate(this.getUserPeriod()[1]);
            }
            item.appointments_users = [appointmentsUser];
        }
        return item;
    }

    public createPresets(typeId?: number): CreatePreset[] {
        return this.createPresetGenerators?.map(generator => generator.generate(typeId)) ?? [];
    }

    // </editor-fold>

    public getOrderBy(): string[][] {
        return this.orderBy;
    }

    public setOrderBy(value: string[][]): AppointmentListConfiguration {
        this.orderBy = value;
        return this;
    }

    public getLimit(): number {
        return super.getLimit();
    }

    public setLimit(value: number): AppointmentListConfiguration {
        super.setLimit(value);
        return this;
    }

    public getUser(): User {
        return this.user;
    }

    public setUser(value: User): AppointmentListConfiguration {
        this.user = value;
        return this;
    }

    public getArchived(): boolean {
        return this.archived;
    }

    public setArchived(value: boolean): AppointmentListConfiguration {
        this.archived = value;
        return this;
    }

    public getDynamicMenuItem(): DynamicMenuItem {
        return this.dynamicMenuItem;
    }

    public getProject(): Project {
        return this.project;
    }

    public setProject(value: Project): AppointmentListConfiguration {
        this.project = value;
        return this;
    }

    public setDynamicMenuItem(value: DynamicMenuItem): AppointmentListConfiguration {
        this.dynamicMenuItem = value;
        return this;
    }

    public getAvoidIds(): number[] {
        return this.avoidIds;
    }

    public setAvoidIds(value: number[]): AppointmentListConfiguration {
        this.avoidIds = value;
        return this;
    }

    public getDisplay(): Display {
        return this.display;
    }

    public setDisplay(value: Display): AppointmentListConfiguration {
        this.display = value;
        return this;
    }

    public getHasDisplay(): boolean {
        return this.hasDisplay;
    }

    public setHasDisplay(value: boolean): AppointmentListConfiguration {
        this.hasDisplay = value;
        return this;
    }

    public getOrArchivedSince(): Date {
        return this.orArchivedSince;
    }

    public setOrArchivedSince(value: Date): AppointmentListConfiguration {
        this.orArchivedSince = value;
        return this;
    }

    public getDataSource(): AppointmentFetcherRequest {
        return this.dataSource;
    }

    public setDataSource(value: AppointmentFetcherRequest): AppointmentListConfiguration {
        this.dataSource = value;
        return this;
    }

    public getUserPeriod(): Date[] {
        return this.userPeriod;
    }

    public setUserPeriod(start: Date, end: Date): AppointmentListConfiguration {
        this.userPeriod = [start, end];
        return this;
    }

    public setUseGlobalSearchFilter(value: boolean): AppointmentListConfiguration {
        super.setUseGlobalSearchFilter(value);
        return this;
    }


}
