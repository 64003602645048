import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DisplayFilterEditorComponent} from '@app/editor/display-filter-editor/display-filter-editor.component';
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {NgbDatepickerModule, NgbNavModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {PhaseSearchModule} from "@app/shared/_forms/search/phase-search/PhaseSearch.module";
import {
    PhaseProgressTypeSearchModule
} from "@app/shared/_forms/search/phase-progress-type-search/PhaseProgressTypeSearch.module";
import {FirstLetterUppercasePipeModule} from "@app/pipes/FirstLetterUppercasePipe.module";
import {StatusSelectorModule} from "@app/shared/_forms/status-selector/StatusSelector.module";
import {ReactionsModule} from "@app/shared/_ui/reactions/Reactions.module";
import {CategoryPickerModule} from "@app/shared/_forms/category-picker/CategoryPicker.module";

@NgModule({

    imports: [
        CommonModule,
        TranslateModule,
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        BrowserAnimationsModule,
        ColorItemModule,
        NgbNavModule,
        ToggleItemModule,
        PhaseSearchModule,
        PhaseProgressTypeSearchModule,
        FirstLetterUppercasePipeModule,
        NgbDatepickerModule,
        StatusSelectorModule,
        ReactionsModule,
        CategoryPickerModule,
        NgbTooltipModule,
    ],
    declarations: [
        DisplayFilterEditorComponent,
    ],
    exports: [
        DisplayFilterEditorComponent

    ]
})
export class DisplayFilterEditorModule {
}
