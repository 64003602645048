/**
 * Created by ModelParser
 */
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class SiteDefinition extends BaseModel {
    azure_access_token?: string;
    azure_tenant?: string;
    default_project_deadline_type_id?: number;
    enable_seeding?: boolean;
    default_load?: number;
    default_work_hours?: number;
    has_reaction_types?: boolean;
    has_category_types?: boolean;
    scim_secret?: string;
    azure_sso_application_id?: string;
    azure_sso_application_secret?: string;
    config?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.azure_access_token;
            delete this.azure_tenant;
            delete this.default_project_deadline_type_id;
            delete this.enable_seeding;
            delete this.default_load;
            delete this.default_work_hours;
            delete this.has_reaction_types;
            delete this.has_category_types;
            delete this.scim_secret;
            delete this.azure_sso_application_id;
            delete this.azure_sso_application_secret;
            delete this.config;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.azure_access_token != null) {
            this.azure_access_token = data.azure_access_token;
        }
        if (data.azure_tenant != null) {
            this.azure_tenant = data.azure_tenant;
        }
        if (data.default_project_deadline_type_id != null) {
            this.default_project_deadline_type_id = data.default_project_deadline_type_id;
        }
        if (data.enable_seeding != null) {
            this.enable_seeding = data.enable_seeding;
        }
        if (data.default_load != null) {
            this.default_load = data.default_load;
        }
        if (data.default_work_hours != null) {
            this.default_work_hours = data.default_work_hours;
        }
        if (data.has_reaction_types != null) {
            this.has_reaction_types = data.has_reaction_types;
        }
        if (data.has_category_types != null) {
            this.has_category_types = data.has_category_types;
        }
        if (data.scim_secret != null) {
            this.scim_secret = data.scim_secret;
        }
        if (data.azure_sso_application_id != null) {
            this.azure_sso_application_id = data.azure_sso_application_id;
        }
        if (data.azure_sso_application_secret != null) {
            this.azure_sso_application_secret = data.azure_sso_application_secret;
        }
        if (data.config != null) {
            this.config = data.config;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
