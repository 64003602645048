/**
 * Created by ModelParser
 * Date: 04-12-2019.
 * Time: 13:20.
 */
import {StatusRuleDefinition} from './definitions/StatusRuleDefinition';
import {StatusRuleTypes, StatusTypes} from '@app/constants';
import Helpers from '@app/core/helpers';
import {Api} from '@app/core/http/Api/Api';

export class StatusRule extends StatusRuleDefinition {

    public defaultDeadlineName: string;

    constructor(json?: any) {
        super(json);
    }

    public static CreateDefault(type?: string) {
        let item = new StatusRule();
        item.id = 0;
        item.created = Helpers.serverDate(new Date());
        item.from_status_id = StatusTypes.GREY;
        item.to_status_id = StatusTypes.GREEN;
        item.type = type ? type : StatusRuleTypes.DefaultConfiguration;
        return item;
    }

    private static AllCache: StatusRule[];
    private static AllCallbacks: ((items: StatusRule[]) => void)[] = [];
    public static GetAll(callback: ((items: StatusRule[]) => void)) {
        if (this.AllCache) {
            callback(this.AllCache);
        } else if (this.AllCallbacks.length) {
            this.AllCallbacks.push(callback);
        } else {
            this.AllCallbacks.push(callback);
            Api.statusRules().get().find(items => {
                this.AllCache = items;
                this.AllCallbacks.forEach(callback => callback(items));
            })
        }
    }

}


