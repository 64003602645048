import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {YearWheelColumn} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/YearWheelColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns";

export class YearWheelTableColumn extends GenericTableColumn {

    public identifier = TableColumns.YearWheel;

    public column: YearWheelColumn;

}
