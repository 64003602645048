import {PageDisplaySetting} from "@app/pages/PageDisplaySetting";

export class Settings {

    public static ProjectTypeIds        = 326;
    public static AddPhaseAtDrop        = 352;

    public static GetProjectTypeIds(settingsMap: Map<number, PageDisplaySetting>): number[] {
        return settingsMap.get(Settings.ProjectTypeIds)?.getIntValues() ?? [];
    }

    public static ShouldAddPhaseAtDrop(settingsMap: Map<number, PageDisplaySetting>): boolean {
        return settingsMap.get(Settings.AddPhaseAtDrop)?.getBoolean() ?? false;
    }

}
