import {Category, ReactionFilter, User} from "@app/core/models";
import {PeriodRange} from "@app/services/FilterGlobalService/PeriodRange";

export class FilterGlobalSettings {

    public isHandUp = false;
    public isStarred = false;
    public activeSortDirection: 'asc'|'desc' = 'asc';
    public activeStatuses: number[] = [];
    public activeReactionFilters: ReactionFilter[] = [];
    public activeCategories: Category[] = [];
    public search = '';
    public period = new PeriodRange();
    public activeUsers: User[] = [];

    public isEqualTo(settings: FilterGlobalSettings): boolean {
        const aReactionFilterIds = this.activeReactionFilters?.map(reactionFilter => reactionFilter.value) ?? [];
        const bReactionFilterIds = settings.activeReactionFilters?.map(reactionFilter => reactionFilter.value) ?? [];
        const aCategoryIds = this.activeCategories?.map(category => category.id) ?? [];
        const bCategoryIds = settings.activeCategories?.map(category => category.id) ?? [];
        const aUserIds = this.activeUsers?.map(category => category.id) ?? [];
        const bUserIds = settings.activeUsers?.map(category => category.id) ?? [];

        return this.isHandUp == settings.isHandUp
            && this.isStarred == settings.isStarred
            // && this.activeSortDirection == settings.activeSortDirection We don't care about sort direction
            && this.activeStatuses.length == settings.activeStatuses.length
            && this.activeStatuses.every((value, index) => value === settings.activeStatuses[index])
            && aReactionFilterIds.length == bReactionFilterIds.length
            && aReactionFilterIds.every((value, index) => value === bReactionFilterIds[index])
            && aCategoryIds.length == bCategoryIds.length
            && aCategoryIds.every((value, index) => value === bCategoryIds[index])
            && aUserIds.length == bUserIds.length
            && aUserIds.every((value, index) => value === bUserIds[index])
            && this.search.localeCompare(settings.search) === 0;
    }

    public clone(): FilterGlobalSettings {
        const clone: any = new FilterGlobalSettings();
        for (const attribute in this) {
            clone[attribute] = this[attribute];
        }
        clone.activeStatuses = [...this.activeStatuses];
        clone.activeReactionFilters = [...this.activeReactionFilters];
        clone.activeCategories = [...this.activeCategories];
        clone.activeUsers = [...this.activeUsers];
        return clone;
    }

}
