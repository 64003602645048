import {EventEmitter} from "@angular/core";

export abstract class BaseOnScreenFilter<T = any> {
    abstract name: string;
    abstract iconActive: string;
    abstract iconInactive: string;
    abstract invertIcon: boolean;

    private enabled = true;
    public visible = true;          // Is this filter visible in on-screen-filter-selector menu
    public multiColumn = true;     // Is this filter shared on multiple columns, only show in on-screen-filter-selector menu if on-screen-filter showMultiColumnFilters is true
    public onEnabledChangeEvent = new EventEmitter<boolean>();
    public borderTop = false;

    public constructor(enabled: boolean) {
        this.enabled = enabled;
    }

    protected abstract validate(item: T): boolean;

    public getEnabled(): boolean {
        return this.enabled;
    }

    public setEnabled(value: boolean) {
        this.enabled = value;
        this.onEnabledChangeEvent.emit(value);
    }

    public toggleEnabled() {
        this.setEnabled(!this.enabled);
    }

    public isValid(item: T): boolean {
        return !this.enabled || this.validate(item);
    }
}
