import {Injectable, OnDestroy} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

export class SnackBarMessage {
    message: string;
    action: string = null;
    config: MatSnackBarConfig = null;
    callback?: Function;
}

@Injectable({
    providedIn: 'root'
})
export class SnackbarService implements OnDestroy {
    private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
    private msgQueue: SnackBarMessage[] = [];
    private isInstanceVisible = false;
    private subscription: Subscription;
    private defaultConfig: MatSnackBarConfig = {duration: 5000, panelClass: 'alert-info'};


    constructor(
        private modalService: NgbModal,
        public snackBar: MatSnackBar,
    ) {
    }

    ngOnDestroy() {
    }

    showNext() {
        if (this.msgQueue.length === 0) {
            return;
        }

        let message = this.msgQueue.shift();
        this.isInstanceVisible = true;
        this.snackBarRef = this.snackBar.open(message.message, message.action, message.config ? message.config : this.defaultConfig);
        this.snackBarRef.afterDismissed().subscribe(() => {
            if (message.callback) {
                message.callback();
            }
            this.isInstanceVisible = false;
            this.showNext();
        });
    }




    /**
     * Add a message
     * @param message The message to show in the snackbar.
     * @param action The label for the snackbar action.
     * @param config Additional configuration options for the snackbar.
     * @param callback
     * @param remove_previous
     */
    public add(message: string,
               action?: string,
               config?: MatSnackBarConfig,
               callback?: Function,
               remove_previous = true
    ): void {

        let sbMessage = new SnackBarMessage();
        sbMessage.message = message;
        sbMessage.action = action;
        if (callback) {
            sbMessage.callback = callback;
        }

        if(remove_previous && this.snackBarRef){
            this.snackBarRef.dismiss();
        }

        this.msgQueue.push(sbMessage);
        if (!this.isInstanceVisible) {
            this.showNext();
        }


    }
}
