import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {MilestonePlan, MilestoneTemplate} from "@app/core/models";
import {TaskTemplate} from '@app/core/models/TaskTemplate';
import {StatusRule} from '@app/core/models/StatusRule';
import {Phase} from '@app/core/models/Phase';
import {ProjectEditorService} from '@app/editor/project-editor-loader/project-editor.service';
import {Api} from "@app/core/http/Api/Api";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '@app/services/snackbar.service';

export interface DeadlineTypeType {
    id: number;
    name: string;
    type: string;
    title: string;
}

@Component({
    selector: 'app-template-milestone-row',
    templateUrl: './template-milestone-row.component.html',
    styleUrls: ['./template-milestone-row.component.scss']
})
export class TemplateMilestoneRowComponent extends BaseDisplayComponent implements OnInit, OnChanges {


    @Input() milestoneTemplate: MilestoneTemplate;
    @Input() projectTypeId: number;
    @Input() milestonePlan: MilestonePlan;
    @Output() expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() expanded: boolean = true;
    @Input() view = this.Constants.MilestoneRowViews.Basic;
    @Output() changed: EventEmitter<MilestoneTemplate> = new EventEmitter<MilestoneTemplate>();
    public allExpanded = false;

    milestoneDeadlineTypes: DeadlineTypeType[] = [
        {id: 0, name: '_admin_milestone_plan_milestone_deadline_type_0', type: 'NONE', title: ''},
        {id: 1, name: '_admin_milestone_plan_milestone_deadline_type_1', type: 'PERCENT_AFTER_START', title: ''},
        {id: 2, name: '_admin_milestone_plan_milestone_deadline_type_2', type: 'DAYS_AFTER_START', title: ''},
        {id: 3, name: '_admin_milestone_plan_milestone_deadline_type_3', type: 'DAYS_BEFORE_END', title: ''},
        {id: 4, name: '_admin_milestone_plan_milestone_deadline_type_4', type: 'DAYS_AFTER_END', title: ''},
        {id: 5, name: '_admin_milestone_plan_milestone_deadline_type_5', type: 'DAYS_AFTER_LAST_MILESTONE', title: ''},
        //{id: 6, name: 'Dage før forrige milepæl', type: 'DAYS_BEFORE_LAST_MILESTONE', title: ''}
        {id: 7, name: '_admin_milestone_plan_milestone_deadline_type_7', type: 'DAYS_BEFORE_START', title: ''},
    ];

    taskDeadlineTypes: DeadlineTypeType[] = [
        {id: 0, name: '_admin_milestone_plan_task_deadline_type_0', type: 'NONE', title: ''},
        {id: 1, name: '_admin_milestone_plan_task_deadline_type_1', type: 'DAYS_BEFORE_MILESTONE', title: ''},
        {id: 2, name: '_admin_milestone_plan_task_deadline_type_2', type: 'DAYS_AFTER_MILESTONE', title: ''},
        {id: 3, name: '_admin_milestone_plan_task_deadline_type_3', type: 'PERCENT_BEFORE_MILESTONE', title: ''},
    ];

    phases: Phase[];
    taskDeadlineTypeMap: Map<any, DeadlineTypeType> = new Map<any, DeadlineTypeType>();
    milestoneDeadlineTypeMap: Map<any, DeadlineTypeType> = new Map<any, DeadlineTypeType>();
    public activated = false;


    constructor(private projectEditorService: ProjectEditorService) {
        super();
        this.taskDeadlineTypes.forEach(dt => this.taskDeadlineTypeMap.set(String(dt.id), dt));
        this.milestoneDeadlineTypes.forEach(dt => this.milestoneDeadlineTypeMap.set(String(dt.id), dt));
    }

    ngOnInit(): void {
        this.projectEditorService.getPhasesForType(this.projectTypeId, phases => {
            this.phases = phases;
            this.setPhase();
        });

    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes) {
        }
    }

    showToast() {
        const translateService = AppInjector.getInjector().get(TranslateService);
        AppInjector.getInjector().get(SnackbarService).add(
            translateService.instant('_ui_item_saved', {item: translateService.instant('_milestone_plan')}),
        );
        console.log('showToast() : this.miletoneTempplate : ', this.milestoneTemplate)
        this.changed.emit(this.milestoneTemplate);
    }

    createTask() {
        if(!this.milestoneTemplate.task_templates)
            this.milestoneTemplate.task_templates = [];
        let taskTemplate: TaskTemplate = TaskTemplate.createDefault();
        taskTemplate.milestone_template_id = this.milestoneTemplate.id;
        taskTemplate.milestone_plan_id = this.milestonePlan.id;
        this.milestoneTemplate.task_templates.push(taskTemplate);
    }

    createStatusRule() {
        if(!this.milestoneTemplate.status_rules)
            this.milestoneTemplate.status_rules = [];
        let statusRule: StatusRule = StatusRule.CreateDefault();
        Api.milestoneTemplates()
            .statusRuleAddPostById(this.milestoneTemplate.id)
            .save(statusRule, (r) => {
                this.milestoneTemplate.status_rules.push(r[0]);
                this.showToast();
            })

    }

    removeTaskTemplate(taskTemplate: TaskTemplate) {
        this.milestoneTemplate.task_templates.splice(this.milestoneTemplate.task_templates.findIndex(tt => tt === taskTemplate), 1);
        if(taskTemplate.id != 0)
            Api.taskTemplates().deleteById(taskTemplate.id).delete((r) => {
                this.showToast();
            });

    }

    removeStatusRuleTemplate(statusRule: StatusRule) {
        this.milestoneTemplate.status_rules.splice(this.milestoneTemplate.status_rules.findIndex(sr => sr === statusRule), 1);
        if(statusRule.id != 0)
            Api.statusRules().deleteById(statusRule.id).delete((r) => {
                this.showToast();
            });
    }


    saveTitle() {
        if(this.milestoneTemplate) {
            let refTemplateIndex = this.milestonePlan.milestone_templates.findIndex(mt => mt == this.milestoneTemplate);
            const title = (this.milestoneTemplate?.title ?? '').trim();
            if (title.length > 0) {
                if (!this.milestoneTemplate.exists()) {
                    this.milestoneTemplate.milestone_plan_id = this.milestonePlan.id;
                    Api.milestoneTemplates()
                        .post()
                        .save(this.milestoneTemplate, (mt) => {
                            this.milestoneTemplate = mt[0];
                            if(refTemplateIndex !== -1){
                                this.milestonePlan.milestone_templates[refTemplateIndex] = this.milestoneTemplate;
                            }

                            console.log('template-milestone.row : saveTitle() : create : ', this.milestoneTemplate);
                            this.showToast();
                        })

                } else {
                    Api.milestoneTemplates()
                        .titlePatchPatchById(this.milestoneTemplate.id)
                        .title(this.milestoneTemplate.title)
                        .save(null, (mt) => {
                            console.log('template-milestone.row : saveTitle() : update : ', mt);
                            this.milestoneTemplate.id = mt.id;
                            this.showToast();
                        });
                }
            }
        }
    }

    saveMilestoneTemplateDeadlineType() {
        Api.milestoneTemplates()
            .deadlinePatchPatchById(this.milestoneTemplate.id)
            .value(this.milestoneTemplate.deadline_value)
            .type(this.milestoneTemplate.deadline_type)
            .save(null, () => {
                this.showToast();
            });

    }

    saveMilestoneTemplateDeadlineValue() {
        Api.milestoneTemplates()
            .deadlinePatchPatchById(this.milestoneTemplate.id)
            .value(this.milestoneTemplate.deadline_value)
            .type(this.milestoneTemplate.deadline_type)
            .save(null, () => {
                this.showToast();
            });
    }

    saveMilestoneTemplatePhase() {
        Api.milestoneTemplates()
            .phaseIdPatchPatchById(this.milestoneTemplate.id)
            .phase_id(this.milestoneTemplate.phase_id)
            .save(null, () => {
                this.showToast();
            });

        this.setPhase();

    }


    milestoneTemplateTaskDrop($event: CdkDragDrop<any, any>) {
        const taskTemplate = $event.item.data as TaskTemplate;
        const targetTaskTemplate = $event.container.data as MilestoneTemplate;
        // Flyt imellem lister
        if($event.previousContainer !== $event.container) {
            transferArrayItem($event.previousContainer.data.task_templates, targetTaskTemplate.task_templates, $event.previousIndex, $event.currentIndex);
            taskTemplate.milestone_template_id = targetTaskTemplate.id;
            Api.taskTemplates().patchById(taskTemplate.id).save(taskTemplate, () => {
                this.showToast();
            });
        } else {
            moveItemInArray(this.milestoneTemplate.task_templates, $event.previousIndex, $event.currentIndex);
        }

        const indexes = this.milestoneTemplate.task_templates.map(m => m.id);
        Api.taskTemplates().indexingPut().save({values: indexes}, (r) => {
        })
    }


    getMappedTaskDeadlineType(taskTemplate: TaskTemplate): DeadlineTypeType {
        return this.taskDeadlineTypeMap.get(String(taskTemplate.deadline_type));
    }

    getMappedMilestoneDeadlineType(milestoneTemplate: MilestoneTemplate): DeadlineTypeType {
        return this.milestoneDeadlineTypeMap.get(String(milestoneTemplate.deadline_type));
    }

    private setPhase() {
        this.milestoneTemplate.phase = this.phases && this.milestoneTemplate.phase_id ? this.phases.find(p => p.id == this.milestoneTemplate.phase_id) : null;
    }
}
