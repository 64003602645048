import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";
import {
    DataFetcherCollectionLoaderComponent
} from "@app/pages/displays/display-projects/data-fetcher-collection-loader/data-fetcher-collection-loader.component";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        TypedTemplateDirective,
        LoadingIndicatorModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DataFetcherCollectionLoaderComponent,
    ],
    exports: [
        DataFetcherCollectionLoaderComponent

    ],
    providers: [

    ],
})
export class DataFetcherCollectionLoaderModule {}
