<div
    *ngIf="showStatusEditor"
    class="d-flex justify-content-between flex-row"
>
    <app-status-editor
        class="me-1"
        [model]="item"
        [configuration]="statusEditorConfiguration"
    />
</div>

<div class="d-flex align-items-center flex-grow-0 icon-row justify-content-start">
    <ul
        class="list-unstyled d-flex flex-row justify-content-between m-0 w-100 align-items-center gap-2"
        style="height: 24px;"
    >

        <!-- Private -->
        <li
            *ngIf="showIsPrivateEditor"
            class="pe-1 me-1 pe-1"
        >
            <app-is-private-editor
                [model]="item"
                [configuration]="isPrivateEditorConfiguration"
            />
        </li>

        <li
            *ngIf="showReactionListEditor"
            class="pe-1 border-start"
        >
            <app-reaction-list-editor
                [model]="item"
                [configuration]="reactionListEditorConfiguration"
            />
        </li>

        <!-- Copy -->
        <li class="pe-1 ms-2 ps-2 -pe-1">
            <app-toggle-item
                (click)="onCopyBtnClicked()"
                [ngbTooltip]="('_ui_copy' | translate) + '...'"
                [iconWeightActive]="'fal'"
                [icon]="'fa-copy'"
                class="cursor-pointer"
            ></app-toggle-item>
        </li>

        <li
            class="pe-1 d-flex"
            *ngIf="showValidationWarning && validator">
            <app-validation-warning-list
                [inline]="false"
                [validatorError]="validator"
            />
        </li>

        <li class="pe-1">
            <app-find-and-focus-btn
                [matchType]="FocusableEntityTypes.Todo"
                [matchIdentifier]="item.id"
            />
        </li>

    </ul>

</div>
