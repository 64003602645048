<div class="container-fluid p-0 h-100" [class.exporting]="isCapturingScreenshot">
    <div class="d-flex flex-column h-100" *ngIf="initialized">
        <div class="flex-fill rounded position-relative" #dataTableContainer>
            <ngx-datatable class="material bootstrap datatable-min-height" id="dataTable"
                           #dataTable
                           ngxDatatableExtension [table]="dataTable"

                           [rows]="tableRows ?? []"
                           [columns]="tableColumns"
                           [columnMode]="'force'"
                           [scrollbarH]="true"
                           [scrollbarV]="true"
                           [headerHeight]="30"
                           [footerHeight]="30"
                           [rowHeight]="'auto'"
                           [loadingIndicator]="true"
                           [externalSorting]="true"
                           [virtualization]="false"
            >

                <ngx-datatable-footer>
                    <ng-template
                        ngx-datatable-footer-template
                        let-rowCount="rowCount"
                        let-pageSize="pageSize"
                        let-selectedCount="selectedCount"
                        let-curPage="curPage"
                        let-offset="offset"
                        let-isVisible="isVisible">

                        <!-- _ui_show_all -->
                        <div class="-ms-auto d-flex align-items-center">
                            <div class="d-flex align-items-center footer-actions -ms-auto pe-2 ps-2">
                                <a class="me-2  d-none"
                                   (click)="onCaptureScreenshotBtnClicked();"
                                   [ngbTooltip]="('_ui_screenshot' | translate)"
                                >
                                    <i class="fal fa-camera-viewfinder d-none" *ngIf="!isCapturingScreenshot"></i>
                                    <app-loading-indicator [showLogo]="false"
                                                           *ngIf="isCapturingScreenshot"></app-loading-indicator>
                                </a>

                                <a class="text-center me-2 " (click)="loadData()"
                                   style="transform: translateY(-1px);"
                                   [ngbTooltip]="('_ui_reload' | translate) + '...'"
                                   container="body"

                                   placement="auto"><i class="fal fa-light fa-arrow-rotate-right"></i></a>


                                <a class="no-caret text-white me-2"
                                   [ngbTooltip]="(!isMinimized ? ('_ui_show_less' | translate) : ('_ui_expand_lists' | translate)) + '...'"
                                   (click)="toggleIsMinimized()">
                                    <i class="fal"
                                       [class.fa-arrows-from-line]="isMinimized"
                                       [class.fa-arrows-to-line]="!isMinimized"></i>
                                </a>

                                <app-on-screen-filter-selector
                                    [onScreenFilters]="onScreenFilters"
                                ></app-on-screen-filter-selector>
                            </div>

                            <div class="d-flex" *ngIf="false">
                                <pre>curPage: {{curPage}}</pre>
                                <pre>pageSize: {{pageSize}}</pre>
                                <pre>rowCount: {{rowCount}}</pre>
                            </div>


                        </div>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
        </div>
    </div>
</div>

<!-- Header template -->
<ng-template #headerTemplate let-column="column" ngx-datatable-header-template
             [typedTemplate]="templateTypes.headerTemplateType"
>
    <ngx-datatable-column
        [name]="column.name"
        [width]="column.width"
        [maxWidth]="column.maxWidth"
        [minWidth]="column.minWidth"
        [sortable]="false"
        [resizeable]="column.resizeable">
        <div class="column-title ps-1 pe-1 d-flex align-items-center justify-content-start w-100">
            <app-column-sorter [display]="display" [column]="column"
                               [filterPageData]="shellPageData"
                               (columnSortChange)="onColumnSortChanged($event)"
            ></app-column-sorter>
        </div>
    </ngx-datatable-column>
</ng-template>
<!-- Header Milestone Card template -->
<ng-template #headerMilestoneCardTemplate let-column="column" ngx-datatable-header-template
             [typedTemplate]="templateTypes.headerTemplateType"
>
    <ngx-datatable-column
        [name]="column.name"
        [width]="column.width"
        [maxWidth]="column.maxWidth"
        [minWidth]="column.minWidth"
        [sortable]="false"
        [resizeable]="column.resizeable">
        <div class="column-title ps-1 pe-1 d-flex align-items-center justify-content-start w-100 h-100">
            <app-column-sorter [display]="display" [column]="column"
                               [filterPageData]="shellPageData"
                               (columnSortChange)="onColumnSortChanged($event)"
            ></app-column-sorter>
            <div
                class="ms-auto buttons d-flex flex-row justify-content-end align-items-center flex-shrink-0 overflow-hidden p-0 m-0 pe-2 flex-grow-1">

                <app-milestone-task-edit-who-selector-popover
                    class="me-1"
                    [tooltip]="'_ui_add_participant_to_all_and_responsible' | translate"
                    [selectedUsers]="selectedMilestonesResponsible"
                    (removeItem)="removeResponsibleFromAllMilestones($event)"
                    (addItem)="addResponsibleToAllMilestones($event)"
                ></app-milestone-task-edit-who-selector-popover>

                <app-create-item-dropdown
                    class="me-1"
                    [placement]="'bottom'"
                    [source]="createItemSourceConfiguration"
                    [createItemPreset]="createItemPreset"
                    [container]="'body'"
                    *ngIf="createItemSourceConfiguration"
                    [label]="('_global_create' | translate) +' '+('_milestone' | translate | lowercase) +'...'"
                ></app-create-item-dropdown>
            </div>
        </div>
    </ngx-datatable-column>
</ng-template>
<!-- Header Period template -->
<ng-template #headerWithPeriodTemplate
             let-tableColumn="column"
             ngx-datatable-header-template
             [typedTemplate]="templateTypes.headerTaskListPeriodType"
>
    <ngx-datatable-column
        [name]="tableColumn.name"
        [width]="tableColumn.width"
        [maxWidth]="tableColumn.maxWidth"
        [minWidth]="tableColumn.minWidth"
        [sortable]="false"
        [resizeable]="tableColumn.resizeable">
        <div class="column-title ps-1 pe-1 d-flex align-items-center justify-content-start w-100">
            <span [innerHTML]="tableColumn.getPeriodString()" class="me-1 rounded p-0 ps-1 pe-1 text-small border"></span>
            <app-column-sorter [display]="display" [column]="tableColumn"
                               [filterPageData]="shellPageData"
                               [onRenderEvent]="onPeriodColumnSortRenderEventEmitter"
                               (columnSortChange)="onColumnSortChanged($event)"
            ></app-column-sorter>
        </div>
    </ngx-datatable-column>
</ng-template>
<!-- Header Status template -->
<ng-template #headerWithStatusTemplate
             let-tableColumn="column"
             ngx-datatable-header-template
             [typedTemplate]="templateTypes.headerTaskListStatusType"
>
    <ngx-datatable-column
        [name]="tableColumn.name"
        [width]="tableColumn.width"
        [maxWidth]="tableColumn.maxWidth"
        [minWidth]="tableColumn.minWidth"
        [sortable]="false"
        [resizeable]="tableColumn.resizeable">
        <div class="column-title ps-1 pe-1 d-flex align-items-center justify-content-start w-100">
            <span class="circle {{tableColumn.getColorClass()}} me-1"></span>
            <app-column-sorter [display]="display" [column]="tableColumn"
                               [filterPageData]="shellPageData"
                               [onRenderEvent]="onStatusColumnSortRenderEventEmitter"
                               (columnSortChange)="onColumnSortChanged($event)"
            ></app-column-sorter>
        </div>
    </ngx-datatable-column>
</ng-template>
<!-- Header template with YearWheel PeriodPicker -->
<ng-template
    #headerTemplateYearWheel
    let-tableColumn="column"
    ngx-datatable-header-template
    [typedTemplate]="templateTypes.headerYearWheelTemplateType"
>
    <ngx-datatable-column [name]="tableColumn.name" [width]="tableColumn.width" [maxWidth]="tableColumn.maxWidth"
                          [minWidth]="tableColumn.minWidth" [resizeable]="tableColumn.resizeable">
        <app-year-wheel-column
            [yearWheelPeriod]="tableColumn.column.yearWheelPeriod"
            [yearWheelDisplayOptions]="tableColumn.column.yearWheelDisplayOptions"
            (onYearWheelPeriodChanged)="tableColumn.column.yearWheelPeriod = $event"
            (onYearWheelDateChanged)="onYearWheelDateChanged($event)"
            (onYearWheelUnitChanged)="onYearWheelUnitChanged($event)"
            (onYearWheelOptionsChanged)="onYearWheelOptionsChanged($event)"
        />
    </ngx-datatable-column>
</ng-template>

<!-- Header Task Edit template -->
<ng-template #headerTaskEditTemplate
             let-tableColumn="column"
             ngx-datatable-header-template
             [typedTemplate]="templateTypes.headerTaskEditTemplateType"
>
    <app-card-vertical-edit-columns
        [enabledEditors]="tableColumn.column.enabledEditors"
    />
</ng-template>

<!-- Milestone Card template -->
<ng-template #cellMilestoneCardTemplate let-row="row" let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTemplateType"
>
    <div class="wrap d-flex flex-column position-relative h-100">
        <app-card-milestone [item]="row.milestoneCard"></app-card-milestone>
        <div
            class="action-panel placement-bottom mt-auto d-flex d-row justify-content-end align-items-center rounded pb-1 -w-100 ps-2 pe-2 -corner-item wide"
            [class.disabled]="isPendingRequests"
        >
            <a class="text-center me-1 ms-1" (click)="reload()"
               [ngbTooltip]="('_ui_reload' | translate) + '...'"
               container="body"
               placement="right"><i class="fal fa-light fa-arrow-rotate-right"></i></a>
            <a class="text-center me-1"
               [ngbTooltip]="('_ui_hide_extra_items' | translate) + '...'"
               container="body"
               placement="right"
               (click)="row.minimizeEvent.emit()"><i class="fal fa-minus-square"></i></a>
        </div>
    </div>
</ng-template>

<!-- <editor-fold desc="Inddatering"> -->

<!-- Task Edit List template -->
<ng-template #cellTaskEditListTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex" ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTaskEditType"
>
    <app-task-list
        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
        class="w-100"
        [configuration]="tableColumn.column.getCell(row).listConfiguration"
        [minimizeEvent]="row.minimizeEvent"
        (dataSetChanged)="setRowTaskEditListItems(row, $event)">
    </app-task-list>
</ng-template>
<!-- Task Edit WhoWhen Header Template -->
<ng-template #cellTaskEditListWhoWhenHeaderTemplate
             let-tableColumn="column"
             [typedTemplate]="templateTypes.cellTemplateType"
>
    <div class="column-title ps-1 pe-1 d-flex align-items-center justify-content-start w-100">
        <span class="flex-shrink-1 ellipsis"
              [translate]="tableColumn.name"
              [ngbTooltip]="tableColumn.name | translate"></span>
        <div
            class="ms-auto buttons d-flex flex-row justify-content-end align-items-center flex-shrink-0 flex-grow-1 overflow-hidden p-0 m-0 pe-2">

            <app-milestone-task-edit-who-selector-popover
                [tooltip]="'_ui_add_participant_to_all' | translate"
                [selectedUsers]="selectedParticipants"
                (addItem)="addParticipantToAllTasks($event)"
                (removeItem)="removeParticipantFromAllTasks($event)"
            ></app-milestone-task-edit-who-selector-popover>
        </div>
    </div>
</ng-template>

<!-- Milestone Edit List template -->
<ng-template #cellMilestoneEditListTemplate
             let-row="row"
             let-tableColumn="column"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellMilestoneEditType"
>
    <ul class="list-unstyled d-flex flex-row justify-content-end m-0 align-items-center w-100">

        <li class="me-3 d-flex align-items-start">
            <app-create-item-dropdown
                style="transform: translateY(-1px);"
                [placement]="'bottom'"
                [source]="tableColumn.column.getCell(row).createItemConfiguration"
                [createItemPreset]="tableColumn.column.getCell(row).createItemPreset"
                [label]="('_global_create' | translate) +' '+('_task' | translate | lowercase) +'...'"
                [container]="'body'"
            ></app-create-item-dropdown>
        </li>
        <li class="me-3 d-flex align-items-start"
            *ngIf="tableColumn.column.isDragDropVisible && (row.allowMilestoneMoveUp || row.allowMilestoneMoveDown)">
            <div role="menuitem"
                 [class.disabled]="!row.allowMilestoneMoveUp"
                 (click)="row.moveMilestoneUp()"
                 [ngbTooltip]="('_ui_filter_move_up' | translate)"
                 class="fal fa-arrow-up cursor-pointer me-1">
            </div>
            <div role="menuitem"
                 [class.disabled]="!row.allowMilestoneMoveDown"
                 (click)="row.moveMilestoneDown()"
                 [ngbTooltip]="('_ui_filter_move_down' | translate)"
                 class="fal fa-arrow-down cursor-pointer">
            </div>
        </li>

        <li class="me-3" *ngIf="tableColumn.column.isArchiveBtnVisible">
            <app-toggle-item [interactive]="true"
                             [active]="row.milestoneCard.item.archived_id > 0"
                             [icon]="'fa-check-circle'"
                             [iconInactive]="'fa-circle'"
                             [ngbTooltip]="(row.milestoneCard.item.archived_id ? translateService.instant('_ui_reopen') :  translateService.instant('_ui_complete') ) + ' ' + ('_milestone' | translate | lowercase)"
                             (onToggle)="row.archiveMilestoneToggle()"></app-toggle-item>
        </li>

        <li class="" *ngIf="tableColumn.column.isDeleteBtnVisible">
            <button role="button" class="btn btn-sm btn-danger "
                    [ngbTooltip]="('_global_delete' | translate) + ' ' + ('_milestone' | translate | lowercase) +'...'"
                    (click)="row.deleteMilestone()">
                <i class="fal fa-trash-alt" aria-hidden="true"></i>
            </button>
        </li>
    </ul>
</ng-template>

<!-- </editor-fold> -->

<!-- Task List Status template -->
<ng-template #cellTaskListStatusTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex" ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTaskListStatusType"
>
    <app-task-list
        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
        class="w-100"
        [configuration]="tableColumn.column.getCell(row).listConfigurations.get(tableColumn.status)"
        [minimizeEvent]="row.minimizeEvent">
    </app-task-list>
</ng-template>

<!-- Task List Period template -->
<ng-template #cellTaskListPeriodTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex" ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTaskListPeriodType"
>
    <app-warning-label *ngIf="showPeriodColumnWarning"></app-warning-label>
    <app-task-list
        *ngIf="!showPeriodColumnWarning"
        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
        class="w-100"
        [configuration]="tableColumn.column.getCell(row).listConfigurations.get(tableColumn.periodType)"
        [minimizeEvent]="row.minimizeEvent">
    </app-task-list>
</ng-template>

<!-- Task List template -->
<ng-template #cellTaskListTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex" ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTaskListAllType"
>
    <app-task-list
        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
        class="w-100"
        [configuration]="tableColumn.column.getCell(row).listConfiguration"
        [minimizeEvent]="row.minimizeEvent">
    </app-task-list>
</ng-template>

<!-- Year-wheel template -->
<ng-template #cellYearWheelTemplate
             let-row="row"
             let-tableColumn="column"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellMilestoneYearWheelType"
>
    <app-year-wheel
        [configuration]="tableColumn.column.getCell(row).yearWheelConfiguration"
        [reloadEvent]="row.reloadYearWheelEvent"
        [rerenderEvent]="row.rerenderYearWheelEvent"
    ></app-year-wheel>
</ng-template>
