export class ColumnTypeSettings {

    public static ProjectUserTypes = 'project-user-types';
    public static ProjectEstimateTypes = 'project-estimate-types';
    public static ProjectDeadlineTypes = 'project-deadline-types';
    public static ProjectDeadlineTypeFromAndTo = 'project-deadline-type-from-and-to';
    public static ProjectNoteField = 'project-note-field';
    public static ProjectStatusTypes = 'project-status-types';
    public static YearWheelProjectDeadlineTypes = 'year-wheel-project-deadline-types'

}
