import {NgModule} from "@angular/core";
import {DisplayProjectsComponent} from "@app/pages/displays/display-projects/display-projects.component";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {ColumnSorterModule} from "@app/shared/_ui/column-sorter/ColumnSorter.module";
import {OnScreenFilterSelectorModule} from "@app/shared/_ui/on-screen-filter-selector/OnScreenFilterSelector.module";
import {ColumnDebuggerModule} from "@app/shared/_elements/column-debugger/ColumnDebugger.module";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {CreateItemDropdownModule} from "@app/shared/_ui/create-item-dropdown/CreateItemDropdown.module";
import {DateRangePickerModule} from "@app/shared/_elements/date-range-picker/DateRangePicker.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbDropdownModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {HeaderSpacerModule} from "@app/shared/_elements/header-spacer/HeaderSpacer.module";
import {ItemNoteModule} from "@app/shared/_elements/item-note/ItemNote.module";
import {TaskListModule} from "@app/shared/_ui/lists/task-list/TaskList.module";
import {MilestoneListModule} from "@app/shared/_ui/lists/milestone-list/MilestoneList.module";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {ItemDateModule} from "@app/shared/_elements/item-date/ItemDate.module";
import {CurrentPhasesProjectModule} from "@app/shared/_elements/current-phases-project/CurrentPhasesProject.module";
import {PhasesProjectsModule} from "@app/shared/_ui/columns/phases-projects/PhasesProjects.module";
import {CategoriesModule} from "@app/shared/_ui/columns/categories/Categories.module";
import {DeadlineDifferenceModule} from "@app/shared/_elements/deadline-difference/DeadlineDifference.module";
import {
    ProjectCalculatedFieldModule
} from "@app/shared/_elements/project-calculated-field/ProjectCalculatedField.module";
import {AppointmentListModule} from "@app/shared/_ui/lists/appointment-list/AppointmentList.module";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {TodoListModule} from "@app/shared/_ui/lists/todo-list/TodoList.module";
import {YearWheelModule} from "@app/shared/_ui/columns/year-wheel/YearWheel.module";
import {ScrollDetectorDirectiveModule} from "@app/directives/ScrollDetectorDirective.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";
import {ScrollDirectiveModule} from "@app/directives/ScrollDirective.module";
import {ProjectStatusesModule} from "@app/shared/_ui/columns/project-statuses/ProjectStatuses.module";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";
import {VisibilityToggleModule} from "@app/shared/_ui/visibility-toggle/VisibilityToggle.module";
import {
    ProjectUserListCellModule
} from "@app/pages/displays/display-projects/CellComponents/project-user-list-cell/project-user-list-cell.module";
import {
    ProjectEstimateListCellModule
} from "@app/pages/displays/display-projects/CellComponents/project-estimate-list-cell/project-estimate-list-cell.module";
import {
    ProjectDeadlineListCellModule
} from "@app/pages/displays/display-projects/CellComponents/project-deadline-list-cell/project-deadline-list-cell.module";
import {
    ProjectDeadlineDifferenceCellModule
} from "@app/pages/displays/display-projects/CellComponents/project-deadline-difference-cell/project-deadline-difference-cell.module";
import {
    ProjectNoteCellModule
} from "@app/pages/displays/display-projects/CellComponents/project-note-cell/project-note-cell.module";
import {
    ProjectStatusListCellModule
} from "@app/pages/displays/display-projects/CellComponents/project-status-list-cell/project-status-list-cell.module";
import {
    DataFetcherCollectionLoaderModule
} from "@app/pages/displays/display-projects/data-fetcher-collection-loader/data-fetcher-collection-loader.module";
import {YearWheelColumnModule} from "@app/shared/_ui/columns/year-wheel/year-wheel-column/year-wheel-column.module";
import {NgxDatatableExtensionModule} from "@app/directives/NgxDatatableExtension.module";

@NgModule({
    imports: [
        CommonModule,
        ToggleItemModule,
        LoadingIndicatorModule,
        NgxDatatableModule,
        ColumnSorterModule,
        OnScreenFilterSelectorModule,
        ColumnDebuggerModule,
        LocalizedDatePipeModule,
        CreateItemDropdownModule,
        DateRangePickerModule,
        TranslateModule,
        NgbTooltipModule,
        HeaderSpacerModule,
        CardsModule,
        ItemNoteModule,
        TaskListModule,
        MilestoneListModule,
        UserWithIconModule,
        ItemDateModule,
        CurrentPhasesProjectModule,
        PhasesProjectsModule,
        CategoriesModule,
        DeadlineDifferenceModule,
        ProjectCalculatedFieldModule,
        AppointmentListModule,
        ColorLabelModule,
        TodoListModule,
        YearWheelModule,
        NgbDropdownModule,
        ScrollDetectorDirectiveModule,
        ScrollDirectiveModule,
        ProjectStatusesModule,
        TypedTemplateDirective,
        VisibilityToggleModule,
        ProjectUserListCellModule,
        ProjectEstimateListCellModule,
        ProjectDeadlineListCellModule,
        ProjectDeadlineDifferenceCellModule,
        ProjectNoteCellModule,
        ProjectStatusListCellModule,
        DataFetcherCollectionLoaderModule,
        YearWheelColumnModule,
        NgxDatatableExtensionModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DisplayProjectsComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class DisplayProjectsModule {}
