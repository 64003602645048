export const MilestonePresetTypes = {
    Title:  'title',
    Project:  'project',
    Task:  'task',
    Responsible:  'responsible',
    Deadline:  'deadline',
    Status:  'status',
    Category: 'category',
    UseStatusRules: 'use-status-rules',
};

export type MilestonePresetType =
    typeof MilestonePresetTypes.Title
    | typeof MilestonePresetTypes.Project
    | typeof MilestonePresetTypes.Task
    | typeof MilestonePresetTypes.Responsible
    | typeof MilestonePresetTypes.Deadline
    | typeof MilestonePresetTypes.Status
    | typeof MilestonePresetTypes.Category
    | typeof MilestonePresetTypes.UseStatusRules
;
