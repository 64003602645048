/**
 * Created by ModelParser
 * Date: 12-05-2021.
 * Time: 14:46.
 */
import {ProjectTypesReactionTypeDefinition} from './definitions/ProjectTypesReactionTypeDefinition';
import {ReactionsTypeInterface} from "@app/shared/_ui/reactions/ReactionsTypeInterface";

export class ProjectTypesReactionType extends ProjectTypesReactionTypeDefinition implements ReactionsTypeInterface {

    constructor(json?: any) {
        super(json);
    }

}
