import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {Color, Phase} from '@app/core/models';

@Component({
    selector: 'app-phase',
    templateUrl: './phase.component.html',
    styleUrls: ['./phase.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhaseComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings
    @Input() phase: Phase;
    @Input() ellipsis = false;

    // UI
    public name: string;
    public color: Color;


    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.render();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        this.name = this.phase?.name ?? '';
        this.color = this.phase?.color ?? null;
    }

}
