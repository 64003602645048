import {Department, Display, TeamDisplayUserPosition, User} from "@app/core/models";
import {Api} from "@app/core/http/Api/Api";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";
import {BaseComparison} from "@app/shared/_ui/lists/BaseComparison";
import {Filters} from "@app/pages/displays/display-team/Helpers/Filters";

export class TeamDisplaySortHelper {

    private displayId: number;
    private departmentId: number
    private teamDisplayUserPositions?: TeamDisplayUserPosition[];

    constructor(displayId: number, departmentId: number) {
        this.displayId = displayId;
        this.departmentId = departmentId;
    }

    private ensureTeamDisplayUserPositions(callback: (items: TeamDisplayUserPosition[]) => void) {
        if (!this.teamDisplayUserPositions) {
            Api.teamDisplayUserPositions().get()
                .where('departments_display.display_id', this.displayId)
                .where('departments_display.department_id', this.departmentId)
                .where('owner_id', AppInjector.getInjector().get(UsersService).user.id)
                .find(items => {
                    this.teamDisplayUserPositions = items;
                    callback(this.teamDisplayUserPositions);
                });
        } else {
            callback(this.teamDisplayUserPositions);
        }
    }

    public performSort(items: User[], type: string, direction: string, callback: (items: User[]) => void) {
        switch (type) {
            default:

            case Filters.SortName:
                items.sort((a, b) => {
                    return direction == 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
                });
                callback(items);
                break;

            case Filters.SortUserDefined:
                this.ensureTeamDisplayUserPositions(positions => {
                    const comparisons = [
                        new PositionComparison(positions),
                        new NameComparison(),
                    ];
                    items.sort((a, b) => {
                        let value = 0;
                        comparisons.find(comparison => {
                            value = comparison.compare(
                                direction == 'asc' ? a : b,
                                direction == 'asc' ? b : a
                            );
                            return value !== 0;
                        });
                        return value;
                    });
                    callback(items);
                });
                break;
        }
    }

    public savePositions(items: User[]) {
        const api = Api.displays().updateTeamDisplayUserPositionsPutByDisplayIdByDepartmentId(this.displayId, this.departmentId)
            .setShowProgressBar(false);
        api.save({
            values: items.map(user => user.id),
        }, response => {
            this.teamDisplayUserPositions = response as unknown as TeamDisplayUserPosition[];
        });
    }

}

class NameComparison extends BaseComparison {
    public compare(a: User, b: User): number {
        return a.name.localeCompare(b.name);
    }
}

class PositionComparison extends BaseComparison {
    private readonly positions: TeamDisplayUserPosition[];

    constructor(positions: TeamDisplayUserPosition[]) {
        super();
        this.positions = positions;
    }

    public compare(a: User, b: User): number {
        const aPos = this.positions.find(position => position.user_id == a.id);
        const bPos = this.positions.find(position => position.user_id == b.id);
        return (aPos?.position ?? 0) - (bPos?.position ?? 0);
    }
}
