<div class="list-group-item rounded mb-1 me-1 border-dark mb-3">

    <div class="d-flex -pb-1 -mb-1 -border rounded pt-1 w-100  align-items-start ">
        <div class="d-flex align-items-center cursor-pointer flex-row flex-shrink-1 flex-grow-1 w-100"
             [ngbTooltip]="('_ui_process_steps_select_active_phase_mouseover' | translate)"
             [placement]="['left', 'auto']"
             style="max-width: calc(100% - 240px);"
             (click)="selectPhasesProject.emit(phaseMilestoneRow.phasesProject)">

            <div>
                <div class="fas fa-dot-circle me-1"
                     *ngIf="phaseMilestoneRow.phasesProject.id == project.current_phases_project_id"></div>
                <div class="fal fa-circle me-1 "
                     *ngIf="phaseMilestoneRow.phasesProject.id != project.current_phases_project_id"></div>
            </div>

            <div class="flex-shrink-1 " style="width: inherit;">
                <app-phase class="d-block flex-shrink-1 " [phase]="phaseMilestoneRow.phasesProject.phase"
                           style="transform: translateY(0px);"
                           [ellipsis]="true"></app-phase>
            </div>
        </div>
        <div class="ms-auto d-flex align-items-center pe-1 ">

            <label class="pe-1 text-black-50 m-0">{{('_ui_phase_deadline' | translate)}}</label>
            <app-item-date class="me-1 pe-2 border-end"
                           [showIcon]="false"
                           [showDatepicker]="true"
                           [showClear]="true"
                           [ellipsis]="true"
                           [label]="translateService.instant('_ui_phase_deadline')"
                           [date]="phaseMilestoneRow.phasesProject.getStartedDate()"
                           (onChangeDate)="updatePhasesProjectDate($event, phaseMilestoneRow)"
                           *ngIf="phaseMilestoneRow.phasesProject.getStartedDate() || true"></app-item-date>

            <app-create-item-dropdown
                [class.text-black-50]="!phaseMilestoneRow.phasesProject.getStartedDate()"
                [ngbTooltip]="phaseMilestoneRow.phasesProject.getStartedDate() ? ('_global_create' | translate) +': '+ ('_milestone' | translate) : ('_ui_phase_missing_deadline' | translate)"
                [source]="createItemSourceConfiguration"
                [createItemPreset]="createItemPreset"
                [placement]="'auto'"
                [container]="'body'"
                [disabled]="!phaseMilestoneRow.phasesProject.getStartedDate()"
            />

            <span
                [ngbTooltip]="('_global_delete' | translate) +': '+ ('_phase_singular' | translate)"

                class="fal fa-trash-alt text-danger cursor-pointer me-1"
                (click)="deletePhaseMilestoneRow(phaseMilestoneRow)"></span>

        </div>

    </div>

    <ng-container *ngIf="phaseMilestoneRow?.phasesProject?.phase &&
    phaseMilestoneRow?.phasesProject?.phase.phase_progress_types_phases && phasesExist(phaseMilestoneRow?.phasesProject?.phase.phase_progress_types_phases)"
    >
        <div class="w-100 pt-1 bt-1 border-top mt-1">
            <span class="text-black-50 text-small">{{'_ui_phase_progress' | translate}}</span>
            <ul class="list-unstyled">
                <li *ngFor="let phaseProgressPhase of phaseMilestoneRow?.phasesProject?.phase.phase_progress_types_phases">

                    <div class="d-flex align-items-center cursor-pointer"
                         [class.disabled]="!phaseMilestoneRow?.phasesProject"
                         *ngIf="phaseProgressPhase.visible && phaseProgressPhase.phase_progress_type"
                         (click)="selectPhasesProjectProgressType.emit({phaseProgressType: phaseProgressPhase.phase_progress_type, phasesProject: phaseMilestoneRow?.phasesProject});">

                        <app-toggle-item [interactive]="false"
                                         [active]="phaseProgressPhase.phase_progress_type_id == phaseMilestoneRow?.phasesProject?.current_phase_progress?.to_phase_progress_type_id"
                                         [icon]="phaseMilestoneRow.phasesProject.id == project.current_phases_project_id ? 'fa-dot-circle' : 'fa-circle'"
                                         [iconInactive]="'fa-circle'"
                                         [iconWeightActive]="phaseMilestoneRow.phasesProject.id == project.current_phases_project_id ? 'fas' : 'fal'"
                                         class="me-1"
                        ></app-toggle-item>

                        <span
                            *ngIf="phaseProgressPhase.phase_progress_type">{{phaseProgressPhase.phase_progress_type?.name}}</span>
                    </div>
                </li>
            </ul>
        </div>
    </ng-container>

    <ng-container *ngIf="phaseMilestoneRow.milestones && phaseMilestoneRow.milestones.length > 0">
        <span class="text-black-50 text-small">{{'_ui_process_steps_milstones_and_phases_title' | translate}}</span>
    </ng-container>

    <ng-container *ngFor="let milestoneRow of phaseMilestoneRow.milestones">
        <div class="d-flex flex-column -border-bottom pb-1 -mb-1 pt-1 list-group-item rounded m-1">
            <div class="d-flex align-items-center">

                <div class="d-flex flex-row no-wrap align-items-center flex-grow-1">
                    <div class="pe-1 pb-1">
                        <app-small-card-milestone
                            [configuration]="cardMilestoneConfiguration"
                            [item]="milestoneRow.card"></app-small-card-milestone>
                    </div>

                    <div class="d-flex flex-column align-items-end  flex-grow-1">

                        <div class="d-flex align-items-center"
                             *ngIf="milestoneRow.card.item.deadline && milestoneRow.card.item.deadline.getDate()">

                            <label
                                class="pe-1 text-black-50 m-0">{{('_ui_project_milestone_deadline' | translate)}}</label>

                            <app-item-date [showIcon]="false" [label]="('_ui_project_milestone_deadline' | translate)"
                                           [date]="milestoneRow.card.item.deadline.getDate()"></app-item-date>
                        </div>
                        <div class="text-small d-flex align-items-center" *ngIf="milestoneRow.card.item.responsible_id">
                            <app-user-with-icon [label]="('_milestone_responsible' | translate)"
                                                [modelId]="milestoneRow.card.item.responsible_id"></app-user-with-icon>
                        </div>
                    </div>

                </div>

                <div class="d-flex ms-auto ps-1">
                    <app-create-item-dropdown
                        [source]="milestoneRow.createItemSourceConfiguration"
                        [createItemPreset]="milestoneRow.createItemPreset"
                        [placement]="'auto'"
                        [container]="'body'"
                        [label]="('_global_create' | translate) +': '+ ('_task' | translate)"
                    />
                    <span
                        [ngbTooltip]="('_global_delete' | translate) +': '+ ('_milestone' | translate)"
                        class="fal fa-trash-alt text-danger cursor-pointer"
                        (click)="deleteMilestone(milestoneRow.card)"></span>
                </div>
            </div>


            <ng-container *ngIf="milestoneRow.card.item.tasks && milestoneRow.card.item.tasks.length > 0">
                <span class="text-black-50 text-small">{{'_ui_process_attached_tasks' | translate}}</span>

                <ul class="list-unstyled mt-1 pt-1">
                    <li *ngFor="let task of milestoneRow.card.item.tasks">
                        <div class="d-flex align-items-center"
                             *ngIf="task"

                        >
                            <div class="d-flex align-items-center cursor-pointer"
                                 (click)="toggleTaskArchived.emit({ task: task, cardMilestone: milestoneRow.card, phaseMilestoneRow: phaseMilestoneRow})">
                                <app-toggle-item [interactive]="false"
                                                 [active]="task.archived_id != 0"
                                                 [icon]="'fa-check-circle'"
                                                 [iconInactive]="'fa-circle'"
                                                 [ngbTooltip]="task.archived_id != 0 ? translateService.instant('_ui_reopen') :  translateService.instant('_ui_complete')"
                                                 class="me-1"
                                ></app-toggle-item>
                                <span>{{task.title}}</span>
                            </div>

                            <div class="d-flex ms-auto">
                                <span
                                    [ngbTooltip]="('_global_edit' | translate) +': '+ ('_task' | translate)"
                                    class="fal fa-pencil cursor-pointer me-1"
                                    (click)="editTask(task)"></span>

                                <span
                                    [ngbTooltip]="('_global_delete' | translate) +': '+ ('_task' | translate)"

                                    class="fal fa-trash-alt text-danger cursor-pointer "
                                    (click)="deleteTask( task, phaseMilestoneRow, milestoneRow.card)"></span>
                            </div>

                        </div>
                    </li>
                    <li *ngIf="phaseMilestoneRow.milestoneTasksComplete.get(milestoneRow.card.item.id) != milestoneRow.card.item.tasks.length"
                        (click)="toggleAllTasksArchived.emit( { cardMilestone: milestoneRow.card, phaseMilestoneRow: phaseMilestoneRow})"
                        class="cursor-pointer"
                    >
                        <span
                            class="fal fa-arrow-circle-right me-1"></span><span>{{'_ui_progress_complete' | translate}}</span>

                    </li>
                </ul>
            </ng-container>
        </div>
    </ng-container>
</div>
