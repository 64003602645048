import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseEditorComponent} from '@app/editor/base-editor/base-editor.component';
import {PANEL_ANIMATION} from '@app/editor/panel.animation';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {NgbNavChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {ProjectListConfiguration} from "@app/shared/_ui/lists/project-list/ProjectListConfiguration";
import {Department} from "@app/core/models";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";

@Component({
    selector: 'app-non-planned-item-list',
    templateUrl: './non-planned-item-list.component.html',
    styleUrls: ['../base-editor/base-editor.component.scss'],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NonPlannedItemListComponent extends BaseEditorComponent implements OnInit {

    // UI: Tabs
    public Tabs = {
        Projects: 'tab-projects',
        Tasks: 'tab-tasks',
    };
    public selectedTab = this.Tabs.Tasks;

    // UI: Lists
    public taskListConfiguration: TaskListConfiguration;
    public projectListConfiguration: ProjectListConfiguration;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        // Setup: UI
        this.initialized = false;
        this.autoClose = false;
        this.isOpen = false;
        this.autoPosition = false;

        // Setup: Lists
        this.taskListConfiguration = new TaskListConfiguration()
            .setOpen(true)
            .setArchived(false)
            .setUseGlobalFilter(true)
            .setAcceptanceStatus(null)
            .setShowProjectMiniCard(false)
            .setShowMilestoneMiniCard(false)
            .setOrderBy([['tasks_deadline.deadline.date', 'null'], ['tasks_deadline.deadline.date', 'asc'], ['id', 'desc']]);
        this.taskListConfiguration
            .setDraggable(true)
            .setAllowDragEnter(false)
            .setShowCreateNew(false);

        this.projectListConfiguration = new ProjectListConfiguration();
        this.projectListConfiguration
            .setOpen(true)
            .setArchived(false)
            .setShowMiniCard_NextMilestone(false)
            .setUseGlobalFilter(true)
            .setDraggable(false)
            .setAllowDragEnter(false)
            .setShowCreateNew(false);

        const pageSettings = this.shellService.getPageSettings();
        if (pageSettings) {
            if (pageSettings.departmentId) {
                const department = new Department({id: pageSettings.departmentId});
                this.projectListConfiguration.setDepartment(department)
                this.taskListConfiguration.setDepartment(department)
            } else {
                this.projectListConfiguration.setDepartment(null);
                this.taskListConfiguration.setDepartment(null);
            }
        }

        const taskFetcher = new TaskFetcher();
        taskFetcher.addRequest(new TaskFetchRequest(this.taskListConfiguration));
        taskFetcher.execute();

        // Open now...
        this.initialized = true;
        this.isOpen = true;
        this.gotoTab();
    }

    // <editor-fold desc="View Actions">

    public tabChange($event: NgbNavChangeEvent) {
        this.gotoTab($event.nextId);
    }

    public close() {
        this.shellService.onShowNonPlannedSidebarChangeEvent.emit(false);
    }

    private gotoTab(tab: string = this.selectedTab) {
        this.selectedTab = tab;
        this.detectChanges();
    }

    // </editor-fold>

}
