import {SharedComponentResizeObserver} from "@app/core/ResizeObserver/SharedComponentResizeObserver";

export class SharedComponentResizeObserverCache {

    // day[monday, tuesday,..] -> SharedComponentResizeObserver
    private cache = new Map<string, SharedComponentResizeObserver>();

    public get(identifier: string): SharedComponentResizeObserver {
        if (!this.cache.has(identifier)) {
            this.cache.set(identifier, new SharedComponentResizeObserver());
        }
        return this.cache.get(identifier);
    }

    public clear() {
        this.cache.clear();
    }

}
