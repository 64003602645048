/**
 * Created by ModelParser
 * Date: 26-01-2022.
 * Time: 12:20.
 */
import {DisplayFiltersTaskTypeDefinition} from './definitions/DisplayFiltersTaskTypeDefinition';

export class DisplayFiltersTaskType extends DisplayFiltersTaskTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
