import {BaseComparison} from "@app/shared/_ui/lists/BaseComparison";
import {HasHansUps} from "@app/interfaces/HasHansUps";

export class HandUpsComparison extends BaseComparison {

    public compare(a: HasHansUps, b: HasHansUps): number {
        return (a.num_hand_ups ?? 0) - (b.num_hand_ups ?? 0);
    }

}
