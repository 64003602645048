import {NgModule} from "@angular/core";
import {
    CurrentPhasesProjectComponent
} from "@app/shared/_elements/current-phases-project/current-phases-project.component";
import {PhaseModule} from "@app/shared/_elements/phase/Phase.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        PhaseModule,
        NgbTooltipModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CurrentPhasesProjectComponent,
    ],
    exports: [
        CurrentPhasesProjectComponent

    ],
    providers: [

    ],
})
export class CurrentPhasesProjectModule {}
