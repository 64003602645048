/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ProjectEstimateDefinition} from './definitions/ProjectEstimateDefinition';
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';
import {Estimate} from '@app/core/models/Estimate';

export class ProjectEstimate extends ProjectEstimateDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static Create(typeId: number, estimate: Estimate): ProjectEstimate {
        const item = new ProjectEstimate();
        item.project_estimate_type_id = typeId;
        item.estimate = estimate;
        return item;
    }

    public static createDefault() {
        let item = new ProjectEstimate();
        item.id = 0;
        item.estimate = Estimate.createDefault();
        return item;
    }

    public print(): string {
        let value = this.estimate.value + "";
        if (this.estimate.estimate_unit) {
            value += " " + AppInjector.getInjector().get(TranslateService).instant(this.estimate.estimate_unit.language_key);
        }
        return value;
    }

}
