/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {RoleDefinition} from './definitions/RoleDefinition';

export class Role extends RoleDefinition {

    constructor(json?: any) {
        super(json);
    }

}
