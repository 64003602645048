import {NgModule} from "@angular/core";
import {ProjectStatusesComponent} from "@app/shared/_ui/columns/project-statuses/project-statuses.component";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ColorLabelModule,
        TranslateModule,
        NgbTooltipModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectStatusesComponent,
    ],
    exports: [
        ProjectStatusesComponent

    ],
    providers: [

    ],
})
export class ProjectStatusesModule {}
