/**
 * Created by ModelParser
 * Date: 12-05-2021.
 * Time: 12:00.
 */
import {ReactionDefinition} from './definitions/ReactionDefinition';

export class Reaction extends ReactionDefinition {

    constructor(json?: any) {
        super(json);
    }

}
