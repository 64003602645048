import {BaseComparison} from "@app/shared/_ui/lists/BaseComparison";
import {Category} from "@app/core/models";

export class CategoryComparison extends BaseComparison {

    private categoryTypeId: number;

    constructor(categoryTypeId: number) {
        super();
        this.categoryTypeId = categoryTypeId;
    }

    public compare(a: {categories?: Category[]}, b: {categories?: Category[]}): number {
        const aCategories = a.categories
            ?.filter(category => category.category_type_id == this.categoryTypeId)
            ?.sort((a, b) => a.name.localeCompare(b.name));
        const bCategories = b.categories
            ?.filter(category => category.category_type_id == this.categoryTypeId)
            ?.sort((a, b) => a.name.localeCompare(b.name));
        const aTitle = aCategories?.length ? aCategories[0].name : '';
        const bTitle = bCategories?.length ? bCategories[0].name : '';
        return aTitle.localeCompare(bTitle);
    }

}
