import {Project} from '@app/core/models/Project';
import {User} from '@app/core/models/User';
import {Display} from '@app/core/models/Display';
import {ListConfiguration} from '@app/shared/_ui/lists/ListConfiguration';
import {TodoFetcherRequest} from '@app/shared/_ui/lists/todo-list/TodoFetcher';
import {Todo} from '@app/core/models/Todo';
import * as moment from 'moment';
import {CreateItemInterface} from '@app/shared/_ui/create-item-dropdown/CreateItemInterface';
import {CreateItemSourceConfiguration} from '@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration';
import {CreateItemPreset} from '@app/shared/_ui/create-item-dropdown/CreateItemPreset';
import {CardInterface, CardItem} from "@app/shared/_ui/cards/CardItem";
import {CdkDropEvent} from "@app/interfaces/CdkDropEvent";
import {Subscription} from "rxjs";
import {EventService} from "@app/services/event.service";
import {AppInjector} from "@app/services/app-injector.service";
import {CardTodoConfiguration} from "@app/shared/_ui/cards/medium/card-todo/card-todo-configuration";
import {Deadline, Milestone} from "@app/core/models";
import Helpers from "@app/core/helpers";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import {CSVExportOptionInterface} from "@app/export/csv/CSVExportOptionInterface";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";

export class TodoListConfiguration<T = Todo> extends ListConfiguration<T> implements CreateItemInterface<Todo>,
    CardInterface<Todo>, ListDragInterface<T> {

    // Filter
    private project?: Project;
    private user?: User;
    private archived?: boolean;
    private avoidIds?: number[];
    private display?: Display;
    private hasDisplay?: boolean;
    private hasShowOnDisplay?: boolean;
    private orArchivedSince: Date;
    private useGlobalFilter: boolean;
    private search: string;
    private deadlineBetween: Date[];
    private deadlineValidator: (deadline: Deadline) => boolean;
    private deadlineValidatorDate: () => Date;
    private starred: boolean = null;
    private hasDeadline: boolean = null;

    // Hooks
    private beforeAddHook: ((item: CardItem<Todo>) => void);

    // Limit & Order
    private orderBy: string[][] = [['main_status.status_id', 'desc']];

    // Show/Hide
    private showProjectMiniCard: boolean = true;

    // Data Provider
    private dataSource: TodoFetcherRequest;

    constructor() {
        super();

        this.createItemConfiguration = new CreateItemSourceConfiguration();
        this.createItemConfiguration.showTasks = false;
        this.createItemConfiguration.showProjects = false;
        this.createItemConfiguration.showMilestone = false;
        this.createItemConfiguration.showTodo = true;
        this.createItemConfiguration.showAppointments = false;
        this.createItemPreset = new CreateItemPreset();
        this.createItemPreset.createTodoInterface = this;
    }

    public validate(todo: Todo): boolean {
        const debug = false;
        if (debug) {
            console.warn('validate() : ', todo, this);
        }

        if (this.getShowOnDisplay() != null) {
            if (this.getShowOnDisplay() != todo.show_on_display) {
                if (debug) {
                    console.warn('ignored, cause of show on display');
                }
                return false;
            }
        }

        if (this.getDisplay()) {
            if (todo.display_id != this.getDisplay().id) {
                if (debug) {
                    console.warn('ignored, cause of display id');
                }
                return false;
            }
        }
        if (this.getHasDisplay()) {
            if (todo.display_id == 0) {
                if (debug) {
                    console.warn('ignored, cause of has display');
                }
                return false;
            }
        }
        if (this.getProject()) {
            if (todo.project_id != this.getProject().id) {
                if (debug) {
                    console.warn('ignored, cause of project id');
                }
                return false;
            }
        }
        if (this.getUser()) {
            if (todo.user_id != this.getUser().id) {
                if (debug) {
                    console.warn('ignored, cause of user id');
                }
                return false;
            }
        }
        if (this.getArchived() != null && !this.getOrArchivedSince()) {
            if (this.getArchived() && todo.archived_id == 0) {
                if (debug) {
                    console.warn('ignored, cause of archived');
                }
                return false;
            } else if (!this.getArchived() && todo.archived_id > 0) {
                if (debug) {
                    console.warn('ignored, cause of archived');
                }
                return false;
            }
        }
        if (this.getOrArchivedSince()) {
            if (todo.archived) {
                if (moment(todo.archived.created).toDate().getTime() < this.getOrArchivedSince().getTime()) {
                    if (debug) {
                        console.warn('ignored, cause of or archived since');
                    }
                    return false;
                }
            }
        }
        if (this.getAvoidIds()) {
            if (this.getAvoidIds().includes(todo.id)) {
                if (debug) {
                    console.warn('ignored, cause of avoid ids');
                }
                return false;
            }
        }
        if (this.getDeadlineBetween()) {
            const period = this.getDeadlineBetween();
            if (!todo.deadline?.isBetween(period[0], period[1])) {
                if (debug) {
                    console.warn('ignored, cause of deadline between');
                }
                return false;
            }
        }
        if (this.getDeadlineValidator()) {
            if (todo.deadline == null || !this.getDeadlineValidator()(todo.deadline)) {
                if (debug) {
                    console.warn('ignored, cause of deadline validator');
                }
                return false;
            }
        }
        if (this.getStarred() !== null) {
            const isStarred = todo.num_stars > 0;
            if (this.getStarred() != isStarred) {
                if (debug) {
                    console.warn('ignored, cause of starred');
                }
                return false;
            }
        }
        if (this.getHasDeadline() !== null) {
            const hasDeadline = todo.deadline?.exists() ?? false;
            if (this.getHasDeadline() != hasDeadline) {
                if (debug) {
                    console.warn('ignored, cause of has deadline');
                }
                return false;
            }
        }
        return true;
    }

    // <editor-fold desc="Create Item">

    public createPresets(typeId?: number): CreatePreset[] {
        return this.createPresetGenerators?.map(generator => generator.generate(typeId)) ?? [];
    }

    // </editor-fold>

    // <editor-fold desc="Data Listeners">

    protected setupDataSource() {
        if (this.getDataSource()) {
            this.getDataSource().subscribe((todos: Todo[], count: number) => {
                const items = todos.map(todo => this.createCard(todo));
                this.onAddItemsEvent.emit({items: items});
                this.onCountEvent.emit({count: count});
                this.setLastItemCount(items.length);
            });
        }
    }

    public createCard(item: Todo): CardItem<Todo> {
        const cardItem = new CardItem<Todo>(item, new CardTodoConfiguration());
        this.applyCardConfiguration(cardItem);
        return cardItem;
    }

    private applyCardConfiguration(card: CardItem<Todo>) {
        const config = card.configuration as unknown as CardTodoConfiguration;
        config.isDraggable = this.isDraggable();
        config.useGlobalFilter = this.getUseGlobalFilter();
        config.showProjectMiniCard = this.getShowProjectMiniCard();
        if (this.getBeforeAddHook()) {
            this.getBeforeAddHook()(card);
        }
        card.setListener(this);
    }

    // </editor-fold>

    // <editor-fold desc="Push">

    private pushSubscription: Subscription;
    public pushSubscribe() {
        AppInjector.getInjector().get(EventService).subscribeToTodo(0, event => {
            switch (event.action) {
                case EventService.Created:
                case EventService.Updated:
                    if (this.validate(event.item)) {
                        this.onAddItemEvent.emit({item: this.createCard(event.item)});
                    }
                    break;
            }
        });
    }

    public pushUnsubscribe() {
        this.pushSubscription?.unsubscribe();
    }

    // </editor-fold>

    // <editor-fold desc="Card Interface">

    public onCardItemDragged(cardItem: CardItem<Todo>, listConfiguration: ListConfiguration, toContainerId: string, fromContainerId: string, cdkDropEvent: CdkDropEvent<Todo>): void {

    }

    public onCardItemUpdated(cardItem: CardItem<Todo>): void {
        if (!this.validate(cardItem.item)) {
            this.onRemoveItemEvent.emit({item: cardItem});
        }
    }

    public onCardItemDeleted(cardItem: CardItem<Todo>): void {

    }

    // </editor-fold>

    // <editor-fold desc="ListDragInterface">

    public allowCardItemDrop(cardItem: CardItem<T>, fromListConfiguration: TodoListConfiguration<T>, result: (allow: boolean) => void) {
        if (!(cardItem.item instanceof Todo)) {
            result(false);
            return;
        }
        result(true);
    }

    public onCardItemDragAdd(cardItem: CardItem<T>, fromListConfiguration: TodoListConfiguration<T>, callback: () => void) {
        if (!(cardItem.item instanceof Todo)) {
            return;
        }

        const todo = cardItem.item as Todo;

        // Move deadline
        let deadlineDate: Date;
        if (this.getDeadlineBetween()) {
            deadlineDate = this.getDeadlineBetween()[0];
        } else if (this.getDeadlineValidatorDate()) {
            deadlineDate = this.getDeadlineValidatorDate()();
        }

        if (deadlineDate) {
            todo.setDeadline(0, Deadline.Create(deadlineDate));
        }

        this.onAddItemEvent.emit({item: this.createCard(todo)});
        callback();
    }

    public onCardItemDragRemove(cardItem: CardItem<T>) {
        if (!(cardItem.item instanceof Todo)) {
            return;
        }
        const card = cardItem as unknown as CardItem<Todo>;
        this.onRemoveItemEvent.emit({item: card});
    }

    public onCardItemDragSaved() {

    }

    // </editor-fold>

    // <editor-fold desc="Get / Set">

    public getOrderBy(): string[][] {
        return this.orderBy;
    }

    public setOrderBy(value: string[][]): TodoListConfiguration<T> {
        this.orderBy = value;
        return this;
    }

    public getLimit(): number {
        return super.getLimit();
    }

    public setLimit(value: number): TodoListConfiguration<T> {
        super.setLimit(value);
        return this;
    }

    public getUser(): User {
        return this.user;
    }

    public setUser(value: User): TodoListConfiguration<T> {
        this.user = value;
        return this;
    }

    public getArchived(): boolean {
        return this.archived;
    }

    public setArchived(value: boolean): TodoListConfiguration<T> {
        this.archived = value;
        return this;
    }

    public getProject(): Project {
        return this.project;
    }

    public setProject(value: Project): TodoListConfiguration<T> {
        this.project = new Project(value);
        return this;
    }

    public getAvoidIds(): number[] {
        return this.avoidIds;
    }

    public setAvoidIds(value: number[]): TodoListConfiguration<T> {
        this.avoidIds = value;
        return this;
    }

    public getDisplay(): Display {
        return this.display;
    }

    public getShowOnDisplay(): boolean {
        return this.hasShowOnDisplay;
    }
    public setShowOnDisplay(value: boolean): TodoListConfiguration<T> {
        this.hasShowOnDisplay = value;
        return this;
    }

    public setDisplay(value: Display): TodoListConfiguration<T> {
        this.display = value;
        return this;
    }

    public getHasDisplay(): boolean {
        return this.hasDisplay;
    }

    public setHasDisplay(value: boolean): TodoListConfiguration<T> {
        this.hasDisplay = value;
        return this;
    }

    public getOrArchivedSince(): Date {
        return this.orArchivedSince;
    }

    public setOrArchivedSince(value: Date): TodoListConfiguration<T> {
        this.orArchivedSince = value;
        return this;
    }

    public getUseGlobalFilter(): boolean {
        return this.useGlobalFilter;
    }

    public setUseGlobalFilter(value: boolean): TodoListConfiguration<T> {
        this.useGlobalFilter = value;
        return this;
    }

    public getSearch(): string {
        return this.search;
    }

    public setSearch(value: string): TodoListConfiguration<T> {
        this.search = value;
        return this;
    }

    public getDataSource(): TodoFetcherRequest {
        return this.dataSource;
    }

    public setDataSource(value: TodoFetcherRequest): TodoListConfiguration<T> {
        this.dataSource = value;
        this.setupDataSource();
        return this;
    }

    public getShowProjectMiniCard(): boolean {
        return this.showProjectMiniCard;
    }

    public setShowProjectMiniCard(value: boolean): TodoListConfiguration<T> {
        this.showProjectMiniCard = value;
        return this;
    }

    public setUseGlobalSearchFilter(value: boolean): TodoListConfiguration<T> {
        super.setUseGlobalSearchFilter(value);
        return this;
    }

    public getDeadlineBetween(): Date[] {
        return this.deadlineBetween;
    }

    public setDeadlineBetween(start: Date, end: Date): TodoListConfiguration<T> {
        this.deadlineBetween = [start, end];
        return this;
    }

    public getDeadlineValidator(): (deadline: Deadline) => boolean {
        return this.deadlineValidator;
    }

    public setDeadlineValidator(value: ((deadline: Deadline) => boolean)): TodoListConfiguration<T> {
        this.deadlineValidator = value;
        return this;
    }

    public getDeadlineValidatorDate(): () => Date {
        return this.deadlineValidatorDate;
    }

    public setDeadlineValidatorDate(value: () => Date): TodoListConfiguration<T> {
        this.deadlineValidatorDate = value;
        return this;
    }

    public getStarred(): boolean {
        return this.starred;
    }

    public setStarred(value: boolean): TodoListConfiguration<T> {
        this.starred = value;
        return this;
    }

    public getHasDeadline(): boolean {
        return this.hasDeadline;
    }

    public setHasDeadline(value: boolean): TodoListConfiguration<T> {
        this.hasDeadline = value;
        return this;
    }

    public getBeforeAddHook(): ((item: CardItem<Todo>) => void) {
        return this.beforeAddHook;
    }

    public setBeforeAddHook(value: ((item: CardItem<Todo>) => void)): TodoListConfiguration<T> {
        this.beforeAddHook = value;
        return this;
    }

    // </editor-fold>

    // <editor-fold desc="CSV Export">

    public csvExport(item: CardItem<Todo>, option?: CSVExportOptionInterface): any {
        return item.item.title;
    }

    // </editor-fold>

}
