import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {HeaderComponent} from '@app/shell/header/header.component';
import {NgbDropdownModule, NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {NgxScreenfullModule} from '@ngx-extensions/screenfull';
import {AngularMaterialModule} from "@app/shared/_modules/angular-material.module";
import {DisplayListModule} from "@app/shell/header/display-list/DisplayList.module";
import {WeekPipeModule} from "@app/pipes/WeekPipe.module";
import {FormsModule} from "@angular/forms";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {
    HeaderTitleFilterSelectorModule
} from "@app/shell/header/header-title-filter-selector/HeaderTitleFilterSelector.module";
import {FilterGlobalsModule} from "@app/shell/header/filters/filter-globals/FilterGlobals.module";
import {FilterColumnSelectorModule} from "@app/shell/header/filters/filter-column-selector/FilterColumnSelector.module";
import {CreateItemDropdownModule} from "@app/shared/_ui/create-item-dropdown/CreateItemDropdown.module";
import {LoadingIndicatorModule} from '@app/shared/_ui/loading-indicator/LoadingIndicator.module';
import {VisibilityToggleModule} from "@app/shared/_ui/visibility-toggle/VisibilityToggle.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        NgbTooltipModule,
        NgbDropdownModule,
        NgbModule,
        BrowserAnimationsModule,
        NgxScreenfullModule,
        AngularMaterialModule,
        DisplayListModule,
        WeekPipeModule,
        FormsModule,
        LocalizedDatePipeModule,
        HeaderTitleFilterSelectorModule,
        FilterGlobalsModule,
        FilterColumnSelectorModule,
        CreateItemDropdownModule,
        LoadingIndicatorModule,
        VisibilityToggleModule
    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        HeaderComponent,
    ],
    exports: [
        HeaderComponent

    ],
})
export class HeaderModule { }
