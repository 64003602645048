import {BaseComponent} from "@app/shared/_system/base/base.component";
import {EditorInterface} from "@app/editor/quick-editor/editors/EditorInterface";
import {Injectable, SimpleChanges} from "@angular/core";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {WampService, WampSubscription} from "../../../../services/wamp.service";
import {AppInjector} from "@app/services/app-injector.service";
import {BaseModel} from "@app/core/models/BaseModel";
import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";

@Injectable()
export abstract class BaseEditor<T> extends BaseComponent implements EditorInterface {

    public abstract model: T & HasEventGenerator & BaseModel;
    public abstract configuration: BaseEditorConfiguration;
    public abstract editorEvents: EditorEvents;

    protected abstract eventFieldName?: string;

    private wampService: WampService;

    constructor() {
        super();
        const injector = AppInjector.getInjector();
        this.wampService = injector.get(WampService);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['model']) {
            this.setupBaseEditor();
        }
        if (changes['configuration']) {
            this.setupBaseEditor();
        }
        this.setup();
        this.render();
    }

    private changeEventSubscription?: WampSubscription;

    private setupBaseEditor() {
        if (!this.model || !this.configuration) {
            return;
        }

        this.changeEventSubscription?.unsubscribe();

        if (!this.model.exists()) {
            return;
        }

        if (this.eventFieldName) {
            this.changeEventSubscription = this.wampService.subscribe(
                this.model.getEventName(this.eventFieldName, this.getEventNameOptions()),
                (event) => {
                    this.model.populate(event.next, true);
                    this.render();
                    this.detectChanges();
                }
            );
        }
    }

    protected abstract setup(): void;
    protected abstract render(): void;

    protected getEventNameOptions(): any {
        return null;
    }

    protected onItemUpdated() {
        this.editorEvents?.onItemUpdated(this.model);
    }

}
