<ng-container *ngFor="let item of items">
    <div class="d-flex flex-row align-items-start -w-100 ellipsis">
        <div class="d-flex justify-content-start flex-wrap w-100 align-items-center">

            <div class="d-flex d-flex align-items-start  justify-content-start w-50-calc ">
                <app-toggle-item
                    class="me-1 mt-1"
                    [interactive]="true"
                    (onToggle)="markCompleted(item, $event)"
                    [active]="item.isCompleted"></app-toggle-item>

                <div class="flex-ellipsis d-flex">
                    <app-phase
                        class="d-flex align-items-center"
                        [ellipsis]="true"
                        [phase]="item.phase"></app-phase>

                    <span
                        *ngIf="item.hasProgress && item.progressType && item.phasesProject?.id == currentPhasesProjectId"
                        class="ps-1 align-self-end text-black-50">({{item.progressType?.name}})</span>
                </div>
            </div>


            <app-item-date
                [alignClass]="'justify-content-start'"
                class="-w-50 w-15 text-end"
                [label]="('_period_start' | translate)"
                [showIcon]="true"
                [ellipsis]="true"
                *ngIf="item.hasDate"
                [date]="item.date"
            ></app-item-date>

            <div class=" w-15 text-end d-flex align-self-end justify-content-end" *ngIf="item.durationInDays > 0">
                <span>{{item.durationInDays}}</span>
                <span
                    translate="{{item.durationInDays == 1 ? '_ui_phases_project_duration_days_singular' : '_ui_phases_project_duration_days_plural'}}"></span>
            </div>
        </div>
    </div>
</ng-container>
