import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {DatepickerModule} from "@app/shared/_forms/datepicker/Datepicker.module";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {
    PhasesProjectEditorComponent
} from "@app/editor/quick-editor/editors/project/phases-project-editor/phases-project-editor.component";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {NgbHighlight, NgbInputDatepicker, NgbTooltip, NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        DatepickerModule,
        FormsModule,
        ToggleItemModule,
        NgbTooltip,
        ColorItemModule,
        NgbInputDatepicker,
        NgbHighlight,
        NgbTypeahead,
        TypedTemplateDirective,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        PhasesProjectEditorComponent,
    ],
    exports: [
        PhasesProjectEditorComponent,
    ],
    providers: [

    ],
})
export class PhasesProjectEditorModule {}
