/**
 * Created by ModelParser
 * Date: 12-05-2021.
 * Time: 16:46.
 */
import {ReactionFilterDefinition} from './definitions/ReactionFilterDefinition';

export class ReactionFilter extends ReactionFilterDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static Create(reactionFilterTypeId: number, value: string): ReactionFilter {
        const item = new ReactionFilter();
        item.reaction_type_id = reactionFilterTypeId;
        item.value = value;
        return item;
    }

}
