import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {GenericCell} from "@app/pages/displays/display-team/Cells/GenericCell";
import {GenericTableColumn} from "@app/pages/displays/display-team/TableColumns/GenericTableColumn";
import {DisplayTeamRow} from "@app/pages/displays/display-team/DisplayTeamRow";

export class GenericColumn<T extends GenericCell = GenericCell> extends BaseColumn<T> {

    public createTableColumns(): GenericTableColumn[] {
        const item = new GenericTableColumn(this);
        this.setTableColumnDefaults(item);
        return [item];
    }

    public createCell(row: DisplayTeamRow): GenericCell {
        return new GenericCell(row, this);
    }

}
