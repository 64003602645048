import {NgModule} from "@angular/core";
import {ScrollDetectorDirective} from "@app/directives/scroll-detector.directive";

@NgModule({
    imports: [

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ScrollDetectorDirective,
    ],
    exports: [
        ScrollDetectorDirective

    ],
    providers: [

    ],
})
export class ScrollDetectorDirectiveModule {}
