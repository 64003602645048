import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {TodoListConfiguration} from "@app/shared/_ui/lists/todo-list/TodoListConfiguration";
import {GenericTableColumn} from "@app/pages/displays/display-team/TableColumns/GenericTableColumn";
import {TodoListCell} from "@app/pages/displays/display-team/Cells/TodoListCell";
import {TableColumns} from "@app/pages/displays/display-team/Helpers/TableColumns";

export class TodoListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TodoList;

    public getListConfiguration(row: BaseRow): TodoListConfiguration {
        return row.getCell<TodoListCell>(this.column).todoListConfiguration;
    }

}
