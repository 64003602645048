<ng-template #rt let-r="result" let-t="term" class="w-100"
             [typedTemplate]="resultTemplateType"
>

    <div class="d-flex align-items-center result-item">
        <ng-container *ngIf="toggle">
            <span class="fal fa-trash-alt text-danger me-1" *ngIf="userExists(r.id); else userNotFound"></span>

            <ng-template #userNotFound>
                <span class="fal fa-arrow-circle-right text-success me-1"></span>
            </ng-template>
        </ng-container>

        <div class="d-flex align-items-center w-100" style="min-width: 300px;">
            <i class="fal fa-user-circle me-1"></i>
            <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
            <span class="ms-auto text-small">{{r.initialsConverter}}</span>
        </div>
    </div>

</ng-template>


<div class="form-group mb-0">

    <div class="d-flex align-items-center">
        <div class="me-1 btn "
             *ngIf="showFilterButton"
             [class.btn-outline-secondary]="departments.length == 0"
             [class.btn-outline-warning]="departments.length > 0"
             [ngbTooltip]="('_ui_filter' | translate)"
             (click)="popover.open()"
        >
            <span class="fal fa-filter cursor-pointer position-relative"
                  [ngbPopover]="popoverContent"
                  [autoClose]="'outside'"
                  placement="bottom left auto"
                  triggers="manual"
                  container="body"
                  #popover="ngbPopover"
            >
                    </span>
            <ng-template #popoverContent>
                <app-department-picker [showAll]="true"
                                       [selectedDepartments]="departments"
                                       [multiple]="true"
                                       [showDepartmentGroups]="true"
                                       (onChangeEvent)="filterByDepartment($event)"></app-department-picker>
            </ng-template>
        </div>

        <div class="search-wrap d-flex  flex-grow-1 input-group input-group"
             [ngbPopover]="((departments?.length > 0 || selectedUsers?.length > 0) && showPopoverDetails) ? popoverFilterList : null"
             placement="auto"
             container="body"
             triggers="mouseenter:mouseleave"
             [autoClose]="false"
             #popover="ngbPopover"

             (click)="((showDeleteButton || replacementSearch) && internalValues) ?  resetClick() : null"
             [class.cursor-pointer]="(showDeleteButton && internalValues)"
        >
            <input
                type="search"
                class="form-control {{className}}  placeholder-sm "
                [class.disabled]="internalValues != null"
                autocomplete="off"
                #instance="ngbTypeahead"
                #searchInput
                [editable]="false"
                container="body"
                popupClass="result-list"
                [class.is-invalid]="searchFailed || invalid"
                [(ngModel)]="internalValues"
                [ngbTypeahead]="search"
                [resultTemplate]="rt"
                [inputFormatter]="formatter"
                [resultFormatter]="formatter"
                (focus)="clickCheck();focusItem($event)"
                (click)="clickItem($event)"
                (blur)="onBlurEvent($any($event.target).value)"
                (selectItem)="triggerSelection($event, instance); $event.preventDefault()"
                [placeholder]="placeholder ? (placeholder | translate) : ('_ui_search_placeholder' | translate: {type: ('_user' | translate | lowercase)})"
                [autofocusAfterInit]="autoFocus"
            />

            <button class="btn btn-outline-danger " (click)="resetClick();" *ngIf="showDeleteButton && internalValues">
                <i class="fal fa-eraser"></i>
                <span class="fal fa-filter text-small text-black-50"
                      style="position: absolute; right: 1.5rem; top: calc(50% - 0.25rem);"
                      *ngIf="(departments?.length > 0 || selectedUsers?.length > 0) && showPopoverDetails">
                </span>
            </button>
        </div>

        <ng-template #popoverFilterList>
            <div class="d-flex flex-row">
                <ul *ngIf="departments?.length > 0" class="list-unstyled mb-0">
                    <li *ngFor="let department of departments">
                        <i class="fal fa-users me-1"></i>
                        <span>{{department.name}}</span>
                    </li>
                </ul>
                <ul *ngIf="selectedUsers?.length > 0" class="list-unstyled  mb-0">
                    <li *ngFor="let selectedUser of selectedUsers">
                        <div class="d-flex align-items-center ">
                            <i class="fa-light fa-ban me-1 d-none"></i>
                            <i class="fal fa-user-circle me-1"></i>
                            <span>{{selectedUser.name}}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </ng-template>

    </div>

    <div class="invalid-feedback" *ngIf="searchFailed">{{('_ui_search_no_results' | translate)}}.</div>

</div>
