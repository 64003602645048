import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {Roster} from "@app/core/models/Task";
import * as moment from "moment/moment";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {TaskListRosterCell} from "@app/pages/displays/display-projects/Cells/TaskListRosterCell";
import {DatePipe} from "@angular/common";

export const RosterDays = {
    Monday: 'monday',
    Tuesday: 'tuesday',
    Wednesday: 'wednesday',
    Thursday: 'thursday',
    Friday: 'friday',
    Saturday: 'saturday',
    Sunday: 'sunday',
}

export type RosterDay = typeof RosterDays.Monday
    | typeof RosterDays.Tuesday
    | typeof RosterDays.Wednesday
    | typeof RosterDays.Thursday
    | typeof RosterDays.Friday
    | typeof RosterDays.Saturday
    | typeof RosterDays.Sunday;

export class TaskListRosterTableColumn extends GenericTableColumn {

    public rosterDay: RosterDay;

    public rosterDate?: Date;

    constructor(column: BaseColumn, rosterDay: RosterDay) {
        super(column);
        this.identifier = rosterDay;
        this.rosterDay = rosterDay;
    }

    public getTaskListConfigurationForRow(row: ProjectsDisplayRow): TaskListConfiguration<Roster> {
        return row.getCell<TaskListRosterCell>(this.column).getTaskListConfiguration(this.rosterDay);
    }

    public setDate(week: Date) {
        this.rosterDate = this.getDate(week);
        const name = new DatePipe('da-DK').transform(this.rosterDate, 'EEEE');
        this.name = name[0].toUpperCase() + name.substring(1);
    }

    public getDate(week: Date) {
        switch (this.rosterDay) {
            default:
            case RosterDays.Monday:
                return moment(week).startOf('week').add('1', 'days').toDate();
            case RosterDays.Tuesday:
                return moment(week).startOf('week').add('2', 'days').toDate();
            case RosterDays.Wednesday:
                return moment(week).startOf('week').add('3', 'days').toDate();
            case RosterDays.Thursday:
                return moment(week).startOf('week').add('4', 'days').toDate();
            case RosterDays.Friday:
                return moment(week).startOf('week').add('5', 'days').toDate();
            case RosterDays.Saturday:
                return moment(week).startOf('week').add('6', 'days').toDate();
            case RosterDays.Sunday:
                return moment(week).startOf('week').add('7', 'days').toDate();
        }
    }

}
