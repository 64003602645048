import {NgModule} from "@angular/core";
import {
    HeaderTitleFilterItemComponent
} from "@app/shell/header/header-title-filter-item/header-title-filter-item.component";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule,
        NgbPopoverModule,
        ToggleItemModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        HeaderTitleFilterItemComponent,
    ],
    exports: [
        HeaderTitleFilterItemComponent

    ],
    providers: [

    ],
})
export class HeaderTitleFilterItemModule {}
