import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    TaskListNextMilestoneTableColumn
} from "@app/pages/displays/dashboard/dashboard-projects/TableColumns/TaskListNextMilestoneTableColumn";
import {
    TaskListNextMilestoneCell
} from "@app/pages/displays/dashboard/dashboard-projects/Cells/TaskListNextMilestoneCell";

export class TaskListNextMilestoneColumn extends BaseColumn<TaskListNextMilestoneCell> {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new TaskListNextMilestoneTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: BaseRow): BaseCell {
        return new TaskListNextMilestoneCell(row, this);
    }

}
