import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {TodoCategory} from '@app/core/models/TodoCategory';
import {BaseService} from '@app/services/base.service';
import {ApiCall} from '@app/http/APICall';
import {Todo} from '@app/core/models/Todo';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '@env/environment';
import {catchError, map} from 'rxjs/operators';
import {OldApi} from '@app/http/Api';
import {ApiFilter, OrderDirection} from '@app/http/APIFilter';
import {EventService} from '@app/services/event.service';
import {TodoField} from "@app/core/models/TodoField";
import {FieldTypes} from "@app/constants";
import {Api} from '@app/core/http/Api/Api';
import {ReactionTypesTodo} from "@app/core/models";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";

@Injectable({
    providedIn: 'root'
})
export class TodosService extends BaseService {

    constructor(private filterGlobalService: FilterGlobalService, private eventService: EventService,) {
        super();
    }

    // <editor-fold desc="Fields (Queue & Cache)">

    // Cache
    private fieldsCache: TodoField[];

    // Queue
    private fieldsCallbacks: ((fields: TodoField[]) => void)[] = [];
    private fireFieldsCallback(fields: TodoField[]) {
        const callbacks = this.fieldsCallbacks;
        this.fieldsCallbacks = [];
        for(let callback of callbacks) {
            callback(fields);
        }
    }

    // Entry point
    private getFields(callback: (fields: TodoField[]) => void) {
        if(this.fieldsCache) // Use cache
            callback(this.fieldsCache);
        else if(this.fieldsCallbacks.length) // Add to queue
            this.fieldsCallbacks.push(callback);
        else { // Get from API
            this.fieldsCallbacks.push(callback);
            OldApi.todoFields().get(TodoField.name)
                .subscribe((fields: TodoField[]) => {
                    this.fieldsCache = fields;
                    this.fireFieldsCallback(fields);
                });
        }
    }

    public getEditorFields(callback: (fields: TodoField[]) => void) {
        this.getFields(fields => {
            callback(fields.filter(field => field.type == FieldTypes.Editor))
        });
    }

    public getMiniCardFields(callback: (fields: TodoField[]) => void) {
        this.getFields(fields => callback(fields.filter(field => field.type == FieldTypes.MiniCard)));
    }

    // </editor-fold>

    // <editor-fold desc="Reaction types (Queue & Cache)">

    // Cache
    private reactionTypesCache: ReactionTypesTodo[];

    // Queue
    private reactionTypesCallbacks: ((fields: ReactionTypesTodo[]) => void)[] = [];
    private fireReactionTypesCallback(fields: ReactionTypesTodo[]) {
        const callbacks = this.reactionTypesCallbacks;
        this.reactionTypesCallbacks = [];
        for (let callback of callbacks) {
            callback(fields);
        }
    }

    // Entry point
    public getReactionTypes(callback: (fields: ReactionTypesTodo[]) => void) {
        if(this.reactionTypesCache) // Use cache
            callback(this.reactionTypesCache);
        else if(this.reactionTypesCallbacks.length) // Add to queue
            this.reactionTypesCallbacks.push(callback);
        else { // Get from API
            this.reactionTypesCallbacks.push(callback);
            Api.reactionTypesTodos().get().find(items => {
                this.reactionTypesCache = items;
                this.fireReactionTypesCallback(items);
            });
        }
    }

    // </editor-fold>

    // <editor-fold desc="Categories (Queue & Cache)">

    private categoriesCache: TodoCategory[];
    private categoriesCallbacks: ((items: TodoCategory[]) => void)[] = [];
    public getCategories(callback: (items: TodoCategory[]) => void) {
        if (this.categoriesCache) {
            callback(this.categoriesCache);
        } else if (this.categoriesCallbacks.length) {
            this.categoriesCallbacks.push(callback);
        } else {
            this.categoriesCallbacks.push(callback);
            Api.todoCategories().get()
                .orderAsc('name')
                .find(items => {
                    this.categoriesCache = items;
                    this.categoriesCallbacks.forEach(callback => callback(items));
                    this.categoriesCallbacks = [];
                });
        }
    }

    // </editor-fold>

    remove$(todo: Todo) {
        const uri = OldApi.todos(todo.id).toString();
        return this.httpClient
            .delete<Todo>(`${environment.apiPath}${uri}`, {headers: this.authService.getAuthorizationHeader()})
            .pipe(
                map((response: any) => {
                    const _todo: Todo = new Todo(response.resource);
                    this.eventService.emitTodo(_todo, EventService.Deleted);
                    return _todo;
                }),
                catchError((err: HttpErrorResponse) => {
                    return throwError(err);
                })
            );

    }

    delete$(_todo: Todo): Observable<Todo> {
        return this.remove$(_todo);
    }

    search(term: string, filters?:ApiFilter): Observable<Todo[]> {
        let httpParams = new HttpParams();
        if(!filters) filters = new ApiFilter();

        if(term !== '') {
            filters.search('title', term);
            filters.search('smart_search', ['user']);
            filters.orderBy('created', OrderDirection.Descending);
            filters.orderBy('title', this.filterGlobalService.getActiveSettings().activeSortDirection);
            httpParams = httpParams.append('limit', '25');
        } else {
            httpParams = httpParams.append('limit', '25');
            filters.orderBy('title', this.filterGlobalService.getActiveSettings().activeSortDirection);
        }

        httpParams = httpParams.append('filter', filters.filtersString());
        httpParams = httpParams.append('ordering', filters.orderingString());

        const api: ApiCall = new ApiCall(OldApi.todos().toString(), httpParams, (response) => {
            return response.resources.map((item: any) => {
                return new Todo(item);
            });
        });

        return api.getHttp();

    }

}
