import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {TaskPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/TaskPresetType";

export class TaskCategoryPresetGenerator implements CreatePresetGenerator {

    private readonly categoryId?: number;
    private readonly categoryIdHandler?: () => number;

    constructor(categoryIdOrHandler: number | (() => number)) {
        if (typeof categoryIdOrHandler == "number") {
            this.categoryId = categoryIdOrHandler;
        } else {
            this.categoryIdHandler = categoryIdOrHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: TaskPresetTypes.Category,
            payload: {
                categoryId: this.categoryId ?? this.categoryIdHandler(),
            }
        };
    }

}
