import {NgModule} from "@angular/core";
import {UsersWithIconComponent} from "@app/shared/_elements/users-with-icon/users-with-icon.component";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {CommonModule} from "@angular/common";
import {ToggleItemModule} from '@app/shared/_elements/toggle-item/ToggleItem.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        NgbPopoverModule,
        UserWithIconModule,
        ToggleItemModule,
        NgbTooltipModule,
        TranslateModule,
    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        UsersWithIconComponent,
    ],
    exports: [
        UsersWithIconComponent
    ],
    providers: [

    ],
})
export class UsersWithIconModule {}
