import {TitleEditorComponent} from "@app/editor/quick-editor/editors/generic/title-editor/title-editor.component";
import {
    DeadlineListEditorComponent
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/deadline-list-editor.component";
import {
    MilestonePlanSelectEditorComponent
} from "@app/editor/quick-editor/editors/project/milestone-plan-select-editor/milestone-plan-select-editor.component";
import {CardEditorComponent} from "@app/editor/quick-editor/editors/generic/card-editor/card-editor.component";
import {StatusEditorComponent} from "@app/editor/quick-editor/editors/generic/status-editor/status-editor.component";
import {
    EstimateListEditorComponent
} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/estimate-list-editor.component";
import {
    CategoryListEditorComponent
} from "@app/editor/quick-editor/editors/generic/category-list-editor/category-list-editor.component";
import {
    ReactionListEditorComponent
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/reaction-list-editor.component";
import {TextEditorComponent} from "@app/editor/quick-editor/editors/generic/text-editor/text-editor.component";
import {LinkEditorComponent} from "@app/editor/quick-editor/editors/generic/link-editor/link-editor.component";
import {
    WhoWhenEditorComponent
} from "@app/editor/quick-editor/editors/generic/who-when-editor/who-when-editor.component";
import {ActionEditorComponent} from "@app/editor/quick-editor/editors/generic/action-editor/action-editor.component";
import {
    MilestoneListEditorComponent
} from "@app/editor/quick-editor/editors/generic/milestone-list-editor/milestone-list-editor.component";
import {
    ArchivedEditorComponent
} from "@app/editor/quick-editor/editors/generic/archived-editor/archived-editor.component";
import {
    UserListEditorComponent
} from "@app/editor/quick-editor/editors/generic/user-list-editor/user-list-editor.component";
import {
    UseStatusRulesEditorComponent
} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/use-status-rules-editor.component";
import {
    ProjectListEditorComponent
} from "@app/editor/quick-editor/editors/generic/project-list-editor/project-list-editor.component";
import {
    CategoryPickerListEditorComponent
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/category-picker-list-editor.component";
import {
    PhasesProjectEditorComponent
} from "@app/editor/quick-editor/editors/project/phases-project-editor/phases-project-editor.component";
import {
    DepartmentPickerEditorComponent
} from "@app/editor/quick-editor/editors/generic/department-picker-editor/department-picker-editor.component";
import {
    TagListEditorComponent
} from "@app/editor/quick-editor/editors/generic/tag-list-editor/tag-list-editor.component";
import {
    CanCopyEditorComponent
} from "@app/editor/quick-editor/editors/generic/can-copy-editor/can-copy-editor.component";
import {
    IsPrivateEditorComponent
} from "@app/editor/quick-editor/editors/generic/is-private-editor/is-private-editor.component";
import {NumberEditorComponent} from "@app/editor/quick-editor/editors/generic/number-editor/number-editor.component";

export default class AvailableEditors {

    public static All(): string[] {
        return [
            MilestonePlanSelectEditorComponent.name,

            CardEditorComponent.name,
            TitleEditorComponent.name,
            StatusEditorComponent.name,
            ArchivedEditorComponent.name,
            DeadlineListEditorComponent.name,
            UserListEditorComponent.name,
            EstimateListEditorComponent.name,
            CategoryListEditorComponent.name,
            ReactionListEditorComponent.name,
            TextEditorComponent.name,
            LinkEditorComponent.name,
            WhoWhenEditorComponent.name,
            ActionEditorComponent.name,
            MilestoneListEditorComponent.name,
            UseStatusRulesEditorComponent.name,
            ProjectListEditorComponent.name,
            CategoryPickerListEditorComponent.name,
            PhasesProjectEditorComponent.name,
            DepartmentPickerEditorComponent.name,
            TagListEditorComponent.name,
            CanCopyEditorComponent.name,
            IsPrivateEditorComponent.name,
            NumberEditorComponent.name,
        ];
    }

    public static QuickCreateWithTemplate(): string[] {
        return [
            MilestonePlanSelectEditorComponent.name,
            TitleEditorComponent.name,
            // StatusEditorComponent.name
        ];
    }

    public static QuickCreateWithTitle(): string[] {
        return [
            TitleEditorComponent.name,
            StatusEditorComponent.name
        ];
    }
}
