<ng-container>
    <!-- No filters selection (Mit overblik) -->
    <div
        *ngIf="(!groups || groups.length == 0) || title == Constants.System.Loading"
        class="d-flex flex-row flex-grow-1 position-relative align-items-center header-title-text flex-shrink-1"
    >
        <div
            class="d-flex align-items-center flex-grow-1 justify-content-center">
            <app-loading-indicator [showLogo]="false" class="w-100 mt-2"
                                   *ngIf="title == Constants.System.Loading"></app-loading-indicator>
            <div
                class="d-flex flex-column align-items-center justify-content-center text-center text-nowrap text-truncate w-100"
                *ngIf="title != Constants.System.Loading">
                <span

                    class="flex-fill w-100 text-center title-block ellipsis " *ngIf="title != ''">{{title}}</span>

                <div class="d-flex flex-row justify-content-between align-items-center w-100"  *ngIf="title == ''">
                    <div class="image-wrap " style="margin: 0 auto; height: 48px;">
                        <img src="/assets/logo-light.svg" class="img-fluid" style=" height: 48px;"
                             alt="Klartboard">
                    </div>
                </div>


                <span class="small" style="font-size: 50%;"
                      *ngIf="selectedDepartment && showDepartment">{{selectedDepartment.name}}</span>
            </div>
        </div>
    </div>

    <!-- Default, filters selection -->
    <div
        class="d-flex flex-row flex-grow-1 position-relative w-100 align-items-center header-title-text flex-shrink-1"
        [ngbPopover]="popoverContent"
        [autoClose]="'outside'"
        placement="auto"
        triggers="manual"
        container="body"
        #popover="ngbPopover"
        *ngIf="groups?.length && user && selectedDepartment && title != Constants.System.Loading">
        <h5 class="cursor-pointer pt-0 w-100 ps-1"
            style="height: 1em;"
            (click)="$event.stopPropagation(); popover.isOpen() ? popover.close() : popover.open();">

            <app-loading-indicator class="w-100 mt-2" [showLogo]="false" *ngIf="title == ''"></app-loading-indicator>

            <div
                class="d-flex flex-column align-items-center justify-content-center text-center text-nowrap text-truncate w-100 no-caret"
                *ngIf="title != ''">
                    <span class="flex-fill w-100 d-flex align-items-center justify-content-center text-center">
                        <i class="fal fa-chevron-circle-down me-1 text-small"
                           [ngbTooltip]="('_ui_header_choose_filter' | translate)"
                           [autoClose]="true"
                           [openDelay]="2000"
                           style="transform: translateY(2px);"
                        >
                        </i>
                        <span [ngbTooltip]="titleToolTip && !isMobile ? (titleToolTip | translate) : null"
                              [openDelay]="2000"
                              [autoClose]="true" class="ellipsis title-block"
                              [class.text-small]="title.length > 20"
                        >{{title}}</span>
                    </span>
                <span class="small" style="font-size: 50%;"
                      *ngIf="selectedDepartment && showDepartment">{{selectedDepartment.name}}</span>
            </div>
        </h5>
    </div>


    <!-- Popover dialog content -->
    <ng-template #popoverContent>

        <h4 class="d-block w-100 mb-1 m-0  popover-title"
            [ngbPopover]="popoverDescription"
            triggers="mouseenter:mouseleave"
            container="body"
            >{{'_ui_header_choose_filter' | translate}}</h4>

        <ng-template #popoverDescription>
            <div class="p-2 callout" style="max-width: 300px;">
                <p>{{'_ui_filters_description' | translate}}</p>
            </div>
        </ng-template>

        <div class="tabs-container mb-1 scroll-y w-100" [attr.data-items]="groups.length">
            <ul ngbNav
                #nav="ngbNav"
                [(activeId)]="activeTab"

                class="nav-tabs categories tabs-editor p-0 m-0">
                <ng-container *ngFor="let group of groups; let i = index;">
                    <li [ngbNavItem]="i"
                        [class.active]="i == activeTab"
                        class=" flex-grow-1">
                        <a
                            ngbNavLink
                            [ngbTooltip]="group.shellFilterGroup.name"
                            [openDelay]="2000"
                             ><span class="group-name ellipsis">{{group.shellFilterGroup.name}}</span></a>
                        <ng-template ngbNavContent>
                            <app-header-title-filter-group
                                [shellPageData]="group"
                                (onCloseEvent)="popover.close()"
                            ></app-header-title-filter-group>
                        </ng-template>
                    </li>
                </ng-container>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </ng-template>

</ng-container>
