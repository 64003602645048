import {CardConfiguration} from '@app/shared/_ui/cards/CardConfiguration';
import {Milestone} from '@app/core/models/Milestone';

export class CardMilestoneConfiguration extends CardConfiguration<Milestone> {

    // UI
    public showProjectMiniCard: boolean = false;
    public showProjectPhase: boolean = false;
    public showDate: boolean = true;
    public showResponsible: boolean = true;
    public showReactions: boolean = true;       // Emoji
    public showActions: boolean = true;         // Fuldfør, stjerne, hånden op

    constructor(showProjectMiniCard: boolean = false, showProjectPhase: boolean = false) {
        super();
        this.showProjectMiniCard = showProjectMiniCard;
        this.showProjectPhase = showProjectPhase;
    }

    get exists(): boolean {
        return true;
    }

    public compare(config: CardMilestoneConfiguration): boolean {
        return true;
    }
}
