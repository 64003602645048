/**
 * Created by ModelParser
 */
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class CronJobDefinition extends BaseModel {
    name?: string;
    uri?: string;
    min?: string;
    hour?: string;
    day_of_month?: string;
    month?: string;
    day_of_week?: string;
    last_run?: string;
    command?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.uri;
            delete this.min;
            delete this.hour;
            delete this.day_of_month;
            delete this.month;
            delete this.day_of_week;
            delete this.last_run;
            delete this.command;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.uri != null) {
            this.uri = data.uri;
        }
        if (data.min != null) {
            this.min = data.min;
        }
        if (data.hour != null) {
            this.hour = data.hour;
        }
        if (data.day_of_month != null) {
            this.day_of_month = data.day_of_month;
        }
        if (data.month != null) {
            this.month = data.month;
        }
        if (data.day_of_week != null) {
            this.day_of_week = data.day_of_week;
        }
        if (data.last_run != null) {
            this.last_run = data.last_run;
        }
        if (data.command != null) {
            this.command = data.command;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
