import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {ProjectStatus} from "@app/core/models";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {
    ProjectStatusListColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/ProjectStatusListColumn";

export class ProjectStatusListCell extends BaseCell {

    public projectStatuses: ProjectStatus[];

    public constructor(row: Row, column: ProjectStatusListColumn) {
        super(row, column);

        const statuses: ProjectStatus[] = [];
        column.getStatusTypes()
            ?.forEach(statusTypeId => {
                const status = row.projectCard.item.findProjectStatusByType(statusTypeId);
                if (status) {
                    statuses.push(status);
                }
            });
        this.projectStatuses = statuses;
    }

    public get visibleProjectStatusTypeIds(): number[] {
        return this.projectStatuses.map(item => item.project_status_type_id);
    }

}
