import {Filter} from '@app/pages/displays/filtering/Filter';
import {Sort} from '@app/pages/displays/filtering/Sort';
import {Period} from '@app/pages/displays/filtering/Period';
import {DisplayFilter} from '@app/core/models/DisplayFilter';
import {Display} from '@app/core/models/Display';
import {AppInjector} from '@app/services/app-injector.service';
import * as moment from 'moment';
import {DisplayFilterEditorFormConfigInterface} from '@app/editor/display-filter-editor/DisplayFilterEditorForm';
import {TranslateService} from '@ngx-translate/core';
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {CategoryType, TaskDeadlineType} from "@app/core/models";
import {CountRunner} from "@app/core/CountRunner/CountRunner";

export class Filters implements FiltersInterface {

    public applyDefaults(displayFilter: DisplayFilter) {
        displayFilter.filter_type = Filters.FilterAll;
        displayFilter.sort_type = Filters.SortTitle;
        displayFilter.period_type = Filters.PeriodNext3Months;
        displayFilter.sort_direction = 'asc';
    }

    // Filters
    public static FilterAll = 'filter-all';
    public static FilterWithDeadlineInPeriod = 'filter-with-deadline-in-period';
    public static FilterWithoutDeadline = 'filter-without-deadline';
    public static FilterPlannedInPeriod = 'filter-planned-in-period';
    public static FilterWithoutPlanningDate = 'filter-without-planning-date';
    public static FilterOpen = 'filter-open';
    public static FilterWithNotes = 'filter-with-notes';
    public static FilterArchivedInPeriod = 'filter-archived-in-period';

    // Sorts (Milestones)
    public static SortMilestoneUserDefined = 'sort-milestones-user-defined';
    public static SortMilestoneTitle = 'sort-milestones-title';
    public static SortMilestoneDeadline = 'sort-milestones-deadline';
    public static SortMilestoneStatus = 'sort-milestones-status';
    public static SortMilestoneResponsible = 'sort-milestones-responsible';
    public static SortMilestoneStars = 'sort-milestones-stars';
    public static SortMilestoneHands = 'sort-milestones-hands';

    // Sorts
    public static SortTitle = 'sort-title';
    public static SortStatus = 'sort-status';
    public static SortDeadlines = 'sort-deadlines';
    public static SortPlanningDate = 'sort-planning-date';
    public static SortStars = 'sort-stars';
    public static SortHands = 'sort-hands';
    public static SortUserDefined = 'sort-user-defined';

    public static SortDeadlineWith = 'sort-deadline-';
    public static SortDeadlineWithGenerator(taskDeadlineType: TaskDeadlineType): string {
        return `${Filters.SortDeadlineWith}${taskDeadlineType.id}`;
    };
    public static ParseSortDeadlineWith(sort: string): number {
        return parseInt(sort.substr(Filters.SortDeadlineWith.length));
    };

    public static SortCategoryType = 'sort-category-type-';
    public static SortCategoryTypeGenerator(categoryType: CategoryType): string {
        return `${Filters.SortCategoryType}${categoryType.id}`;
    };
    public static ParseSortCategoryType(sort: string): string {
        return sort.substr(Filters.SortCategoryType.length);
    };

    public static GetBaseSort(sort: string): string {
        // Remove any identifiers from the end
        return sort.replace(/[0-9]/g, '');
    }

    // Periods
    public static PeriodUserDefined = 'user-defined';
    public static PeriodLastWeek = 'last-week';
    public static PeriodToday = 'today';
    public static PeriodThisWeek = 'this-week';
    public static PeriodNext2Week = 'next-2-weeks';
    public static PeriodNextMonth = 'next-month';
    public static PeriodNext3Months = 'next-3-months';
    public static PeriodNextYear = 'next-year';

    public filters(display: Display): Filter[] {
        const all = new Filter(
            Filters.FilterAll,
            this.getTranslation('_displays_project_details_milestones_filter_all')
        );

        const withDeadline = new Filter(
            Filters.FilterWithDeadlineInPeriod,
            this.getTranslation('_displays_project_details_milestones_filter_with_deadline'),
            true
        );
        withDeadline.showTaskDeadlineType = true;

        const withoutDeadline = new Filter(
            Filters.FilterWithoutDeadline,
            this.getTranslation('_displays_project_details_milestones_filter_without_deadline')
        );
        withoutDeadline.showTaskDeadlineType = true;

        const planned = new Filter(
            Filters.FilterPlannedInPeriod,
            this.getTranslation('_displays_project_details_milestones_filter_planned'),
            true
        );

        const withoutPlanningDate = new Filter(
            Filters.FilterWithoutPlanningDate,
            this.getTranslation('_displays_project_details_milestones_filter_without_planning_date')
        );

        const open = new Filter(
            Filters.FilterOpen,
            this.getTranslation('_displays_project_details_milestones_filter_open')
        );

        const withNotes = new Filter(
            Filters.FilterWithNotes,
            this.getTranslation('_displays_project_details_milestones_filter_with_notes')
        );

        const archived = new Filter(
            Filters.FilterArchivedInPeriod,
            this.getTranslation('_displays_project_details_milestones_filter_archived'),
            true
        );

        const allFilters = [
            all, withDeadline, withoutDeadline, planned, withoutPlanningDate, open, withNotes, archived,
        ];

        return allFilters;
    }

    private getTranslation(key: string, interpolateParams?: Object): string {
        return AppInjector.getInjector().get(TranslateService).instant(key, interpolateParams);
    }

    public sorts(display: Display, includePseudoTypes = false): Sort[] {
        const title = new Sort(Filters.SortTitle, this.getTranslation('_ui_title'), true);
        const status = new Sort(Filters.SortStatus, this.getTranslation('_ui_status'), true);
        status.sortDirectionTranslation = '_ui_order_direction_status';
        const deadlines = new Sort(Filters.SortDeadlines, this.getTranslation('_ui_filter_sort_deadlines'), true);
        const planningDate = new Sort(Filters.SortPlanningDate, this.getTranslation('_ui_filter_sort_planning_date'), true);
        const stars = new Sort(Filters.SortStars, this.getTranslation('_ui_filter_sort_stars'), true);
        const hands = new Sort(Filters.SortHands, this.getTranslation('_ui_filter_sort_hands'), true);
        const userDefined = new Sort(Filters.SortUserDefined, this.getTranslation('_ui_filter_user_defined_with_drag_drop'), false);

        const sorts = [
            title, status, deadlines, planningDate, stars, hands, userDefined
        ];

        const config = this.getEditorConfig(display);
        config.categoryTypes?.forEach(categoryType => {
            sorts.push(new Sort(
                Filters.SortCategoryTypeGenerator(categoryType),
                `${categoryType.name} ${this.getTranslation('_filter_show_only', {name: categoryType.name.toLocaleLowerCase()})}`,
                true));
        });
        config.taskDeadlineTypes?.forEach(taskDeadlineType => {
            sorts.push(new Sort(
                Filters.SortDeadlineWithGenerator(taskDeadlineType),
                `${taskDeadlineType.name} ${this.getTranslation('_filter_show_only', {name: taskDeadlineType.name.toLocaleLowerCase()})}`,
                true));
        });

        if (includePseudoTypes) {
            sorts.push(
                new Sort(Filters.SortMilestoneUserDefined, this.getTranslation('_ui_filter_user_defined_with_drag_drop'), false),
                new Sort(Filters.SortMilestoneTitle, this.getTranslation('_ui_title'), true),
                new Sort(Filters.SortMilestoneDeadline, this.getTranslation('_ui_filter_sort_deadline'), false),
                new Sort(Filters.SortMilestoneStatus, this.getTranslation('_ui_status'), true),
                new Sort(Filters.SortMilestoneResponsible, this.getTranslation('_milestone_responsible'), true),
                new Sort(Filters.SortMilestoneStars, this.getTranslation('_ui_filter_sort_stars'), true),
                new Sort(Filters.SortMilestoneHands, this.getTranslation('_ui_filter_sort_hands'), true),
            );
        }

        return sorts;
    }

    public getSort(display: Display, sort: string): Sort {
        return this.sorts(display).find(item => item.id == sort);
    }

    public periods(): Period[] {
        return [
            new Period(Filters.PeriodUserDefined, this.getTranslation('_ui_date_userdefined'), true),
            new Period(Filters.PeriodLastWeek, this.getTranslation('_ui_date_last_week')),
            new Period(Filters.PeriodToday, this.getTranslation('_ui_date_today')),
            new Period(Filters.PeriodThisWeek, this.getTranslation('_ui_date_this_week')),
            new Period(Filters.PeriodNext2Week, this.getTranslation('_ui_date_next_2_weeks')),
            new Period(Filters.PeriodNextMonth, this.getTranslation('_ui_date_next_month')),
            new Period(Filters.PeriodNext3Months, this.getTranslation('_ui_date_next_3_months')),
            new Period(Filters.PeriodNextYear, this.getTranslation('_ui_date_next_year')),
        ];
    }

    public getPeriodStart(period: string, sourceDate?: Date): Date {
        let date = moment();
        switch (period) {
            case Filters.PeriodLastWeek:
                date.startOf('isoWeek').subtract(1, 'week');
                break;
            case Filters.PeriodToday:
                date.startOf('day');
                break;
            case Filters.PeriodThisWeek:
                date.startOf('isoWeek');
                break;
            case Filters.PeriodNext2Week:
                date.startOf('day');
                break;
            case Filters.PeriodNextMonth:
                date.startOf('day');
                break;
            case Filters.PeriodNext3Months:
                date.startOf('day');
                break;
            case Filters.PeriodNextYear:
                date.startOf('day');
                break;
        }
        return date.toDate();
    }

    public getPeriodEnd(period: string, sourceDate?: Date): Date {
        let date = moment();
        switch (period) {
            case Filters.PeriodLastWeek:
                date.endOf('isoWeek').subtract(1, 'week');
                break;
            case Filters.PeriodToday:
                date.endOf('day');
                break;
            case Filters.PeriodThisWeek:
                date.endOf('isoWeek');
                break;
            case Filters.PeriodNext2Week:
                date.endOf('day').add(2, 'weeks');
                break;
            case Filters.PeriodNextMonth:
                date.endOf('day').add(1, 'month');
                break;
            case Filters.PeriodNext3Months:
                date.endOf('day').add(3, 'month');
                break;
            case Filters.PeriodNextYear:
                date.endOf('day').add(1, 'year');
                break;
        }
        return date.toDate();
    }

    public standards(display: Display): DisplayFilter[] {
        let standards: DisplayFilter[] = [];

        let allByTitle = new DisplayFilter();
        allByTitle.display_id = display.id;
        allByTitle.display = display;
        allByTitle.filter_type = Filters.FilterAll;
        allByTitle.sort_type = Filters.SortTitle;
        allByTitle.sort_direction = 'asc';
        allByTitle.statuses = [];
        standards.push(allByTitle);

        standards.forEach(item => {
            item.name = item.generateName(this);
        });

        return standards;
    }

    public generateName(displayFilter: DisplayFilter): string {
        let start;
        let status;
        let period;
        let sort;

        const filter = this.filters(displayFilter.display).find(filter => filter.id == displayFilter.filter_type);
        switch (displayFilter.filter_type) {
            case Filters.FilterWithDeadlineInPeriod:
                start = this.getTranslation('_displays_project_details_milestones_filter_with_deadline_item', {item: displayFilter.task_deadline_type.getSmartName().toLocaleLowerCase()});
                break;
            case Filters.FilterWithoutDeadline:
                start = this.getTranslation('_displays_project_details_milestones_filter_without_deadline_item', {item: displayFilter.task_deadline_type.getSmartName().toLocaleLowerCase()});
                break;
            default:
                start = filter?.name ?? '';
                break;
        }

        let result = start;

        // Status
        const statuses = displayFilter.statuses?.map(status => status.name().toLocaleLowerCase()) ?? [];
        if (statuses.length < 4 && statuses.length > 0) {
            if (statuses.length > 1) {
                const lastStatus = statuses.pop();
                status = this.getTranslation('_ui_filter_status_many', {
                    status1: statuses.join(', '),
                    status2: lastStatus
                });
            } else if (statuses.length == 1) {
                status = this.getTranslation('_ui_filter_status_single', {status: statuses.join(', ')});
            } else {
                status = this.getTranslation('_ui_filter_status_none');
            }
            result += ' ' + status;
        }

        // Period
        if (filter?.showPeriod) {
            switch (displayFilter.period_type) {
                case Filters.PeriodUserDefined:
                    period = this.getTranslation('_ui_filter_deadline_custom', {
                        start: displayFilter.getPeriodStartString(),
                        end: displayFilter.getPeriodEndString()
                    });
                    break;
                case Filters.PeriodLastWeek:
                    period = this.getTranslation('_ui_date_last_week').toLocaleLowerCase();
                    break;
                case Filters.PeriodToday:
                    period = this.getTranslation('_ui_date_today').toLocaleLowerCase();
                    break;
                case Filters.PeriodThisWeek:
                    period = this.getTranslation('_ui_date_this_week').toLocaleLowerCase();
                    break;
                case Filters.PeriodNext2Week:
                    period = this.getTranslation('_ui_date_next_2_weeks').toLocaleLowerCase();
                    break;
                case Filters.PeriodNextMonth:
                    period = this.getTranslation('_ui_date_next_month').toLocaleLowerCase();
                    break;
                case Filters.PeriodNext3Months:
                    period = this.getTranslation('_ui_date_next_3_months').toLocaleLowerCase();
                    break;
                case Filters.PeriodNextYear:
                    period = this.getTranslation('_ui_date_next_year').toLocaleLowerCase();
                    break;
            }
            if (period?.length) {
                result += ' ' + period;
            }
        }

        // Sort
        const sortMatch = this.getSort(displayFilter.display, displayFilter.sort_type);
        if (sortMatch) {
            sort = sortMatch.name.toLocaleLowerCase();
            if (sortMatch.hasOrderDirection) {
                sort = sort + ' ' + (displayFilter.sort_direction == 'asc' ? 'a-z' : 'z-a');
            }
        }

        if (displayFilter.starred) {
            result += ', ' + this.getTranslation('_ui_starred').toLocaleLowerCase();
        }
        if (displayFilter.hand_up) {
            result += ', ' + this.getTranslation('_ui_hand_up').toLocaleLowerCase();
        }

        if (sort) {
            result += ', ' + this.getTranslation('_ui_filter_sortet_on').toLocaleLowerCase() + ' ' + sort;
        }

        return result;
    }

    public getEditorConfig(display: Display, result?: (config: DisplayFilterEditorFormConfigInterface) => void): DisplayFilterEditorFormConfigInterface {
        const config: DisplayFilterEditorFormConfigInterface = {};

        if (display == null) {
            // Not fit for editor config
            if (result) {
                result(config);
            }
            return config;
        }

        const runner = new CountRunner(2);
        runner.setRunner(() => {
            if (result) {
                result(config);
            }
        })
        runner.start();

        TaskDeadlineType.GetAll(taskDeadlineTypes => {
            config.taskDeadlineTypes = taskDeadlineTypes;
            runner.tick();
        });

        CategoryType.GetAll(categoryTypes => {
            config.categoryTypes = categoryTypes;
            runner.tick();
        });

        return config;
    }

}
