import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {DataFetcherCollection} from "@app/core/DataFetchers/DataFetcherCollection";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-data-fetcher-collection-loader',
    templateUrl: './data-fetcher-collection-loader.component.html',
    styleUrls: ['./data-fetcher-collection-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataFetcherCollectionLoaderComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() dataFetcherCollection: DataFetcherCollection;

    // Bindings to view
    public displayMode: 'loading' | 'completed' | 'none' = 'none';
    public executingDataFetcherNames: string[] = [];

    // Data
    private changeSubscription?: Subscription;
    private finishSubscription?: Subscription;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setup();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.changeSubscription?.unsubscribe();
        this.finishSubscription?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['dataFetcherCollection']) {
            this.setup();
        }
    }

    private setup() {
        this.changeSubscription?.unsubscribe();
        this.finishSubscription?.unsubscribe();

        this.changeSubscription = this.dataFetcherCollection.onChangeEvent.subscribe(() => {
            if (this.dataFetcherCollection.executingFetcherCount > 0) {
                this.displayMode = 'loading';
                this.executingDataFetcherNames = this.dataFetcherCollection.executingFetchers.map(fetcher => fetcher.name);
                this.detectChanges();
            }
        });
        this.finishSubscription = this.dataFetcherCollection.onFinishEvent.subscribe(() => {
            this.displayMode = 'completed';
            this.detectChanges();
            setTimeout(() => {
                this.displayMode = 'none';
                this.detectChanges();
            }, 250);
        });

        this.detectChanges();
    }

}
