<ng-template #rt let-r="result" let-t="term" class="w-100"
             [typedTemplate]="resultTemplateType"
>
    <div class="d-flex align-items-center">
        <div class="d-flex align-items-center w-100" style="min-width: 300px;">
            <ngb-highlight [result]="r?.name" [term]="t"></ngb-highlight>
        </div>
    </div>
</ng-template>

<div class="input-group">
    <input type="search" class="form-control placeholder-sm placeholder-sm"
           autocomplete="off"
           #instance="ngbTypeahead"
           container="body"
           popupClass="result-list"
           [(ngModel)]="internalValues"
           [ngbTypeahead]="search"
           [resultTemplate]="rt"
           [inputFormatter]="formatter"
           (selectItem)="triggerSelection($event, instance)"
           (focus)="clickCheck();focus$.next($any($event.target).value)"
           (click)="click$.next($any($event.target).value)"
           (blur)="onBlurEvent($any($event.target).value)"
           [placeholder]="('_ui_search_placeholder' | translate: {type: ('_department_singular' | translate | lowercase)})"
    />
</div>
