import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {AppointmentListTableColumn} from "@app/pages/displays/display-projects/TableColumns/AppointmentListTableColumn";
import {AppointmentListCell} from "@app/pages/displays/display-projects/Cells/AppointmentListCell";
import {AppointmentFetcher, AppointmentFetcherRequest} from "@app/shared/_ui/lists/appointment-list/AppointmentFetcher";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";

export class AppointmentListColumn extends GenericColumn<AppointmentListCell> implements ColumnDataFetcherInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: AppointmentFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new AppointmentFetcher(column.name);
    }

    public createTableColumns(): AppointmentListTableColumn[] {
        const item = new AppointmentListTableColumn(this);
        this.setTableColumnDefaults(item);
        return [item];
    }

    public createCell(row: ProjectsDisplayRow): AppointmentListCell {
        const cell = new AppointmentListCell(row, this);

        this.dataFetcher.addRequest(new AppointmentFetcherRequest(cell.listConfiguration));
        return cell;
    }

    public implementsCSVExport = true;

}
