import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {PageComponent} from '@app/pages/page.component';
import {Api, LoadCalculationResponse} from '@app/core/http/Api/Api';
import {Settings} from '@app/pages/displays/display-load-summary/Settings';
import * as moment from 'moment';
import Helpers from '@app/core/helpers';
import {PeriodRating} from '@app/core/models/PeriodRating';
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";

@Component({
    selector: 'app-display-load-summary',
    templateUrl: './display-load-summary.component.html',
    styleUrls: ['./display-load-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayLoadSummaryComponent extends PageComponent implements OnInit {
    protected filtersSettings?: FiltersInterface;
    protected columnController?: ColumnController;

    @Input() isMainDisplay = false;
    @Input() displayId: number;
    @Output() isExpandedChangeEvent = new EventEmitter<boolean>();
    @Input() visibleSubDisplays: number;

    // UI
    private expanded: boolean = false;
    public loads: LoadCalculationResponse[];
    public periodRatings: PeriodRating[];
    public workTimeTotal = 0;
    public loadTimeTotal = 0;
    public freeTimeTotal = 0;

    constructor(private _cdr: ChangeDetectorRef) {
        super();
        this.cdr = _cdr;
        this.shellService.setHeaderTitle(this.Constants.System.Loading);

        this.subscribe(this.filterGlobalService.onSettingsPeriodChangeEvent.subscribe(settings => {
            this.loadData();
        }));
    }

    ngOnInit() {
        super.ngOnInit();
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();
        setTimeout(() => {
            this.initialize();
        }, 2000);
    }

    // <editor-fold desc="View">

    public reload() {
        this.periodRatings = null;
        this.loads = null;
        this.initialized = false;
        this.loadData();
    }

    public toggleExpanded() {
        this.expanded = !this.expanded;
        this.isExpandedChangeEvent.emit(this.expanded);
        if (this.expanded) {
            this.loadData();
        }
    }

    @HostListener('window:resize', ['$event'])
    sizeChange(event: Event) {
        setTimeout((t: any) => {
        });
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    private loadData() {

        this.isLoading = true;

        let start = this.filterGlobalService.getActiveSettings().period.start;
        start = moment(start).startOf('isoWeek').toDate();
        let end = moment(start).endOf('isoWeek').toDate();

        this.loadLoads(start, end);
        this.loadPeriodRatings(start, end);
    }

    private loadLoads(start: Date, end: Date) {
        Api.loads().calculateGetByLoadId(this.getConfigLoadProfileId())
            .department_id(this.department.id)
            .start(Helpers.serverDate(start))
            .end(Helpers.serverDate(end))
            .find(loads => {
                this.loads = loads;
                console.log('loadLoads() : ', this.loads)
                this.render();
            });
    }

    private loadPeriodRatings(start: Date, end: Date) {
        Api.periodRatings().smartGet()
            .department_id(this.department.id)
            .start(Helpers.serverDate(start))
            .end(Helpers.serverDate(end))
            .find(periodRatings => {
                console.log('loadPeriodRatings() : ', this.periodRatings)
                this.periodRatings = periodRatings;
                this.render();
            });
    }

    private render() {
        if (this.periodRatings) {
            let workTimeTotal = 0;
            this.periodRatings.forEach(rating => workTimeTotal += rating.work_hours ? rating.work_hours : 0);
            this.workTimeTotal = workTimeTotal;
        }

        if (this.loads && this.periodRatings) {
            let loadTimeTotal = 0;
            this.loads.forEach(load => loadTimeTotal += load.load.total ? load.load.total : 0);
            this.periodRatings.forEach(rating => loadTimeTotal += rating.load_ ? rating.load_ : 0);
            this.periodRatings.forEach(rating => loadTimeTotal += rating.custom_meeting_load ? rating.custom_meeting_load : 0);
            this.loadTimeTotal = loadTimeTotal;
            this.freeTimeTotal = this.workTimeTotal - loadTimeTotal;
        }
        this.detectChanges();
    }


    // </editor-fold>

    // <editor-fold desc="Setup">

    private initialize() {
        this.loadData();
        this.initialized = true;
    }

    private getConfigLoadProfileId() {
        if (this.settingsMap.has(Settings.LoadProfileId)) {
            return this.settingsMap.get(Settings.LoadProfileId).getIntValue();
        } else {
            return 0;
        }
    }

    // </editor-fold>
}
