<div class="d-flex align-items-start flex-column">
    <div class="d-flex align-items-center flex-row">
    <app-color-item
        *ngIf="staticDeadline.color"
        [color]="staticDeadline.color"
        class="me-1 d-flex flex-direction-row align-items-center"
    />

    <div class="text-capitalize-firstletter ellipsis deadline-header text-bold" style="min-width: 160px; max-width: 200px;" [ngbTooltip]="staticDeadlineName">
        {{staticDeadlineName}}
    </div>
    </div>

    <div class="position-relative d-flex align-items-center w-100">
        <span>{{staticDeadlineDate | localizedDate}} </span>
        <div *ngIf="hasPreviousDeadline" class="badge badge-pill badge-info ms-auto" style="" >{{ diffDescription }}</div>
    </div>

</div>
