import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    ProjectStatusListTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectStatusListTableColumn";
import {
    ProjectStatusListCell
} from "@app/pages/displays/display-project-details/subdisplay-top/Cells/ProjectStatusListCell";
import {ColumnTypeSettings} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypeSettings";
import {
    ProjectDetails_Top_ProjectStatusTypesSettingValue
} from "@app/core/http/Api/Api";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";

export class ProjectStatusListColumn extends BaseColumn<ProjectStatusListCell> {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new ProjectStatusListTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: Row): BaseCell {
        return new ProjectStatusListCell(row, this);
    }

    public getStatusTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.ProjectStatusTypes)
            ?.getObject<ProjectDetails_Top_ProjectStatusTypesSettingValue>()
            ?.projectStatusTypeIds ?? [];
    }

}
