/**
 * Created by ModelParser
 */
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class LogDefinition extends BaseModel {
    log_type_id?: number;
    type?: string;
    model?: string;
    action?: string;
    ip?: string;
    model_id?: number;
    severity?: number;
    description?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.log_type_id;
            delete this.type;
            delete this.model;
            delete this.action;
            delete this.ip;
            delete this.model_id;
            delete this.severity;
            delete this.description;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.log_type_id != null) {
            this.log_type_id = data.log_type_id;
        }
        if (data.type != null) {
            this.type = data.type;
        }
        if (data.model != null) {
            this.model = data.model;
        }
        if (data.action != null) {
            this.action = data.action;
        }
        if (data.ip != null) {
            this.ip = data.ip;
        }
        if (data.model_id != null) {
            this.model_id = data.model_id;
        }
        if (data.severity != null) {
            this.severity = data.severity;
        }
        if (data.description != null) {
            this.description = data.description;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
