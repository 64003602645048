import {NgModule} from "@angular/core";
import {YearWheelComponent} from "@app/shared/_ui/columns/year-wheel/year-wheel.component";
import {ScrollDirectiveModule} from "@app/directives/ScrollDirective.module";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {
    YearWheelItemDisplayModule
} from "@app/shared/_ui/columns/year-wheel/year-wheel-item-display/YearWheelItemDisplay.module";
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ScrollDirectiveModule,
        LocalizedDatePipeModule,
        YearWheelItemDisplayModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        YearWheelComponent,
    ],
    exports: [
        YearWheelComponent

    ],
    providers: [

    ],
})
export class YearWheelModule {}
