import {NgModule} from "@angular/core";
import {DisplayTeamComponent} from "@app/pages/displays/display-team/display-team.component";
import {SubDisplayWrapperModule} from "@app/pages/displays/sub-display-wrapper/SubDisplayWrapper.module";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {ColumnSorterModule} from "@app/shared/_ui/column-sorter/ColumnSorter.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ProjectListModule} from "@app/shared/_ui/lists/project-list/ProjectList.module";
import {MilestoneListModule} from "@app/shared/_ui/lists/milestone-list/MilestoneList.module";
import {TaskListModule} from "@app/shared/_ui/lists/task-list/TaskList.module";
import {AppointmentListModule} from "@app/shared/_ui/lists/appointment-list/AppointmentList.module";
import {TodoListModule} from "@app/shared/_ui/lists/todo-list/TodoList.module";
import {ScrollDetectorDirectiveModule} from "@app/directives/ScrollDetectorDirective.module";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {OnScreenFilterSelectorModule} from "@app/shared/_ui/on-screen-filter-selector/OnScreenFilterSelector.module";
import {ItemCounterModule} from "@app/shared/_elements/item-counter/ItemCounter.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";
import {DisplayExpandButtonModule} from "@app/shared/_elements/display-expand-button/DisplayExpandButton.module";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";
import {
    DataFetcherCollectionLoaderModule
} from "@app/pages/displays/display-projects/data-fetcher-collection-loader/data-fetcher-collection-loader.module";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {NgxDatatableExtensionModule} from "@app/directives/NgxDatatableExtension.module";

@NgModule({
    imports: [
        CommonModule,
        SubDisplayWrapperModule,
        NgxDatatableModule,
        ColumnSorterModule,
        NgbTooltipModule,
        TranslateModule,
        DragDropModule,
        CardsModule,
        ProjectListModule,
        MilestoneListModule,
        TaskListModule,
        AppointmentListModule,
        TodoListModule,
        ScrollDetectorDirectiveModule,
        LoadingIndicatorModule,
        OnScreenFilterSelectorModule,
        ItemCounterModule,
        DisplayExpandButtonModule,
        TypedTemplateDirective,
        DataFetcherCollectionLoaderModule,
        LocalizedDatePipeModule,
        NgxDatatableExtensionModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DisplayTeamComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class DisplayTeamModule {}
