/**
 * Created by ModelParser
 */
import {LanguageCategory} from '../LanguageCategory';
import {LanguageValue} from '../LanguageValue';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class LanguageKeyDefinition extends BaseModel {
    key?: string;
    language_category_id?: number;
    language_category?: LanguageCategory;
    editable?: boolean;
    note?: string;
    is_rich_text?: boolean;
    language_values?: LanguageValue[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.key;
            delete this.language_category_id;
            delete this.language_category;
            delete this.editable;
            delete this.note;
            delete this.is_rich_text;
            delete this.language_values;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.key != null) {
            this.key = data.key;
        }
        if (data.language_category_id != null) {
            this.language_category_id = data.language_category_id;
        }
        if (data.language_category != null) {
            this.language_category = new LanguageCategory(data.language_category);
        }
        if (data.editable != null) {
            this.editable = data.editable;
        }
        if (data.note != null) {
            this.note = data.note;
        }
        if (data.is_rich_text != null) {
            this.is_rich_text = data.is_rich_text;
        }
        if (data.language_values != null) {
            this.language_values = data.language_values.map((i: any) => new LanguageValue(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
