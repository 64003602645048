import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    TaskListStatusTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/TaskListStatusTableColumn";
import {
    TaskListStatusCell
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Cells/TaskListStatusCell";
import {ColumnTypeSettings} from "@app/pages/displays/display-project-details/subdisplay-milestones/ColumnTypeSettings";
import {
    ProjectDetails_Milestones_TaskListStatusColorsSettingValue
} from "@app/core/http/Api/Api";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-milestones/Row";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {StatusTypes} from "@app/constants";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-milestones/Filters";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";

export class TaskListStatusColumn extends BaseColumn<TaskListStatusCell> implements ColumnDataFetcherInterface, SortableColumnInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TaskFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    // <editor-fold desc="Sorting">

    public implementsSorting: true = true;

    public applyRowSort(row: Row, tableColumn: TaskListStatusTableColumn): void {
        tableColumn.column.getCell(row).listConfigurations.forEach(listConfiguration => {
            listConfiguration.setOrderBy(
                this.getOrderBy(Filters.GetBaseSort(tableColumn.activeSortTypeId), tableColumn.activeSortDirection)
            );

            listConfiguration.clearSortFilters();
            switch (Filters.GetBaseSort(tableColumn.activeSortTypeId)) {
                case Filters.SortDeadlineWith:
                    listConfiguration.setSort_DeadlineValidator(task => {
                        return task.findTasksDeadlineByType(Filters.ParseSortDeadlineWith(tableColumn.activeSortTypeId))?.exists();
                    });
                    break;
                case Filters.SortCategoryType:
                    listConfiguration.setSort_CategoryTypeId(parseInt(Filters.ParseSortCategoryType(tableColumn.activeSortTypeId)));
                    break;
            }
        });
    }

    private getOrderBy(type: string, direction: string): string[][] {
        const orderBy: string[][] = [];
        switch (Filters.GetBaseSort(type)) {
            case Filters.SortTitle:
                orderBy.push(['title', direction]);
                break;
            case Filters.SortStatus:
                orderBy.push(['main_status.status_id', direction]);
                break;
            case Filters.SortDeadlines:
                orderBy.push(['tasks_deadline.deadline.date', 'null'], ['tasks_deadline.deadline.date', direction]);
                break;
            case Filters.SortPlanningDate:
                orderBy.push(['tasks_user.deadline.date', direction]);
                break;
            case Filters.SortStars:
                orderBy.push(['num_stars', (direction == 'asc') ? 'desc' : 'asc']);
                break;
            case Filters.SortHands:
                orderBy.push(['num_hand_ups', (direction == 'asc') ? 'desc' : 'asc']);
                break;
            case Filters.SortUserDefined:
                orderBy.push(['milestones_task.index_', 'asc']);
                orderBy.push(['main_status.status_id', 'asc']);
                orderBy.push(['tasks_deadline.deadline.date', 'asc']);
                orderBy.push(['title', 'asc']);
                break;
            case Filters.SortDeadlineWith:
                orderBy.push(['tasks_deadline.deadline.date', direction]);
                break;
            case Filters.SortCategoryType:
                orderBy.push(['category.name', direction]);
                break;
        }
        return orderBy;
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new TaskFetcher(
            undefined, undefined, undefined,
            column.name
        );
        this.dataFetcher.setMainStatusIds(
            this.getColors().map(color => {
                switch (color) {
                    default:
                    case 'grey':
                        return StatusTypes.GREY;
                    case 'yellow':
                        return StatusTypes.YELLOW;
                    case 'red':
                        return StatusTypes.RED;
                    case 'green':
                        return StatusTypes.GREEN;
                }
            })
        );
    }

    createTableColumns(): BaseTableColumn[] {
        // Share sort items across all table columns
        const sortItems = [
            PageColumnSort.CreateWithSortId(Filters.SortTitle),
            PageColumnSort.CreateWithSortId(Filters.SortDeadlines),
            PageColumnSort.CreateWithSortId(Filters.SortPlanningDate),
            PageColumnSort.CreateWithSortId(Filters.SortStars),
            PageColumnSort.CreateWithSortId(Filters.SortHands),
            PageColumnSort.CreateWithSortId(Filters.SortUserDefined),
        ];

        return this.getColors().map(color => {
            const tableColumn = new TaskListStatusTableColumn(this, color);
            tableColumn.sortItems = sortItems;
            this.setTableColumnDefaults(tableColumn);
            return tableColumn;
        });
    }

    createCell(row: Row): BaseCell {
        const cell = new TaskListStatusCell(row, this);

        cell.listConfigurations.forEach(listConfiguration => {
            this.dataFetcher.addRequest(new TaskFetchRequest(listConfiguration));
        });

        return cell;
    }

    public getColors(): string[] {
        return this.settings.get(ColumnTypeSettings.TaskList_Status_Colors)
            ?.getObject<ProjectDetails_Milestones_TaskListStatusColorsSettingValue>()
            ?.colorTypes ?? [];
    }

}
