import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {EventEmitter} from '@angular/core';
import {Project, Task} from "@app/core/models";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";

export class Row extends BaseRow {

    project: Project;

    // Data
    taskEditListItems: CardItem<Task>[];

    // Events
    minimizeEvent: EventEmitter<any> = new EventEmitter();

    constructor(project: Project) {
        super();
        this.project = project;
    }

    public reload() {

    }

}
