import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    ProjectEstimateListTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectEstimateListTableColumn";
import {
    ProjectEstimateListCell
} from "@app/pages/displays/display-project-details/subdisplay-top/Cells/ProjectEstimateListCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {ColumnTypeSettings} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypeSettings";
import {
    ProjectDetails_Top_ProjectEstimateTypesSettingValue
} from "@app/core/http/Api/Api";

export class ProjectEstimateListColumn extends BaseColumn<ProjectEstimateListCell> {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new ProjectEstimateListTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: Row): BaseCell {
        return new ProjectEstimateListCell(row, this);
    }

    public getEstimateTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.ProjectEstimateTypes)
            ?.getObject<ProjectDetails_Top_ProjectEstimateTypesSettingValue>()
            ?.projectEstimateTypeIds ?? [];
    }

}
