<!--
toggle menu, to show each option
-->

<div
    *ngIf="miniCardOptions"
    ngbDropdown
    [autoClose]="'outside'"
    [container]="container"
    [placement]="placement"
>

    <div class="d-flex align-items-center no-wrap">
        <a class="no-caret text-white"
           ngbDropdownToggle
           [ngbTooltip]="('_ui_visibility' | translate)"
           placement="bottom top auto"
        >
            <i class=" fa-eye"
               [class.fal]="!hasEnabledVisibilityOption"
               [class.fas]="hasEnabledVisibilityOption"></i>

            <!-- warning triangle if enabled -->
            <i class="fas fa-exclamation-triangle text-warning"
               style="transform: scale(.75) translate(-10px, -5px); position:absolute; "
               *ngIf="hasEnabledVisibilityOption"
            ></i>
        </a>

        <ul class="dropdown-menu sub-menu p-0 mt-4 pt-2 pb-2 border shadow-sm visibility-dropdown" ngbDropdownMenu>
            <li class="ps-2 pe-2 mt-0 mb-0" *ngFor="let option of miniCardOptions">
                <app-toggle-item [active]="option.enabled"
                                 [iconWeightActive]="'fal'"
                                 [iconWeightInactive]="'fal'"
                                 [iconInactive]=" 'fa-ban'"
                                 [icon]="'fa-check-circle'"
                                 [iconColorInactive]=" 'text-danger'"
                                 [iconColorActive]="'text-success'"
                                 [interactive]="true"
                                 [label]="(option.label | translate)"
                                 (onToggle)="setOption(option, $event)"
                ></app-toggle-item>

            </li>

        </ul>
    </div>
</div>
