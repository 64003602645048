import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {ProjectEstimate} from "@app/core/models";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {
    ProjectEstimateListColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/ProjectEstimateListColumn";

export class ProjectEstimateListCell extends BaseCell {

    public projectEstimates: ProjectEstimate[];

    public constructor(row: Row, column: ProjectEstimateListColumn) {
        super(row, column);

        const estimates: ProjectEstimate[] = [];
        column.getEstimateTypes()
            ?.forEach(estimateTypeId => {
                const estimate = row.projectCard.item.findProjectEstimateByType(estimateTypeId);
                if (estimate) {
                    estimates.push(estimate);
                }
            });
        this.projectEstimates = estimates;
    }

}
