import {EventEmitter, Injectable} from "@angular/core";
import {ShellColumnGroup} from "@app/services/ShellColumnService/ShellColumnGroup";

@Injectable({
    providedIn: 'root'
})
export class ShellColumnService {

    // Data
    private columnGroups: ShellColumnGroup[] = [];

    // Events
    public onColumnGroupChangeEvent = new EventEmitter<ShellColumnGroup[]>();

    // Public methods

    public attachGroup(group: ShellColumnGroup) {
        this.columnGroups.push(group);
        this.onColumnGroupChangeEvent.emit(this.columnGroups);
    }

    public detachGroup(group: ShellColumnGroup) {
        this.columnGroups.splice(this.columnGroups.indexOf(group), 1);
        this.onColumnGroupChangeEvent.emit(this.columnGroups);
    }

    public getAllGroups(): ShellColumnGroup[] {
        return this.columnGroups;
    }

}
