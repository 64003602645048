import {NgModule} from "@angular/core";
import {
    CreateProjectRedirectPlanningDialogComponent
} from "@app/shared/_modals/create-project-redirect-planning-dialog/create-project-redirect-planning-dialog.component";
import {NgxModalDraggableDirectiveModule} from "@app/directives/NgxModalDraggableDirective.module";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {
    ProjectEditCollectionModule
} from "@app/editor/quick-editor/collections/project-edit-collection/ProjectEditCollection.module";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgxModalDraggableDirectiveModule,
        ColorItemModule,
        TranslateModule,
        NgbTooltipModule,
        CardsModule,
        ProjectEditCollectionModule,
        LoadingIndicatorModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CreateProjectRedirectPlanningDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class CreateProjectRedirectPlanningDialogModule {}
