import {NgModule} from "@angular/core";
import {
    MissingDepartmentsDialogComponent
} from "@app/shared/_modals/missing-departments-dialog/missing-departments-dialog.component";
import {NgxModalDraggableDirectiveModule} from "@app/directives/NgxModalDraggableDirective.module";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {DepartmentPickerModule} from "@app/shared/_forms/department-picker/DepartmentPicker.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgxModalDraggableDirectiveModule,
        ColorItemModule,
        NgbTooltipModule,
        TranslateModule,
        DepartmentPickerModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MissingDepartmentsDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class MissingDepartmentsDialogModule {}
