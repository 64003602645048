import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";
import {CSVExporter} from "@app/export/csv/CSVExporter";

export class ProjectDeadlineListCell extends GenericCell {

    public exportCSV(secondary?: any): CSVCellValue {
        const projectsDeadline = this.row.project.item.findProjectsDeadlineByType(secondary);
        if (projectsDeadline?.deadline?.exists()) {
            return CSVExporter.convertToDate(projectsDeadline.deadline.getDate());
        } else {
            return '';
        }
    }

}
