import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'placeholder' })
export class PlaceholderPipe implements PipeTransform {
    transform(value: string, replacement?: string): string {
        if(!value || value == ''){
            return replacement ? replacement : '*Mangler*'
        }
        return value;
    }
}