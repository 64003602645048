import {DisplaysSetting} from "@app/core/models";

export class PageDisplaySetting {
    value: any = null;
    values: any[] = [];
    displaysSettings: DisplaysSetting[] = [];

    addSetting(displaysSetting: DisplaysSetting) {
        this.displaysSettings.push(displaysSetting);
        this.value = displaysSetting.value;
        this.values.push(displaysSetting.value);
    }

    public isSet() {
        return this.value !== '0';
    }

    public getIntValue(): number {
        return Number(this.value);
    }

    public getBoolean(): boolean {
        return this.value != null && this.value != "0";
    }

    public getIntValues(): number[] {
        let ints = [];
        for (let value of this.values) ints.push(Number(value));
        return ints;
    }
}
