import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {UserTypeItem} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserTypeItem";
import {Deadline, Department, User} from "@app/core/models";
import {
    EditEnhancedUserList,
    EditUserList
} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserList";
import {
    EditUserListConfiguration
} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserListConfiguration";
import {UserItem} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserItem";

@Component({
    selector: 'app-user-list-editor',
    templateUrl: './user-list-editor.component.html',
    styleUrls: ['./user-list-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListEditorComponent extends BaseEditor<EditUserList | EditEnhancedUserList> {
    protected eventFieldName = 'users';

    // Bindings to parent
    @Input() model: (EditUserList | EditEnhancedUserList) & HasEventGenerator & BaseModel;
    @Input() configuration: EditUserListConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public types: UserTypeItem[];

    // Data
    private get enhancedModel(): EditEnhancedUserList & HasEventGenerator & BaseModel {
        return this.model as EditEnhancedUserList & HasEventGenerator & BaseModel;
    }

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
        this.configuration.types.forEach(type => {
            type.userItems = [];
            this.subscribe(type.onUsersRemovedEvent.subscribe(userItems => {
                this.removeUsers(type, userItems.map(userItem => userItem.user));
            }));
        });

        this.types = this.configuration.types;
    }

    protected render() {
        this.types.forEach(type => {
            if ((this.model as EditEnhancedUserList).findEnhancedUsersByTypeId) {
                type.userItems = this.enhancedModel.findEnhancedUsersByTypeId(type.id);
            } else {
                type.userItems = this.model.findUsersByTypeId(type.id).map(user => new UserItem(user));
            }
            type.validate();
        });
    }

    // <editor-fold desc="View actions">

    public onUserAdded(type: UserTypeItem, user: User) {
        const alreadySelected = type.userItems.find(userItem => userItem.user.id == user.id);
        if (alreadySelected !== undefined) {
            return;
        }

        const userItem = new UserItem(user);

        if (type.multiple) {
            type.userItems.push(userItem);
        } else {
            if (type.userItems.length) {
                this.model.removeUsers(type.id, type.userItems.map(user => user.user));
            }
            type.userItems = [userItem];
        }

        this.model.addUser(type.id, user);
        this.onItemUpdated();

        type.onUserAddedEvent.emit(userItem);
    }

    public onDepartmentSelected(type: UserTypeItem, department: Department, users: User[]) {
        this.model.addUsersFromDepartment(type.id, department, users);
        this.onItemUpdated();
    }

    public onUserArchivedChanged(type: UserTypeItem, item: UserItem, value: boolean): void {
        this.enhancedModel.setUserArchived(item.user, value);
        this.onItemUpdated();
    }

    public onUserDeadlineChanged(type: UserTypeItem, item: UserItem, value?: Deadline): void {
        this.enhancedModel.setUserDeadline(item.user, value?.getDate() ?? undefined, value?.is_soft);
        type.onUserDeadlineChangeEvent.emit(item);
        this.onItemUpdated();
    }

    public onTypeUserDeadlineChanged(type: UserTypeItem, value: Deadline) {
        type.userItems.forEach(userItem => {
            this.onUserDeadlineChanged(type, userItem, value.date ? value : undefined);
        });
        this.onItemUpdated();
    }

    // </editor-fold>

    // Debounced removal from UserTypeItem
    private removeUsers(type: UserTypeItem, users: User[]) {
        if (users.length == 1) {
            this.model.removeUser(type.id, users[0]);
        } else if (users.length > 1) {
            this.model.removeUsers(type.id, users);
        }
        this.render();
        this.onItemUpdated();
        this.detectChanges();
    }

    protected onItemUpdated() {
        super.onItemUpdated();
        this.render();
        this.detectChanges();
    }

}
