/**
 * Created by ModelParser
 * Date: 14-04-2024.
 * Time: 13:51.
 */
import {DisplayFiltersColumnDefinition} from './definitions/DisplayFiltersColumnDefinition';
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

export class DisplayFiltersColumn extends DisplayFiltersColumnDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static Create(tableColumn: BaseTableColumn): DisplayFiltersColumn {
        const item = new DisplayFiltersColumn();
        item.column_id = tableColumn.column.column.id;
        item.table_column_identifier = tableColumn.fullIdentifier;
        item.is_visible = true; 
        return item;
    }

}
