<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title" [innerHTML]="'_ui_dialog_prioritization_title' | translate"></h4>

        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <div class="modal-body">

        <div class="d-flex flex-row align-items-start">
            <app-priority-matrix
                class="me-1"
                [color1]="color1"
                [color2]="color2"
                [color3]="color3"
                [color4]="color4"
                [text1]="text1"
                [text2]="text2"
                [text3]="text3"
                [text4]="text4"
                [axisX]="axisX"
                [axisY]="axisY"
            />

            <app-category-picker
                *ngIf="categoryType"
                [internalValue]="presetValue"
                [categoryType]="categoryType"
                [multiple]="false"
                (itemSelected)="setSelectedCategory($event)"
                class="flex-grow-1 mt-4"
            ></app-category-picker>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary ellipsis" (click)="decline()">{{ '_global_cancel' | translate }}</button>
        <div [ngbTooltip]="selectedCategory ? '' : ('_ui_dialog_prioritization_btn_ok_disabled_mouseover' | translate)">
            <button type="button" class="btn btn-success ellipsis" ngbAutofocus
                    [disabled]="!selectedCategory"
                    (click)="accept()"
            >{{ '_global_ok' | translate }}</button>
        </div>
    </div>
</div>
