import {NgModule} from "@angular/core";
import {
    MilestonePlanSelectEditorComponent
} from "@app/editor/quick-editor/editors/project/milestone-plan-select-editor/milestone-plan-select-editor.component";
import {
    MilestonePlanSelectorModule
} from "@app/shared/_elements/milestone-plan-selector/MilestonePlanSelector.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        MilestonePlanSelectorModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MilestonePlanSelectEditorComponent,
    ],
    exports: [
        MilestonePlanSelectEditorComponent

    ],
    providers: [

    ],
})
export class MilestonePlanSelectEditorModule {}
