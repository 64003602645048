import {NgModule} from "@angular/core";
import {NumericSpinnerComponent} from "@app/shared/_elements/numeric-spinner/numeric-spinner.component";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {ValueEvaluatorModule} from "@app/shared/_elements/value-evaluator/ValueEvaluator.module";
import {AutofocusDirectiveModule} from "@app/directives/AutofocusDirective.module";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        ValueEvaluatorModule,
        AutofocusDirectiveModule,
        FormsModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        NumericSpinnerComponent,
    ],
    exports: [
        NumericSpinnerComponent,
        NumericSpinnerComponent

    ],
    providers: [

    ],
})
export class NumericSpinnerModule {}
