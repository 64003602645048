/**
 * Created by ModelParser
 */
import {UserGroup} from '../UserGroup';
import {NotificationSetting} from '../NotificationSetting';
import {Display} from '../Display';
import {Language} from '../Language';
import {Appointment} from '../Appointment';
import {Department} from '../Department';
import {Project} from '../Project';
import {ProjectsUser} from '../ProjectsUser';
import {AppointmentsUser} from '../AppointmentsUser';
import {Deletion} from '../Deletion';
import {Task} from '../Task';
import {TasksUser} from '../TasksUser';
import {Role} from '../Role';
import {Todo} from '../Todo';
import {DisplaysTaskUserType} from '../DisplaysTaskUserType';
import {HandUp} from '../HandUp';
import {Milestone} from '../Milestone';
import {TaskTemplate} from '../TaskTemplate';
import {Origin} from '../Origin';
import {DisplaysSetting} from '../DisplaysSetting';
import {Notification} from '../Notification';
import {NotificationSubscription} from '../NotificationSubscription';
import {Reaction} from '../Reaction';
import {MilestonePlan} from '../MilestonePlan';
import {UserMeta} from '../UserMeta';
import {DisplayFilterUserSetting} from '../DisplayFilterUserSetting';
import {User} from '../User';
import {BaseModel} from '../BaseModel';

export class UserDefinition extends BaseModel {
    user_group_id?: number;
    user_group?: UserGroup;
    username?: string;
    first_name?: string;
    last_name?: string;
    password?: string;
    exchange_email?: string;
    last_exchange_sync?: string;
    exchange_disabled?: boolean;
    hidden?: boolean;
    deactivated?: boolean;
    initials?: string;
    notification_setting_id?: number;
    notification_settings?: NotificationSetting;
    default_displays_department_id?: number;
    default_displays_department?: Display;
    renew_password?: boolean;
    language_id?: number;
    language?: Language;
    is_welcome_mail_sent?: boolean;
    is_waiting_team_selection?: boolean;
    allow_access_to_all_departments?: boolean;
    appointments?: Appointment[];
    departments?: Department[];
    projects?: Project[];
    projects_users?: ProjectsUser[];
    appointments_users?: AppointmentsUser[];
    deletions?: Deletion[];
    tasks?: Task[];
    tasks_users?: TasksUser[];
    roles?: Role[];
    todos?: Todo[];
    displays_task_user_types?: DisplaysTaskUserType[];
    hand_ups?: HandUp[];
    milestones?: Milestone[];
    task_templates?: TaskTemplate[];
    origins?: Origin[];
    displays_settings?: DisplaysSetting[];
    notifications?: Notification[];
    notification_subscriptions?: NotificationSubscription[];
    reactions?: Reaction[];
    milestone_plans?: MilestonePlan[];
    user_metas?: UserMeta[];
    display_filter_user_settings?: DisplayFilterUserSetting[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.user_group_id;
            delete this.user_group;
            delete this.username;
            delete this.first_name;
            delete this.last_name;
            delete this.password;
            delete this.exchange_email;
            delete this.last_exchange_sync;
            delete this.exchange_disabled;
            delete this.hidden;
            delete this.deactivated;
            delete this.initials;
            delete this.notification_setting_id;
            delete this.notification_settings;
            delete this.default_displays_department_id;
            delete this.default_displays_department;
            delete this.renew_password;
            delete this.language_id;
            delete this.language;
            delete this.is_welcome_mail_sent;
            delete this.is_waiting_team_selection;
            delete this.allow_access_to_all_departments;
            delete this.appointments;
            delete this.departments;
            delete this.projects;
            delete this.projects_users;
            delete this.appointments_users;
            delete this.deletions;
            delete this.tasks;
            delete this.tasks_users;
            delete this.roles;
            delete this.todos;
            delete this.displays_task_user_types;
            delete this.hand_ups;
            delete this.milestones;
            delete this.task_templates;
            delete this.origins;
            delete this.displays_settings;
            delete this.notifications;
            delete this.notification_subscriptions;
            delete this.reactions;
            delete this.milestone_plans;
            delete this.user_metas;
            delete this.display_filter_user_settings;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.user_group_id != null) {
            this.user_group_id = data.user_group_id;
        }
        if (data.user_group != null) {
            this.user_group = new UserGroup(data.user_group);
        }
        if (data.username != null) {
            this.username = data.username;
        }
        if (data.first_name != null) {
            this.first_name = data.first_name;
        }
        if (data.last_name != null) {
            this.last_name = data.last_name;
        }
        if (data.password != null) {
            this.password = data.password;
        }
        if (data.exchange_email != null) {
            this.exchange_email = data.exchange_email;
        }
        if (data.last_exchange_sync != null) {
            this.last_exchange_sync = data.last_exchange_sync;
        }
        if (data.exchange_disabled != null) {
            this.exchange_disabled = data.exchange_disabled;
        }
        if (data.hidden != null) {
            this.hidden = data.hidden;
        }
        if (data.deactivated != null) {
            this.deactivated = data.deactivated;
        }
        if (data.initials != null) {
            this.initials = data.initials;
        }
        if (data.notification_setting_id != null) {
            this.notification_setting_id = data.notification_setting_id;
        }
        if (data.notification_settings != null) {
            this.notification_settings = new NotificationSetting(data.notification_settings);
        }
        if (data.default_displays_department_id != null) {
            this.default_displays_department_id = data.default_displays_department_id;
        }
        if (data.default_displays_department != null) {
            this.default_displays_department = new Display(data.default_displays_department);
        }
        if (data.renew_password != null) {
            this.renew_password = data.renew_password;
        }
        if (data.language_id != null) {
            this.language_id = data.language_id;
        }
        if (data.language != null) {
            this.language = new Language(data.language);
        }
        if (data.is_welcome_mail_sent != null) {
            this.is_welcome_mail_sent = data.is_welcome_mail_sent;
        }
        if (data.is_waiting_team_selection != null) {
            this.is_waiting_team_selection = data.is_waiting_team_selection;
        }
        if (data.allow_access_to_all_departments != null) {
            this.allow_access_to_all_departments = data.allow_access_to_all_departments;
        }
        if (data.appointments != null) {
            this.appointments = data.appointments.map((i: any) => new Appointment(i));
        }
        if (data.departments != null) {
            this.departments = data.departments.map((i: any) => new Department(i));
        }
        if (data.projects != null) {
            this.projects = data.projects.map((i: any) => new Project(i));
        }
        if (data.projects_users != null) {
            this.projects_users = data.projects_users.map((i: any) => new ProjectsUser(i));
        }
        if (data.appointments_users != null) {
            this.appointments_users = data.appointments_users.map((i: any) => new AppointmentsUser(i));
        }
        if (data.deletions != null) {
            this.deletions = data.deletions.map((i: any) => new Deletion(i));
        }
        if (data.tasks != null) {
            this.tasks = data.tasks.map((i: any) => new Task(i));
        }
        if (data.tasks_users != null) {
            this.tasks_users = data.tasks_users.map((i: any) => new TasksUser(i));
        }
        if (data.roles != null) {
            this.roles = data.roles.map((i: any) => new Role(i));
        }
        if (data.todos != null) {
            this.todos = data.todos.map((i: any) => new Todo(i));
        }
        if (data.displays_task_user_types != null) {
            this.displays_task_user_types = data.displays_task_user_types.map((i: any) => new DisplaysTaskUserType(i));
        }
        if (data.hand_ups != null) {
            this.hand_ups = data.hand_ups.map((i: any) => new HandUp(i));
        }
        if (data.milestones != null) {
            this.milestones = data.milestones.map((i: any) => new Milestone(i));
        }
        if (data.task_templates != null) {
            this.task_templates = data.task_templates.map((i: any) => new TaskTemplate(i));
        }
        if (data.origins != null) {
            this.origins = data.origins.map((i: any) => new Origin(i));
        }
        if (data.displays_settings != null) {
            this.displays_settings = data.displays_settings.map((i: any) => new DisplaysSetting(i));
        }
        if (data.notifications != null) {
            this.notifications = data.notifications.map((i: any) => new Notification(i));
        }
        if (data.notification_subscriptions != null) {
            this.notification_subscriptions = data.notification_subscriptions.map((i: any) => new NotificationSubscription(i));
        }
        if (data.reactions != null) {
            this.reactions = data.reactions.map((i: any) => new Reaction(i));
        }
        if (data.milestone_plans != null) {
            this.milestone_plans = data.milestone_plans.map((i: any) => new MilestonePlan(i));
        }
        if (data.user_metas != null) {
            this.user_metas = data.user_metas.map((i: any) => new UserMeta(i));
        }
        if (data.display_filter_user_settings != null) {
            this.display_filter_user_settings = data.display_filter_user_settings.map((i: any) => new DisplayFilterUserSetting(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
