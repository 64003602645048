<!-- Category Types -->
<!-- ligesom filter-column.component -->
<ng-container>
    <div class="d-flex flex-row">
        <ul class="list-unstyled d-flex align-items-center flex-wrap  m-0">
            <li class="me-1"
                [ngbTooltip]="popover && popover.isOpen() ? null : tooltip"
                container="body"
                placement="bottom"
            >
            <span class="fa-user-circle cursor-pointer position-relative"
                  [class.fal]="!users || users?.length == 0"
                  [class.fas]="users && users.length > 0"
                  [ngbPopover]="popoverContent"
                  [autoClose]="false"
                  triggers="manual"
                  [placement]="['bottom', 'auto']"
                  container="body"
                  (click)="popover.isOpen() ? popover.close() : popover.open()"
                  #popover="ngbPopover"
            >

            </span>
            </li>
        </ul>
    </div>

    <ng-template #popoverContent>
        <div class="items-container flex-column d-flex">
            <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>


            <div class="d-flex flex-row align-items-center w-100 pb-1 " >

                <h4 class="p-0 m-0 popover-title flex-grow-1" [ngbTooltip]="('_ui_display_reaction_filter_user_description' | translate)"> {{tooltip}}</h4>

                <div class="d-flex ms-auto reset-panel-buttons" >

                    <span (click)="popover.close();reset()"
                          class="cursor-pointer  btn btn-sm btn-danger">
                        <i class="fal fa-eraser" aria-hidden="true"></i>
                        {{'_ui_emoji_clear' | translate}}
                    </span>

                    <span (click)="popover.close();activateFilters();"
                          *ngIf="false"
                          [class.disabled]="!users || users.length == 0"
                          class="cursor-pointer btn btn-sm btn-success">
                        <i class="fa-duotone fa-filters"></i>
                        {{'_ui_filter' | translate}}</span>


                </div>
            </div>

            <div class="d-flex flex-column align-items-center w-100 pb-1 " >

                <ul class="list-unstyled list-group w-100">
                    <li class="list-group-item ">
                        <app-user-search

                            [autoReset]="true"
                            [selectedUsers]="users"
                            (itemSelected)="toggleUser($event)"></app-user-search></li>
                    <li class="list-group-item " *ngFor="let u of users">
                        <div class="d-flex align-items-center gap-1">
<!--                            <app-user-with-icon [model]="u"></app-user-with-icon>-->
                            <app-user-with-icon [navigateToDashboard]="false" [model]="u" [showTooltip]="true"  class="me-1 w-150px ellipsis" ></app-user-with-icon>
                            <app-user-with-icon [navigateToDashboard]="false" [model]="u" [showInitials]="true" [showIcon]="false" [displayStrong]="true" class="ms-auto"></app-user-with-icon>
                            <div class="ms-auto fal fa-trash-alt text-danger cursor-pointer" (click)="toggleUser(u)"></div>
                        </div>
                    </li>
                </ul>



            </div>


        </div>
    </ng-template>

</ng-container>
