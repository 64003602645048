<div [class.card-wrapper]="taskCards"
>
<app-card-preview [mini]="mini" *ngIf="!initialized && visible"></app-card-preview>
<div #cardContainer
     class="card p-1" (click)="onCardClick($event)" *ngIf="model && initialized && visible "
     [class.inline]="(!configuration || configuration.inline)"
     [class.grayed-out]="(configuration && configuration.isGrayedOut)"
     [class.mini]="mini"
     [class.mb-0]="mini"
     [class.focused]="outline"
>

    <div class="d-flex w-100 font-size-lg  align-items-center justify-content-center">

        <app-color-label class="card-type me-1"
                         *ngIf="isFieldVisible(Fields.Status)"
                         [status]="model.status"
                         [itemWithTypeProperties]="model"
                         (onStatusChange)="onStatusChange($event)"></app-color-label>

        <div class="title p-0 font-weight-bold col -border-bottom -border-light flex-fill me-1"
             *ngIf="isFieldVisible(Fields.Title)"
        >
            <input class="form-control" [(ngModel)]="model.title" *ngIf="editable"/>
            <div *ngIf="!editable" class="pt-0 h-100 text-capitalize-firstletter"
                 [class.unset-height]="(!configuration || configuration.inline)"
                 [ngbTooltip]="tooltip ? tooltip : (model.title | placeholder: ('_ui_no_title' | translate))"
            >{{model.title | placeholder: ('_ui_no_title' | translate)}}
            </div>
        </div>

        <!-- Micro card icons -->
        <ng-container *ngIf="mini">
            <a class="font-size-md text-danger remove d-block flex-row align-items-center ms-1 me-1"
               (click)="triggerRemove(model);$event.stopPropagation()"
               [ngbTooltip]="('_ui_emoji_clear' | translate)"
               *ngIf="removable">
                <i class="fal fa-eraser"></i>
            </a>
        </ng-container>

    </div>


    <ng-container *ngIf="!mini">
        <div class="justify-content-between font-size-md mt-1 d-flex flex-col flex-wrap w-100 card-details"
             *ngIf="!editable && !mini && !simple && ((!configuration || configuration.showActions) || date && (!configuration ||configuration.showDate) && isFieldVisible(Fields.Deadline))"
        >
            <a class="text-danger remove d-flex flex-row align-items-center ms-1 me-1"
               (click)="triggerRemove(model);$event.stopPropagation()"
               [ngbTooltip]="('_ui_emoji_clear' | translate)"
               *ngIf="removable">
                <i class="fal fa-eraser"></i>
            </a>


            <ng-container *ngIf="!mini">
                <div class="d-flex justify-content-between font-size-md align-items-center flex-grow-1"
                     *ngIf="!editable && !simple">

                    <ng-template #popoverContent>
                        <div class="scale-2x-wrapper">
                            <div class="card-wrap">
                                <app-card-milestone *ngIf="item" [item]="item"></app-card-milestone>
                            </div>
                        </div>
                    </ng-template>


                    <div class="d-flex flex-row w-100 justify-content-start align-items-center"
                         *ngIf="(!configuration || configuration.showActions) || (date && (!configuration ||configuration.showDate) && isFieldVisible(Fields.Deadline))"
                    >
                        <ul class="flex-grow-1 d-flex list-unstyled justify-content-start align-items-center m-0 flex-wrap"
                            *ngIf="(!configuration || configuration.showActions)">

                            <!-- https://podio.com/klartboard/softwareudvikling/apps/stories/items/1169 -->
                            <li
                                class="pe-1"
                                #toolsPopover="ngbPopover"
                                [ngbPopover]="toolsDropdownContent"
                                [autoClose]="'outside'"
                                placement="bottom left auto"
                                triggers="manual"
                                container="body"
                                (click)="$event.stopImmediatePropagation(); !toolsPopover.isOpen() ? toolsPopover.open() : toolsPopover.close()"
                            >
                                <i class="fal fa-toolbox cursor-pointer"
                                   [ngbTooltip]="('_ui_navigation_tools' | translate)"></i>

                                <ng-template #toolsDropdownContent>
                                    <ul class="d-flex list-unstyled justify-content-start align-items-center m-0 flex-wrap "
                                        [class.disabled]="model.id == 0"
                                    >
                                        <li class="pe-1">
                                            <app-toggle-item
                                                *ngIf="isFieldVisible(Fields.Archived)"
                                                [interactive]="true"
                                                [active]="model.archived_id > 0"
                                                [class.text-black-50]="!model?.archived_id"
                                                [ngbTooltip]="model?.archived_id == 0 ? translateService.instant('_ui_complete') : translateService.instant('_ui_reopen')"
                                                [icon]="'fa-check-circle'"
                                                [iconInactive]="'fa-circle'"
                                                (onToggle)="onArchivedToggle($event); toolsPopover.close();"></app-toggle-item>
                                        </li>
                                        <li class="pe-1" *ngIf="isFieldVisible(Fields.HandUp)">
                                            <app-hand-up-selector [model]="model"
                                                                  [showUsers]="false"
                                                                  (onToggle)=" toolsPopover.close();"></app-hand-up-selector>
                                        </li>
                                        <li class="pe-1" *ngIf="isFieldVisible(Fields.Star)">
                                            <app-star-selector *ngIf="isFieldVisible(Fields.Star)" [model]="model"
                                                               [showUsers]="false"
                                                               (onToggle)=" toolsPopover.close();"></app-star-selector>
                                        </li>
                                        <li class="pe-1">
                                            <app-reactions [reactionsApiInterface]="item?.item"
                                                           [reactionsSourceInterface]="item?.item"
                                                           [reactionsTypeInterfaces]="reactionsTypeInterfaces"
                                                           [reactions]="item?.item?.reactions"
                                                           [reactionsEditable]="true"
                                                           [reactionsVisible]="false"
                                                           (onToggle)=" toolsPopover.close();"
                                                           (click)="$event.stopImmediatePropagation()"></app-reactions>

                                        </li>

                                        <li class="pe-1"
                                            [ngbPopover]="popoverContent"
                                            placement="bottom ottom-left left-bottom right-bottom top-left auto"
                                            popoverClass="popover-transparent"
                                            container="body"
                                            [autoClose]="true"
                                            triggers="mouseenter:mouseleave"
                                        >
                                            <a class="cursor-pointer">
                                                <i class="fal fa-search-plus "></i>
                                            </a>

                                        </li>
                                        <li *ngIf="removable && false" class="pe-1"
                                            (click)="triggerRemove(model);$event.stopPropagation()">
                                            <a class="-text-danger remove d-flex flex-row align-items-center cursor-pointer"
                                            >
                                                <i class="fal fa-trash-alt"></i>
                                            </a>
                                        </li>

                                        <li class="pe-1"
                                            [ngbTooltip]="translateService.instant('_ui_copy') + '...'"
                                            (click)="copyMilestone();$event.stopPropagation();toolsPopover.close();">
                                            <a class="-text-danger remove d-flex flex-row align-items-center cursor-pointer"
                                            >
                                                <i class="fal fa-copy"></i>
                                            </a>
                                        </li>

                                        <li  class="pe-1"
                                            (click)="delete();$event.stopPropagation();toolsPopover.close();">
                                            <a class="-text-danger remove d-flex flex-row align-items-center cursor-pointer text-danger"
                                            >
                                                <i class="fal fa-trash-alt"></i>
                                            </a>
                                        </li>

                                    </ul>
                                </ng-template>
                            </li>

                            <li class="pe-1" *ngIf="model?.archived_id != 0">
                                <app-toggle-item
                                    *ngIf="isFieldVisible(Fields.Archived)"
                                    [interactive]="true"
                                    [active]="model.archived_id > 0"
                                    [class.text-black-50]="!model?.archived_id"
                                    [tooltipLabel]="model?.archived_id == 0 ? translateService.instant('_ui_complete') : translateService.instant('_ui_reopen')"
                                    [icon]="'fa-check-circle'"
                                    [iconInactive]="'fa-circle'"
                                    (onToggle)="onArchivedToggle($event)"></app-toggle-item>
                            </li>

                            <li class="pe-1" *ngIf="isFieldVisible(Fields.HandUp) && model.num_hand_ups > 0">
                                <app-hand-up-selector [model]="model"></app-hand-up-selector>
                            </li>

                            <li class="pe-1" *ngIf="isFieldVisible(Fields.Star) && model.num_stars > 0">
                                <app-star-selector [model]="model"></app-star-selector>
                            </li>

                            <li class="relation m-0 me-1 user-list d-flex align-items-center "
                                *ngIf="model.responsible && isFieldVisible(Fields.Responsible)  && (!configuration ||configuration.showResponsible)">
                                <app-users-with-icon
                                    class="w-100"
                                    [initials]="true"
                                    [icon]="true"
                                    [users]="[model.responsible]"></app-users-with-icon>
                            </li>

                            <li class="d-flex align-items-center flex-wrap pt-1"
                                *ngIf="item && (!configuration || configuration.showReactions)"
                                [class.d-none]="!hasVisibleReactions"
                                [class.d-flex]="hasVisibleReactions"
                            >
                                <app-reactions [reactionsApiInterface]="item.item"
                                               [reactionsSourceInterface]="item.item"
                                               [reactionsTypeInterfaces]="reactionsTypeInterfaces"
                                               [reactions]="item.item.reactions"
                                               [reactionsPickerVisible]="false"
                                               (onDataSetChanged)="onReactionDataSetChanged($event);"
                                               (click)="$event.stopImmediatePropagation()"></app-reactions>

                            </li>


                            <ng-container *ngIf="isFieldVisible(Fields.CalculatedFields) && item?.item">
                                <li *ngFor="let calculatedFieldsMilestone of model.calculated_fields_milestones"
                                    class="m-0 me-1">
                                    <app-milestone-calculated-field
                                        [milestone]="item.item"
                                        [calculatedField]="calculatedFieldsMilestone.calculated_field"
                                        [calculatedFieldType]="Constants.CalculatedFieldTypes.Milestone_RelatedTasksEstimateSum"
                                        [calculatedFieldsMilestone]="calculatedFieldsMilestone"
                                    ></app-milestone-calculated-field>
                                </li>
                            </ng-container>

                        </ul>
                        <ul class="flex-grow-1 d-flex list-unstyled justify-content-end align-items-center m-0 flex-wrap"
                            *ngIf="date && (!configuration ||configuration.showDate) && isFieldVisible(Fields.Deadline)">
                            <li class="ms-auto align-self-end"
                                *ngIf="date && (!configuration || configuration.showDate)">
                                <app-item-date
                                    [showIcon]="!isMobile"
                                    [date]="date" *ngIf="isFieldVisible(Fields.Deadline)"
                                    [showDatepicker]="false"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
                <ul class="d-flex list-unstyled justify-content-between align-items-center w-100 m-0 border-top phase flex-wrap text-small"
                    *ngIf="(!configuration || configuration.showProjectPhase) && !simple && ( phasesProject || (item && item.item?.categories?.length > 0))">

                    <li class="-d-none" (click)="$event.stopPropagation()">
                        <app-phases-project-picker
                            [projects]="item.item?.projects"
                        ></app-phases-project-picker>
                    </li>
                    <li class="d-flex flex-row align-items-center -w-100 ellipsis" *ngIf="phasesProject">
                        <app-color-item *ngIf="phasesProject?.phase?.color" [color]="phasesProject?.phase?.color"
                                        class="me-1"></app-color-item>
                        <div class="text-capitalize-firstletter ellipsis">{{phasesProject.phase.name}}</div>
                    </li>


                    <ng-container *ngIf="item && item.item?.categories?.length > 0">
                        <li *ngFor="let category of item.item.categories"
                            class="w-100"
                            [ngbTooltip]="category.category_type?.name">
                            <app-category
                                class="d-flex flex-row align-items-center -w-100 ellipsis"
                                [category]="category"></app-category>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>

        </div>
    </ng-container>
    <div class="d-flex project-display w-100 mt-1" *ngIf="projectCard && isFieldVisible(Fields.Project) && !simple">
        <app-card-project [item]="$any(projectCard)" [model]="$any(projectCard.item)"
                          [configuration]="$any(projectCard.configuration)"
                          [mini]="true" class="w-100"></app-card-project>
    </div>


</div>

<ul *ngIf="taskCards && showTaskCards" class="list-unstyled relations p-1 pb-0 m-0 mb-1 pt-0">
    <ng-container *ngFor="let tc of taskCards">
        <li class="mb-1 d-flex align-items-center">
            <i class="fa-light fa-arrow-turn-down-right me-2 ms-3"></i>
            <app-card-task [item]="tc" [model]="tc.item"
                           [configuration]="$any(tc.configuration)"
                           [mini]="true" class="w-100"></app-card-task>
        </li>
    </ng-container>
</ul>

</div>
