import {NgModule} from "@angular/core";
import {
    PhasesProjectPickerComponent
} from "@app/shared/_elements/phases-project-picker/phases-project-picker.component";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ProjectPhaseSearchModule} from "@app/shared/_elements/project-phase-search/ProjectPhaseSearch.module";
import {ProcessStepsModule} from "@app/shared/_elements/process-steps/ProcessSteps.module";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {SmallCardProjectModule} from "@app/shared/_ui/cards/small/small-card-project/SmallCardProject.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbPopoverModule,
        TranslateModule,
        ProjectPhaseSearchModule,
        NgbTooltipModule,
        ProcessStepsModule,
        LoadingIndicatorModule,
        ColorItemModule,
        LocalizedDatePipeModule,
        SmallCardProjectModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        PhasesProjectPickerComponent,
    ],
    exports: [
        PhasesProjectPickerComponent

    ],
    providers: [

    ],
})
export class PhasesProjectPickerModule {}
