<div class="modal-inner-content p-0" [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header d-flex flex-row">
        <h4 class="modal-title" >{{'_global_settings' | translate}}</h4>

        <!-- Password -->
        <app-toggle-item
            [active]="changePassword"
            [iconColorInactive]="'text-black-25'"
            [iconColorActive]="'text-success'"
            [label]="('_ui_settings_change_password' | translate)"
            [interactive]="true"
            (onToggle)="changePassword = !changePassword; updateValidation(changePassword)"
            icon="fa-check-circle"
            iconInactive="fa-circle"
        ></app-toggle-item>

        <div class="form-check" *ngIf="false">
            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" [(ngModel)]="changePassword"
                   (change)="updateValidation(changePassword)">
            <label class="form-check-label" for="defaultCheck1">
                {{('_ui_settings_change_password' | translate)}}
            </label>
        </div>
    </div>
    <div class="modal-body p-0 " *ngIf="initialized && editForm">
        <div class="container p-0">
            <form [formGroup]="editForm" (ngSubmit)="saveForm()" novalidate
                  [@fadeAnimation]="'in'"
                  class="h-100">
                <div class="d-flex flex-column h-100" style="padding-bottom: 61px;">

                    <div class="scroll-y align-self-stretch card-content">

                        <div class="container p-2">

                            <div class="d-flex flex-row justify-content-center">

                                <div class="d-flex flex-column w-50 p-2">
                                    <!-- _user_first_name -->
                                    <div class="form-group">
                                        <label for="first_name">
                                            <span >{{'_user_first_name' | translate}}</span>
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input formControlName="first_name" placeholder=""
                                               class="form-control form-control-sm" id="first_name"
                                               [ngClass]="{ 'is-invalid': _efc.first_name.errors }">
                                        <!-- Validering -->
                                        <div
                                            *ngIf="_efc.first_name.invalid && (_efc.first_name.dirty || _efc.first_name.touched)"
                                            class="alert alert-danger mt-1 small">
                                            <div *ngIf="_efc.first_name.errors.required" class="d-flex align-items-center">
                                                <i class="fa-regular fa-circle-exclamation me-1"></i>
                                                <span >{{'_ui_is_required' | translate}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- _user_last_name -->
                                    <div class="form-group">
                                        <label for="last_name">
                                            <span >{{'_user_last_name' | translate}}</span>
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input formControlName="last_name" placeholder=""
                                               class="form-control form-control-sm" id="last_name"
                                               [ngClass]="{ 'is-invalid': _efc.last_name.errors }">
                                        <!-- Validering -->
                                        <div
                                            *ngIf="_efc.first_name.invalid && (_efc.last_name.dirty || _efc.last_name.touched)"
                                            class="alert alert-danger mt-1 small">
                                            <div *ngIf="_efc.last_name.errors.required" class="d-flex align-items-center">
                                                <i class="fa-regular fa-circle-exclamation me-1"></i>
                                                <span >{{'_ui_is_required' | translate}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- https://podio.com/klartboard/softwareudvikling/apps/supports/items/256 -->
                                    <!-- filtered displays by department-id -->

                                    <div class="form-row d-flex flex-row">
<!--                                        <span class="text-small">{{selectedDepartment?.name}}</span>-->
                                        <div class="row col-md-6 m-0" >
                                            <div class="form-group w-100 p-0" *ngIf="userDepartments">
                                                <label for="default-display-id">
                                                    <span >{{'_project_department' | translate}}</span> (<span >{{'_ui_settings_defaul_display' | translate}}</span>)
                                                </label>
                                                <select
                                                    class="form-control form-control-sm"
                                                    #mySelect
                                                    (change)="updateAvailableDisplays($any(mySelect).value)"
                                                    id="department-id">
                                                    <ng-container *ngFor="let department of userDepartments">
                                                        <option [ngValue]="department"
                                                                [selected]="department.id == selectedDepartment?.id"
                                                                [value]="department.id"
                                                        >
                                                            {{department.name}}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row col-md-6  m-0" *ngIf="selectedDepartment">
                                            <div class="form-group w-100 p-0 ps-1" *ngIf="availableDisplays">
                                                <label for="default-display-id">
                                                    <span >{{'_ui_settings_defaul_display' | translate}}</span>
                                                </label>
                                                <select
                                                        class="form-control form-control-sm"
                                                        [ngModelOptions]="{standalone: true}"
                                                        [(ngModel)]="selectedDepartmentDisplay"
                                                        (ngModelChange)="displayChange($event)"

                                                        id="default-display-id">
                                                    <ng-container *ngFor="let display of availableDisplays">

                                                        <!-- <option [ngValue]="item.key" *ngFor="let item of Globals.LanguageIds | keyvalue"> -->
                                                        <ng-container

                                                            *ngFor="let departmentsDisplay of display.departments_displays">
                                                            <option *ngIf="departmentsDisplay.visible && ((selectedDepartment && selectedDepartment.id == departmentsDisplay?.department_id) || departmentsDisplay.id == item.default_displays_department_id)"
                                                                    value="{{departmentsDisplay.id}}"
                                                                    [ngValue]="departmentsDisplay"
                                                                    [disabled]="departmentsDisplay.id == item.default_displays_department_id"
                                                            >
                                                                {{display?.name}}, {{departmentsDisplay?.department?.name}}
                                                            </option>
                                                        </ng-container>
                                                    </ng-container>
                                                </select>
                                            </div>
                                        </div>



                                    </div>
                                    <span>
                                        <a class="ms-1 remove d-flex flex-row align-items-center cursor-pointer text-danger text-small" (click)="clearDisplaySelection()"
                                        >
                                            <i class="fal fa-trash-alt me-1"></i> <span>{{'_ui_reset' | translate}}</span>
                                        </a>
                                    </span>

                                    <div class="form-group">
                                        <label for="language_id">
                                            <span >{{'_ui_language' | translate}}</span>
                                        </label>
                                        <select formControlName="language_id"
                                                class="form-control form-control-sm"
                                                [(ngModel)]="selectedLanguage"
                                                id="language_id">

                                                <option [ngValue]="item.key" *ngFor="let item of Globals.LanguageIds | keyvalue">
                                                    {{item.value | translate}}
                                                </option>
                                        </select>
                                    </div>

                                </div>

                                <div class="d-flex flex-column w-50 p-2">
                                    <!-- Brugernavn -->
                                    <div class="form-group">
                                        <label for="last_name">
                                            <span >{{'_user_email' | translate}}</span>
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input formControlName="username" placeholder=""
                                               class="form-control form-control-sm"
                                               id="username"
                                               email
                                               [ngClass]="{ 'is-invalid': _efc.username.errors }">
                                        <!-- Validering -->
                                        <ng-container
                                            *ngFor="let validation of account_validation_messages.email">
                                            <div class="alert alert-danger mt-1 small"
                                                 *ngIf="editForm.get('username').hasError(validation.type) &&
                                              (editForm.get('username').dirty
                                              || editForm.get('username').touched)">
                                                {{validation.message}}
                                            </div>
                                        </ng-container>
                                    </div>


                                    <div formGroupName="matching_passwords" *ngIf="changePassword">
                                        <div class="form-group">
                                            <label for="password">
                                                <span >{{'_user_password' | translate}}</span>
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input class="form-control form-control-sm" type="password"
                                                   placeholder="" formControlName="password" id="password"
                                                   autocomplete="new-password"
                                                   required>
                                            <ng-container
                                                *ngFor="let validation of account_validation_messages.password">
                                                <div class="alert alert-danger mt-1 small"
                                                     *ngIf="editForm.get('matching_passwords').get('password').hasError(validation.type) &&
                                              (editForm.get('matching_passwords').get('password').dirty
                                              || editForm.get('matching_passwords').get('password').touched)">
                                                    {{validation.message}}
                                                </div>
                                            </ng-container>

                                        </div>
                                        <div class="form-group">
                                            <label for="confirm_password">
                                                <span >{{'_user_confirm_password' | translate}}</span>
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input class="form-control form-control-sm" type="password"
                                                   placeholder="" formControlName="confirm_password"
                                                   autocomplete="new-password"
                                                   id="confirm_password"
                                                   required>

                                            <ng-container
                                                *ngFor="let validation of account_validation_messages.confirm_password">
                                                <div class="alert alert-danger mt-1 small"
                                                     *ngIf="(editForm.get('matching_passwords').get('confirm_password').hasError(validation.type)
                                          || editForm.get('matching_passwords').hasError(validation.type))
                                          && (editForm.get('matching_passwords').get('confirm_password').dirty
                                          || editForm.get('matching_passwords').get('confirm_password').touched)">
                                                    {{validation.message}}
                                                </div>
                                            </ng-container>

                                        </div>
                                    </div>

                                    <button class="btn d-flex align-items-center btn-sm btn-outline-primary mt-1" type="button" (click)="clearStorage();"><span class="fa fa-cookie me-1"></span>{{'_ui_reset_cookies' | translate}}</button>
                                </div>

                            </div>

                            <!-- Support Category -->
                            <!--
                          <div class="form-group">
                            <label for="support-category-id">
                              <span translate>Kategori</span>
                              <span class="text-danger">*</span>
                            </label>
                            <select formControlName="support_category_id" class="form-control form-control-sm" id="support-category-id">
                              <option [value]="1">Spørgsmål</option>
                              <option [value]="2">Fejl i systemet</option>
                              <option [value]="3">Problem</option>
                              <option [value]="4">Opgave</option>
                            </select>
                          </div>

                          <div class="form-group">
                            <div class="custom-file">

                              <input type="file" class="custom-file-input" id="customFile" (change)="onFileChange($event)" #fileInput>
                              <label class="custom-file-label" for="customFile">Vælg fil...</label>
                            </div>

                          </div>

                            -->


                        </div>


                    </div>
                </div>

                <ul class="nav nav-pills nav-fill position-absolute bg-light border-top w-100 p-2" style="bottom: 0;">
                    <li class="nav-item -px-2">
                        <button type="button" class="btn btn-sm btn-secondary  panel-button"
                                (click)="closeForm()">
                            <span >{{'_ui_close' | translate}}</span>
                        </button>
                    </li>
                    <li class="nav-item -px-2">
                        <button type="submit" class="btn btn-sm  panel-button"
                                [disabled]="!editForm.valid || isLoading || editForm.pristine"
                                [class.btn-success]="!(!editForm.valid || isLoading || editForm.pristine)"
                                [class.btn-outline-dark]="!editForm.valid || isLoading || editForm.pristine"
                        >
                            <span >{{'_ui_save' | translate}}</span>
                        </button>
                    </li>

                </ul>

            </form>
            <app-ghost-container *ngIf="!editable || isLoading" (click)="close()"></app-ghost-container>
        </div>
    </div>
</div>
