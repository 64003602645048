import {NgModule} from "@angular/core";
import {ColumnDebuggerComponent} from "@app/shared/_elements/column-debugger/column-debugger.component";
import {NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        NgbPopoverModule,
        CommonModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ColumnDebuggerComponent,
    ],
    exports: [
        ColumnDebuggerComponent

    ],
    providers: [

    ],
})
export class ColumnDebuggerModule {}
