<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title" [innerHTML]="title"></h4>
        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <div class="modal-body">
        <form [formGroup]="formGroup" *ngIf="formGroup">
            <p>{{message}}</p>
            <div class="form-group">
                <label for="planned">{{'_ui_planned' | translate}}</label>
                <input id="planned" class="form-control" type="number" [formControl]="$any(formGroup.controls).planned"/>
            </div>
            <div class="form-group">
                <label for="reached">{{'_ui_reached' | translate}}</label>
                <input id="reached" class="form-control" type="number" [formControl]="$any(formGroup.controls).reached"/>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary ellipsis" (click)="decline()">{{ btnCancelText }}</button>
        <button type="button" class="btn btn-success ellipsis" ngbAutofocus (click)="accept()">{{ btnOkText }}</button>
    </div>
</div>
