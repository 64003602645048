import {NgModule} from "@angular/core";
import {StatusEditorComponent} from "@app/editor/quick-editor/editors/generic/status-editor/status-editor.component";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ColorLabelModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        StatusEditorComponent,
    ],
    exports: [
        StatusEditorComponent

    ],
    providers: [

    ],
})
export class StatusEditorModule {}
