import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {TodoPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/TodoPresetType";

export class TodoProjectPresetGenerator implements CreatePresetGenerator {

    private readonly projectId?: number;
    private readonly projectIdHandler?: () => number;

    constructor(projectIdOrHandler: number | (() => number)) {
        if (typeof projectIdOrHandler == "number") {
            this.projectId = projectIdOrHandler;
        } else {
            this.projectIdHandler = projectIdOrHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: TodoPresetTypes.Project,
            payload: {
                projectId: this.projectId ?? this.projectIdHandler(),
            }
        };
    }

}
