import {NgModule} from "@angular/core";
import {
    ProjectEditCollectionComponent
} from "@app/editor/quick-editor/collections/project-edit-collection/project-edit-collection.component";
import {EditorSwitcherModule} from "@app/editor/quick-editor/editors/editor-switcher/EditorSwitcher.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        EditorSwitcherModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectEditCollectionComponent,
    ],
    exports: [
        ProjectEditCollectionComponent

    ],
    providers: [

    ],
})
export class ProjectEditCollectionModule {}
