export class FilterItem {
    name: string;
    operand: string;
    value: any;

    constructor(name: string, operand: string, value: any) {
        this.name = name;
        this.operand = operand;
        this.value = value;
    }

    public toString() {
        if(this.operand) {
            return `${this.name}:${this.operand}${this.value}`;
        }else{
            return `${this.name}:${this.value}`;
        }
    }

}

export class OrderingItem {
    name: string;
    direction: string;

    constructor(name: string, direction: string) {
        this.name = name;
        this.direction = direction;
    }

    public toString() {
        return `${this.name}:${this.direction}`;
    }

}


export class FilterOperators {
    static Equals = '';
    static Not = '-';
    static GreaterThan = '>';
    static GreaterThanOrEqual = '>=';
    static LessThan = '<';
    static LessThanOrEqual = '<=';
    static Search = '~';
}

export class OrderDirection {
    static Ascending = 'asc';
    static Descending = 'desc';
}

export class ApiFilter {

    filterItems: FilterItem[] = [];
    orderItems: OrderingItem[] = [];

    public orderBy(name: string, direction: string) {
        this.orderItems.push(new OrderingItem(name, direction));
        return this;
    }

    public orderAsc(name: string) {
        this.orderItems.push(new OrderingItem(name, OrderDirection.Ascending));
        return this;
    }

    public orderDesc(name: string) {
        this.orderItems.push(new OrderingItem(name, OrderDirection.Descending));
        return this;
    }

    public whereEquals(name: string, value: any) {
        this.filterItems.push(new FilterItem(name, FilterOperators.Equals, value));
        return this;
    }

    public whereInArray(name: string, value: any[]) {
        this.filterItems.push(new FilterItem(name, null, `[${value.join(',')}]`));
        return this;
    }

    public whereNot(name: string, value: any) {
        this.filterItems.push(new FilterItem(name, FilterOperators.Not, value));
        return this;
    }

    public whereGreaterThan(name: string, value: any) {
        this.filterItems.push(new FilterItem(name, FilterOperators.GreaterThan, value));
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any) {
        this.filterItems.push(new FilterItem(name, FilterOperators.GreaterThanOrEqual, value));
        return this;
    }

    public whereLessThan(name: string, value: any) {
        this.filterItems.push(new FilterItem(name, FilterOperators.LessThan, value));
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any) {
        this.filterItems.push(new FilterItem(name, FilterOperators.LessThanOrEqual, value));
        return this;
    }

    public search(name: string, value: any) {
        this.filterItems.push(new FilterItem(name, FilterOperators.Search, `"${value}"`));
        return this;
    }


    public filtersString(): string {
        let strings: string[] = [];
        this.filterItems.forEach((i) => {
            strings.push(`${i.toString()}`);
        });
        return `${strings.join(',')}`;
    }

    public orderingString(): string {
        let strings: string[] = [];
        this.orderItems.forEach((i) => {
            strings.push(i.toString());
        });
        return `${strings.join(',')}`;
    }


}