import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ReferenceComponent} from "@app/pages/reference/reference.component";
import {extract} from "@app/core";
import {ReferenceModule} from "@app/pages/reference/reference.module";


const routes: Routes = [
    {
        path: 'reference',
        pathMatch: 'full',
        component: ReferenceComponent,
        data: {
            title: extract('Reference'),
            animation: 'Page'
        }
    }
];

@NgModule({
    imports: [
        ReferenceModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
    providers: []
})
export class ReferenceRoutingModule {
}
