import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {User} from '@app/core/models/User';
import {Display} from '@app/core/models/Display';
import {PeriodRange} from '@app/services/FilterGlobalService/PeriodRange';
import {NgbDate, NgbDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {Department} from '@app/core/models/Department';
import * as moment from 'moment';
import {unitOfTime} from 'moment';
import {CreateItemSourceConfiguration} from '@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration';
import {SnackbarService} from "@app/services/snackbar.service";
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {DisplayService} from '@app/services/display.service';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {PageSettings} from "@app/services/ShellService/PageSettings";
import {Deadline, Milestone, Project, Site} from "@app/core/models";
import {ProjectStatusTypes, StatusTypes, System, TaskStatusTypes, TaskUserTypes} from '@app/constants';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {IndexedDBItem} from '@app/services/cache.service';
import {CreateItemPreset} from "@app/shared/_ui/create-item-dropdown/CreateItemPreset";
import {CreateItemInterface} from "@app/shared/_ui/create-item-dropdown/CreateItemInterface";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {ProjectUserTypes} from "@app/core/models/Project";
import {ScreenshotHelper} from '@app/core/ScreenshotHelper/ScreenshotHelper';
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {
    ProjectUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUserPresetGenerator";
import {
    ProjectDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectDepartmentPresetGenerator";
import {
    ProjectUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUseStatusRulesPresetGenerator";
import {
    ProjectStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectStatusPresetGenerator";
import {
    MilestoneStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneStatusPresetGenerator";
import {
    MilestoneUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneUseStatusRulesPresetGenerator";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseDisplayComponent implements OnInit, OnDestroy, OnChanges {

    @ViewChild('datepicker', {static: false}) datepicker: NgbDatepicker;

    @Output() toggleSidenav = new EventEmitter<void>();

    public menuHidden = true;
    public displayUser: User;
    public displays: Display[];
    public selectedDepartment: Department;
    public createItemDropdownConfiguration: CreateItemSourceConfiguration;
    public createItemDropdownPreset = new CreateItemPreset();

    public datePickerVisible = false;
    public currentUser: User;

    public csvExport: boolean = false;
    public showPurgeHands: boolean = false;
    public showCategoryTypes = false;
    public showReactionTypes = false;
    public showWeekPicker = false;
    public showDatePicker = false;
    public isCapturingScreenshot = false;

    public limit: number = 5;
    public pageSettings: PageSettings;
    private departments: Department[];

    private urlParams: Params;
    private period: PeriodRange;
    public resetStartDate: Date;
    public selectedDate: NgbDate;
    startDate: NgbDate;
    public showCreateNew: boolean = true;
    public showColumnPicker: boolean = true;

    constructor(private snackbar: SnackbarService,
                private cd: ChangeDetectorRef,
                private displayService: DisplayService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private filterGlobalService: FilterGlobalService,
                private dbService: NgxIndexedDBService,
    ) {
        super();
        this.cdr = cd;
        this.initialized = false;

        this.resetStartDate = moment()
            .startOf('isoWeek' as unitOfTime.StartOf)
            .toDate();

        this.subscribe(this.usersService.currentUser$.subscribe(user => {
            this.displayUser = user;

            if (user && user.departments) {
                if (this.selectedDepartment == null) {
                    this.selectedDepartment = user.departments[0];
                }

                const userDepartmentIds = user.departments.map(department => department.id);
                if (userDepartmentIds) {
                    // https://podio.com/klartboard/softwareudvikling/apps/supports/items/217
                    this.createItemDropdownConfiguration = CreateItemSourceConfiguration.AllInDepartment(userDepartmentIds);
                    this.detectChanges();
                    // console.log('this.createItemDropdownConfiguration : ', this.createItemDropdownConfiguration)
                }
            }
            this.markChangeDetectionDirty();
        }));

        this.pageSettings = this.shellService.getPageSettings();
        this.subscribe(this.shellService.onPageSettingsChangeEvent.subscribe(s => {
            this.pageSettings = s;
            this.findSelectedDepartment();
        }));

        this.subscribe(this.shellService.onHeaderTitleChangeEvent.subscribe(data => {
            this.showCreateNew = data.showCreateNew ?? true;
            this.showColumnPicker = data.showColumnPicker ?? true;
        }));

        Site.Get(site => {
            this.showCategoryTypes = site?.has_category_types;
            this.showReactionTypes = site?.has_reaction_types;
        });

        Department.GetAll(departments => {
            this.departments = departments;
            this.findSelectedDepartment();
        });

        if (this.activatedRoute) {
            this.activatedRoute.queryParams
                .subscribe(params => {
                    this.urlParams = params;
                    let start, end;
                    if (params.periodStart) {
                        start = moment(params.periodStart);
                    }
                    if (params.periodEnd) {
                        end = moment(params.periodEnd);
                    }

                    if (start && end) {
                        // console.log('(activatedRoute) Period changed : ', start.isSame(moment(this.period?.start), 'date'));
                        if (this.period && start.isSame(moment(this.period?.start), 'date') && end.isSame(moment(this.period?.end), 'date')) {
                        } else {
                            this.period = new PeriodRange(start.toDate(), end.toDate());
                            this.filterGlobalService.setPeriod(this.period);
                        }
                    }
                });
        }

        this.setupCreateItemPreset();
    }

    private setupCreateItemPreset() {
        const thisClass = this;
        this.createItemDropdownPreset.createTaskInterface = new class implements CreateItemInterface<Task> {
            createPresets(options?: any): CreatePreset[] {
                return [
                    // Defaults
                    new TaskUserPresetGenerator(TaskUserTypes.Creator, thisClass.user.id),
                    new TaskDepartmentPresetGenerator(thisClass.pageSettings.departmentId),
                    new TaskUseStatusRulesPresetGenerator(true),
                    new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),

                    new TaskUserPresetGenerator(TaskUserTypes.Participant, thisClass.user.id, Deadline.Today()),
                ].map(generator => generator.generate());
            }
        };
        this.createItemDropdownPreset.createProjectInterface = new class implements CreateItemInterface<Project> {
            createPresets(options?: any): CreatePreset[] {
                return [
                    // Defaults
                    new ProjectUserPresetGenerator(ProjectUserTypes.Responsible, thisClass.user.id),
                    new ProjectDepartmentPresetGenerator(thisClass.pageSettings.departmentId),
                    new ProjectUseStatusRulesPresetGenerator(true),
                    new ProjectStatusPresetGenerator(ProjectStatusTypes.Normal, StatusTypes.GREEN),
                ].map(generator => generator.generate());
            }
        };
        this.createItemDropdownPreset.createMilestoneInterface = new class implements CreateItemInterface<Milestone> {
            createPresets(options?: any): CreatePreset[] {
                return [
                    // Defaults
                    new MilestoneStatusPresetGenerator(StatusTypes.GREEN),
                    new MilestoneUseStatusRulesPresetGenerator(true),
                ].map(generator => generator.generate());
            }
        };
    }

    updateRoute(period: PeriodRange) {
        // console.log('header.component.ts :: updateRoute : period : ', period, 'this.period: ', this.period)
        let start, end;
        if (this.urlParams.periodStart) {
            start = moment(this.urlParams.periodStart).toDate();
        }
        if (this.urlParams.periodEnd) {
            end = moment(this.urlParams.periodEnd).toDate();
        }

        if (start && end) {
            if (this.period && this.period.start == start && this.period.end == end) {
            } else {
                this.period = new PeriodRange(start, end);
            }
        }

        if (!this.period || period.start !== this.period.start) {

            this.router.navigate(
                [],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: {
                        periodStart: moment(period.start).toISOString(),
                        periodEnd: moment(period.end).toISOString()
                    },
                    queryParamsHandling: 'merge',
                });
            this.period = period;
        }
    }

    ngOnInit() {
        super.ngOnInit();

        this.subscribe(this.filterGlobalService.onSettingsPeriodChangeEvent.subscribe((p: PeriodRange) => {
            this.startDate = new NgbDate(p.start.getFullYear(), p.start.getMonth() + 1, p.start.getDate());

            this.updateRoute(p); // TO-DO Flyt til en service..
            if (this.datepicker) {
                this.datepicker.navigateTo(this.startDate);
            }
            this.detectChanges();
        }));

        this.subscribe(this.filterGlobalService.onVisibilityChangeEvent.subscribe(settings => {
            this.showDatePicker = settings.showDatePicker;
            this.showWeekPicker = settings.showWeekPicker;
            this.detectChanges();
        }));

        this.subscribe(this.usersService.currentUser$.subscribe((u) => {
            if (u) {
                this.currentUser = u;
                this.showPurgeHands = (this.currentUser?.administrator || this.currentUser?.uber) && this.selectedDepartment !== null;

                if (!this.initialized) {
                    this.initialized = true;
                    this.displayService.getDisplaysWithoutSettings(displays => {
                        this.displays = displays;
                        this.markChangeDetectionDirty();
                    });
                }

            } else {
                this.showPurgeHands = false;
            }
            this.detectChanges();
        }));

        this.limit = Math.floor((window.innerWidth * .265) / 60) - 1;



        let p: PeriodRange = this.filterGlobalService.getActiveSettings().period;
        if (p)
            this.startDate = new NgbDate(p.start.getFullYear(), p.start.getMonth() + 1, p.start.getDate());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['user']) {
            this.markChangeDetectionDirty();
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    private findSelectedDepartment() {
        if (this.pageSettings && this.pageSettings.departmentId && this.departments) {
            this.selectedDepartment = this.departments.find(d => d.id == this.pageSettings.departmentId);
            this.markChangeDetectionDirty();
        }
    }

    // <editor-fold desc="View Actions">

    public toggleHamburgerMenu($event: MouseEvent) {
        $event.stopImmediatePropagation();
        this.toggleSidenav.emit()
    }

    private changeDateClickTimeout: any = null;

    public changeDate(number: number) {
        let p: PeriodRange = this.filterGlobalService.getActiveSettings().period;
        p.changeDate(number);
        this.startDate = new NgbDate(p.start.getFullYear(), p.start.getMonth() + 1, p.start.getDate());
        clearTimeout(this.changeDateClickTimeout);
        this.changeDateClickTimeout = setTimeout(() => {
            this.filterGlobalService.setPeriod(p);
        }, 1000);
    }

    get start(): Date {
        let p: PeriodRange = this.filterGlobalService.getActiveSettings().period;
        return p && p.start ? p?.start : null;
    }

    get end(): Date {
        let p: PeriodRange = this.filterGlobalService.getActiveSettings().period;
        return p && p.end ? p?.end : null;
    }

    changeSelectedWeek(ngbDate: any) {
        this.selectedDate = null;
        this.datePickerVisible = false;
        const date: Date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
        let p: PeriodRange = this.filterGlobalService.getActiveSettings().period;
        p.setPeriodStartOfWeek(date);
        this.filterGlobalService.setPeriod(p);
    }

    resetDate() {
        this.datePickerVisible = false;
        let p: PeriodRange = this.filterGlobalService.getActiveSettings().period;
        p.setPeriodStartOfWeek(this.resetStartDate);
        this.filterGlobalService.setPeriod(p);
    }

    changeSelectedDate(ngbDate: NgbDate) {
        this.datePickerVisible = false;
        const date: Date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
        let p: PeriodRange = this.filterGlobalService.getActiveSettings().period;
        p.setPeriod(date, moment(date).endOf('day').toDate());
        this.filterGlobalService.setPeriod(p);
    }

    // </editor-fold>

    // <editor-fold desc="Key bindings">

    @HostListener('document:keydown', ['$event'])
    onKeyDown($event: KeyboardEvent) {
        var activeElement = document.activeElement;
        var inputs = ['input', 'select', 'button', 'textarea'];
        if (activeElement &&
            inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1 ||
            activeElement &&
            activeElement.classList.contains('ql-editor') ||
            $event.metaKey
        ) {

        } else {
            switch ($event.key) {
                case 'p':
                    break;
                case 'P':
                    // this.editorPanelService.open(new Project({id: 9094}), {selectedTab: Tabs.Who}); // Debug
                    break;
                case 't':
                    // this.createItem(this.Globals.ItemTypes.To-do);
                    break;
                case 'T':
                    // this.editorPanelService.open(new To-do({id: 20})); // Debug
                    break;
                case 'm':
                    // this.createItem(this.Globals.ItemTypes.Milestone);
                    break;
                case 'O':
                    // this.editorPanelService.open(new Task({id: 158571}), {selectedTab: Tabs.Who}); // Debug
                    break;
                case 'ArrowLeft':
                    this.changeDate(-7);
                    //this.editorPanelService.open(new Task({id: 1}), {selectedTab: Tabs.Basic}); // Debug
                    break;
                case 'ArrowRight':
                    this.changeDate(7);
                    //this.editorPanelService.open(new Task({id: 1}), {selectedTab: Tabs.Basic}); // Debug
                    break;
                default:
                    break;

            }
        }
    }

    // </editor-fold>

    logSettings() {
        console.log('pageSettings : ', this.pageSettings);
    }
    clearStorage(){
        this.dbService.clear(System.DBCache).subscribe((r)=>{
            document.location.reload();
            this.snackbar.add(this.translateService.instant('_ui_reset_cookies_complete'));
        })
    }

    clearLanguage(){
        this.dbService.getByIndex(System.DBCache, 'key', System.Translations)
            .subscribe((dbItem: IndexedDBItem) => {
                console.log('clearDBKey : item', dbItem);
                if(dbItem && dbItem.key) {
                    this.dbService.deleteByKey(System.DBCache, dbItem.key).subscribe((status) => {
                        this.detectChanges();
                    });

                    this.snackbar.add(this.translateService.instant('_ui_reset_language_complete'));
                }
            })
    }

    public onCaptureScreenshotBtnClicked() {
        this.shellService.setIsCapturingScreenshot(true);
        this.isCapturingScreenshot = true;
        this.detectChanges();
        ScreenshotHelper.Capture(document.getElementById('klartboard'), () => {
            this.isCapturingScreenshot = false;
            this.shellService.setIsCapturingScreenshot(false);
            this.detectChanges();
        }, {selector:document.getElementById('klartboard')});
    }

}
