import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Todo} from "@app/core/models";
import {Field} from "@app/editor/todo-editor-loader/todo-editor.service";
import {Fields} from "@app/editor/todo-editor-loader/Fields";
import {
    EditUserListConfiguration
} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserListConfiguration";
import {UserTypeItem} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserTypeItem";
import {
    EditDeadlineListConfiguration
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineListConfiguration";
import {DeadlineItem} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/DeadlineItem";
import {ReactionTypes} from "@app/constants";
import {
    EditProjectListConfiguration
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListConfiguration";
import {
    EditProjectListFilter
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListFilter";
import {EditTextConfiguration} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextConfiguration";
import {
    EditReactionListConfiguration
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionListConfiguration";
import {TodosService} from "@app/services/todos.service";
import {EditTextValidator} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextValidator";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {ValidatorCollection} from "@app/editor/Validation/ValidatorCollection";
import {
    EditTodoCategoryConfiguration
} from "@app/editor/quick-editor/editors/todo/todo-category-editor/EditTodoCategoryConfiguration";
import {
    EditCheckboxConfiguration
} from "@app/editor/quick-editor/editors/generic/checkbox-editor/EditCheckboxConfiguration";
import {EditUserTypeValidator} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserTypeValidator";
import {
    EditDeadlineTypeValidator
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineTypeValidator";
import {
    EditProjectListValidator
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListValidator";

@Component({
    selector: 'app-todo-editor-tabs-basic',
    templateUrl: './todo-editor-tabs-basic-component.html',
    styleUrls: ['./todo-editor-tabs-basic.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoEditorTabsBasicComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item: Todo;
    @Input() fields: Map<number, Field>;
    @Output() validatorChange = new EventEmitter<ValidationErrorInterface>();

    // Bindings to view
    public isReady = false;

    // Bindings to view: Category
    public showTodoCategoryEditor = false;
    public todoCategoryEditorConfiguration: EditTodoCategoryConfiguration;

    // Bindings to view: User
    public showUserEditor = false;
    public userEditorConfiguration: EditUserListConfiguration;

    // Bindings to view: Deadline
    public showDeadlineEditor = false;
    public deadlineEditorConfiguration: EditDeadlineListConfiguration;

    // Bindings to view: Project
    public showProjectListEditor = false;
    public projectListEditorConfiguration: EditProjectListConfiguration;

    // Bindings to view: Description
    public showDescriptionTextEditor = false;
    public descriptionTextEditorConfiguration: EditTextConfiguration;

    // Bindings to view: Emoji
    public showEmojiReactionEditor = false;
    public emojiReactionEditorConfiguration: EditReactionListConfiguration;

    // Bindings to view: Show On Display
    public showOnDisplayEditor = false;
    public showOnDisplayEditorConfiguration: EditCheckboxConfiguration;

    // Data
    private validator: ValidationErrorInterface;

    constructor(
        private cd: ChangeDetectorRef,
        private todosService: TodosService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.setupEditors();
        this.setupValidator();
        this.isReady = true;
    }

    private setupEditors() {
        this.showTodoCategoryEditor = this.isFieldVisible(Fields.Category);
        this.todoCategoryEditorConfiguration = new EditTodoCategoryConfiguration(
            this.fields.get(Fields.Category)?.smartName ?? ''
        );

        this.showUserEditor = this.isFieldVisible(Fields.User);
        this.userEditorConfiguration = new EditUserListConfiguration(
            '',
            [
                new UserTypeItem(
                    this.fields.get(Fields.User)?.smartName ?? '',
                    0,
                    false,
                    false,
                    false,
                    false,
                    false,
                    new EditUserTypeValidator(this.fields.get(Fields.User)?.required ?? false)
                )
            ]
        );

        this.showDeadlineEditor = this.isFieldVisible(Fields.Deadline);
        const editDeadlineItem = new DeadlineItem(
            this.fields.get(Fields.Deadline)?.smartName ?? '',
            0,
            true,
            new EditDeadlineTypeValidator(false)
        );
        this.deadlineEditorConfiguration = new EditDeadlineListConfiguration('', [editDeadlineItem]);

        this.showProjectListEditor = this.isFieldVisible(Fields.Project);
        this.projectListEditorConfiguration = new EditProjectListConfiguration(
            this.fields.get(Fields.Project)?.smartName ?? '',
            new EditProjectListFilter(),
            new EditProjectListValidator(false)
        );

        this.showDescriptionTextEditor = this.isFieldVisible(Fields.Description);
        this.descriptionTextEditorConfiguration = new EditTextConfiguration(
            'description',
            'multi',
            this.fields.get(Fields.Description).smartName,
            'rich',
            new EditTextValidator(false),
        );

        this.todosService.getReactionTypes(reactionsTypes => {
            this.showEmojiReactionEditor = reactionsTypes
                ?.find(reactionType => {
                    return reactionType.reaction_type_id == ReactionTypes.Emoji;
                })
                ?.visible;
        });
        this.emojiReactionEditorConfiguration = new EditReactionListConfiguration(
            false,
            false,
            true,
            true,
            false
        );

        this.showOnDisplayEditor = this.isFieldVisible(Fields.ShowOnDisplay);
        this.showOnDisplayEditorConfiguration = new EditCheckboxConfiguration(
            'show_on_display',
            this.fields.get(Fields.ShowOnDisplay).smartName,
        );
    }

    private setupValidator() {
        this.validator = new ValidatorCollection([
            this.userEditorConfiguration.types[0].validator,
        ]);
        this.validatorChange.emit(this.validator);
    }

    private isFieldVisible(fieldId: number): boolean {
        return this.fields.has(fieldId) && this.fields.get(fieldId).visible;
    }

    // <editor-fold desc="View actions">

    // </editor-fold>

    // <editor-fold desc="Dialogs">

    // </editor-fold>

}
