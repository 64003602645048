import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {ProjectPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/ProjectPresetType";

export class ProjectUserPresetGenerator implements CreatePresetGenerator {

    private readonly typeId?: number;
    private readonly typeIdHandler?: () => number;

    private readonly userId?: number;
    private readonly userIdHandler?: () => number;

    constructor(
        typeIdOrHandler: number | (() => number),
        userIdOrHandler: number | (() => number),
    ) {
        if (typeof typeIdOrHandler == "number") {
            this.typeId = typeIdOrHandler;
        } else {
            this.typeIdHandler = typeIdOrHandler;
        }
        if (typeof userIdOrHandler == "number") {
            this.userId = userIdOrHandler;
        } else {
            this.userIdHandler = userIdOrHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: ProjectPresetTypes.User,
            payload: {
                typeId: this.typeId ?? this.typeIdHandler(),
                userId: this.userId ?? this.userIdHandler(),
            }
        };
    }

}
