import {GenericCell} from "@app/pages/displays/display-project-details/subdisplay-tasks/Cells/GenericCell";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-tasks/Row";
import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-tasks/Columns/TaskEditColumn";
import {ListTypes, StatusTypes, TaskStatusTypes, TaskUserTypes} from "@app/constants";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";

export class TaskEditCell extends GenericCell {

    public listConfiguration = new TaskListConfiguration();
    public column: TaskEditColumn;

    constructor(row: Row, column: TaskEditColumn) {
        super(row, column);

        const taskTypeIdsFilteredByProjectType = row.project.project_type?.project_types_task_types
            ?.filter(projectTypesTaskType => projectTypesTaskType.visible)
            ?.map(projectTypesTaskType => projectTypesTaskType.task_type_id) ?? [];

        this.listConfiguration
            .setLimit(1000) // Show all!
            .setShowProjectMiniCard(false)
            .setAcceptanceStatus(null)
            .setProject(row.project)
            .setTaskTypeIds(taskTypeIdsFilteredByProjectType)
            .setUseGlobalFilter(true)
            .setShowMilestoneMiniCard(true)
            .setPrepareCreateItemSourceHook(createItemSourceHook => createItemSourceHook.showEditor = false)
            .setCardEditExpander_enabledEditors(column.enabledEditors)
            .setListType(ListTypes.EditList)
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                // Defaults
                new TaskProjectPresetGenerator(row.project.id),
                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                new TaskUseStatusRulesPresetGenerator(true),
                ...(row.project.departments?.map(department => new TaskDepartmentPresetGenerator(department.id)) ?? []),
                new TaskUserPresetGenerator(TaskUserTypes.Creator, AppInjector.getInjector().get(UsersService).user.id),
            ]);
    }

}
