import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/TaskEditColumn";
import {
    BaseEditorTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/EditorTableColumns/BaseEditorTableColumn";
import {TextColumnConfiguration} from "@app/editor/quick-editor/columns/generic/text-column/TextColumnConfiguration";
import {
    EditorWithColumnInterface
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/EditorWithColumnInterface";
import {TextColumnComponent} from "@app/editor/quick-editor/columns/generic/text-column/text-column.component";
import {EditActionConfiguration} from "@app/editor/quick-editor/editors/generic/action-editor/EditActionConfiguration";
import {ActionEditorComponent} from "@app/editor/quick-editor/editors/generic/action-editor/action-editor.component";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns";

export class TaskEdit_Actions_TableColumn extends BaseEditorTableColumn {

    public identifier = TableColumns.TaskEdit_Actions;
    public column: TaskEditColumn;
    public minWidth = 100;
    public maxWidth = 100;
    public columnConfiguration: TextColumnConfiguration;
    public editorWithColumn: EditorWithColumnInterface;

    constructor(column: TaskEditColumn) {
        super(column);
        this.name = '_displays_project_details_subdisplay_task_edit_minicard_actions';

        this.columnConfiguration = new TextColumnConfiguration(
            '_displays_project_details_subdisplay_task_edit_minicard_actions',
            false,
            false
        );
        this.columnConfiguration.minWidth = 100;
        this.columnConfiguration.maxWidth = 100;

        const editorConfiguration = new EditActionConfiguration(
            column.hasField(TaskEditColumn.Column_ActionPositioning),
            column.hasField(TaskEditColumn.Column_ActionArchive),
            column.hasField(TaskEditColumn.Column_ActionDelete)
        );
        editorConfiguration.widthSynchronizer = this.columnConfiguration.widthSynchronizer;

        this.editorWithColumn = {
            name: ActionEditorComponent.name,
            columnName: TextColumnComponent.name,
            columnConfiguration: this.columnConfiguration,
            configuration: editorConfiguration,
        };
    }

}
