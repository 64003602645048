import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StatusRule} from '@app/core/models';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {Api} from "@app/core/http/Api/Api";
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '@app/services/snackbar.service';

@Component({
    selector: 'app-template-status-rule-row',
    templateUrl: './template-status-rule-row.component.html',
    styleUrls: ['./template-status-rule-row.component.scss']
})
export class TemplateStatusRuleRowComponent extends BaseDisplayComponent implements OnInit {
    @Input() statusRule: StatusRule; // Udlæses i milestone-plan-editor.component.ts -> render()
    @Input() showRemove = true;
    @Output() removeEvent: EventEmitter<StatusRule> = new EventEmitter();


    public daysValue = 0;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if(this.statusRule){
            this.daysValue = this.statusRule.date_difference / 86400;
        }
    }

    showToast(){
        const translateService = AppInjector.getInjector().get(TranslateService);
        AppInjector.getInjector().get(SnackbarService).add(
            translateService.instant('_ui_item_saved', {item: translateService.instant('_milestone_plan')}),
        );
    }

    removeRule() {
        this.removeEvent.emit(this.statusRule);
        Api.statusRules().deleteById(this.statusRule.id).delete((r)=>{
            // deleted
            this.showToast();
        });
    }
    createRule() {
        this.removeEvent.emit(this.statusRule);
    }

    public keepOriginalOrder = (a:any, b:any) => a.key;

    saveStatusDateDifference() {
        console.log('daysValue : ', this.daysValue);
        this.statusRule.date_difference = this.daysValue * 86400;
        this.patch();

    }

    patch() {
        if(this.statusRule.id != 0) {
            Api.statusRules()
                .patchById(this.statusRule.id)
                .save(this.statusRule, ()=>{
                    this.showToast();
                });
        }
    }
}
