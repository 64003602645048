import {Observable, of} from 'rxjs';

import {Credentials, LoginContext} from '@app/core';

export class MockAuthenticationService {

    credentials: Credentials | null = {
        email: 'test',
        token: '123'
    };

    login(context: LoginContext): Observable<Credentials> {
        return of({
            email: context.email,
            token: '123456'
        } as Credentials);
    }

    logout(): Observable<boolean> {
        this.credentials = null;
        return of(true);
    }

    isAuthenticated(): boolean {
        return !!this.credentials;
    }

}
