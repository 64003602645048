import {NgModule} from "@angular/core";
import {WhoWhenEditComponent} from "@app/shared/_elements/who-when-edit/who-when-edit.component";
import {DatepickerModule} from "@app/shared/_forms/datepicker/Datepicker.module";
import {WhoWhenEditItemModule} from "@app/shared/_elements/who-when-edit/who-when-edit-item/WhoWhenEditItem.module";
import {UserSearchModule} from "@app/shared/_forms/search/user-search/UserSearch.module";
import {DepartmentUsersPickerModule} from "@app/shared/_forms/department-users-picker/DepartmentUsersPicker.module";
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        DatepickerModule,
        WhoWhenEditItemModule,
        UserSearchModule,
        DepartmentUsersPickerModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        WhoWhenEditComponent,
    ],
    exports: [
        WhoWhenEditComponent

    ],
    providers: [

    ],
})
export class WhoWhenEditModule {}
