import {Component, OnInit} from '@angular/core';
import {PANEL_ANIMATION} from "@app/editor/panel.animation";
import {fadeAnimation, slideDownAnimation} from "@app/animations";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {BaseModalComponent} from "@app/shared/_modals/base-modal.component";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {EditorPanelService} from "@app/services/editor-panel.service";
import {StatusRule} from "@app/core/models";

@Component({
  selector: 'app-create-status-rule-dialog',
  templateUrl: './create-status-rule-dialog.component.html',
  styleUrls: ['./create-status-rule-dialog.component.scss'],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation,
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ]
})
export class CreateStatusRuleDialogComponent extends BaseModalComponent implements OnInit {
    public statusRule: StatusRule = StatusRule.CreateDefault();

    constructor(private modal: NgbActiveModal,
                private editorPanelService: EditorPanelService,
    ) {
        super(modal);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    public accept() {
        this.activeModal.close(this.statusRule);
    }
}
