import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {TableColumns} from "@app/pages/displays/display-projects/TableColumns";
import {TodoListColumn} from "@app/pages/displays/display-projects/Columns/TodoListColumn";

export class TodoListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TodoList;

    public column: TodoListColumn;

}
