import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";

export class TaskListNextMilestoneCell extends BaseCell {

    public listConfiguration = new TaskListConfiguration();

    public constructor(row: BaseRow, column: BaseColumn) {
        super(row, column);

        this.listConfiguration
            .setOrderBy([
                ['projects_task.index_', 'asc'],
                ['main_status.status_id', 'desc']
            ])
            .setShowProjectMiniCard(false)
            // .setDepartment(this.department). https://podio.com/klartboard/softwareudvikling/apps/stories/items/223
            .setAcceptanceStatus(null)
            // .setLimit(2)
            .setCardItemDragRemoveValidator(cardItem => false)
            .setLimit(ListConfiguration.SmartLimit)
            .setDraggable(true)
            .setAllowDragEnter(false)
            .setShowCreateNew(true);
    }

}
