import {NgModule} from "@angular/core";
import {DisplayDebugComponent} from "@app/pages/displays/display-debug/display-debug.component";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";
import {ResizableModule} from "angular-resizable-element";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {ProjectListModule} from "@app/shared/_ui/lists/project-list/ProjectList.module";

@NgModule({
    imports: [
        CommonModule,
        CardsModule,
        ResizableModule,
        NgbTooltipModule,
        ProjectListModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DisplayDebugComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class DisplayDebugModule {}
