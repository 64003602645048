import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Department, DepartmentGroup, User} from '@app/core/models';
import {BaseFormControlComponent} from '@app/shared/_forms/base-form-control/base-form-control.component';
import {merge, Observable, OperatorFunction, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {NgbTypeahead, NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-department-search',
    templateUrl: './department-search.component.html',
    styleUrls: ['./department-search.component.scss']
})
export class DepartmentSearchComponent extends BaseFormControlComponent implements OnInit, OnChanges {

    @Input() removeOnBlur: boolean = false;
    @Input() toggle: boolean = false;
    @Input() multiple: boolean;
    @Input() autoReset: boolean = false;

    @Input() clearInputOnBlur: boolean = true;
    @Input() selectedDepartments: Department[] = [];
    @Input() _internalValue: Department[] = [];
    @Output() onChangeEvent: EventEmitter<Department[]> = new EventEmitter();
    @Input() onChangedEvent: EventEmitter<Department[]>;
    @Output() itemSelected = new EventEmitter<any>();
    @Output() itemRemoved = new EventEmitter<any>();
    @Output() valueChanged = new EventEmitter<any>();

    public departmentGroups: DepartmentGroup[];
    public visibleDepartments: Department[];
    public selectedDepartmentGroup: DepartmentGroup;

    result: Department[];

    @ViewChild('instance', {static: true}) instance: NgbTypeahead;
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    searching = false;
    searchFailed = false;


    public departments: Department[];
    private selectFirstDepartment: boolean = false;

    constructor() {
        super();
        Department.GetAll(departments => {
            this.departments = departments;
            this.filterVisbibleDepartments();
        });
        DepartmentGroup.GetAll(departmentGroups => this.departmentGroups = departmentGroups);
    }

    search: OperatorFunction<string, readonly Department[]> = (text$: Observable<string>) =>
    {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
        const inputFocus$ = this.focus$;
        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            debounceTime(200),
            tap(() => this.searching = true),
            distinctUntilChanged(),
            map(term => this.departments.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)),
            tap(() => this.searching = true),


        )
    }


    public userExists(userId: number): boolean {
        return this.selectedDepartments && this.Helpers.itemExists(this.selectedDepartments, userId);
    }

    triggerSelection($event: NgbTypeaheadSelectItemEvent, input: any) {
        console.log('triggerSelection() : ', $event, input, 'this.toggle : ', this.toggle);

        $event.preventDefault();
        input.value = '';
        this.internalValues = null;

        this.itemSelected.emit($event.item);
        this.valueChanged.emit($event.item);

        // TO-DO: Trigger toggle / renmove
    }


    onBlurEvent(value: any) {
        if (this.removeOnBlur && !value) {
            console.log('blur : ', value, this.removeOnBlur);
            this.itemSelected.emit(value);
        }
        this.updateInputField();
    }

    formatter = (x: Department) => {
        return x && x.name ? `${x?.name}` : '';
    };


    updateInputField() {
        if (!this.clearInputOnBlur) {
            if (!this.multiple) {
                const user = this.selectedDepartments && this.selectedDepartments.length > 0 ? this.selectedDepartments[0] : null;
                if (user) {
                    if (!this.selectedDepartments || this.selectedDepartments.length == 0) {
                        this.internalValues = null;
                    } else {
                        if (this.selectedDepartments && this.selectedDepartments.length > 0) {
                            this.internalValues = user || null;
                        }
                    }
                } else {
                    this.internalValue = null;
                }
            } else {
                // TO-DO: Implement list of user names
            }
        } else {
            // this.internalValue = null;
        }
    }

    private filterVisbibleDepartments() {
        if(!this.selectedDepartmentGroup)
            this.visibleDepartments = this.departments;
        else {
            this.visibleDepartments = this.departments.filter(d => {
                return d.department_group_id == this.selectedDepartmentGroup.id;
            });

            // Update selected departments from group
            // this._internalValue = this.selectedDepartments.filter(d => d.department_group_id == this.selectedDepartmentGroup.id);

            this.onChange(this._internalValue); // Value is good!
            this.propagateChange(this.internalValue);
            this.onChangeEvent.emit(this._internalValue);
            this.selectedDepartments = this._internalValue;
        }

        // console.log('filterVisbibleDepartments : ', this.visibleDepartments, this.selectedDepartments, this.selectedDepartmentGroup);
    }

    ngOnInit(): void {
    }

    clickCheck() {
        this._internalValue = null;
    }

    resultTemplateType: {
        result: Department,
        term: string,
    }

}
