import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {
    AppointmentListColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/AppointmentListColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns";

export class AppointmentListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.AppointmentList;

    public column: AppointmentListColumn;

}
