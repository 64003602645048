import { BaseColumn } from "@app/core/ColumnControl/BaseColumn";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {ColumnTypes} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/ColumnTypes";
import {
    TaskCanCopyListColumn
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/Columns/TaskCanCopyListColumn";

export class TaskCanCopyListColumnType extends BaseColumnType {

    public identifier = ColumnTypes.TaskCanCopyList;

    public isAlwaysVisible = false;
    public customWidth?: number;
    public minWidth: number;
    public maxWidth?: number;
    public resizeable: boolean;
    public frozenLeft: boolean;
    public canAutoResize: boolean;
    public isList: boolean;
    public cellClass?: string;

    public createColumn(column: Column, settings: Map<string, ColumnSetting>): BaseColumn {
        return new TaskCanCopyListColumn(this, column, settings);
    }

}
