/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TaskEstimateTypeDefinition,} from './definitions/TaskEstimateTypeDefinition';

export class TaskEstimateType extends TaskEstimateTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
