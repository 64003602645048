<form class="form-inline ">

    <div class="d-flex cursor-pointer align-items-center" (click)="toggle()">
        <i class="fal fa-calendar me-1" aria-hidden="true" *ngIf="showIcon"></i>
        <ng-container *ngIf="showDateText">
        <span>{{convertToDate(fromDate) | localizedDate}}</span>
        <span *ngIf="toDate" clasS="ms-1">- {{convertToDate(toDate) | localizedDate}}</span>
        </ng-container>
    </div>

    <div class="form-group hidden">
        <div class="input-group">
            <input name="datepicker"
                   class="form-control"
                   ngbDatepicker
                   [weekdays]="true"
                   [showWeekNumbers]="true"
                   container="body"
                   [placeholder]="('_ui_date_select_period' | translate)"
                   #datepicker="ngbDatepicker"
                   [class.focus]="datepicker.isOpen()"
                   [autoClose]="'true'"
                   (dateSelect)="onDateSelection($event)"
                   [displayMonths]="2"
                   [dayTemplate]="t"
                   outsideDays="hidden"
                   [startDate]="fromDate!">

            <ng-template #t let-date let-focused="focused"
                         [typedTemplate]="dayTemplateType"
            >
                <span class="custom-day"
                      [class.focused]="focused"
                      [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                  {{ date.day }}
                </span>
            </ng-template>

        </div>
    </div>
</form>


