<quill-editor
    *ngIf="!dialog"
    [placeholder]="''"
    [modules]="quillEditorConfig"
    (blur)="triggerBlur();"
    (focus)="triggerFocus();"
    [ngModel]="value"
    (onContentChanged)="contentChanged($event)"
></quill-editor>

<div (click)="openDialog()" *ngIf="dialog && (value && value != '')"
     [innerHTML]="value"
     class="cursor-pointer d-flex flex-fill flex-grow-1 flex-column flex-wrap justify-content-start align-items-center wrapper rounded input-padding w-100 -h-100 ellipsis"></div>

<div class="w-100 position-relative" *ngIf="!value || value == ''">
    <a class="cursor-pointer corner-item" (click)="openDialog()"><i class="fal fa-edit"></i></a>
</div>
