import {Injectable} from '@angular/core';
// @ts-ignore
import * as autobahn from 'autobahn-browser';
import {Connection, ISubscription, Session} from 'autobahn';
import {environment} from '@env/environment';
import {ApiPrefixInterceptor, AuthenticationService} from '@app/core';
import {Subscription} from 'rxjs';
import {Site} from "@app/core/models";

@Injectable({
    providedIn: 'root'
})
export class WampService {

    constructor(private authenticationService: AuthenticationService) {
        this.authenticationService.isSignedIn().subscribe(authenticated => {
            if (authenticated) {
                if (!this.initialized) {
                    this.initialized = true;
                    this.initialize();
                }
            } else {
                console.warn('WampService : Missing socket server in environment!');
                this.close();
            }
        });
    }

    private connection: Connection;
    private subscriptions: WampSubscription[] = [];
    private session: Session;
    private initialized = false;

    private initialize(): void {
        this.close();

        if (!environment.socketUrl) {
            console.warn('WampService : Missing socket server in environment!');
            return;
        }
        this.connection = new autobahn.Connection({
            url: environment.socketUrl,
            realm: 'realm1',
            onchallenge: (session: Session, method: string, extra: any): string => {
                // console.warn('WampService onchallenge');
                return this.authenticationService.getAuthorizationToken();
            },
            authmethods: ['boris'],
            retry_if_unreachable: true,
            auto_reconnect: true
        });

        this.connection.onopen = (session, details) => {
            // console.log('WampService connection open : ', session);
            this.session = session;
            this.subscribeToSession();
        };

        this.connection.onclose = (reason, details) => {
            // console.warn('WampService connection close : ', reason, details);
            return false;
        };

        this.connection.open();
    }

    private close() {
        if (this.connection) {
            this.connection.close();
        }
    }

    private subscribeToSession() {
        this.subscriptions.forEach(subscription => {
            subscription.subscribe(this.session);
        });
    }

    public subscribe(event: string, handler: (args: any) => void): WampSubscription {
        // console.log('WampService subscribe to', event);
        const subscription = new WampSubscription(this, event, handler);
        this.subscriptions.push(subscription);
        subscription.subscribe(this.session);
        return subscription;
    }

    public unsubscribe(subscription: WampSubscription) {
        const index = this.subscriptions.indexOf(subscription);
        if (index !== -1) {
            this.subscriptions.splice(index, 1);
        }
    }
}

export class WampSubscription extends Subscription {
    private readonly wampService: WampService;
    private readonly event: string;
    private readonly handler: (data: any) => void;
    private subscription: ISubscription;
    private isSubscribed = false;

    constructor(wampService: WampService, event: string, handler: (data: any) => void) {
        super();
        this.wampService = wampService;
        this.event = event;
        this.handler = handler;
    }

    public subscribe(session: Session) {
        this.isSubscribed = true;
        try {
            session?.subscribe(this.event, (payload: any) => {
                const data: { event: string, data: any } = JSON.parse(payload);
                this.handler(data.data);
            }).then(subscription => {
                this.subscription = subscription;
                if (!this.isSubscribed) { // We have been unsubscribed while we were subscribing
                    this.unsubscribe();
                }
            }, error => console.warn(error));
        } catch (e: any) {
            console.warn('wamp-service failed to subscribe', e);
        }
    }

    public unsubscribe() {
        this.isSubscribed = false;
        this.subscription?.unsubscribe().then(null);
        this.wampService?.unsubscribe(this);
    }
}

export class ChangeEvent<T> {
    public previous: T;
    public next: T;

    constructor(previous: T, next: T) {
        this.previous = previous;
        this.next = next;
    }
}
