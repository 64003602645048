<div class="d-flex h-100">
    <ng-template #popContent>
        <ul
            class="list-unstyled w-200px p-0 m-0 flex-grow-1 d-flex flex-column">
            <li class="p-0">
                <div class="input-group">
                    <input type="search" class="form-control form-control-sm"
                           [placeholder]="placeholder ? placeholder : ('_ui_search' | translate) + '...' "
                           [(ngModel)]="searchValue"
                           (search)="search()"
                           [autofocusAfterInit]="true"
                           (ngModelChange)="changed($event)"
                    />
                        <button class="btn btn-sm btn-outline-success"
                                role="button"
                                type="button"
                                (click)="search()"
                                [disabled]="isLoading"
                        >
                            <i class="fa fa-search" aria-hidden="true" *ngIf="!isLoading"></i>
                            <span *ngIf="isLoading" class="text-small">
                                <app-loading-indicator [showLogo]="false"></app-loading-indicator>
                            </span>
                        </button>
                        <button class="btn btn-sm btn-outline-danger" role="button" type="button" *ngIf="listMode">
                            <i class="fa fa-times" aria-hidden="true" (click)="reset();"></i>
                        </button>
                </div>
            </li>
            <li *ngIf="result && result.length > 0"><span
                class="text-black-50 text-small text-center w-100 p-1 d-block mt-2" >{{'_ui_result_sorted_by_deadline' | translate}}</span>
            </li>
            <li *ngIf="!isLoading && result && result.length > 0" class="flex-shrink-1 flex-grow-1" style="min-height: 0;">
                <cdk-virtual-scroll-viewport [itemSize]="result.length" class="h-100" appendOnly>
                <ul class="list-unstyled mt-2 result-list h-100">
                    <li *cdkVirtualFor="let cardItem of result" class="mb-1">
                        <app-card-switcher [cardItem]="cardItem"></app-card-switcher>
                    </li>
                </ul>
                </cdk-virtual-scroll-viewport>
            </li>
            <li *ngIf="isLoading && false" class="d-flex align-items-center justify-content-center">
                <app-loading-indicator [showLogo]="false"></app-loading-indicator>
            </li>
        </ul>

    </ng-template>

    <ng-container *ngIf="listMode">
        <ng-content *ngTemplateOutlet="popContent"></ng-content>
    </ng-container>
</div>
