/**
 * Created by ModelParser
 * Date: 09-12-2020.
 * Time: 14:53.
 */
import {CategoriesDepartmentDefinition} from './definitions/CategoriesDepartmentDefinition';

export class CategoriesDepartment extends CategoriesDepartmentDefinition {

    constructor(json?: any) {
        super(json);
    }

}
