import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {
    CategoryPickerListEditorComponent
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/category-picker-list-editor.component";
import {
    CategoryPickerModule
} from "@app/shared/_forms/category-picker/CategoryPicker.module";
import {FormsModule} from "@angular/forms";
import {UserSearchModule} from "@app/shared/_forms/search/user-search/UserSearch.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        CategoryPickerModule,
        FormsModule,
        UserSearchModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CategoryPickerListEditorComponent,
    ],
    exports: [
        CategoryPickerListEditorComponent

    ],
    providers: [

    ],
})
export class CategoryPickerListEditorModule {}
