import {ShellFilterGroup} from "@app/services/ShellFilterService/ShellFilterGroup";
import {ShellColumnGroup} from "@app/services/ShellColumnService/ShellColumnGroup";
import {Display, DisplayFilter} from "@app/core/models";
import {AppInjector} from "@app/services/app-injector.service";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {ShellService} from "@app/services/ShellService/shell.service";
import {ShellColumnService} from "@app/services/ShellColumnService/shell-column.service";
import {ShellFilterService} from "@app/services/ShellFilterService/shell-filter.service";
import {ActivatedRoute, Router} from '@angular/router';

export class ShellPageData {
    display: Display;
    departmentId: number;
    shellFilterGroup: ShellFilterGroup;
    shellColumnGroup: ShellColumnGroup;
    activePageColumnSort: PageColumnSort;
    displayFilterExtra?: string; // Used by displays to identify specific display configuration (Ex. unique filter usages for projects in planning-details display)
    isMainDisplay: boolean;

    private uriDisplayFilterId: number = null;

    public constructor(display: Display, departmentId: number, filterDisplayGroup: ShellFilterGroup, columnGroup: ShellColumnGroup, isMainDisplay: boolean) {
        this.display = display;
        this.departmentId = departmentId;
        this.shellFilterGroup = filterDisplayGroup;
        this.shellColumnGroup = columnGroup;
        this.isMainDisplay = isMainDisplay;

        const activatedRoute: ActivatedRoute = AppInjector.getInjector().get(ActivatedRoute);
        activatedRoute.queryParams
            .subscribe(params => {
                if (params.displayFilterId) {
                    this.uriDisplayFilterId =  params.displayFilterId;
                }
            });

    }

    public findAndActivateDisplayFilter() {
        // 1) If default filter is set in URI, try to find that filter // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1608
        let lastSelectedFilter: DisplayFilter = this.shellFilterGroup.filters.find((f) => {
            return f.id == this.uriDisplayFilterId;
        });
        if(lastSelectedFilter){
            this.setActiveDisplayFilter(lastSelectedFilter);
            return;
        }

        // 2) Look for last used displayFilter (https://podio.com/klartboard/softwareudvikling/apps/delprojekter/items/8)
        let lastUsed: DisplayFilter;
        this.shellFilterGroup.filters.forEach(displayFilter => {
            if (displayFilter.getLastUsedDate()) {
                if (!lastUsed || lastUsed.getLastUsedDate().getTime() < displayFilter.getLastUsedDate().getTime()) {
                    lastUsed = displayFilter;
                }
            }
        });
        if (lastUsed && lastUsed.getLastUsedDate().getTime() > new Date().getTime() - 60 * 60 * 1000) {
            this.setActiveDisplayFilter(lastUsed);
            return;
        }

        // 3) Brug standardfiltrering (https://podio.com/klartboard/softwareudvikling/apps/delprojekter/items/8)
        const defaultFilter = this.shellFilterGroup.filters.find(displayFilter => displayFilter.my_settings?.is_default);
        if (defaultFilter) {
            this.setActiveDisplayFilter(defaultFilter);
            return;
        }

        // 4) Brug første favorit
        const firstFavoriteFilter = this.shellFilterGroup.filters.find(filter => filter.my_settings?.is_favorite);
        if (firstFavoriteFilter) {
            this.setActiveDisplayFilter(firstFavoriteFilter);
            return;
        }

        // 5) Brug første ikke-favorit
        const firstNonFavoriteFilter = this.shellFilterGroup.filters.find(filter => !filter.my_settings?.is_favorite);
        if (firstNonFavoriteFilter) {
            this.setActiveDisplayFilter(firstNonFavoriteFilter);
            return;
        }

        // 6) Brug statisk standard DisplayFilter
        let standards = this.shellFilterGroup.settings.standards(this.display);
        if (standards && standards.length) {
            this.setActiveDisplayFilter(standards[0]);
        } else {
            // Only set title
            this.setActiveDisplayFilter(null);
        }
    }

    public setActiveDisplayFilter(displayFilter: DisplayFilter) {
        const router = AppInjector.getInjector().get(Router);
        const activatedRoute = AppInjector.getInjector().get(ActivatedRoute);

        router.navigate(
            [],
            {
                relativeTo: activatedRoute,
                queryParams: {
                    displayFilterId: displayFilter.id,
                },
                skipLocationChange: true,
                replaceUrl: true,
                queryParamsHandling: 'merge',
            });
        displayFilter.display = this.display; // Ensure display isset
        this.shellFilterGroup.setActiveFilter(displayFilter);

        if (this.isMainDisplay) { // Only main displays can overwrite global settings
            AppInjector.getInjector().get(FilterGlobalService).applyDisplayFilter(displayFilter, this.shellFilterGroup.settings);
        }
        this.updateColumnVisibilityBasedOnActiveFilter();

        if (displayFilter?.exists()) {
            displayFilter.markLastUsed(this.departmentId, this.display.id, this.displayFilterExtra);
        }
    }

    public updateColumnVisibilityBasedOnActiveFilter() {
        if (this.shellFilterGroup?.activeFilter?.override_display_columns) {
            // Find checked setting ids by looking in related filter settings
            this.shellColumnGroup.applyDisplayFilterColumns(
                this.shellFilterGroup.activeFilter.display_filters_columns
                    ?.filter(displayFiltersColumn => displayFiltersColumn.is_visible) ?? []
            );
        } else {
            this.shellColumnGroup.markChanged();
        }
        this.shellColumnGroup.triggerUpdateEvent();
        AppInjector.getInjector()
            .get(ShellColumnService).onColumnGroupChangeEvent
            .emit(AppInjector.getInjector().get(ShellColumnService).getAllGroups());
    }

    public attach(isMainDisplay: boolean) {
        AppInjector.getInjector().get(ShellService).attachFilterPageData(this, isMainDisplay);
        if (this.shellColumnGroup) {
            AppInjector.getInjector().get(ShellColumnService).attachGroup(this.shellColumnGroup);
        }
        if (this.shellFilterGroup) {
            AppInjector.getInjector().get(ShellFilterService).attachGroup(this.shellFilterGroup);
        }
    }

    public detach(isMainDisplay: boolean) {
        if (this.shellColumnGroup) {
            AppInjector.getInjector().get(ShellColumnService)?.detachGroup(this.shellColumnGroup);
        }
        if (this.shellFilterGroup) {
            AppInjector.getInjector().get(ShellFilterService)?.detachGroup(this.shellFilterGroup);
        }
        AppInjector.getInjector().get(ShellService)?.detachFilterPageData(this, isMainDisplay);
    }

}
