import {BaseColumnConfiguration} from "@app/editor/quick-editor/columns/BaseColumnConfiguration";
import {Widths} from "@app/constants";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

export class SortableTextColumnConfiguration extends BaseColumnConfiguration {

    public value: string;
    public shellPageData: ShellPageData;
    public column: BaseTableColumn;
    public onColumnSortChange: (column: BaseTableColumn) => void;

    constructor(value: string, column: BaseTableColumn, shellPageData: ShellPageData,
                onColumnSortChange: (column: BaseTableColumn) => void,
                canResize: boolean, canAutoResize: boolean) {
        super(Widths.TextColumn, canResize, canAutoResize);
        this.value = value;
        this.column = column;
        this.shellPageData = shellPageData;
        this.onColumnSortChange = onColumnSortChange;
    }

}
