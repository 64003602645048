<div class="form-group flex-grow-1 align-items-center p-0 m-0">
    <label *ngIf="configuration?.label">
        <span>{{ configuration.label | translate }}</span>
        <span *ngIf="configuration.validator.required" class="text-danger">*</span>
    </label>
    <app-project-search
        [_internalValue]="items"
        [allowCreation]="false"
        [configuration]="configuration"
        (itemAdded)="onProjectAdded($event)"
        (itemRemoved)="onProjectRemoved($event)"
        [placeholder]="('_ui_search' | translate) + '...'"
    />
</div>
