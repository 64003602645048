import {NgModule} from "@angular/core";
import {SubDisplayWrapperComponent} from "@app/pages/displays/sub-display-wrapper/sub-display-wrapper.component";
import {ResizableModule} from "angular-resizable-element";
import {CommonModule} from "@angular/common";
import {DisplayCasesModule} from "@app/pages/displays/display-cases/DisplayCases.module";
import {DisplayLoadSummaryModule} from "@app/pages/displays/display-load-summary/DisplayLoadSummary.module";

@NgModule({
    imports: [
        ResizableModule,
        CommonModule,
        DisplayCasesModule,
        DisplayLoadSummaryModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        SubDisplayWrapperComponent,
    ],
    exports: [
        SubDisplayWrapperComponent

    ],
    providers: [

    ],
})
export class SubDisplayWrapperModule {}
