<div class="-container w-100 h-100 bg-light -bg-white bg-dark" style="max-width: unset;">

<!--    <app-settings-editor  *ngIf="false"></app-settings-editor>-->

<!--    <app-priority-matrix></app-priority-matrix>-->

    <div
        class="row">

        <div class="col col-6">
<!--            <button (click)="testProgressBar()">RELOAD</button>-->

<!--            <app-card-switcher-->
<!--                *ngFor="let card of cards"-->
<!--                [cardItem]="card"-->
<!--            ></app-card-switcher>-->
        </div>
        <div class="col col-6">

<!--            <app-project-list-->
<!--                [configuration]="projectListConfiguration"></app-project-list>-->

        </div>

    </div>


</div>

