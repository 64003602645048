<a class="nav-link no-caret pt-0 pb-0"
   [ngbTooltip]="(mode$ | async) != 'active' ? ('_ui_fullscreen' | translate) : ('_ui_minimize' | translate)"
   container="body"
   placement="auto"
   (click)="toggleFullScreen()"
>
  <i class="fal "
  [class.fa-expand]="(mode$ | async) != 'active'"
  [class.fa-compress]="(mode$ | async) == 'active'"
  ></i>
</a>
