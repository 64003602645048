import {BaseColumnConfiguration} from "@app/editor/quick-editor/columns/BaseColumnConfiguration";
import {
    EditorWithColumnInterface
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/EditorWithColumnInterface";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

export abstract class BaseEditorTableColumn extends BaseTableColumn {

    public abstract columnConfiguration: BaseColumnConfiguration;
    public abstract editorWithColumn: EditorWithColumnInterface;

}
