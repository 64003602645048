<!-- Liste -->
<div class="w-100 ellipsis scroll-x">
    <div class="d-flex flex-column justify-content-start"
         container="body">

        <ul class="list-unstyled cursor-pointer d-flex flex-row flex-wrap pt-0 w-100">
            <li class="w-100 ">
<!--                <pre class="text-light debug">{{selectedUsers | json}}</pre>-->

                <app-user-search
                    [className]="'form-control-sm w-100'"
                    [selectedUsers]="selectedUsers"
                    [placeholder]="' '"
                    [autoReset]="true"
                    (valueChanged)="createNewRosterWithUser($event)"
                    (click)="$event.stopPropagation()"></app-user-search>
            </li>
            <ng-container *ngFor="let item of items">
                <li class="mt-1 w-100">
                    <app-card-roster [item]="item" (onDelete)="deleteRoster(item)"></app-card-roster>
                </li>
            </ng-container>
        </ul>
    </div>

</div>
