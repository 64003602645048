import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ProjectListConfiguration} from '@app/shared/_ui/lists/project-list/ProjectListConfiguration';
import {PageComponent} from '@app/pages/page.component';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {ActivatedRoute} from '@angular/router';
import {ProjectStatusTypes, StatusTypes, TaskUserTypes} from '@app/constants';
import {Api} from '@app/core/http/Api/Api';
import { Project } from '@app/core/models';
import {AppInjector} from '@app/services/app-injector.service';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {
    ProjectStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectStatusPresetGenerator";
import {
    ProjectUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUseStatusRulesPresetGenerator";
import {
    ProjectDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectDepartmentPresetGenerator";
import {
    ProjectUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUserPresetGenerator";
import {ProjectUserTypes} from "@app/core/models/Project";
import {
    ProjectTitlePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectTitlePresetGenerator";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default, // TO-DO: Changedetection
})
export class ProjectsComponent extends PageComponent implements OnInit {
    protected filtersSettings?: FiltersInterface;
    protected columnController?: ColumnController;

    public configuration: ProjectListConfiguration;
    public searchValue: string;
    public reloadEmitter = new EventEmitter();

    txtQueryChanged: Subject<string> = new Subject<string>();
    @Input() placeholder: string = '';
    public projects: Project[];

    constructor(private activatedRoute: ActivatedRoute, private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;


        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe((f) => {
            this.search();
        }));

        this.txtQueryChanged
            .pipe(debounceTime(1000), distinctUntilChanged()) // wait 1 sec after the last event before emitting last event &&  only emit if value is different from previous value
            .subscribe((value: string) => {
                this.search();
            });
    }

    ngOnInit() {
        this.usersService.currentUser$.subscribe((activeUser)=> {
            if(activeUser) {
                const params = this.activatedRoute.snapshot.params;

                const displayModeHandlers = {
                    deleted: () => this.handleDeletedDisplayMode(),
                    personal: () => this.handlePersonalDisplayMode(activeUser),
                };

                const handler = displayModeHandlers[params?.displayMode];
                if (handler) {
                    handler();
                } else {
                    this.handleDefaultDisplayMode();
                }
            }
        });
    }

    private handleDeletedDisplayMode() {
        this.shellService.setHeaderTitle(this.translateService.instant('_ui_deleted_projects'), null, false, false, false);
        Api.projects().deletedGet()
            .limit(100)
            .find((projects) => {
                // console.log('projects : ', projects);
                this.projects = projects;
                this.detectChanges();
            });

    }

    private handlePersonalDisplayMode(activeUser: any) {
        this.shellService.setHeaderTitle(this.translateService.instant('_projects'), null, false, true, false);

        if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
            this.placeholder = 'Find...';
        }

        this.configuration = new ProjectListConfiguration();
        this.configuration.showChangeType = true;
        this.configuration.listClassName = 'table-mode';
        this.configuration
            .setOrderBy([['title', 'asc']])
            .setLimit(50)
            .setUseGlobalFilter(true);
        this.configuration
            .setUser(activeUser)
            .setArchived(false)
            .setUseGlobalFilter(true)
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                // Defaults
                new ProjectStatusPresetGenerator(ProjectStatusTypes.Normal, StatusTypes.GREEN),
                new ProjectUseStatusRulesPresetGenerator(true),

                new ProjectUserPresetGenerator(ProjectUserTypes.Responsible, activeUser.id),
                new ProjectTitlePresetGenerator(() => this.searchValue),
            ]);
    }

    private handleDefaultDisplayMode() {
        // Set default values
        this.configuration = new ProjectListConfiguration();
        this.configuration.showChangeType = true;
        this.configuration.listClassName = 'table-mode';
        this.configuration
            .setOrderBy([['title', 'asc']])
            .setLimit(50)
            .setUseGlobalFilter(true)
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                // Defaults
                new ProjectStatusPresetGenerator(ProjectStatusTypes.Normal, StatusTypes.GREEN),
                new ProjectUseStatusRulesPresetGenerator(true),

                new ProjectTitlePresetGenerator(() => this.searchValue ?? ''),
            ]);
    }

    public search() {
        if(this.configuration) {
            this.configuration.setSearch(this.searchValue);
        }
        this.reloadEmitter.emit();
    }

    public reset() {
        this.searchValue = null;
        this.search();
    }

    changed(query: string) {
        this.txtQueryChanged.next(query);
    }

    restore(item: Project) {

        this.dialogService.confirm(
            this.translateService.instant('_ui_restore'),
            this.translateService.instant('_ui_restore_description', {name: item.title}),
            this.translateService.instant('_ui_restore'),
        )
            .then((confirmed) => {
                if (confirmed) {
                    this.projects = this.projects.filter(p => p.id != item.id);
                    this.detectChanges();
                    Api.projects().restoreRestoreById(item.id)
                        .cascade(true)
                        .save(item, ()=>{
                            // console.log('item restored : ', item)

                            AppInjector.getInjector().get(EditorPanelService).open(item);
                        });
                }
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));



    }
}
