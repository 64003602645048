import {EventEmitter, Injectable,} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Project} from '@app/core/models/Project';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {DisplayFilter, Milestone, Task, Todo} from "@app/core/models";
import {Roster} from "@app/core/models/Task";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {DisplayFilterEditorFormConfigInterface} from "@app/editor/display-filter-editor/DisplayFilterEditorForm";
import {SettingsEditorForm} from "@app/editor/settings-editor/SettingsEditorForm";

export interface EditorOptions {
    selectedTab?: string;
    editorVersion?: string;
    reload?: boolean;
}

export interface EditorObj {
    item: AnyItem;
    options: any;
    component?: any;
}

export interface ShowDisplayFilterInterface {
    displayFilter: DisplayFilter;
    filterPageData: ShellPageData;
    config: DisplayFilterEditorFormConfigInterface;
}

@Injectable({
    providedIn: 'root'
})
export class EditorPanelService {

    public editorPanels: EditorObj[] = [];

    // https://angular.io/guide/component-interaction > Astronaut
    item$: Observable<AnyItem>;
    closeItem$: Observable<AnyItem>;

    editor$: Observable<EditorObj>;
    private editorSubject: Subject<EditorObj>;

    private itemSubject: Subject<AnyItem>;

    private closeSubject: Subject<any>;

    public onOpenDisplayFilterEditor = new EventEmitter<ShowDisplayFilterInterface>();

    constructor() {
        this.itemSubject = new Subject<AnyItem>();
        this.item$ = this.itemSubject.asObservable();

        this.closeSubject = new Subject<AnyItem>();
        this.closeItem$ = this.closeSubject.asObservable();

        this.editorSubject = new Subject<EditorObj>();
        this.editor$ = this.editorSubject.asObservable();
    }

    public openUri(item: AnyItem, options?: EditorOptions) {
        this.open(item, options);
    }

    public open(item: AnyItem, options?: EditorOptions) {
        const found = this.findItemIndex(item);
        if (found == -1) {
            this.itemSubject.next(item);
            this.editorSubject.next({
                item: item,
                options: options,
            });
        }
    }

    public addItem(editorObj: EditorObj) {
        if (editorObj.item) {
            if (this.findItemIndex(editorObj.item) == -1) {
                this.editorPanels.push(editorObj);
            }
        }
    }

    public closeItem(item: AnyItem | SettingsEditorForm) {
        const editorPanelIndex = this.findItemIndex(item);
        if (editorPanelIndex !== -1) {
            this.closeSubject.next(this.editorPanels[editorPanelIndex].item);
            this.editorPanels.splice(editorPanelIndex, 1);
        } else {
            if (this.editorPanels.length == 1) {
                this.editorPanels = [];
            }
        }
    }

    private findItemIndex(item: AnyItem | SettingsEditorForm): number {
        let index = -1;
        if (!item) {
            return index;
        }

        switch (item.constructor) {
            case Project:
                this.editorPanels.forEach((ep, i) => {
                    if (ep.item.constructor == Project && (ep.item.id == item.id || ep.item.id == 0)) {
                        index = i;
                    }
                })
                break;

            case Milestone:
                this.editorPanels.forEach((ep, i) => {
                    if ((ep.item.constructor == Milestone) && ep.item.id == item.id || ep.item.id == 0) {
                        index = i;
                    }
                })
                break;

            case Todo:
                this.editorPanels.forEach((ep, i) => {
                    if ((ep.item.constructor == Todo) && ep.item.id == item.id || ep.item.id == 0) {
                        index = i;
                    }
                })
                break;

            case Task:
            case Roster:
                this.editorPanels.forEach((ep, i) => {
                    if ((ep.item.constructor == Task || ep.item.constructor == Roster)
                        && ep.item.id == item.id || ep.item.id == 0) {
                        index = i;
                    }
                });
                break;
        }
        return index;
    }

    public openDisplayFilterEditor(filterPageData: ShellPageData, displayFilter: DisplayFilter) {
        filterPageData.shellFilterGroup.settings.getEditorConfig(filterPageData.display, config => {
            this.onOpenDisplayFilterEditor.emit({
                displayFilter: displayFilter,
                filterPageData: filterPageData,
                config: config,
            });
        });
    }

    public isOpen(item: AnyItem) {
        return this.findItemIndex(item) !== -1;
    }

}
