/**
 * Created by ModelParser
 */
import {Phase} from '../Phase';
import {Project} from '../Project';
import {PhaseProgress} from '../PhaseProgress';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class PhasesProjectDefinition extends BaseModel {
    phase_id?: number;
    phase?: Phase;
    project_id?: number;
    project?: Project;
    start?: string;
    current_phase_progress_id?: number;
    current_phase_progress?: PhaseProgress;
    phase_progress?: PhaseProgress[];
    is_current?: boolean;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.phase_id;
            delete this.phase;
            delete this.project_id;
            delete this.project;
            delete this.start;
            delete this.current_phase_progress_id;
            delete this.current_phase_progress;
            delete this.phase_progress;
            delete this.is_current;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.phase_id != null) {
            this.phase_id = data.phase_id;
        }
        if (data.phase != null) {
            this.phase = new Phase(data.phase);
        }
        if (data.project_id != null) {
            this.project_id = data.project_id;
        }
        if (data.project != null) {
            this.project = new Project(data.project);
        }
        if (data.start != null) {
            this.start = data.start;
        }
        if (data.current_phase_progress_id != null) {
            this.current_phase_progress_id = data.current_phase_progress_id;
        }
        if (data.current_phase_progress != null) {
            this.current_phase_progress = new PhaseProgress(data.current_phase_progress);
        }
        if (data.phase_progress != null) {
            this.phase_progress = data.phase_progress.map((i: any) => new PhaseProgress(i));
        }
        if (data.is_current != null) {
            this.is_current = data.is_current;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
