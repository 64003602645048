import {BaseComponent} from "@app/shared/_system/base/base.component";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {WhoWhenEditItem} from "@app/shared/_elements/who-when-edit/WhoWhenEditItem";
import {Deadline, Department, User} from "@app/core/models";
import {
    EditEnhancedUserList,
} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserList";
import {TaskUserTypes} from "@app/constants";
import {EnhancedUser} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserItem";

@Component({
    selector: 'app-who-when-edit',
    templateUrl: './who-when-edit.component.html',
    styleUrls: ['./who-when-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhoWhenEditComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() model: EditEnhancedUserList;
    @Output() onUpdated: EventEmitter<EditEnhancedUserList> = new EventEmitter();

    // Bindings to view
    public items: WhoWhenEditItem[];
    public selectedUsers: User[];

    // Data

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.render();
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    private render() {
        this.items = this.model.findEnhancedUsersByTypeId(TaskUserTypes.Participant)
            .map(item => new WhoWhenEditItem(item));
        this.selectedUsers = this.items.map(item => item.user);
        this.detectChanges();
    }

    public setDeadlineForAll(deadline: Deadline) {
        this.items.forEach(item => {
            this.model.setUserDeadline(item.user, deadline?.getDate(), deadline?.is_soft);
        });
        this.render();
    }

    public addAllFromDepartment($event: { users: User[]; department: Department }) {
        const users = $event.users
                ?.filter(user => this.selectedUsers.find(selectedUser => selectedUser.id == user.id) === undefined)
            ?? [];
        this.items.push(
            ...users.map(user => new WhoWhenEditItem(new EnhancedUser(user)))
        );
        this.model.addUsersFromDepartment(TaskUserTypes.Participant, $event.department, users);
        this.render();
    }

    public addItem(user: User) {
        this.model.addUser(TaskUserTypes.Participant, user);
        this.onUpdated.emit(this.model);
        this.render();
    }

    public removeItem(item: WhoWhenEditItem) {
        this.model.removeUser(TaskUserTypes.Participant, item.user);
        this.render();
    }

    public setDeadlineForItem(item: WhoWhenEditItem, deadline: Deadline) {
        item.deadline = deadline;
        this.model.setUserDeadline(item.user, deadline?.getDate(), deadline?.is_soft);
        this.render();
    }

    public setUserForItem(item: WhoWhenEditItem, user: User) {
        this.model.removeUser(TaskUserTypes.Participant, item.user);
        this.model.addUser(TaskUserTypes.Participant, user);
        this.render();
    }

    public setArchivedForItem(item: WhoWhenEditItem, archived: boolean) {
        item.isArchived = archived;
        this.model.setUserArchived(item.user, archived);
        this.render();
    }
}
