import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    TaskOrderListColumn
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/Columns/TaskOrderListColumn";
import {TableColumns} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/TableColumns";

export class TaskOrderListTableColumn extends BaseTableColumn {

    public identifier = TableColumns.TaskOrderList;

    public column: TaskOrderListColumn;

}
