/**
 * Created by ModelParser
 */
import {TasksUser} from '../TasksUser';
import {TaskStatusType} from '../TaskStatusType';
import {Display} from '../Display';
import {DisplaysTaskUserType} from '../DisplaysTaskUserType';
import {TaskTypesTaskUserType} from '../TaskTypesTaskUserType';
import {TaskType} from '../TaskType';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class TaskUserTypeDefinition extends BaseModel {
    name?: string;
    language_key?: string;
    tasks_users?: TasksUser[];
    task_status_types?: TaskStatusType[];
    displays?: Display[];
    displays_task_user_types?: DisplaysTaskUserType[];
    task_user_types_task_types?: TaskTypesTaskUserType[];
    task_types?: TaskType[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.language_key;
            delete this.tasks_users;
            delete this.task_status_types;
            delete this.displays;
            delete this.displays_task_user_types;
            delete this.task_user_types_task_types;
            delete this.task_types;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.language_key != null) {
            this.language_key = data.language_key;
        }
        if (data.tasks_users != null) {
            this.tasks_users = data.tasks_users.map((i: any) => new TasksUser(i));
        }
        if (data.task_status_types != null) {
            this.task_status_types = data.task_status_types.map((i: any) => new TaskStatusType(i));
        }
        if (data.displays != null) {
            this.displays = data.displays.map((i: any) => new Display(i));
        }
        if (data.displays_task_user_types != null) {
            this.displays_task_user_types = data.displays_task_user_types.map((i: any) => new DisplaysTaskUserType(i));
        }
        if (data.task_user_types_task_types != null) {
            this.task_user_types_task_types = data.task_user_types_task_types.map((i: any) => new TaskTypesTaskUserType(i));
        }
        if (data.task_types != null) {
            this.task_types = data.task_types.map((i: any) => new TaskType(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
