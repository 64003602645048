import {Project, ProjectUserTypes} from '@app/core/models/Project';
import {Subscription} from 'rxjs';
import {EventEmitter} from '@angular/core';
import Helpers from '../../../../core/helpers';
import {ProjectListConfiguration} from '@app/shared/_ui/lists/project-list/ProjectListConfiguration';
import {ProjectsDeadline} from '@app/core/models/ProjectsDeadline';
import {AppInjector} from "@app/services/app-injector.service";
import {Api} from "@app/core/http/Api/Api";
import {Category} from "@app/core/models";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";

export class ProjectFetchRequest {

    public configuration: ProjectListConfiguration;
    public onProjectsFetchedEvent = new EventEmitter<Project[]>();
    public onCountFetchedEvent = new EventEmitter<number>();

    // Data
    public result: Project[] = [];
    public count: number = 0;
    private lastEmittedItems?: Project[];
    private lastEmittedCount?: number;

    public constructor(configuration: ProjectListConfiguration) {
        this.configuration = configuration;
        this.configuration.setDataSource(this);

        this.configuration.onAddItemEvent.subscribe(value => {
            const item = value.item.item as Project;

            // Add to result if not already there
            if (this.result.find(localItem => localItem.id == item.id) === undefined) {
                this.result.push(item);
            }

            // Add to last emitted items at right position
            if (this.lastEmittedItems?.find(localItem => localItem.id == item.id) === undefined) {
                if (value.index !== undefined) {
                    this.lastEmittedItems?.splice(value.index, 0, item);
                } else {
                    this.lastEmittedItems?.push(item);
                }
                this.lastEmittedCount = this.lastEmittedItems?.length;
            }
        });

        this.configuration.onRemoveItemEvent.subscribe(value => {
            const item = value.item.item as Project;

            // Remove from result if not already there
            let index = this.result?.findIndex(localItem => localItem.id == item.id);
            if (index !== -1) {
                this.result.splice(index, 1);
            }

            // Remove from last emitted items at right position
            index = this.lastEmittedItems?.findIndex(localItem => localItem.id == item.id);
            if (index !== -1 && this.lastEmittedItems) {
                this.lastEmittedItems.splice(index, 1);
                this.lastEmittedCount = this.lastEmittedItems.length;
            }
        });
    }

    public reset() {
        this.result = [];
        this.count = 0;
    }

    public addItem(item: Project) {
        this.result.push(item);
        this.count++;
    }

    public getItemsById(id: number): Project[] {
        return this.result?.filter(item => item.id == id) ?? [];
    }

    public subscribeToItems(next: (items: Project[]) => void) {
        this.onProjectsFetchedEvent.subscribe(next);
        if (this.lastEmittedItems) {
            next(this.lastEmittedItems);
        }
    }

    public subscribeToCount(next: (count: number) => void) {
        this.onCountFetchedEvent.subscribe(next);
        if (this.lastEmittedCount) {
            next(this.lastEmittedCount);
        }
    }

    public cleanupAndEmitWhenReady(showAll: boolean): any {
        // Order by
        if (this.configuration.getOrderBy() && this.configuration.getOrderBy().length > 0) {
            const firstOrderBy = this.configuration.getOrderBy()[0];
            const orderBy = [...this.configuration.getOrderBy()];
            this.result = this.applyOrdering(this.result, firstOrderBy[0], firstOrderBy[1], orderBy.splice(1));
        }

        this.configuration.onScreenFilterChange.subscribe(() => this.emitSlice(showAll));

        if (this.configuration.hasSmartLimit()) {
            // Wait for SmartLimit. The limit can change over time (cause of screen resize). Keep listening for new limits and return slices.
            this.configuration.onLimitSetEvent.subscribe((limit: number) => this.emitSlice(showAll));
        } else {
            this.emitSlice(showAll);
        }
    }

    private applyOrdering(items: Project[], type: string, direction: string, nestedSorting: string[][]): Project[] {
        switch (type) {
            case 'main_status.status_id':
                items.sort((a, b) => {
                    let aValue = a.main_status != null ? a.main_status.status_id : 0;
                    let bValue = b.main_status != null ? b.main_status.status_id : 0;

                    if (aValue === bValue && nestedSorting.length > 0) {
                        const nestedSort = this.applyOrdering([a, b], nestedSorting[0][0], nestedSorting[0][1], nestedSorting.splice(1));
                        aValue = nestedSort.indexOf(a);
                        bValue = nestedSort.indexOf(b);
                        return aValue - bValue;
                    } else {
                        switch (direction) {
                            default:
                            case 'asc':
                                return aValue - bValue;
                            case 'desc':
                                return bValue - aValue;
                        }
                    }
                });
                break;

            case 'title':
                items.sort((a, b) => {
                    let aValue = a.title;
                    let bValue = b.title;
                    switch (direction) {
                        default:
                        case 'asc':
                            return aValue.localeCompare(bValue);
                        case 'desc':
                            return bValue.localeCompare(aValue);
                    }
                });
                break;

            case 'projects_deadline.deadline.date':
                // Map projectsDeadline to project
                const projectsDeadlineToProject = new Map<ProjectsDeadline, Project>();
                items.forEach(item => {
                    if (item.projects_deadlines) {
                        item.projects_deadlines.forEach(projectsDeadline => {
                            projectsDeadlineToProject.set(projectsDeadline, item);
                        });
                    }
                });

                // Sort deadlines
                const keysAsArray = Array.from(projectsDeadlineToProject.keys());
                const sortedProjectsDeadlines = keysAsArray.sort((aProjectsDeadline, bProjectsDeadline) => {
                    const aValue = aProjectsDeadline.deadline.getDate().getTime();
                    const bValue = bProjectsDeadline.deadline.getDate().getTime();
                    switch (direction) {
                        default:
                        case 'asc':
                            return aValue - bValue;
                        case 'desc':
                            return bValue - aValue;
                    }
                });

                // Use sorted keys to sort projects
                const sortedProjects = sortedProjectsDeadlines.map(projectsDeadline => projectsDeadlineToProject.get(projectsDeadline));

                // Remove duplicates (Ensure to keep the last copy. This is how mysql sorts)
                items = [];
                sortedProjects.forEach(project => {
                    const index = items.indexOf(project);
                    if (index !== -1) {
                        // Delete already added project
                        items.splice(index, 1);
                    }
                    items.push(project);
                });

                break;
        }
        return items;
    }

    private emitSlice(showAll: boolean) {
        // Apply on screen filters
        const filteredItems = this.result.filter(item => {
            return this.configuration.onScreenFilters.find(filter => !filter.isValid(item)) === undefined;
        });

        // Slice according to requested limit
        const sliceOfItems = this.getSlicedResult(filteredItems, showAll);

        // Emit to ListConfiguration
        this.emitItems(sliceOfItems);
        this.emitCount(filteredItems.length);
    }

    private getSlicedResult(items: Project[], showAll: boolean) {
        if (showAll) {
            return items;
        } else {
            const limit = Math.max(this.configuration.getLastItemCount() ?? 0, this.configuration.getLimit() ?? 0);
            if (limit > 0) {
                return items.slice(0, limit);
            } else {
                return items;
            }
        }
    }

    public clear() {
        this.configuration.setLastItemCount(0);
        this.result = [];
        this.count = 0;
    }

    // Called from List to request "ShowMore/ShowAll"
    public requestSlice(offset: number, limit: number) {
        // Apply on screen filters
        const filteredItems = this.result.filter(item => {
            return this.configuration.onScreenFilters.find(filter => !filter.isValid(item)) === undefined;
        });

        // Slice according to requested offset and limit
        const sliceOfItems = filteredItems.slice(offset, limit);

        // Emit to ListConfiguration
        this.emitItems(sliceOfItems);
        this.emitCount(filteredItems.length);
    }

    private emitItems(items: Project[]) {
        this.lastEmittedItems = items;
        this.onProjectsFetchedEvent.emit(items);
    }

    private emitCount(value: number) {
        this.lastEmittedCount = value;
        this.onCountFetchedEvent.emit(value);
    }

    public getLastEmittedItems(): Project[] {
        return this.lastEmittedItems;
    }

}

export class ProjectFetcher extends BaseFetcher {

    private requests: ProjectFetchRequest[] = [];
    private userIds: number[];
    private api: Subscription;
    public showAll = false;
    public onFinishEvent: EventEmitter<boolean> = new EventEmitter();

    // Global scope
    private start: Date;
    private end: Date;
    private activeCategories: Category[] = [];

    public constructor(name?: string) {
        super(name ?? '');
    }

    public clear(): void {
        this.requests = [];
    }

    public addRequest(request: ProjectFetchRequest) {
        this.requests.push(request);
        request.reset();

        if (request.configuration.getUser()) {
            const userId = request.configuration.getUser().id;
            if (!this.userIds) {
                this.userIds = [];
            }
            if (!this.userIds.includes(userId)) {
                this.userIds.push(userId);
            }
        }
    }

    public executeForPeriod(start: Date, end: Date) {
        // Set global scope
        this.start = start;
        this.end = end;

        // Execute!
        this.execute();
    }

    public execute() {
        if (this.requests.length == 0) {
            return;
        }

        let configuration = this.requests[0].configuration;

        let api = Api.projects().get();

        // Always required do to default configuration (projectType.default_project_estimate_type_id) shown in medium card
        api.include('project_estimate');

        if (configuration.getShowMiniCard_NextMilestone()) {
            api.include('next_milestone.main_status');
        }

        api
            .include('current_phases_project.phase.color')
            .include('current_phases_project.current_phase_progress.to_phase_progress_type');

        if (this.userIds) {
            api.whereIn('projects_user.user_id', this.userIds)
                .where('projects_user.project_user_type_id', ProjectUserTypes.Responsible);
        }

        if (configuration.getProjectType()) {
            api.where('project_type_id', configuration.getProjectType().id);
        }
        if (configuration.getProjectTypeIds()) {
            api.whereIn('project_type_id', configuration.getProjectTypeIds());
        }
        if (configuration.getOrArchivedSince()) {
            api.where('or_archived_since', Helpers.serverDate(configuration.getOrArchivedSince()));
        }
        if (configuration.getDepartment()) {
            api.where('department.id', configuration.getDepartment().id);
        }
        if (configuration.getArchived() != null) {
            if (configuration.getArchived() == true) {
                api.whereGreaterThan('archived_id', 0);
            } else {
                api.where('archived_id', 0);
            }
        }
        if (configuration.getProjectDeadlineTypeIds()?.length > 0) {
            api.whereIn('projects_deadline.project_deadline_type_id', configuration.getProjectDeadlineTypeIds());
        }
        if (this.start && this.end) {
            api.whereGreaterThanOrEqual('projects_deadline.deadline.date', Helpers.serverDate(this.start))
                .whereLessThanOrEqual('projects_deadline.deadline.date', Helpers.serverDate(this.end));
        }
        if (configuration.getOpen() !== null) {
            api.where('open', configuration.getOpen() ? 1 : 0);
        }
        if (configuration.getHasNonDeadline() !== undefined && configuration.getHasNonDeadline() !== null) {
            if (configuration.getHasNonDeadline()) {
                api.where('projects_deadline.deadline_id', null);
            } else {
                api.whereNot('projects_deadline.deadline_id', null);
            }
        }
        if (configuration.getArchivedBetween()) {
            api.include('archived')
                .whereGreaterThanOrEqual('archived.created', Helpers.serverDate(configuration.getArchivedBetween()[0]))
                .whereLessThanOrEqual('archived.created', Helpers.serverDate(configuration.getArchivedBetween()[1]));
        }

        const categoryIds: number[] = [];
        if (configuration.getUseGlobalFilter()) {
            let filter = AppInjector.getInjector().get(FilterGlobalService).getActiveSettings();


            if (configuration.getUseGlobalSearchFilter() && filter.search) {
                api.search('title', filter.search);

                // https://podio.com/klartboard/softwareudvikling/apps/supports/items/1029
                let smartSearch = [];
                smartSearch.push('responsible'); // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1006
                if (smartSearch.length) api.search('smart_search', smartSearch);

            }

            if (configuration.getUseGlobalSearchFilter() && filter.activeUsers?.length > 0) {
                api.search('users', filter.activeUsers.map(user => user.id));

                let smartSearch = [];
                smartSearch.push('responsible');
                if (smartSearch.length) api.search('users_smart_search', smartSearch);
            }

            if (filter.isHandUp) {
                api.whereGreaterThan('num_hand_ups', 0);
            }
            if (filter.isStarred) {
                api.whereGreaterThan('num_stars', 0);
            }
            if (filter.activeStatuses.length < 4 && filter.activeStatuses.length > 0) {
                api.whereIn('main_status.status_id', filter.activeStatuses);
            }
            if (filter.activeReactionFilters?.length > 0) {
                filter.activeReactionFilters.forEach(reactionFilter => {
                    api.whereIn('reaction_filters', [reactionFilter.reaction_type_id, reactionFilter.value]);
                });
            }

            if (filter.activeCategories?.length) {
                categoryIds.push(...filter.activeCategories?.map(category => category.id));
            } else if (this.activeCategories?.length) {
                categoryIds.push(...this.activeCategories?.map(category => category.id));
            }


        } else {
            // Uden globalt filter skal vi gøre brug af kolonnefilter
            if (this.activeCategories?.length) {
                categoryIds.push(...this.activeCategories?.map(category => category.id));
            }
        }

        if (categoryIds.length > 0) {
            api.whereIn('category.id', categoryIds);
        }

        if (configuration.getSearch() && configuration.getSearch().length > 0) {
            api.search('title', configuration.getSearch());
        }

        this.api = api.find(projects => {

            // Clear all requests for earlier data
            this.requests.forEach(request => request.clear());

            // Apply projects to fetch requests
            projects.forEach(project => {
                this.requests.forEach(request => {
                    if (request.configuration.validate(project)) {
                        request.addItem(project);
                    }
                });
            });

            // Emit each fetch request
            this.requests.forEach(request => {
                request.cleanupAndEmitWhenReady(this.showAll)
            });

            // Emit all done
            this.onFinishEvent.emit(true);
        });
    }


    public cancel() {
        if (this.api) {
            this.api.unsubscribe();
        }
    }

    public setCategories(categories: Category[]) {
        this.activeCategories = categories;
        return this;
    }

    public setPeriod(start: Date, end: Date) {
        this.start = start;
        this.end = end;
    }

    public setUserIds(value: number[]) {
        this.userIds = value;
    }

}
