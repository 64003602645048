<ng-template #rt let-r="result" let-t="term"
             [typedTemplate]="resultTemplateType"
>
  <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>

<ul class="list-group">
  <li class="list-group-item">
    <div class="form-group mb-0">
      <div class="search-wrap">
        <input type="search" class="form-control placeholder-sm"
               autocomplete="off"
               #instance="ngbTypeahead"
               container="body"
               [class.is-invalid]="searchFailed"
               [(ngModel)]="model"
               [ngbTypeahead]="search"
               popupClass="result-list"
               [resultTemplate]="rt"
               [inputFormatter]="formatter"
               (focus)="focus$.next($any($event.target).value)"
               (click)="click$.next($any($event.target).value)"
               (selectItem)="triggerSelection($event, instance)"
               [placeholder]="('_ui_search_placeholder' | translate: {type: ('' | translate)})"
        />
        <span class="searching" *ngIf="searching" [@fadeAnimation]="'in'">{{('_ui_searching' | translate)}}...</span>
        <span class="searching" *ngIf="result && result.length == 0" [@fadeAnimation]="'in'">{{('Ingen resultater' | translate)}}...</span>
      </div>
      <div class="invalid-feedback" *ngIf="searchFailed">{{('_ui_search_no_results' | translate)}}.</div>
    </div>

  </li>
  <li class="list-group-item" *ngFor="let c of internalValues" >
    <span>{{c.name}}</span>
    <span class="float-right text-danger" (click)="removeItem(c)"><i class="fal fa-trash-alt"></i></span>
  </li>
</ul>
