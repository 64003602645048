import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Api} from '@app/core/http/Api/Api';
import {Milestone} from "@app/core/models";

@Component({
    selector: 'app-milestone-delete-dialog',
    templateUrl: './milestone-delete-dialog.component.html',
    styleUrls: ['./milestone-delete-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneDeleteDialogComponent extends BaseModalComponent implements OnInit {

    // Bindings to parent
    @Input() primaryClass: string = 'btn-danger';
    @Input() item: Milestone;

    // Bindings to view
    public milestone: Milestone;
    public cascade: boolean = true;

    constructor(private modal: NgbActiveModal) {
        super(modal);
    }

    ngOnInit() {
        if (this.item) {
            Api.milestones()
                .getById(this.item.id)
                .include('task')
                .find(milestone => {
                    this.milestone = milestone[0];
                });
        }
    }

    public accept() {
        this.activeModal.close({cascade: this.cascade});
    }

}
