import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-card-preview',
    templateUrl: './card-preview.component.html',
    styleUrls: ['../card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardPreviewComponent implements OnInit {

    @Input() mini = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
