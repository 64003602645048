import {NgModule} from "@angular/core";
import {ColorLabelComponent} from "@app/shared";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ColorLabelComponent,
    ],
    exports: [
        ColorLabelComponent

    ],
    providers: [

    ],
})
export class ColorLabelModule {}
