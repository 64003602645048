import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {environment} from '@env/environment';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BaseService} from '@app/services/base.service';
import {ProjectDeadlineType} from '@app/core/models/ProjectDeadlineType';
import {Project} from '@app/core/models/Project';
import {ProjectUserType} from '@app/core/models/ProjectUserType';
import {ApiFilter, OrderDirection} from '@app/http/APIFilter';
import {ApiCall} from '@app/http/APICall';
import {ProjectType} from '@app/core/models/ProjectType';
import {OldApi as LegacyApi} from '@app/http/Api';
import {EventService} from '@app/services/event.service';
import {Api} from '@app/core/http/Api/Api';
import {CategoryType} from '@app/core/models/CategoryType';
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {System} from '@app/constants';
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";
import {ProjectStatusType} from "@app/core/models";

@Injectable({
    providedIn: 'root'
})
export class ProjectsService extends BaseService {

    constructor(private filterGlobalService: FilterGlobalService,
                private eventService: EventService) {
        super();
    }

    // <editor-fold desc="Project Type (Queue & Cache)">

    private projectTypeDataCaches: Map<number, ApiDataCache<ProjectType>> = new Map();

    // Entry point
    public getProjectType(id: number, callback: (projectType: ProjectType) => void): any {

        if(!id){
            console.warn('getProjectType() : missing id', id)
            return;
        }
        if (!this.projectTypeDataCaches.has(id)) {
            const dataCache = new ApiDataCache<ProjectType>(
                `${System.ProjectType}-${id}`,
                2 * 60 * 60, // 2 hours
                Api.projectTypes().getById(id)
                    .include('project_types_status_rule?include=' + encodeURIComponent('status_rule'))
                    .include('project_deadline_type')
                    .include('phase.color')
            );
            this.projectTypeDataCaches.set(id, dataCache);
        }
        this.projectTypeDataCaches.get(id).getFirst(callback);
    }

    // </editor-fold>

    // <editor-fold desc="Project Types Simple version (DataCache)">

    private projectTypesDataCache = new ApiDataCache<ProjectType>(
        System.ProjectTypes,
        2 * 60 * 60, // 2 hours
        Api.projectTypes().get()
            .setShowProgressBar(false)
            .include('department')
            .include('project_types_task_type')
            .include('project_status_types_project_type?include=project_status_type')
            .include('project_deadline_type')
            .include('departments_project_type')
            .include('project_field')
            .orderBy('index_', 'asc')
    );

    public getProjectTypes(callback: (items: ProjectType[]) => void) {
        this.projectTypesDataCache.get(callback);
    }

    public getProjectTypesByDepartmentId(departmentId: number, callback: (items: ProjectType[]) => void) {
        this.getProjectTypes(items => callback(items.filter(item => {
            return item.departments?.find(department => departmentId == department.id);
        })));
    }

    public getProjectTypesByDepartmentIds(departmentIds: number[], callback: (items: ProjectType[]) => void) {
        this.getProjectTypes(items => callback(items.filter(item => {
            return item.departments?.find(department => departmentIds.includes(department.id));
        })));
    }

    public getMemoryCachedProjectType(id: number): ProjectType {
        return this.projectTypesDataCache.getMemoryCache().find(projectType => projectType.id == id);
    }

    // </editor-fold>

    // <editor-fold desc="Project Deadline Type (Queue & Cache)">

    private projectDeadlineTypesDataCache = new ApiDataCache(
        System.ProjectDeadlineTypes,
        2 * 60 * 60, // 2 hours
        Api.projectDeadlineTypes().get()
    );

    public getProjectDeadlineTypes(callback: (items: ProjectDeadlineType[]) => void) {
        this.projectDeadlineTypesDataCache.get(callback);
    }

    public getProjectDeadlineType(id: number, callback: (item: ProjectDeadlineType) => void) {
        this.getProjectDeadlineTypes(items => callback(items.find(item => item.id == id)));
    }

    // </editor-fold>

    // <editor-fold desc="Project Status Type (Queue & Cache)">

    private projectStatusTypesDataCache = new ApiDataCache(
        System.ProjectStatusTypes,
        2 * 60 * 60, // 2 hours
        Api.projectStatusTypes().get()
    );

    public getProjectStatusTypes(callback: (items: ProjectStatusType[]) => void) {
        this.projectStatusTypesDataCache.get(callback);
    }

    public getProjectStatusType(id: number, callback: (item: ProjectStatusType) => void) {
        this.getProjectStatusTypes(items => callback(items.find(item => item.id == id)));
    }

    // </editor-fold>

    // <editor-fold desc="Project User Type (Queue & Cache)">

    private projectUserTypesDataCache = new ApiDataCache(
        System.ProjectUserTypes,
        2 * 60 * 60, // 2 hours
        Api.projectUserTypes().get()
    );

    public getProjectUserTypes(callback: (items: ProjectUserType[]) => void) {
        this.projectUserTypesDataCache.get(callback);
    }

    public getProjectUserType(id: number, callback: (item: ProjectUserType) => void) {
        this.getProjectUserTypes(items => callback(items.find(item => item.id == id)));
    }

    // </editor-fold>

    // TO-DO Convert to new Api
    public remove$(project: Project, cascade = false) {
        let uri = LegacyApi.projects(project.id).toString();
        if (cascade) {
            uri += '?cascade=1';

            // Fetch all milestones and tasks related to project and emit delete.
            // No need to actually delete these items. The server does it.
            Api.projects().getById(project.id)
                .include('task')
                .include('milestone')
                .find(projects => {
                    if (projects.length == 1) {
                        const project = projects[0];
                        if (project.tasks) {
                            project.tasks.forEach(task => this.eventService.emitTask(task, EventService.Deleted));
                        }
                        if (project.milestones) {
                            project.milestones.forEach(milestone => this.eventService.emitMilestone(milestone, EventService.Deleted));
                        }
                    }
                });
        }
        return this.httpClient
            .delete<Project>(`${environment.apiPath}${uri}`, {headers: this.authService.getAuthorizationHeader()})
            .pipe(
                map((response: any) => {
                    const _project: Project = new Project(response.resource);
                    this.eventService.emitProject(_project, EventService.Deleted);
                    return _project;
                }),
                catchError((err: HttpErrorResponse) => {
                    return throwError(err);
                })
            );
    }

    // TO-DO Convert to new Api
    public search(term: string, filters?: ApiFilter, includeMilestone: boolean = true): Observable<Project[]> {
        const uri = LegacyApi.projects().toString();

        let httpParams = new HttpParams();
        if (!filters) {
            filters = new ApiFilter();
        }

        if (term !== '') {
            filters.search('title', term);
            filters.search('smart_search', ['responsible']);
            filters.orderBy('created', OrderDirection.Descending);
            filters.orderBy('title', this.filterGlobalService.getActiveSettings().activeSortDirection);
            httpParams = httpParams.append('limit', '25');
        } else {
            filters.orderBy('title', this.filterGlobalService.getActiveSettings().activeSortDirection);
            httpParams = httpParams.append('limit', '25');
        }

        httpParams = httpParams.append('filter', filters.filtersString());
        httpParams = httpParams.append('ordering', filters.orderingString());
        // httpParams = httpParams.append('include', 'milestone,phase,phase.deadline');
        if(includeMilestone)
            httpParams = httpParams.append('include', 'milestone');

        const api: ApiCall = new ApiCall(uri, httpParams, (response) => {
            const items = response.resources.map((item: any) => {
                return new Project(item);
            });

            return items;
        });

        return api.getHttp();
    }

}
