<ng-container [ngSwitch]="cardItem.item.constructor.name">

    <app-generic-vertical-edit-collection
        *ngSwitchCase="'Task'"
        [cardItem]="cardItem"
        [enabledEditors]="enabledEditors"
        [listDragInterface]="listDragInterface"
        [listConfiguration]="listConfiguration"
        [showCreateNew]="showCreateNew"
    ></app-generic-vertical-edit-collection>
    <app-generic-vertical-edit-collection
        *ngSwitchCase="'TaskEditorForm'"
        [cardItem]="cardItem"
        [enabledEditors]="enabledEditors"
        [listDragInterface]="listDragInterface"
        [listConfiguration]="listConfiguration"
        [showCreateNew]="showCreateNew"
    ></app-generic-vertical-edit-collection>

    <!-- Add project, milestone.. -->

</ng-container>
