import {NgModule} from "@angular/core";
import {DashboardComponent} from "@app/pages/home/dashboard/dashboard.component";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {TranslateModule} from "@ngx-translate/core";
import {DisplayListModule} from "@app/shell/header/display-list/DisplayList.module";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        LoadingIndicatorModule,
        TranslateModule,
        DisplayListModule,
        RouterModule,
        NgOptimizedImage

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DashboardComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class DashboardModule {}
