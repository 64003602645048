<ng-container>
    <div
        [ngbPopover]="popContent"
        triggers="manual"
        #popover="ngbPopover"
        container="body"
        [placement]="['bottom', 'auto']"
        [autoClose]="false">

    <span class="fa-filter cursor-pointer position-relative"
          [class.fal]="!searchText || searchText == ''"
          [class.fas]="searchText && searchText != ''"
          [ngbTooltip]="'_ui_filter' | translate"
          (click)="popover.isOpen() ? popover.close() : popover.open()"
    ></span>
    </div>

    <ng-template #popContent>
        <div class="items-container flex-column d-flex">
            <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>


            <div class="d-flex flex-row align-items-center w-100 pb-1 " >

                <h4 class="p-0 m-0 popover-title flex-grow-1" [ngbTooltip]="('_ui_filter_search_description' | translate)">{{'_ui_search' | translate}}</h4>

                <div class="d-flex ms-auto reset-panel-buttons" >

                    <span (click)="popover.close();reset()"
                          [class.disabled]="!searchText"
                          class="cursor-pointer  btn btn-sm btn-danger">
                        <i class="fal fa-eraser" aria-hidden="true"></i>
                        {{'_ui_emoji_clear' | translate}}
                    </span>

                    <span (click)="popover.close();triggerSearch(searchText);"
                          [class.disabled]="!searchText"
                          class="cursor-pointer btn btn-sm btn-success">
                        <i class="fa-duotone fa-filters"></i>
                        {{'_ui_filter' | translate}}</span>


                </div>
            </div>


            <ul class="list-unstyled w-100 p-0 m-0">
                <li class="p-0">
                    <div class="input-group">
                        <input type="search"
                               class="form-control"
                               autocomplete="off"
                               [autofocusAfterInit]="true"
                               [(ngModel)]="searchText"
                               (ngModelChange)="modelChange($event)"/>

                    </div>
                </li>
            </ul>
        </div>
    </ng-template>
</ng-container>
