import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    TaskListAllTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/TaskListAllTableColumn";
import {TaskListAllCell} from "@app/pages/displays/display-project-details/subdisplay-top/Cells/TaskListAllCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-top/Filters";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";

export class TaskListAllColumn extends BaseColumn<TaskListAllCell>
    implements ColumnDataFetcherInterface, SortableColumnInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TaskFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    // <editor-fold desc="Sorting">

    public implementsSorting: true = true;

    public applyRowSort(row: Row, tableColumn: TaskListAllTableColumn): void {
        const listConfiguration = tableColumn.column.getCell(row).listConfiguration;
        listConfiguration.setOrderBy(
            this.getOrderBy(Filters.GetBaseSort(tableColumn.activeSortTypeId), tableColumn.activeSortDirection)
        );

        listConfiguration.clearSortFilters();
        switch (Filters.GetBaseSort(tableColumn.activeSortTypeId)) {
            case Filters.SortTasksDeadline:
                listConfiguration.setSort_DeadlineTypeId(Filters.ParseSortTasksDeadlineType(tableColumn.activeSortTypeId));
                break;
            case Filters.SortTasksCategoryType:
                listConfiguration.setSort_CategoryTypeId(Filters.ParseSortTasksCategoryType(tableColumn.activeSortTypeId));
                break;
        }
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new TaskFetcher(undefined, undefined, undefined, column.name);
    }

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new TaskListAllTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: Row): BaseCell {
        const cell = new TaskListAllCell(row, this);
        this.dataFetcher.addRequest(new TaskFetchRequest(cell.listConfiguration));
        return cell;
    }

    private getOrderBy(type: string, direction: string) {
        const orderBy = [];
        switch (Filters.GetBaseSort(type)) {
            case Filters.SortTasksTitle:
                orderBy.push(['title', direction]);
                break;
            case Filters.SortTasksDeadline:
                orderBy.push(['tasks_deadline.deadline.date', direction]);
                break;
            case Filters.SortTasksPlanningDate:
                orderBy.push(
                    ['tasks_user.deadline.date', 'null'],
                    ['tasks_user.deadline.date', direction],
                );
                break;
            case Filters.SortTasksStatus:
                orderBy.push(
                    ['main_status.status_id', direction  == 'asc' ? 'desc' : 'asc'],
                    ['tasks_deadline.deadline.date', 'null'],
                    ['tasks_deadline.deadline.date', 'asc'],
                    ['title', 'asc'],
                );
                break;
            case Filters.SortTasksStars:
                orderBy.push(['num_stars', (direction == 'asc') ? 'desc' : 'asc']);
                break;
            case Filters.SortTasksHands:
                orderBy.push(['num_hand_ups', (direction == 'asc') ? 'desc' : 'asc']);
                break;
            case Filters.SortTasksCategoryType:
                orderBy.push(['category.name', direction]);
                break;
        }

        return orderBy;
    }

}
