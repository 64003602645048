/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {MilestoneStatusDefinition} from './definitions/MilestoneStatusDefinition';
import {StatusTypes} from '@app/constants';

export class MilestoneStatus extends MilestoneStatusDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static CreateDefault(statusType?:number) {
        let item = new MilestoneStatus();
        item.id = 0;
        item.status_id = statusType ? statusType : StatusTypes.GREEN;
        return item;
    }

}
