/**
 * Created by ModelParser
 * Date: 25-01-2019.
 * Time: 12:30.
 */
import {Office365EventDefinition} from './definitions/Office365EventDefinition';
import * as moment from 'moment';

export class Office365Event extends Office365EventDefinition {

    num_stars: number;

    constructor(json?: any) {
        super(json);
    }

    get title(): string {
        return this.subject;
    }

    set title(s:string){
        this.subject = s;
    }

    get status(): any {
        return null;
    }

    get main_status(): any {
        return null;
    }

    set main_status(b: any) {

    }

    get handUp(): boolean {
        return null;
    }

    get starred(): boolean {
        return null;
    }

    public getStartDate(): Date {
        return moment(this.start).toDate();
    }

    public getEndDate(): Date {
        return moment(this.end).toDate();
    }

}
