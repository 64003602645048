<div class="flex-grow-1 -align-items-center m-0 p-0">
    <label *ngIf="configuration && configuration?.labelName">{{ configuration.labelName | translate }}</label>
    <ul
        *ngIf="types"
        class="list-unstyled list-group"
    >

        <li
            *ngFor="let type of types"
            class="list-group-item"
        >
            <div class="flex-grow-1 align-items-center">
                <label>
                    <span>{{ type.name | translate }}</span>
                    <span *ngIf="type.validator.required" class="text-danger">*</span>
                </label>

                <ul class="list-group"
                    [class.is-invalid]="!type.validator.isValid()"
                >
                    <li *ngIf="!type.validator.isValid()"
                        class="list-group-item d-flex justify-content-between flex-row align-items-center validation-error"
                    >
                        <app-validation-warning-list [inline]="true" [validatorError]="type.validator"></app-validation-warning-list>
                    </li>

                    <li
                        *ngIf="type.isDatePickerVisible"
                        class="list-group-item d-flex justify-content-between flex-row align-items-center"
                    >
                        <div class="d-flex flex-row flex-fill align-items-center w-100 disable-validation">
                            <div class="flex-grow-1 flex-shrink-1 me-1">
                                <label class="">{{'_ui_change_task_user_deadline' | translate}}</label>
                            </div>
                            <app-datepicker
                                [autoReset]="true"
                                (valueChanged)="onTypeUserDeadlineChanged(type, $event)"
                                class="mb-0 align-self-end flex-grow-1 flex-shrink-1"
                                style="max-width: 263px; min-width: 220px; padding-right: 45px;"
                            />
                        </div>
                    </li>

                    <li
                        *ngFor="let userItem of type.userItems"
                        class="list-group-item"
                        [class.is-invalid]="!type.validator.isValid()"
                    >
                        <app-user-list-editor-row
                            [type]="type"
                            [userItem]="userItem"
                            (userSelected)="onUserAdded(type, $event)"
                            (userRemoved)="type.removeUser(userItem)"
                            (archiveChanged)="onUserArchivedChanged(type, userItem, $event)"
                            (deadlineChanged)="onUserDeadlineChanged(type, userItem, $event)"
                        />
                    </li>

                    <li
                        *ngIf="type.showEmptySelect || type.showDepartmentPicker"
                        class="list-group-item d-flex flex-column gap-1 disable-validation"
                    >
                        <app-user-search
                            *ngIf="type.showEmptySelect"
                            [clearInputOnBlur]="true"
                            [autoReset]="true"
                            [selectedUsers]="type.selectedUsers"
                            (itemSelected)="onUserAdded(type, $event)"
                        />
                        <app-department-users-picker
                            *ngIf="type.showDepartmentPicker"
                            class="w-100"
                            (valueChanged)="onDepartmentSelected(type, $event.department, $event.users)"
                        />
                    </li>

                </ul>
            </div>
        </li>
    </ul>
</div>
