/**
 * Created by ModelParser
 */
import {Department} from '../Department';
import {Display} from '../Display';
import {DisplayFilter} from '../DisplayFilter';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class DisplayFilterUserSettingDefinition extends BaseModel {
    department_id?: number;
    department?: Department;
    display_id?: number;
    display?: Display;
    display_identifier?: string;
    display_filter_id?: number;
    display_filter?: DisplayFilter;
    user_id?: number;
    user?: User;
    position?: number;
    is_visible?: boolean;
    is_favorite?: boolean;
    is_default?: boolean;
    last_used?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.department_id;
            delete this.department;
            delete this.display_id;
            delete this.display;
            delete this.display_identifier;
            delete this.display_filter_id;
            delete this.display_filter;
            delete this.user_id;
            delete this.user;
            delete this.position;
            delete this.is_visible;
            delete this.is_favorite;
            delete this.is_default;
            delete this.last_used;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.department_id != null) {
            this.department_id = data.department_id;
        }
        if (data.department != null) {
            this.department = new Department(data.department);
        }
        if (data.display_id != null) {
            this.display_id = data.display_id;
        }
        if (data.display != null) {
            this.display = new Display(data.display);
        }
        if (data.display_identifier != null) {
            this.display_identifier = data.display_identifier;
        }
        if (data.display_filter_id != null) {
            this.display_filter_id = data.display_filter_id;
        }
        if (data.display_filter != null) {
            this.display_filter = new DisplayFilter(data.display_filter);
        }
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.position != null) {
            this.position = data.position;
        }
        if (data.is_visible != null) {
            this.is_visible = data.is_visible;
        }
        if (data.is_favorite != null) {
            this.is_favorite = data.is_favorite;
        }
        if (data.is_default != null) {
            this.is_default = data.is_default;
        }
        if (data.last_used != null) {
            this.last_used = data.last_used;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
