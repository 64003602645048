import {NgModule} from "@angular/core";
import {UserWithIconComponent} from "@app/shared/_elements/user-with-icon/user-with-icon.component";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        LoadingIndicatorModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        UserWithIconComponent,
    ],
    exports: [
        UserWithIconComponent

    ],
    providers: [

    ],
})
export class UserWithIconModule {}
