import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {AppointmentListConfiguration} from "@app/shared/_ui/lists/appointment-list/AppointmentListConfiguration";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";

export class AppointmentListCell extends BaseCell {

    public listConfiguration = new AppointmentListConfiguration();

    public constructor(row: BaseRow, column: BaseColumn) {
        super(row, column);

        this.listConfiguration
            .setLimit(2)
            .setOrderBy([['updated', 'desc'], ['created', 'desc']]);
    }

}
