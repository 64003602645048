import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {extract} from '@app/core';
import {ErrorComponent} from '@app/pages/error/error.component';
import {ErrorModule} from "@app/pages/error/error.module";


const routes: Routes = [
    {
        path: 'error',
        component: ErrorComponent,
        data: {
            title: extract('Error'),
            animation: 'Login',
        }
    },
];

@NgModule({
    imports: [
        ErrorModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
    providers: []
})
export class ErrorRoutingModule {
}
