<div class="d-flex flex-row align-self-right align-items-center filter-wrap"
     *ngIf="settings"
     [class.active]="!isDefaultValues">
    <a class="nav-link p-0 header-icon ms-2"
       *ngIf="isAltered"
       (click)="revertFilter()"
       [@simpleFadeAnimation]="'in'"
       style="transform: translate(2px, 0px);"
       [ngbTooltip]="('_ui_header_display_changed' | translate)"
       container="body"
       [placement]="['bottom', 'auto']"
    >
        <i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
    </a>

    <app-categories-selector class="header-icon ms-2"
                             *ngIf="visibility.showCategories"
                             [class.disabled]="!visibility.showCategories"
                             [categories]="settings.activeCategories"
                             (onCategoriesChangeEvent)="setCategories($event)"
    ></app-categories-selector>

    <app-emoji-picker class="header-icon ms-2"

                      [class.disabled]="!visibility.showReactionTypes"

                      (changeReactionEvent)="applyEmojiFilters($event)"
                      style="transform: translateY(0px);"
                      [toggleUsers]="false"
                      [autoClose]="false"
                      [showBadge]="true"
                      [showPopoverEmoji]="true"
                      [visible]="false"
                      [emojiItems]="emojiItems"
                      [tooltipTitle]="'_ui_display_reaction_filter_emoji'"
    ></app-emoji-picker>

    <app-filter-search

        [class.disabled]="!visibility.showSearch"
        class="ms-2 header-icon"></app-filter-search>

    <app-filter-users class="header-icon ms-2"
                      [class.disabled]="!visibility.showUsers"
                      (onUsersChangeEvent)="setUsers($event)"
                      [users]="settings.activeUsers"></app-filter-users>

    <app-filter-status class="d-flex align-items-center justify-content-center"></app-filter-status>


</div>
