<li  class="d-flex flex-row comma-delimeter align-items-center me-1 mb-1">

    <app-user-with-icon
        [showIcon]="false"
        [showInitials]="true"
        *ngFor="let tasksUser of tasksUsers"
        [model]="tasksUser.user"
        [ngbTooltip]="tasksUser?.user?.name ? tasksUser?.user?.name : tasksUser?.user?.first_name"
        class="me-1 text-small rounded text-dark ps-2 pe-2"></app-user-with-icon>

    <app-value-evaluator [badge]="true" class="text-12px me-1"
                         [value]="item.item.cases_planned"
                         [ngbTooltip]="('_task_plan' | translate)" ></app-value-evaluator>

    <app-value-evaluator [badge]="true"
                         class="text-12px"
                         [value]="item.item.cases_reached"
                         [showColors]="true"
                         [target]="item.item.cases_planned ? item.item.cases_planned : 0"
                         [ngbTooltip]="('_task_reached' | translate)"></app-value-evaluator>
</li>
