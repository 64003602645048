<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title" [innerHTML]="title"></h4>

        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <ng-container *ngIf="milestone">
        <div class="modal-body" [innerHTML]="message"></div>

        <div class="ps-3 pe-3 border-top pt-3" *ngIf="milestone.tasks?.length > 0">
            <div class="d-flex align-items-center justify-content-start no-wrap w-100  text-black-50 text-small">
                <i class="fal fa-info-circle me-1" aria-hidden="true"></i>
                <p class="m-0 p-0" [innerHTML]="'_ui_has_related_content' | translate:{item: milestone.title} "></p>
            </div>
            <ul class="list-unstyled mt-2">
                <li *ngIf="milestone.tasks" class="d-flex align-items-center">
                    <span [class.text-danger]="cascade">
                        <i class="fal trash me-1"
                           [class.fa-trash-can-check]="cascade"
                           [class.fa-trash-can-xmark]="!cascade"
                        ></i>
                        {{'_tasks' | translate}}</span>
                    <span class="badge rounded-pill badge-primary ms-auto"
                          [class.badge-danger]="cascade">{{milestone.tasks?.length}}</span>
                </li>
            </ul>
            <app-toggle-item class="me-2 "
                             [active]="cascade"
                             [interactive]="true"
                             [labelClass]="'-faux-label'"
                             [icon]="'fa-check-circle'"
                             [iconInactive]="'fa-circle'"
                             [iconColorActive]="'text-success'"
                             [iconColorInactive]="'text-black-50'"
                             (onToggle)="cascade = !cascade; "
                             [label]="('_ui_delete_attached_tasks' | translate)"
                             [tooltipLabel]="''"></app-toggle-item>
        </div>
    </ng-container>

    <div class="d-flex align-items-center justify-content-center pt-2 pb-2" *ngIf="!milestone">
        <app-loading-indicator [showLogo]="false"></app-loading-indicator>
    </div>
</div>
<div class="modal-footer">
    <button type="button" role="button" class="btn btn-secondary ellipsis" (click)="decline()"
            *ngIf="btnCancelText">{{ btnCancelText }}</button>
    <button type="button" role="button" [disabled]="!milestone" [class.disabled]="!milestone"
            class="btn ngbAutofocus {{primaryClass}} ellipsis" (click)="accept()">{{ btnOkText }}</button>
</div>
