import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {EventEmitter, TemplateRef} from "@angular/core";
import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {Widths} from "@app/constants";
import {Color} from "@app/core/models";

export abstract class BaseTableColumn {

    public abstract identifier: string;

    // ngx-datatable inputs
    $$id: string;
    name: string;
    frozenLeft: boolean = false;
    cellClass?: string;
    resizeable?: boolean = true;
    canAutoResize = true;
    cellTemplate?: TemplateRef<any>;
    headerTemplate?: TemplateRef<any>;
    width?: number;
    minWidth?: number = Widths.NumberColumn;
    maxWidth?: number;

    public column: BaseColumn;
    public isVisible: boolean;

    public customWidth?: number;
    public color?: Color;
    public isList: boolean = false; // Ex tasks-list, milestones-list, used by column-sorter

    constructor(column: BaseColumn) {
        this.column = column;
        this.name = column.column.name ?? '';
        this.isVisible = column.column.is_show_as_default ?? false;
    }

    public setWidth(value: number) {
        if (!isNaN(value)) {
            if (this.minWidth && value < this.minWidth) {
                this.width = this.minWidth;
            } else if (this.maxWidth && value > this.maxWidth) {
                this.width = this.maxWidth;
            } else {
                this.width = value;
            }
        }
        if (value == 0) {
            if (!this.minWidth || this.minWidth != Widths.CardColumn) {
                this.canAutoResize = true;
            }
            this.customWidth = undefined;
        }
        if (!this.customWidth) {
            this.canAutoResize = true;
        }
    }

    public get fullIdentifier(): string {
        return `${this.column.columnType.identifier}-${this.identifier}`;
    }

    // <editor-fold desc="Sort Items">

    public activeSortTypeId: string;
    public activeSortDirection: string = 'asc';
    public activeSortItem: PageColumnSort;
    public sortItems: PageColumnSort[];
    public onSortItemsChangeEvent = new EventEmitter();

    public setSortItems(value: PageColumnSort[]) {
        this.sortItems = value;
        this.onSortItemsChangeEvent.emit();
    }

    public setActiveSortItem(sortId: string, direction: string) {
        this.activeSortItem = this.sortItems.find(item => item.sortId == sortId);
        this.activeSortDirection = direction;
    }

    // </editor-fold>

    // <editor-fold desc="OnScreen Filters">

    private onScreenFilters: BaseOnScreenFilter[];
    public hasEnabledOnScreenFilters = false;

    public setOnScreenFilters(value: BaseOnScreenFilter[]) {
        this.onScreenFilters = value;
        const checkForEnabledOnScreenFilters = () => {
            this.hasEnabledOnScreenFilters = this.onScreenFilters.find(filter => filter.getEnabled()) !== undefined;
        };
        this.onScreenFilters.forEach(filter => {
            filter.onEnabledChangeEvent.subscribe(checkForEnabledOnScreenFilters);
        });
    }

    public getOnScreenFilters = (): BaseOnScreenFilter[] => {
        return this.onScreenFilters;
    }

    // </editor-fold>

}
