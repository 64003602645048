import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {TableColumns} from "@app/pages/displays/display-projects/TableColumns";
import {ProjectCardColumn} from "@app/pages/displays/display-projects/Columns/ProjectCardColumn";

export class ProjectCardTableColumn extends GenericTableColumn {

    public identifier = TableColumns.ProjectCard;
    public column: ProjectCardColumn;

}
