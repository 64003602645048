/**
 * Created by ModelParser
 * Date: 01-12-2020.
 * Time: 11:05.
 */
import {OauthClientDefinition} from './definitions/OauthClientDefinition';

export class OauthClient extends OauthClientDefinition {

    constructor(json?: any) {
        super(json);
    }

}
