import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Shell} from '@app/shell/Shell';
import {extract} from '@app/core';
import {DashboardComponent} from '@app/pages/home/dashboard/dashboard.component';
import {DashboardModule} from "@app/pages/home/dashboard/Dashboard.module";

const routes: Routes = [
    Shell.childRoutes([
        {
            path: 'home/dashboard/:displayMode',
            pathMatch: 'full',
            component: DashboardComponent,
            data: {
                title: extract('Velkommen'),
                animation: 'Page',
            }
        },
        {
            path: 'home/dashboard',
            pathMatch: 'full',
            component: DashboardComponent,
            data: {
                title: extract('Velkommen'),
                animation: 'Page',
            }
        },
    ])
];

@NgModule({
    imports: [
        DashboardModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
    providers: []
})
export class HomeRoutingModule {
}
