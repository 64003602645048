/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TaskEstimateDefinition} from './definitions/TaskEstimateDefinition';
import {Estimate} from '@app/core/models/Estimate';

export class TaskEstimate extends TaskEstimateDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static Create(typeId: number, estimate: Estimate): TaskEstimate {
        const item = new TaskEstimate();
        item.task_estimate_type_id = typeId;
        item.estimate = estimate;
        return item;
    }

    static createDefault() {
        let item = new TaskEstimate();
        item.id = 0;
        item.estimate = Estimate.createDefault();
        return item;
    }
}
