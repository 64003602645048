// `.env.ts` is generated by the `npm run env` command
import {versions} from '@env/versions';

const myHost = window.location.origin.split('://')[1];
const socketUrl = `wss://socket-${myHost}`;

export const environment = {
    production: true,
    hmr: false,
    version: versions.version,
    environment: 'Test',
    serverUrl: 'klarttest.dk/', // Genereres automatisk i api-prefix ud fra URI [prefix].[kunde].klartdev.dk
    apiPath: '/',
    socketUrl: socketUrl,
    defaultLanguage: 'da-DK',
    supportedLanguages: [
        'da-DK',
        'en-US',
        // 'nb',
        // 'nn',
    ],
    OAuthConfig: {
        clientId: 'klartboard-frontend',
        redirectUri: window.location.origin+'/login',
        postLogoutRedirectUri: window.location.origin,
        scope: 'openid offline_access', // offline_access profile email groups resource
        oidc: true,
        issuer: 'https://api-klart.klarttest.dk', // Overskrives i configureWithNewConfigApi
        requireHttps: false,
        responseType: 'id_token token',
        showDebugInformation: true,
        timeoutFactor: 0.75,
        sessionChecksEnabled: false,
        silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
        sessionCheckIntervall: 3 * 1000,
        silentRefreshTimeout: 20 * 1000
    }
};
