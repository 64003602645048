<ng-container>

    <ul class="list-group" *ngIf="!categoryType.show_name && categoryType.show_color && colors">

        <li *ngIf="type && !type.validator?.isValid()"
            class="list-group-item d-flex justify-content-between flex-row align-items-center validation-error"
        >
            <app-validation-warning-list [inline]="true" [validatorError]="type.validator"></app-validation-warning-list>
        </li>


        <li class="list-group-item" *ngIf="colors">
            <div class="form-group mb-0">
                <div class="search-wrap">
                    <div class="input-group">
                        <div class="form-control d-flex align-items-center">
                            <app-color-picker
                                (onToggle)="toggleColor($event)"
                                [colors]="colors"
                                [selectedColors]="selectedColors"
                                [tooltipTitle]=""></app-color-picker>
                        </div>
                        <span class="btn btn-outline-danger" (click)="removeAllColors()"><i
                            class="fal fa-trash-alt"></i></span>
                    </div>
                </div>
            </div>
        </li>
    </ul>

    <ng-template #rt let-r="result" let-t="term"
                 [typedTemplate]="resultTemplateType"
    >
        <div class="d-flex align-items-center">
            <app-color-item *ngIf="categoryType.show_color" [color]="r.color" class="me-1"
                            [className]="'large'"></app-color-item>
            <ngb-highlight [result]="r.name" [term]="t" *ngIf="categoryType.show_name"></ngb-highlight>
        </div>
    </ng-template>

    <ul class="list-group" *ngIf="(categoryType.show_name || (!colors || colors.length == 0))">
        <li class="list-group-item loading" *ngIf="!items">
            <app-loading-indicator [showLogo]="false"></app-loading-indicator>
        </li>

        <li *ngIf="type && !type.validator?.isValid()"
            class="list-group-item d-flex justify-content-between flex-row align-items-center validation-error"
        >
            <app-validation-warning-list [inline]="true" [validatorError]="type.validator"></app-validation-warning-list>
        </li>


        <li class="list-group-item" *ngIf="items && internalValues && items.length !== internalValues.length">
            <div class="form-group mb-0">
                <div class="search-wrap">
                    <div class="input-group">

                        <input type="search" class="form-control placeholder-sm"
                               autocomplete="off"
                               #instance="ngbTypeahead"
                               container="body"
                               [class.is-invalid]="searchFailed"
                               [(ngModel)]="model"
                               [ngbTypeahead]="search"
                               [resultTemplate]="rt"
                               popupClass="result-list"
                               [inputFormatter]="formatter"
                               (focus)="focus$.next($any($event.target).value)"
                               (click)="click$.next($any($event.target).value)"
                               (selectItem)="triggerSelection($event, instance)"
                               [placeholder]="('_ui_search_or_select_placeholder' | translate: {type: (categoryType.name)})"
                        />
                    </div>

                    <span class="searching" *ngIf="searching" [@fadeAnimation]="'in'">{{('_ui_searching' | translate)}}
                        ...</span>
                </div>
                <div class="invalid-feedback" *ngIf="searchFailed">{{('_ui_search_no_results' | translate)}}
                    .
                </div>
            </div>
        </li>
        <li *ngIf="items && items.length == 0" class="list-group-item">
            <div class="text-muted d-block m-0 text-small"><i class="fal fa-info-circle me-1"></i>
                {{('_ui_missing_categories' | translate)}}
            </div>
        </li>

        <ng-container
            *ngIf="internalValues"
        >
            <li class="list-group-item" *ngFor="let item of internalValues">
                <div class="input-group d-flex align-items-start">
                    <div class="form-control d-flex align-items-center ellipsis input-group-prepend pt-0 pb-0">
                        <app-color-item *ngIf="categoryType.show_color && item.color"
                                        [color]="item.color"
                                        class="me-1"></app-color-item>
                        <span
                            *ngIf="categoryType.show_name"
                            [ngbTooltip]="item.name"
                            style="border: none; background-color: transparent;"
                            class="form-control ellipsis">{{item.name}}</span>
                    </div>

                    <span class="btn btn-outline-danger" (click)="removeItem(item)"><i
                        class="fal fa-trash-alt"></i></span>

                </div>
            </li>
        </ng-container>
    </ul>

</ng-container>



