<h6 >{{display.name | translate}}</h6>
<ul class="list-unstyled scroll-y flex-grow-1">
    <li class=" p-1 ps-0  mb-1 border-bottom nav-item"
        *ngIf="getUser()"
    >
        <a
            class="d-flex flex-row p-1 align-items-center nav-link"
            routerLinkActive="active"
            [routerLink]="getRoute(displayUser)"
            [routerLinkActiveOptions]="{ exact: true }"
            queryParamsHandling="merge"
            (click)="toggleMenu()"
        >
            <i [class]="icon +' me-1'"></i>
            <span  class="ellipsis">{{display.name | translate}}</span>
        </a>
    </li>
    <ng-container *ngIf="isLoading">
        <li class="d-flex -align-items-center -justify-content-center ps-3">
            <app-loading-indicator [showLogo]="false"></app-loading-indicator>
        </li>
    </ng-container>
    <ng-container *ngIf="!isLoading || users">
        <li *ngFor="let user of users"
            (click)="selectUser(user)"
            [class.active]="selectedUser.id == user.id"
            class=" p-1 ps-0 nav-item">
            <a
                class="d-flex flex-row p-1 align-items-center nav-link"
                routerLinkActive="active"
                [routerLink]="getRoute(user)"
                [routerLinkActiveOptions]="{ exact: true }"
                queryParamsHandling="merge"
                (click)="toggleMenu()">
                <i [class]="icon +' me-1'"></i>
                <span  class="ellipsis">{{user.name | translate}}</span>
            </a>

        </li>
        <li *ngIf="!isLoading && users.length == 0" class="d-flex align-items-center">
            <i class="fal fa-exclamation-triangle"></i>
            <span >{{'_ui_missing_users' | translate}}</span>
        </li>
    </ng-container>
</ul>
