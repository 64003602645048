import {BaseApi} from '@app/core/http/Api/BaseApi';
import { Observable, Subscription as RXJSSubscription } from 'rxjs';
import {ColumnTypeSetting} from '@app/core/models';
import {ColumnType} from '@app/core/models';
import {Column} from '@app/core/models';
import {ColumnSetting} from '@app/core/models';
import {Display} from '@app/core/models';
import {Appointment} from '@app/core/models';
import {Category} from '@app/core/models';
import {CategoryType} from '@app/core/models';
import {CategoryTypesMilestone} from '@app/core/models';
import {Color} from '@app/core/models';
import {Deletion} from '@app/core/models';
import {DepartmentGroup} from '@app/core/models';
import {Department} from '@app/core/models';
import {DisplayFilter} from '@app/core/models';
import {DisplayFilterUserSetting} from '@app/core/models';
import {DisplayType} from '@app/core/models';
import {TeamDisplayUserPosition} from '@app/core/models';
import {DisplaysSetting} from '@app/core/models';
import {DynamicMenuItem} from '@app/core/models';
import {EstimateUnit} from '@app/core/models';
import {Holiday} from '@app/core/models';
import {Import} from '@app/core/models';
import {LanguageKey} from '@app/core/models';
import {LanguageValue} from '@app/core/models';
import {Language} from '@app/core/models';
import {Load} from '@app/core/models';
import {Log} from '@app/core/models';
import {MilestoneField} from '@app/core/models';
import {MilestonePlan} from '@app/core/models';
import {BlockedPeriod} from '@app/core/models';
import {BlockedHoliday} from '@app/core/models';
import {MilestoneTemplate} from '@app/core/models';
import {MilestoneReactionType} from '@app/core/models';
import {MilestoneSetting} from '@app/core/models';
import {MilestoneStatusType} from '@app/core/models';
import {StatusRule} from '@app/core/models';
import {TaskTemplate} from '@app/core/models';
import {Milestone} from '@app/core/models';
import {Office365Event} from '@app/core/models';
import {PeriodRating} from '@app/core/models';
import {Period} from '@app/core/models';
import {PhaseProgressType} from '@app/core/models';
import {Phase} from '@app/core/models';
import {ProjectCase} from '@app/core/models';
import {ProjectDeadlineType} from '@app/core/models';
import {ProjectEstimateType} from '@app/core/models';
import {ProjectEstimate} from '@app/core/models';
import {ProjectField} from '@app/core/models';
import {ProjectStatusType} from '@app/core/models';
import {ProjectType} from '@app/core/models';
import {ProjectUserType} from '@app/core/models';
import {Project} from '@app/core/models';
import {PhasesProject} from '@app/core/models';
import {ReactionTypesTodo} from '@app/core/models';
import {Role} from '@app/core/models';
import {Site} from '@app/core/models';
import {StaticDeadline} from '@app/core/models';
import {Statistic} from '@app/core/models';
import {Status} from '@app/core/models';
import {Tag} from '@app/core/models';
import {TaskDeadlineType} from '@app/core/models';
import {TaskEstimateType} from '@app/core/models';
import {TaskField} from '@app/core/models';
import {TaskStatusType} from '@app/core/models';
import {User} from '@app/core/models';
import {TaskType} from '@app/core/models';
import {TaskUserType} from '@app/core/models';
import {Task} from '@app/core/models';
import {TodoCategory} from '@app/core/models';
import {TodoField} from '@app/core/models';
import {TodoSetting} from '@app/core/models';
import {TodoStatusType} from '@app/core/models';
import {Todo} from '@app/core/models';
import {UserField} from '@app/core/models';

export interface AzureGraphInterface {
}

export interface CaseShelves_CaseTextFieldSettingValue {
    fieldName?: string;
}

export interface CaseShelves_DaysTaskTypesSettingValue {
    taskTypeIds?: number[];
}

export interface CaseShelves_DaysVisibleDaysSettingValue {
    days?: string[];
}

export interface CaseShelves_GoalTextFieldSettingValue {
    fieldName?: string;
}

export interface CaseShelves_PresetDeadline_DaySettingValue {
    day?: string;
}

export interface Category_Category_FilterCategorySettingValue {
    categoryId?: number;
}

export interface ColumnCreateRequest {
    displayId?: number;
    identifier?: string;
    name?: string;
}

export interface ColumnsValidateSettingsResponse {
    isValid?: boolean;
    errors?: string[];
}

export interface IntArrayInterface {
    values?: number[];
}

export interface KanbanProgress_PhaseProgress_FilterPhaseProgressSettingValue {
    phaseProgressId?: number;
}

export interface Kanban_Phase_FilterPhaseSettingValue {
    phaseId?: number;
}

export interface Kanban_Prioritization_CategoryTypeSettingValue {
    categoryTypeId?: number;
}

export interface Kanban_Prioritization_MatrixSettingValue {
    color1?: string;
    color2?: string;
    color3?: string;
    color4?: string;
    text1?: string;
    text2?: string;
    text3?: string;
    text4?: string;
    axisX?: string;
    axisY?: string;
}

export interface LoadCalculationResponse {
    user?: LoadCalculationResponseUser;
    load?: LoadCalculationResponseLoad;
}

export interface LoadCalculationResponseLoad {
    tasks?: number;
    events?: number;
    total?: number;
}

export interface LoadCalculationResponseUser {
    id?: number;
    name?: string;
}

export interface MilestonePlansCopyResponse {
    error?: string;
    project?: Project;
    milestones?: Milestone[];
}

export interface MilestonesCreateRequest {
    presets?: MilestonesCreateRequestPreset[];
}

export interface MilestonesCreateRequestPreset {
    type?: string;
    payload?: string;
}

export interface ProjectCasesCopyResponse {
    items?: ProjectCasesCopyResponseItem[];
}

export interface ProjectCasesCopyResponseItem {
    project_id?: number;
    from_date?: string;
    to_date?: string;
    from_cases?: number;
    from_reached?: number;
    to_cases?: number;
    goal?: number;
    from_project_case?: ProjectCase;
    to_project_case?: ProjectCase;
}

export interface ProjectCasesSummaryResponse {
    total_cases_estimate?: number;
    total_goal_estimate?: number;
    total_plan_estimate?: number;
    total_reached_estimate?: number;
}

export interface ProjectDetails_Milestones_MilestoneEditFieldsSettingValue {
    fields?: string[];
}

export interface ProjectDetails_Milestones_TaskEditFieldsSettingValue {
    fields?: string[];
}

export interface ProjectDetails_Milestones_TaskEdit_DeadlineDeadlineTypeSettingValue {
    taskDeadlineTypeId?: number;
}

export interface ProjectDetails_Milestones_TaskListPeriodTypesSettingValue {
    periodTypes?: string[];
}

export interface ProjectDetails_Milestones_TaskListStatusColorsSettingValue {
    colorTypes?: string[];
}

export interface ProjectDetails_Tasks_TaskEditFieldsSettingValue {
    fields?: string[];
}

export interface ProjectDetails_Tasks_TaskEdit_DeadlineDeadlineTypeSettingValue {
    taskDeadlineTypeId?: number;
}

export interface ProjectDetails_Tasks_TaskListPeriodTypesSettingValue {
    periodTypes?: string[];
}

export interface ProjectDetails_Tasks_TaskListStatusColorsSettingValue {
    colorTypes?: string[];
}

export interface ProjectDetails_Top_ProjectDeadlineTypeFromAndToSettingValue {
    fromProjectDeadlineTypeId?: number;
    toProjectDeadlineTypeId?: number;
}

export interface ProjectDetails_Top_ProjectDeadlineTypesSettingValue {
    projectDeadlineTypeIds?: number[];
}

export interface ProjectDetails_Top_ProjectEstimateTypesSettingValue {
    projectEstimateTypeIds?: number[];
}

export interface ProjectDetails_Top_ProjectNoteFieldSettingValue {
    fieldName?: string;
}

export interface ProjectDetails_Top_ProjectStatusTypesSettingValue {
    projectStatusTypeIds?: number[];
}

export interface ProjectDetails_Top_ProjectUserTypesSettingValue {
    projectUserTypeIds?: number[];
}

export interface ProjectDetails_Top_YearWheelProjectDeadlineTypesSettingValue {
    startProjectDeadlineTypeId?: number;
    endProjectDeadlineTypeId?: number;
}

export interface ProjectDetails_Users_TaskEditFieldsSettingValue {
    fields?: string[];
}

export interface ProjectDetails_Users_TaskEdit_DeadlineDeadlineTypeSettingValue {
    taskDeadlineTypeId?: number;
}

export interface ProjectDetails_Users_TaskListPeriodTypesSettingValue {
    periodTypes?: string[];
}

export interface ProjectDetails_Users_TaskListStatusColorsSettingValue {
    colorTypes?: string[];
}

export interface ProjectsCreateRequest {
    presets?: ProjectsCreateRequestPreset[];
}

export interface ProjectsCreateRequestPreset {
    type?: string;
    payload?: string;
}

export interface ProjectsSmartCountResponse {
    user_id?: number;
    statuses?: ProjectsSmartCountResponseStatus[];
}

export interface ProjectsSmartCountResponseStatus {
    status_id?: number;
    count?: number;
}

export interface Projects_FollowingMilestoneListDefaultSortSettingValue {
    type?: string;
    direction?: string;
}

export interface Projects_ProjectDeadlineTypeFromAndToSettingValue {
    fromProjectDeadlineTypeId?: number;
    toProjectDeadlineTypeId?: number;
}

export interface Projects_ProjectDeadlineTypesSettingValue {
    projectDeadlineTypeIds?: number[];
}

export interface Projects_ProjectEstimateTypesSettingValue {
    projectEstimateTypeIds?: number[];
}

export interface Projects_ProjectNoteFieldSettingValue {
    fieldName?: string;
}

export interface Projects_ProjectStatusTypesSettingValue {
    projectStatusTypeIds?: number[];
}

export interface Projects_ProjectUserTypesSettingValue {
    projectUserTypeIds?: number[];
}

export interface Projects_RosterDaysSettingValue {
    days?: string[];
}

export interface Projects_TaskListDefaultSortSettingValue {
    type?: string;
    direction?: string;
}

export interface Projects_TaskListNextMilestoneDefaultSortSettingValue {
    type?: string;
    direction?: string;
}

export interface Projects_TaskListNextMilestoneTaskTypesSettingValue {
    taskTypeIds?: number[];
}

export interface Projects_TaskListTaskTypesSettingValue {
    taskTypeIds?: number[];
}

export interface Projects_TaskListWithoutMilestoneDefaultSortSettingValue {
    type?: string;
    direction?: string;
}

export interface Projects_TaskListWithoutMilestoneTaskTypesSettingValue {
    taskTypeIds?: number[];
}

export interface Projects_TaskTypesSettingValue {
    taskTypeIds?: number[];
}

export interface Projects_TodoListIncludeArchivedSinceSettingValue {
    days?: number;
}

export interface Projects_YearWheelProjectDeadlineTypesSettingValue {
    startProjectDeadlineTypeId?: number;
    endProjectDeadlineTypeId?: number;
}

export interface StringInterface {
    value?: string;
}

export interface TasksCreateRequest {
    presets?: TasksCreateRequestPreset[];
}

export interface TasksCreateRequestPreset {
    type?: string;
    payload?: string;
}

export interface Team_CaseListTaskTypesSettingValue {
    taskTypeIds?: number[];
}

export interface Team_DaysTaskTypesSettingValue {
    taskTypeIds?: number[];
}

export interface Team_DaysVisibleDaysSettingValue {
    days?: string[];
}

export interface Team_MilestoneListProjectTypesSettingValue {
    projectTypeIds?: number[];
}

export interface Team_ProjectListProjectTypesSettingValue {
    projectTypeIds?: number[];
}

export interface Team_TaskListSoftNextWeekTaskTypesSettingValue {
    taskTypeIds?: number[];
}

export interface Team_TaskListSoftThisWeekTaskTypesSettingValue {
    taskTypeIds?: number[];
}

export interface Team_TaskListTaskTypesSettingValue {
    taskTypeIds?: number[];
}

export interface Team_UserCardLoadProfileSettingValue {
    loadProfileId?: number;
}

export interface TodosCreateRequest {
    presets?: TodosCreateRequestPreset[];
}

export interface TodosCreateRequestPreset {
    type?: string;
    payload?: string;
}

export interface UserOverviewCalendar_Days_FilterProjectDeadlineTypesSettingValue {
    projectDeadlineTypeIds?: number[];
}

export interface UserOverviewCalendar_Days_IncludeMilestonesSettingValue {
    value?: boolean;
}

export interface UserOverviewCalendar_Days_IncludeProjectTypesSettingValue {
    projectTypeIds?: number[];
}

export interface UserOverviewCalendar_Days_IncludeTaskPlanningsSettingValue {
    value?: boolean;
}

export interface UserOverviewCalendar_Days_IncludeTaskTypesSettingValue {
    taskTypeIds?: number[];
}

export interface UserOverviewCalendar_Days_VisibleDaysSettingValue {
    days?: string[];
}

export interface UserOverviewCalendar_SoftNextWeek_IncludeTaskTypesSettingValue {
    taskTypeIds?: number[];
}

export interface UserOverviewCalendar_SoftThisWeek_IncludeTaskTypesSettingValue {
    taskTypeIds?: number[];
}

export interface UserOverviewItems_TaskOrderList_TaskUserTypesSettingValue {
    taskUserTypeIds?: number[];
}

export interface UserOverviewMyWeek_Load_LoadProfileSettingValue {
    loadProfileId?: number;
}

export class Api {

    public static adminApiColumnTypeSettings(): AdminApiColumnTypeSettings {
        return new AdminApiColumnTypeSettings();
    }

    public static adminApiColumnTypes(): AdminApiColumnTypes {
        return new AdminApiColumnTypes();
    }

    public static adminApiColumns(): AdminApiColumns {
        return new AdminApiColumns();
    }

    public static adminApiDisplays(): AdminApiDisplays {
        return new AdminApiDisplays();
    }

    public static appointments(): Appointments {
        return new Appointments();
    }

    public static authRequest(): AuthRequest {
        return new AuthRequest();
    }

    public static azure(): Azure {
        return new Azure();
    }

    public static categories(): Categories {
        return new Categories();
    }

    public static categoryTypes(): CategoryTypes {
        return new CategoryTypes();
    }

    public static categoryTypesMilestones(): CategoryTypesMilestones {
        return new CategoryTypesMilestones();
    }

    public static colors(): Colors {
        return new Colors();
    }

    public static deletions(): Deletions {
        return new Deletions();
    }

    public static departmentGroups(): DepartmentGroups {
        return new DepartmentGroups();
    }

    public static departments(): Departments {
        return new Departments();
    }

    public static displayFilters(): DisplayFilters {
        return new DisplayFilters();
    }

    public static displayTypes(): DisplayTypes {
        return new DisplayTypes();
    }

    public static displays(): Displays {
        return new Displays();
    }

    public static displaysSettings(): DisplaysSettings {
        return new DisplaysSettings();
    }

    public static dynamicMenuItems(): DynamicMenuItems {
        return new DynamicMenuItems();
    }

    public static estimateUnits(): EstimateUnits {
        return new EstimateUnits();
    }

    public static holidays(): Holidays {
        return new Holidays();
    }

    public static imports(): Imports {
        return new Imports();
    }

    public static languageKeys(): LanguageKeys {
        return new LanguageKeys();
    }

    public static languageValues(): LanguageValues {
        return new LanguageValues();
    }

    public static languages(): Languages {
        return new Languages();
    }

    public static loads(): Loads {
        return new Loads();
    }

    public static logs(): Logs {
        return new Logs();
    }

    public static milestoneFields(): MilestoneFields {
        return new MilestoneFields();
    }

    public static milestonePlans(): MilestonePlans {
        return new MilestonePlans();
    }

    public static milestoneReactionTypes(): MilestoneReactionTypes {
        return new MilestoneReactionTypes();
    }

    public static milestoneSettings(): MilestoneSettings {
        return new MilestoneSettings();
    }

    public static milestoneStatusTypes(): MilestoneStatusTypes {
        return new MilestoneStatusTypes();
    }

    public static milestoneTemplates(): MilestoneTemplates {
        return new MilestoneTemplates();
    }

    public static milestones(): Milestones {
        return new Milestones();
    }

    public static office365Events(): Office365Events {
        return new Office365Events();
    }

    public static periodRatings(): PeriodRatings {
        return new PeriodRatings();
    }

    public static periods(): Periods {
        return new Periods();
    }

    public static phaseProgressTypes(): PhaseProgressTypes {
        return new PhaseProgressTypes();
    }

    public static phases(): Phases {
        return new Phases();
    }

    public static projectCases(): ProjectCases {
        return new ProjectCases();
    }

    public static projectDeadlineTypes(): ProjectDeadlineTypes {
        return new ProjectDeadlineTypes();
    }

    public static projectEstimateTypes(): ProjectEstimateTypes {
        return new ProjectEstimateTypes();
    }

    public static projectEstimates(): ProjectEstimates {
        return new ProjectEstimates();
    }

    public static projectFields(): ProjectFields {
        return new ProjectFields();
    }

    public static projectStatusTypes(): ProjectStatusTypes {
        return new ProjectStatusTypes();
    }

    public static projectTypes(): ProjectTypes {
        return new ProjectTypes();
    }

    public static projectUserTypes(): ProjectUserTypes {
        return new ProjectUserTypes();
    }

    public static projects(): Projects {
        return new Projects();
    }

    public static reactionTypesTodos(): ReactionTypesTodos {
        return new ReactionTypesTodos();
    }

    public static roles(): Roles {
        return new Roles();
    }

    public static sCIM(): SCIM {
        return new SCIM();
    }

    public static sites(): Sites {
        return new Sites();
    }

    public static staticDeadlines(): StaticDeadlines {
        return new StaticDeadlines();
    }

    public static statistics(): Statistics {
        return new Statistics();
    }

    public static statusRules(): StatusRules {
        return new StatusRules();
    }

    public static statuses(): Statuses {
        return new Statuses();
    }

    public static tags(): Tags {
        return new Tags();
    }

    public static taskDeadlineTypes(): TaskDeadlineTypes {
        return new TaskDeadlineTypes();
    }

    public static taskEstimateTypes(): TaskEstimateTypes {
        return new TaskEstimateTypes();
    }

    public static taskFields(): TaskFields {
        return new TaskFields();
    }

    public static taskStatusTypes(): TaskStatusTypes {
        return new TaskStatusTypes();
    }

    public static taskTemplates(): TaskTemplates {
        return new TaskTemplates();
    }

    public static taskTypes(): TaskTypes {
        return new TaskTypes();
    }

    public static taskUserTypes(): TaskUserTypes {
        return new TaskUserTypes();
    }

    public static tasks(): Tasks {
        return new Tasks();
    }

    public static teamDisplayUserPositions(): TeamDisplayUserPositions {
        return new TeamDisplayUserPositions();
    }

    public static todoCategories(): TodoCategories {
        return new TodoCategories();
    }

    public static todoFields(): TodoFields {
        return new TodoFields();
    }

    public static todoSettings(): TodoSettings {
        return new TodoSettings();
    }

    public static todoStatusTypes(): TodoStatusTypes {
        return new TodoStatusTypes();
    }

    public static todos(): Todos {
        return new Todos();
    }

    public static userFields(): UserFields {
        return new UserFields();
    }

    public static users(): Users {
        return new Users();
    }

}

class AdminApiColumnTypeSettings {

    public getByColumnTypeSettingId(columnTypeSettingId: number): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        return new AdminApiColumnTypeSettingsGetByColumnTypeSettingId(columnTypeSettingId);
    }

}

export class AdminApiColumnTypeSettingsGetByColumnTypeSettingId extends BaseApi<ColumnTypeSetting> {

    public topic = 'Resources.ColumnTypeSettings';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(columnTypeSettingId: number) {
        super();
        this.uri = `/admin/api/column-type-settings/${columnTypeSettingId}`;
    }

    protected convertToResource(data: any): ColumnTypeSetting {
        return new ColumnTypeSetting(data);
    }

    public where(name: string, value: any): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): AdminApiColumnTypeSettingsGetByColumnTypeSettingId {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: ColumnTypeSetting[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ColumnTypeSetting[] | any[]> {
        return super.executeClientGet();
    }
}

class AdminApiColumnTypes {

    public getByColumnTypeId(columnTypeId: number): AdminApiColumnTypesGetByColumnTypeId {
        return new AdminApiColumnTypesGetByColumnTypeId(columnTypeId);
    }

}

export class AdminApiColumnTypesGetByColumnTypeId extends BaseApi<ColumnType> {

    public topic = 'Resources.ColumnTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(columnTypeId: number) {
        super();
        this.uri = `/admin/api/column-types/${columnTypeId}`;
    }

    protected convertToResource(data: any): ColumnType {
        return new ColumnType(data);
    }

    public where(name: string, value: any): AdminApiColumnTypesGetByColumnTypeId {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): AdminApiColumnTypesGetByColumnTypeId {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): AdminApiColumnTypesGetByColumnTypeId {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): AdminApiColumnTypesGetByColumnTypeId {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): AdminApiColumnTypesGetByColumnTypeId {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): AdminApiColumnTypesGetByColumnTypeId {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): AdminApiColumnTypesGetByColumnTypeId {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): AdminApiColumnTypesGetByColumnTypeId {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): AdminApiColumnTypesGetByColumnTypeId {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): AdminApiColumnTypesGetByColumnTypeId {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): AdminApiColumnTypesGetByColumnTypeId {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): AdminApiColumnTypesGetByColumnTypeId {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): AdminApiColumnTypesGetByColumnTypeId {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): AdminApiColumnTypesGetByColumnTypeId {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): AdminApiColumnTypesGetByColumnTypeId {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): AdminApiColumnTypesGetByColumnTypeId {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): AdminApiColumnTypesGetByColumnTypeId {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: ColumnType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ColumnType[] | any[]> {
        return super.executeClientGet();
    }
}

class AdminApiColumns {

    public createPost(): AdminApiColumnsCreatePost {
        return new AdminApiColumnsCreatePost();
    }

    public updateNamePutByColumnId(columnId: number): AdminApiColumnsUpdateNamePutByColumnId {
        return new AdminApiColumnsUpdateNamePutByColumnId(columnId);
    }

    public updateIsShownAsDefaultPutByColumnId(columnId: number): AdminApiColumnsUpdateIsShownAsDefaultPutByColumnId {
        return new AdminApiColumnsUpdateIsShownAsDefaultPutByColumnId(columnId);
    }

    public updatePositionPutByColumnId(columnId: number): AdminApiColumnsUpdatePositionPutByColumnId {
        return new AdminApiColumnsUpdatePositionPutByColumnId(columnId);
    }

    public updatePositionsPut(): AdminApiColumnsUpdatePositionsPut {
        return new AdminApiColumnsUpdatePositionsPut();
    }

    public updateSettingPutByColumnIdBySettingIdentifier(columnId: number, settingIdentifier: string): AdminApiColumnsUpdateSettingPutByColumnIdBySettingIdentifier {
        return new AdminApiColumnsUpdateSettingPutByColumnIdBySettingIdentifier(columnId, settingIdentifier);
    }

    public validateSettingsGetByColumnId(columnId: number): AdminApiColumnsValidateSettingsGetByColumnId {
        return new AdminApiColumnsValidateSettingsGetByColumnId(columnId);
    }

    public getByColumnId(columnId: number): AdminApiColumnsGetByColumnId {
        return new AdminApiColumnsGetByColumnId(columnId);
    }

    public deleteByColumnId(columnId: number): AdminApiColumnsDeleteByColumnId {
        return new AdminApiColumnsDeleteByColumnId(columnId);
    }

}

export class AdminApiColumnsCreatePost extends BaseApi<Column> {

    public topic = 'Resources.Columns';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/admin/api/columns/create`;
    }

    protected convertToResource(data: any): Column {
        return new Column(data);
    }

    public save(data: ColumnCreateRequest, next?: (value: Column) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class AdminApiColumnsUpdateNamePutByColumnId extends BaseApi<Column> {

    public topic = 'Resources.Columns';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(columnId: number) {
        super();
        this.uri = `/admin/api/columns/${columnId}/name`;
    }

    protected convertToResource(data: any): Column {
        return new Column(data);
    }

    public value(value: string): AdminApiColumnsUpdateNamePutByColumnId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Column) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class AdminApiColumnsUpdateIsShownAsDefaultPutByColumnId extends BaseApi<Column> {

    public topic = 'Resources.Columns';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(columnId: number) {
        super();
        this.uri = `/admin/api/columns/${columnId}/is-shown-as-default`;
    }

    protected convertToResource(data: any): Column {
        return new Column(data);
    }

    public value(value: boolean): AdminApiColumnsUpdateIsShownAsDefaultPutByColumnId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Column) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class AdminApiColumnsUpdatePositionPutByColumnId extends BaseApi<Column> {

    public topic = 'Resources.Columns';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(columnId: number) {
        super();
        this.uri = `/admin/api/columns/${columnId}/position`;
    }

    protected convertToResource(data: any): Column {
        return new Column(data);
    }

    public value(value: number): AdminApiColumnsUpdatePositionPutByColumnId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Column) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class AdminApiColumnsUpdatePositionsPut extends BaseApi<Column> {

    public topic = 'Resources.Columns';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/admin/api/columns/positions`;
    }

    protected convertToResource(data: any): Column {
        return new Column(data);
    }

    public save(data: IntArrayInterface, next?: (value: Column) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class AdminApiColumnsUpdateSettingPutByColumnIdBySettingIdentifier extends BaseApi<ColumnSetting> {

    public topic = 'Resources.ColumnSettings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(columnId: number, settingIdentifier: string) {
        super();
        this.uri = `/admin/api/columns/${columnId}/settings/${settingIdentifier}/value`;
    }

    protected convertToResource(data: any): ColumnSetting {
        return new ColumnSetting(data);
    }

    public value(value: string): AdminApiColumnsUpdateSettingPutByColumnIdBySettingIdentifier {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: ColumnSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class AdminApiColumnsValidateSettingsGetByColumnId extends BaseApi<ColumnsValidateSettingsResponse> {

    public topic = 'Resources.ColumnsValidateSettingsResponses';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(columnId: number) {
        super();
        this.uri = `/admin/api/columns/${columnId}/settings/validate`;
    }

    protected convertToResource(data: any): ColumnsValidateSettingsResponse {
        return data;
    }

    public find(next?: (value: ColumnsValidateSettingsResponse[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ColumnsValidateSettingsResponse[] | any[]> {
        return super.executeClientGet();
    }
}

export class AdminApiColumnsGetByColumnId extends BaseApi<Column> {

    public topic = 'Resources.Columns';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(columnId: number) {
        super();
        this.uri = `/admin/api/columns/${columnId}`;
    }

    protected convertToResource(data: any): Column {
        return new Column(data);
    }

    public where(name: string, value: any): AdminApiColumnsGetByColumnId {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): AdminApiColumnsGetByColumnId {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): AdminApiColumnsGetByColumnId {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): AdminApiColumnsGetByColumnId {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): AdminApiColumnsGetByColumnId {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): AdminApiColumnsGetByColumnId {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): AdminApiColumnsGetByColumnId {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): AdminApiColumnsGetByColumnId {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): AdminApiColumnsGetByColumnId {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): AdminApiColumnsGetByColumnId {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): AdminApiColumnsGetByColumnId {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): AdminApiColumnsGetByColumnId {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): AdminApiColumnsGetByColumnId {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): AdminApiColumnsGetByColumnId {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): AdminApiColumnsGetByColumnId {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): AdminApiColumnsGetByColumnId {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): AdminApiColumnsGetByColumnId {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Column[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Column[] | any[]> {
        return super.executeClientGet();
    }
}

export class AdminApiColumnsDeleteByColumnId extends BaseApi<Column> {

    public topic = 'Resources.Columns';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(columnId: number) {
        super();
        this.uri = `/admin/api/columns/${columnId}`;
    }

    protected convertToResource(data: any): Column {
        return new Column(data);
    }

    public delete(next?: (value: Column) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class AdminApiDisplays {

    public getByDisplayId(displayId: number): AdminApiDisplaysGetByDisplayId {
        return new AdminApiDisplaysGetByDisplayId(displayId);
    }

}

export class AdminApiDisplaysGetByDisplayId extends BaseApi<Display> {

    public topic = 'Resources.Displays';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(displayId: number) {
        super();
        this.uri = `/admin/api/displays/${displayId}`;
    }

    protected convertToResource(data: any): Display {
        return new Display(data);
    }

    public where(name: string, value: any): AdminApiDisplaysGetByDisplayId {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): AdminApiDisplaysGetByDisplayId {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): AdminApiDisplaysGetByDisplayId {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): AdminApiDisplaysGetByDisplayId {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): AdminApiDisplaysGetByDisplayId {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): AdminApiDisplaysGetByDisplayId {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): AdminApiDisplaysGetByDisplayId {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): AdminApiDisplaysGetByDisplayId {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): AdminApiDisplaysGetByDisplayId {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): AdminApiDisplaysGetByDisplayId {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): AdminApiDisplaysGetByDisplayId {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): AdminApiDisplaysGetByDisplayId {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): AdminApiDisplaysGetByDisplayId {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): AdminApiDisplaysGetByDisplayId {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): AdminApiDisplaysGetByDisplayId {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): AdminApiDisplaysGetByDisplayId {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): AdminApiDisplaysGetByDisplayId {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Display[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Display[] | any[]> {
        return super.executeClientGet();
    }
}

class Appointments {

    public get(): AppointmentsGet {
        return new AppointmentsGet();
    }

    public getById(id: number): AppointmentsGetById {
        return new AppointmentsGetById(id);
    }

    public post(): AppointmentsPost {
        return new AppointmentsPost();
    }

    public putById(id: number): AppointmentsPutById {
        return new AppointmentsPutById(id);
    }

    public put(): AppointmentsPut {
        return new AppointmentsPut();
    }

    public patchById(id: number): AppointmentsPatchById {
        return new AppointmentsPatchById(id);
    }

    public patch(): AppointmentsPatch {
        return new AppointmentsPatch();
    }

    public deleteById(id: number): AppointmentsDeleteById {
        return new AppointmentsDeleteById(id);
    }

}

export class AppointmentsGet extends BaseApi<Appointment> {

    public topic = 'Resources.Appointments';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/appointments`;
    }

    protected convertToResource(data: any): Appointment {
        return new Appointment(data);
    }

    public where(name: string, value: any): AppointmentsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): AppointmentsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): AppointmentsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): AppointmentsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): AppointmentsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): AppointmentsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): AppointmentsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): AppointmentsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): AppointmentsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): AppointmentsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): AppointmentsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): AppointmentsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): AppointmentsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): AppointmentsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): AppointmentsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): AppointmentsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): AppointmentsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Appointment[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Appointment[] | any[]> {
        return super.executeClientGet();
    }
}

export class AppointmentsGetById extends BaseApi<Appointment> {

    public topic = 'Resources.Appointments';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/appointments/${id}`;
    }

    protected convertToResource(data: any): Appointment {
        return new Appointment(data);
    }

    public include(name: string): AppointmentsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Appointment[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Appointment[] | any[]> {
        return super.executeClientGet();
    }
}

export class AppointmentsPost extends BaseApi<Appointment> {

    public topic = 'Resources.Appointments';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/appointments`;
    }

    protected convertToResource(data: any): Appointment {
        return new Appointment(data);
    }

    public save(data: Appointment, next?: (value: Appointment) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class AppointmentsPutById extends BaseApi<Appointment> {

    public topic = 'Resources.Appointments';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/appointments/${id}`;
    }

    protected convertToResource(data: any): Appointment {
        return new Appointment(data);
    }

    public save(data: Appointment, next?: (value: Appointment) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class AppointmentsPut extends BaseApi<Appointment> {

    public topic = 'Resources.Appointments';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/appointments`;
    }

    protected convertToResource(data: any): Appointment {
        return new Appointment(data);
    }

    public save(data: Appointment, next?: (value: Appointment) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class AppointmentsPatchById extends BaseApi<Appointment> {

    public topic = 'Resources.Appointments';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/appointments/${id}`;
    }

    protected convertToResource(data: any): Appointment {
        return new Appointment(data);
    }

    public save(data: Appointment, next?: (value: Appointment) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class AppointmentsPatch extends BaseApi<Appointment> {

    public topic = 'Resources.Appointments';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/appointments`;
    }

    protected convertToResource(data: any): Appointment {
        return new Appointment(data);
    }

    public save(data: Appointment, next?: (value: Appointment) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class AppointmentsDeleteById extends BaseApi<Appointment> {

    public topic = 'Resources.Appointments';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/appointments/${id}`;
    }

    protected convertToResource(data: any): Appointment {
        return new Appointment(data);
    }

    public delete(next?: (value: Appointment) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class AuthRequest {

    public post(): AuthRequestPost {
        return new AuthRequestPost();
    }

}

export class AuthRequestPost extends BaseApi<any> {

    public topic = 'UnknownResource';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/auth/request/support`;
    }

    protected convertToResource(data: any): any {
        return data;
    }

    public email(value: string): AuthRequestPost {
        this.addQueryParameter('email', value);
        return this;
    }

    public first_name(value: string): AuthRequestPost {
        this.addQueryParameter('first_name', value);
        return this;
    }

    public last_name(value: string): AuthRequestPost {
        this.addQueryParameter('last_name', value);
        return this;
    }

    public save(data: any, next?: (value: any) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

class Azure {

    public sync_eventsGet(): AzureSync_eventsGet {
        return new AzureSync_eventsGet();
    }

    public sync_usersGet(): AzureSync_usersGet {
        return new AzureSync_usersGet();
    }

}

export class AzureSync_eventsGet extends BaseApi<any> {

    public topic = 'UnknownResource';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/azure/sync_events`;
    }

    protected convertToResource(data: any): any {
        return data;
    }

    public user_id(value: number): AzureSync_eventsGet {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public start(value: string): AzureSync_eventsGet {
        this.addQueryParameter('start', value);
        return this;
    }

    public end(value: string): AzureSync_eventsGet {
        this.addQueryParameter('end', value);
        return this;
    }

    public find(next?: (value: any[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | any[] | any[]> {
        return super.executeClientGet();
    }
}

export class AzureSync_usersGet extends BaseApi<any> {

    public topic = 'UnknownResource';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/azure/sync_users`;
    }

    protected convertToResource(data: any): any {
        return data;
    }

    public user_id(value: number): AzureSync_usersGet {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public start(value: string): AzureSync_usersGet {
        this.addQueryParameter('start', value);
        return this;
    }

    public end(value: string): AzureSync_usersGet {
        this.addQueryParameter('end', value);
        return this;
    }

    public find(next?: (value: any[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | any[] | any[]> {
        return super.executeClientGet();
    }
}

class Categories {

    public get(): CategoriesGet {
        return new CategoriesGet();
    }

    public getById(id: number): CategoriesGetById {
        return new CategoriesGetById(id);
    }

    public post(): CategoriesPost {
        return new CategoriesPost();
    }

    public putById(id: number): CategoriesPutById {
        return new CategoriesPutById(id);
    }

    public put(): CategoriesPut {
        return new CategoriesPut();
    }

    public patchById(id: number): CategoriesPatchById {
        return new CategoriesPatchById(id);
    }

    public patch(): CategoriesPatch {
        return new CategoriesPatch();
    }

    public deleteById(id: number): CategoriesDeleteById {
        return new CategoriesDeleteById(id);
    }

}

export class CategoriesGet extends BaseApi<Category> {

    public topic = 'Resources.Categories';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/categories`;
    }

    protected convertToResource(data: any): Category {
        return new Category(data);
    }

    public where(name: string, value: any): CategoriesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): CategoriesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): CategoriesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): CategoriesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): CategoriesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): CategoriesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): CategoriesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): CategoriesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): CategoriesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): CategoriesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): CategoriesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): CategoriesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): CategoriesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): CategoriesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): CategoriesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): CategoriesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): CategoriesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Category[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Category[] | any[]> {
        return super.executeClientGet();
    }
}

export class CategoriesGetById extends BaseApi<Category> {

    public topic = 'Resources.Categories';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/categories/${id}`;
    }

    protected convertToResource(data: any): Category {
        return new Category(data);
    }

    public include(name: string): CategoriesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Category[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Category[] | any[]> {
        return super.executeClientGet();
    }
}

export class CategoriesPost extends BaseApi<Category> {

    public topic = 'Resources.Categories';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/categories`;
    }

    protected convertToResource(data: any): Category {
        return new Category(data);
    }

    public save(data: Category, next?: (value: Category) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoriesPutById extends BaseApi<Category> {

    public topic = 'Resources.Categories';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/categories/${id}`;
    }

    protected convertToResource(data: any): Category {
        return new Category(data);
    }

    public save(data: Category, next?: (value: Category) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoriesPut extends BaseApi<Category> {

    public topic = 'Resources.Categories';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/categories`;
    }

    protected convertToResource(data: any): Category {
        return new Category(data);
    }

    public save(data: Category, next?: (value: Category) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoriesPatchById extends BaseApi<Category> {

    public topic = 'Resources.Categories';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/categories/${id}`;
    }

    protected convertToResource(data: any): Category {
        return new Category(data);
    }

    public save(data: Category, next?: (value: Category) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoriesPatch extends BaseApi<Category> {

    public topic = 'Resources.Categories';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/categories`;
    }

    protected convertToResource(data: any): Category {
        return new Category(data);
    }

    public save(data: Category, next?: (value: Category) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoriesDeleteById extends BaseApi<Category> {

    public topic = 'Resources.Categories';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/categories/${id}`;
    }

    protected convertToResource(data: any): Category {
        return new Category(data);
    }

    public delete(next?: (value: Category) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class CategoryTypes {

    public get(): CategoryTypesGet {
        return new CategoryTypesGet();
    }

    public getById(id: number): CategoryTypesGetById {
        return new CategoryTypesGetById(id);
    }

    public post(): CategoryTypesPost {
        return new CategoryTypesPost();
    }

    public putById(id: number): CategoryTypesPutById {
        return new CategoryTypesPutById(id);
    }

    public put(): CategoryTypesPut {
        return new CategoryTypesPut();
    }

    public patchById(id: number): CategoryTypesPatchById {
        return new CategoryTypesPatchById(id);
    }

    public patch(): CategoryTypesPatch {
        return new CategoryTypesPatch();
    }

    public deleteById(id: number): CategoryTypesDeleteById {
        return new CategoryTypesDeleteById(id);
    }

}

export class CategoryTypesGet extends BaseApi<CategoryType> {

    public topic = 'Resources.CategoryTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/category_types`;
    }

    protected convertToResource(data: any): CategoryType {
        return new CategoryType(data);
    }

    public where(name: string, value: any): CategoryTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): CategoryTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): CategoryTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): CategoryTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): CategoryTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): CategoryTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): CategoryTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): CategoryTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): CategoryTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): CategoryTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): CategoryTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): CategoryTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): CategoryTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): CategoryTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): CategoryTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): CategoryTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): CategoryTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: CategoryType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | CategoryType[] | any[]> {
        return super.executeClientGet();
    }
}

export class CategoryTypesGetById extends BaseApi<CategoryType> {

    public topic = 'Resources.CategoryTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/category_types/${id}`;
    }

    protected convertToResource(data: any): CategoryType {
        return new CategoryType(data);
    }

    public include(name: string): CategoryTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: CategoryType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | CategoryType[] | any[]> {
        return super.executeClientGet();
    }
}

export class CategoryTypesPost extends BaseApi<CategoryType> {

    public topic = 'Resources.CategoryTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/category_types`;
    }

    protected convertToResource(data: any): CategoryType {
        return new CategoryType(data);
    }

    public save(data: CategoryType, next?: (value: CategoryType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoryTypesPutById extends BaseApi<CategoryType> {

    public topic = 'Resources.CategoryTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/category_types/${id}`;
    }

    protected convertToResource(data: any): CategoryType {
        return new CategoryType(data);
    }

    public save(data: CategoryType, next?: (value: CategoryType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoryTypesPut extends BaseApi<CategoryType> {

    public topic = 'Resources.CategoryTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/category_types`;
    }

    protected convertToResource(data: any): CategoryType {
        return new CategoryType(data);
    }

    public save(data: CategoryType, next?: (value: CategoryType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoryTypesPatchById extends BaseApi<CategoryType> {

    public topic = 'Resources.CategoryTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/category_types/${id}`;
    }

    protected convertToResource(data: any): CategoryType {
        return new CategoryType(data);
    }

    public save(data: CategoryType, next?: (value: CategoryType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoryTypesPatch extends BaseApi<CategoryType> {

    public topic = 'Resources.CategoryTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/category_types`;
    }

    protected convertToResource(data: any): CategoryType {
        return new CategoryType(data);
    }

    public save(data: CategoryType, next?: (value: CategoryType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoryTypesDeleteById extends BaseApi<CategoryType> {

    public topic = 'Resources.CategoryTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/category_types/${id}`;
    }

    protected convertToResource(data: any): CategoryType {
        return new CategoryType(data);
    }

    public delete(next?: (value: CategoryType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class CategoryTypesMilestones {

    public get(): CategoryTypesMilestonesGet {
        return new CategoryTypesMilestonesGet();
    }

    public getById(id: number): CategoryTypesMilestonesGetById {
        return new CategoryTypesMilestonesGetById(id);
    }

    public post(): CategoryTypesMilestonesPost {
        return new CategoryTypesMilestonesPost();
    }

    public putById(id: number): CategoryTypesMilestonesPutById {
        return new CategoryTypesMilestonesPutById(id);
    }

    public put(): CategoryTypesMilestonesPut {
        return new CategoryTypesMilestonesPut();
    }

    public patchById(id: number): CategoryTypesMilestonesPatchById {
        return new CategoryTypesMilestonesPatchById(id);
    }

    public patch(): CategoryTypesMilestonesPatch {
        return new CategoryTypesMilestonesPatch();
    }

    public deleteById(id: number): CategoryTypesMilestonesDeleteById {
        return new CategoryTypesMilestonesDeleteById(id);
    }

}

export class CategoryTypesMilestonesGet extends BaseApi<CategoryTypesMilestone> {

    public topic = 'Resources.CategoryTypesMilestones';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/category_types_milestones`;
    }

    protected convertToResource(data: any): CategoryTypesMilestone {
        return new CategoryTypesMilestone(data);
    }

    public where(name: string, value: any): CategoryTypesMilestonesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): CategoryTypesMilestonesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): CategoryTypesMilestonesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): CategoryTypesMilestonesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): CategoryTypesMilestonesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): CategoryTypesMilestonesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): CategoryTypesMilestonesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): CategoryTypesMilestonesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): CategoryTypesMilestonesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): CategoryTypesMilestonesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): CategoryTypesMilestonesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): CategoryTypesMilestonesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): CategoryTypesMilestonesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): CategoryTypesMilestonesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): CategoryTypesMilestonesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): CategoryTypesMilestonesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): CategoryTypesMilestonesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: CategoryTypesMilestone[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | CategoryTypesMilestone[] | any[]> {
        return super.executeClientGet();
    }
}

export class CategoryTypesMilestonesGetById extends BaseApi<CategoryTypesMilestone> {

    public topic = 'Resources.CategoryTypesMilestones';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/category_types_milestones/${id}`;
    }

    protected convertToResource(data: any): CategoryTypesMilestone {
        return new CategoryTypesMilestone(data);
    }

    public include(name: string): CategoryTypesMilestonesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: CategoryTypesMilestone[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | CategoryTypesMilestone[] | any[]> {
        return super.executeClientGet();
    }
}

export class CategoryTypesMilestonesPost extends BaseApi<CategoryTypesMilestone> {

    public topic = 'Resources.CategoryTypesMilestones';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/category_types_milestones`;
    }

    protected convertToResource(data: any): CategoryTypesMilestone {
        return new CategoryTypesMilestone(data);
    }

    public save(data: CategoryTypesMilestone, next?: (value: CategoryTypesMilestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoryTypesMilestonesPutById extends BaseApi<CategoryTypesMilestone> {

    public topic = 'Resources.CategoryTypesMilestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/category_types_milestones/${id}`;
    }

    protected convertToResource(data: any): CategoryTypesMilestone {
        return new CategoryTypesMilestone(data);
    }

    public save(data: CategoryTypesMilestone, next?: (value: CategoryTypesMilestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoryTypesMilestonesPut extends BaseApi<CategoryTypesMilestone> {

    public topic = 'Resources.CategoryTypesMilestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/category_types_milestones`;
    }

    protected convertToResource(data: any): CategoryTypesMilestone {
        return new CategoryTypesMilestone(data);
    }

    public save(data: CategoryTypesMilestone, next?: (value: CategoryTypesMilestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoryTypesMilestonesPatchById extends BaseApi<CategoryTypesMilestone> {

    public topic = 'Resources.CategoryTypesMilestones';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/category_types_milestones/${id}`;
    }

    protected convertToResource(data: any): CategoryTypesMilestone {
        return new CategoryTypesMilestone(data);
    }

    public save(data: CategoryTypesMilestone, next?: (value: CategoryTypesMilestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoryTypesMilestonesPatch extends BaseApi<CategoryTypesMilestone> {

    public topic = 'Resources.CategoryTypesMilestones';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/category_types_milestones`;
    }

    protected convertToResource(data: any): CategoryTypesMilestone {
        return new CategoryTypesMilestone(data);
    }

    public save(data: CategoryTypesMilestone, next?: (value: CategoryTypesMilestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class CategoryTypesMilestonesDeleteById extends BaseApi<CategoryTypesMilestone> {

    public topic = 'Resources.CategoryTypesMilestones';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/category_types_milestones/${id}`;
    }

    protected convertToResource(data: any): CategoryTypesMilestone {
        return new CategoryTypesMilestone(data);
    }

    public delete(next?: (value: CategoryTypesMilestone) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Colors {

    public get(): ColorsGet {
        return new ColorsGet();
    }

    public getById(id: number): ColorsGetById {
        return new ColorsGetById(id);
    }

    public post(): ColorsPost {
        return new ColorsPost();
    }

    public putById(id: number): ColorsPutById {
        return new ColorsPutById(id);
    }

    public put(): ColorsPut {
        return new ColorsPut();
    }

    public patchById(id: number): ColorsPatchById {
        return new ColorsPatchById(id);
    }

    public patch(): ColorsPatch {
        return new ColorsPatch();
    }

    public deleteById(id: number): ColorsDeleteById {
        return new ColorsDeleteById(id);
    }

}

export class ColorsGet extends BaseApi<Color> {

    public topic = 'Resources.Colors';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/colors`;
    }

    protected convertToResource(data: any): Color {
        return new Color(data);
    }

    public where(name: string, value: any): ColorsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): ColorsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): ColorsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): ColorsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): ColorsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): ColorsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): ColorsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): ColorsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): ColorsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): ColorsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): ColorsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): ColorsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): ColorsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): ColorsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): ColorsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): ColorsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): ColorsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Color[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Color[] | any[]> {
        return super.executeClientGet();
    }
}

export class ColorsGetById extends BaseApi<Color> {

    public topic = 'Resources.Colors';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/colors/${id}`;
    }

    protected convertToResource(data: any): Color {
        return new Color(data);
    }

    public include(name: string): ColorsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Color[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Color[] | any[]> {
        return super.executeClientGet();
    }
}

export class ColorsPost extends BaseApi<Color> {

    public topic = 'Resources.Colors';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/colors`;
    }

    protected convertToResource(data: any): Color {
        return new Color(data);
    }

    public save(data: Color, next?: (value: Color) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ColorsPutById extends BaseApi<Color> {

    public topic = 'Resources.Colors';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/colors/${id}`;
    }

    protected convertToResource(data: any): Color {
        return new Color(data);
    }

    public save(data: Color, next?: (value: Color) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ColorsPut extends BaseApi<Color> {

    public topic = 'Resources.Colors';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/colors`;
    }

    protected convertToResource(data: any): Color {
        return new Color(data);
    }

    public save(data: Color, next?: (value: Color) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ColorsPatchById extends BaseApi<Color> {

    public topic = 'Resources.Colors';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/colors/${id}`;
    }

    protected convertToResource(data: any): Color {
        return new Color(data);
    }

    public save(data: Color, next?: (value: Color) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ColorsPatch extends BaseApi<Color> {

    public topic = 'Resources.Colors';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/colors`;
    }

    protected convertToResource(data: any): Color {
        return new Color(data);
    }

    public save(data: Color, next?: (value: Color) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ColorsDeleteById extends BaseApi<Color> {

    public topic = 'Resources.Colors';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/colors/${id}`;
    }

    protected convertToResource(data: any): Color {
        return new Color(data);
    }

    public delete(next?: (value: Color) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Deletions {

    public get(): DeletionsGet {
        return new DeletionsGet();
    }

    public getById(id: number): DeletionsGetById {
        return new DeletionsGetById(id);
    }

    public restorePutById(id: number): DeletionsRestorePutById {
        return new DeletionsRestorePutById(id);
    }

}

export class DeletionsGet extends BaseApi<Deletion> {

    public topic = 'Resources.Deletions';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/deletions`;
    }

    protected convertToResource(data: any): Deletion {
        return new Deletion(data);
    }

    public where(name: string, value: any): DeletionsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): DeletionsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): DeletionsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): DeletionsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): DeletionsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): DeletionsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): DeletionsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): DeletionsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): DeletionsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): DeletionsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): DeletionsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): DeletionsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): DeletionsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): DeletionsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): DeletionsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): DeletionsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): DeletionsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Deletion[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Deletion[] | any[]> {
        return super.executeClientGet();
    }
}

export class DeletionsGetById extends BaseApi<Deletion> {

    public topic = 'Resources.Deletions';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/deletions/${id}`;
    }

    protected convertToResource(data: any): Deletion {
        return new Deletion(data);
    }

    public include(name: string): DeletionsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Deletion[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Deletion[] | any[]> {
        return super.executeClientGet();
    }
}

export class DeletionsRestorePutById extends BaseApi<Deletion> {

    public topic = 'Resources.Deletions';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/deletions/${id}/restore`;
    }

    protected convertToResource(data: any): Deletion {
        return new Deletion(data);
    }

    public save(data: any, next?: (value: Deletion) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

class DepartmentGroups {

    public get(): DepartmentGroupsGet {
        return new DepartmentGroupsGet();
    }

    public getById(id: number): DepartmentGroupsGetById {
        return new DepartmentGroupsGetById(id);
    }

    public post(): DepartmentGroupsPost {
        return new DepartmentGroupsPost();
    }

    public putById(id: number): DepartmentGroupsPutById {
        return new DepartmentGroupsPutById(id);
    }

    public put(): DepartmentGroupsPut {
        return new DepartmentGroupsPut();
    }

    public patchById(id: number): DepartmentGroupsPatchById {
        return new DepartmentGroupsPatchById(id);
    }

    public patch(): DepartmentGroupsPatch {
        return new DepartmentGroupsPatch();
    }

    public deleteById(id: number): DepartmentGroupsDeleteById {
        return new DepartmentGroupsDeleteById(id);
    }

}

export class DepartmentGroupsGet extends BaseApi<DepartmentGroup> {

    public topic = 'Resources.DepartmentGroups';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/department_groups`;
    }

    protected convertToResource(data: any): DepartmentGroup {
        return new DepartmentGroup(data);
    }

    public where(name: string, value: any): DepartmentGroupsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): DepartmentGroupsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): DepartmentGroupsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): DepartmentGroupsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): DepartmentGroupsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): DepartmentGroupsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): DepartmentGroupsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): DepartmentGroupsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): DepartmentGroupsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): DepartmentGroupsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): DepartmentGroupsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): DepartmentGroupsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): DepartmentGroupsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): DepartmentGroupsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): DepartmentGroupsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): DepartmentGroupsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): DepartmentGroupsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: DepartmentGroup[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | DepartmentGroup[] | any[]> {
        return super.executeClientGet();
    }
}

export class DepartmentGroupsGetById extends BaseApi<DepartmentGroup> {

    public topic = 'Resources.DepartmentGroups';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/department_groups/${id}`;
    }

    protected convertToResource(data: any): DepartmentGroup {
        return new DepartmentGroup(data);
    }

    public include(name: string): DepartmentGroupsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: DepartmentGroup[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | DepartmentGroup[] | any[]> {
        return super.executeClientGet();
    }
}

export class DepartmentGroupsPost extends BaseApi<DepartmentGroup> {

    public topic = 'Resources.DepartmentGroups';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/department_groups`;
    }

    protected convertToResource(data: any): DepartmentGroup {
        return new DepartmentGroup(data);
    }

    public save(data: DepartmentGroup, next?: (value: DepartmentGroup) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DepartmentGroupsPutById extends BaseApi<DepartmentGroup> {

    public topic = 'Resources.DepartmentGroups';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/department_groups/${id}`;
    }

    protected convertToResource(data: any): DepartmentGroup {
        return new DepartmentGroup(data);
    }

    public save(data: DepartmentGroup, next?: (value: DepartmentGroup) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DepartmentGroupsPut extends BaseApi<DepartmentGroup> {

    public topic = 'Resources.DepartmentGroups';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/department_groups`;
    }

    protected convertToResource(data: any): DepartmentGroup {
        return new DepartmentGroup(data);
    }

    public save(data: DepartmentGroup, next?: (value: DepartmentGroup) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DepartmentGroupsPatchById extends BaseApi<DepartmentGroup> {

    public topic = 'Resources.DepartmentGroups';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/department_groups/${id}`;
    }

    protected convertToResource(data: any): DepartmentGroup {
        return new DepartmentGroup(data);
    }

    public save(data: DepartmentGroup, next?: (value: DepartmentGroup) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DepartmentGroupsPatch extends BaseApi<DepartmentGroup> {

    public topic = 'Resources.DepartmentGroups';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/department_groups`;
    }

    protected convertToResource(data: any): DepartmentGroup {
        return new DepartmentGroup(data);
    }

    public save(data: DepartmentGroup, next?: (value: DepartmentGroup) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DepartmentGroupsDeleteById extends BaseApi<DepartmentGroup> {

    public topic = 'Resources.DepartmentGroups';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/department_groups/${id}`;
    }

    protected convertToResource(data: any): DepartmentGroup {
        return new DepartmentGroup(data);
    }

    public delete(next?: (value: DepartmentGroup) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Departments {

    public get(): DepartmentsGet {
        return new DepartmentsGet();
    }

    public getById(id: number): DepartmentsGetById {
        return new DepartmentsGetById(id);
    }

    public post(): DepartmentsPost {
        return new DepartmentsPost();
    }

    public putById(id: number): DepartmentsPutById {
        return new DepartmentsPutById(id);
    }

    public put(): DepartmentsPut {
        return new DepartmentsPut();
    }

    public patchById(id: number): DepartmentsPatchById {
        return new DepartmentsPatchById(id);
    }

    public patch(): DepartmentsPatch {
        return new DepartmentsPatch();
    }

    public deleteById(id: number): DepartmentsDeleteById {
        return new DepartmentsDeleteById(id);
    }

    public handUpDeleteDeleteById(id: number): DepartmentsHandUpDeleteDeleteById {
        return new DepartmentsHandUpDeleteDeleteById(id);
    }

}

export class DepartmentsGet extends BaseApi<Department> {

    public topic = 'Resources.Departments';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/departments`;
    }

    protected convertToResource(data: any): Department {
        return new Department(data);
    }

    public where(name: string, value: any): DepartmentsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): DepartmentsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): DepartmentsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): DepartmentsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): DepartmentsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): DepartmentsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): DepartmentsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): DepartmentsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): DepartmentsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): DepartmentsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): DepartmentsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): DepartmentsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): DepartmentsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): DepartmentsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): DepartmentsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): DepartmentsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): DepartmentsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Department[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Department[] | any[]> {
        return super.executeClientGet();
    }
}

export class DepartmentsGetById extends BaseApi<Department> {

    public topic = 'Resources.Departments';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/departments/${id}`;
    }

    protected convertToResource(data: any): Department {
        return new Department(data);
    }

    public include(name: string): DepartmentsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Department[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Department[] | any[]> {
        return super.executeClientGet();
    }
}

export class DepartmentsPost extends BaseApi<Department> {

    public topic = 'Resources.Departments';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/departments`;
    }

    protected convertToResource(data: any): Department {
        return new Department(data);
    }

    public save(data: Department, next?: (value: Department) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DepartmentsPutById extends BaseApi<Department> {

    public topic = 'Resources.Departments';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/departments/${id}`;
    }

    protected convertToResource(data: any): Department {
        return new Department(data);
    }

    public save(data: Department, next?: (value: Department) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DepartmentsPut extends BaseApi<Department> {

    public topic = 'Resources.Departments';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/departments`;
    }

    protected convertToResource(data: any): Department {
        return new Department(data);
    }

    public save(data: Department, next?: (value: Department) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DepartmentsPatchById extends BaseApi<Department> {

    public topic = 'Resources.Departments';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/departments/${id}`;
    }

    protected convertToResource(data: any): Department {
        return new Department(data);
    }

    public save(data: Department, next?: (value: Department) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DepartmentsPatch extends BaseApi<Department> {

    public topic = 'Resources.Departments';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/departments`;
    }

    protected convertToResource(data: any): Department {
        return new Department(data);
    }

    public save(data: Department, next?: (value: Department) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DepartmentsDeleteById extends BaseApi<Department> {

    public topic = 'Resources.Departments';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/departments/${id}`;
    }

    protected convertToResource(data: any): Department {
        return new Department(data);
    }

    public delete(next?: (value: Department) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class DepartmentsHandUpDeleteDeleteById extends BaseApi<Department> {

    public topic = 'Resources.Departments';
    protected method = 'delete';
    protected scope = '';
    protected summary = 'Remove hands from all projects, tasks and milestones for this department';

    public constructor(id: number) {
        super();
        this.uri = `/departments/${id}/hand_up`;
    }

    protected convertToResource(data: any): Department {
        return new Department(data);
    }

    public delete(next?: (value: Department) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class DisplayFilters {

    public get(): DisplayFiltersGet {
        return new DisplayFiltersGet();
    }

    public getById(id: number): DisplayFiltersGetById {
        return new DisplayFiltersGetById(id);
    }

    public post(): DisplayFiltersPost {
        return new DisplayFiltersPost();
    }

    public putById(id: number): DisplayFiltersPutById {
        return new DisplayFiltersPutById(id);
    }

    public put(): DisplayFiltersPut {
        return new DisplayFiltersPut();
    }

    public patchById(id: number): DisplayFiltersPatchById {
        return new DisplayFiltersPatchById(id);
    }

    public patch(): DisplayFiltersPatch {
        return new DisplayFiltersPatch();
    }

    public deleteById(id: number): DisplayFiltersDeleteById {
        return new DisplayFiltersDeleteById(id);
    }

    public addFavoritePutById(id: number): DisplayFiltersAddFavoritePutById {
        return new DisplayFiltersAddFavoritePutById(id);
    }

    public removeFavoriteDeleteById(id: number): DisplayFiltersRemoveFavoriteDeleteById {
        return new DisplayFiltersRemoveFavoriteDeleteById(id);
    }

    public indexingPut(): DisplayFiltersIndexingPut {
        return new DisplayFiltersIndexingPut();
    }

    public markLastUsedPutById(id: number): DisplayFiltersMarkLastUsedPutById {
        return new DisplayFiltersMarkLastUsedPutById(id);
    }

    public markDefaultPutById(id: number): DisplayFiltersMarkDefaultPutById {
        return new DisplayFiltersMarkDefaultPutById(id);
    }

    public markVisiblePutById(id: number): DisplayFiltersMarkVisiblePutById {
        return new DisplayFiltersMarkVisiblePutById(id);
    }

}

export class DisplayFiltersGet extends BaseApi<DisplayFilter> {

    public topic = 'Resources.DisplayFilters';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/display_filters`;
    }

    protected convertToResource(data: any): DisplayFilter {
        return new DisplayFilter(data);
    }

    public where(name: string, value: any): DisplayFiltersGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): DisplayFiltersGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): DisplayFiltersGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): DisplayFiltersGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): DisplayFiltersGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): DisplayFiltersGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): DisplayFiltersGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): DisplayFiltersGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): DisplayFiltersGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): DisplayFiltersGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): DisplayFiltersGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): DisplayFiltersGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): DisplayFiltersGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): DisplayFiltersGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): DisplayFiltersGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): DisplayFiltersGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): DisplayFiltersGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: DisplayFilter[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | DisplayFilter[] | any[]> {
        return super.executeClientGet();
    }
}

export class DisplayFiltersGetById extends BaseApi<DisplayFilter> {

    public topic = 'Resources.DisplayFilters';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/display_filters/${id}`;
    }

    protected convertToResource(data: any): DisplayFilter {
        return new DisplayFilter(data);
    }

    public include(name: string): DisplayFiltersGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: DisplayFilter[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | DisplayFilter[] | any[]> {
        return super.executeClientGet();
    }
}

export class DisplayFiltersPost extends BaseApi<DisplayFilter> {

    public topic = 'Resources.DisplayFilters';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/display_filters`;
    }

    protected convertToResource(data: any): DisplayFilter {
        return new DisplayFilter(data);
    }

    public save(data: DisplayFilter, next?: (value: DisplayFilter) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayFiltersPutById extends BaseApi<DisplayFilter> {

    public topic = 'Resources.DisplayFilters';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/display_filters/${id}`;
    }

    protected convertToResource(data: any): DisplayFilter {
        return new DisplayFilter(data);
    }

    public save(data: DisplayFilter, next?: (value: DisplayFilter) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayFiltersPut extends BaseApi<DisplayFilter> {

    public topic = 'Resources.DisplayFilters';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/display_filters`;
    }

    protected convertToResource(data: any): DisplayFilter {
        return new DisplayFilter(data);
    }

    public save(data: DisplayFilter, next?: (value: DisplayFilter) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayFiltersPatchById extends BaseApi<DisplayFilter> {

    public topic = 'Resources.DisplayFilters';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/display_filters/${id}`;
    }

    protected convertToResource(data: any): DisplayFilter {
        return new DisplayFilter(data);
    }

    public save(data: DisplayFilter, next?: (value: DisplayFilter) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayFiltersPatch extends BaseApi<DisplayFilter> {

    public topic = 'Resources.DisplayFilters';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/display_filters`;
    }

    protected convertToResource(data: any): DisplayFilter {
        return new DisplayFilter(data);
    }

    public save(data: DisplayFilter, next?: (value: DisplayFilter) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayFiltersDeleteById extends BaseApi<DisplayFilter> {

    public topic = 'Resources.DisplayFilters';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/display_filters/${id}`;
    }

    protected convertToResource(data: any): DisplayFilter {
        return new DisplayFilter(data);
    }

    public delete(next?: (value: DisplayFilter) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class DisplayFiltersAddFavoritePutById extends BaseApi<DisplayFilterUserSetting> {

    public topic = 'Resources.DisplayFilterUserSettings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/display_filters/${id}/favorite`;
    }

    protected convertToResource(data: any): DisplayFilterUserSetting {
        return new DisplayFilterUserSetting(data);
    }

    public departmentId(value: number): DisplayFiltersAddFavoritePutById {
        this.addQueryParameter('departmentId', value);
        return this;
    }

    public displayId(value: number): DisplayFiltersAddFavoritePutById {
        this.addQueryParameter('displayId', value);
        return this;
    }

    public displayIdentifier(value: string): DisplayFiltersAddFavoritePutById {
        this.addQueryParameter('displayIdentifier', value);
        return this;
    }

    public save(data: any, next?: (value: DisplayFilterUserSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayFiltersRemoveFavoriteDeleteById extends BaseApi<DisplayFilterUserSetting> {

    public topic = 'Resources.DisplayFilterUserSettings';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/display_filters/${id}/favorite`;
    }

    protected convertToResource(data: any): DisplayFilterUserSetting {
        return new DisplayFilterUserSetting(data);
    }

    public departmentId(value: number): DisplayFiltersRemoveFavoriteDeleteById {
        this.addQueryParameter('departmentId', value);
        return this;
    }

    public displayId(value: number): DisplayFiltersRemoveFavoriteDeleteById {
        this.addQueryParameter('displayId', value);
        return this;
    }

    public displayIdentifier(value: string): DisplayFiltersRemoveFavoriteDeleteById {
        this.addQueryParameter('displayIdentifier', value);
        return this;
    }

    public delete(next?: (value: DisplayFilterUserSetting) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class DisplayFiltersIndexingPut extends BaseApi<DisplayFilterUserSetting> {

    public topic = 'Resources.DisplayFilterUserSettings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/display_filters/indexing`;
    }

    protected convertToResource(data: any): DisplayFilterUserSetting {
        return new DisplayFilterUserSetting(data);
    }

    public departmentId(value: number): DisplayFiltersIndexingPut {
        this.addQueryParameter('departmentId', value);
        return this;
    }

    public displayId(value: number): DisplayFiltersIndexingPut {
        this.addQueryParameter('displayId', value);
        return this;
    }

    public displayIdentifier(value: string): DisplayFiltersIndexingPut {
        this.addQueryParameter('displayIdentifier', value);
        return this;
    }

    public save(data: IntArrayInterface, next?: (value: DisplayFilterUserSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayFiltersMarkLastUsedPutById extends BaseApi<DisplayFilterUserSetting> {

    public topic = 'Resources.DisplayFilterUserSettings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/display_filters/${id}/last_used`;
    }

    protected convertToResource(data: any): DisplayFilterUserSetting {
        return new DisplayFilterUserSetting(data);
    }

    public departmentId(value: number): DisplayFiltersMarkLastUsedPutById {
        this.addQueryParameter('departmentId', value);
        return this;
    }

    public displayId(value: number): DisplayFiltersMarkLastUsedPutById {
        this.addQueryParameter('displayId', value);
        return this;
    }

    public displayIdentifier(value: string): DisplayFiltersMarkLastUsedPutById {
        this.addQueryParameter('displayIdentifier', value);
        return this;
    }

    public save(data: any, next?: (value: DisplayFilterUserSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayFiltersMarkDefaultPutById extends BaseApi<DisplayFilterUserSetting> {

    public topic = 'Resources.DisplayFilterUserSettings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/display_filters/${id}/default`;
    }

    protected convertToResource(data: any): DisplayFilterUserSetting {
        return new DisplayFilterUserSetting(data);
    }

    public departmentId(value: number): DisplayFiltersMarkDefaultPutById {
        this.addQueryParameter('departmentId', value);
        return this;
    }

    public displayId(value: number): DisplayFiltersMarkDefaultPutById {
        this.addQueryParameter('displayId', value);
        return this;
    }

    public displayIdentifier(value: string): DisplayFiltersMarkDefaultPutById {
        this.addQueryParameter('displayIdentifier', value);
        return this;
    }

    public value(value: boolean): DisplayFiltersMarkDefaultPutById {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: DisplayFilterUserSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayFiltersMarkVisiblePutById extends BaseApi<DisplayFilterUserSetting> {

    public topic = 'Resources.DisplayFilterUserSettings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/display_filters/${id}/visible`;
    }

    protected convertToResource(data: any): DisplayFilterUserSetting {
        return new DisplayFilterUserSetting(data);
    }

    public departmentId(value: number): DisplayFiltersMarkVisiblePutById {
        this.addQueryParameter('departmentId', value);
        return this;
    }

    public displayId(value: number): DisplayFiltersMarkVisiblePutById {
        this.addQueryParameter('displayId', value);
        return this;
    }

    public displayIdentifier(value: string): DisplayFiltersMarkVisiblePutById {
        this.addQueryParameter('displayIdentifier', value);
        return this;
    }

    public value(value: boolean): DisplayFiltersMarkVisiblePutById {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: DisplayFilterUserSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

class DisplayTypes {

    public get(): DisplayTypesGet {
        return new DisplayTypesGet();
    }

    public getById(id: number): DisplayTypesGetById {
        return new DisplayTypesGetById(id);
    }

    public post(): DisplayTypesPost {
        return new DisplayTypesPost();
    }

    public putById(id: number): DisplayTypesPutById {
        return new DisplayTypesPutById(id);
    }

    public put(): DisplayTypesPut {
        return new DisplayTypesPut();
    }

    public patchById(id: number): DisplayTypesPatchById {
        return new DisplayTypesPatchById(id);
    }

    public patch(): DisplayTypesPatch {
        return new DisplayTypesPatch();
    }

    public deleteById(id: number): DisplayTypesDeleteById {
        return new DisplayTypesDeleteById(id);
    }

}

export class DisplayTypesGet extends BaseApi<DisplayType> {

    public topic = 'Resources.DisplayTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/display_types`;
    }

    protected convertToResource(data: any): DisplayType {
        return new DisplayType(data);
    }

    public where(name: string, value: any): DisplayTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): DisplayTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): DisplayTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): DisplayTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): DisplayTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): DisplayTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): DisplayTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): DisplayTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): DisplayTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): DisplayTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): DisplayTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): DisplayTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): DisplayTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): DisplayTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): DisplayTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): DisplayTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): DisplayTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: DisplayType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | DisplayType[] | any[]> {
        return super.executeClientGet();
    }
}

export class DisplayTypesGetById extends BaseApi<DisplayType> {

    public topic = 'Resources.DisplayTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/display_types/${id}`;
    }

    protected convertToResource(data: any): DisplayType {
        return new DisplayType(data);
    }

    public include(name: string): DisplayTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: DisplayType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | DisplayType[] | any[]> {
        return super.executeClientGet();
    }
}

export class DisplayTypesPost extends BaseApi<DisplayType> {

    public topic = 'Resources.DisplayTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/display_types`;
    }

    protected convertToResource(data: any): DisplayType {
        return new DisplayType(data);
    }

    public save(data: DisplayType, next?: (value: DisplayType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayTypesPutById extends BaseApi<DisplayType> {

    public topic = 'Resources.DisplayTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/display_types/${id}`;
    }

    protected convertToResource(data: any): DisplayType {
        return new DisplayType(data);
    }

    public save(data: DisplayType, next?: (value: DisplayType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayTypesPut extends BaseApi<DisplayType> {

    public topic = 'Resources.DisplayTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/display_types`;
    }

    protected convertToResource(data: any): DisplayType {
        return new DisplayType(data);
    }

    public save(data: DisplayType, next?: (value: DisplayType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayTypesPatchById extends BaseApi<DisplayType> {

    public topic = 'Resources.DisplayTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/display_types/${id}`;
    }

    protected convertToResource(data: any): DisplayType {
        return new DisplayType(data);
    }

    public save(data: DisplayType, next?: (value: DisplayType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayTypesPatch extends BaseApi<DisplayType> {

    public topic = 'Resources.DisplayTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/display_types`;
    }

    protected convertToResource(data: any): DisplayType {
        return new DisplayType(data);
    }

    public save(data: DisplayType, next?: (value: DisplayType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplayTypesDeleteById extends BaseApi<DisplayType> {

    public topic = 'Resources.DisplayTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/display_types/${id}`;
    }

    protected convertToResource(data: any): DisplayType {
        return new DisplayType(data);
    }

    public delete(next?: (value: DisplayType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Displays {

    public get(): DisplaysGet {
        return new DisplaysGet();
    }

    public getById(id: number): DisplaysGetById {
        return new DisplaysGetById(id);
    }

    public post(): DisplaysPost {
        return new DisplaysPost();
    }

    public putById(id: number): DisplaysPutById {
        return new DisplaysPutById(id);
    }

    public put(): DisplaysPut {
        return new DisplaysPut();
    }

    public patchById(id: number): DisplaysPatchById {
        return new DisplaysPatchById(id);
    }

    public patch(): DisplaysPatch {
        return new DisplaysPatch();
    }

    public deleteById(id: number): DisplaysDeleteById {
        return new DisplaysDeleteById(id);
    }

    public filtersGetGetByDisplayIdByDepartmentId(displayId: number, departmentId: number): DisplaysFiltersGetGetByDisplayIdByDepartmentId {
        return new DisplaysFiltersGetGetByDisplayIdByDepartmentId(displayId, departmentId);
    }

    public columnsGetGetByDisplayId(displayId: number): DisplaysColumnsGetGetByDisplayId {
        return new DisplaysColumnsGetGetByDisplayId(displayId);
    }

    public updateTeamDisplayUserPositionsPutByDisplayIdByDepartmentId(displayId: number, departmentId: number): DisplaysUpdateTeamDisplayUserPositionsPutByDisplayIdByDepartmentId {
        return new DisplaysUpdateTeamDisplayUserPositionsPutByDisplayIdByDepartmentId(displayId, departmentId);
    }

}

export class DisplaysGet extends BaseApi<Display> {

    public topic = 'Resources.Displays';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/displays`;
    }

    protected convertToResource(data: any): Display {
        return new Display(data);
    }

    public where(name: string, value: any): DisplaysGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): DisplaysGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): DisplaysGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): DisplaysGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): DisplaysGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): DisplaysGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): DisplaysGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): DisplaysGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): DisplaysGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): DisplaysGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): DisplaysGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): DisplaysGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): DisplaysGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): DisplaysGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): DisplaysGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): DisplaysGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): DisplaysGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Display[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Display[] | any[]> {
        return super.executeClientGet();
    }
}

export class DisplaysGetById extends BaseApi<Display> {

    public topic = 'Resources.Displays';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/displays/${id}`;
    }

    protected convertToResource(data: any): Display {
        return new Display(data);
    }

    public include(name: string): DisplaysGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Display[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Display[] | any[]> {
        return super.executeClientGet();
    }
}

export class DisplaysPost extends BaseApi<Display> {

    public topic = 'Resources.Displays';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/displays`;
    }

    protected convertToResource(data: any): Display {
        return new Display(data);
    }

    public save(data: Display, next?: (value: Display) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplaysPutById extends BaseApi<Display> {

    public topic = 'Resources.Displays';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/displays/${id}`;
    }

    protected convertToResource(data: any): Display {
        return new Display(data);
    }

    public save(data: Display, next?: (value: Display) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplaysPut extends BaseApi<Display> {

    public topic = 'Resources.Displays';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/displays`;
    }

    protected convertToResource(data: any): Display {
        return new Display(data);
    }

    public save(data: Display, next?: (value: Display) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplaysPatchById extends BaseApi<Display> {

    public topic = 'Resources.Displays';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/displays/${id}`;
    }

    protected convertToResource(data: any): Display {
        return new Display(data);
    }

    public save(data: Display, next?: (value: Display) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplaysPatch extends BaseApi<Display> {

    public topic = 'Resources.Displays';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/displays`;
    }

    protected convertToResource(data: any): Display {
        return new Display(data);
    }

    public save(data: Display, next?: (value: Display) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplaysDeleteById extends BaseApi<Display> {

    public topic = 'Resources.Displays';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/displays/${id}`;
    }

    protected convertToResource(data: any): Display {
        return new Display(data);
    }

    public delete(next?: (value: Display) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class DisplaysFiltersGetGetByDisplayIdByDepartmentId extends BaseApi<DisplayFilter> {

    public topic = 'Resources.DisplayFilters';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(displayId: number, departmentId: number) {
        super();
        this.uri = `/displays/${displayId}/filters/${departmentId}`;
    }

    protected convertToResource(data: any): DisplayFilter {
        return new DisplayFilter(data);
    }

    public displayIdentifier(value: string): DisplaysFiltersGetGetByDisplayIdByDepartmentId {
        this.addQueryParameter('displayIdentifier', value);
        return this;
    }

    public find(next?: (value: DisplayFilter[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | DisplayFilter[] | any[]> {
        return super.executeClientGet();
    }
}

export class DisplaysColumnsGetGetByDisplayId extends BaseApi<Column> {

    public topic = 'Resources.Columns';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(displayId: number) {
        super();
        this.uri = `/displays/${displayId}/columns`;
    }

    protected convertToResource(data: any): Column {
        return new Column(data);
    }

    public find(next?: (value: Column[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Column[] | any[]> {
        return super.executeClientGet();
    }
}

export class DisplaysUpdateTeamDisplayUserPositionsPutByDisplayIdByDepartmentId extends BaseApi<TeamDisplayUserPosition> {

    public topic = 'Resources.TeamDisplayUserPositions';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(displayId: number, departmentId: number) {
        super();
        this.uri = `/displays/${displayId}/team-display/${departmentId}/user-positions`;
    }

    protected convertToResource(data: any): TeamDisplayUserPosition {
        return new TeamDisplayUserPosition(data);
    }

    public save(data: IntArrayInterface, next?: (value: TeamDisplayUserPosition) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

class DisplaysSettings {

    public get(): DisplaysSettingsGet {
        return new DisplaysSettingsGet();
    }

    public getById(id: number): DisplaysSettingsGetById {
        return new DisplaysSettingsGetById(id);
    }

    public post(): DisplaysSettingsPost {
        return new DisplaysSettingsPost();
    }

    public putById(id: number): DisplaysSettingsPutById {
        return new DisplaysSettingsPutById(id);
    }

    public put(): DisplaysSettingsPut {
        return new DisplaysSettingsPut();
    }

    public patchById(id: number): DisplaysSettingsPatchById {
        return new DisplaysSettingsPatchById(id);
    }

    public patch(): DisplaysSettingsPatch {
        return new DisplaysSettingsPatch();
    }

    public deleteById(id: number): DisplaysSettingsDeleteById {
        return new DisplaysSettingsDeleteById(id);
    }

}

export class DisplaysSettingsGet extends BaseApi<DisplaysSetting> {

    public topic = 'Resources.DisplaysSettings';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/displays_settings`;
    }

    protected convertToResource(data: any): DisplaysSetting {
        return new DisplaysSetting(data);
    }

    public where(name: string, value: any): DisplaysSettingsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): DisplaysSettingsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): DisplaysSettingsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): DisplaysSettingsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): DisplaysSettingsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): DisplaysSettingsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): DisplaysSettingsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): DisplaysSettingsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): DisplaysSettingsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): DisplaysSettingsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): DisplaysSettingsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): DisplaysSettingsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): DisplaysSettingsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): DisplaysSettingsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): DisplaysSettingsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): DisplaysSettingsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): DisplaysSettingsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: DisplaysSetting[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | DisplaysSetting[] | any[]> {
        return super.executeClientGet();
    }
}

export class DisplaysSettingsGetById extends BaseApi<DisplaysSetting> {

    public topic = 'Resources.DisplaysSettings';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/displays_settings/${id}`;
    }

    protected convertToResource(data: any): DisplaysSetting {
        return new DisplaysSetting(data);
    }

    public include(name: string): DisplaysSettingsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: DisplaysSetting[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | DisplaysSetting[] | any[]> {
        return super.executeClientGet();
    }
}

export class DisplaysSettingsPost extends BaseApi<DisplaysSetting> {

    public topic = 'Resources.DisplaysSettings';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/displays_settings`;
    }

    protected convertToResource(data: any): DisplaysSetting {
        return new DisplaysSetting(data);
    }

    public save(data: DisplaysSetting, next?: (value: DisplaysSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplaysSettingsPutById extends BaseApi<DisplaysSetting> {

    public topic = 'Resources.DisplaysSettings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/displays_settings/${id}`;
    }

    protected convertToResource(data: any): DisplaysSetting {
        return new DisplaysSetting(data);
    }

    public save(data: DisplaysSetting, next?: (value: DisplaysSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplaysSettingsPut extends BaseApi<DisplaysSetting> {

    public topic = 'Resources.DisplaysSettings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/displays_settings`;
    }

    protected convertToResource(data: any): DisplaysSetting {
        return new DisplaysSetting(data);
    }

    public save(data: DisplaysSetting, next?: (value: DisplaysSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplaysSettingsPatchById extends BaseApi<DisplaysSetting> {

    public topic = 'Resources.DisplaysSettings';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/displays_settings/${id}`;
    }

    protected convertToResource(data: any): DisplaysSetting {
        return new DisplaysSetting(data);
    }

    public save(data: DisplaysSetting, next?: (value: DisplaysSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplaysSettingsPatch extends BaseApi<DisplaysSetting> {

    public topic = 'Resources.DisplaysSettings';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/displays_settings`;
    }

    protected convertToResource(data: any): DisplaysSetting {
        return new DisplaysSetting(data);
    }

    public save(data: DisplaysSetting, next?: (value: DisplaysSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DisplaysSettingsDeleteById extends BaseApi<DisplaysSetting> {

    public topic = 'Resources.DisplaysSettings';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/displays_settings/${id}`;
    }

    protected convertToResource(data: any): DisplaysSetting {
        return new DisplaysSetting(data);
    }

    public delete(next?: (value: DisplaysSetting) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class DynamicMenuItems {

    public get(): DynamicMenuItemsGet {
        return new DynamicMenuItemsGet();
    }

    public getById(id: number): DynamicMenuItemsGetById {
        return new DynamicMenuItemsGetById(id);
    }

    public post(): DynamicMenuItemsPost {
        return new DynamicMenuItemsPost();
    }

    public putById(id: number): DynamicMenuItemsPutById {
        return new DynamicMenuItemsPutById(id);
    }

    public put(): DynamicMenuItemsPut {
        return new DynamicMenuItemsPut();
    }

    public patchById(id: number): DynamicMenuItemsPatchById {
        return new DynamicMenuItemsPatchById(id);
    }

    public patch(): DynamicMenuItemsPatch {
        return new DynamicMenuItemsPatch();
    }

    public deleteById(id: number): DynamicMenuItemsDeleteById {
        return new DynamicMenuItemsDeleteById(id);
    }

}

export class DynamicMenuItemsGet extends BaseApi<DynamicMenuItem> {

    public topic = 'Resources.DynamicMenuItems';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/dynamic_menu_items`;
    }

    protected convertToResource(data: any): DynamicMenuItem {
        return new DynamicMenuItem(data);
    }

    public where(name: string, value: any): DynamicMenuItemsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): DynamicMenuItemsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): DynamicMenuItemsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): DynamicMenuItemsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): DynamicMenuItemsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): DynamicMenuItemsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): DynamicMenuItemsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): DynamicMenuItemsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): DynamicMenuItemsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): DynamicMenuItemsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): DynamicMenuItemsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): DynamicMenuItemsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): DynamicMenuItemsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): DynamicMenuItemsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): DynamicMenuItemsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): DynamicMenuItemsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): DynamicMenuItemsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: DynamicMenuItem[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | DynamicMenuItem[] | any[]> {
        return super.executeClientGet();
    }
}

export class DynamicMenuItemsGetById extends BaseApi<DynamicMenuItem> {

    public topic = 'Resources.DynamicMenuItems';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/dynamic_menu_items/${id}`;
    }

    protected convertToResource(data: any): DynamicMenuItem {
        return new DynamicMenuItem(data);
    }

    public include(name: string): DynamicMenuItemsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: DynamicMenuItem[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | DynamicMenuItem[] | any[]> {
        return super.executeClientGet();
    }
}

export class DynamicMenuItemsPost extends BaseApi<DynamicMenuItem> {

    public topic = 'Resources.DynamicMenuItems';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/dynamic_menu_items`;
    }

    protected convertToResource(data: any): DynamicMenuItem {
        return new DynamicMenuItem(data);
    }

    public save(data: DynamicMenuItem, next?: (value: DynamicMenuItem) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DynamicMenuItemsPutById extends BaseApi<DynamicMenuItem> {

    public topic = 'Resources.DynamicMenuItems';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/dynamic_menu_items/${id}`;
    }

    protected convertToResource(data: any): DynamicMenuItem {
        return new DynamicMenuItem(data);
    }

    public save(data: DynamicMenuItem, next?: (value: DynamicMenuItem) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DynamicMenuItemsPut extends BaseApi<DynamicMenuItem> {

    public topic = 'Resources.DynamicMenuItems';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/dynamic_menu_items`;
    }

    protected convertToResource(data: any): DynamicMenuItem {
        return new DynamicMenuItem(data);
    }

    public save(data: DynamicMenuItem, next?: (value: DynamicMenuItem) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DynamicMenuItemsPatchById extends BaseApi<DynamicMenuItem> {

    public topic = 'Resources.DynamicMenuItems';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/dynamic_menu_items/${id}`;
    }

    protected convertToResource(data: any): DynamicMenuItem {
        return new DynamicMenuItem(data);
    }

    public save(data: DynamicMenuItem, next?: (value: DynamicMenuItem) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DynamicMenuItemsPatch extends BaseApi<DynamicMenuItem> {

    public topic = 'Resources.DynamicMenuItems';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/dynamic_menu_items`;
    }

    protected convertToResource(data: any): DynamicMenuItem {
        return new DynamicMenuItem(data);
    }

    public save(data: DynamicMenuItem, next?: (value: DynamicMenuItem) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class DynamicMenuItemsDeleteById extends BaseApi<DynamicMenuItem> {

    public topic = 'Resources.DynamicMenuItems';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/dynamic_menu_items/${id}`;
    }

    protected convertToResource(data: any): DynamicMenuItem {
        return new DynamicMenuItem(data);
    }

    public delete(next?: (value: DynamicMenuItem) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class EstimateUnits {

    public get(): EstimateUnitsGet {
        return new EstimateUnitsGet();
    }

    public getById(id: number): EstimateUnitsGetById {
        return new EstimateUnitsGetById(id);
    }

    public post(): EstimateUnitsPost {
        return new EstimateUnitsPost();
    }

    public putById(id: number): EstimateUnitsPutById {
        return new EstimateUnitsPutById(id);
    }

    public put(): EstimateUnitsPut {
        return new EstimateUnitsPut();
    }

    public patchById(id: number): EstimateUnitsPatchById {
        return new EstimateUnitsPatchById(id);
    }

    public patch(): EstimateUnitsPatch {
        return new EstimateUnitsPatch();
    }

    public deleteById(id: number): EstimateUnitsDeleteById {
        return new EstimateUnitsDeleteById(id);
    }

    public tasksGet(): EstimateUnitsTasksGet {
        return new EstimateUnitsTasksGet();
    }

}

export class EstimateUnitsGet extends BaseApi<EstimateUnit> {

    public topic = 'Resources.EstimateUnits';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/estimate_units`;
    }

    protected convertToResource(data: any): EstimateUnit {
        return new EstimateUnit(data);
    }

    public where(name: string, value: any): EstimateUnitsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): EstimateUnitsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): EstimateUnitsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): EstimateUnitsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): EstimateUnitsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): EstimateUnitsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): EstimateUnitsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): EstimateUnitsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): EstimateUnitsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): EstimateUnitsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): EstimateUnitsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): EstimateUnitsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): EstimateUnitsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): EstimateUnitsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): EstimateUnitsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): EstimateUnitsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): EstimateUnitsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: EstimateUnit[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | EstimateUnit[] | any[]> {
        return super.executeClientGet();
    }
}

export class EstimateUnitsGetById extends BaseApi<EstimateUnit> {

    public topic = 'Resources.EstimateUnits';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/estimate_units/${id}`;
    }

    protected convertToResource(data: any): EstimateUnit {
        return new EstimateUnit(data);
    }

    public include(name: string): EstimateUnitsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: EstimateUnit[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | EstimateUnit[] | any[]> {
        return super.executeClientGet();
    }
}

export class EstimateUnitsPost extends BaseApi<EstimateUnit> {

    public topic = 'Resources.EstimateUnits';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/estimate_units`;
    }

    protected convertToResource(data: any): EstimateUnit {
        return new EstimateUnit(data);
    }

    public save(data: EstimateUnit, next?: (value: EstimateUnit) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class EstimateUnitsPutById extends BaseApi<EstimateUnit> {

    public topic = 'Resources.EstimateUnits';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/estimate_units/${id}`;
    }

    protected convertToResource(data: any): EstimateUnit {
        return new EstimateUnit(data);
    }

    public save(data: EstimateUnit, next?: (value: EstimateUnit) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class EstimateUnitsPut extends BaseApi<EstimateUnit> {

    public topic = 'Resources.EstimateUnits';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/estimate_units`;
    }

    protected convertToResource(data: any): EstimateUnit {
        return new EstimateUnit(data);
    }

    public save(data: EstimateUnit, next?: (value: EstimateUnit) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class EstimateUnitsPatchById extends BaseApi<EstimateUnit> {

    public topic = 'Resources.EstimateUnits';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/estimate_units/${id}`;
    }

    protected convertToResource(data: any): EstimateUnit {
        return new EstimateUnit(data);
    }

    public save(data: EstimateUnit, next?: (value: EstimateUnit) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class EstimateUnitsPatch extends BaseApi<EstimateUnit> {

    public topic = 'Resources.EstimateUnits';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/estimate_units`;
    }

    protected convertToResource(data: any): EstimateUnit {
        return new EstimateUnit(data);
    }

    public save(data: EstimateUnit, next?: (value: EstimateUnit) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class EstimateUnitsDeleteById extends BaseApi<EstimateUnit> {

    public topic = 'Resources.EstimateUnits';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/estimate_units/${id}`;
    }

    protected convertToResource(data: any): EstimateUnit {
        return new EstimateUnit(data);
    }

    public delete(next?: (value: EstimateUnit) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class EstimateUnitsTasksGet extends BaseApi<EstimateUnit> {

    public topic = 'Resources.EstimateUnits';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/estimate_units/tasks`;
    }

    protected convertToResource(data: any): EstimateUnit {
        return new EstimateUnit(data);
    }

    public find(next?: (value: EstimateUnit[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | EstimateUnit[] | any[]> {
        return super.executeClientGet();
    }
}

class Holidays {

    public get(): HolidaysGet {
        return new HolidaysGet();
    }

    public getById(id: number): HolidaysGetById {
        return new HolidaysGetById(id);
    }

    public post(): HolidaysPost {
        return new HolidaysPost();
    }

    public putById(id: number): HolidaysPutById {
        return new HolidaysPutById(id);
    }

    public put(): HolidaysPut {
        return new HolidaysPut();
    }

    public patchById(id: number): HolidaysPatchById {
        return new HolidaysPatchById(id);
    }

    public patch(): HolidaysPatch {
        return new HolidaysPatch();
    }

    public deleteById(id: number): HolidaysDeleteById {
        return new HolidaysDeleteById(id);
    }

    public getGetById(id: number): HolidaysGetGetById {
        return new HolidaysGetGetById(id);
    }

}

export class HolidaysGet extends BaseApi<Holiday> {

    public topic = 'Resources.Holidays';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/holidays`;
    }

    protected convertToResource(data: any): Holiday {
        return new Holiday(data);
    }

    public where(name: string, value: any): HolidaysGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): HolidaysGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): HolidaysGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): HolidaysGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): HolidaysGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): HolidaysGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): HolidaysGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): HolidaysGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): HolidaysGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): HolidaysGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): HolidaysGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): HolidaysGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): HolidaysGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): HolidaysGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): HolidaysGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): HolidaysGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): HolidaysGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Holiday[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Holiday[] | any[]> {
        return super.executeClientGet();
    }
}

export class HolidaysGetById extends BaseApi<Holiday> {

    public topic = 'Resources.Holidays';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/holidays/${id}`;
    }

    protected convertToResource(data: any): Holiday {
        return new Holiday(data);
    }

    public include(name: string): HolidaysGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Holiday[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Holiday[] | any[]> {
        return super.executeClientGet();
    }
}

export class HolidaysPost extends BaseApi<Holiday> {

    public topic = 'Resources.Holidays';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/holidays`;
    }

    protected convertToResource(data: any): Holiday {
        return new Holiday(data);
    }

    public save(data: Holiday, next?: (value: Holiday) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class HolidaysPutById extends BaseApi<Holiday> {

    public topic = 'Resources.Holidays';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/holidays/${id}`;
    }

    protected convertToResource(data: any): Holiday {
        return new Holiday(data);
    }

    public save(data: Holiday, next?: (value: Holiday) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class HolidaysPut extends BaseApi<Holiday> {

    public topic = 'Resources.Holidays';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/holidays`;
    }

    protected convertToResource(data: any): Holiday {
        return new Holiday(data);
    }

    public save(data: Holiday, next?: (value: Holiday) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class HolidaysPatchById extends BaseApi<Holiday> {

    public topic = 'Resources.Holidays';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/holidays/${id}`;
    }

    protected convertToResource(data: any): Holiday {
        return new Holiday(data);
    }

    public save(data: Holiday, next?: (value: Holiday) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class HolidaysPatch extends BaseApi<Holiday> {

    public topic = 'Resources.Holidays';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/holidays`;
    }

    protected convertToResource(data: any): Holiday {
        return new Holiday(data);
    }

    public save(data: Holiday, next?: (value: Holiday) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class HolidaysDeleteById extends BaseApi<Holiday> {

    public topic = 'Resources.Holidays';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/holidays/${id}`;
    }

    protected convertToResource(data: any): Holiday {
        return new Holiday(data);
    }

    public delete(next?: (value: Holiday) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class HolidaysGetGetById extends BaseApi<Holiday> {

    public topic = 'Resources.Holidays';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/holidays`;
    }

    protected convertToResource(data: any): Holiday {
        return new Holiday(data);
    }

    public start(value: number): HolidaysGetGetById {
        this.addQueryParameter('start', value);
        return this;
    }

    public end(value: number): HolidaysGetGetById {
        this.addQueryParameter('end', value);
        return this;
    }

    public find(next?: (value: Holiday[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Holiday[] | any[]> {
        return super.executeClientGet();
    }
}

class Imports {

    public get(): ImportsGet {
        return new ImportsGet();
    }

    public getById(id: number): ImportsGetById {
        return new ImportsGetById(id);
    }

    public post(): ImportsPost {
        return new ImportsPost();
    }

    public putById(id: number): ImportsPutById {
        return new ImportsPutById(id);
    }

    public put(): ImportsPut {
        return new ImportsPut();
    }

    public patchById(id: number): ImportsPatchById {
        return new ImportsPatchById(id);
    }

    public patch(): ImportsPatch {
        return new ImportsPatch();
    }

    public deleteById(id: number): ImportsDeleteById {
        return new ImportsDeleteById(id);
    }

}

export class ImportsGet extends BaseApi<Import> {

    public topic = 'Resources.Imports';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/imports`;
    }

    protected convertToResource(data: any): Import {
        return new Import(data);
    }

    public where(name: string, value: any): ImportsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): ImportsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): ImportsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): ImportsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): ImportsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): ImportsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): ImportsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): ImportsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): ImportsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): ImportsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): ImportsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): ImportsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): ImportsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): ImportsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): ImportsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): ImportsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): ImportsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Import[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Import[] | any[]> {
        return super.executeClientGet();
    }
}

export class ImportsGetById extends BaseApi<Import> {

    public topic = 'Resources.Imports';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/imports/${id}`;
    }

    protected convertToResource(data: any): Import {
        return new Import(data);
    }

    public include(name: string): ImportsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Import[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Import[] | any[]> {
        return super.executeClientGet();
    }
}

export class ImportsPost extends BaseApi<Import> {

    public topic = 'Resources.Imports';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/imports`;
    }

    protected convertToResource(data: any): Import {
        return new Import(data);
    }

    public save(data: Import, next?: (value: Import) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ImportsPutById extends BaseApi<Import> {

    public topic = 'Resources.Imports';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/imports/${id}`;
    }

    protected convertToResource(data: any): Import {
        return new Import(data);
    }

    public save(data: Import, next?: (value: Import) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ImportsPut extends BaseApi<Import> {

    public topic = 'Resources.Imports';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/imports`;
    }

    protected convertToResource(data: any): Import {
        return new Import(data);
    }

    public save(data: Import, next?: (value: Import) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ImportsPatchById extends BaseApi<Import> {

    public topic = 'Resources.Imports';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/imports/${id}`;
    }

    protected convertToResource(data: any): Import {
        return new Import(data);
    }

    public save(data: Import, next?: (value: Import) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ImportsPatch extends BaseApi<Import> {

    public topic = 'Resources.Imports';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/imports`;
    }

    protected convertToResource(data: any): Import {
        return new Import(data);
    }

    public save(data: Import, next?: (value: Import) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ImportsDeleteById extends BaseApi<Import> {

    public topic = 'Resources.Imports';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/imports/${id}`;
    }

    protected convertToResource(data: any): Import {
        return new Import(data);
    }

    public delete(next?: (value: Import) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class LanguageKeys {

    public get(): LanguageKeysGet {
        return new LanguageKeysGet();
    }

    public getById(id: number): LanguageKeysGetById {
        return new LanguageKeysGetById(id);
    }

    public post(): LanguageKeysPost {
        return new LanguageKeysPost();
    }

    public putById(id: number): LanguageKeysPutById {
        return new LanguageKeysPutById(id);
    }

    public put(): LanguageKeysPut {
        return new LanguageKeysPut();
    }

    public patchById(id: number): LanguageKeysPatchById {
        return new LanguageKeysPatchById(id);
    }

    public patch(): LanguageKeysPatch {
        return new LanguageKeysPatch();
    }

    public deleteById(id: number): LanguageKeysDeleteById {
        return new LanguageKeysDeleteById(id);
    }

}

export class LanguageKeysGet extends BaseApi<LanguageKey> {

    public topic = 'Resources.LanguageKeys';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/language_keys`;
    }

    protected convertToResource(data: any): LanguageKey {
        return new LanguageKey(data);
    }

    public where(name: string, value: any): LanguageKeysGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): LanguageKeysGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): LanguageKeysGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): LanguageKeysGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): LanguageKeysGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): LanguageKeysGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): LanguageKeysGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): LanguageKeysGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): LanguageKeysGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): LanguageKeysGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): LanguageKeysGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): LanguageKeysGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): LanguageKeysGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): LanguageKeysGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): LanguageKeysGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): LanguageKeysGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): LanguageKeysGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: LanguageKey[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | LanguageKey[] | any[]> {
        return super.executeClientGet();
    }
}

export class LanguageKeysGetById extends BaseApi<LanguageKey> {

    public topic = 'Resources.LanguageKeys';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/language_keys/${id}`;
    }

    protected convertToResource(data: any): LanguageKey {
        return new LanguageKey(data);
    }

    public include(name: string): LanguageKeysGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: LanguageKey[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | LanguageKey[] | any[]> {
        return super.executeClientGet();
    }
}

export class LanguageKeysPost extends BaseApi<LanguageKey> {

    public topic = 'Resources.LanguageKeys';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/language_keys`;
    }

    protected convertToResource(data: any): LanguageKey {
        return new LanguageKey(data);
    }

    public save(data: LanguageKey, next?: (value: LanguageKey) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguageKeysPutById extends BaseApi<LanguageKey> {

    public topic = 'Resources.LanguageKeys';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/language_keys/${id}`;
    }

    protected convertToResource(data: any): LanguageKey {
        return new LanguageKey(data);
    }

    public save(data: LanguageKey, next?: (value: LanguageKey) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguageKeysPut extends BaseApi<LanguageKey> {

    public topic = 'Resources.LanguageKeys';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/language_keys`;
    }

    protected convertToResource(data: any): LanguageKey {
        return new LanguageKey(data);
    }

    public save(data: LanguageKey, next?: (value: LanguageKey) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguageKeysPatchById extends BaseApi<LanguageKey> {

    public topic = 'Resources.LanguageKeys';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/language_keys/${id}`;
    }

    protected convertToResource(data: any): LanguageKey {
        return new LanguageKey(data);
    }

    public save(data: LanguageKey, next?: (value: LanguageKey) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguageKeysPatch extends BaseApi<LanguageKey> {

    public topic = 'Resources.LanguageKeys';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/language_keys`;
    }

    protected convertToResource(data: any): LanguageKey {
        return new LanguageKey(data);
    }

    public save(data: LanguageKey, next?: (value: LanguageKey) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguageKeysDeleteById extends BaseApi<LanguageKey> {

    public topic = 'Resources.LanguageKeys';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/language_keys/${id}`;
    }

    protected convertToResource(data: any): LanguageKey {
        return new LanguageKey(data);
    }

    public delete(next?: (value: LanguageKey) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class LanguageValues {

    public get(): LanguageValuesGet {
        return new LanguageValuesGet();
    }

    public getById(id: number): LanguageValuesGetById {
        return new LanguageValuesGetById(id);
    }

    public post(): LanguageValuesPost {
        return new LanguageValuesPost();
    }

    public putById(id: number): LanguageValuesPutById {
        return new LanguageValuesPutById(id);
    }

    public put(): LanguageValuesPut {
        return new LanguageValuesPut();
    }

    public patchById(id: number): LanguageValuesPatchById {
        return new LanguageValuesPatchById(id);
    }

    public patch(): LanguageValuesPatch {
        return new LanguageValuesPatch();
    }

    public deleteById(id: number): LanguageValuesDeleteById {
        return new LanguageValuesDeleteById(id);
    }

    public getGetById(id: number): LanguageValuesGetGetById {
        return new LanguageValuesGetGetById(id);
    }

}

export class LanguageValuesGet extends BaseApi<LanguageValue> {

    public topic = 'Resources.LanguageValues';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/language_values`;
    }

    protected convertToResource(data: any): LanguageValue {
        return new LanguageValue(data);
    }

    public where(name: string, value: any): LanguageValuesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): LanguageValuesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): LanguageValuesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): LanguageValuesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): LanguageValuesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): LanguageValuesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): LanguageValuesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): LanguageValuesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): LanguageValuesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): LanguageValuesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): LanguageValuesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): LanguageValuesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): LanguageValuesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): LanguageValuesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): LanguageValuesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): LanguageValuesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): LanguageValuesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: LanguageValue[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | LanguageValue[] | any[]> {
        return super.executeClientGet();
    }
}

export class LanguageValuesGetById extends BaseApi<LanguageValue> {

    public topic = 'Resources.LanguageValues';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/language_values/${id}`;
    }

    protected convertToResource(data: any): LanguageValue {
        return new LanguageValue(data);
    }

    public include(name: string): LanguageValuesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: LanguageValue[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | LanguageValue[] | any[]> {
        return super.executeClientGet();
    }
}

export class LanguageValuesPost extends BaseApi<LanguageValue> {

    public topic = 'Resources.LanguageValues';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/language_values`;
    }

    protected convertToResource(data: any): LanguageValue {
        return new LanguageValue(data);
    }

    public save(data: LanguageValue, next?: (value: LanguageValue) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguageValuesPutById extends BaseApi<LanguageValue> {

    public topic = 'Resources.LanguageValues';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/language_values/${id}`;
    }

    protected convertToResource(data: any): LanguageValue {
        return new LanguageValue(data);
    }

    public save(data: LanguageValue, next?: (value: LanguageValue) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguageValuesPut extends BaseApi<LanguageValue> {

    public topic = 'Resources.LanguageValues';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/language_values`;
    }

    protected convertToResource(data: any): LanguageValue {
        return new LanguageValue(data);
    }

    public save(data: LanguageValue, next?: (value: LanguageValue) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguageValuesPatchById extends BaseApi<LanguageValue> {

    public topic = 'Resources.LanguageValues';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/language_values/${id}`;
    }

    protected convertToResource(data: any): LanguageValue {
        return new LanguageValue(data);
    }

    public save(data: LanguageValue, next?: (value: LanguageValue) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguageValuesPatch extends BaseApi<LanguageValue> {

    public topic = 'Resources.LanguageValues';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/language_values`;
    }

    protected convertToResource(data: any): LanguageValue {
        return new LanguageValue(data);
    }

    public save(data: LanguageValue, next?: (value: LanguageValue) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguageValuesDeleteById extends BaseApi<LanguageValue> {

    public topic = 'Resources.LanguageValues';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/language_values/${id}`;
    }

    protected convertToResource(data: any): LanguageValue {
        return new LanguageValue(data);
    }

    public delete(next?: (value: LanguageValue) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class LanguageValuesGetGetById extends BaseApi<LanguageValue> {

    public topic = 'Resources.LanguageValues';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/language_values`;
    }

    protected convertToResource(data: any): LanguageValue {
        return new LanguageValue(data);
    }

    public find(next?: (value: LanguageValue[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | LanguageValue[] | any[]> {
        return super.executeClientGet();
    }
}

class Languages {

    public get(): LanguagesGet {
        return new LanguagesGet();
    }

    public getById(id: number): LanguagesGetById {
        return new LanguagesGetById(id);
    }

    public post(): LanguagesPost {
        return new LanguagesPost();
    }

    public putById(id: number): LanguagesPutById {
        return new LanguagesPutById(id);
    }

    public put(): LanguagesPut {
        return new LanguagesPut();
    }

    public patchById(id: number): LanguagesPatchById {
        return new LanguagesPatchById(id);
    }

    public patch(): LanguagesPatch {
        return new LanguagesPatch();
    }

    public deleteById(id: number): LanguagesDeleteById {
        return new LanguagesDeleteById(id);
    }

}

export class LanguagesGet extends BaseApi<Language> {

    public topic = 'Resources.Languages';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/languages`;
    }

    protected convertToResource(data: any): Language {
        return new Language(data);
    }

    public where(name: string, value: any): LanguagesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): LanguagesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): LanguagesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): LanguagesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): LanguagesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): LanguagesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): LanguagesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): LanguagesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): LanguagesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): LanguagesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): LanguagesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): LanguagesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): LanguagesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): LanguagesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): LanguagesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): LanguagesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): LanguagesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Language[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Language[] | any[]> {
        return super.executeClientGet();
    }
}

export class LanguagesGetById extends BaseApi<Language> {

    public topic = 'Resources.Languages';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/languages/${id}`;
    }

    protected convertToResource(data: any): Language {
        return new Language(data);
    }

    public include(name: string): LanguagesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Language[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Language[] | any[]> {
        return super.executeClientGet();
    }
}

export class LanguagesPost extends BaseApi<Language> {

    public topic = 'Resources.Languages';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/languages`;
    }

    protected convertToResource(data: any): Language {
        return new Language(data);
    }

    public save(data: Language, next?: (value: Language) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguagesPutById extends BaseApi<Language> {

    public topic = 'Resources.Languages';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/languages/${id}`;
    }

    protected convertToResource(data: any): Language {
        return new Language(data);
    }

    public save(data: Language, next?: (value: Language) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguagesPut extends BaseApi<Language> {

    public topic = 'Resources.Languages';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/languages`;
    }

    protected convertToResource(data: any): Language {
        return new Language(data);
    }

    public save(data: Language, next?: (value: Language) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguagesPatchById extends BaseApi<Language> {

    public topic = 'Resources.Languages';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/languages/${id}`;
    }

    protected convertToResource(data: any): Language {
        return new Language(data);
    }

    public save(data: Language, next?: (value: Language) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguagesPatch extends BaseApi<Language> {

    public topic = 'Resources.Languages';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/languages`;
    }

    protected convertToResource(data: any): Language {
        return new Language(data);
    }

    public save(data: Language, next?: (value: Language) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LanguagesDeleteById extends BaseApi<Language> {

    public topic = 'Resources.Languages';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/languages/${id}`;
    }

    protected convertToResource(data: any): Language {
        return new Language(data);
    }

    public delete(next?: (value: Language) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Loads {

    public get(): LoadsGet {
        return new LoadsGet();
    }

    public getById(id: number): LoadsGetById {
        return new LoadsGetById(id);
    }

    public post(): LoadsPost {
        return new LoadsPost();
    }

    public putById(id: number): LoadsPutById {
        return new LoadsPutById(id);
    }

    public put(): LoadsPut {
        return new LoadsPut();
    }

    public patchById(id: number): LoadsPatchById {
        return new LoadsPatchById(id);
    }

    public patch(): LoadsPatch {
        return new LoadsPatch();
    }

    public deleteById(id: number): LoadsDeleteById {
        return new LoadsDeleteById(id);
    }

    public calculateGetByLoadId(loadId: number): LoadsCalculateGetByLoadId {
        return new LoadsCalculateGetByLoadId(loadId);
    }

}

export class LoadsGet extends BaseApi<Load> {

    public topic = 'Resources.Loads';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/loads`;
    }

    protected convertToResource(data: any): Load {
        return new Load(data);
    }

    public where(name: string, value: any): LoadsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): LoadsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): LoadsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): LoadsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): LoadsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): LoadsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): LoadsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): LoadsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): LoadsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): LoadsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): LoadsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): LoadsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): LoadsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): LoadsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): LoadsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): LoadsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): LoadsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Load[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Load[] | any[]> {
        return super.executeClientGet();
    }
}

export class LoadsGetById extends BaseApi<Load> {

    public topic = 'Resources.Loads';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/loads/${id}`;
    }

    protected convertToResource(data: any): Load {
        return new Load(data);
    }

    public include(name: string): LoadsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Load[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Load[] | any[]> {
        return super.executeClientGet();
    }
}

export class LoadsPost extends BaseApi<Load> {

    public topic = 'Resources.Loads';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/loads`;
    }

    protected convertToResource(data: any): Load {
        return new Load(data);
    }

    public save(data: Load, next?: (value: Load) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LoadsPutById extends BaseApi<Load> {

    public topic = 'Resources.Loads';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/loads/${id}`;
    }

    protected convertToResource(data: any): Load {
        return new Load(data);
    }

    public save(data: Load, next?: (value: Load) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LoadsPut extends BaseApi<Load> {

    public topic = 'Resources.Loads';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/loads`;
    }

    protected convertToResource(data: any): Load {
        return new Load(data);
    }

    public save(data: Load, next?: (value: Load) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LoadsPatchById extends BaseApi<Load> {

    public topic = 'Resources.Loads';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/loads/${id}`;
    }

    protected convertToResource(data: any): Load {
        return new Load(data);
    }

    public save(data: Load, next?: (value: Load) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LoadsPatch extends BaseApi<Load> {

    public topic = 'Resources.Loads';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/loads`;
    }

    protected convertToResource(data: any): Load {
        return new Load(data);
    }

    public save(data: Load, next?: (value: Load) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LoadsDeleteById extends BaseApi<Load> {

    public topic = 'Resources.Loads';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/loads/${id}`;
    }

    protected convertToResource(data: any): Load {
        return new Load(data);
    }

    public delete(next?: (value: Load) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class LoadsCalculateGetByLoadId extends BaseApi<LoadCalculationResponse> {

    public topic = 'Resources.LoadCalculationResponses';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(loadId: number) {
        super();
        this.uri = `/loads/${loadId}/calculate`;
    }

    protected convertToResource(data: any): LoadCalculationResponse {
        return data;
    }

    public user_ids(value: number[]): LoadsCalculateGetByLoadId {
        this.addQueryParameter('user_ids', value);
        return this;
    }

    public department_id(value: number): LoadsCalculateGetByLoadId {
        this.addQueryParameter('department_id', value);
        return this;
    }

    public start(value: string): LoadsCalculateGetByLoadId {
        this.addQueryParameter('start', value);
        return this;
    }

    public end(value: string): LoadsCalculateGetByLoadId {
        this.addQueryParameter('end', value);
        return this;
    }

    public find(next?: (value: LoadCalculationResponse[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | LoadCalculationResponse[] | any[]> {
        return super.executeClientGet();
    }
}

class Logs {

    public get(): LogsGet {
        return new LogsGet();
    }

    public getById(id: number): LogsGetById {
        return new LogsGetById(id);
    }

    public post(): LogsPost {
        return new LogsPost();
    }

    public putById(id: number): LogsPutById {
        return new LogsPutById(id);
    }

    public put(): LogsPut {
        return new LogsPut();
    }

    public patchById(id: number): LogsPatchById {
        return new LogsPatchById(id);
    }

    public patch(): LogsPatch {
        return new LogsPatch();
    }

    public deleteById(id: number): LogsDeleteById {
        return new LogsDeleteById(id);
    }

}

export class LogsGet extends BaseApi<Log> {

    public topic = 'Resources.Logs';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/logs`;
    }

    protected convertToResource(data: any): Log {
        return new Log(data);
    }

    public where(name: string, value: any): LogsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): LogsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): LogsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): LogsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): LogsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): LogsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): LogsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): LogsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): LogsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): LogsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): LogsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): LogsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): LogsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): LogsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): LogsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): LogsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): LogsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Log[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Log[] | any[]> {
        return super.executeClientGet();
    }
}

export class LogsGetById extends BaseApi<Log> {

    public topic = 'Resources.Logs';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/logs/${id}`;
    }

    protected convertToResource(data: any): Log {
        return new Log(data);
    }

    public include(name: string): LogsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Log[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Log[] | any[]> {
        return super.executeClientGet();
    }
}

export class LogsPost extends BaseApi<Log> {

    public topic = 'Resources.Logs';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/logs`;
    }

    protected convertToResource(data: any): Log {
        return new Log(data);
    }

    public save(data: Log, next?: (value: Log) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LogsPutById extends BaseApi<Log> {

    public topic = 'Resources.Logs';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/logs/${id}`;
    }

    protected convertToResource(data: any): Log {
        return new Log(data);
    }

    public save(data: Log, next?: (value: Log) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LogsPut extends BaseApi<Log> {

    public topic = 'Resources.Logs';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/logs`;
    }

    protected convertToResource(data: any): Log {
        return new Log(data);
    }

    public save(data: Log, next?: (value: Log) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LogsPatchById extends BaseApi<Log> {

    public topic = 'Resources.Logs';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/logs/${id}`;
    }

    protected convertToResource(data: any): Log {
        return new Log(data);
    }

    public save(data: Log, next?: (value: Log) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LogsPatch extends BaseApi<Log> {

    public topic = 'Resources.Logs';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/logs`;
    }

    protected convertToResource(data: any): Log {
        return new Log(data);
    }

    public save(data: Log, next?: (value: Log) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class LogsDeleteById extends BaseApi<Log> {

    public topic = 'Resources.Logs';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/logs/${id}`;
    }

    protected convertToResource(data: any): Log {
        return new Log(data);
    }

    public delete(next?: (value: Log) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class MilestoneFields {

    public get(): MilestoneFieldsGet {
        return new MilestoneFieldsGet();
    }

    public getById(id: number): MilestoneFieldsGetById {
        return new MilestoneFieldsGetById(id);
    }

    public post(): MilestoneFieldsPost {
        return new MilestoneFieldsPost();
    }

    public putById(id: number): MilestoneFieldsPutById {
        return new MilestoneFieldsPutById(id);
    }

    public put(): MilestoneFieldsPut {
        return new MilestoneFieldsPut();
    }

    public patchById(id: number): MilestoneFieldsPatchById {
        return new MilestoneFieldsPatchById(id);
    }

    public patch(): MilestoneFieldsPatch {
        return new MilestoneFieldsPatch();
    }

    public deleteById(id: number): MilestoneFieldsDeleteById {
        return new MilestoneFieldsDeleteById(id);
    }

}

export class MilestoneFieldsGet extends BaseApi<MilestoneField> {

    public topic = 'Resources.MilestoneFields';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_fields`;
    }

    protected convertToResource(data: any): MilestoneField {
        return new MilestoneField(data);
    }

    public where(name: string, value: any): MilestoneFieldsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): MilestoneFieldsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): MilestoneFieldsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): MilestoneFieldsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): MilestoneFieldsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): MilestoneFieldsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): MilestoneFieldsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): MilestoneFieldsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): MilestoneFieldsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): MilestoneFieldsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): MilestoneFieldsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): MilestoneFieldsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): MilestoneFieldsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): MilestoneFieldsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): MilestoneFieldsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): MilestoneFieldsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): MilestoneFieldsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: MilestoneField[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestoneField[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestoneFieldsGetById extends BaseApi<MilestoneField> {

    public topic = 'Resources.MilestoneFields';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_fields/${id}`;
    }

    protected convertToResource(data: any): MilestoneField {
        return new MilestoneField(data);
    }

    public include(name: string): MilestoneFieldsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: MilestoneField[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestoneField[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestoneFieldsPost extends BaseApi<MilestoneField> {

    public topic = 'Resources.MilestoneFields';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_fields`;
    }

    protected convertToResource(data: any): MilestoneField {
        return new MilestoneField(data);
    }

    public save(data: MilestoneField, next?: (value: MilestoneField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneFieldsPutById extends BaseApi<MilestoneField> {

    public topic = 'Resources.MilestoneFields';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_fields/${id}`;
    }

    protected convertToResource(data: any): MilestoneField {
        return new MilestoneField(data);
    }

    public save(data: MilestoneField, next?: (value: MilestoneField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneFieldsPut extends BaseApi<MilestoneField> {

    public topic = 'Resources.MilestoneFields';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_fields`;
    }

    protected convertToResource(data: any): MilestoneField {
        return new MilestoneField(data);
    }

    public save(data: MilestoneField, next?: (value: MilestoneField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneFieldsPatchById extends BaseApi<MilestoneField> {

    public topic = 'Resources.MilestoneFields';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_fields/${id}`;
    }

    protected convertToResource(data: any): MilestoneField {
        return new MilestoneField(data);
    }

    public save(data: MilestoneField, next?: (value: MilestoneField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneFieldsPatch extends BaseApi<MilestoneField> {

    public topic = 'Resources.MilestoneFields';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_fields`;
    }

    protected convertToResource(data: any): MilestoneField {
        return new MilestoneField(data);
    }

    public save(data: MilestoneField, next?: (value: MilestoneField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneFieldsDeleteById extends BaseApi<MilestoneField> {

    public topic = 'Resources.MilestoneFields';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_fields/${id}`;
    }

    protected convertToResource(data: any): MilestoneField {
        return new MilestoneField(data);
    }

    public delete(next?: (value: MilestoneField) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class MilestonePlans {

    public get(): MilestonePlansGet {
        return new MilestonePlansGet();
    }

    public getById(id: number): MilestonePlansGetById {
        return new MilestonePlansGetById(id);
    }

    public post(): MilestonePlansPost {
        return new MilestonePlansPost();
    }

    public putById(id: number): MilestonePlansPutById {
        return new MilestonePlansPutById(id);
    }

    public put(): MilestonePlansPut {
        return new MilestonePlansPut();
    }

    public patchById(id: number): MilestonePlansPatchById {
        return new MilestonePlansPatchById(id);
    }

    public patch(): MilestonePlansPatch {
        return new MilestonePlansPatch();
    }

    public deleteById(id: number): MilestonePlansDeleteById {
        return new MilestonePlansDeleteById(id);
    }

    public copyGetById(id: number): MilestonePlansCopyGetById {
        return new MilestonePlansCopyGetById(id);
    }

    public indexingPut(): MilestonePlansIndexingPut {
        return new MilestonePlansIndexingPut();
    }

    public blockedPeriodsGetById(id: number): MilestonePlansBlockedPeriodsGetById {
        return new MilestonePlansBlockedPeriodsGetById(id);
    }

    public blockedHolidaysGetById(id: number): MilestonePlansBlockedHolidaysGetById {
        return new MilestonePlansBlockedHolidaysGetById(id);
    }

    public milestoneTemplatesGetById(id: number): MilestonePlansMilestoneTemplatesGetById {
        return new MilestonePlansMilestoneTemplatesGetById(id);
    }

    public titlePatchPatchById(id: number): MilestonePlansTitlePatchPatchById {
        return new MilestonePlansTitlePatchPatchById(id);
    }

    public userIdPatchPatchById(id: number): MilestonePlansUserIdPatchPatchById {
        return new MilestonePlansUserIdPatchPatchById(id);
    }

    public departmentsEnsurePutById(id: number): MilestonePlansDepartmentsEnsurePutById {
        return new MilestonePlansDepartmentsEnsurePutById(id);
    }

    public departmentAddPostByIdByDepartmentId(id: number, departmentId: number): MilestonePlansDepartmentAddPostByIdByDepartmentId {
        return new MilestonePlansDepartmentAddPostByIdByDepartmentId(id, departmentId);
    }

    public departmentRemoveDeleteByIdByDepartmentId(id: number, departmentId: number): MilestonePlansDepartmentRemoveDeleteByIdByDepartmentId {
        return new MilestonePlansDepartmentRemoveDeleteByIdByDepartmentId(id, departmentId);
    }

    public projectTypeIdPatchPatchById(id: number): MilestonePlansProjectTypeIdPatchPatchById {
        return new MilestonePlansProjectTypeIdPatchPatchById(id);
    }

    public startProjectDeadlineTypeIdPatchPatchById(id: number): MilestonePlansStartProjectDeadlineTypeIdPatchPatchById {
        return new MilestonePlansStartProjectDeadlineTypeIdPatchPatchById(id);
    }

    public endProjectDeadlineTypeIdPatchPatchById(id: number): MilestonePlansEndProjectDeadlineTypeIdPatchPatchById {
        return new MilestonePlansEndProjectDeadlineTypeIdPatchPatchById(id);
    }

    public taskEstimateTypeIdPatchPatchById(id: number): MilestonePlansTaskEstimateTypeIdPatchPatchById {
        return new MilestonePlansTaskEstimateTypeIdPatchPatchById(id);
    }

    public defaultTaskTypeIdPatchPatchById(id: number): MilestonePlansDefaultTaskTypeIdPatchPatchById {
        return new MilestonePlansDefaultTaskTypeIdPatchPatchById(id);
    }

    public blockedPeriodAddPostById(id: number): MilestonePlansBlockedPeriodAddPostById {
        return new MilestonePlansBlockedPeriodAddPostById(id);
    }

    public blockedPeriodRemoveDeleteByIdByBlockedPeriodId(id: number, blockedPeriodId: number): MilestonePlansBlockedPeriodRemoveDeleteByIdByBlockedPeriodId {
        return new MilestonePlansBlockedPeriodRemoveDeleteByIdByBlockedPeriodId(id, blockedPeriodId);
    }

    public blockedHolidayAddPostById(id: number): MilestonePlansBlockedHolidayAddPostById {
        return new MilestonePlansBlockedHolidayAddPostById(id);
    }

    public blockedHolidayRemoveDeleteByIdByBlockedHolidayId(id: number, blockedHolidayId: number): MilestonePlansBlockedHolidayRemoveDeleteByIdByBlockedHolidayId {
        return new MilestonePlansBlockedHolidayRemoveDeleteByIdByBlockedHolidayId(id, blockedHolidayId);
    }

    public projectNotesPatchPatchById(id: number): MilestonePlansProjectNotesPatchPatchById {
        return new MilestonePlansProjectNotesPatchPatchById(id);
    }

    public useProjectResponsiblePatchById(id: number): MilestonePlansUseProjectResponsiblePatchById {
        return new MilestonePlansUseProjectResponsiblePatchById(id);
    }

}

export class MilestonePlansGet extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_plans`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public where(name: string, value: any): MilestonePlansGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): MilestonePlansGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): MilestonePlansGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): MilestonePlansGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): MilestonePlansGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): MilestonePlansGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): MilestonePlansGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): MilestonePlansGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): MilestonePlansGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): MilestonePlansGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): MilestonePlansGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): MilestonePlansGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): MilestonePlansGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): MilestonePlansGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): MilestonePlansGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): MilestonePlansGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): MilestonePlansGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: MilestonePlan[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestonePlan[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestonePlansGetById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public include(name: string): MilestonePlansGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: MilestonePlan[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestonePlan[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestonePlansPost extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_plans`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public save(data: MilestonePlan, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansPutById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public save(data: MilestonePlan, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansPut extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_plans`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public save(data: MilestonePlan, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansPatchById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public save(data: MilestonePlan, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansPatch extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_plans`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public save(data: MilestonePlan, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansDeleteById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public delete(next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class MilestonePlansCopyGetById extends BaseApi<MilestonePlansCopyResponse> {

    public topic = 'Resources.MilestonePlansCopyResponses';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/copy`;
    }

    protected convertToResource(data: any): MilestonePlansCopyResponse {
        return data;
    }

    public project_id(value: number): MilestonePlansCopyGetById {
        this.addQueryParameter('project_id', value);
        return this;
    }

    public find(next?: (value: MilestonePlansCopyResponse[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestonePlansCopyResponse[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestonePlansIndexingPut extends BaseApi<IntArrayInterface> {

    public topic = 'Resources.IntArrayInterfaces';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_plans/indexing`;
    }

    protected convertToResource(data: any): IntArrayInterface {
        return data;
    }

    public save(data: IntArrayInterface, next?: (value: IntArrayInterface) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansBlockedPeriodsGetById extends BaseApi<BlockedPeriod> {

    public topic = 'Resources.BlockedPeriods';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/blocked_periods`;
    }

    protected convertToResource(data: any): BlockedPeriod {
        return new BlockedPeriod(data);
    }

    public find(next?: (value: BlockedPeriod[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | BlockedPeriod[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestonePlansBlockedHolidaysGetById extends BaseApi<BlockedHoliday> {

    public topic = 'Resources.BlockedHolidays';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/blocked_holidays`;
    }

    protected convertToResource(data: any): BlockedHoliday {
        return new BlockedHoliday(data);
    }

    public find(next?: (value: BlockedHoliday[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | BlockedHoliday[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestonePlansMilestoneTemplatesGetById extends BaseApi<MilestoneTemplate> {

    public topic = 'Resources.MilestoneTemplates';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/milestone_templates`;
    }

    protected convertToResource(data: any): MilestoneTemplate {
        return new MilestoneTemplate(data);
    }

    public find(next?: (value: MilestoneTemplate[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestoneTemplate[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestonePlansTitlePatchPatchById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/title`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public title(value: string): MilestonePlansTitlePatchPatchById {
        this.addQueryParameter('title', value);
        return this;
    }

    public save(data: any, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansUserIdPatchPatchById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/user_id`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public user_id(value: number): MilestonePlansUserIdPatchPatchById {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public save(data: any, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansDepartmentsEnsurePutById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/departments`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public departmentIds(value: number[]): MilestonePlansDepartmentsEnsurePutById {
        this.addQueryParameter('departmentIds', value);
        return this;
    }

    public save(data: any, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansDepartmentAddPostByIdByDepartmentId extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor(id: number, departmentId: number) {
        super();
        this.uri = `/milestone_plans/${id}/departments/${departmentId}`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public save(data: any, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansDepartmentRemoveDeleteByIdByDepartmentId extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number, departmentId: number) {
        super();
        this.uri = `/milestone_plans/${id}/departments/${departmentId}`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public delete(next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class MilestonePlansProjectTypeIdPatchPatchById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/project_type_id`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public project_type_id(value: number): MilestonePlansProjectTypeIdPatchPatchById {
        this.addQueryParameter('project_type_id', value);
        return this;
    }

    public save(data: any, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansStartProjectDeadlineTypeIdPatchPatchById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/start_project_deadline_type_id`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public start_project_deadline_type_id(value: number): MilestonePlansStartProjectDeadlineTypeIdPatchPatchById {
        this.addQueryParameter('start_project_deadline_type_id', value);
        return this;
    }

    public save(data: any, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansEndProjectDeadlineTypeIdPatchPatchById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/end_project_deadline_type_id`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public end_project_deadline_type_id(value: number): MilestonePlansEndProjectDeadlineTypeIdPatchPatchById {
        this.addQueryParameter('end_project_deadline_type_id', value);
        return this;
    }

    public save(data: any, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansTaskEstimateTypeIdPatchPatchById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/task_estimate_type_id`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public task_estimate_type_id(value: number): MilestonePlansTaskEstimateTypeIdPatchPatchById {
        this.addQueryParameter('task_estimate_type_id', value);
        return this;
    }

    public save(data: any, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansDefaultTaskTypeIdPatchPatchById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/default_task_type_id`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public default_task_type_id(value: number): MilestonePlansDefaultTaskTypeIdPatchPatchById {
        this.addQueryParameter('default_task_type_id', value);
        return this;
    }

    public save(data: any, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansBlockedPeriodAddPostById extends BaseApi<BlockedPeriod> {

    public topic = 'Resources.BlockedPeriods';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/blocked_periods`;
    }

    protected convertToResource(data: any): BlockedPeriod {
        return new BlockedPeriod(data);
    }

    public save(data: BlockedPeriod, next?: (value: BlockedPeriod) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansBlockedPeriodRemoveDeleteByIdByBlockedPeriodId extends BaseApi<BlockedPeriod> {

    public topic = 'Resources.BlockedPeriods';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number, blockedPeriodId: number) {
        super();
        this.uri = `/milestone_plans/${id}/blocked_periods/${blockedPeriodId}`;
    }

    protected convertToResource(data: any): BlockedPeriod {
        return new BlockedPeriod(data);
    }

    public delete(next?: (value: BlockedPeriod) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class MilestonePlansBlockedHolidayAddPostById extends BaseApi<BlockedHoliday> {

    public topic = 'Resources.BlockedHolidays';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/blocked_holidays`;
    }

    protected convertToResource(data: any): BlockedHoliday {
        return new BlockedHoliday(data);
    }

    public save(data: BlockedHoliday, next?: (value: BlockedHoliday) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansBlockedHolidayRemoveDeleteByIdByBlockedHolidayId extends BaseApi<BlockedPeriod> {

    public topic = 'Resources.BlockedPeriods';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number, blockedHolidayId: number) {
        super();
        this.uri = `/milestone_plans/${id}/blocked_holidays/${blockedHolidayId}`;
    }

    protected convertToResource(data: any): BlockedPeriod {
        return new BlockedPeriod(data);
    }

    public delete(next?: (value: BlockedPeriod) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class MilestonePlansProjectNotesPatchPatchById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/project_notes`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public project_notes(value: string): MilestonePlansProjectNotesPatchPatchById {
        this.addQueryParameter('project_notes', value);
        return this;
    }

    public save(data: any, next?: (value: MilestonePlan) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonePlansUseProjectResponsiblePatchById extends BaseApi<MilestoneTemplate> {

    public topic = 'Resources.MilestoneTemplates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_plans/${id}/use_project_responsible`;
    }

    protected convertToResource(data: any): MilestoneTemplate {
        return new MilestoneTemplate(data);
    }

    public use_project_responsible(value: boolean): MilestonePlansUseProjectResponsiblePatchById {
        this.addQueryParameter('use_project_responsible', value);
        return this;
    }

    public save(data: any, next?: (value: MilestoneTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

class MilestoneReactionTypes {

    public get(): MilestoneReactionTypesGet {
        return new MilestoneReactionTypesGet();
    }

    public getById(id: number): MilestoneReactionTypesGetById {
        return new MilestoneReactionTypesGetById(id);
    }

    public post(): MilestoneReactionTypesPost {
        return new MilestoneReactionTypesPost();
    }

    public putById(id: number): MilestoneReactionTypesPutById {
        return new MilestoneReactionTypesPutById(id);
    }

    public put(): MilestoneReactionTypesPut {
        return new MilestoneReactionTypesPut();
    }

    public patchById(id: number): MilestoneReactionTypesPatchById {
        return new MilestoneReactionTypesPatchById(id);
    }

    public patch(): MilestoneReactionTypesPatch {
        return new MilestoneReactionTypesPatch();
    }

    public deleteById(id: number): MilestoneReactionTypesDeleteById {
        return new MilestoneReactionTypesDeleteById(id);
    }

}

export class MilestoneReactionTypesGet extends BaseApi<MilestoneReactionType> {

    public topic = 'Resources.MilestoneReactionTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_reaction_types`;
    }

    protected convertToResource(data: any): MilestoneReactionType {
        return new MilestoneReactionType(data);
    }

    public where(name: string, value: any): MilestoneReactionTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): MilestoneReactionTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): MilestoneReactionTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): MilestoneReactionTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): MilestoneReactionTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): MilestoneReactionTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): MilestoneReactionTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): MilestoneReactionTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): MilestoneReactionTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): MilestoneReactionTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): MilestoneReactionTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): MilestoneReactionTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): MilestoneReactionTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): MilestoneReactionTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): MilestoneReactionTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): MilestoneReactionTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): MilestoneReactionTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: MilestoneReactionType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestoneReactionType[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestoneReactionTypesGetById extends BaseApi<MilestoneReactionType> {

    public topic = 'Resources.MilestoneReactionTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_reaction_types/${id}`;
    }

    protected convertToResource(data: any): MilestoneReactionType {
        return new MilestoneReactionType(data);
    }

    public include(name: string): MilestoneReactionTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: MilestoneReactionType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestoneReactionType[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestoneReactionTypesPost extends BaseApi<MilestoneReactionType> {

    public topic = 'Resources.MilestoneReactionTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_reaction_types`;
    }

    protected convertToResource(data: any): MilestoneReactionType {
        return new MilestoneReactionType(data);
    }

    public save(data: MilestoneReactionType, next?: (value: MilestoneReactionType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneReactionTypesPutById extends BaseApi<MilestoneReactionType> {

    public topic = 'Resources.MilestoneReactionTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_reaction_types/${id}`;
    }

    protected convertToResource(data: any): MilestoneReactionType {
        return new MilestoneReactionType(data);
    }

    public save(data: MilestoneReactionType, next?: (value: MilestoneReactionType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneReactionTypesPut extends BaseApi<MilestoneReactionType> {

    public topic = 'Resources.MilestoneReactionTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_reaction_types`;
    }

    protected convertToResource(data: any): MilestoneReactionType {
        return new MilestoneReactionType(data);
    }

    public save(data: MilestoneReactionType, next?: (value: MilestoneReactionType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneReactionTypesPatchById extends BaseApi<MilestoneReactionType> {

    public topic = 'Resources.MilestoneReactionTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_reaction_types/${id}`;
    }

    protected convertToResource(data: any): MilestoneReactionType {
        return new MilestoneReactionType(data);
    }

    public save(data: MilestoneReactionType, next?: (value: MilestoneReactionType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneReactionTypesPatch extends BaseApi<MilestoneReactionType> {

    public topic = 'Resources.MilestoneReactionTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_reaction_types`;
    }

    protected convertToResource(data: any): MilestoneReactionType {
        return new MilestoneReactionType(data);
    }

    public save(data: MilestoneReactionType, next?: (value: MilestoneReactionType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneReactionTypesDeleteById extends BaseApi<MilestoneReactionType> {

    public topic = 'Resources.MilestoneReactionTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_reaction_types/${id}`;
    }

    protected convertToResource(data: any): MilestoneReactionType {
        return new MilestoneReactionType(data);
    }

    public delete(next?: (value: MilestoneReactionType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class MilestoneSettings {

    public get(): MilestoneSettingsGet {
        return new MilestoneSettingsGet();
    }

    public getById(id: number): MilestoneSettingsGetById {
        return new MilestoneSettingsGetById(id);
    }

    public post(): MilestoneSettingsPost {
        return new MilestoneSettingsPost();
    }

    public putById(id: number): MilestoneSettingsPutById {
        return new MilestoneSettingsPutById(id);
    }

    public put(): MilestoneSettingsPut {
        return new MilestoneSettingsPut();
    }

    public patchById(id: number): MilestoneSettingsPatchById {
        return new MilestoneSettingsPatchById(id);
    }

    public patch(): MilestoneSettingsPatch {
        return new MilestoneSettingsPatch();
    }

    public deleteById(id: number): MilestoneSettingsDeleteById {
        return new MilestoneSettingsDeleteById(id);
    }

}

export class MilestoneSettingsGet extends BaseApi<MilestoneSetting> {

    public topic = 'Resources.MilestoneSettings';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_settings`;
    }

    protected convertToResource(data: any): MilestoneSetting {
        return new MilestoneSetting(data);
    }

    public where(name: string, value: any): MilestoneSettingsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): MilestoneSettingsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): MilestoneSettingsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): MilestoneSettingsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): MilestoneSettingsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): MilestoneSettingsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): MilestoneSettingsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): MilestoneSettingsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): MilestoneSettingsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): MilestoneSettingsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): MilestoneSettingsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): MilestoneSettingsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): MilestoneSettingsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): MilestoneSettingsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): MilestoneSettingsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): MilestoneSettingsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): MilestoneSettingsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: MilestoneSetting[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestoneSetting[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestoneSettingsGetById extends BaseApi<MilestoneSetting> {

    public topic = 'Resources.MilestoneSettings';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_settings/${id}`;
    }

    protected convertToResource(data: any): MilestoneSetting {
        return new MilestoneSetting(data);
    }

    public include(name: string): MilestoneSettingsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: MilestoneSetting[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestoneSetting[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestoneSettingsPost extends BaseApi<MilestoneSetting> {

    public topic = 'Resources.MilestoneSettings';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_settings`;
    }

    protected convertToResource(data: any): MilestoneSetting {
        return new MilestoneSetting(data);
    }

    public save(data: MilestoneSetting, next?: (value: MilestoneSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneSettingsPutById extends BaseApi<MilestoneSetting> {

    public topic = 'Resources.MilestoneSettings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_settings/${id}`;
    }

    protected convertToResource(data: any): MilestoneSetting {
        return new MilestoneSetting(data);
    }

    public save(data: MilestoneSetting, next?: (value: MilestoneSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneSettingsPut extends BaseApi<MilestoneSetting> {

    public topic = 'Resources.MilestoneSettings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_settings`;
    }

    protected convertToResource(data: any): MilestoneSetting {
        return new MilestoneSetting(data);
    }

    public save(data: MilestoneSetting, next?: (value: MilestoneSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneSettingsPatchById extends BaseApi<MilestoneSetting> {

    public topic = 'Resources.MilestoneSettings';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_settings/${id}`;
    }

    protected convertToResource(data: any): MilestoneSetting {
        return new MilestoneSetting(data);
    }

    public save(data: MilestoneSetting, next?: (value: MilestoneSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneSettingsPatch extends BaseApi<MilestoneSetting> {

    public topic = 'Resources.MilestoneSettings';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_settings`;
    }

    protected convertToResource(data: any): MilestoneSetting {
        return new MilestoneSetting(data);
    }

    public save(data: MilestoneSetting, next?: (value: MilestoneSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneSettingsDeleteById extends BaseApi<MilestoneSetting> {

    public topic = 'Resources.MilestoneSettings';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_settings/${id}`;
    }

    protected convertToResource(data: any): MilestoneSetting {
        return new MilestoneSetting(data);
    }

    public delete(next?: (value: MilestoneSetting) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class MilestoneStatusTypes {

    public get(): MilestoneStatusTypesGet {
        return new MilestoneStatusTypesGet();
    }

    public getById(id: number): MilestoneStatusTypesGetById {
        return new MilestoneStatusTypesGetById(id);
    }

    public post(): MilestoneStatusTypesPost {
        return new MilestoneStatusTypesPost();
    }

    public putById(id: number): MilestoneStatusTypesPutById {
        return new MilestoneStatusTypesPutById(id);
    }

    public put(): MilestoneStatusTypesPut {
        return new MilestoneStatusTypesPut();
    }

    public patchById(id: number): MilestoneStatusTypesPatchById {
        return new MilestoneStatusTypesPatchById(id);
    }

    public patch(): MilestoneStatusTypesPatch {
        return new MilestoneStatusTypesPatch();
    }

    public deleteById(id: number): MilestoneStatusTypesDeleteById {
        return new MilestoneStatusTypesDeleteById(id);
    }

}

export class MilestoneStatusTypesGet extends BaseApi<MilestoneStatusType> {

    public topic = 'Resources.MilestoneStatusTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_status_types`;
    }

    protected convertToResource(data: any): MilestoneStatusType {
        return new MilestoneStatusType(data);
    }

    public where(name: string, value: any): MilestoneStatusTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): MilestoneStatusTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): MilestoneStatusTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): MilestoneStatusTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): MilestoneStatusTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): MilestoneStatusTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): MilestoneStatusTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): MilestoneStatusTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): MilestoneStatusTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): MilestoneStatusTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): MilestoneStatusTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): MilestoneStatusTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): MilestoneStatusTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): MilestoneStatusTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): MilestoneStatusTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): MilestoneStatusTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): MilestoneStatusTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: MilestoneStatusType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestoneStatusType[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestoneStatusTypesGetById extends BaseApi<MilestoneStatusType> {

    public topic = 'Resources.MilestoneStatusTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_status_types/${id}`;
    }

    protected convertToResource(data: any): MilestoneStatusType {
        return new MilestoneStatusType(data);
    }

    public include(name: string): MilestoneStatusTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: MilestoneStatusType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestoneStatusType[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestoneStatusTypesPost extends BaseApi<MilestoneStatusType> {

    public topic = 'Resources.MilestoneStatusTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_status_types`;
    }

    protected convertToResource(data: any): MilestoneStatusType {
        return new MilestoneStatusType(data);
    }

    public save(data: MilestoneStatusType, next?: (value: MilestoneStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneStatusTypesPutById extends BaseApi<MilestoneStatusType> {

    public topic = 'Resources.MilestoneStatusTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_status_types/${id}`;
    }

    protected convertToResource(data: any): MilestoneStatusType {
        return new MilestoneStatusType(data);
    }

    public save(data: MilestoneStatusType, next?: (value: MilestoneStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneStatusTypesPut extends BaseApi<MilestoneStatusType> {

    public topic = 'Resources.MilestoneStatusTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_status_types`;
    }

    protected convertToResource(data: any): MilestoneStatusType {
        return new MilestoneStatusType(data);
    }

    public save(data: MilestoneStatusType, next?: (value: MilestoneStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneStatusTypesPatchById extends BaseApi<MilestoneStatusType> {

    public topic = 'Resources.MilestoneStatusTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_status_types/${id}`;
    }

    protected convertToResource(data: any): MilestoneStatusType {
        return new MilestoneStatusType(data);
    }

    public save(data: MilestoneStatusType, next?: (value: MilestoneStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneStatusTypesPatch extends BaseApi<MilestoneStatusType> {

    public topic = 'Resources.MilestoneStatusTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_status_types`;
    }

    protected convertToResource(data: any): MilestoneStatusType {
        return new MilestoneStatusType(data);
    }

    public save(data: MilestoneStatusType, next?: (value: MilestoneStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneStatusTypesDeleteById extends BaseApi<MilestoneStatusType> {

    public topic = 'Resources.MilestoneStatusTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_status_types/${id}`;
    }

    protected convertToResource(data: any): MilestoneStatusType {
        return new MilestoneStatusType(data);
    }

    public delete(next?: (value: MilestoneStatusType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class MilestoneTemplates {

    public get(): MilestoneTemplatesGet {
        return new MilestoneTemplatesGet();
    }

    public getById(id: number): MilestoneTemplatesGetById {
        return new MilestoneTemplatesGetById(id);
    }

    public post(): MilestoneTemplatesPost {
        return new MilestoneTemplatesPost();
    }

    public putById(id: number): MilestoneTemplatesPutById {
        return new MilestoneTemplatesPutById(id);
    }

    public put(): MilestoneTemplatesPut {
        return new MilestoneTemplatesPut();
    }

    public patchById(id: number): MilestoneTemplatesPatchById {
        return new MilestoneTemplatesPatchById(id);
    }

    public patch(): MilestoneTemplatesPatch {
        return new MilestoneTemplatesPatch();
    }

    public deleteById(id: number): MilestoneTemplatesDeleteById {
        return new MilestoneTemplatesDeleteById(id);
    }

    public statusRulesGetById(id: number): MilestoneTemplatesStatusRulesGetById {
        return new MilestoneTemplatesStatusRulesGetById(id);
    }

    public taskTemplatesGetById(id: number): MilestoneTemplatesTaskTemplatesGetById {
        return new MilestoneTemplatesTaskTemplatesGetById(id);
    }

    public indexingPut(): MilestoneTemplatesIndexingPut {
        return new MilestoneTemplatesIndexingPut();
    }

    public titlePatchPatchById(id: number): MilestoneTemplatesTitlePatchPatchById {
        return new MilestoneTemplatesTitlePatchPatchById(id);
    }

    public deadlinePatchPatchById(id: number): MilestoneTemplatesDeadlinePatchPatchById {
        return new MilestoneTemplatesDeadlinePatchPatchById(id);
    }

    public phaseIdPatchPatchById(id: number): MilestoneTemplatesPhaseIdPatchPatchById {
        return new MilestoneTemplatesPhaseIdPatchPatchById(id);
    }

    public statusRuleAddPostById(id: number): MilestoneTemplatesStatusRuleAddPostById {
        return new MilestoneTemplatesStatusRuleAddPostById(id);
    }

    public statusRuleRemoveDeleteByIdByStatusRuleId(id: number, statusRuleId: number): MilestoneTemplatesStatusRuleRemoveDeleteByIdByStatusRuleId {
        return new MilestoneTemplatesStatusRuleRemoveDeleteByIdByStatusRuleId(id, statusRuleId);
    }

}

export class MilestoneTemplatesGet extends BaseApi<MilestoneTemplate> {

    public topic = 'Resources.MilestoneTemplates';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_templates`;
    }

    protected convertToResource(data: any): MilestoneTemplate {
        return new MilestoneTemplate(data);
    }

    public where(name: string, value: any): MilestoneTemplatesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): MilestoneTemplatesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): MilestoneTemplatesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): MilestoneTemplatesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): MilestoneTemplatesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): MilestoneTemplatesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): MilestoneTemplatesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): MilestoneTemplatesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): MilestoneTemplatesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): MilestoneTemplatesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): MilestoneTemplatesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): MilestoneTemplatesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): MilestoneTemplatesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): MilestoneTemplatesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): MilestoneTemplatesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): MilestoneTemplatesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): MilestoneTemplatesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: MilestoneTemplate[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestoneTemplate[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestoneTemplatesGetById extends BaseApi<MilestoneTemplate> {

    public topic = 'Resources.MilestoneTemplates';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_templates/${id}`;
    }

    protected convertToResource(data: any): MilestoneTemplate {
        return new MilestoneTemplate(data);
    }

    public include(name: string): MilestoneTemplatesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: MilestoneTemplate[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestoneTemplate[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestoneTemplatesPost extends BaseApi<MilestoneTemplate> {

    public topic = 'Resources.MilestoneTemplates';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_templates`;
    }

    protected convertToResource(data: any): MilestoneTemplate {
        return new MilestoneTemplate(data);
    }

    public save(data: MilestoneTemplate, next?: (value: MilestoneTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneTemplatesPutById extends BaseApi<MilestoneTemplate> {

    public topic = 'Resources.MilestoneTemplates';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_templates/${id}`;
    }

    protected convertToResource(data: any): MilestoneTemplate {
        return new MilestoneTemplate(data);
    }

    public save(data: MilestoneTemplate, next?: (value: MilestoneTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneTemplatesPut extends BaseApi<MilestoneTemplate> {

    public topic = 'Resources.MilestoneTemplates';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_templates`;
    }

    protected convertToResource(data: any): MilestoneTemplate {
        return new MilestoneTemplate(data);
    }

    public save(data: MilestoneTemplate, next?: (value: MilestoneTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneTemplatesPatchById extends BaseApi<MilestoneTemplate> {

    public topic = 'Resources.MilestoneTemplates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_templates/${id}`;
    }

    protected convertToResource(data: any): MilestoneTemplate {
        return new MilestoneTemplate(data);
    }

    public save(data: MilestoneTemplate, next?: (value: MilestoneTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneTemplatesPatch extends BaseApi<MilestoneTemplate> {

    public topic = 'Resources.MilestoneTemplates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_templates`;
    }

    protected convertToResource(data: any): MilestoneTemplate {
        return new MilestoneTemplate(data);
    }

    public save(data: MilestoneTemplate, next?: (value: MilestoneTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneTemplatesDeleteById extends BaseApi<MilestoneTemplate> {

    public topic = 'Resources.MilestoneTemplates';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_templates/${id}`;
    }

    protected convertToResource(data: any): MilestoneTemplate {
        return new MilestoneTemplate(data);
    }

    public delete(next?: (value: MilestoneTemplate) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class MilestoneTemplatesStatusRulesGetById extends BaseApi<StatusRule> {

    public topic = 'Resources.StatusRules';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_templates/${id}/status_rules`;
    }

    protected convertToResource(data: any): StatusRule {
        return new StatusRule(data);
    }

    public find(next?: (value: StatusRule[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | StatusRule[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestoneTemplatesTaskTemplatesGetById extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_templates/${id}/task_templates`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public find(next?: (value: TaskTemplate[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskTemplate[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestoneTemplatesIndexingPut extends BaseApi<IntArrayInterface> {

    public topic = 'Resources.IntArrayInterfaces';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestone_templates/indexing`;
    }

    protected convertToResource(data: any): IntArrayInterface {
        return data;
    }

    public save(data: IntArrayInterface, next?: (value: IntArrayInterface) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneTemplatesTitlePatchPatchById extends BaseApi<MilestoneTemplate> {

    public topic = 'Resources.MilestoneTemplates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_templates/${id}/title`;
    }

    protected convertToResource(data: any): MilestoneTemplate {
        return new MilestoneTemplate(data);
    }

    public title(value: string): MilestoneTemplatesTitlePatchPatchById {
        this.addQueryParameter('title', value);
        return this;
    }

    public save(data: any, next?: (value: MilestoneTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneTemplatesDeadlinePatchPatchById extends BaseApi<MilestoneTemplate> {

    public topic = 'Resources.MilestoneTemplates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_templates/${id}/deadline`;
    }

    protected convertToResource(data: any): MilestoneTemplate {
        return new MilestoneTemplate(data);
    }

    public value(value: number): MilestoneTemplatesDeadlinePatchPatchById {
        this.addQueryParameter('value', value);
        return this;
    }

    public type(value: number): MilestoneTemplatesDeadlinePatchPatchById {
        this.addQueryParameter('type', value);
        return this;
    }

    public save(data: any, next?: (value: MilestoneTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneTemplatesPhaseIdPatchPatchById extends BaseApi<MilestoneTemplate> {

    public topic = 'Resources.MilestoneTemplates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_templates/${id}/phase_id`;
    }

    protected convertToResource(data: any): MilestoneTemplate {
        return new MilestoneTemplate(data);
    }

    public phase_id(value: number): MilestoneTemplatesPhaseIdPatchPatchById {
        this.addQueryParameter('phase_id', value);
        return this;
    }

    public save(data: any, next?: (value: MilestoneTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneTemplatesStatusRuleAddPostById extends BaseApi<StatusRule> {

    public topic = 'Resources.StatusRules';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestone_templates/${id}/status_rules`;
    }

    protected convertToResource(data: any): StatusRule {
        return new StatusRule(data);
    }

    public save(data: StatusRule, next?: (value: StatusRule) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestoneTemplatesStatusRuleRemoveDeleteByIdByStatusRuleId extends BaseApi<StatusRule> {

    public topic = 'Resources.StatusRules';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number, statusRuleId: number) {
        super();
        this.uri = `/milestone_templates/${id}/status_rules/${statusRuleId}`;
    }

    protected convertToResource(data: any): StatusRule {
        return new StatusRule(data);
    }

    public delete(next?: (value: StatusRule) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Milestones {

    public get(): MilestonesGet {
        return new MilestonesGet();
    }

    public getById(id: number): MilestonesGetById {
        return new MilestonesGetById(id);
    }

    public deleteById(id: number): MilestonesDeleteById {
        return new MilestonesDeleteById(id);
    }

    public createPost(): MilestonesCreatePost {
        return new MilestonesCreatePost();
    }

    public updateTitlePutByMilestoneId(milestoneId: number): MilestonesUpdateTitlePutByMilestoneId {
        return new MilestonesUpdateTitlePutByMilestoneId(milestoneId);
    }

    public updateStatusPutByMilestoneId(milestoneId: number): MilestonesUpdateStatusPutByMilestoneId {
        return new MilestonesUpdateStatusPutByMilestoneId(milestoneId);
    }

    public updateArchivedPutByMilestoneId(milestoneId: number): MilestonesUpdateArchivedPutByMilestoneId {
        return new MilestonesUpdateArchivedPutByMilestoneId(milestoneId);
    }

    public updateResponsiblePutByMilestoneId(milestoneId: number): MilestonesUpdateResponsiblePutByMilestoneId {
        return new MilestonesUpdateResponsiblePutByMilestoneId(milestoneId);
    }

    public updateDeadlinePutByMilestoneId(milestoneId: number): MilestonesUpdateDeadlinePutByMilestoneId {
        return new MilestonesUpdateDeadlinePutByMilestoneId(milestoneId);
    }

    public updateUseStatusRulesPutByMilestoneId(milestoneId: number): MilestonesUpdateUseStatusRulesPutByMilestoneId {
        return new MilestonesUpdateUseStatusRulesPutByMilestoneId(milestoneId);
    }

    public updateNotesPutByMilestoneId(milestoneId: number): MilestonesUpdateNotesPutByMilestoneId {
        return new MilestonesUpdateNotesPutByMilestoneId(milestoneId);
    }

    public updateRiskPutByMilestoneId(milestoneId: number): MilestonesUpdateRiskPutByMilestoneId {
        return new MilestonesUpdateRiskPutByMilestoneId(milestoneId);
    }

    public addProjectPutByMilestoneId(milestoneId: number): MilestonesAddProjectPutByMilestoneId {
        return new MilestonesAddProjectPutByMilestoneId(milestoneId);
    }

    public removeProjectPutByMilestoneId(milestoneId: number): MilestonesRemoveProjectPutByMilestoneId {
        return new MilestonesRemoveProjectPutByMilestoneId(milestoneId);
    }

    public addCategoryPutByMilestoneId(milestoneId: number): MilestonesAddCategoryPutByMilestoneId {
        return new MilestonesAddCategoryPutByMilestoneId(milestoneId);
    }

    public removeCategoryPutByMilestoneId(milestoneId: number): MilestonesRemoveCategoryPutByMilestoneId {
        return new MilestonesRemoveCategoryPutByMilestoneId(milestoneId);
    }

    public starGetByMilestoneId(milestoneId: number): MilestonesStarGetByMilestoneId {
        return new MilestonesStarGetByMilestoneId(milestoneId);
    }

    public starPutByMilestoneId(milestoneId: number): MilestonesStarPutByMilestoneId {
        return new MilestonesStarPutByMilestoneId(milestoneId);
    }

    public starDeleteByMilestoneId(milestoneId: number): MilestonesStarDeleteByMilestoneId {
        return new MilestonesStarDeleteByMilestoneId(milestoneId);
    }

    public hand_upGetByMilestoneId(milestoneId: number): MilestonesHand_upGetByMilestoneId {
        return new MilestonesHand_upGetByMilestoneId(milestoneId);
    }

    public hand_upPutByMilestoneId(milestoneId: number): MilestonesHand_upPutByMilestoneId {
        return new MilestonesHand_upPutByMilestoneId(milestoneId);
    }

    public hand_upDeleteByMilestoneId(milestoneId: number): MilestonesHand_upDeleteByMilestoneId {
        return new MilestonesHand_upDeleteByMilestoneId(milestoneId);
    }

    public copyGetById(id: number): MilestonesCopyGetById {
        return new MilestonesCopyGetById(id);
    }

    public reactionAddPutByMilestoneId(milestoneId: number): MilestonesReactionAddPutByMilestoneId {
        return new MilestonesReactionAddPutByMilestoneId(milestoneId);
    }

    public reactionRemoveDeleteByMilestoneId(milestoneId: number): MilestonesReactionRemoveDeleteByMilestoneId {
        return new MilestonesReactionRemoveDeleteByMilestoneId(milestoneId);
    }

    public indexingPutById(id: number): MilestonesIndexingPutById {
        return new MilestonesIndexingPutById(id);
    }

    public deletedGet(): MilestonesDeletedGet {
        return new MilestonesDeletedGet();
    }

    public deleteDeleteById(id: number): MilestonesDeleteDeleteById {
        return new MilestonesDeleteDeleteById(id);
    }

    public logsGetByMilestoneId(milestoneId: number): MilestonesLogsGetByMilestoneId {
        return new MilestonesLogsGetByMilestoneId(milestoneId);
    }

}

export class MilestonesGet extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestones`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public where(name: string, value: any): MilestonesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): MilestonesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): MilestonesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): MilestonesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): MilestonesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): MilestonesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): MilestonesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): MilestonesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): MilestonesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): MilestonesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): MilestonesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): MilestonesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): MilestonesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): MilestonesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): MilestonesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): MilestonesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): MilestonesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Milestone[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Milestone[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestonesGetById extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestones/${id}`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public include(name: string): MilestonesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Milestone[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Milestone[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestonesDeleteById extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestones/${id}`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public delete(next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class MilestonesCreatePost extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestones/create`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public save(data: MilestonesCreateRequest, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesUpdateTitlePutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/title`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public value(value: string): MilestonesUpdateTitlePutByMilestoneId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesUpdateStatusPutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/status`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public value(value: number): MilestonesUpdateStatusPutByMilestoneId {
        this.addQueryParameter('value', value);
        return this;
    }

    public resetUseStatusRules(value: boolean): MilestonesUpdateStatusPutByMilestoneId {
        this.addQueryParameter('resetUseStatusRules', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesUpdateArchivedPutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/archived`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public value(value: boolean): MilestonesUpdateArchivedPutByMilestoneId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesUpdateResponsiblePutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/responsible`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public userId(value: number): MilestonesUpdateResponsiblePutByMilestoneId {
        this.addQueryParameter('userId', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesUpdateDeadlinePutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/deadline`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public date(value: string): MilestonesUpdateDeadlinePutByMilestoneId {
        this.addQueryParameter('date', value);
        return this;
    }

    public isSoft(value: boolean): MilestonesUpdateDeadlinePutByMilestoneId {
        this.addQueryParameter('isSoft', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesUpdateUseStatusRulesPutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/useStatusRules`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public value(value: boolean): MilestonesUpdateUseStatusRulesPutByMilestoneId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesUpdateNotesPutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/notes`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public value(value: string): MilestonesUpdateNotesPutByMilestoneId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesUpdateRiskPutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/risk`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public value(value: string): MilestonesUpdateRiskPutByMilestoneId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesAddProjectPutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/projects/add`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public value(value: number): MilestonesAddProjectPutByMilestoneId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesRemoveProjectPutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/projects/remove`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public value(value: number): MilestonesRemoveProjectPutByMilestoneId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesAddCategoryPutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/categories/add`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public value(value: number): MilestonesAddCategoryPutByMilestoneId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesRemoveCategoryPutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/categories/remove`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public value(value: number): MilestonesRemoveCategoryPutByMilestoneId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesStarGetByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/star`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public find(next?: (value: Milestone[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Milestone[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestonesStarPutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/star`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public user_id(value: number): MilestonesStarPutByMilestoneId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesStarDeleteByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/star`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public user_id(value: number): MilestonesStarDeleteByMilestoneId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public delete(next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class MilestonesHand_upGetByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/hand_up`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public find(next?: (value: Milestone[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Milestone[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestonesHand_upPutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/hand_up`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public user_id(value: number): MilestonesHand_upPutByMilestoneId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesHand_upDeleteByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/hand_up`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public user_id(value: number): MilestonesHand_upDeleteByMilestoneId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public delete(next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class MilestonesCopyGetById extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestones/${id}/copy`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public copy_tasks(value: boolean): MilestonesCopyGetById {
        this.addQueryParameter('copy_tasks', value);
        return this;
    }

    public find(next?: (value: Milestone[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Milestone[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestonesReactionAddPutByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/reactions`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public reaction_type_id(value: number): MilestonesReactionAddPutByMilestoneId {
        this.addQueryParameter('reaction_type_id', value);
        return this;
    }

    public value(value: string): MilestonesReactionAddPutByMilestoneId {
        this.addQueryParameter('value', value);
        return this;
    }

    public user_id(value: number): MilestonesReactionAddPutByMilestoneId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public save(data: any, next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesReactionRemoveDeleteByMilestoneId extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/reactions`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public reaction_type_id(value: number): MilestonesReactionRemoveDeleteByMilestoneId {
        this.addQueryParameter('reaction_type_id', value);
        return this;
    }

    public value(value: string): MilestonesReactionRemoveDeleteByMilestoneId {
        this.addQueryParameter('value', value);
        return this;
    }

    public user_id(value: number): MilestonesReactionRemoveDeleteByMilestoneId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public delete(next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class MilestonesIndexingPutById extends BaseApi<IntArrayInterface> {

    public topic = 'Resources.IntArrayInterfaces';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestones/${id}/tasks/indexing`;
    }

    protected convertToResource(data: any): IntArrayInterface {
        return data;
    }

    public save(data: IntArrayInterface, next?: (value: IntArrayInterface) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class MilestonesDeletedGet extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/milestones/deleted`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public limit(value: number): MilestonesDeletedGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): MilestonesDeletedGet {
        this.offsetValue = value;
        return this;
    }

    public find(next?: (value: Milestone[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Milestone[] | any[]> {
        return super.executeClientGet();
    }
}

export class MilestonesDeleteDeleteById extends BaseApi<Milestone> {

    public topic = 'Resources.Milestones';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/milestones/${id}`;
    }

    protected convertToResource(data: any): Milestone {
        return new Milestone(data);
    }

    public cascade(value: boolean): MilestonesDeleteDeleteById {
        this.addQueryParameter('cascade', value);
        return this;
    }

    public delete(next?: (value: Milestone) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class MilestonesLogsGetByMilestoneId extends BaseApi<Log> {

    public topic = 'Resources.Logs';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(milestoneId: number) {
        super();
        this.uri = `/milestones/${milestoneId}/logs`;
    }

    protected convertToResource(data: any): Log {
        return new Log(data);
    }

    public find(next?: (value: Log[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Log[] | any[]> {
        return super.executeClientGet();
    }
}

class Office365Events {

    public get(): Office365EventsGet {
        return new Office365EventsGet();
    }

    public getById(id: number): Office365EventsGetById {
        return new Office365EventsGetById(id);
    }

    public post(): Office365EventsPost {
        return new Office365EventsPost();
    }

    public putById(id: number): Office365EventsPutById {
        return new Office365EventsPutById(id);
    }

    public put(): Office365EventsPut {
        return new Office365EventsPut();
    }

    public patchById(id: number): Office365EventsPatchById {
        return new Office365EventsPatchById(id);
    }

    public patch(): Office365EventsPatch {
        return new Office365EventsPatch();
    }

    public deleteById(id: number): Office365EventsDeleteById {
        return new Office365EventsDeleteById(id);
    }

}

export class Office365EventsGet extends BaseApi<Office365Event> {

    public topic = 'Resources.Office365Events';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/office365_events`;
    }

    protected convertToResource(data: any): Office365Event {
        return new Office365Event(data);
    }

    public where(name: string, value: any): Office365EventsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): Office365EventsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): Office365EventsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): Office365EventsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): Office365EventsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): Office365EventsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): Office365EventsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): Office365EventsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): Office365EventsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): Office365EventsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): Office365EventsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): Office365EventsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): Office365EventsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): Office365EventsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): Office365EventsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): Office365EventsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): Office365EventsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Office365Event[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Office365Event[] | any[]> {
        return super.executeClientGet();
    }
}

export class Office365EventsGetById extends BaseApi<Office365Event> {

    public topic = 'Resources.Office365Events';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/office365_events/${id}`;
    }

    protected convertToResource(data: any): Office365Event {
        return new Office365Event(data);
    }

    public include(name: string): Office365EventsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Office365Event[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Office365Event[] | any[]> {
        return super.executeClientGet();
    }
}

export class Office365EventsPost extends BaseApi<Office365Event> {

    public topic = 'Resources.Office365Events';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/office365_events`;
    }

    protected convertToResource(data: any): Office365Event {
        return new Office365Event(data);
    }

    public save(data: Office365Event, next?: (value: Office365Event) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class Office365EventsPutById extends BaseApi<Office365Event> {

    public topic = 'Resources.Office365Events';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/office365_events/${id}`;
    }

    protected convertToResource(data: any): Office365Event {
        return new Office365Event(data);
    }

    public save(data: Office365Event, next?: (value: Office365Event) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class Office365EventsPut extends BaseApi<Office365Event> {

    public topic = 'Resources.Office365Events';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/office365_events`;
    }

    protected convertToResource(data: any): Office365Event {
        return new Office365Event(data);
    }

    public save(data: Office365Event, next?: (value: Office365Event) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class Office365EventsPatchById extends BaseApi<Office365Event> {

    public topic = 'Resources.Office365Events';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/office365_events/${id}`;
    }

    protected convertToResource(data: any): Office365Event {
        return new Office365Event(data);
    }

    public save(data: Office365Event, next?: (value: Office365Event) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class Office365EventsPatch extends BaseApi<Office365Event> {

    public topic = 'Resources.Office365Events';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/office365_events`;
    }

    protected convertToResource(data: any): Office365Event {
        return new Office365Event(data);
    }

    public save(data: Office365Event, next?: (value: Office365Event) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class Office365EventsDeleteById extends BaseApi<Office365Event> {

    public topic = 'Resources.Office365Events';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/office365_events/${id}`;
    }

    protected convertToResource(data: any): Office365Event {
        return new Office365Event(data);
    }

    public delete(next?: (value: Office365Event) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class PeriodRatings {

    public get(): PeriodRatingsGet {
        return new PeriodRatingsGet();
    }

    public getById(id: number): PeriodRatingsGetById {
        return new PeriodRatingsGetById(id);
    }

    public post(): PeriodRatingsPost {
        return new PeriodRatingsPost();
    }

    public putById(id: number): PeriodRatingsPutById {
        return new PeriodRatingsPutById(id);
    }

    public put(): PeriodRatingsPut {
        return new PeriodRatingsPut();
    }

    public patchById(id: number): PeriodRatingsPatchById {
        return new PeriodRatingsPatchById(id);
    }

    public patch(): PeriodRatingsPatch {
        return new PeriodRatingsPatch();
    }

    public deleteById(id: number): PeriodRatingsDeleteById {
        return new PeriodRatingsDeleteById(id);
    }

    public smartGet(): PeriodRatingsSmartGet {
        return new PeriodRatingsSmartGet();
    }

    public updateWorkHoursPutById(id: number): PeriodRatingsUpdateWorkHoursPutById {
        return new PeriodRatingsUpdateWorkHoursPutById(id);
    }

    public updateLoadPutById(id: number): PeriodRatingsUpdateLoadPutById {
        return new PeriodRatingsUpdateLoadPutById(id);
    }

    public updateCustomMeetingLoadPutById(id: number): PeriodRatingsUpdateCustomMeetingLoadPutById {
        return new PeriodRatingsUpdateCustomMeetingLoadPutById(id);
    }

}

export class PeriodRatingsGet extends BaseApi<PeriodRating> {

    public topic = 'Resources.PeriodRatings';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/period_ratings`;
    }

    protected convertToResource(data: any): PeriodRating {
        return new PeriodRating(data);
    }

    public where(name: string, value: any): PeriodRatingsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): PeriodRatingsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): PeriodRatingsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): PeriodRatingsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): PeriodRatingsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): PeriodRatingsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): PeriodRatingsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): PeriodRatingsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): PeriodRatingsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): PeriodRatingsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): PeriodRatingsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): PeriodRatingsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): PeriodRatingsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): PeriodRatingsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): PeriodRatingsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): PeriodRatingsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): PeriodRatingsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: PeriodRating[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | PeriodRating[] | any[]> {
        return super.executeClientGet();
    }
}

export class PeriodRatingsGetById extends BaseApi<PeriodRating> {

    public topic = 'Resources.PeriodRatings';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/period_ratings/${id}`;
    }

    protected convertToResource(data: any): PeriodRating {
        return new PeriodRating(data);
    }

    public include(name: string): PeriodRatingsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: PeriodRating[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | PeriodRating[] | any[]> {
        return super.executeClientGet();
    }
}

export class PeriodRatingsPost extends BaseApi<PeriodRating> {

    public topic = 'Resources.PeriodRatings';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/period_ratings`;
    }

    protected convertToResource(data: any): PeriodRating {
        return new PeriodRating(data);
    }

    public save(data: PeriodRating, next?: (value: PeriodRating) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PeriodRatingsPutById extends BaseApi<PeriodRating> {

    public topic = 'Resources.PeriodRatings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/period_ratings/${id}`;
    }

    protected convertToResource(data: any): PeriodRating {
        return new PeriodRating(data);
    }

    public save(data: PeriodRating, next?: (value: PeriodRating) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PeriodRatingsPut extends BaseApi<PeriodRating> {

    public topic = 'Resources.PeriodRatings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/period_ratings`;
    }

    protected convertToResource(data: any): PeriodRating {
        return new PeriodRating(data);
    }

    public save(data: PeriodRating, next?: (value: PeriodRating) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PeriodRatingsPatchById extends BaseApi<PeriodRating> {

    public topic = 'Resources.PeriodRatings';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/period_ratings/${id}`;
    }

    protected convertToResource(data: any): PeriodRating {
        return new PeriodRating(data);
    }

    public save(data: PeriodRating, next?: (value: PeriodRating) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PeriodRatingsPatch extends BaseApi<PeriodRating> {

    public topic = 'Resources.PeriodRatings';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/period_ratings`;
    }

    protected convertToResource(data: any): PeriodRating {
        return new PeriodRating(data);
    }

    public save(data: PeriodRating, next?: (value: PeriodRating) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PeriodRatingsDeleteById extends BaseApi<PeriodRating> {

    public topic = 'Resources.PeriodRatings';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/period_ratings/${id}`;
    }

    protected convertToResource(data: any): PeriodRating {
        return new PeriodRating(data);
    }

    public delete(next?: (value: PeriodRating) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class PeriodRatingsSmartGet extends BaseApi<PeriodRating> {

    public topic = 'Resources.PeriodRatings';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/period_ratings/smart`;
    }

    protected convertToResource(data: any): PeriodRating {
        return new PeriodRating(data);
    }

    public user_ids(value: number[]): PeriodRatingsSmartGet {
        this.addQueryParameter('user_ids', value);
        return this;
    }

    public department_id(value: number): PeriodRatingsSmartGet {
        this.addQueryParameter('department_id', value);
        return this;
    }

    public start(value: string): PeriodRatingsSmartGet {
        this.addQueryParameter('start', value);
        return this;
    }

    public end(value: string): PeriodRatingsSmartGet {
        this.addQueryParameter('end', value);
        return this;
    }

    public find(next?: (value: PeriodRating[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | PeriodRating[] | any[]> {
        return super.executeClientGet();
    }
}

export class PeriodRatingsUpdateWorkHoursPutById extends BaseApi<PeriodRating> {

    public topic = 'Resources.PeriodRatings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/period_ratings/${id}/work_hours`;
    }

    protected convertToResource(data: any): PeriodRating {
        return new PeriodRating(data);
    }

    public value(value: number): PeriodRatingsUpdateWorkHoursPutById {
        this.addQueryParameter('value', value);
        return this;
    }

    public updateAllFollowing(value: boolean): PeriodRatingsUpdateWorkHoursPutById {
        this.addQueryParameter('updateAllFollowing', value);
        return this;
    }

    public save(data: any, next?: (value: PeriodRating) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PeriodRatingsUpdateLoadPutById extends BaseApi<PeriodRating> {

    public topic = 'Resources.PeriodRatings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/period_ratings/${id}/load`;
    }

    protected convertToResource(data: any): PeriodRating {
        return new PeriodRating(data);
    }

    public value(value: number): PeriodRatingsUpdateLoadPutById {
        this.addQueryParameter('value', value);
        return this;
    }

    public updateAllFollowing(value: boolean): PeriodRatingsUpdateLoadPutById {
        this.addQueryParameter('updateAllFollowing', value);
        return this;
    }

    public save(data: any, next?: (value: PeriodRating) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PeriodRatingsUpdateCustomMeetingLoadPutById extends BaseApi<PeriodRating> {

    public topic = 'Resources.PeriodRatings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/period_ratings/${id}/custom_meeting_load`;
    }

    protected convertToResource(data: any): PeriodRating {
        return new PeriodRating(data);
    }

    public value(value: number): PeriodRatingsUpdateCustomMeetingLoadPutById {
        this.addQueryParameter('value', value);
        return this;
    }

    public updateAllFollowing(value: boolean): PeriodRatingsUpdateCustomMeetingLoadPutById {
        this.addQueryParameter('updateAllFollowing', value);
        return this;
    }

    public save(data: any, next?: (value: PeriodRating) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

class Periods {

    public get(): PeriodsGet {
        return new PeriodsGet();
    }

    public getById(id: number): PeriodsGetById {
        return new PeriodsGetById(id);
    }

    public post(): PeriodsPost {
        return new PeriodsPost();
    }

    public putById(id: number): PeriodsPutById {
        return new PeriodsPutById(id);
    }

    public put(): PeriodsPut {
        return new PeriodsPut();
    }

    public patchById(id: number): PeriodsPatchById {
        return new PeriodsPatchById(id);
    }

    public patch(): PeriodsPatch {
        return new PeriodsPatch();
    }

    public deleteById(id: number): PeriodsDeleteById {
        return new PeriodsDeleteById(id);
    }

}

export class PeriodsGet extends BaseApi<Period> {

    public topic = 'Resources.Periods';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/periods`;
    }

    protected convertToResource(data: any): Period {
        return new Period(data);
    }

    public where(name: string, value: any): PeriodsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): PeriodsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): PeriodsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): PeriodsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): PeriodsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): PeriodsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): PeriodsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): PeriodsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): PeriodsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): PeriodsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): PeriodsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): PeriodsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): PeriodsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): PeriodsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): PeriodsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): PeriodsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): PeriodsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Period[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Period[] | any[]> {
        return super.executeClientGet();
    }
}

export class PeriodsGetById extends BaseApi<Period> {

    public topic = 'Resources.Periods';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/periods/${id}`;
    }

    protected convertToResource(data: any): Period {
        return new Period(data);
    }

    public include(name: string): PeriodsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Period[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Period[] | any[]> {
        return super.executeClientGet();
    }
}

export class PeriodsPost extends BaseApi<Period> {

    public topic = 'Resources.Periods';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/periods`;
    }

    protected convertToResource(data: any): Period {
        return new Period(data);
    }

    public save(data: Period, next?: (value: Period) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PeriodsPutById extends BaseApi<Period> {

    public topic = 'Resources.Periods';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/periods/${id}`;
    }

    protected convertToResource(data: any): Period {
        return new Period(data);
    }

    public save(data: Period, next?: (value: Period) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PeriodsPut extends BaseApi<Period> {

    public topic = 'Resources.Periods';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/periods`;
    }

    protected convertToResource(data: any): Period {
        return new Period(data);
    }

    public save(data: Period, next?: (value: Period) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PeriodsPatchById extends BaseApi<Period> {

    public topic = 'Resources.Periods';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/periods/${id}`;
    }

    protected convertToResource(data: any): Period {
        return new Period(data);
    }

    public save(data: Period, next?: (value: Period) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PeriodsPatch extends BaseApi<Period> {

    public topic = 'Resources.Periods';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/periods`;
    }

    protected convertToResource(data: any): Period {
        return new Period(data);
    }

    public save(data: Period, next?: (value: Period) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PeriodsDeleteById extends BaseApi<Period> {

    public topic = 'Resources.Periods';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/periods/${id}`;
    }

    protected convertToResource(data: any): Period {
        return new Period(data);
    }

    public delete(next?: (value: Period) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class PhaseProgressTypes {

    public get(): PhaseProgressTypesGet {
        return new PhaseProgressTypesGet();
    }

    public getById(id: number): PhaseProgressTypesGetById {
        return new PhaseProgressTypesGetById(id);
    }

    public post(): PhaseProgressTypesPost {
        return new PhaseProgressTypesPost();
    }

    public putById(id: number): PhaseProgressTypesPutById {
        return new PhaseProgressTypesPutById(id);
    }

    public put(): PhaseProgressTypesPut {
        return new PhaseProgressTypesPut();
    }

    public patchById(id: number): PhaseProgressTypesPatchById {
        return new PhaseProgressTypesPatchById(id);
    }

    public patch(): PhaseProgressTypesPatch {
        return new PhaseProgressTypesPatch();
    }

    public deleteById(id: number): PhaseProgressTypesDeleteById {
        return new PhaseProgressTypesDeleteById(id);
    }

}

export class PhaseProgressTypesGet extends BaseApi<PhaseProgressType> {

    public topic = 'Resources.PhaseProgressTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/phase_progress_types`;
    }

    protected convertToResource(data: any): PhaseProgressType {
        return new PhaseProgressType(data);
    }

    public where(name: string, value: any): PhaseProgressTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): PhaseProgressTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): PhaseProgressTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): PhaseProgressTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): PhaseProgressTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): PhaseProgressTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): PhaseProgressTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): PhaseProgressTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): PhaseProgressTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): PhaseProgressTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): PhaseProgressTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): PhaseProgressTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): PhaseProgressTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): PhaseProgressTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): PhaseProgressTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): PhaseProgressTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): PhaseProgressTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: PhaseProgressType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | PhaseProgressType[] | any[]> {
        return super.executeClientGet();
    }
}

export class PhaseProgressTypesGetById extends BaseApi<PhaseProgressType> {

    public topic = 'Resources.PhaseProgressTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/phase_progress_types/${id}`;
    }

    protected convertToResource(data: any): PhaseProgressType {
        return new PhaseProgressType(data);
    }

    public include(name: string): PhaseProgressTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: PhaseProgressType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | PhaseProgressType[] | any[]> {
        return super.executeClientGet();
    }
}

export class PhaseProgressTypesPost extends BaseApi<PhaseProgressType> {

    public topic = 'Resources.PhaseProgressTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/phase_progress_types`;
    }

    protected convertToResource(data: any): PhaseProgressType {
        return new PhaseProgressType(data);
    }

    public save(data: PhaseProgressType, next?: (value: PhaseProgressType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PhaseProgressTypesPutById extends BaseApi<PhaseProgressType> {

    public topic = 'Resources.PhaseProgressTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/phase_progress_types/${id}`;
    }

    protected convertToResource(data: any): PhaseProgressType {
        return new PhaseProgressType(data);
    }

    public save(data: PhaseProgressType, next?: (value: PhaseProgressType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PhaseProgressTypesPut extends BaseApi<PhaseProgressType> {

    public topic = 'Resources.PhaseProgressTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/phase_progress_types`;
    }

    protected convertToResource(data: any): PhaseProgressType {
        return new PhaseProgressType(data);
    }

    public save(data: PhaseProgressType, next?: (value: PhaseProgressType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PhaseProgressTypesPatchById extends BaseApi<PhaseProgressType> {

    public topic = 'Resources.PhaseProgressTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/phase_progress_types/${id}`;
    }

    protected convertToResource(data: any): PhaseProgressType {
        return new PhaseProgressType(data);
    }

    public save(data: PhaseProgressType, next?: (value: PhaseProgressType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PhaseProgressTypesPatch extends BaseApi<PhaseProgressType> {

    public topic = 'Resources.PhaseProgressTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/phase_progress_types`;
    }

    protected convertToResource(data: any): PhaseProgressType {
        return new PhaseProgressType(data);
    }

    public save(data: PhaseProgressType, next?: (value: PhaseProgressType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PhaseProgressTypesDeleteById extends BaseApi<PhaseProgressType> {

    public topic = 'Resources.PhaseProgressTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/phase_progress_types/${id}`;
    }

    protected convertToResource(data: any): PhaseProgressType {
        return new PhaseProgressType(data);
    }

    public delete(next?: (value: PhaseProgressType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Phases {

    public get(): PhasesGet {
        return new PhasesGet();
    }

    public getById(id: number): PhasesGetById {
        return new PhasesGetById(id);
    }

    public post(): PhasesPost {
        return new PhasesPost();
    }

    public putById(id: number): PhasesPutById {
        return new PhasesPutById(id);
    }

    public put(): PhasesPut {
        return new PhasesPut();
    }

    public patchById(id: number): PhasesPatchById {
        return new PhasesPatchById(id);
    }

    public patch(): PhasesPatch {
        return new PhasesPatch();
    }

    public deleteById(id: number): PhasesDeleteById {
        return new PhasesDeleteById(id);
    }

}

export class PhasesGet extends BaseApi<Phase> {

    public topic = 'Resources.Phases';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/phases`;
    }

    protected convertToResource(data: any): Phase {
        return new Phase(data);
    }

    public where(name: string, value: any): PhasesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): PhasesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): PhasesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): PhasesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): PhasesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): PhasesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): PhasesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): PhasesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): PhasesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): PhasesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): PhasesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): PhasesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): PhasesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): PhasesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): PhasesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): PhasesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): PhasesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Phase[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Phase[] | any[]> {
        return super.executeClientGet();
    }
}

export class PhasesGetById extends BaseApi<Phase> {

    public topic = 'Resources.Phases';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/phases/${id}`;
    }

    protected convertToResource(data: any): Phase {
        return new Phase(data);
    }

    public include(name: string): PhasesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Phase[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Phase[] | any[]> {
        return super.executeClientGet();
    }
}

export class PhasesPost extends BaseApi<Phase> {

    public topic = 'Resources.Phases';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/phases`;
    }

    protected convertToResource(data: any): Phase {
        return new Phase(data);
    }

    public save(data: Phase, next?: (value: Phase) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PhasesPutById extends BaseApi<Phase> {

    public topic = 'Resources.Phases';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/phases/${id}`;
    }

    protected convertToResource(data: any): Phase {
        return new Phase(data);
    }

    public save(data: Phase, next?: (value: Phase) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PhasesPut extends BaseApi<Phase> {

    public topic = 'Resources.Phases';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/phases`;
    }

    protected convertToResource(data: any): Phase {
        return new Phase(data);
    }

    public save(data: Phase, next?: (value: Phase) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PhasesPatchById extends BaseApi<Phase> {

    public topic = 'Resources.Phases';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/phases/${id}`;
    }

    protected convertToResource(data: any): Phase {
        return new Phase(data);
    }

    public save(data: Phase, next?: (value: Phase) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PhasesPatch extends BaseApi<Phase> {

    public topic = 'Resources.Phases';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/phases`;
    }

    protected convertToResource(data: any): Phase {
        return new Phase(data);
    }

    public save(data: Phase, next?: (value: Phase) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class PhasesDeleteById extends BaseApi<Phase> {

    public topic = 'Resources.Phases';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/phases/${id}`;
    }

    protected convertToResource(data: any): Phase {
        return new Phase(data);
    }

    public delete(next?: (value: Phase) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class ProjectCases {

    public get(): ProjectCasesGet {
        return new ProjectCasesGet();
    }

    public getById(id: number): ProjectCasesGetById {
        return new ProjectCasesGetById(id);
    }

    public deleteById(id: number): ProjectCasesDeleteById {
        return new ProjectCasesDeleteById(id);
    }

    public updateCasesPutById(id: number): ProjectCasesUpdateCasesPutById {
        return new ProjectCasesUpdateCasesPutById(id);
    }

    public updateGoalPutById(id: number): ProjectCasesUpdateGoalPutById {
        return new ProjectCasesUpdateGoalPutById(id);
    }

    public updateCasesText1PutById(id: number): ProjectCasesUpdateCasesText1PutById {
        return new ProjectCasesUpdateCasesText1PutById(id);
    }

    public updateCasesText2PutById(id: number): ProjectCasesUpdateCasesText2PutById {
        return new ProjectCasesUpdateCasesText2PutById(id);
    }

    public updateCasesText3PutById(id: number): ProjectCasesUpdateCasesText3PutById {
        return new ProjectCasesUpdateCasesText3PutById(id);
    }

    public updateGoalText1PutById(id: number): ProjectCasesUpdateGoalText1PutById {
        return new ProjectCasesUpdateGoalText1PutById(id);
    }

    public updateGoalText2PutById(id: number): ProjectCasesUpdateGoalText2PutById {
        return new ProjectCasesUpdateGoalText2PutById(id);
    }

    public updateGoalText3PutById(id: number): ProjectCasesUpdateGoalText3PutById {
        return new ProjectCasesUpdateGoalText3PutById(id);
    }

    public summaryGet(): ProjectCasesSummaryGet {
        return new ProjectCasesSummaryGet();
    }

    public smartGet(): ProjectCasesSmartGet {
        return new ProjectCasesSmartGet();
    }

    public copyGet(): ProjectCasesCopyGet {
        return new ProjectCasesCopyGet();
    }

}

export class ProjectCasesGet extends BaseApi<ProjectCase> {

    public topic = 'Resources.ProjectCases';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_cases`;
    }

    protected convertToResource(data: any): ProjectCase {
        return new ProjectCase(data);
    }

    public where(name: string, value: any): ProjectCasesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): ProjectCasesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): ProjectCasesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): ProjectCasesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): ProjectCasesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): ProjectCasesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): ProjectCasesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): ProjectCasesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): ProjectCasesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): ProjectCasesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): ProjectCasesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): ProjectCasesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): ProjectCasesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): ProjectCasesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): ProjectCasesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): ProjectCasesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): ProjectCasesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: ProjectCase[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectCase[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectCasesGetById extends BaseApi<ProjectCase> {

    public topic = 'Resources.ProjectCases';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_cases/${id}`;
    }

    protected convertToResource(data: any): ProjectCase {
        return new ProjectCase(data);
    }

    public include(name: string): ProjectCasesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: ProjectCase[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectCase[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectCasesDeleteById extends BaseApi<ProjectCase> {

    public topic = 'Resources.ProjectCases';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_cases/${id}`;
    }

    protected convertToResource(data: any): ProjectCase {
        return new ProjectCase(data);
    }

    public delete(next?: (value: ProjectCase) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class ProjectCasesUpdateCasesPutById extends BaseApi<ProjectCase> {

    public topic = 'Resources.ProjectCases';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_cases/${id}/cases`;
    }

    protected convertToResource(data: any): ProjectCase {
        return new ProjectCase(data);
    }

    public value(value: number): ProjectCasesUpdateCasesPutById {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: ProjectCase) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectCasesUpdateGoalPutById extends BaseApi<ProjectCase> {

    public topic = 'Resources.ProjectCases';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_cases/${id}/goal`;
    }

    protected convertToResource(data: any): ProjectCase {
        return new ProjectCase(data);
    }

    public value(value: number): ProjectCasesUpdateGoalPutById {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: ProjectCase) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectCasesUpdateCasesText1PutById extends BaseApi<ProjectCase> {

    public topic = 'Resources.ProjectCases';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_cases/${id}/casesText1`;
    }

    protected convertToResource(data: any): ProjectCase {
        return new ProjectCase(data);
    }

    public value(value: string): ProjectCasesUpdateCasesText1PutById {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: ProjectCase) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectCasesUpdateCasesText2PutById extends BaseApi<ProjectCase> {

    public topic = 'Resources.ProjectCases';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_cases/${id}/casesText2`;
    }

    protected convertToResource(data: any): ProjectCase {
        return new ProjectCase(data);
    }

    public value(value: string): ProjectCasesUpdateCasesText2PutById {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: ProjectCase) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectCasesUpdateCasesText3PutById extends BaseApi<ProjectCase> {

    public topic = 'Resources.ProjectCases';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_cases/${id}/casesText3`;
    }

    protected convertToResource(data: any): ProjectCase {
        return new ProjectCase(data);
    }

    public value(value: string): ProjectCasesUpdateCasesText3PutById {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: ProjectCase) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectCasesUpdateGoalText1PutById extends BaseApi<ProjectCase> {

    public topic = 'Resources.ProjectCases';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_cases/${id}/goalText1`;
    }

    protected convertToResource(data: any): ProjectCase {
        return new ProjectCase(data);
    }

    public value(value: string): ProjectCasesUpdateGoalText1PutById {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: ProjectCase) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectCasesUpdateGoalText2PutById extends BaseApi<ProjectCase> {

    public topic = 'Resources.ProjectCases';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_cases/${id}/goalText2`;
    }

    protected convertToResource(data: any): ProjectCase {
        return new ProjectCase(data);
    }

    public value(value: string): ProjectCasesUpdateGoalText2PutById {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: ProjectCase) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectCasesUpdateGoalText3PutById extends BaseApi<ProjectCase> {

    public topic = 'Resources.ProjectCases';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_cases/${id}/goalText3`;
    }

    protected convertToResource(data: any): ProjectCase {
        return new ProjectCase(data);
    }

    public value(value: string): ProjectCasesUpdateGoalText3PutById {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: ProjectCase) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectCasesSummaryGet extends BaseApi<ProjectCasesSummaryResponse> {

    public topic = 'Resources.ProjectCasesSummaryResponses';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_cases/summary`;
    }

    protected convertToResource(data: any): ProjectCasesSummaryResponse {
        return data;
    }

    public start(value: string): ProjectCasesSummaryGet {
        this.addQueryParameter('start', value);
        return this;
    }

    public end(value: string): ProjectCasesSummaryGet {
        this.addQueryParameter('end', value);
        return this;
    }

    public department_id(value: number): ProjectCasesSummaryGet {
        this.addQueryParameter('department_id', value);
        return this;
    }

    public where(name: string, value: any): ProjectCasesSummaryGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): ProjectCasesSummaryGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): ProjectCasesSummaryGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): ProjectCasesSummaryGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): ProjectCasesSummaryGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): ProjectCasesSummaryGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): ProjectCasesSummaryGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): ProjectCasesSummaryGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): ProjectCasesSummaryGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): ProjectCasesSummaryGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): ProjectCasesSummaryGet {
        this.filter().search(name, value);
        return this;
    }

    public find(next?: (value: ProjectCasesSummaryResponse[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectCasesSummaryResponse[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectCasesSmartGet extends BaseApi<ProjectCase> {

    public topic = 'Resources.ProjectCases';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_cases/smart`;
    }

    protected convertToResource(data: any): ProjectCase {
        return new ProjectCase(data);
    }

    public project_ids(value: number[]): ProjectCasesSmartGet {
        this.addQueryParameter('project_ids', value);
        return this;
    }

    public date(value: string): ProjectCasesSmartGet {
        this.addQueryParameter('date', value);
        return this;
    }

    public find(next?: (value: ProjectCase[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectCase[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectCasesCopyGet extends BaseApi<ProjectCasesCopyResponse> {

    public topic = 'Resources.ProjectCasesCopyResponses';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_cases/copy`;
    }

    protected convertToResource(data: any): ProjectCasesCopyResponse {
        return data;
    }

    public project_ids(value: number[]): ProjectCasesCopyGet {
        this.addQueryParameter('project_ids', value);
        return this;
    }

    public from_date(value: string): ProjectCasesCopyGet {
        this.addQueryParameter('from_date', value);
        return this;
    }

    public to_date(value: string): ProjectCasesCopyGet {
        this.addQueryParameter('to_date', value);
        return this;
    }

    public department_id(value: number): ProjectCasesCopyGet {
        this.addQueryParameter('department_id', value);
        return this;
    }

    public subtract_task_estimates(value: boolean): ProjectCasesCopyGet {
        this.addQueryParameter('subtract_task_estimates', value);
        return this;
    }

    public readonly(value: boolean): ProjectCasesCopyGet {
        this.addQueryParameter('readonly', value);
        return this;
    }

    public find(next?: (value: ProjectCasesCopyResponse[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectCasesCopyResponse[] | any[]> {
        return super.executeClientGet();
    }
}

class ProjectDeadlineTypes {

    public get(): ProjectDeadlineTypesGet {
        return new ProjectDeadlineTypesGet();
    }

    public getById(id: number): ProjectDeadlineTypesGetById {
        return new ProjectDeadlineTypesGetById(id);
    }

    public post(): ProjectDeadlineTypesPost {
        return new ProjectDeadlineTypesPost();
    }

    public putById(id: number): ProjectDeadlineTypesPutById {
        return new ProjectDeadlineTypesPutById(id);
    }

    public put(): ProjectDeadlineTypesPut {
        return new ProjectDeadlineTypesPut();
    }

    public patchById(id: number): ProjectDeadlineTypesPatchById {
        return new ProjectDeadlineTypesPatchById(id);
    }

    public patch(): ProjectDeadlineTypesPatch {
        return new ProjectDeadlineTypesPatch();
    }

    public deleteById(id: number): ProjectDeadlineTypesDeleteById {
        return new ProjectDeadlineTypesDeleteById(id);
    }

}

export class ProjectDeadlineTypesGet extends BaseApi<ProjectDeadlineType> {

    public topic = 'Resources.ProjectDeadlineTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_deadline_types`;
    }

    protected convertToResource(data: any): ProjectDeadlineType {
        return new ProjectDeadlineType(data);
    }

    public where(name: string, value: any): ProjectDeadlineTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): ProjectDeadlineTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): ProjectDeadlineTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): ProjectDeadlineTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): ProjectDeadlineTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): ProjectDeadlineTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): ProjectDeadlineTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): ProjectDeadlineTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): ProjectDeadlineTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): ProjectDeadlineTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): ProjectDeadlineTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): ProjectDeadlineTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): ProjectDeadlineTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): ProjectDeadlineTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): ProjectDeadlineTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): ProjectDeadlineTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): ProjectDeadlineTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: ProjectDeadlineType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectDeadlineType[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectDeadlineTypesGetById extends BaseApi<ProjectDeadlineType> {

    public topic = 'Resources.ProjectDeadlineTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_deadline_types/${id}`;
    }

    protected convertToResource(data: any): ProjectDeadlineType {
        return new ProjectDeadlineType(data);
    }

    public include(name: string): ProjectDeadlineTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: ProjectDeadlineType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectDeadlineType[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectDeadlineTypesPost extends BaseApi<ProjectDeadlineType> {

    public topic = 'Resources.ProjectDeadlineTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_deadline_types`;
    }

    protected convertToResource(data: any): ProjectDeadlineType {
        return new ProjectDeadlineType(data);
    }

    public save(data: ProjectDeadlineType, next?: (value: ProjectDeadlineType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectDeadlineTypesPutById extends BaseApi<ProjectDeadlineType> {

    public topic = 'Resources.ProjectDeadlineTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_deadline_types/${id}`;
    }

    protected convertToResource(data: any): ProjectDeadlineType {
        return new ProjectDeadlineType(data);
    }

    public save(data: ProjectDeadlineType, next?: (value: ProjectDeadlineType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectDeadlineTypesPut extends BaseApi<ProjectDeadlineType> {

    public topic = 'Resources.ProjectDeadlineTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_deadline_types`;
    }

    protected convertToResource(data: any): ProjectDeadlineType {
        return new ProjectDeadlineType(data);
    }

    public save(data: ProjectDeadlineType, next?: (value: ProjectDeadlineType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectDeadlineTypesPatchById extends BaseApi<ProjectDeadlineType> {

    public topic = 'Resources.ProjectDeadlineTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_deadline_types/${id}`;
    }

    protected convertToResource(data: any): ProjectDeadlineType {
        return new ProjectDeadlineType(data);
    }

    public save(data: ProjectDeadlineType, next?: (value: ProjectDeadlineType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectDeadlineTypesPatch extends BaseApi<ProjectDeadlineType> {

    public topic = 'Resources.ProjectDeadlineTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_deadline_types`;
    }

    protected convertToResource(data: any): ProjectDeadlineType {
        return new ProjectDeadlineType(data);
    }

    public save(data: ProjectDeadlineType, next?: (value: ProjectDeadlineType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectDeadlineTypesDeleteById extends BaseApi<ProjectDeadlineType> {

    public topic = 'Resources.ProjectDeadlineTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_deadline_types/${id}`;
    }

    protected convertToResource(data: any): ProjectDeadlineType {
        return new ProjectDeadlineType(data);
    }

    public delete(next?: (value: ProjectDeadlineType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class ProjectEstimateTypes {

    public get(): ProjectEstimateTypesGet {
        return new ProjectEstimateTypesGet();
    }

    public getById(id: number): ProjectEstimateTypesGetById {
        return new ProjectEstimateTypesGetById(id);
    }

    public post(): ProjectEstimateTypesPost {
        return new ProjectEstimateTypesPost();
    }

    public putById(id: number): ProjectEstimateTypesPutById {
        return new ProjectEstimateTypesPutById(id);
    }

    public put(): ProjectEstimateTypesPut {
        return new ProjectEstimateTypesPut();
    }

    public patchById(id: number): ProjectEstimateTypesPatchById {
        return new ProjectEstimateTypesPatchById(id);
    }

    public patch(): ProjectEstimateTypesPatch {
        return new ProjectEstimateTypesPatch();
    }

    public deleteById(id: number): ProjectEstimateTypesDeleteById {
        return new ProjectEstimateTypesDeleteById(id);
    }

}

export class ProjectEstimateTypesGet extends BaseApi<ProjectEstimateType> {

    public topic = 'Resources.ProjectEstimateTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_estimate_types`;
    }

    protected convertToResource(data: any): ProjectEstimateType {
        return new ProjectEstimateType(data);
    }

    public where(name: string, value: any): ProjectEstimateTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): ProjectEstimateTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): ProjectEstimateTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): ProjectEstimateTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): ProjectEstimateTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): ProjectEstimateTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): ProjectEstimateTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): ProjectEstimateTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): ProjectEstimateTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): ProjectEstimateTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): ProjectEstimateTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): ProjectEstimateTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): ProjectEstimateTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): ProjectEstimateTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): ProjectEstimateTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): ProjectEstimateTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): ProjectEstimateTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: ProjectEstimateType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectEstimateType[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectEstimateTypesGetById extends BaseApi<ProjectEstimateType> {

    public topic = 'Resources.ProjectEstimateTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_estimate_types/${id}`;
    }

    protected convertToResource(data: any): ProjectEstimateType {
        return new ProjectEstimateType(data);
    }

    public include(name: string): ProjectEstimateTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: ProjectEstimateType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectEstimateType[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectEstimateTypesPost extends BaseApi<ProjectEstimateType> {

    public topic = 'Resources.ProjectEstimateTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_estimate_types`;
    }

    protected convertToResource(data: any): ProjectEstimateType {
        return new ProjectEstimateType(data);
    }

    public save(data: ProjectEstimateType, next?: (value: ProjectEstimateType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectEstimateTypesPutById extends BaseApi<ProjectEstimateType> {

    public topic = 'Resources.ProjectEstimateTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_estimate_types/${id}`;
    }

    protected convertToResource(data: any): ProjectEstimateType {
        return new ProjectEstimateType(data);
    }

    public save(data: ProjectEstimateType, next?: (value: ProjectEstimateType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectEstimateTypesPut extends BaseApi<ProjectEstimateType> {

    public topic = 'Resources.ProjectEstimateTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_estimate_types`;
    }

    protected convertToResource(data: any): ProjectEstimateType {
        return new ProjectEstimateType(data);
    }

    public save(data: ProjectEstimateType, next?: (value: ProjectEstimateType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectEstimateTypesPatchById extends BaseApi<ProjectEstimateType> {

    public topic = 'Resources.ProjectEstimateTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_estimate_types/${id}`;
    }

    protected convertToResource(data: any): ProjectEstimateType {
        return new ProjectEstimateType(data);
    }

    public save(data: ProjectEstimateType, next?: (value: ProjectEstimateType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectEstimateTypesPatch extends BaseApi<ProjectEstimateType> {

    public topic = 'Resources.ProjectEstimateTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_estimate_types`;
    }

    protected convertToResource(data: any): ProjectEstimateType {
        return new ProjectEstimateType(data);
    }

    public save(data: ProjectEstimateType, next?: (value: ProjectEstimateType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectEstimateTypesDeleteById extends BaseApi<ProjectEstimateType> {

    public topic = 'Resources.ProjectEstimateTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_estimate_types/${id}`;
    }

    protected convertToResource(data: any): ProjectEstimateType {
        return new ProjectEstimateType(data);
    }

    public delete(next?: (value: ProjectEstimateType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class ProjectEstimates {

    public get(): ProjectEstimatesGet {
        return new ProjectEstimatesGet();
    }

    public getById(id: number): ProjectEstimatesGetById {
        return new ProjectEstimatesGetById(id);
    }

    public post(): ProjectEstimatesPost {
        return new ProjectEstimatesPost();
    }

    public putById(id: number): ProjectEstimatesPutById {
        return new ProjectEstimatesPutById(id);
    }

    public put(): ProjectEstimatesPut {
        return new ProjectEstimatesPut();
    }

    public patchById(id: number): ProjectEstimatesPatchById {
        return new ProjectEstimatesPatchById(id);
    }

    public patch(): ProjectEstimatesPatch {
        return new ProjectEstimatesPatch();
    }

    public deleteById(id: number): ProjectEstimatesDeleteById {
        return new ProjectEstimatesDeleteById(id);
    }

}

export class ProjectEstimatesGet extends BaseApi<ProjectEstimate> {

    public topic = 'Resources.ProjectEstimates';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_estimates`;
    }

    protected convertToResource(data: any): ProjectEstimate {
        return new ProjectEstimate(data);
    }

    public where(name: string, value: any): ProjectEstimatesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): ProjectEstimatesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): ProjectEstimatesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): ProjectEstimatesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): ProjectEstimatesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): ProjectEstimatesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): ProjectEstimatesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): ProjectEstimatesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): ProjectEstimatesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): ProjectEstimatesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): ProjectEstimatesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): ProjectEstimatesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): ProjectEstimatesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): ProjectEstimatesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): ProjectEstimatesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): ProjectEstimatesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): ProjectEstimatesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: ProjectEstimate[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectEstimate[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectEstimatesGetById extends BaseApi<ProjectEstimate> {

    public topic = 'Resources.ProjectEstimates';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_estimates/${id}`;
    }

    protected convertToResource(data: any): ProjectEstimate {
        return new ProjectEstimate(data);
    }

    public include(name: string): ProjectEstimatesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: ProjectEstimate[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectEstimate[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectEstimatesPost extends BaseApi<ProjectEstimate> {

    public topic = 'Resources.ProjectEstimates';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_estimates`;
    }

    protected convertToResource(data: any): ProjectEstimate {
        return new ProjectEstimate(data);
    }

    public save(data: ProjectEstimate, next?: (value: ProjectEstimate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectEstimatesPutById extends BaseApi<ProjectEstimate> {

    public topic = 'Resources.ProjectEstimates';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_estimates/${id}`;
    }

    protected convertToResource(data: any): ProjectEstimate {
        return new ProjectEstimate(data);
    }

    public save(data: ProjectEstimate, next?: (value: ProjectEstimate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectEstimatesPut extends BaseApi<ProjectEstimate> {

    public topic = 'Resources.ProjectEstimates';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_estimates`;
    }

    protected convertToResource(data: any): ProjectEstimate {
        return new ProjectEstimate(data);
    }

    public save(data: ProjectEstimate, next?: (value: ProjectEstimate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectEstimatesPatchById extends BaseApi<ProjectEstimate> {

    public topic = 'Resources.ProjectEstimates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_estimates/${id}`;
    }

    protected convertToResource(data: any): ProjectEstimate {
        return new ProjectEstimate(data);
    }

    public save(data: ProjectEstimate, next?: (value: ProjectEstimate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectEstimatesPatch extends BaseApi<ProjectEstimate> {

    public topic = 'Resources.ProjectEstimates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_estimates`;
    }

    protected convertToResource(data: any): ProjectEstimate {
        return new ProjectEstimate(data);
    }

    public save(data: ProjectEstimate, next?: (value: ProjectEstimate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectEstimatesDeleteById extends BaseApi<ProjectEstimate> {

    public topic = 'Resources.ProjectEstimates';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_estimates/${id}`;
    }

    protected convertToResource(data: any): ProjectEstimate {
        return new ProjectEstimate(data);
    }

    public delete(next?: (value: ProjectEstimate) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class ProjectFields {

    public get(): ProjectFieldsGet {
        return new ProjectFieldsGet();
    }

    public getById(id: number): ProjectFieldsGetById {
        return new ProjectFieldsGetById(id);
    }

    public post(): ProjectFieldsPost {
        return new ProjectFieldsPost();
    }

    public putById(id: number): ProjectFieldsPutById {
        return new ProjectFieldsPutById(id);
    }

    public put(): ProjectFieldsPut {
        return new ProjectFieldsPut();
    }

    public patchById(id: number): ProjectFieldsPatchById {
        return new ProjectFieldsPatchById(id);
    }

    public patch(): ProjectFieldsPatch {
        return new ProjectFieldsPatch();
    }

    public deleteById(id: number): ProjectFieldsDeleteById {
        return new ProjectFieldsDeleteById(id);
    }

}

export class ProjectFieldsGet extends BaseApi<ProjectField> {

    public topic = 'Resources.ProjectFields';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_fields`;
    }

    protected convertToResource(data: any): ProjectField {
        return new ProjectField(data);
    }

    public where(name: string, value: any): ProjectFieldsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): ProjectFieldsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): ProjectFieldsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): ProjectFieldsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): ProjectFieldsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): ProjectFieldsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): ProjectFieldsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): ProjectFieldsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): ProjectFieldsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): ProjectFieldsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): ProjectFieldsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): ProjectFieldsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): ProjectFieldsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): ProjectFieldsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): ProjectFieldsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): ProjectFieldsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): ProjectFieldsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: ProjectField[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectField[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectFieldsGetById extends BaseApi<ProjectField> {

    public topic = 'Resources.ProjectFields';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_fields/${id}`;
    }

    protected convertToResource(data: any): ProjectField {
        return new ProjectField(data);
    }

    public include(name: string): ProjectFieldsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: ProjectField[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectField[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectFieldsPost extends BaseApi<ProjectField> {

    public topic = 'Resources.ProjectFields';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_fields`;
    }

    protected convertToResource(data: any): ProjectField {
        return new ProjectField(data);
    }

    public save(data: ProjectField, next?: (value: ProjectField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectFieldsPutById extends BaseApi<ProjectField> {

    public topic = 'Resources.ProjectFields';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_fields/${id}`;
    }

    protected convertToResource(data: any): ProjectField {
        return new ProjectField(data);
    }

    public save(data: ProjectField, next?: (value: ProjectField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectFieldsPut extends BaseApi<ProjectField> {

    public topic = 'Resources.ProjectFields';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_fields`;
    }

    protected convertToResource(data: any): ProjectField {
        return new ProjectField(data);
    }

    public save(data: ProjectField, next?: (value: ProjectField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectFieldsPatchById extends BaseApi<ProjectField> {

    public topic = 'Resources.ProjectFields';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_fields/${id}`;
    }

    protected convertToResource(data: any): ProjectField {
        return new ProjectField(data);
    }

    public save(data: ProjectField, next?: (value: ProjectField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectFieldsPatch extends BaseApi<ProjectField> {

    public topic = 'Resources.ProjectFields';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_fields`;
    }

    protected convertToResource(data: any): ProjectField {
        return new ProjectField(data);
    }

    public save(data: ProjectField, next?: (value: ProjectField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectFieldsDeleteById extends BaseApi<ProjectField> {

    public topic = 'Resources.ProjectFields';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_fields/${id}`;
    }

    protected convertToResource(data: any): ProjectField {
        return new ProjectField(data);
    }

    public delete(next?: (value: ProjectField) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class ProjectStatusTypes {

    public get(): ProjectStatusTypesGet {
        return new ProjectStatusTypesGet();
    }

    public getById(id: number): ProjectStatusTypesGetById {
        return new ProjectStatusTypesGetById(id);
    }

    public post(): ProjectStatusTypesPost {
        return new ProjectStatusTypesPost();
    }

    public putById(id: number): ProjectStatusTypesPutById {
        return new ProjectStatusTypesPutById(id);
    }

    public put(): ProjectStatusTypesPut {
        return new ProjectStatusTypesPut();
    }

    public patchById(id: number): ProjectStatusTypesPatchById {
        return new ProjectStatusTypesPatchById(id);
    }

    public patch(): ProjectStatusTypesPatch {
        return new ProjectStatusTypesPatch();
    }

    public deleteById(id: number): ProjectStatusTypesDeleteById {
        return new ProjectStatusTypesDeleteById(id);
    }

}

export class ProjectStatusTypesGet extends BaseApi<ProjectStatusType> {

    public topic = 'Resources.ProjectStatusTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_status_types`;
    }

    protected convertToResource(data: any): ProjectStatusType {
        return new ProjectStatusType(data);
    }

    public where(name: string, value: any): ProjectStatusTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): ProjectStatusTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): ProjectStatusTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): ProjectStatusTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): ProjectStatusTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): ProjectStatusTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): ProjectStatusTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): ProjectStatusTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): ProjectStatusTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): ProjectStatusTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): ProjectStatusTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): ProjectStatusTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): ProjectStatusTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): ProjectStatusTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): ProjectStatusTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): ProjectStatusTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): ProjectStatusTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: ProjectStatusType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectStatusType[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectStatusTypesGetById extends BaseApi<ProjectStatusType> {

    public topic = 'Resources.ProjectStatusTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_status_types/${id}`;
    }

    protected convertToResource(data: any): ProjectStatusType {
        return new ProjectStatusType(data);
    }

    public include(name: string): ProjectStatusTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: ProjectStatusType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectStatusType[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectStatusTypesPost extends BaseApi<ProjectStatusType> {

    public topic = 'Resources.ProjectStatusTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_status_types`;
    }

    protected convertToResource(data: any): ProjectStatusType {
        return new ProjectStatusType(data);
    }

    public save(data: ProjectStatusType, next?: (value: ProjectStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectStatusTypesPutById extends BaseApi<ProjectStatusType> {

    public topic = 'Resources.ProjectStatusTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_status_types/${id}`;
    }

    protected convertToResource(data: any): ProjectStatusType {
        return new ProjectStatusType(data);
    }

    public save(data: ProjectStatusType, next?: (value: ProjectStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectStatusTypesPut extends BaseApi<ProjectStatusType> {

    public topic = 'Resources.ProjectStatusTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_status_types`;
    }

    protected convertToResource(data: any): ProjectStatusType {
        return new ProjectStatusType(data);
    }

    public save(data: ProjectStatusType, next?: (value: ProjectStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectStatusTypesPatchById extends BaseApi<ProjectStatusType> {

    public topic = 'Resources.ProjectStatusTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_status_types/${id}`;
    }

    protected convertToResource(data: any): ProjectStatusType {
        return new ProjectStatusType(data);
    }

    public save(data: ProjectStatusType, next?: (value: ProjectStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectStatusTypesPatch extends BaseApi<ProjectStatusType> {

    public topic = 'Resources.ProjectStatusTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_status_types`;
    }

    protected convertToResource(data: any): ProjectStatusType {
        return new ProjectStatusType(data);
    }

    public save(data: ProjectStatusType, next?: (value: ProjectStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectStatusTypesDeleteById extends BaseApi<ProjectStatusType> {

    public topic = 'Resources.ProjectStatusTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_status_types/${id}`;
    }

    protected convertToResource(data: any): ProjectStatusType {
        return new ProjectStatusType(data);
    }

    public delete(next?: (value: ProjectStatusType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class ProjectTypes {

    public get(): ProjectTypesGet {
        return new ProjectTypesGet();
    }

    public getById(id: number): ProjectTypesGetById {
        return new ProjectTypesGetById(id);
    }

    public post(): ProjectTypesPost {
        return new ProjectTypesPost();
    }

    public putById(id: number): ProjectTypesPutById {
        return new ProjectTypesPutById(id);
    }

    public put(): ProjectTypesPut {
        return new ProjectTypesPut();
    }

    public patchById(id: number): ProjectTypesPatchById {
        return new ProjectTypesPatchById(id);
    }

    public patch(): ProjectTypesPatch {
        return new ProjectTypesPatch();
    }

    public deleteById(id: number): ProjectTypesDeleteById {
        return new ProjectTypesDeleteById(id);
    }

}

export class ProjectTypesGet extends BaseApi<ProjectType> {

    public topic = 'Resources.ProjectTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_types`;
    }

    protected convertToResource(data: any): ProjectType {
        return new ProjectType(data);
    }

    public where(name: string, value: any): ProjectTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): ProjectTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): ProjectTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): ProjectTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): ProjectTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): ProjectTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): ProjectTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): ProjectTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): ProjectTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): ProjectTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): ProjectTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): ProjectTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): ProjectTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): ProjectTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): ProjectTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): ProjectTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): ProjectTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: ProjectType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectType[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectTypesGetById extends BaseApi<ProjectType> {

    public topic = 'Resources.ProjectTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_types/${id}`;
    }

    protected convertToResource(data: any): ProjectType {
        return new ProjectType(data);
    }

    public include(name: string): ProjectTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: ProjectType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectType[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectTypesPost extends BaseApi<ProjectType> {

    public topic = 'Resources.ProjectTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_types`;
    }

    protected convertToResource(data: any): ProjectType {
        return new ProjectType(data);
    }

    public save(data: ProjectType, next?: (value: ProjectType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectTypesPutById extends BaseApi<ProjectType> {

    public topic = 'Resources.ProjectTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_types/${id}`;
    }

    protected convertToResource(data: any): ProjectType {
        return new ProjectType(data);
    }

    public save(data: ProjectType, next?: (value: ProjectType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectTypesPut extends BaseApi<ProjectType> {

    public topic = 'Resources.ProjectTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_types`;
    }

    protected convertToResource(data: any): ProjectType {
        return new ProjectType(data);
    }

    public save(data: ProjectType, next?: (value: ProjectType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectTypesPatchById extends BaseApi<ProjectType> {

    public topic = 'Resources.ProjectTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_types/${id}`;
    }

    protected convertToResource(data: any): ProjectType {
        return new ProjectType(data);
    }

    public save(data: ProjectType, next?: (value: ProjectType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectTypesPatch extends BaseApi<ProjectType> {

    public topic = 'Resources.ProjectTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_types`;
    }

    protected convertToResource(data: any): ProjectType {
        return new ProjectType(data);
    }

    public save(data: ProjectType, next?: (value: ProjectType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectTypesDeleteById extends BaseApi<ProjectType> {

    public topic = 'Resources.ProjectTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_types/${id}`;
    }

    protected convertToResource(data: any): ProjectType {
        return new ProjectType(data);
    }

    public delete(next?: (value: ProjectType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class ProjectUserTypes {

    public get(): ProjectUserTypesGet {
        return new ProjectUserTypesGet();
    }

    public getById(id: number): ProjectUserTypesGetById {
        return new ProjectUserTypesGetById(id);
    }

    public post(): ProjectUserTypesPost {
        return new ProjectUserTypesPost();
    }

    public putById(id: number): ProjectUserTypesPutById {
        return new ProjectUserTypesPutById(id);
    }

    public put(): ProjectUserTypesPut {
        return new ProjectUserTypesPut();
    }

    public patchById(id: number): ProjectUserTypesPatchById {
        return new ProjectUserTypesPatchById(id);
    }

    public patch(): ProjectUserTypesPatch {
        return new ProjectUserTypesPatch();
    }

    public deleteById(id: number): ProjectUserTypesDeleteById {
        return new ProjectUserTypesDeleteById(id);
    }

}

export class ProjectUserTypesGet extends BaseApi<ProjectUserType> {

    public topic = 'Resources.ProjectUserTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_user_types`;
    }

    protected convertToResource(data: any): ProjectUserType {
        return new ProjectUserType(data);
    }

    public where(name: string, value: any): ProjectUserTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): ProjectUserTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): ProjectUserTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): ProjectUserTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): ProjectUserTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): ProjectUserTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): ProjectUserTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): ProjectUserTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): ProjectUserTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): ProjectUserTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): ProjectUserTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): ProjectUserTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): ProjectUserTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): ProjectUserTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): ProjectUserTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): ProjectUserTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): ProjectUserTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: ProjectUserType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectUserType[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectUserTypesGetById extends BaseApi<ProjectUserType> {

    public topic = 'Resources.ProjectUserTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_user_types/${id}`;
    }

    protected convertToResource(data: any): ProjectUserType {
        return new ProjectUserType(data);
    }

    public include(name: string): ProjectUserTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: ProjectUserType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectUserType[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectUserTypesPost extends BaseApi<ProjectUserType> {

    public topic = 'Resources.ProjectUserTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_user_types`;
    }

    protected convertToResource(data: any): ProjectUserType {
        return new ProjectUserType(data);
    }

    public save(data: ProjectUserType, next?: (value: ProjectUserType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectUserTypesPutById extends BaseApi<ProjectUserType> {

    public topic = 'Resources.ProjectUserTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_user_types/${id}`;
    }

    protected convertToResource(data: any): ProjectUserType {
        return new ProjectUserType(data);
    }

    public save(data: ProjectUserType, next?: (value: ProjectUserType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectUserTypesPut extends BaseApi<ProjectUserType> {

    public topic = 'Resources.ProjectUserTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_user_types`;
    }

    protected convertToResource(data: any): ProjectUserType {
        return new ProjectUserType(data);
    }

    public save(data: ProjectUserType, next?: (value: ProjectUserType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectUserTypesPatchById extends BaseApi<ProjectUserType> {

    public topic = 'Resources.ProjectUserTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_user_types/${id}`;
    }

    protected convertToResource(data: any): ProjectUserType {
        return new ProjectUserType(data);
    }

    public save(data: ProjectUserType, next?: (value: ProjectUserType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectUserTypesPatch extends BaseApi<ProjectUserType> {

    public topic = 'Resources.ProjectUserTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/project_user_types`;
    }

    protected convertToResource(data: any): ProjectUserType {
        return new ProjectUserType(data);
    }

    public save(data: ProjectUserType, next?: (value: ProjectUserType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectUserTypesDeleteById extends BaseApi<ProjectUserType> {

    public topic = 'Resources.ProjectUserTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/project_user_types/${id}`;
    }

    protected convertToResource(data: any): ProjectUserType {
        return new ProjectUserType(data);
    }

    public delete(next?: (value: ProjectUserType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Projects {

    public get(): ProjectsGet {
        return new ProjectsGet();
    }

    public getById(id: number): ProjectsGetById {
        return new ProjectsGetById(id);
    }

    public deleteById(id: number): ProjectsDeleteById {
        return new ProjectsDeleteById(id);
    }

    public createPost(): ProjectsCreatePost {
        return new ProjectsCreatePost();
    }

    public updateTitlePutByProjectId(projectId: number): ProjectsUpdateTitlePutByProjectId {
        return new ProjectsUpdateTitlePutByProjectId(projectId);
    }

    public updateStatusPutByProjectId(projectId: number): ProjectsUpdateStatusPutByProjectId {
        return new ProjectsUpdateStatusPutByProjectId(projectId);
    }

    public updateArchivedPutByProjectId(projectId: number): ProjectsUpdateArchivedPutByProjectId {
        return new ProjectsUpdateArchivedPutByProjectId(projectId);
    }

    public addUserPutByProjectId(projectId: number): ProjectsAddUserPutByProjectId {
        return new ProjectsAddUserPutByProjectId(projectId);
    }

    public addUserFromDepartmentPutByProjectId(projectId: number): ProjectsAddUserFromDepartmentPutByProjectId {
        return new ProjectsAddUserFromDepartmentPutByProjectId(projectId);
    }

    public removeUserPutByProjectId(projectId: number): ProjectsRemoveUserPutByProjectId {
        return new ProjectsRemoveUserPutByProjectId(projectId);
    }

    public removeUsersPutByProjectId(projectId: number): ProjectsRemoveUsersPutByProjectId {
        return new ProjectsRemoveUsersPutByProjectId(projectId);
    }

    public updateDeadlinePutByProjectId(projectId: number): ProjectsUpdateDeadlinePutByProjectId {
        return new ProjectsUpdateDeadlinePutByProjectId(projectId);
    }

    public updateEstimatePutByProjectId(projectId: number): ProjectsUpdateEstimatePutByProjectId {
        return new ProjectsUpdateEstimatePutByProjectId(projectId);
    }

    public updateUseStatusRulesPutByProjectId(projectId: number): ProjectsUpdateUseStatusRulesPutByProjectId {
        return new ProjectsUpdateUseStatusRulesPutByProjectId(projectId);
    }

    public updateNotesPutByProjectId(projectId: number): ProjectsUpdateNotesPutByProjectId {
        return new ProjectsUpdateNotesPutByProjectId(projectId);
    }

    public updatePurposePutByProjectId(projectId: number): ProjectsUpdatePurposePutByProjectId {
        return new ProjectsUpdatePurposePutByProjectId(projectId);
    }

    public updateObsPutByProjectId(projectId: number): ProjectsUpdateObsPutByProjectId {
        return new ProjectsUpdateObsPutByProjectId(projectId);
    }

    public updateReferencePutByProjectId(projectId: number): ProjectsUpdateReferencePutByProjectId {
        return new ProjectsUpdateReferencePutByProjectId(projectId);
    }

    public addCategoryPutByProjectId(projectId: number): ProjectsAddCategoryPutByProjectId {
        return new ProjectsAddCategoryPutByProjectId(projectId);
    }

    public setCategoriesForTypePutByProjectId(projectId: number): ProjectsSetCategoriesForTypePutByProjectId {
        return new ProjectsSetCategoriesForTypePutByProjectId(projectId);
    }

    public replaceCategoriesPutByProjectId(projectId: number): ProjectsReplaceCategoriesPutByProjectId {
        return new ProjectsReplaceCategoriesPutByProjectId(projectId);
    }

    public removeCategoryPutByProjectId(projectId: number): ProjectsRemoveCategoryPutByProjectId {
        return new ProjectsRemoveCategoryPutByProjectId(projectId);
    }

    public addDepartmentPutByProjectId(projectId: number): ProjectsAddDepartmentPutByProjectId {
        return new ProjectsAddDepartmentPutByProjectId(projectId);
    }

    public removeDepartmentPutByProjectId(projectId: number): ProjectsRemoveDepartmentPutByProjectId {
        return new ProjectsRemoveDepartmentPutByProjectId(projectId);
    }

    public addTagPutByProjectId(projectId: number): ProjectsAddTagPutByProjectId {
        return new ProjectsAddTagPutByProjectId(projectId);
    }

    public removeTagPutByProjectId(projectId: number): ProjectsRemoveTagPutByProjectId {
        return new ProjectsRemoveTagPutByProjectId(projectId);
    }

    public updateCurrentPhasesProjectPutByProjectId(projectId: number): ProjectsUpdateCurrentPhasesProjectPutByProjectId {
        return new ProjectsUpdateCurrentPhasesProjectPutByProjectId(projectId);
    }

    public addPhasesProjectPutByProjectId(projectId: number): ProjectsAddPhasesProjectPutByProjectId {
        return new ProjectsAddPhasesProjectPutByProjectId(projectId);
    }

    public updateMilestoneSequencePutByProjectId(projectId: number): ProjectsUpdateMilestoneSequencePutByProjectId {
        return new ProjectsUpdateMilestoneSequencePutByProjectId(projectId);
    }

    public updateTypePutByProjectId(projectId: number): ProjectsUpdateTypePutByProjectId {
        return new ProjectsUpdateTypePutByProjectId(projectId);
    }

    public addProjectPutByProjectId(projectId: number): ProjectsAddProjectPutByProjectId {
        return new ProjectsAddProjectPutByProjectId(projectId);
    }

    public removeProjectPutByProjectId(projectId: number): ProjectsRemoveProjectPutByProjectId {
        return new ProjectsRemoveProjectPutByProjectId(projectId);
    }

    public smart_countGet(): ProjectsSmart_countGet {
        return new ProjectsSmart_countGet();
    }

    public logsGetByProjectId(projectId: number): ProjectsLogsGetByProjectId {
        return new ProjectsLogsGetByProjectId(projectId);
    }

    public starGetByProjectId(projectId: number): ProjectsStarGetByProjectId {
        return new ProjectsStarGetByProjectId(projectId);
    }

    public starPutByProjectId(projectId: number): ProjectsStarPutByProjectId {
        return new ProjectsStarPutByProjectId(projectId);
    }

    public starDeleteByProjectId(projectId: number): ProjectsStarDeleteByProjectId {
        return new ProjectsStarDeleteByProjectId(projectId);
    }

    public hand_upGetByProjectId(projectId: number): ProjectsHand_upGetByProjectId {
        return new ProjectsHand_upGetByProjectId(projectId);
    }

    public hand_upPutByProjectId(projectId: number): ProjectsHand_upPutByProjectId {
        return new ProjectsHand_upPutByProjectId(projectId);
    }

    public hand_upDeleteByProjectId(projectId: number): ProjectsHand_upDeleteByProjectId {
        return new ProjectsHand_upDeleteByProjectId(projectId);
    }

    public deletedGet(): ProjectsDeletedGet {
        return new ProjectsDeletedGet();
    }

    public restoreRestoreById(id: number): ProjectsRestoreRestoreById {
        return new ProjectsRestoreRestoreById(id);
    }

    public deleteDeleteById(id: number): ProjectsDeleteDeleteById {
        return new ProjectsDeleteDeleteById(id);
    }

    public copyGetById(id: number): ProjectsCopyGetById {
        return new ProjectsCopyGetById(id);
    }

    public convertToPlanGetById(id: number): ProjectsConvertToPlanGetById {
        return new ProjectsConvertToPlanGetById(id);
    }

    public reactionAddPutByProjectId(projectId: number): ProjectsReactionAddPutByProjectId {
        return new ProjectsReactionAddPutByProjectId(projectId);
    }

    public reactionRemoveDeleteByProjectId(projectId: number): ProjectsReactionRemoveDeleteByProjectId {
        return new ProjectsReactionRemoveDeleteByProjectId(projectId);
    }

    public phasesProjectCreatePostByProjectId(projectId: number): ProjectsPhasesProjectCreatePostByProjectId {
        return new ProjectsPhasesProjectCreatePostByProjectId(projectId);
    }

    public phasesProjectDeleteDeleteByProjectIdByPhasesProjectId(projectId: number, phasesProjectId: number): ProjectsPhasesProjectDeleteDeleteByProjectIdByPhasesProjectId {
        return new ProjectsPhasesProjectDeleteDeleteByProjectIdByPhasesProjectId(projectId, phasesProjectId);
    }

    public phasesProjectUpdateStartPutByProjectIdByPhasesProjectId(projectId: number, phasesProjectId: number): ProjectsPhasesProjectUpdateStartPutByProjectIdByPhasesProjectId {
        return new ProjectsPhasesProjectUpdateStartPutByProjectIdByPhasesProjectId(projectId, phasesProjectId);
    }

    public phasesProjectCompletePutByProjectIdByPhasesProjectId(projectId: number, phasesProjectId: number): ProjectsPhasesProjectCompletePutByProjectIdByPhasesProjectId {
        return new ProjectsPhasesProjectCompletePutByProjectIdByPhasesProjectId(projectId, phasesProjectId);
    }

    public phasesProjectIncompletePutByProjectIdByPhasesProjectId(projectId: number, phasesProjectId: number): ProjectsPhasesProjectIncompletePutByProjectIdByPhasesProjectId {
        return new ProjectsPhasesProjectIncompletePutByProjectIdByPhasesProjectId(projectId, phasesProjectId);
    }

    public phasesProjectProgressPutByProjectIdByPhasesProjectId(projectId: number, phasesProjectId: number): ProjectsPhasesProjectProgressPutByProjectIdByPhasesProjectId {
        return new ProjectsPhasesProjectProgressPutByProjectIdByPhasesProjectId(projectId, phasesProjectId);
    }

}

export class ProjectsGet extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/projects`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public where(name: string, value: any): ProjectsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): ProjectsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): ProjectsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): ProjectsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): ProjectsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): ProjectsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): ProjectsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): ProjectsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): ProjectsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): ProjectsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): ProjectsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): ProjectsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): ProjectsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): ProjectsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): ProjectsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): ProjectsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): ProjectsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Project[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Project[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectsGetById extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/projects/${id}`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public include(name: string): ProjectsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Project[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Project[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectsDeleteById extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/projects/${id}`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public delete(next?: (value: Project) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class ProjectsCreatePost extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/projects/create`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public typeId(value: number): ProjectsCreatePost {
        this.addQueryParameter('typeId', value);
        return this;
    }

    public save(data: ProjectsCreateRequest, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsUpdateTitlePutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/title`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public value(value: string): ProjectsUpdateTitlePutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsUpdateStatusPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/status`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public projectStatusTypeId(value: number): ProjectsUpdateStatusPutByProjectId {
        this.addQueryParameter('projectStatusTypeId', value);
        return this;
    }

    public status(value: number): ProjectsUpdateStatusPutByProjectId {
        this.addQueryParameter('status', value);
        return this;
    }

    public resetUseStatusRules(value: boolean): ProjectsUpdateStatusPutByProjectId {
        this.addQueryParameter('resetUseStatusRules', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsUpdateArchivedPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/archived`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public value(value: boolean): ProjectsUpdateArchivedPutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsAddUserPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/users/add`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public projectUserTypeId(value: number): ProjectsAddUserPutByProjectId {
        this.addQueryParameter('projectUserTypeId', value);
        return this;
    }

    public userId(value: number): ProjectsAddUserPutByProjectId {
        this.addQueryParameter('userId', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsAddUserFromDepartmentPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/users/add-from-department`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public projectUserTypeId(value: number): ProjectsAddUserFromDepartmentPutByProjectId {
        this.addQueryParameter('projectUserTypeId', value);
        return this;
    }

    public departmentId(value: number): ProjectsAddUserFromDepartmentPutByProjectId {
        this.addQueryParameter('departmentId', value);
        return this;
    }

    public userIds(value: number[]): ProjectsAddUserFromDepartmentPutByProjectId {
        this.addQueryParameter('userIds', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsRemoveUserPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/users/remove`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public projectUserTypeId(value: number): ProjectsRemoveUserPutByProjectId {
        this.addQueryParameter('projectUserTypeId', value);
        return this;
    }

    public userId(value: number): ProjectsRemoveUserPutByProjectId {
        this.addQueryParameter('userId', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsRemoveUsersPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/users/remove-multiple`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public projectUserTypeId(value: number): ProjectsRemoveUsersPutByProjectId {
        this.addQueryParameter('projectUserTypeId', value);
        return this;
    }

    public userIds(value: number[]): ProjectsRemoveUsersPutByProjectId {
        this.addQueryParameter('userIds', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsUpdateDeadlinePutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/deadline`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public projectDeadlineTypeId(value: number): ProjectsUpdateDeadlinePutByProjectId {
        this.addQueryParameter('projectDeadlineTypeId', value);
        return this;
    }

    public date(value: string): ProjectsUpdateDeadlinePutByProjectId {
        this.addQueryParameter('date', value);
        return this;
    }

    public isSoft(value: boolean): ProjectsUpdateDeadlinePutByProjectId {
        this.addQueryParameter('isSoft', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsUpdateEstimatePutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/estimate`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public projectEstimateTypeId(value: number): ProjectsUpdateEstimatePutByProjectId {
        this.addQueryParameter('projectEstimateTypeId', value);
        return this;
    }

    public unitId(value: number): ProjectsUpdateEstimatePutByProjectId {
        this.addQueryParameter('unitId', value);
        return this;
    }

    public value(value: number): ProjectsUpdateEstimatePutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsUpdateUseStatusRulesPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/useStatusRules`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public value(value: boolean): ProjectsUpdateUseStatusRulesPutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsUpdateNotesPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/notes`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public prop(value: string): ProjectsUpdateNotesPutByProjectId {
        this.addQueryParameter('prop', value);
        return this;
    }

    public save(data: StringInterface, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsUpdatePurposePutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/purpose`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public save(data: StringInterface, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsUpdateObsPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/obs`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public save(data: StringInterface, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsUpdateReferencePutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/reference`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public save(data: StringInterface, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsAddCategoryPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/categories/add`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public value(value: number): ProjectsAddCategoryPutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsSetCategoriesForTypePutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/categories/set-for-type`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public categoryTypeId(value: number): ProjectsSetCategoriesForTypePutByProjectId {
        this.addQueryParameter('categoryTypeId', value);
        return this;
    }

    public categoryIds(value: number[]): ProjectsSetCategoriesForTypePutByProjectId {
        this.addQueryParameter('categoryIds', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsReplaceCategoriesPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/categories/replace`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public removedCategoryIds(value: number[]): ProjectsReplaceCategoriesPutByProjectId {
        this.addQueryParameter('removedCategoryIds', value);
        return this;
    }

    public addedCategoryIds(value: number[]): ProjectsReplaceCategoriesPutByProjectId {
        this.addQueryParameter('addedCategoryIds', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsRemoveCategoryPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/categories/remove`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public value(value: number): ProjectsRemoveCategoryPutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsAddDepartmentPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/departments/add`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public value(value: number): ProjectsAddDepartmentPutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsRemoveDepartmentPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/departments/remove`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public value(value: number): ProjectsRemoveDepartmentPutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsAddTagPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/tags/add`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public value(value: number): ProjectsAddTagPutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsRemoveTagPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/tags/remove`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public value(value: number): ProjectsRemoveTagPutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsUpdateCurrentPhasesProjectPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/currentPhasesProject`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public value(value: number): ProjectsUpdateCurrentPhasesProjectPutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsAddPhasesProjectPutByProjectId extends BaseApi<PhasesProject> {

    public topic = 'Resources.PhasesProjects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/phasesProjects/add`;
    }

    protected convertToResource(data: any): PhasesProject {
        return new PhasesProject(data);
    }

    public phaseId(value: number): ProjectsAddPhasesProjectPutByProjectId {
        this.addQueryParameter('phaseId', value);
        return this;
    }

    public start(value: string): ProjectsAddPhasesProjectPutByProjectId {
        this.addQueryParameter('start', value);
        return this;
    }

    public save(data: any, next?: (value: PhasesProject) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsUpdateMilestoneSequencePutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/milestones/sequence`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public milestoneIds(value: number[]): ProjectsUpdateMilestoneSequencePutByProjectId {
        this.addQueryParameter('milestoneIds', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsUpdateTypePutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/type`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public value(value: number): ProjectsUpdateTypePutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsAddProjectPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/projects/add`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public value(value: number): ProjectsAddProjectPutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsRemoveProjectPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/projects/remove`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public value(value: number): ProjectsRemoveProjectPutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsSmart_countGet extends BaseApi<ProjectsSmartCountResponse> {

    public topic = 'Resources.ProjectsSmartCountResponses';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/projects/smart_count`;
    }

    protected convertToResource(data: any): ProjectsSmartCountResponse {
        return data;
    }

    public find(next?: (value: ProjectsSmartCountResponse[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ProjectsSmartCountResponse[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectsLogsGetByProjectId extends BaseApi<Log> {

    public topic = 'Resources.Logs';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/logs`;
    }

    protected convertToResource(data: any): Log {
        return new Log(data);
    }

    public find(next?: (value: Log[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Log[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectsStarGetByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/star`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public find(next?: (value: Project[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Project[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectsStarPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/star`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public user_id(value: number): ProjectsStarPutByProjectId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsStarDeleteByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/star`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public user_id(value: number): ProjectsStarDeleteByProjectId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public delete(next?: (value: Project) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class ProjectsHand_upGetByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/hand_up`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public find(next?: (value: Project[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Project[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectsHand_upPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/hand_up`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public user_id(value: number): ProjectsHand_upPutByProjectId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsHand_upDeleteByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/hand_up`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public user_id(value: number): ProjectsHand_upDeleteByProjectId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public delete(next?: (value: Project) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class ProjectsDeletedGet extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/projects/deleted`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public limit(value: number): ProjectsDeletedGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): ProjectsDeletedGet {
        this.offsetValue = value;
        return this;
    }

    public find(next?: (value: Project[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Project[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectsRestoreRestoreById extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'restore';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/projects/${id}/restore`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public cascade(value: boolean): ProjectsRestoreRestoreById {
        this.addQueryParameter('cascade', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsDeleteDeleteById extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/projects/${id}/delete`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public cascade(value: boolean): ProjectsDeleteDeleteById {
        this.addQueryParameter('cascade', value);
        return this;
    }

    public delete(next?: (value: Project) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class ProjectsCopyGetById extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/projects/${id}/copy`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public copy_milestones(value: boolean): ProjectsCopyGetById {
        this.addQueryParameter('copy_milestones', value);
        return this;
    }

    public copy_tasks(value: boolean): ProjectsCopyGetById {
        this.addQueryParameter('copy_tasks', value);
        return this;
    }

    public find(next?: (value: Project[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Project[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectsConvertToPlanGetById extends BaseApi<MilestonePlan> {

    public topic = 'Resources.MilestonePlans';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/projects/${id}/convert_to_plan`;
    }

    protected convertToResource(data: any): MilestonePlan {
        return new MilestonePlan(data);
    }

    public user_id(value: number): ProjectsConvertToPlanGetById {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public department_id(value: number): ProjectsConvertToPlanGetById {
        this.addQueryParameter('department_id', value);
        return this;
    }

    public find(next?: (value: MilestonePlan[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | MilestonePlan[] | any[]> {
        return super.executeClientGet();
    }
}

export class ProjectsReactionAddPutByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/reactions`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public reaction_type_id(value: number): ProjectsReactionAddPutByProjectId {
        this.addQueryParameter('reaction_type_id', value);
        return this;
    }

    public value(value: string): ProjectsReactionAddPutByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public user_id(value: number): ProjectsReactionAddPutByProjectId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsReactionRemoveDeleteByProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/reactions`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public reaction_type_id(value: number): ProjectsReactionRemoveDeleteByProjectId {
        this.addQueryParameter('reaction_type_id', value);
        return this;
    }

    public value(value: string): ProjectsReactionRemoveDeleteByProjectId {
        this.addQueryParameter('value', value);
        return this;
    }

    public user_id(value: number): ProjectsReactionRemoveDeleteByProjectId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public delete(next?: (value: Project) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class ProjectsPhasesProjectCreatePostByProjectId extends BaseApi<PhasesProject> {

    public topic = 'Resources.PhasesProjects';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number) {
        super();
        this.uri = `/projects/${projectId}/phases_projects`;
    }

    protected convertToResource(data: any): PhasesProject {
        return new PhasesProject(data);
    }

    public phase_id(value: number): ProjectsPhasesProjectCreatePostByProjectId {
        this.addQueryParameter('phase_id', value);
        return this;
    }

    public start(value: string): ProjectsPhasesProjectCreatePostByProjectId {
        this.addQueryParameter('start', value);
        return this;
    }

    public save(data: any, next?: (value: PhasesProject) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsPhasesProjectDeleteDeleteByProjectIdByPhasesProjectId extends BaseApi<PhasesProject> {

    public topic = 'Resources.PhasesProjects';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number, phasesProjectId: number) {
        super();
        this.uri = `/projects/${projectId}/phases_projects/${phasesProjectId}`;
    }

    protected convertToResource(data: any): PhasesProject {
        return new PhasesProject(data);
    }

    public delete(next?: (value: PhasesProject) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class ProjectsPhasesProjectUpdateStartPutByProjectIdByPhasesProjectId extends BaseApi<PhasesProject> {

    public topic = 'Resources.PhasesProjects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number, phasesProjectId: number) {
        super();
        this.uri = `/projects/${projectId}/phases_projects/${phasesProjectId}/start`;
    }

    protected convertToResource(data: any): PhasesProject {
        return new PhasesProject(data);
    }

    public start(value: string): ProjectsPhasesProjectUpdateStartPutByProjectIdByPhasesProjectId {
        this.addQueryParameter('start', value);
        return this;
    }

    public save(data: any, next?: (value: PhasesProject) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsPhasesProjectCompletePutByProjectIdByPhasesProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number, phasesProjectId: number) {
        super();
        this.uri = `/projects/${projectId}/phases_project/${phasesProjectId}/complete`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsPhasesProjectIncompletePutByProjectIdByPhasesProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number, phasesProjectId: number) {
        super();
        this.uri = `/projects/${projectId}/phases_project/${phasesProjectId}/incomplete`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ProjectsPhasesProjectProgressPutByProjectIdByPhasesProjectId extends BaseApi<Project> {

    public topic = 'Resources.Projects';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(projectId: number, phasesProjectId: number) {
        super();
        this.uri = `/projects/${projectId}/phases_project/${phasesProjectId}/progress`;
    }

    protected convertToResource(data: any): Project {
        return new Project(data);
    }

    public phaseProgressTypeId(value: number): ProjectsPhasesProjectProgressPutByProjectIdByPhasesProjectId {
        this.addQueryParameter('phaseProgressTypeId', value);
        return this;
    }

    public save(data: any, next?: (value: Project) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

class ReactionTypesTodos {

    public get(): ReactionTypesTodosGet {
        return new ReactionTypesTodosGet();
    }

    public getById(id: number): ReactionTypesTodosGetById {
        return new ReactionTypesTodosGetById(id);
    }

    public post(): ReactionTypesTodosPost {
        return new ReactionTypesTodosPost();
    }

    public putById(id: number): ReactionTypesTodosPutById {
        return new ReactionTypesTodosPutById(id);
    }

    public put(): ReactionTypesTodosPut {
        return new ReactionTypesTodosPut();
    }

    public patchById(id: number): ReactionTypesTodosPatchById {
        return new ReactionTypesTodosPatchById(id);
    }

    public patch(): ReactionTypesTodosPatch {
        return new ReactionTypesTodosPatch();
    }

    public deleteById(id: number): ReactionTypesTodosDeleteById {
        return new ReactionTypesTodosDeleteById(id);
    }

}

export class ReactionTypesTodosGet extends BaseApi<ReactionTypesTodo> {

    public topic = 'Resources.ReactionTypesTodos';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/reaction_types_todos`;
    }

    protected convertToResource(data: any): ReactionTypesTodo {
        return new ReactionTypesTodo(data);
    }

    public where(name: string, value: any): ReactionTypesTodosGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): ReactionTypesTodosGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): ReactionTypesTodosGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): ReactionTypesTodosGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): ReactionTypesTodosGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): ReactionTypesTodosGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): ReactionTypesTodosGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): ReactionTypesTodosGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): ReactionTypesTodosGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): ReactionTypesTodosGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): ReactionTypesTodosGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): ReactionTypesTodosGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): ReactionTypesTodosGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): ReactionTypesTodosGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): ReactionTypesTodosGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): ReactionTypesTodosGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): ReactionTypesTodosGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: ReactionTypesTodo[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ReactionTypesTodo[] | any[]> {
        return super.executeClientGet();
    }
}

export class ReactionTypesTodosGetById extends BaseApi<ReactionTypesTodo> {

    public topic = 'Resources.ReactionTypesTodos';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/reaction_types_todos/${id}`;
    }

    protected convertToResource(data: any): ReactionTypesTodo {
        return new ReactionTypesTodo(data);
    }

    public include(name: string): ReactionTypesTodosGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: ReactionTypesTodo[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | ReactionTypesTodo[] | any[]> {
        return super.executeClientGet();
    }
}

export class ReactionTypesTodosPost extends BaseApi<ReactionTypesTodo> {

    public topic = 'Resources.ReactionTypesTodos';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/reaction_types_todos`;
    }

    protected convertToResource(data: any): ReactionTypesTodo {
        return new ReactionTypesTodo(data);
    }

    public save(data: ReactionTypesTodo, next?: (value: ReactionTypesTodo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ReactionTypesTodosPutById extends BaseApi<ReactionTypesTodo> {

    public topic = 'Resources.ReactionTypesTodos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/reaction_types_todos/${id}`;
    }

    protected convertToResource(data: any): ReactionTypesTodo {
        return new ReactionTypesTodo(data);
    }

    public save(data: ReactionTypesTodo, next?: (value: ReactionTypesTodo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ReactionTypesTodosPut extends BaseApi<ReactionTypesTodo> {

    public topic = 'Resources.ReactionTypesTodos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/reaction_types_todos`;
    }

    protected convertToResource(data: any): ReactionTypesTodo {
        return new ReactionTypesTodo(data);
    }

    public save(data: ReactionTypesTodo, next?: (value: ReactionTypesTodo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ReactionTypesTodosPatchById extends BaseApi<ReactionTypesTodo> {

    public topic = 'Resources.ReactionTypesTodos';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/reaction_types_todos/${id}`;
    }

    protected convertToResource(data: any): ReactionTypesTodo {
        return new ReactionTypesTodo(data);
    }

    public save(data: ReactionTypesTodo, next?: (value: ReactionTypesTodo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ReactionTypesTodosPatch extends BaseApi<ReactionTypesTodo> {

    public topic = 'Resources.ReactionTypesTodos';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/reaction_types_todos`;
    }

    protected convertToResource(data: any): ReactionTypesTodo {
        return new ReactionTypesTodo(data);
    }

    public save(data: ReactionTypesTodo, next?: (value: ReactionTypesTodo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class ReactionTypesTodosDeleteById extends BaseApi<ReactionTypesTodo> {

    public topic = 'Resources.ReactionTypesTodos';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/reaction_types_todos/${id}`;
    }

    protected convertToResource(data: any): ReactionTypesTodo {
        return new ReactionTypesTodo(data);
    }

    public delete(next?: (value: ReactionTypesTodo) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Roles {

    public get(): RolesGet {
        return new RolesGet();
    }

    public getById(id: number): RolesGetById {
        return new RolesGetById(id);
    }

    public post(): RolesPost {
        return new RolesPost();
    }

    public putById(id: number): RolesPutById {
        return new RolesPutById(id);
    }

    public put(): RolesPut {
        return new RolesPut();
    }

    public patchById(id: number): RolesPatchById {
        return new RolesPatchById(id);
    }

    public patch(): RolesPatch {
        return new RolesPatch();
    }

    public deleteById(id: number): RolesDeleteById {
        return new RolesDeleteById(id);
    }

}

export class RolesGet extends BaseApi<Role> {

    public topic = 'Resources.Roles';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/roles`;
    }

    protected convertToResource(data: any): Role {
        return new Role(data);
    }

    public where(name: string, value: any): RolesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): RolesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): RolesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): RolesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): RolesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): RolesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): RolesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): RolesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): RolesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): RolesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): RolesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): RolesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): RolesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): RolesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): RolesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): RolesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): RolesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Role[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Role[] | any[]> {
        return super.executeClientGet();
    }
}

export class RolesGetById extends BaseApi<Role> {

    public topic = 'Resources.Roles';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/roles/${id}`;
    }

    protected convertToResource(data: any): Role {
        return new Role(data);
    }

    public include(name: string): RolesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Role[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Role[] | any[]> {
        return super.executeClientGet();
    }
}

export class RolesPost extends BaseApi<Role> {

    public topic = 'Resources.Roles';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/roles`;
    }

    protected convertToResource(data: any): Role {
        return new Role(data);
    }

    public save(data: Role, next?: (value: Role) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class RolesPutById extends BaseApi<Role> {

    public topic = 'Resources.Roles';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/roles/${id}`;
    }

    protected convertToResource(data: any): Role {
        return new Role(data);
    }

    public save(data: Role, next?: (value: Role) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class RolesPut extends BaseApi<Role> {

    public topic = 'Resources.Roles';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/roles`;
    }

    protected convertToResource(data: any): Role {
        return new Role(data);
    }

    public save(data: Role, next?: (value: Role) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class RolesPatchById extends BaseApi<Role> {

    public topic = 'Resources.Roles';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/roles/${id}`;
    }

    protected convertToResource(data: any): Role {
        return new Role(data);
    }

    public save(data: Role, next?: (value: Role) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class RolesPatch extends BaseApi<Role> {

    public topic = 'Resources.Roles';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/roles`;
    }

    protected convertToResource(data: any): Role {
        return new Role(data);
    }

    public save(data: Role, next?: (value: Role) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class RolesDeleteById extends BaseApi<Role> {

    public topic = 'Resources.Roles';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/roles/${id}`;
    }

    protected convertToResource(data: any): Role {
        return new Role(data);
    }

    public delete(next?: (value: Role) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class SCIM {

    public usersGetByIdGet(): SCIMUsersGetByIdGet {
        return new SCIMUsersGetByIdGet();
    }

    public usersGetGet(): SCIMUsersGetGet {
        return new SCIMUsersGetGet();
    }

    public usersPostPost(): SCIMUsersPostPost {
        return new SCIMUsersPostPost();
    }

    public usersPatchPatch(): SCIMUsersPatchPatch {
        return new SCIMUsersPatchPatch();
    }

    public usersDeleteDelete(): SCIMUsersDeleteDelete {
        return new SCIMUsersDeleteDelete();
    }

}

export class SCIMUsersGetByIdGet extends BaseApi<any> {

    public topic = 'UnknownResource';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/SCIM/Users/{id}`;
    }

    protected convertToResource(data: any): any {
        return data;
    }

    public find(next?: (value: any[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | any[] | any[]> {
        return super.executeClientGet();
    }
}

export class SCIMUsersGetGet extends BaseApi<any> {

    public topic = 'UnknownResource';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/SCIM/Users`;
    }

    protected convertToResource(data: any): any {
        return data;
    }

    public find(next?: (value: any[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | any[] | any[]> {
        return super.executeClientGet();
    }
}

export class SCIMUsersPostPost extends BaseApi<any> {

    public topic = 'UnknownResource';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/SCIM/Users`;
    }

    protected convertToResource(data: any): any {
        return data;
    }

    public save(data: any, next?: (value: any) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class SCIMUsersPatchPatch extends BaseApi<any> {

    public topic = 'UnknownResource';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/SCIM/Users/{id}`;
    }

    protected convertToResource(data: any): any {
        return data;
    }

    public save(data: any, next?: (value: any) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class SCIMUsersDeleteDelete extends BaseApi<any> {

    public topic = 'UnknownResource';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/SCIM/Users`;
    }

    protected convertToResource(data: any): any {
        return data;
    }

    public delete(next?: (value: any) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Sites {

    public get(): SitesGet {
        return new SitesGet();
    }

    public getById(id: number): SitesGetById {
        return new SitesGetById(id);
    }

    public post(): SitesPost {
        return new SitesPost();
    }

    public putById(id: number): SitesPutById {
        return new SitesPutById(id);
    }

    public put(): SitesPut {
        return new SitesPut();
    }

    public patchById(id: number): SitesPatchById {
        return new SitesPatchById(id);
    }

    public patch(): SitesPatch {
        return new SitesPatch();
    }

    public deleteById(id: number): SitesDeleteById {
        return new SitesDeleteById(id);
    }

}

export class SitesGet extends BaseApi<Site> {

    public topic = 'Resources.Sites';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/sites`;
    }

    protected convertToResource(data: any): Site {
        return new Site(data);
    }

    public where(name: string, value: any): SitesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): SitesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): SitesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): SitesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): SitesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): SitesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): SitesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): SitesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): SitesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): SitesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): SitesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): SitesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): SitesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): SitesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): SitesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): SitesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): SitesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Site[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Site[] | any[]> {
        return super.executeClientGet();
    }
}

export class SitesGetById extends BaseApi<Site> {

    public topic = 'Resources.Sites';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/sites/${id}`;
    }

    protected convertToResource(data: any): Site {
        return new Site(data);
    }

    public include(name: string): SitesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Site[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Site[] | any[]> {
        return super.executeClientGet();
    }
}

export class SitesPost extends BaseApi<Site> {

    public topic = 'Resources.Sites';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/sites`;
    }

    protected convertToResource(data: any): Site {
        return new Site(data);
    }

    public save(data: Site, next?: (value: Site) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class SitesPutById extends BaseApi<Site> {

    public topic = 'Resources.Sites';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/sites/${id}`;
    }

    protected convertToResource(data: any): Site {
        return new Site(data);
    }

    public save(data: Site, next?: (value: Site) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class SitesPut extends BaseApi<Site> {

    public topic = 'Resources.Sites';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/sites`;
    }

    protected convertToResource(data: any): Site {
        return new Site(data);
    }

    public save(data: Site, next?: (value: Site) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class SitesPatchById extends BaseApi<Site> {

    public topic = 'Resources.Sites';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/sites/${id}`;
    }

    protected convertToResource(data: any): Site {
        return new Site(data);
    }

    public save(data: Site, next?: (value: Site) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class SitesPatch extends BaseApi<Site> {

    public topic = 'Resources.Sites';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/sites`;
    }

    protected convertToResource(data: any): Site {
        return new Site(data);
    }

    public save(data: Site, next?: (value: Site) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class SitesDeleteById extends BaseApi<Site> {

    public topic = 'Resources.Sites';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/sites/${id}`;
    }

    protected convertToResource(data: any): Site {
        return new Site(data);
    }

    public delete(next?: (value: Site) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class StaticDeadlines {

    public get(): StaticDeadlinesGet {
        return new StaticDeadlinesGet();
    }

    public getById(id: number): StaticDeadlinesGetById {
        return new StaticDeadlinesGetById(id);
    }

    public post(): StaticDeadlinesPost {
        return new StaticDeadlinesPost();
    }

    public putById(id: number): StaticDeadlinesPutById {
        return new StaticDeadlinesPutById(id);
    }

    public put(): StaticDeadlinesPut {
        return new StaticDeadlinesPut();
    }

    public patchById(id: number): StaticDeadlinesPatchById {
        return new StaticDeadlinesPatchById(id);
    }

    public patch(): StaticDeadlinesPatch {
        return new StaticDeadlinesPatch();
    }

    public deleteById(id: number): StaticDeadlinesDeleteById {
        return new StaticDeadlinesDeleteById(id);
    }

}

export class StaticDeadlinesGet extends BaseApi<StaticDeadline> {

    public topic = 'Resources.StaticDeadlines';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/static_deadlines`;
    }

    protected convertToResource(data: any): StaticDeadline {
        return new StaticDeadline(data);
    }

    public where(name: string, value: any): StaticDeadlinesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): StaticDeadlinesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): StaticDeadlinesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): StaticDeadlinesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): StaticDeadlinesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): StaticDeadlinesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): StaticDeadlinesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): StaticDeadlinesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): StaticDeadlinesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): StaticDeadlinesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): StaticDeadlinesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): StaticDeadlinesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): StaticDeadlinesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): StaticDeadlinesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): StaticDeadlinesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): StaticDeadlinesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): StaticDeadlinesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: StaticDeadline[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | StaticDeadline[] | any[]> {
        return super.executeClientGet();
    }
}

export class StaticDeadlinesGetById extends BaseApi<StaticDeadline> {

    public topic = 'Resources.StaticDeadlines';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/static_deadlines/${id}`;
    }

    protected convertToResource(data: any): StaticDeadline {
        return new StaticDeadline(data);
    }

    public include(name: string): StaticDeadlinesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: StaticDeadline[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | StaticDeadline[] | any[]> {
        return super.executeClientGet();
    }
}

export class StaticDeadlinesPost extends BaseApi<StaticDeadline> {

    public topic = 'Resources.StaticDeadlines';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/static_deadlines`;
    }

    protected convertToResource(data: any): StaticDeadline {
        return new StaticDeadline(data);
    }

    public save(data: StaticDeadline, next?: (value: StaticDeadline) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StaticDeadlinesPutById extends BaseApi<StaticDeadline> {

    public topic = 'Resources.StaticDeadlines';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/static_deadlines/${id}`;
    }

    protected convertToResource(data: any): StaticDeadline {
        return new StaticDeadline(data);
    }

    public save(data: StaticDeadline, next?: (value: StaticDeadline) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StaticDeadlinesPut extends BaseApi<StaticDeadline> {

    public topic = 'Resources.StaticDeadlines';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/static_deadlines`;
    }

    protected convertToResource(data: any): StaticDeadline {
        return new StaticDeadline(data);
    }

    public save(data: StaticDeadline, next?: (value: StaticDeadline) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StaticDeadlinesPatchById extends BaseApi<StaticDeadline> {

    public topic = 'Resources.StaticDeadlines';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/static_deadlines/${id}`;
    }

    protected convertToResource(data: any): StaticDeadline {
        return new StaticDeadline(data);
    }

    public save(data: StaticDeadline, next?: (value: StaticDeadline) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StaticDeadlinesPatch extends BaseApi<StaticDeadline> {

    public topic = 'Resources.StaticDeadlines';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/static_deadlines`;
    }

    protected convertToResource(data: any): StaticDeadline {
        return new StaticDeadline(data);
    }

    public save(data: StaticDeadline, next?: (value: StaticDeadline) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StaticDeadlinesDeleteById extends BaseApi<StaticDeadline> {

    public topic = 'Resources.StaticDeadlines';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/static_deadlines/${id}`;
    }

    protected convertToResource(data: any): StaticDeadline {
        return new StaticDeadline(data);
    }

    public delete(next?: (value: StaticDeadline) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Statistics {

    public get(): StatisticsGet {
        return new StatisticsGet();
    }

    public getById(id: number): StatisticsGetById {
        return new StatisticsGetById(id);
    }

    public post(): StatisticsPost {
        return new StatisticsPost();
    }

    public putById(id: number): StatisticsPutById {
        return new StatisticsPutById(id);
    }

    public put(): StatisticsPut {
        return new StatisticsPut();
    }

    public patchById(id: number): StatisticsPatchById {
        return new StatisticsPatchById(id);
    }

    public patch(): StatisticsPatch {
        return new StatisticsPatch();
    }

    public deleteById(id: number): StatisticsDeleteById {
        return new StatisticsDeleteById(id);
    }

}

export class StatisticsGet extends BaseApi<Statistic> {

    public topic = 'Resources.Statistics';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/statistics`;
    }

    protected convertToResource(data: any): Statistic {
        return new Statistic(data);
    }

    public where(name: string, value: any): StatisticsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): StatisticsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): StatisticsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): StatisticsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): StatisticsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): StatisticsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): StatisticsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): StatisticsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): StatisticsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): StatisticsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): StatisticsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): StatisticsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): StatisticsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): StatisticsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): StatisticsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): StatisticsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): StatisticsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Statistic[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Statistic[] | any[]> {
        return super.executeClientGet();
    }
}

export class StatisticsGetById extends BaseApi<Statistic> {

    public topic = 'Resources.Statistics';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/statistics/${id}`;
    }

    protected convertToResource(data: any): Statistic {
        return new Statistic(data);
    }

    public include(name: string): StatisticsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Statistic[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Statistic[] | any[]> {
        return super.executeClientGet();
    }
}

export class StatisticsPost extends BaseApi<Statistic> {

    public topic = 'Resources.Statistics';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/statistics`;
    }

    protected convertToResource(data: any): Statistic {
        return new Statistic(data);
    }

    public save(data: Statistic, next?: (value: Statistic) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatisticsPutById extends BaseApi<Statistic> {

    public topic = 'Resources.Statistics';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/statistics/${id}`;
    }

    protected convertToResource(data: any): Statistic {
        return new Statistic(data);
    }

    public save(data: Statistic, next?: (value: Statistic) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatisticsPut extends BaseApi<Statistic> {

    public topic = 'Resources.Statistics';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/statistics`;
    }

    protected convertToResource(data: any): Statistic {
        return new Statistic(data);
    }

    public save(data: Statistic, next?: (value: Statistic) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatisticsPatchById extends BaseApi<Statistic> {

    public topic = 'Resources.Statistics';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/statistics/${id}`;
    }

    protected convertToResource(data: any): Statistic {
        return new Statistic(data);
    }

    public save(data: Statistic, next?: (value: Statistic) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatisticsPatch extends BaseApi<Statistic> {

    public topic = 'Resources.Statistics';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/statistics`;
    }

    protected convertToResource(data: any): Statistic {
        return new Statistic(data);
    }

    public save(data: Statistic, next?: (value: Statistic) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatisticsDeleteById extends BaseApi<Statistic> {

    public topic = 'Resources.Statistics';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/statistics/${id}`;
    }

    protected convertToResource(data: any): Statistic {
        return new Statistic(data);
    }

    public delete(next?: (value: Statistic) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class StatusRules {

    public get(): StatusRulesGet {
        return new StatusRulesGet();
    }

    public getById(id: number): StatusRulesGetById {
        return new StatusRulesGetById(id);
    }

    public post(): StatusRulesPost {
        return new StatusRulesPost();
    }

    public putById(id: number): StatusRulesPutById {
        return new StatusRulesPutById(id);
    }

    public put(): StatusRulesPut {
        return new StatusRulesPut();
    }

    public patchById(id: number): StatusRulesPatchById {
        return new StatusRulesPatchById(id);
    }

    public patch(): StatusRulesPatch {
        return new StatusRulesPatch();
    }

    public deleteById(id: number): StatusRulesDeleteById {
        return new StatusRulesDeleteById(id);
    }

}

export class StatusRulesGet extends BaseApi<StatusRule> {

    public topic = 'Resources.StatusRules';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/status_rules`;
    }

    protected convertToResource(data: any): StatusRule {
        return new StatusRule(data);
    }

    public where(name: string, value: any): StatusRulesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): StatusRulesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): StatusRulesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): StatusRulesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): StatusRulesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): StatusRulesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): StatusRulesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): StatusRulesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): StatusRulesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): StatusRulesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): StatusRulesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): StatusRulesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): StatusRulesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): StatusRulesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): StatusRulesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): StatusRulesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): StatusRulesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: StatusRule[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | StatusRule[] | any[]> {
        return super.executeClientGet();
    }
}

export class StatusRulesGetById extends BaseApi<StatusRule> {

    public topic = 'Resources.StatusRules';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/status_rules/${id}`;
    }

    protected convertToResource(data: any): StatusRule {
        return new StatusRule(data);
    }

    public include(name: string): StatusRulesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: StatusRule[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | StatusRule[] | any[]> {
        return super.executeClientGet();
    }
}

export class StatusRulesPost extends BaseApi<StatusRule> {

    public topic = 'Resources.StatusRules';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/status_rules`;
    }

    protected convertToResource(data: any): StatusRule {
        return new StatusRule(data);
    }

    public save(data: StatusRule, next?: (value: StatusRule) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatusRulesPutById extends BaseApi<StatusRule> {

    public topic = 'Resources.StatusRules';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/status_rules/${id}`;
    }

    protected convertToResource(data: any): StatusRule {
        return new StatusRule(data);
    }

    public save(data: StatusRule, next?: (value: StatusRule) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatusRulesPut extends BaseApi<StatusRule> {

    public topic = 'Resources.StatusRules';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/status_rules`;
    }

    protected convertToResource(data: any): StatusRule {
        return new StatusRule(data);
    }

    public save(data: StatusRule, next?: (value: StatusRule) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatusRulesPatchById extends BaseApi<StatusRule> {

    public topic = 'Resources.StatusRules';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/status_rules/${id}`;
    }

    protected convertToResource(data: any): StatusRule {
        return new StatusRule(data);
    }

    public save(data: StatusRule, next?: (value: StatusRule) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatusRulesPatch extends BaseApi<StatusRule> {

    public topic = 'Resources.StatusRules';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/status_rules`;
    }

    protected convertToResource(data: any): StatusRule {
        return new StatusRule(data);
    }

    public save(data: StatusRule, next?: (value: StatusRule) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatusRulesDeleteById extends BaseApi<StatusRule> {

    public topic = 'Resources.StatusRules';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/status_rules/${id}`;
    }

    protected convertToResource(data: any): StatusRule {
        return new StatusRule(data);
    }

    public delete(next?: (value: StatusRule) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Statuses {

    public get(): StatusesGet {
        return new StatusesGet();
    }

    public getById(id: number): StatusesGetById {
        return new StatusesGetById(id);
    }

    public post(): StatusesPost {
        return new StatusesPost();
    }

    public putById(id: number): StatusesPutById {
        return new StatusesPutById(id);
    }

    public put(): StatusesPut {
        return new StatusesPut();
    }

    public patchById(id: number): StatusesPatchById {
        return new StatusesPatchById(id);
    }

    public patch(): StatusesPatch {
        return new StatusesPatch();
    }

    public deleteById(id: number): StatusesDeleteById {
        return new StatusesDeleteById(id);
    }

    public getGetById(id: number): StatusesGetGetById {
        return new StatusesGetGetById(id);
    }

    public postPost(): StatusesPostPost {
        return new StatusesPostPost();
    }

    public putPutById(id: number): StatusesPutPutById {
        return new StatusesPutPutById(id);
    }

    public patchPatchById(id: number): StatusesPatchPatchById {
        return new StatusesPatchPatchById(id);
    }

    public deleteDelete(): StatusesDeleteDelete {
        return new StatusesDeleteDelete();
    }

}

export class StatusesGet extends BaseApi<Status> {

    public topic = 'Resources.Status';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/statuses`;
    }

    protected convertToResource(data: any): Status {
        return new Status(data);
    }

    public where(name: string, value: any): StatusesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): StatusesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): StatusesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): StatusesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): StatusesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): StatusesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): StatusesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): StatusesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): StatusesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): StatusesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): StatusesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): StatusesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): StatusesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): StatusesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): StatusesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): StatusesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): StatusesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Status[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Status[] | any[]> {
        return super.executeClientGet();
    }
}

export class StatusesGetById extends BaseApi<Status> {

    public topic = 'Resources.Status';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/statuses/${id}`;
    }

    protected convertToResource(data: any): Status {
        return new Status(data);
    }

    public include(name: string): StatusesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Status[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Status[] | any[]> {
        return super.executeClientGet();
    }
}

export class StatusesPost extends BaseApi<Status> {

    public topic = 'Resources.Status';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/statuses`;
    }

    protected convertToResource(data: any): Status {
        return new Status(data);
    }

    public save(data: Status, next?: (value: Status) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatusesPutById extends BaseApi<Status> {

    public topic = 'Resources.Status';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/statuses/${id}`;
    }

    protected convertToResource(data: any): Status {
        return new Status(data);
    }

    public save(data: Status, next?: (value: Status) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatusesPut extends BaseApi<Status> {

    public topic = 'Resources.Status';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/statuses`;
    }

    protected convertToResource(data: any): Status {
        return new Status(data);
    }

    public save(data: Status, next?: (value: Status) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatusesPatchById extends BaseApi<Status> {

    public topic = 'Resources.Status';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/statuses/${id}`;
    }

    protected convertToResource(data: any): Status {
        return new Status(data);
    }

    public save(data: Status, next?: (value: Status) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatusesPatch extends BaseApi<Status> {

    public topic = 'Resources.Status';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/statuses`;
    }

    protected convertToResource(data: any): Status {
        return new Status(data);
    }

    public save(data: Status, next?: (value: Status) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatusesDeleteById extends BaseApi<Status> {

    public topic = 'Resources.Status';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/statuses/${id}`;
    }

    protected convertToResource(data: any): Status {
        return new Status(data);
    }

    public delete(next?: (value: Status) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class StatusesGetGetById extends BaseApi<Status> {

    public topic = 'Resources.Status';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/statuses`;
    }

    protected convertToResource(data: any): Status {
        return new Status(data);
    }

    public find(next?: (value: Status[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Status[] | any[]> {
        return super.executeClientGet();
    }
}

export class StatusesPostPost extends BaseApi<Status> {

    public topic = 'Resources.Status';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/statuses`;
    }

    protected convertToResource(data: any): Status {
        return new Status(data);
    }

    public save(data: Status, next?: (value: Status) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatusesPutPutById extends BaseApi<Status> {

    public topic = 'Resources.Status';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/statuses`;
    }

    protected convertToResource(data: any): Status {
        return new Status(data);
    }

    public save(data: Status, next?: (value: Status) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatusesPatchPatchById extends BaseApi<Status> {

    public topic = 'Resources.Status';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/statuses`;
    }

    protected convertToResource(data: any): Status {
        return new Status(data);
    }

    public save(data: Status, next?: (value: Status) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class StatusesDeleteDelete extends BaseApi<Status> {

    public topic = 'Resources.Status';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/statuses`;
    }

    protected convertToResource(data: any): Status {
        return new Status(data);
    }

    public delete(next?: (value: Status) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Tags {

    public get(): TagsGet {
        return new TagsGet();
    }

    public getById(id: number): TagsGetById {
        return new TagsGetById(id);
    }

    public post(): TagsPost {
        return new TagsPost();
    }

    public putById(id: number): TagsPutById {
        return new TagsPutById(id);
    }

    public put(): TagsPut {
        return new TagsPut();
    }

    public patchById(id: number): TagsPatchById {
        return new TagsPatchById(id);
    }

    public patch(): TagsPatch {
        return new TagsPatch();
    }

    public deleteById(id: number): TagsDeleteById {
        return new TagsDeleteById(id);
    }

}

export class TagsGet extends BaseApi<Tag> {

    public topic = 'Resources.Tags';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/tags`;
    }

    protected convertToResource(data: any): Tag {
        return new Tag(data);
    }

    public where(name: string, value: any): TagsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TagsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TagsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TagsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TagsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TagsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TagsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TagsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TagsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TagsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TagsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TagsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TagsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TagsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TagsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TagsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TagsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Tag[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Tag[] | any[]> {
        return super.executeClientGet();
    }
}

export class TagsGetById extends BaseApi<Tag> {

    public topic = 'Resources.Tags';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/tags/${id}`;
    }

    protected convertToResource(data: any): Tag {
        return new Tag(data);
    }

    public include(name: string): TagsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Tag[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Tag[] | any[]> {
        return super.executeClientGet();
    }
}

export class TagsPost extends BaseApi<Tag> {

    public topic = 'Resources.Tags';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/tags`;
    }

    protected convertToResource(data: any): Tag {
        return new Tag(data);
    }

    public save(data: Tag, next?: (value: Tag) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TagsPutById extends BaseApi<Tag> {

    public topic = 'Resources.Tags';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/tags/${id}`;
    }

    protected convertToResource(data: any): Tag {
        return new Tag(data);
    }

    public save(data: Tag, next?: (value: Tag) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TagsPut extends BaseApi<Tag> {

    public topic = 'Resources.Tags';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/tags`;
    }

    protected convertToResource(data: any): Tag {
        return new Tag(data);
    }

    public save(data: Tag, next?: (value: Tag) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TagsPatchById extends BaseApi<Tag> {

    public topic = 'Resources.Tags';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/tags/${id}`;
    }

    protected convertToResource(data: any): Tag {
        return new Tag(data);
    }

    public save(data: Tag, next?: (value: Tag) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TagsPatch extends BaseApi<Tag> {

    public topic = 'Resources.Tags';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/tags`;
    }

    protected convertToResource(data: any): Tag {
        return new Tag(data);
    }

    public save(data: Tag, next?: (value: Tag) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TagsDeleteById extends BaseApi<Tag> {

    public topic = 'Resources.Tags';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/tags/${id}`;
    }

    protected convertToResource(data: any): Tag {
        return new Tag(data);
    }

    public delete(next?: (value: Tag) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class TaskDeadlineTypes {

    public get(): TaskDeadlineTypesGet {
        return new TaskDeadlineTypesGet();
    }

    public getById(id: number): TaskDeadlineTypesGetById {
        return new TaskDeadlineTypesGetById(id);
    }

    public post(): TaskDeadlineTypesPost {
        return new TaskDeadlineTypesPost();
    }

    public putById(id: number): TaskDeadlineTypesPutById {
        return new TaskDeadlineTypesPutById(id);
    }

    public put(): TaskDeadlineTypesPut {
        return new TaskDeadlineTypesPut();
    }

    public patchById(id: number): TaskDeadlineTypesPatchById {
        return new TaskDeadlineTypesPatchById(id);
    }

    public patch(): TaskDeadlineTypesPatch {
        return new TaskDeadlineTypesPatch();
    }

    public deleteById(id: number): TaskDeadlineTypesDeleteById {
        return new TaskDeadlineTypesDeleteById(id);
    }

}

export class TaskDeadlineTypesGet extends BaseApi<TaskDeadlineType> {

    public topic = 'Resources.TaskDeadlineTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_deadline_types`;
    }

    protected convertToResource(data: any): TaskDeadlineType {
        return new TaskDeadlineType(data);
    }

    public where(name: string, value: any): TaskDeadlineTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TaskDeadlineTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TaskDeadlineTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TaskDeadlineTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TaskDeadlineTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TaskDeadlineTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TaskDeadlineTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TaskDeadlineTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TaskDeadlineTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TaskDeadlineTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TaskDeadlineTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TaskDeadlineTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TaskDeadlineTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TaskDeadlineTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TaskDeadlineTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TaskDeadlineTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TaskDeadlineTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: TaskDeadlineType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskDeadlineType[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskDeadlineTypesGetById extends BaseApi<TaskDeadlineType> {

    public topic = 'Resources.TaskDeadlineTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_deadline_types/${id}`;
    }

    protected convertToResource(data: any): TaskDeadlineType {
        return new TaskDeadlineType(data);
    }

    public include(name: string): TaskDeadlineTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: TaskDeadlineType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskDeadlineType[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskDeadlineTypesPost extends BaseApi<TaskDeadlineType> {

    public topic = 'Resources.TaskDeadlineTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_deadline_types`;
    }

    protected convertToResource(data: any): TaskDeadlineType {
        return new TaskDeadlineType(data);
    }

    public save(data: TaskDeadlineType, next?: (value: TaskDeadlineType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskDeadlineTypesPutById extends BaseApi<TaskDeadlineType> {

    public topic = 'Resources.TaskDeadlineTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_deadline_types/${id}`;
    }

    protected convertToResource(data: any): TaskDeadlineType {
        return new TaskDeadlineType(data);
    }

    public save(data: TaskDeadlineType, next?: (value: TaskDeadlineType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskDeadlineTypesPut extends BaseApi<TaskDeadlineType> {

    public topic = 'Resources.TaskDeadlineTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_deadline_types`;
    }

    protected convertToResource(data: any): TaskDeadlineType {
        return new TaskDeadlineType(data);
    }

    public save(data: TaskDeadlineType, next?: (value: TaskDeadlineType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskDeadlineTypesPatchById extends BaseApi<TaskDeadlineType> {

    public topic = 'Resources.TaskDeadlineTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_deadline_types/${id}`;
    }

    protected convertToResource(data: any): TaskDeadlineType {
        return new TaskDeadlineType(data);
    }

    public save(data: TaskDeadlineType, next?: (value: TaskDeadlineType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskDeadlineTypesPatch extends BaseApi<TaskDeadlineType> {

    public topic = 'Resources.TaskDeadlineTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_deadline_types`;
    }

    protected convertToResource(data: any): TaskDeadlineType {
        return new TaskDeadlineType(data);
    }

    public save(data: TaskDeadlineType, next?: (value: TaskDeadlineType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskDeadlineTypesDeleteById extends BaseApi<TaskDeadlineType> {

    public topic = 'Resources.TaskDeadlineTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_deadline_types/${id}`;
    }

    protected convertToResource(data: any): TaskDeadlineType {
        return new TaskDeadlineType(data);
    }

    public delete(next?: (value: TaskDeadlineType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class TaskEstimateTypes {

    public get(): TaskEstimateTypesGet {
        return new TaskEstimateTypesGet();
    }

    public getById(id: number): TaskEstimateTypesGetById {
        return new TaskEstimateTypesGetById(id);
    }

    public post(): TaskEstimateTypesPost {
        return new TaskEstimateTypesPost();
    }

    public putById(id: number): TaskEstimateTypesPutById {
        return new TaskEstimateTypesPutById(id);
    }

    public put(): TaskEstimateTypesPut {
        return new TaskEstimateTypesPut();
    }

    public patchById(id: number): TaskEstimateTypesPatchById {
        return new TaskEstimateTypesPatchById(id);
    }

    public patch(): TaskEstimateTypesPatch {
        return new TaskEstimateTypesPatch();
    }

    public deleteById(id: number): TaskEstimateTypesDeleteById {
        return new TaskEstimateTypesDeleteById(id);
    }

}

export class TaskEstimateTypesGet extends BaseApi<TaskEstimateType> {

    public topic = 'Resources.TaskEstimateTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_estimate_types`;
    }

    protected convertToResource(data: any): TaskEstimateType {
        return new TaskEstimateType(data);
    }

    public where(name: string, value: any): TaskEstimateTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TaskEstimateTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TaskEstimateTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TaskEstimateTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TaskEstimateTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TaskEstimateTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TaskEstimateTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TaskEstimateTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TaskEstimateTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TaskEstimateTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TaskEstimateTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TaskEstimateTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TaskEstimateTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TaskEstimateTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TaskEstimateTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TaskEstimateTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TaskEstimateTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: TaskEstimateType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskEstimateType[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskEstimateTypesGetById extends BaseApi<TaskEstimateType> {

    public topic = 'Resources.TaskEstimateTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_estimate_types/${id}`;
    }

    protected convertToResource(data: any): TaskEstimateType {
        return new TaskEstimateType(data);
    }

    public include(name: string): TaskEstimateTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: TaskEstimateType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskEstimateType[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskEstimateTypesPost extends BaseApi<TaskEstimateType> {

    public topic = 'Resources.TaskEstimateTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_estimate_types`;
    }

    protected convertToResource(data: any): TaskEstimateType {
        return new TaskEstimateType(data);
    }

    public save(data: TaskEstimateType, next?: (value: TaskEstimateType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskEstimateTypesPutById extends BaseApi<TaskEstimateType> {

    public topic = 'Resources.TaskEstimateTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_estimate_types/${id}`;
    }

    protected convertToResource(data: any): TaskEstimateType {
        return new TaskEstimateType(data);
    }

    public save(data: TaskEstimateType, next?: (value: TaskEstimateType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskEstimateTypesPut extends BaseApi<TaskEstimateType> {

    public topic = 'Resources.TaskEstimateTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_estimate_types`;
    }

    protected convertToResource(data: any): TaskEstimateType {
        return new TaskEstimateType(data);
    }

    public save(data: TaskEstimateType, next?: (value: TaskEstimateType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskEstimateTypesPatchById extends BaseApi<TaskEstimateType> {

    public topic = 'Resources.TaskEstimateTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_estimate_types/${id}`;
    }

    protected convertToResource(data: any): TaskEstimateType {
        return new TaskEstimateType(data);
    }

    public save(data: TaskEstimateType, next?: (value: TaskEstimateType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskEstimateTypesPatch extends BaseApi<TaskEstimateType> {

    public topic = 'Resources.TaskEstimateTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_estimate_types`;
    }

    protected convertToResource(data: any): TaskEstimateType {
        return new TaskEstimateType(data);
    }

    public save(data: TaskEstimateType, next?: (value: TaskEstimateType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskEstimateTypesDeleteById extends BaseApi<TaskEstimateType> {

    public topic = 'Resources.TaskEstimateTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_estimate_types/${id}`;
    }

    protected convertToResource(data: any): TaskEstimateType {
        return new TaskEstimateType(data);
    }

    public delete(next?: (value: TaskEstimateType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class TaskFields {

    public get(): TaskFieldsGet {
        return new TaskFieldsGet();
    }

    public getById(id: number): TaskFieldsGetById {
        return new TaskFieldsGetById(id);
    }

    public post(): TaskFieldsPost {
        return new TaskFieldsPost();
    }

    public putById(id: number): TaskFieldsPutById {
        return new TaskFieldsPutById(id);
    }

    public put(): TaskFieldsPut {
        return new TaskFieldsPut();
    }

    public patchById(id: number): TaskFieldsPatchById {
        return new TaskFieldsPatchById(id);
    }

    public patch(): TaskFieldsPatch {
        return new TaskFieldsPatch();
    }

    public deleteById(id: number): TaskFieldsDeleteById {
        return new TaskFieldsDeleteById(id);
    }

}

export class TaskFieldsGet extends BaseApi<TaskField> {

    public topic = 'Resources.TaskFields';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_fields`;
    }

    protected convertToResource(data: any): TaskField {
        return new TaskField(data);
    }

    public where(name: string, value: any): TaskFieldsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TaskFieldsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TaskFieldsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TaskFieldsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TaskFieldsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TaskFieldsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TaskFieldsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TaskFieldsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TaskFieldsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TaskFieldsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TaskFieldsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TaskFieldsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TaskFieldsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TaskFieldsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TaskFieldsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TaskFieldsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TaskFieldsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: TaskField[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskField[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskFieldsGetById extends BaseApi<TaskField> {

    public topic = 'Resources.TaskFields';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_fields/${id}`;
    }

    protected convertToResource(data: any): TaskField {
        return new TaskField(data);
    }

    public include(name: string): TaskFieldsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: TaskField[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskField[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskFieldsPost extends BaseApi<TaskField> {

    public topic = 'Resources.TaskFields';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_fields`;
    }

    protected convertToResource(data: any): TaskField {
        return new TaskField(data);
    }

    public save(data: TaskField, next?: (value: TaskField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskFieldsPutById extends BaseApi<TaskField> {

    public topic = 'Resources.TaskFields';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_fields/${id}`;
    }

    protected convertToResource(data: any): TaskField {
        return new TaskField(data);
    }

    public save(data: TaskField, next?: (value: TaskField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskFieldsPut extends BaseApi<TaskField> {

    public topic = 'Resources.TaskFields';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_fields`;
    }

    protected convertToResource(data: any): TaskField {
        return new TaskField(data);
    }

    public save(data: TaskField, next?: (value: TaskField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskFieldsPatchById extends BaseApi<TaskField> {

    public topic = 'Resources.TaskFields';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_fields/${id}`;
    }

    protected convertToResource(data: any): TaskField {
        return new TaskField(data);
    }

    public save(data: TaskField, next?: (value: TaskField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskFieldsPatch extends BaseApi<TaskField> {

    public topic = 'Resources.TaskFields';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_fields`;
    }

    protected convertToResource(data: any): TaskField {
        return new TaskField(data);
    }

    public save(data: TaskField, next?: (value: TaskField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskFieldsDeleteById extends BaseApi<TaskField> {

    public topic = 'Resources.TaskFields';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_fields/${id}`;
    }

    protected convertToResource(data: any): TaskField {
        return new TaskField(data);
    }

    public delete(next?: (value: TaskField) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class TaskStatusTypes {

    public get(): TaskStatusTypesGet {
        return new TaskStatusTypesGet();
    }

    public getById(id: number): TaskStatusTypesGetById {
        return new TaskStatusTypesGetById(id);
    }

    public post(): TaskStatusTypesPost {
        return new TaskStatusTypesPost();
    }

    public putById(id: number): TaskStatusTypesPutById {
        return new TaskStatusTypesPutById(id);
    }

    public put(): TaskStatusTypesPut {
        return new TaskStatusTypesPut();
    }

    public patchById(id: number): TaskStatusTypesPatchById {
        return new TaskStatusTypesPatchById(id);
    }

    public patch(): TaskStatusTypesPatch {
        return new TaskStatusTypesPatch();
    }

    public deleteById(id: number): TaskStatusTypesDeleteById {
        return new TaskStatusTypesDeleteById(id);
    }

}

export class TaskStatusTypesGet extends BaseApi<TaskStatusType> {

    public topic = 'Resources.TaskStatusTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_status_types`;
    }

    protected convertToResource(data: any): TaskStatusType {
        return new TaskStatusType(data);
    }

    public where(name: string, value: any): TaskStatusTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TaskStatusTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TaskStatusTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TaskStatusTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TaskStatusTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TaskStatusTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TaskStatusTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TaskStatusTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TaskStatusTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TaskStatusTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TaskStatusTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TaskStatusTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TaskStatusTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TaskStatusTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TaskStatusTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TaskStatusTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TaskStatusTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: TaskStatusType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskStatusType[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskStatusTypesGetById extends BaseApi<TaskStatusType> {

    public topic = 'Resources.TaskStatusTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_status_types/${id}`;
    }

    protected convertToResource(data: any): TaskStatusType {
        return new TaskStatusType(data);
    }

    public include(name: string): TaskStatusTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: TaskStatusType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskStatusType[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskStatusTypesPost extends BaseApi<TaskStatusType> {

    public topic = 'Resources.TaskStatusTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_status_types`;
    }

    protected convertToResource(data: any): TaskStatusType {
        return new TaskStatusType(data);
    }

    public save(data: TaskStatusType, next?: (value: TaskStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskStatusTypesPutById extends BaseApi<TaskStatusType> {

    public topic = 'Resources.TaskStatusTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_status_types/${id}`;
    }

    protected convertToResource(data: any): TaskStatusType {
        return new TaskStatusType(data);
    }

    public save(data: TaskStatusType, next?: (value: TaskStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskStatusTypesPut extends BaseApi<TaskStatusType> {

    public topic = 'Resources.TaskStatusTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_status_types`;
    }

    protected convertToResource(data: any): TaskStatusType {
        return new TaskStatusType(data);
    }

    public save(data: TaskStatusType, next?: (value: TaskStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskStatusTypesPatchById extends BaseApi<TaskStatusType> {

    public topic = 'Resources.TaskStatusTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_status_types/${id}`;
    }

    protected convertToResource(data: any): TaskStatusType {
        return new TaskStatusType(data);
    }

    public save(data: TaskStatusType, next?: (value: TaskStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskStatusTypesPatch extends BaseApi<TaskStatusType> {

    public topic = 'Resources.TaskStatusTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_status_types`;
    }

    protected convertToResource(data: any): TaskStatusType {
        return new TaskStatusType(data);
    }

    public save(data: TaskStatusType, next?: (value: TaskStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskStatusTypesDeleteById extends BaseApi<TaskStatusType> {

    public topic = 'Resources.TaskStatusTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_status_types/${id}`;
    }

    protected convertToResource(data: any): TaskStatusType {
        return new TaskStatusType(data);
    }

    public delete(next?: (value: TaskStatusType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class TaskTemplates {

    public get(): TaskTemplatesGet {
        return new TaskTemplatesGet();
    }

    public getById(id: number): TaskTemplatesGetById {
        return new TaskTemplatesGetById(id);
    }

    public post(): TaskTemplatesPost {
        return new TaskTemplatesPost();
    }

    public putById(id: number): TaskTemplatesPutById {
        return new TaskTemplatesPutById(id);
    }

    public put(): TaskTemplatesPut {
        return new TaskTemplatesPut();
    }

    public patchById(id: number): TaskTemplatesPatchById {
        return new TaskTemplatesPatchById(id);
    }

    public patch(): TaskTemplatesPatch {
        return new TaskTemplatesPatch();
    }

    public deleteById(id: number): TaskTemplatesDeleteById {
        return new TaskTemplatesDeleteById(id);
    }

    public usersGetById(id: number): TaskTemplatesUsersGetById {
        return new TaskTemplatesUsersGetById(id);
    }

    public titlePatchPatchById(id: number): TaskTemplatesTitlePatchPatchById {
        return new TaskTemplatesTitlePatchPatchById(id);
    }

    public deadlinePatchPatchById(id: number): TaskTemplatesDeadlinePatchPatchById {
        return new TaskTemplatesDeadlinePatchPatchById(id);
    }

    public estimatePatchPatchById(id: number): TaskTemplatesEstimatePatchPatchById {
        return new TaskTemplatesEstimatePatchPatchById(id);
    }

    public deliveryDescriptionPatchPatchById(id: number): TaskTemplatesDeliveryDescriptionPatchPatchById {
        return new TaskTemplatesDeliveryDescriptionPatchPatchById(id);
    }

    public purposePatchPatchById(id: number): TaskTemplatesPurposePatchPatchById {
        return new TaskTemplatesPurposePatchPatchById(id);
    }

    public userAddPostById(id: number): TaskTemplatesUserAddPostById {
        return new TaskTemplatesUserAddPostById(id);
    }

    public userRemoveDeleteByIdByUserId(id: number, userId: number): TaskTemplatesUserRemoveDeleteByIdByUserId {
        return new TaskTemplatesUserRemoveDeleteByIdByUserId(id, userId);
    }

    public indexingPut(): TaskTemplatesIndexingPut {
        return new TaskTemplatesIndexingPut();
    }

}

export class TaskTemplatesGet extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_templates`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public where(name: string, value: any): TaskTemplatesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TaskTemplatesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TaskTemplatesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TaskTemplatesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TaskTemplatesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TaskTemplatesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TaskTemplatesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TaskTemplatesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TaskTemplatesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TaskTemplatesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TaskTemplatesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TaskTemplatesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TaskTemplatesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TaskTemplatesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TaskTemplatesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TaskTemplatesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TaskTemplatesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: TaskTemplate[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskTemplate[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskTemplatesGetById extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_templates/${id}`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public include(name: string): TaskTemplatesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: TaskTemplate[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskTemplate[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskTemplatesPost extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_templates`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public save(data: TaskTemplate, next?: (value: TaskTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTemplatesPutById extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_templates/${id}`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public save(data: TaskTemplate, next?: (value: TaskTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTemplatesPut extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_templates`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public save(data: TaskTemplate, next?: (value: TaskTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTemplatesPatchById extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_templates/${id}`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public save(data: TaskTemplate, next?: (value: TaskTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTemplatesPatch extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_templates`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public save(data: TaskTemplate, next?: (value: TaskTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTemplatesDeleteById extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_templates/${id}`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public delete(next?: (value: TaskTemplate) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class TaskTemplatesUsersGetById extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_templates/${id}/users`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public find(next?: (value: User[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | User[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskTemplatesTitlePatchPatchById extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_templates/${id}/title`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public title(value: string): TaskTemplatesTitlePatchPatchById {
        this.addQueryParameter('title', value);
        return this;
    }

    public save(data: any, next?: (value: TaskTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTemplatesDeadlinePatchPatchById extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_templates/${id}/deadline`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public value(value: number): TaskTemplatesDeadlinePatchPatchById {
        this.addQueryParameter('value', value);
        return this;
    }

    public type(value: number): TaskTemplatesDeadlinePatchPatchById {
        this.addQueryParameter('type', value);
        return this;
    }

    public save(data: any, next?: (value: TaskTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTemplatesEstimatePatchPatchById extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_templates/${id}/estimate`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public value(value: number): TaskTemplatesEstimatePatchPatchById {
        this.addQueryParameter('value', value);
        return this;
    }

    public unit_id(value: number): TaskTemplatesEstimatePatchPatchById {
        this.addQueryParameter('unit_id', value);
        return this;
    }

    public save(data: any, next?: (value: TaskTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTemplatesDeliveryDescriptionPatchPatchById extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_templates/${id}/delivery_description`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public delivery_description(value: string): TaskTemplatesDeliveryDescriptionPatchPatchById {
        this.addQueryParameter('delivery_description', value);
        return this;
    }

    public save(data: any, next?: (value: TaskTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTemplatesPurposePatchPatchById extends BaseApi<TaskTemplate> {

    public topic = 'Resources.TaskTemplates';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_templates/${id}/purpose`;
    }

    protected convertToResource(data: any): TaskTemplate {
        return new TaskTemplate(data);
    }

    public purpose(value: string): TaskTemplatesPurposePatchPatchById {
        this.addQueryParameter('purpose', value);
        return this;
    }

    public save(data: any, next?: (value: TaskTemplate) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTemplatesUserAddPostById extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_templates/${id}/users`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public user_id(value: number): TaskTemplatesUserAddPostById {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public save(data: any, next?: (value: User) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTemplatesUserRemoveDeleteByIdByUserId extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number, userId: number) {
        super();
        this.uri = `/task_templates/${id}/users/${userId}`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public delete(next?: (value: User) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class TaskTemplatesIndexingPut extends BaseApi<IntArrayInterface> {

    public topic = 'Resources.IntArrayInterfaces';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_templates/indexing`;
    }

    protected convertToResource(data: any): IntArrayInterface {
        return data;
    }

    public save(data: IntArrayInterface, next?: (value: IntArrayInterface) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

class TaskTypes {

    public get(): TaskTypesGet {
        return new TaskTypesGet();
    }

    public getById(id: number): TaskTypesGetById {
        return new TaskTypesGetById(id);
    }

    public post(): TaskTypesPost {
        return new TaskTypesPost();
    }

    public putById(id: number): TaskTypesPutById {
        return new TaskTypesPutById(id);
    }

    public put(): TaskTypesPut {
        return new TaskTypesPut();
    }

    public patchById(id: number): TaskTypesPatchById {
        return new TaskTypesPatchById(id);
    }

    public patch(): TaskTypesPatch {
        return new TaskTypesPatch();
    }

    public deleteById(id: number): TaskTypesDeleteById {
        return new TaskTypesDeleteById(id);
    }

}

export class TaskTypesGet extends BaseApi<TaskType> {

    public topic = 'Resources.TaskTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_types`;
    }

    protected convertToResource(data: any): TaskType {
        return new TaskType(data);
    }

    public where(name: string, value: any): TaskTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TaskTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TaskTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TaskTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TaskTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TaskTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TaskTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TaskTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TaskTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TaskTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TaskTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TaskTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TaskTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TaskTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TaskTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TaskTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TaskTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: TaskType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskType[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskTypesGetById extends BaseApi<TaskType> {

    public topic = 'Resources.TaskTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_types/${id}`;
    }

    protected convertToResource(data: any): TaskType {
        return new TaskType(data);
    }

    public include(name: string): TaskTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: TaskType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskType[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskTypesPost extends BaseApi<TaskType> {

    public topic = 'Resources.TaskTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_types`;
    }

    protected convertToResource(data: any): TaskType {
        return new TaskType(data);
    }

    public save(data: TaskType, next?: (value: TaskType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTypesPutById extends BaseApi<TaskType> {

    public topic = 'Resources.TaskTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_types/${id}`;
    }

    protected convertToResource(data: any): TaskType {
        return new TaskType(data);
    }

    public save(data: TaskType, next?: (value: TaskType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTypesPut extends BaseApi<TaskType> {

    public topic = 'Resources.TaskTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_types`;
    }

    protected convertToResource(data: any): TaskType {
        return new TaskType(data);
    }

    public save(data: TaskType, next?: (value: TaskType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTypesPatchById extends BaseApi<TaskType> {

    public topic = 'Resources.TaskTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_types/${id}`;
    }

    protected convertToResource(data: any): TaskType {
        return new TaskType(data);
    }

    public save(data: TaskType, next?: (value: TaskType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTypesPatch extends BaseApi<TaskType> {

    public topic = 'Resources.TaskTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_types`;
    }

    protected convertToResource(data: any): TaskType {
        return new TaskType(data);
    }

    public save(data: TaskType, next?: (value: TaskType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskTypesDeleteById extends BaseApi<TaskType> {

    public topic = 'Resources.TaskTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_types/${id}`;
    }

    protected convertToResource(data: any): TaskType {
        return new TaskType(data);
    }

    public delete(next?: (value: TaskType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class TaskUserTypes {

    public get(): TaskUserTypesGet {
        return new TaskUserTypesGet();
    }

    public getById(id: number): TaskUserTypesGetById {
        return new TaskUserTypesGetById(id);
    }

    public post(): TaskUserTypesPost {
        return new TaskUserTypesPost();
    }

    public putById(id: number): TaskUserTypesPutById {
        return new TaskUserTypesPutById(id);
    }

    public put(): TaskUserTypesPut {
        return new TaskUserTypesPut();
    }

    public patchById(id: number): TaskUserTypesPatchById {
        return new TaskUserTypesPatchById(id);
    }

    public patch(): TaskUserTypesPatch {
        return new TaskUserTypesPatch();
    }

    public deleteById(id: number): TaskUserTypesDeleteById {
        return new TaskUserTypesDeleteById(id);
    }

}

export class TaskUserTypesGet extends BaseApi<TaskUserType> {

    public topic = 'Resources.TaskUserTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_user_types`;
    }

    protected convertToResource(data: any): TaskUserType {
        return new TaskUserType(data);
    }

    public where(name: string, value: any): TaskUserTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TaskUserTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TaskUserTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TaskUserTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TaskUserTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TaskUserTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TaskUserTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TaskUserTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TaskUserTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TaskUserTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TaskUserTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TaskUserTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TaskUserTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TaskUserTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TaskUserTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TaskUserTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TaskUserTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: TaskUserType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskUserType[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskUserTypesGetById extends BaseApi<TaskUserType> {

    public topic = 'Resources.TaskUserTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_user_types/${id}`;
    }

    protected convertToResource(data: any): TaskUserType {
        return new TaskUserType(data);
    }

    public include(name: string): TaskUserTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: TaskUserType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TaskUserType[] | any[]> {
        return super.executeClientGet();
    }
}

export class TaskUserTypesPost extends BaseApi<TaskUserType> {

    public topic = 'Resources.TaskUserTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_user_types`;
    }

    protected convertToResource(data: any): TaskUserType {
        return new TaskUserType(data);
    }

    public save(data: TaskUserType, next?: (value: TaskUserType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskUserTypesPutById extends BaseApi<TaskUserType> {

    public topic = 'Resources.TaskUserTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_user_types/${id}`;
    }

    protected convertToResource(data: any): TaskUserType {
        return new TaskUserType(data);
    }

    public save(data: TaskUserType, next?: (value: TaskUserType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskUserTypesPut extends BaseApi<TaskUserType> {

    public topic = 'Resources.TaskUserTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_user_types`;
    }

    protected convertToResource(data: any): TaskUserType {
        return new TaskUserType(data);
    }

    public save(data: TaskUserType, next?: (value: TaskUserType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskUserTypesPatchById extends BaseApi<TaskUserType> {

    public topic = 'Resources.TaskUserTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_user_types/${id}`;
    }

    protected convertToResource(data: any): TaskUserType {
        return new TaskUserType(data);
    }

    public save(data: TaskUserType, next?: (value: TaskUserType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskUserTypesPatch extends BaseApi<TaskUserType> {

    public topic = 'Resources.TaskUserTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/task_user_types`;
    }

    protected convertToResource(data: any): TaskUserType {
        return new TaskUserType(data);
    }

    public save(data: TaskUserType, next?: (value: TaskUserType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TaskUserTypesDeleteById extends BaseApi<TaskUserType> {

    public topic = 'Resources.TaskUserTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/task_user_types/${id}`;
    }

    protected convertToResource(data: any): TaskUserType {
        return new TaskUserType(data);
    }

    public delete(next?: (value: TaskUserType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Tasks {

    public get(): TasksGet {
        return new TasksGet();
    }

    public getById(id: number): TasksGetById {
        return new TasksGetById(id);
    }

    public deleteById(id: number): TasksDeleteById {
        return new TasksDeleteById(id);
    }

    public createPost(): TasksCreatePost {
        return new TasksCreatePost();
    }

    public updateTitlePutByTaskId(taskId: number): TasksUpdateTitlePutByTaskId {
        return new TasksUpdateTitlePutByTaskId(taskId);
    }

    public updateStatusPutByTaskId(taskId: number): TasksUpdateStatusPutByTaskId {
        return new TasksUpdateStatusPutByTaskId(taskId);
    }

    public updateArchivedPutByTaskId(taskId: number): TasksUpdateArchivedPutByTaskId {
        return new TasksUpdateArchivedPutByTaskId(taskId);
    }

    public addUserPutByTaskId(taskId: number): TasksAddUserPutByTaskId {
        return new TasksAddUserPutByTaskId(taskId);
    }

    public addUserFromDepartmentPutByTaskId(taskId: number): TasksAddUserFromDepartmentPutByTaskId {
        return new TasksAddUserFromDepartmentPutByTaskId(taskId);
    }

    public removeUserPutByTaskId(taskId: number): TasksRemoveUserPutByTaskId {
        return new TasksRemoveUserPutByTaskId(taskId);
    }

    public removeUsersPutByTaskId(taskId: number): TasksRemoveUsersPutByTaskId {
        return new TasksRemoveUsersPutByTaskId(taskId);
    }

    public replaceUserPutByTaskId(taskId: number): TasksReplaceUserPutByTaskId {
        return new TasksReplaceUserPutByTaskId(taskId);
    }

    public updateUserDeadlinePutByTaskId(taskId: number): TasksUpdateUserDeadlinePutByTaskId {
        return new TasksUpdateUserDeadlinePutByTaskId(taskId);
    }

    public updateUserArchivedPutByTaskId(taskId: number): TasksUpdateUserArchivedPutByTaskId {
        return new TasksUpdateUserArchivedPutByTaskId(taskId);
    }

    public updateDeadlinePutByTaskId(taskId: number): TasksUpdateDeadlinePutByTaskId {
        return new TasksUpdateDeadlinePutByTaskId(taskId);
    }

    public updateEstimatePutByTaskId(taskId: number): TasksUpdateEstimatePutByTaskId {
        return new TasksUpdateEstimatePutByTaskId(taskId);
    }

    public updateUseStatusRulesPutByTaskId(taskId: number): TasksUpdateUseStatusRulesPutByTaskId {
        return new TasksUpdateUseStatusRulesPutByTaskId(taskId);
    }

    public updatePurposePutByTaskId(taskId: number): TasksUpdatePurposePutByTaskId {
        return new TasksUpdatePurposePutByTaskId(taskId);
    }

    public updateDeliveryDescriptionPutByTaskId(taskId: number): TasksUpdateDeliveryDescriptionPutByTaskId {
        return new TasksUpdateDeliveryDescriptionPutByTaskId(taskId);
    }

    public updateSuccessCriteriaPutByTaskId(taskId: number): TasksUpdateSuccessCriteriaPutByTaskId {
        return new TasksUpdateSuccessCriteriaPutByTaskId(taskId);
    }

    public updateObsPutByTaskId(taskId: number): TasksUpdateObsPutByTaskId {
        return new TasksUpdateObsPutByTaskId(taskId);
    }

    public addCategoryPutByTaskId(taskId: number): TasksAddCategoryPutByTaskId {
        return new TasksAddCategoryPutByTaskId(taskId);
    }

    public setCategoriesForTypePutByTaskId(taskId: number): TasksSetCategoriesForTypePutByTaskId {
        return new TasksSetCategoriesForTypePutByTaskId(taskId);
    }

    public replaceCategoriesPutByTaskId(taskId: number): TasksReplaceCategoriesPutByTaskId {
        return new TasksReplaceCategoriesPutByTaskId(taskId);
    }

    public removeCategoryPutByTaskId(taskId: number): TasksRemoveCategoryPutByTaskId {
        return new TasksRemoveCategoryPutByTaskId(taskId);
    }

    public addDepartmentPutByTaskId(taskId: number): TasksAddDepartmentPutByTaskId {
        return new TasksAddDepartmentPutByTaskId(taskId);
    }

    public removeDepartmentPutByTaskId(taskId: number): TasksRemoveDepartmentPutByTaskId {
        return new TasksRemoveDepartmentPutByTaskId(taskId);
    }

    public addTagPutByTaskId(taskId: number): TasksAddTagPutByTaskId {
        return new TasksAddTagPutByTaskId(taskId);
    }

    public removeTagPutByTaskId(taskId: number): TasksRemoveTagPutByTaskId {
        return new TasksRemoveTagPutByTaskId(taskId);
    }

    public updateCanCopyPutByTaskId(taskId: number): TasksUpdateCanCopyPutByTaskId {
        return new TasksUpdateCanCopyPutByTaskId(taskId);
    }

    public updateIsPrivatePutByTaskId(taskId: number): TasksUpdateIsPrivatePutByTaskId {
        return new TasksUpdateIsPrivatePutByTaskId(taskId);
    }

    public updateUserAcceptanceStatusPutByTaskId(taskId: number): TasksUpdateUserAcceptanceStatusPutByTaskId {
        return new TasksUpdateUserAcceptanceStatusPutByTaskId(taskId);
    }

    public updateCasesPlannedPutByTaskId(taskId: number): TasksUpdateCasesPlannedPutByTaskId {
        return new TasksUpdateCasesPlannedPutByTaskId(taskId);
    }

    public updateCasesReachedPutByTaskId(taskId: number): TasksUpdateCasesReachedPutByTaskId {
        return new TasksUpdateCasesReachedPutByTaskId(taskId);
    }

    public addMilestonePutByTaskId(taskId: number): TasksAddMilestonePutByTaskId {
        return new TasksAddMilestonePutByTaskId(taskId);
    }

    public removeMilestonePutByTaskId(taskId: number): TasksRemoveMilestonePutByTaskId {
        return new TasksRemoveMilestonePutByTaskId(taskId);
    }

    public replaceMilestonePutByTaskId(taskId: number): TasksReplaceMilestonePutByTaskId {
        return new TasksReplaceMilestonePutByTaskId(taskId);
    }

    public addProjectPutByTaskId(taskId: number): TasksAddProjectPutByTaskId {
        return new TasksAddProjectPutByTaskId(taskId);
    }

    public removeProjectPutByTaskId(taskId: number): TasksRemoveProjectPutByTaskId {
        return new TasksRemoveProjectPutByTaskId(taskId);
    }

    public setProjectForTypePutByTaskId(taskId: number): TasksSetProjectForTypePutByTaskId {
        return new TasksSetProjectForTypePutByTaskId(taskId);
    }

    public replaceProjectPutByTaskId(taskId: number): TasksReplaceProjectPutByTaskId {
        return new TasksReplaceProjectPutByTaskId(taskId);
    }

    public updateTypePutByTaskId(taskId: number): TasksUpdateTypePutByTaskId {
        return new TasksUpdateTypePutByTaskId(taskId);
    }

    public starGetByTaskId(taskId: number): TasksStarGetByTaskId {
        return new TasksStarGetByTaskId(taskId);
    }

    public starPutByTaskId(taskId: number): TasksStarPutByTaskId {
        return new TasksStarPutByTaskId(taskId);
    }

    public starDeleteByTaskId(taskId: number): TasksStarDeleteByTaskId {
        return new TasksStarDeleteByTaskId(taskId);
    }

    public hand_upGetByTaskId(taskId: number): TasksHand_upGetByTaskId {
        return new TasksHand_upGetByTaskId(taskId);
    }

    public hand_upPutByTaskId(taskId: number): TasksHand_upPutByTaskId {
        return new TasksHand_upPutByTaskId(taskId);
    }

    public deleteByTaskId(taskId: number): TasksDeleteByTaskId {
        return new TasksDeleteByTaskId(taskId);
    }

    public logsGetByTaskId(taskId: number): TasksLogsGetByTaskId {
        return new TasksLogsGetByTaskId(taskId);
    }

    public copyGetById(id: number): TasksCopyGetById {
        return new TasksCopyGetById(id);
    }

    public reactionAddPutByTaskId(taskId: number): TasksReactionAddPutByTaskId {
        return new TasksReactionAddPutByTaskId(taskId);
    }

    public reactionRemoveDeleteByTaskId(taskId: number): TasksReactionRemoveDeleteByTaskId {
        return new TasksReactionRemoveDeleteByTaskId(taskId);
    }

    public deletedGet(): TasksDeletedGet {
        return new TasksDeletedGet();
    }

    public delete(): TasksDelete {
        return new TasksDelete();
    }

}

export class TasksGet extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/tasks`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public where(name: string, value: any): TasksGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TasksGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TasksGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TasksGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TasksGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TasksGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TasksGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TasksGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TasksGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TasksGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TasksGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TasksGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TasksGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TasksGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TasksGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TasksGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TasksGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Task[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Task[] | any[]> {
        return super.executeClientGet();
    }
}

export class TasksGetById extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/tasks/${id}`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public include(name: string): TasksGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Task[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Task[] | any[]> {
        return super.executeClientGet();
    }
}

export class TasksDeleteById extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/tasks/${id}`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public delete(next?: (value: Task) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class TasksCreatePost extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/tasks/create`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public typeId(value: number): TasksCreatePost {
        this.addQueryParameter('typeId', value);
        return this;
    }

    public save(data: TasksCreateRequest, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateTitlePutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/title`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: string): TasksUpdateTitlePutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateStatusPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/status`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public taskStatusTypeId(value: number): TasksUpdateStatusPutByTaskId {
        this.addQueryParameter('taskStatusTypeId', value);
        return this;
    }

    public status(value: number): TasksUpdateStatusPutByTaskId {
        this.addQueryParameter('status', value);
        return this;
    }

    public resetUseStatusRules(value: boolean): TasksUpdateStatusPutByTaskId {
        this.addQueryParameter('resetUseStatusRules', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateArchivedPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/archived`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: boolean): TasksUpdateArchivedPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksAddUserPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/users/add`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public taskUserTypeId(value: number): TasksAddUserPutByTaskId {
        this.addQueryParameter('taskUserTypeId', value);
        return this;
    }

    public userId(value: number): TasksAddUserPutByTaskId {
        this.addQueryParameter('userId', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksAddUserFromDepartmentPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/users/add-from-department`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public taskUserTypeId(value: number): TasksAddUserFromDepartmentPutByTaskId {
        this.addQueryParameter('taskUserTypeId', value);
        return this;
    }

    public departmentId(value: number): TasksAddUserFromDepartmentPutByTaskId {
        this.addQueryParameter('departmentId', value);
        return this;
    }

    public userIds(value: number[]): TasksAddUserFromDepartmentPutByTaskId {
        this.addQueryParameter('userIds', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksRemoveUserPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/users/remove`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public taskUserTypeId(value: number): TasksRemoveUserPutByTaskId {
        this.addQueryParameter('taskUserTypeId', value);
        return this;
    }

    public userId(value: number): TasksRemoveUserPutByTaskId {
        this.addQueryParameter('userId', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksRemoveUsersPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/users/remove-multiple`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public taskUserTypeId(value: number): TasksRemoveUsersPutByTaskId {
        this.addQueryParameter('taskUserTypeId', value);
        return this;
    }

    public userIds(value: number[]): TasksRemoveUsersPutByTaskId {
        this.addQueryParameter('userIds', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksReplaceUserPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/users/replace`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public taskUserTypeId(value: number): TasksReplaceUserPutByTaskId {
        this.addQueryParameter('taskUserTypeId', value);
        return this;
    }

    public fromUserId(value: number): TasksReplaceUserPutByTaskId {
        this.addQueryParameter('fromUserId', value);
        return this;
    }

    public toUserId(value: number): TasksReplaceUserPutByTaskId {
        this.addQueryParameter('toUserId', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateUserDeadlinePutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/users/deadline`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public userId(value: number): TasksUpdateUserDeadlinePutByTaskId {
        this.addQueryParameter('userId', value);
        return this;
    }

    public date(value: string): TasksUpdateUserDeadlinePutByTaskId {
        this.addQueryParameter('date', value);
        return this;
    }

    public isSoft(value: boolean): TasksUpdateUserDeadlinePutByTaskId {
        this.addQueryParameter('isSoft', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateUserArchivedPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/users/archived`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public userId(value: number): TasksUpdateUserArchivedPutByTaskId {
        this.addQueryParameter('userId', value);
        return this;
    }

    public isArchived(value: boolean): TasksUpdateUserArchivedPutByTaskId {
        this.addQueryParameter('isArchived', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateDeadlinePutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/deadline`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public taskDeadlineTypeId(value: number): TasksUpdateDeadlinePutByTaskId {
        this.addQueryParameter('taskDeadlineTypeId', value);
        return this;
    }

    public date(value: string): TasksUpdateDeadlinePutByTaskId {
        this.addQueryParameter('date', value);
        return this;
    }

    public isSoft(value: boolean): TasksUpdateDeadlinePutByTaskId {
        this.addQueryParameter('isSoft', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateEstimatePutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/estimate`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public taskEstimateTypeId(value: number): TasksUpdateEstimatePutByTaskId {
        this.addQueryParameter('taskEstimateTypeId', value);
        return this;
    }

    public unitId(value: number): TasksUpdateEstimatePutByTaskId {
        this.addQueryParameter('unitId', value);
        return this;
    }

    public value(value: number): TasksUpdateEstimatePutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateUseStatusRulesPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/useStatusRules`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: boolean): TasksUpdateUseStatusRulesPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdatePurposePutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/purpose`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public save(data: StringInterface, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateDeliveryDescriptionPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/deliveryDescription`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public save(data: StringInterface, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateSuccessCriteriaPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/successCriteria`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public save(data: StringInterface, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateObsPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/obs`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public save(data: StringInterface, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksAddCategoryPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/categories/add`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksAddCategoryPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksSetCategoriesForTypePutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/categories/set-for-type`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public categoryTypeId(value: number): TasksSetCategoriesForTypePutByTaskId {
        this.addQueryParameter('categoryTypeId', value);
        return this;
    }

    public categoryIds(value: number[]): TasksSetCategoriesForTypePutByTaskId {
        this.addQueryParameter('categoryIds', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksReplaceCategoriesPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/categories/replace`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public removedCategoryIds(value: number[]): TasksReplaceCategoriesPutByTaskId {
        this.addQueryParameter('removedCategoryIds', value);
        return this;
    }

    public addedCategoryIds(value: number[]): TasksReplaceCategoriesPutByTaskId {
        this.addQueryParameter('addedCategoryIds', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksRemoveCategoryPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/categories/remove`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksRemoveCategoryPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksAddDepartmentPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/departments/add`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksAddDepartmentPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksRemoveDepartmentPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/departments/remove`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksRemoveDepartmentPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksAddTagPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/tags/add`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksAddTagPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksRemoveTagPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/tags/remove`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksRemoveTagPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateCanCopyPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/canCopy`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: boolean): TasksUpdateCanCopyPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateIsPrivatePutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/isPrivate`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: boolean): TasksUpdateIsPrivatePutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateUserAcceptanceStatusPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/users/acceptance-status`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksUpdateUserAcceptanceStatusPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateCasesPlannedPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/casesPlanned`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksUpdateCasesPlannedPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateCasesReachedPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/casesReached`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksUpdateCasesReachedPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksAddMilestonePutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/milestones/add`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksAddMilestonePutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksRemoveMilestonePutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/milestones/remove`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksRemoveMilestonePutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksReplaceMilestonePutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/milestones/replace`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public fromMilestoneId(value: number): TasksReplaceMilestonePutByTaskId {
        this.addQueryParameter('fromMilestoneId', value);
        return this;
    }

    public toMilestoneId(value: number): TasksReplaceMilestonePutByTaskId {
        this.addQueryParameter('toMilestoneId', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksAddProjectPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/projects/add`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksAddProjectPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksRemoveProjectPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/projects/remove`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksRemoveProjectPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksSetProjectForTypePutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/projects/set-for-type`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public projectId(value: number): TasksSetProjectForTypePutByTaskId {
        this.addQueryParameter('projectId', value);
        return this;
    }

    public projectTypeId(value: number): TasksSetProjectForTypePutByTaskId {
        this.addQueryParameter('projectTypeId', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksReplaceProjectPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/projects/replace`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public fromProjectId(value: number): TasksReplaceProjectPutByTaskId {
        this.addQueryParameter('fromProjectId', value);
        return this;
    }

    public toProjectId(value: number): TasksReplaceProjectPutByTaskId {
        this.addQueryParameter('toProjectId', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksUpdateTypePutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/type`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public value(value: number): TasksUpdateTypePutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksStarGetByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/star`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public find(next?: (value: Task[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Task[] | any[]> {
        return super.executeClientGet();
    }
}

export class TasksStarPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/star`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public user_id(value: number): TasksStarPutByTaskId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksStarDeleteByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/star`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public user_id(value: number): TasksStarDeleteByTaskId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public delete(next?: (value: Task) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class TasksHand_upGetByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/hand_up`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public find(next?: (value: Task[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Task[] | any[]> {
        return super.executeClientGet();
    }
}

export class TasksHand_upPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/hand_up`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public user_id(value: number): TasksHand_upPutByTaskId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksDeleteByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/hand_up`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public user_id(value: number): TasksDeleteByTaskId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public delete(next?: (value: Task) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class TasksLogsGetByTaskId extends BaseApi<Log> {

    public topic = 'Resources.Logs';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/logs`;
    }

    protected convertToResource(data: any): Log {
        return new Log(data);
    }

    public find(next?: (value: Log[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Log[] | any[]> {
        return super.executeClientGet();
    }
}

export class TasksCopyGetById extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/tasks/${id}/copy`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public find(next?: (value: Task[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Task[] | any[]> {
        return super.executeClientGet();
    }
}

export class TasksReactionAddPutByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/reactions`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public reaction_type_id(value: number): TasksReactionAddPutByTaskId {
        this.addQueryParameter('reaction_type_id', value);
        return this;
    }

    public value(value: string): TasksReactionAddPutByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public user_id(value: number): TasksReactionAddPutByTaskId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public save(data: any, next?: (value: Task) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TasksReactionRemoveDeleteByTaskId extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(taskId: number) {
        super();
        this.uri = `/tasks/${taskId}/reactions`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public reaction_type_id(value: number): TasksReactionRemoveDeleteByTaskId {
        this.addQueryParameter('reaction_type_id', value);
        return this;
    }

    public value(value: string): TasksReactionRemoveDeleteByTaskId {
        this.addQueryParameter('value', value);
        return this;
    }

    public user_id(value: number): TasksReactionRemoveDeleteByTaskId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public delete(next?: (value: Task) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class TasksDeletedGet extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/tasks/deleted`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public limit(value: number): TasksDeletedGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TasksDeletedGet {
        this.offsetValue = value;
        return this;
    }

    public find(next?: (value: Task[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Task[] | any[]> {
        return super.executeClientGet();
    }
}

export class TasksDelete extends BaseApi<Task> {

    public topic = 'Resources.Tasks';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/tasks/{id}`;
    }

    protected convertToResource(data: any): Task {
        return new Task(data);
    }

    public delete(next?: (value: Task) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class TeamDisplayUserPositions {

    public get(): TeamDisplayUserPositionsGet {
        return new TeamDisplayUserPositionsGet();
    }

    public getById(id: number): TeamDisplayUserPositionsGetById {
        return new TeamDisplayUserPositionsGetById(id);
    }

}

export class TeamDisplayUserPositionsGet extends BaseApi<TeamDisplayUserPosition> {

    public topic = 'Resources.TeamDisplayUserPositions';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/team_display_user_positions`;
    }

    protected convertToResource(data: any): TeamDisplayUserPosition {
        return new TeamDisplayUserPosition(data);
    }

    public where(name: string, value: any): TeamDisplayUserPositionsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TeamDisplayUserPositionsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TeamDisplayUserPositionsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TeamDisplayUserPositionsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TeamDisplayUserPositionsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TeamDisplayUserPositionsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TeamDisplayUserPositionsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TeamDisplayUserPositionsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TeamDisplayUserPositionsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TeamDisplayUserPositionsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TeamDisplayUserPositionsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TeamDisplayUserPositionsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TeamDisplayUserPositionsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TeamDisplayUserPositionsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TeamDisplayUserPositionsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TeamDisplayUserPositionsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TeamDisplayUserPositionsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: TeamDisplayUserPosition[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TeamDisplayUserPosition[] | any[]> {
        return super.executeClientGet();
    }
}

export class TeamDisplayUserPositionsGetById extends BaseApi<TeamDisplayUserPosition> {

    public topic = 'Resources.TeamDisplayUserPositions';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/team_display_user_positions/${id}`;
    }

    protected convertToResource(data: any): TeamDisplayUserPosition {
        return new TeamDisplayUserPosition(data);
    }

    public include(name: string): TeamDisplayUserPositionsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: TeamDisplayUserPosition[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TeamDisplayUserPosition[] | any[]> {
        return super.executeClientGet();
    }
}

class TodoCategories {

    public get(): TodoCategoriesGet {
        return new TodoCategoriesGet();
    }

    public getById(id: number): TodoCategoriesGetById {
        return new TodoCategoriesGetById(id);
    }

    public post(): TodoCategoriesPost {
        return new TodoCategoriesPost();
    }

    public putById(id: number): TodoCategoriesPutById {
        return new TodoCategoriesPutById(id);
    }

    public put(): TodoCategoriesPut {
        return new TodoCategoriesPut();
    }

    public patchById(id: number): TodoCategoriesPatchById {
        return new TodoCategoriesPatchById(id);
    }

    public patch(): TodoCategoriesPatch {
        return new TodoCategoriesPatch();
    }

    public deleteById(id: number): TodoCategoriesDeleteById {
        return new TodoCategoriesDeleteById(id);
    }

}

export class TodoCategoriesGet extends BaseApi<TodoCategory> {

    public topic = 'Resources.TodoCategories';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_categories`;
    }

    protected convertToResource(data: any): TodoCategory {
        return new TodoCategory(data);
    }

    public where(name: string, value: any): TodoCategoriesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TodoCategoriesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TodoCategoriesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TodoCategoriesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TodoCategoriesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TodoCategoriesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TodoCategoriesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TodoCategoriesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TodoCategoriesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TodoCategoriesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TodoCategoriesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TodoCategoriesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TodoCategoriesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TodoCategoriesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TodoCategoriesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TodoCategoriesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TodoCategoriesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: TodoCategory[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TodoCategory[] | any[]> {
        return super.executeClientGet();
    }
}

export class TodoCategoriesGetById extends BaseApi<TodoCategory> {

    public topic = 'Resources.TodoCategories';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_categories/${id}`;
    }

    protected convertToResource(data: any): TodoCategory {
        return new TodoCategory(data);
    }

    public include(name: string): TodoCategoriesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: TodoCategory[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TodoCategory[] | any[]> {
        return super.executeClientGet();
    }
}

export class TodoCategoriesPost extends BaseApi<TodoCategory> {

    public topic = 'Resources.TodoCategories';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_categories`;
    }

    protected convertToResource(data: any): TodoCategory {
        return new TodoCategory(data);
    }

    public save(data: TodoCategory, next?: (value: TodoCategory) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoCategoriesPutById extends BaseApi<TodoCategory> {

    public topic = 'Resources.TodoCategories';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_categories/${id}`;
    }

    protected convertToResource(data: any): TodoCategory {
        return new TodoCategory(data);
    }

    public save(data: TodoCategory, next?: (value: TodoCategory) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoCategoriesPut extends BaseApi<TodoCategory> {

    public topic = 'Resources.TodoCategories';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_categories`;
    }

    protected convertToResource(data: any): TodoCategory {
        return new TodoCategory(data);
    }

    public save(data: TodoCategory, next?: (value: TodoCategory) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoCategoriesPatchById extends BaseApi<TodoCategory> {

    public topic = 'Resources.TodoCategories';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_categories/${id}`;
    }

    protected convertToResource(data: any): TodoCategory {
        return new TodoCategory(data);
    }

    public save(data: TodoCategory, next?: (value: TodoCategory) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoCategoriesPatch extends BaseApi<TodoCategory> {

    public topic = 'Resources.TodoCategories';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_categories`;
    }

    protected convertToResource(data: any): TodoCategory {
        return new TodoCategory(data);
    }

    public save(data: TodoCategory, next?: (value: TodoCategory) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoCategoriesDeleteById extends BaseApi<TodoCategory> {

    public topic = 'Resources.TodoCategories';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_categories/${id}`;
    }

    protected convertToResource(data: any): TodoCategory {
        return new TodoCategory(data);
    }

    public delete(next?: (value: TodoCategory) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class TodoFields {

    public get(): TodoFieldsGet {
        return new TodoFieldsGet();
    }

    public getById(id: number): TodoFieldsGetById {
        return new TodoFieldsGetById(id);
    }

    public post(): TodoFieldsPost {
        return new TodoFieldsPost();
    }

    public putById(id: number): TodoFieldsPutById {
        return new TodoFieldsPutById(id);
    }

    public put(): TodoFieldsPut {
        return new TodoFieldsPut();
    }

    public patchById(id: number): TodoFieldsPatchById {
        return new TodoFieldsPatchById(id);
    }

    public patch(): TodoFieldsPatch {
        return new TodoFieldsPatch();
    }

    public deleteById(id: number): TodoFieldsDeleteById {
        return new TodoFieldsDeleteById(id);
    }

}

export class TodoFieldsGet extends BaseApi<TodoField> {

    public topic = 'Resources.TodoFields';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_fields`;
    }

    protected convertToResource(data: any): TodoField {
        return new TodoField(data);
    }

    public where(name: string, value: any): TodoFieldsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TodoFieldsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TodoFieldsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TodoFieldsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TodoFieldsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TodoFieldsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TodoFieldsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TodoFieldsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TodoFieldsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TodoFieldsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TodoFieldsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TodoFieldsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TodoFieldsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TodoFieldsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TodoFieldsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TodoFieldsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TodoFieldsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: TodoField[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TodoField[] | any[]> {
        return super.executeClientGet();
    }
}

export class TodoFieldsGetById extends BaseApi<TodoField> {

    public topic = 'Resources.TodoFields';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_fields/${id}`;
    }

    protected convertToResource(data: any): TodoField {
        return new TodoField(data);
    }

    public include(name: string): TodoFieldsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: TodoField[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TodoField[] | any[]> {
        return super.executeClientGet();
    }
}

export class TodoFieldsPost extends BaseApi<TodoField> {

    public topic = 'Resources.TodoFields';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_fields`;
    }

    protected convertToResource(data: any): TodoField {
        return new TodoField(data);
    }

    public save(data: TodoField, next?: (value: TodoField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoFieldsPutById extends BaseApi<TodoField> {

    public topic = 'Resources.TodoFields';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_fields/${id}`;
    }

    protected convertToResource(data: any): TodoField {
        return new TodoField(data);
    }

    public save(data: TodoField, next?: (value: TodoField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoFieldsPut extends BaseApi<TodoField> {

    public topic = 'Resources.TodoFields';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_fields`;
    }

    protected convertToResource(data: any): TodoField {
        return new TodoField(data);
    }

    public save(data: TodoField, next?: (value: TodoField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoFieldsPatchById extends BaseApi<TodoField> {

    public topic = 'Resources.TodoFields';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_fields/${id}`;
    }

    protected convertToResource(data: any): TodoField {
        return new TodoField(data);
    }

    public save(data: TodoField, next?: (value: TodoField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoFieldsPatch extends BaseApi<TodoField> {

    public topic = 'Resources.TodoFields';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_fields`;
    }

    protected convertToResource(data: any): TodoField {
        return new TodoField(data);
    }

    public save(data: TodoField, next?: (value: TodoField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoFieldsDeleteById extends BaseApi<TodoField> {

    public topic = 'Resources.TodoFields';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_fields/${id}`;
    }

    protected convertToResource(data: any): TodoField {
        return new TodoField(data);
    }

    public delete(next?: (value: TodoField) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class TodoSettings {

    public get(): TodoSettingsGet {
        return new TodoSettingsGet();
    }

    public getById(id: number): TodoSettingsGetById {
        return new TodoSettingsGetById(id);
    }

    public post(): TodoSettingsPost {
        return new TodoSettingsPost();
    }

    public putById(id: number): TodoSettingsPutById {
        return new TodoSettingsPutById(id);
    }

    public put(): TodoSettingsPut {
        return new TodoSettingsPut();
    }

    public patchById(id: number): TodoSettingsPatchById {
        return new TodoSettingsPatchById(id);
    }

    public patch(): TodoSettingsPatch {
        return new TodoSettingsPatch();
    }

    public deleteById(id: number): TodoSettingsDeleteById {
        return new TodoSettingsDeleteById(id);
    }

}

export class TodoSettingsGet extends BaseApi<TodoSetting> {

    public topic = 'Resources.TodoSettings';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_settings`;
    }

    protected convertToResource(data: any): TodoSetting {
        return new TodoSetting(data);
    }

    public where(name: string, value: any): TodoSettingsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TodoSettingsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TodoSettingsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TodoSettingsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TodoSettingsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TodoSettingsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TodoSettingsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TodoSettingsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TodoSettingsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TodoSettingsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TodoSettingsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TodoSettingsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TodoSettingsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TodoSettingsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TodoSettingsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TodoSettingsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TodoSettingsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: TodoSetting[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TodoSetting[] | any[]> {
        return super.executeClientGet();
    }
}

export class TodoSettingsGetById extends BaseApi<TodoSetting> {

    public topic = 'Resources.TodoSettings';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_settings/${id}`;
    }

    protected convertToResource(data: any): TodoSetting {
        return new TodoSetting(data);
    }

    public include(name: string): TodoSettingsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: TodoSetting[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TodoSetting[] | any[]> {
        return super.executeClientGet();
    }
}

export class TodoSettingsPost extends BaseApi<TodoSetting> {

    public topic = 'Resources.TodoSettings';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_settings`;
    }

    protected convertToResource(data: any): TodoSetting {
        return new TodoSetting(data);
    }

    public save(data: TodoSetting, next?: (value: TodoSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoSettingsPutById extends BaseApi<TodoSetting> {

    public topic = 'Resources.TodoSettings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_settings/${id}`;
    }

    protected convertToResource(data: any): TodoSetting {
        return new TodoSetting(data);
    }

    public save(data: TodoSetting, next?: (value: TodoSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoSettingsPut extends BaseApi<TodoSetting> {

    public topic = 'Resources.TodoSettings';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_settings`;
    }

    protected convertToResource(data: any): TodoSetting {
        return new TodoSetting(data);
    }

    public save(data: TodoSetting, next?: (value: TodoSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoSettingsPatchById extends BaseApi<TodoSetting> {

    public topic = 'Resources.TodoSettings';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_settings/${id}`;
    }

    protected convertToResource(data: any): TodoSetting {
        return new TodoSetting(data);
    }

    public save(data: TodoSetting, next?: (value: TodoSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoSettingsPatch extends BaseApi<TodoSetting> {

    public topic = 'Resources.TodoSettings';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_settings`;
    }

    protected convertToResource(data: any): TodoSetting {
        return new TodoSetting(data);
    }

    public save(data: TodoSetting, next?: (value: TodoSetting) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoSettingsDeleteById extends BaseApi<TodoSetting> {

    public topic = 'Resources.TodoSettings';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_settings/${id}`;
    }

    protected convertToResource(data: any): TodoSetting {
        return new TodoSetting(data);
    }

    public delete(next?: (value: TodoSetting) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class TodoStatusTypes {

    public get(): TodoStatusTypesGet {
        return new TodoStatusTypesGet();
    }

    public getById(id: number): TodoStatusTypesGetById {
        return new TodoStatusTypesGetById(id);
    }

    public post(): TodoStatusTypesPost {
        return new TodoStatusTypesPost();
    }

    public putById(id: number): TodoStatusTypesPutById {
        return new TodoStatusTypesPutById(id);
    }

    public put(): TodoStatusTypesPut {
        return new TodoStatusTypesPut();
    }

    public patchById(id: number): TodoStatusTypesPatchById {
        return new TodoStatusTypesPatchById(id);
    }

    public patch(): TodoStatusTypesPatch {
        return new TodoStatusTypesPatch();
    }

    public deleteById(id: number): TodoStatusTypesDeleteById {
        return new TodoStatusTypesDeleteById(id);
    }

}

export class TodoStatusTypesGet extends BaseApi<TodoStatusType> {

    public topic = 'Resources.TodoStatusTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_status_types`;
    }

    protected convertToResource(data: any): TodoStatusType {
        return new TodoStatusType(data);
    }

    public where(name: string, value: any): TodoStatusTypesGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TodoStatusTypesGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TodoStatusTypesGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TodoStatusTypesGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TodoStatusTypesGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TodoStatusTypesGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TodoStatusTypesGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TodoStatusTypesGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TodoStatusTypesGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TodoStatusTypesGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TodoStatusTypesGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TodoStatusTypesGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TodoStatusTypesGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TodoStatusTypesGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TodoStatusTypesGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TodoStatusTypesGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TodoStatusTypesGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: TodoStatusType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TodoStatusType[] | any[]> {
        return super.executeClientGet();
    }
}

export class TodoStatusTypesGetById extends BaseApi<TodoStatusType> {

    public topic = 'Resources.TodoStatusTypes';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_status_types/${id}`;
    }

    protected convertToResource(data: any): TodoStatusType {
        return new TodoStatusType(data);
    }

    public include(name: string): TodoStatusTypesGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: TodoStatusType[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | TodoStatusType[] | any[]> {
        return super.executeClientGet();
    }
}

export class TodoStatusTypesPost extends BaseApi<TodoStatusType> {

    public topic = 'Resources.TodoStatusTypes';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_status_types`;
    }

    protected convertToResource(data: any): TodoStatusType {
        return new TodoStatusType(data);
    }

    public save(data: TodoStatusType, next?: (value: TodoStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoStatusTypesPutById extends BaseApi<TodoStatusType> {

    public topic = 'Resources.TodoStatusTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_status_types/${id}`;
    }

    protected convertToResource(data: any): TodoStatusType {
        return new TodoStatusType(data);
    }

    public save(data: TodoStatusType, next?: (value: TodoStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoStatusTypesPut extends BaseApi<TodoStatusType> {

    public topic = 'Resources.TodoStatusTypes';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_status_types`;
    }

    protected convertToResource(data: any): TodoStatusType {
        return new TodoStatusType(data);
    }

    public save(data: TodoStatusType, next?: (value: TodoStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoStatusTypesPatchById extends BaseApi<TodoStatusType> {

    public topic = 'Resources.TodoStatusTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_status_types/${id}`;
    }

    protected convertToResource(data: any): TodoStatusType {
        return new TodoStatusType(data);
    }

    public save(data: TodoStatusType, next?: (value: TodoStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoStatusTypesPatch extends BaseApi<TodoStatusType> {

    public topic = 'Resources.TodoStatusTypes';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todo_status_types`;
    }

    protected convertToResource(data: any): TodoStatusType {
        return new TodoStatusType(data);
    }

    public save(data: TodoStatusType, next?: (value: TodoStatusType) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodoStatusTypesDeleteById extends BaseApi<TodoStatusType> {

    public topic = 'Resources.TodoStatusTypes';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todo_status_types/${id}`;
    }

    protected convertToResource(data: any): TodoStatusType {
        return new TodoStatusType(data);
    }

    public delete(next?: (value: TodoStatusType) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Todos {

    public get(): TodosGet {
        return new TodosGet();
    }

    public getById(id: number): TodosGetById {
        return new TodosGetById(id);
    }

    public deleteById(id: number): TodosDeleteById {
        return new TodosDeleteById(id);
    }

    public createPost(): TodosCreatePost {
        return new TodosCreatePost();
    }

    public updateTitlePutByTodoId(todoId: number): TodosUpdateTitlePutByTodoId {
        return new TodosUpdateTitlePutByTodoId(todoId);
    }

    public updateCategoryPutByTodoId(todoId: number): TodosUpdateCategoryPutByTodoId {
        return new TodosUpdateCategoryPutByTodoId(todoId);
    }

    public updateStatusPutByTodoId(todoId: number): TodosUpdateStatusPutByTodoId {
        return new TodosUpdateStatusPutByTodoId(todoId);
    }

    public updateUserPutByTodoId(todoId: number): TodosUpdateUserPutByTodoId {
        return new TodosUpdateUserPutByTodoId(todoId);
    }

    public updateArchivedPutByTodoId(todoId: number): TodosUpdateArchivedPutByTodoId {
        return new TodosUpdateArchivedPutByTodoId(todoId);
    }

    public updateDeadlinePutByTodoId(todoId: number): TodosUpdateDeadlinePutByTodoId {
        return new TodosUpdateDeadlinePutByTodoId(todoId);
    }

    public updateDescriptionPutByTodoId(todoId: number): TodosUpdateDescriptionPutByTodoId {
        return new TodosUpdateDescriptionPutByTodoId(todoId);
    }

    public updateProjectPutByTodoId(todoId: number): TodosUpdateProjectPutByTodoId {
        return new TodosUpdateProjectPutByTodoId(todoId);
    }

    public updateIsPrivatePutByTodoId(todoId: number): TodosUpdateIsPrivatePutByTodoId {
        return new TodosUpdateIsPrivatePutByTodoId(todoId);
    }

    public updateShowOnDisplayPutByTodoId(todoId: number): TodosUpdateShowOnDisplayPutByTodoId {
        return new TodosUpdateShowOnDisplayPutByTodoId(todoId);
    }

    public starGetByTodoId(todoId: number): TodosStarGetByTodoId {
        return new TodosStarGetByTodoId(todoId);
    }

    public putByTodoId(todoId: number): TodosPutByTodoId {
        return new TodosPutByTodoId(todoId);
    }

    public deleteByTodoId(todoId: number): TodosDeleteByTodoId {
        return new TodosDeleteByTodoId(todoId);
    }

    public copyGetById(id: number): TodosCopyGetById {
        return new TodosCopyGetById(id);
    }

    public reactionAddPutByTodoId(todoId: number): TodosReactionAddPutByTodoId {
        return new TodosReactionAddPutByTodoId(todoId);
    }

    public reactionRemoveDeleteByTodoId(todoId: number): TodosReactionRemoveDeleteByTodoId {
        return new TodosReactionRemoveDeleteByTodoId(todoId);
    }

    public delete(): TodosDelete {
        return new TodosDelete();
    }

    public logsGetByTodoId(todoId: number): TodosLogsGetByTodoId {
        return new TodosLogsGetByTodoId(todoId);
    }

}

export class TodosGet extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todos`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public where(name: string, value: any): TodosGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): TodosGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): TodosGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): TodosGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): TodosGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): TodosGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): TodosGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): TodosGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): TodosGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): TodosGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): TodosGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): TodosGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): TodosGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): TodosGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): TodosGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): TodosGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): TodosGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: Todo[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Todo[] | any[]> {
        return super.executeClientGet();
    }
}

export class TodosGetById extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todos/${id}`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public include(name: string): TodosGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: Todo[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Todo[] | any[]> {
        return super.executeClientGet();
    }
}

export class TodosDeleteById extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todos/${id}`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public delete(next?: (value: Todo) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class TodosCreatePost extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todos/create`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public save(data: TodosCreateRequest, next?: (value: Todo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodosUpdateTitlePutByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/title`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public value(value: string): TodosUpdateTitlePutByTodoId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Todo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodosUpdateCategoryPutByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/category`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public todoCategoryId(value: number): TodosUpdateCategoryPutByTodoId {
        this.addQueryParameter('todoCategoryId', value);
        return this;
    }

    public save(data: any, next?: (value: Todo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodosUpdateStatusPutByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/status`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public value(value: number): TodosUpdateStatusPutByTodoId {
        this.addQueryParameter('value', value);
        return this;
    }

    public resetUseStatusRules(value: boolean): TodosUpdateStatusPutByTodoId {
        this.addQueryParameter('resetUseStatusRules', value);
        return this;
    }

    public save(data: any, next?: (value: Todo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodosUpdateUserPutByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/user`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public userId(value: number): TodosUpdateUserPutByTodoId {
        this.addQueryParameter('userId', value);
        return this;
    }

    public save(data: any, next?: (value: Todo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodosUpdateArchivedPutByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/archived`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public value(value: boolean): TodosUpdateArchivedPutByTodoId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Todo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodosUpdateDeadlinePutByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/deadline`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public date(value: string): TodosUpdateDeadlinePutByTodoId {
        this.addQueryParameter('date', value);
        return this;
    }

    public isSoft(value: boolean): TodosUpdateDeadlinePutByTodoId {
        this.addQueryParameter('isSoft', value);
        return this;
    }

    public save(data: any, next?: (value: Todo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodosUpdateDescriptionPutByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/description`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public value(value: string): TodosUpdateDescriptionPutByTodoId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Todo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodosUpdateProjectPutByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/projects`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public value(value: number): TodosUpdateProjectPutByTodoId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Todo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodosUpdateIsPrivatePutByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/isPrivate`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public value(value: boolean): TodosUpdateIsPrivatePutByTodoId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Todo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodosUpdateShowOnDisplayPutByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/showOnDisplay`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public value(value: boolean): TodosUpdateShowOnDisplayPutByTodoId {
        this.addQueryParameter('value', value);
        return this;
    }

    public save(data: any, next?: (value: Todo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodosStarGetByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/star`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public find(next?: (value: Todo[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Todo[] | any[]> {
        return super.executeClientGet();
    }
}

export class TodosPutByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/star`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public user_id(value: number): TodosPutByTodoId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public save(data: any, next?: (value: Todo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodosDeleteByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/star`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public user_id(value: number): TodosDeleteByTodoId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public delete(next?: (value: Todo) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class TodosCopyGetById extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/todos/${id}/copy`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public find(next?: (value: Todo[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Todo[] | any[]> {
        return super.executeClientGet();
    }
}

export class TodosReactionAddPutByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/reactions`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public reaction_type_id(value: number): TodosReactionAddPutByTodoId {
        this.addQueryParameter('reaction_type_id', value);
        return this;
    }

    public value(value: string): TodosReactionAddPutByTodoId {
        this.addQueryParameter('value', value);
        return this;
    }

    public user_id(value: number): TodosReactionAddPutByTodoId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public save(data: any, next?: (value: Todo) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class TodosReactionRemoveDeleteByTodoId extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/reactions`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public reaction_type_id(value: number): TodosReactionRemoveDeleteByTodoId {
        this.addQueryParameter('reaction_type_id', value);
        return this;
    }

    public value(value: string): TodosReactionRemoveDeleteByTodoId {
        this.addQueryParameter('value', value);
        return this;
    }

    public user_id(value: number): TodosReactionRemoveDeleteByTodoId {
        this.addQueryParameter('user_id', value);
        return this;
    }

    public delete(next?: (value: Todo) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class TodosDelete extends BaseApi<Todo> {

    public topic = 'Resources.Todos';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/todos/{id}`;
    }

    protected convertToResource(data: any): Todo {
        return new Todo(data);
    }

    public delete(next?: (value: Todo) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class TodosLogsGetByTodoId extends BaseApi<Log> {

    public topic = 'Resources.Logs';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(todoId: number) {
        super();
        this.uri = `/todos/${todoId}/logs`;
    }

    protected convertToResource(data: any): Log {
        return new Log(data);
    }

    public find(next?: (value: Log[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | Log[] | any[]> {
        return super.executeClientGet();
    }
}

class UserFields {

    public get(): UserFieldsGet {
        return new UserFieldsGet();
    }

    public getById(id: number): UserFieldsGetById {
        return new UserFieldsGetById(id);
    }

    public post(): UserFieldsPost {
        return new UserFieldsPost();
    }

    public putById(id: number): UserFieldsPutById {
        return new UserFieldsPutById(id);
    }

    public put(): UserFieldsPut {
        return new UserFieldsPut();
    }

    public patchById(id: number): UserFieldsPatchById {
        return new UserFieldsPatchById(id);
    }

    public patch(): UserFieldsPatch {
        return new UserFieldsPatch();
    }

    public deleteById(id: number): UserFieldsDeleteById {
        return new UserFieldsDeleteById(id);
    }

}

export class UserFieldsGet extends BaseApi<UserField> {

    public topic = 'Resources.UserFields';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/user_fields`;
    }

    protected convertToResource(data: any): UserField {
        return new UserField(data);
    }

    public where(name: string, value: any): UserFieldsGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): UserFieldsGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): UserFieldsGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): UserFieldsGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): UserFieldsGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): UserFieldsGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): UserFieldsGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): UserFieldsGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): UserFieldsGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): UserFieldsGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): UserFieldsGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): UserFieldsGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): UserFieldsGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): UserFieldsGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): UserFieldsGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): UserFieldsGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): UserFieldsGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: UserField[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | UserField[] | any[]> {
        return super.executeClientGet();
    }
}

export class UserFieldsGetById extends BaseApi<UserField> {

    public topic = 'Resources.UserFields';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/user_fields/${id}`;
    }

    protected convertToResource(data: any): UserField {
        return new UserField(data);
    }

    public include(name: string): UserFieldsGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: UserField[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | UserField[] | any[]> {
        return super.executeClientGet();
    }
}

export class UserFieldsPost extends BaseApi<UserField> {

    public topic = 'Resources.UserFields';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/user_fields`;
    }

    protected convertToResource(data: any): UserField {
        return new UserField(data);
    }

    public save(data: UserField, next?: (value: UserField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class UserFieldsPutById extends BaseApi<UserField> {

    public topic = 'Resources.UserFields';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/user_fields/${id}`;
    }

    protected convertToResource(data: any): UserField {
        return new UserField(data);
    }

    public save(data: UserField, next?: (value: UserField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class UserFieldsPut extends BaseApi<UserField> {

    public topic = 'Resources.UserFields';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/user_fields`;
    }

    protected convertToResource(data: any): UserField {
        return new UserField(data);
    }

    public save(data: UserField, next?: (value: UserField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class UserFieldsPatchById extends BaseApi<UserField> {

    public topic = 'Resources.UserFields';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/user_fields/${id}`;
    }

    protected convertToResource(data: any): UserField {
        return new UserField(data);
    }

    public save(data: UserField, next?: (value: UserField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class UserFieldsPatch extends BaseApi<UserField> {

    public topic = 'Resources.UserFields';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/user_fields`;
    }

    protected convertToResource(data: any): UserField {
        return new UserField(data);
    }

    public save(data: UserField, next?: (value: UserField) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class UserFieldsDeleteById extends BaseApi<UserField> {

    public topic = 'Resources.UserFields';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/user_fields/${id}`;
    }

    protected convertToResource(data: any): UserField {
        return new UserField(data);
    }

    public delete(next?: (value: UserField) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

class Users {

    public get(): UsersGet {
        return new UsersGet();
    }

    public getById(id: number): UsersGetById {
        return new UsersGetById(id);
    }

    public post(): UsersPost {
        return new UsersPost();
    }

    public putById(id: number): UsersPutById {
        return new UsersPutById(id);
    }

    public put(): UsersPut {
        return new UsersPut();
    }

    public patchById(id: number): UsersPatchById {
        return new UsersPatchById(id);
    }

    public patch(): UsersPatch {
        return new UsersPatch();
    }

    public deleteById(id: number): UsersDeleteById {
        return new UsersDeleteById(id);
    }

    public meGet(): UsersMeGet {
        return new UsersMeGet();
    }

    public projectsGetByUserIdByProjectId(userId: number, projectId: number): UsersProjectsGetByUserIdByProjectId {
        return new UsersProjectsGetByUserIdByProjectId(userId, projectId);
    }

    public tasksGetByUserIdByTaskId(userId: number, taskId: number): UsersTasksGetByUserIdByTaskId {
        return new UsersTasksGetByUserIdByTaskId(userId, taskId);
    }

}

export class UsersGet extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/users`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public where(name: string, value: any): UsersGet {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): UsersGet {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): UsersGet {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): UsersGet {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): UsersGet {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): UsersGet {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): UsersGet {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): UsersGet {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): UsersGet {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): UsersGet {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): UsersGet {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): UsersGet {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): UsersGet {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): UsersGet {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): UsersGet {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): UsersGet {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): UsersGet {
        this.offsetValue = value;
        return this;
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: User[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | User[] | any[]> {
        return super.executeClientGet();
    }
}

export class UsersGetById extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/users/${id}`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public include(name: string): UsersGetById {
        this.getInclude().include(name);
        return this;
    }

    public find(next?: (value: User[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | User[] | any[]> {
        return super.executeClientGet();
    }
}

export class UsersPost extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'post';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/users`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public save(data: User, next?: (value: User) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class UsersPutById extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/users/${id}`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public save(data: User, next?: (value: User) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class UsersPut extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'put';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/users`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public save(data: User, next?: (value: User) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class UsersPatchById extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/users/${id}`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public save(data: User, next?: (value: User) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class UsersPatch extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'patch';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/users`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public save(data: User, next?: (value: User) => void): RXJSSubscription {
        return super.executeSave(data, next);
    }
}

export class UsersDeleteById extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'delete';
    protected scope = '';
    protected summary = '';

    public constructor(id: number) {
        super();
        this.uri = `/users/${id}`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public delete(next?: (value: User) => void): RXJSSubscription {
        return super.executeDelete(next);
    }
}

export class UsersMeGet extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor() {
        super();
        this.uri = `/users/me`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public find(next?: (value: User[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | User[] | any[]> {
        return super.executeClientGet();
    }
}

export class UsersProjectsGetByUserIdByProjectId extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(userId: number, projectId: number) {
        super();
        this.uri = `/users/${userId}/projects/${projectId}`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: User[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | User[] | any[]> {
        return super.executeClientGet();
    }
}

export class UsersTasksGetByUserIdByTaskId extends BaseApi<User> {

    public topic = 'Resources.Users';
    protected method = 'get';
    protected scope = '';
    protected summary = '';

    public constructor(userId: number, taskId: number) {
        super();
        this.uri = `/users/${userId}/tasks/${taskId}`;
    }

    protected convertToResource(data: any): User {
        return new User(data);
    }

    public count(next?: (value: number) => void): RXJSSubscription {
        return this.executeCount(next);
    }

    public find(next?: (value: User[]) => void): RXJSSubscription {
        return super.executeFind(next);
    }

    public getClient(): Observable<any | User[] | any[]> {
        return super.executeClientGet();
    }
}
