import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import Globals from '@app/constants';
import {BaseFormControlComponent} from '@app/shared/_forms/base-form-control/base-form-control.component';
import {HasTypeProperties} from "@app/interfaces/HasTypeProperties";

// Angular animation:
// https://github.com/seeschweiler/angular-animation/blob/master/src/app/animate/animate.component.ts
// https://www.youtube.com/watch?v=sQsCbFdFCKY
// https://angular.io/guide/animations

@Component({
    selector: 'app-status-selector',
    templateUrl: './status-selector.component.html',
    styleUrls: ['./status-selector.component.scss'],
    animations: [
        trigger('changeState', [
            state('open', style({
                borderWidth: '0px',
                transform: 'scale(1)'
            })),
            state('closed', style({
                borderWidth: '2px',
                transform: 'scale(.8)'
            })),
            transition('open => closed', [
                animate('.2s ease-in')
            ]),
            transition('closed => open', [
                animate('0.1s ease-out')
            ])

        ])
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StatusSelectorComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => StatusSelectorComponent),
            multi: true,
        }]
})

// Custom form elements:
// https://medium.com/@tarik.nzl/angular-2-custom-form-control-with-validation-json-input-2b4cf9bc2d73

export class StatusSelectorComponent extends BaseFormControlComponent implements ControlValueAccessor, Validator, OnInit, OnChanges {

    // TO-DO: Refactor til multiple
    @Input() item: HasTypeProperties;

    @Input() status_id: number;
    @Input() readonly = false;
    @Input() mini = false;
    @Input() items?: {id: number}[];
    @Input() multiple:boolean = false;
    @Output() valueChanged = new EventEmitter<number>();

    statusTypes: { id: number; active: boolean; className: string }[] = [
        {
            id: Globals.StatusTypes.GREY,
            active: false,
            className: Globals.StatusTypeClasses[Globals.StatusTypes.GREY]
        },
        {
            id: Globals.StatusTypes.YELLOW,
            active: false,
            className: Globals.StatusTypeClasses[Globals.StatusTypes.YELLOW]
        },
        {
            id: Globals.StatusTypes.GREEN,
            active: false,
            className: Globals.StatusTypeClasses[Globals.StatusTypes.GREEN]
        },
        {
            id: Globals.StatusTypes.RED,
            active: false,
            className: Globals.StatusTypeClasses[Globals.StatusTypes.RED]
        }
    ];
    //protected baseValue:number = 0;

    constructor() {
        super();
        this.baseValue = this.multiple ? 0 : [];

    }

    ngOnInit(): void {
        if(this.status_id){
            this.internalValue = this.status_id;
            // console.log('status-selector : ', this.internalValue);
        }
        if(this.items) {
            let _statusTypes: any[] = [];
            this.items.forEach((i) => {
                var item = this.statusTypes.find((item) => {return item.id == i.id});
                _statusTypes.push(item)
            });
            this.statusTypes = _statusTypes;
        }
    }

    ngOnChanges(changes: any) {
        super.ngOnChanges(changes);
        if (changes['status_id']) {
            this.internalValue = this.status_id;
        }
    }

    setActive(id: number) {
        if(!this.readonly) {
            if (this.status_id) {
                this.status_id = id;
            }
            if (this.multiple) {
                const index = this.internalValue.indexOf(id);
                if (index !== -1) {
                    this.internalValue.splice(index, 1);
                } else {
                    this.internalValue.push(id);
                }
            } else {
                this.internalValue = id;
            }
            this.propagateChange(this.internalValue);
            this.valueChanged.emit(this.internalValue);
        }
    }

}
