<ul class="nav nav-pills nav-fill w-100">
    <li class="nav-item -px-2" *ngIf="showDeleteBtn">
        <button type="button" class="btn btn-sm btn-danger panel-button" (click)="onDeleteBtnClicked()"
                [ngbTooltip]="'_global_delete' | translate">
            <span class="fal fa-trash-alt"></span>
        </button>
    </li>

    <li class="nav-item -px-2">
        <button
                type="button"
                class="btn btn-sm btn-secondary  panel-button" (click)="onCloseBtnClicked()"
                [disabled]="isCloseBtnDisabled"
        >
            <span>{{'_ui_close' | translate}}</span>
        </button>
    </li>
</ul>
