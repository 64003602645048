import {NgModule} from '@angular/core';
import {
    WindowActionButtonsComponent
} from "@app/editor/base-editor-v3/components/window-action-buttons/window-action-buttons-component";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        ColorItemModule,
        NgbTooltip,
        TranslateModule

    ],
    declarations: [
        WindowActionButtonsComponent,
    ],
    exports: [
        WindowActionButtonsComponent,
    ],
    providers: [

    ]
})
export class BaseEditorV3Module {
}
