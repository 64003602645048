/**
 * Created by ModelParser
 * Date: 20-12-2018.
 * Time: 11:42.
 */
import {TaskTypesTaskUserTypeDefinition,} from './definitions/TaskTypesTaskUserTypeDefinition';

export class TaskTypesTaskUserType extends TaskTypesTaskUserTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
