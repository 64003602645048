import {NgModule} from "@angular/core";
import {CreateItemDropdownComponent} from "@app/shared/_ui/create-item-dropdown/create-item-dropdown.component";
import {NgbDropdownModule, NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {DropdownListItemModule} from "@app/shared/_ui/displays/dropdown-list-item/DropdownListItem.module";
import {CommonModule} from "@angular/common";
import {LoadingIndicatorModule} from '@app/shared/_ui/loading-indicator/LoadingIndicator.module';

@NgModule({
    imports: [
        CommonModule,
        NgbDropdownModule,
        NgbTooltipModule,
        TranslateModule,
        DropdownListItemModule,
        NgbPopoverModule,
        LoadingIndicatorModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CreateItemDropdownComponent,
    ],
    exports: [
        CreateItemDropdownComponent

    ],
    providers: [

    ],
})
export class CreateItemDropdownModule {}
