import {Injectable} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {includes} from 'lodash';
import enUS from '../../translations/en-US.json';
import daDK from '../../translations/da-DK.json';
import nb from '../../translations/nb.json';
import nn from '../../translations/nn.json';
import {BehaviorSubject, Observable} from "rxjs";
import {UsersService} from "@app/services/users.service";
import {LanguageValue, User} from "@app/core/models";
import {LanguageIds} from "@app/constants";
import {AppInjector} from '@app/services/app-injector.service';
import {DisplayService} from '@app/services/display.service';
import {CacheService} from '@app/services/cache.service';

const languageKey = 'Klartboard.System.Language';

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
export function extract(s: string) {
    return s;
}

@Injectable({
    providedIn: 'root'
})
export class I18nService {

    defaultLanguage: string;
    supportedLanguages: string[];

    private _languageLoaded: BehaviorSubject<boolean>;
    public languageLoaded$: Observable<boolean>;
    private displayUser: User;

    constructor(private translateService: TranslateService,
                private usersService: UsersService) {
        this._languageLoaded = <BehaviorSubject<boolean>>new BehaviorSubject(false);
        this.languageLoaded$ = this._languageLoaded.asObservable();

        // Embed languages to avoid extra HTTP requests
        translateService.setTranslation('da-DK', daDK);
        translateService.setTranslation('en-US', enUS);
        translateService.setTranslation('nb', nb);
        translateService.setTranslation('nn', nn);

        LanguageValue.GetAll((response) => {
            // Opdaterer eksisternde sprog-objekter
            response.forEach(value => {
                if (value.key) {
                    switch (value.languageId) {
                        case 1: // Dansk
                            daDK[value.key] = value.value;
                            break;
                        case 2: // Engelsk
                            enUS[value.key] = value.value;
                            break;
                        case 3: // Tysk
                            // de[value.key] = value.value;
                            break;
                        case 4: // Norsk, Bokmål
                            nb[value.key] = value.value;
                            break;
                        case 5: // Norsk, Nynorsk
                            nn[value.key] = value.value;
                            break;
                    }
                }
            });

            translateService.setTranslation('da-DK', daDK); // Overskriver
            translateService.setTranslation('en-US', enUS);
            translateService.setTranslation('nb', nb);      // Norsk Bokmål
            translateService.setTranslation('nn', nn);      // Nynorsk
            translateService.setDefaultLang('da-DK');

            if (response.length > 0) {
                this._languageLoaded.next(true);
            }
        });
        this.usersService.currentUser$.subscribe(value => {
            if (value) {
                this.displayUser = value;
            }
        })
    }


    /**
     * Initializes i18n for the application.
     * Loads language from local storage if present, or sets default language.
     * @param defaultLanguage The default language to use.
     * @param supportedLanguages The list of supported languages.
     */
    init(defaultLanguage: string, supportedLanguages: string[]) {
        this.defaultLanguage = defaultLanguage;
        this.supportedLanguages = supportedLanguages;
        this.language = '';

        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            // Set data with key+version
            try {
                localStorage.setItem(languageKey, event.lang);
            } catch (err) {
                if (CacheService.isQuotaExceededError(err)) {
                    // Handle the case where there wasn't enough space to store the item in localStorage.
                    console.warn('Not able to store data: ',err)
                } else {
                    // Handle the case where the localStorage API is not supported.
                }
            }
        });
    }

    /**
     * Sets the current language.
     * Note: The current language is saved to the local storage.
     * If no parameter is specified, the language is loaded from local storage (if present).
     * @param language The IETF language code to set.
     */
    set language(language: string) {
        language = language || localStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang();
        let isSupportedLanguage = includes(this.supportedLanguages, language);

        // If no exact match is found, search without the region
        if (language && !isSupportedLanguage) {
            language = language.split('-')[0];
            language = this.supportedLanguages.find(supportedLanguage => supportedLanguage.startsWith(language)) || '';
            isSupportedLanguage = Boolean(language);
        }

        // Fallback if language is not supported
        if (!isSupportedLanguage) {
            language = this.defaultLanguage;
        }

        this.translateService.use(language);

        if (this.displayUser) {
            const languageId = I18nService.LanguageCodeToLanguageId(language);
            if (this.displayUser.language_id != languageId) {
                this.displayUser.language_id = languageId;
                this.displayUser.patch(['language_id']);
            }
        }
    }


    public static languageIdToLanguageCode(id: any): string {
        return LanguageIds[id] || 'da-DK';
    }

    public static LanguageCodeToLanguageId(languageCode: string): any {
        return Object.keys(LanguageIds).find(key => LanguageIds[key] === languageCode);
    }

    /**
     * Gets the current language.
     * @return The current language code.
     */
    get language(): string {
        return this.translateService.currentLang;
    }

}
