<div
    class="d-flex align-items-center position-relative justify-content-start"
    [class.cursor-pointer]="showDatepicker"
    [class.focus]="active"

>
    <div class="wrap d-flex flex-row align-items-center {{alignClass}}  w-100">
        <i class="fal fa-calendar me-1" aria-hidden="true" *ngIf="showIcon"></i>
        <span class="me-1" *ngIf="!showDatepicker && date"
              [class.ellipsis]="ellipsis"
              ngbTooltip="{{label}} {{displayDate}}"
              container="body">{{displayDate}}</span>

        <div class="input-wrap d-flex align-items-center no-wrap" style="width: 65px;"
             *ngIf="showDatepicker"
             ngbTooltip="{{label}} {{date? (displayDate) : ('_ui_date_select' | translate | lowercase) + '...'}}"
             container="body"
        >
            <input name="dp"
                   [ngModel]="dateModel"
                   [placeholder]="('_ui_date_select' | translate) + '...'"
                   class="form-control-text"
                   ngbDatepicker
                   [weekdays]="true"
                   [showWeekNumbers]="true"
                   #d="ngbDatepicker"
                   [class.focus]="d.isOpen()"
                   autocomplete="false"
                   container="body"

                   [autofocusAfterInit]="autofocus"
                   (focus)="dpAutofocus(autofocus, d)"
                   [disabled]="!showDatepicker"
                   placement="top bottom right left"

                   (click)="toggleDatepicker(d); $event.stopImmediatePropagation()"
                   (dateSelect)="changeSelectedDate($event);"
                   (select)="changeSelectedDate($any($event));"/>


        </div>
        <i *ngIf="showClear && showDatepicker && dateModel"
           [ngbTooltip]="('_ui_reset' | translate)"
           class="text-danger fal fa-eraser ms-1" (click)="zeroValue()"></i>
    </div>
</div>
