<label>
    <span>{{ configuration.labelName | translate}}</span>
</label>
<app-department-picker
    class="list-group-item rounded"
    [_internalValue]="values"
    [showDepartmentGroups]="true"
    [showAll]="true"
    [filteredByDepartmentIds]="configuration.filter.departmentIds"
    (onItemAdded)="onItemAdded($event)"
    (onItemRemoved)="onItemRemoved($event)"
/>
