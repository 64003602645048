import {NgModule} from "@angular/core";
import {TasksComponent} from "@app/pages/lists/tasks/tasks.component";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {TaskListModule} from "@app/shared/_ui/lists/task-list/TaskList.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        TaskListModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TasksComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class TasksModule {}
