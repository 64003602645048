<div class="w-100 h-100 d-flex align-items-center justify-content-center" >
    <div class="login-box d-flex align-items-center flex-column bg-light rounded p-2" style="width: 145px;">
        <h4 class="card-title text-center">
            <i class="fal fa-3x fa-cloud-exclamation text-black-50"></i>
        </h4>
        <p (click)="navigate()" class="cursor-pointer ">
            <span class="fal fa-refresh text-danger align-middle me-1"></span>
            <span>{{'_ui_error_occured' | translate}}</span>
        </p>
    </div>
</div>
