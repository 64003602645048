export class FilterGlobalVisibility {

    public showCategories = false;
    public showReactionTypes = false;
    public showSearch = false;
    public showSorting = false;
    public showWeekPicker = false;
    public showDatePicker = false;
    public showUsers = false;

}
