import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {TableColumns} from "@app/pages/displays/display-projects/TableColumns";
import {FollowingMilestoneListColumn} from "@app/pages/displays/display-projects/Columns/FollowingMilestoneListColumn";

export class FollowingMilestoneTableColumn extends GenericTableColumn {

    public identifier = TableColumns.FollowingMilestoneList;

    public column: FollowingMilestoneListColumn;

}
