/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ProjectsUserDefinition} from './definitions/ProjectsUserDefinition';
import {AppInjector} from '@app/services/app-injector.service';
import {ProjectsService} from '@app/services/projects.service';
import {UsersService} from '@app/services/users.service';
import {ProjectUserTypes} from '@app/core/models/Project';
import {ProjectUserType} from '@app/core/models/ProjectUserType';
import {HasTypeId} from "@app/interfaces/HasTypeId";
import {HasUser} from "@app/interfaces/HasUser";
import {User} from "@app/core/models/User";

export class ProjectsUser extends ProjectsUserDefinition implements
    HasTypeId,
    HasUser {

    constructor(json?: any) {
        super(json);
    }

    public static Create(typeId: number, userId: number, user?: User): ProjectsUser {
        const item = new ProjectsUser();
        item.project_user_type_id = typeId;
        item.user_id = userId;
        item.user = user;
        return item;
    }

    public static createDefault(projectUserTypeId?: number) {
        let item = new ProjectsUser();
        item.id = 0;
        if (projectUserTypeId) {
            item.project_user_type_id = projectUserTypeId;
            switch (projectUserTypeId) {
                case ProjectUserTypes.Responsible:
                    item.user = AppInjector.getInjector().get(UsersService).user;
                    break;
                case ProjectUserTypes.Participants:
                    break;
            }
        }
        return item;
    }

    get projectUserType(): ProjectUserType {
        if (!this.project_user_type && this.project_user_type_id) {
            AppInjector.getInjector().get(ProjectsService).getProjectUserType(this.project_user_type_id, item => {
                this.project_user_type = item;
            })
        }
        if (this.project_user_type) {
            return this.project_user_type;
        }

        return null;
    }

    public get typeId(): number {
        return this.project_user_type_id;
    }

}
