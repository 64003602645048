/**
 * Created by ModelParser
 */
import {TasksDeadline} from '../TasksDeadline';
import {ProjectsDeadline} from '../ProjectsDeadline';
import {TasksUser} from '../TasksUser';
import {Todo} from '../Todo';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class DeadlineDefinition extends BaseModel {
    date?: string;
    is_soft?: boolean;
    tasks_deadlines?: TasksDeadline[];
    projects_deadlines?: ProjectsDeadline[];
    tasks_users?: TasksUser[];
    todos?: Todo[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.date;
            delete this.is_soft;
            delete this.tasks_deadlines;
            delete this.projects_deadlines;
            delete this.tasks_users;
            delete this.todos;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.date != null) {
            this.date = data.date;
        }
        if (data.is_soft != null) {
            this.is_soft = data.is_soft;
        }
        if (data.tasks_deadlines != null) {
            this.tasks_deadlines = data.tasks_deadlines.map((i: any) => new TasksDeadline(i));
        }
        if (data.projects_deadlines != null) {
            this.projects_deadlines = data.projects_deadlines.map((i: any) => new ProjectsDeadline(i));
        }
        if (data.tasks_users != null) {
            this.tasks_users = data.tasks_users.map((i: any) => new TasksUser(i));
        }
        if (data.todos != null) {
            this.todos = data.todos.map((i: any) => new Todo(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
