import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RestoreComponent} from "@app/pages/lists/restore/restore.component";

const routes: Routes = [
    {
        path: 'restore/list',
        component: RestoreComponent
    }
];

@NgModule({
  imports: [
      RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class RestoreRoutingModule { }
