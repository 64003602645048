<div class="d-flex flex-row justify-content-between align-content-center mb-2 w-100"
     [class.bg-dark]="isMobileVersion"
     [class.text-light]="isMobileVersion"
     style="min-height: 48px;">
    <h6 class="align-self-center m-0 ps-2 col-2">
        {{filterGlobalService.getActiveSettings().period.start | localizedDate: 'microDate'}}
        - {{filterGlobalService.getActiveSettings().period.end | localizedDate: 'microDate'}}
        , {{'_ui_week' | translate}} {{filterGlobalService.getActiveSettings().period.start | week}}
    </h6>

    <div class="d-flex flex-row justify-content-end align-items-center flex-fill ms-auto" style="width: 50%;">
        <div class="d-flex flex-row justify-content-end align-items-center" *ngIf="ratingTableColumn">
            <app-time-calculator
                *ngIf="isFieldVisible(Fields.Load) || isFieldVisible(Fields.LoadWorkHours) || isFieldVisible(Fields.LoadFreeHours)"
                [showLoad]="isFieldVisible(Fields.Load)"
                [showLoadWorkHours]="isFieldVisible(Fields.LoadWorkHours)"
                [showLoadFreeHours]="isFieldVisible(Fields.LoadFreeHours)"
                [displayColumn]="false"
                [loadCalculateResponse]="loadCalculation"
                [periodRating]="periodRating" class="me-1 position-relative"></app-time-calculator>
            <app-color-label
                [emoji]="true"
                [ngbTooltip]="(ratingTableColumn.name | translate)"
                placement="right"
                [status]="periodRating.status_id"
                (onStatusChange)="toggleStatus($event)"
            >
            </app-color-label>
        </div>

        <div *ngIf="appointmentsTableColumn && appointment" class="align-self-center p-1 -flex-grow-1 p-2 dashboard-header"
             style="width: calc(100% - 75px); ">
            <app-card-appointment style="resize: none; height: 32px; max-width: initial;" class="d-block text-small "
                                  [multiline]="false"
                                  [ngbTooltip]="'_display_user_overview_note_description' | translate"
                                  [label]="'_display_user_overview_note_description' | translate"
                                  container="body"
                                  [item]="appointment" [interactive]="true"></app-card-appointment>
        </div>
    </div>
</div>
