<ng-container *ngIf="rows">
    <div class="scroll-y item-wrapper">

        <ul class="list-unstyled p-0 menu-list">
            <ng-container *ngFor="let row of rows">

                <ng-container
                    *ngIf="!row.isGroup"
                >
                    <ng-container *ngFor="let tableColumn of row.columnGroup.columns">
                        <li class="list-item d-flex align-items-center">
                            <app-toggle-item
                                class="dropdown-item w-100"
                                [disabled]="tableColumn.column.columnType.isAlwaysVisible"
                                [interactive]="!tableColumn.column.columnType.isAlwaysVisible"
                                [active]="tableColumn.isVisible"
                                [icon]="tableColumn.column.columnType.isAlwaysVisible ? 'fa-lock' : 'fa-check-circle'"
                                [iconInactive]="'fa-ban'"
                                [iconWeightActive]="'fal'"
                                [iconWeightInactive]="'fal'"
                                [iconColorActive]="tableColumn.column.columnType.isAlwaysVisible ? 'text-black-50' : 'text-success'"
                                [iconColorInactive]="'text-danger'"
                                [color]="tableColumn.color"
                                [label]="(tableColumn.name | translate)"
                                (onToggle)="toggleColumn(tableColumn)"></app-toggle-item>
                        </li>
                    </ng-container>
                </ng-container>

                <li
                    *ngIf="row.isGroup"
                    class=" list-group d-flex flex-column"
                    [class.open]="row.columnGroup.open"
                >
                    <div class="d-flex align-items-center justify-content-between w-100 dropdown-item list-item"
                    >
                        <app-toggle-item
                            class="flex-grow-1"
                            [interactive]="true"
                            [active]="row.columnGroup.active"
                            [icon]="'fa-check-circle'"
                            [iconInactive]="'fa-ban'"
                            [iconWeightActive]="'fal'"
                            [iconWeightInactive]="'fal'"
                            [iconColorActive]="'text-success'"
                            [iconColorInactive]="'text-danger'"
                            [label]="(row.columnGroup.name | translate)"
                            [underline]="true"
                            (onToggle)="toggleGroup(row.columnGroup)"></app-toggle-item>

                        <i class="fal "
                           [class.fa-circle-chevron-down]="!row.columnGroup.open"
                           [class.fa-circle-chevron-up]="row.columnGroup.open"
                           (click)="row.columnGroup.toggleOpen()"></i>
                    </div>


                    <ul class="list-unstyled p-0 d-flex align-items-end justify-content-end flex-wrap flex-column"
                        *ngIf="row.columnGroup.open">
                        <ng-container *ngFor="let tableColumn of row.columnGroup.columns">
                            <li class="list-item w-100">
                                <app-toggle-item
                                    class="w-100 dropdown-item flex-grow-1"
                                    [disabled]="tableColumn.column.columnType.isAlwaysVisible"
                                    [interactive]="!tableColumn.column.columnType.isAlwaysVisible"
                                    [active]="tableColumn.isVisible"
                                    [icon]="tableColumn.column.columnType.isAlwaysVisible ? 'fa-lock' : 'fa-check-circle'"
                                    [iconInactive]="'fa-ban'"
                                    [iconWeightActive]="'fal'"
                                    [iconWeightInactive]="'fal'"
                                    [iconColorActive]="tableColumn.column.columnType.isAlwaysVisible ? 'text-black-50' : 'text-success'"
                                    [iconColorInactive]="'text-danger'"
                                    [color]="tableColumn.color"
                                    [label]="(tableColumn.name | translate)"
                                    (onToggle)="toggleColumn(tableColumn)"></app-toggle-item>
                            </li>
                        </ng-container>

                    </ul>
                </li>
            </ng-container>
        </ul>
    </div>
</ng-container>

<app-loading-indicator [showLogo]="false" *ngIf="!rows"
                       class="align-self-center justify-self-center"></app-loading-indicator>
