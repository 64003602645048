import {BaseComponent} from "@app/shared/_system/base/base.component";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {WhoWhenEditItem} from "@app/shared/_elements/who-when-edit/WhoWhenEditItem";
import {Deadline, User} from "@app/core/models";

@Component({
    selector: 'app-who-when-edit-item',
    templateUrl: './who-when-edit-item.component.html',
    styleUrls: ['./who-when-edit-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhoWhenEditItemComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() item: WhoWhenEditItem;
    @Input() selectedUsers: User[];
    @Output() onSetDeadlineEvent = new EventEmitter<Deadline>();
    @Output() onSetArchived = new EventEmitter<boolean>();
    @Output() onSetUserEvent = new EventEmitter<User>();
    @Output() onRemoveEvent = new EventEmitter();

    // Bindings to view

    // Data

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.render();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.render();
    }

    private render() {
    }

    public removeItem() {
        this.onRemoveEvent.emit();
    }

    public setArchived(value: boolean) {
        this.onSetArchived.emit(value);
        this.render();
    }

    public setDeadline(value: Deadline) {
        this.onSetDeadlineEvent.emit(value.date ? value : null);
    }

    setUser(value: User) {
        if (value) {
            this.onSetUserEvent.emit(value);
        } else {
            this.removeItem();
        }
    }

}
