/**
 * Created by ModelParser
 * Date: 12-11-2020.
 * Time: 09:13.
 */
import {DynamicMenuItemsRoleDefinition} from './definitions/DynamicMenuItemsRoleDefinition';

export class DynamicMenuItemsRole extends DynamicMenuItemsRoleDefinition {

    constructor(json?: any) {
        super(json);
    }

}
