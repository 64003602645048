/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {DepartmentDefinition} from './definitions/DepartmentDefinition';
import {Api} from "@app/core/http/Api/Api";
import {ApiDataCache} from '@app/core/DataCache/ApiDataCache';
import {System} from '@app/constants';
import {LanguageValueInterface} from '@app/interfaces/LanguageValueInterface';

export class Department extends DepartmentDefinition {

    constructor(json?: any) {
        super(json);
    }


    /* DB Cache */
    private static DataCache?: ApiDataCache<Department>;

    private static GetDataCache(): ApiDataCache<Department> {
        if (!this.DataCache) {
            this.DataCache = new ApiDataCache<Department>(
                System.Departments,
                24 * 60 * 60 * 1, // 1 days
                Api.departments().get()
                    .include('department_group')
                    .orderAsc('name')

            );
        }
        return this.DataCache;
    }

    // Entry point
    public static GetAll(callback: (items: Department[]) => void) {
        Department.GetDataCache().get(callback);
    }

    public static GetById(id:number | string, callback: ((department: Department) => void)) {
        this.GetAll((items)=>{
            callback(items.find(d => d.id == id));
        })
    }

}
