import {NgModule} from "@angular/core";
import {DynamicMenuItemsComponent} from "@app/shared/_ui/dynamic-menu-items/dynamic-menu-items.component";
import {AppointmentListModule} from "@app/shared/_ui/lists/appointment-list/AppointmentList.module";
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        AppointmentListModule,
        TranslateModule,
        NgbTooltipModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DynamicMenuItemsComponent,
    ],
    exports: [
        DynamicMenuItemsComponent

    ],
    providers: [

    ],
})
export class DynamicMenuItemsModule {}
