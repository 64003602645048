/**
 * Created by ModelParser
 * Date: 23-06-2021.
 * Time: 14:43.
 */
import {PhaseProgressTypesPhaseDefinition} from './definitions/PhaseProgressTypesPhaseDefinition';

export class PhaseProgressTypesPhase extends PhaseProgressTypesPhaseDefinition {

    constructor(json?: any) {
        super(json);
    }

}
