import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {ProjectResponsibleCell} from "@app/pages/displays/display-projects/Cells/ProjectResponsibleCell";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";

export class ProjectResponsibleColumn extends GenericColumn<ProjectResponsibleCell> {

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new ProjectResponsibleCell(row, this);
    }

    public implementsCSVExport = true;

}
