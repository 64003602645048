<!-- Category Types -->
<!-- ligesom filter-column.component -->
<ng-container>
    <div class="d-flex flex-row">
        <ul class="list-unstyled d-flex align-items-center flex-wrap  m-0">
            <li class="me-1"
                [ngbTooltip]="popover && popover.isOpen() ? null : tooltip"
                container="body"
                placement="bottom"
            >
            <span class="fal fa-layer-group cursor-pointer position-relative"
                  [ngbPopover]="popoverContent"
                  [autoClose]="'outside'"
                  triggers="manual"
                  [placement]="['bottom', 'auto']"
                  container="body"
                  (click)="loadCategories(); popover.open()"
                  #popover="ngbPopover"
            >
                <span class="badge rounded-pill badge-notification badge-danger badge-counter"
                      *ngIf="categories?.length > 0">{{categories?.length}}</span>
            </span>
            </li>
        </ul>
    </div>

    <ng-template #popoverContent>
        <div class="items-container flex-column d-flex">

            <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>

            <div class="d-flex flex-row align-items-center w-100 pb-1 " *ngIf="showFooter" >
                <h4 class="p-0 m-0 popover-title flex-grow-1">
                    {{('_ui_display_reaction_filter_category' | translate)}}
                </h4>

                <div class="d-flex ms-auto reset-panel-buttons" >

                    <span (click)="popover.close();removeAllItems()"
                          [class.disabled]="!(categories.length > 0 && showReset)"
                          class="cursor-pointer btn btn-sm btn-danger">
                        <i class="fal fa-eraser" aria-hidden="true"></i>
                        {{'_ui_emoji_clear' | translate}}</span>

                    <span (click)="popover.close();activateFilters()"
                          [class.disabled]="!filtersAltered"
                          class="cursor-pointer btn btn-sm btn-success">
                        <i class="fa-duotone fa-filters"></i>
                        {{'_ui_filter' | translate}}</span>
                </div>
            </div>

            <app-loading-indicator *ngIf="!categoryTypes || categoryTypes.length == 0" [showLogo]="false"
                                   class="ms-2"></app-loading-indicator>

            <ng-container *ngIf="categoryTypes && categoryTypes.length > 0">
                <div class="tabs-container" [attr.data-items]="categoryTypes.length">

                    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs categories tabs-editor p-0 m-0">
                        <ng-container *ngFor="let categoryType of categoryTypes; let i = index;">
                            <li [ngbNavItem]="i"
                                [class.active]="i == activeTab"

                                *ngIf="categoryType.categories && categoryType.categories.length > 0"
                                class=" -flex-grow-1">
                                <a ngbNavLink>
                                    <span class="group-name ellipsis" style="max-width: 120px;">
                                    {{categoryType.name}}
                                    </span>
                                </a>
                                <ng-template ngbNavContent>
                                    <ul class="list-unstyled m-0 p-0 dropdown-content scroll-y item-wrapper menu-list"
                                        [class.d-flex]="categoryType.show_color && !categoryType.show_name"
                                        [class.flex-wrap]="categoryType.show_color && !categoryType.show_name"
                                    >
                                        <li *ngFor="let category of categoryType.categories">
                                            <app-toggle-item
                                                class="dropdown-item"
                                                [class.w-100]="!(categoryType.show_color && !categoryType.show_name)"
                                                [interactive]="true"
                                                [active]="categoryMap.has(category.id)"
                                                [icon]="'fa-check-circle'"
                                                [iconInactive]="'fa-ban'"
                                                [iconWeightActive]="'fal'"
                                                [iconWeightInactive]="'fal'"
                                                [iconColorActive]="'text-success'"
                                                [iconColorInactive]="'text-danger'"
                                                [colorClassName]="'larges'"
                                                [color]="categoryType.show_color ? category.color : null"
                                                [label]="categoryType.show_name ? (category.name | translate) : null"
                                                (onToggle)="toggleCategory(category)"></app-toggle-item>

                                        </li>
                                        <li *ngIf="!categoryType.categories || categoryType.categories.length == 0">
                                            <app-loading-indicator [showLogo]="false" class="ms-2"></app-loading-indicator>
                                        </li>
                                    </ul>
                                </ng-template>
                            </li>
                        </ng-container>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="categories"></div>
                </div>
            </ng-container>
        </div>
    </ng-template>

</ng-container>
