import {EventEmitter, Injectable, OnDestroy, OnInit} from '@angular/core';
import {AppInjector} from '@app/services/app-injector.service';
import {ShellService} from '@app/services/ShellService/shell.service';
import {UsersService} from '@app/services/users.service';
import {User} from '@app/core/models/User';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {TranslateService} from '@ngx-translate/core';
import {System} from '@app/constants';
import {LocalStorageService} from '@app/services/local-storage.service';

@Injectable()
export class BaseDisplayComponent extends BaseComponent implements OnInit, OnDestroy {

    set isLoading(value: boolean) {
        this._isLoading = value;
        this.isLoadingEventEmitter.emit(value);
    }
    get isLoading(): boolean {
        return this._isLoading;
    }
    public initialized = true;
    public _isLoading = false;
    public isMobile = false;
    public isLoadingEventEmitter = new EventEmitter<boolean>();

    public user: User;

    protected shellService: ShellService;
    protected usersService: UsersService;
    public translateService: TranslateService;

    constructor() {
        super();
        const injector = AppInjector.getInjector();
        this.shellService = injector.get(ShellService);
        this.usersService = injector.get(UsersService);
        this.translateService = injector.get(TranslateService);
    }

    ngOnInit() {
        // this.isMobile = window.matchMedia("(max-width : 480px)")?.matches;
        this.isMobile = AppInjector.getInjector().get(LocalStorageService).get(System.CookieMobile);
        this.subscribe(this.usersService.currentUser$.subscribe(user => {
            this.user = user;
            if (this.cdr) {
                this.detectChanges();
            }
        }));
        if (this.usersService.user) {
            this.user = this.usersService.user;
        }

        super.ngOnInit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    // Show browser debugger
    public debugger(){
        debugger
    }

}
