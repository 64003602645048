import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {Department} from '@app/core/models/Department';
import {DynamicMenuItem} from '@app/core/models/DynamicMenuItem';
import {Api} from '@app/core/http/Api/Api';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {DynamicMenuItemTypes, ListTypes} from '@app/constants';
import {Appointment} from '@app/core/models/Appointment';
import {AppointmentDialogComponent} from '@app/shared/_modals/appointment-dialog/appointment-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EventService} from '@app/services/event.service';
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {AppointmentListConfiguration} from '@app/shared/_ui/lists/appointment-list/AppointmentListConfiguration';
import {OrderDirection} from '@app/http/APIFilter';

@Component({
    selector: 'app-dynamic-menu-items',
    templateUrl: './dynamic-menu-items.component.html',
    styleUrls: ['./dynamic-menu-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicMenuItemsComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @Input() public className: string = '';
    @Input() public department: Department;
    @Input() public menuItems: DynamicMenuItem[];
    @Input() public editable = true;
    public activeMenuItem: DynamicMenuItem;
    public appointmentListConfiguration: AppointmentListConfiguration;

    // Map til at finde ud af om jeg har skriverettigheder på et menupunkt
    public menuItemWriteable: Map<number, boolean>;
    public menuItemClass: Map<number, string>;

    constructor(private modalService: NgbModal,
                private cd: ChangeDetectorRef,
                private dialogService: BaseDialogService,
                private eventService: EventService) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.subscribeToAppointments();
        this.generateSettingMaps();
    }

    subscribeToAppointments() {
        this.subscribe(this.eventService.subscribeToAppointment(0, event => {
            if (event.item.dynamic_menu_item_id) {
                const menuItem = this.menuItems.find(mi => mi.id == event.item.dynamic_menu_item_id);
                switch (event.action) {
                    case EventService.Created:
                        if (!menuItem.appointments) {
                            menuItem.appointments = [];
                        }
                        if(event.item.dynamic_menu_item_id && !event.item.dynamic_menu_item && this.menuItems){
                            const mi = this.menuItems.find(mi => mi.id == event.item.dynamic_menu_item_id);
                            event.item.dynamic_menu_item = new DynamicMenuItem({name: mi.name, id: mi.id});
                        }
                        menuItem.appointments.push(event.item);
                        break;
                    case EventService.Updated:
                        const app = menuItem?.appointments?.find(a => a.id == event.item.id);
                        if (app) {
                            event.fields.forEach(k => app[k] = event.item[k]);
                            app.description = event.item.description;
                        }
                        break;
                    case EventService.Deleted:
                        if (menuItem && menuItem.appointments) {
                            const index = menuItem.appointments.findIndex(a => a.id == event.item.id);
                            menuItem.appointments.splice(index, 1);
                        }
                        break;
                }
                this.generateSettingMaps();
                this.detectChanges();
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.menuItems && changes.menuItems.currentValue !== changes.menuItems.previousValue) {
            this.generateSettingMaps();
            this.detectChanges();
        }
        if (changes.department && changes.department.currentValue !== changes.department.previousValue) {
            this.loadMenuItems();
        }
    }

    loadMenuItems() {
        if (!this.department) {
            return;
        }

        Api.dynamicMenuItems()
            .get()
            .whereInArray('department_id', [this.department.id, 0])
            .where('parent_id', 0)
            .include('role')
            .orderBy('index_', OrderDirection.Ascending)
            .find((items: DynamicMenuItem[]) => {
                this.menuItems = [...items];
                this.generateSettingMaps();
                this.detectChanges();
            });
    }

    generateSettingMaps() {
        if (!this.menuItems) {
            return;
        }

        if (this.user) {
            const userRoleIds = this.user?.roles?.map(r => r.id) ?? [];
            this.menuItemWriteable = new Map<number, boolean>();
            this.menuItems.forEach((mi) => {
                const access = mi.roles?.filter(r => userRoleIds.indexOf(r.id) !== -1).length > 0;
                this.menuItemWriteable.set(mi.id, this.user?.administrator || access); // Admin skal altid kunne redigere
            });
        }

        this.menuItemClass = new Map<number, string>();
        this.menuItems.forEach((mi) => {
            switch (mi.type) {
                case DynamicMenuItemTypes.Link:
                    this.menuItemClass.set(mi.id, 'fa-sticky-note');
                    break;
                case DynamicMenuItemTypes.Appointment:
                    this.menuItemClass.set(mi.id, 'fa-sticky-note');
                    break;
                case DynamicMenuItemTypes.AppointmentList:
                case DynamicMenuItemTypes.Category:
                    this.menuItemClass.set(mi.id, (this.activeMenuItem && mi.id == this.activeMenuItem.id) ? 'fa-angle-down' : 'fa-angle-right');
                    break;
            }
        });
    }


    setupAppointmentConfiguration(item: DynamicMenuItem) {
        this.appointmentListConfiguration = new AppointmentListConfiguration();
        this.appointmentListConfiguration
            .setDynamicMenuItem(item)
            .setListType(ListTypes.SimpleList)
            .setShowCreateNew(false)
            .setLimit(5);
    }

    clickMenuItem($event: MouseEvent, item: DynamicMenuItem) {

        switch (item.type) {
            case DynamicMenuItemTypes.Appointment:
                this.activeMenuItem = item;
                this.generateSettingMaps();
                console.log('clickMenuItem : ', item);

                Api.appointments()
                    .get()
                    .where('dynamic_menu_item_id', item.id)
                    .find(a => this.editAppointment($event, item, a[0]));
                break;

            case DynamicMenuItemTypes.Link:
                if (item.value) {
                    window.open(item.value, '_blank');
                }
                break;

            case DynamicMenuItemTypes.AppointmentList:
                $event.stopImmediatePropagation();
                $event.preventDefault();
                this.activeMenuItem = this.activeMenuItem == item ? null : item;
                this.generateSettingMaps();
                this.setupAppointmentConfiguration(this.activeMenuItem);
                if (!item.appointments) {
                    Api.appointments()
                        .get()
                        .where('dynamic_menu_item_id', item.id)
                        .find(a => {
                            item.appointments = a ? a : [];
                            this.detectChanges();
                        });
                }
                this.detectChanges();
                break;

            case DynamicMenuItemTypes.Category:
                $event.stopImmediatePropagation();
                $event.preventDefault();
                this.activeMenuItem = this.activeMenuItem == item ? null : item;
                this.generateSettingMaps();
                if (!item.childrens) {
                    Api.dynamicMenuItems()
                        .get()
                        .where('parent_id', this.activeMenuItem.id)
                        .whereInArray('department_id', [this.department.id, 0])
                        .include('role')
                        .orderBy('index_', OrderDirection.Ascending)
                        .find((items: DynamicMenuItem[]) => {
                            item.childrens = items;
                            this.detectChanges();
                        });
                }
                break;

            default:
                $event.stopImmediatePropagation();
                $event.preventDefault();
                break;
        }
    }

    appointmentDialog(model: Appointment, editable = false) {
        if (!model) {
            return;
        }

        const modalRef = this.modalService.open(
            AppointmentDialogComponent,
            {
                size: 'lg',
                windowClass: 'modal-holder',
                centered: true,
                backdrop: false
            });

        const componentRef = (modalRef.componentInstance as AppointmentDialogComponent);
        componentRef.title = this.activeMenuItem.name;
        componentRef.model = model;
        componentRef.userEditable = editable;
    }

    deleteAppointment($event: MouseEvent, appointment: Appointment) {
        $event.stopImmediatePropagation();
        $event.preventDefault();

        this.dialogService.confirm(
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_ui_delete_item', {name: ''}), // this.translateService.instant('_dynamic_menu_item_type_appointment')
            this.translateService.instant('_global_delete')
        )
            .then((confirmed) => {
                if (confirmed) {
                    appointment.delete(() => {

                    });
                }
            });
    }

    editAppointment($event: MouseEvent, menuItem: DynamicMenuItem, appointment: Appointment) {
        $event.stopImmediatePropagation();
        $event.preventDefault();
        this.appointmentDialog(appointment, this.menuItemWriteable.get(menuItem.id));
    }

    createAppointment($event: MouseEvent, menuItem: DynamicMenuItem) {
        console.log('createAppointment() : ', menuItem);
        $event.stopImmediatePropagation();
        $event.preventDefault();
        this.activeMenuItem = menuItem;
        const appointment = new Appointment();
        appointment.id = 0;
        appointment.dynamic_menu_item_id = menuItem.id;

        this.appointmentDialog(appointment, true);
    }
}
