import {NgModule} from '@angular/core';
import {ErrorComponent} from "@app/pages/error/error.component";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ErrorComponent,
    ],
    exports: [

    ],
    providers: [

    ]
})
export class ErrorModule {
}
