import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";

export abstract class BaseRow {

    private cells: BaseCell[] = [];
    private cellMap = new Map<BaseColumn, BaseCell>();

    public addCell(cell: BaseCell) {
        this.cells.push(cell);
        this.cellMap.set(cell.column, cell);
    }

    public getCell<T extends BaseCell>(column: BaseColumn): T | undefined {
        return this.cellMap.get(column) as T;
    }

}
