import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {TranslateModule} from "@ngx-translate/core";
import {
    ProjectStatusListCellComponent
} from "@app/pages/displays/display-projects/CellComponents/project-status-list-cell/project-status-list-cell.component";
import {ProjectStatusesModule} from "@app/shared/_ui/columns/project-statuses/ProjectStatuses.module";

@NgModule({
    imports: [
        CommonModule,
        UserWithIconModule,
        TranslateModule,
        ProjectStatusesModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectStatusListCellComponent,
    ],
    exports: [
        ProjectStatusListCellComponent

    ],
    providers: [

    ],
})
export class ProjectStatusListCellModule {}
