import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {Todo} from '@app/core/models/Todo';
import {BaseDisplayComponent} from '../../../_system/base-display/base-display.component';
import {OldApi} from '@app/http/Api';
import {Subscription} from 'rxjs';
import {TodoListConfiguration} from '@app/shared/_ui/lists/todo-list/TodoListConfiguration';
import {EventService} from '@app/services/event.service';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import Helpers from '@app/core/helpers';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {CSVListBinding} from '@app/export/csv/CSVListBinding';
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {TodoFetcher} from "@app/shared/_ui/lists/todo-list/TodoFetcher";

@Component({
    selector: 'app-todo-list',
    templateUrl: './todo-list.component.html',
    styleUrls: ['./todo-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoListComponent extends BaseDisplayComponent implements OnInit, OnChanges, OnDestroy {

    @Input() listClass: string = 'grid-3';
    @Input() configuration: TodoListConfiguration;
    @Input() minimizeEvent: EventEmitter<any>;
    @Input() loadAllEvent: EventEmitter<any>;
    @Input() reloadEvent: EventEmitter<any>;
    @Input() scrollContainer: HTMLElement;
    @Input() csvListBinding: CSVListBinding;

    @Output() dataSetChanged = new EventEmitter<CardItem<Todo>[]>();

    // Data
    public items: CardItem<Todo>[];
    private itemsMap: Map<number, CardItem<Todo>>;
    public offset = 0;
    public itemCount = 0;
    private loadAllEventSubscription?: Subscription;

    constructor(private eventService: EventService,
                private editorPanelService: EditorPanelService,
                private cd: ChangeDetectorRef,
                private filterGlobalService: FilterGlobalService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        if (!this.configuration) {
            this.configuration = new TodoListConfiguration();
        }

        if (this.minimizeEvent) {
            this.minimizeEvent.subscribe(() => {
                if (this.items) {
                    this.items.splice(this.configuration.getLimit());
                }
                this.itemsMap.clear();
                this.items?.forEach(item => this.itemsMap.set(item.item.id, item));
                this.offset = 0;
                this.items = [...this.items ?? []];
                this.detectChanges();
            });
        }

        if (this.reloadEvent) {
            this.reloadEvent.subscribe(() => {
                if (this.configuration.hasSmartLimit()) {
                    return;
                }
                this.loadData();
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['loadAllEvent']) {
            if (this.loadAllEvent) {
                this.loadAllEventSubscription?.unsubscribe();
                this.loadAllEventSubscription = this.loadAllEvent.subscribe(() => {
                    this.loadAll();
                });
            }
        }
        if (changes['configuration'] != null) {
            const onAddItemsListener = (items: CardItem<Todo>[]) => {
                // Reset
                this.offset = 0;
                this.itemCount = 0;
                this.itemsMap = new Map();

                // Add items
                this.items = items;
                items.forEach(item => this.itemsMap.set(item.item.id, item));
                this.configuration.setLastItemCount(items.length);

                this.isLoading = false;
                this.detectChanges();
                this.dataSetChanged.emit(this.items);
            };
            const onAddItemListener = (item: CardItem<Todo>) => {
                if (!this.itemsMap.has(item.item.id)) { // Add of not found
                    this.items.push(item);
                    this.itemCount++;
                } else { // Replace existing card
                    this.items[this.items.indexOf(this.itemsMap.get(item.item.id))] = item;
                }
                this.itemsMap.set(item.item.id, item);
                this.configuration.setLastItemCount(this.items.length);
                this.detectChanges();
                this.dataSetChanged.emit(this.items);
            };
            const onRemoveItemListener = (item: CardItem<Todo>) => {
                this.removeItem(item.item);
                this.detectChanges();
            };
            const onCountListener = (count: number) => {
                this.itemCount = count;
                this.detectChanges();
            };
            this.configuration.addDataListeners(onAddItemsListener, onAddItemListener, onRemoveItemListener, onCountListener);

            this.configuration.pushUnsubscribe();
            this.configuration.pushSubscribe();

            if (this.configuration.getDataSource()) {
                // Do nothing. Everything is handled by TodoListConfiguration
            } else if (this.configuration.hasSmartLimit()) { // Wait for SmartLimit
                this.subscribe(this.configuration.onLimitSetEvent.subscribe((limit: number) => this.loadData()));
            } else {
                this.loadData();
            }
        }

        // CSV Export
        if (this.csvListBinding) {
            this.csvListBinding.setExportFunction(options => {
                return this.items?.map(item => this.configuration.csvExport(item, options)) ?? [];
            });
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.configuration?.pushUnsubscribe();
    }

    // <editor-fold desc="View Actions">

    loadMore() {
        if (this.configuration.getDataSource()) {
            this.configuration.getDataSource().requestSlice(0, this.offset + this.configuration.getLimit());
        } else {
            this.loadItems(this.offset + this.configuration.getLimit());
        }
    }

    loadLess() {
        this.offset = 0;
        this.items = this.items.slice(0, this.configuration.getLimit());
        this.dataSetChanged.emit(this.items);
        this.detectChanges();
    }

    loadAll() {
        if (this.configuration.getDataSource()) {
            this.configuration.getDataSource().requestSlice(0, this.itemCount);
        } else {
            this.loadItems(this.offset + this.configuration.getLimit(), false, true);
        }
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    private loadData() {
        this.items = [];
        this.itemsMap = new Map();
        this.itemCount = 0;

        if (this.configuration?.getDataSource()) {
            const fetcher = new TodoFetcher();
            fetcher.addRequest(this.configuration.getDataSource());
            fetcher.execute();
        } else {
            this.loadItems(0, true);
        }
        this.loadItems(0, true);
    }

    private apiRequest: Subscription;

    private loadItems(offset: number, doCount: boolean = false, loadAll: boolean = false) {
        if (this.apiRequest) this.apiRequest.unsubscribe();
        let api = OldApi.todos();

        if (this.configuration.getDisplay()) api.where('display_id', this.configuration.getDisplay().id);
        if (this.configuration.getHasDisplay()) api.whereGreaterThan('display_id', 0);
        if (this.configuration.getHasDisplay() === false) api.where('display_id', 0);
        if (this.configuration.getProject()) api.where('project_id', this.configuration.getProject().id);
        if (this.configuration.getUser()) api.where('user_id', this.configuration.getUser().id);
        if (this.configuration.getShowOnDisplay() != null) {
            if (this.configuration.getShowOnDisplay() == true)
                api.where('show_on_display', 1);
            else
                api.where('show_on_display', 0);
        }
        if (this.configuration.getArchived() != null) {
            if (this.configuration.getArchived() == true)
                api.whereGreaterThan('archived_id', 0);
            else
                api.where('archived_id', 0);
        }
        if (this.configuration.getOrArchivedSince()) api.where('or_archived_since', Helpers.serverDate(this.configuration.getOrArchivedSince()));
        if (this.configuration.getAvoidIds())
            api.whereNotIn('id', this.configuration.getAvoidIds());
        if (this.configuration.getUseGlobalFilter()) {
            let filter = this.filterGlobalService.getActiveSettings();
            if (this.configuration.getUseGlobalSearchFilter() && filter.search?.length > 0) {
                api.search('title', filter.search);
            }
            if (filter.isStarred) {
                api.whereGreaterThan('num_stars', 0);
            }
            if (filter.activeStatuses.length < 4 && filter.activeStatuses.length > 0) {
                api.whereIn('main_status.status_id', filter.activeStatuses);
            }
            if (filter.activeReactionFilters?.length > 0) {
                filter.activeReactionFilters.forEach(reactionFilter => {
                    api.whereIn('reaction_filters', [reactionFilter.reaction_type_id, reactionFilter.value]);
                });
            }
        }
        if (this.configuration.getSearch() && this.configuration.getSearch().length > 0) {
            api.search('title', this.configuration.getSearch());
        }
        if (this.configuration.getStarred() !== null) {
            api.where('starred', this.configuration.getStarred() ? 1 : 0);
        }
        if (this.configuration.getHasDeadline() !== null) {
            if (this.configuration.getHasDeadline()) {
                api.whereGreaterThan('deadline.id', 0);
            } else {
                api.where('deadline.id', 0);
            }
        }

        this.configuration.getOrderBy().forEach(orderBy => api.orderBy(orderBy[0], orderBy[1]));
        this.apiRequest = api
            .setOffset(offset)
            .setLimit(loadAll ? 100 : this.configuration.getLimit())
            .get(Todo.name)
            .subscribe((todos: Todo[]) => {
                this.offset = offset;
                todos.forEach(todo => this.addItem(todo));
                this.items = [...this.items];
                this.dataSetChanged.emit(this.items);
                this.detectChanges();
            });

        if (doCount) api.count(count => {
            this.itemCount = count;
            this.detectChanges();
        });
    }

    private addItem(item: Todo, appendToEnd: boolean = true) {
        const cardItem = this.configuration.createCard(item);
        if (appendToEnd) {
            this.items.push(cardItem);
        } else {
            this.items.unshift(cardItem);
        }
        this.itemsMap.set(item.id, cardItem);
        this.markChangeDetectionDirty();
    }

    private removeItem(item: Todo) {
        if (this.itemsMap.has(item.id)) {
            this.items.splice(this.items.indexOf(this.itemsMap.get(item.id)), 1);
            this.itemsMap.delete(item.id);
            this.itemCount--;
            this.detectChanges();
        }
    }

    // </editor-fold>

}
