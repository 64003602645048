import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {
    EditCategoryListConfiguration
} from "@app/editor/quick-editor/editors/generic/category-list-editor/EditCategoryListConfiguration";
import {Category} from "@app/core/models";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {
    EditCategoryPickerList
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerList";

@Component({
    selector: 'app-category-list-editor',
    templateUrl: './category-list-editor.component.html',
    styleUrls: ['./category-list-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryListEditorComponent extends BaseEditor<EditCategoryPickerList> {
    protected eventFieldName = 'category-list';

    @Input() model: EditCategoryPickerList & HasEventGenerator & BaseModel;
    @Input() configuration: EditCategoryListConfiguration;
    @Input() editorEvents: EditorEvents;

    public values: Category[];

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    protected render() {
        this.values = [...this.model.getCategories()];
        this.detectChanges();
    }

    public onCategoryAdded(value: Category): void {
        this.model.addCategory(value);
        this.onItemUpdated();
    }

    public onCategoryRemoved(value: Category): void {
        this.model.removeCategory(value);
        this.onItemUpdated();
    }

}
