import {Widths} from "@app/constants";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {TemplateRef} from "@angular/core";
import {ColumnTypes} from "@app/pages/displays/display-team/Helpers/ColumnTypes";
import {CaseListColumn} from "@app/pages/displays/display-team/Columns/CaseListColumn";

export class CaseListColumnType extends BaseColumnType {

    public identifier = ColumnTypes.CaseList;

    public isAlwaysVisible = false;

    public customWidth?: number;
    public minWidth = Widths.CardColumn;
    public maxWidth?: number;

    public resizeable = true;
    public canAutoResize = false;

    public frozenLeft = false;
    public cellClass?: string;

    public isList = true;

    public constructor(cellTemplate: TemplateRef<any>, headerTemplate: TemplateRef<any>) {
        super();
        this.cellTemplate = cellTemplate;
        this.headerTemplate = headerTemplate;
    }

    public createColumn(column: Column, settings: Map<string, ColumnSetting>): CaseListColumn {
        return new CaseListColumn(this, column, settings);
    }

}
