<div class="list-group">
    <app-toggle-item
        class="list-group-item"
        [interactive]="true"
        [labelClass]="'faux-label'"
        [icon]="'fa-check-circle'"
        [iconInactive]="'fa-circle'"
        [iconColorActive]="'text-success'"
        [iconColorInactive]="'text-black-50'"
        [active]="value"
        [label]="(configuration.label | translate)"
        (onToggle)="onToggle()"
    />
</div>
