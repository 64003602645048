import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {LocalizedDatePipeModule} from '@app/pipes/LocalizedDatePipe.module';
import {RestoreComponent} from "@app/pages/lists/restore/restore.component";
import {FormsModule} from "@angular/forms";
import {NgbInputDatepicker, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {CardsModule} from "@app/modules/Cards.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        LocalizedDatePipeModule,
        FormsModule,
        NgbInputDatepicker,
        CardsModule,
        NgbTooltip,
    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        RestoreComponent
    ],
    exports: [

    ],
    providers: [

    ]
})
export class RestoreModule {
}
