import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";

export class EditActionConfiguration extends BaseEditorConfiguration {

    public showDragDrop: boolean;
    public showArchive: boolean;
    public showDelete: boolean;

    constructor(showDragDrop: boolean, showArchive: boolean, showDelete: boolean) {
        super();
        this.showDragDrop = showDragDrop;
        this.showArchive = showArchive;
        this.showDelete = showDelete;
    }

}
