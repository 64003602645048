<div class="container">
    <h2>Reference</h2>
    <h4>Projektkort</h4>

    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <app-card-project class="example-box" *ngFor="let project of projects" [model]="project" cdkDrag>
        </app-card-project>
        <!--<app-task-card class="example-box" *ngFor="let task of tasks" [model]="task" cdkDrag></app-task-card>-->
    </div>

</div>
