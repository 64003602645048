import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

export class ColumnGroup {
    public name: string;
    public columns: BaseTableColumn[];
    public active: boolean;
    public open: boolean = false;

    constructor(name: string, columns: BaseTableColumn[]) {
        this.name = name;
        this.columns = columns;
        this.checkActive();
    }

    public checkActive() {
        this.active = this.columns.filter(column => column.isVisible).length > 0;
    }

    public toggleActive() {
        this.active = !this.active;
    }

    public toggleOpen() {
        this.open = !this.open;
    }
}
