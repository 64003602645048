import {NgModule} from "@angular/core";
import {EditorSwitcherComponent} from "@app/editor/quick-editor/editors/editor-switcher/editor-switcher.component";
import {CommonModule} from "@angular/common";
import {TitleEditorModule} from "@app/editor/quick-editor/editors/generic/title-editor/TitleEditor.module";
import {
    DeadlineListEditorModule
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/DeadlineListEditor.module";
import {
    MilestonePlanSelectEditorModule
} from "@app/editor/quick-editor/editors/project/milestone-plan-select-editor/MilestonePlanSelectEditor.module";
import {CardEditorModule} from "@app/editor/quick-editor/editors/generic/card-editor/CardEditor.module";
import {StatusEditorModule} from "@app/editor/quick-editor/editors/generic/status-editor/StatusEditor.module";
import {
    EstimateListEditorModule
} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EstimateListEditor.module";
import {
    CategoryListEditorModule
} from "@app/editor/quick-editor/editors/generic/category-list-editor/CategoryListEditor.module";
import {
    ReactionListEditorModule
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/ReactionListEditor.module";
import {TextEditorModule} from "@app/editor/quick-editor/editors/generic/text-editor/TextEditor.module";
import {ListEditorModule} from "@app/editor/quick-editor/editors/generic/link-editor/LinkEditor.module";
import {WhoWhenEditorModule} from "@app/editor/quick-editor/editors/generic/who-when-editor/WhoWhenEditor.module";
import {ActionEditorModule} from "@app/editor/quick-editor/editors/generic/action-editor/ActionEditor.module";
import {
    MilestoneListEditorModule
} from "@app/editor/quick-editor/editors/generic/milestone-list-editor/MilestoneListEditor.module";
import {ArchivedEditorModule} from "@app/editor/quick-editor/editors/generic/archived-editor/ArchivedEditor.module";
import {UserListEditorModule} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserListEditor.module";
import {
    UseStatusRulesEditorModule
} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/UseStatusRulesEditor.module";
import {
    CategoryPickerListEditorModule
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/CategoryPickerListEditor.module";
import {
    DepartmentPickerEditorModule
} from "@app/editor/quick-editor/editors/generic/department-picker-editor/DepartmentPickerEditor.module";
import {TagListEditorModule} from "@app/editor/quick-editor/editors/generic/tag-list-editor/TagListEditor.module";
import {CanCopyEditorModule} from "@app/editor/quick-editor/editors/generic/can-copy-editor/CanCopyEditor.module";
import {IsPrivateEditorModule} from "@app/editor/quick-editor/editors/generic/is-private-editor/IsPrivateEditor.module";
import {NumberEditorModule} from "@app/editor/quick-editor/editors/generic/number-editor/NumberEditor.module";
import {
    TodoCategoryEditorModule
} from "@app/editor/quick-editor/editors/todo/todo-category-editor/TodoCategoryEditor.module";
import {CheckboxEditorModule} from "@app/editor/quick-editor/editors/generic/checkbox-editor/CheckboxEditor.module";

@NgModule({
    imports: [
        CommonModule,
        TitleEditorModule,
        DeadlineListEditorModule,
        MilestonePlanSelectEditorModule,
        CardEditorModule,
        StatusEditorModule,
        EstimateListEditorModule,
        CategoryListEditorModule,
        ReactionListEditorModule,
        TextEditorModule,
        ListEditorModule,
        WhoWhenEditorModule,
        ActionEditorModule,
        MilestoneListEditorModule,
        ArchivedEditorModule,
        UserListEditorModule,
        UseStatusRulesEditorModule,
        CategoryPickerListEditorModule,
        DepartmentPickerEditorModule,
        TagListEditorModule,
        CanCopyEditorModule,
        IsPrivateEditorModule,
        NumberEditorModule,
        TodoCategoryEditorModule,
        CheckboxEditorModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        EditorSwitcherComponent,
    ],
    exports: [
        EditorSwitcherComponent

    ],
    providers: [

    ],
})
export class EditorSwitcherModule {}
