<div
    *ngIf="displayMode != 'none'"
    class="d-flex flex-column gap-1"
>
    <div
        *ngFor="let fetcherName of executingDataFetcherNames"
        class="page-loading-indicator d-flex align-items-center gap-1 active"
    >
        <app-loading-indicator [showLogo]="false"></app-loading-indicator>
        <span>{{ fetcherName }}...</span>
    </div>

    <ng-container *ngIf="displayMode == 'completed'">
        <i class="fa-duotone fa-circle-check"></i>
        <span >{{'_ui_complete' | translate}}...</span>
    </ng-container>

</div>
