<div
    class="container container-fluid shadow p-0 narrow-editor modal-content base-editor"
    [ngStyle]="windowStyle"

    [class.minimized]="minimized"
    [class.maximized]="maximized"
    [class.un-docked]="!docked"
    [class.loading]="!isReady"
    [class.editable]="editable"

    cdkDrag
    [cdkDragDisabled]="docked"
    [cdkDragFreeDragPosition]="freeDragPosition"

    [@changeState]="isOpen ? 'open' : 'closed'"
    (@changeState.start)="animationStarted($event)"
    (@changeState.done)="animationDone($event)"

    mwlResizable
    [enableGhostResize]="true"
    [validateResize]="validateResize"
    (resizeEnd)="onResizeEnd($event)"
    [resizeEdges]="{top: false, bottom: false, left: false, right: false}"
>

    <div
        class="h-100"
    >

        <div
            class="d-flex flex-column h-100"
        >
            <div
                class="pt-2 flex-shrink-1 card-header flex-row"
            >
                <div
                    cdkDragHandle
                    class="w-100 mb-0 d-flex justify-content-start w-100 flex-row align-items-center header-row"
                >

                    <app-task-editor-header
                        class="d-flex"
                        [item]="item"
                        [taskType]="configuration"
                        [validator]="validator"
                        [fields]="fields"
                        [onReloadEventEmitter]="onReloadEventEmitter"
                    />

                    <app-window-action-buttons
                        class="ms-auto d-mobile-none"
                        (minimize)="onWindowMinimizeClicked()"
                        (maximize)="onWindowMaximizeClicked()"
                        (close)="onCloseBtnClicked()"
                        [validator]="validator"
                    />

                </div>

                <app-task-editor-title
                    [item]="item"
                    [fields]="fields"
                    (onValidatorChange)="onTitleValidatorChanged($event)"
                />
            </div>

            <div
                class="scroll-y align-self-stretch card-content"
            >
                <ul ngbNav
                    class="nav-tabs tabs-editor"
                    (navChange)="onTabChanged($event)"
                    #nav="ngbNav"
                    [(activeId)]="selectedTab"
                    [destroyOnHide]="false"
                >

                    <!-- Basic Information -->
                    <li
                        [ngbNavItem]="Globals.Tabs.Basic"
                        [class.active]="nav.activeId == Globals.Tabs.Basic"
                    >
                        <a ngbNavLink class="">
                            <span>
                                <span>{{'_task_basis_information' | translate}}</span>
                                <app-validation-warning-list
                                    [inline]="false"
                                    *ngIf="basicTabValidator"
                                    class="tab-validation-warning-list"
                                    [validatorError]="basicTabValidator"
                                />
                            </span>
                        </a>

                        <ng-template ngbNavContent>
                            <app-task-editor-tabs-basic
                                [item]="item"
                                [taskType]="configuration"
                                [fields]="fields"
                                (validatorChange)="onBasicTabValidatorChanged($event)"
                            />
                        </ng-template>
                    </li>

                    <!-- Categories -->
                    <li
                        [ngbNavItem]="Globals.Tabs.Categories"
                        [class.active]="nav.activeId == Globals.Tabs.Categories"
                        *ngIf="fields.get(Fields.Categories.Tab)?.visible"
                    >
                        <a ngbNavLink class="">
                            <span>
                                <span>{{ fields.get(Fields.Categories.Tab).smartName | translate }}</span>
                                <app-validation-warning-list
                                    [inline]="false"
                                    *ngIf="categoriesTabValidator"
                                    class="tab-validation-warning-list"
                                    [validatorError]="categoriesTabValidator"
                                />
                            </span>
                        </a>

                        <ng-template ngbNavContent>
                            <app-task-editor-tabs-categories
                                [item]="item"
                                [taskType]="configuration"
                                [fields]="fields"
                                [isSelectedTab]="selectedTab == Globals.Tabs.Categories"
                                (validatorChange)="onCategoriesTabValidatorChanged($event)"
                            />
                        </ng-template>
                    </li>

                    <!-- Users -->
                    <li
                        [ngbNavItem]="Globals.Tabs.Who"
                        [class.active]="nav.activeId == Globals.Tabs.Who"
                        *ngIf="fields.get(Fields.Responsible)?.visible"
                    >
                        <a ngbNavLink class="">
                            <span>
                                <span>{{ fields.get(Fields.Responsible).smartName | translate }}</span>
                                <app-validation-warning-list
                                    [inline]="false"
                                    *ngIf="usersTabValidator"
                                    class="tab-validation-warning-list"
                                    [validatorError]="usersTabValidator"
                                />
                            </span>
                        </a>

                        <ng-template ngbNavContent>
                            <app-task-editor-tabs-users
                                [item]="item"
                                [taskType]="configuration"
                                [fields]="fields"
                                (validatorChange)="onUsersTabValidatorChanged($event)"
                            />
                        </ng-template>
                    </li>

                    <!-- Attach To -->
                    <li
                        [ngbNavItem]="Globals.Tabs.AttachTo"
                        [class.active]="nav.activeId == Globals.Tabs.AttachTo"
                        *ngIf="fields.get(Fields.AttachTo.Tab)?.visible"
                    >
                        <a ngbNavLink class="">
                            <span>
                                <span>{{ fields.get(Fields.AttachTo.Tab).smartName | translate }}</span>
                                <app-validation-warning-list
                                    [inline]="false"
                                    *ngIf="attachToTabValidator"
                                    class="tab-validation-warning-list"
                                    [validatorError]="attachToTabValidator"
                                />
                            </span>
                        </a>

                        <ng-template ngbNavContent>
                            <app-task-editor-tabs-attach-to
                                [item]="item"
                                [taskType]="configuration"
                                [fields]="fields"
                                [projectTypes]="projectTypes"
                                [isSelectedTab]="selectedTab == Globals.Tabs.AttachTo"
                                (validatorChange)="onAttachToTabValidatorChanged($event)"
                            />
                        </ng-template>
                    </li>

                    <!-- Logs -->
                    <li
                        [ngbNavItem]="Globals.Tabs.Logs"
                        [class.active]="nav.activeId == Globals.Tabs.Logs"
                        *ngIf="fields.get(Fields.Log)?.visible"
                    >
                        <a ngbNavLink class="">
                            <span>
                                <span>{{ fields.get(Fields.Log).smartName | translate }}</span>
                                <app-validation-warning-list
                                    [inline]="false"
                                    *ngIf="logTabValidator"
                                    class="tab-validation-warning-list"
                                    [validatorError]="logTabValidator"
                                />
                            </span>
                        </a>

                        <ng-template ngbNavContent>
                            <app-task-editor-tabs-log
                                [item]="item"
                                [taskType]="configuration"
                                [fields]="fields"
                                [openEventEmitter]="onLogTabOpenEventEmitter"
                                (validatorChange)="onLogTabValidatorChanged($event)"
                            />
                        </ng-template>
                    </li>

                </ul>
                <div [ngbNavOutlet]="nav" class="categories ngb-tabs-content"></div>
            </div>
        </div>

        <app-task-editor-footer
            class="position-absolute bg-light border-top w-100 p-2 nav nav-pills"
            [item]="item"
            [validator]="validator"
            (close)="onCloseBtnClicked()"
            (delete)="onDeleteBtnClicked()"
        />

    </div>

</div>
