/**
 * Created by ModelParser
 * Date: 24-06-2021.
 * Time: 14:01.
 */
import {CalculatedFieldsMilestoneDefinition} from './definitions/CalculatedFieldsMilestoneDefinition';
import {ChangeEvent, WampService, WampSubscription} from "../../../services/wamp.service";

export class CalculatedFieldsMilestone extends CalculatedFieldsMilestoneDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static CreateChangeSubscription(event: string, wampService: WampService, handler: (event: ChangeEvent<CalculatedFieldsMilestone>) => void): WampSubscription {
        return wampService.subscribe(event, data => handler(CalculatedFieldsMilestone.ParseChangeEvent(data)));
    }

    public static ParseChangeEvent(data: any): ChangeEvent<CalculatedFieldsMilestone> {
        const previous = new CalculatedFieldsMilestone(data.previous);
        const next = new CalculatedFieldsMilestone(data.next);
        return new ChangeEvent<CalculatedFieldsMilestone>(previous, next);
    }

}
