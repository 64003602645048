<div
    ngbDropdown
    #dropdown="ngbDropdown"
    container="body"
    [placement]="placement"
    class="position-relative"
    (click)="$event.stopPropagation(); dropdown.isOpen() || !showUsers ? null : dropdown.open()"
>
    <a
        class="no-caret"
        (click)="preventPropagation($event)"
        id="handup-dropdown"
        ngbDropdownToggle
    >
        <app-toggle-item
            container="body"
            [ngbTooltip]="('_ui_mark_hand_up' | translate)"
            [openDelay]="500"
            [closeDelay]="300"
            [placement]="tooltipPlacement"
            [class.text-black-50]="showUsers ? model?.num_hand_ups === 0 : false"
            [interactive]="false"
            [active]="showUsers ? model.num_hand_ups > 0 : false"
            [icon]="'fa-hand-paper'"></app-toggle-item>
    </a>

    <ul class="dropdown-menu sub-menu p-0 mt-4 border shadow-sm"
        aria-labelledby="handup-dropdown"
        ngbDropdownMenu
        [class.d-none]="!showUsers"
        (mouseleave)="$event.stopPropagation(); "
    >

        <ng-container *ngIf="isLoading">
            <li class="d-flex align-items-center justify-content-center">
                <app-loading-indicator [showLogo]="false"></app-loading-indicator>
            </li>
        </ng-container>
        <ng-container *ngIf="!isLoading">
            <li class="p-0 m-0 mb-1">
                <small class="w-100 p-2 ps-3 pe-3 bg-light m-0 border-bottom"
                       style="border-radius: 0.25rem 0.25rem 0 0;" >{{'_ui_following_has_hand_up' | translate}}</small>
            </li>
            <li class="d-flex cursor-pointer m-0 align-items-center " *ngIf="!active && user">
                <app-toggle-item class="w-100  d-block p-1" [interactive]="true" [active]="false"
                                 [icon]="'fa-hand-paper'"
                                 (click)="$event.stopPropagation();"
                                 (onToggle)="toggleActive($event, null)" [label]="user?.name"></app-toggle-item>
            </li>
            <ng-container *ngFor="let handUp of handsUp">
                <li class="d-flex cursor-pointer m-0 align-items-center border-top">
                    <app-toggle-item class="w-100  d-block p-1" [interactive]="true" [active]="true"
                                     [icon]="'fa-hand-paper'"
                                     (click)="$event.stopPropagation();"
                                     (onToggle)="toggleActive($event, handUp)"
                                     [label]="handUp.user.name"></app-toggle-item>
                </li>
            </ng-container>
        </ng-container>
    </ul>

</div>
