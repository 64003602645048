import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    ProjectCardTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectCardTableColumn";
import {ProjectCardCell} from "@app/pages/displays/display-project-details/subdisplay-top/Cells/ProjectCardCell";

export class ProjectCardColumn extends BaseColumn {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new ProjectCardTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: BaseRow): BaseCell {
        return new ProjectCardCell(row, this);
    }

}
