import {BaseColumnConfiguration} from "@app/editor/quick-editor/columns/BaseColumnConfiguration";
import {Widths} from "@app/constants";
import {TemplateRef} from "@angular/core";

export class TemplateColumnConfiguration extends BaseColumnConfiguration {

    public value: TemplateRef<any>;
    public context: any;

    constructor(value: TemplateRef<any>, canResize: boolean, canAutoResize: boolean) {
        super(Widths.TextColumn, canResize, canAutoResize);
        this.value = value;
    }

}
