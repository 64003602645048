import {GenericTableColumn} from "@app/pages/displays/display-team/TableColumns/GenericTableColumn";
import {TableColumns} from "@app/pages/displays/display-team/Helpers/TableColumns";
import {TaskListSoftThisWeekColumn} from "@app/pages/displays/display-team/Columns/TaskListSoftThisWeekColumn";

export class TaskListSoftThisWeekTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TaskList_SoftThisWeek;

    public column: TaskListSoftThisWeekColumn;

}
