<div class="card preview p-1" [class.mini]="mini">
    <div class="d-flex w-100 font-size-lg ">
        <app-color-label class="card-type me-1 flex-shrink-0"></app-color-label>
        <div class="title p-0 font-weight-bold col -border-bottom -border-light flex-fill">
            <div class="pt-0 h-100 text-capitalize-firstletter w-100 item-bg"></div>
        </div>
    </div>
    <div class="d-flex justify-content-between font-size-md flex-wrap align-items-center card-details mt-1">
        <ul class="d-flex list-unstyled justify-content-start align-items-center w-100 m-0 flex-wrap item-bg">
            <li class="w-100 "></li>
        </ul>
    </div>
</div>
