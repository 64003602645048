import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbPopover, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {
    FindAndFocusBtnComponent
} from "@app/editor/base-editor-v3/components/find-and-focus-btn/find-and-focus-btn.component";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        FindAndFocusBtnComponent,
    ],
    exports: [
        FindAndFocusBtnComponent,
    ],
    imports: [
        CommonModule,
        NgbPopover,
        NgbTooltip,
        TranslateModule,
    ],
})
export class FindAndFocusBtnModule {
}
