import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input, OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {PageComponent} from '@app/pages/page.component';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {Project} from '@app/core/models/Project';
import {ProjectCase} from '@app/core/models/ProjectCase';
import {DisplayFilter} from '@app/core/models/DisplayFilter';
import {Subscription} from 'rxjs';
import {Api, ProjectCasesSummaryGet} from '@app/core/http/Api/Api';
import {CardProjectConfiguration} from '@app/shared/_ui/cards/medium/card-project/card-project-configuration';
import {DatatableComponent, SortDirection} from '@swimlane/ngx-datatable';
import {EventService} from '@app/services/event.service';
import * as moment from 'moment';
import {CaseRow} from '@app/pages/displays/display-cases/CaseRow';
import Helpers from '@app/core/helpers';
import {Settings} from '@app/pages/displays/display-cases/Settings';
import {Filters} from '@app/pages/displays/display-cases/Filters';
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {StatusTypes, TaskDeadlineTypes, TaskStatusTypes, TaskUserTypes} from "@app/constants";
import {ScreenshotHelper} from "@app/core/ScreenshotHelper/ScreenshotHelper";
import {
    TaskTitlePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskTitlePresetGenerator";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDeadlinePresetGenerator";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {ProjectCardColumnType} from "@app/pages/displays/display-cases/ColumnTypes/ProjectCardColumnType";
import {CaseAmountColumnType} from "@app/pages/displays/display-cases/ColumnTypes/CaseAmountColumnType";
import {GoalAmountColumnType} from "@app/pages/displays/display-cases/ColumnTypes/GoalAmountColumnType";
import {PlannedValueColumnType} from "@app/pages/displays/display-cases/ColumnTypes/PlannedValueColumnType";
import {AchievedValueColumnType} from "@app/pages/displays/display-cases/ColumnTypes/AchievedValueColumnType";
import {DaysColumnType} from "@app/pages/displays/display-cases/ColumnTypes/DaysColumnType";
import {CaseTextColumnType} from "@app/pages/displays/display-cases/ColumnTypes/CaseTextColumnType";
import {GoalTextColumnType} from "@app/pages/displays/display-cases/ColumnTypes/GoalTextColumnType";
import {AppointmentListColumnType} from "@app/pages/displays/display-cases/ColumnTypes/AppointmentListColumnType";
import {DaysColumn} from "@app/pages/displays/display-cases/Columns/DaysColumn";
import {ColumnTypes} from "@app/pages/displays/display-cases/ColumnTypes";
import {ProjectCardColumn} from "@app/pages/displays/display-projects/Columns/ProjectCardColumn";
import {BaseApi} from "@app/core/http/Api/BaseApi";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {DataFetcherCollection} from "@app/core/DataFetchers/DataFetcherCollection";
import {AppointmentListColumn} from "@app/pages/displays/display-cases/Columns/AppointmentListColumn";
import {AppointmentListCell} from "@app/pages/displays/display-cases/Cells/AppointmentListCell";
import {DaysTableColumn} from "@app/pages/displays/display-cases/TableColumns/DaysTableColumn";
import {TemplateTypes} from "@app/pages/displays/display-cases/TemplateTypes";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {WeekColumnType} from "@app/pages/displays/display-cases/ColumnTypes/WeekColumnType";
import {WeekColumn} from "@app/pages/displays/display-cases/Columns/WeekColumn";
import {WeekTableColumn} from "@app/pages/displays/display-cases/TableColumns/WeekTableColumn";
import {Deadline, User} from "@app/core/models";

export interface CasesRowHeightData {
    rows: number;
    height: number;
}

@Component({
    selector: 'app-display-cases',
    templateUrl: './display-cases.component.html',
    styleUrls: ['./display-cases.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayCasesComponent extends PageComponent implements OnInit, OnChanges {
    protected filtersSettings = new Filters();
    protected columnController = new ColumnController();

    @Input() isExpanded = false;
    @Input() visibleSubDisplays: number;
    @Input() isMainDisplay = false;
    @Input() displayId: number;
    @Output() isExpandedChangeEvent = new EventEmitter<boolean>();
    @Output() onSortChange = new EventEmitter<BaseTableColumn>();
    @Output() itemCount = new EventEmitter<CasesRowHeightData>();

    // UI
    @ViewChild('contentContainer', {static: true}) contentContainer: ElementRef;
    @ViewChild('dataTable', {static: false}) table: DatatableComponent;
    @ViewChild('headerTemplate', {static: true}) headerTemplate: TemplateRef<any>;
    @ViewChild('headerTemplateCopyPrevious', {static: true}) headerTemplateCopyPrevious: TemplateRef<any>;
    @ViewChild('headerDateTemplate', {static: true}) headerDateTemplate: TemplateRef<any>;
    @ViewChild('headerTemplateNavigation', {static: true}) headerTemplateNavigation: TemplateRef<any>;
    @ViewChild('projectNameTemplate', {static: true}) projectNameTemplate: TemplateRef<any>;
    @ViewChild('casesText1Template', {static: true}) casesText1Template: TemplateRef<any>;
    @ViewChild('goalText1Template', {static: true}) goalText1Template: TemplateRef<any>;
    @ViewChild('casesText2Template', {static: true}) casesText2Template: TemplateRef<any>;
    @ViewChild('goalText2Template', {static: true}) goalText2Template: TemplateRef<any>;
    @ViewChild('casesText3Template', {static: true}) casesText3Template: TemplateRef<any>;
    @ViewChild('goalText3Template', {static: true}) goalText3Template: TemplateRef<any>;
    @ViewChild('casesTemplate', {static: true}) casesTemplate: TemplateRef<any>;
    @ViewChild('appointmentsTemplate', {static: true}) appointmentsTemplate: TemplateRef<any>;
    @ViewChild('goalTemplate', {static: true}) goalTemplate: TemplateRef<any>;
    @ViewChild('plannedTemplate', {static: true}) plannedTemplate: TemplateRef<any>;
    @ViewChild('reachedTemplate', {static: true}) reachedTemplate: TemplateRef<any>;
    @ViewChild('daysTemplate', {static: true}) daysTemplate: TemplateRef<any>;
    @ViewChild('weekTemplate', {static: true}) weekTemplate: TemplateRef<any>;

    @HostListener('window:resize', ['$event'])
    sizeChange(event: Event) {
        setTimeout(() => this.recalculateTableHeight(false));
    }

    public tableColumns: BaseTableColumn[] = [];
    public templateTypes = new TemplateTypes();
    public rows: CaseRow[] = [];
    public count: number;
    public displayFilter: DisplayFilter;
    public hasProjectTypeIdSetting: boolean;
    public dataFetcherCollection = new DataFetcherCollection();

    // Summary
    public showSummary = false;
    public totalCasesEstimate = 0;
    public totalGoalEstimate = 0;
    public totalPlanEstimate = 0;
    public totalReachedEstimate = 0;
    public rowsReady: boolean = false;

    // Data
    private limit = 50;
    private offset = 0;
    private rowsMap: Map<number, CaseRow>; // ProjectID -> Row

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.initialized = false;
        this.buildColumns();
        super.ngOnInit();
        this.setupPush();

        this.subscribe(this.filterGlobalService.onSettingsPeriodChangeEvent.subscribe(period => {
            this.setColumnDate(period.start);
            this.detectChanges();
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        if (changes['isExpanded']) {
            this.recalculateTableHeight();
        }
    }

    // <editor-fold desc="Push">

    private setupPush() {
        this.eventService.subscribeToProject(0, event => {
            switch (event.action) {
                case EventService.Created:
                    this.loadProject(event.item.id);
                    break;
                case EventService.Updated:
                    if (this.rowsMap.has(event.item.id)) {
                        this.loadProject(event.item.id);
                    } // Reload this project
                    break;
                case EventService.Deleted:
                    if (this.rowsMap.has(event.item.id)) {
                        this.removeRow(this.rowsMap.get(event.item.id));
                    } // Delete row
                    break;
            }
        });

        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(() => this.loadData()));
        this.subscribe(this.filterGlobalService.onSettingsPeriodChangeEvent.subscribe(() => this.loadData()));
    }

    // </editor-fold>

    // <editor-fold desc="View">

    public expandProjects() {
        if (this.isExpanded) {
            this.toggleExpanded();
            this.loadProjects(this.offset + (this.rows.length - this.offset));
        } else {
            this.toggleExpanded();
        }
    }

    public updateProjectCaseGoal(value: number, projectCase: ProjectCase) {
        projectCase.setGoal(value);
    }

    public updateProjectCaseCases(value: number, projectCase: ProjectCase) {
        projectCase.setCases(value);
    }

    toggleExpanded() {
        this.isExpanded = !this.isExpanded;
        this.isExpandedChangeEvent.emit(this.isExpanded);
        this.recalculateTableHeight(true);
        if (this.isExpanded) {
            this.loadProjects(this.offset + this.limit, true, false, true);
        }
    }

    updateProjectCase(field: string, value: string, projectCase: ProjectCase) {
        switch (field) {
            case 'cases_text1':
                projectCase.setCasesText1(value);
                break;
            case 'cases_text2':
                projectCase.setCasesText2(value);
                break;
            case 'cases_text3':
                projectCase.setCasesText3(value);
                break;
            case 'goal_text1':
                projectCase.setGoalText1(value);
                break;
            case 'goal_text2':
                projectCase.setGoalText2(value);
                break;
            case 'goal_text3':
                projectCase.setGoalText3(value);
                break;
        }
    }

    public columnSortChange(column: BaseTableColumn) {
        this.onSortChange.emit(column);
        this.loadData();
    }

    public copyProjectCaseFromLastWeek(row: CaseRow) {
        this.copyProjectAllCasesFromLastWeekDialog([row]);
    }

    public copyProjectAllCasesFromLastWeekDialog(rows?: CaseRow[]) {
        let start = moment(this.filterGlobalService.getActiveSettings().period.start).startOf('isoWeek').subtract(1, 'week').toDate();
        this.dialogService.copyProjectCaseDialog(rows ? rows : this.rows, this.department, Helpers.serverDate(start), this.rows[0].projectCase?.date)
            .then((response) => {
                if (response && response.copyResponse) {
                    response.copyResponse.items.forEach(cri => {
                        const projectRow = this.rows.find(pr => pr.projectCard.item.id == cri.project_id);
                        if (projectRow && cri.to_project_case) {
                            const caseItem = new ProjectCase(cri.to_project_case);
                            projectRow.setProjectCase(caseItem);
                        }
                    });
                    this.detectChanges();
                }
            })
    }

    public onCaptureScreenshotBtnClicked() {
        if (this.isCapturingScreenshot) {
            return;
        }
        this.isCapturingScreenshot = true;
        ScreenshotHelper.Capture(this.contentContainer.nativeElement, () => {
            this.isCapturingScreenshot = false;
            this.detectChanges();
        });
    }

    reloadAll() {
        // loadData
        this.loadData();
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    private loadData(force = false) {
        if (!this.displayFilter || !this.initialized && !force) {
            return;
        }

        this.rows = [];
        this.rowsMap = new Map();
        this.offset = 0;
        this.isLoading = true;
        this.loadProjects(this.offset, true, true, this.isExpanded);
        this.loadSummary();
    }

    private projectsApi: Subscription;

    private loadProjects(offset: number, doItems: boolean = true, doCount: boolean = false, loadAll: boolean = false) {
        this.isLoading = true;

        let api = Api.projects().get();
        this.loadAppendFilters(api);

        if (doItems) {
            this.projectsApi?.unsubscribe();
            this.dataFetcherCollection.cancel();

            this.projectsApi = api
                .limit(loadAll ? 100 : this.limit)
                .offset(offset)
                .find(projects => {
                    this.offset = offset;
                    this.isLoading = false;

                    this.columnController.getVisibleColumns()
                        .filter(column => column.implementsDataFetching)
                        .forEach(column => {
                            this.dataFetcherCollection.add((column as unknown as ColumnDataFetcherInterface).getDataFetchers());
                        });
                    this.dataFetcherCollection.clearFetchers();

                    const rows = projects.map(project => this.createRow(project));

                    this.dataFetcherCollection.execute(() => {
                        const projectId2Row = new Map<number, CaseRow>();

                        rows.forEach(row => {
                            this.addRow(row);
                            projectId2Row.set(row.projectCard.item.id, row);
                        });

                        if (projectId2Row.size) {
                            let start = this.filterGlobalService.getActiveSettings().period.start;
                            start = moment(start).startOf('isoWeek').toDate();
                            Api.projectCases()
                                .smartGet()
                                .project_ids(Array.from(projectId2Row.keys()))
                                .date(Helpers.serverDate(moment(start).startOf('isoWeek').toDate()))
                                .find(projectCases => {
                                    projectCases.forEach(projectCase => {
                                        if (projectId2Row.has(projectCase.project_id)) {
                                            const row = projectId2Row.get(projectCase.project_id);
                                            row.setProjectCase(projectCase);
                                        }
                                    });
                                });
                        }

                        setTimeout(() => {
                            this.recalculateTableHeight(); // Højde på tabel skal opdateres manuelt
                        }, 500);
                    });
                });
        }

        if (doCount) {
            api.count(count => this.count = count);
        }
    }

    private projectsSummaryApi: Subscription;

    public loadSummary() {
        if (this.projectsSummaryApi) {
            this.projectsSummaryApi.unsubscribe();
        }
        let api = Api.projectCases().summaryGet();
        this.loadAppendFilters(api, false);

        let start = this.filterGlobalService.getActiveSettings().period.start;
        start = moment(start).startOf('isoWeek').toDate();
        let end = moment(start).add(4, 'day').endOf('day').toDate();

        this.projectsSummaryApi = api
            .start(Helpers.serverDate(start))
            .end(Helpers.serverDate(end))
            .department_id(this.department.id)
            .find(summary => {
                // console.log('loadSummary() : ', summary)
                this.totalCasesEstimate = Math.round(summary[0].total_cases_estimate);
                this.totalGoalEstimate = Math.round(summary[0].total_goal_estimate);
                this.totalPlanEstimate = Math.round(summary[0].total_plan_estimate);
                this.totalReachedEstimate = Math.round(summary[0].total_reached_estimate);
            });
    }

    public loadProject(projectId: number) {
        this.isLoading = true;

        let api = Api.projects().getById(projectId);
        this.loadAppendFilters(api);

        api.find(projects => {
            this.isLoading = false;

            if (projects.length == 1) { // Valid
                let row = this.createRow(projects[0]);
                if (!this.rowsMap.has(row.projectCard.item.id)) {
                    this.addRow(row);
                    this.count++;
                }
                row.reload(this.filterGlobalService.getActiveSettings().period.start);
            } else if (this.rowsMap.has(projectId)) { // Ikke valid
                this.removeRow(this.rowsMap.get(projectId));
            }

            this.recalculateTableHeight(); // Højde på tabel skal opdateres manuelt
        });
    }

    private loadAppendFilters(api: BaseApi | ProjectCasesSummaryGet, include: boolean = true) {
        if (include && api instanceof BaseApi) {
            api.include('project_estimate');
        }

        // Apply always
        api
            .where('archived_id', 0)
            .where('project_type_id', this.getConfigProjectTypeId());
        if (this.department) {
            api.where('department.id', this.department.id);
        }

        // Apply active filter
        switch (this.displayFilter.filter_type) {
            case Filters.FilterAll:
                break;
        }
        if (this.displayFilter.categories?.length) {
            api.whereIn('category.id', this.displayFilter.categories.map(category => category.id));
        }

        // Apply global settings
        const globalSettings = this.filterGlobalService.getActiveSettings();
        if (globalSettings.activeUsers?.length > 0) {
            api.search('users', this.filterGlobalService.getActiveSettings().activeUsers.map(user => user.id));
            // columns
            let smartSearch = [];
            smartSearch.push('project_case_tasks');
            if (smartSearch.length) {
                api.search('users_smart_search', smartSearch);
            }
        }
        if (globalSettings.search?.length > 0) {
            api.search('title', globalSettings.search);
        }
        if (globalSettings.isHandUp) {
            api.whereGreaterThan('num_hand_ups', 0);
        }
        if (globalSettings.isStarred) {
            api.whereGreaterThan('num_stars', 0);
        }
        if (globalSettings.activeStatuses.length < 4 && globalSettings.activeStatuses.length > 0) {
            api.whereIn('main_status.status_id', globalSettings.activeStatuses);
        }
        if (globalSettings.activeReactionFilters?.length > 0) {
            globalSettings.activeReactionFilters.forEach(reactionFilter => {
                api.whereIn('reaction_filters', [reactionFilter.reaction_type_id, reactionFilter.value]);
            });
        }
        if (globalSettings.activeCategories?.length) {
            api.whereIn('category.id', globalSettings.activeCategories?.map(category => category.id));
        }

        // Apply sort (Name column can overwrite display filter
        const projectCardTableColumn = this.columnController.getColumns<ProjectCardColumn>(ColumnTypes.ProjectCard)[0].getVisibleTableColumns()[0];
        const overwriteSort = projectCardTableColumn.activeSortItem !== undefined;
        const sortType = overwriteSort
            ? projectCardTableColumn.activeSortItem?.sortId
            : this.displayFilter.sort_type;
        const sortDirection = overwriteSort
            ? projectCardTableColumn.activeSortDirection
            : globalSettings.activeSortDirection;
        switch (Filters.GetBaseSort(sortType)) {
            default:
            case Filters.SortTitle:
                api.orderBy('title', sortDirection);
                break;
            case Filters.SortMainStatus:
                // Rækkefølge ønskes vendt: https://podio.com/klartboard/softwareudvikling/apps/stories/items/848
                api.orderBy('main_status.status_id', sortDirection == SortDirection.asc ? SortDirection.desc : SortDirection.asc);
                break;
            case Filters.SortCategoryType:
                api.where('category.category_type_id', Filters.ParseSortCategoryType(sortType));
                api.orderBy('category.name', sortDirection);
                break;
        }
    }

    private createRow(project: Project): CaseRow {
        let row = new CaseRow();

        if (!this.rowsMap) {
            this.rowsMap = new Map();
        }

        let update = this.rowsMap.has(project.id);
        if (update) { // Update existing row
            row = this.rowsMap.get(project.id);
        }

        this.columnController.getAllColumns().forEach(column => {
            row.addCell(column.createCell(row));
        });


        const projectConfiguration = new CardProjectConfiguration();
        row.setProjectCard(new CardItem<Project>(project, projectConfiguration));

        this.columnController.getColumns<AppointmentListColumn>(ColumnTypes.AppointmentList)
            .forEach(column => {
                const cell = row.getCell<AppointmentListCell>(column);
                cell.listConfiguration.setProject(project);
            });

        this.columnController.getColumns<DaysColumn>(ColumnTypes.Days)
            .forEach(column => {
                column.getTableColumns<DaysTableColumn>().forEach(tableColumn => {
                    const start = tableColumn.getDate(this.filterGlobalService.getActiveSettings().period.start);
                    tableColumn.getListConfigurationForRow(row)
                        .setProject(project)
                        .setDepartment(this.department)
                        .setDeadlineBetween(start, moment(start).endOf('day').toDate())
                        .setCreatePresetGenerators([
                            new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                            new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                            new TaskUseStatusRulesPresetGenerator(true),
                            new TaskTitlePresetGenerator(() => project.title),
                            new TaskProjectPresetGenerator(project.id),
                            new TaskDeadlinePresetGenerator(TaskDeadlineTypes.Normal, start, false),
                            new TaskDepartmentPresetGenerator(this.department.id),
                            new TaskUserPresetGenerator(
                                TaskUserTypes.Participant,
                                (options: {user: User}) => options.user.id,
                                Deadline.Create(start, false)
                            ),
                        ]);
                });
            });

        const start = this.filterGlobalService.getActiveSettings().period.start;
        const end = moment(this.filterGlobalService.getActiveSettings().period.start).endOf('isoWeek').toDate();
        this.columnController.getColumns<WeekColumn>(ColumnTypes.Week)
            .forEach(column => {
                column.setPeriod(start, end);

                const cell = column.getCell(row);
                cell.listConfiguration
                    .setProject(project)
                    .setDepartment(this.department)
                    .setDeadlineBetween(start, end)
                    .setCreatePresetGenerators([
                        new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                        new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                        new TaskUseStatusRulesPresetGenerator(true),
                        new TaskTitlePresetGenerator(() => project.title),
                        new TaskProjectPresetGenerator(project.id),
                        new TaskDeadlinePresetGenerator(
                            TaskDeadlineTypes.Normal,
                            column.getPresetDeadlineDate(start),
                            false
                        ),
                        new TaskDepartmentPresetGenerator(this.department.id),
                        new TaskUserPresetGenerator(
                            TaskUserTypes.Participant,
                            (options: {user: User}) => options.user.id,
                            Deadline.Create(column.getPresetDeadlineDate(start), false)
                        ),
                    ]);
            });

        return row;
    }

    private addRow(row: CaseRow) {
        if (!this.rowsMap.has(row.projectCard.item.id)) {
            this.rows.push(row);
            this.rowsMap.set(row.projectCard.item.id, row);
        }
    }

    private removeRow(row: CaseRow) {
        if (this.rowsMap.has(row.projectCard.item.id)) {
            let rowTemp = [...this.rows];
            rowTemp.splice(this.rows.indexOf(row), 1);
            this.rows = rowTemp;
            this.rowsMap.delete(row.projectCard.item.id);
            this.recalculateTableHeight();

            this.count--;
        }
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    private buildColumns() {
        this.columnController.addColumnTypes([
            new ProjectCardColumnType(this.projectNameTemplate, this.headerTemplateNavigation),
            new CaseAmountColumnType(this.casesTemplate, this.headerTemplateCopyPrevious),
            new GoalAmountColumnType(this.goalTemplate, this.headerTemplate),
            new PlannedValueColumnType(this.plannedTemplate, this.headerTemplate),
            new AchievedValueColumnType(this.reachedTemplate, this.headerTemplate),
            new DaysColumnType(this.daysTemplate, this.headerDateTemplate),
            new WeekColumnType(this.weekTemplate, this.headerTemplate),
            new CaseTextColumnType(this.casesText1Template, this.headerTemplate),
            new GoalTextColumnType(this.goalText1Template, this.headerTemplate),
            new AppointmentListColumnType(this.appointmentsTemplate, this.headerTemplate),
        ]);
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();
        this.columnController.loadColumns(this.displayId, () => {
            this.setColumnDate(this.filterGlobalService.getActiveSettings().period.start);

            this.initialize();
        });
    }

    private initialize() {
        this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
            this.tableColumns = this.columnController.getVisibleTableColumns();
            this.loadData();
        }));

        this.subscribe(this.shellFilterGroup.onActiveFilterChangeEventSubscribe(filter => {
            this.displayFilter = filter;
            this.loadData();
        }));

        this.initialized = true;
        this.showSummary = this.getShowSummary();
        this.hasProjectTypeIdSetting = this.getConfigProjectTypeId() > 0;

        this.filtersSettings.getEditorConfig(this.display, config => {
            const categoryColumnSorts = config.categoryTypes?.map(categoryType => {
                return PageColumnSort.CreateWithSortId(Filters.SortCategoryTypeGenerator(categoryType));
            });
            this.columnController.getColumns<ProjectCardColumn>(ColumnTypes.ProjectCard)
                .forEach(column => column
                    .getTableColumns()
                    .forEach(tableColumn => {
                        tableColumn.sortItems.push(...categoryColumnSorts);
                    }));
        });

        this.loadData();
    }

    private setColumnDate(start: Date) {
        this.columnController
            .getColumns<DaysColumn>(ColumnTypes.Days)
            .forEach(column => column.setDate(start));
    }

    private recalculateTableHeight(dispatch: boolean = true) {
        if (this.table) {
            this.table.recalculateColumns(this.tableColumns);
            this.rows = [...this.rows]; //This is input into <ngx-datatable>
            this.tableColumns = [...this.tableColumns]; //This is input into <ngx-datatable>
            this.table.recalculate(); //ngx-datatable reference
            this.rowsReady = true;
        }
        if (dispatch) {
            setTimeout(() => {
                const targetElement = this.contentContainer.nativeElement.querySelector('.datatable-body-row');
                window.dispatchEvent(new Event('resize'));
                this.itemCount.emit({rows: this.rows.length, height: targetElement?.offsetHeight});
                if (this.table) {
                    setTimeout(() => {
                        this.table.recalculate(); //ngx-datatable reference
                    }, 500);
                }
            }, 500);
        }
    }

    private getShowSummary(): boolean {
        if (this.settingsMap.has(Settings.ShowSummary)) {
            return this.settingsMap.get(Settings.ShowSummary).getBoolean();
        } else {
            return false;
        }
    }

    private getConfigProjectTypeId() {
        if (this.settingsMap.has(Settings.ProjectTypeId)) {
            return this.settingsMap.get(Settings.ProjectTypeId).getIntValue();
        } else {
            return 0;
        }
    }

    // </editor-fold>

    protected readonly ColumnTypes = ColumnTypes;
}
