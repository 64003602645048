/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {DeadlineDefinition} from './definitions/DeadlineDefinition';
import * as moment from 'moment';
import Helpers from '../helpers';

export class Deadline extends DeadlineDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static createDefault(date?: Date) {
        let item = new Deadline();
        item.id = 0;
        item.date = Helpers.serverDate(date || new Date());
        return item;
    }

    public static Create(date: Date, isSoft?: boolean): Deadline {
        const item = new Deadline();
        item.date = Helpers.serverDate(date);
        item.is_soft = isSoft;
        return item;
    }

    public static Today(): Deadline {
        const item = new Deadline();
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        item.date = Helpers.serverDate(date);
        return item;
    }

    public static NoDeadline(): undefined {
        return undefined;
    }

    public getDate(): Date {
        if (this.date) {
            const _moment: moment.Moment = moment(this.date, 'YYYY-MM-DDTHH:mm:ssZ');
            return _moment.isValid() ? _moment.toDate() : null;
        }
        return null;
    }

    public getServerDate(): string {
        if (this.date) {
            return moment(this.date).format('YYYY-MM-DDTHH:mm:ssZ')
        }
        return null;
    }

    public isDate(date: number): boolean {
        let deadlineDate = this.getDate();
        deadlineDate.setHours(0, 0, 0, 0);
        return deadlineDate.getTime() == date;
    }

    public isBetween(start: Date, end: Date): boolean {
        let deadlineDate = this.getDate();
        deadlineDate?.setHours(0, 0, 0, 0);
        let time = deadlineDate?.getTime();
        return time && time >= start.getTime() && time <= end.getTime();
    }

}
