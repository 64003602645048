/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {AppointmentDefinition} from './definitions/AppointmentDefinition';
import {OldApi} from '@app/http/Api';
import {AppInjector} from '@app/services/app-injector.service';
import {EventService} from '@app/services/event.service';
import * as moment from "moment";
import {TranslateService} from "@ngx-translate/core";

export class Appointment extends AppointmentDefinition {

    num_stars: number;
    constructor(json?: any) {
        super(json);
        //this.translateService = AppInjector.getInjector().get(TranslateService);
    }

    stripHtml(html:string):string
    {
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.firstChild.textContent || "";

    }

    get title(): string {
        if (this.description !== '') {
            return this.stripHtml(this.description); //.replace(/<[^>]*>/g, '');
        } else {
            return `Mangler beskrivelse (${this.id})`;
        }
    }
    set title(s:string){
        this.description = s;
    }

    set main_status(b: any) {
    }

    get main_status(): any {
        return null;
    }

    get status(): any {
        return null;
    }

    get handUp(): boolean {
        return null;
    }

    get starred(): boolean {
        return null;
    }

    public isCompleted(): boolean {
        return false;
    }

    public getCleanDescription(): string {
        return this.description.replace('<br>', "\r\n\r\n").replace(/<[^>]*>/g, '');
    }

    public getDateString(): string {
        const date = moment(this.updated ? this.updated : this.created);
        let format = AppInjector.getInjector().get(TranslateService).instant('_ui_date_format_longDate');
        if (date.year() != moment().year()) {

            return date.format(format);
        }else {
            format = AppInjector.getInjector().get(TranslateService).instant('_ui_date_format_microDate');
            return date.format(format);
        }
    }

    public post(callback?: (response:any) => void) {
        OldApi.appointments().post(this).subscribe(response => {
            this.populate(response.resource);
            AppInjector.getInjector().get(EventService)
                .emitAppointment(this, EventService.Created);
            if(callback) callback(response.resource);
        });
    }

    public delete(callback?: () => void) {
        OldApi.appointments(this.id).delete().subscribe(response => {
            AppInjector.getInjector().get(EventService).emitAppointment(this, EventService.Deleted);
            if(callback) callback();
        });
    }

    public patch(fields?: string[], callback?: (response:any) => void) {
        // console.log('onItemUpdated : patch() : ', fields, 'data : ', this.description);
        let data: {} = {};
        if(fields) {
            for (let field of fields) data[field] = this[field];
        } else {
            data = this;
        }
        OldApi.appointments(this.id).patch(data).subscribe(response => {
            this.populate(response.resource, !!fields);
            AppInjector.getInjector().get(EventService).emitAppointment(this, EventService.Updated, fields);
            if(callback) callback(response);
        });
    }

}
