import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {fadeAnimation} from '@app/animations';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Phase} from '@app/core/models/Phase';
import {merge, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {BaseSearchComponent} from '@app/shared/_forms/search/base-search/base-search.component';
import {Department} from '@app/core/models/Department';
import {PhasesProject, Project} from '@app/core/models';
import {ProjectEditorService} from '@app/editor/project-editor-loader/project-editor.service';
import {Api} from '@app/core/http/Api/Api';
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {AppInjector} from '@app/services/app-injector.service';
import {ResultTemplateContext} from "@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window";

@Component({
    selector: 'app-project-phase-search',
    templateUrl: './project-phase-search.component.html',
    styleUrls: ['./project-phase-search.component.scss'],
    animations: [fadeAnimation],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProjectPhaseSearchComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ProjectPhaseSearchComponent),
            multi: true,
        }]
})
export class ProjectPhaseSearchComponent extends BaseSearchComponent implements OnInit {

    @Input() _internalValue: PhasesProject[] = [];
    @Input() departmentsChangeEvent: EventEmitter<Department[]>;
    @Input() departmentId: number;
    @Input() project: Project;
    @Input() showPhases = true;
    @Input() autoSave = false;
    @Output() updateIsCurrent = new EventEmitter<PhasesProject>();

    protected dialogService: BaseDialogService;

    public showDateTitle = false;
    public phases: Phase[];
    public result: Phase[] = [];
    formatter = (x: Phase) => x.name;
    placeholder: string = this.translateService.instant('_ui_search') + '...';
    private departments: Department[];

    constructor(private projectEditorService: ProjectEditorService) {
        super();
        const injector = AppInjector.getInjector();
        this.dialogService = injector.get(BaseDialogService);
    }

    ngOnInit() {
        this.getPhases();

        if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
            this.placeholder = 'Find...';
        }

        if (this.departmentsChangeEvent) {
            this.departmentsChangeEvent.subscribe((departments: Department[]) => this.departments = departments);
        }
        this.showDateTitle = this._internalValue?.filter(s => s.phase && s.phase.changeable_date).length > 0;

        if (this.project && this._internalValue && this.project.current_phases_project_id) {
            const pp = this._internalValue.find(pp => pp.id == this.project.current_phases_project_id);
            if (pp) pp.is_current = true;
        }
    }

    ngOnChanges(changes: any) {
        super.ngOnChanges(changes);
        if (changes['departmentId']) {
            this.getPhases();
        }
    }

    getPhases() {
        this.projectEditorService.getPhasesForType(this.project.project_type_id, phases => {
            this.showDateTitle = this._internalValue?.filter(s => s.phase && s.phase.changeable_date).length > 0;
            phases = this.departmentId ? phases.filter(item => {
                return !item.departments || item.departments?.findIndex(d => d.id == this.departmentId) !== -1;
            }) : phases;
            return this.phases = phases;
        });
    }

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            tap(() => this.searching = true),
            map(term => {
                let r = this.phases
                    .filter(v => String(v.name).toLowerCase().indexOf(String(term).toLowerCase()) > -1)
                // .filter(item => this._internalValue == null);
                this.result = r.length > 0 ? r : null;
                this.searchFailed = term !== '' ? this.result == null : null;
                let items = term === '' ? this.phases.filter(item => true) : r;

                if (this.departments && this.departments.length > 0) {
                    let allowedDepartmentIds = this.departments.map(department => department.id);
                    items = items.filter(item => {
                        if (item.departments) {
                            const match = item.departments.filter(department => allowedDepartmentIds.includes(department.id));
                            if (match.length > 0) {
                                return true;
                            }
                        }
                        return false;
                    });
                }
                return items;
            }),
            tap(() => this.searching = false),
        );
    };

    triggerSelection($event: NgbTypeaheadSelectItemEvent, input: any) {
        const projectDeadline = this.project.projects_deadlines && this.project.projects_deadlines.length > 0 ? this.project.projects_deadlines[0] : null;
        const date = projectDeadline && (!this._internalValue || this._internalValue.length == 0) ? projectDeadline.deadline.getDate() : null;
        this.itemSelected.emit($event.item);
        $event.preventDefault();
        input.value = '';

        const phasesProject: PhasesProject = PhasesProject.createDefault(this.project.id, date);
        phasesProject.phase_id = $event.item.id;
        phasesProject.project_id = this.project.id;
        phasesProject.phase = $event.item;

        this.addItem(phasesProject);
    }

    addItem(item: PhasesProject, force?: boolean) {
        if (!this._internalValue || !this.multiple) {
            this._internalValue = [];
            item.is_current = true;
            this.updateIsCurrent.emit(item);
        }

        this._internalValue.push(item);
        this.onChange(this._internalValue);
        this.propagateChange(this.internalValue);
        this.showDateTitle = this._internalValue?.filter(s => s.phase && s.phase.changeable_date).length > 0;

        if (this.autoSave || force) {
            Api.projects()
                .phasesProjectCreatePostByProjectId(this.project.id)
                .phase_id(item.phase_id)
                .save(null, (responsePhasesProject) => {
                    if (responsePhasesProject.id == item.id && !responsePhasesProject.phase && item.phase) {
                        if (responsePhasesProject.phase_id == item.phase_id) {
                            responsePhasesProject.phase = item.phase;
                        }
                    }
                })
        }
    }

    removeItem(item: PhasesProject) {
        this._internalValue = this._internalValue?.filter(p => p !== item);
        this.onChange(this._internalValue); // Value is good!
        this.propagateChange(this.internalValue);
        this.showDateTitle = this._internalValue?.filter(s => s.phase && s.phase.changeable_date).length > 0;

        if (item.is_current) {
            this.updateIsCurrent.emit(null);
        }
    }

    createField() {
        const projectDeadline = this.project.projects_deadlines && this.project.projects_deadlines.length > 0 ? this.project.projects_deadlines[0] : null;
        const date = projectDeadline && this._internalValue && this._internalValue.length == 0 ? projectDeadline.deadline.getDate() : null;

        const phasesProject: PhasesProject = PhasesProject.createDefault(this.project.id, date);
        phasesProject.phase = Phase.createDefault();
        phasesProject.phase.changeable_date = true;
        phasesProject.project_id = this.project.id;

        this.addItem(phasesProject);
    }

    activatePhase(item: PhasesProject) {
        this._internalValue.forEach(pp => {
            if (pp.id !== item.id) {
                pp.is_current = false;
            }
        });
        item.is_current = !item.is_current;

        this.updateIsCurrent.emit(item.is_current ? item : null);

        this.project.current_phases_project_id = item.is_current ? item.id : 0;

        this.onChange(this._internalValue); // Value is good!
        this.propagateChange(this.internalValue);
    }

    createPhaseDialog() {
        const departments: Department[] = this.project.departments;
        const projectTypeIDs = [this.project.project_type_id];
        this.dialogService.createPhaseDialog(departments, projectTypeIDs).then((response) => {
            if (response.phase) {
                const projectDeadline = this.project.projects_deadlines && this.project.projects_deadlines.length > 0 ? this.project.projects_deadlines[0] : null;
                const date = projectDeadline && this._internalValue && this._internalValue.length == 0 ? projectDeadline.deadline.getDate() : null;

                const phasesProject = PhasesProject.createDefault(this.project.id, date);
                phasesProject.phase = response.phase;
                phasesProject.phase_id = response.phase.id;
                phasesProject.phase.changeable_date = true;

                this.projectEditorService.appendPhase(this.project.project_type_id, response.phase);

                phasesProject.project_id = this.project.id

                this.addItem(phasesProject, false);
            }
        }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking'));
    }

    resultTemplateType: {
        result: Phase,
        term: string,
    }
}
