/**
 * Created by ModelParser
 */
import {User} from '../User';
import {ImportColumn} from '../ImportColumn';
import {Origin} from '../Origin';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class ImportDefinition extends BaseModel {
    user_id?: number;
    user?: User;
    file?: string;
    type?: string;
    import_columns?: ImportColumn[];
    origins?: Origin[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.user_id;
            delete this.user;
            delete this.file;
            delete this.type;
            delete this.import_columns;
            delete this.origins;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.file != null) {
            this.file = data.file;
        }
        if (data.type != null) {
            this.type = data.type;
        }
        if (data.import_columns != null) {
            this.import_columns = data.import_columns.map((i: any) => new ImportColumn(i));
        }
        if (data.origins != null) {
            this.origins = data.origins.map((i: any) => new Origin(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
