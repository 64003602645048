/**
 * Created by ModelParser
 */
import {Project} from '../Project';
import {Estimate} from '../Estimate';
import {ProjectEstimateType} from '../ProjectEstimateType';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class ProjectEstimateDefinition extends BaseModel {
    project_id?: number;
    project?: Project;
    estimate_id?: number;
    estimate?: Estimate;
    project_estimate_type_id?: number;
    project_estimate_type?: ProjectEstimateType;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.project_id;
            delete this.project;
            delete this.estimate_id;
            delete this.estimate;
            delete this.project_estimate_type_id;
            delete this.project_estimate_type;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.project_id != null) {
            this.project_id = data.project_id;
        }
        if (data.project != null) {
            this.project = new Project(data.project);
        }
        if (data.estimate_id != null) {
            this.estimate_id = data.estimate_id;
        }
        if (data.estimate != null) {
            this.estimate = new Estimate(data.estimate);
        }
        if (data.project_estimate_type_id != null) {
            this.project_estimate_type_id = data.project_estimate_type_id;
        }
        if (data.project_estimate_type != null) {
            this.project_estimate_type = new ProjectEstimateType(data.project_estimate_type);
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
