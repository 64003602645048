import {NgModule} from "@angular/core";
import {PriorityMatrixComponent} from "@app/shared/_elements/priority-matrix/priority-matrix.component";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        PriorityMatrixComponent,
    ],
    exports: [
        PriorityMatrixComponent

    ],
    providers: [

    ],
})
export class PriorityMatrixModule {}
