import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {environment} from '@env/environment';
import {AuthenticationService, I18nService} from '@app/core';
import {Observable} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import {versions} from '@env/versions';
import {User} from '@app/core/models/User';
import {map} from 'rxjs/operators';
import {UsersService} from '@app/services/users.service';
import Helpers from "@app/core/helpers";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default, // TO-DO: Changedetection
})
export class LoginComponent implements OnInit {
    version: string = environment.version;
    environment: any = environment;

    gitVersions: {
        npm_version: string;
        revision: string;
        branch: string;
        version: string;
        project_id: string
    } = versions;
    error: any;
    loginForm: UntypedFormGroup;
    isLoading = false;

    signedIn: Observable<boolean>;
    email: string;

    debug = false;


    constructor(private router: Router,
                private route: ActivatedRoute,
                private formBuilder: UntypedFormBuilder,
                private i18nService: I18nService,
                private authService: AuthenticationService,
                private oAuthService: OAuthService,
                private usersService: UsersService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.signedIn = this.authService.isSignedIn().pipe(
            map((signedIn: boolean) => {
                let redirectURI = this.authService.getItem('redirectUrl');
                // console.log('loadUserProfile() : redirectURI : ', redirectURI, 'signedIn: ', signedIn);
                if (signedIn && !this.debug) {
                    this.usersService.currentUser$.subscribe(value => {
                        if (value) {
                            let redirect: string = redirectURI && redirectURI !== '/' && redirectURI != '/login'
                                ? redirectURI
                                : '/app/home/dashboard';

                            redirect = Helpers.encodeUri(redirect)
                                .replace('%25253F', '?')
                                .replace('%25253D', '=')
                            // Redirects the user.
                            window.location.href = redirect;
                            this.authService.removeItem('redirectUrl')
                            // this.router.navigate(['/'], {replaceUrl: true});
                            // console.log('loadUserProfile() : redirectURI : user loaded : ', value, 'url : ', redirectURI, 'redirect : ', redirect);
                            // this.router.navigate([redirect], {
                            //     relativeTo: this.route,
                            //     skipLocationChange: false,
                            //     taskqueryParamsHandling: 'merge',
                            //     replaceUrl: true
                            // });
                        }
                    })

                }
                return signedIn;
            })
        );
        this.authService.userChanged().subscribe(
            (user: User) => {
                console.log('this.authService.userChanged : ', user);
                if (user.username)
                    this.email = user.username;
            });


        if (!this.oAuthService.hasValidAccessToken() && !window.location.hash && !this.debug) {
            setTimeout(() => this.login(), 250);
        }
    }

    login(): void {
        if(!this.oAuthService.hasValidAccessToken()) {
            console.log('login() : initImplicitFlow()');
            // this.oAuthService.initImplicitFlow();
            // this.oAuthService.logOut();

            this.oAuthService.tryLogin({
                onTokenReceived: (info) => {
                    console.log('login() : ', info);
                }
            }).catch((e) => {
                console.warn('login() : error : ', e);
                this.oAuthService.logOut();
            })
        }else{
            this.logout()
        }
    }

    logout(): void {
        this.authService.signout();
    }

    setLanguage(language: string) {
        this.i18nService.language = language;
    }

    get currentLanguage(): string {
        return this.i18nService.language;
    }

    get languages(): string[] {
        return this.i18nService.supportedLanguages;
    }

    private createForm() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            remember: true
        });
    }

}
