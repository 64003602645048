import {PageDisplaySetting} from "@app/pages/PageDisplaySetting";

export class Settings {

    public static HasCSVExport              = 275; // Bool, show header button for CSV Export

    public static ProjectTypeIds            = 237; // ProjectTypes tavlen skal vise

    public static GetHasCSVExport(settingsMap: Map<number, PageDisplaySetting>): boolean {
        if (settingsMap.has(Settings.HasCSVExport)) {
            return settingsMap.get(Settings.HasCSVExport).getBoolean();
        } else {
            return false;
        }
    }

    public static GetProjectTypeIds(settingsMap: Map<number, PageDisplaySetting>): number[] {
        if (settingsMap.has(Settings.ProjectTypeIds)) {
            return settingsMap.get(Settings.ProjectTypeIds).getIntValues();
        } else {
            return []; // Intet fallback. Hvis ikke sat, tavle er konfigureret forkert. Hvis vi bruger fallback, kan tavlen vise data den ikke må
        }
    }

}
