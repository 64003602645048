export class TableColumns {

    public static ProjectList = 'project-list';
    public static MilestoneList = 'milestone-list';
    public static CaseList = 'case-list';
    public static TaskList = 'task-list';
    public static TaskList_SoftThisWeek = 'task-list-soft-this-week';
    public static TaskList_SoftNextWeek = 'task-list-soft-next-week';
    public static TodoList = 'todo-list';
    public static AppointmentList = 'appointment-list';

}
