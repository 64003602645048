import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {Project, ProjectDeadlineType} from "@app/core/models";
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";

export class AvoidDeadlineTypeFilter extends BaseOnScreenFilter<Project> {

    public get name(): string {
        return AppInjector.getInjector()
            .get(TranslateService)
            .instant('_ui_without_item', {
                item: this.deadlineType.getSmartName().toLocaleLowerCase()
            });
    }
    public iconActive = 'fa-check-circle';
    public iconInactive = 'fa-ban';
    public invertIcon: boolean = false;

    private deadlineType: ProjectDeadlineType;

    constructor(enabled: boolean, deadlineType: ProjectDeadlineType) {
        super(enabled);
        this.deadlineType = deadlineType;
    }

    protected validate(item: Project): boolean {
        return item.findDeadlineByTypeId(this.deadlineType.id) === undefined;
    }

}
