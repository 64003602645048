import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseEditorComponent} from '@app/editor/base-editor/base-editor.component';
import {Appointment} from '@app/core/models/Appointment';
import {AppointmentForm} from '@app/shared/_modals/appointment-dialog/AppointmentForm';
import {PANEL_ANIMATION} from '@app/editor/panel.animation';
import {fadeAnimation, slideDownAnimation} from '@app/animations';

@Component({
    selector: 'app-appointment-dialog',
    templateUrl: './appointment-dialog.component.html',
    styleUrls: ['./appointment-dialog.component.scss'],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation,
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0.5
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ]
})
export class AppointmentDialogComponent extends BaseEditorComponent implements OnInit {

    // return this.editable && !this.isLoading && this.editForm !== null && this.fields != null;

    @Input() public userEditable:boolean = true;
    @Input() public title: string;



    public item: AppointmentForm;
    public model: Appointment;

    constructor(private activeModal: NgbActiveModal) {
        //this._activeModal = activeModal;
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        if(!this.title)
            this.title = this.translateService.instant('_display_efforts_appointment');

        this.setItem(this.model ? this.model : new Appointment());
    }

    private setItem(item: Appointment) {

          this.item = new AppointmentForm(item);
          this.editForm = this.item.toFormGroup(this.formBuilder);
          this.isLoading = false;
          this.editable = true;
          this.initialized = true;
          this.isOpen = true;

          // console.log('setItem() : ', this.item, ' from : ', item);
    }

    closeForm() {
        this.activeModal.close();
    }
    close(){
        this.closeForm();
        super.close();
    }

    saveForm(close: boolean = true) {
        if (this.isLoading || !this.validateForm() || !this.userEditable) {
            return;
        }
        const result = AppointmentForm.create(Object.assign({}, this.editForm.value));
        const callback = (r?:any) => {
            this.isLoading = false;
            if(r){
                this.item.populate(r, true);
            }
            if (close) {
                this.isOpen = false;
                this.closeForm();
            }else{
                this.editForm = this.item.toFormGroup(this.formBuilder);
            }
            const titleItem = this.translateService.instant('_display_efforts_appointment');
            const snack = this.snackbar.add(this.translateService.instant('_ui_item_saved', {item: titleItem}));
        };

        this.item.populate(result, true);

        if(this.item.exists())
            this.item.patch(['description'], callback);
        else
            this.item.post(callback);

    }


    delete() {
        if (this.isLoading) {
            return;
        }

        this.dialogService.confirm(
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_ui_delete_item', {name: this.item?.title}),
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_global_cancel'),
            'sm',
            false,
            'btn-danger'
        )
            .then((confirmed) => {
                if (confirmed) {
                    this.isLoading = true;
                    const result = AppointmentForm.create(Object.assign({}, this.editForm.value));
                    if(result.exists()) result.delete(() =>{
                        this.isLoading = false;
                        this.closeForm();
                    });

                }

            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    public get ready():boolean{
        return this.editable && !this.isLoading && this.editForm !== null;
    }

    saveAndClose() {
        this.saveForm(true);
    }


}
