/**
 * Created by ModelParser
 * Date: 09-11-2020.
 * Time: 15:55.
 */
import {CalculatedFieldsProjectDefinition} from './definitions/CalculatedFieldsProjectDefinition';
import {ChangeEvent, WampService, WampSubscription} from '../../../services/wamp.service';

export class CalculatedFieldsProject extends CalculatedFieldsProjectDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static CreateChangeSubscription(event: string, wampService: WampService, handler: (event: ChangeEvent<CalculatedFieldsProject>) => void): WampSubscription {
        return wampService.subscribe(event, data => handler(CalculatedFieldsProject.ParseChangeEvent(data)));
    }

    public static ParseChangeEvent(data: any): ChangeEvent<CalculatedFieldsProject> {
        const previous = new CalculatedFieldsProject(data.previous);
        const next = new CalculatedFieldsProject(data.next);
        return new ChangeEvent<CalculatedFieldsProject>(previous, next);
    }

}
