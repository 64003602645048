import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {CaseUser} from "@app/core/models/Task";
import {WeekDay} from "@app/pages/displays/display-cases/TableColumns/DaysTableColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {DaysColumn} from "@app/pages/displays/display-cases/Columns/DaysColumn";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {CaseRow} from "@app/pages/displays/display-cases/CaseRow";

export class DaysCell extends BaseCell {

    public column: DaysColumn;

    private listConfigurations = new Map<WeekDay, TaskListConfiguration<CaseUser>>();

    constructor(row: CaseRow, column: BaseColumn) {
        super(row, column);

        this.column.getVisibleDays().forEach(day => {
            const listConfiguration = new TaskListConfiguration<CaseUser>();

            listConfiguration
                .setTaskTypeIds(this.column.getTaskTypes())
                .setLimit(100)
                .setModelClass(CaseUser.name);

            listConfiguration.onItemUpdatedEvent.subscribe(value => {
                row.setSum(day, listConfiguration.getDataSource().getAllItems());
            });

            this.listConfigurations.set(day, listConfiguration);
        });
    }

    public getListConfiguration(weekDay: WeekDay): TaskListConfiguration {
        return this.listConfigurations.get(weekDay);
    }

    public getAllListConfigurations(): TaskListConfiguration[] {
        return [...this.listConfigurations.values()];
    }

    public setDate(week: Date) {

    }

}
