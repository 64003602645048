/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {DepartmentsProjectDefinition,} from './definitions/DepartmentsProjectDefinition';

export class DepartmentsProject extends DepartmentsProjectDefinition {

    constructor(json?: any) {
        super(json);
    }

}
