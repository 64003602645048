import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {Color, Phase} from "@app/core/models";

export abstract class GenericColumn extends BaseColumn {

    public phase?: Phase;
    public color?: Color;
    public showMatrix?: boolean;

}
