<div class="modal-inner-content p-0 overflow-visible d-flex align-items-center flex-column modal-content"
     [@openClose]="isOpen ? 'open' : 'closed'"
     [class.minimized]="minimized"
     [class.maximized]="maximized"
>
    <div class="modal-header d-flex align-items-center w-100" ngxModalDraggable>
        <h4 class="modal-description">{{title}}</h4>

        <div class="ms-auto  d-flex align-content-center d-flex me-1">

            <app-color-item class="cursor-pointer me-2"
                            [className]="'medium'"
                            [colorValue]="'#ff5f57'"
                            [iconClass]="'fa-times'"
                            container="body" [ngbTooltip]="('_ui_close' | translate)"
                            (click)="closeForm();"></app-color-item>

            <app-color-item class="cursor-pointer me-2"
                            [className]="'medium'"
                            [colorValue]="'#febc2e'"
                            [iconClass]="'fa-minus'"
                            container="body" [ngbTooltip]="('_ui_minimize' | translate)"
                            (click)="minimize();"></app-color-item>
            <app-color-item class="cursor-pointer"
                            [className]="'medium'"
                            [colorValue]="'#28c840'"
                            [iconClass]="'fa-sort'"
                            style="transform: rotate(45deg);"
                            container="body" [ngbTooltip]="('_ui_maximize' | translate)"
                            (click)="maximize();"></app-color-item>

        </div>

    </div>
    <div class="modal-body p-0 ">
        <div class="container p-0">
            <form *ngIf="ready"
                  [formGroup]="editForm"
                  (ngSubmit)="saveForm(false)" novalidate
                  [@fadeAnimation]="null"
                  class="h-100">
                <div class="d-flex flex-column h-100" style="padding-bottom: 61px;">

                    <div class="scroll-y align-self-stretch card-content">

                        <div class="container p-0">

                            <div class="d-flex m-0 p-3">

                                <ng-container *ngIf="userEditable">

                                    <app-rich-text-editor
                                        [parentFormControl]="$any(_efc.text)"
                                        id="description"
                                        class="h-100 w-100 rounded"
                                        [hideLabel]="true"
                                        [ngClass]="{ 'is-invalid': _efc.text.errors }"></app-rich-text-editor>

                                    <div *ngIf="_efc.text.invalid && (_efc.text.dirty || _efc.text.touched)"
                                         class="alert alert-danger mt-1">
                                        <div *ngIf="_efc.text.errors.required" class="d-flex align-items-center">
                                            <i class="fa-regular fa-circle-exclamation me-1"></i>
                                            <span >{{'_ui_is_required' | translate}}</span>
                                        </div>
                                    </div>
                                </ng-container>

                                <div *ngIf="!userEditable" [innerHTML]="text" class="scroll-y max-screen"></div>
                            </div>


                        </div>


                    </div>
                </div>

                <ul class="nav nav-pills nav-fill position-absolute bg-light border-top w-100 p-2" style="bottom: 0;">

                    <li class="nav-item -px-2">
                        <button type="button" class="btn btn-sm btn-secondary  panel-button"
                                (click)="closeForm()">
                            <span >{{'_ui_close' | translate}}</span>
                        </button>
                    </li>

                    <li class="nav-item -px-2">
                        <button type="button" class="btn btn-sm btn-primary panel-button" (click)="saveAndClose()"
                                [disabled]="!editForm.valid"
                        >
                            <span >{{'_ui_save_and_close' | translate}}</span>
                        </button>
                    </li>

                </ul>

            </form>
            <app-ghost-container *ngIf="!editable || isLoading" (click)="close()"></app-ghost-container>
        </div>
    </div>
</div>
