import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {ProjectPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/ProjectPresetType";

export class ProjectTitlePresetGenerator implements CreatePresetGenerator {

    private readonly handler: () => string;

    constructor(handler: () => string) {
        this.handler = handler;
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: ProjectPresetTypes.Title,
            payload: {
                title: this.handler(),
            }
        };
    }

}
