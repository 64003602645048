<ng-container>
    <div class="d-flex flex-row">
        <ul class="list-unstyled d-flex align-items-center flex-wrap  m-0">
            <li class=""
                [ngbTooltip]="popover && popover.isOpen() ? null : ('_milestone_plans' | translate)"
                [placement]="['left', 'auto']"
                container="body"
                placement="bottom"
            >
            <span class="fal fa-pencil-ruler cursor-pointer position-relative"
                  [ngbPopover]="popoverContent"
                  [autoClose]="false"
                  [placement]="placement"
                  [popoverClass]="'popover'"
                  triggers="manual"
                  container="body"
                  (click)="load(); !popover.isOpen() ? popover.open() : popover.close()"
                  #popover="ngbPopover"
            >
            </span>
            </li>
        </ul>
    </div>

    <ng-template #popoverContent>
        <div class="d-flex align-items-center flex-column ms-1 milestone-plan-selector">
            <app-loading-indicator [showLogo]="false" *ngIf="isLoading"></app-loading-indicator>
            <ng-container *ngIf="!isLoading">
                <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>

                <ng-container>
                    <div class="d-flex mt-1 mb-1 w-100 align-items-center">
                        <h4 class="mt-0 ms-0 mb-0 me-2 popover-title">{{('_ui_milestoneplan_select_template' | translate)}}</h4>

                        <div class="d-flex align-items-center ms-auto d-mobile-none">
                            <div class="me-1"
                                 *ngIf="visibleMilestonePlans && departments"
                                 [class.text-warning]="departments && departments.length > 0"
                                 [ngbTooltip]="('_ui_filter' | translate)"
                                 (click)="popover.open()"
                            >

                                <button
                                    class="d-flex align-items-center cursor-pointer position-relative btn btn-sm w-100 pt-1 h-100 btn-rounded"
                                    [ngbPopover]="popoverContent"
                                    [autoClose]="'outside'"
                                    [placement]="['left', 'auto']"
                                    [class.btn-outline-secondary]="departments.length == 0"
                                    [class.btn-outline-warning]="departments.length > 0"
                                    triggers="manual"
                                    container="body"
                                    popoverClass="zindex-top"
                                    type="button"

                                    #popover="ngbPopover">
                                    <span class="fal fa-filter"></span>
                                </button>

                                <ng-template #popoverContent>
                                    <app-department-picker [showAll]="true"
                                                           [selectedDepartments]="departments"
                                                           [multiple]="true"
                                                           [showDepartmentGroups]="true"
                                                           (onChangeEvent)="filterByDepartment($event)"></app-department-picker>
                                </ng-template>
                            </div>

                            <div class=""
                                 *ngIf="enableConvertToTemplate"
                                 ngbDropdown
                                 container="body"
                                 [placement]="['left-bottom', 'auto']">
                                <button id="create-milestone-dropdown"
                                        class="no-caret btn btn-sm btn-outline-secondary text-small me-0 btn-rounded"
                                        type="button"
                                        [ngbTooltip]="('_ui_milestoneplan_save_as_new_template' | translate) + '...' "
                                        ngbDropdownToggle>
                                    <i class="fal fa-plus-circle" aria-hidden="true"></i>
                                </button>

                                <div ngbDropdownMenu
                                     class="dropdown-wrapper shadow-sm dropdown-menu sub-menu p-0"
                                >
                                    <h5
                                        class="w-100 p-2 ps-3 pe-3 bg-dark m-0 border-bottom text-small text-light d-block">{{('_ui_milestoneplan_save_as_new_template' | translate)}}
                                    </h5>

                                    <div class="dropdown-scroll-wrapper">
                                        <button class="dropdown-item d-flex align-items-center" type="button"
                                                (click)="convertToTemplate_Personal()">
                                            <div class="d-flex align-items-center justify-content-start me-1">
                                                <span class="fal fa-plus-circle me-1"></span>
                                                <span >{{'_ui_milestoneplan_create_personal' | translate}}</span>
                                            </div>
                                            <div
                                                class="ms-auto text-small d-flex align-items-center justify-content-end">
                                                <span >{{'_ui_milestone_plan_selector_personal' | translate}}</span>
                                                <span class="fal fa-user ms-1"></span>
                                            </div>
                                        </button>

                                        <button class="dropdown-item d-flex align-items-center" type="button"
                                                (click)="convertToTemplate_Team()" *ngIf="isAdmin">
                                            <div class="d-flex align-items-center justify-content-start me-1">
                                                <span class="fal fa-plus-circle me-1"></span>
                                                <span >{{'_ui_milestoneplan_create_team' | translate}}</span>
                                            </div>
                                            <div
                                                class="ms-auto text-small d-flex align-items-center justify-content-end">
                                                <span >{{'_ui_milestone_plan_selector_team' | translate}}</span>
                                                <span class="fal fa-users ms-1"></span>
                                            </div>
                                        </button>

                                        <button class="dropdown-item d-flex align-items-center" type="button"
                                                (click)="convertToTemplate_Shared()" *ngIf="isAdmin">
                                            <div class="d-flex align-items-center justify-content-start me-1">
                                                <span class="fal fa-plus-circle me-1"></span>
                                                <span >{{'_ui_milestoneplan_create_global' | translate}}</span>
                                            </div>
                                            <div
                                                class="ms-auto text-small d-flex align-items-center justify-content-end">
                                                <span >{{'_ui_milestone_plan_selector_global' | translate}}</span>
                                                <span class="fal fa-globe-stand ms-1"></span>
                                            </div>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dropdown-scroll-wrapper tall w-100 border rounded scroll-y "
                         *ngIf="visibleMilestonePlans && visibleMilestonePlans.length > 0">
                        <app-milestone-plan-select-item
                            class="d-flex align-items-center p-1 ps-2 pe-2"
                            *ngFor="let milestonePlan of visibleMilestonePlans"
                            [disabled]="isApplying"
                            [milestonePlan]="milestonePlan"
                            [project]="project"
                            (onClickEvent)="popover.close(); applyMilestonePlan(milestonePlan)"
                            [showEditable]="enableEdit"
                            [showDelete]="enableDelete"
                        ></app-milestone-plan-select-item>
                    </div>
                    <div class="disabled text-small pt-1 w-100"
                         *ngIf="!visibleMilestonePlans || visibleMilestonePlans.length == 0">{{'_ui_no_results' | translate}}</div>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>

</ng-container>
