import {NgModule} from "@angular/core";
import {CategoryPickerComponent} from "@app/shared/_forms/category-picker/category-picker.component";
import {ColorPickerModule} from "@app/shared/_elements/color-picker/ColorPicker.module";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {NgbTooltipModule, NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {LoadingIndicatorModule} from '@app/shared/_ui/loading-indicator/LoadingIndicator.module';
import {
    ValidationWarningListModule
} from '@app/editor/base-editor-v3/components/validation-warning-list/validation-warning-list.module';
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";

@NgModule({
    imports: [
        CommonModule,
        ColorPickerModule,
        ColorItemModule,
        NgbTypeaheadModule,
        FormsModule,
        TranslateModule,
        NgbTooltipModule,
        LoadingIndicatorModule,
        ValidationWarningListModule,
        TypedTemplateDirective

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CategoryPickerComponent,
    ],
    exports: [
        CategoryPickerComponent

    ],
    providers: [

    ],
})
export class CategoryPickerModule {}
