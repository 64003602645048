import {CardConfiguration} from '@app/shared/_ui/cards/CardConfiguration';

export class CardTodoConfiguration extends CardConfiguration {

    public showProjectMiniCard: boolean = true;

    constructor() {
        super();
    }

    get exists(): boolean {
        return true;
    }

    public compare(config: CardTodoConfiguration): boolean {
        return true;
    }
}
