<div class="weekdays d-flex h-100 position-relative -overflow-scroll w-100 scroll-x"
     [class.bg-dark]="isMobileVersion"
     [class.ps-2]="isMobileVersion"
     [class.pe-2]="isMobileVersion"
     *ngIf="!isLoading">

    <div class="h-100 d-flex position-relative justify-content-between"
         style="min-width: 100%;">

        <div
            style="width: 16px; height: 16px; top: 7px; left: 24px; z-index: 200; position: absolute; transform: scale(.75);">
            <app-on-screen-filter-selector
                [onScreenFilters]="onScreenFilters"
            ></app-on-screen-filter-selector>
        </div>

        <div class="d-flex flex-row justify-content-between align-items-start align-content-center flex-grow-1"
             [class.soft-next-week-visible]="softNextWeekTableColumn"
             [class.w-100]="!softNextWeekTableColumn"
        >

            <div class="d-flex flex-column weekday flex-fill h-100" *ngIf="softThisWeekTableColumn">
                <div class="date d-flex flex-column text-center align-content-center">
                    <span><span>{{softThisWeekTableColumn.name | translate}}</span>, <span
                        translate="_ui_week"></span> {{filterGlobalService.getActiveSettings().period.start | week}}</span>
                    <span>({{(softThisWeekTableColumn.load / 60 / 60) | number : '1.0-2'}} <span
                        translate="_ui_hours"></span>)</span>
                </div>
                <div class="items ">
                    <app-multi-list (dataSetChanged)="softThisWeekTableColumn.dataSetChanged()"
                                    [configuration]="softThisWeekTableColumn.listConfiguration"></app-multi-list>
                </div>
            </div>

            <ng-container *ngFor="let day of daysTableColumns; let i = index;">
                <div class="d-flex flex-column weekday flex-fill flex-grow-1 flex-shrink-1 h-100 day">
                    <div class="date d-flex flex-column text-center align-content-center ">
                        <span>
                            <span>{{day.dateStart | localizedDate: 'EEEE'}} </span>
                            <span>{{day.dateStart | localizedDate: 'microDate'}}</span>
                            <span
                                *ngIf="day.dateStart.getDate() != day.dateEnd.getDate()">- {{day.dateEnd | localizedDate: 'microDate'}}</span>
                        </span>
                        <span>({{day.load / 60 / 60 | number : '1.0-2'}} <span translate="_ui_hours"></span>)</span>
                    </div>
                    <div class="items">
                        <app-multi-list (dataSetChanged)="day.dataSetChanged()"
                                        [configuration]="day.listConfiguration"></app-multi-list>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="day d-flex flex-column weekday flex-fill h-100 soft-next-week flex-shrink-1" style="width: 90px;"
             *ngIf="softNextWeekTableColumn">
            <div class="date d-flex flex-column text-center align-content-center">
                <span>&nbsp;</span>
                <span>&nbsp;</span>
            </div>
            <div class="items w-100 small-items">
                <div class=" p-1 pt-2 h-100">
                    <ul class="list-unstyled scroll-y"
                        id="{{softNextWeekTableColumn.dropListId}}"
                        cdkDropList
                        [cdkDropListConnectedTo]="dropList"
                        [cdkDropListData]="softNextWeekTableColumn.listConfiguration"
                        (cdkDropListDropped)="softNextWeekDropEvent($event)"
                        [cdkDropListEnterPredicate]="softNextWeekDragEnterPredicate"
                    >
                    </ul>
                </div>
                <div class="rotated pointer-events-none">
                    <span>{{softNextWeekTableColumn.name | translate}}</span><span>({{softNextWeekTableColumn.itemCount}} <span
                    >{{'_tasks' | translate}}</span>)</span>
                </div>
            </div>
        </div>
    </div>
</div>


