import {Injectable} from '@angular/core';
import {MilestoneField} from '@app/core/models/MilestoneField';
import {MilestonesService} from "@app/services/milestones.service";

export class Field {
    visible: boolean;
    required: boolean;
    translation: string;
    name: string;
    smartName: string;
    index: number;

    constructor(milestoneField: MilestoneField) {
        this.visible = milestoneField.visible;
        this.required = milestoneField.required;
        this.translation = milestoneField.language_key;
        this.name = milestoneField.name;
        this.smartName = (this.name && this.name.length > 0) ? this.name : this.translation;
        this.index = milestoneField.index_;
    }
}

@Injectable({
  providedIn: 'root'
})
export class MilestoneEditorService {

    constructor(private milestonesService: MilestonesService) {

    }

    // <editor-fold desc="Fields">

    private fields: Map<number, Field>;
    public getFields(callback: (fields: Map<number, Field>) => void) {
        // Return if already cached
        if(this.fields) return callback(this.fields);

        // Get and generate fields
        this.milestonesService.getEditorFields(fields => {
            if(!this.fields) this.fields = new Map();
            fields.forEach(field => this.fields.set(field.id, new Field(field)));
            callback(this.fields);
        });
    }

    // </editor-fold>
}
