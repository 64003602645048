import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {EventEmitter} from "@angular/core";

export class ValidatorCollection implements ValidationErrorInterface {

    private validators: ValidationErrorInterface[];

    constructor(validators: ValidationErrorInterface[]) {
        this.validators = validators;

        validators.forEach(validator => validator.onIsValidChanged.subscribe(() => this.validate()));

        this.validate();
    }

    public addValidator(validator: ValidationErrorInterface) {
        this.validators.push(validator);
        validator.onIsValidChanged.subscribe(() => this.validate());
        this.validate();
    }

    private lastValidation: true | string[] = true;

    public validate() {
        let isValid = true;
        const errors: string[] = [];
        this.validators.forEach(validator => {
            if (!validator.isValid()) {
                isValid = false;
                errors.push(...validator.getErrorDescriptions());
            }
        });

        const newValidation = isValid ? true: errors;
        if (this.lastValidation !== newValidation) {
            this.lastValidation = newValidation;
            this.onIsValidChanged.emit(isValid);
        }
    }

    // <editor-fold desc="Validator interface">

    public onIsValidChanged = new EventEmitter<boolean>()

    isValid(): boolean {
        return this.lastValidation === true;
    }

    getErrorDescriptions(): string[] {
        return this.lastValidation === true ? [] : this.lastValidation;
    }

    // </editor-fold>

}
