import {NgModule} from "@angular/core";
import {
    DashboardSidebarTaskListComponent
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/dashboard-sidebar-task-list.component";
import {NgbDropdownModule, NgbNavModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {TaskListModule} from "@app/shared/_ui/lists/task-list/TaskList.module";
import {TodoListModule} from "@app/shared/_ui/lists/todo-list/TodoList.module";
import {MilestoneListModule} from "@app/shared/_ui/lists/milestone-list/MilestoneList.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbDropdownModule,
        TranslateModule,
        ToggleItemModule,
        NgbNavModule,
        TaskListModule,
        TodoListModule,
        CardsModule,
        MilestoneListModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DashboardSidebarTaskListComponent,
    ],
    exports: [
        DashboardSidebarTaskListComponent

    ],
    providers: [

    ],
})
export class DashboardSidebarTaskListModule {}
