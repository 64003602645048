import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {ProjectPhaseCell} from "@app/pages/displays/display-projects/Cells/ProjectPhaseCell";

export class ProjectPhaseColumn extends GenericColumn<ProjectPhaseCell> {

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new ProjectPhaseCell(row, this);
    }

    public implementsCSVExport = true;

}
