import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVColumn} from "@app/export/csv/CSVColumn";
import {ProjectPhaseListCell} from "@app/pages/displays/display-projects/Cells/ProjectPhaseListCell";
import {Phase} from "@app/core/models";
import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {
    ProjectPhaseListTableColumn
} from "@app/pages/displays/display-projects/TableColumns/ProjectPhaseListTableColumn";

export class ProjectPhaseListColumn extends GenericColumn<ProjectPhaseListCell> {

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new ProjectPhaseListCell(row, this);
    }

    public createTableColumns(): GenericTableColumn[] {
        const tableColumn = new ProjectPhaseListTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public implementsCSVExport = true;

    public toCSVColumn(result: (column: CSVColumn) => void, rows: ProjectsDisplayRow[]): void {
        const column = CSVColumn.CreatePrimary(this, []);

        // Collect all unique phases from all visible rows
        const phaseMap: Map<number, Phase> = new Map();
        rows?.forEach(row => {
            row.project?.item.phases_projects?.forEach(phasesProject => {
                if (phasesProject.phase) {
                    phaseMap.set(phasesProject.phase_id, phasesProject.phase);
                }
            });
        });
        // Sort phases by index
        const phases = Array.from(phaseMap.values()).sort((a, b) => a.index_ - b.index_);
        // Add one column per phases and push to columns as columnList
        column.subColumns = phases.map(phase => CSVColumn.CreateSecondary(phase.id, phase.name));

        result(column);
    }

}
