import {NgModule} from "@angular/core";
import {
    MilestonePlanSelectorComponent
} from "@app/shared/_elements/milestone-plan-selector/milestone-plan-selector.component";
import {NgbDropdownModule, NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {TranslateModule} from "@ngx-translate/core";
import {DepartmentPickerModule} from "@app/shared/_forms/department-picker/DepartmentPicker.module";
import {
    MilestonePlanSelectItemModule
} from "@app/shared/_elements/milestone-plan-select-item/MilestonePlanSelectItem.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        NgbPopoverModule,
        LoadingIndicatorModule,
        TranslateModule,
        DepartmentPickerModule,
        NgbDropdownModule,
        MilestonePlanSelectItemModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MilestonePlanSelectorComponent,
    ],
    exports: [
        MilestonePlanSelectorComponent

    ],
    providers: [

    ],
})
export class MilestonePlanSelectorModule {}
