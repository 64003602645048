import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {PhaseProgressColumn} from "@app/pages/displays/display-kanban-progress/Columns/PhaseProgressColumn";
import {ProjectListConfiguration} from "@app/shared/_ui/lists/project-list/ProjectListConfiguration";
import {EventEmitter} from "@angular/core";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters} from "@app/pages/displays/display-kanban-progress/Filters";
import {DisplayFilter, Phase} from "@app/core/models";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {ResizeEvent} from "angular-resizable-element";
import {TableColumns} from "@app/pages/displays/display-kanban-progress/TableColumns";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

export class PhaseProgressTableColumn extends BaseTableColumn {

    public identifier = TableColumns.PhaseProgress;

    public column: PhaseProgressColumn;

    public projectListConfiguration = new ProjectListConfiguration();

    public reloadEvent = new EventEmitter();

    constructor(column: BaseColumn) {
        super(column);

        this.sortItems = [
            PageColumnSort.CreateWithSortId(Filters.SortTitle),
            PageColumnSort.CreateWithSortId(Filters.SortResponsible),
            PageColumnSort.CreateWithSortId(Filters.SortStatus),
            PageColumnSort.CreateWithSortId(Filters.SortStarred),
            PageColumnSort.CreateWithSortId(Filters.SortHandUp),
        ];

        this.projectListConfiguration
            .setArchived(false)
            .setCurrentPhaseProgressTypeId(this.column.getFilterPhaseProgress())
            .setCustomAllowCardItemDropFunction((cardItem, fromListConfiguration, result) => {
                Phase.GetSingle(cardItem.item.current_phases_project.phase_id, phase => {
                    const canEnterPhaseProgressTypeId = phase.getVisiblePhaseProgressTypes()?.find(phaseProgressType => phaseProgressType.id == this.column.getFilterPhaseProgress());
                    result(canEnterPhaseProgressTypeId != null);
                });
            })
            .setUseGlobalFilter(true)
            .setDraggable(true)
            .setLimit(ListConfiguration.SmartLimit)
            .setShowCreateNew(true);

        this.projectListConfiguration.showProjectPhase = false;
        this.projectListConfiguration.showProjectPhaseIfHasProgress = true;
    }

    public applyFilter(displayFilter: DisplayFilter, filtersSettings: FiltersInterface) {
        // Clear whatever the filter types had set
        this.projectListConfiguration.setProjectDeadlineTypeIds(null);
        this.projectListConfiguration.setDeadlineBetween(null, null);
        this.projectListConfiguration.setHasNonDeadline(null);
        this.projectListConfiguration.setDeadlineValidator(null);
        this.projectListConfiguration.setArchivedBetween(null, null);
        this.projectListConfiguration.setCurrentPhaseId(null);

        // Apply filter type
        switch (displayFilter?.filter_type) {
            case Filters.FilterAll:
                break;
            case Filters.FilterDeadlineInPeriod:
                this.projectListConfiguration.setDeadlineValidator(((project, deadlines) => {
                    const deadline = project.findProjectsDeadlineByType(displayFilter.project_deadline_type_id);
                    return deadline?.deadline?.isBetween(displayFilter.getPeriodStart(filtersSettings), displayFilter.getPeriodEnd(filtersSettings));
                }));
                break;
            case Filters.FilterNonDeadline:
                this.projectListConfiguration.setDeadlineValidator(((project, deadlines) => {
                    return !project.findProjectsDeadlineByType(displayFilter.project_deadline_type_id);
                }));
                break;
            case Filters.FilterArchivedInPeriod:
                this.projectListConfiguration.setArchivedBetween(displayFilter.getPeriodStart(filtersSettings), displayFilter.getPeriodEnd(filtersSettings));
                break;
            case Filters.FilterPhase:
                if (displayFilter.phase_id) {
                    this.projectListConfiguration.setCurrentPhaseId(displayFilter.phase_id);
                } else if (displayFilter.phase.id) {
                    this.projectListConfiguration.setCurrentPhaseId(displayFilter.phase.id);
                }
        }

        // Default for all filter types
        if (displayFilter.filter_type != Filters.FilterArchivedInPeriod) {
            this.projectListConfiguration.setOrArchivedSince(displayFilter.getIncludeArchivedSinceDate(filtersSettings));
        }
    }

    public applySortItem(sortItem: PageColumnSort, sortDirection: string) {
        // Apply order by and direction
        const orderBy = [];
        switch (Filters.GetBaseSort(sortItem.sortId)) {
            case Filters.SortTitle:
                orderBy.push(['title', sortDirection]);
                break;
            case Filters.SortResponsible:
                orderBy.push(['responsible.user.first_name', 'null ' + sortDirection]);
                orderBy.push(['responsible.user.first_name', sortDirection]);
                break;
            case Filters.SortStatus:
                orderBy.push(['main_status.status_id', (sortDirection == 'asc') ? 'desc' : 'asc']);
                break;
            case Filters.SortStarred:
                orderBy.push(['num_stars', (sortDirection == 'asc') ? 'desc' : 'asc']);
                break;
            case Filters.SortHandUp:
                orderBy.push(['num_hand_ups', (sortDirection == 'asc') ? 'desc' : 'asc']);
                break;
            case Filters.SortCategoryType:
                orderBy.push(['category.name', sortDirection]);
                break;
            case Filters.SortDeadline:
                orderBy.push(['projects_deadline.deadline.date', sortDirection]);
                break;
        }
        this.projectListConfiguration.setOrderBy(orderBy);

        // Apple order filters
        this.projectListConfiguration.clearSortFilters();
        switch (Filters.GetBaseSort(sortItem.sortId)) {
            case Filters.SortDeadline:
                this.projectListConfiguration.setSort_DeadlineValidator(project => {
                    return project.findProjectsDeadlineByType(Filters.ParseSortDeadline(sortItem.sortId))?.exists();
                });
                break;
            case Filters.SortCategoryType:
                this.projectListConfiguration.setSort_CategoryTypeId(parseInt(Filters.ParseSortCategoryType(sortItem.sortId)));
                break;
        }
    }

    public resize(width: number) {
        this.setWidth(Math.max(this.minWidth, width));
    }

    public validateResize(event: ResizeEvent): boolean {
        return !(event.rectangle.width && (event.rectangle.width < this.minWidth));
    }

}
