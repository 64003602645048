import {NgModule} from "@angular/core";
import {
    SortableTextColumnComponent
} from "@app/editor/quick-editor/columns/generic/sortable-text-column/sortable-text-column.component";
import {ColumnSorterModule} from "@app/shared/_ui/column-sorter/ColumnSorter.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ColumnSorterModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        SortableTextColumnComponent,
    ],
    exports: [
        SortableTextColumnComponent

    ],
    providers: [

    ],
})
export class SortableTextColumnModule {}
