import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseOnScreenFilter} from '@app/shared/_ui/lists/BaseOnScreenFilter';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {PlacementArray} from "@app/directives/positioning";

interface ToggleOption {
    label: string;
    enabled: boolean;
    attribute: string;
}


@Component({
  selector: 'app-visibility-toggle',
  templateUrl: './visibility-toggle.component.html',
  styleUrls: ['./visibility-toggle.component.scss']
})
export class VisibilityToggleComponent extends BaseComponent implements OnInit, OnChanges {
    public hasEnabledVisibilityOption = false;
    @Input() container: 'body' | null;
    @Input() placement: PlacementArray =  ['left', 'bottom', 'bottom-left'] ;
    public miniCardOptions: ToggleOption[] = [
        {
            label: '_ui_visibility_status',
            enabled: true,
            attribute: 'card-show-status'

        },
        {
            label: '_ui_visibility_details',
            enabled: true,
            attribute: 'card-show-details'

        },
        {
            label: '_ui_visibility_phase',
            enabled: true,
            attribute: 'card-show-phase'

        },
        {
            label: '_ui_visibility_micro_cards',
            enabled: true,
            attribute: 'card-show-micro_cards'

        },
        {
            label: '_ui_visibility_actions',
            enabled: true,
            attribute: 'card-show-actions'

        },

    ];
    ngOnInit() {
        super.ngOnInit();
        // get options from local storage
        this.getOptionsFromLocalStorage();
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    setOption(option: ToggleOption, enabled: boolean) {
        option.enabled = enabled;

        this.setAttribute(option.attribute, enabled);
        this.saveOptionsInLocalStorage();

    }

    setAttribute(attribute: string, enabled: boolean) {
        const body = document.getElementsByTagName('body')[0];
        if (body) {
            body.setAttribute(attribute, enabled.toString());
        }
        this.checkIfAllOptionsAreEnabled();
    }

    private checkIfAllOptionsAreEnabled() {
        this.hasEnabledVisibilityOption =
            this.miniCardOptions.filter((option) => option.enabled === true).length !== this.miniCardOptions.length;

    }
    private saveOptionsInLocalStorage() {
        localStorage.setItem('miniCardOptions', JSON.stringify(this.miniCardOptions));
    }
    private getOptionsFromLocalStorage() {
        const miniCardOptions = localStorage.getItem('miniCardOptions');
        if (miniCardOptions) {
            this.miniCardOptions = JSON.parse(miniCardOptions);
            this.miniCardOptions.forEach((option) => {
                this.setAttribute(option.attribute, option.enabled);
            });

        }
    }

}
