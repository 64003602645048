<ng-template #rt let-r="result" let-t="term" class="w-100"
             [typedTemplate]="resultTemplate"
>
    <div class="d-flex flex-row align-items-center" style="min-width: 300px;" *ngIf="r.title">
        <app-color-label [status]="r.status" class="circle m-0 me-1"></app-color-label>
        <ngb-highlight [result]="r.title" [term]="t"></ngb-highlight>
        <ng-container *ngIf="r.responsible && r.responsible.user">
            <app-user-with-icon class="text-small" [model]="r.responsible.user"
                                [label]="('_display_program_responsible' | translate)"></app-user-with-icon>
        </ng-container>
    </div>
</ng-template>

<div class="form-group mb-0">
    <div class="d-flex align-items-center">
        <div class="me-1 btn "
             *ngIf="showFilterButton"
             [class.btn-outline-secondary]="departments.length == 0"
             [class.btn-outline-warning]="departments.length > 0"
             [ngbTooltip]="('_ui_filter' | translate)"
             (click)="popover.open()">
            <span class="fal fa-filter cursor-pointer position-relative"
                  [ngbPopover]="popoverContent"
                  [autoClose]="'outside'"
                  placement="bottom left auto"
                  triggers="manual"
                  container="body"
                  #popover="ngbPopover"></span>
            <ng-template #popoverContent>
                <app-department-picker [showAll]="true"
                                       [selectedDepartments]="departments"
                                       [multiple]="true"
                                       [showDepartmentGroups]="true"
                                       (onChangeEvent)="filterByDepartment($event)"></app-department-picker>
            </ng-template>
        </div>

        <div class="search-wrap d-flex  flex-grow-1">
            <input type="search" class="form-control placeholder-sm"
                   autocomplete="off"
                   #instance="ngbTypeahead"
                   container="body"
                   [autofocus]="false"
                   [autocomplete]="'off'"
                   [class.is-invalid]="searchFailed"
                   popupClass="result-list"
                   [(ngModel)]="model"
                   [ngbTypeahead]="search"
                   [resultTemplate]="rt"
                   [inputFormatter]="formatter"
                   (focus)="focus$.next($any($event.target).value)"
                   (click)="click$.next($any($event.target).value)"
                   (selectItem)="triggerSelection($event, instance)"
                   [placeholder]="placeholder ? placeholder : (('_ui_search_placeholder' | translate: {type: (type.modelType.name | translate | lowercase)}))"/>
            <span class="searching" *ngIf="searching" [@fadeAnimation]="'in'"><span
                >{{'_ui_searching' | translate}}</span>...</span>
            <span class="searching" *ngIf="result && result.length == 0" [@fadeAnimation]="'in'"><span >{{'_ui_search_no_results' | translate}}</span>...</span>
        </div>
        <div class="invalid-feedback" *ngIf="searchFailed" ><span >{{'_ui_search_no_results' | translate}}</span>.</div>
    </div>
</div>

<!--<pre>Model: {{ model | json }}</pre>-->
