<div
    *ngIf="configuration.variant == 'compact'"
    class="position-relative w-100 h-100"
>
    <div class="position-absolute w-100 h-100" style="max-width: 100vw;">

        <ul class="list-unstyled d-flex justify-content-start align-items-center rounded pb-1 ps-2 pe-2 -corner-item left text-small action-panel"
            [class.no-hover]="!value"
            *ngIf="!isEditMode"
        >
            <li [ngbTooltip]="value ? ('_global_edit' | translate) : null" *ngIf="model">
                <a class="text-center me-1 ms-1"

                   *ngIf="!isEditMode" (click)="isEditMode = true">
                    <i class="fal " aria-hidden="true"
                       [class.fa-edit]="value"
                       [class.fa-plus-circle]="!value"
                    ></i></a>
            </li>

            <li [ngbTooltip]="(isExpanded ? ('_ui_show_less' | translate) : ('_ui_expand_lists' | translate)) + '...'"
                *ngIf="value"
                (click)="toggleExpanded()"
            >
                <a class="text-center me-1 ms-1">
                    <i class="fa-duotone"
                       [class.fa-circle-chevron-up]="isExpanded"
                       [class.fa-circle-chevron-down]="!isExpanded"
                       aria-hidden="true"></i>
                </a>
            </li>
        </ul>

        <input
            [(ngModel)]="value"
            placeholder=""
            class="form-control"
            name="title"
            autocomplete="on"
            (blur)="save();"
            *ngIf="isEditMode"
            [autofocusAfterInit]="true"
        >

        <app-url-link
            *ngIf="!isEditMode"
            class="d-block ellipsis w-100 position-relative"
            [link]="value"
            [title]="true"
            [icon]="true"
            [expanded]="isExpanded"
        ></app-url-link>
    </div>
</div>

<div
    *ngIf="configuration.variant == 'standard'"
>
    <label *ngIf="configuration.label">
        <span>{{ configuration.label | translate}}</span>
    </label>

    <ul class="list-group">

        <li class="list-group-item" >

            <input
                [(ngModel)]="value"
                placeholder=""
                class="form-control"
                [placeholder]="('_ui_add_link' | translate) +'...'"
                (blur)="save()"
            />
            <app-url-link
                [link]="value"
                [title]="true"
                [icon]="true"
            />
        </li>
    </ul>

</div>
