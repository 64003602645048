import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {PANEL_ANIMATION} from '@app/editor/panel.animation';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {Department, User} from '@app/core/models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-missing-departments-dialog',
  templateUrl: './missing-departments-dialog.component.html',
  styleUrls: ['./missing-departments-dialog.component.scss'],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation,
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0.5
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissingDepartmentsDialogComponent extends BaseModalComponent implements OnInit {

    @Input() title: string;

    @Input() departments: Department[] = [];
    @Input() user: User;

    constructor(private modal: NgbActiveModal,
                private cd: ChangeDetectorRef) {
        super(modal);
        this.cdr = cd;
    }
    ngOnInit() {
        super.ngOnInit();
        this.isOpen = true;
    }



    public decline() {
        // this.model?.delete();
        this.activeModal.close(false);
    }

    public accept() {
        this.activeModal.close({ departments: this.departments });
    }

    public dismiss() {
        this.activeModal.dismiss();
        this.activeModal.close(false);
    }

    setDepartments($event: Department[]) {
        if($event) {
            this.departments = $event;
        }
        this.detectChanges();
    }

}
