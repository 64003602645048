import {NgModule} from "@angular/core";
import {
    DisplayKanbanProgressComponent
} from "@app/pages/displays/display-kanban-progress/display-kanban-progress.component";
import {ResizableModule} from "angular-resizable-element";
import {ColumnSorterModule} from "@app/shared/_ui/column-sorter/ColumnSorter.module";
import {OnScreenFilterSelectorModule} from "@app/shared/_ui/on-screen-filter-selector/OnScreenFilterSelector.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ProjectListModule} from "@app/shared/_ui/lists/project-list/ProjectList.module";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ResizableModule,
        ColumnSorterModule,
        OnScreenFilterSelectorModule,
        NgbTooltipModule,
        TranslateModule,
        ProjectListModule,
        LoadingIndicatorModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DisplayKanbanProgressComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class DisplayKanbanProgressModule {}
