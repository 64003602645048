import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {TableColumns} from "@app/pages/displays/display-projects/TableColumns";
import {
    TaskListWithoutMilestoneColumn
} from "@app/pages/displays/display-projects/Columns/TaskListWithoutMilestoneColumn";

export class TaskListWithoutMilestoneTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TaskListWithoutMilestone;

    public column: TaskListWithoutMilestoneColumn;

}
