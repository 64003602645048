import Globals from "@app/constants";
import {Appointment, Milestone, Project, Task, Todo} from "@app/core/models";
import {AnyItem} from "@app/interfaces/CustomTypes";

export const FocusableEntityTypes = {
    Milestone: 'milestone',
    Project: 'project',
    Task: 'task',
    Todo: 'todo',
    Appointment: 'appointment',
}

export type FocusableEntityType =
    typeof FocusableEntityTypes.Milestone
    | typeof FocusableEntityTypes.Project
    | typeof FocusableEntityTypes.Task
    | typeof FocusableEntityTypes.Todo
;

export class FocusableEntityTypesHelper {

    public static GetEntityType(anyItem: AnyItem): FocusableEntityType {
        switch (anyItem.constructor.name) {
            case Globals.ItemTypes.CaseUser:
            case Globals.ItemTypes.Roster:
            case Task.name:
                return FocusableEntityTypes.Task;
            case Project.name:
                return FocusableEntityTypes.Project;
            case Todo.name:
                return FocusableEntityTypes.Todo;
            case Milestone.name:
                return FocusableEntityTypes.Milestone;
            case Globals.ItemTypes.AppointmentForm:
            case Appointment.name:
                return FocusableEntityTypes.Appointment;
        }
    }

}
