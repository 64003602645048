import {NgModule} from "@angular/core";
import {AttachedTypesComponent} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-tabs/project-editor-tab-attach-to/attached-types/attached-types.component";
import {ProjectSelectSearchModule} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-tabs/project-editor-tab-attach-to/attached-types/project-select-search/ProjectSelectSearch.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ProjectSelectSearchModule,
        CardsModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        AttachedTypesComponent,
    ],
    exports: [
        AttachedTypesComponent

    ],
    providers: [

    ],
})
export class AttachedTypesModule {}
