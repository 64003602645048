import {TemplateRef} from "@angular/core";
import {Column, ColumnSetting} from "@app/core/models";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";

export abstract class BaseColumnType {

    public abstract identifier: string;

    public abstract isAlwaysVisible: boolean;

    public abstract customWidth?: number;
    public abstract minWidth: number;
    public abstract maxWidth?: number;
    public abstract resizeable: boolean;
    public abstract frozenLeft: boolean;
    public abstract canAutoResize: boolean;

    public abstract isList: boolean;
    public abstract cellClass?: string;

    public cellTemplate?: TemplateRef<any>;
    public headerTemplate?: TemplateRef<any>;

    public onScreenFilters?: BaseOnScreenFilter[];

    public abstract createColumn(column: Column, settings: Map<string, ColumnSetting>): BaseColumn;

}
