import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {GenericColumn} from "@app/pages/displays/display-team/Columns/GenericColumn";
import {DisplayTeamRow} from "@app/pages/displays/display-team/DisplayTeamRow";
import {MilestoneFetcher, MilestoneFetchRequest} from "@app/shared/_ui/lists/milestone-list/MilestoneFetcher";
import {MilestoneListCell} from "@app/pages/displays/display-team/Cells/MilestoneListCell";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {MilestoneListTableColumn} from "@app/pages/displays/display-team/TableColumns/MilestoneListTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-team/Helpers/ColumnTypeSettings";
import {
    Team_MilestoneListProjectTypesSettingValue,
} from "@app/core/http/Api/Api";

export class MilestoneListColumn extends GenericColumn<MilestoneListCell> implements ColumnDataFetcherInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: MilestoneFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new MilestoneFetcher(column.name);
    }

    public createTableColumns(): MilestoneListTableColumn[] {
        const item = new MilestoneListTableColumn(this);
        this.setTableColumnDefaults(item);
        return [item];
    }

    public createCell(row: DisplayTeamRow): MilestoneListCell {
        const cell = new MilestoneListCell(row, this);

        cell.milestoneListConfiguration
            .setProjectTypeIds([null, ...this.getProjectTypeIds()])

        this.dataFetcher.addRequest(new MilestoneFetchRequest(cell.milestoneListConfiguration));
        return cell;
    }

    public getProjectTypeIds(): number[] {
        return this.settings.get(ColumnTypeSettings.MilestoneList_ProjectTypes)
            ?.getObject<Team_MilestoneListProjectTypesSettingValue>()
            ?.projectTypeIds ?? [];
    }

}
