/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {DisplaysRelateddisplayDefinition,} from './definitions/DisplaysRelateddisplayDefinition';

export class DisplaysRelateddisplay extends DisplaysRelateddisplayDefinition {

    constructor(json?: any) {
        super(json);
    }

}
