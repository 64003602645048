<div class="d-flex flex-row justify-content-start flex-wrap" *ngIf="project">

    <div class="border-bottom w-100 mb-2 pb-2 " style="position: sticky;" *ngIf="showAppender">
        <app-project-phase-search
            [project]="project"
            [autoSave]="true"
            [showPhases]="false"
            [allowCreation]="false"
        ></app-project-phase-search>
    </div>

    <ng-container *ngIf="phaseMilestoneRows && phaseMilestoneRows.length == 0">
        <div class="text-center text-black-50 w-100 d-flex align-items-center justify-content-center text-small"
             style="opacity: .5;">
            <span class="fal fa-moon me-1"></span>
            <span>{{'_ui_project_has_no_phases' | translate}}</span></div>
    </ng-container>


    <ng-container *ngFor="let phaseMilestoneRow of phaseMilestoneRows">
        <app-project-phase
            [phaseMilestoneRow]="phaseMilestoneRow"
            [project]="project"
            (selectPhasesProject)="setProjectPhasesProject($event)"
            (selectPhasesProjectProgressType)="setPhasesProjectProgressType($event.phaseProgressType, $event.phasesProject)"
            (toggleTaskArchived)="toggleTaskArchived($event.task, $event.cardMilestone, $event.phaseMilestoneRow)"
            (toggleAllTasksArchived)="toggleAllTasksArchived($event.cardMilestone, $event.phaseMilestoneRow)"
        ></app-project-phase>
    </ng-container>
</div>


<div class="d-flex align-items-center justify-content-center" *ngIf="!project">
    <app-loading-indicator [showLogo]="false"></app-loading-indicator>
</div>

