import {PageDisplaySetting} from "@app/pages/PageDisplaySetting";

export class Settings {
    public static ProjectTypeIds        = 328;
    public static PhaseIds              = 330;

    public static GetProjectTypeIds(settingsMap: Map<number, PageDisplaySetting>): number[] {
        return settingsMap.get(Settings.ProjectTypeIds)?.getIntValues() ?? [];
    }

    public static GetPhaseIds(settingsMap: Map<number, PageDisplaySetting>): number[] {
        return settingsMap.get(Settings.PhaseIds)?.getIntValues() ?? [];
    }
}
