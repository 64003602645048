<app-card-preview [mini]="mini" *ngIf="!initialized && visible"></app-card-preview>

<div #cardContainer
     class="card p-1 gap-1" (click)="onCardClick($event)"
     *ngIf="model && initialized && visible && model.project_type"
     [class.grayed-out]="configuration && configuration.isGrayedOut"
     [class.mini]="mini"
     [class.mb-0]="mini"
     [class.focused]="outline"
>
    <div class="d-flex w-100 font-size-lg align-items-center justify-content-center ellipsis">


        <app-color-label class="card-type me-1"
                         *ngIf="isFieldVisible(Fields.Status)"
                         [itemWithTypeProperties]="model"
                         [status]="model.status"
                         (onStatusChange)="onStatusChange($event)"></app-color-label>

        <div class="title p-0 font-weight-bold col -border-bottom -border-light flex-fill me-1"
             *ngIf="isFieldVisible(Fields.Title)">
            <input class="form-control" [(ngModel)]="model.title" *ngIf="editable"/>
            <div *ngIf="!editable" class="pt-0 h-100 text-capitalize-firstletter"
                 [ngbTooltip]="model.title | placeholder: ('_ui_no_title' | translate)"
            >
                {{model.title | placeholder: ('_ui_no_title' | translate)}}
            </div>
        </div>



        <a class="text-danger -remove d-flex flex-row align-items-center ms-1 me-1 font-size-md"
           (click)="triggerRemove(model);$event.stopPropagation()"
           [ngbTooltip]="('_ui_emoji_clear' | translate)"
           *ngIf="removable">
            <i class="fal fa-eraser"></i>
        </a>





        <div class="pe-1" *ngIf="!(!editable && !mini && configuration && !simple) && model.reference">
            <app-url-link [icon]="true" [title]="false" [maxLinks]="1" [link]="model.reference"></app-url-link>
        </div>


        <ng-container
            *ngIf="showNavigationPlanning && configuration.showGoToPlanningDisplay && planningDisplays?.length && isFieldVisible(Fields.GoToPlanning) && mini">

            <ng-container *ngIf="mini">
                    <span
                        [ngbPopover]="processStepsPopover"
                        [autoClose]="false"
                        placement="auto"
                        [popoverClass]="'popover-with-dialog'"
                        triggers="manual"
                        container="body"
                        class="d-flex align-items-center"
                        #popoverProcess="ngbPopover"
                    >

                        <span
                            class="ms-auto cursor-pointer"
                            [ngbTooltip]="('_phases' | translate)"
                            (click)="$event.stopPropagation(); popoverProcess.isOpen() ? popoverProcess.close() : popoverProcess.open();"><i
                            class="fal fa-moon"></i></span>

                        <ng-template #processStepsPopover>
                            <span class="close-button" (click)="popoverProcess.close()"><span
                                class="fa fa-times"></span></span>

                            <label class="d-flex card-project mt-1">
                                <span >{{'_ui_process_steps_phase_title' | translate}}</span>
                            </label>
                            <div class="-border-top w-100 mb-2 pb-2 " style="position: sticky;">
                                <app-project-phase-search
                                    [project]="model"
                                    [autoSave]="true"
                                    [showPhases]="false"
                                    [allowCreation]="true"
                                ></app-project-phase-search>
                            </div>

                            <label class="d-flex card-project">
                                <span >{{'_ui_process_steps_process_step_title' | translate}}</span>
                            </label>
                            <div class="popover-content-wrapper scroll-y border-top">
                                <app-process-steps [projectId]="model.id"
                                                   [showAppender]="false"
                                ></app-process-steps>
                            </div>
                        </ng-template>
                    </span>
            </ng-container>

            <app-toggle-item class="cursor-pointer d-mobile-none"
                             [interactive]="false"
                             [active]="true"
                             [icon]="'fa-door-open'"
                             *ngIf="planningDisplays.length == 1"
                             [ngbTooltip]="translateService.instant('_ui_navigate_to')+ ' ' +planningDisplays[0].name"
                             iconWeightActive="fal"
                             (click)="openDoor($event, planningDisplays[0])"></app-toggle-item>
            <div ngbDropdown
                 placement="bottom bottom-left left-bottom right-bottom top-left auto"
                 container="body" *ngIf="planningDisplays.length > 1"
                 class="d-mobile-none"

            >
                <app-toggle-item class="cursor-pointer no-caret"
                                 ngbDropdownToggle
                                 container="body"
                                 placement="bottom"
                                 id="select-planning-display"
                                 [interactive]="false"
                                 placement="bottom left auto"
                                 [active]="true"
                                 [icon]="'fa-door-open'"
                                 (click)="$event.stopImmediatePropagation()"
                                 [ngbTooltip]="planningDisplayName"
                                 iconWeightActive="fal"></app-toggle-item>
                <div
                    ngbDropdownMenu
                    aria-labelledby="select-planning-display"

                    class="-position-absolute ">
                    <ng-container *ngFor="let pd of planningDisplays">
                        <app-dropdown-list-item (click)="openDoor($event, pd)"
                                                [iconClass]="'fa-door-open'"
                                                [label]="translateService.instant('_ui_navigate_to')+ ' ' +pd.name"></app-dropdown-list-item>
                    </ng-container>
                </div>
            </div>
        </ng-container>

        <!-- Micro card icons -->
        <ng-container *ngIf="mini">
            <ng-container *ngIf="programDisplays && programDisplays.length && isFieldVisible(Fields.GoToProgram)">

                <app-toggle-item class="cursor-pointer"
                                 [interactive]="false"
                                 [active]="true"
                                 [icon]="'fa-presentation'"
                                 *ngIf="programDisplays.length == 1"
                                 [ngbTooltip]="programDisplaysName"
                                 iconWeightActive="fal"
                                 (click)="openDisplay($event, programDisplays[0])"></app-toggle-item>
                <div ngbDropdown
                     placement="bottom top left auto"
                     container="body" *ngIf="programDisplays.length > 1">
                    <app-toggle-item class="cursor-pointer no-caret"
                                     ngbDropdownToggle
                                     container="body"
                                     placement="bottom"
                                     id="select-display"
                                     [interactive]="false"
                                     [active]="true"
                                     [icon]="'fa-presentation'"
                                     (click)="$event.stopImmediatePropagation()"
                                     [ngbTooltip]="programDisplaysName"
                                     iconWeightActive="fal"></app-toggle-item>
                    <div
                        ngbDropdownMenu
                        aria-labelledby="select-display"
                        class="-position-absolute ">
                        <ng-container *ngFor="let pd of programDisplays">
                            <app-dropdown-list-item (click)="openDisplay($event, pd)"
                                                    [iconClass]="'fa-presentation'"
                                                    [label]="translateService.instant('_ui_navigate_to')+ ' ' +pd.name"></app-dropdown-list-item>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

        </ng-container>

    </div>
    <div class="d-flex justify-content-between font-size-md flex-wrap align-items-center pb-0 -mt-1 card-details"
         *ngIf="!editable && !mini && configuration && !simple && (!configuration || configuration.showActions)">

        <ng-template #popoverContent>
            <div class="scale-2x-wrapper">
                <div class="card-wrap">
                    <app-card-project *ngIf="item" [item]="item"></app-card-project>
                </div>
            </div>
        </ng-template>

        <div class="d-flex flex-row w-100 justify-content-start align-items-center scroll-x"
             *ngIf="(!configuration || configuration.showActions)"
        >
            <ul class="flex-grow-1 d-flex list-unstyled justify-content-start align-items-center m-0 flex-wrap"
                [class.disabled]="model.id == 0"
            >



                <!-- https://podio.com/klartboard/softwareudvikling/apps/stories/items/1169 -->
                <li
                    class="pe-1"
                    #toolsPopover="ngbPopover"
                    [ngbPopover]="toolsDropdownContent"
                    [autoClose]="false"
                    placement="bottom left auto"
                    [popoverClass]="'popover-tools'"
                    (clickOutside)="onToolsPopoverClickOutsideEvent()"
                    [clickOutsideEnabled]="toolsPopover.isOpen()"
                    triggers="manual"
                    container="body"
                    (click)="$event.stopImmediatePropagation(); toggleToolsMenu()"
                >
                    <i class="fal fa-toolbox cursor-pointer" [ngbTooltip]="('_ui_navigation_tools' | translate)"></i>

                    <ng-template #toolsDropdownContent>


                        <h5 class="d-none d-mobile-flex border-bottom pb-3 pt-1">{{('_ui_navigation_tools' | translate)}}</h5>
                        <span class="close-button d-none d-mobile-flex" (click)="toolsPopover.close()"><span class="fa fa-times"></span></span>

                        <ul class="d-flex list-unstyled justify-content-start align-items-center m-0 flex-wrap"
                            [class.disabled]="model.id == 0"
                        >

                            <!-- venstre side -->
                            <li class="pe-1" *ngIf="isFieldVisible(Fields.Archived) && item?.item">
                                <app-toggle-item
                                    [interactive]="true"
                                    [active]="item.item.completed"
                                    [class.text-black-50]="!item.item.completed"
                                    [ngbTooltip]="!item.item.completed ? translateService.instant('_ui_complete') : translateService.instant('_ui_reopen')"
                                    [icon]="'fa-check-circle'"
                                    [iconInactive]="'fa-circle'"
                                    (onToggle)="onArchivedToggle($event); closeToolsMenu();"></app-toggle-item>
                            </li>
                            <li class="pe-1" *ngIf="isFieldVisible(Fields.HandUp)">
                                <app-hand-up-selector [model]="model"
                                                      [showUsers]="false"
                                                      (onToggle)=" closeToolsMenu()"
                                ></app-hand-up-selector>
                            </li>
                            <li class="pe-1" *ngIf="isFieldVisible(Fields.Star)">
                                <app-star-selector [model]="model"
                                                   [showUsers]="false"
                                                   (onToggle)="closeToolsMenu()"
                                ></app-star-selector>
                            </li>
                            <li *ngIf="item" class="pe-1">
                                <app-reactions [reactionsApiInterface]="item.item"
                                               [reactionsSourceInterface]="item.item"
                                               [reactionsTypeInterfaces]="reactionsTypeInterfaces"
                                               [reactions]="item?.item?.reactions"
                                               [reactionsEditable]="true"
                                               [reactionsVisible]="false"
                                               (click)="$event.stopImmediatePropagation()"
                                               (onToggle)="closeToolsMenu()"
                                ></app-reactions>
                            </li>

                            <li *ngIf=currentPhasesProject class="pe-1 d-mobile-none">
                                <div
                                    *ngIf=currentPhasesProject
                                    [ngbPopover]="processStepsPopover"
                                    [autoClose]="false"
                                    placement="auto"
                                    [popoverClass]="'popover-with-dialog'"
                                    triggers="manual"
                                    container="body"
                                    class="d-flex align-items-center"
                                    #popoverProcess="ngbPopover"
                                >

                        <span
                            class="ms-auto cursor-pointer"
                            [ngbTooltip]="('_phases' | translate)"
                            (click)="$event.stopPropagation(); popoverProcess.isOpen() ? popoverProcess.close() : popoverProcess.open();"><i
                            class="fal fa-moon"></i></span>

                                    <ng-template #processStepsPopover>
                            <span class="close-button" (click)="popoverProcess.close()"><span
                                class="fa fa-times"></span></span>

                                        <label class="d-flex card-project mt-1">
                                            <span >{{'_ui_process_steps_phase_title' | translate}}</span>
                                        </label>
                                        <div class="-border-top w-100 mb-2 pb-2 " style="position: sticky;">
                                            <app-project-phase-search
                                                [project]="model"
                                                [autoSave]="true"
                                                [showPhases]="false"
                                                [allowCreation]="true"
                                            ></app-project-phase-search>
                                        </div>

                                        <label class="d-flex card-project">
                                            <span >{{'_ui_process_steps_process_step_title' | translate}}</span>
                                        </label>
                                        <div class="popover-content-wrapper scroll-y border-top">
                                            <app-process-steps [projectId]="model.id"
                                                               [showAppender]="false"
                                            ></app-process-steps>
                                        </div>
                                    </ng-template>
                                </div>
                            </li>

                            <li
                                class="pe-1 d-mobile-none"
                                *ngIf="isFieldVisible(Fields.YearWheel) && yearWheelConfiguration"
                                #previewPopover="ngbPopover"
                                [ngbPopover]="previewDropdownContent"
                                [autoClose]="false"
                                [popoverClass]="'popover-with-dialog'"
                                placement="bottom bottom-left left-bottom right-bottom top-left auto"
                                triggers="manual"
                                container="body"
                            >
                                <i class="fal fa-timeline-arrow cursor-pointer"
                                   (click)="$event.stopImmediatePropagation(); previewPopover.open(); previewOpen = true;"
                                   [ngbTooltip]="('_ui_year_wheel' | translate)"></i>

                                <ng-template #previewDropdownContent>
                                    <span class="close-button" (click)="$event.stopImmediatePropagation(); this.previewPopover.close(); previewOpen = false; lockedMenu = false; this.toolsPopover.close()"><span
                                        class="fa fa-times"></span></span>
                                    <div style="width: 40vw; height: 120px;">
                                        <app-year-wheel
                                            (clickOutside)="lockedMenu = false"
                                            [clickOutsideEnabled]="lockedMenu"
                                            (click)="lockedMenu = true; $event.stopImmediatePropagation(); $event.stopPropagation();"

                                            [configuration]="yearWheelConfiguration">
                                        </app-year-wheel>
                                    </div>

                                </ng-template>

                            </li>


                            <li class="pe-1 d-mobile-none"
                                [ngbPopover]="popoverContent"
                                placement="bottom bottom-left left-bottom right-bottom top-left auto"
                                popoverClass="popover-transparent"
                                container="body"
                                [autoClose]="true"
                                triggers="mouseenter:mouseleave"
                            >
                                <a class="cursor-pointer">
                                    <i class="fal fa-search-plus "></i>
                                </a>
                            </li>

                            <li class="pe-1"
                                [ngbTooltip]="translateService.instant('_ui_copy') + '...'"
                                (click)="copyProjectDialog();$event.stopPropagation();closeToolsMenu();">
                                <a class="-text-danger remove d-flex flex-row align-items-center cursor-pointer"
                                >
                                    <i class="fal fa-copy"></i>
                                </a>
                            </li>
                            <li class="pe-1">
                                <app-milestone-plan-selector
                                    (clickOutside)="lockedMenu = false"
                                    [clickOutsideEnabled]="lockedMenu"
                                    (click)="lockedMenu = true; $event.stopImmediatePropagation(); $event.stopPropagation();"
                                    [project]="item.item"
                                    (onMilestonePlanAppliedEvent)="onMilestonePlanAppliedEvent($event)"
                                ></app-milestone-plan-selector>
                            </li>

                            <li *ngIf="configuration && configuration.showChangeType">
                                <a class="d-flex flex-row align-items-center pe-1"
                                   [ngbTooltip]="translateService.instant('_ui_change_type')"
                                   (click)="triggerChangeDialog(model); $event.stopImmediatePropagation();">
                                    <i class="fal fa-repeat"></i>
                                </a>
                            </li>

                            <li  class="pe-1"
                                (click)="delete(model);$event.stopPropagation();closeToolsMenu()">
                                <a class="-text-danger remove d-flex flex-row align-items-center cursor-pointer text-danger"
                                >
                                    <i class="fal fa-trash-alt"></i>
                                </a>
                            </li>

                        </ul>
                    </ng-template>
                </li>

                <!-- https://podio.com/klartboard/softwareudvikling/apps/stories/items/1169 -->


                <!-- https://podio.com/klartboard/softwareudvikling/apps/supports/items/460 -->

                <li class="pe-1" *ngIf="isFieldVisible(Fields.Archived) && item?.item?.completed">
                    <app-toggle-item
                        [interactive]="true"
                        [active]="item.item.completed"
                        [class.text-black-50]="!item.item.completed"
                        [tooltipLabel]="item.item.completed ? translateService.instant('_ui_complete') : translateService.instant('_ui_reopen')"
                        [icon]="'fa-check-circle'"
                        [iconInactive]="'fa-circle'"
                        (onToggle)="onArchivedToggle($event)"></app-toggle-item>
                </li>
                <li class="pe-1" *ngIf="isFieldVisible(Fields.HandUp) && model.num_hand_ups > 0">
                    <app-hand-up-selector [model]="model"></app-hand-up-selector>
                </li>
                <li class="pe-1" *ngIf="isFieldVisible(Fields.Star)  && model.num_stars > 0">
                    <app-star-selector [model]="model"></app-star-selector>
                </li>


                <!-- estimat -->
                <li class="pe-1 d-flex align-items-center" *ngIf="projectEstimate && isFieldVisible(Fields.Estimate) && projectEstimate?.estimate"
                    [ngbTooltip]="projectEstimate?.estimate?.value + ' ' + (projectEstimate.estimate.estimate_unit?.language_key | translate)">
                    <i class="fal fa-clock me-1"></i><span>{{projectEstimate.estimate.value}}</span>
                </li>

                <li *ngIf="configuration && configuration.showChangeType && false">
                    <a class="d-flex flex-row align-items-center pe-1"
                       [ngbTooltip]="translateService.instant('_ui_change_type')"
                       (click)="triggerChangeDialog(model); $event.stopImmediatePropagation();">
                        <i class="fal fa-repeat"></i>
                    </a>
                </li>

                <ng-container *ngIf="isFieldVisible(Fields.CalculatedFields) && item?.item">
                    <li *ngFor="let calculatedFieldsProject of model.calculated_fields_projects" class="m-0 me-1">
                        <app-project-calculated-field
                            [project]="item.item"
                            [calculatedField]="calculatedFieldsProject.calculated_field"
                            [calculatedFieldsProject]="calculatedFieldsProject"
                        ></app-project-calculated-field>
                    </li>
                </ng-container>

                <!-- højre side / responsible / ansvarlig-->

                <li class="relation m-0 me-1 user-list d-flex align-items-center "
                    *ngIf="projectUser && !mini && projectUser.user && isFieldVisible(Fields.Who)"
                >
                    <app-users-with-icon
                        class="w-100"
                        [initials]="!isMobile"
                        [icon]="true"
                        [userType]="projectUserType"
                        [users]="projectUserUsersUserlist"></app-users-with-icon>

                </li>

                <li class="d-flex align-items-center flex-wrap pt-1"
                    *ngIf="item"
                    [class.d-none]="!hasVisibleReactions"
                    [class.d-flex]="hasVisibleReactions"
                >
                    <app-reactions [reactionsApiInterface]="item.item"
                                   [reactionsSourceInterface]="item.item"
                                   [reactionsTypeInterfaces]="reactionsTypeInterfaces"
                                   [reactions]="item?.item?.reactions"
                                   (onDataSetChanged)="hasVisibleReactions = $event.length > 0;"
                                   [reactionsPickerVisible]="false"
                                   (click)="$event.stopImmediatePropagation()"></app-reactions>

                </li>
            </ul>
            <ul class="flex-grow-0 d-flex list-unstyled justify-content-end align-items-center m-0 flex-wrap "
            >
                <!-- dør -->

                <li style="transform: translateY(1px);" class="pe-1"
                    *ngIf="showNavigationPlanning && programDisplays && programDisplays.length && isFieldVisible(Fields.GoToProgram)">
                    <div ngbDropdown container="body">
                        <!-- programDisplays -->
                        <i class="fal fa-presentation cursor-pointer"
                           *ngIf="programDisplays.length == 1"
                           [ngbTooltip]="programDisplaysName + ': '+programDisplays[0].name"
                           (click)="openDisplay($event, programDisplays[0])"
                        ></i>

                        <i class="fal fa-presentation cursor-pointer no-caret"
                           [ngbTooltip]="programDisplaysName"
                           *ngIf="programDisplays.length > 1"
                           ngbDropdownToggle
                           container="body"
                           placement="auto"
                           id="select-display-main"
                           (click)="$event.stopImmediatePropagation()"
                        ></i>


                        <div
                            ngbDropdownMenu
                            aria-labelledby="select-display-main"
                            class="position-absolute ">

                            <ng-container *ngFor="let pd of programDisplays">
                                <app-dropdown-list-item (click)="openDisplay($event, pd)"
                                                        [iconClass]="'fa-presentation'"
                                                        [label]="translateService.instant('_ui_navigate_to')+ ' ' +pd.name"></app-dropdown-list-item>
                            </ng-container>
                        </div>
                    </div>
                </li>

                <li class="pe-1" *ngIf="model && model.reference">
                    <app-url-link [icon]="true" [title]="false" [maxLinks]="1" [link]="model.reference"></app-url-link>
                </li>


                <li class="clearfix pe-1"
                    *ngIf="showNavigationPlanning && configuration.showGoToPlanningDisplay && planningDisplays?.length && isFieldVisible(Fields.GoToPlanning)">

                    <app-toggle-item class="cursor-pointer"
                                     [interactive]="false"
                                     [active]="true"
                                     [icon]="'fa-door-open'"
                                     *ngIf="planningDisplays.length == 1"
                                     [ngbTooltip]="translateService.instant('_ui_navigate_to')+ ' ' +planningDisplays[0].name"
                                     iconWeightActive="fal"
                                     (click)="openDoor($event, planningDisplays[0])"></app-toggle-item>
                    <div ngbDropdown
                         class="planning-displays-dropdown"
                         placement="bottom top-left top-right bottom-left left-bottom right-bottom auto"
                         container="body" *ngIf="planningDisplays.length > 1">
                        <app-toggle-item class="cursor-pointer no-caret"
                                         ngbDropdownToggle
                                         container="body"
                                         id="select-planning-display2"
                                         placement="bottom top-left top-right bottom-left left-bottom right-bottom auto"
                                         [interactive]="false"
                                         [active]="true"
                                         [icon]="'fa-door-open'"
                                         (click)="$event.stopImmediatePropagation()"
                                         [ngbTooltip]="planningDisplayName"
                                         iconWeightActive="fal"></app-toggle-item>
                        <div
                            ngbDropdownMenu
                            aria-labelledby="select-planning-display2"
                            class="-position-absolute ">
                            <ng-container *ngFor="let pd of planningDisplays">
                                <app-dropdown-list-item (click)="openDoor($event, pd)"
                                                        [iconClass]="'fa-door-open'"
                                                        [label]="translateService.instant('_ui_navigate_to')+ ' ' +pd.name"></app-dropdown-list-item>
                            </ng-container>
                        </div>
                    </div>
                </li>
                <li class="ms-auto -w-100 text-end ellipsis"
                    *ngIf="projectDeadline && projectDeadline.deadline && !mini && isFieldVisible(Fields.Deadline)">
                    <app-item-date
                        [showIcon]="!isMobile"
                        [label]="projectDeadlineName"
                        [date]="projectDeadlineDate"></app-item-date>
                </li>

            </ul>
        </div>


        <!-- Kategori + Fase -->
        <ul class="d-flex list-unstyled justify-content-start align-items-center w-100 m-0 border-top phase flex-wrap text-small gap-1"
            [class.pt-1]="isMobile"
            [class.mb-1]="isMobile"
            *ngIf="item && (currentPhasesProject || visibleCategories?.length > 0)">

            <li *ngIf=currentPhasesProject
                [class.d-none]="isPhasesProjectEmpty"
                [class.d-flex]="!isPhasesProjectEmpty"
                [class.border-bottom]="visibleCategories && visibleCategories.length > 0"
                [class.pb-1]="visibleCategories && visibleCategories.length > 0"
                [class.pt-1]="visibleCategories && visibleCategories.length > 0"
                class="w-100">
                <div *ngIf=currentPhasesProject
                     [class.d-none]="isPhasesProjectEmpty"
                     [class.d-flex]="!isPhasesProjectEmpty"
                     class="w-100"
                     [ngbTooltip]="(editorFields?.get(EditorFields.BasicInformation.Phase)?.smartName | translate)">
                    <app-current-phases-project
                        [project]="item.item"
                        (onIsEmptyChangeEvent)="isPhasesProjectEmpty = $event"></app-current-phases-project>
                </div>
            </li>


            <li *ngFor="let category of visibleCategories"
                [ngbTooltip]="(category.category_type ? category.category_type?.name + ': ' : '') + category.name"
                class="w-100"
            >
                <app-category
                    class="d-flex flex-row align-items-center -w-100 ellipsis"
                    [category]="category"></app-category>
            </li>
        </ul>


        <ng-container *ngIf="isFieldVisible(Fields.NextMilestone) && nextMilestoneCard">
            <div class="d-flex project-display w-100"
                 [class.mb-1]="isFieldVisible(Fields.Program) && microCards && microCards.length > 0"
            >
                <app-card-milestone
                    class="flex-grow-1 w-100"
                    [item]="nextMilestoneCard"
                    [configuration]="$any(nextMilestoneCard.configuration)"
                    [mini]="true"
                    [tooltip]="('_display_projects_milestone' | translate) +': '+nextMilestoneCard.item.title"
                ></app-card-milestone>
            </div>
        </ng-container>


        <ng-container *ngIf="isFieldVisible(Fields.Program)">
            <ng-container *ngFor="let projectCard of microCards">
                <div class="d-flex project-display w-100">
                    <app-card-project
                        [item]="projectCard"
                        [model]="projectCard.item"
                        [configuration]="$any(projectCard.configuration)"
                        [mini]="true" class="w-100"></app-card-project>
                </div>
            </ng-container>
        </ng-container>


    </div>

</div>
