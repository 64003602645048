<div class="form-group d-flex flex-column"
     [class.is-invalid]="!parentFormControl.valid"
>
    <ng-container *ngIf="!hideLabel">
        <label>
            <span>{{label | translate}}</span>
            <span *ngIf="Helpers.hasRequiredField(parentFormControl)" class="text-danger">*</span>
        </label>
    </ng-container>

    <quill-editor
        [placeholder]="('_ui_add_description' | translate) + '...'"
        #quillEditorComponent
        [modules]="quillEditorConfig"
        [formControl]="parentFormControl"
        (onEditorCreated)="onEditorCreated($event)"
        (click)="focusQuill($event)"
        (onContentChanged)="contentChanged($event)"
        (onBlur)="modelChanged($event)"
        *ngIf="parentFormControl"
    ></quill-editor>

    <app-url-link [formControlElement]="parentFormControl" [icon]="true" [title]="true"></app-url-link>

</div>
