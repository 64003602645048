import {NgModule} from "@angular/core";
import {ReactionsComponent} from "@app/shared/_ui/reactions/reactions.component";
import {EmojiPickerModule} from "@app/shared/_elements/emoji-picker/EmojiPicker.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        EmojiPickerModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ReactionsComponent,
    ],
    exports: [
        ReactionsComponent

    ],
    providers: [

    ],
})
export class ReactionsModule {}
