<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title" [innerHTML]="title"></h4>
        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>

    <div class="container">
        <div class="w-100 d-flex justify-content-center  align-items-center" style="height: 200px;" *ngIf="isLoading">
            <app-loading-indicator [showLogo]="false" class="text-large "></app-loading-indicator>
        </div>
        <ng-container *ngIf="!isLoading">

            <div class="pt-1 d-none" [innerHTML]="message"></div>

            <div *ngIf="item.deadline" class="pb-1 mt-2 mb-1">
                    <span class="text-black-50  d-flex flex-grow-1">
                        <span>{{item.name}}</span>
                        <app-item-date [date]="item.deadline?.getDate()" [showIcon]="false"
                                       class="ms-auto"></app-item-date>
                    </span>
            </div>

            <div class="border rounded mb-1 mt-1 p-2">
                <ul class="list-unstyled list-group ">


                    <ng-container *ngIf="taskRows && taskRows.length > 0">
                        <!-- List opgaver -->
                        <li>
                            <p>{{'_ui_milestone_archive_dialog_tasks_description' | translate: {name: item.title} }}</p>
                        </li>
                        <!--                    <li><span class="label">{{'_tasks' | translate}}</span></li>-->
                        <ng-container *ngFor="let row of taskRows">
                            <li (click)="row.selected = !row.selected" class="cursor-pointer">
                                <div class="d-flex flex-row justify-content-start align-items-center">
                                    <i class="fa fa-check-circle text-success" *ngIf="row.selected"></i>
                                    <i class="fal fa-circle text-black-50" *ngIf="!row.selected"></i>
                                    <span class="ms-1 d-flex flex-grow-1 " [class.disabled]="!row.selected">
                                <span>{{row.item.title}}</span>
                            </span>
                                </div>
                            </li>
                        </ng-container>

                    </ng-container>
                </ul>
            </div>
            <ng-container *ngIf="projectPhasesRows && projectPhasesRows.length > 0">
                <div class="scroll-y">
                    <ng-container *ngFor="let projectRow of projectPhasesRows">

                        <div class="border rounded mb-1 p-2 pb-0"
                             *ngIf="projectRow.phasesProjects && projectRow.phasesProjects.length > 0">

                            <ul class="list-unstyled mb-0">
                                <!-- List faser -->
                                <li>
                                    <div class="d-flex align-items-end flex-grow-1 pb-2 border-bottom mb-1">
                                        <p>{{'_ui_milestone_archive_set_active_phase' | translate}}</p>
                                        <app-card-project [model]="projectRow.project"
                                                          [configuration]="cardProjectConfiguration"
                                                          class="ms-auto"></app-card-project>
                                    </div>
                                </li>

                                <ng-container *ngFor="let row of projectRow.phasesProjects">
                                    <li (click)="togglePhaseRow(projectRow, row)" class="cursor-pointer">
                                        <div class="d-flex flex-row justify-content-start align-items-center">
                                            <i class="fa fa-dot-circle " *ngIf="row.selected"></i>
                                            <i class="fal fa-circle text-black-50" *ngIf="!row.selected"></i>
                                            <span class="ms-1 d-flex flex-grow-1 " [class.disabled]="!row.selectable">
                                <app-phase [phase]="row.item.phase"></app-phase>

                                <span class="ms-auto d-flex">
                                    <app-item-date [date]="row.item.getStartedDate()"
                                                   [showIcon]="false"></app-item-date>
                                    <span *ngIf="row.item.getStartedDate() && row.item.getEndDate()"> - </span>
                                    <app-item-date [date]="row.item.getEndDate()" [showIcon]="false"></app-item-date>
                                </span>
                            </span>
                                        </div>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </ng-container>
                </div>
            </ng-container>


        </ng-container>

        <!--<app-card-task [model]="item"></app-card-task>-->

    </div>
    <div class="modal-footer">
        <button type="button" role="button" class="btn btn-secondary ellipsis" (click)="decline()"
                *ngIf="btnCancelText">{{ btnCancelText }}</button>
        <button type="button" role="button" class="btn ngbAutofocus ellipsis {{primaryClass}}"
                (click)="accept()">{{ btnOkText }}</button>
    </div>
</div>
