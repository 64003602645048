import {NgModule} from "@angular/core";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";
import {
    CanCopyEditorComponent
} from "@app/editor/quick-editor/editors/generic/can-copy-editor/can-copy-editor.component";

@NgModule({
    imports: [
        CommonModule,
        ToggleItemModule,
        TranslateModule,
        NgbTooltipModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CanCopyEditorComponent,
    ],
    exports: [
        CanCopyEditorComponent

    ],
    providers: [

    ],
})
export class CanCopyEditorModule {}
