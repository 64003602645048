/**
 * Created by ModelParser
 * Date: 04-12-2019.
 * Time: 13:20.
 */
import {TaskTypesStatusRuleDefinition,} from './definitions/TaskTypesStatusRuleDefinition';

export class TaskTypesStatusRule extends TaskTypesStatusRuleDefinition {

    constructor(json?: any) {
        super(json);
    }

}
