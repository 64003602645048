import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";
import {CSVCustomBinding} from "@app/export/csv/CSVCustomBinding";
import {CSVExportOptions} from "@app/shared/_ui/lists/milestone-list/CSVExportOptions";

export class ProjectCategoryListCell extends GenericCell {

    public csvListBinding = new CSVCustomBinding();

    public exportCSV(secondary?: any): CSVCellValue {
        return this.csvListBinding.export(CSVExportOptions.createIdentifierOption(secondary.toString()));
    }

}
