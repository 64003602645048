/**
 * Created by ModelParser
 */
import {TaskType} from '../TaskType';
import {TaskStatus} from '../TaskStatus';
import {Archived} from '../Archived';
import {ProjectCase} from '../ProjectCase';
import {Milestone} from '../Milestone';
import {MilestonesTask} from '../MilestonesTask';
import {TasksUser} from '../TasksUser';
import {TasksDeadline} from '../TasksDeadline';
import {User} from '../User';
import {TaskEstimate} from '../TaskEstimate';
import {Department} from '../Department';
import {ProjectsTask} from '../ProjectsTask';
import {Project} from '../Project';
import {HandUp} from '../HandUp';
import {Tag} from '../Tag';
import {Favorite} from '../Favorite';
import {Reaction} from '../Reaction';
import {CalculatedFieldsTask} from '../CalculatedFieldsTask';
import {Category} from '../Category';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class TaskDefinition extends BaseModel {
    title?: string;
    purpose?: string;
    delivery_description?: string;
    success_criteria?: string;
    strategy?: string;
    task_type_id?: number;
    task_type?: TaskType;
    main_status_id?: number;
    main_status?: TaskStatus;
    obs?: string;
    open?: boolean;
    archived_id?: number;
    archived?: Archived;
    created_custom?: number;
    is_private?: boolean;
    num_hand_ups?: number;
    num_stars?: number;
    project_case?: ProjectCase;
    use_status_rules?: boolean;
    can_copy?: boolean;
    cases_planned?: number;
    cases_reached?: number;
    milestones?: Milestone[];
    milestones_tasks?: MilestonesTask[];
    task_statuses?: TaskStatus[];
    tasks_users?: TasksUser[];
    tasks_deadlines?: TasksDeadline[];
    users?: User[];
    task_estimates?: TaskEstimate[];
    departments?: Department[];
    projects_tasks?: ProjectsTask[];
    projects?: Project[];
    hand_ups?: HandUp[];
    tags?: Tag[];
    favorites?: Favorite[];
    reactions?: Reaction[];
    calculated_fields_tasks?: CalculatedFieldsTask[];
    categories?: Category[];
    acceptance_status?: number;
    is_order?: boolean;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.title;
            delete this.purpose;
            delete this.delivery_description;
            delete this.success_criteria;
            delete this.strategy;
            delete this.task_type_id;
            delete this.task_type;
            delete this.main_status_id;
            delete this.main_status;
            delete this.obs;
            delete this.open;
            delete this.archived_id;
            delete this.archived;
            delete this.created_custom;
            delete this.is_private;
            delete this.num_hand_ups;
            delete this.num_stars;
            delete this.project_case;
            delete this.use_status_rules;
            delete this.can_copy;
            delete this.cases_planned;
            delete this.cases_reached;
            delete this.milestones;
            delete this.milestones_tasks;
            delete this.task_statuses;
            delete this.tasks_users;
            delete this.tasks_deadlines;
            delete this.users;
            delete this.task_estimates;
            delete this.departments;
            delete this.projects_tasks;
            delete this.projects;
            delete this.hand_ups;
            delete this.tags;
            delete this.favorites;
            delete this.reactions;
            delete this.calculated_fields_tasks;
            delete this.categories;
            delete this.acceptance_status;
            delete this.is_order;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.title != null) {
            this.title = data.title;
        }
        if (data.purpose != null) {
            this.purpose = data.purpose;
        }
        if (data.delivery_description != null) {
            this.delivery_description = data.delivery_description;
        }
        if (data.success_criteria != null) {
            this.success_criteria = data.success_criteria;
        }
        if (data.strategy != null) {
            this.strategy = data.strategy;
        }
        if (data.task_type_id != null) {
            this.task_type_id = data.task_type_id;
        }
        if (data.task_type != null) {
            this.task_type = new TaskType(data.task_type);
        }
        if (data.main_status_id != null) {
            this.main_status_id = data.main_status_id;
        }
        if (data.main_status != null) {
            this.main_status = new TaskStatus(data.main_status);
        }
        if (data.obs != null) {
            this.obs = data.obs;
        }
        if (data.open != null) {
            this.open = data.open;
        }
        if (data.archived_id != null) {
            this.archived_id = data.archived_id;
        }
        if (data.archived != null) {
            this.archived = new Archived(data.archived);
        }
        if (data.created_custom != null) {
            this.created_custom = data.created_custom;
        }
        if (data.is_private != null) {
            this.is_private = data.is_private;
        }
        if (data.num_hand_ups != null) {
            this.num_hand_ups = data.num_hand_ups;
        }
        if (data.num_stars != null) {
            this.num_stars = data.num_stars;
        }
        if (data.project_case != null) {
            this.project_case = new ProjectCase(data.project_case);
        }
        if (data.use_status_rules != null) {
            this.use_status_rules = data.use_status_rules;
        }
        if (data.can_copy != null) {
            this.can_copy = data.can_copy;
        }
        if (data.cases_planned != null) {
            this.cases_planned = data.cases_planned;
        }
        if (data.cases_reached != null) {
            this.cases_reached = data.cases_reached;
        }
        if (data.milestones != null) {
            this.milestones = data.milestones.map((i: any) => new Milestone(i));
        }
        if (data.milestones_tasks != null) {
            this.milestones_tasks = data.milestones_tasks.map((i: any) => new MilestonesTask(i));
        }
        if (data.task_statuses != null) {
            this.task_statuses = data.task_statuses.map((i: any) => new TaskStatus(i));
        }
        if (data.tasks_users != null) {
            this.tasks_users = data.tasks_users.map((i: any) => new TasksUser(i));
        }
        if (data.tasks_deadlines != null) {
            this.tasks_deadlines = data.tasks_deadlines.map((i: any) => new TasksDeadline(i));
        }
        if (data.users != null) {
            this.users = data.users.map((i: any) => new User(i));
        }
        if (data.task_estimates != null) {
            this.task_estimates = data.task_estimates.map((i: any) => new TaskEstimate(i));
        }
        if (data.departments != null) {
            this.departments = data.departments.map((i: any) => new Department(i));
        }
        if (data.projects_tasks != null) {
            this.projects_tasks = data.projects_tasks.map((i: any) => new ProjectsTask(i));
        }
        if (data.projects != null) {
            this.projects = data.projects.map((i: any) => new Project(i));
        }
        if (data.hand_ups != null) {
            this.hand_ups = data.hand_ups.map((i: any) => new HandUp(i));
        }
        if (data.tags != null) {
            this.tags = data.tags.map((i: any) => new Tag(i));
        }
        if (data.favorites != null) {
            this.favorites = data.favorites.map((i: any) => new Favorite(i));
        }
        if (data.reactions != null) {
            this.reactions = data.reactions.map((i: any) => new Reaction(i));
        }
        if (data.calculated_fields_tasks != null) {
            this.calculated_fields_tasks = data.calculated_fields_tasks.map((i: any) => new CalculatedFieldsTask(i));
        }
        if (data.categories != null) {
            this.categories = data.categories.map((i: any) => new Category(i));
        }
        if (data.acceptance_status != null) {
            this.acceptance_status = data.acceptance_status;
        }
        if (data.is_order != null) {
            this.is_order = data.is_order;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
