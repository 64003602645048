import {Directive, ElementRef, HostBinding, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[severity]',
})
export class SeverityDirective implements OnInit {

    // https://stackoverflow.com/questions/40705819/angular2-cant-bind-to-directive-since-it-isnt-a-known-property-of-element
    // https://angular.io/guide/attribute-directives
    // https://blog.angularindepth.com/enhance-components-with-directives-58f16c4ca1f

    @Input('severity') severityValue:any;

    protected _elementClass: string[] = [];
    private element: ElementRef;


    @HostBinding('class')
    get elementClass(): string {
        return this._elementClass.join(' ');
    }

    constructor(private elr: ElementRef) {
        this.element = elr;
        this._elementClass.push('badge');
        this._elementClass.push('circle');
        //this._elementClass.push('another-class');
    }

    ngOnInit(): void {
        //console.log('Severity : ', this.severityValue, this.elementClass, this.severityValue === 2);
        switch (parseInt(this.severityValue)) {
            case 1:
                this._elementClass.push('bg-danger');
                break;
            case 2:
                this._elementClass.push('bg-secondary');
                break;
            case 3:
                this._elementClass.push('bg-warning');
                break;


        }

    }

}
