/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TasksDeadlineDefinition} from './definitions/TasksDeadlineDefinition';
import {Deadline} from '@app/core/models/Deadline';
import {TaskDeadlineType} from '@app/core/models/TaskDeadlineType';
import {TaskDeadlineTypes} from "@app/constants";

export class TasksDeadline extends TasksDeadlineDefinition {

    constructor(json?: any) {
        super(json);

        if (this.deadline) {
            this.deadline = new Deadline(this.deadline);
        }
    }

    public static Create(typeId: number, deadline: Deadline): TasksDeadline {
        const item = new TasksDeadline();
        item.task_deadline_type_id = typeId;
        item.deadline = deadline;
        return item;
    }

    public static CreateDefault(taskDeadlineTypeId?: number) {
        let item = new TasksDeadline();
        item.id = 0;
        item.task_deadline_type_id = taskDeadlineTypeId ?? TaskDeadlineTypes.Normal;
        item.deadline = Deadline.createDefault();
        return item;
    }


    get deadlineType(): TaskDeadlineType {
        if (!this.task_deadline_type && this.task_deadline_type_id) {
            TaskDeadlineType.GetById(this.task_deadline_type_id, type => {
                this.task_deadline_type = type;
            });
        }
        if (this.task_deadline_type) {
            return this.task_deadline_type;
        }

        return null;
    }

}
