import {NgModule} from "@angular/core";
import {
    SelectProjectMilestoneDialogComponent
} from "@app/shared/_modals/select-project-milestone-dialog/select-project-milestone-dialog.component";
import {NgxModalDraggableDirectiveModule} from "@app/directives/NgxModalDraggableDirective.module";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgxModalDraggableDirectiveModule,
        ColorItemModule,
        TranslateModule,
        NgbTooltipModule,
        CardsModule,
        LoadingIndicatorModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        SelectProjectMilestoneDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class SelectProjectMilestoneDialogModule {}
