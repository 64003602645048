import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {Projects_ProjectUserTypesSettingValue} from "@app/core/http/Api/Api";
import {CSVColumn} from "@app/export/csv/CSVColumn";
import {CountRunner} from "@app/core/CountRunner/CountRunner";
import {AppInjector} from "@app/services/app-injector.service";
import {ProjectsService} from "@app/services/projects.service";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {ProjectUserListCell} from "@app/pages/displays/display-projects/Cells/ProjectUserListCell";
import {TranslateService} from "@ngx-translate/core";

export class ProjectUserListColumn extends GenericColumn<ProjectUserListCell> {

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new ProjectUserListCell(row, this);
    }

    public getProjectUserTypeIds(): number[] {
        return this.settings.get(ColumnTypeSettings.ProjectUserTypes)
            ?.getObject<Projects_ProjectUserTypesSettingValue>()
            ?.projectUserTypeIds ?? [];
    }

    public implementsCSVExport = true;

    public toCSVColumn(result: (column: CSVColumn) => void): void {
        const column = CSVColumn.CreatePrimary(this, []);

        const countRunner = new CountRunner(this.getProjectUserTypeIds().length);
        countRunner.setRunner(() => result(column));
        const projectsService = AppInjector.getInjector().get(ProjectsService);
        this.getProjectUserTypeIds().forEach(projectUserTypeId => {
            projectsService.getProjectUserType(projectUserTypeId, projectUserType => {
                column.subColumns.push(CSVColumn.CreateSecondary(
                    projectUserTypeId,
                    AppInjector.getInjector().get(TranslateService).instant(projectUserType.title)
                ));
                countRunner.tick();
            });
        });
        countRunner.start();
    }

}
