import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {ProjectsUser} from "@app/core/models";
import {
    ProjectUserListColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/ProjectUserListColumn";

export class ProjectUserListCell extends BaseCell {

    public projectsUsers: ProjectsUser[];

    public constructor(row: Row, column: ProjectUserListColumn) {
        super(row, column);

        const projectsUsers: ProjectsUser[] = [];
        column.getUserTypes()
            ?.forEach(projectUserTypeId => {
                projectsUsers.push(...row.projectCard.item.findProjectsUsersByType(projectUserTypeId));
            });
        this.projectsUsers = projectsUsers;
    }

}
