/**
 * Created by ModelParser
 * Date: 10-01-2022.
 * Time: 12:12.
 */
import {UserMetaDefinition} from './definitions/UserMetaDefinition';

export class UserMeta extends UserMetaDefinition {

    constructor(json?: any) {
        super(json);
    }

}
