export const TaskPresetTypes = {
    Title:  'title',
    Project:  'project',
    Milestone:  'milestone',
    User:  'user',
    Department:  'department',
    Deadline:  'deadline',
    Status:  'status',
    Category: 'category',
    Estimate: 'estimate',
    UseStatusRules: 'use-status-rules',
};

export type TaskPresetType =
    typeof TaskPresetTypes.Title
    | typeof TaskPresetTypes.Project
    | typeof TaskPresetTypes.Milestone
    | typeof TaskPresetTypes.User
    | typeof TaskPresetTypes.Department
    | typeof TaskPresetTypes.Deadline
    | typeof TaskPresetTypes.Status
    | typeof TaskPresetTypes.Category
    | typeof TaskPresetTypes.Estimate
    | typeof TaskPresetTypes.UseStatusRules
;
