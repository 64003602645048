import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {MultiListConfiguration} from "@app/shared/_ui/lists/multi-list/MultiListConfiguration";
import {ProjectListConfiguration} from "@app/shared/_ui/lists/project-list/ProjectListConfiguration";
import {DaysColumn} from "@app/pages/displays/dashboard/dashboard-calendar/Columns/DaysColumn";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";
import {TodoListConfiguration} from "@app/shared/_ui/lists/todo-list/TodoListConfiguration";
import {MilestoneListConfiguration} from "@app/shared/_ui/lists/milestone-list/MilestoneListConfiguration";
import {
    Office365EventListConfiguration
} from "@app/shared/_ui/lists/office365event-list/Office365EventListConfiguration";
import {CreateItemSourceInterface} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceInterface";
import {CardTaskConfiguration} from "@app/shared/_ui/cards/medium/card-task/card-task-configuration";
import * as moment from "moment";
import {TableColumns} from "@app/pages/displays/dashboard/dashboard-calendar/TableColumns";
import {DatePipe} from "@angular/common";

export const WeekDays = {
    Monday: 'monday',
    Tuesday: 'tuesday',
    Wednesday: 'wednesday',
    Thursday: 'thursday',
    Friday: 'friday',
    Saturday: 'saturday',
    Sunday: 'sunday',
}

export type WeekDay = typeof WeekDays.Monday
    | typeof WeekDays.Tuesday
    | typeof WeekDays.Wednesday
    | typeof WeekDays.Thursday
    | typeof WeekDays.Friday
    | typeof WeekDays.Saturday
    | typeof WeekDays.Sunday;

export class DaysTableColumn extends BaseTableColumn {

    public identifier = TableColumns.Days;
    public weekDay: WeekDay;

    public dateStart = new Date();
    public dateEnd = new Date();

    public listConfiguration = new MultiListConfiguration();
    public projectListConfiguration?: ProjectListConfiguration;
    public taskParticipantDeadlineListConfiguration?: TaskListConfiguration;
    public todoListConfiguration?: TodoListConfiguration;
    public milestoneListConfiguration?: MilestoneListConfiguration;
    public office365EventListConfiguration?: Office365EventListConfiguration;

    public load = 0;

    constructor(column: DaysColumn, weekDay: WeekDay) {
        super(column);
        this.weekDay = weekDay;
        this.name = column.column.name;
        this.identifier = weekDay;

        this.listConfiguration
            .setDraggable(true)
            .setAllowDragEnter(true);

        const createItemConfiguration = this.listConfiguration.createItemConfiguration;
        const createItemPreset = this.listConfiguration.createItemPreset;

        if (column.getFilterProjectDeadlineTypes().length) {
            this.projectListConfiguration = new ProjectListConfiguration();
            this.projectListConfiguration
                .setProjectDeadlineTypeIds(column.getFilterProjectDeadlineTypes())
                .setProjectTypeIds(column.getIncludeProjectTypes())
                .setLimit(100)
                .setUseGlobalFilter(true)
                .setDraggable(true);
            this.listConfiguration.addSource(this.projectListConfiguration);
            createItemConfiguration.showProjects = true;
            createItemConfiguration.filterProjectsTypesById = column.getIncludeProjectTypes();
            createItemPreset.createProjectInterface = this.projectListConfiguration;
        }

        if (column.getIncludeTaskPlannings()) {
            this.taskParticipantDeadlineListConfiguration = new TaskListConfiguration();
            this.taskParticipantDeadlineListConfiguration
                .setSoftDeadline(false)
                .setTaskTypeIds(column.getIncludeTaskTypes())
                .setLimit(100)
                .setUseGlobalFilter(true)
                .setDraggable(true);
            this.listConfiguration.addSource(this.taskParticipantDeadlineListConfiguration);
            createItemConfiguration.showTasks = true;
            createItemConfiguration.filterByDepartmentIds = AppInjector.getInjector().get(UsersService).user
                ?.departments?.map(d => d.id) || [];
            createItemConfiguration.filterTaskTypesById = column.getIncludeTaskTypes();
            createItemPreset.createTaskInterface = this.taskParticipantDeadlineListConfiguration;
        }

        this.todoListConfiguration = new TodoListConfiguration();
        this.todoListConfiguration
            .setLimit(100)
            .setUseGlobalFilter(true)
            .setDraggable(true)
            .setShowCreateNew(true);
        this.listConfiguration.addSource(this.todoListConfiguration);
        createItemConfiguration.showTodo = true;
        createItemPreset.createTodoInterface = this.todoListConfiguration;

        if (column.getIncludeMilestones()) {
            this.milestoneListConfiguration = new MilestoneListConfiguration();
            this.milestoneListConfiguration
                .setLimit(100)
                .setShowProjectMiniCard(true)
                .setUseGlobalFilter(true)
                .setDraggable(true)
                .setShowCreateNew(true);
            this.listConfiguration.addSource(this.milestoneListConfiguration);
            createItemConfiguration.showMilestone = true;
            createItemPreset.createMilestoneInterface = this.milestoneListConfiguration;
        }

        this.office365EventListConfiguration = new Office365EventListConfiguration();
        this.office365EventListConfiguration
            .setLimit(100)
            .setDraggable(false);
        this.listConfiguration.addSource(this.office365EventListConfiguration);

        // Lazy function for CreateItem prepareSource
        const listConfiguration = this.listConfiguration;
        createItemConfiguration.sourceInterface = new class implements CreateItemSourceInterface {
            prepareSource(): void {
                listConfiguration.getSources().forEach(source => {
                    source.listConfiguration.prepareSource();
                });
            }
        };
    }

    public setDate(week: Date) {
        switch (this.weekDay) {
            case WeekDays.Monday:
                this.dateStart = moment(week).startOf('isoWeek').toDate();
                break;
            case WeekDays.Tuesday:
                this.dateStart = moment(week).startOf('isoWeek').add(1, 'days').toDate();
                break;
            case WeekDays.Wednesday:
                this.dateStart = moment(week).startOf('isoWeek').add(2, 'days').toDate();
                break;
            case WeekDays.Thursday:
                this.dateStart = moment(week).startOf('isoWeek').add(3, 'days').toDate();
                break;
            case WeekDays.Friday:
                this.dateStart = moment(week).startOf('isoWeek').add(4, 'days').toDate();
                break;
            case WeekDays.Saturday:
                this.dateStart = moment(week).startOf('isoWeek').add(5, 'days').toDate();
                break;
            case WeekDays.Sunday:
                this.dateStart = moment(week).startOf('isoWeek').add(6, 'days').toDate();
                break;
        }
        this.dateEnd = moment(this.dateStart).endOf('day').toDate();

        const name = new DatePipe('da-DK').transform(this.dateStart, 'EEEE');
        this.name = name[0].toUpperCase() + name.substring(1);
    }

    public dataSetChanged() { // Triggered by dataSetChange event in MultiListComponent
        let load = 0;
        this.listConfiguration.getSources().forEach(source => {
            if (source.listConfiguration instanceof TaskListConfiguration) {
                source.items.forEach(item => {
                    if (item.configuration instanceof CardTaskConfiguration) {
                        if (item.configuration.taskEstimate && item.configuration.taskEstimate.estimate) {
                            load += item.configuration.taskEstimate.estimate.getLoadInSeconds();
                        }
                    }
                });
            }
        });
        this.load = load;
    }

}
