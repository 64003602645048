import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    MilestoneEditTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/MilestoneEditTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-project-details/subdisplay-milestones/ColumnTypeSettings";
import {
    ProjectDetails_Milestones_MilestoneEditFieldsSettingValue,
} from "@app/core/http/Api/Api";
import {
    MilestoneEditCell
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Cells/MilestoneEditCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-milestones/Row";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";

export class MilestoneEditColumn extends BaseColumn<MilestoneEditCell> {

    static Field_ActionPositioning = 'action-positioning';
    static Field_ActionDelete = 'action-delete';
    static Field_ActionArchive = 'action-archive';

    public isDragDropVisible = false;
    public isArchiveBtnVisible = false;
    public isDeleteBtnVisible = false;

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.isDragDropVisible = this.hasField(MilestoneEditColumn.Field_ActionPositioning);
        this.isArchiveBtnVisible = this.hasField(MilestoneEditColumn.Field_ActionArchive);
        this.isDeleteBtnVisible = this.hasField(MilestoneEditColumn.Field_ActionDelete);
    }

    createTableColumns(): BaseTableColumn[] {
        const tableColumn = new MilestoneEditTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    createCell(row: Row): BaseCell {
        return new MilestoneEditCell(row, this);
    }

    public getFields(): string[] {
        return this.settings.get(ColumnTypeSettings.MilestoneEdit_Fields)
            ?.getObject<ProjectDetails_Milestones_MilestoneEditFieldsSettingValue>()
            ?.fields ?? [];
    }

    public hasField(field: string): boolean {
        return this.getFields().includes(field);
    }

}
