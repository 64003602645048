/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ProjectsDeadlineDefinition,} from './definitions/ProjectsDeadlineDefinition';
import {AppInjector} from '@app/services/app-injector.service';
import {ProjectsService} from '@app/services/projects.service';
import {ProjectDeadlineType} from '@app/core/models/ProjectDeadlineType';
import {Deadline} from '@app/core/models/Deadline';

export class ProjectsDeadline extends ProjectsDeadlineDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static createDefault() {
        let item = new ProjectsDeadline();
        item.id = 0;
        item.deadline = new Deadline();
        return item;
    }

    public static Create(typeId: number, deadline: Deadline): ProjectsDeadline {
        const item = new ProjectsDeadline();
        item.project_deadline_type_id = typeId;
        item.deadline = deadline;
        return item;
    }

    get deadlineType(): ProjectDeadlineType {
        if (!this.project_deadline_type && this.project_deadline_type_id) {
            AppInjector.getInjector().get(ProjectsService)
                .getProjectDeadlineType(this.project_deadline_type_id, item => {
                    this.project_deadline_type = item;
                });
        }
        if (this.project_deadline_type) {
            return this.project_deadline_type;
        }
        return null;
    }

}
