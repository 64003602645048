import {GenericTableColumn} from "@app/pages/displays/display-team/TableColumns/GenericTableColumn";
import {TableColumns} from "@app/pages/displays/display-team/Helpers/TableColumns";
import {TaskListColumn} from "@app/pages/displays/display-team/Columns/TaskListColumn";

export class TaskListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TaskList;

    public column: TaskListColumn;

}
