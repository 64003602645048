import {NgModule} from "@angular/core";
import {
    MilestonePlanTimelineComponent
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-timeline/milestone-plan-timeline.component";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        LoadingIndicatorModule,
        NgbPopoverModule,
        CommonModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MilestonePlanTimelineComponent,
    ],
    exports: [
        MilestonePlanTimelineComponent

    ],
    providers: [

    ],
})
export class MilestonePlanTimelineModule {}
