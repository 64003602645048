import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/GenericTableColumn";
import {
    MilestoneEditColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/MilestoneEditColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns";

export class MilestoneEditTableColumn extends GenericTableColumn {

    public identifier = TableColumns.MilestoneEdit;

    public column: MilestoneEditColumn;

}
