/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ProjectUserTypeDefinition} from './definitions/ProjectUserTypeDefinition';
import {AnyUserType} from '@app/interfaces/AnyUserType';

export class ProjectUserType extends ProjectUserTypeDefinition implements AnyUserType {

    // OTF
    required?: boolean;
    multiple?: boolean;
    visible?: boolean;

    constructor(json?: any) {
        // console.log('ProjectUserType : constructor : ', json);
        super(json);
    }

    get title(): string {
        if (this.name != '') {
            return this.name;
        }
        if(this.language_key){
            return this.language_key;
        }
    }

}
