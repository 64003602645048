<ng-container *ngIf="links && links.length > 0">
    <div class="d-flex" *ngFor="let url of links; let i = index;"
         [class.pe-3]="!expanded" [class.pt-1]="!expanded"
    >
        <a class="link ellipsis"
           [class.text-primary]="title"
           [class.text-small]="title"
           [class.me-1]="title"
           [ngbTooltip]="linkNames ? linkNames[i] : null"
           (click)="navigateToUrl(url, $event, i)"
           [target]="target"

           *ngIf="i < maxLinks && linkNames && (expanded || !expanded && i < 1)"
        >
            <span class="{{iconNames[i]}}"
                  [class.me-1]="title" *ngIf="icon"></span>
            <span *ngIf="title">{{linkNames[i]}}</span>
        </a>

        <div class="badge rounded-pill badge-danger position-absolute badge-counter" style="top:0; right: 0;" *ngIf="maxLinks > 1 && expanded !== null && links.length > 1 && !expanded">{{links.length}}</div>
    </div>
</ng-container>



