/**
 * Created by ModelParser
 */
import {TaskEstimate} from '../TaskEstimate';
import {MilestonePlan} from '../MilestonePlan';
import {Load} from '../Load';
import {TaskEstimateTypesTaskType} from '../TaskEstimateTypesTaskType';
import {TaskType} from '../TaskType';
import {CalculatedField} from '../CalculatedField';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class TaskEstimateTypeDefinition extends BaseModel {
    name?: string;
    language_key?: string;
    task_estimates?: TaskEstimate[];
    milestone_plans?: MilestonePlan[];
    loads?: Load[];
    task_estimate_types_task_types?: TaskEstimateTypesTaskType[];
    task_types?: TaskType[];
    calculated_fields?: CalculatedField[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.language_key;
            delete this.task_estimates;
            delete this.milestone_plans;
            delete this.loads;
            delete this.task_estimate_types_task_types;
            delete this.task_types;
            delete this.calculated_fields;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.language_key != null) {
            this.language_key = data.language_key;
        }
        if (data.task_estimates != null) {
            this.task_estimates = data.task_estimates.map((i: any) => new TaskEstimate(i));
        }
        if (data.milestone_plans != null) {
            this.milestone_plans = data.milestone_plans.map((i: any) => new MilestonePlan(i));
        }
        if (data.loads != null) {
            this.loads = data.loads.map((i: any) => new Load(i));
        }
        if (data.task_estimate_types_task_types != null) {
            this.task_estimate_types_task_types = data.task_estimate_types_task_types.map((i: any) => new TaskEstimateTypesTaskType(i));
        }
        if (data.task_types != null) {
            this.task_types = data.task_types.map((i: any) => new TaskType(i));
        }
        if (data.calculated_fields != null) {
            this.calculated_fields = data.calculated_fields.map((i: any) => new CalculatedField(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
