import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {AnyItem} from "@app/interfaces/CustomTypes";
import {Office365Event} from "@app/core/models";

export class NonOffice365EventFilter extends BaseOnScreenFilter<AnyItem> {

    // Præsenteret som "Fuldført med omvendt ikon"
    public name = '_admin_nav_office_365';
    public iconActive = 'fa-calendar-times';
    public iconInactive = 'fa-calendar-check';
    public invertIcon: boolean = false;

    protected validate(item: AnyItem): boolean {
        return !(item instanceof Office365Event);
    }

}
