import {NgModule} from "@angular/core";
import {
    TaskCalculatedFieldComponent
} from "@app/shared/_elements/task-calculated-field/task-calculated-field.component";
import {CalculatedFieldModule} from "@app/shared/_elements/calculated-field/CalculatedField.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        CalculatedFieldModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TaskCalculatedFieldComponent,
    ],
    exports: [
        TaskCalculatedFieldComponent

    ],
    providers: [

    ],
})
export class TaskCalculatedFieldModule {}
