import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {TaskPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/TaskPresetType";

export class TaskTitlePresetGenerator implements CreatePresetGenerator {

    private readonly handler: () => string;

    constructor(handler: () => string) {
        this.handler = handler;
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: TaskPresetTypes.Title,
            payload: {
                title: this.handler(),
            }
        };
    }

}
