import {
    Injectable,
    OnInit,
} from '@angular/core';
import {AppInjector} from '@app/services/app-injector.service';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {ResizeEvent} from "angular-resizable-element";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ShellService} from "@app/services/ShellService/shell.service";

@Injectable()
export abstract class BaseEditorV3Component extends BaseComponent implements OnInit {

    protected shellService: ShellService;
    protected editorPanelService: EditorPanelService;

    protected autoClose: boolean = false;

    public isOpen = true;
    public editable = false;

    protected constructor() {
        super();
        const injector = AppInjector.getInjector();
        this.editorPanelService = injector.get(EditorPanelService);
        this.shellService = injector.get(ShellService);

        this.editorPanelService.editor$.subscribe((editorEvent) => {
            this.evaluateTarget(editorEvent);
        });
    }

    ngOnInit() {
        super.ngOnInit();
    }

    protected evaluateTarget(editorEvent: any): void {

    }

    protected open(): void {
        // Paneler styres i shell.component.ts
        if (this.editorPanelService.editorPanels.length > 1) {
            this.freeDragPosition.x = -550;
        }
    }

    // <editor-fold desc="Animation">

    private animationStateReady = false;

    animationStarted($event: any) {
        if ($event.toState === 'open') {
            this.animationStateReady = false;
            this.editable = true;
        }
    }

    animationDone($event: any) {
        if ($event.toState === 'open') {
            this.animationStateReady = true;
        }
        if ($event.toState === 'closed') {
            this.animationStateReady = false;
            this.editable = false;
        }
    }

    // </editor-fold>

    // <editor-fold desc="Window maximization / minimization">

    public docked: boolean = false;
    public freeDragPosition = {x: 0, y: 0};
    private windowWidth = 540;
    private windowHeight: number;
    public windowStyle: object = {
        width: `${this.windowWidth}px`
    };

    public minimized: boolean = false;
    public maximized: boolean = false;

    onWindowMinimizeClicked() {
        this.maximized = false;
        this.minimized = !this.minimized;
    }

    onWindowMaximizeClicked() {
        this.minimized = false;
        if (!this.maximized) {
            this.maximized = true;
            this.windowStyle = {
                height: `100vh`,
                width: `100vw`
            };
        } else {
            this.maximized = false;
            this.windowStyle = {
                width: `${this.windowWidth}px`
            };
        }
    }

    public onResizeEnd(event: any) {
        this.windowWidth = event.rectangle.width;
        this.windowHeight = event.rectangle.event.rectangle.height;
        this.windowStyle = {
            height: `${this.windowHeight}px`,
            width: `${this.windowWidth}px`
        };
    }

    public validateResize(event: ResizeEvent): boolean {
        return true;
    }

    // </editor-fold>

}
