import {NgModule} from "@angular/core";
import {FullscreenToggleComponent} from "@app/shared/_elements/fullscreen-toggle/fullscreen-toggle.component";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        NgbTooltipModule,
        CommonModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        FullscreenToggleComponent,
    ],
    exports: [
        FullscreenToggleComponent

    ],
    providers: [

    ],
})
export class FullscreenToggleModule {}
