export class Filter {
    id: string;
    name: string;
    showPeriod: boolean;
    showPhase: boolean;
    showPhaseProgressType: boolean;
    showProjectDeadlineType: boolean;
    showIncludeArchivedSince: boolean;
    showStatus = true;
    showStarred = true;
    showHands = true;
    showColumns = true;
    showReactionTypes = true;
    showCategoryTypes = true;
    showTaskDeadlineType: boolean;

    public constructor(id: string, name: string, showPeriod: boolean = false) {
        this.id = id;
        this.name = name;
        this.showPeriod = showPeriod;
    }

}
