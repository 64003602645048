import {GenericCell} from "@app/pages/displays/display-project-details/subdisplay-users/Cells/GenericCell";
import {MilestoneListConfiguration} from "@app/shared/_ui/lists/milestone-list/MilestoneListConfiguration";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-users/Row";
import {
    MilestoneListUserColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/Columns/MilestoneListUserColumn";
import {
    MilestoneStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneStatusPresetGenerator";
import {StatusTypes} from "@app/constants";
import {
    MilestoneUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneUseStatusRulesPresetGenerator";
import {
    MilestoneProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneProjectPresetGenerator";
import {
    MilestoneResponsiblePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneResponsiblePresetGenerator";

export class MilestoneListUserCell extends GenericCell {

    public listConfiguration = new MilestoneListConfiguration();

    constructor(row: Row, column: MilestoneListUserColumn) {
        super(row, column);

        this.listConfiguration
            .setProject(row.project)
            .setResponsible(row.userCard.item)
            .setUseGlobalFilter(true)
            .setDraggable(true)
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                // Defaults
                new MilestoneStatusPresetGenerator(StatusTypes.GREEN),
                new MilestoneUseStatusRulesPresetGenerator(true),

                new MilestoneProjectPresetGenerator(row.project.id),
                new MilestoneResponsiblePresetGenerator(row.userCard.item.id),
            ]);
    }

}
