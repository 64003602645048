import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {ProjectPhaseCell} from "@app/pages/displays/display-projects/Cells/ProjectPhaseCell";
import {ProjectPhaseDateCell} from "@app/pages/displays/display-projects/Cells/ProjectPhaseDateCell";

export class ProjectPhaseDateColumn extends GenericColumn<ProjectPhaseCell> {

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new ProjectPhaseDateCell(row, this);
    }

    public implementsCSVExport = true;

}
