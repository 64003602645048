/**
 * Created by ModelParser
 * Date: 10-02-2024.
 * Time: 09:05.
 */
import {ColumnDefinition} from './definitions/ColumnDefinition';

export class Column extends ColumnDefinition {

    constructor(json?: any) {
        super(json);
    }

}
