import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {CaseTextColumn} from "@app/pages/displays/display-cases/Columns/CaseTextColumn";
import {CaseRow} from "@app/pages/displays/display-cases/CaseRow";

export class CaseTextCell extends BaseCell {

    public row: CaseRow;
    public column: CaseTextColumn;

    public constructor(row: BaseRow, column: BaseColumn) {
        super(row, column);
    }

    public get value(): string {
        switch (this.column.getField()) {
            case 'casesText1':
                return this.row.projectCase.cases_text1;
            case 'casesText2':
                return this.row.projectCase.cases_text2;
            case 'casesText3':
                return this.row.projectCase.cases_text3;
            default:
                return '';
        }
    }

    public setValue(value: string): void {
        switch (this.column.getField()) {
            case 'casesText1':
                this.row.projectCase.setCasesText1(value);
                break;
            case 'casesText2':
                this.row.projectCase.setCasesText2(value);
                break;
            case 'casesText3':
                this.row.projectCase.setCasesText3(value);
                break;
        }
    }

}
