import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns/GenericTableColumn";
import {
    TaskListAllColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/Columns/TaskListAllColumn";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-tasks/Filters";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns";

export class TaskListAllTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TaskList_All;

    public column: TaskListAllColumn;

    constructor(column: TaskListAllColumn) {
        super(column);

        this.setSortItems([
            PageColumnSort.CreateWithSortId(Filters.SortTitle),
            PageColumnSort.CreateWithSortId(Filters.SortStatus),
            PageColumnSort.CreateWithSortId(Filters.SortDeadlines),
            PageColumnSort.CreateWithSortId(Filters.SortPlanningDate),
            PageColumnSort.CreateWithSortId(Filters.SortStars),
            PageColumnSort.CreateWithSortId(Filters.SortHands),
            PageColumnSort.CreateWithSortId(Filters.SortUserDefined),
        ]);
    }

}
