<ng-container *ngIf="phases && (project && project.exists() && autoSave) || !autoSave">
    <ng-template #rt let-r="result" let-t="term"
                 [typedTemplate]="resultTemplateType"
    >
        <div class="d-flex align-items-center">
            <app-color-item [color]="r.color" class="me-1"></app-color-item>
            <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
        </div>
    </ng-template>

    <ul class="list-group">
        <li class="list-group-item">
            <div class="form-group mb-0">
                <div class="search-wrap">
                    <div class="input-group">

                        <input id="typeahead-http" type="search" class="form-control placeholder-sm"
                               autocomplete="off"
                               #instance="ngbTypeahead"
                               container="body"
                               popupClass="result-list"
                               [class.is-invalid]="searchFailed"
                               [ngbTypeahead]="search"
                               [resultTemplate]="rt"
                               [inputFormatter]="formatter"
                               (focus)="focus$.next($any($event.target).value); getPhases();"
                               (click)="click$.next($any($event.target).value)"
                               (selectItem)="triggerSelection($event, instance)"
                               [placeholder]="(placeholder | translate)"/>
                        <button class="btn btn-outline-primary" type="button" (click)="createPhaseDialog()"
                                *ngIf="allowCreation"><i
                                class="fa fa-plus" aria-hidden="true"></i></button>
                    </div>
                    <span class="searching" *ngIf="searching" [@fadeAnimation]="'in'">Søger...</span>
                </div>
                <div class="invalid-feedback" *ngIf="searchFailed">Beklager, ingen resultater fundet.</div>
            </div>

        </li>

        <ng-container *ngIf="showPhases">
            <li class="list-group-item" *ngIf="_internalValue && _internalValue.length > 0">
                <div style="padding-left: 230px;transform: translateY(2px);">
                    <label>{{'_ui_phase_deadline' | translate}}</label>
                </div>
            </li>

            <li class="list-group-item" *ngFor="let item of _internalValue" [@fadeAnimation]="'in'">

                <ng-container>
                    <div class="input-group d-flex align-items-start">
                        <div class="form-control d-flex align-items-center ellipsis"
                             [class.input-group-prepend]="item.phase?.changeable_date || true">

                            <app-toggle-item
                                    [iconColorInactive]="'text-black-25'"
                                    [ngbTooltip]="('_display_projects_phase' | translate)"
                                    [interactive]="false"
                                    [active]="item.is_current"
                                    icon="fa-dot-circle"
                                    [iconInactive]="'fa-circle'"
                                    class="me-1 cursor-pointer"
                                    (click)="activatePhase(item)"
                            ></app-toggle-item>

                            <app-color-item [color]="item.phase?.color" class="me-1 ms-1"
                                            [className]="'large'"></app-color-item>

                            <ng-container *ngIf="item.phase">
                                <div class=" ellipsis"
                                     *ngIf="item.phase?.id != 0"
                                     [ngbTooltip]="item.phase?.name">
                                    {{item.phase.name}}
                                </div>
                                <input type="text"
                                       *ngIf="item.phase?.id == 0"
                                       style="border: none; background-color: transparent; outline: none;"
                                       [ngModelOptions]="{standalone: true}"
                                       [(ngModel)]="item.phase.name"
                                       class="form-control"/>
                            </ng-container>

                        </div>
                        <div class="input-group-append w-50 input-group-date" novalidate
                             *ngIf="item.phase?.changeable_date">
                            <input class="form-control d-lg-block d-md-block flex-fill "
                                   container="body"
                                   [placeholder]="('_ui_select_phase_deadline' | translate)+'...'"
                                   autocomplete="off"
                                   readonly
                                   [ngModelOptions]="{standalone: true}"
                                   [(ngModel)]="item.startNgbDate"
                                   (ngModelChange)="item.setStart($event)"
                                   ngbDatepicker
                                   [weekdays]="true"
                                   [showWeekNumbers]="true"
                                   #d="ngbDatepicker"
                                   [class.focus]="d.isOpen()"
                                   (click)="d.toggle()"/>
                        </div>

                        <div class="ms-1 -input-group-append w-50" novalidate *ngIf="!item.phase?.changeable_date"
                             [class.d-none]="!item.phase?.changeable_date">
                            <input class="form-control d-lg-block d-md-block flex-fill"
                                   container="body"
                                   [placeholder]="('_ui_select_phase_deadline' | translate)+'...'"
                                   readonly
                                   [ngModelOptions]="{standalone: true}"
                                   [(ngModel)]="item.startNgbDate"
                                   (ngModelChange)="item.setStart($event)"
                                   ngbDatepicker
                                   [weekdays]="true"
                                   [showWeekNumbers]="true"
                                   #db="ngbDatepicker"
                                   [class.focus]="db.isOpen()"
                            />
                        </div>

                        <div class="ms-1 -input-group-append">
                            <span class="btn btn-outline-danger" (click)="removeItem(item)"><i
                                    class="fal fa-trash-alt"></i></span>
                        </div>
                    </div>
                </ng-container>
            </li>
        </ng-container>
    </ul>
</ng-container>


<pre *ngIf="(!project || !project.exists()) && autoSave">
    TODO: Projektet skal gemmes før du kan tilføje faser...
</pre>
