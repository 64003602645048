<div class="container container-fluid shadow p-0"
     [class.un-docked]="!docked"
     [@changeState]="isOpen ? 'open' : 'closed'"
     (@changeState.start)="animationStarted($event)"
     (@changeState.done)="animationDone($event)"
     cdkDrag
     [class.editable]="editable"
     *ngIf="initialized && isOpen"
     style="width: 440px; z-index: 20000;"
>
    <div *ngIf="!isLoading" class="h-100">

        <div class="d-flex flex-column h-100">
            <div class="pt-2 flex-shrink-1 card-header flex-row d-flex align-items-center" style="height: 46px;"
                 cdkDragHandle
            >
                <h4 class="text-center m-0">{{('_ui_header_non_planned' | translate)}}</h4>

                <div class="d-flex align-content-center d-flex ms-2 mb-2  me-1 d-mobile-none ms-auto">
                    <app-color-item class="cursor-pointer me-2"
                                    [className]="'medium'"
                                    [colorValue]="'#ff5f57'"
                                    [iconClass]="'fa-times'"
                                    container="body" [ngbTooltip]="('_ui_close' | translate)"
                                    (click)="close();"></app-color-item>
                </div>
            </div>

            <div class="scroll-y align-self-stretch card-content -debug" style="height: calc(100% - 107px);">
                <ul ngbNav
                    #nav="ngbNav"
                    class="nav-tabs tabs-editor "
                    (navChange)="tabChange($event)"
                    [(activeId)]="selectedTab"
                >
                    <li ngbNavItem [ngbNavItem]="Tabs.Tasks"
                        [class.active]="nav.activeId == Tabs.Tasks"
                    >
                        <a ngbNavLink><span >{{'_tasks' | translate}}</span></a>
                        <ng-template ngbNavContent>
                            <div class="container p-2">
                                <div class=" list-group-item rounded mb-2">
                                    <app-task-list
                                        [listClass]="'grid-list-box'"
                                        [configuration]="taskListConfiguration"></app-task-list>
                                </div>
                            </div>
                        </ng-template>
                    </li>

                    <li ngbNavItem [ngbNavItem]="Tabs.Projects"
                        [class.active]="nav.activeId == Tabs.Projects"
                    >
                        <a ngbNavLink><span >{{'_projects' | translate}}</span></a>
                        <ng-template ngbNavContent>
                            <div class="container p-2">
                                <div class=" list-group-item rounded mb-2">
                                    <app-project-list
                                        [listClass]="'grid-list-box'"
                                        [configuration]="projectListConfiguration"></app-project-list>
                                </div>
                            </div>
                        </ng-template>
                    </li>

                </ul>
                <div [ngbNavOutlet]="nav" class="categories ngb-tabs-content"></div>
            </div>
        </div>

        <ul class="nav nav-pills nav-fill position-absolute bg-light border-top w-100 p-2" style="bottom: 0;">
            <li class="nav-item -px-2">
                <button type="button" class="btn btn-sm btn-secondary  panel-button" (click)="close()">
                    <span >{{'_ui_close' | translate}}</span>
                </button>
            </li>
        </ul>

    </div>

    <app-ghost-container *ngIf="isLoading" (click)="close()"></app-ghost-container>
</div>
