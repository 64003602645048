<div
    class="banner d-flex justify-content-center cursor-pointer"
    *ngIf="countDown"
    (click)="activateUpdate()"
>
    <div class="p-2 w-50 pt-4 d-flex align-items-start">
        <i class="fa-duotone fa-badge-check text-success"></i>
        <div class="wr">
            <h4 class="text-strong">{{'_ui_update_title' | translate}}</h4>
            <p>{{'_ui_update_description' | translate}}</p>
            <p class="text-strong">
                {{'_ui_update_countdown' | translate}} {{counter | formatTime}}
            </p>
            <a class="btn btn-outline-success w-100 max-width-unset text-strong pt-2 pb-2">
                <i class="fal fa-sync-alt me-1 d-none"></i>
                <span>{{'_ui_reload' | translate}}</span>
            </a>
            <small class="d-none">
                <div class="d-inline-block d-flex flex-column"><!--v{{version}}-->
                    <div class="d-flex flex-row justify-content-start small flex-wrap "
                         >
                        <div class="me-2"><i
                            class="fal fa-code-branch"></i> {{environment.version}}
                        </div>
                        <div class="me-2" *ngIf="gitVersions"><i
                            class="fab fa-github"></i> {{gitVersions.npm_version}}, {{gitVersions.revision}}
                            , {{gitVersions.branch}}</div>
                    </div>
                </div>
            </small>
        </div>

    </div>
</div>
