<ng-container>
    <div class="d-flex flex-grow-1 -p-1 align-items-center w-100">
        <div class="d-flex flex-shrink-1 align-items-center item-name ellipsis"
             *ngIf="showTitle"
             [class.cursor-pointer]="interactive || showEditable"
             [ngbTooltip]="warningTooltip"
             [placement]="['bottom']"
             (click)="interactive || showEditable ? onClick() : null">
                <i class="fa-duotone fa-triangle-exclamation me-2 text-warning" *ngIf="warningTooltip"></i>
                <span class="ellipsis flex-shrink-1 flex-grow-1" [ngbTooltip]="milestonePlan.title">{{milestonePlan.title}}</span>
        </div>

        <div class="d-flex ms-auto">

            <i *ngIf="isGlobal"
               class="fal fa-globe-stand" style="opacity: .5;"
               [ngbTooltip]="('_ui_milestone_plan_selector_global' | translate)"
               [container]="'body'"
            ></i>
            <i *ngIf="isTeam"
               class="fal fa-users" style="opacity: .5;"
               [ngbTooltip]="('_ui_milestone_plan_selector_team' | translate)"
               [container]="'body'"
            ></i>
            <i *ngIf="isPersonal"
               class="fal fa-user" style="opacity: .5;"
               [ngbTooltip]="('_ui_milestone_plan_selector_personal' | translate)"
               [container]="'body'"
            ></i>

            <div class="ms-2 d-flex d-mobile-none" *ngIf="showEditable || showDelete">
                <a class="fal fa-edit"
                   *ngIf="showEditable"
                   [class.disabled]="!isEditable"
                   [ngbTooltip]="('_global_edit' | translate)"
                   [container]="'body'"
                   (click)="isEditable ? onEditClick() : null"></a>

                <a class="fal ms-2 d-mobile-none"
                   [class.fa-eye]="!popover.isOpen()"
                   [class.fa-lock]="popover.isOpen()"
                   [ngbPopover]="popoverContent"
                   [autoClose]="false"
                   triggers="manual"
                   container="body"
                   #popover="ngbPopover"
                   (click)="$event.preventDefault(); $event.stopImmediatePropagation(); !popover.isOpen() ? popover.open() : popover.close()"></a>

                <ng-template #popoverContent>
                    <div class="rounded bg-light d-flex align-items-center justify-content-center"
                         style="width: 50vw;">

                        <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>
                        <app-milestone-plan-timeline
                            class="w-100 d-flex align-items-center justify-content-center"
                            [milestonePlan]="milestonePlan"
                            [project]="project"
                        ></app-milestone-plan-timeline>
                    </div>
                </ng-template>

                <a class="fal fa-trash-alt  text-danger ms-2 d-mobile-none"
                   *ngIf="showDelete"
                   [class.disabled]="!isDeletable"
                   [ngbTooltip]="('_global_delete' | translate)"
                   [container]="'body'"
                   (click)="isDeletable ? onDeleteClick() : null"></a>


            </div>
        </div>

    </div>
</ng-container>
