export class StatusItem {
    public name: string;
    public id: number;
    public options: number[];

    public status?: number;

    constructor(name: string, id: number, options: number[]) {
        this.name = name;
        this.id = id;
        this.options = options;
    }

    public setStatus(status?: number) {
        this.status = status;
    }

    public get optionsAsObjects(): {id: number}[] {
        return this.options.map(option => {
            return {
                id: option
            };
        });
    }

}
