/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {MilestonesProjectDefinition,} from './definitions/MilestonesProjectDefinition';

export class MilestonesProject extends MilestonesProjectDefinition {

    constructor(json?: any) {
        super(json);
    }

}
