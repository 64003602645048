import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-cases/ColumnTypeSettings";
import {
    CaseShelves_DaysTaskTypesSettingValue,
    CaseShelves_PresetDeadline_DaySettingValue
} from "@app/core/http/Api/Api";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {CaseRow} from "@app/pages/displays/display-cases/CaseRow";
import {WeekCell} from "@app/pages/displays/display-cases/Cells/WeekCell";
import {WeekTableColumn} from "@app/pages/displays/display-cases/TableColumns/WeekTableColumn";
import {WeekDays} from "@app/pages/displays/display-cases/TableColumns/DaysTableColumn";
import * as moment from "moment";

export class WeekColumn extends BaseColumn<WeekCell> implements ColumnDataFetcherInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TaskFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new TaskFetcher(
            undefined, undefined, undefined,
            column.name
        );
        this.dataFetcher.setTaskTypeIds(this.getTaskTypes());
    }

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new WeekTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: CaseRow): BaseCell {
        const cell = new WeekCell(row, this);
        this.dataFetcher.addRequest(new TaskFetchRequest(cell.listConfiguration));
        return cell;
    }

    public getPresetDeadlineDay(): string {
        return this.settings.get(ColumnTypeSettings.Preset_Deadline_Day)
            ?.getObject<CaseShelves_PresetDeadline_DaySettingValue>()
            ?.day ?? '';
    }

    public getPresetDeadlineDate(periodStart: Date): Date {
        switch (this.getPresetDeadlineDay()) {
            default:
            case WeekDays.Monday:
                return moment(periodStart).startOf('isoWeek').add(0, 'days').toDate();
            case WeekDays.Tuesday:
                return moment(periodStart).startOf('isoWeek').add(1, 'days').toDate();
            case WeekDays.Wednesday:
                return moment(periodStart).startOf('isoWeek').add(2, 'days').toDate();
            case WeekDays.Thursday:
                return moment(periodStart).startOf('isoWeek').add(3, 'days').toDate();
            case WeekDays.Friday:
                return moment(periodStart).startOf('isoWeek').add(4, 'days').toDate();
            case WeekDays.Saturday:
                return moment(periodStart).startOf('isoWeek').add(5, 'days').toDate();
            case WeekDays.Sunday:
                return moment(periodStart).startOf('isoWeek').add(6, 'days').toDate();
        }
    }

    public getTaskTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.Days_TaskTypes)
            ?.getObject<CaseShelves_DaysTaskTypesSettingValue>()
            ?.taskTypeIds ?? [];
    }

    public setPeriod(start: Date, end: Date): void {
        this.dataFetcher.setPeriod(
            moment(start).startOf('isoWeek').toDate(),
            moment(end).endOf('isoWeek').toDate()
        );
    }

}
