import {GenericCell} from "@app/pages/displays/display-project-details/subdisplay-users/Cells/GenericCell";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {StatusTypes, TaskStatusTypes, TaskUserTypes} from "@app/constants";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {
    TaskListAllColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/Columns/TaskListAllColumn";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-users/Row";
import {SubDisplayHelper} from "@app/pages/displays/display-project-details/SubDisplayHelper";

export class TaskListAllCell extends GenericCell {

    public listConfiguration = new TaskListConfiguration();

    constructor(row: Row, column: TaskListAllColumn) {
        super(row, column);

        const taskTypeIdsFilteredByProjectType = row.project.project_type?.project_types_task_types
            ?.filter(projectTypesTaskType => projectTypesTaskType.visible)
            ?.map(projectTypesTaskType => projectTypesTaskType.task_type_id) ?? [];

        this.listConfiguration
            .setLimit(ListConfiguration.SmartLimit)
            .setShowProjectMiniCard(false)
            .setAcceptanceStatus(null)
            .setProject(row.project)
            .setUser(row.userCard.item)
            .setUserTypeId(TaskUserTypes.Participant)
            .setTaskTypeIds(taskTypeIdsFilteredByProjectType)
            .setShowMilestoneMiniCard(true)
            .setUseGlobalFilter(true)
            .setCustomOnCardItemDragAddFunction((cardItem, fromConfiguration) => {
                SubDisplayHelper.DragDropUpdateUser(fromConfiguration, this.listConfiguration, cardItem);
            })
            .setDraggable(true)
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                new TaskUserPresetGenerator(TaskUserTypes.Creator, AppInjector.getInjector().get(UsersService).user.id),
                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                new TaskUseStatusRulesPresetGenerator(true),

                new TaskProjectPresetGenerator(row.project.id),
                new TaskUserPresetGenerator(TaskUserTypes.Participant, row.userCard.item.id),
                ...row.project.departments?.map(department => new TaskDepartmentPresetGenerator(department.id)) ?? [],
            ]);
    }

}
