<ng-container *ngIf="modelId || model">
    <div class="d-flex justify-content-start align-items-center -text-13px"

         *ngIf="model"
         container="body"
         (click)="openUserDashboard($event)"
         [class.text-strong]="displayStrong"
         [class.text-small]="displayStrong"
         [ngbTooltip]="showTooltip ? (label ? label : model?.name) : null">

        <i class="fal fa-chalkboard-teacher me-1 cursor-pointer" aria-hidden="true"
           [ngbTooltip]="('_user_goto_user_overview' | translate)"
           *ngIf="navigateToDashboard && !displayStrong && false"></i>

        <i class="fal fa-user-circle me-1" aria-hidden="true" *ngIf="showIcon"></i>

        <i class="fal fa-id-badge me-1" aria-hidden="true" *ngIf="displayStrong"></i>


        <span *ngIf="showInitials" class="ellipsis flex-fill"
              [class.linkable]="navigateToDashboard"
        >{{ initialsString }}</span>
        <span *ngIf="!showInitials" class="ellipsis flex-fill"
              [class.linkable]="navigateToDashboard"
        >{{fullName ? model?.name : model?.first_name }}</span>


    </div>
    <app-loading-indicator *ngIf="!model" [showLogo]="false"></app-loading-indicator>
</ng-container>
