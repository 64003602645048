import {GenericTableColumn} from "@app/pages/displays/display-kanban/TableColumns/GenericTableColumn";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {PhaseColumn} from "@app/pages/displays/display-kanban/Columns/PhaseColumn";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {TableColumns} from "@app/pages/displays/display-kanban/TableColumns";

export class PhaseTableColumn extends GenericTableColumn {

    public identifier = TableColumns.Phase;

    public column: PhaseColumn;

    constructor(column: BaseColumn) {
        super(column);

        this.projectListConfiguration
            .setCurrentPhaseId(this.column.getFilterPhase())
            .setUseGlobalFilter(true)
            .setDraggable(true)
            .setLimit(ListConfiguration.SmartLimit)
            .setShowCreateNew(true);

        this.projectListConfiguration.showProjectPhase = false;
        this.projectListConfiguration.showProjectPhaseIfHasProgress = true;
    }

}
