import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {CSVColumn} from "@app/export/csv/CSVColumn";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {ProjectCategoryListCell} from "@app/pages/displays/display-projects/Cells/ProjectCategoryListCell";
import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {
    ProjectCategoryListTableColumn
} from "@app/pages/displays/display-projects/TableColumns/ProjectCategoryListTableColumn";
import {CategoryType} from "@app/core/models";

export class ProjectCategoryListColumn extends GenericColumn<ProjectCategoryListCell> {

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new ProjectCategoryListCell(row, this);
    }

    public createTableColumns(): GenericTableColumn[] {
        const tableColumn = new ProjectCategoryListTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public implementsCSVExport = true;

    public toCSVColumn(result: (column: CSVColumn) => void, rows: ProjectsDisplayRow[]): void {
        const column = CSVColumn.CreatePrimary(this, []);

        // Collect all unique category types from all visible rows
        const categoryTypesMap: Map<number, CategoryType> = new Map();
        rows?.forEach(row => {
            row.project?.item.categories?.forEach(category => {
                categoryTypesMap.set(category.category_type_id, category.category_type);
            });
        });
        // Sort category types by index
        const categoryTypes = Array.from(categoryTypesMap.values()).sort((a: CategoryType, b: CategoryType) => a.index_ - b.index_);
        // Add one column per category type and push to columns as columnList
        column.subColumns = categoryTypes.map(categoryType => CSVColumn.CreateSecondary(categoryType.id, categoryType.name));

        result(column);
    }

}
