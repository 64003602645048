import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {Office365Event} from '@app/core/models/Office365Event';
import {QuillEditorConfig} from "@app/constants";
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';

@Component({
    selector: 'app-item-note',
    templateUrl: './item-note.component.html',
    styleUrls: ['./item-note.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemNoteComponent implements OnInit {

    @Input() value: string;
    @Input() model: AnyItem; // TO-DO: Open if model and fields. model.patch(fields)
    @Input() fields: string[];

    @Output() onItemNoteChange: EventEmitter<string> = new EventEmitter<string>();

    editorActive: boolean = false;
    expanded: boolean = false;

    constructor(private dialogueService: BaseDialogService) {
    }

    ngOnInit() {

    }

    public editorDialog() {
        this.dialogueService.richTextEditor(this.value)
            .then((r) => {
                if (r && r.saveItem) {
                    this.onItemNoteChange.emit(r.text);
                }
            })
    }
}
