import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {TodoListConfiguration} from "@app/shared/_ui/lists/todo-list/TodoListConfiguration";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {EventEmitter} from "@angular/core";
import {TableColumns} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/TableColumns";

export class TodoListTableColumn extends BaseTableColumn {

    public identifier = TableColumns.TodoList;

    public listConfiguration = new TodoListConfiguration();
    public reloadEvent = new EventEmitter();

    constructor(column: BaseColumn) {
        super(column);

        this.listConfiguration
            .setArchived(false)
            .setUseGlobalFilter(true)
            .setDraggable(true)
            .setAllowDragEnter(false)
            .setShowCreateNew(true);
    }

}
