import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {TodoCategoryPickerComponent} from "@app/shared/_forms/todo-category-picker/todo-category-picker.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TodoCategoryPickerComponent,
    ],
    exports: [
        TodoCategoryPickerComponent

    ],
    providers: [

    ],
})
export class TodoCategoryPickerModule {}
