import {NgModule} from "@angular/core";
import {
    RichTextEditorDialogComponent
} from "@app/shared/_modals/rich-text-editor-dialog/rich-text-editor-dialog.component";
import {NgxModalDraggableDirectiveModule} from "@app/directives/NgxModalDraggableDirective.module";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RichTextEditorModule} from "@app/shared/_forms/rich-text-editor/RichTextEditor.module";
import {CommonModule} from "@angular/common";
import {GhostContainerModule} from "@app/editor/ghost-container/GhostContainer.module";

@NgModule({
    imports: [
        CommonModule,
        NgxModalDraggableDirectiveModule,
        ColorItemModule,
        NgbTooltipModule,
        TranslateModule,
        ReactiveFormsModule,
        RichTextEditorModule,
        GhostContainerModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        RichTextEditorDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class RichTextEditorDialogModule {}
