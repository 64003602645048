import {DefaultDirections, DefaultDirectionType} from "@app/core/ColumnControl/DefaultDirections";

export class PageColumnSort {

    public sortId: string;
    public defaultDirection: DefaultDirectionType;

    // Filter values
    public projectDeadlineTypeId: number;

    public static CreateWithSortId(sortId: string, defaultDirection: DefaultDirectionType = DefaultDirections.FollowGlobalSetting): PageColumnSort {
        return new PageColumnSort(sortId, defaultDirection);
    }

    public static CreateWithSortIdAndProjectDeadlineTypeId(sortId: string, defaultDirection: DefaultDirectionType, projectDeadlineTypeId: number): PageColumnSort {
        const sort = new PageColumnSort(sortId, defaultDirection);
        sort.projectDeadlineTypeId = projectDeadlineTypeId;
        return sort;
    }

    public constructor(sortId: string, defaultDirection: DefaultDirectionType = DefaultDirections.FollowGlobalSetting) {
        this.sortId = sortId;
        this.defaultDirection = defaultDirection;
    }

}
