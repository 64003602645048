export class LoadCalculateResponse {
    user: {
        id: number,
        string: string
    };
    load: {
        tasks: number,
        events: number,
        total: number
    };

    constructor(data?: any) {
        this.user = data.user;
        this.load = data.load;
    }

}