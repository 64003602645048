import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {
    Projects_ProjectStatusTypesSettingValue
} from "@app/core/http/Api/Api";
import {ProjectStatus} from "@app/core/models";
import {ProjectsService} from "@app/services/projects.service";

@Component({
    selector: 'app-project-status-list-cell',
    templateUrl: './project-status-list-cell.component.html',
    styleUrls: ['./project-status-list-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectStatusListCellComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() row: ProjectsDisplayRow;
    @Input() column: GenericTableColumn;

    // Bindings to view
    public visibleProjectStatusTypeIds?: number[];

    // Data

    constructor(private cd: ChangeDetectorRef,
                private projectsService: ProjectsService) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        const project = this.row.project.item;

        const projectStatusTypeIds = this.column.column.settings.get(ColumnTypeSettings.ProjectStatusTypes)
            ?.getObject<Projects_ProjectStatusTypesSettingValue>()
            ?.projectStatusTypeIds ?? [];

        const projectStatuses: ProjectStatus[] = [];
        projectStatusTypeIds
            ?.forEach(projectStatusTypeId => {
                let status = project.findProjectStatusByType(projectStatusTypeId);

                if (!status) {
                    status = ProjectStatus.createDefault();
                    status.project_status_type_id = projectStatusTypeId;
                }
                if (!status.project_status_type || !status.project_status_type.statuses) {
                    this.projectsService.getProjectType(project.project_type_id, projectType => {
                        const projectStatusTypesProjectType = projectType.getProjectStatusTypesProjectType(projectStatusTypeId);
                        if (projectStatusTypesProjectType) {
                            status.project_status_type = projectStatusTypesProjectType.project_status_type;
                        }
                    });
                }
                projectStatuses.push(status);
            });
        project.project_statuses = projectStatuses;

        this.visibleProjectStatusTypeIds = projectStatusTypeIds;

        this.detectChanges();
    }

}
