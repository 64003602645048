import {NgModule} from "@angular/core";
import {TimeCalculatorComponent} from "@app/shared/_elements/time-calculator/time-calculator.component";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {TranslateModule} from "@ngx-translate/core";
import {NumericSpinnerModule} from "@app/shared/_elements/numeric-spinner/NumericSpinner.module";
import {CommonModule} from "@angular/common";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [
        LocalizedDatePipeModule,
        TranslateModule,
        NumericSpinnerModule,
        CommonModule,
        NgbPopoverModule,
        NgbTooltipModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TimeCalculatorComponent,
    ],
    exports: [
        TimeCalculatorComponent

    ],
    providers: [

    ],
})
export class TimeCalculatorModule {}
