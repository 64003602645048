import {Injectable} from '@angular/core';
import {BaseService} from '@app/services/base.service';
import {ApiFilter, OrderDirection} from '@app/http/APIFilter';
import {Observable, throwError} from 'rxjs';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '@env/environment';
import {catchError, map} from 'rxjs/operators';
import {TaskType} from '@app/core/models/TaskType';
import {ApiCall} from '@app/http/APICall';
import {Task} from '@app/core/models/Task';
import {TaskUserType} from '@app/core/models/TaskUserType';
import {OldApi} from '@app/http/Api';
import {EventService} from '@app/services/event.service';
import {Api} from '@app/core/http/Api/Api';
import {TaskEstimateType} from "@app/core/models";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {System} from '@app/constants';
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";

@Injectable({
    providedIn: 'root'
})
export class TasksService extends BaseService {

    constructor(private filterGlobalService: FilterGlobalService,
                private eventService: EventService) {
        super();
    }

    // <editor-fold desc="Task Type (Queue & Cache)">

    private taskTypeDataCaches: Map<number, ApiDataCache<TaskType>> = new Map();

    public getTaskType(id: number, callback: (taskType: TaskType) => void): any {
        if (!this.taskTypeDataCaches.has(id)) {
            const dataCache = new ApiDataCache<TaskType>(
                `${System.TaskType}-${id}`,
                2 * 60 * 60, // 2 hours
                Api.taskTypes().getById(id)
                    .include('task_types_status_rule?include=status_rule')
                    .include('task_deadline_type')
            );
            this.taskTypeDataCaches.set(id, dataCache);
        }
        this.taskTypeDataCaches.get(id).getFirst(callback);
    }

    // </editor-fold>

    // <editor-fold desc="Task Estimate Type (Queue & Cache)">

    private taskEstimateTypeDataCache = new ApiDataCache<TaskEstimateType>(
        System.TaskEstimateTypes,
        2 * 60 * 60, // 2 hours
        Api.taskEstimateTypes().get()
    );

    public getTaskEstimateTypes(callback: (items: TaskEstimateType[]) => void) {
        this.taskEstimateTypeDataCache.get(callback);
    }

    // </editor-fold>

    // <editor-fold desc="Task Types Simple version (Queue & Cache)">

    private taskTypesDataCache = new ApiDataCache<TaskType>(
        System.TaskTypes,
        2 * 60 * 60, // 2 hours
        Api.taskTypes().get()
            .setShowProgressBar(false)
            .include('department')
    );

    public getTaskTypes(callback: (items: TaskType[]) => void) {
        this.taskTypesDataCache.get(callback);
    }

    // </editor-fold>

    // <editor-fold desc="Task User Type (Queue & Cache)">

    private taskUserTypesDataCache = new ApiDataCache<TaskUserType>(
        System.TaskUserTypes,
        2 * 60 * 60, // 2 hours
        Api.taskUserTypes().get()
    );

    public getTaskUserTypes(callback: (items: TaskUserType[]) => void) {
        this.taskUserTypesDataCache.get(callback);
    }

    public getTaskUserType(id: number, callback: (item: TaskUserType) => void) {
        this.getTaskUserTypes(items => callback(items.find(item => item.id == id)));
    }

    // </editor-fold>

    search(term: string, filters?:ApiFilter): Observable<Task[]> {
        let httpParams = new HttpParams();
        if(!filters) filters = new ApiFilter();

        if(term !== '') {
            filters.search('title', term);
            filters.orderBy('created', OrderDirection.Descending);
            filters.orderBy('title', this.filterGlobalService.getActiveSettings().activeSortDirection);
            httpParams = httpParams.append('limit', '25');
        } else {
            httpParams = httpParams.append('limit', '25');
            filters.orderBy('title', this.filterGlobalService.getActiveSettings().activeSortDirection);
        }

        httpParams = httpParams.append('filter', filters.filtersString());
        httpParams = httpParams.append('ordering', filters.orderingString());

        const api: ApiCall = new ApiCall(OldApi.tasks().toString(), httpParams, (response) => {
            return response.resources.map((item: any) => {
                return new Task(item);
            });
        });

        return api.getHttp();

    }

}
