import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {AuthenticationService} from "@app/core";

@Component({
    selector: 'app-auto-login',
    templateUrl: './auto-login.component.html',
    styleUrls: ['./auto-login.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default, // TO-DO: Changedetection
})
export class AutoLoginComponent implements OnInit {

    constructor(
        private oAuthService: OAuthService,
        private authenticationService: AuthenticationService
    ) {
        // Fjerner token og bruger
        // console.log('initImplicitFlow()');
        // this.oAuthService.initImplicitFlow(); // AutoLogin


        // this.oAuthService.revokeTokenAndLogout();
    }

    ngOnInit() {
        // this.authenticationService.signout();
        this.oAuthService.revokeTokenAndLogout();

        setTimeout(() => {
            window.location.reload();
        }, 1000)
    }

}
