import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DisplayDashboardComponent} from '@app/pages/displays/dashboard/display-dashboard.component';
import {Shell} from '@app/shell/Shell';
import {extract} from '@app/core';
import {DisplayTeamComponent} from '@app/pages/displays/display-team/display-team.component';
import {DisplayDebugComponent} from '@app/pages/displays/display-debug/display-debug.component';
import {DisplayProjectsComponent} from '@app/pages/displays/display-projects/display-projects.component';
import {DisplayKanbanComponent} from "@app/pages/displays/display-kanban/display-kanban.component";
import {
    DisplayKanbanProgressComponent
} from "@app/pages/displays/display-kanban-progress/display-kanban-progress.component";
import {DisplayCategoryComponent} from "@app/pages/displays/display-category/display-category.component";
import {
    DisplayProjectDetailsComponent
} from "@app/pages/displays/display-project-details/display-project-details.component";
import {DisplayDebugModule} from "@app/pages/displays/display-debug/DisplayDebug.module";
import {DisplayDashboardModule} from "@app/pages/displays/dashboard/display-dashboard.module";
import {DisplayProjectsModule} from "@app/pages/displays/display-projects/DisplayProjects.module";
import {DisplayTeamModule} from "@app/pages/displays/display-team/DisplayTeam.module";
import {DisplayKanbanModule} from "@app/pages/displays/display-kanban/DisplayKanban.module";
import {DisplayKanbanProgressModule} from "@app/pages/displays/display-kanban-progress/DisplayKanbanProgress.module";
import {DisplayCategoryModule} from "@app/pages/displays/display-category/DisplayCategory.module";
import {CommonModule} from "@angular/common";


// https://angular-2-training-book.rangle.io/handout/routing/routeparams.html
// https://blog.angularindepth.com/angular-routing-series-pillar-1-router-states-and-url-matching-12520e62d0fc
const routes: Routes = [
    Shell.childRoutes([

        {
            path: 'debug/:projectPlanningId',
            component: DisplayDebugComponent,
            pathMatch: 'full',
            data: {
                title: extract('Debug visning'),
                animation: 'Page'
            }
        },
        {
            path: 'debug',
            component: DisplayDebugComponent,
            pathMatch: 'prefix',
            data: {
                title: extract('Debug visning'),
                animation: 'Page'
            }
        },
        {
            path: 'displays/debug',
            component: DisplayDebugComponent,
            pathMatch: 'prefix',
            data: {
                title: extract('Debug visning'),
                animation: 'Page'
            }
        },

        {
            path: 'displays/list/projects/:displayId/:departmentId/:userId',
            component: DisplayDashboardComponent,
            pathMatch: 'full',
            data: {
                title: extract('Alle projekter'),
                animation: 'Page'
            }
        },
        {
            path: 'displays/list/milestones/:displayId/:departmentId/:userId',
            component: DisplayDashboardComponent,
            pathMatch: 'prefix',
            data: {
                title: extract('Alle Milepæle'),
                animation: 'Page',
            }
        },
        {
            path: 'displays/list/todos/:displayId/:departmentId/:userId/:projectPlanningId',
            component: DisplayDashboardComponent,
            pathMatch: 'prefix',
            data: {
                title: extract('Alle Todos'),
                animation: 'Page',
            }
        },
        {
            path: 'displays/list/tasks/:displayId/:departmentId',
            component: DisplayDashboardComponent,
            pathMatch: 'full',
            children: [
                {
                    path: ':userId',
                    component: DisplayDashboardComponent,
                    children: [
                        {
                            path: ':projectPlanningId',
                            component: DisplayDashboardComponent,
                        },
                    ]
                }
            ],
            data: {
                title: extract('Alle Opgaver'),
                animation: 'Page',
            }
        },

        // Mit overblik
        {
            path: 'displays/dashboard/:displayId/:departmentId',
            pathMatch: 'full',
            redirectTo: 'displays/dashboard/:displayId/:departmentId/0'
        },
        {
            path: 'displays/dashboard/:displayId/:departmentId/:userId', // Display + Department + Valgt bruger
            component: DisplayDashboardComponent,
            pathMatch: 'full',
            data: {
                title: extract('Overview'),
                animation: 'Page',
            }
        },
        {
            path: 'displays/dashboard/:displayId/:departmentId/:userId/:projectPlanningId', // Display + Department + Valgt bruger
            component: DisplayDashboardComponent,
            pathMatch: 'full',
            data: {
                title: extract('Overview'),
                animation: 'Page',
            }
        },

        // Projekttavle
        {
            path: 'displays/projects/:displayId/:departmentId',
            redirectTo: 'displays/projects/:displayId/:departmentId/0',
            pathMatch: 'full'
        },
        {
            path: 'displays/projects/:displayId/:departmentId/:userId',
            component: DisplayProjectsComponent,
            pathMatch: 'full',
            data: {
                title: extract('Projekter'),
                animation: 'Page',
            }
        },
        {
            path: 'displays/projects/:displayId/:departmentId/:userId/:projectPlanningId',
            component: DisplayProjectsComponent,
            pathMatch: 'full',
            data: {
                title: extract('Projekter'),
                animation: 'Page',
            }
        },
        {
            path: 'displays/projects/:displayId/:departmentId/:userId/:projectPlanningId/:programId',
            component: DisplayProjectsComponent,
            pathMatch: 'full',
            data: {
                title: extract('Projekter'),
                animation: 'Page',
            }
        },



        // Teamtavle
        {
            path: 'displays/teams/:displayId/:departmentId',
            pathMatch: 'full',
            redirectTo: 'displays/teams/:displayId/:departmentId/0'
        },
        {
            path: 'displays/teams/:displayId/:departmentId/:userId',
            pathMatch: 'full',
            component: DisplayTeamComponent,
            data: {
                title: extract('Teamdisplay'),
                animation: 'Page',
                reuse: false,
            }
        },
        {
            path: 'displays/teams/:displayId/:departmentId/:userId/:projectPlanningId',
            pathMatch: 'full',
            component: DisplayTeamComponent,
            data: {
                title: extract('Teamdisplay'),
                animation: 'Page',
                reuse: false,
            }
        },

        // Planlægningsrum
        {
            path: 'displays/project-details/:displayId/:departmentId/:userId/:projectId/:previousDisplayId',
            component: DisplayProjectDetailsComponent,
            pathMatch: 'prefix',
            data: {
                title: extract('Planlægningrummet'),
                animation: 'Page',
            }
        },


        // Kanban
        {
            path: 'displays/kanban/:displayId/:departmentId',
            pathMatch: 'full',
            redirectTo: 'displays/kanban/:displayId/:departmentId/0'
        },
        {
            path: 'displays/kanban/:displayId/:departmentId/:userId',
            pathMatch: 'full',
            component: DisplayKanbanComponent,
            data: {
                title: extract('Kanban'),
                animation: 'Page',
            }
        },
        {
            path: 'displays/kanban/:displayId/:departmentId/:userId/:projectPlanningId',
            pathMatch: 'full',
            component: DisplayKanbanComponent,
            data: {
                title: extract('Kanban'),
                animation: 'Page',
            }
        },
        {
            path: 'displays/kanban-progress/:displayId/:departmentId',
            pathMatch: 'full',
            redirectTo: 'displays/kanban-progress/:displayId/:departmentId/0'
        },
        {
            path: 'displays/kanban-progress/:displayId/:departmentId/:userId',
            pathMatch: 'full',
            component: DisplayKanbanProgressComponent,
            data: {
                title: extract('Kanban Progress'),
                animation: 'Page',
            }
        },
        {
            path: 'displays/kanban-progress/:displayId/:departmentId/:userId/:projectPlanningId',
            pathMatch: 'full',
            component: DisplayKanbanProgressComponent,
            data: {
                title: extract('Kanban Progress'),
                animation: 'Page',
            }
        },


        // Category
        {
            path: 'displays/categories/:displayId/:departmentId',
            pathMatch: 'full',
            redirectTo: 'displays/categories/:displayId/:departmentId/0'
        },
        {
            path: 'displays/categories/:displayId/:departmentId/:userId',
            pathMatch: 'full',
            component: DisplayCategoryComponent,
            data: {
                title: extract('Category'),
                animation: 'Page',
            }
        },
        {
            path: 'displays/categories/:displayId/:departmentId/:userId/:projectPlanningId',
            pathMatch: 'full',
            component: DisplayCategoryComponent,
            data: {
                title: extract('Category'),
                animation: 'Page',
            }
        },

    ])
];

@NgModule({
    imports: [
        CommonModule,
        DisplayDebugModule,
        DisplayDashboardModule,
        DisplayProjectsModule,
        DisplayTeamModule,
        DisplayKanbanModule,
        DisplayKanbanProgressModule,
        DisplayCategoryModule,

        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
})
export class DisplaysRoutingModule {
}
