import {Project, ProjectType} from "@app/core/models";

export class AttachedType {

    public modelType: ProjectType;
    public itemType: string;
    public items: Project[] = [];
    public visible: boolean = false;

    constructor(itemType: string) {
        this.itemType = itemType;
    }

    public addItem(item: Project) {
        this.items.push(item);
        this.visible = true;
    }

    public removeItem(item: Project) {
        this.items.splice(this.items.findIndex(i => i.id == item.id), 1);
    }

}
