import {MissingTranslationHandler, MissingTranslationHandlerParams} from "@ngx-translate/core";

export class MyMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams): any {

        // if(!environment.production) console.warn(`Missing translation: ${params.key}`);
        //return environment.production ? params.key : '*' + params.key;
        return params.key;
    }
}
