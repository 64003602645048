import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ResizableModule} from 'angular-resizable-element';
import {
    DisplayProjectDetailsComponent
} from "@app/pages/displays/display-project-details/display-project-details.component";
import {HeaderModule} from "@app/shell/header/header.module";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {PhasesProjectPickerModule} from "@app/shared/_elements/phases-project-picker/PhasesProjectPicker.module";
import {
    MilestonePlanSelectorModule
} from "@app/shared/_elements/milestone-plan-selector/MilestonePlanSelector.module";
import {NgbNavModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {
    NavFilterSelectorModule
} from "@app/pages/displays/display-project-details/components/nav-filter-selector/NavFilterSelector.module";
import {
    DisplayProjectDetailsTopModule
} from "@app/pages/displays/display-project-details/subdisplay-top/DisplayProjectDetailsTop.module";
import {
    DisplayProjectDetailsMilestonesModule
} from "@app/pages/displays/display-project-details/subdisplay-milestones/DisplayProjectDetailsMilestones.module";
import {
    DisplayProjectDetailsUsersModule
} from "@app/pages/displays/display-project-details/subdisplay-users/DisplayProjectDetailsUsers.module";
import {
    DisplayProjectDetailsTasksModule
} from "@app/pages/displays/display-project-details/subdisplay-tasks/DisplayProjectDetailsTasks.module";
import {BrowserModule} from '@angular/platform-browser';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        TranslateModule,
        NgxDatatableModule,
        DragDropModule,
        ResizableModule,
        HeaderModule,
        ToggleItemModule,
        PhasesProjectPickerModule,
        MilestonePlanSelectorModule,
        NgbTooltipModule,
        LoadingIndicatorModule,
        NavFilterSelectorModule,
        DisplayProjectDetailsTopModule,
        NgbNavModule,
        DisplayProjectDetailsMilestonesModule,
        DisplayProjectDetailsUsersModule,
        DisplayProjectDetailsTasksModule,
    ],
    declarations: [
        DisplayProjectDetailsComponent,
    ],

})
export class DisplayProjectDetailsModule {

}
