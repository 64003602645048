<a
    *ngIf="isVisible"
    role="button" class="me-1"
    [ngbTooltip]="toolTip | translate"
    (click)="onButtonClicked()">
    <i class="fal" aria-hidden="true"
       [class.fa-arrow-up-from-dotted-line]="showArrowUp"
       [class.fa-arrow-down-from-dotted-line]="!showArrowUp"
    ></i>
</a>
