import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditText} from "@app/editor/quick-editor/editors/generic/text-editor/EditText";
import {EditTextConfiguration} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextConfiguration";
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {QuillEditorConfig} from "@app/constants"
import {isNull} from "lodash";

@Component({
    selector: 'app-text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEditorComponent extends BaseEditor<EditText> {
    protected eventFieldName?: string = 'note-field';

    // Bindings to parent
    @Input() model: EditText & HasEventGenerator & BaseModel;
    @Input() configuration: EditTextConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public value: string;
    public isExpanded = false;

    // Data
    private isFocused = false;

    constructor(private cd: ChangeDetectorRef, private dialogService: BaseDialogService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscribe(this.configuration?.onChangeEvent.subscribe(() => this.render()));
    }

    protected setup() {
    }

    protected getEventNameOptions(): any {
        return {
            prop: this.configuration.prop
        };
    }

    protected render() {
        if (this.isFocused) {
            return;
        }
        this.value = this.model.getText(this.configuration.prop);
        this.isExpanded = this.configuration?.isExpanded;
        this.configuration.validator.validate(this.value, this.configuration.label);
        this.detectChanges();
    }

    public openEditorDialog() {
        this.dialogService.richTextEditor(this.value).then((response) => {
            if (response && response.saveItem) {
                this.value = response.text ?? '';
                this.save();
            }
        });
    }

    public save() {
        if (isNull(this.value)) {
            this.value = '';
        }
        this.configuration.validator.validate(this.value, this.configuration.label);

        if (this.model.getText(this.configuration.prop).localeCompare(this.value) !== 0) {
            this.model.setText(this.configuration.prop, this.value);
            this.onItemUpdated();
            this.render();
        }
    }

    public toggleIsExpanded() {
        this.configuration?.setIsExpanded(!this.isExpanded);
    }

    public onFocusChanged(value: boolean) {
        this.isFocused = value;
        if (!value) {
            this.save();
        }
    }

    protected readonly QuillEditorConfig = QuillEditorConfig;
}
