import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {TableColumns} from "@app/pages/displays/display-projects/TableColumns";
import {TaskListAllColumn} from "@app/pages/displays/display-projects/Columns/TaskListAllColumn";

export class TaskListAllTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TaskListAll;

    public column: TaskListAllColumn;

}
