/**
 * Created by ModelParser
 */
import {ProjectsUser} from '../ProjectsUser';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class ProjectsUserPlanDefinition extends BaseModel {
    projects_user_id?: number;
    projects_user?: ProjectsUser;
    start?: string;
    end?: string;
    week_day?: number;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.projects_user_id;
            delete this.projects_user;
            delete this.start;
            delete this.end;
            delete this.week_day;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.projects_user_id != null) {
            this.projects_user_id = data.projects_user_id;
        }
        if (data.projects_user != null) {
            this.projects_user = new ProjectsUser(data.projects_user);
        }
        if (data.start != null) {
            this.start = data.start;
        }
        if (data.end != null) {
            this.end = data.end;
        }
        if (data.week_day != null) {
            this.week_day = data.week_day;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
