import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns/GenericTableColumn";
import {
    MilestoneListUserColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/Columns/MilestoneListUserColumn";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-users/Filters";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns";

export class MilestoneListUserTableColumn extends GenericTableColumn {

    public identifier = TableColumns.MilestoneListUser;

    public column: MilestoneListUserColumn;

    constructor(column: MilestoneListUserColumn) {
        super(column);
        this.sortItems = [
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneUserDefined),
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneTitle),
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneDeadline),
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneStatus),
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneResponsible),
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneStars),
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneHands),
        ];
    }

}
