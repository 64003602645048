const BasicInformation = {
    Tab: 7,
    Title: 1, // Requireable
    Notes1: 3, // Tidligere  "Delivery Description" // Requireable
    Notes2: 4, // Tidligere  "Success Criteria" // Requireable
    Deadlines: 8,
    Estimates: 11,
    Customers: 15, // No longer in use (Deleted in 2.27.0)
    StatusRules: 35,
    CanCopy: 36,
    CaseValues: 37,
};

const AttachTo = {
    Tab: 19,
    Reference: 2, // Tidligere  "Purpose"
    Milestone: 6,
    Project: 10,
    YearWheel: 12, // No longer in use (Deleted in 2.27.0)
    Obs: 13, // Requireable
    Departments: 14,
    StrategyGoals: 17,
    Tags: 21,
};

const Categories = {
    Tab: 42,
}

export const Fields = {
    BasicInformation: BasicInformation,
    Responsible: 9,
    IsPrivate: 16,
    AttachTo: AttachTo,
    Log: 20,
    CalculatedFields: 39,
    Categories: Categories,
};

export const MiniCardFields = {
    Status: 23,
    Title: 24,
    Archived: 25,
    HandUp: 26,
    Star: 27,
    Estimate: 28,
    Plan: 29,
    Reached: 30,
    Who: 31,
    Deadline: 32,
    Customer: 33, // No longer in use
    Project: 34,
    CaseValues: 38,
    CalculatedFields: 40,
    Milestones: 41,
};
