import {NgModule} from "@angular/core";
import {ValueEvaluatorComponent} from "@app/shared/_elements/value-evaluator/value-evaluator.component";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ValueEvaluatorComponent,
    ],
    exports: [
        ValueEvaluatorComponent

    ],
    providers: [

    ],
})
export class ValueEvaluatorModule {}
