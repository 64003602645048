/**
 * Created by ModelParser
 */
import {Import} from '../Import';
import {Project} from '../Project';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class OriginDefinition extends BaseModel {
    type_id?: number;
    foreign_id?: string;
    import_id?: number;
    import?: Import;
    projects?: Project[];
    users?: User[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.type_id;
            delete this.foreign_id;
            delete this.import_id;
            delete this.import;
            delete this.projects;
            delete this.users;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.type_id != null) {
            this.type_id = data.type_id;
        }
        if (data.foreign_id != null) {
            this.foreign_id = data.foreign_id;
        }
        if (data.import_id != null) {
            this.import_id = data.import_id;
        }
        if (data.import != null) {
            this.import = new Import(data.import);
        }
        if (data.projects != null) {
            this.projects = data.projects.map((i: any) => new Project(i));
        }
        if (data.users != null) {
            this.users = data.users.map((i: any) => new User(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
