import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {SwUpdate, UpdateAvailableEvent} from '@angular/service-worker';
import {merge, Observable, of, Subject, Subscription, timer} from 'rxjs';
import {environment} from '@env/environment';
import {map} from 'rxjs/operators';
import {versions} from "@env/versions";
import {BaseComponent} from "@app/shared/_system/base/base.component";

@Component({
    selector: 'app-updates-notification',
    templateUrl: './updates-notification.component.html',
    styleUrls: ['./updates-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdatesNotificationComponent extends BaseComponent {

    // https://stackoverflow.com/questions/50968902/angular-service-worker-swupdate-available-not-triggered
    // http://localhost:4300/ngsw/state

    updateAvailable$: Observable<boolean>;
    closed$ = new Subject<void>();
    public updateEvent: UpdateAvailableEvent;

    version: string = environment.version;
    environment: any = environment;

    gitVersions: {
        npm_version: string;
        revision: string;
        branch: string;
        version: string;
        project_id: string
    } = versions;

    countDown: Subscription;
    counter = 120;
    tick = 1000;

    constructor(public updates: SwUpdate, private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
        this.updateAvailable$ = merge(
            of(false),
            this.updates.available.pipe(map(() => true)),
            this.closed$.pipe(map(() => false)),
        );

        if (updates.isEnabled) {
            updates.checkForUpdate().then(response => {
                if (response) {
                    this.createCountdown();
                }
            })
        }

        updates.available.subscribe(event => {
            // Create countdown
            this.createCountdown();
            this.updateEvent = event;
            console.log('current version is', event.current);
            console.log('available version is', event.available);
        });
        updates.activated.subscribe(event => {
            console.log('old version was', event.previous);
            console.log('new version is', event.current);
        });
    }

    createCountdown() {
        this.countDown = timer(0, this.tick)
            .subscribe(() => {
                --this.counter;
                if (this.counter == 0) {
                    location.reload();
                    this.countDown = null;
                }
                this.detectChanges();
            })
    }

    activateUpdate() {
        if (environment.production) {
            this.updates.activateUpdate().then(() => {
                window.location.reload();
            });
        }else {
            window.location.reload();
        }
    }

    ngOnDestroy() {
        this.countDown = null;
    }

}



