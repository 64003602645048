import {Column, ColumnSetting} from "@app/core/models";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";

export abstract class BaseColumn<T extends BaseCell = BaseCell> {

    public columnType: BaseColumnType;
    public column: Column;
    public settings: Map<string, ColumnSetting>;

    // Table-stuff
    protected tableColumns?: BaseTableColumn[];

    // Available interfaces
    public implementsDataFetching?: boolean; // ColumnDataFetcherInterface
    public implementsSorting?: boolean; // SortableColumnInterface

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        this.columnType = columnType;
        this.column = column;
        this.settings = settings;
    }

    public abstract createTableColumns(): BaseTableColumn[];

    public getTableColumns<T extends BaseTableColumn = BaseTableColumn>(): T[] {
        if (!this.tableColumns) {
            this.tableColumns = this.createTableColumns();
        }
        return this.tableColumns as T[];
    }

    public get isVisible(): boolean {
        return this.getVisibleTableColumns().length > 0;
    }

    public getVisibleTableColumns<T extends BaseTableColumn = BaseTableColumn>(): T[] {
        return this.getTableColumns()
            .filter(tableColumn => tableColumn.isVisible) as T[];
    }

    protected setTableColumnDefaults(column: BaseTableColumn) {
        column.cellTemplate = this.columnType.cellTemplate;
        column.headerTemplate = this.columnType.headerTemplate;
        column.customWidth = this.columnType.customWidth;
        column.minWidth = this.columnType.minWidth;
        column.maxWidth = this.columnType.maxWidth;
        column.resizeable = this.columnType.resizeable;
        column.frozenLeft = this.columnType.frozenLeft;
        column.canAutoResize = this.columnType.canAutoResize;
        column.isList = this.columnType.isList;
        column.cellClass = this.columnType.cellClass;

        if (this.columnType.onScreenFilters) {
            column.setOnScreenFilters(this.columnType.onScreenFilters);
        }
    }

    public abstract createCell(row: BaseRow): BaseCell;

    public getCell(row: BaseRow): T | undefined {
        return row.getCell<T>(this);
    }

}
