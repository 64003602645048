import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {TranslateModule} from "@ngx-translate/core";
import {ItemDateModule} from "@app/shared/_elements/item-date/ItemDate.module";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";
import {
    ProjectDeadlineDifferenceCellComponent
} from "@app/pages/displays/display-projects/CellComponents/project-deadline-difference-cell/project-deadline-difference-cell.component";
import {DeadlineDifferenceModule} from "@app/shared/_elements/deadline-difference/DeadlineDifference.module";

@NgModule({
    imports: [
        CommonModule,
        UserWithIconModule,
        TranslateModule,
        ItemDateModule,
        TypedTemplateDirective,
        DeadlineDifferenceModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectDeadlineDifferenceCellComponent,
    ],
    exports: [
        ProjectDeadlineDifferenceCellComponent

    ],
    providers: [

    ],
})
export class ProjectDeadlineDifferenceCellModule {}
