import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {TaskListNextMilestoneColumn} from "@app/pages/displays/display-projects/Columns/TaskListNextMilestoneColumn";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {Milestone} from "@app/core/models";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {StatusTypes, TaskDeadlineTypes, TaskStatusTypes, TaskUserTypes} from "@app/constants";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskMilestonePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskMilestonePresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {
    TaskDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDeadlinePresetGenerator";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";
import {CSVListBinding} from "@app/export/csv/CSVListBinding";

export class TaskListNextMilestoneCell extends GenericCell {

    public listConfiguration = new TaskListConfiguration();
    public csvListBinding = new CSVListBinding();

    constructor(row: ProjectsDisplayRow, column: TaskListNextMilestoneColumn) {
        super(row, column);

        this.listConfiguration
            //.setLimit(2)
            .setLimit(ListConfiguration.SmartLimit)
            // .setArchived(false) https://podio.com/klartboard/softwareudvikling/apps/stories/items/695
            //.setDepartment(this.department) https://podio.com/klartboard/softwareudvikling/apps/stories/items/363
            .setProject(row.project.item)
            .setTaskTypeIds(column.getTaskTypes())
            .setShowProjectMiniCard(false)
            .setAcceptanceStatus(null)
            .setMilestone(row.nextMilestone?.item ?? new Milestone())
            .setCustomOnCardItemDragAddFunction((cardItem, fromConfiguration) => {
                const task = cardItem.item;

                const previousProject = fromConfiguration.getProject();
                const previousMilestone = fromConfiguration.getMilestone();

                if (previousProject && previousMilestone) {
                    const nextProject = row.project.item;
                    const nextMilestone = row.nextMilestone?.item;

                    task.replaceProject(previousProject, nextProject);
                    task.replaceMilestone(previousMilestone, nextMilestone);
                }
            })
            .setDraggable(true)
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                // Defaults
                new TaskUserPresetGenerator(TaskUserTypes.Creator, AppInjector.getInjector().get(UsersService).user.id),
                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                new TaskUseStatusRulesPresetGenerator(true),

                new TaskProjectPresetGenerator(row.project.item.id),
                new TaskMilestonePresetGenerator(() => row.nextMilestone?.item?.id),
                ...row.project.item.departments?.map(department => new TaskDepartmentPresetGenerator(department.id)) ?? [],
                ...row.nextMilestone?.item?.deadline ? [
                    new TaskDeadlinePresetGenerator(
                        TaskDeadlineTypes.Normal,
                        row.nextMilestone.item.deadline.getDate(),
                        row.nextMilestone.item.deadline.is_soft
                    )
                ] : [],
                ...row.nextMilestone?.item?.responsible ? [
                    new TaskUserPresetGenerator(
                        TaskUserTypes.Participant,
                        row.nextMilestone.item.responsible.id,
                        row.nextMilestone.item.deadline
                    )
                ] : [],
            ]);
    }

    public exportCSV(secondary?: any): CSVCellValue {
        return this.csvListBinding.export();
    }

}
