import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";

export class ProjectCalculatedFieldListCell extends GenericCell {

    public exportCSV(secondary?: any): CSVCellValue {
        return this.row.project.item.calculated_fields_projects
            ?.map(calculatedFieldsProject => {
                return calculatedFieldsProject?.calculated_field?.standardizedValue(calculatedFieldsProject.value);
            })
            ?.join(', ') ?? '';
    }

}
