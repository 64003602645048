import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {
    ProjectUserListColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/ProjectUserListColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns";

export class ProjectUserListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.ProjectUserList;

    public column: ProjectUserListColumn;

}
