import {NgModule} from "@angular/core";
import {DisplayListComponent} from "@app/shell/header/display-list/display-list.component";
import {RouterModule} from "@angular/router";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';
import {ToggleItemModule} from '@app/shared/_elements/toggle-item/ToggleItem.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbTooltipModule,
        TranslateModule,
        ToggleItemModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DisplayListComponent,
    ],
    exports: [
        DisplayListComponent

    ],
    providers: [

    ],
})
export class DisplayListModule {}
