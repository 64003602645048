<div class="d-flex flex-column align-items-center justify-content-center description-block">
    <p *ngIf="title"
       [attr.data-color]="color"
       [class.ps-2]="item.configuration && item.startDate < item.configuration.periodStart"
       [class.pe-2]="item.configuration && item.endDate > item.configuration.periodEnd"
       class="w-100 text-center ellipsis title-block" [ngbTooltip]="title">{{ title }}</p>

    <div class="d-flex justify-content-between align-items-center text-center header-text w-100"
         *ngIf="item.isStartDateVisible || item.isEndDateVisible">
        <div class="start-date ellipsis"
             *ngIf="item.isStartDateVisible && item.startDate">{{ item.startDate | localizedDate: 'microDate' }}
        </div>
        <div class="end-date ellipsis"
             *ngIf="item.isEndDateVisible && item.endDate">{{ item.endDate | localizedDate: 'microDate' }}
        </div>
    </div>
</div>

<div class="block-line" [style.background-color]="color" *ngIf="color" [attr.data-color]="color"
     [ngbTooltip]="title ? title : null"
>
    <div class="start-cap" *ngIf="item.configuration && item.startDate < item.configuration.periodStart">
        <i class="fal fa-arrow-left"></i>
    </div>
    <div class="end-cap" *ngIf="item.configuration && item.endDate > item.configuration.periodEnd">
        <i class="fal fa-arrow-right"></i>
    </div>
</div>
