<div class="">
    <svg width="261" height="261" viewBox="0 0 261 261" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 1">
            <path id="Arrow 1" d="M17 0L8.33974 15L25.6602 15L17 0ZM18.5 245L18.5 13.5L15.5 13.5L15.5 245L18.5 245Z"
                  class="fill"/>
            <path id="Arrow 2" d="M260.5 243.5L245.5 234.84V252.16L260.5 243.5ZM15.5 245L247 245V242L15.5 242V245Z"
                  class="fill"/>

            <text
                id="Ressourcer"
                class="fill"
                style="white-space: pre;"
                font-family="sans-serif"
                text-anchor="middle"
                font-size="12"
                letter-spacing="0em">
                <tspan x="132" y="258.102">{{ axisX }}</tspan>
            </text>

            <g transform="translate(29,20)">
                <rect
                    id="Rectangle 1" width="100" height="100" rx="5"
                    [style.fill]="color1"
                />
                <text class="text-red name-text" x="50" y="50"
                      alignment-baseline="middle" font-size="12" stroke-width="0" text-anchor="middle">{{ text1 }}</text>
            </g>

            <g transform="translate(140,20)">
                <rect
                    id="Rectangle 2" width="100" height="100" rx="5"
                    [style.fill]="color2"
                />
                <text class="text-red name-text" x="50" y="50"
                      alignment-baseline="middle" font-size="12" stroke-width="0" text-anchor="middle">{{ text2 }}</text>
            </g>

            <g transform="translate(29,132)">
                <rect
                    id="Rectangle 3" width="100" height="100" rx="5"
                    [style.fill]="color3"
                />
                <text class="text-red name-text" x="50" y="50"
                      alignment-baseline="middle" font-size="12" stroke-width="0" text-anchor="middle">{{ text4 }}</text>
            </g>

            <g transform="translate(140,132)">
                <rect
                    id="Rectangle 4" width="100" height="100" rx="5"
                    [style.fill]="color4"
                />
                <text class="text-red name-text" x="50" y="50"
                      alignment-baseline="middle" font-size="12" stroke-width="0" text-anchor="middle">{{ text4 }}</text>
            </g>


            <text id="Effekt" transform="translate(1.52588e-05 245) rotate(-90)" class="fill" style="white-space: pre;"
                  font-family="sans-serif" text-anchor="middle" font-size="12" letter-spacing="0em">
                <tspan x="118" y="11.1016" dominant-baseline="middle" text-anchor="middle">{{ axisY }}</tspan>
            </text>
        </g>
    </svg>
</div>
