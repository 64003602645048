import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-users/Columns/TaskEditColumn";
import {
    BaseEditorTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/BaseEditorTableColumn";
import {TextColumnConfiguration} from "@app/editor/quick-editor/columns/generic/text-column/TextColumnConfiguration";
import {
    EditorWithColumnInterface
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/EditorWithColumnInterface";
import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {StatusEditorComponent} from "@app/editor/quick-editor/editors/generic/status-editor/status-editor.component";
import {TextColumnComponent} from "@app/editor/quick-editor/columns/generic/text-column/text-column.component";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns";

export class TaskEdit_Status_TableColumn extends BaseEditorTableColumn {

    public identifier = TableColumns.TaskEdit_Status;

    public column: TaskEditColumn;
    public minWidth = 36;
    public maxWidth = 36;
    public columnConfiguration: TextColumnConfiguration;
    public editorWithColumn: EditorWithColumnInterface;

    constructor(column: TaskEditColumn) {
        super(column);
        this.name = '_displays_project_details_subdisplay_task_edit_minicard_status';

        this.columnConfiguration = new TextColumnConfiguration(
            '',
            false,
            false
        );
        this.columnConfiguration.savedWidth = this.width > 0 ? this.width : undefined;
        this.columnConfiguration.minWidth = this.minWidth;
        this.columnConfiguration.maxWidth = this.maxWidth;
        this.columnConfiguration.canAutoResize = false;
        this.columnConfiguration.onResizeEndEvent.subscribe(() => {
            this.width = this.columnConfiguration.width;
        });

        const editorConfiguration = new BaseEditorConfiguration();
        editorConfiguration.widthSynchronizer = this.columnConfiguration.widthSynchronizer;

        this.editorWithColumn = {
            name: StatusEditorComponent.name,
            columnName: TextColumnComponent.name,
            columnConfiguration: this.columnConfiguration,
            configuration: editorConfiguration,
        };
    }

}
