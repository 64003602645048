<app-loading-indicator *ngIf="loading" [showLogo]="false" class="mt-1 mb-1 align-self-center justify-self-center"></app-loading-indicator>

<div class="timeline-grid scroll-x bg-light border-light w-100"
     (click)="generateItems()"
     *ngIf="!loading"
>

    <ng-container *ngFor="let item of timelineItems">
        <div class="{{item.type}}"
             *ngIf="item.deadline"
             [style.grid-column-start]="item.columnStart"
             [style.grid-column-end]="item.columnEnd"

             [style.grid-row-start]="item.row"
             [style.grid-row-end]="item.row+1"

             [ngbPopover]="popoverContent"
             triggers="mouseenter:mouseleave"
             container="body"
             #popover="ngbPopover"
        >
            <div class="label"
                 [style.background-color]="item?.color"
            >{{item.label}}</div>
        </div>

        <!-- Grid -->
        <div class="{{item.type}}"
             *ngIf="!item.deadline"
             [style.grid-column-start]="item.columnStart"
             [style.grid-column-end]="item.columnEnd"
        >
        </div>

        <ng-template #popoverContent>
            <ul class="list-unstyled">
                <li class="text-bold d-flex align-items-center">

                    <span class="me-1">{{item.name}}</span>

                    <div class="badge bg-secondary rounded-pill ms-auto"
                         *ngIf="item.itemType"
                         [style.background-color]="item?.color">{{item.itemType}}</div>
                </li>
                <li>{{item.deadline.format('DD/MM-YYYY')}}</li>
                <li *ngIf="item.description">
                    <p>{{item.description}}</p>
                </li>

                <pre class="debug" *ngIf="false">{{item | json}}</pre>
            </ul>
        </ng-template>

    </ng-container>
</div>
