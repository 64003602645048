import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {TodoPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/TodoPresetType";

export class TodoShowOnDisplayPresetGenerator implements CreatePresetGenerator {

    private readonly showOnDisplay?: boolean;
    private readonly showOnDisplayHandler?: () => boolean;

    constructor(showOnDisplayOrHandler: boolean | (() => boolean)) {
        if (typeof showOnDisplayOrHandler == "boolean") {
            this.showOnDisplay = showOnDisplayOrHandler;
        } else {
            this.showOnDisplayHandler = showOnDisplayOrHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: TodoPresetTypes.ShowOnDisplay,
            payload: {
                showOnDisplay: this.showOnDisplay ?? this.showOnDisplayHandler(),
            }
        };
    }

}
