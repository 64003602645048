import {BaseComparison} from "@app/shared/_ui/lists/BaseComparison";

export class SortItem {

    public comparison: BaseComparison;
    public direction: string;

    public constructor(comparison: BaseComparison, direction: string) {
        this.comparison = comparison;
        this.direction = direction;

    }

}
