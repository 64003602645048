<div class="d-flex flex-row justify-content-between mb-0 align-items-center">
    <div class="d-flex flex-row flex-fill align-items-start">
        <div class="d-flex align-items-center justify-content-between flex-grow-1" style="min-width: 246px;">
            <div class="input-group-text d-flex align-items-center me-1">
                <app-toggle-item
                    [interactive]="true"
                    [class.text-success]="item.isArchived"
                    [class.text-muted]="!item.isArchived"
                    [active]="item.isArchived"
                    [icon]="'fa-check-circle'"
                    [iconInactive]="'fa-circle'"
                    [ngbTooltip]="item.isArchived ? ('_ui_reopen' | translate) : ('_ui_complete' | translate)"
                    (onToggle)="setArchived($event)"></app-toggle-item>
            </div>

            <app-user-search
                [multiple]="false"
                [removeOnBlur]="true"
                [showDeleteButton]="false"
                [showFilterButton]="false"
                [_internalValue]="item.user"
                [selectedUsers]="selectedUsers"
                [autoFocus]="!item.user"
                (itemSelected)="setUser($event)"
                class="flex-fill align-self-start w-100 flex-grow-1 me-1"
            ></app-user-search>
        </div>

        <app-datepicker
            class="mb-0 align-self-end flex-grow-1" style="max-width: 230px;"
            [internalValue]="item.deadline"
            (valueChanged)="setDeadline($event)"
        ></app-datepicker>
    </div>

    <a *ngIf="item.user"
       class="ms-1 btn btn-outline-danger" (click)="removeItem()">
        <i class="fal fa-trash-alt"></i>
    </a>

</div>
