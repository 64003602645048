/**
 * Created by ModelParser
 */
import {User} from '../User';
import {Project} from '../Project';
import {Task} from '../Task';
import {Milestone} from '../Milestone';
import {Todo} from '../Todo';
import {TasksUser} from '../TasksUser';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class ArchivedDefinition extends BaseModel {
    user_id?: number;
    user?: User;
    projects?: Project[];
    tasks?: Task[];
    milestones?: Milestone[];
    todos?: Todo[];
    tasks_users?: TasksUser[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.user_id;
            delete this.user;
            delete this.projects;
            delete this.tasks;
            delete this.milestones;
            delete this.todos;
            delete this.tasks_users;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.projects != null) {
            this.projects = data.projects.map((i: any) => new Project(i));
        }
        if (data.tasks != null) {
            this.tasks = data.tasks.map((i: any) => new Task(i));
        }
        if (data.milestones != null) {
            this.milestones = data.milestones.map((i: any) => new Milestone(i));
        }
        if (data.todos != null) {
            this.todos = data.todos.map((i: any) => new Todo(i));
        }
        if (data.tasks_users != null) {
            this.tasks_users = data.tasks_users.map((i: any) => new TasksUser(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
