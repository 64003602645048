import {EventEmitter, Injectable} from "@angular/core";
import {FocusableTarget} from "@app/core/Focusable/FocusableTarget";
import {FocusableEntityType} from "@app/core/Focusable/FocusableEntityTypes";
import {FocusableInterface} from "@app/core/Focusable/FocusableInterface";
import {Subscription} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FocusableService {

    private targetMap = new Map<FocusableEntityType, Map<number, FocusableTarget[]>>();
    private targetsChangeEvents = new Map<FocusableEntityType, Map<number, EventEmitter<number>>>();

    constructor() {

    }

    // <editor-fold desc="Target registration">

    public register(type: FocusableEntityType, identifier: number, implementation: FocusableInterface): Subscription {
        const target = new FocusableTarget(
            this,
            implementation,
            type,
            identifier
        );
        const list = this.getTargetsForTypeAndIdentifier(type, identifier);
        list.push(target);
        this.getListenersForTypeAndIdentifier(type, identifier).emit(list.length);
        return target;
    }

    public unregister(type: FocusableEntityType, identifier: number, item: FocusableTarget): void {
        const list = this.getTargetsForTypeAndIdentifier(type, identifier);
        const index = list.indexOf(item);
        if (index !== -1) {
            list.splice(index, 1);
        }
        this.getListenersForTypeAndIdentifier(type, identifier).emit(list.length);
    }

    private getTargetsForTypeAndIdentifier(type: FocusableEntityType, identifier: number): FocusableTarget[] {
        if (!this.targetMap.has(type)) {
            this.targetMap.set(type, new Map<number, FocusableTarget[]>());
        }

        const identifierMap = this.targetMap.get(type);
        if (!identifierMap.has(identifier)) {
            identifierMap.set(identifier, []);
        }
        return identifierMap.get(identifier);
    }

    // </editor-fold>

    // <editor-fold desc="Target listeners">

    public hasTargets(type: FocusableEntityType, identifier: number): boolean {
        return this.getTargetsForTypeAndIdentifier(type, identifier).length > 0;
    }

    public subscribeToTargetChanges(type: FocusableEntityType, identifier: number, next: (value: number) => void): Subscription {
        return this.getListenersForTypeAndIdentifier(type, identifier).subscribe(next);
    }

    private getListenersForTypeAndIdentifier(type: FocusableEntityType, identifier: number): EventEmitter<number> {
        if (!this.targetsChangeEvents.has(type)) {
            this.targetsChangeEvents.set(type, new Map<number, EventEmitter<number>>());
        }

        const identifierMap = this.targetsChangeEvents.get(type);
        if (!identifierMap.has(identifier)) {
            identifierMap.set(identifier, new EventEmitter<number>());
        }
        return identifierMap.get(identifier);
    }

    // </editor-fold>

    // <editor-fold desc="Target focus">

    public focus(type: FocusableEntityType, identifier: number, position: number = 0) {
        const list = this.getTargetsForTypeAndIdentifier(type, identifier);
        if (list.length > 0) {
            list[position % list.length].focus();
        }
    }

    // </editor-fold>

}
