import {AnyItem} from '@app/interfaces/CustomTypes';
import {CardConfiguration} from '@app/shared/_ui/cards/CardConfiguration';
import {CardTaskConfiguration} from "@app/shared/_ui/cards/medium/card-task/card-task-configuration";
import {CardProjectConfiguration} from "@app/shared/_ui/cards/medium/card-project/card-project-configuration";
import {CardTodoConfiguration} from "@app/shared/_ui/cards/medium/card-todo/card-todo-configuration";
import {CardMilestoneConfiguration} from "@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration";
import {CdkDropEvent} from "@app/interfaces/CdkDropEvent";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";

export interface CardItemWrapperI<T = AnyItem> {
    date?: Date;
    items: CardItem<T>[];
}

export interface CardInterface<T = AnyItem> {
    onCardItemUpdated(cardItem: CardItem<T>): void;
    onCardItemDeleted(cardItem: CardItem<T>): void;
    onCardItemDragged(cardItem: CardItem<T>, listConfiguration: ListConfiguration<T>, toContainerId: string, fromContainerId: string, cdkDropEvent: CdkDropEvent<T>): void;
}

export class CardItem<T = AnyItem> {
    type: string;
    item: T;
    configuration: CardConfiguration<T>;

    constructor(item: T, configuration?: CardConfiguration<T>, listener?: CardInterface<T>) {
        this.type = item.constructor.name;
        this.item = item;
        this.configuration = configuration;
        this.listener = listener;
    }

    // <editor-fold desc="Event Handling"

    private listener: CardInterface<T>;

    public setListener(listener: CardInterface<T>): void {
        this.listener = listener;
    }

    public emitUpdated() {
        if(this.listener) {
            this.listener.onCardItemUpdated(this);
        }
    }

    public emitDeleted() {
        if(this.listener) {
            this.listener.onCardItemDeleted(this);
        }
    }

    public emitDragged(event: CdkDropEvent<T>) {
        if(this.listener) {
            this.listener.onCardItemDragged(this, null, event.container.id, event.previousContainer.id, event);
        }
    }

    public emitDragged2(listConfiguration: ListConfiguration<T>, toContainerId: string) {
        if(this.listener) {
            this.listener.onCardItemDragged(this, listConfiguration, toContainerId, null, null);
        }
    }

    // </editor-fold>

    public compare(item: CardItem): boolean {
        //console.warn('compare', this, item);
        let cardItem = this as any as CardItem;
        let validId = item.item.id == cardItem.item.id;
        if(item.configuration == null && cardItem.configuration == null)
            return validId;
        else if(item.configuration != null && cardItem.configuration == null)
            return false;
        else if(item.configuration == null && cardItem.configuration != null)
            return false;
        else if(validId) {
            if(item.configuration.constructor.name != cardItem?.configuration?.constructor.name)
                return false;
            else { // Same configuration
                switch(item.configuration.constructor) {
                    case CardTaskConfiguration:
                        return (item.configuration as CardTaskConfiguration).compare(cardItem.configuration as CardTaskConfiguration);
                    case CardProjectConfiguration:
                        return (item.configuration as CardProjectConfiguration).compare(cardItem.configuration as CardProjectConfiguration);
                    case CardTodoConfiguration:
                        return (item.configuration as CardTodoConfiguration).compare(cardItem.configuration as CardTodoConfiguration);
                    case CardMilestoneConfiguration:
                        return (item.configuration as CardMilestoneConfiguration).compare(cardItem.configuration as CardMilestoneConfiguration);
                }
            }
        }
        return false;
    }

    public exists(): boolean {
        return (this.item as any).exists();
    }
}
