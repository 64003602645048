import {NgModule} from "@angular/core";
import {
    TemplateStatusRuleRowComponent
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-editor/partials/template-status-rule-row.component";
import {StatusRuleItemModule} from "@app/shared/_forms/status-rule-item/StatusRuleItem.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        StatusRuleItemModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TemplateStatusRuleRowComponent,
    ],
    exports: [
        TemplateStatusRuleRowComponent

    ],
    providers: [

    ],
})
export class TemplateStatusRuleRowModule {}
