<div class="container d-flex gap-1 p-2">

    <div class="sidebar bg-light p-2 rounded">
        <label>{{'_ui_restore_item_types' | translate}}</label>
        <select
            [(ngModel)]="types"
            (ngModelChange)="onSelectedTypesChanged()"
            [multiple]="true"
            class="form-select form-select-sm"
        >
            <option value="project">{{'_projects' | translate}}</option>
            <option value="task">{{'_tasks' | translate}}</option>
            <option value="milestone">{{'_milestones' | translate}}</option>
        </select>

        <div class="d-flex flex-column gap-1">

            <label>{{'_period_singular' | translate}}</label>

            <div class="d-flex align-items-center">
                <label for="periodStart">
                    {{'_ui_start_date' | translate}}
                </label>
                <input
                    [ngModel]="periodStart"
                    id="periodStart"
                    #periodStartPicker="ngbDatepicker"
                    class="form-control"
                    ngbDatepicker
                    autocomplete="off"
                    (click)="periodStartPicker.toggle();"
                    (dateSelect)="onPeriodChanged();"
                />
            </div>


        <div class="d-flex align-items-center gap-1">
                <label for="periodEnd">
                    {{'_ui_end_date' | translate}}
                </label>

                <input
                    [ngModel]="periodEnd"
                    id="periodEnd"
                    class="form-control"
                    #periodEndPicker="ngbDatepicker"
                    ngbDatepicker
                    autocomplete="off"
                    (click)="periodEndPicker.toggle();"
                    (dateSelect)="onPeriodChanged();"
                />
            </div>

        <div class="input-group">
            <input type="search" class="form-control form-control-sm"
                   autocomplete="off"
                   [placeholder]="('_ui_search' | translate) + '...' "
                   [(ngModel)]="searchValue"
                   (search)="onSearchBtnClicked()"
                   (ngModelChange)="onSearchValueChanged($event)"
            />

        </div>
            <button role="button" (click)="onSearchBtnClicked()" class="btn -btn-success btn-sm btn-outline-primary"
            ><i
                class="fal fa-search me-1" aria-hidden="true"></i>{{ '_ui_search' | translate }}...
            </button>

        </div>



    </div>

    <div class="container bg-light p-2 rounded scroll-y">
        <!-- list of cards -->
        <ul class="list-unstyled item-list rounded gap-1">
        <li
            class="d-flex flex-column gap-0 align-items-start border rounded"
            *ngFor="let item of items"
        >
            <div class="w-100 border-bottom d-flex align-items-center p-2">
                <span class="text-small">{{item.deletion.created | localizedDate}} {{item.deletion.created | date: 'HH:mm' }}</span>

                <button
                    class="btn btn-sm btn-outline-danger ms-auto align-self-start"
                    (click)="onRestoreBtnClicked(item)"
                >{{'_ui_restore' | translate}}

                </button>

            </div>
            <div class="d-flex flex-row gap-2 align-items-center p-2 w-100">

            <div class="d-flex column gap-2 align-items-center flex-shrink-1 flex-wrap">
                <app-card-switcher
                    *ngFor="let card of item.cards"
                    [cardItem]="card"

                />
            </div>


    </div>
        </li>
        </ul>
    </div>

</div>

