import {NgModule} from "@angular/core";
import {UserSearchComponent} from "@app/shared/_forms/search/user-search/user-search.component";
import {NgbPopoverModule, NgbTooltipModule, NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {DepartmentPickerModule} from "@app/shared/_forms/department-picker/DepartmentPicker.module";
import {FormsModule} from "@angular/forms";
import {AutofocusDirectiveModule} from "@app/directives/AutofocusDirective.module";
import {CommonModule} from "@angular/common";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";

@NgModule({
    imports: [
        NgbTypeaheadModule,
        TranslateModule,
        NgbTooltipModule,
        DepartmentPickerModule,
        NgbPopoverModule,
        FormsModule,
        AutofocusDirectiveModule,
        CommonModule,
        TypedTemplateDirective

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        UserSearchComponent,
    ],
    exports: [
        UserSearchComponent

    ],
    providers: [

    ],
})
export class UserSearchModule {}
