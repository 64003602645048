import {NgModule} from "@angular/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NumberEditorComponent} from "@app/editor/quick-editor/editors/generic/number-editor/number-editor.component";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule,
        FormsModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        NumberEditorComponent,
    ],
    exports: [
        NumberEditorComponent

    ],
    providers: [

    ],
})
export class NumberEditorModule {}
