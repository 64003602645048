/**
 * Created by ModelParser
 * Date: 14-05-2021.
 * Time: 13:03.
 */
import {CalculatedFieldsTaskDefinition} from './definitions/CalculatedFieldsTaskDefinition';
import {ChangeEvent, WampService, WampSubscription} from "../../../services/wamp.service";

export class CalculatedFieldsTask extends CalculatedFieldsTaskDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static CreateChangeSubscription(event: string, wampService: WampService, handler: (event: ChangeEvent<CalculatedFieldsTask>) => void): WampSubscription {
        return wampService.subscribe(event, data => handler(CalculatedFieldsTask.ParseChangeEvent(data)));
    }

    public static ParseChangeEvent(data: any): ChangeEvent<CalculatedFieldsTask> {
        const previous = new CalculatedFieldsTask(data.previous);
        const next = new CalculatedFieldsTask(data.next);
        return new ChangeEvent<CalculatedFieldsTask>(previous, next);
    }

}
