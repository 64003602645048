import {NgModule} from "@angular/core";
import {
    ChangeProjectTypeDialogComponent
} from "@app/shared/_modals/change-project-type-dialog/change-project-type-dialog.component";
import {NgxModalDraggableDirectiveModule} from "@app/directives/NgxModalDraggableDirective.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgxModalDraggableDirectiveModule,
        DragDropModule,
        ColorItemModule,
        NgbTooltipModule,
        TranslateModule,
        LoadingIndicatorModule,
        FormsModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ChangeProjectTypeDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class ChangeProjectTypeDialogModule {}
