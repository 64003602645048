import {NgModule} from "@angular/core";
import {FilterColumnComponent} from "@app/shell/header/filters/filter-column/filter-column.component";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {CommonModule} from "@angular/common";
import {ColorItemModule} from '@app/shared/_ui/color-item/ColorItem.module';

@NgModule({
    imports: [
        CommonModule,
        ToggleItemModule,
        TranslateModule,
        LoadingIndicatorModule,
        ColorItemModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        FilterColumnComponent,
    ],
    exports: [
        FilterColumnComponent

    ],
    providers: [

    ],
})
export class FilterColumnModule {}
