import {BaseComponent} from "@app/shared/_system/base/base.component";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-on-screen-filter-selector',
    templateUrl: './on-screen-filter-selector.component.html',
    styleUrls: ['./on-screen-filter-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnScreenFilterSelectorComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() onScreenFilters: BaseOnScreenFilter<any>[];
    @Input() showMultiColumnFilters = true; // If filter shared on multiple columns, only show filter if showMultiColumnFilters is true (ie. Kanban display)
    public hasEnabledOnScreenFilter = false;
    public activeFilters: BaseOnScreenFilter<any>[];

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private enabledChangeEventSubscription: Subscription;
    private render() {
        this.enabledChangeEventSubscription?.unsubscribe();
        this.enabledChangeEventSubscription = new Subscription();
        this.onScreenFilters?.forEach(filter => {
            this.enabledChangeEventSubscription.add(
                filter.onEnabledChangeEvent.subscribe(_ => this.checkHasEnabledOnScreenFilter())
            );
        });

        this.activeFilters = this.onScreenFilters.filter(filter => {
            return filter.visible && (this.showMultiColumnFilters && filter.multiColumn || (!this.showMultiColumnFilters && !filter.multiColumn))
        })
        this.checkHasEnabledOnScreenFilter();
    }

    private checkHasEnabledOnScreenFilter() {
        this.hasEnabledOnScreenFilter = this.onScreenFilters.find(filter => filter.getEnabled()) !== undefined;
        this.detectChanges();
    }

    resetFilters() {
        this.onScreenFilters.forEach(f => f.setEnabled(false));
        this.checkHasEnabledOnScreenFilter();
    }
}
