import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters as ProjectsFilters} from "@app/pages/displays/display-projects/Filters";
import {ProjectCardTableColumn} from "@app/pages/displays/display-projects/TableColumns/ProjectCardTableColumn";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {ProjectCardCell} from "@app/pages/displays/display-projects/Cells/ProjectCardCell";
import {CSVColumn} from "@app/export/csv/CSVColumn";
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";

export class ProjectCardColumn extends GenericColumn<ProjectCardCell> {

    public implementsCSVExport = true;

    public createTableColumns(): GenericTableColumn[] {
        const item = new ProjectCardTableColumn(this);
        this.setTableColumnDefaults(item);

        item.sortItems = [
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortTitle),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortResponsible),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortDeadline),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortStatus),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortStarred),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortHandUp),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortPhase),
        ];
        return [item];
    }

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new ProjectCardCell(row, this);
    }

    public toCSVColumn(result: (column: CSVColumn) => void): void {
        result(CSVColumn.CreatePrimary(this, [
            CSVColumn.CreateSecondary('name', this.column.name),
            CSVColumn.CreateSecondary('archived', `${(AppInjector.getInjector().get(TranslateService).instant('_ui_closed'))}`)
        ]));
    }

}
