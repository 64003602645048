/**
 * Created by ModelParser
 * Date: 11-12-2019.
 * Time: 13:46.
 */
import {ProjectTypesStaticDeadlineDefinition} from './definitions/ProjectTypesStaticDeadlineDefinition';

export class ProjectTypesStaticDeadline extends ProjectTypesStaticDeadlineDefinition {

    constructor(json?: any) {
        super(json);
    }

}
