<div class="d-flex flex-column h-100">
    <div class="title-block text-center d-flex justify-content-center align-content-center">
        <span class="align-self-center">{{'_ui_tasks_and_todos' | translate}}</span>
    </div>
    <div class="sidebar-wrapper rounded m-2 flex-fill d-flex flex-column">
        <div
            ngbDropdown
            container="body"
            class="d-flex flex-row"
            placement="bottom-left"
        >
            <a
                id="create-item-dropdown"
                class="d-flex flex-row align-items-center m-2 flex-fill"
                ngbDropdownToggle
            >
                <span class="flex-fill">{{sortFilterName ? sortFilterName : ('_task_deadline' | translate)}}</span>
            </a>
            <div ngbDropdownMenu
                 aria-labelledby="create-item-dropdown"
                 class="position-absolute"
            >
                <a class="dropdown-item d-flex flex-row align-items-center"
                   (click)="sortBy(sortFilter.DeadlineDate, $any($event.target).innerText)">
                    <app-toggle-item class="me-1"
                                     [icon]="'fal fa-check-circle text-succcess'"
                                     [iconInactive]="'fal fa-circle'"
                                     [active]="sortFilterValue == sortFilter.DeadlineDate">
                    </app-toggle-item>
                    <span >{{'_displays_project_task_deadline' | translate}}</span>
                </a>
                <a class="dropdown-item d-flex flex-row align-items-center"
                   (click)="sortBy(sortFilter.PlanningDate, $any($event.target).innerText)">
                    <app-toggle-item class="me-1"
                                     [icon]="'fal fa-check-circle text-succcess'"
                                     [iconInactive]="'fal fa-circle'"
                                     [active]="sortFilterValue == sortFilter.PlanningDate">
                    </app-toggle-item>
                    <span >{{'_ui_planned_date' | translate}}</span>
                </a>
                <a class="dropdown-item d-flex flex-row align-items-center"
                   (click)="sortBy(sortFilter.Status, $any($event.target).innerText)">
                    <app-toggle-item class="me-1"
                                     [icon]="'fal fa-check-circle text-succcess'"
                                     [iconInactive]="'fal fa-circle'"
                                     [active]="sortFilterValue == sortFilter.Status">
                    </app-toggle-item>
                    <span >{{'_task_status' | translate}}</span>
                </a>
                <a class="dropdown-item d-flex flex-row align-items-center"
                   (click)="sortBy(sortFilter.Starred, $any($event.target).innerText)">
                    <app-toggle-item class="me-1"
                                     [icon]="'fal fa-check-circle text-succcess'"
                                     [iconInactive]="'fal fa-circle'"
                                     [active]="sortFilterValue == sortFilter.Starred">
                    </app-toggle-item>
                    <span >{{'_ui_starred' | translate}}</span>
                </a>
                <a class="dropdown-item d-flex flex-row align-items-center"
                   (click)="sortBy(sortFilter.HandUp, $any($event.target).innerText)">
                    <app-toggle-item class="me-1"
                                     [icon]="'fal fa-check-circle text-succcess'"
                                     [iconInactive]="'fal fa-circle'"
                                     [active]="sortFilterValue == sortFilter.HandUp">
                    </app-toggle-item>
                    <span >{{'_ui_hand_up' | translate}}</span>
                </a>
                <a class="dropdown-item d-flex flex-row align-items-center"
                   (click)="sortBy(sortFilter.WithoutDeadlineAndPlan, $any($event.target).innerText)">
                    <app-toggle-item class="me-1"
                                     [icon]="'fal fa-check-circle text-succcess'"
                                     [iconInactive]="'fal fa-circle'"
                                     [active]="sortFilterValue == sortFilter.WithoutDeadlineAndPlan">
                    </app-toggle-item>
                    <span >{{'_no_deadline_not_planned' | translate}}</span>
                </a>
                <a class="dropdown-item d-flex flex-row align-items-center"
                   (click)="sortBy(sortFilter.Project, $any($event.target).innerText)">
                    <app-toggle-item class="me-1"
                                     [icon]="'fal fa-check-circle text-succcess'"
                                     [iconInactive]="'fal fa-circle'"
                                     [active]="sortFilterValue == sortFilter.Project">
                    </app-toggle-item>
                    <span >{{'_project_attachment' | translate}}</span>
                </a>


                <a class="dropdown-item d-flex flex-row align-items-center"
                   *ngIf="false"
                   (click)="sortBy(sortFilter.CanCopy, $any($event.target).innerText)">
                    <app-toggle-item class="me-1"
                                     [icon]="'fal fa-check-circle text-succcess'"
                                     [iconInactive]="'fal fa-circle'"
                                     [active]="sortFilterValue == sortFilter.CanCopy">
                    </app-toggle-item>
                    <span >{{'_task_can_copy' | translate}}</span>
                </a>
            </div>
        </div>


        <div class="scroll-y align-self-stretch tab-wrapper">
            <ul ngbNav
                #nav="ngbNav"
                class="nav-tabs tabs"
                (navChange)="tabChange($event)"
                [(activeId)]="selectedTab">

                <!-- Opgaver -->
                <li [ngbNavItem]="tabs.Tasks" *ngIf="taskListTableColumn">
                    <a ngbNavLink>
                        <span >{{taskListTableColumn.name | translate}}</span></a>
                    <ng-template ngbNavContent>
                        <div class="container p-2 d-flex flex-column">
                            <app-task-list class="w-100 flex-fill item-list"
                                           [configuration]="taskListTableColumn.listConfiguration"
                                           (dataSetChanged)="tasksListDataSetChanged($event)"
                                           [reloadEvent]="taskListTableColumn.reloadEvent"
                            />
                        </div>
                    </ng-template>
                </li>

                <!-- Gentagne -->
                <li [ngbNavItem]="tabs.TasksCanBeCopied" *ngIf="taskCanCopyListTableColumn">
                    <a ngbNavLink>
                        <span >{{taskCanCopyListTableColumn.name | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container p-2 d-flex flex-column">
                            <app-task-list class="w-100 flex-fill mt-2 pt-2"
                                           [configuration]="taskCanCopyListTableColumn.listConfiguration"
                                           (dataSetChanged)="tasksListDataSetChanged($event)"
                                           [reloadEvent]="taskCanCopyListTableColumn.reloadEvent"
                            />
                        </div>
                    </ng-template>

                </li>

                <!-- To-dos -->
                <li [ngbNavItem]="tabs.Todos" *ngIf="todoListTableColumn">
                    <a ngbNavLink>
                        <span >{{todoListTableColumn.name | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container p-2 d-flex flex-column">
                            <app-todo-list class="w-100 flex-fill item-list"
                                           [configuration]="todoListTableColumn.listConfiguration"
                                           (dataSetChanged)="todoListDataSetChanged($event)"
                                           [reloadEvent]="todoListTableColumn.reloadEvent"
                            />
                        </div>
                    </ng-template>
                </li>

                <!-- Bestillinger -->
                <li [ngbNavItem]="tabs.TaskOrders" *ngIf="taskOrderListTableColumn && taskOrderList">
                    <a ngbNavLink><span >{{taskOrderListTableColumn.name | translate}}</span></a>
                    <ng-template ngbNavContent>
                        <div class="container p-2 d-flex flex-column">
                            <ul class="list-unstyled scroll-y mt-2">
                                <li *ngFor="let item of taskOrderList">
                                    <app-card-task
                                        [item]="item"
                                        [model]="item.item"
                                        [configuration]="$any(item.configuration)"></app-card-task>
                                </li>
                            </ul>
                        </div>
                    </ng-template>
                </li>

                <!-- Milestones -->
                <li [ngbNavItem]="tabs.Milestones" *ngIf="milestoneListTableColumn">
                    <a ngbNavLink>
                        <span >{{milestoneListTableColumn.name | translate}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container p-2 d-flex flex-column">
                            <app-milestone-list class="w-100 flex-fill item-list"
                                                [configuration]="milestoneListTableColumn.listConfiguration"
                                                [reloadEvent]="milestoneListTableColumn.reloadEvent"
                            />
                        </div>
                    </ng-template>
                </li>


            </ul>
            <div [ngbNavOutlet]="nav" class="categories ngb-tabs-content"></div>
        </div>
    </div>
</div>
