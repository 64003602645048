import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {CaseUser} from '@app/core/models/Task';
import {User} from '@app/core/models/User';
import {TasksUser} from '@app/core/models/TasksUser';
import {EstimateUnits, TaskUserTypes} from '@app/constants';
import {Deadline} from '@app/core/models/Deadline';
import {TaskListConfiguration} from '@app/shared/_ui/lists/task-list/TaskListConfiguration';
import Helpers from '@app/core/helpers';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {Project} from '@app/core/models/Project';
import {AppInjector} from '@app/services/app-injector.service';
import {TasksService} from '@app/services/tasks.service';
import {TaskEstimate} from '@app/core/models/TaskEstimate';
import {Api} from '@app/core/http/Api/Api';
import {Subscription as RXJSSubscription} from 'rxjs/internal/Subscription';
import {EditorPanelService} from "@app/services/editor-panel.service";
import {Estimate, Task} from "@app/core/models";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {TaskPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/TaskPresetType";
import {ShellService} from "@app/services/ShellService/shell.service";

@Component({
    selector: 'app-case-user-expander',
    templateUrl: './case-user-expander.component.html',
    styleUrls: ['./case-user-expander.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseUserExpanderComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @ViewChild('popover') public popover: NgbPopover;
    @Input() items: CardItem<CaseUser>[];
    @Input() listConfiguration: TaskListConfiguration;
    @Input() updateValuesEvent: EventEmitter<any>;

    // Bindings to view
    public totalPlanned: number;
    public totalReached: number;
    public selectedUsers: User[] = [];
    public showValues = false;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.updateValues();

        if (this.updateValuesEvent) {
            this.subscribe(this.updateValuesEvent.subscribe((_: any) => this.updateValues()));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['items'] != null) {
            this.updateValues();
        }
    }

    private updateValues() {
        this.totalReached = 0;
        this.totalPlanned = 0;
        this.showValues = false;
        const result: User[] = [];
        if (this.items && this.items.length > 0) {
            this.items.forEach(item => {
                this.totalPlanned += item.item.cases_planned ? item.item.cases_planned : 0;
                this.totalReached += item.item.cases_reached ? item.item.cases_reached : 0;
            });

            let users: User[] = [];
            // Fjern opretter: https://podio.com/klartboard/softwareudvikling/apps/stories/items/1415
            this.items.forEach(cardItem => {
                users = cardItem.item.tasks_users ?
                    users
                        .concat(cardItem.item.tasks_users
                            .filter(tu => tu.task_user_type_id != TaskUserTypes.Creator)
                            .map(taskUser => taskUser.user)) : [];
            });

            const map = new Map();
            for (const item of users) {
                if (item && item.id && !map.has(item.id)) {
                    map.set(item.id, true);    // set any value to Map
                    result.push(item);
                }
            }
        }
        this.selectedUsers = result;
        this.showValues = true;
        this.detectChanges();
    }

    public open() {
        this.popover.isOpen() ? this.popover.close() : this.popover.open();
    }

    public changeCasesPlanned(value: number, item: CardItem<CaseUser>) {
        const task = item.item;

        task.setCasesPlanned(value);

        // 2.3.0 Set estimate based on project estimate https://podio.com/klartboard/softwareudvikling/apps/stories/items/331
        // https://podio.com/klartboard/softwareudvikling/apps/stories/items/873

        Api.projects() // Find projektets estimat
            .get()
            .include('project_estimate')
            .where('task.id', item.item.id)
            .find(projects => {
                const firstProjectEstimateWithHourUnit = projects
                    .find(project => {
                        return project.project_estimates?.some(projectEstimate => projectEstimate.estimate?.estimate_unit_id == EstimateUnits.Hours);
                    })
                    ?.project_estimates
                    ?.find(projectEstimate => projectEstimate.estimate?.estimate_unit_id == EstimateUnits.Hours);

                if (firstProjectEstimateWithHourUnit) {
                    const taskEstimateValue = firstProjectEstimateWithHourUnit.estimate.value * value;

                    // Opdater task estimat hvis der allerede findes et
                    const firstTaskEstimateWithHourUnit = task.task_estimates?.find(taskEstimate => taskEstimate.estimate.estimate_unit_id == EstimateUnits.Hours);
                    if (firstTaskEstimateWithHourUnit) {
                        task.setEstimate(
                            firstTaskEstimateWithHourUnit.task_estimate_type_id,
                            Estimate.Create(taskEstimateValue, EstimateUnits.Hours)
                        );
                    }

                    // Ellers opret nyt estimat, hvis typen er opsat til estimater
                    else {
                        AppInjector.getInjector().get(TasksService).getTaskType(task.task_type_id, taskType => {
                            const firstTaskEstimateType = taskType.task_estimate_types_task_types[0]?.task_estimate_type;
                            if (firstTaskEstimateType) {
                                task.setEstimate(
                                    firstTaskEstimateType.id,
                                    Estimate.Create(taskEstimateValue, EstimateUnits.Hours)
                                );
                            }
                        });
                    }
                }
            });

        this.updateValues();
    }

    changeCasesReached(value: number, item: CardItem<CaseUser>) {
        item.item.setCasesReached(value);
        this.updateValues();
    }

    createNewCaseWithUser($event: User) {
        if (!$event) {
            return;
        }

        this.listConfiguration.createItemConfiguration.sourceInterface.prepareSource();
        const taskTypeId = this.listConfiguration.createItemConfiguration.filterTaskTypesById[0];
        const presets = this.listConfiguration.createPresets(taskTypeId, {
            user: $event
        }) ?? [];

        Task.Create(taskTypeId, presets);
    }

    openCase(item: CardItem<CaseUser>) {
        AppInjector.getInjector().get(EditorPanelService).open(item.item);
    }

}
