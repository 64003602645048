import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input,
    OnChanges,
    OnInit, Output,
    SimpleChanges
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {UserTypeItem} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserTypeItem";
import {Deadline, User} from "@app/core/models";
import {TasksUserAcceptanceStatus} from "@app/constants";
import {UserItem} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserItem";


@Component({
    selector: 'app-user-list-editor-row',
    templateUrl: './user-list-editor-row.component.html',
    styleUrls: ['./user-list-editor-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListEditorRowComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() type!: UserTypeItem;
    @Input() userItem!: UserItem;
    @Output() userSelected = new EventEmitter<User>();
    @Output() userRemoved = new EventEmitter<any>();
    @Output() archiveChanged = new EventEmitter<boolean>();
    @Output() deadlineChanged = new EventEmitter<Deadline | undefined>();

    // Bindings to view
    public showAcceptanceStatus = false;
    public showArchivedToggle = false;
    public showDatePicker = false;
    public user: User;
    public isArchived?: boolean;
    public acceptanceStatus?: number;
    public deadline?: Deadline;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.render();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['type']) {
            this.render();
        }
        if (changes['userItem']) {
            this.render();
        }
    }

    protected render() {
        if (!this.type || !this.userItem) {
            return;
        }

        this.showAcceptanceStatus = this.type.showAcceptanceStatus;
        this.showArchivedToggle = this.type.showArchived;
        this.showDatePicker = this.type.showDatePicker;

        this.user = this.userItem.user;
        this.acceptanceStatus = this.userItem.acceptanceStatus;
        this.isArchived = this.userItem.archived !== undefined;
        this.deadline = this.userItem.deadline;
    }

    // <editor-fold desc="View actions">

    public onArchivedToggled() {
        this.isArchived = !this.isArchived;
        this.archiveChanged.emit(this.isArchived);
    }

    public onUserSelected(user: User) {
        if (user) {
            this.userSelected.emit(user);
        } else {
            this.userRemoved.emit();
        }
    }

    public onDeleteBtnClicked() {
        this.userRemoved.emit();
    }

    public onDeadlineValueChanged(deadline: Deadline) {
        this.deadline = deadline;
        this.deadlineChanged.emit(deadline.date ? deadline : undefined);
    }

    // </editor-fold>

    protected readonly TasksUserAcceptanceStatus = TasksUserAcceptanceStatus;
}
