import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output, ViewChild
} from '@angular/core';
import {CardComponent} from '@app/shared/_ui/cards/card.component';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {CardConfiguration} from '@app/shared/_ui/cards/CardConfiguration';
import {Roster} from '@app/core/models/Task';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {EventService} from "@app/services/event.service";
import {Field, TaskEditorService} from '@app/editor/task-editor-loader/task-editor.service';
import {AppInjector} from '@app/services/app-injector.service';
import {MiniCardFields} from '@app/editor/task-editor-loader/Fields';
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";

@Component({
    selector: 'app-card-roster',
    templateUrl: './card-roster.component.html',
    styleUrls: ['./card-roster.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardRosterComponent extends CardComponent implements OnInit {

    @Input() item: CardItem<Roster>;
    @Input() configuration: CardConfiguration;
    @Input() listConfiguration: ListConfiguration;
    @Input() model: any;
    @Input() removable = false;
    @Input() tooltip: string;
    @Input() editable = false;
    @Input() interactive = true;
    @Output() onDelete = new EventEmitter();
    @Output() onCardClickEvent = new EventEmitter();
    @Output() onRemove = new EventEmitter();
    @ViewChild('cardContainer') cardContainer: ElementRef;
    public fields: Map<number, Field>;

    // Data
    private editorService: TaskEditorService;

    constructor(protected cd: ChangeDetectorRef,
                private eventService: EventService,
                ) {
        super();
        this.cdr = cd;
        this.editorService = AppInjector.getInjector().get(TaskEditorService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.editorService.getMiniCardFieldsForType(this.model.task_type_id, fields => {
            this.fields = fields;
            // this.markChangeDetectionDirty();
        });


        // Push
        this.eventService.subscribeToTask(0, event => {
            switch (event.action) {
                case EventService.Created:
                case EventService.Updated:

                    if(this.item.item.id == event.item.id){
                        console.log('subscribeToTask : ','this.status: ',this.item.item.status, '> updated task: ', event.item.main_status_id)
                        if(event.item.main_status_id && !this.item.item.main_status_id || this.item.item.main_status_id != event.item.main_status_id){
                            // this.model.status = this.item.item.status = event.item.main_status_id;
                            // Load status
                            this.render();
                            this.markChangeDetectionDirty();
                        }
                    }
                    break;
            }
        });
    }

    onArchivedToggle($event: any) {
        if (this.model.archived_id) {
            delete this.model.archived;
            this.model.archived_id = 0;
            this.model.patch(['archived', 'archived_id']);
        } else
            this.model.archive();
    }

    get Fields() {
        return MiniCardFields;
    }

    onItemUpdated(item: Roster) {
        super.onItemUpdated(item);
        this.render();
    }

    protected render() {
        super.render();

        this.initialized = true;
        this.markChangeDetectionDirty();
    }

    onDeleteEvent(model: AnyItem, event: MouseEvent) {
        event.stopImmediatePropagation();
        this.onDelete.emit(model);
    }

    onStatusChange($event: number) {
        if (this.model.id !== 0 && !this.editable) {
            this.item.item.setStatus($event);
        }
    }

    public isFieldVisible(fieldId: number): boolean {
        if (this.fields && this.fields.get(fieldId)) {
            return this.fields.get(fieldId).visible;
        } else {
            return false;
        }
    }

}
