/**
 * Created by ModelParser
 * Date: 09-11-2020.
 * Time: 15:53.
 */
import {CalculatedFieldDefinition} from './definitions/CalculatedFieldDefinition';
import {CalculatedFieldTypes} from '@app/constants';

export class CalculatedField extends CalculatedFieldDefinition {

    constructor(json?: any) {
        super(json);
    }

    /**
     * Value is always a SI-unit
     * @param value
     * @param type
     */
    public standardizedValue(value: number, type?: string) {
        switch (type ? type : this.type) {
            case CalculatedFieldTypes.RelatedTasks: // Time
            case CalculatedFieldTypes.Milestone_RelatedTasksEstimateSum: // Time
                return Math.round(value / 60 / 60);
            case CalculatedFieldTypes.Task_WeekPlanningDateChanges: // Time
                return value;
            default:
                return Math.round(value / 60 / 60);
        }
    }

}
