import {
    ElementRef,
    EventEmitter,
    Injectable,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {AppInjector} from '@app/services/app-injector.service';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {CardConfiguration} from '@app/shared/_ui/cards/CardConfiguration';
import {EventService} from '@app/services/event.service';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {Subscription} from 'rxjs';
import {CoreService} from "../../../../services/core.service";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {entriesIn} from "lodash";
import {FocusableService} from "@app/core/Focusable/Focusable.service";
import {FocusableEntityTypes, FocusableEntityTypesHelper} from "@app/core/Focusable/FocusableEntityTypes";
import {FocusableInterface} from "@app/core/Focusable/FocusableInterface";

@Injectable()
export abstract class CardComponent extends BaseDisplayComponent implements OnInit, OnChanges, OnDestroy, FocusableInterface {

    // Abstract properties must be implemented by every CardComponent
    abstract item: CardItem<any>;
    abstract tooltip: string;
    abstract model: any;
    abstract configuration: CardConfiguration;
    abstract listConfiguration: ListConfiguration;
    abstract cardContainer: ElementRef;

    abstract interactive: boolean;  // true
    abstract removable: boolean;    // false
    abstract editable: boolean;     // false

    abstract onRemove: EventEmitter<any>;
    abstract onCardClickEvent: EventEmitter<any>;

    // Shared properties
    public simple = false;
    public type = 'None';
    public visible = true;
    public outline: boolean;

    // Data
    private eventServiceSubscription: Subscription;
    private focusableSubscription: Subscription;
    private coreService: CoreService;
    private filterGlobalService: FilterGlobalService;

    protected constructor() {
        super();
        this.initialized = false;
        this.coreService = AppInjector.getInjector().get(CoreService);
        this.filterGlobalService = AppInjector.getInjector().get(FilterGlobalService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.render(); // TO-DO: Kan denne flyttes til et andet event, ngOnAfterInit?
        this.updateVisibility();
        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(() => this.updateVisibility()));

        this.subscribe(AppInjector.getInjector().get(EditorPanelService).editor$.subscribe(item => {
            if (item.item.constructor.name == this.model.constructor.name && item.item.id == this.model.id) {
                this.outline = true;
                this.detectChanges();
            }
        }));
        this.subscribe(AppInjector.getInjector().get(EditorPanelService).closeItem$.subscribe(item => {
            if (item.constructor.name == this.model.constructor.name && item.id == this.model.id) {
                this.outline = false;
                this.detectChanges();
            }
        }));
    }

    ngOnDestroy() {
        this.eventServiceSubscription?.unsubscribe();
        this.focusableSubscription?.unsubscribe();
        super.ngOnDestroy();
    }

    protected render() {
        if (this.item) {
            this.model = this.item.item;
            this.configuration = this.item.configuration;
        }
        this.visible = true;
        this.outline = AppInjector.getInjector().get(EditorPanelService).isOpen(this.model);

        this.eventServiceSubscription?.unsubscribe();
        this.eventServiceSubscription = AppInjector.getInjector().get(EventService).subscribe(this.model, event => {
            switch (event.action) {
                case EventService.Updated:
                    if (this.item) {
                        if (event.isPatch) {
                            this.item.item.patchProperties(event.changes, event.fields);
                        } else {
                            this.item.item.populate(event.changes, event.isPatch);
                        }
                        this.item.emitUpdated();
                    }
                    this.onItemUpdated(event.item);
                    break;
                case EventService.Deleted:
                    this.onItemDeleted(event.item);
                    break;
            }
        });

        this.focusableSubscription?.unsubscribe();
        this.focusableSubscription = AppInjector.getInjector().get(FocusableService)
            .register(FocusableEntityTypesHelper.GetEntityType(this.model), this.model.id, this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['item'] != null && changes['item'].currentValue !== changes['item'].previousValue) {
            this.render();
        }

        if (changes['simple'] != null && changes['simple'].currentValue !== changes['simple'].previousValue) {
            this.render();
        }
    }

    onItemUpdated(item: AnyItem) {
        let changeDetect = false;
        if (this.model['main_status'] && item['main_status']) {
            this.model.main_status = item.main_status;
            changeDetect = true;
        }
        if (this.model.title && item['title']) {
            this.model.title = item.title;
            changeDetect = true;
        }
        if (this.model['name'] && item['name']) {
            this.model['name'] = item['name'];
            changeDetect = true;
        }
        if (this.model['description']) {
            this.model['description'] = item['description'];
            changeDetect = true;
        }

        if (changeDetect) {
            this.detectChanges();
        }
    }

    onItemDeleted(item: AnyItem) {
        this.visible = false;
        this.onRemove.emit();
        if (this.item) this.item.emitDeleted();
        this.markChangeDetectionDirty();
    }

    private updateVisibility() {
        if (this.configuration && !this.configuration.useGlobalFilter) {
            return;
        }

        this.visible = true;

        // Fix filtre i søgefelter
        if (!this.editable) {
            if (this.filterGlobalService.getActiveSettings().isStarred && this.model.num_stars == 0) {
                this.visible = false;
            }
            if (!this.model || !this.model.status) {
                //console.error('Model missing status : ', this.model)
            } else {
                if (this.filterGlobalService.getActiveSettings().activeStatuses.length > 0
                    && this.filterGlobalService.getActiveSettings().activeStatuses.indexOf(this.model.status) == -1) {
                    this.visible = false;
                }
            }
            if (this.filterGlobalService.getActiveSettings().isHandUp && this.model.num_hand_ups == 0) {
                this.visible = false;
            }
        }
        this.detectChanges();
    }

    triggerRemove($event: AnyItem) {
        this.onRemove.emit($event);
    }

    onCardClick($event: MouseEvent) {
        $event.stopPropagation();
        if (this.interactive) {
            AppInjector.getInjector().get(EditorPanelService).open(this.model);
        }
        this.onCardClickEvent.emit(this.model);
    }

    // <editor-fold desc="Focusable">

    public focus(): void {
        this.cardContainer?.nativeElement?.scrollIntoView({behavior: 'smooth', block: 'center'});

        this.outline = false;
        this.detectChanges();
        setTimeout(() => {
            this.outline = true;
            this.detectChanges();
        }, 100);
    }

    // </editor-fold>

}
