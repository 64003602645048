/**
 * Created by ModelParser
 * Date: 20-12-2018.
 * Time: 11:42.
 */
import {CronJobDefinition} from './definitions/CronJobDefinition';

export class CronJob extends CronJobDefinition {

    constructor(json?: any) {
        super(json);
    }

}
