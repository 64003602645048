import {Injectable} from '@angular/core';
import {Display} from '@app/core/models/Display';
import {System} from '@app/constants';
import {Api} from '@app/core/http/Api/Api';
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";
import {DisplayFilter} from "@app/core/models";

@Injectable({
    providedIn: 'root'
})
export class DisplayService {

    public constructor() {

    }

    // <editor-fold desc="Fetch displays">

    private displaysWithoutSettingsDataCache = new ApiDataCache<Display>(
        System.DisplaysWithoutSettings,
        2 * 60 * 60, // 2 hours
        Api.displays().get()
            .setShowProgressBar(false)
            .where('display_type.visible', true)
            // No includes! This is used at page load
            .orderAsc('index_')
            .orderAsc('display_type_id')
            .orderAsc('name')
    );

    // Entry point
    public getDisplaysWithoutSettings(callback: (items: Display[]) => void) {
        this.displaysWithoutSettingsDataCache.get(callback);
    }

    private displaysWithSettingsDataCache = new ApiDataCache<Display>(
        System.DisplaysWithSettings,
        2 * 60 * 60, // 2 hours
        Api.displays().get()
            // You may include
            .include('displays_setting')
            .include('relateddisplay?include=displays_setting')
            .include('role')
            .orderAsc('index_')
            .orderAsc('display_type_id')
            .orderAsc('name'),
        displays => {
            displays.forEach(display => {
                delete display.created;
                delete display.created_by_id;
                delete display.updated;
                delete display.updated_by_id;
                display.departments_displays?.forEach(departmentsDisplay => {
                    delete departmentsDisplay.created;
                    delete departmentsDisplay.created_by_id;
                    delete departmentsDisplay.updated;
                    delete departmentsDisplay.updated_by_id;
                    if (departmentsDisplay.department) {
                        delete departmentsDisplay.department.created;
                        delete departmentsDisplay.department.created_by_id;
                        delete departmentsDisplay.department.updated;
                        delete departmentsDisplay.department.updated_by_id;
                    }
                });
                display.roles?.forEach(role => {
                    delete role.created;
                    delete role.created_by_id;
                    delete role.updated;
                    delete role.updated_by_id;
                });
                display.displays_settings?.forEach(displaysSetting => {
                    delete displaysSetting.created;
                    delete displaysSetting.created_by_id;
                    delete displaysSetting.updated;
                    delete displaysSetting.updated_by_id;
                    if (displaysSetting.setting) {
                        delete displaysSetting.setting.created;
                        delete displaysSetting.setting.created_by_id;
                        delete displaysSetting.setting.updated;
                        delete displaysSetting.setting.updated_by_id;
                    }
                });
            })
        }
    );

    // Entry point
    public getDisplaysWithSettings(callback: (items: Display[]) => void) {
        // console.log('getDisplaysWithSettings() : this.displaysWithSettingsDataCache : ',this.displaysWithSettingsDataCache)
        this.displaysWithSettingsDataCache.get(callback);
    }

    // Entry point
    public getDisplayWithSettings(displayId: number, callback: (item: Display) => void, retryIfFail = true) {
        // console.log('getDisplaysWithSettings() : displayId : ', displayId, 'retryIfFail : ', retryIfFail);
        this.getDisplaysWithSettings((displays) => {
            const display = displays.find(display => display.id == displayId);
            // console.log('getDisplayWithSettings() -> getDisplaysWithSettings() : displayId : ', displayId, 'display : ', display);

            if (display === undefined && retryIfFail) {
                this.displaysWithSettingsDataCache.clear();
                this.getDisplayWithSettings(displayId, callback, false);
            } else {
                // console.log('getDisplaysWithSettings() : callback display : ', display);
                callback(display);
            }
        });
    }

    public getDisplaysInDepartmentAndType(departmentId: number, displayTypeId: number, callback: (displays: Display[]) => void) {
        this.getDisplaysWithoutSettings(displays => {
            const displayInDepartment = displays.filter((display) => {
                const hasType = display.display_type_id == displayTypeId;
                const hasDepartment = display.departments_displays?.find(departmentsDisplay => {
                    return departmentsDisplay.department_id == departmentId;
                });
                return hasType && hasDepartment;
            })
            callback(displayInDepartment);
        })
    };

    // </editor-fold>

    // <editor-fold desc="Filters">

    // Does not cache because we change filters often...
    public getFilters(displayId: number, departmentId: number, displayIdentifier: string, callback: (items: DisplayFilter[]) => void) {
        Api.displays()
            .filtersGetGetByDisplayIdByDepartmentId(displayId, departmentId)
            .displayIdentifier(displayIdentifier)
            .find(items => {
                callback(items.filter(item => item.my_settings.is_visible));
            });
    }

    // </editor-fold>

}
