import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {User} from '@app/core/models/User';
import {PeriodRange} from '@app/services/FilterGlobalService/PeriodRange';
import {PeriodRating} from '@app/core/models/PeriodRating';
import Helpers from '@app/core/helpers';
import {Appointment} from '@app/core/models/Appointment';
import {AppointmentsUser} from '@app/core/models/AppointmentsUser';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {MiniCardFields} from "@app/editor/user-editor/Fields";
import {UserField} from "@app/core/models/UserField";
import {PageComponent} from "@app/pages/page.component";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {LoadColumnType} from "@app/pages/displays/dashboard/dashboard-header/ColumnTypes/LoadColumnType";
import {RatingColumnType} from "@app/pages/displays/dashboard/dashboard-header/ColumnTypes/RatingColumnType";
import {AppointmentColumnType} from "@app/pages/displays/dashboard/dashboard-header/ColumnTypes/AppointmentColumnType";
import {ColumnTypes} from "@app/pages/displays/dashboard/dashboard-header/ColumnTypes";
import {GenericTableColumn} from "@app/pages/displays/dashboard/dashboard-header/TableColumns/GenericTableColumn";
import {Api, LoadCalculationResponse, UserOverviewMyWeek_Load_LoadProfileSettingValue} from "@app/core/http/Api/Api";
import {ColumnTypeSettings} from "@app/pages/displays/dashboard/dashboard-header/ColumnTypeSettings";

@Component({
    selector: 'app-dashboard-header',
    templateUrl: './dashboard-header.component.html',
    styleUrls: ['./dashboard-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardHeaderComponent extends PageComponent implements OnInit {
    protected filtersSettings?: FiltersInterface;
    protected columnController = new ColumnController();

    // Bindings to parent
    @Input() activeUser: User;
    @Input() isMainDisplay = true;
    @Input() displayId: number;

    // Bindings to view
    public loadTableColumn?: GenericTableColumn;
    public ratingTableColumn?: GenericTableColumn;
    public appointmentsTableColumn?: GenericTableColumn;

    // Data
    public periodRating: PeriodRating = new PeriodRating();
    public loadCalculation: LoadCalculationResponse;
    public appointment: CardItem<Appointment>;
    public period: PeriodRange;
    public fields: Map<number, UserField>;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;

        this.subscribe(this.filterGlobalService.onSettingsPeriodChangeEvent.subscribe(settings => {
            this.loadData(true);
        }))
    }

    ngOnInit() {
        this.buildColumns();
        super.ngOnInit();
        this.isLoading = true;

        this.usersService.getMiniCardFields(fields => {
            this.fields = new Map();
            fields.forEach(field => this.fields.set(field.id, field));
        });

        this.setupPush();
    }

    // <editor-fold desc="View Actions">

    toggleStatus(statusId: number) {
        this.periodRating.status_id = statusId;
        this.periodRating.patch(['status_id']);
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    private loadData(force = false) {
        if (!this.initialized && !force) {
            return;
        }

        this.loadPeriodRating();

        const period = this.filterGlobalService.getActiveSettings().period;
        if (this.loadTableColumn) {
            const loadProfileId = this.loadTableColumn.column.settings.get(ColumnTypeSettings.LoadProfile)
                ?.getObject<UserOverviewMyWeek_Load_LoadProfileSettingValue>()
                ?.loadProfileId ?? 0;
            Api.loads().calculateGetByLoadId(loadProfileId)
                .user_ids([this.activeUser.id])
                .start(Helpers.serverDate(period.start))
                .end(Helpers.serverDate(period.end))
                .find(loads => {
                    if (loads.length > 0) {
                        this.loadCalculation = loads[0];
                    }
                });
        }

        if (this.appointmentsTableColumn) {
            Api.appointments().get()
                .whereGreaterThanOrEqual('appointments_user.start', Helpers.serverDate(period.start))
                .whereLessThan('appointments_user.start', Helpers.serverDate(period.end))
                .where('appointments_user.user_id', this.activeUser.id)
                .orderBy('appointments_user.start', 'asc')
                .limit(1)
                .find(appointments => {
                    if (appointments.length > 0) {
                        this.appointment = new CardItem<Appointment>(appointments[0]);
                    } else {
                        this.appointment = new CardItem<Appointment>(new Appointment());
                        let appointmentsUser = new AppointmentsUser();
                        appointmentsUser.user_id = this.activeUser.id;
                        appointmentsUser.start = Helpers.serverDate(this.filterGlobalService.getActiveSettings().period.start);
                        appointmentsUser.end = Helpers.serverDate(this.filterGlobalService.getActiveSettings().period.end);
                        this.appointment.item.appointments_users = [appointmentsUser];
                        this.appointment.item.description = '';
                    }
                });
        }
    }

    private loadPeriodRating() {
        if (this.ratingTableColumn) {
            const period = this.filterGlobalService.getActiveSettings().period;
            Api.periodRatings().smartGet()
                .start(Helpers.serverDate(period.start))
                .end(Helpers.serverDate(period.end))
                .user_ids([this.activeUser.id!])
                .find(periodRatings => {
                    this.periodRating = periodRatings[0];
                });
        }
    }

    // </editor-fold>

    // <editor-fold desc="Push">

    private setupPush() {
        this.subscribe(this.eventService.subscribeToAppointment(0, _ => this.loadData()));
        this.subscribe(this.eventService.subscribeToTask(0, _ => this.loadData()));
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    private buildColumns() {
        this.columnController.addColumnTypes([
            new LoadColumnType(),
            new RatingColumnType(),
            new AppointmentColumnType(),
        ]);
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();
        this.columnController.loadColumns(this.displayId, () => {
            this.initialize();
        });
    }

    private initialize() {
        this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
            const tableColumns = this.columnController.getVisibleTableColumns();
            this.loadTableColumn = tableColumns.find(tableColumn => tableColumn.column.columnType.identifier == ColumnTypes.Load);
            this.ratingTableColumn = tableColumns.find(tableColumn => tableColumn.column.columnType.identifier == ColumnTypes.Rating);
            this.appointmentsTableColumn = tableColumns.find(tableColumn => tableColumn.column.columnType.identifier == ColumnTypes.Appointment);
            this.loadData();
        }));

        this.initialized = true;
        this.loadData();
    }

    public isFieldVisible(fieldId: number): boolean {
        if (fieldId && this.fields && this.fields.get(fieldId)) {
            return this.fields.get(fieldId).visible;
        } else {
            return false;
        }
    }

    get Fields() {
        return MiniCardFields;
    }

    // </editor-fold>

}
