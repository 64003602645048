<app-data-fetcher-collection-loader
    [dataFetcherCollection]="dataFetcherCollection"
/>

<div class="container-fluid p-0 h-100"
     [attr.data-isCapturingScreenshot]="isCapturingScreenshot"
     [attr.data-listsExpanded]="listsExpanded"
     [class.is-loading]="isLoading || dataFetcherCollection.executingFetcherCount"
>
    <div class="d-flex flex-column h-100">
        <div class="go-back -bg-info bg-dark text-white" *ngIf="(programRow && initialized && displayFilter)">
            <a class="d-flex flex-row align-items-center p-1 ps-4 text-white" (click)="historyBack()">
                <app-toggle-item icon="fa-arrow-left" class="me-1"></app-toggle-item>
                <span class="flex-fill"><span [translate]="'_admin_error_page_go_back'"></span>...</span>
            </a>
        </div>

        <ng-container>

            <div class="position-relative project-row -dark header-program" style="height: 150px;"
                 *ngIf="programRow && initialized && displayFilter">

                <ngx-datatable

                    class="material bootstrap position-relative"
                    #dataTableProgram

                    [rows]="programRow"
                    [columns]="programColumns"
                    [columnMode]="isDataTableResizeEnabled ? 'force':'force'"
                    [scrollbarH]="true"

                    [headerHeight]="30"
                    [footerHeight]="0"

                    [loadingIndicator]="true"
                    [externalSorting]="true"
                    [virtualization]="false">
                </ngx-datatable>
            </div>

            <div class="flex-fill rounded position-relative" #dataTableContainer>

                <!-- Header template -->
                <ng-template
                    let-column="column"
                    #headerTemplate
                    ngx-datatable-header-template
                    [typedTemplate]="templateTypes.headerTemplateType"
                >
                    <ngx-datatable-column
                        [name]="column.name"
                        [width]="column.width"
                        [maxWidth]="column.maxWidth"
                        [minWidth]="column.minWidth"
                        [resizeable]="column.resizeable"
                    >
                        <div class="column-title d-flex align-items-center">
                            <app-column-sorter [display]="display" [column]="column"
                                               [filterPageData]="shellPageData"
                                               (columnSortChange)="columnSortChange($event)"
                            ></app-column-sorter>
                        </div>
                    </ngx-datatable-column>
                </ng-template>

                <!-- Header template with filter option -->
                <ng-template
                    #headerTemplateLiveFilter
                    let-column="column"
                    ngx-datatable-header-template
                    [typedTemplate]="templateTypes.headerTemplateType"
                >
                    <ngx-datatable-column
                        [name]="column.name"
                        [width]="column.width"
                        [maxWidth]="column.maxWidth"
                        [minWidth]="column.minWidth"
                        [resizeable]="column.resizeable"
                    >
                        <div class="column-title d-flex align-items-center w-100 pe-2">
                            <app-column-sorter [display]="display"
                                               [column]="column"
                                               [filterPageData]="shellPageData"
                                               (columnSortChange)="columnSortChange($event)"
                                               class="flex-shrink-1 flex-grow-1 filter-selector"
                            ></app-column-sorter>

                            <app-on-screen-filter-selector
                                class="ms-auto me-1"
                                [showMultiColumnFilters]="false"
                                *ngIf="column.getOnScreenFilters()"
                                [onScreenFilters]="column.getOnScreenFilters()"
                            ></app-on-screen-filter-selector>
                        </div>
                    </ngx-datatable-column>
                </ng-template>

                <!-- Header template with date (roster) -->
                <ng-template
                    #headerDateTemplate
                    let-column="column"
                    ngx-datatable-header-template
                    [typedTemplate]="templateTypes.rosterHeaderTemplateType"
                >
                    <ngx-datatable-column
                        [name]="column.name"
                        [width]="column.width"
                        [maxWidth]="column.maxWidth"
                        [minWidth]="column.minWidth"
                        [resizeable]="column.resizeable"
                    >
                        <div class="column-title d-flex align-items-center">
                            <span
                                class="text-capitalize-firstletter">{{ column.rosterDate | localizedDate: 'EEEE' }}</span>
                            <span class="ms-1">{{ column.rosterDate | localizedDate: 'microDate' }}</span>
                        </div>
                    </ngx-datatable-column>
                </ng-template>

                <!-- Header template with navigation -->
                <ng-template
                    #headerTemplateNavigation
                    let-column="column"
                    ngx-datatable-header-template
                    [typedTemplate]="templateTypes.headerTemplateType"
                >
                    <ngx-datatable-column
                        [name]="column.name"
                        [width]="column.width"
                        [maxWidth]="column.maxWidth"
                        [minWidth]="column.minWidth"
                        [resizeable]="column.resizeable">
                        <div class="column-title ps-1 pe-1 d-flex align-items-center justify-content-start w-100">
                            <app-column-sorter [display]="display" [column]="column"
                                               [filterPageData]="shellPageData"
                                               (columnSortChange)="columnSortChange($event)"
                                               class="flex-grow-1 align-self-start ellipsis"
                            ></app-column-sorter>
                            <div
                                class="buttons d-flex flex-row justify-content-end align-items-center flex-shrink-1 overflow-hidden p-0 m-0 pe-2">
                                <app-create-item-dropdown
                                    *ngIf="!column.disableCreate"
                                    [placement]="'bottom'"
                                    [source]="createItemSourceConfiguration"
                                    [createItemPreset]="createItemPreset"
                                    [container]="'body'"></app-create-item-dropdown>


                            </div>
                        </div>
                    </ngx-datatable-column>
                </ng-template>

                <!-- YearWheel PeriodPicker template -->
                <ng-template #headerTemplateYearWheel let-column="column" ngx-datatable-header-template
                             [typedTemplate]="templateTypes.yearWheelHeaderTemplateType"
                >
                    <ngx-datatable-column [name]="column.name" [width]="column.width" [maxWidth]="column.maxWidth"
                                          [minWidth]="column.minWidth" [resizeable]="column.resizeable">
                        <app-year-wheel-column
                            [yearWheelPeriod]="column.column.yearWheelPeriod"
                            [yearWheelDisplayOptions]="column.column.yearWheelDisplayOptions"
                            (onYearWheelPeriodChanged)="column.column.yearWheelPeriod = $event"
                            (onYearWheelDateChanged)="onYearWheelDateChanged($event)"
                            (onYearWheelUnitChanged)="onYearWheelUnitChanged($event)"
                            (onYearWheelOptionsChanged)="onYearWheelOptionsChanged($event)"
                        />
                    </ngx-datatable-column>
                </ng-template>

                <!-- Project name template -->
                <ng-template #projectNameTemplate let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.cellTemplateType"
                >
                    <div class="wrap d-flex flex-column position-relative h-100">
                        <app-card-project [item]="row.project"></app-card-project>
                        <div
                            class="action-panel placement-bottom mt-auto d-flex d-row justify-content-end align-items-center rounded pb-1 -w-100 ps-2 pe-2 -corner-item wide"
                            [class.disabled]="isPendingRequests"
                        >
                            <!-- refresh / reload project -->
                            <a class="text-center me-1 ms-1" (click)="reloadRow(row)"
                               [ngbTooltip]="('_ui_reload' | translate) + '...'"
                               container="body"
                               placement="right"><i class="fal fa-light fa-arrow-rotate-right"></i></a>
                            <a class="text-center me-1"
                               [ngbTooltip]="('_ui_hide_extra_items' | translate) + '...'"
                               container="body"
                               placement="right"
                               (click)="row.minimizeEvent.emit()"><i class="fal fa-minus-square"></i></a>
                        </div>
                    </div>
                </ng-template>

                <!-- Next Milestone template -->
                <ng-template #nextMilestoneTemplate let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.cellTemplateType"
                >
                    <div class="wrap">
                        <app-header-spacer></app-header-spacer>
                        <app-card-milestone *ngIf="row.nextMilestone" [item]="row.nextMilestone"></app-card-milestone>
                    </div>
                </ng-template>

                <!-- Next Milestone Risk template -->
                <ng-template #nextMilestoneRisk let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.cellTemplateType"
                >
                    <div class="wrap">
                        <app-header-spacer></app-header-spacer>
                        <app-item-note
                            [value]="row.nextMilestoneRisk"
                            [model]="row.nextMilestone?.item"
                            (onItemNoteChange)="saveNextMilestoneRisk(row, $event)"
                        ></app-item-note>
                    </div>
                </ng-template>

                <!-- NextMilestoneTasks template -->
                <ng-template #nextMilestoneTasksTemplate
                             let-row="row"
                             let-tableColumn="column"
                             let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.taskListNextMilestoneCellTemplateType"
                >
                    <app-task-list
                        *ngIf="row.nextMilestone"
                        class="w-100"
                        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
                        [configuration]="tableColumn.column.getCell(row).listConfiguration"
                        [reloadEvent]="row.reloadNextMilestoneTasksEvent"
                        [minimizeEvent]="row.minimizeEvent"
                        [loadAllEvent]="row.loadAllEvent"
                        [csvListBinding]="tableColumn.column.getCell(row).csvListBinding"
                    >
                    </app-task-list>
                </ng-template>

                <!-- FollowingMilestones template -->
                <ng-template #followingMilestonesTemplate
                             let-row="row"
                             let-tableColumn="column"
                             let-rowIndex="rowIndex" ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.followingMilestoneCellTemplateType"
                >
                    <app-milestone-list
                        class="w-100"
                        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
                        [configuration]="tableColumn.column.getCell(row).listConfiguration"
                        [reloadEvent]="row.reloadFollowingMilestonesEvent"
                        [minimizeEvent]="row.minimizeEvent"
                        [loadAllEvent]="row.loadAllEvent"
                        [csvListBinding]="tableColumn.column.getCell(row).csvListBinding"
                    />
                </ng-template>

                <!-- ProjectTasks template -->
                <ng-template #projectTasksTemplate
                             let-tableColumn="column"
                             let-row="row"
                             let-rowIndex="rowIndex" ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.taskListAllCellTemplateType"
                >
                    <app-task-list
                        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
                        class="w-100"
                        [configuration]="tableColumn.column.getCell(row).listConfiguration"
                        [minimizeEvent]="row.minimizeEvent"
                        [loadAllEvent]="row.loadAllEvent"
                        [csvListBinding]="tableColumn.column.getCell(row).csvListBinding"
                    >
                    </app-task-list>
                </ng-template>

                <!-- ProjectTasksWithoutMilestones template -->
                <ng-template #projectTasksWithoutMilestonesTemplate
                             let-tableColumn="column"
                             let-row="row"
                             let-rowIndex="rowIndex" ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.taskListWithoutMilestoneCellTemplateType"
                >
                    <app-task-list
                        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
                        class="w-100"
                        [configuration]="tableColumn.column.getCell(row).listConfiguration"
                        [minimizeEvent]="row.minimizeEvent"
                        [loadAllEvent]="row.loadAllEvent"
                        [csvListBinding]="tableColumn.column.getCell(row).csvListBinding"
                    >
                    </app-task-list>
                </ng-template>

                <!-- Responsible template -->
                <ng-template #responsibleTemplate let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.cellTemplateType"
                >
                    <div class="wrap">
                        <app-header-spacer></app-header-spacer>
                        <app-user-with-icon *ngIf="row.responsible" [model]="row.responsible.user"
                                            [showInitials]="true"></app-user-with-icon>
                    </div>
                </ng-template>

                <!-- Participants template -->
                <ng-template #projectUserListTemplate
                             let-row="row"
                             let-column="column"
                             let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.cellTemplateType"
                >
                    <div class="wrap">
                        <app-header-spacer></app-header-spacer>
                        <app-project-user-list-cell
                            [row]="row"
                            [column]="column"
                        />
                    </div>
                </ng-template>

                <!-- Deadline template -->
                <ng-template #projectDeadlineListTemplate
                             let-row="row"
                             let-column="column"
                             let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.cellTemplateType"
                >
                    <app-project-deadline-list-cell
                        [row]="row"
                        [column]="column"
                    />
                </ng-template>

                <!-- Estimate template -->
                <ng-template #projectEstimateListTemplate let-row="row"
                             let-column="column"
                             let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.cellTemplateType"
                >
                    <div class="wrap">
                        <app-header-spacer></app-header-spacer>
                        <app-project-estimate-list-cell
                            [row]="row"
                            [column]="column"
                        />
                    </div>
                </ng-template>

                <!-- Phase template -->
                <ng-template #phaseTemplate let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.cellTemplateType"
                >
                    <app-header-spacer></app-header-spacer>
                    <app-current-phases-project [project]="row.project.item"></app-current-phases-project>
                </ng-template>

                <!-- Phase Date template -->
                <ng-template #phaseDateTemplate let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.cellTemplateType"
                >
                    <app-header-spacer></app-header-spacer>
                    <span *ngIf="row.project.item.current_phases_project">
                    <app-item-date [date]="row.project.item.current_phases_project.getStartedDate()"></app-item-date>
                </span>
                </ng-template>

                <!-- Phases template -->
                <ng-template #phasesTemplate
                             let-tableColumn="column"
                             let-row="row"
                             let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.phaseListCellTemplateType"
                >
                    <app-header-spacer></app-header-spacer>
                    <ng-container *ngIf="row.project.item.phases_projects">
                        <app-phases-projects
                            [project]="row.project.item"
                            [csvBinding]="tableColumn.column.getCell(row).csvListBinding"
                        />
                    </ng-container>
                </ng-template>

                <!-- Categories template -->
                <ng-template #categoriesTemplate
                             let-tableColumn="column"
                             let-row="row"
                             let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.categoryListCellTemplateType"
                >
                    <app-header-spacer></app-header-spacer>
                    <ng-container *ngIf="row.project.item.categories">
                        <app-categories
                            [csvBinding]="tableColumn.column.getCell(row).csvListBinding"
                            [categories]="row.project.item.categories"></app-categories>
                    </ng-container>
                </ng-template>

                <!-- Note template -->
                <ng-template #noteTemplate
                             let-row="row"
                             let-column="column"
                             let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.cellTemplateType"
                >
                    <div class="wrap h-100">
                        <app-header-spacer></app-header-spacer>
                        <app-project-note-cell
                            [row]="row"
                            [tableColumn]="column"
                        />
                    </div>
                </ng-template>

                <!-- Deadline Difference template -->
                <ng-template #dateDifferenceTemplate
                             let-row="row"
                             let-column="column"
                             let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.cellTemplateType"
                >
                    <app-header-spacer></app-header-spacer>
                    <app-project-deadline-difference-cell
                        [row]="row"
                        [column]="column"
                    />
                </ng-template>

                <!-- Calculated fields template -->
                <ng-template #calculatedFieldsTemplate let-row="row" let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.cellTemplateType"
                >
                    <app-header-spacer></app-header-spacer>
                    <ng-container *ngFor="let calculatedFieldsProject of row.project.item.calculated_fields_projects">
                        <app-project-calculated-field class="me-1 mb-1"
                                                      [project]="row.project.item"
                                                      [calculatedField]="calculatedFieldsProject.calculated_field"
                                                      [calculatedFieldsProject]="calculatedFieldsProject"
                        ></app-project-calculated-field>
                    </ng-container>
                </ng-template>

                <!-- Appointment template -->
                <ng-template #appointmentsTemplate
                             let-row="row"
                             let-tableColumn="column"
                             let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.appointmentListCellTemplateType"
                >
                    <app-appointment-list
                        [listClass]="'flex-row justify-content-start'"
                        [configuration]="tableColumn.column.getCell(row).listConfiguration"
                        [minimizeEvent]="row.minimizeEvent"
                        [loadAllEvent]="row.loadAllEvent"
                        [reloadEvent]="row.reloadAppointmentsEvent"
                        [csvListBinding]="tableColumn.column.getCell(row).csvListBinding"
                    >
                    </app-appointment-list>

                </ng-template>

                <!-- Status template -->
                <ng-template #statusesTemplate
                             let-row="row"
                             let-column="column"
                             let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.cellTemplateType"
                >
                    <app-header-spacer></app-header-spacer>
                    <app-project-status-list-cell
                        [row]="row"
                        [column]="column"
                    />
                </ng-template>

                <!-- Vagtplan -->
                <ng-template #rosterDayTemplate
                             let-row="row"
                             let-column="column"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.rosterCellTemplateType"
                >
                    <app-header-spacer></app-header-spacer>
                    <app-task-list [listClass]="'w-100'" class="w-100"
                                   [reloadEvent]="row.reloadRostersEvent"
                                   [configuration]="column.getTaskListConfigurationForRow(row)"
                                   [minimizeEvent]="row.minimizeEvent"
                                   [loadAllEvent]="row.loadAllEvent"></app-task-list>
                </ng-template>

                <!-- To-dos template -->
                <ng-template #todosTemplate
                             let-row="row"
                             let-tableColumn="column"
                             let-rowIndex="rowIndex" ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.todoListCellTemplateType"
                >
                    <app-todo-list
                        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
                        [configuration]="tableColumn.column.getCell(row).listConfiguration"
                        [minimizeEvent]="row.minimizeEvent"
                        [loadAllEvent]="row.loadAllEvent"
                        [reloadEvent]="row.reloadTodosEvent"
                        [csvListBinding]="tableColumn.column.getCell(row).csvListBinding"
                    >
                    </app-todo-list>
                </ng-template>

                <!-- Year-wheel template -->
                <ng-template #yearWheelTemplate
                             let-row="row"
                             let-tableColumn="column"
                             let-rowIndex="rowIndex"
                             ngx-datatable-cell-template
                             [typedTemplate]="templateTypes.yearWheelCellTemplateType"
                >
                    <app-year-wheel
                        [attr.data-index]="rowIndex"
                        [configuration]="tableColumn.column.getCell(row).yearWheelConfiguration"
                        [reloadEvent]="row.reloadYearWheelEvent"
                        [rerenderEvent]="row.rerenderYearWheelEvent"
                        (onScrollEvent)="tableColumn.onScroll(rows, row)"
                    >
                    </app-year-wheel>
                </ng-template>


                <ngx-datatable
                    class="material bootstrap datatable-flex-column"
                    id="dataTable"
                    #dataTable
                    ngxDatatableExtension [table]="dataTable"
                    [attr.data-isCapturingScreenshot]="isCapturingScreenshot"

                    *ngIf="rows && initialized && displayFilter && projectTypes"
                    [rows]="rows"
                    [columns]="tableColumns"
                    [columnMode]="isDataTableResizeEnabled ? 'force':'force'"
                    [headerHeight]="30"

                    [scrollbarV]="true"
                    [scrollbarH]="false"

                    [loadingIndicator]="true"
                    [externalSorting]="true"
                    [virtualization]="false"
                    [class.d-none]="hasOverlayDisplay"

                    appScrollDetector

                    [externalPaging]="true"
                    [footerHeight]="35"
                    [count]="projectsCount"
                    [offset]="pageNo"
                    [limit]="limit"
                    (page)="setPage($event)"
                >
                    <ngx-datatable-footer>
                        <ng-template
                            ngx-datatable-footer-template
                            let-rowCount="rowCount"
                            let-pageSize="pageSize"
                            let-selectedCount="selectedCount"
                            let-curPage="curPage"
                            let-offset="offset"
                            let-isVisible="isVisible">

                            <!-- _ui_show_all -->
                            <div class="-ms-auto d-flex align-items-center w-100 ">
                                <div class="d-flex align-items-center footer-actions -ms-auto pe-2 ps-2">
                                    <a class="me-2  d-none"
                                       (click)="onCaptureScreenshotBtnClicked();"
                                       [ngbTooltip]="('_ui_screenshot_view_all' | translate)"
                                    >
                                        <i class="fal fa-camera-viewfinder" *ngIf="!isCapturingScreenshot"></i>
                                        <app-loading-indicator [showLogo]="false"
                                                               *ngIf="isCapturingScreenshot"></app-loading-indicator>
                                    </a>

                                    <a class="text-center me-2 " (click)="onFooterRefreshClicked()"
                                       style="transform: translateY(-1px);"
                                       [ngbTooltip]="('_ui_reload' | translate) + '...'"
                                       container="body"

                                       placement="auto"><i class="fal fa-light fa-arrow-rotate-right"></i></a>

                                    <a class="text-center me-2"
                                       (click)="listsExpanded ? minimizeLists() : expandLists()"
                                       style="transform: translateY(-1px);"
                                       [ngbTooltip]="(listsExpanded ? ('_ui_show_less' | translate) : ('_ui_expand_lists' | translate)) + '...'"
                                       container="body"

                                       placement="auto">

                                        <i class="fa-duotone "
                                           [class.fa-circle-chevron-up]="listsExpanded"
                                           [class.fa-circle-chevron-down]="!listsExpanded"></i>

                                    </a>

                                    <app-on-screen-filter-selector
                                        class="me-2"
                                        [onScreenFilters]="pageFilterRoster"
                                        [showMultiColumnFilters]="true"
                                        *ngIf="hasRosterColumn"></app-on-screen-filter-selector>

                                    <a class="me-2"
                                       [ngbTooltip]="('_ui_csv_export_description' | translate)"
                                       container="body"
                                       placement="right"
                                       (click)="csvExport()"
                                    >
                                        <i class="fal fa-file-spreadsheet"></i>
                                    </a>

                                    <app-on-screen-filter-selector
                                        class="me-2"
                                        *ngIf="onScreenFilters?.length > 0 && showGlobalOnScreenFilter"
                                        [showMultiColumnFilters]="true"
                                        [onScreenFilters]="onScreenFilters"
                                    ></app-on-screen-filter-selector>


                                </div>

                                <div class="ms-auto d-flex align-items-center">
                                    <div class="d-flex align-items-center  ">

                                        <div class="p-2 me-2 d-flex ms-auto">
                                            <span style="width: max-content;" *ngIf="projectsCount">{{ projectsCount }}</span><span
                                            class="me-1" *ngIf="pageCount && pageCount > 1">, </span><span
                                            style="width: max-content;"
                                            *ngIf="pageCount && pageCount > 1">{{ pageCount }}
                                            sider
                                        </span>
                                        </div>
                                        <div ngbDropdown [container]="'body'" *ngIf="rowCount > 10"
                                             class="text-small d-flex align-items-center">
                                            <button style="border-color: #fff;"
                                                    class="btn btn-outline-primary btn-micro mt-1 text-light"
                                                    ngbDropdownToggle>{{ '_ui_per_page' | translate : {itemCount: limit} }}
                                            </button>
                                            <div ngbDropdownMenu class="p-2">
                                                <button ngbDropdownItem (click)="setLimit(5)" [disabled]="limit == 5"
                                                        *ngIf="rowCount >= 5">{{ '_ui_per_page' | translate : {itemCount: 5} }}
                                                </button>
                                                <button ngbDropdownItem (click)="setLimit(10)" [disabled]="limit == 10"
                                                        *ngIf="rowCount >= 10">{{ '_ui_per_page' | translate : {itemCount: 10} }}
                                                </button>
                                                <button ngbDropdownItem (click)="setLimit(25)" [disabled]="limit == 25"
                                                        *ngIf="rowCount >= 25">{{ '_ui_per_page' | translate : {itemCount: 25} }}
                                                </button>
                                                <button ngbDropdownItem (click)="setLimit(50)" [disabled]="limit == 50"
                                                        *ngIf="rowCount >= 50">{{ '_ui_per_page' | translate : {itemCount: 50} }}
                                                </button>
                                                <button ngbDropdownItem (click)="setLimit(rowCount)"
                                                        [disabled]="limit == rowCount ">{{ '_ui_show_all' | translate }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <datatable-pager
                                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'"
                                        [page]="curPage"
                                        [size]="pageSize"
                                        [count]="rowCount"
                                        [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="onPageChange($event)"
                                    >
                                    </datatable-pager>
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>

            </div>

        </ng-container>
    </div>

</div>
