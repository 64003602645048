import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";
import {ProjectStatusListColumn} from "@app/pages/displays/display-projects/Columns/ProjectStatusListColumn";

export class ProjectStatusListCell extends GenericCell {

    public column: ProjectStatusListColumn;

    public exportCSV(secondary?: any): CSVCellValue {
        return this.row.project.item.findProjectStatusByType(secondary)?.name() ?? '';
    }

}
