import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {ProjectPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/ProjectPresetType";

export class ProjectDepartmentPresetGenerator implements CreatePresetGenerator {

    private readonly departmentId?: number;
    private readonly departmentIdHandler?: () => number;

    constructor(departmentIdOrHandler: number | (() => number)) {
        if (typeof departmentIdOrHandler == "number") {
            this.departmentId = departmentIdOrHandler;
        } else {
            this.departmentIdHandler = departmentIdOrHandler;
        }
    }

    public generate(typeId?:number): CreatePreset {
        return {
            type: ProjectPresetTypes.Department,
            payload: {
                departmentId: this.departmentId ?? this.departmentIdHandler(),
            }
        };
    }

}
