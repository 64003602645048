<div
    class="item-list dropdown-item d-flex flex-row justify-content-start pe-1 align-items-center rounded d-flex gap-2 align-items-center"
    [class.active-filter]="isActive"
    [class.actions-visible]="actionsVisible"
>

    <div class="d-flex align-items-center justify-content-start class-edit-actions gap-2"
         [class.editable]="actionsVisible"
         *ngIf="item.my_settings"
    >
        <app-toggle-item
            class="p-0"
            [interactive]="!item.my_settings.is_default"
            [active]="item.my_settings.is_default"
            icon="fa-thumbtack"
            (click)="$event.stopPropagation();"
            (onToggle)="onSetDefaultBtnClicked(item)"
            [ngbTooltip]="('_ui_set_this_as_default_filter' | translate)"
        />
        <app-toggle-item
            class=""
            [interactive]="true"
            [active]="item.my_settings.is_favorite"
            icon="fa-star"
            [ngbTooltip]="('_ui_make_favorite' | translate)"
            (click)="$event.stopPropagation();"
            (onToggle)="$event ? setFavorite(item) : removeFavorite(item)"
        />

        <a class="fal fa-bars"
           *ngIf="dragAndDrop"

           [ngbTooltip]="('_ui_drag_and_drop' | translate)"

        ></a>
    </div>


    <i class="fa"
       [class.fal]="!isActive"
       [class.fa-circle]="!isActive"
       [class.text-success]="isActive"
       [class.fas]="isActive"
       [class.fa-check-circle]="isActive"
       (click)="changeFilter(item)"
    ></i>

    <div class="flex-fill text-wrap pe-1 filter-title d-flex flex-column border-end-1"
         (click)="changeFilter(item)">
        <span class="ellipsis text-small filter-name"
              [ngbTooltip]="item.name ? item.name : ('_ui_no_title' | translate)"
              [openDelay]="2000"
              [container]="'body'">{{item.name ? item.name : ('_ui_no_title' | translate)}}
        </span>
    </div>

    <div class="d-flex flex-row justify-content-between align-self-start ms-auto gap-2 class-edit-actions"
    [class.editable]="actionsVisible"
    >

        <a class="fal fa-arrow-up "
           *ngIf="!dragAndDrop"
           (click)="moveFilterUp(item, $event);"
           [ngbTooltip]="('_ui_filter_move_up' | translate)"
           [container]="'body'"
           [class.disabled]="!canMoveUp"></a>
        <a class="fal fa-arrow-down me-2"
           *ngIf="!dragAndDrop"
           (click)="moveFilterDown(item, $event)"
           [ngbTooltip]="('_ui_filter_move_down' | translate)"
           [container]="'body'"
           [class.disabled]="!canMoveDown"></a>






        <ng-container *ngIf="canEdit">
            <a class="fal fa-edit "
               [ngbTooltip]="('_ui_filter_edit' | translate)"
               [container]="'body'"
               (click)="editFilter(item)"></a>
        </ng-container>

        <a
            [ngbPopover]="deletePopover"
            [autoClose]="true"
            placement="auto"
            triggers="manuel"
            class="fal fa-trash-alt text-danger"
            [ngbTooltip]="('_global_delete' | translate)"
            [container]="'body'"
            #popover="ngbPopover"
            (click)="$event.stopPropagation(); popover.isOpen() ? popover.close() : popover.open();"></a>



    </div>

    <!-- Vis ejerskab -->
    <i *ngIf="item.isOwnedDepartment()"
       class="fal fa-users " style="opacity: .5;"
       [ngbTooltip]="('_project_department' | translate)"
       [container]="'body'"
    ></i>
    <i *ngIf="item.isMine()"
       class="fal fa-user " style="opacity: .5;"
       [ngbTooltip]="('_ui_milestone_plan_selector_personal' | translate)"
       [container]="'body'"
    ></i>
</div>


<ng-template #deletePopover>
    <ul
        class="list-unstyled menu-list m-0 p-0"
    >
        <li *ngIf="canDelete">
            <div
                class="cursor-pointer d-flex align-items-center gap-2"
                (click)="deleteFilter(item)"
            >
                <i class="fal fa-trash-alt text-danger"></i>
                <span>{{ '_ui_display_filter_delete' | translate }}</span>
            </div>
        </li>

        <li *ngIf="canRemoveDepartment">
            <div
                class="cursor-pointer d-flex align-items-center gap-2"
               (click)="removeDepartmentFromFilter(item)"
            >
                <i class="fal fa-trash-alt text-danger"></i>
                <span>{{ '_ui_display_filter_delete_global_from_team' | translate }}</span>
            </div>
        </li>



        <li *ngIf="canSetInvisible">
            <div
                class="cursor-pointer d-flex align-items-center gap-2"
                (click)="onSetVisibilityBtnClicked(false)"
            >
                <i class="fal fa-trash-alt text-danger"></i>
                <span>{{ '_ui_display_filter_set_invisible' | translate }}</span>
            </div>
        </li>
    </ul>
</ng-template>
