import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AttachedType} from '@app/editor/project-editor-loader/project-editor-v3/components/project-editor-tabs/project-editor-tab-attach-to/attached-types/AttachedType';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import {NgbTypeahead, NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {merge, Observable, of, Subject} from 'rxjs';
import {ProjectsService} from '@app/services/projects.service';
import {ApiFilter} from '@app/http/APIFilter';
import {Project} from '@app/core/models/Project';
import {fadeAnimation} from '@app/animations';
import {Department} from "@app/core/models";

@Component({
    selector: 'app-project-select-search',
    templateUrl: './project-select-search.component.html',
    styleUrls: ['../../../../../../../../shared/_forms/search/base-search/base-search.component.scss'],
    providers: [],
    animations: [fadeAnimation]
})
export class ProjectSelectSearchComponent extends BaseDisplayComponent implements OnInit {

    // https://ng-bootstrap.github.io/#/components/typeahead/examples

    @Input() type: AttachedType; // Find hvilke der er valgt
    @Output() itemSelected = new EventEmitter<Project>();
    @Input() placeholder: string;
    @Input() showFilterButton: boolean = true;

    @ViewChild('instance', {static: true}) instance: NgbTypeahead;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    public departments: Department[] = [];


    model: any;
    searching = false;
    searchFailed = false;

    result: Project[];

    search = (text$: Observable<string>) => {

        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            tap(() => this.searching = true),
            switchMap(term => {
                    let apiFilter = new ApiFilter();

                    apiFilter.whereEquals('project_type_id', this.type.modelType.id); // Filtrer på typen

                    if(this.departments.length > 0)
                        apiFilter.whereInArray('department.id', this.departments.map(d => d.id));

                    this.result = null;
                    return this.projectsService.search(term, apiFilter).pipe(
                        map((items: Project[]) => {
                            const itemResult = items.filter((project: Project) => {
                                return !this.Helpers.itemExists(this.type.items, project.id); // Udlæs ikke de valgte projekter
                            });
                            this.result = itemResult;
                            return itemResult;

                        }),
                        tap(() => this.searchFailed = false),
                        catchError(() => {
                            this.searchFailed = true;
                            return of([]);
                        }));
                }
            ),
            tap(() => this.searching = false)
        );

    };

    formatter = (x: Project) => x.title;


    constructor(private projectsService: ProjectsService) {
        super();

    }

    ngOnInit() {
        if(navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1)
            this.placeholder = 'Find...';

    }

    triggerSelection($event: NgbTypeaheadSelectItemEvent, input: any) {
        this.itemSelected.emit($event.item);
        $event.preventDefault();
        input.value = '';
        this.model = null;
    }

    filterByDepartment($event: Department[]) {
        this.departments = $event;
    }

    resultTemplate: {
        result: Project,
        term: string,
    }

}
