/**
 * Created by ModelParser
 */
import {Project} from '../Project';
import {ProjectField} from '../ProjectField';
import {ProjectFieldsProjectType} from '../ProjectFieldsProjectType';
import {ProjectDeadlineType} from '../ProjectDeadlineType';
import {ProjectDeadlineTypesProjectType} from '../ProjectDeadlineTypesProjectType';
import {ProjectStatusType} from '../ProjectStatusType';
import {ProjectStatusTypesProjectType} from '../ProjectStatusTypesProjectType';
import {ProjectUserType} from '../ProjectUserType';
import {ProjectTypesProjectUserType} from '../ProjectTypesProjectUserType';
import {ProjectEstimateType} from '../ProjectEstimateType';
import {ProjectEstimateTypesProjectType} from '../ProjectEstimateTypesProjectType';
import {Phase} from '../Phase';
import {PhasesProjectType} from '../PhasesProjectType';
import {Department} from '../Department';
import {DepartmentsProjectType} from '../DepartmentsProjectType';
import {ProjectTypesStatusRule} from '../ProjectTypesStatusRule';
import {ProjectTypesStaticDeadline} from '../ProjectTypesStaticDeadline';
import {StaticDeadline} from '../StaticDeadline';
import {ProjectTypesTaskType} from '../ProjectTypesTaskType';
import {ProjectType} from '../ProjectType';
import {ProjectTypesProjectType} from '../ProjectTypesProjectType';
import {DisplayFiltersProjectType} from '../DisplayFiltersProjectType';
import {DisplayFilter} from '../DisplayFilter';
import {CalculatedField} from '../CalculatedField';
import {CategoryType} from '../CategoryType';
import {CategoryTypesProjectType} from '../CategoryTypesProjectType';
import {ProjectTypesReactionType} from '../ProjectTypesReactionType';
import {Role} from '../Role';
import {ProjectTypesRole} from '../ProjectTypesRole';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class ProjectTypeDefinition extends BaseModel {
    description?: string;
    name?: string;
    plural?: string;
    definition?: string;
    index_?: number;
    default_project_deadline_type_id?: number;
    default_project_estimate_type_id?: number;
    change_type_available?: boolean;
    projects?: Project[];
    project_fields?: ProjectField[];
    project_fields_project_types?: ProjectFieldsProjectType[];
    project_deadline_types?: ProjectDeadlineType[];
    project_deadline_types_project_types?: ProjectDeadlineTypesProjectType[];
    project_status_types?: ProjectStatusType[];
    project_status_types_project_types?: ProjectStatusTypesProjectType[];
    project_user_types?: ProjectUserType[];
    project_types_project_user_types?: ProjectTypesProjectUserType[];
    project_estimate_types?: ProjectEstimateType[];
    project_estimate_types_project_types?: ProjectEstimateTypesProjectType[];
    phases?: Phase[];
    phases_project_types?: PhasesProjectType[];
    departments?: Department[];
    departments_project_types?: DepartmentsProjectType[];
    project_types_status_rules?: ProjectTypesStatusRule[];
    project_types_static_deadlines?: ProjectTypesStaticDeadline[];
    static_deadlines?: StaticDeadline[];
    project_types_task_types?: ProjectTypesTaskType[];
    ups?: ProjectType[];
    downs?: ProjectType[];
    project_types_project_type_up?: ProjectTypesProjectType[];
    project_types_project_type_down?: ProjectTypesProjectType[];
    display_filters_project_types?: DisplayFiltersProjectType[];
    display_filters?: DisplayFilter[];
    calculated_fields?: CalculatedField[];
    category_types?: CategoryType[];
    category_types_project_types?: CategoryTypesProjectType[];
    project_types_reaction_types?: ProjectTypesReactionType[];
    roles?: Role[];
    project_types_roles?: ProjectTypesRole[];
    selected?: boolean;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.description;
            delete this.name;
            delete this.plural;
            delete this.definition;
            delete this.index_;
            delete this.default_project_deadline_type_id;
            delete this.default_project_estimate_type_id;
            delete this.change_type_available;
            delete this.projects;
            delete this.project_fields;
            delete this.project_fields_project_types;
            delete this.project_deadline_types;
            delete this.project_deadline_types_project_types;
            delete this.project_status_types;
            delete this.project_status_types_project_types;
            delete this.project_user_types;
            delete this.project_types_project_user_types;
            delete this.project_estimate_types;
            delete this.project_estimate_types_project_types;
            delete this.phases;
            delete this.phases_project_types;
            delete this.departments;
            delete this.departments_project_types;
            delete this.project_types_status_rules;
            delete this.project_types_static_deadlines;
            delete this.static_deadlines;
            delete this.project_types_task_types;
            delete this.ups;
            delete this.downs;
            delete this.project_types_project_type_up;
            delete this.project_types_project_type_down;
            delete this.display_filters_project_types;
            delete this.display_filters;
            delete this.calculated_fields;
            delete this.category_types;
            delete this.category_types_project_types;
            delete this.project_types_reaction_types;
            delete this.roles;
            delete this.project_types_roles;
            delete this.selected;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.description != null) {
            this.description = data.description;
        }
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.plural != null) {
            this.plural = data.plural;
        }
        if (data.definition != null) {
            this.definition = data.definition;
        }
        if (data.index_ != null) {
            this.index_ = data.index_;
        }
        if (data.default_project_deadline_type_id != null) {
            this.default_project_deadline_type_id = data.default_project_deadline_type_id;
        }
        if (data.default_project_estimate_type_id != null) {
            this.default_project_estimate_type_id = data.default_project_estimate_type_id;
        }
        if (data.change_type_available != null) {
            this.change_type_available = data.change_type_available;
        }
        if (data.projects != null) {
            this.projects = data.projects.map((i: any) => new Project(i));
        }
        if (data.project_fields != null) {
            this.project_fields = data.project_fields.map((i: any) => new ProjectField(i));
        }
        if (data.project_fields_project_types != null) {
            this.project_fields_project_types = data.project_fields_project_types.map((i: any) => new ProjectFieldsProjectType(i));
        }
        if (data.project_deadline_types != null) {
            this.project_deadline_types = data.project_deadline_types.map((i: any) => new ProjectDeadlineType(i));
        }
        if (data.project_deadline_types_project_types != null) {
            this.project_deadline_types_project_types = data.project_deadline_types_project_types.map((i: any) => new ProjectDeadlineTypesProjectType(i));
        }
        if (data.project_status_types != null) {
            this.project_status_types = data.project_status_types.map((i: any) => new ProjectStatusType(i));
        }
        if (data.project_status_types_project_types != null) {
            this.project_status_types_project_types = data.project_status_types_project_types.map((i: any) => new ProjectStatusTypesProjectType(i));
        }
        if (data.project_user_types != null) {
            this.project_user_types = data.project_user_types.map((i: any) => new ProjectUserType(i));
        }
        if (data.project_types_project_user_types != null) {
            this.project_types_project_user_types = data.project_types_project_user_types.map((i: any) => new ProjectTypesProjectUserType(i));
        }
        if (data.project_estimate_types != null) {
            this.project_estimate_types = data.project_estimate_types.map((i: any) => new ProjectEstimateType(i));
        }
        if (data.project_estimate_types_project_types != null) {
            this.project_estimate_types_project_types = data.project_estimate_types_project_types.map((i: any) => new ProjectEstimateTypesProjectType(i));
        }
        if (data.phases != null) {
            this.phases = data.phases.map((i: any) => new Phase(i));
        }
        if (data.phases_project_types != null) {
            this.phases_project_types = data.phases_project_types.map((i: any) => new PhasesProjectType(i));
        }
        if (data.departments != null) {
            this.departments = data.departments.map((i: any) => new Department(i));
        }
        if (data.departments_project_types != null) {
            this.departments_project_types = data.departments_project_types.map((i: any) => new DepartmentsProjectType(i));
        }
        if (data.project_types_status_rules != null) {
            this.project_types_status_rules = data.project_types_status_rules.map((i: any) => new ProjectTypesStatusRule(i));
        }
        if (data.project_types_static_deadlines != null) {
            this.project_types_static_deadlines = data.project_types_static_deadlines.map((i: any) => new ProjectTypesStaticDeadline(i));
        }
        if (data.static_deadlines != null) {
            this.static_deadlines = data.static_deadlines.map((i: any) => new StaticDeadline(i));
        }
        if (data.project_types_task_types != null) {
            this.project_types_task_types = data.project_types_task_types.map((i: any) => new ProjectTypesTaskType(i));
        }
        if (data.ups != null) {
            this.ups = data.ups.map((i: any) => new ProjectType(i));
        }
        if (data.downs != null) {
            this.downs = data.downs.map((i: any) => new ProjectType(i));
        }
        if (data.project_types_project_type_up != null) {
            this.project_types_project_type_up = data.project_types_project_type_up.map((i: any) => new ProjectTypesProjectType(i));
        }
        if (data.project_types_project_type_down != null) {
            this.project_types_project_type_down = data.project_types_project_type_down.map((i: any) => new ProjectTypesProjectType(i));
        }
        if (data.display_filters_project_types != null) {
            this.display_filters_project_types = data.display_filters_project_types.map((i: any) => new DisplayFiltersProjectType(i));
        }
        if (data.display_filters != null) {
            this.display_filters = data.display_filters.map((i: any) => new DisplayFilter(i));
        }
        if (data.calculated_fields != null) {
            this.calculated_fields = data.calculated_fields.map((i: any) => new CalculatedField(i));
        }
        if (data.category_types != null) {
            this.category_types = data.category_types.map((i: any) => new CategoryType(i));
        }
        if (data.category_types_project_types != null) {
            this.category_types_project_types = data.category_types_project_types.map((i: any) => new CategoryTypesProjectType(i));
        }
        if (data.project_types_reaction_types != null) {
            this.project_types_reaction_types = data.project_types_reaction_types.map((i: any) => new ProjectTypesReactionType(i));
        }
        if (data.roles != null) {
            this.roles = data.roles.map((i: any) => new Role(i));
        }
        if (data.project_types_roles != null) {
            this.project_types_roles = data.project_types_roles.map((i: any) => new ProjectTypesRole(i));
        }
        if (data.selected != null) {
            this.selected = data.selected;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
