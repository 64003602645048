<div class="container container-fluid shadow p-0 container-sm display-filter-editor -height-unset"
     [class.closed]="!isOpen"
     [class.un-docked]="!docked"
     (cdkDragStarted)="dragStarted($event)"
     (cdkDragEnded)="dragEnded($event)"
     (cdkDragMoved)="dragMoved($event)"
     [@changeState]="isOpen ? 'open' : 'closed'"
     (@changeState.start)="animationStarted($event)"
     (@changeState.done)="animationDone($event)"
     [class.editable]="editable"
     cdkDrag
     [cdkDragDisabled]="docked"
     #container
     *ngIf="initialized && editorForm"
     [class.disable-tabs]="!showTabs"
>
    <div
        *ngIf="isReady"
        class="h-100">
        <form [formGroup]="editForm" (ngSubmit)="saveForm()" novalidate
              *ngIf="editForm && editable && !isLoading"
              (change)="generatePlaceholderName()"
              [@fadeAnimation]="null"
              class="h-100">
            <div class="d-flex flex-column h-100" style="padding-bottom: 61px;">
                <div class="pt-2 flex-shrink-1 card-header flex-row align-items-center d-flex"
                     cdkDragHandle
                >

                    <div class="d-flex align-items-center w-100 pt-2 pb-2">
                        <h4 class="text-center" *ngIf="!editorForm.exists()"
                            translate="_ui_header_define_new_filter"></h4>
                        <h4 class="text-center" *ngIf="editorForm.exists()">{{ editorForm.name }}</h4>

                        <app-color-item class="cursor-pointer me-2 ms-auto"
                                        [className]="'medium'"
                                        [colorValue]="'#ff5f57'"
                                        [iconClass]="'fa-times'"
                                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                                        (click)="close();"></app-color-item>
                    </div>
                </div>


                <!-- Templates -->
                <ng-template #titleTemplate>
                    <!-- Title -->
                    <div class="mt-2 display-contents d-flex flex-column  border rounded bg-light">
                        <div class="form-group p-2 m-0 -w-50"
                             *ngIf="!autoGenerateName || (!autoGeneratedName && !autoGenerateName)">
                            <label>
                                <span>{{ '_ui_title' | translate }}</span>
                                <span class="text-danger">*</span>
                            </label>
                            <input [formControl]="$any(_efc.name)"
                                   [placeholder]="('_ui_title' | translate)"
                                   class="form-control"
                                   name="name"
                                   autocomplete="on"
                                   id="name"
                                   [disabled]="autoGenerateName"
                                   [class.disabled]="autoGenerateName"
                                   [ngClass]="{ 'is-invalid': _efc.name.errors }">
                        </div>

                        <div class="form-group p-2 m-0 -w-50">

                            <div class="text-black-50 d-flex mt-1 text-small d-flex align-items-center ellipsis w-100"
                                 [ngbTooltip]="autoGeneratedName"
                                 *ngIf="autoGeneratedName && autoGenerateName">
                                <i class="fal fa-wand-sparkles me-1"></i>
                                <span
                                    class="ellipsis">{{ '_ui_auto_generated_name_label' | translate }}{{ autoGeneratedName }}</span>
                            </div>

                            <app-toggle-item
                                class="mt-1"
                                [iconColorInactive]="'text-black-25'"
                                [label]="('_ui_auto_generate_name' | translate)"
                                [iconColorActive]="'text-success'"
                                [interactive]="true"
                                [active]="autoGenerateName"
                                (onToggle)="autoGenerateNameToggle($event)"

                                icon="fa-check-circle"
                                iconInactive="fa-circle"
                            ></app-toggle-item>
                        </div>
                    </div>
                    <!-- Title end -->
                </ng-template>
                <!-- Templates end -->

                <div class="scroll-y align-self-stretch card-content" style="height: calc(100% - 46px);">

                    <ul ngbNav
                        #nav="ngbNav"
                        class="nav-tabs tabs-editor "
                        (navChange)="tabChange($event)"
                        [(activeId)]="selectedTab"
                    >

                        <li ngbNavItem [ngbNavItem]="Globals.Tabs.Contents"
                            [class.active]="nav.activeId == Globals.Tabs.Contents"
                        >
                            <a ngbNavLink
                               [class.d-none]="!editorForm.exists() && !this.showTabs"><span>{{ '_ui_filter_editor_tab_2' | translate }}</span></a>
                            <ng-template ngbNavContent>

                                <!-- basis  / skabeloner -->
                                <input type="hidden" [formControl]="$any(_efc.display)">


                                <ng-container
                                    *ngIf="editorForm.filter_type == Constants.DisplayFilterTypes.ColumnsOnly">
                                    <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
                                </ng-container>

                                <ng-container
                                    *ngIf="editorForm.filter_type !== Constants.DisplayFilterTypes.ColumnsOnly">

                                    <div
                                        class="faux-label ps-1 mt-2">{{ '_ui_filter_editor_group_label_create_filter' | translate }}
                                    </div>
                                    <div class="d-flex flex-column bg-white rounded mt-2"
                                         *ngIf="!editorForm.exists() && false">
                                        <div class="form-group mt-0 p-2 mb-0" *ngIf="!editorForm.exists()">
                                            <app-toggle-item
                                                [active]="showTabs"
                                                [iconColorInactive]="'text-black-25'"
                                                [iconColorActive]="'text-success'"
                                                [label]="('_ui_display_filter_show_tabs' | translate)"
                                                [interactive]="true"
                                                (onToggle)="showTabs = !showTabs"
                                                icon="fa-check-circle"
                                                iconInactive="fa-circle"
                                            ></app-toggle-item>

                                        </div>
                                    </div>


                                    <ng-container
                                        *ngIf="editorForm.filter_type !== Constants.DisplayFilterTypes.ColumnsOnly">
                                        <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
                                    </ng-container>


                                    <div class="mt-2 display-contents d-flex flex-column  border rounded bg-light"
                                         *ngIf="editorForm.filter_type !== Constants.DisplayFilterTypes.ColumnsOnly">
                                        <ng-container *ngIf="!editorForm.exists()">
                                            <!-- Foruddefineret  / predifined -->

                                            <div class="d-flex flex-column -border-top w-100 mt-2"
                                                 *ngIf="!editorForm.exists()">
                                                <div class="form-group -border-end ps-2 pe-2 pt-1 m-0 -w-50 w-100">
                                                    <div
                                                        class="text-black-50 text-small">{{ '_ui_filter_editor_label_predifined_filter' | translate }}
                                                    </div>
                                                    <label class="form-check-label" for="standards"
                                                           translate="_ui_filter_label_select_filter"></label>
                                                    <select class="form-control" id="standards"
                                                            (ngModelChange)="standardSelected($event)"
                                                            [(ngModel)]="selectedStandard"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <option [value]="false">-</option>
                                                        <option *ngFor="let standard of standards"
                                                                [ngValue]="standard">{{ standard.name | translate | firstLetterUppercase }}
                                                        </option>
                                                    </select>
                                                    <div
                                                        class="text-black-50 text-small mt-3">{{ '_ui_filter_editor_label_predifined_filter_and_or' | translate }}
                                                    </div>
                                                </div>
                                            </div>

                                            <hr/>
                                        </ng-container>


                                        <!-- Defineret -->
                                        <div class="form-group p-2 m-0 -w-50">
                                            <label class="form-check-label" translate>Tavle indhold</label>
                                            <select class="form-control" [formControl]="$any(_efc.filter_type)">
                                                <!--<option [value]="null">Ikke valgt</option>-->
                                                <option *ngFor="let filter of _efc.filters.value"
                                                        [ngValue]="filter">{{ filter.name | translate | firstLetterUppercase }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group p-2"
                                             *ngIf="_efc.filter_type?.value && _efc.filter_type?.value.showPhase">
                                            <label class="form-check-label"
                                                   translate="_ui_filter_label_select_phase"></label>
                                            <app-phase-search
                                                [formControl]="$any(_efc.phase)" [allowCreation]="false"
                                                [projectTypes]="_efc.config.value.projectTypes"
                                                [filterByActiveDepartment]="true"
                                            />
                                        </div>

                                        <div class="form-group p-2"
                                             *ngIf="_efc.filter_type?.value && _efc.filter_type?.value.showPhaseProgressType">
                                            <label class="form-check-label"
                                                   translate="_ui_filter_label_select_phase_progress_type"></label>
                                            <app-phase-progress-type-search
                                                [formControl]="$any(_efc.phase_progress_type)"
                                                [allowCreation]="false"
                                                [projectTypes]="_efc.config.value.projectTypes"
                                                [filterByActiveDepartment]="true"
                                            />
                                        </div>

                                        <div class="form-group  p-2"
                                             *ngIf="_efc.filter_type?.value && _efc.filter_type?.value.showProjectDeadlineType">
                                            <label class="form-check-label" for="projectDeadlineTypes"
                                                   translate="_admin_milestone_plan_pick_deadline"></label>
                                            <select class="form-control" id="projectDeadlineTypes"
                                                    [formControl]="$any(_efc.project_deadline_type)">
                                                <option *ngFor="let projectDeadlineType of projectDeadlineTypes"
                                                        [ngValue]="projectDeadlineType">{{ projectDeadlineType.getSmartName() | translate }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group  p-2"
                                             *ngIf="_efc.filter_type?.value && _efc.filter_type?.value.showTaskDeadlineType">
                                            <label class="form-check-label" for="taskDeadlineTypes"
                                                   translate="_admin_milestone_plan_pick_deadline"></label>
                                            <select class="form-control" id="taskDeadlineTypes"
                                                    [formControl]="$any(_efc.task_deadline_type)">
                                                <option *ngFor="let taskDeadlineType of taskDeadlineTypes"
                                                        [ngValue]="taskDeadlineType">{{ taskDeadlineType.getSmartName() | translate }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group p-2"
                                             *ngIf="_efc.filter_type?.value && _efc.filter_type?.value.showPeriod">
                                            <label class="form-check-label"
                                                   translate="_ui_filter_label_select_period"></label>
                                            <select class="form-control" [formControl]="$any(_efc.period_type)">
                                                <option *ngFor="let period of _efc.periods.value"
                                                        [ngValue]="period">{{ period.name | firstLetterUppercase }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="periods d-flex flex-row">
                                            <div class="form-group m-0 p-2"
                                                 *ngIf="_efc.filter_type?.value && _efc.filter_type?.value.showPeriod && _efc.period_type.value && _efc.period_type.value.showCalendar">
                                                <label translate="_period_start"></label>
                                                <div class="input-group" novalidate
                                                >

                                                    <input class="form-control d-lg-block d-md-block flex-fill"
                                                           container="body"
                                                           placeholder=""
                                                           autocomplete="off"
                                                           readonly
                                                           [formControl]="$any(_efc.period_start)"
                                                           ngbDatepicker
                                                           [weekdays]="true"
                                                           [showWeekNumbers]="true"
                                                           [placeholder]="('_ui_date_select' | translate)"
                                                           #d="ngbDatepicker"
                                                           [class.focus]="d.isOpen()"
                                                           (click)="$event.stopPropagation(); d.toggle();">
                                                    <div class="input-group-append" *ngIf="false">
                                                        <button class="btn btn-outline-secondary d-none"
                                                                (click)="$event.stopPropagation(); d.toggle()"
                                                                type="button">
                                                            <i class="far fa-calendar"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group m-0 p-2"
                                                 *ngIf="_efc.filter_type?.value && _efc.filter_type?.value.showPeriod && _efc.period_type.value && _efc.period_type.value.showCalendar">
                                                <label translate="_period_end"></label>
                                                <div class="input-group" novalidate
                                                     *ngIf="_efc.filter_type?.value && _efc.filter_type?.value.showPeriod && _efc.period_type.value && _efc.period_type.value.showCalendar">

                                                    <input class="form-control d-lg-block d-md-block flex-fill"
                                                           container="body"
                                                           readonly
                                                           [formControl]="$any(_efc.period_end)"
                                                           ngbDatepicker
                                                           [weekdays]="true"
                                                           [showWeekNumbers]="true"
                                                           #d="ngbDatepicker"
                                                           [placeholder]="('_ui_date_select' | translate)"
                                                           [class.focus]="d.isOpen()"
                                                           (click)="d.toggle()">
                                                    <div class="input-group-append" *ngIf="false">
                                                        <button class="btn btn-outline-secondary d-none"
                                                                (click)="d.toggle()"
                                                                type="button">
                                                            <i class="far fa-calendar"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </ng-container>

                                <div
                                    class="faux-label ps-1 mt-2">{{ '_ui_filter_editor_group_label_select_usage' | translate }}
                                </div>
                                <div class="d-flex flex-column rounded bg-white p-2">
                                    <!-- Brugertyper -->
                                    <div class="border p-2 rounded bg-light"
                                         *ngIf="user && user.user_group_id <= Globals.UserGroups.Admin"
                                    >
                                        <div class="form-group mb-0">
                                            <label
                                                class="form-check-label">{{ '_ui_filter_editor_label_user_type' | translate }}</label>
                                            <ul class="list-group mt-1">
                                                <li class="list-group-item cursor-pointer d-flex align-items-center justify-content-center"
                                                    (click)="onToggleType(filterSaveTypes.Personal)">

                                                    <app-toggle-item
                                                        [iconColorInactive]="'text-black-25'"
                                                        [label]="('_ui_milestone_plan_selector_personal' | translate)"
                                                        [iconColorActive]="'text-success'"
                                                        [interactive]="false"
                                                        [active]="filterSaveType == filterSaveTypes.Personal"

                                                        icon="fa-check-circle"
                                                        iconInactive="fa-circle"
                                                    ></app-toggle-item>

                                                    <i
                                                        class="fal fa-user ms-auto me-2" style="opacity: .5;"
                                                        [ngbTooltip]="('_ui_milestone_plan_selector_personal' | translate)"
                                                        [container]="'body'"
                                                    ></i>


                                                </li>


                                                <li class="list-group-item cursor-pointer d-flex"
                                                    *ngIf="user && user.user_group_id <= Globals.UserGroups.Admin"
                                                    (click)="onToggleType(filterSaveTypes.Team)">
                                                    <app-toggle-item
                                                        [iconColorInactive]="'text-black-25'"
                                                        [label]="('_project_department' | translate)"
                                                        [iconColorActive]="'text-success'"
                                                        [interactive]="false"
                                                        [active]="filterSaveType == filterSaveTypes.Team"

                                                        icon="fa-check-circle"
                                                        iconInactive="fa-circle"
                                                    ></app-toggle-item>

                                                    <i
                                                        class="fal fa-users ms-auto me-2" style="opacity: .5;"
                                                        [ngbTooltip]="('_project_department' | translate)"
                                                        [container]="'body'"
                                                    ></i>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <!-- <editor-fold desc="Departments"> -->
                                    <!-- Vis kun såfremt at filter er globalt OG brugeren er SysAdmin -->
                                    <div class="border p-2 mt-2 rounded bg-light"
                                         *ngIf="
                                (filterSaveType == filterSaveTypes.Team)
                                && user && user.user_group_id <= Globals.UserGroups.Admin"
                                    >
                                        <div class="form-group mb-0">
                                            <label class="form-check-label"
                                                   translate="_ui_display_filter_departments"></label>
                                            <ul class="list-unstyled list-group mt-1 scroll-y">
                                                <li class="list-group-item"
                                                    (click)="toggleAllDepartments(_efc.departments)">

                                                    <app-toggle-item [active]="allDepartmentsChecked"
                                                                     [interactive]="false"
                                                                     [iconColorInactive]="'text-black-25'"
                                                                     [iconColorActive]="'text-success'"
                                                                     [label]="('_ui_select_all' | translate)"
                                                    ></app-toggle-item>
                                                </li>
                                                <li class="list-group-item"
                                                    *ngFor="let departmentGroup of $any(_efc.departments).controls">
                                                    <app-toggle-item [interactive]="true"
                                                                     [iconColorInactive]="'text-black-25'"
                                                                     [iconColorActive]="'text-success'"
                                                                     [label]="departmentGroup.value.department.name"
                                                                     [formControl]="departmentGroup.controls.checked"
                                                                     icon="fa-check-circle"
                                                    ></app-toggle-item>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- </editor-fold> -->

                                    <!-- Årshjul -->
                                    <div class="border p-2 rounded  bg-light mt-2"
                                         *ngIf="_efc.config.value.showYearWheelPeriod">
                                        <!-- <editor-fold desc="Year Wheel Period"> -->
                                        <div class="form-group mb-0"
                                             *ngIf="_efc.config.value.showYearWheelPeriod">
                                            <label class="form-check-label"
                                                   translate="_ui_filter_label_year_wheel_select_period"></label>
                                            <select class="form-control"
                                                    [formControl]="$any(_efc.year_wheel_period_type)">
                                                <option *ngFor="let period of _efc.yearWheelPeriods.value"
                                                        [ngValue]="period">{{ period.name | firstLetterUppercase }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- årshjul -->
                                    <div class="form-group m-0"
                                         *ngIf="_efc.config.value.showYearWheelPeriod && _efc.year_wheel_period_type.value && _efc.year_wheel_period_type.value.showCalendar">
                                        <label translate="_period_start"></label>
                                        <div class="input-group" novalidate>
                                            <input class="form-control d-lg-block d-md-block flex-fill"
                                                   container="body"
                                                   [placeholder]="('_ui_date_select' | translate)"
                                                   autocomplete="off"
                                                   readonly
                                                   [formControl]="$any(_efc.year_wheel_period_start)"
                                                   ngbDatepicker
                                                   [weekdays]="true"
                                                   [showWeekNumbers]="true"
                                                   [class.focus]="d.isOpen()"
                                                   #d="ngbDatepicker"
                                                   (click)="$event.stopPropagation(); d.toggle();">
                                            <div class="input-group-append" *ngIf="false">
                                                <button class="btn btn-outline-secondary far fa-calendar d-none"
                                                        (click)="$event.stopPropagation(); d.toggle()"
                                                        type="button"></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group m-0"
                                         *ngIf="_efc.config.value.showYearWheelPeriod && _efc.year_wheel_period_type.value && _efc.year_wheel_period_type.value.showCalendar">
                                        <label translate="_period_end"></label>
                                        <div class="input-group" novalidate
                                             *ngIf=" _efc.config.value.showYearWheelPeriod && _efc.year_wheel_period_type.value && _efc.year_wheel_period_type.value.showCalendar">
                                            <input class="form-control d-lg-block d-md-block flex-fill"
                                                   container="body"
                                                   [placeholder]="('_ui_date_select' | translate)"
                                                   readonly
                                                   [formControl]="$any(_efc.year_wheel_period_end)"
                                                   ngbDatepicker
                                                   [weekdays]="true"
                                                   [showWeekNumbers]="true"
                                                   [class.focus]="d.isOpen()"
                                                   #d="ngbDatepicker"
                                                   (click)="d.toggle()">
                                            <div class="input-group-append" *ngIf="false">
                                                <button class="btn btn-outline-secondary far fa-calendar d-none"
                                                        (click)="d.toggle()"
                                                        type="button"></button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </editor-fold> -->
                                    <ng-container
                                        *ngIf="editorForm.filter_type && editorForm.filter_type !== Constants.DisplayFilterTypes.ColumnsOnly">
                                        <!-- </editor-fold> -->
                                        <!-- projekttyper -->
                                        <ng-container
                                            *ngIf="_efc.project_types.value.length > 0 && (_efc.project_types.value.length + _efc.task_types.value.length) > 1">
                                            <hr/>
                                            <!-- <editor-fold desc="Project types"> -->
                                            <div class="form-group mb-0  mb-0 border p-2 mt-2 rounded bg-light">
                                                <label class="form-check-label"
                                                       translate="_ui_display_filter_project_types"></label>
                                                <ul class="list-group mt-1">
                                                    <li class="list-group-item"
                                                        *ngFor="let projectTypeFormGroup of $any(_efc.project_types).controls">
                                                        <app-toggle-item [interactive]="true"
                                                                         [iconColorInactive]="'text-black-25'"
                                                                         [label]="projectTypeFormGroup.value.projectType.getPlural() | translate"
                                                                         [iconColorActive]="'text-success'"
                                                                         [formControl]="projectTypeFormGroup.controls.checked"
                                                                         icon="fa-check-circle"
                                                        ></app-toggle-item>
                                                    </li>
                                                </ul>
                                            </div>
                                        </ng-container>

                                        <!-- </editor-fold> -->

                                        <!-- <editor-fold desc="Task types"> -->
                                        <ng-container
                                            *ngIf="_efc.task_types.value.length > 0 && (_efc.project_types.value.length + _efc.task_types.value.length) > 1">
                                            <hr/>
                                            <div class="form-group mb-0 border p-2 mt-2 rounded bg-light">
                                                <label class="form-check-label"
                                                       translate="_ui_display_filter_task_types"></label>
                                                <ul class="list-group mt-1 scroll-y">
                                                    <li class="list-group-item"
                                                        *ngFor="let taskTypeFormGroup of $any(_efc.task_types).controls">
                                                        <app-toggle-item [interactive]="true"
                                                                         [iconColorInactive]="'text-black-25'"
                                                                         [label]="taskTypeFormGroup.value.taskType.name | translate"
                                                                         [iconColorActive]="'text-success'"
                                                                         [formControl]="taskTypeFormGroup.controls.checked"
                                                                         icon="fa-check-circle"
                                                        ></app-toggle-item>
                                                    </li>
                                                </ul>
                                            </div>
                                        </ng-container>

                                        <!-- </editor-fold> -->
                                    </ng-container>

                                    <!-- end -->


                                    <button type="button" class="btn btn-sm btn-primary panel-button w-100 d-none"
                                            (click)="show()"
                                            [disabled]="!editForm.valid">
                                        <span translate="_ui_filter_button_show_board"></span>
                                    </button>
                                </div>


                                <!-- Vælg kolonner -->
                                <ng-container *ngIf="editorForm.filter_type">
                                    <div
                                        class="faux-label ps-1 mt-2">{{ '_ui_filter_editor_group_label_select_fields' | translate }}
                                    </div>
                                    <!-- kolonnevalg -->
                                    <div class="d-flex flex-column rounded bg-white mt-2 p-2">
                                        <ng-container
                                            *ngIf="editorForm.filter_type && _efc.filter_type?.value?.showColumns">
                                            <!-- <editor-fold desc="Display columns"> -->
                                            <div class="border p-2 rounded bg-light ">
                                                <label
                                                    class="form-check-label">{{ '_ui_filter_editor_label_column_toggle' | translate }}</label>
                                                <div class="form-group mb-0">
                                                    <ul class="list-group mt-1">
                                                        <li class="list-group-item">
                                                            <!-- _ui_display_filter_override_display_columns -->
                                                            <app-toggle-item
                                                                [formControl]="$any(_efc.override_display_columns)"
                                                                [iconColorInactive]="'text-black-25'"
                                                                [iconColorActive]="'text-success'"
                                                                [label]="('_global_yes' | translate)"
                                                                [interactive]="true"
                                                                icon="fa-check-circle"
                                                                iconInactive="fa-circle"
                                                            ></app-toggle-item>
                                                        </li>
                                                        <ng-container *ngIf="_efc.override_display_columns.value">

                                                            <ng-container
                                                                *ngFor="let columnGroup of $any(_efc.columnGroups).controls">

                                                                <ng-container *ngIf="!columnGroup.value.isGroup">
                                                                    <li
                                                                        class="list-group-item"
                                                                        *ngFor="let columnFormGroup of columnGroup.controls.columns.controls">
                                                                        <app-toggle-item
                                                                            [label]="columnFormGroup.value.name | translate"
                                                                            [interactive]="!columnFormGroup.value.disabled"
                                                                            [iconColorInactive]="'text-black-25'"
                                                                            [iconColorActive]="columnFormGroup.value.disabled ? 'text-black-50' : 'text-success'"
                                                                            [icon]="columnFormGroup.value.disabled ? 'fa-lock' : 'fa-check-circle'"
                                                                            [formControl]="columnFormGroup.controls.checked"
                                                                            icon="fa-check-circle"
                                                                        ></app-toggle-item>
                                                                    </li>
                                                                </ng-container>

                                                                <ng-container *ngIf="columnGroup.value.isGroup">
                                                                    <li
                                                                        class="list-group-item">
                                                                        <app-toggle-item
                                                                            [label]="columnGroup.value.name"
                                                                            [interactive]="true"
                                                                            [iconColorInactive]="'text-black-25'"
                                                                            [iconColorActive]="'text-success'"
                                                                            [icon]="'fa-check-circle'"
                                                                            [formControl]="columnGroup.controls.checked"
                                                                            (onToggle)="toggleColumnGroup(columnGroup)"
                                                                            icon="fa-check-circle"
                                                                            iconInactive="fa-circle"
                                                                        ></app-toggle-item>
                                                                    </li>
                                                                    <li class="list-group-item ps-4"
                                                                        *ngFor="let columnFormGroup of columnGroup.controls.columns.controls">
                                                                        <app-toggle-item
                                                                            [label]="columnFormGroup.value.name | translate"
                                                                            [interactive]="!columnFormGroup.value.disabled"
                                                                            [iconColorInactive]="'text-black-25'"
                                                                            [iconColorActive]="columnFormGroup.value.disabled ? 'text-black-50' : 'text-success'"
                                                                            [icon]="columnFormGroup.value.disabled ? 'fa-lock' : 'fa-check-circle'"
                                                                            [formControl]="columnFormGroup.controls.checked"
                                                                            (onToggle)="checkColumnGroupStatus(columnGroup)"
                                                                            icon="fa-check-circle"
                                                                        ></app-toggle-item>
                                                                    </li>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ul>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>

                                <!-- Navigation videre -->
                                <div class="d-flex align-items-center flex-fill w-100 mt-2 rounded p-1 bg-light"
                                     *ngIf="!editorForm.exists() && false">
                                    <a class="btn btn-sm btn-outline-primary panel-button mt-0"
                                       (click)="selectedTab = Globals.Tabs.Filters">
                                        <span>{{ '_ui_filter_editor_tab_3' | translate }}</span>
                                        <span class="fal ms-1 fa-arrow-right"></span>
                                    </a>
                                </div>

                            </ng-template>
                        </li>


                        <li ngbNavItem [ngbNavItem]="Globals.Tabs.Filters"
                            *ngIf="editorForm && editorForm.filter_type !== Constants.DisplayFilterTypes.ColumnsOnly && editorForm.filter_type && _efc.filter_type?.value"
                            [class.active]="nav.activeId == Globals.Tabs.Filters"
                        >
                            <a ngbNavLink [class.d-none]="!editorForm.exists()&& !this.showTabs"><span translate>_ui_filter_editor_tab_3</span></a>
                            <ng-template ngbNavContent>
                                <!-- Filtrering på indhold -->

                                <ng-container *ngIf="_efc.filter_type?.value.showStatus || _efc.filter_type?.value.showStarred || _efc.filter_type?.value.showHands ||
                                _efc.filter_type?.value.showIncludeArchivedSince">
                                    <div
                                        class="faux-label ps-1 mt-2">{{ '_ui_filter_editor_group_label_filters' | translate }}
                                    </div>
                                    <!-- Filtreringer: Status, stjernemarkering, hånden op, afdeling  -->
                                    <div class="border p-2 rounded bg-light mt-2">

                                        <div class="form-group mb-0">
                                            <ul class="list-group">
                                                <li class="list-group-item" *ngIf="_efc.filter_type?.value.showStatus">
                                                    <div class="form-group pb-0 pt-0 d-flex flex-row mb-0">
                                                        <label class="form-check-label" translate="_ui_status"></label>
                                                        <app-status-selector [formControl]="$any(_efc.statuses)"
                                                                             [multiple]="true"></app-status-selector>
                                                    </div>
                                                </li>
                                                <li class="list-group-item" *ngIf="_efc.filter_type?.value.showStarred">
                                                    <app-toggle-item [interactive]="true"
                                                                     [iconColorInactive]="'text-black-25'"
                                                                     [label]="('_ui_filter_show_only' | translate) +': ' + ('_ui_starred' | translate)"
                                                                     [formControl]="$any(_efc.starred)"
                                                                     icon="fa-star"></app-toggle-item>
                                                </li>
                                                <li class="list-group-item" *ngIf="_efc.filter_type?.value.showHands">
                                                    <app-toggle-item [interactive]="true"
                                                                     [iconColorInactive]="'text-black-25'"
                                                                     [label]="('_ui_filter_show_only' | translate) +': ' + ('_ui_hand_up' | translate)"
                                                                     [formControl]="$any(_efc.hand_up)"
                                                                     icon="fa-hand-paper"></app-toggle-item>
                                                </li>

                                                <!-- Flyt til filteringer -->

                                                <li class="list-group-item "
                                                    *ngIf="_efc.filter_type?.value.showIncludeArchivedSince">
                                                    <label
                                                        translate>{{ '_ui_include_archived_since' | translate }}</label>
                                                    <select class="form-control"
                                                            [formControl]="$any(_efc.include_archived_since_type)">
                                                        <option
                                                            *ngFor="let period of _efc.includeArchivedSincePeriods.value"
                                                            [ngValue]="period">{{ period.name }}
                                                        </option>
                                                    </select>
                                                </li>

                                                <li class="list-group-item  "
                                                    *ngIf="_efc.filter_type?.value.showIncludeArchivedSince && _efc.include_archived_since_type.value && _efc.include_archived_since_type.value.showCalendar">

                                                    <div class="input-group" novalidate>
                                                        <input class="form-control d-lg-block d-md-block flex-fill"
                                                               container="body"
                                                               [placeholder]="('_ui_date_select' | translate)"
                                                               readonly
                                                               [formControl]="$any(_efc.include_archived_since_date)"
                                                               ngbDatepicker
                                                               [weekdays]="true"
                                                               [showWeekNumbers]="true"
                                                               #d="ngbDatepicker"
                                                               [class.focus]="d.isOpen()"
                                                               (click)="d.toggle()">
                                                        <div class="input-group-append" *ngIf="false">
                                                            <button
                                                                class="btn btn-outline-secondary far fa-calendar d-none"
                                                                (click)="d.toggle()"
                                                                type="button"></button>
                                                        </div>
                                                    </div>
                                                </li>


                                            </ul>

                                        </div>
                                    </div>
                                </ng-container>

                                <!-- Kategorier, Emojis mv. -->
                                <ng-container *ngIf="
                                (_efc.filter_type?.value.showReactionTypes && showReactionTypes)
                                || (_efc.filter_type?.value.showCategoryTypes && showCategoryTypes)">
                                    <div
                                        class="faux-label ps-1 mt-2">{{ '_ui_filter_editor_group_label_categories' | translate }}
                                    </div>
                                    <div class="border p-2 rounded bg-light mt-2">
                                        <!-- <editor-fold desc="Reaction types (emojis)"> -->
                                        <div class="form-group mb-0"
                                             *ngIf="showReactionTypes && _efc.filter_type?.value.showReactionTypes">
                                            <label class="form-check-label" translate="_ui_display_emoji"></label>
                                            <!--                                <pre class="debug position-relative border mb-2">{{_item.reaction_filters | json}}</pre>-->
                                            <ul class="list-group mt-1">
                                                <li class="list-group-item">
                                                    <div class="input-group">
                                                        <div
                                                            class="form-control d-flex align-items-center ellipsis input-group-prepend">
                                                            <app-reactions [reactions]="emojiReactionFilters"
                                                                           [reactionsEditable]="true"
                                                                           [reactionsVisible]="true"
                                                                           [reactionsToggleUsers]="false"
                                                                           [reactionsShowUserCounter]="false"
                                                                           [reactionsTypeInterfaces]="reactionsTypeInterfaces"
                                                                           [reactionsApiInterface]="editorComponent"
                                                                           (click)="$event.stopImmediatePropagation()"

                                                            ></app-reactions>
                                                        </div>

                                                        <span class="btn btn-outline-danger"
                                                              (click)="removeReactions()"><i
                                                            class="fal fa-trash-alt"></i></span>

                                                    </div>

                                                </li>
                                            </ul>
                                        </div>
                                        <!-- </editor-fold> -->

                                        <!-- <editor-fold desc="Category Types"> -->
                                        <ng-container
                                            *ngIf="showCategoryTypes && _efc.filter_type?.value.showCategoryTypes">
                                            <div *ngFor="let categoryType of $any(_efc.categoryTypes).controls">
                                                <label>{{ categoryType.value.name }}</label>
                                                <app-category-picker
                                                    [categoryType]="categoryType.value"
                                                    [departmentId]="departmentId"
                                                    [formControl]="categoryType.controls.categories"></app-category-picker>
                                            </div>
                                        </ng-container>
                                        <!-- </editor-fold> -->
                                    </div>

                                </ng-container>


                                <div
                                    class="faux-label ps-1 mt-2">{{ '_ui_filter_editor_group_label_sorting' | translate }}
                                </div>
                                <!-- sorting - orderdirection -->
                                <div class="border p-2 rounded bg-light mt-2"
                                     *ngIf="(_efc.sorts.value && _efc.sorts.value.length > 0) || (_efc.sort_type.value && _efc.sort_type.value.hasOrderDirection)">
                                    <div class="form-group mb-0"
                                         *ngIf="_efc.sorts.value && _efc.sorts.value.length > 0">
                                        <label class="form-check-label">Sortering</label>

                                        <select class="form-control mt-1" [formControl]="$any(_efc.sort_type)">
                                            <option *ngFor="let sort of _efc.sorts.value"
                                                    [ngValue]="sort">{{ sort.name }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="form-group mb-0 mt-1"
                                         *ngIf="_efc.sort_type.value && _efc.sort_type.value.hasOrderDirection">
                                        <ul class="list-group">
                                            <li class="list-group-item">
                                                <app-toggle-item [interactive]="true"
                                                                 [label]="((_efc.sort_type.value.sortDirectionTranslation ?? '_ui_order_direction') | translate)"
                                                                 [formControl]="$any(_efc.sort_direction)"
                                                                 iconInactive="fa-sort-amount-down"
                                                                 icon="fa-sort-amount-up"
                                                                 iconWeightActive="fal"
                                                ></app-toggle-item>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div
                                    *ngIf="_efc.extraSort1Types?.value?.length > 0"
                                    class="border p-2 rounded bg-light mt-2"
                                >
                                    <div class="form-group mb-0">
                                        <label class="form-check-label">{{ _efc.extraSort1Label.value }}</label>

                                        <select
                                            class="form-control mt-1 text-capitalize-firstletter"
                                            [formControl]="$any(_efc.extra_sort1_type)">
                                            <option *ngFor="let sort of _efc.extraSort1Types.value"
                                                    [ngValue]="sort">{{ sort.name }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="form-group mb-0 mt-1"
                                         *ngIf="_efc.extra_sort1_type?.value?.hasOrderDirection">
                                        <ul class="list-group">
                                            <li class="list-group-item">
                                                <app-toggle-item [interactive]="true"
                                                                 [label]="((_efc.extra_sort1_type.value.sortDirectionTranslation ?? '_ui_order_direction') | translate)"
                                                                 [formControl]="$any(_efc.extra_sort1_direction)"
                                                                 iconInactive="fa-sort-amount-down"
                                                                 icon="fa-sort-amount-up"
                                                                 iconWeightActive="fal"
                                                ></app-toggle-item>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- Tab 3 navigation -->
                                <div class="d-flex align-items-center flex-fill w-100 mt-2 rounded p-1 bg-light"
                                     *ngIf="!editorForm.exists() && false">
                                    <a class="btn btn-sm btn-outline-primary panel-button me-1 mt-0"
                                       (click)="selectedTab = Globals.Tabs.Contents">
                                        <span class="fal me-1 fa-arrow-left"></span>
                                        <span>{{ '_ui_filter_editor_tab_2' | translate }}</span>
                                    </a>
                                    <a class="btn btn-sm btn-outline-success panel-button mt-0"
                                       (click)="saveForType()">
                                        <span>{{ '_ui_save' | translate }}</span>
                                        <span class="fal ms-1 fa-check-circle"></span>
                                    </a>
                                </div>
                            </ng-template>
                        </li>

                    </ul>
                    <div class="container mb-2">
                        <div [ngbNavOutlet]="nav" class="categories ngb-tabs-content"></div>
                    </div>
                </div>
            </div>

            <ul class="nav -nav-pills nav-fill position-absolute bg-light border-top w-100 p-2  justify-content-between align-items-center"
                style="bottom: 0;"
                [class.d-none]="!editorForm.exists()&& !this.showTabs"
                [class.d-flex]="!(!editorForm.exists()&& !this.showTabs)"
            >
                <li class="nav-item -px-2" *ngIf="editorForm && editorForm.exists()">
                    <button type="button" class="btn btn-sm btn-danger panel-button ellipsis w-100"
                            (click)="delete()">
                        <!--                        <span translate>_global_delete</span>-->
                        <span class="fal fa-trash-alt"></span>
                    </button>
                </li>


                <li class="nav-item -px-2 -d-none">
                    <button type="button" class="btn btn-sm panel-button ellipsis w-100"
                            (click)="show()"
                            [class.btn-primary]="editForm.valid"
                            [class.btn-outline-dark]="!editForm.valid"
                            [disabled]="!editForm.valid"
                            [ngbTooltip]="('_ui_filter_button_show_board' | translate)"
                            [container]="'body'"
                    >
                        <i
                            class="fal fa-eye"
                        ></i>
                    </button>
                </li>


                <li class="nav-item -px-2">
                    <button class="btn btn-sm nav-link -btn-block ellipsis w-100" (click)="saveForType()"
                            type="button"
                            [class.btn-success]="editForm.valid"
                            [class.btn-outline-dark]="!editForm.valid"
                            [disabled]="!editForm.valid">
                        <span>{{ '_ui_save_and_close' | translate }}</span>
                    </button>
                </li>

            </ul>

        </form>
    </div>

</div>
