import {NgModule} from "@angular/core";
import {
    FilterColumnSelectorComponent
} from "@app/shell/header/filters/filter-column-selector/filter-column-selector.component";
import {NgbNavModule, NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {FilterColumnModule} from "@app/shell/header/filters/filter-column/FilterColumn.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule,
        NgbPopoverModule,
        LoadingIndicatorModule,
        NgbNavModule,
        FilterColumnModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        FilterColumnSelectorComponent,
    ],
    exports: [
        FilterColumnSelectorComponent

    ],
    providers: [

    ],
})
export class FilterColumnSelectorModule {}
