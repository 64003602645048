import {ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {RouteReusableStrategy} from './route-reusable-strategy';
import {AuthenticationService} from './authentication/authentication.service';
import {AuthenticationGuard} from './authentication/authentication.guard';
import {I18nService} from './i18n.service';
import {HttpService} from './http/http.service';
import {HttpCacheService} from './http/http-cache.service';
import {ApiPrefixInterceptor} from './http/api-prefix.interceptor';
import {ErrorHandlerInterceptor} from './http/error-handler.interceptor';
import {CacheInterceptor} from './http/cache.interceptor';
import {AuthInterceptor} from '@app/core/http/auth.interceptor';
import {CoreService} from "../../services/core.service";
import {GlobalErrorHandler} from "@app/core/errors/global-error-handler";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule,
        RouterModule,
    ],
    providers: [
        AuthenticationService,
        AuthenticationGuard,
        I18nService,
        HttpCacheService,
        ApiPrefixInterceptor,
        // ErrorHandlerInterceptor,
        // {
        //     // processes all errors
        //     provide: ErrorHandler,
        //     useClass: GlobalErrorHandler,
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
            deps: [CoreService]
        },
        CacheInterceptor,
        {
            provide: HttpClient,
            useClass: HttpService
        },
        {
            provide: RouteReuseStrategy,
            useClass: RouteReusableStrategy
        },
        ErrorHandlerInterceptor,
        {
            // processes all errors
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
    ],
    declarations: [],
    exports: [
    ]
})
export class CoreModule {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        // Import guard
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
        }
    }

}
