import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {
    EditDepartmentPickerFilter
} from "@app/editor/quick-editor/editors/generic/department-picker-editor/EditDepartmentPickerFilter";

export class EditDepartmentPickerConfiguration extends BaseEditorConfiguration {

    public labelName: string;
    public filter: EditDepartmentPickerFilter;

    constructor(labelName: string, filter: EditDepartmentPickerFilter) {
        super();
        this.labelName = labelName;
        this.filter = filter;
    }

}
