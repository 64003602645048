import {DisplayFilter} from "@app/core/models";
import {EventEmitter} from "@angular/core";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {Subscription} from "rxjs";

export class ShellFilterGroup {

    // Data
    public name: string;
    public filters: DisplayFilter[];
    public activeFilter: DisplayFilter;
    public settings: FiltersInterface;

    // Events
    private onFiltersSetEvent = new EventEmitter<DisplayFilter[]>();
    public onActiveFilterChangeEvent = new EventEmitter<DisplayFilter>();

    public constructor(name: string, settings: FiltersInterface) {
        this.name = name;
        this.settings = settings;
    }

    public setFilters(value: DisplayFilter[]) {
        value.sort((a, b) => {
            const aIndex = a.my_settings?.position ?? 0;
            const bIndex = b.my_settings?.position ?? 0;
             if (aIndex == bIndex) {
                return a.id - b.id;
            } else {
                return aIndex > bIndex ? 1 : -1;
            }
        });
        this.filters = value;
        this.onFiltersSetEvent.emit(value);
    }

    public setActiveFilter(value: DisplayFilter) {
        this.activeFilter = value;
        this.onActiveFilterChangeEvent.emit(value);
    }

    public onActiveFilterChangeEventSubscribe(next?: (value: DisplayFilter) => void): Subscription {
        const subscription = this.onActiveFilterChangeEvent.subscribe(next);
        if (this.activeFilter) {
            next(this.activeFilter);
        }
        return subscription;
    }

}
