import {GenericTableColumn} from "@app/pages/displays/display-team/TableColumns/GenericTableColumn";
import {TableColumns} from "@app/pages/displays/display-team/Helpers/TableColumns";
import {TaskListSoftNextWeekColumn} from "@app/pages/displays/display-team/Columns/TaskListSoftNextWeekColumn";

export class TaskListSoftNextWeekTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TaskList_SoftNextWeek;

    public column: TaskListSoftNextWeekColumn;

}
