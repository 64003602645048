import {Widths} from "@app/constants";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {TemplateRef} from "@angular/core";
import {ColumnTypes} from "@app/pages/displays/display-projects/ColumnTypes";
import {YearWheelColumn} from "@app/pages/displays/display-projects/Columns/YearWheelColumn";

export class YearWheelColumnType extends BaseColumnType {

    public identifier = ColumnTypes.YearWheel;

    public isAlwaysVisible = false;

    public customWidth?: number;
    public minWidth = (Widths.CardColumn * 2) + 10;
    public maxWidth?: number;

    public resizeable = true;
    public canAutoResize = true;

    public frozenLeft = false;
    public cellClass?: string;

    public isList = false;

    public constructor(cellTemplate?: TemplateRef<any>, headerTemplate?: TemplateRef<any>) {
        super();
        this.cellTemplate = cellTemplate;
        this.headerTemplate = headerTemplate;
    }

    public createColumn(column: Column, settings: Map<string, ColumnSetting>): YearWheelColumn {
        return new YearWheelColumn(this, column, settings);
    }

}
