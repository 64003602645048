import {Component, Input, OnInit} from '@angular/core';
import {LoadCalculateResponse} from '@app/http/responses';
import {PeriodRating} from '@app/core/models/PeriodRating';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {Site} from "@app/core/models";
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {Api, LoadCalculationResponse} from "@app/core/http/Api/Api";
import {ConfigHelper} from "@app/core/ConfigHelper";
import {Configs} from "@app/constants";
import {NonOffice365EventFilter} from "@app/shared/_ui/lists/multi-list/OnScreenFilters/NonOffice365EventFilter";

@Component({
    selector: 'app-time-calculator',
    templateUrl: './time-calculator.component.html',
    styleUrls: ['./time-calculator.component.scss']
})
export class TimeCalculatorComponent extends BaseDisplayComponent implements OnInit {

    @Input() loadCalculateResponse: LoadCalculateResponse | LoadCalculationResponse;
    @Input() periodRating: PeriodRating;
    @Input() showLoad = false;
    @Input() showLoadWorkHours = false;
    @Input() showLoadFreeHours = false;
    @Input() displayColumn = true;
    public active: boolean = false;
    public meetingInteractive: boolean = false;

    // Data
    private workHoursChangedSubject: Subject<number> = new Subject();
    private loadChangedSubject: Subject<number> = new Subject();
    private customLoadChangedSubject: Subject<number> = new Subject();

    constructor(private dialogService: BaseDialogService) {
        super();
        ConfigHelper.GetBool(Configs.Feature_Azure, value => {
            this.meetingInteractive = !value;
        });
    }

    private confirmWorkTimeUpdate(amount: number) {
        this.periodRating.work_hours = amount * 60 * 60;
        this.dialogService.confirm(
            this.translateService.instant('_ui_update_work_hours'),
            this.translateService.instant('_ui_update_work_hours_description'),
            this.translateService.instant('_ui_all_following_weeks'),
            this.translateService.instant('_ui_only_this_week'),
            'md',
            false,
            'btn-success',
            false
        )
            .then(response => {
                Api.periodRatings().updateWorkHoursPutById(this.periodRating.id)
                    .updateAllFollowing(response)
                    .value(this.periodRating.work_hours)
                    .save(null);
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    private confirmAdHocUpdate(amount: number) {
        this.periodRating.load_ = amount * 60 * 60;
        this.dialogService.confirm(
            this.translateService.instant('_ui_update_ad_hoc'),
            this.translateService.instant('_ui_update_ad_hoc_description'),
            this.translateService.instant('_ui_all_following_weeks'),
            this.translateService.instant('_ui_only_this_week'),
            'md',
            false,
            'btn-success',
            false
        )
            .then(response => {
             Api.periodRatings().updateLoadPutById(this.periodRating.id)
                 .updateAllFollowing(response)
                 .value(this.periodRating.load_)
                 .save(null);
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    private confirmCustomLoadChangedSubject(amount: number) {
        this.periodRating.custom_meeting_load = amount * 60 * 60;
        this.dialogService.confirm(
            this.translateService.instant('_ui_update_custom_meeting_load'),
            this.translateService.instant('_ui_update_custom_meeting_load_description'),
            this.translateService.instant('_ui_all_following_weeks'),
            this.translateService.instant('_ui_only_this_week'),
            'md',
            false,
            'btn-success',
            false
        )
            .then(response => {
                Api.periodRatings().updateCustomMeetingLoadPutById(this.periodRating.id)
                    .updateAllFollowing(response)
                    .value(this.periodRating.custom_meeting_load)
                    .save(null);
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    private waitTimeout: any;

    ngOnInit() {
        this.workHoursChangedSubject
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(value => {
                clearTimeout(this.waitTimeout);
                this.waitTimeout = setTimeout(() => {
                    this.confirmWorkTimeUpdate(value);
                }, 100)

            });
        this.loadChangedSubject
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(value => {
                this.confirmAdHocUpdate(value);
            });
        this.customLoadChangedSubject
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(value => {
                this.confirmCustomLoadChangedSubject(value);
            });
    }

    loadChanged($event: number) {
        this.loadChangedSubject.next($event);
    }

    workHoursChanged($event: number) {
        this.workHoursChangedSubject.next($event);
    }

    customLoadChanged($event: number) {
        this.customLoadChangedSubject.next($event);
    }

    onClickedOutside(e: Event) {
        this.active = false;
    }

    get totalLoad(): number {
        let load: number = 0;
        if (this.periodRating) {
            load += this.periodRating.load_;
            load += this.periodRating.custom_meeting_load;
        }
        if (this.loadCalculateResponse) {
            load += this.loadCalculateResponse.load.events;
            load += this.loadCalculateResponse.load.tasks;
        }
        return load;
    }

    get totalWorkHours(): number {
        return this.periodRating ? (this.periodRating.work_hours / 60 / 60) : 0;
    }

    get totalFreeHours(): number {
        return this.totalWorkHours - (this.totalLoad / 60 / 60);
    }

}
