import {CardConfiguration} from '@app/shared/_ui/cards/CardConfiguration';
import {ProjectEstimate} from '@app/core/models/ProjectEstimate';
import {ProjectsDeadline} from '@app/core/models/ProjectsDeadline';
import {ProjectsUser} from '@app/core/models/ProjectsUser';
import {Project} from '@app/core/models/Project';
import {Display} from '@app/core/models/Display';
import {EventEmitter} from '@angular/core';

export class CardProjectConfiguration extends CardConfiguration<Project> {

    public projectEstimate: ProjectEstimate;
    public projectDeadline: ProjectsDeadline;
    public projectUser: ProjectsUser;

    public showGoToPlanningDisplay = true;
    public showProgramMiniCard = true;
    public showChangeType = true;              // Vis knap til at skifte type
    public showPhase = true;
    public showPhaseIfHasProgress = false;
    public showActions: boolean = true;         // Fuldfør, stjerne, hånden op
    public showNextMilestoneMicroCard = true;
    public showCategories = false;

    public excludedProgramDisplays: Display[] = [];
    public excludedCategoryIds: number[];
    public onGoToProgramClickEvent: EventEmitter<Display> = new EventEmitter<Display>();

    constructor(projectEstimate?: ProjectEstimate,
                projectDeadline?: ProjectsDeadline,
                projectUser?: ProjectsUser,
                showProgramMiniCard: boolean = true,
                showChangeType: boolean = true) {
        super();
        this.projectEstimate = projectEstimate;
        this.projectDeadline = projectDeadline;
        this.projectUser = projectUser;
        this.showProgramMiniCard = showProgramMiniCard;
        this.showChangeType = showChangeType;
        this.showCategories = true;
    }

    get exists(): boolean {
        if (this.projectDeadline) { return true; }
    }

    public compare(config: CardProjectConfiguration): boolean {
        const validProjectEstimate = (this.projectEstimate == null && config.projectEstimate == null)
            || (this.projectEstimate != null && config.projectEstimate != null
                && this.projectEstimate.id == config.projectEstimate.id);
        const validProjectDeadline = (this.projectDeadline == null && config.projectDeadline == null)
            || (this.projectDeadline != null && config.projectDeadline != null
                && this.projectDeadline.id == config.projectDeadline.id);
        const validProjectsUser = (this.projectUser == null && config.projectUser == null)
            || (this.projectUser != null && config.projectUser != null
                && this.projectUser.id == config.projectUser.id);
        return validProjectEstimate && validProjectDeadline && validProjectsUser;
    }

}
