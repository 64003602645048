import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from "@angular/core";
import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";

@Component({
    selector: 'app-editor-switcher',
    templateUrl: './editor-switcher.component.html',
    styleUrls: ['./editor-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorSwitcherComponent extends BaseComponent implements OnInit {

    @Input() name: string;
    @Input() configuration: BaseEditorConfiguration;
    @Input() model: any;
    @Input() card: CardItem;
    @Input() listDragInterface: ListDragInterface;
    @Input() editorEvents: EditorEvents;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
    }

}
