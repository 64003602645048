import {BrowserModule, Title} from '@angular/platform-browser';
import {Injector, LOCALE_ID, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MissingTranslationHandler, TranslateModule} from '@ngx-translate/core';
import {
    NgbActiveModal,
    NgbDateParserFormatter,
    NgbDatepickerI18n,
    NgbModalModule,
    NgbModule,
    NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationGuard, AuthenticationService, CoreModule} from '@app/core';
import {ShellModule} from './shell/shell.module';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {OAuthModule} from 'angular-oauth2-oidc';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AppInjector} from '@app/services/app-injector.service';
import {CustomDatepickerI18n} from '@app/datepicker-i18n';
import {MomentDateFormatter} from '@app/dateformat';
import {CommonModule, registerLocaleData} from '@angular/common';
import localeDaDk from '@angular/common/locales/da';
import localeNb from '@angular/common/locales/nb';
import localeNn from '@angular/common/locales/nn';
import {DomService} from '@app/services/dom.service';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ClickOutsideModule} from 'ng-click-outside';
import {environment} from '@env/environment';
import {ResizableModule} from 'angular-resizable-element';
import {QuillModule} from 'ngx-quill';
import {NgxScreenfullModule} from '@ngx-extensions/screenfull';
import {MyMissingTranslationHandler} from '@app/core/MyMissingTranslationHandler';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NgProgressHttpModule} from 'ngx-progressbar/http';
import {NgProgressModule} from 'ngx-progressbar';
import {WampService} from '../services/wamp.service';
import {DBConfig, NgxIndexedDBModule} from 'ngx-indexed-db';
import {System} from '@app/constants';

// Ahead of time compiles requires an exported function for factories
export function migrationFactory() {
    // The animal table was added with version 2 but none of the existing tables or data needed
    // to be modified so a migrator for that version is not included.
    return {
        1: (db:any, transaction:any) => {
            const store = transaction.objectStore(System.DBCache);
            store.createIndex('timeToLiveInSeconds', 'timeToLiveInSeconds', { unique: false });
        },

    };
}

const dbConfig: DBConfig  = {
    name: 'KlartboardLocalDB',
    version: 1,
    objectStoresMeta: [{
        store: System.DBCache,
        storeConfig: { keyPath: 'key', autoIncrement: false },
        storeSchema: [
            { name: 'data', keypath: 'data', options: { unique: false } },
            { name: 'key', keypath: 'key', options: { unique: true } },
            { name: 'userId', keypath: 'userId', options: { unique: false } },
            { name: 'created', keypath: 'created', options: { unique: false } },
            { name: 'version', keypath: 'version', options: { unique: false } }
        ]
    }],
    migrationFactory

};


registerLocaleData(localeDaDk); // Note: Language / Andre sprog (da-DK)
registerLocaleData(localeNb);
registerLocaleData(localeNn);

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
        HttpClientModule,
        NgxIndexedDBModule.forRoot(dbConfig),
        TranslateModule.forRoot({
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MyMissingTranslationHandler,
            },
        }),
        CoreModule,
        ShellModule,
        BrowserAnimationsModule,
        DragDropModule,
        ScrollingModule,
        MatSnackBarModule,
        ClickOutsideModule,
        ResizableModule,
        QuillModule.forRoot(),
        ReactiveFormsModule,
        NgxScreenfullModule,
        NgbModule,
        NgbModalModule,
        NgbTooltipModule,
        NgProgressModule.withConfig({
            trickleSpeed: 100,
            min: 20,
            spinner: false,
            meteor: true,
            thick: true,
            debounceTime: 100,
            color: '#01aaa0' // 01aaa0
        }),
        NgProgressHttpModule.withConfig({
            silentApis: [
                environment.serverUrl + '/language_values',
                environment.serverUrl + '/connect/userinfo'
            ]
        }),
        MatProgressSpinnerModule,
        MatDatepickerModule,
        OAuthModule.forRoot(),
        AppRoutingModule, // Page routing: Must be imported as the last module as it contains the fallback activatedRoute
    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        AppComponent
    ],
    exports: [],
    providers: [
        Title,
        AuthenticationGuard,
        AuthenticationService,
        NgbActiveModal,
        DomService,
        WampService,
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
        { provide: LOCALE_ID, useValue: 'da-DK' },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(injector: Injector) {
        try {
            AppInjector.setInjector(injector);
        } catch (e) {
            console.error('Error : ', e);
        }
    }
}
