import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output, ViewChild
} from '@angular/core';
import {CardComponent} from '@app/shared/_ui/cards/card.component';
import {CardTodoConfiguration} from '@app/shared/_ui/cards/medium/card-todo/card-todo-configuration';
import {Todo} from '@app/core/models/Todo';
import {Archived} from '@app/core/models/Archived';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {Project} from '@app/core/models/Project';
import {CardProjectConfiguration} from '@app/shared/_ui/cards/medium/card-project/card-project-configuration';
import {Field, TodoEditorService} from '@app/editor/todo-editor-loader/todo-editor.service';
import {Fields, MiniCardFields} from '@app/editor/todo-editor-loader/Fields';
import {TodoField} from "@app/core/models/TodoField";
import {TodosService} from "@app/services/todos.service";
import {ReactionsTypeInterface} from "@app/shared/_ui/reactions/ReactionsTypeInterface";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";

@Component({
    selector: 'app-card-todo',
    templateUrl: './card-todo.component.html',
    styleUrls: ['../../card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardTodoComponent extends CardComponent implements OnInit {

    @Input() item: CardItem<Todo>;
    @Input() model: Todo;
    @Input() configuration: CardTodoConfiguration;
    @Input() listConfiguration: ListConfiguration;
    @Output() onCardClickEvent = new EventEmitter();
    @Output() onRemove = new EventEmitter();
    @Input() removable = false;
    @Input() tooltip: string;
    @Input() editable = false;
    @Input() interactive = true;
    @ViewChild('cardContainer') cardContainer: ElementRef;

    public projectCard: CardItem<Project>; // Tilknyttet projekt
    public editorFields: Map<number, Field>;
    private fields: Map<number, TodoField>;
    public reactionsTypeInterfaces: ReactionsTypeInterface[];
    public hasVisibleReactions = false;

    constructor(private cd: ChangeDetectorRef,
                private editorService: TodoEditorService,
                private todosService: TodosService) {
        super();
        this.cdr = cd;
        this.initialized = true;
    }
    ngOnInit() {
        this.editorService.getFields(fields => {
            this.editorFields = fields;
        });

        if(!this.model && this.item) {
            this.model = this.item.item as Todo;
        }

        if(this.item && this.item.item.project && this.configuration && this.configuration.showProjectMiniCard) {
            this.projectCard = new CardItem<Project>(this.item.item.project, new CardProjectConfiguration());
        } else {
            this.projectCard = null;
        }
        super.ngOnInit();

        this.todosService.getMiniCardFields(fields => {
            this.fields = new Map();
            fields.forEach(field => this.fields.set(field.id, field));
            this.detectChanges();
        })

        this.todosService.getReactionTypes(reactionTypes => {
            this.reactionsTypeInterfaces = reactionTypes;
            this.render();
            this.detectChanges();
        })
    }


    onItemUpdated(item: Todo) {
        super.onItemUpdated(item);
        this.render();
        this.detectChanges();
    }

    get EditorFields() {
        return Fields;
    }

    get Fields() {
        return MiniCardFields;
    }

    public isFieldVisible(fieldId: number): boolean {
        if (this.fields && this.fields.get(fieldId)) {
            return this.fields.get(fieldId).visible;
        } else {
            return false;
        }
    }

    onStatusChange($event: number) {
        if (this.model.id != 0 && !this.editable) {
            this.model.setStatus($event);
        }
    }

    onArchivedToggle($event: any) {
        this.model.setArchived(!this.model.isArchived());
    }

}
