import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StatusRule} from '@app/core/models/StatusRule';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import ChangeEvent = JQuery.ChangeEvent;

@Component({
    selector: 'app-status-rule-item',
    templateUrl: './status-rule-item.component.html',
    styleUrls: ['./status-rule-item.component.scss'],

})
export class StatusRuleItemComponent extends BaseDisplayComponent implements OnInit {

    @Input() model: StatusRule;
    @Input() readonly = false;
    @Input() showRemove = true;
    @Output() removeItemEvent = new EventEmitter<StatusRule>();
    @Output() onChange = new EventEmitter<StatusRule>();
    public days: number = 0;

    constructor() {
        super();

    }

    ngOnInit() {
        if (this.model && this.model.date_difference) {
            this.days = this.model.date_difference / 60 / 60 / 24;
        } else {
            this.days = 0;
        }
    }

    public removeItem() {
        this.removeItemEvent.emit(this.model);
    }

    changeValue($event: number, modelKey: string) {
        this.model[modelKey] = $event;
        this.emitChange();
    }

    private debounceTimeout: any;

    daysChange($event: ChangeEvent) {
        if(!this.days) this.days = 0;
        this.model.date_difference = this.days * 60 * 60 * 24;
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(()=>{
            this.emitChange();
        }, 500)

    }
    emitChange(){
        this.onChange.emit(this.model);
    }
}
