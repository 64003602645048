import {NgModule} from "@angular/core";
import {
    ProjectSelectSearchComponent
} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-tabs/project-editor-tab-attach-to/attached-types/project-select-search/project-select-search.component";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {NgbPopoverModule, NgbTooltipModule, NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {DepartmentPickerModule} from "@app/shared/_forms/department-picker/DepartmentPicker.module";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";

@NgModule({
    imports: [
        CommonModule,
        ColorLabelModule,
        NgbTypeaheadModule,
        UserWithIconModule,
        NgbTooltipModule,
        NgbPopoverModule,
        DepartmentPickerModule,
        FormsModule,
        TranslateModule,
        TypedTemplateDirective

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectSelectSearchComponent,
    ],
    exports: [
        ProjectSelectSearchComponent

    ],
    providers: [

    ],
})
export class ProjectSelectSearchModule {}
