<div class="dropdown-item d-flex justify-content-start align-items-center border-bottom-none">
    <app-toggle-item class="me-2"

                     [active]="true"
                     [underline]="true"
                     [iconWeightActive]="'fal'"
                     [iconWeightInactive]="'fal'"
                     [iconInactive]="iconClass"
                     [icon]="iconClass"

                     [iconColorInactive]="'text-secondary'"
                     [iconColorActive]="'text-secondary'"
                     [label]="(label | translate)"
    ></app-toggle-item>
    <i class="sqaure ms-auto" *ngIf="badgeLabel">{{badgeLabel}}</i>
</div>
