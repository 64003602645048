/**
 * Created by ModelParser
 * Date: 14-08-2020.
 * Time: 12:04.
 */
import {ColorDefinition} from './definitions/ColorDefinition';
import {Api} from "@app/core/http/Api/Api";

export class Color extends ColorDefinition {

    constructor(json?: any) {
        super(json);
    }

    // <editor-fold desc="Colors (Queue & Cache)">

    private static Cache: Color[];
    private static CacheCallbacks: ((items: Color[]) => void)[] = [];

    public static GetAll(callback: (items: Color[]) => void) {
        if (this.Cache) {
            callback(this.Cache);
        } else if (this.CacheCallbacks.length > 0) {
            this.CacheCallbacks.push(callback);
        } else {
            this.CacheCallbacks.push(callback);
            Api.colors().get().find(items => {
                this.Cache = items;
                this.CacheCallbacks.forEach(callback => callback(items));
                this.CacheCallbacks = [];
            });
        }
    }

    public static GetById(id: number, callback: (item: Color) => void) {
        Color.GetAll(items => callback(items.find(item => item.id == id)));
    }

    // </editor-fold>

}
