/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {DisplayDefinition} from './definitions/DisplayDefinition';
import {DisplayTypes} from '@app/constants';
import {AppInjector} from '@app/services/app-injector.service';
import {Router} from '@angular/router';
import {DepartmentsDisplay} from '@app/core/models/DepartmentsDisplay';
import {User} from '@app/core/models/User';
import {Project} from '@app/core/models/Project';

export class Display extends DisplayDefinition {

    constructor(json?: any) {
        super(json);
    }

    get icon(): string {
        return 'far fa-arrow-right';
    }

    navigate(user?: User, departmentsDisplay?: DepartmentsDisplay) {
        if (departmentsDisplay) {
            const route = this.getRoute(departmentsDisplay, user);
            if (route) {
                const router = AppInjector.getInjector().get(Router);
                if (router.url !== route.join('/')) {
                    AppInjector.getInjector().get(Router).navigate(route, {queryParamsHandling: 'merge'});
                }
            } else {
                console.warn('Display : No route! : ', departmentsDisplay, user, route)
            }
        } else {
            // console.warn('Display : Missing departmentDisplay : ', departmentsDisplay, user)
        }
    }

    get routerPath(): string {
        switch (this.display_type_id) {
            case DisplayTypes.Team:
                return `displays/teams/${this.id}`;     // Teamtavle
            case DisplayTypes.Projects:
                return `displays/projects/${this.id}`;  // Projekttavle
            case DisplayTypes.UserOverview:
                return `displays/dashboard/${this.id}`; // Mit overblik
            case DisplayTypes.Efforts:
                return `displays/tasks/${this.id}`;     // Indsatstavle?
            case DisplayTypes.TeamWeekly:
                return `displays/teams/weekly/${this.id}`;
            case DisplayTypes.Kanban:
                return `displays/kanban/${this.id}`;
            case DisplayTypes.KanbanProgress:
                return `displays/kanban-progress/${this.id}`;
            case DisplayTypes.Category:
                return `displays/categories/${this.id}`;
            default:
            //console.error('Unknown display_type_id : ', this);
        }
        return null;
    }

    getRoute(departmentsDisplay?: DepartmentsDisplay, user?: User): any[] {
        let route: string = null;
        if (this) {
            switch (this.display_type_id) {
                case DisplayTypes.UserOverview:
                    route = `/app/displays/dashboard`;
                    break;
                case DisplayTypes.Team:
                    route = `/app/displays/teams`;
                    break;
                case DisplayTypes.Projects:
                    route = `/app/displays/projects`;
                    break;
                case DisplayTypes.TeamWeekly:
                    route = `/app/displays/teams/weekly`;
                    break;
                case DisplayTypes.Kanban:
                    route = `/app/displays/kanban`;
                    break;
                case DisplayTypes.KanbanProgress:
                    route = `/app/displays/kanban-progress`;
                    break;
                case DisplayTypes.Category:
                    route = `/app/displays/categories`;
                    break;
                default:
                    console.warn('getRoute() : Unknown display_type_id : ', this.display_type_id);
                    return null;
            }
        }


        let items: any[] = [route];

        if (departmentsDisplay && departmentsDisplay.department) {
            items.push(this.id);
            items.push(departmentsDisplay.department.id);
        } else {
            items.push(this.id);
            items.push(this.departments_displays[0].department_id);
        }

        if (user) {
            items.push(user.id);
        }

        if (route) {
            return items;
        } else {
            console.warn('getRoute() : not found : ', route)
        }
        return [];
    }

    public getName(project?: Project) {
        if (project) {
            return project.title;
        } else {
            return this.name;
        }
    }

}
