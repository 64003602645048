import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {TaskPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/TaskPresetType";

export class TaskMilestonePresetGenerator implements CreatePresetGenerator {

    private readonly milestoneId?: number;
    private readonly milestoneIdHandler?: () => number;

    constructor(milestoneIdOrHandler: number | (() => number)) {
        if (typeof milestoneIdOrHandler == "number") {
            this.milestoneId = milestoneIdOrHandler;
        } else {
            this.milestoneIdHandler = milestoneIdOrHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: TaskPresetTypes.Milestone,
            payload: {
                milestoneId: this.milestoneId ?? this.milestoneIdHandler(),
            }
        };
    }

}
