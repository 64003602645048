import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {PANEL_ANIMATION} from '@app/editor/panel.animation';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {Project} from '@app/core/models';
import AvailableEditors from '@app/editor/quick-editor/AvailableEditors';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EventService} from '@app/services/event.service';
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {CardProjectConfiguration} from "@app/shared/_ui/cards/medium/card-project/card-project-configuration";

@Component({
    selector: 'app-create-project-template-dialog',
    templateUrl: './create-project-template-dialog.component.html',
    styleUrls: ['./create-project-template-dialog.component.scss'],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation,
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0.5
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateProjectTemplateDialogComponent extends BaseModalComponent implements OnInit, EditorEvents<Project> {

    // Bindings to parent
    @Input() model: Project;
    @Input() title: string;

    // Bindings to view
    public editorTypes = AvailableEditors.QuickCreateWithTemplate();
    public editorEvents = this;
    public card: CardItem<Project>;

    constructor(private modal: NgbActiveModal,
                private cd: ChangeDetectorRef,
                private eventsService: EventService) {
        super(modal);
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.isOpen = true;
    }

    public decline() {
        // Delete project if created
        this.activeModal.close(false);
    }

    public accept() {
        this.activeModal.close({project: this.model});
    }

    public dismiss() {
        this.activeModal.dismiss();
        this.activeModal.close(false);
    }

    // <editor-fold desc="EditorEvents">

    public onItemUpdated(item: Project): void {
        this.card = new CardItem<Project>(item, new CardProjectConfiguration());
        this.detectChanges();
    }

    // </editor-fold>

}
