import {ProjectType} from '@app/core/models/ProjectType';
import {TaskType} from '@app/core/models/TaskType';
import {AppInjector} from '@app/services/app-injector.service';
import {ProjectsService} from '@app/services/projects.service';
import {TasksService} from '@app/services/tasks.service';
import {CreateItemSourceInterface} from '@app/shared/_ui/create-item-dropdown/CreateItemSourceInterface';

export class CreateItemSourceConfiguration {

    // Lazy populate this class
    public sourceInterface: CreateItemSourceInterface;

    // Filter
    public filterByDepartmentIds: number[];
    // Filter: Tasks
    public filterTaskTypesById: number[];
    public filterByAvoidTaskTypeIds: number[];
    // Filter: Projects
    public filterProjectsTypesById: number[];

    private projectTypes?: ProjectType[];
    private taskTypes?: TaskType[];

    // Elements to show
    public showTasks = true;
    public showProjects = true;
    public showMilestone = true;
    public showTodo = true;
    public showAppointments = false;

    public showEditor = true;

    public static Create(): CreateItemSourceConfiguration {
        const item = new CreateItemSourceConfiguration();
        // Reset everything - as it should be…
        item.showTasks = false;
        item.showProjects = false;
        item.showMilestone = false;
        item.showTodo = false;
        item.showAppointments = false;
        item.showTasks = false;
        item.showEditor = false;
        return item;
    }

    public static AllInDepartment(departmentIds: number[], milestone = true, todo = true): CreateItemSourceConfiguration {
        const config = new CreateItemSourceConfiguration();
        config.filterByDepartmentIds = departmentIds ? departmentIds : [];
        config.showMilestone = milestone;
        config.showTodo = todo;
        return config;
    }

    public getTaskTypes(callback: (items: TaskType[]) => void) {
        const tasksService = AppInjector.getInjector().get(TasksService);
        if (this.taskTypes) {
            callback(this.taskTypes);
        } else {
            tasksService.getTaskTypes(items => {

                const filteredItems = items.filter(item => {
                    if (this.filterByAvoidTaskTypeIds && this.filterByAvoidTaskTypeIds.includes(item.id)) {
                        return false;
                    }

                    // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1028
                    // https://podio.com/klartboard/softwareudvikling/apps/supports/items/217
                    // https://podio.com/klartboard/softwareudvikling/apps/stories/items/908
                    if (this.filterByDepartmentIds
                        && !item.departments?.find(department => this.filterByDepartmentIds.includes(department.id))) {
                        return false;
                    }

                    if (this.filterTaskTypesById && !this.filterTaskTypesById.includes(item.id)) {
                        return false;
                    }
                    return true;
                });

                callback(filteredItems);
            });
        }
    }

    public getProjectTypes(callback: (items: ProjectType[]) => void) {
        const projectsService = AppInjector.getInjector().get(ProjectsService);
        if (this.projectTypes) {
            callback(this.projectTypes);
        } else {
            projectsService.getProjectTypes(items => {

                const filteredItems = items.filter(item => {
                    if (this.filterByDepartmentIds
                        && !item.departments?.find(department => this.filterByDepartmentIds.includes(department.id))) {
                        return false;
                    }

                    if (this.filterProjectsTypesById
                        && !this.filterProjectsTypesById.includes(item.id)) {
                        return false;
                    }

                    return true;
                });

                callback(filteredItems);
            });
        }
    }

}
