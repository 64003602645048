import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {UnauthorizedComponent} from '@app/pages/unauthorized/unauthorized.component';
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        UnauthorizedComponent,
    ]
})
export class UnauthorizedModule {
}
