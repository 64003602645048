<div
    *ngIf="isReady"
    class="container p-2 d-flex gap-1 w-100 flex-wrap editor-tab"
>

    <app-phases-project-editor
        *ngIf="showPhaseEditor && isSelectedTab"
        class="sticky-formgroup"
        [model]="item"
        [configuration]="phasesProjectEditorConfiguration"
    />

    <div class="form-group">
        <label class="d-flex d-no">
            <span>{{'_ui_process_steps_process_step_title' | translate}}</span>
        </label>
        <app-process-steps
            *ngIf="isSelectedTab"
            [projectId]="item.id"
        />
    </div>

</div>
