<div class="container-fluid p-0 h-100" [class.exporting]="isCapturingScreenshot">
    <div class="d-flex flex-column h-100" *ngIf="initialized">
        <div class="flex-fill rounded position-relative" #dataTableContainer>
            <ngx-datatable class="material bootstrap datatable-flex-fill" id="dataTable"
                           #dataTable
                           ngxDatatableExtension [table]="dataTable"

                           [rows]="row ? [row] : []"
                           [columns]="tableColumns"
                           [columnMode]="'force'"
                           [scrollbarH]="true"
                           [scrollbarV]="true"
                           [headerHeight]="30"
                           [footerHeight]="30"
                           [rowHeight]="'auto'"
                           [loadingIndicator]="true"
                           [externalSorting]="true"
                           [virtualization]="false"
            >

                <ngx-datatable-footer>
                    <ng-template
                        ngx-datatable-footer-template
                        let-rowCount="rowCount"
                        let-pageSize="pageSize"
                        let-selectedCount="selectedCount"
                        let-curPage="curPage"
                        let-offset="offset"
                        let-isVisible="isVisible">

                        <!-- _ui_show_all -->
                        <div class="-ms-auto d-flex align-items-center">
                            <div class="d-flex align-items-center footer-actions -ms-auto pe-2 ps-2">
                                <a class="me-2  d-none"
                                   (click)="onCaptureScreenshotBtnClicked();"
                                   [ngbTooltip]="('_ui_screenshot' | translate) + ' (' +(display?.name)+')'"
                                >
                                    <i class="fal fa-camera-viewfinder d-none" *ngIf="!isCapturingScreenshot"></i>
                                    <app-loading-indicator [showLogo]="false" *ngIf="isCapturingScreenshot"></app-loading-indicator>
                                </a>

                                <a class="text-center me-2 " (click)="loadData()"
                                   style="transform: translateY(-1px);"
                                   [ngbTooltip]="('_ui_reload' | translate) + '...'"
                                   container="body"

                                   placement="auto"><i class="fal fa-light fa-arrow-rotate-right"></i></a>

                                <a class="no-caret text-white me-2"
                                   [ngbTooltip]="(!isMinimized ? ('_ui_show_less' | translate) : ('_ui_expand_lists' | translate)) + '...'"
                                   (click)="toggleIsMinimized()">
                                    <i class="fal"
                                       [class.fa-arrows-from-line]="isMinimized"
                                       [class.fa-arrows-to-line]="!isMinimized"></i>
                                </a>

                                <app-on-screen-filter-selector
                                    [onScreenFilters]="taskListsOnScreenFilters"
                                ></app-on-screen-filter-selector>

                            </div>

                            <div class="d-flex" *ngIf="false">
                                <pre>curPage: {{curPage}}</pre>
                                <pre>pageSize: {{pageSize}}</pre>
                                <pre>rowCount: {{rowCount}}</pre>
                            </div>


                        </div>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
        </div>
    </div>
</div>

<!-- Header template -->
<ng-template #headerTemplate let-column="column" ngx-datatable-header-template
             [typedTemplate]="templateTypes.headerTemplateType"
>
    <ngx-datatable-column
        [name]="column.name"
        [width]="column.width"
        [maxWidth]="column.maxWidth"
        [minWidth]="column.minWidth"
        [sortable]="false"
        [resizeable]="column.resizeable">
        <div class="column-title ps-1 pe-1 d-flex align-items-center justify-content-start w-100">
            <app-column-sorter [display]="display" [column]="column"
                               [filterPageData]="shellPageData"
                               (columnSortChange)="onColumnSortChanged($event)"
            ></app-column-sorter>
        </div>
    </ngx-datatable-column>
</ng-template>
<!-- Header Period template -->
<ng-template #headerWithPeriodTemplate let-column="column" ngx-datatable-header-template
             [typedTemplate]="templateTypes.headerTaskListPeriodType"
>
    <ngx-datatable-column
        [name]="column.name"
        [width]="column.width"
        [maxWidth]="column.maxWidth"
        [minWidth]="column.minWidth"
        [sortable]="false"
        [resizeable]="column.resizeable">
        <div class="column-title ps-1 pe-1 d-flex align-items-center justify-content-start w-100">
            <span [innerHTML]="column.getPeriodString()" class="me-1 rounded p-0 ps-1 pe-1 text-small border"></span>
            <app-column-sorter [display]="display" [column]="column"
                               [filterPageData]="shellPageData"
                               [onRenderEvent]="onPeriodColumnSortRenderEventEmitter"
                               (columnSortChange)="onColumnSortChanged($event)"
            ></app-column-sorter>
        </div>
    </ngx-datatable-column>
</ng-template>
<!-- Header Status template -->
<ng-template #headerWithStatusTemplate let-column="column" ngx-datatable-header-template
             [typedTemplate]="templateTypes.headerTaskListStatusType"
>
    <ngx-datatable-column
        [name]="column.name"
        [width]="column.width"
        [maxWidth]="column.maxWidth"
        [minWidth]="column.minWidth"
        [sortable]="false"
        [resizeable]="column.resizeable">
        <div class="column-title ps-1 pe-1 d-flex align-items-center justify-content-start w-100">
            <span class="circle {{column.getColorClass()}} me-1"></span>
            <app-column-sorter [display]="display" [column]="column"
                               [filterPageData]="shellPageData"
                               [onRenderEvent]="onStatusColumnSortRenderEventEmitter"
                               (columnSortChange)="onColumnSortChanged($event)"
            ></app-column-sorter>
        </div>
    </ngx-datatable-column>
</ng-template>
<!-- Header Task Edit template -->
<ng-template #headerTaskEditTemplate
             let-tableColumn="column"
             ngx-datatable-header-template
             [typedTemplate]="templateTypes.headerTaskEditTemplateType"
>
    <app-card-vertical-edit-columns
        [enabledEditors]="tableColumn.column.enabledEditors"
    ></app-card-vertical-edit-columns>
</ng-template>

<!-- <editor-fold desc="Inddatering"> -->

<!-- Header template -->

<!-- Task Edit List template -->
<ng-template #cellTaskEditListTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex" ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTaskEditType"
>
    <app-task-list
        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
        class="w-100"
        [configuration]="tableColumn.column.getCell(row).listConfiguration"
        [minimizeEvent]="row.minimizeEvent"
        (dataSetChanged)="setRowTaskEditListItems($event)">
    </app-task-list>
</ng-template>
<!-- Task Edit WhoWhen Header Template -->
<ng-template #cellTaskEditListWhoWhenHeaderTemplate
             let-column="column"
             [typedTemplate]="templateTypes.cellTemplateType"
>
    <div class="column-title ps-1 pe-1 d-flex align-items-center justify-content-start w-100">
        <span class="flex-shrink-1 ellipsis"
              [ngbTooltip]="column.name"
              [translate]="column.name"
        ></span>
        <div
            class="ms-auto buttons d-flex flex-row justify-content-end align-items-center flex-shrink-0 flex-grow-1 overflow-hidden p-0 m-0 pe-2">
            <app-milestone-task-edit-who-selector-popover
                [tooltip]="'_ui_add_participant_to_all' | translate"
                [selectedUsers]="selectedParticipants"
                (addItem)="addParticipantToAll($event)"
                (removeItem)="removeParticipantFromAll($event)"
            ></app-milestone-task-edit-who-selector-popover>
        </div>
    </div>
</ng-template>

<!-- </editor-fold> -->

<!-- Task List Status template -->
<ng-template #cellTaskListStatusTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex" ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTaskListStatusType"
>
    <app-task-list
        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
        class="w-100"
        [configuration]="tableColumn.column.getCell(row).listConfigurations.get(tableColumn.status)"
        [minimizeEvent]="row.minimizeEvent">
    </app-task-list>
</ng-template>

<!-- Task List Period template -->
<ng-template #cellTaskListPeriodTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex" ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTaskListPeriodType"
>
    <app-warning-label *ngIf="showPeriodColumnWarning"></app-warning-label>
    <app-task-list
        *ngIf="!showPeriodColumnWarning"
        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
        class="w-100"
        [configuration]="tableColumn.column.getCell(row).listConfigurations.get(tableColumn.periodType)"
        [minimizeEvent]="row.minimizeEvent">
    </app-task-list>
</ng-template>

<!-- Task List template -->
<ng-template #cellTaskListTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex" ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTaskListAllType"
>
    <app-task-list
        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
        class="w-100"
        [configuration]="tableColumn.column.getCell(row).listConfiguration"
        [minimizeEvent]="row.minimizeEvent">
    </app-task-list>
</ng-template>
