import {NgModule} from "@angular/core";
import {CaseArchivedDialogComponent} from "@app/shared/_modals/case-archived-dialog/case-archived-dialog.component";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {NgxModalDraggableDirectiveModule} from "@app/directives/NgxModalDraggableDirective.module";

@NgModule({
    imports: [
        CommonModule,
        ColorItemModule,
        TranslateModule,
        NgbTooltipModule,
        ReactiveFormsModule,
        NgxModalDraggableDirectiveModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CaseArchivedDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class CardArchivedDialogModule {}
