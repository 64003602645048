import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-priority-matrix',
    templateUrl: './priority-matrix.component.html',
    styleUrls: ['./priority-matrix.component.scss']
})
export class PriorityMatrixComponent implements OnInit {

    @Input() color1: string;
    @Input() color2: string;
    @Input() color3: string;
    @Input() color4: string;
    @Input() text1: string;
    @Input() text2: string;
    @Input() text3: string;
    @Input() text4: string;
    @Input() axisX: string;
    @Input() axisY: string;

    constructor() {
    }

    ngOnInit(): void {

    }

}
