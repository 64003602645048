<div class="form-group flex-grow-1 align-items-center p-0 m-0" *ngIf="configuration">
    <label *ngIf="configuration?.labelName">
        <span>{{ configuration.labelName | translate }}</span>
        <span *ngIf="configuration?.validator.required" class="text-danger">*</span>
    </label>
    <textarea
        [class.singleline]="!isExpanded"
        [(ngModel)]="value"
        placeholder=""
        class="form-control"
        name="title"
        autocomplete="on"
        #fieldValue="ngModel"
        [required]="configuration?.validator.required"
        [autofocusAfterInit]="configuration?.autofocus"
        [class.is-invalid]="fieldValue?.invalid"
        (ngModelChange)="onValueChanged()"
        (focus)="onFocusChanged(true)"
        (focusout)="onFocusChanged(false)"
    ></textarea>
    <div *ngIf="fieldValue.invalid && (fieldValue.dirty || fieldValue.touched)"
         class="alert d-none">
        <span *ngIf="fieldValue.errors?.['required']"
              [ngbTooltip]="('_ui_missing_fields' | translate)"
              class="text-warning fal fa-circle-exclamation cursor-pointer"></span>
    </div>
</div>
