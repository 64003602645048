import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';

@Component({
    selector: 'app-value-evaluator',
    templateUrl: './value-evaluator.component.html',
    styleUrls: ['./value-evaluator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValueEvaluatorComponent implements OnInit, OnChanges {

    @Input() value: number = 0;
    @Input() icon: string;
    @Input() target: number;
    @Input() badge: boolean = false;
    @Input() showColors: boolean = false;
    @Input() showZero: boolean = true;

    public visible: boolean = true;

    constructor(private cdRef:ChangeDetectorRef,
                private _ngZone: NgZone) {
    }

    ngOnInit() {
        this.checkVisibility();
    }

    ngOnChanges(changes: SimpleChanges): void {

        if((changes['value'] || changes['showColors']  || changes['target']) && this.cdRef) {
            setTimeout(() => {
                this.cdRef.detectChanges();
                this.checkVisibility();
            }, 100);
        }else{
            this.checkVisibility();
        }

    }

    checkVisibility() {
        if (!this.showZero && this.value === 0) {
            this.visible = false;
        } else {
            this.visible = true;
        }

        this.cdRef.detectChanges();


    }

}
