import {NgModule} from "@angular/core";
import {DisplayLoadSummaryComponent} from "@app/pages/displays/display-load-summary/display-load-summary.component";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {LoadingIndicatorModule} from '@app/shared/_ui/loading-indicator/LoadingIndicator.module';

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        NgbTooltipModule,
        LoadingIndicatorModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DisplayLoadSummaryComponent,
    ],
    exports: [
        DisplayLoadSummaryComponent

    ],
    providers: [

    ],
})
export class DisplayLoadSummaryModule {}
