<ul class="attached-types list-unstyled">
    <ng-container *ngFor="let type of types">

        <ng-container *ngIf="type.modelType">
            <li [@slideDownAnimation]="'in'" class="mb-1">
                <label >{{type.modelType.name | translate}}</label>
                <ul class="list-group list-unstyled" *ngIf="type.modelType">
                    <li class="list-group-item">
                        <app-project-select-search
                            [type]="type" (itemSelected)="appendProject($event)">
                        </app-project-select-search>
                    </li>
                    <li *ngFor="let item of type.items" [@fadeAnimation]="'in'" class="list-group-item">
                        <app-card-project [model]="item"
                                          [removable]="true"
                                          [interactive]="true"
                                          [mini]="true"
                                          (onRemove)="removeProject($event)"></app-card-project>
                    </li>
                </ul>
            </li>
        </ng-container>
    </ng-container>
</ul>
