import {Inject, Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PeriodUnits, System} from '@app/constants';

export interface YearWheelOptions {
    showProject: boolean;
    showPhase: boolean;
    showMilestone: boolean;
    showTask: boolean;
    unit: string;
}

@Injectable({
    providedIn: 'root'
})
export class YearWheelService {

    public expandAllMilestones$: Observable<boolean>;
    private _expandAllMilestones: BehaviorSubject<boolean> = <BehaviorSubject<boolean>>new BehaviorSubject(false);

    public expandAllTasks$: Observable<boolean>;
    private _expandAllTasks: BehaviorSubject<boolean> = <BehaviorSubject<boolean>>new BehaviorSubject(false);

    public storage: Storage = sessionStorage;

    constructor(
        @Inject(Injector)
        private readonly injector: Injector,
    ) {
        this.expandAllMilestones$ = this._expandAllMilestones.asObservable();
        this.expandAllTasks$ = this._expandAllTasks.asObservable();
    }

    public expandAllMilestones(value: boolean) {
        this._expandAllMilestones.next(value);
    }
    public expandAllTasks(value: boolean) {
        this._expandAllTasks.next(value);
    }

    public getSettings(): any {
        return JSON.parse(this.storage.getItem(System.CookieYearWheelSettings)) || {
            showProject: true,
            showPhase: true,
            showMilestone: true,
            showTask: true,
            unit: PeriodUnits.Weeks
        };
    }

    public removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    saveOptions(yearWheelDisplayOptions: YearWheelOptions) {
        this.storage.setItem(System.CookieYearWheelSettings, JSON.stringify(yearWheelDisplayOptions));
    }
}
