<div class="flex-grow-1 -align-items-center m-0 p-0">
    <label *ngIf="configuration && configuration?.labelName">{{ configuration.labelName | translate }}</label>
    <ul
        *ngIf="types"
        class="list-unstyled"
    >
        <li
            *ngFor="let type of types"
        >
            <div class="flex-grow-1 align-items-center">
                <label>
                    <span>{{ type.name | translate }}</span>
                    <span *ngIf="type.validator.required" class="text-danger">*</span>
                </label>
                <app-category-picker
                    [type]="type"
                    [(ngModel)]="type.categories"
                    [categoryType]="type.categoryType"
                    (itemSelected)="onItemSelected(type, $event)"
                    (itemRemoved)="onItemRemoved(type, $event)"
                />
            </div>
        </li>
    </ul>
</div>
