import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {TableColumns} from "@app/pages/displays/display-projects/TableColumns";
import {TaskListNextMilestoneColumn} from "@app/pages/displays/display-projects/Columns/TaskListNextMilestoneColumn";

export class TaskListNextMilestoneTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TaskListNextMilestone;

    public column: TaskListNextMilestoneColumn;

}
