/**
 * Created by ModelParser
 */
import {TasksDeadline} from '../TasksDeadline';
import {TaskDeadlineTypesTaskType} from '../TaskDeadlineTypesTaskType';
import {TaskType} from '../TaskType';
import {TaskTypesStatusRule} from '../TaskTypesStatusRule';
import {DisplayFilter} from '../DisplayFilter';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class TaskDeadlineTypeDefinition extends BaseModel {
    name?: string;
    language_key?: string;
    tasks_deadline?: TasksDeadline[];
    task_deadline_types_task_types?: TaskDeadlineTypesTaskType[];
    task_types?: TaskType[];
    task_types_status_rules?: TaskTypesStatusRule[];
    display_filters?: DisplayFilter[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.language_key;
            delete this.tasks_deadline;
            delete this.task_deadline_types_task_types;
            delete this.task_types;
            delete this.task_types_status_rules;
            delete this.display_filters;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.language_key != null) {
            this.language_key = data.language_key;
        }
        if (data.tasks_deadline != null) {
            this.tasks_deadline = data.tasks_deadline.map((i: any) => new TasksDeadline(i));
        }
        if (data.task_deadline_types_task_types != null) {
            this.task_deadline_types_task_types = data.task_deadline_types_task_types.map((i: any) => new TaskDeadlineTypesTaskType(i));
        }
        if (data.task_types != null) {
            this.task_types = data.task_types.map((i: any) => new TaskType(i));
        }
        if (data.task_types_status_rules != null) {
            this.task_types_status_rules = data.task_types_status_rules.map((i: any) => new TaskTypesStatusRule(i));
        }
        if (data.display_filters != null) {
            this.display_filters = data.display_filters.map((i: any) => new DisplayFilter(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
