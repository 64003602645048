/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TaskDeadlineTypeDefinition,} from './definitions/TaskDeadlineTypeDefinition';
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";
import {HasSmartName} from "@app/interfaces/HasSmartName";
import {Api} from "@app/core/http/Api/Api";
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";
import {System} from "@app/constants";

export class TaskDeadlineType extends TaskDeadlineTypeDefinition implements HasSmartName {

    // OTF
    required?: boolean;
    visible?: boolean;
    index_?: number;

    constructor(json?: any) {
        super(json);
    }

    public getSmartName(): string {
        if(this.name) return this.name;
        else return AppInjector.getInjector().get(TranslateService).instant(this.language_key);
    }

    // <editor-fold desc="Cache">

    private static DataCache?: ApiDataCache<TaskDeadlineType>;

    private static GetDataCache(): ApiDataCache<TaskDeadlineType> {
        if (!this.DataCache) {
            this.DataCache = new ApiDataCache<TaskDeadlineType>(
                System.TaskDeadlineTypes,
                2 * 60 * 60, // 2 hours
                Api.taskDeadlineTypes().get()
            );
        }
        return this.DataCache;
    }

    public static GetAll(callback: (items: TaskDeadlineType[]) => void) {
        TaskDeadlineType.GetDataCache().get(callback);
    }

    public static GetById(id: number, callback: (item: TaskDeadlineType) => void) {
        this.GetAll(items => callback(items.find(item => item.id == id)));
    }

    // </editor-fold>

}
