/**
 * Created by ModelParser
 */
import {ProjectUserType} from '../ProjectUserType';
import {ProjectType} from '../ProjectType';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class ProjectTypesProjectUserTypeDefinition extends BaseModel {
    project_user_type_id?: number;
    project_user_type?: ProjectUserType;
    project_type_id?: number;
    project_type?: ProjectType;
    visible?: boolean;
    multiple?: boolean;
    required?: boolean;
    index_?: number;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.project_user_type_id;
            delete this.project_user_type;
            delete this.project_type_id;
            delete this.project_type;
            delete this.visible;
            delete this.multiple;
            delete this.required;
            delete this.index_;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.project_user_type_id != null) {
            this.project_user_type_id = data.project_user_type_id;
        }
        if (data.project_user_type != null) {
            this.project_user_type = new ProjectUserType(data.project_user_type);
        }
        if (data.project_type_id != null) {
            this.project_type_id = data.project_type_id;
        }
        if (data.project_type != null) {
            this.project_type = new ProjectType(data.project_type);
        }
        if (data.visible != null) {
            this.visible = data.visible;
        }
        if (data.multiple != null) {
            this.multiple = data.multiple;
        }
        if (data.required != null) {
            this.required = data.required;
        }
        if (data.index_ != null) {
            this.index_ = data.index_;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
