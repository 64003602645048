import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/GenericTableColumn";
import {
    TaskListStatusColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/TaskListStatusColumn";
import {StatusTypeColors, StatusTypes} from "@app/constants";

export class TaskListStatusTableColumn extends GenericTableColumn {

    public status: string;

    public column: TaskListStatusColumn;

    constructor(column: TaskListStatusColumn, status: string) {
        super(column);
        this.identifier = status;
        this.name = status;
        this.status = status;
    }

    public get statusId(): number {
        switch (this.status) {
            default:
            case 'grey':
                return StatusTypes.GREY;
            case 'yellow':
                return StatusTypes.YELLOW;
            case 'red':
                return StatusTypes.RED;
            case 'green':
                return StatusTypes.GREEN;
        }
    }

    public getColorClass(): string {
        return StatusTypeColors[this.statusId];
    }

}
