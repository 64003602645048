import {NgModule} from "@angular/core";
import {
    MilestonePlanListComponent
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-list/milestone-plan-list.component";
import {TranslateModule} from "@ngx-translate/core";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {DepartmentPickerModule} from "@app/shared/_forms/department-picker/DepartmentPicker.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {
    MilestonePlanSelectItemModule
} from "@app/shared/_elements/milestone-plan-select-item/MilestonePlanSelectItem.module";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbTooltipModule,
        NgbPopoverModule,
        DepartmentPickerModule,
        DragDropModule,
        MilestonePlanSelectItemModule,
        RouterModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MilestonePlanListComponent,
    ],
    exports: [
        MilestonePlanListComponent

    ],
    providers: [

    ],
})
export class MilestonePlanListModule {}
