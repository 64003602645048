import {AppInjector} from '@app/services/app-injector.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '@app/core';
import {TranslateService} from '@ngx-translate/core';
import {Injector} from '@angular/core';

export class BaseService {
    protected httpClient: HttpClient;
    protected authService: AuthenticationService;
    protected translateService: TranslateService;
    protected injector: Injector;

    constructor() {
        this.injector = AppInjector.getInjector();
        if (this.injector) {
            this.httpClient = this.injector.get(HttpClient);
            this.authService = this.injector.get(AuthenticationService);
            this.translateService = this.injector.get(TranslateService);
        } else {
            console.error('Injector missing!', this.injector)
        }
    }

}
