import {Injectable} from '@angular/core';
import {versions} from "@env/versions";
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';
import {System} from '@app/constants';
import {UsersService} from '@app/services/users.service';

export interface IndexedDBItem {
    id?: number;
    userId?: number;
    key?: string;
    data?: any;
    created?: Date;
    version?: string;
    timeToLiveInSeconds?: number;
}

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    private dbService: NgxIndexedDBService;

    constructor() {
        this.dbService = AppInjector.getInjector().get(NgxIndexedDBService);
        // console.log('this.dbService : ', this.dbService);
    }

    /**
     * Determines whether an error is a QuotaExceededError.
     *
     * Browsers love throwing slightly different variations of QuotaExceededError
     * (this is especially true for old browsers/versions), so we need to check
     * different fields and values to ensure we cover every edge-case.
     *
     * @param err - The error to check
     * @return Is the error a QuotaExceededError?
     */
    static isQuotaExceededError(err: unknown): boolean {
        return (
            err instanceof DOMException &&
            // everything except Firefox
            (err.code === 22 ||
                // Firefox
                err.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                err.name === "QuotaExceededError" ||
                // Firefox
                err.name === "NS_ERROR_DOM_QUOTA_REACHED")
        );
    }

    public setLocalStorageItem(key: string, data: any) {
        // Remove existing data with same key
        Object.keys(localStorage).forEach(targetKey => {
            if (targetKey.includes(key)) {
                localStorage.removeItem(targetKey);
            }
        });

        // Set localstorage data with key+version
        try {
            localStorage.setItem(CacheService.GenerateCookieName(key), JSON.stringify(data));
        } catch (err) {
            if (CacheService.isQuotaExceededError(err)) {
                // Handle the case where there wasn't enough space to store the item in localStorage.
                console.warn('Not able to store data: ', err, key)
            } else {
                // Handle the case where the localStorage API is not supported.
            }
        }
    }

    public setIndexedDBItem(key: string, data: any, created?: Date, timeToLiveInSeconds?: number) {
        // Indexed DB: https://www.npmjs.com/package/ngx-indexed-db

        this.dbService.getAllByIndex(System.DBCache, 'key', IDBKeyRange.only(key))
            .subscribe((items: IndexedDBItem[]) => {
                const item = items.find(i => i.userId == AppInjector.getInjector().get(UsersService).user?.id)

                if (item) {
                    // update
                    this.dbService
                        .update(System.DBCache, {
                            id: item?.id,
                            key: key,
                            userId: AppInjector.getInjector().get(UsersService).user?.id,
                            data: data,
                            created: created ?? new Date(),
                            version: versions.branch,
                            timeToLiveInSeconds: timeToLiveInSeconds,
                        })
                        .subscribe((storeData) => {
                            // console.log('DB storage: ', storeData);
                        });
                } else {
                    // create
                    this.dbService
                        .update(System.DBCache, {
                            key: key,
                            userId: AppInjector.getInjector().get(UsersService).user?.id,
                            data: data,
                            created: created ?? new Date(),
                            version: versions.branch,
                            timeToLiveInSeconds: timeToLiveInSeconds,
                        })
                        .subscribe((storeData) => {
                            // console.log('DB storage: ', storeData);
                        });
                }
            })
    }

    public getLocalStorageItem(key: string): any {
        const data = localStorage.getItem(CacheService.GenerateCookieName(key));
        return data ? JSON.parse(data) : null;
    }

    public getIndexedDBItem(key: string, response: (value: IndexedDBItem) => void) {
        // console.log('cache.service : getIndexedDBItem() : ', key)

        this.dbService.getAllByIndex(System.DBCache, 'key', IDBKeyRange.only(key))
            .subscribe((items: IndexedDBItem[]) => {
                let item;
                switch (key){
                    case System.Translations:
                    case System.Site:
                    case System.TaskDeadlineTypes:
                    case System.TaskEstimateTypes:
                    case System.ProjectDeadlineTypes:
                    case System.Departments:
                        item = items[0];
                        break;
                    default:
                        item = items.find(i => i.userId == AppInjector.getInjector().get(UsersService).user?.id)
                        break;
                }

                // Tjek om item.version er ok. Hvis ikke, returner {}
                response(item && item.version == versions.branch ? item : {});
            })
    }

    public getAllIndexedDBItems(response: (value: IndexedDBItem[]) => void) {
        this.dbService.getAll(System.DBCache)
            .subscribe(items => {
                response(items);
            });
    }

    public clearLocalStorageItem(key: string) {
        localStorage.removeItem(CacheService.GenerateCookieName(key));
    }

    public clearIndexedDBItem(key: string, finished?: () => void) {
        this.dbService.getByIndex(System.DBCache, 'key', key)
            .subscribe((item: IndexedDBItem) => {
                if( item && item.key) {
                    this.dbService.deleteByKey(System.DBCache, item.key).subscribe((status) => {
                        if (finished) {
                            finished();
                        }
                    });
                }
            })
    }

    private static GenerateCookieName(key: string): string {
        return `${key}.${versions.branch}`;
    }

}
