import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Shell} from '@app/shell/Shell';
import {extract} from '@app/core';
import {AboutComponent} from '@app/pages/about/about.component';
import {AboutModule} from "@app/pages/about/about.module";

const routes: Routes = [
    Shell.childRoutes([
        {
            path: 'about',
            pathMatch: 'full',
            component: AboutComponent,
            data: {
                title: extract('About'),
                animation: 'Page',
            }
        },
    ])
];

@NgModule({
    imports: [
        AboutModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
    providers: []
})
export class AboutRoutingModule {
}
