import {Injectable} from '@angular/core';
import {ProjectFieldsProjectType} from '@app/core/models/ProjectFieldsProjectType';
import {BaseService} from '@app/services/base.service';
import {ProjectsService} from '@app/services/projects.service';
import {ProjectField} from '@app/core/models/ProjectField';
import {Phase} from '@app/core/models/Phase';
import {FieldTypes} from "@app/constants";
import {SnackbarService} from "@app/services/snackbar.service";
import {CategoryType} from '@app/core/models/CategoryType';

export class Field {
    visible: boolean;
    required: boolean;
    translation: string;
    name: string;
    smartName: string;
    projectField: ProjectField;
    index: number;

    constructor(projectFieldsTaskType: ProjectFieldsProjectType) {
        this.visible = projectFieldsTaskType.visible;
        this.required = projectFieldsTaskType.required;
        this.translation = projectFieldsTaskType.project_field.language_key;
        this.name = projectFieldsTaskType.name;
        this.smartName = (this.name && this.name.length > 0) ? this.name : this.translation;
        this.projectField = projectFieldsTaskType.project_field;
        this.index = projectFieldsTaskType.index_;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ProjectEditorService extends BaseService {

    constructor(private projectsService: ProjectsService,
                private snackbar: SnackbarService) {
        super();
    }

    // <editor-fold desc="Fields">

    private fields: Map<number, ProjectFieldsProjectType[]>;

    public getFieldsForType(typeId: number, callback: (fields: ProjectFieldsProjectType[]) => void) {
        // Initialize map
        if (!this.fields) {
            this.fields = new Map();
        }

        // Return if already cached
        if (this.fields.has(typeId)) {
            return callback(this.fields.get(typeId));
        }

        // Get and generate fields
        this.projectsService.getProjectType(typeId, type => {
            callback(type.project_fields_project_types);
            this.fields.set(typeId, type.project_fields_project_types);
        });
    }

    public getEditorFieldsForType(projectTypeId: number, callback: (fields: Map<number, Field>) => void) {
        this.getFieldsForType(projectTypeId, fields => {
            let filteredFields = fields?.filter(field => {
                if (field.project_field)
                    return field.project_field.type == FieldTypes.Editor || field.project_field.type == FieldTypes.MiniCard;
                else {
                    this.snackbar.add(`Systemfejl: Der mangler projekttyper på projektTypeId: ${projectTypeId}`, null, {
                        duration: 5000,
                    });
                    console.warn('Warning : Missing project type on "field.project_field.type" : ', field, 'projectTypeId: ', projectTypeId);
                    return false;
                }
            }) ?? [];
            let fieldsMap = new Map<number, Field>();
            filteredFields.forEach(field => fieldsMap.set(field.project_field_id, new Field(field)));
            callback(fieldsMap);
        });
    }

    public getMiniCardFieldsForType(projectTypeId: number, callback: (fields: Map<number, Field>) => void) {
        this.getFieldsForType(projectTypeId, fields => {
            let filteredFields = fields?.filter(field => field.project_field.type == FieldTypes.MiniCard) ?? [];
            let fieldsMap = new Map<number, Field>();
            filteredFields.forEach(field => fieldsMap.set(field.project_field_id, new Field(field)));
            callback(fieldsMap);
        });
    }

    // </editor-fold>

    // <editor-fold desc="Phases">

    private phases: Map<number, Phase[]>;

    public getPhasesForType(typeId: number, callback: (phases: Phase[]) => void) {
        // Initialize map
        if (!this.phases) {
            this.phases = new Map();
        }

        // Return if already cached
        if (this.phases.has(typeId)) {
            return callback(this.phases.get(typeId));
        }

        // Get and generate phases
        this.projectsService.getProjectType(typeId, type => {
            let phases = [];
            if (type.phases_project_types) {
                for (let phasesProjectType of type.phases_project_types) {
                    if (phasesProjectType.visible) phases.push(phasesProjectType.phase);
                }
            }
            phases = phases.sort((a, b) => a.index_ - b.index_);
            callback(phases);
            this.phases.set(typeId, phases);
        });
    }

    public appendPhase(projectTypeId: number, phase: Phase) {
        const phases = this.phases.get(projectTypeId) ?? [];
        phases.push(phase);
        this.phases.set(projectTypeId, phases);
    }

    // </editor-fold>

    // <editor-fold desc="Category Types">

    private categoryTypes: Map<number, CategoryType[]>;

    public getCategoryTypes(typeId: number, callback: (items: CategoryType[]) => void) {
        // Initialize map
        if (!this.categoryTypes) {
            this.categoryTypes = new Map();
        }

        // Return if already cached
        if (this.categoryTypes.has(typeId)) {
            return callback(this.categoryTypes.get(typeId));
        }

        // Get and generate phases
        this.projectsService.getProjectType(typeId, type => {
            const items = type.category_types_project_types
                ?.filter(categoryTypesProjectType => categoryTypesProjectType.visible)
                ?.map(categoryTypesProjectType => categoryTypesProjectType.category_type)
                ?.sort((a: CategoryType, b: CategoryType) => a.index_ - b.index_);
            callback(items);
            this.categoryTypes.set(typeId, items);
        });
    }

    // </editor-fold>

}
