import {Display, ProjectType, TaskType} from '@app/core/models';

export class PageSettings {

    public departmentId?: number;
    public userId?: number;
    public primaryDisplayId?: number;
    public programId?: number;
    public projectId?: number;
    public url?: string;
    public display?: Display;
    public projectTypes: ProjectType[];
    public taskTypes: TaskType[];

    constructor(departmentId: number, userId: number, primaryDisplayId: number, programId: number, projectId: number, url: string, display?: Display) {
        this.departmentId = departmentId;
        this.userId = userId;
        this.primaryDisplayId = primaryDisplayId;
        this.programId = programId;
        this.projectId = projectId;
        this.url = url;
        this.display = display;
        this.projectTypes = null;
        this.taskTypes = null;
    }


}
