<div class="d-flex"
     [ngbPopover]="taskEditListWhoWhenEdit"
     [autoClose]="false"
     [placement]="['auto']"
     triggers="manual"
     container="body"
     #popover="ngbPopover">
    <div class="position-relative" >
        <a class="text-center me-1 ms-1 d-block"
           [ngbTooltip]="popover.isOpen() ? null : ('_displays_project_details_users_add_project_participant' | translate)"
           triggers="hover"
           container="body"

           (click)="$event.stopPropagation(); popover.isOpen() ? popover.close() : popover.open();">
            <i class="fal fa-user-plus" aria-hidden="true"></i>
        </a>
    </div>
</div>

<!-- Popover dialog content -->
<ng-template #taskEditListWhoWhenEdit>
    <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>
    <app-user-search
        class="mt-1 mb-1 ms-auto"
        style="width: 30%;"
        [selectedUsers]="selectedUsers"
        [ngbTooltip]="('_displays_project_details_users_add_project_participant' | translate)"
        [placeholder]="('_ui_search' | translate) + '...'"
        [autoReset]="true"
        [toggle]="false"
        [showFilterButton]="false"
        [className]="'form-control-sm placeholder-sm'"
        (itemSelected)="addItem.emit($event)"
    ></app-user-search>
</ng-template>
