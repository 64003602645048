import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Milestone, Phase, PhasesProject, Project, Task} from "@app/core/models";
import {TranslateService} from "@ngx-translate/core";
import {CardProjectConfiguration} from '@app/shared/_ui/cards/medium/card-project/card-project-configuration';

interface SelectionRow<T> {
    item: T;
    selected: boolean;
    selectable: boolean;
    archived: boolean;
}

interface ProjectPhasesRows {
    project: Project;
    phasesProjects: SelectionRow<PhasesProject>[];
    activePhase: SelectionRow<PhasesProject>;
}

@Component({
    selector: 'app-milestone-archive-dialog',
    templateUrl: './milestone-archive-dialog.component.html',
    styleUrls: ['./milestone-archive-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ]
})
export class MilestoneArchiveDialogComponent extends BaseModalComponent implements OnInit {

    @Input() primaryClass: string = 'btn-danger';
    @Input() item: Milestone;
    @Input() projects: Project[];

    public isLoading: boolean = false;
    public projectPhasesRows: ProjectPhasesRows[] = [];
    public phasesRows: SelectionRow<PhasesProject>[] = [];
    public taskRows: SelectionRow<Task>[] = [];
    public cardProjectConfiguration: CardProjectConfiguration = new CardProjectConfiguration();

    constructor(private modal: NgbActiveModal,
                private translateService: TranslateService) {
        super(modal);
    }

    ngOnInit() {
        super.ngOnInit();

        this.cardProjectConfiguration.showPhase = false;
        this.cardProjectConfiguration.showProgramMiniCard = false;
        this.cardProjectConfiguration.showChangeType = false;
        this.cardProjectConfiguration.showGoToPlanningDisplay = false;
        this.cardProjectConfiguration.showActions = false;

        this.btnCancelText = this.translateService.instant('_global_cancel');
        this.btnOkText = this.translateService.instant('_global_ok');
        this.title = this.translateService.instant('_ui_milestone_archive_complete_phases_project_title', {
            milestoneName: this.item.name,
        });
        this.message = this.translateService.instant(`_ui_milestone_archive_complete_phases_projects_message`, {
            phasePlural: this.translateService.instant('_phase_plural').toLowerCase(),
            tasksPlural: this.translateService.instant('_tasks').toLowerCase(),
        });

        if (this.item.tasks) {
            this.item.tasks.forEach((task) => {
                if (!task.archived_id || task.archived_id == 0) {
                    this.taskRows.push({item: task, selected: true, selectable: true, archived: false});
                }
            })
        }

        if (this.projects) {

            this.projects.forEach(project => {

                let activePhases = this.activeProjectPhases(project);
                let phasesProjects: SelectionRow<PhasesProject>[] = [];

                activePhases.forEach((phasesProject, index) => {
                    phasesProjects.push({
                        item: phasesProject,
                        selected: index == activePhases.length - 1,
                        selectable: true,
                        archived: true
                    });
                })
                let activePhase: SelectionRow<PhasesProject> = null;
                if (phasesProjects.length > 2) {
                    activePhase = phasesProjects[activePhases.length - 2];
                }

                this.projectPhasesRows.push({
                    project: project,
                    phasesProjects: phasesProjects,
                    activePhase: activePhase
                });
            })

        }
    }

    private activeProjectPhases(project: Project) {
        return this.item.deadline
            ? project.getAllPhasesProjectByDate(this.item.deadline?.getDate(), true)
            : [];
    }

    public accept() {
        // Fuldfør opgaver
        this.taskRows.forEach(tr => {
            if (tr.selected) {
                const task = tr.item as Task;
                task.setArchived(true);
            }
        })

        // Sæt aktiv fase
        this.projectPhasesRows?.forEach(projectPhasesRow => {
            const rowActivePhase = projectPhasesRow.phasesProjects.find(pp => pp.selected);
            if (rowActivePhase) {
                const phasesProject = rowActivePhase.item as PhasesProject;
                const p = this.generateTempProject(projectPhasesRow.project);
                p.setCurrentPhasesProject(phasesProject);
            }
        });

        this.activeModal.close(true);
    }

    generateTempProject(project: Project): Project {
        const p = new Project();
        p.id = project.id;
        p.title = project.title;
        p.main_status = project.main_status;
        p.main_status_id = project.main_status_id;
        p.project_type_id = project.project_type_id;
        p.phases_projects = project.phases_projects;
        p.projects_deadlines = project.projects_deadlines;
        p.reactions = project.reactions;
        p.archived_id = project.archived_id;
        return p;
    }

    togglePhaseRow(projectRow: ProjectPhasesRows, row: SelectionRow<PhasesProject>) {
        if (!row.selected) {
            let found = false;
            if (!row.selected) {
                projectRow.phasesProjects.forEach((r, index) => {
                    r.selected = false;
                    if (r.item.id == row.item.id) {
                        found = true;
                        if (index > 0)
                            projectRow.activePhase = this.phasesRows[index - 1];
                        else
                            projectRow.activePhase = null;
                    }
                });

            }
            row.selected = true;
            projectRow.activePhase = row;
        }
    }
}
