<ul class="list-unstyled max-screen scroll-y -show-scroll {{className}} d-flex flex-column">

    <ng-container *ngFor="let item of menuItems">
        <li class="cursor-pointer w-100 nav-item" [class.active]="item === activeMenuItem">
            <div class="w-100 d-flex align-items-center" >
                <a (click)="clickMenuItem($event, item);" class=" nav-link flex-grow-1 d-flex align-items-center dynamic-menu-item p-2">
                    <i class="-fa-2x fal me-1 {{menuItemClass.get(item.id)}}"></i>
                    <span class="ellipsis" [ngbTooltip]="item.name" style="max-width: 250px" >{{item.name}}</span>

                    <i
                        [ngbTooltip]="('_dynamic_menu_item_type_' + item.type) | translate"
                        class="fal ms-auto"
                        [class.fa-note]="item.type == Constants.DynamicMenuItemTypes.Appointment && false"
                        [class.fa-link]="item.type == Constants.DynamicMenuItemTypes.Link"
                        [class.fa-list]="item.type == Constants.DynamicMenuItemTypes.AppointmentList && false"
                        [class.fa-rectangle-history]="item.type == Constants.DynamicMenuItemTypes.Category && false"
                    ></i>
                </a>
                <i class="fal fa-circle-plus ms-auto "
                   (click)="createAppointment($event, item)"
                   [ngbTooltip]="('_global_create' | translate) + '...'"
                   *ngIf="editable && menuItemWriteable && menuItemWriteable.get(item.id) && item.type === Constants.DynamicMenuItemTypes.AppointmentList"
                ></i>

                <i class="fal fa-users  ms-auto "
                   [ngbTooltip]="('_admin_milestone_plan_user_option_0' | translate)"
                   *ngIf="!editable || !(menuItemWriteable && menuItemWriteable.get(item.id) && item.type === Constants.DynamicMenuItemTypes.AppointmentList)"
                ></i>

            </div>

            <app-appointment-list
                *ngIf="item === activeMenuItem &&
                       appointmentListConfiguration &&
                       activeMenuItem.type === Constants.DynamicMenuItemTypes.AppointmentList"
                [configuration]="appointmentListConfiguration"
                style="height: 105px;"></app-appointment-list>


            <ng-container *ngIf="item === activeMenuItem && item.type === Constants.DynamicMenuItemTypes.Category && item.childrens">

                <app-dynamic-menu-items class="d-block w-100 pt-0 pb-1 ps-2 pe-0" [menuItems]="item.childrens"></app-dynamic-menu-items>
            </ng-container>
        </li>
    </ng-container>
</ul>
