import {GenericTableColumn} from "@app/pages/displays/display-cases/TableColumns/GenericTableColumn";
import {CaseTextColumn} from "@app/pages/displays/display-cases/Columns/CaseTextColumn";
import {TableColumns} from "@app/pages/displays/display-cases/TableColumns";

export class CaseTextTableColumn extends GenericTableColumn {

    public identifier = TableColumns.CaseText;

    public column: CaseTextColumn;

}
