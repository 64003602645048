import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    TodoListTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/TodoListTableColumn";
import {TodoListCell} from "@app/pages/displays/display-project-details/subdisplay-top/Cells/TodoListCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";

export class TodoListColumn extends BaseColumn<TodoListCell> {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new TodoListTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: Row): BaseCell {
        return new TodoListCell(row, this);
    }

}
