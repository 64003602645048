import {NgModule} from "@angular/core";
import {PlaceholderPipe} from "@app/pipes/placeholder.pipe";

@NgModule({
    imports: [

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        PlaceholderPipe,
    ],
    exports: [
        PlaceholderPipe

    ],
    providers: [

    ],
})
export class PlaceholderPipeModule {}
