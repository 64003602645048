import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import {Injectable} from "@angular/core";

/**
 * A route strategy allowing for explicit route reuse.
 * Used as a workaround for https://github.com/angular/angular/issues/18374
 * To reuse a given route, add `data: { reuse: true }` to the route definition.
 */
@Injectable()
export class RouteReusableStrategy extends RouteReuseStrategy {
    public shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle | null): void {}

    public shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null;
    }

    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // console.warn(future, curr, future.routeConfig, curr.routeConfig);

        const identicalRouteConfig = future.routeConfig === curr.routeConfig;
        const identicalParams = this.equals(future.params, curr.params);
        const isIdentical = identicalRouteConfig && identicalParams;

        // console.log('shouldReuseRoute : ', identicalRouteConfig, identicalParams, isIdentical, future, 'reuse: ', (isIdentical || future.data.reuse));

        return isIdentical || future.data.reuse;
        // // here I get the end route child 'cause it is necesary for comparing
        // let currTemp = curr;
        //
        // while(currTemp.firstChild){
        //     currTemp = currTemp.firstChild;
        // }
        //
        // // if this route is set to true then I go to reuse it
        // let componentTest = null;
        // if(currTemp.data.reuse) {
        //     componentTest = currTemp.component;
        // }
        //
        //
        // return (curr.component === future.component) && (curr.component !== componentTest);
        //
        // // return future.routeConfig === curr.routeConfig || future.data.reuse;
    }

    private equals(o1: any, o2: any): boolean {
        if (o1 === o2) return true;
        if (o1 === null || o2 === null) return false;
        if (o1 !== o1 && o2 !== o2) return true; // NaN === NaN
        let t1 = typeof o1, t2 = typeof o2, length: number, key: any, keySet: any;
        if (t1 == t2 && t1 == 'object') {
            if (Array.isArray(o1)) {
                if (!Array.isArray(o2)) return false;
                if ((length = o1.length) == o2.length) {
                    for (key = 0; key < length; key++) {
                        if (!this.equals(o1[key], o2[key])) return false;
                    }
                    return true;
                }
            } else {
                if (Array.isArray(o2)) {
                    return false;
                }
                keySet = Object.create(null);
                for (key in o1) {
                    if (!this.equals(o1[key], o2[key])) {
                        return false;
                    }
                    keySet[key] = true;
                }
                for (key in o2) {
                    if (!(key in keySet) && typeof o2[key] !== 'undefined') {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    }
}
