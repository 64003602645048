import {ElementRef, EventEmitter} from "@angular/core";
import {Subscription} from "rxjs";

export class ComponentResizeObserver {

    private componentRef: ElementRef;
    private previousWidth?: number;
    private previousHeight?: number;

    private onWidthChangeEventEmitter = new EventEmitter<number>();
    private onHeightChangeEventEmitter = new EventEmitter<number>();

    public constructor(componentRef: ElementRef) {
        this.componentRef = componentRef;
        setTimeout(() => {
            const observer = new ResizeObserver(entries => {
                this.handleResize(entries[0]);
            });
            observer.observe(componentRef.nativeElement);
        });
    }

    public subscribeToWidth(next: (value: number) => void): Subscription {
        return this.onWidthChangeEventEmitter.subscribe(next);
    }

    public subscribeToHeight(next: (value: number) => void): Subscription {
        return this.onHeightChangeEventEmitter.subscribe(next);
    }

    private handleResize(entry: ResizeObserverEntry) {
        if (this.previousWidth !== entry.contentRect.width) {
            this.setWidth(entry.contentRect.width);
        }
        if (this.previousHeight !== entry.contentRect.height) {
            this.setHeight(entry.contentRect.height);
        }
    }

    private setWidth(value: number) {
        this.previousWidth = value;
        this.onWidthChangeEventEmitter.emit(value);
    }

    private setHeight(value: number) {
        this.previousHeight = value;
        this.onHeightChangeEventEmitter.emit(value);
    }

}
