import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {Milestone, Project} from "@app/core/models";
import {EventEmitter} from "@angular/core";

export class ProjectDisplayRow extends BaseRow {

    projectCard: CardItem<Project>;
    nextMilestoneCard: CardItem<Milestone>;

    minimizeEvent: EventEmitter<any> = new EventEmitter();
    reloadAppointmentsEvent: EventEmitter<any> = new EventEmitter();

    public reload() {
        this.reloadAppointmentsEvent.emit();
    }

    public minimize() {
        this.minimizeEvent.emit();
    }

}
