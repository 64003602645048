/**
 * Created by ModelParser
 */
import {TaskStatusType} from '../TaskStatusType';
import {Task} from '../Task';
import {Status} from '../Status';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class TaskStatusDefinition extends BaseModel {
    task_status_type_id?: number;
    task_status_type?: TaskStatusType;
    task_id?: number;
    task?: Task;
    status_id?: number;
    status?: Status;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.task_status_type_id;
            delete this.task_status_type;
            delete this.task_id;
            delete this.task;
            delete this.status_id;
            delete this.status;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.task_status_type_id != null) {
            this.task_status_type_id = data.task_status_type_id;
        }
        if (data.task_status_type != null) {
            this.task_status_type = new TaskStatusType(data.task_status_type);
        }
        if (data.task_id != null) {
            this.task_id = data.task_id;
        }
        if (data.task != null) {
            this.task = new Task(data.task);
        }
        if (data.status_id != null) {
            this.status_id = data.status_id;
        }
        if (data.status != null) {
            this.status = new Status(data.status);
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
