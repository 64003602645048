import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {TextColumnConfiguration} from "@app/editor/quick-editor/columns/generic/text-column/TextColumnConfiguration";
import {BaseColumn} from "@app/editor/quick-editor/columns/BaseColumn";

@Component({
    selector: 'app-text-column',
    templateUrl: './text-column.component.html',
    styleUrls: ['./text-column.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextColumnComponent extends BaseColumn {

    @Input() configuration: TextColumnConfiguration;

    public value: string;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected render() {
        this.value = this.configuration.value;
    }

}
