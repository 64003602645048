/**
 * Created by ModelParser
 * Date: 06-02-2024.
 * Time: 07:55.
 */
import {DisplayFilterUserSettingDefinition} from './definitions/DisplayFilterUserSettingDefinition';

export class DisplayFilterUserSetting extends DisplayFilterUserSettingDefinition {

    constructor(json?: any) {
        super(json);
    }

}
