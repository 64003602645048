import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";
import {ProjectEstimateListColumn} from "@app/pages/displays/display-projects/Columns/ProjectEstimateListColumn";

export class ProjectEstimateListCell extends GenericCell {

    public column: ProjectEstimateListColumn;

    public exportCSV(secondary?: any): CSVCellValue {
        return this.column.getProjectEstimateTypeIds()

            // Find ProjectEstimate
            ?.map(projectEstimateTypeId => this.row.project.item.findProjectEstimateByType(projectEstimateTypeId))

            // Filter out invalid items
            ?.filter(projectEstimate => projectEstimate?.estimate?.exists())

            // Add to view
            ?.map(projectEstimate => projectEstimate.print()) ?? '';
    }

}
