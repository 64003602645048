<div
    class="d-flex flex-column position-relative h-100 {{listConfiguration.listClassName}} list-container"
    [class.actions-active]="items && ((items.length != itemCount || items.length == itemCount && itemCount > itemsPerRow))"
    *ngIf="listConfiguration.getListType() == Constants.ListTypes.CardList"
    [attr.data-items]="items && items.length"
>

    <ul
        *ngIf="showCreateNew && listConfiguration?.createItemConfiguration && listConfiguration.getModelClass() != 'CaseUser'"
        class="list-unstyled d-flex justify-content-start align-items-center rounded left text-small action-panel m-0 no-hover -placement-bottom-right placement-top-right no-fade solid corner">
        <li>
            <!-- Opret skal ikke vises på CaseUser -->
            <app-create-item-dropdown
                class="position-relative me-1 ms-1"
                [source]="listConfiguration?.createItemConfiguration"
                [createItemPreset]="listConfiguration?.createItemPreset"
                [placement]="'auto'"
                [container]="'body'"

                [label]="('_global_create' | translate) + '...' "
                style=""
            ></app-create-item-dropdown>
        </li>
    </ul>

    <div class="list-wrapper flex-fill d-flex flex-column {{listClass}} scroll-y"
         [class.footer-padding]="!showLoader  && items && items.length > 0 && itemCount && listConfiguration.getModelClass() != Constants.ItemTypes.CaseUser &&
        ((items.length > 0 && items.length != itemCount && itemCount) ||
        (items && items.length == itemCount && itemCount && itemCount > itemsPerRow))"
    >

        <!-- Liste af kort -->
        <div class="cell flex-grow-1 justify-content-start position-relative"
             cdkDropList
             id="{{listId}}"
             [cdkDropListConnectedTo]="dropList"
             [cdkDropListData]="listDragInterface"
             (cdkDropListDropped)="itemDropped($event)"
             [cdkDropListEnterPredicate]="itemEnterPredicate"
             [cdkDropListSortingDisabled]="!listDragInterface?.isSortingEnabled"
             (cdkDropListEntered)="dropListEntered($event)"
             (cdkDropListExited)="dropListExited($event)"
             [class.cdk-drop-list-disabled]="listDragInterface?.dropDisabled"
        >
            <ng-container *ngIf="listConfiguration.getModelClass() == Constants.ItemTypes.Roster">
                <app-roster-expander
                    #rosterExpander class="w-100 m-0 -debug" [items]="$any(items)"
                    [listConfiguration]="$any(listConfiguration)"
                    [updateValuesEvent]="dataSetChanged"
                />
            </ng-container>

            <ng-container *ngIf="listConfiguration.getModelClass() == Constants.ItemTypes.CaseUser">
                <a class="text-center -flex-fill -ms-auto position-absolute d-flex justify-content-center align-items-center corner-item"
                   *ngIf="showCreateNew"
                   [ngbTooltip]="('_global_edit' | translate) + '...'"
                   container="body"
                   placement="bottom"
                   (click)="caseUserExpander.open()">
                    <i class="fal fa-edit" aria-hidden="true"></i>
                </a>
                <app-case-user-expander
                    #caseUserExpander class="w-100 m-0"
                    [items]="$any(items)"
                    [listConfiguration]="$any(listConfiguration)"
                    [updateValuesEvent]="dataSetChanged"
                />
            </ng-container>

            <ng-container
                *ngIf="listConfiguration.getModelClass() != 'CaseUser' && listConfiguration.getModelClass() != 'Roster'">
                <div cdkDrag
                     [cdkDragStartDelay]="isMobile ? 200 : null"
                     class="-w-100 m-0"
                     *ngFor="let item of items; let index = index; trackBy: trackByFn"
                     [class.m-half]="item.item.constructor.name != 'Appointment' && item.item.constructor.name != 'CaseUser'"
                     [class.w-100]="item.item.constructor.name == 'CaseUser' || item.item.constructor.name == 'Appointment'"
                     [class.draggable]="listDragInterface?.isCardItemDraggable(item)"
                     (cdkDragStarted)="dragStarted($event)"
                     (cdkDragEnded)="dragEnded($event)"
                     [cdkDragData]="item"
                     [cdkDragDisabled]="!listDragInterface?.isCardItemDraggable(item)">
                    <app-card-switcher
                        [listConfiguration]="listConfiguration"
                        [cardItem]="item">{{index}}</app-card-switcher>
                </div>
            </ng-container>
        </div>

        <!-- Navigation -->
        <div *ngIf="showLoader"
             class="p-2 d-flex justify-content-center align-items-center flex-column -bg-light w-100 me-0 loading-block position-absolute h-100 ">
            <app-loading-indicator [showLogo]="false"></app-loading-indicator>
        </div>

        <ul *ngIf="!showLoader  && items && items.length > 0 && itemCount && listConfiguration.getModelClass() != Constants.ItemTypes.CaseUser &&
        ((items.length > 0 && items.length != itemCount && itemCount) ||
        (items && items.length == itemCount && itemCount && itemCount > itemsPerRow))"
            class="list-unstyled d-flex justify-content-start align-items-center rounded left text-small action-panel placement-bottom border-none no-fade m-0 list-counter"
            style="left: 0;">
            <li class="me-1 cursor-pointer"
                (click)="items.length > 0 && itemCount && items.length < itemCount ? showAll() : showLess()"
            >
                <div class="  text-small border p-1 corner-counter rounded d-flex align-items-center gap-1">
                    <span>{{items.length }} <span>{{'_ui_of' | translate}}</span> {{itemCount}}</span>
                    <i class="fa-duotone "
                       [class.fa-circle-chevron-down]="items.length > 0 && itemCount && items.length < itemCount"
                       [class.fa-circle-chevron-up]="items && items.length == itemCount && itemCount && itemCount > itemsPerRow"
                       aria-hidden="true"></i>
                </div>


            </li>


        </ul>

    </div>
</div>

<ul *ngIf="listConfiguration.getListType() == Constants.ListTypes.SimpleList"
    scroll
    [offsetBottom]="20"
    style="max-height: 160px; "
    (onReachBottom)="onScrollReachedBottom($event)"
    class="scroll-y show-scroll d-flex list-unstyled flex-column pt-0 pb-1 ps-2 pe-2 pe-0">
    <ng-container
        *ngFor="let item of items; let index = index; trackBy: trackByFn">
        <app-card-switcher
            [cardItem]="item"
            [listConfiguration]="listConfiguration"
        >{{index}}</app-card-switcher>
    </ng-container>
    <li *ngIf="showLoader && items && items.length != itemCount"
        class="p-2 d-flex justify-content-between align-items-center flex-column -bg-light w-100 me-0 loading-block">
        <app-loading-indicator [showLogo]="false"></app-loading-indicator>
    </li>
</ul>

<app-card-edit-expander *ngIf="listConfiguration.getListType() == Constants.ListTypes.EditList"
                        [items]="items"
                        [itemCount]="itemCount"
                        [showCreateNew]="showCreateNew"
                        [listConfiguration]="listConfiguration"
                        [listDragInterface]="listDragInterface"
></app-card-edit-expander>


