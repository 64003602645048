import {EventEmitter} from "@angular/core";

export class ComponentVisibilityObserver {

    private htmlElement: HTMLElement;
    private isVisible = false;

    public onVisibilityChangeEvent = new EventEmitter<boolean>();

    constructor(htmlElement: HTMLElement) {
        this.htmlElement = htmlElement;
    }

    public check() {
        const isVisibleNow = this.htmlElement.offsetParent !== null;
        if (this.isVisible != isVisibleNow) {
            this.isVisible = isVisibleNow;
            this.onVisibilityChangeEvent.emit(isVisibleNow);
        }
    }

}
