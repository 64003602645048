/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {PhasesProjectDefinition} from './definitions/PhasesProjectDefinition';
import Helpers from '../helpers';
import * as moment from 'moment';
import {Phase} from '@app/core/models/Phase';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

export class PhasesProject extends PhasesProjectDefinition {

    public startNgbDate: NgbDateStruct;
    public is_current = false;
    public end: string;
    public status = 0;
    public title: string;

    public static Create(projectId?: number, phaseId?: number, date?: Date) {
        const item = new PhasesProject();
        item.project_id = projectId;
        item.phase_id = phaseId;
        if (date) {
            item.start = Helpers.serverDate(date);
        }
        return item;
    }

    public static createDefault(projectId?: number, date?: Date) {
        const item = new PhasesProject();
        item.id = 0;
        item.phase_id = 0;
        item.phase = Phase.createDefault();
        item.project_id = projectId;
        if (date) {
            item.start = Helpers.serverDate(date ? date : new Date());
            item.startNgbDate = Helpers.toNgbDateStruct(item.start);
        }
        item.is_current = false;
        return item;
    }

    constructor(json?: any) {
        super(json);
        if (json?.is_current) {
            this.is_current = json.is_current;
        }
    }



    populate(data?: any, patch: boolean = false) {
        super.populate(data, patch);
        if (this.start && !this.startNgbDate) {
            this.startNgbDate = Helpers.toNgbDateStruct(this.start);
        }
        if (data?.is_current) {
            this.is_current = data.is_current;
        }
    }

    public getStartedDate(): Date | undefined {
        if (this.start) {
            const _moment: moment.Moment = moment(this.start);
            return _moment.isValid() ? _moment.toDate() : undefined;
        }
        return undefined;
    }

    public getEndDate(): Date | undefined {
        if (this.end) {
            const _moment: moment.Moment = moment(this.end);
            return _moment.isValid() ? _moment.subtract(1, 'minute').toDate() : undefined;
        }
        return undefined;
    }

    public setStart(date: NgbDateStruct) {
        if (date) {
            this.start = Helpers.serverDate(Helpers.fromNgbDateStruct(date));
        }
    }

    public setStruct() {
        if (this.start) {
            this.startNgbDate = Helpers.toNgbDateStruct(this.start);
        }
    }

}
