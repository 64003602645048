import {NgModule} from "@angular/core";
import {
    ReactionListEditorComponent
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/reaction-list-editor.component";
import {StarSelectorModule} from "@app/shared/_elements/star-selector/StarSelector.module";
import {HandUpSelectorModule} from "@app/shared/_elements/hand-up-selector/HandUpSelector.module";
import {ReactionsModule} from "@app/shared/_ui/reactions/Reactions.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        StarSelectorModule,
        HandUpSelectorModule,
        ReactionsModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ReactionListEditorComponent,
    ],
    exports: [
        ReactionListEditorComponent

    ],
    providers: [

    ],
})
export class ReactionListEditorModule {}
