/**
 * Created by ModelParser
 * Date: 05-08-2021.
 * Time: 10:57.
 */
import {DepartmentsDisplayFilterDefinition} from './definitions/DepartmentsDisplayFilterDefinition';

export class DepartmentsDisplayFilter extends DepartmentsDisplayFilterDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static CreateVisible(departmentId: number): DepartmentsDisplayFilter {
        const item = new DepartmentsDisplayFilter();
        item.department_id = departmentId;
        item.visible = true;
        return item;
    }

}
