import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {NextMilestoneCardCell} from "@app/pages/displays/display-projects/Cells/NextMilestoneCardCell";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {NextMilestoneRiskCell} from "@app/pages/displays/display-projects/Cells/NextMilestoneRiskCell";

export class NextMilestoneRiskColumn extends GenericColumn<NextMilestoneCardCell> {

    public implementsCSVExport = true;

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new NextMilestoneRiskCell(row, this);
    }

}
