<div class="d-flex flex-row w-100 flex-wrap">
    <div class="flex-grow-1 w-100" *ngIf="showDepartmentGroups && visibleDepartmentGroups?.length > 1">
        <label *ngIf="showDepartmentGroups" class="d-flex align-items-center cursor-pointer" (click)="toggleDepartmentGroupsActive = !toggleDepartmentGroupsActive">
            <i class="fa-filter me-1"
               [class.fal]="!selectedDepartmentGroup"
               [class.fas]="selectedDepartmentGroup"
            ></i>
            <span>{{'_ui_filter_on' | translate}}: {{'_department_group' | translate}}</span>

            <i class="fal  ms-auto"
            [class.fa-circle-chevron-up]="toggleDepartmentGroupsActive"
            [class.fa-circle-chevron-down]="!toggleDepartmentGroupsActive"
            ></i>
        </label>
        <!-- showDepartmentGroups -->
        <ul class="list-unstyled list-group scroll-y" *ngIf="showDepartmentGroups && toggleDepartmentGroupsActive">
            <li *ngIf="showAll" (click)="setDepartmentGroup(null)" class="list-group-item cursor-pointer d-flex align-items-center">
                <i class="fa fa-check-circle text-success me-1" *ngIf="!selectedDepartmentGroup"></i>
                <i class="fal fa-circle text-black-50 me-1" *ngIf="selectedDepartmentGroup"></i>
                <span>{{'_ui_see_all' | translate}}</span>
            </li>
            <li *ngFor="let departmentGroup of visibleDepartmentGroups" (click)="setDepartmentGroup(departmentGroup)"
                class="list-group-item cursor-pointer d-flex align-items-center">
                <i class="fa fa-check-circle text-success me-1" *ngIf="selectedDepartmentGroup == departmentGroup"></i>
                <i class="fal fa-circle text-black-50 me-1" *ngIf="selectedDepartmentGroup != departmentGroup"></i>
                <span class="ellipsis" [ngbTooltip]="departmentGroup.name">{{departmentGroup.name}}</span>
            </li>
        </ul>

    </div>
    <div class="w-100 flex-grow-1" >
        <label *ngIf="showDepartmentGroups && multiple">{{'_departments' | translate}}</label>
        <label *ngIf="showDepartmentGroups && !multiple">{{'_department_singular' | translate}}</label>

        <ul class="list-unstyled list-group w-100"
            [class.scroll-y]="showDepartmentGroups"
        >
            <li *ngFor="let department of visibleDepartments" (click)="toggleItem(department); $event.preventDefault()"
                class="list-group-item cursor-pointer d-flex align-items-center">
                <i class="fa fa-check-circle text-success me-1" *ngIf="selected(department.id)"></i>
                <i class="fal fa-circle text-black-50 me-1" *ngIf="!selected(department.id)"></i>
                <span class="ellipsis" [ngbTooltip]="department.name">{{department.name}}</span>
            </li>

        </ul>
    </div>

</div>
