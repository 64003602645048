import {NgModule} from "@angular/core";
import {StatusSelectorComponent} from "@app/shared/_forms/status-selector/status-selector.component";
import {NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbPopoverModule,
        ColorLabelModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        StatusSelectorComponent,
    ],
    exports: [
        StatusSelectorComponent

    ],
    providers: [

    ],
})
export class StatusSelectorModule {}
