import {CaseRow} from "@app/pages/displays/display-cases/CaseRow";
import {AppointmentListTableColumn} from "@app/pages/displays/display-cases/TableColumns/AppointmentListTableColumn";
import {CaseTextTableColumn} from "@app/pages/displays/display-cases/TableColumns/CaseTextTableColumn";
import {DaysTableColumn} from "@app/pages/displays/display-cases/TableColumns/DaysTableColumn";
import {GoalTextTableColumn} from "@app/pages/displays/display-cases/TableColumns/GoalTextTableColumn";
import {ProjectCardTableColumn} from "@app/pages/displays/display-cases/TableColumns/ProjectCardTableColumn";
import {GenericTableColumn} from "@app/pages/displays/display-cases/TableColumns/GenericTableColumn";
import {WeekTableColumn} from "@app/pages/displays/display-cases/TableColumns/WeekTableColumn";

export class TemplateTypes {

    daysHeaderTemplateType: {
        column: DaysTableColumn,
    }

    headerTemplateType: {
        column: GenericTableColumn,
    }

    cellTemplateType: {
        column: GenericTableColumn,
        row: CaseRow,
        rowIndex: number,
    }

    projectCardCellTemplateType: {
        column: ProjectCardTableColumn
        row: CaseRow,
        rowIndex: number,
    }

    appointmentListCellTemplateType: {
        column: AppointmentListTableColumn,
        row: CaseRow,
        rowIndex: number,
    }

    caseTextCellTemplateType: {
        column: CaseTextTableColumn,
        row: CaseRow,
        rowIndex: number,
    }

    daysCellTemplateType: {
        column: DaysTableColumn,
        row: CaseRow,
        rowIndex: number,
    }

    weekCellTemplateType: {
        column: WeekTableColumn,
        row: CaseRow,
        rowIndex: number,
    }

    goalTextCellTemplateType: {
        column: GoalTextTableColumn,
        row: CaseRow,
        rowIndex: number,
    }

}
