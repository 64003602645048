<div class="d-flex flex-row w-100 h-100" style="max-height: calc(100% - 56px);"
     [attr.data-isCapturingScreenshot]="isCapturingScreenshot"

>

    <div class="d-flex w-100 justify-content-center align-items-center" *ngIf="!activeUser && display">
        <app-loading-indicator [showLogo]="false" class="align-self-center"></app-loading-indicator>
    </div>

    <div class="p-0 d-flex flex-row h-100 dashboard-wrapper w-100  ng-star-inserted justify-content-start flex-wrap"
         [class.bg-dark]="isMobileVersion"


         *ngIf="display && !isLoading && department && activeUser">

        <a class="me-1 text-white d-mobile-none"
           style="position: absolute; left: 8px; top: 64px; z-index: 10; height: 25px;"
           (click)="showItems = ! showItems">
            <i class="fal " aria-hidden="true"
               [class.fa-angle-right]="!showItems"
               [class.fa-angle-left]="showItems"
               [ngbTooltip]="showItems ? ('_ui_contract_display' | translate) : ('_ui_expand_display' | translate)"
            ></i>
        </a>
        <div class="-row p-0 -flex-grow-0 w-100 bg-dark" *ngIf="showMyWeek && !isMobileVersion">
            <div class="col-12 align-items-center justify-content-center w-100">
                <app-dashboard-header
                    *ngIf="showMyWeek"
                    [activeUser]="activeUser"
                    [isMainDisplay]="false"
                    [displayId]="dashboardHeaderDisplayId"
                />
            </div>
        </div>
        <div class="p-0 d-flex  w-100 justify-content-start h-100"
            [class.flex-row]="!isMobileVersion"
            [class.flex-column]="isMobileVersion"
        >

            <div class="m-0 pe-0 flex-fill h-100 sidebar-list" *ngIf="showItems && department"
                 >
                <app-dashboard-sidebar-task-list
                    class="flex-fill h-100"
                    *ngIf="showItems"
                    [activeUser]="activeUser"
                    [isMainDisplay]="false"
                    [displayId]="dashboardSidebarTaskListDisplayId">
                </app-dashboard-sidebar-task-list>
            </div>

            <!-- https://codepen.io/micjamking/pen/QdojLz -->
            <!-- https://github.com/mattlewis92/angular-resizable-element/blob/master/demo/demo.component.ts -->
            <!-- I løbet af ugen / projekter -->
            <div
                #splitContainer
                class="flex-fill d-flex flex-column p-0 -justify-content-center flex-shrink-1"
                style="min-height: calc(100% - 66px); overflow-x: auto;"
                [class.bg-dark]="isMobileVersion"

            >

                <div
                    class="d-flex bg-light position-relative"
                    [class.pb-2]="!isMobileVersion"
                    id="block-week"
                    [ngStyle]="projectsFullSizeActive ? styleTop : null"
                    [class.h-100]="calendarFullSizeActive || !showProjects"
                    *ngIf="(showCalendar && department) && projectsFullSizeActive"
                    mwlResizable
                    [enableGhostResize]="false"
                    [validateResize]="validate"
                    (resizeEnd)="onResizeEnd($event)"
                >

                    <a class="me-1 text-white d-mobile-none"
                       style="position: absolute; left: 8px; top: 8px; z-index: 10; height: 25px;"
                       [class.push-left]="!showItems"
                       *ngIf="showProjects"
                       (click)="calendarFullSizeActive = ! calendarFullSizeActive">
                        <i class="fal " aria-hidden="true"

                           [class.fa-arrow-up-from-dotted-line]="calendarFullSizeActive"
                           [class.fa-arrow-down-from-dotted-line]="!calendarFullSizeActive"

                           [ngbTooltip]="calendarFullSizeActive ? ('_ui_contract_display' | translate) : ('_ui_expand_display' | translate)"
                        ></i>
                    </a>

                    <app-dashboard-calendar
                        class="w-100 h-100"
                        *ngIf="showCalendar"
                        [activeUser]="activeUser"
                        [isMainDisplay]="false"
                        [displayId]="dashboardCalendarDisplayId"
                        (onShellPageDataChangeEvent)="onCalendarShellPageDataUpdated($event)"
                    />

                    <!--<div class="debug w-100" style="height: 400px;" ></div>-->

                </div>


                <div class="project-wrap flex-grow-1  flex-shrink-1" *ngIf="!calendarFullSizeActive && showProjects"
                     [class.bg-dark]="isMobileVersion"
                     [class.ps-2]="isMobileVersion"
                     [class.pe-2]="isMobileVersion"
                >
                    <app-dashboard-projects
                        class="flex-fill rounded flex-grow-1 -ps-1 -pb-2 bg-light w-100 h-100" id="block-projects"
                        *ngIf="showProjects && department"
                        [ngStyle]="styleBottom"
                        [enableToggle]="showCalendar"
                        [period]="period"
                        [isMainDisplay]="false"
                        [displayId]="dashboardProjectsDisplayId"
                        [activeUser]="activeUser"
                        (fullSize)="toggleProjectSize($event)"
                    >
                    </app-dashboard-projects>
                </div>
                <!--<div class="debug" style="width: 8000px; height: 8000px; background: green;"></div>-->
            </div>
        </div>
    </div>
</div>
