import {EventEmitter} from '@angular/core';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {ListTypes} from "@app/constants";
import {CreateItemSourceConfiguration} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration";
import {CreateItemPreset} from "@app/shared/_ui/create-item-dropdown/CreateItemPreset";
import {CSVExportOptionInterface} from "@app/export/csv/CSVExportOptionInterface";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {CreateItemSourceInterface} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceInterface";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {Subscription} from "rxjs";
import {SharedComponentResizeObserver} from "@app/core/ResizeObserver/SharedComponentResizeObserver";
import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {ItemFocusRequest} from "@app/shared/_ui/lists/ItemFocusRequest";

export abstract class ListConfiguration<T = AnyItem> implements CreateItemSourceInterface, ListDragInterface<T> {

    public listClassName: string;
    protected modelClass: string = null;
    private useGlobalSearchFilter: boolean = true;
    private lastItemCount: number; // Tror at ideen var, at ved skift af sortering, skal der udlæses samme antal elementer. Men det virker ikke. Den nulstiller altid limit til én række.
    private listType: string = ListTypes.CardList;

    // <editor-fold desc="DragDrop">

    private draggable: boolean;
    private allowDragEnter: boolean = true;
    public dropDisabled: boolean = false;

    // </editor-fold>

    // <editor-fold desc="Create Item Dropdown">

    private showCreateNewButton = true;
    public createItemConfiguration: CreateItemSourceConfiguration;
    public createItemPreset: CreateItemPreset;
    protected createPresetGenerators: CreatePresetGenerator[];

    prepareSource(): void {
        // Override
    }

    public setCreatePresetGenerators(createPresetGenerators: CreatePresetGenerator[]): ListConfiguration<T> {
        this.createPresetGenerators = createPresetGenerators;
        return this;
    }

    public addCreatePresetGenerator(createPresetGenerator: CreatePresetGenerator): ListConfiguration<T> {
        if (!this.createPresetGenerators) {
            this.createPresetGenerators = [];
        }
        this.createPresetGenerators.push(createPresetGenerator);
        return this;
    }

    // </editor-fold>

    // <editor-fold desc="Smart Limit">

    public static SmartLimit = -1;
    protected limit: number;
    private defaultLimit = 5;
    public onLimitSetEvent: EventEmitter<number> = new EventEmitter<number>();
    public sharedComponentResizeObserver?: SharedComponentResizeObserver;

    public getLimit(): number {
        return this.limit ? this.limit : this.defaultLimit;
    }

    public setLimit(value: number): ListConfiguration<T> {
        if (value == ListConfiguration.SmartLimit && this.limit) { // SmartLimit can only be set once
            return;
        }

        if (this.limit != value) {
            this.limit = value;
            this.lastItemCount = 0; // Reset last item count
            this.onLimitSetEvent.emit(value);
        }
        return this;
    }

    public hasSmartLimit(): boolean {
        return this.limit == ListConfiguration.SmartLimit;
    }

    // </editor-fold>

    // <editor-fold desc="Data Listeners">

    public onAddItemsEvent = new EventEmitter<{ items: CardItem[] }>(); // Add all at once, whole scope
    public onAddItemEvent = new EventEmitter<{ item: CardItem, index?: number }>(); // Add single item to existing scope
    public onRemoveItemEvent = new EventEmitter<{ item: CardItem }>(); // Remove single item from existing scope
    public onCountEvent = new EventEmitter<{ count: number }>();

    private dataListerSubscriptions: Subscription;
    public addDataListeners(onAddItemsListener: (items: CardItem[]) => void,
                            onAddItemListener: (item: CardItem, index?: number) => void,
                            onRemoveItemListener: (item: CardItem) => void,
                            onCountListener: (count: number) => void) {
        this.dataListerSubscriptions?.unsubscribe();
        this.dataListerSubscriptions = new Subscription();
        this.dataListerSubscriptions.add(this.onAddItemsEvent.subscribe(value => onAddItemsListener(value.items)));
        this.dataListerSubscriptions.add(this.onAddItemEvent.subscribe(value => onAddItemListener(value.item, value.index)));
        this.dataListerSubscriptions.add(this.onRemoveItemEvent.subscribe(value => onRemoveItemListener(value.item)));
        this.dataListerSubscriptions.add(this.onCountEvent.subscribe(value => onCountListener(value.count)));
        this.setupDataSource();
    }

    protected setupDataSource() {
        // Override
    }

    // </editor-fold>

    // <editor-fold desc="Push">

    public pushSubscribe() {
        // Override
    }

    public pushUnsubscribe() {
        // Override
    }

    // </editor-fold>

    // <editor-fold desc="CSV Export">

    public csvExport(item: CardItem, option?: CSVExportOptionInterface): any {
        // Override
    }

    // </editor-fold>

    // <editor-fold desc="Get / Set">

    public getModelClass(): string {
        return this.modelClass;
    }

    public setModelClass(value: string): ListConfiguration<T> {
        this.modelClass = value;
        return this;
    }

    public getListType(): string {
        return this.listType;
    }

    public setListType(value: string): ListConfiguration<T> {
        this.listType = value;
        return this;
    }

    public setUseGlobalSearchFilter(value: boolean): ListConfiguration<T> {
        this.useGlobalSearchFilter = value;
        return this;
    }

    public getUseGlobalSearchFilter(): boolean {
        return this.useGlobalSearchFilter;
    }

    public getLastItemCount(): number {
        return this.lastItemCount;
    }

    public setLastItemCount(value: number) {
        this.lastItemCount = value;
    }

    public getShowCreateNewButton(): boolean {
        return this.showCreateNewButton;
    }

    public setShowCreateNew(value: boolean): ListConfiguration<T> {
        this.showCreateNewButton = value;
        return this;
    }

    public isDraggable(): boolean {
        return this.draggable;
    }

    public setDraggable(value: boolean): ListConfiguration<T> {
        this.draggable = value;
        return this;
    }

    public getAllowDragEnter(): boolean {
        return this.allowDragEnter;
    }

    public setAllowDragEnter(value: boolean): ListConfiguration<T> {
        this.allowDragEnter = value;
        return this;
    }

    public getSharedComponentResizeObserver(): SharedComponentResizeObserver {
        return this.sharedComponentResizeObserver;
    }

    public setSharedComponentResizeObserver(value: SharedComponentResizeObserver): ListConfiguration<T> {
        this.sharedComponentResizeObserver = value;
        return this;
    }

    // </editor-fold>

    // <editor-fold desc="ListDragInterface">

    configuration = this;

    isCardItemDraggable(cardItem: CardItem<T>): boolean {
        return this.isDraggable();
    }

    allowCardItemDrop(cardItem: CardItem<T>, configuration: ListConfiguration<T>, result: (allow: boolean) => void) {
        result(this.isDraggable());
    }

    allowCardItemEnter(cardItem: CardItem<T>, configuration: ListConfiguration<T>) {
        return this.isDraggable();
    }

    onCardItemDragAdd(cardItem: CardItem<T>, configuration: ListConfiguration<T>, callback: () => void): void {
    }

    onCardItemDragRemove(cardItem: CardItem<T>): void {
    }

    onCardItemDragSaved(): void {
    }

    public getListConfigurationForDragDropEvent(cardItem: CardItem<T>): ListConfiguration<T> {
        return this;
    }

    // </editor-fold>

    // <editor-fold desc="OnScreen Filtering">

    public onScreenFilters: BaseOnScreenFilter<T>[] = [];
    public onScreenFilterChange = new EventEmitter();

    public addOnScreenFilters(value: BaseOnScreenFilter<T>[]): ListConfiguration<T> {
        value.forEach(value => this.addOnScreenFilter(value));
        return this;
    }

    public setOnScreenFilters(value: BaseOnScreenFilter<T>[]): ListConfiguration<T> {
        this.onScreenFilters = [];
        value.forEach(value => this.addOnScreenFilter(value));
        return this;
    }

    public addOnScreenFilter(value: BaseOnScreenFilter<T>): ListConfiguration<T> {
        this.onScreenFilters.push(value);
        value.onEnabledChangeEvent.subscribe(_ => this.onScreenFilterChange.emit());
        return this;
    }

    // </editor-fold>

    // <editor-fold desc="Card Edit Expander">

    public cardEditExpander_enabledEditors?: { name: string, configuration?: BaseEditorConfiguration }[];

    public setCardEditExpander_enabledEditors(value: { name: string, configuration?: BaseEditorConfiguration }[]): ListConfiguration<T> {
        this.cardEditExpander_enabledEditors = value;
        return this;
    }

    // </editor-fold>

}
