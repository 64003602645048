import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges, ViewChild
} from '@angular/core';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {Emoji, EmojiData, EmojiEvent} from "@ctrl/ngx-emoji-mart/ngx-emoji";
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';

export interface EmojiItem {
    emojiId: string;
    count: number;
    userIds: number[];
}

@Component({
    selector: 'app-emoji-picker',
    templateUrl: './emoji-picker.component.html',
    styleUrls: ['./emoji-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiPickerComponent extends BaseDisplayComponent implements OnInit, OnChanges, OnDestroy {

    public emojis: EmojiData[] = [];
    @ViewChild('popover', {static: false}) popover: NgbPopover;

    public translations = {
        search: 'Search',
        emojilist: 'List of emoji',
        notfound: 'No Emoji Found',
        clear: 'Clear',
        categories: {
            search: 'Search Results',
            recent: 'Frequently Used',
            people: 'Smileys & People',
            nature: 'Animals & Nature',
            foods: 'Food & Drink',
            activity: 'Activity',
            places: 'Travel & Places',
            objects: 'Objects',
            symbols: 'Symbols',
            flags: 'Flags',
            custom: 'Custom',
        },
    };

    @Input() public emojiItems: EmojiItem[] = [];
    @Input() public visible = true;                 // Display emoji-icons with badges
    @Input() public showPopoverEmoji = true;        // Display emoji-icons inside popover
    @Input() public showBadge = false;              // Display emoji-icons counter as badge
    @Input() public showUserCounter = true;         // Display emoji-icons user counter
    @Input() public showPicker = true;              // Display emoji-icon picker
    @Input() public toggleUsers = true;             // Toggle users in EmojiItem
    @Input() public editable = true;                // Display emoji-icon picker, and make emojis removeable
    @Input() public autoClose = true;               // Close on emoji selection

    @Output() addReactionEvent = new EventEmitter<string>();
    @Output() removeReactionEvent = new EventEmitter<string>();
    @Output() changeReactionEvent = new EventEmitter<EmojiItem[]>();

    public minimumUserCount = 1;
    public activeEmojiItem: EmojiItem;
    public filterEmittet = true;
    public filterIsDefault = true;
    @Input() tooltipTitle: string = '_ui_display_emoji';

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        }

        this.subscribe(this.translateService.onLangChange.subscribe(() => {
            this.updateTranslations();
        }));
        this.updateTranslations();
    }

    private updateTranslations() {
        this.translations.search = this.translateService.instant('_ui_emoji_search');
        this.translations.emojilist = this.translateService.instant('_ui_emoji_emojilist');
        this.translations.notfound = this.translateService.instant('_ui_emoji_notfound');
        this.translations.clear = this.translateService.instant('_ui_emoji_clear');
        this.translations.categories.search = this.translateService.instant('_ui_emoji_categories_search');
        this.translations.categories.recent = this.translateService.instant('_ui_emoji_categories_recent');
        this.translations.categories.people = this.translateService.instant('_ui_emoji_categories_people');
        this.translations.categories.nature = this.translateService.instant('_ui_emoji_categories_nature');
        this.translations.categories.foods = this.translateService.instant('_ui_emoji_categories_foods');
        this.translations.categories.activity = this.translateService.instant('_ui_emoji_categories_activity');
        this.translations.categories.places = this.translateService.instant('_ui_emoji_categories_places');
        this.translations.categories.objects = this.translateService.instant('_ui_emoji_categories_objects');
        this.translations.categories.symbols = this.translateService.instant('_ui_emoji_categories_symbols');
        this.translations.categories.flags = this.translateService.instant('_ui_emoji_categories_flags');
        this.translations.categories.custom = this.translateService.instant('_ui_emoji_categories_custom');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        }
    }

    clickEmoji($event: EmojiEvent) {
        if ($event.emoji) {
            this.addEmoji($event.emoji);
        }
        this.filterEmittet = false;
        this.filterIsDefault = false;
        if (this.autoClose) {
            this.popover.close();
        }
    }

    private addEmoji(emoji: EmojiData) {
        const index = this.emojiItems.findIndex(ei => ei.emojiId == emoji.id);
        if (index !== -1) {
            if (this.toggleUsers) {
                this.toggleEmoji(this.emojiItems[index]);
            } else {
                this.emojiItems.splice(index, 1);
                this.removeReactionEvent.emit(EmojiPickerComponent.encodeEmoji(emoji.id));
            }
        } else {
            let item: EmojiItem = {emojiId: emoji.id, count: 1, userIds: [this.toggleUsers ? this.usersService.user.id : null]};
            this.emojiItems.push(item);
            this.addReactionEvent.emit(EmojiPickerComponent.encodeEmoji(emoji.id));
        }
    }

    toggleEmoji(item: EmojiItem) {
        if (!this.editable) return;

        if (this.toggleUsers) {
            const userIndex = item.userIds.findIndex(uid => uid == this.usersService.user.id);
            if (userIndex !== -1) {
                if (item.count > 1) {
                    // User already picked this Emoji, but there are multiple users. Reduce count, remove user.
                    item.count--;
                    item.userIds.splice(userIndex, 1);
                } else {
                    // Only one user picked this Emoji. Remove emoji entirely.
                    this.emojiItems.splice(this.emojiItems.findIndex(ei => ei === item), 1);
                }
                // Allways emit removal
                this.removeReactionEvent.emit(EmojiPickerComponent.encodeEmoji(item.emojiId));
            } else {
                // User does not exist on this emoji, add user.
                item.count++;
                item.userIds.push(this.usersService.user.id);
                this.addReactionEvent.emit(EmojiPickerComponent.encodeEmoji(item.emojiId));
            }
        } else {
            // No users is used in the header filters
            const index = this.emojiItems.findIndex(ei => ei.emojiId == item.emojiId);
            this.emojiItems.splice(index, 1);
            this.removeReactionEvent.emit(EmojiPickerComponent.encodeEmoji(item.emojiId));
        }

        this.filterEmittet = false;
        this.filterIsDefault = false;

        if (this.autoClose) {
            this.popover.close();
        }

    }

    static encodeEmoji(value: string) {
        return value.replace(/\+/gi, '%2B'); // https://github.com/angular/angular/issues/11058 // +1 reaction
    }

    decodeEmoji(value: string) {
        return value.replace(/%2B/gi, '+');  // https://github.com/angular/angular/issues/11058 // +1 reaction
    }

    removeAllItems() {
        if (!this.editable) return;

        if (this.toggleUsers) {
            this.emojiItems.forEach(ei => {
                if (ei.userIds && ei.userIds.findIndex(uid => uid == this.usersService.user.id) !== -1) {
                    console.log('removeAllItems() : item : ', ei);
                    this.toggleEmoji(ei);
                }
            })
        } else {
            this.emojiItems.forEach(ei => {
                this.removeReactionEvent.emit(EmojiPickerComponent.encodeEmoji(ei.emojiId));
            });
            this.emojiItems = [];
        }
        this.filterIsDefault = true;
    }


    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.cdr.detach(); // do this
    }

    public backgroundImage: Emoji['backgroundImageFn'] = (set: string, sheetSize: number) => {
        return '/assets/emoji/64.png';
    };

    emitItems() {
        this.changeReactionEvent.emit(this.emojiItems);
        this.filterEmittet = true;
    }
}
