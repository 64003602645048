import {Injectable} from '@angular/core';
import {TodoField} from '@app/core/models/TodoField';
import {TodosService} from "@app/services/todos.service";

export class Field {
    visible: boolean;
    required: boolean;
    translation: string;
    name: string;
    smartName: string;
    index: number;
    constructor(todoField: TodoField) {
        this.visible = todoField.visible;
        this.required = todoField.required;
        this.translation = todoField.language_key;
        this.name = todoField.name;
        this.smartName = (this.name && this.name.length > 0) ? this.name : this.translation;
        this.index = todoField.index_;
    }
}

@Injectable({
  providedIn: 'root'
})
export class TodoEditorService {

    constructor(private todosService: TodosService) {

    }

    // <editor-fold desc="Fields">

    private fields: Map<number, Field>;
    public getFields(callback: (fields: Map<number, Field>) => void) {
        // Use cache
        if(this.fields) return callback(this.fields);

        // Get and generate fields
        this.todosService.getEditorFields(fields => {
            if(!this.fields) this.fields = new Map();
            fields.forEach(field => this.fields.set(field.id, new Field(field)));
            callback(this.fields);
        });
    }

    // </editor-fold>

}
