import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {BaseModel} from "@app/core/models/BaseModel";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {EditActionConfiguration} from "@app/editor/quick-editor/editors/generic/action-editor/EditActionConfiguration";
import {EditAction} from "@app/editor/quick-editor/editors/generic/action-editor/EditAction";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {HasTypeProperties} from '@app/interfaces/HasTypeProperties';

@Component({
    selector: 'app-action-editor',
    templateUrl: './action-editor.component.html',
    styleUrls: ['./action-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionEditorComponent extends BaseEditor<EditAction> {
    protected eventFieldName?: string;

    // Bindings to parent
    @Input() model: HasTypeProperties & EditAction & HasEventGenerator & BaseModel;
    @Input() cardItem: CardItem;
    @Input() listDragInterface: ListDragInterface;
    @Input() configuration: EditActionConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public isArchived: boolean;
    public isDragEnabled: boolean;
    public typeName = '';

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    protected render() {
        this.model.getTypeNameAsync(name => {
            this.typeName = ' ' + name.toLowerCase();
            this.detectChanges();
        });

        this.isArchived = this.model.isArchived();
        this.isDragEnabled = this.listDragInterface?.isCardItemDraggable(this.cardItem) ?? false;
        this.detectChanges();
    }

    public archiveToggle() {
        this.model.setArchived(!this.isArchived);
        this.onItemUpdated();
        this.render();
    }

    public delete() {
        this.model.setDeleted();
        this.onItemUpdated();
        this.render();
    }

}
