<ng-container *ngIf="configuration?.validate()">

    <div class="wheel-scroll-wrapper position-relative "
         scroll
         (setScroll)="updateScroll($event)"
         #wrapperContainer>

        <div class="wheel-container position-relative unit-{{configuration.periodUnit}}" #container
             [style.width.px]="(periodDaysArray.length * unitWidth)-2"
        >
            <ul class="day-display d-flex flex-row" *ngIf="periodDaysArray">
                <li *ngFor="let pd of periodDaysArray; let i = index" [style.width.px]="getColumnWidth(i)">
                    <div class="text-center w-100">{{ pd }}</div>
                </li>
            </ul>

            <div class="milestones-container"
                 [class.no-phases]="configuration.displayOptions.showPhase == false"
                 *ngIf="configuration.displayOptions.showMilestone"
            >
                <ng-container *ngFor="let item of allItems">
                    <app-year-wheel-item-display
                        *ngIf="item.item.constructor.name == Constants.ItemTypes.Milestone && item.visible"
                        className="milestone"
                        [model]="item"
                    />
                </ng-container>
            </div>

            <div class="phases-container">
                <ng-container *ngFor="let item of allItems">
                    <app-year-wheel-item-display
                        *ngIf="item.item.constructor.name == Constants.ItemTypes.PhasesProject"
                        className="phasesProject"
                        [model]="item"
                    />
                </ng-container>
            </div>

            <div class="projects-container">
                <ng-container *ngFor="let item of allItems">
                    <app-year-wheel-item-display
                        *ngIf="item.item.constructor.name == Constants.ItemTypes.Project && item.visible"
                        className="project"
                        [model]="item"
                    />
                </ng-container>
            </div>

            <div class="tasks-container" *ngIf="configuration.displayOptions.showTask"
                 [class.no-phases]="configuration.displayOptions.showPhase == false"
                 [class.no-milestones]="configuration.displayOptions.showMilestone == false"
            >
                <ng-container *ngFor="let item of allItems">
                    <app-year-wheel-item-display
                        *ngIf="item.item.constructor.name == Constants.ItemTypes.Task && item.visible"
                        className="task"
                        [model]="item"
                    />
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="!configuration?.validate()" class="m-2 p-2">
    <div class="alert alert-warning">
        <i class="fa fa-exclamation-triangle me-1" aria-hidden="true"></i>
        <span>Der er ikke konfigureret tavleindstillinger for årshjultavlen. Kontakt venligst administrator.</span>
    </div>
</div>

<div *ngIf="(configuration && !(configuration.periodStart || configuration.periodEnd))" class="m-2 p-2">
    <div class="alert alert-warning">
        <i class="fa fa-exclamation-triangle me-1" aria-hidden="true"></i>
        <span>Der er ikke konfigureret en start eller slutdatoperiode. Kontakt venligst administrator.</span>
    </div>
</div>


