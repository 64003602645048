import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {EventEmitter} from "@angular/core";
import {EditTextValidator} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextValidator";

export class EditTextConfiguration extends BaseEditorConfiguration {

    public prop: string;
    public lineMode: 'single' | 'multi';
    public label: string;
    public textMode: 'standard' | 'rich';
    public validator: EditTextValidator;

    public isExpanded = false;
    public onChangeEvent = new EventEmitter();

    constructor(
        prop: string,
        lineMode: 'single' | 'multi',
        label: string,
        textMode: 'standard' | 'rich',
        validator: EditTextValidator,
    ) {
        super();
        this.prop = prop;
        this.lineMode = lineMode;
        this.label = label;
        this.textMode = textMode;
        this.validator = validator;
    }

    public setIsExpanded(value: boolean) {
        this.isExpanded = value;
        this.onChangeEvent.emit();
    }

}
