import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {GenericColumn} from "@app/pages/displays/display-team/Columns/GenericColumn";
import {DisplayTeamRow} from "@app/pages/displays/display-team/DisplayTeamRow";

export class GenericCell extends BaseCell {

    constructor(row: DisplayTeamRow, column: GenericColumn) {
        super(row, column);
    }

}
