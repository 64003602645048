import {ChangeDetectionStrategy, Component, Inject, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import {environment} from '@env/environment';
import {AuthenticationService, I18nService} from '@app/core';
import {Observable} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default, // TO-DO: Changedetection
})
export class UnauthorizedComponent implements OnInit {
    version: string = environment.version;
    environment: string = environment.environment;
    error: any;
    loginForm: UntypedFormGroup;
    isLoading = false;

    signedIn: Observable<boolean>;
    email: string;

    private authService: AuthenticationService;
    private oAuthService: OAuthService;


    constructor(@Inject(Injector)
                private readonly injector: Injector,
                public router: Router,
                private route: ActivatedRoute,
                private formBuilder: UntypedFormBuilder,
                private i18nService: I18nService,

    ) {

        this.oAuthService = this.injector.get(OAuthService);
        this.authService = this.injector.get(AuthenticationService);
    }

    ngOnInit() {

        /*
        let claims = this.oAuthService.getIdentityClaims();
        console.log('claims :: ', claims)

        this.signedIn = this.authService.isSignedIn();
        this.authService.userChanged().subscribe(
            (user: User) => {
                console.log('this.authService.userChanged : ', user);
                this.email = user.email;
            });
            */

        //this.authService.signout();
        setTimeout(() => {
            //this.authService.signout();
            //this.router.navigate(['/login'])
        }, 5000)

        /*
        this.userService.me().subscribe(response => {
            console.log('userservice response : ', response);
        });
        */

    }

    login(): void {
        this.authService.removeItem('redirectUrl');
        this.oAuthService.initImplicitFlow();
        /*
        this.oAuthService.tryLogin({
            onTokenReceived: (info) => {
                console.debug('state', info.state);
            }
        })
        */
    }

    logout(): void {
        this.authService.signout();
    }


    setLanguage(language: string) {
        this.i18nService.language = language;
    }

    get currentLanguage(): string {
        return this.i18nService.language;
    }

    get languages(): string[] {
        return this.i18nService.supportedLanguages;
    }


    navigate() {
        // const extras: NavigationExtras = {
        //     replaceUrl: true,
        //     skipLocationChange: false
        //
        // }
        // this.router.navigate(['/'], extras)
        // this.router.navigateByUrl('/', {skipLocationChange: false}).then(()=>
        //     this.router.navigate(['/app/home/dashboard']));
        window.location.href = '/';
    }
}
