import {NgModule} from "@angular/core";
import {
    SmallCardMilestoneComponent
} from "@app/shared/_ui/cards/small/small-card-milestone/small-card-milestone.component";
import {CardPreviewModule} from "@app/shared/_ui/cards/card-preview/CardPreview.module";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {FormsModule} from "@angular/forms";
import {PlaceholderPipeModule} from "@app/pipes/PlaceholderPipe.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        CardPreviewModule,
        ColorLabelModule,
        FormsModule,
        PlaceholderPipeModule,
        NgbTooltipModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        SmallCardMilestoneComponent,
    ],
    exports: [
        SmallCardMilestoneComponent,
    ],
    providers: [

    ],
})
export class SmallCardMilestoneModule {}
