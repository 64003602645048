/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {StatusDefinition} from './definitions/StatusDefinition';
import {StatusTypes} from '@app/constants';
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';

export class Status extends StatusDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static createGrey(): Status {
        let status = new Status();
        status.id = StatusTypes.GREY;
        return status;
    }

    public static createGreen(): Status {
        let status = new Status();
        status.id = StatusTypes.GREEN;
        return status;
    }

    public static createYellow(): Status {
        let status = new Status();
        status.id = StatusTypes.YELLOW;
        return status;
    }

    public static createRed(): Status {
        let status = new Status();
        status.id = StatusTypes.RED;
        return status;
    }

    public static all(): Status[] {
        return [this.createGrey(), this.createGreen(), this.createYellow(), this.createRed()];
    }

    public name(): string {

        const translateService = AppInjector.getInjector().get(TranslateService);

        switch(this.id) {
            case StatusTypes.GREY:
                return translateService.instant('grey');//'Grå';
            case StatusTypes.GREEN:
                return translateService.instant('green');//'Grøn';
            case StatusTypes.YELLOW:
                return translateService.instant('yellow');//'Gul';
            case StatusTypes.RED:
                return translateService.instant('red');//'Rød';
            default:
                return '?';
        }
    }

}
