import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Injectable, SimpleChanges} from "@angular/core";
import {BaseColumnConfiguration} from "@app/editor/quick-editor/columns/BaseColumnConfiguration";

@Injectable()
export abstract class BaseColumn extends BaseComponent {

    public abstract configuration: BaseColumnConfiguration;

    protected constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.render();
    }

    protected abstract render(): void;
}
