export const Fields = {
    Title: 1, // Requireable
    Description: 2,
    User: 3, // Requireable
    Category: 4,
    Deadline: 5,
    IsPrivate: 6,
    Status: 7,
    Project: 8,
    Display: 9,
    ShowOnDisplay: 10
};

export const MiniCardFields = {
    Status: 11,
    Title: 12,
    Archived: 13,
    Star: 14,
    Deadline: 15,
    Category: 16,
    Project: 17
};
