import {NgModule} from "@angular/core";
import {CardExpanderComponent} from "@app/shared/_ui/displays/card-expander/card-expander.component";
import {CreateItemDropdownModule} from "@app/shared/_ui/create-item-dropdown/CreateItemDropdown.module";
import {TranslateModule} from "@ngx-translate/core";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {RosterExpanderModule} from "@app/shared/_ui/displays/roster-expander/RosterExpander.module";
import {CaseUserExpanderModule} from "@app/shared/_ui/displays/case-user-expander/CaseUserExpander.module";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {ScrollDirectiveModule} from "@app/directives/ScrollDirective.module";
import {CardEditExpanderModule} from "@app/shared/_ui/displays/card-edit-expander/CardEditExpander.module";
import {CommonModule} from "@angular/common";
import {CardsModule} from "@app/modules/Cards.module";

@NgModule({
    imports: [
        CommonModule,
        CreateItemDropdownModule,
        TranslateModule,
        DragDropModule,
        NgbTooltipModule,
        RosterExpanderModule,
        CaseUserExpanderModule,
        LoadingIndicatorModule,
        ScrollDirectiveModule,
        CardEditExpanderModule,
        CardsModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CardExpanderComponent,
    ],
    exports: [
        CardExpanderComponent

    ],
    providers: [

    ],
})
export class CardExpanderModule {}
