import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {Display, User} from '@app/core/models';
import Helpers from '@app/core/helpers';
import {Department} from '@app/core/models/Department';
import {UsersService} from '@app/services/users.service';
import {DisplayService} from "@app/services/display.service";

@Component({
    selector: 'app-user-dashboard-menu-item',
    templateUrl: './user-dashboard-menu-item.component.html',
    styleUrls: ['./user-dashboard-menu-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDashboardMenuItemComponent extends BaseComponent implements OnInit {

    // <editor-fold desc="Bindings">

    @Input() display: Display;
    @Input() selectedDepartment: Department;
    @Input() selectedUser: User;
    @Output() selectedUserChange = new EventEmitter();
    @Output() toggleMenuEvent = new EventEmitter();

    public displayUser: User;
    public currentUserMissing: boolean = true;
    public icon: string;
    public name: string;
    public users: User[];
    public routerLink: any[];

    // </editor-fold>

    // <editor-fold desc="Life cycle">

    public isLoading: boolean = false;

    public constructor(private cd: ChangeDetectorRef,
                       private displayService: DisplayService,
                       private usersService: UsersService) {
        super();
        this.cdr = cd;
    }

    public ngOnInit() {
        super.ngOnInit();
        this.loadDepartmentUsers();
        this.displayUser = this.getUser();
    }

    private loadDepartmentUsers() {
        this.isLoading = true;
        this.displayService.getDisplayWithSettings(this.display.id, display => {
            const displayRoleIds = display.roles?.map(role => role.id) ?? [];
            this.usersService.getUsersByDepartment(this.selectedDepartment.id, (items) => {
                if (displayRoleIds.length > 0) { // Only if display has roles, we should restrict users
                    this.users = items.filter(item => {
                        const userRoleIds = item.roles?.map(role => role.id) ?? [];
                        // Users without roles can see everything.
                        return userRoleIds.length == 0 || displayRoleIds.find(roleId => userRoleIds.includes(roleId)) !== undefined;
                    });
                } else {
                    this.users = items;
                }
                this.isLoading = false;
                this.markChangeDetectionDirty();
            });
        });

        this.renderUi();
    }

    // </editor-fold>

    // <editor-fold desc="UI">

    private renderUi() {
        this.icon = Helpers.getIcon(this.display);

        const u = this.getUser();
        this.currentUserMissing = !(this.selectedDepartment && u && u.departments.find(d => d.id == this.selectedDepartment.id));
    }

    public getRoute(user: User): any[] {
        return Helpers.getRoute(this.display, this.selectedDepartment.id, user.id);
    }

    // </editor-fold>

    // <editor-fold desc="Actions">

    public selectUser(user: User) {
        this.selectedUser = user;
        this.selectedUserChange.emit(user);
    }

    public toggleMenu() {
        this.toggleMenuEvent.emit();
    }

    // </editor-fold>


}
