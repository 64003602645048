import {NgModule} from "@angular/core";
import {CategoriesSelectorComponent} from "@app/shared/_elements/categories-selector/categories-selector.component";
import {NgbNavModule, NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule,
        LoadingIndicatorModule,
        NgbPopoverModule,
        NgbNavModule,
        ToggleItemModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CategoriesSelectorComponent,
    ],
    exports: [
        CategoriesSelectorComponent

    ],
    providers: [

    ],
})
export class CategoriesSelectorModule {}
