import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    MilestoneCardTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/MilestoneCardTableColumn";
import {GenericCell} from "@app/pages/displays/display-project-details/subdisplay-milestones/Cells/GenericCell";

export class MilestoneCardColumn extends BaseColumn {

    createTableColumns(): BaseTableColumn[] {
        const tableColumn = new MilestoneCardTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    createCell(row: BaseRow): BaseCell {
        return new GenericCell(row, this);
    }

}
