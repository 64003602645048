import {NgModule} from "@angular/core";
import {
    ProjectCalculatedFieldComponent
} from "@app/shared/_elements/project-calculated-field/project-calculated-field.component";
import {CalculatedFieldModule} from "@app/shared/_elements/calculated-field/CalculatedField.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        CalculatedFieldModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectCalculatedFieldComponent,
    ],
    exports: [
        ProjectCalculatedFieldComponent

    ],
    providers: [

    ],
})
export class ProjectCalculatedFieldModule {}
