/**
 * Created by ModelParser
 * Date: 10-02-2024.
 * Time: 09:05.
 */
import {ColumnTypeSettingDefinition} from './definitions/ColumnTypeSettingDefinition';

export class ColumnTypeSetting extends ColumnTypeSettingDefinition {

    constructor(json?: any) {
        super(json);
    }

}
