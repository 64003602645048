import {Subscription} from 'rxjs';
import {EventEmitter} from '@angular/core';
import {MilestoneListConfiguration} from '@app/shared/_ui/lists/milestone-list/MilestoneListConfiguration';
import {Milestone} from '@app/core/models/Milestone';
import {Project} from '@app/core/models/Project';
import Helpers from "@app/core/helpers";
import {AppInjector} from "@app/services/app-injector.service";
import {Api} from "@app/core/http/Api/Api";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {
    MultiLoaderFetcherInterface
} from "@app/shared/_ui/displays/display-multi-loader/Helpers/MultiLoaderFetcherInterface";
import {Category} from '@app/core/models';
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";

export class MilestoneFetchRequest {

    public configuration: MilestoneListConfiguration;
    public onMilestonesFetchedEvent = new EventEmitter<Milestone[]>();
    public onCountFetchedEvent = new EventEmitter<number>();

    // Data
    public result: Milestone[] = [];
    public count: number = 0;
    private lastEmittedItems?: Milestone[];
    private lastEmittedCount?: number;

    public constructor(configuration: MilestoneListConfiguration) {
        this.configuration = configuration;
        this.configuration.setDataSource(this);

        this.configuration.onAddItemEvent.subscribe(value => {
            const item = value.item.item as Milestone;

            // Add to result if not already there
            if (this.result.find(localItem => localItem.id == item.id) === undefined) {
                this.result.push(item);
            }

            // Add to last emitted items at right position
            if (this.lastEmittedItems?.find(localItem => localItem.id == item.id) === undefined) {
                if (value.index !== undefined) {
                    this.lastEmittedItems?.splice(value.index, 0, item);
                } else {
                    this.lastEmittedItems?.push(item);
                }
                this.lastEmittedCount = this.lastEmittedItems?.length;
            }
        });

        this.configuration.onRemoveItemEvent.subscribe(value => {
            const item = value.item.item as Milestone;

            // Remove from result if not already there
            let index = this.result?.findIndex(localItem => localItem.id == item.id);
            if (index !== -1) {
                this.result.splice(index, 1);
            }

            // Remove from last emitted items at right position
            index = this.lastEmittedItems?.findIndex(localItem => localItem.id == item.id);
            if (index !== -1 && this.lastEmittedItems) {
                this.lastEmittedItems.splice(index, 1);
                this.lastEmittedCount = this.lastEmittedItems.length;
            }
        });
    }

    public reset() {
        this.result = [];
        this.count = 0;
    }

    public addItem(item: Milestone) {
        this.result.push(item);
        this.count++;
    }

    public getItemsById(id: number): Milestone[] {
        return this.result?.filter(item => item.id == id) ?? [];
    }

    public subscribeToItems(next: (items: Milestone[]) => void) {
        this.onMilestonesFetchedEvent.subscribe(next);
        if (this.lastEmittedItems) {
            next(this.lastEmittedItems);
        }
    }

    public subscribeToCount(next: (count: number) => void) {
        this.onCountFetchedEvent.subscribe(next);
        if (this.lastEmittedCount) {
            next(this.lastEmittedCount);
        }
    }

    public cleanupAndEmitWhenReady(showAll: boolean) : any {
        this.applyOrdering();

        this.configuration.onScreenFilterChange.subscribe(() => this.emitSlice(showAll));

        if (this.configuration.hasSmartLimit()) {
            // Wait for SmartLimit. The limit can change over time (cause of screen resize). Keep listening for new limits and return slices.
            this.configuration.onLimitSetEvent.subscribe((limit: number) => this.emitSlice(showAll));
        } else {
            this.emitSlice(showAll);
        }
    }

    private applyOrdering() {
        if (this.configuration.getOrderBy() && this.configuration.getOrderBy().length > 0) {
            let orderBy = this.configuration.getOrderBy()[0];
            switch (orderBy[0]) {
                case 'main_status.status_id':
                    this.result.sort((a, b) => {
                        let aValue = a.main_status != null ? a.main_status.status_id : 0;
                        let bValue = b.main_status != null ? b.main_status.status_id : 0;
                        switch (orderBy[1]) {
                            default:
                            case 'asc':
                                return aValue - bValue;
                            case 'desc':
                                return bValue - aValue;
                        }
                    });
                    break;
            }
        }
    }

    private emitSlice(showAll: boolean) {
        // Apply on screen filters
        const filteredItems = this.result.filter(item => {
            return this.configuration.onScreenFilters.find(filter => !filter.isValid(item)) === undefined;
        });

        // Slice according to requested limit
        const sliceOfItems = this.getSlicedResult(filteredItems, showAll);

        // Emit to ListConfiguration
        this.emitItems(sliceOfItems);
        this.emitCount(filteredItems.length);
    }

    private getSlicedResult(items: Milestone[], showAll: boolean) {
        if (showAll) {
            return items;
        } else {
            const limit = Math.max(this.configuration.getLastItemCount() ?? 0, this.configuration.getLimit() ?? 0);
            if (limit > 0) {
                return items.slice(0, limit);
            } else {
                return items;
            }
        }
    }

    public clear() {
        this.configuration.setLastItemCount(0);
        this.result = [];
        this.count = 0;
    }

    // Called from TaskList to request "ShowMore/ShowAll"
    public requestSlice(offset: number, limit: number) {
        // Apply on screen filters
        const filteredItems = this.result.filter(item => {
            return this.configuration.onScreenFilters.find(filter => !filter.isValid(item)) === undefined;
        });

        // Slice according to requested offset and limit
        const sliceOfItems = filteredItems.slice(offset, limit);

        // Emit to ListConfiguration
        this.emitItems(sliceOfItems);
        this.emitCount(filteredItems.length);
    }

    private emitItems(items: Milestone[]) {
        this.lastEmittedItems = items;
        this.onMilestonesFetchedEvent.emit(items);
    }

    private emitCount(value: number) {
        this.lastEmittedCount = value;
        this.onCountFetchedEvent.emit(value);
    }

    public getLastEmittedItems(): Milestone[] {
        return this.lastEmittedItems;
    }

}

export class MilestoneFetcher extends BaseFetcher implements MultiLoaderFetcherInterface {

    private requests: MilestoneFetchRequest[] = [];
    private api: Subscription;
    public showAll = false;

    // Global scope
    private projectIds: number[] = [];
    private userIds: number[] = [];
    private avoidIds: number[] = [];
    private start: Date;
    private end: Date;
    private activeCategories: Category[] = [];

    constructor(name?: string) {
        super(name ?? '');
    }

    public clear(): void {
        this.requests = [];
    }

    public addRequest(request: MilestoneFetchRequest) {
        this.requests.push(request);
        request.reset();
    }

    public executeForPeriod(start: Date, end: Date) {
        // Set global scope
        this.start = start;
        this.end = end;

        // Execute!
        this.execute(true);
    }

    public execute(showGlobalLoadingIndicator: boolean = true) {
        if (this.requests.length === 0) {
            this.onFinishEvent.emit(true);
            return;
        }

        this.requests.forEach(request => {
            if (request.configuration.getProject()) {
                const projectId = request.configuration.getProject().id;
                if (!this.projectIds.includes(projectId)) {
                    this.projectIds.push(projectId);
                }
            }
            if (request.configuration.getResponsible()) {
                const userId = request.configuration.getResponsible().id;
                if (!this.userIds.includes(userId)) {
                    this.userIds.push(userId);
                }
            }
            if (request.configuration.getAvoidIds()) {
                this.avoidIds = this.avoidIds.concat(request.configuration.getAvoidIds());
            }
        });

        const configuration = this.requests[0].configuration;

        const api = Api.milestones().get();
        api.include('responsible');

        if (configuration.getShowProjectMiniCard()) {
            api.include('project');
        }

        if (configuration.getIncludeRelatedTasks()) {
            api.include('task');
        }

        if (this.projectIds.length) {
            // Custom include. Adds simplified milestones_projects to milestones
            api.include('project_ids')
                .whereIn('project.id', this.projectIds);
        }

        if (this.userIds.length) {
            api.whereIn('responsible_id', this.userIds);
        }

        if (this.avoidIds.length > 0) {
            api.whereNotIn('id', this.avoidIds);
        }

        if (configuration.getArchived() != null) {
            if (configuration.getArchived() === true) {
                api.whereGreaterThan('archived_id', 0);
            } else {
                api.where('archived_id', 0);
            }
        }

        // Skal filtreres fra i validate
        /*if(configuration.getDeadlineDateMinOrNull())
            api.whereGreaterThanOrEqual('deadline.date_or_null', Helpers.serverDate(configuration.getDeadlineDateMin()));*/

        if (configuration.getDeadlineDateMin() || configuration.getDeadlineValidator()) {
            api.whereGreaterThanOrEqual('deadline.date', Helpers.serverDate(this.start ?? configuration.getDeadlineDateMin()));
        }
        if (configuration.getDeadlineDateMax() || configuration.getDeadlineValidator()) {
            api.whereLessThanOrEqual('deadline.date', Helpers.serverDate(this.end ?? configuration.getDeadlineDateMax()));
        }



        if (configuration.getUseGlobalFilter()) {
            // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1536
            let filter = AppInjector.getInjector().get(FilterGlobalService).getActiveSettings();
            if (configuration.getUseGlobalSearchFilter() && filter.search) {
                api.search('name', filter.search);
            }

            if (configuration.getUseGlobalSearchFilter() && filter.activeUsers?.length > 0) {
                api.search('users', filter.activeUsers.map(user => user.id));
            }

            if (filter.isHandUp) api.whereGreaterThan('num_hand_ups', 0);
            if (filter.isStarred) api.whereGreaterThan('num_stars', 0);
            if (filter.activeStatuses.length < 4 && filter.activeStatuses.length > 0) {
                api.whereIn('main_status.status_id', filter.activeStatuses);
            }
            if (filter.activeReactionFilters?.length > 0) {
                filter.activeReactionFilters.forEach(reactionFilter => {
                    api.whereIn('reaction_filters', [reactionFilter.reaction_type_id, reactionFilter.value]);
                });
            }
            if (filter.activeCategories?.length) {
                api.whereIn('category.id', filter.activeCategories?.map(category => category.id));
            }
        }


        if (this.activeCategories?.length) {
            api.whereIn('category.id', this.activeCategories?.map(category => category.id));
        }

        configuration.getOrderBy().forEach(orderBy => api.orderBy(orderBy[0], orderBy[1]));

        api.setShowProgressBar(showGlobalLoadingIndicator);
        this.api = api.find(milestones => {

            // Clear all requests for earlier data
            this.requests.forEach(request => request.clear());

            milestones.forEach(milestone => {

                // Convert milestones_projects to project
                if (milestone.milestones_projects) {
                    milestone.projects = milestone.milestones_projects.map(value => {
                        const project = new Project();
                        project.id = value.project_id;
                        return project;
                    });
                }

                // Apply projects to fetch requests
                this.requests.forEach(request => {
                    if (request.configuration.validate(milestone)) {

                        const configurationProject = request.configuration.getProject();
                        if(configurationProject && !milestone.projects){
                            milestone.projects = [request.configuration.getProject()];
                        }else if(configurationProject && milestone.projects){
                            milestone.projects.find(p => p.id == configurationProject.id).phases_projects = configurationProject.phases_projects;
                        }

                        request.addItem(milestone);
                    }
                });
            });

            // Emit each fetch request
            this.requests.forEach(request => {
                request.cleanupAndEmitWhenReady(this.showAll)
            });

            // Emit all done
            this.onFinishEvent.emit(true);
        });
    }


    public cancel() {
        if (this.api) {
            this.api.unsubscribe();
        }
    }
    setCategories(categories: Category[]) {
        this.activeCategories = categories;
        return this;
    }
}
