import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Department, Project, ProjectType, User} from "@app/core/models";
import {Field} from "@app/editor/project-editor-loader/project-editor.service";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {ValidatorCollection} from "@app/editor/Validation/ValidatorCollection";
import {
    EditUserListConfiguration
} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserListConfiguration";
import {UserTypeItem} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserTypeItem";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {EditUserTypeValidator} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserTypeValidator";

@Component({
    selector: 'app-project-editor-tabs-users',
    templateUrl: './project-editor-tabs-users-component.html',
    styleUrls: ['./project-editor-tabs-users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectEditorTabsUsersComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item: Project;
    @Input() projectType: ProjectType;
    @Input() fields: Map<number, Field>;
    @Output() validatorChange = new EventEmitter<ValidationErrorInterface>();

    // Bindings to view
    public isReady = false;

    // Bindings to view: Users
    public userListEditorConfiguration: EditUserListConfiguration;

    // Data
    private validator: ValidationErrorInterface;

    constructor(
        private cd: ChangeDetectorRef,
        private dialogService: BaseDialogService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.setupEditors();
        this.setupValidator();
    }

    private setupEditors() {
        this.userListEditorConfiguration = new EditUserListConfiguration(
            '',
            this.projectType.project_types_project_user_types
                ?.filter(projectTypesProjectUserType => projectTypesProjectUserType.visible && projectTypesProjectUserType.project_user_type?.exists())
                ?.map(projectTypesProjectUserType => {
                    const item = new UserTypeItem(
                        projectTypesProjectUserType.project_user_type.title ?? '',
                        projectTypesProjectUserType.project_user_type_id,
                        projectTypesProjectUserType.multiple,
                        projectTypesProjectUserType.multiple,
                        false,
                        false,
                        false,
                        new EditUserTypeValidator(projectTypesProjectUserType.required)
                    );
                    this.subscribe(item.onUserAddedEvent.subscribe(userItem => this.onUserAdded(userItem.user)));
                    return item;
                }) ?? []
        );
    }

    private setupValidator() {
        // Collect validators
        this.validator = new ValidatorCollection([
            ...this.userListEditorConfiguration.types.map(type => type.validator),
        ]);
        this.validatorChange.emit(this.validator);

        this.isReady = true;
    }

    private isFieldVisible(fieldId: number): boolean {
        return this.fields.has(fieldId) && this.fields.get(fieldId).visible;
    }

    // <editor-fold desc="View actions">

    private onUserAdded(user: User) {
        // Tjekker team for bruger + projekt - vis dialog til at vælge teams for projektet hvis brugerne ikke er en del af samme afdeling
        // https://podio.com/klartboard/softwareudvikling/apps/stories/items/716

        // Dialog skal kun komme frem såfremt *brugeren* ikke er en del af én eller flere af forløbets afdelinger
        // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1398

        const projectDepartments = this.item.departments ?? [];
        const userMissingDepartments: Department[] = [];
        const projectMissingDepartments: Department[] = [];

        if (user.departments) {
            user.departments.forEach(userDepartment => {
                if (!projectDepartments.some(department => department.id === userDepartment.id)) {
                    userMissingDepartments.push(userDepartment);
                }
            })

            projectDepartments.forEach(projectDepartment => {
                if (!user.departments.some(department => department.id === projectDepartment.id)) {
                    projectMissingDepartments.push(projectDepartment);
                }
            })
        }

        const departmentsAvailable = projectMissingDepartments.filter(projectDepartment =>
            !userMissingDepartments.some(department => department.id === projectDepartment.id)
        )

        const departmentsMissing = userMissingDepartments.filter(userDepartment =>
            !projectMissingDepartments.some(department => department.id === userDepartment.id)
        )

        if (departmentsAvailable.length > 0 && departmentsMissing.length > 0) {
            this.showMissingDepartmentDialog(departmentsMissing, user);
        }
    }

    // </editor-fold>

    // <editor-fold desc="Dialogs">

    private showMissingDepartmentDialog(missingDepartments: Department[], user: User) {
        this.dialogService.missingDepartmentsDialog(missingDepartments, user)
            .then((confirmed) => {
                confirmed?.departments
                    ?.map((department: Department) => this.item.addDepartment(department));
            });
    }

    // </editor-fold>

}
