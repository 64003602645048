<div class="w-100 -h-100 sub-display-inner position-relative"
     *ngIf="visible"
     mwlResizable
     [enableGhostResize]="false"
     [allowNegativeResizes]="true"
     [ghostElementPositioning]="'absolute'"
     [resizeCursorPrecision]="15"
     [ngStyle]="styleTop"
     [validateResize]="validate"
     [resizeEdges]="{top: false, bottom: displayVisibility.casesDisplayId ? true : false, left: false, right: false}"
     (resizing)="onResize($event)"
     (resizeEnd)="onResizeEnd($event)"
     (resizeStart)="onResizeStart($event)"
>

    <div class="resize-box align-items-center justify-content-center border-light"
         [ngStyle]="resizeStyle"
         [class.d-flex]="resizing"
         [class.d-none]="!resizing">
        <pre class="align-self-center d-none">{{height}}px</pre>
    </div>

    <app-display-cases
        id="display-cases"
        *ngIf="displayVisibility.casesDisplayId"
        class="w-100 h-100" style="display: block; "
        (onSortChange)="onSortChange($event)"
        [class.position-fixed]="resizing"
        [isExpanded]="isExpanded"
        (isExpandedChangeEvent)="isExpandedChanged($event)"
        [visibleSubDisplays]="visibleSubDisplays"
        (itemCount)="updateCasesHeight($event)"
        [isMainDisplay]="false"
        [displayId]="displayVisibility.casesDisplayId"
    ></app-display-cases>

    <app-display-load-summary
        *ngIf="displayVisibility.loadSummaryDisplayId"
        class="w-100 h-100"
        (isExpandedChangeEvent)="isExpandedChanged($event)"
        [isMainDisplay]="false"
        [visibleSubDisplays]="visibleSubDisplays"
        [displayId]="displayVisibility.loadSummaryDisplayId"
    ></app-display-load-summary>

    <div
        *ngIf="displayVisibility.casesDisplayId && visibleSubDisplays > 1 && !isExpanded"
            class="resize-handle-bottom text-small cursor-drag placement-bottom"
            style="position: absolute; left: 50%; "
    >
        <i class="fas fa-grip-horizontal"></i>
    </div>

</div>
