/**
 * Created by ModelParser
 */
import {DisplayFilter} from '../DisplayFilter';
import {TaskType} from '../TaskType';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class DisplayFiltersTaskTypeDefinition extends BaseModel {
    display_filter_id?: number;
    display_filter?: DisplayFilter;
    task_type_id?: number;
    task_type?: TaskType;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.display_filter_id;
            delete this.display_filter;
            delete this.task_type_id;
            delete this.task_type;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.display_filter_id != null) {
            this.display_filter_id = data.display_filter_id;
        }
        if (data.display_filter != null) {
            this.display_filter = new DisplayFilter(data.display_filter);
        }
        if (data.task_type_id != null) {
            this.task_type_id = data.task_type_id;
        }
        if (data.task_type != null) {
            this.task_type = new TaskType(data.task_type);
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
