import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {ProjectPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/ProjectPresetType";

export class ProjectStatusPresetGenerator implements CreatePresetGenerator {

    private readonly typeId?: number;
    private readonly typeIdHandler?: () => number;

    private readonly statusId?: number;
    private readonly statusIdHandler?: () => number;

    constructor(
        typeIdOrHandler: number | (() => number),
        statusIdOrHandler: number | (() => number)
    ) {
        if (typeof typeIdOrHandler == "number") {
            this.typeId = typeIdOrHandler;
        } else {
            this.typeIdHandler = typeIdOrHandler;
        }
        if (typeof statusIdOrHandler == "number") {
            this.statusId = statusIdOrHandler;
        } else {
            this.statusIdHandler = statusIdOrHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: ProjectPresetTypes.Status,
            payload: {
                typeId: this.typeId ?? this.typeIdHandler(),
                statusId: this.statusId ?? this.statusIdHandler(),
            }
        };
    }

}
