const BasicInformation = {
    Tab: 7,
    Title: 1, // Requireable
    Purpose: 2, // Tidligere  "Purpose" // Requireable
    Deadlines: 12,
    Customers: 22, // No longer in use
    Notes: 32, // Requireable
    Notes2: 58, // Requireable
    Notes3: 59, // Requireable
    Notes4: 60, // Requireable
    Notes5: 61, // Requireable
    Notes6: 62, // Requireable
    Notes7: 63, // Requireable
    Notes8: 64, // Requireable
    Notes9: 65, // Requireable
    Notes10: 66, // Requireable
    Phase: 19,
    PhaseDate: 20,
    Category: 18, // No longer in use
    Area: 17, // No longer in use
    StatusRules: 47,
};

const AttachTo = {
    Tab: 28,
    Departments: 11,
    YearWheel: 15,
    Obs: 16, // Requireable
    Program: 24, // Deleted
    StrategyGoals: 25, // Deleted
    RelatedProjects: 26, // Deleted
    Reference: 31,
    Tags: 30,
    Tasks: 48, // Tjekboks: Opgavetyper / TaskTypes
    Milestones: 51, // Tjekboks: Opgavetyper / TaskTypes
};
const ProcessSteps = {
    Tab: 55,
}

const Categories = {
    Tab: 67,
}

export const MiniCardFields = {
    Status: 33,
    Title: 34,
    GoToPlanning: 35,
    GoToProgram: 36,
    Archived: 37,
    HandUp: 38,
    Star: 39,
    Who: 40,
    Deadline: 41,
    Area: 42, // No longer in use
    AreaCategory: 43, // No longer in use
    Phase: 44,
    Customer: 45, // No longer in use
    Program: 46,
    CalculatedFields: 50,
    Estimate: 56,
    NextMilestone: 57,
    YearWheel: 68,
};

export const Fields = {
    BasicInformation: BasicInformation,
    Responsible: 9,
    MilestonePlan: 27, // Deleted
    AttachTo: AttachTo,
    Log: 29,
    CalculatedFields: 49,
    GoToPlanning: 52,
    HandUp: 53,
    Star: 54,
    ProcessSteps: ProcessSteps,
    Categories: Categories,

    NoteFields: [
        {
            id: BasicInformation.Notes,
            prop: 'notes',
        },
        {
            id: BasicInformation.Notes2,
            prop: 'notes2',
        },
        {
            id: BasicInformation.Notes3,
            prop: 'notes3',
        },
        {
            id: BasicInformation.Notes4,
            prop: 'notes4',
        },
        {
            id: BasicInformation.Notes5,
            prop: 'notes5',
        },
        {
            id: BasicInformation.Notes6,
            prop: 'notes6',
        },
        {
            id: BasicInformation.Notes7,
            prop: 'notes7',
        },
        {
            id: BasicInformation.Notes8,
            prop: 'notes8',
        },
        {
            id: BasicInformation.Notes9,
            prop: 'notes9',
        },
        {
            id: BasicInformation.Notes10,
            prop: 'notes10',
        },
    ],
};


