import {BaseComponent} from "@app/shared/_system/base/base.component";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {User} from "@app/core/models";

@Component({
    selector: 'app-milestone-task-edit-who-selector-popover',
    templateUrl: './milestone-task-edit-who-selector-popover.component.html',
    styleUrls: ['./milestone-task-edit-who-selector-popover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneTaskEditWhoSelectorPopoverComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings: Input
    @Output() addItem = new EventEmitter<User>();
    @Output() removeItem = new EventEmitter<User>();
    @Input() tooltip: string;
    @Input() selectedUsers: User[];

    // Bindings: View


    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();


    }

    ngOnChanges(changes: SimpleChanges) {
        this.render();
        // console.log('selectedUsers : ', this.selectedUsers)
    }

    private render() {
        this.detectChanges();
    }

}
