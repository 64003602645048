/**
 * Created by ModelParser
 * Date: 20-12-2018.
 * Time: 11:42.
 */
import {DepartmentsPhaseDefinition,} from './definitions/DepartmentsPhaseDefinition';

export class DepartmentsPhase extends DepartmentsPhaseDefinition {

    constructor(json?: any) {
        super(json);
    }

}
