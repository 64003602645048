/**
 * Created by ModelParser
 */
import {ProjectType} from '../ProjectType';
import {ProjectDeadlineType} from '../ProjectDeadlineType';
import {TaskEstimateType} from '../TaskEstimateType';
import {TaskType} from '../TaskType';
import {User} from '../User';
import {BlockedPeriod} from '../BlockedPeriod';
import {BlockedHoliday} from '../BlockedHoliday';
import {MilestoneTemplate} from '../MilestoneTemplate';
import {TaskTemplate} from '../TaskTemplate';
import {DepartmentsMilestonePlan} from '../DepartmentsMilestonePlan';
import {Department} from '../Department';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class MilestonePlanDefinition extends BaseModel {
    title?: string;
    project_type_id?: number;
    project_type?: ProjectType;
    start_project_deadline_type_id?: number;
    start_project_deadline_type?: ProjectDeadlineType;
    end_project_deadline_type_id?: number;
    end_project_deadline_type?: ProjectDeadlineType;
    task_estimate_type_id?: number;
    task_estimate_type?: TaskEstimateType;
    block_weekends?: boolean;
    index_?: number;
    default_task_type_id?: number;
    default_task_type?: TaskType;
    user_id?: number;
    user?: User;
    project_notes?: string;
    use_project_responsible?: boolean;
    blocked_periods?: BlockedPeriod[];
    blocked_holidays?: BlockedHoliday[];
    milestone_templates?: MilestoneTemplate[];
    task_templates?: TaskTemplate[];
    departments_milestone_plans?: DepartmentsMilestonePlan[];
    departments?: Department[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.title;
            delete this.project_type_id;
            delete this.project_type;
            delete this.start_project_deadline_type_id;
            delete this.start_project_deadline_type;
            delete this.end_project_deadline_type_id;
            delete this.end_project_deadline_type;
            delete this.task_estimate_type_id;
            delete this.task_estimate_type;
            delete this.block_weekends;
            delete this.index_;
            delete this.default_task_type_id;
            delete this.default_task_type;
            delete this.user_id;
            delete this.user;
            delete this.project_notes;
            delete this.use_project_responsible;
            delete this.blocked_periods;
            delete this.blocked_holidays;
            delete this.milestone_templates;
            delete this.task_templates;
            delete this.departments_milestone_plans;
            delete this.departments;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.title != null) {
            this.title = data.title;
        }
        if (data.project_type_id != null) {
            this.project_type_id = data.project_type_id;
        }
        if (data.project_type != null) {
            this.project_type = new ProjectType(data.project_type);
        }
        if (data.start_project_deadline_type_id != null) {
            this.start_project_deadline_type_id = data.start_project_deadline_type_id;
        }
        if (data.start_project_deadline_type != null) {
            this.start_project_deadline_type = new ProjectDeadlineType(data.start_project_deadline_type);
        }
        if (data.end_project_deadline_type_id != null) {
            this.end_project_deadline_type_id = data.end_project_deadline_type_id;
        }
        if (data.end_project_deadline_type != null) {
            this.end_project_deadline_type = new ProjectDeadlineType(data.end_project_deadline_type);
        }
        if (data.task_estimate_type_id != null) {
            this.task_estimate_type_id = data.task_estimate_type_id;
        }
        if (data.task_estimate_type != null) {
            this.task_estimate_type = new TaskEstimateType(data.task_estimate_type);
        }
        if (data.block_weekends != null) {
            this.block_weekends = data.block_weekends;
        }
        if (data.index_ != null) {
            this.index_ = data.index_;
        }
        if (data.default_task_type_id != null) {
            this.default_task_type_id = data.default_task_type_id;
        }
        if (data.default_task_type != null) {
            this.default_task_type = new TaskType(data.default_task_type);
        }
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.project_notes != null) {
            this.project_notes = data.project_notes;
        }
        if (data.use_project_responsible != null) {
            this.use_project_responsible = data.use_project_responsible;
        }
        if (data.blocked_periods != null) {
            this.blocked_periods = data.blocked_periods.map((i: any) => new BlockedPeriod(i));
        }
        if (data.blocked_holidays != null) {
            this.blocked_holidays = data.blocked_holidays.map((i: any) => new BlockedHoliday(i));
        }
        if (data.milestone_templates != null) {
            this.milestone_templates = data.milestone_templates.map((i: any) => new MilestoneTemplate(i));
        }
        if (data.task_templates != null) {
            this.task_templates = data.task_templates.map((i: any) => new TaskTemplate(i));
        }
        if (data.departments_milestone_plans != null) {
            this.departments_milestone_plans = data.departments_milestone_plans.map((i: any) => new DepartmentsMilestonePlan(i));
        }
        if (data.departments != null) {
            this.departments = data.departments.map((i: any) => new Department(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
