import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns/GenericTableColumn";
import {
    TaskListPeriodColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/Columns/TaskListPeriodColumn";
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";
import * as moment from "moment/moment";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-users/Filters";

export class TaskListPeriodTableColumn extends GenericTableColumn {

    public periodType: string;
    public start?: Date;
    public end?: Date;

    public column: TaskListPeriodColumn;

    constructor(column: TaskListPeriodColumn, periodType: string) {
        super(column);
        this.identifier = periodType;
        this.periodType = periodType;

        switch (this.periodType) {
            case TaskListPeriodColumn.Period_WithoutDeadline:
                this.setSortItems([
                    PageColumnSort.CreateWithSortId(Filters.SortTitle),
                    PageColumnSort.CreateWithSortId(Filters.SortStatus),
                    PageColumnSort.CreateWithSortId(Filters.SortPlanningDate),
                    PageColumnSort.CreateWithSortId(Filters.SortStars),
                    PageColumnSort.CreateWithSortId(Filters.SortHands),
                    PageColumnSort.CreateWithSortId(Filters.SortUserDefined),
                ]);
                break;
            default:
                this.setSortItems([
                    PageColumnSort.CreateWithSortId(Filters.SortTitle),
                    PageColumnSort.CreateWithSortId(Filters.SortStatus),
                    PageColumnSort.CreateWithSortId(Filters.SortDeadlines),
                    PageColumnSort.CreateWithSortId(Filters.SortPlanningDate),
                    PageColumnSort.CreateWithSortId(Filters.SortStars),
                    PageColumnSort.CreateWithSortId(Filters.SortHands),
                    PageColumnSort.CreateWithSortId(Filters.SortUserDefined),
                ]);
                break;
        }

        switch (this.periodType) {
            case TaskListPeriodColumn.Period_BeforeThisWeek:
                this.name = '_displays_project_details_milestones_column_period_before_this_week';
                break;
            case TaskListPeriodColumn.Period_ThisWeek:
                this.name = '_displays_project_details_milestones_column_period_this_week';
                break;
            case TaskListPeriodColumn.Period_NextWeek:
                this.name = '_displays_project_details_milestones_column_period_next_week';
                break;
            case TaskListPeriodColumn.Period_ThisAndNextWeek:
                this.name = '_displays_project_details_milestones_column_period_this_and_next_week';
                break;
            case TaskListPeriodColumn.Period_ThirdAndForthWeek:
                this.name = '_displays_project_details_milestones_column_period_third_and_fourth_week';
                break;
            case TaskListPeriodColumn.Period_FifthAndSixthWeek:
                this.name = '_displays_project_details_milestones_column_period_fifth_and_sixth_week';
                break;
            case TaskListPeriodColumn.Period_SeventhAndEightsWeek:
                this.name = '_displays_project_details_milestones_column_period_seventh_and_eight_week';
                break;
            case TaskListPeriodColumn.Period_FollowingWeek:
                this.name = '_displays_project_details_milestones_column_period_following_week';
                break;
            case TaskListPeriodColumn.Period_Following:
                this.name = '_displays_project_details_milestones_column_period_following';
                break;
            case TaskListPeriodColumn.Period_WithoutDeadline:
                this.name = '_displays_project_details_milestones_column_period_without_deadline';
                break;
        }
        this.name = AppInjector.getInjector().get(TranslateService).instant(this.name);
    }

    public setPeriod(start: Date, end: Date) {
        const mondayThisWeek = moment().startOf('isoWeek');

        switch (this.periodType) {
            case TaskListPeriodColumn.Period_BeforeThisWeek:
                this.start = undefined;
                this.end = moment(mondayThisWeek).subtract(1, 'week').endOf('isoWeek').toDate();
                break;
            case TaskListPeriodColumn.Period_ThisWeek:
                this.start = moment(mondayThisWeek).toDate();
                this.end = moment(mondayThisWeek).endOf('isoWeek').toDate();
                break;
            case TaskListPeriodColumn.Period_NextWeek:
                this.start = moment(mondayThisWeek).add(1, 'week').toDate();
                this.end = moment(mondayThisWeek).add(1, 'week').endOf('isoWeek').toDate();
                break;
            case TaskListPeriodColumn.Period_ThisAndNextWeek:
                this.start = moment(mondayThisWeek).toDate();
                this.end = moment(mondayThisWeek).add(1, 'week').endOf('isoWeek').toDate();
                break;
            case TaskListPeriodColumn.Period_ThirdAndForthWeek:
                this.start = moment(mondayThisWeek).add(2, 'week').startOf('isoWeek').toDate();
                this.end = moment(mondayThisWeek).add(3, 'week').endOf('isoWeek').toDate();
                break;
            case TaskListPeriodColumn.Period_FifthAndSixthWeek:
                this.start = moment(mondayThisWeek).add(4, 'week').startOf('isoWeek').toDate();
                this.end = moment(mondayThisWeek).add(5, 'week').endOf('isoWeek').toDate();
                break;
            case TaskListPeriodColumn.Period_SeventhAndEightsWeek:
                this.start = moment(mondayThisWeek).add(6, 'week').startOf('isoWeek').toDate();
                this.end = moment(mondayThisWeek).add(7, 'week').endOf('isoWeek').toDate();
                break;
            case TaskListPeriodColumn.Period_Following:
            case TaskListPeriodColumn.Period_FollowingWeek:
                // Disse bliver sat efterfølgende, da de afhænger af andre kolonners perioder
                break;
            case TaskListPeriodColumn.Period_WithoutDeadline:
                this.start = undefined;
                this.end = undefined;
                break;
        }
    }

    public getStartDate(): Date {
        return this.start ?? moment().subtract(10, 'year').startOf('isoWeek').toDate();
    }

    public getEndDate(): Date {
        return this.end ?? moment().add(10, 'year').endOf('isoWeek').toDate();
    }

    public getPeriodString(): string {
        const translateService = AppInjector.getInjector().get(TranslateService);
        if (this.start && this.end) {
            const weekA = translateService.instant('_ui_week_no', {no: moment(this.start).isoWeek()});
            const weekB = translateService.instant('_ui_week_no', {no: moment(this.end).isoWeek()});
            if (moment(this.end).diff(moment(this.start), 'week') > 0) {
                return `${weekA}+${weekB}`;
            } else {
                return weekA;
            }
        } else if (this.start) { // Alt efter start
            const week = translateService.instant('_ui_week_no', {no: moment(this.start).isoWeek()});
            return `<span>${week}</span><span class="fal fa-arrow-right ms-1"></span>`;

        } else if (this.end) { // Alt før end
            const week = translateService.instant('_ui_week_no', {no: moment(this.end).isoWeek()});
            return `<span class="fal fa-arrow-left me-1"></span><span>${week}</span>`;
        } else {
            return '';
        }
    }

    public getStartDateForTaskPreset(): Date {
        switch (this.periodType) {
            case TaskListPeriodColumn.Period_BeforeThisWeek:
                // Start of last week
                return moment().subtract(1, 'week').startOf('isoWeek').toDate();
            case TaskListPeriodColumn.Period_ThisWeek:
                // Sæt frist til "I dag"
                return new Date();
            default:
                return this.getStartDate();
        }
    }

}
