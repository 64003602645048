import {NgModule} from "@angular/core";
import {StatusRuleAppenderComponent} from "@app/shared/_forms/status-rule-appender/status-rule-appender.component";
import {TranslateModule} from "@ngx-translate/core";
import {StatusRuleItemModule} from "@app/shared/_forms/status-rule-item/StatusRuleItem.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        StatusRuleItemModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        StatusRuleAppenderComponent,
    ],
    exports: [
        StatusRuleAppenderComponent

    ],
    providers: [

    ],
})
export class StatusRuleAppenderModule {}
