/**
 * Created by ModelParser
 */
import {DisplayType} from '../DisplayType';
import {DepartmentsDisplay} from '../DepartmentsDisplay';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class FeedDefinition extends BaseModel {
    display_type_id?: number;
    display_type?: DisplayType;
    displays_department_id?: number;
    departments_display?: DepartmentsDisplay;
    settings?: string;
    content?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.display_type_id;
            delete this.display_type;
            delete this.displays_department_id;
            delete this.departments_display;
            delete this.settings;
            delete this.content;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.display_type_id != null) {
            this.display_type_id = data.display_type_id;
        }
        if (data.display_type != null) {
            this.display_type = new DisplayType(data.display_type);
        }
        if (data.displays_department_id != null) {
            this.displays_department_id = data.displays_department_id;
        }
        if (data.departments_display != null) {
            this.departments_display = new DepartmentsDisplay(data.departments_display);
        }
        if (data.settings != null) {
            this.settings = data.settings;
        }
        if (data.content != null) {
            this.content = data.content;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
