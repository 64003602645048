import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {GenericTableColumn} from "@app/pages/displays/display-cases/TableColumns/GenericTableColumn";
import {GenericCell} from "@app/pages/displays/display-cases/Cells/GenericCell";

export class GenericColumn extends BaseColumn {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new GenericTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: BaseRow): BaseCell {
        return new GenericCell(row, this);
    }

}
