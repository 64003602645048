<div class="milestone-wrap" [ngbTooltip]="title ? title : null">
    <app-color-label class="card-type me-1"
                     [showTooltip]="false"
                     [status]="item.item.status"
                     [itemWithTypeProperties]="item.item"
                     [interactive]="false"
                     [style.width.px]="item.width ? item.width : null"
                     (click)="popover.toggle()"
                     [ngbPopover]="popoverContent "
                     [autoClose]="false"
                     triggers="manual"
                     container="body"
                     #popover="ngbPopover"
                     [popoverClass]="'popover-sub-header'"
                     [placement]="['bottom','auto']"
    ></app-color-label>
    <div class="extra-badge"
         *ngIf="item.relatedMilestones.length > 0 ">{{ (item.relatedMilestones.length + 1) }}
    </div>
</div>

<ng-template #popoverContent>
    <div class="popover-container sub-header">
        <ng-container *ngFor="let related of item.sortedMilestones">
            <div class="rounded mb-1 p-1 border">
                <div class="d-flex align-items-center item-wrapper mt-0 cursor-pointer"
                     (click)="onOpenBtnClicked(related.item)">
                    <app-color-label class="card-type me-1 flex-shrink-0"
                                     [status]="related.item.status"
                                     [itemWithTypeProperties]="related.item"
                                     [interactive]="false"
                                     [style.width.px]="24"
                    ></app-color-label>
                    <span>{{ related.item.title }}</span>
                    <span class="ms-auto"
                          *ngIf="related.item?.deadline">{{ related.item?.deadline?.getDate() | localizedDate: 'microDate' }}</span>
                </div>

                <ng-container *ngIf="related.item?.tasks && related.item.tasks.length > 0">
                    <ng-container *ngFor="let task of related.item.tasks">
                        <div class="milestone-tasks mt-1">
                            <div class="d-flex align-items-center item-wrapper cursor-pointer"
                                 (click)="onOpenBtnClicked(task)">
                                <app-color-label class="card-type me-1 flex-shrink-0"
                                                 [status]="task.status"
                                                 [itemWithTypeProperties]="task"
                                                 [interactive]="false"
                                                 [style.width.px]="24"
                                ></app-color-label>

                                <span class="ellipsis">{{ task.title }}</span>
                                <span class="ms-auto"
                                      *ngIf="task.findDeadlineByTypeId(task.getMiniCardDeadlineTypeId())">{{ task.findDeadlineByTypeId(task.getMiniCardDeadlineTypeId())?.getDate() | localizedDate: 'microDate' }}</span>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-template>
