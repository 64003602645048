import {HostListener, Injectable, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AppInjector} from '@app/services/app-injector.service';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {Display} from '@app/core/models/Display';
import {Department} from '@app/core/models/Department';
import {DisplayService} from '@app/services/display.service';
import {DisplaysSetting} from '@app/core/models/DisplaysSetting';
import {EventService} from '@app/services/event.service';
import {DragAndDropService} from '@app/services/drag-and-drop.service';
import {LocalStorageService} from '@app/services/local-storage.service';
import {CoreService} from "../../services/core.service";
import {PageDisplaySetting} from "@app/pages/PageDisplaySetting";
import {ShellColumnGroup} from "@app/services/ShellColumnService/ShellColumnGroup";
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {ShellColumnService} from "@app/services/ShellColumnService/shell-column.service";
import {ShellFilterService} from "@app/services/ShellFilterService/shell-filter.service";
import {ShellFilterGroup} from "@app/services/ShellFilterService/ShellFilterGroup";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {NgxCaptureService} from 'ngx-capture';
import {System} from '@app/constants';
import {ColumnController} from "@app/core/ColumnControl/ColumnController";

@Injectable()
export abstract class PageComponent extends BaseDisplayComponent implements OnInit, OnChanges, OnDestroy {

    // Bindings
    public isMainDisplay = true;
    public isOverlayDisplay = false;
    public displayId: number;
    public display: Display;
    public displayUnavailable = false;

    // UI: Loading
    public isPendingRequests = false;
    public isCapturingScreenshot = false;

    // Data
    public department: Department;
    public shellPageData: ShellPageData;
    public settingsMap: Map<number, PageDisplaySetting> = new Map(); // DisplaySettings mapped by setting_id

    // Columns
    protected abstract columnController?: ColumnController;
    public shellColumnGroup?: ShellColumnGroup;

    // Services
    protected coreService: CoreService;
    protected displayService: DisplayService;
    protected dragAndDropService: DragAndDropService;
    protected localStorageService: LocalStorageService;
    protected dialogService: BaseDialogService;
    protected shellColumnService: ShellColumnService;
    protected shellFilterService: ShellFilterService;
    public filterGlobalService: FilterGlobalService;
    protected eventService: EventService;
    protected captureService: NgxCaptureService;
    public isMobileVersion = false;

    protected constructor() {
        super();
        const injector = AppInjector.getInjector();
        this.localStorageService = injector.get(LocalStorageService);
        this.displayService = injector.get(DisplayService);
        this.dragAndDropService = injector.get(DragAndDropService);
        this.coreService = injector.get(CoreService);
        this.eventService = injector.get(EventService);
        this.dialogService = injector.get(BaseDialogService);
        this.shellColumnService = injector.get(ShellColumnService);
        this.shellFilterService = injector.get(ShellFilterService);
        this.filterGlobalService = injector.get(FilterGlobalService);
        this.captureService = injector.get(NgxCaptureService);
    }

    ngOnInit() {
        this.isMobileVersion = this.localStorageService.get(System.CookieMobile);
        this.isLoading = true;
        super.ngOnInit();

        // Setup subscriptions
        this.subscribe(this.coreService.pendingRequestCount$.subscribe(value => {
            this.isPendingRequests = value > 0;
            if (this.cdr) {
                this.detectChanges();
            }
        }));

        this.subscribe(this.shellService.isCapturingScreenshotEventEmitter.subscribe(value => {
            this.isCapturingScreenshot = value;
            if (this.cdr) {
                this.detectChanges();
            }
        }));

        // Override local properties from page settings
        const pageSettings = this.shellService.getPageSettings();
        if (!this.displayId) {
            this.displayId = pageSettings?.primaryDisplayId;
        }
        if (pageSettings?.departmentId) {
            this.department = new Department();
            this.department.id = pageSettings.departmentId;
        }

        // Load display
        if (this.displayId && this.department?.id) {
            this.loadDisplay();
        } else {
            this.onAfterDisplay();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.displayId) { // DisplayId might be added later, ex. Mit Overblik.
            if (this.displayId && this.department?.id) {
                this.loadDisplay();
            }
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.isLoading = true;
        this.shellPageData?.detach(this.isMainDisplay);
    }

    // Everything display related is loaded; columns and settings, filters are not guaranteed
    protected onAfterDisplay() {

    }

    // <editor-fold desc="Load display">

    protected loadDisplay() {
        if (this.display) {
            return;
        }

        this.isLoading = true;

        this.displayService.getDisplayWithSettings(this.displayId, (display) => {
            this.display = display;
            this.shellService.setPageSettingsDisplay(display);
            if (!display) {
                this.shellService.setHeaderTitle(this.translateService.instant('_admin_error_page_header'));
                this.dialogService.alert(
                    this.translateService.instant('_admin_error_page_header'),
                    (`<div class="d-flex flex-column"><p>${this.translateService.instant('_ui_error_display_missing')}</p></div>`),
                    this.translateService.instant('_global_ok')
                );
                this.displayUnavailable = true;
                this.detectChanges();
            }

            if (this.display) {
                if (this.filtersSettings) {
                    this.shellFilterGroup = new ShellFilterGroup(this.display.name, this.filtersSettings);
                }
                if (this.columnController) {
                    this.shellColumnGroup = new ShellColumnGroup(this.display.name, this.display, this.columnController);
                }
                this.shellPageData = new ShellPageData(this.display, this.department.id, this.shellFilterGroup, this.shellColumnGroup, this.isMainDisplay);
                this.shellPageData.attach(this.isMainDisplay);
                this.initializeShellPageData();

                this.applyDisplaySettings(this.display);

                if (this.filtersSettings) {
                    this.setupDisplayFilterGroup();
                }

                this.isLoading = false;
                this.onAfterDisplay();
            }
        });
    }

    // Override this method to apply custom configuration before continuing
    protected initializeShellPageData() {
    }

    protected applyDisplaySettings(display: Display) {
        const addSettings = (displaysSettings: DisplaysSetting[]) => {
            for (let displaysSetting of displaysSettings) {
                if (!this.settingsMap.has(displaysSetting.setting_id)) {
                    this.settingsMap.set(displaysSetting.setting_id, new PageDisplaySetting());
                }
                this.settingsMap.get(displaysSetting.setting_id).addSetting(displaysSetting);
            }
        };

        this.settingsMap.clear();
        if (display.displays_settings) {
            addSettings(display.displays_settings);
        }
        if (display.relateddisplays) {
            for (let relatedDisplay of display.relateddisplays) {
                if (relatedDisplay.displays_settings) {
                    addSettings(relatedDisplay.displays_settings);
                }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="Display Filters">

    public shellFilterGroup: ShellFilterGroup;
    protected abstract filtersSettings?: FiltersInterface;

    private setupDisplayFilterGroup() {
        this.subscribe(this.eventService.subscribeToDisplayFilters(0, event => {
            this.fetchDisplayFilters(false);
        }));
        this.subscribe(this.shellFilterGroup.onActiveFilterChangeEventSubscribe(displayFilter => {
            this.onFilterChange();
        }));

        this.fetchDisplayFilters(true);
    }

    private fetchDisplayFilters(findActiveFilter: boolean) {
        if (this.display && this.department) {
            this.displayService.getFilters(this.display.id, this.department.id, this.shellPageData.displayFilterExtra ?? '', filters => {
                // Attach filters to filter display group
                filters.forEach((displayFilter) => {
                    if (displayFilter.display_id && !displayFilter.display && displayFilter.display_id == this.display.id) {
                        displayFilter.display = this.display;
                    }
                })
                this.shellFilterGroup.setFilters(filters);

                // Find and activate a filter
                if (findActiveFilter) {
                    this.shellPageData.findAndActivateDisplayFilter();
                }
            });
        } else {
            console.error('fetchDisplayFilters() : error : missing display: ', this.display, 'this.department : ', this.department);
        }
    }

    public onFilterChange() {
        if (this.isMainDisplay) { // Primary display always decides the header

            // Har display og active filter, vis "DisplayName: FilterName"
            if (this.display && this.shellPageData?.shellFilterGroup?.activeFilter) {
                this.shellService.setHeaderTitle(
                    `${this.display.name}: ${this.shellPageData.shellFilterGroup.activeFilter.name}`
                );
            }

            // Har kun display, vis "DisplayName"
            else if (this.display) {
                this.shellService.setHeaderTitle(this.display.name);
            }

            // Har kun active filter, vis "FilterName"
            else if (this.shellPageData?.shellFilterGroup?.activeFilter) {
                this.shellService.setHeaderTitle(this.shellPageData.shellFilterGroup.activeFilter.name);
            }

            // Har ingen af delene, vis "-"
            else {
                this.shellService.setHeaderTitle('-');
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="Data Table Specific TO-DO: Move somewhere else">

    public isDataTableResizeEnabled = true;

    @HostListener('window:resize', ['$event'])
    sizeChange(event: Event) {
        this.updateTableWidth();
    }

    updateTableWidth() {
        if (this.columnController) {
            const element: HTMLElement = document.getElementById('dataTable')?.getElementsByClassName('datatable-scroll')[0] as HTMLElement;
            if (element) {
                if (element?.offsetWidth != window.innerWidth && element?.offsetWidth > window.innerWidth) {
                    this.isDataTableResizeEnabled = false;
                    document.getElementById('dataTable').classList.add('disable-resize');
                } else {
                    this.isDataTableResizeEnabled = true;
                    document.getElementById('dataTable').classList.remove('disable-resize');
                }
            }
        }
    }

    // </editor-fold>


}
