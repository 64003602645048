<div class="modal-inner-content p-0 overflow-visible" [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header">
        <h4 class="modal-description">{{ title ?? ('_display_efforts_appointment' | translate) }}</h4>

        <div class="ms-auto  d-flex align-content-center d-flex me-1">

            <app-color-item class="cursor-pointer me-2"
                            [className]="'medium'"
                            [colorValue]="'#ff5f57'"
                            [iconClass]="'fa-times'"
                            container="body" [ngbTooltip]="('_ui_close' | translate)"
                            (click)="closeForm();"></app-color-item>
        </div>

    </div>
    <div class="modal-body p-0 ">
        <div class="container p-0">

            <div *ngIf="ready"
                 [@fadeAnimation]="'in'"
                 class="h-100">
                <div class="d-flex flex-column h-100" style="padding-bottom: 61px;">

                    <div class="scroll-y align-self-stretch card-content">

                        <div class="container p-4">
                            <div class="form-group">
                                <quill-editor
                                    [placeholder]="''"
                                    [modules]="quillEditorConfig"
                                    (blur)="triggerBlur($event);"
                                    (focus)="triggerFocus();"
                                    [ngModel]="model"
                                    (onContentChanged)="contentChanged($event)"

                                ></quill-editor>
                            </div>
                        </div>
                    </div>
                </div>

                <ul class="nav nav-pills nav-fill position-absolute bg-light border-top w-100 p-2" style="bottom: 0;">
                    <li class="nav-item -px-2 flex-shrink-1" *ngIf="model">
                        <button type="button" class="btn btn-sm btn-danger panel-button max-width-unset"
                                (click)="delete()"
                                [ngbTooltip]="'_global_delete' | translate">
                            <!--                    <span translate>_global_delete</span>-->
                            <span class="fal fa-trash-alt"></span>
                        </button>
                    </li>
                    <li class="nav-item -px-2 flex-grow-1">
                        <button type="button" class="btn btn-sm btn-secondary  panel-button  max-width-unset"
                                (click)="closeForm()">
                            <span>{{ '_ui_close' | translate }}</span>
                        </button>
                    </li>
                    <li class="nav-item -px-2 flex-grow-1">
                        <button type="button" class="btn btn-sm btn-success panel-button max-width-unset"
                                (click)="saveForm()"
                        >
                            <span>{{ '_ui_save' | translate }}</span>
                        </button>
                    </li>
                </ul>

            </div>
            <app-ghost-container *ngIf="!editable || isLoading" (click)="close()"></app-ghost-container>
        </div>
    </div>
</div>
