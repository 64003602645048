import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {MultiListConfiguration} from "@app/shared/_ui/lists/multi-list/MultiListConfiguration";
import {SoftNextWeekColumn} from "@app/pages/displays/dashboard/dashboard-calendar/Columns/SoftNextWeekColumn";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {TableColumns} from "@app/pages/displays/dashboard/dashboard-calendar/TableColumns";

export class SoftNextWeekTableColumn extends BaseTableColumn {

    public identifier = TableColumns.SoftNextWeek;
    public dropListId = '_' + Math.random().toString(36).substr(2, 9);

    public dateStart: Date = new Date();
    public dateEnd: Date = new Date();

    public listConfiguration = new MultiListConfiguration();
    public taskListConfiguration = new TaskListConfiguration();

    public itemCount = 0;

    constructor(column: SoftNextWeekColumn) {
        super(column);
        this.name = column.column.name;

        this.taskListConfiguration
            .setSoftDeadline(true)
            .setTaskTypeIds(column.getIncludeTaskTypes())
            .setLimit(100)
            .setShowCreateNew(false)
            .setDraggable(true);
        this.listConfiguration.addSource(this.taskListConfiguration);

        this.listConfiguration.pushSubscribe();
        this.listConfiguration.addDataListeners(items => {
            this.dataSetChanged();
        }, item => {
            this.dataSetChanged();
        }, item => {
            this.dataSetChanged();
        }, count => {
            this.dataSetChanged();
        });
    }

    public dataSetChanged() { // Triggered by dataSetChange event in MultiListComponent
        let itemCount = 0;
        this.listConfiguration.getSources().forEach(source => itemCount += source.items.length);
        this.itemCount = itemCount;
    }

}
