import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[scroll]'
})
export class ScrollDirective {

    @Output() setScroll = new EventEmitter();
    private scrollTop: number;
    private scrollLeft: number;

    private ticking = false;

    @Output('onReachTop') onReachTop: EventEmitter<any> = new EventEmitter<any>();
    @Output('onReachBottom') onReachBottom: EventEmitter<any> = new EventEmitter<any>();
    @Output('onScroll') onScroll: EventEmitter<any> = new EventEmitter<any>();

    @Input('offsetTop') offsetTop = 0;
    @Input('offsetBottom') offsetBottom = 0;

    constructor(private el: ElementRef) { }

    @HostListener('scroll', ['$event'])
    scrollIt(event: UIEvent):void {
        // console.log(this, 'emit scroll')
        this.setScroll.emit(event.target);

        let emitted: boolean = false;

        const scrollElement = this.el.nativeElement;

        const scrollTop: number = scrollElement.scrollTop;
        const scrollHeight: number = scrollElement.scrollHeight;
        const clientHeight: number = scrollElement.clientHeight;

        if (scrollTop <= this.offsetTop) {
            // console.log('ScrollDetectorDirective : top reached');
            this.onReachTop.emit(scrollTop);
            emitted = true;
        }

        if (scrollHeight - (scrollTop + clientHeight) <= this.offsetBottom) {
            this.onReachBottom.emit(scrollTop);
            // console.log('ScrollDetectorDirective : bottom reached');
            emitted = true;
        }
        if (!emitted) {
            this.onScroll.emit(scrollTop);
        }

    }

    reset() {
        // this.el.nativeElement.scrollTop = this.scrollTop;
        // this.el.nativeElement.scrollLeft = this.scrollLeft;
    }

}
