<div
    *ngIf="isReady"
    class="container p-2 d-flex gap-1 w-100 flex-wrap editor-tab"
>

    <app-todo-category-editor
        *ngIf="showTodoCategoryEditor"
        [model]="item"
        [configuration]="todoCategoryEditorConfiguration"
    />

    <app-user-list-editor
        *ngIf="showUserEditor"
        [model]="item"
        [configuration]="userEditorConfiguration"
    />

    <app-deadline-list-editor
        *ngIf="showDeadlineEditor"
        [model]="item"
        [configuration]="deadlineEditorConfiguration"
    />

    <app-project-list-editor
        *ngIf="showProjectListEditor"
        [model]="item"
        [configuration]="projectListEditorConfiguration"
    />

    <app-text-editor
        *ngIf="showDescriptionTextEditor"
        [model]="item"
        [configuration]="descriptionTextEditorConfiguration"
    />

    <div
        *ngIf="showEmojiReactionEditor"
        class="form-group"
    >
        <label>{{'_ui_display_emoji' | translate}}</label>
        <ul class="list-group mt-1">
            <li class="list-group-item">
                <app-reaction-list-editor
                    [model]="item"
                    [configuration]="emojiReactionEditorConfiguration"
                />
            </li>
        </ul>
    </div>

    <app-checkbox-editor
        *ngIf="showOnDisplayEditor"
        class="mt-2"
        [model]="item"
        [configuration]="showOnDisplayEditorConfiguration"
    />

</div>
