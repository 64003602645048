import {FocusableService} from "@app/core/Focusable/Focusable.service";
import {FocusableInterface} from "@app/core/Focusable/FocusableInterface";
import {FocusableEntityType} from "@app/core/Focusable/FocusableEntityTypes";
import {Subscription} from "rxjs";

export class FocusableTarget extends Subscription {

    private service: FocusableService;
    private implementation: FocusableInterface;

    private readonly type: FocusableEntityType;
    private readonly identifier: number;

    constructor(
        service: FocusableService,
        implementation: FocusableInterface,
        type: FocusableEntityType,
        identifier: number,
    ) {
        super();
        this.service = service;
        this.implementation = implementation;
        this.type = type;
        this.identifier = identifier;
    }

    public focus() {
        this.implementation.focus();
    }

    public unsubscribe() {
        this.service.unregister(this.type, this.identifier, this);
    }

}
