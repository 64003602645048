import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/TaskEditColumn";
import {
    BaseEditorTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/EditorTableColumns/BaseEditorTableColumn";
import {TextColumnConfiguration} from "@app/editor/quick-editor/columns/generic/text-column/TextColumnConfiguration";
import {
    EditorWithColumnInterface
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/EditorWithColumnInterface";
import {TextColumnComponent} from "@app/editor/quick-editor/columns/generic/text-column/text-column.component";
import {EditLinkConfiguration} from "@app/editor/quick-editor/editors/generic/link-editor/EditLinkConfiguration";
import {LinkEditorComponent} from "@app/editor/quick-editor/editors/generic/link-editor/link-editor.component";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns";

export class TaskEdit_Link_TableColumn extends BaseEditorTableColumn {

    public identifier = TableColumns.TaskEdit_Link;

    public column: TaskEditColumn;
    public minWidth = 100;
    public columnConfiguration: TextColumnConfiguration;
    public editorWithColumn: EditorWithColumnInterface;

    constructor(column: TaskEditColumn, isMinimized: boolean) {
        super(column);
        this.name = '_displays_project_details_milestones_column_task_edit_link';

        this.columnConfiguration = new TextColumnConfiguration(
            '_displays_project_details_milestones_column_task_edit_link',
            true,
            true
        );
        this.columnConfiguration.savedWidth = this.width > 0 ? this.width : undefined;
        this.columnConfiguration.minWidth = this.minWidth;
        this.columnConfiguration.maxWidth = this.maxWidth;
        this.columnConfiguration.canAutoResize = false;
        this.columnConfiguration.onResizeEndEvent.subscribe(() => {
            this.width = this.columnConfiguration.width;
        });

        const editorConfiguration = new EditLinkConfiguration("", "compact");
        editorConfiguration.widthSynchronizer = this.columnConfiguration.widthSynchronizer;
        editorConfiguration.isExpanded = !isMinimized;

        this.editorWithColumn = {
            name: LinkEditorComponent.name,
            columnName: TextColumnComponent.name,
            columnConfiguration: this.columnConfiguration,
            configuration: editorConfiguration,
        };
    }

}
