<app-card-preview [mini]="true" *ngIf="!initialized && visible"></app-card-preview>
<div #cardContainer
     class="card p-1 mini mb-0" (click)="onCardClick($event)" *ngIf="model && initialized && visible"
     [class.inline]="(!configuration || configuration.inline)"
     [class.grayed-out]="(configuration && configuration.isGrayedOut)">

    <div class="d-flex w-100 font-size-lg  align-items-center justify-content-center">

        <app-color-label class="card-type me-1"
                         *ngIf="isFieldVisible(Fields.Status)"
                         [status]="model.status"
                         [itemWithTypeProperties]="model"
                         (onStatusChange)="onStatusChange($event)"></app-color-label>

        <div class="title p-0 font-weight-bold col -border-bottom -border-light flex-fill me-1"
             *ngIf="isFieldVisible(Fields.Title)"
        >
            <input class="form-control" [(ngModel)]="model.title" *ngIf="editable"/>
            <div *ngIf="!editable" class="pt-0 h-100 text-capitalize-firstletter"
                 [class.unset-height]="(!configuration || configuration.inline)"
                 [ngbTooltip]="tooltip ? tooltip : (model.title | placeholder: ('_ui_no_title' | translate))"
            >{{model.title | placeholder: ('_ui_no_title' | translate)}}
            </div>
        </div>

        <a class="font-size-md text-danger remove d-block flex-row align-items-center ms-1 me-1"
           (click)="triggerRemove(model);$event.stopPropagation()"
           [ngbTooltip]="('_ui_emoji_clear' | translate)"
           *ngIf="removable">
            <i class="fal fa-eraser"></i>
        </a>

    </div>
</div>
