import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {TodoListConfiguration} from "@app/shared/_ui/lists/todo-list/TodoListConfiguration";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";

export class TodoListCell extends BaseCell {

    public listConfiguration = new TodoListConfiguration();

    public constructor(row: BaseRow, column: BaseColumn) {
        super(row, column);

        this.listConfiguration
            .setLimit(2)
            // .setLimit(ListConfiguration.SmartLimit) Slået fra da det ikke er implementeret på TodoListComponent, melder fejl.
            .setArchived(false)
            .setHasDisplay(true)
            .setShowCreateNew(true);
    }

}
