import {NgModule} from "@angular/core";
import {
    OnScreenFilterSelectorComponent
} from "@app/shared/_ui/on-screen-filter-selector/on-screen-filter-selector.component";
import {NgbDropdownModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbDropdownModule,
        TranslateModule,
        NgbTooltipModule,
        ToggleItemModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        OnScreenFilterSelectorComponent,
    ],
    exports: [
        OnScreenFilterSelectorComponent

    ],
    providers: [

    ],
})
export class OnScreenFilterSelectorModule {}
