import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/GenericTableColumn";
import {
    MilestoneYearWheelColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/MilestoneYearWheelColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns";

export class MilestoneYearWheelTableColumn extends GenericTableColumn {

    public identifier = TableColumns.MilestoneYearWheel;

    public column: MilestoneYearWheelColumn;

}
