/**
 * Created by ModelParser
 */
import {CategoryType} from '../CategoryType';
import {Color} from '../Color';
import {Department} from '../Department';
import {CategoriesDepartment} from '../CategoriesDepartment';
import {Project} from '../Project';
import {Task} from '../Task';
import {Milestone} from '../Milestone';
import {DisplayFilter} from '../DisplayFilter';
import {CategoriesDisplayFilter} from '../CategoriesDisplayFilter';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class CategoryDefinition extends BaseModel {
    name?: string;
    category_type_id?: number;
    category_type?: CategoryType;
    index_?: number;
    color_id?: number;
    color?: Color;
    departments?: Department[];
    categories_departments?: CategoriesDepartment[];
    projects?: Project[];
    tasks?: Task[];
    milestones?: Milestone[];
    display_filters?: DisplayFilter[];
    categories_display_filters?: CategoriesDisplayFilter[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.category_type_id;
            delete this.category_type;
            delete this.index_;
            delete this.color_id;
            delete this.color;
            delete this.departments;
            delete this.categories_departments;
            delete this.projects;
            delete this.tasks;
            delete this.milestones;
            delete this.display_filters;
            delete this.categories_display_filters;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.category_type_id != null) {
            this.category_type_id = data.category_type_id;
        }
        if (data.category_type != null) {
            this.category_type = new CategoryType(data.category_type);
        }
        if (data.index_ != null) {
            this.index_ = data.index_;
        }
        if (data.color_id != null) {
            this.color_id = data.color_id;
        }
        if (data.color != null) {
            this.color = new Color(data.color);
        }
        if (data.departments != null) {
            this.departments = data.departments.map((i: any) => new Department(i));
        }
        if (data.categories_departments != null) {
            this.categories_departments = data.categories_departments.map((i: any) => new CategoriesDepartment(i));
        }
        if (data.projects != null) {
            this.projects = data.projects.map((i: any) => new Project(i));
        }
        if (data.tasks != null) {
            this.tasks = data.tasks.map((i: any) => new Task(i));
        }
        if (data.milestones != null) {
            this.milestones = data.milestones.map((i: any) => new Milestone(i));
        }
        if (data.display_filters != null) {
            this.display_filters = data.display_filters.map((i: any) => new DisplayFilter(i));
        }
        if (data.categories_display_filters != null) {
            this.categories_display_filters = data.categories_display_filters.map((i: any) => new CategoriesDisplayFilter(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
