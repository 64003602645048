import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {User} from '@app/core/models/User';

interface FileUploadInterface {
    filename: string;
    filetype: string;
    value: any;
}

export class PasswordValidator {
    // Inspired on: http://plnkr.co/edit/Zcbg2T3tOxYmhxs7vaAm?p=preview
    static areEqual(formGroup: UntypedFormGroup) {
        let value;
        let valid = true;
        for (let key in formGroup.controls) {
            if (formGroup.controls.hasOwnProperty(key)) {
                let control: UntypedFormControl = <UntypedFormControl>formGroup.controls[key];

                if (value === undefined) {
                    value = control.value;
                } else {
                    if (value !== control.value) {
                        valid = false;
                        break;
                    }
                }
            }
        }

        if (valid) {
            return null;
        }

        return {
            areEqual: true
        };
    }
}

export class UsernameValidator {
    static validUsername(fc: UntypedFormControl) {
        if (fc.value.toLowerCase() === 'abc123' || fc.value.toLowerCase() === '123abc') {
            return ({validUsername: true});
        } else {
            return null;
        }
    }
}

interface SettingsEditorFormInterface {
    id?: number;
    username: string;
    first_name: string;
    last_name: string;
    language_id: number;

    //password: string;
    //confirm_password: string;
    matching_passwords: {
        password: string,
        confirm_password: string
    },
    default_displays_department_id?: number
    //file?: FileUploadInterface,
    //image?: string // Base64 fil
}

export class SettingsEditorForm extends User {
    image: string;

    public static create(form: SettingsEditorFormInterface): SettingsEditorForm {
        let item = new SettingsEditorForm(form);
        if(form.matching_passwords) {
            item.password = form.matching_passwords.password;
        }else{
            delete item.password;
        }


        return item;
    }

    public toFormObject(): SettingsEditorFormInterface {
        let item: SettingsEditorFormInterface = {
            id: this.id ? this.id : 0,
            username: this.username ? this.username : '',
            first_name: this.first_name,
            last_name: this.last_name,
            language_id: this.language_id,
            matching_passwords: {
                password: this.password,
                confirm_password: this.password
            },
            default_displays_department_id: this.default_displays_department_id

        };
        return item;
    }


    public passwordValidator: ValidatorFn = Validators.compose([
        Validators.minLength(5),
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$') //this is for the letters (both uppercase and lowercase) and numbers validation
    ]);

    public setPasswordValidation(form: any, required: boolean) {

        if (required) {
            form.get('matching_passwords.password').enable();
            form.get('matching_passwords.confirm_password').enable();

            /*
            form.get('matching_passwords.password').setValidators(this.passwordValidator);
            form.get('matching_passwords.confirm_password').setValidators([Validators.required]);
            */
        } else {
            form.get('matching_passwords.password').disable();
            form.get('matching_passwords.confirm_password').disable();
            /*
            form.get('matching_passwords.password').clearValidators();
            form.get('matching_passwords.confirm_password').clearValidators();
            */
        }
        form.get('matching_passwords.password').updateValueAndValidity();
        form.get('matching_passwords.confirm_password').updateValueAndValidity();
        form.get('matching_passwords').updateValueAndValidity();

        //form.updateValueAndValidity();
        console.log('setPasswordValidation : ', required, form);

    }

    // https://angular-templates.io/tutorials/about/angular-forms-and-validations
    public toFormGroup(formBuilder: UntypedFormBuilder) {
        let item = this.toFormObject();
        let form: any = item;

        form.first_name = [item.first_name, Validators.required];
        form.last_name = [item.last_name, Validators.required];
        form.default_displays_department_id = [item.default_displays_department_id];

        form.username = [item.username, Validators.compose([
            Validators.required,
            Validators.email,
            Validators.minLength(4)

        ])];


        form.matching_passwords = formBuilder.group(
            {
                password: new UntypedFormControl(this.password, this.passwordValidator),
                confirm_password: new UntypedFormControl(this.password, [Validators.required])
            },
            {
                validator: (formGroup: UntypedFormGroup) => {
                    return PasswordValidator.areEqual(formGroup);
                }
            }
        );


        /*
        form.email = [item.email, Validators.compose([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
        ])]
        */

        return formBuilder.group(form);
    }

}
