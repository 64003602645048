<div class="position-relative w-100 h-100 note-wrapper">
    <ul class="list-unstyled d-flex justify-content-start rounded pb-1 ps-2 pe-2 -corner-item left text-small action-panel"
            *ngIf="value"
            [class.no-hover]="!value"
            [class.placement-bottom-right]="!value"
        >
            <li [ngbTooltip]="value ? ('_global_edit' | translate) : null" *ngIf="model">
                <a class="text-center me-1 ms-1"

                   (click)="editorDialog()">
                    <i class=" " aria-hidden="true"
                    [class.fal]="value"
                    [class.fa-edit]="value"
                    [class.fas]="!value"
                    [class.fa-plus-circle]="!value"
                    ></i></a>
            </li>
            <li [ngbTooltip]="expanded ? ('_ui_minimize' | translate) : ('_ui_maximize' | translate)"
                (click)="this.expanded = !this.expanded"
                *ngIf="value"
            >
                <a class="text-center me-1 ms-1"
                   [ngbTooltip]="(expanded ? ('_ui_show_less' | translate) : ('_ui_expand_lists' | translate)) + '...'"
                >
                    <i class="fal "
                       [class.fa-arrows-to-line]="expanded"
                       [class.fa-arrows-from-line]="!expanded"
                       aria-hidden="true"></i>
                </a>
            </li>
        </ul>

    <div class="rich-text-wrapper flex-grow-1"
         *ngIf="value"
         [class.expanded]="expanded"
         [innerHTML]="value"></div>




</div>

<ul class="list-unstyled m-0 d-flex justify-content-start align-items-center rounded pb-1 ps-2 pe-2 -corner-item left text-small action-panel placement-top-right no-fade solid corner"
    *ngIf="!value && model"
>
    <li *ngIf="model">
        <a class="text-center me-1 ms-1"
           (click)="editorDialog()">
            <i class="fas fa-plus-circle " aria-hidden="true"></i></a>
    </li>
</ul>

