export class Columns {
    public static Name                      = 77;
    public static _Archived                 = 10077; // Client-only, used in CSV Export
    public static NextMilestone             = 20;
    public static _NextMilestoneDeadline    = 10020; // Client-only, used in CSV Export
    public static NextMilestoneDeadline     = 21; // No longer in use
    public static NextMilestoneRisk         = 23;
    public static NextMilestoneTasks        = 75;
    public static Appointments              = 25;

    public static Responsible               = 17;
    public static Participants              = 18;
    public static Deadline                  = 19;
    public static Status                    = 24; // No longer in use
    public static _Area                     = 58; // Deleted, 2.19.0
    public static _Category                 = 59; // Deleted, 2.19.0
    public static _Customer                 = 60; // Deleted, 2.19.0
    public static Estimate                  = 61;
    public static Phase                     = 62;
    public static PhaseDate                 = 63;
    public static UserColumn1               = 163;
    public static Deadline2                 = 165;
    public static Purpose                   = 276;
    public static DeadlineDifference        = 277;
    public static NB                        = 304;
    public static Notes                     = 312;
    public static Notes2                    = 353;
    public static Notes3                    = 354;
    public static Notes4                    = 355;
    public static Notes5                    = 356;

    public static Notes6                    = 495;
    public static Notes7                    = 496;
    public static Notes8                    = 497;
    public static Notes9                    = 498;
    public static Notes10                   = 499;

    public static CalculatedFields          = 315;
    public static Phases                    = 318;
    public static Categories                = 324;
    public static Users                     = 491;

    public static FollowingMilestones           = 230;
    public static _FollowingMilestonesDeadline  = 100230; // Client-only, used in CSV Export
    public static ProjectTasks                  = 231;
    public static ExtraStatuses                 = 232;
    public static Todos                         = 233;
    public static ProjectTasksWithoutMilestones = 316;

    public static YearWheel                     = 306;

    // Vagtplan
    public static Roster_Monday             = 333;
    public static Roster_Tuesday            = 334;
    public static Roster_Wednesday          = 335;
    public static Roster_Thursday           = 336;
    public static Roster_Friday             = 337;
    public static Roster_Saturday           = 338;
    public static Roster_Sunday             = 339;

    public static GetRosterColumns(): number[] {
        return [
            this.Roster_Monday,
            this.Roster_Tuesday,
            this.Roster_Wednesday,
            this.Roster_Thursday,
            this.Roster_Friday,
            this.Roster_Saturday,
            this.Roster_Sunday,
        ];
    }

}
