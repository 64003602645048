import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {
    ProjectDeadlineDifferenceTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectDeadlineDifferenceTableColumn";
import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {
    ProjectNoteTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectNoteTableColumn";
import {
    AppointmentListTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/AppointmentListTableColumn";
import {
    ProjectCardTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectCardTableColumn";
import {
    ProjectDeadlineListTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectDeadlineListTableColumn";
import {
    ProjectEstimateListTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectEstimateListTableColumn";
import {
    ProjectStatusListTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectStatusListTableColumn";
import {
    ProjectUserListTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectUserListTableColumn";
import {
    TaskListAllTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/TaskListAllTableColumn";
import {
    TaskListOpenTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/TaskListOpenTableColumn";
import {
    TaskListWithoutDeadlineTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/TaskListWithoutDeadlineTableColumn";
import {
    TaskListWithoutMilestoneTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/TaskListWithoutMilestoneTableColumn";
import {
    TodoListTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/TodoListTableColumn";
import {
    YearWheelTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/YearWheelTableColumn";

export class TemplateTypes {

    headerTemplateType: {
        column: GenericTableColumn,
    }

    headerYearWheelTemplateType: {
        column: YearWheelTableColumn,
    }

    cellTemplateType: {
        column: GenericTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellProjectDeadlineDifferenceTemplateType: {
        column: ProjectDeadlineDifferenceTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellProjectNoteTemplateType: {
        column: ProjectNoteTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellAppointmentListTemplateType: {
        column: AppointmentListTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellProjectCardTemplateType: {
        column: ProjectCardTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellProjectDeadlineListTemplateType: {
        column: ProjectDeadlineListTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellProjectEstimateListTemplateType: {
        column: ProjectEstimateListTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellProjectStatusListTemplateType: {
        column: ProjectStatusListTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellProjectUserListTemplateType: {
        column: ProjectUserListTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellTaskListAllTemplateType: {
        column: TaskListAllTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellTaskListOpenTemplateType: {
        column: TaskListOpenTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellTaskListWithoutDeadlineTemplateType: {
        column: TaskListWithoutDeadlineTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellTaskListWithoutMilestoneTemplateType: {
        column: TaskListWithoutMilestoneTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellTodoListTemplateType: {
        column: TodoListTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellYearWheelTemplateType: {
        column: YearWheelTableColumn,
        row: Row,
        rowIndex: number,
    }

}
