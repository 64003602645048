import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {BaseModel} from "@app/core/models/BaseModel";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";

@Component({
    selector: 'app-card-editor',
    templateUrl: './card-editor.component.html',
    styleUrls: ['./card-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardEditorComponent extends BaseEditor<CardItem> {
    protected eventFieldName?: string;

    // Bindings to parent
    @Input() model: CardItem&HasEventGenerator&BaseModel;
    @Input() cardItem: CardItem;
    @Input() configuration: BaseEditorConfiguration;
    @Input() listDragInterface: ListDragInterface;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public isDragEnabled: boolean;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    protected render() {
        this.isDragEnabled = this.listDragInterface?.isCardItemDraggable(this.cardItem) ?? false;
    }

}
