import {NgModule} from "@angular/core";
import {DropdownAppendToBodyDirective} from "@app/directives/dropdown-append-to-body.directive";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [
        NgbDropdownModule
    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DropdownAppendToBodyDirective,
    ],
    exports: [
        DropdownAppendToBodyDirective

    ],
    providers: [

    ],
})
export class DropdownAppendToBodyDirectiveModule {}
