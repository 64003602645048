import {EventEmitter, Injectable} from "@angular/core";
import {FilterGlobalSettings} from "@app/services/FilterGlobalService/FilterGlobalSettings";
import {Category, DisplayFilter, ReactionFilter, Site, User} from "@app/core/models";
import {FilterGlobalVisibility} from "@app/services/FilterGlobalService/FilterGlobalVisibility";
import {PeriodRange} from "@app/services/FilterGlobalService/PeriodRange";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {ShellService} from "@app/services/ShellService/shell.service";
import {Filters} from '@app/pages/displays/display-projects/Filters';

@Injectable({
    providedIn: 'root',
})
export class FilterGlobalService {

    // Data
    private visibilitySettings = new FilterGlobalVisibility();
    private activeSettings = new FilterGlobalSettings();
    private lastAppliedSettings?: FilterGlobalSettings;

    // Events
    public onSettingsChangeEvent = new EventEmitter<FilterGlobalSettings>();
    public onSettingsPeriodChangeEvent = new EventEmitter<PeriodRange>();
    public onVisibilityChangeEvent = new EventEmitter<FilterGlobalVisibility>();

    constructor(private shellService: ShellService) {
        Site.Get(site => {
            if (site) {
                this.visibilitySettings.showCategories = site.has_category_types;
                this.visibilitySettings.showReactionTypes = site.has_reaction_types;
                this.onVisibilityChangeEvent.emit(this.visibilitySettings);
            }
        });
        // Hide date & week picker on page disappear
        this.shellService.onPrimaryPageDataChangeEvent.subscribe(value => {
            if (value === undefined) {
                this.setShowWeekPicker(false);
                this.setShowDatePicker(false);
                this.setShowUserSearch(false);
            }
        });
    }

    // <editor-fold desc="Filter Settings">
    public setStarred(value: boolean) {
        if (this.activeSettings.isStarred != value) {
            this.activeSettings.isStarred = value;
            // console.log('onSettingsChangeEvent : setStarred')
            this.onSettingsChangeEvent.emit(this.activeSettings);
        }
    }

    public setHandUp(value: boolean) {
        if (this.activeSettings.isHandUp != value) {
            this.activeSettings.isHandUp = value;
            // console.log('onSettingsChangeEvent : isHandUp')
            this.onSettingsChangeEvent.emit(this.activeSettings);
        }
    }

    public setActiveSortDirection(value: 'asc' | 'desc') {
        if (this.activeSettings.activeSortDirection != value) {
            this.activeSettings.activeSortDirection = value;
            // console.log('onSettingsChangeEvent : activeSortDirection')
            this.onSettingsChangeEvent.emit(this.activeSettings);
        }
    }

    public setActiveStatuses(value: number[]) {
        if (this.activeSettings.activeStatuses != value) {
            this.activeSettings.activeStatuses = value;
            // console.log('onSettingsChangeEvent : activeStatuses')
        }
        this.onSettingsChangeEvent.emit(this.activeSettings);
    }

    public toggleStatus(value: number) {
        if (this.activeSettings.activeStatuses.includes(value)) {
            this.activeSettings.activeStatuses.splice(this.activeSettings.activeStatuses.indexOf(value), 1);
        } else {
            this.activeSettings.activeStatuses.push(value);
        }
        this.onSettingsChangeEvent.emit(this.activeSettings);
    }

    public setActiveReactionFilters(value: ReactionFilter[]) {
        if (this.activeSettings.activeReactionFilters != value) {
            this.activeSettings.activeReactionFilters = value;
        }
        this.onSettingsChangeEvent.emit(this.activeSettings);
    }

    public setActiveCategories(value: Category[]) {
        if (this.activeSettings.activeCategories != value) {
            this.activeSettings.activeCategories = value;
        }
        this.onSettingsChangeEvent.emit(this.activeSettings);
    }

    public setActiveUsers(value: User[]) {
        if (this.activeSettings.activeUsers != value) {
            this.activeSettings.activeUsers = value;
        }
        this.onSettingsChangeEvent.emit(this.activeSettings);
    }

    public setSearch(value: string) {
        if (this.activeSettings.search != value) {
            this.activeSettings.search = value ?? '';
            this.onSettingsChangeEvent.emit(this.activeSettings);
        }
    }

    public setPeriod(value: PeriodRange) {
        if (value.start.getDate() != this.activeSettings.period.start.getDate() || value.end.getDate() != this.activeSettings.period.end.getDate()) {
            this.activeSettings.period = value;
            this.onSettingsChangeEvent.emit(this.activeSettings);
        }
        this.onSettingsPeriodChangeEvent.emit(value);
    }

    public applyDisplayFilter(value: DisplayFilter, filtersSettings: FiltersInterface) {
        const settings = new FilterGlobalSettings();
        if (value) {
            settings.isStarred = value.starred ?? false;
            settings.isHandUp = value.hand_up ?? false;
            settings.activeSortDirection = value.sort_direction == 'asc' ? 'asc' : 'desc';
            settings.activeStatuses = value.statusArray();
            settings.activeReactionFilters = value.reaction_filters ?? [];
            settings.activeCategories = value.categories ?? [];

            // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1569
            // Reset period when today, this week or next week is selected

            switch (value.period_type) {
                case Filters.PeriodToday:
                case Filters.PeriodThisWeek:
                    settings.period = new PeriodRange();
                    // console.log('applyDisplayFilter : change period : PeriodThisWeek: ', 'settings period : ', settings.period)
                    break;
                case Filters.PeriodNextWeek:
                    settings.period = new PeriodRange();
                    settings.period.changeDate(7);
                    // console.log('applyDisplayFilter : change period : PeriodNextWeek: ', 'settings period : ', settings.period)
                    break;
                default:
                    settings.period = value.getPeriodStart(filtersSettings)
                        ? new PeriodRange(value.getPeriodStart(filtersSettings), value.getPeriodEnd(filtersSettings))
                        : new PeriodRange();
                    break;
            }
        }
        this.applySettings(settings);
        this.setShowSorting(value?.showSortDirection(filtersSettings) ?? false);
    }

    public revertToLastApplied() {
        this.applySettings(this.lastAppliedSettings ?? new FilterGlobalSettings());
        this.onSettingsChangeEvent.emit(this.activeSettings);
    }

    private applySettings(settings: FilterGlobalSettings) {
        const emitSettings = this.lastAppliedSettings !== settings;
        this.lastAppliedSettings = settings.clone();
        this.activeSettings = settings;
        // console.log('applySettings() : onSettingsChangeEvent : settings : ', settings, 'emitSettings : ', emitSettings)
        if (emitSettings)
            this.onSettingsChangeEvent.emit(this.activeSettings);
    }

    public getActiveSettings(): FilterGlobalSettings {
        return this.activeSettings;
    }

    public isSettingsAltered(): boolean {
        return !this.activeSettings.isEqualTo(this.lastAppliedSettings ?? new FilterGlobalSettings());
    }

    public isSettingsDefaultValues(): boolean {
        return this.activeSettings.isEqualTo(new FilterGlobalSettings());
    }

    // </editor-fold>

    // <editor-fold desc="Visibility Settings">

    public setShowSearch(value: boolean) {
        if (this.visibilitySettings.showSearch != value) {
            this.visibilitySettings.showSearch = value;
            this.onVisibilityChangeEvent.emit(this.visibilitySettings);
        }
    }

    public setShowUserSearch(value: boolean) {
        if (this.visibilitySettings.showUsers != value) {
            this.visibilitySettings.showUsers = value;
            this.onVisibilityChangeEvent.emit(this.visibilitySettings);
        }
    }

    public setShowSorting(value: boolean) {
        if (this.visibilitySettings.showSorting != value) {
            this.visibilitySettings.showSorting = value;
            this.onVisibilityChangeEvent.emit(this.visibilitySettings);
        }
    }

    public setShowWeekPicker(value: boolean) {
        if (this.visibilitySettings.showWeekPicker != value) {
            this.visibilitySettings.showDatePicker = false;
            this.visibilitySettings.showWeekPicker = value;
            this.onVisibilityChangeEvent.emit(this.visibilitySettings);
        }
    }

    public setShowDatePicker(value: boolean) {
        if (this.visibilitySettings.showDatePicker != value) {
            this.visibilitySettings.showWeekPicker = false;
            this.visibilitySettings.showDatePicker = value;
            this.onVisibilityChangeEvent.emit(this.visibilitySettings);
        }
    }

    public getVisibilitySettings(): FilterGlobalVisibility {
        return this.visibilitySettings;
    }

    // </editor-fold>

}
