import {NgModule} from "@angular/core";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {NgbTooltipModule, NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RichTextEditorModule} from "@app/shared/_forms/rich-text-editor/RichTextEditor.module";
import {CommonModule} from "@angular/common";
import {GhostContainerModule} from "@app/editor/ghost-container/GhostContainer.module";
import {NgxModalDraggableDirectiveModule} from "@app/directives/NgxModalDraggableDirective.module";
import {
    MoveTaskPlanningDateMultipleUsersDialogComponent
} from "@app/shared/_modals/move-task-planning-date-multiple-users-dialog/move-task-planning-date-multiple-users-dialog.component";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {UserSearchModule} from "@app/shared/_forms/search/user-search/UserSearch.module";

@NgModule({
    imports: [
        ColorItemModule,
        NgbTooltipModule,
        TranslateModule,
        ReactiveFormsModule,
        RichTextEditorModule,
        CommonModule,
        GhostContainerModule,
        NgxModalDraggableDirectiveModule,
        LoadingIndicatorModule,
        UserSearchModule,
        NgbTypeahead,
        FormsModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MoveTaskPlanningDateMultipleUsersDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class MoveTaskPlanningDateMultipleUsersDialogModule {}
