import {NgModule} from "@angular/core";
import {
    ProjectParticipantAddPopoverComponent
} from "@app/pages/displays/display-project-details/components/project-participant-add-popover/project-participant-add-popover.component";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {UserSearchModule} from "@app/shared/_forms/search/user-search/UserSearch.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbPopoverModule,
        NgbTooltipModule,
        TranslateModule,
        UserSearchModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectParticipantAddPopoverComponent,
    ],
    exports: [
        ProjectParticipantAddPopoverComponent

    ],
    providers: [

    ],
})
export class ProjectParticipantAddPopoverModule {}
