<div [class.full-width-]="fullWidth" [class.edit-mode]="editmode" class="-w-100 text-center d-flex align-items-center spinner-wrapper">
    <div class="-w-100 text-center cursor-pointer rounded -bg-light input-padding d-flex align-items-center justify-content-center"
         *ngIf="!editmode" (click)="editmode = true"
         [ngbTooltip]="label + ': '  + (value ? value : 0)"
         [disableTooltip]="!label"
    >
        <i class="fal {{icon}} me-1" *ngIf="icon" aria-hidden="true"></i>
        <app-value-evaluator [value]="value" [target]="target"></app-value-evaluator>
    </div>
    <div class="d-flex w-100">
        <input type="number"
               *ngIf="editmode"
               style="min-width: 2rem;"
               [class.p-0]="fullWidth"
               [placeholder]="oldValue ? oldValue : ''"
               [class.text-success]="showColors && (target && value >= target)"
               [class.text-warning]="showColors &&(target && value < target)"
               min="0"
               class="numeric-input form-control text-center text-normal input-padding ms-auto flex-shrink-1 flex-grow-0"
               [autofocusAfterInit]="true"

               (blur)="triggerBlur();"
               (focus)="triggerFocus();"
               [(ngModel)]="displayValue"
               (ngModelChange)="changed($event)"
        />
    </div>
</div>
