import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {EventEmitter} from "@angular/core";
import {
    EditProjectListFilter
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListFilter";
import {Project} from "@app/core/models";
import {
    EditProjectListValidator
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListValidator";

export class EditProjectListConfiguration extends BaseEditorConfiguration {

    public label: string;
    public filter: EditProjectListFilter;
    public validator: EditProjectListValidator;

    public onChangeEvent = new EventEmitter();
    public onProjectAddedEvent = new EventEmitter<Project>();
    public onProjectRemovedEvent = new EventEmitter<Project>();

    constructor(label: string, filter: EditProjectListFilter,
                validator: EditProjectListValidator) {
        super();
        this.label = label;
        this.filter = filter;
        this.validator = validator;
    }

}
