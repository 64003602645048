import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {Milestone} from "@app/core/models";

export class NonArchivedFilter extends BaseOnScreenFilter<Milestone> {

    // Præsenteret som "Fuldført med omvendt ikon"
    public name = '_ui_completed';
    public iconActive = 'fa-ban';
    public iconInactive = 'fa-check-circle';
    public invertIcon: boolean = false;

    protected validate(item: Milestone): boolean {
        return item.archived_id == 0;
    }

}
