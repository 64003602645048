/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TaskStatusTypeDefinition} from './definitions/TaskStatusTypeDefinition';

export class TaskStatusType extends TaskStatusTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
