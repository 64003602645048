import {BaseComparison} from "@app/shared/_ui/lists/BaseComparison";
import {HasTitle} from "@app/interfaces/HasTitle";

export class TitleComparison extends BaseComparison {

    public compare(a: HasTitle, b: HasTitle): number {
        return a.title.localeCompare(b.title);
    }

}
