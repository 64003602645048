<ng-template #rt let-r="result" let-t="term" class="w-100"
             [typedTemplate]="resultTemplateType"
>
    <div class="d-flex flex-row align-items-center" style="min-width: 300px;" *ngIf="r.title">
        <app-color-label [status]="r.status" class="circle m-0 me-1"></app-color-label>
        <ngb-highlight [result]="r.title" [term]="t"></ngb-highlight>
    </div>
</ng-template>


<ul class="list-group"
    [class.is-invalid]="!configuration?.validator.isValid()"
>

    <li *ngIf="configuration && !configuration.validator.isValid()"
        class="list-group-item d-flex justify-content-between flex-row align-items-center validation-error"
    >
        <app-validation-warning-list [inline]="true" [validatorError]="configuration.validator"></app-validation-warning-list>
    </li>
    <li class="list-group-item">
        <div class="form-group mb-0">

            <div class="d-flex align-items-center">
                <div class="me-1 btn "
                     *ngIf="showFilterButton"
                     [class.btn-outline-secondary]="departments.length == 0"
                     [class.btn-outline-warning]="departments.length > 0"
                     [ngbTooltip]="('_ui_filter' | translate)"
                     (click)="popover.open()"
                >
            <span class="fal fa-filter cursor-pointer position-relative"
                  [ngbPopover]="popoverContent"
                  [autoClose]="'outside'"
                  [placement]="['bottom', 'left', 'auto']"
                  triggers="manual"
                  container="body"
                  #popover="ngbPopover"
            >
                    </span>
                    <ng-template #popoverContent>
                        <app-department-picker [showAll]="true"
                                               [selectedDepartments]="departments"
                                               [multiple]="true"
                                               [showDepartmentGroups]="true"
                                               (onChangeEvent)="filterByDepartment($event)"></app-department-picker>
                    </ng-template>
                </div>

                <div class="search-wrap d-flex  flex-grow-1">
                    <div class="input-group">
                        <input type="search" class="form-control placeholder-sm placeholder-sm"
                               autocomplete="off"
                               #instance="ngbTypeahead"
                               #searchInput
                               container="body"
                               popupClass="result-list"
                               [class.is-invalid]="searchFailed || invalid"
                               [(ngModel)]="model"
                               [ngbTypeahead]="search"
                               [resultTemplate]="rt"
                               [class.inputSearching]="searching"
                               [inputFormatter]="formatter"
                               (focus)="focus$.next($any($event.target).value)"
                               (click)="click$.next($any($event.target).value)"
                               (selectItem)="triggerSelection($event, instance);"

                               [placeholder]="placeholder ? placeholder : ('_ui_search_placeholder' | translate: {type: typeLabel})"/>
                        <button class="btn btn-outline-primary" type="button" (click)="createField()"
                                *ngIf="allowCreation"><i
                            class="fa fa-plus" aria-hidden="true"></i></button>
                    </div>

                    <app-loading-indicator [showLogo]="false" *ngIf="searching" class="searching"></app-loading-indicator>
                </div>
                <div class="invalid-feedback" *ngIf="searchFailed">{{('_ui_search_no_results' | translate)}}.</div>
                <div class="invalid-feedback" *ngIf="invalid">{{('_ui_is_required' | translate)}}.</div>
            </div>
        </div>

    </li>

    <li class="list-group-item" *ngFor="let c of projectsOfType()" >
        <!-- TODO: Vis tilknyttede opgaver / MP -->
        <app-card-project
            [model]="c"
            [mini]="true"
            [configuration]="projectConfiguration"
            [removable]="true"
            (onRemove)="removeItem($event)"
            [editable]="c.id == 0"
            [interactive]="c.id != 0"></app-card-project>
    </li>
</ul>
