<!-- Template  til popover -->
<ng-template #popContent>

    <ul
        class="list-unstyled">

        <li class="border-bottom  pb-2  mb-1"
            [class.border-bottom]="items && items.length > 0">
            <app-user-search
                [className]="'form-control-sm'"
                [selectedUsers]="selectedUsers"
                [showDeleteButton]="true"
                [autoReset]="true"
                (valueChanged)="createNewCaseWithUser($event)"
                (click)="$event.stopPropagation()"></app-user-search>
        </li>

        <ng-container *ngFor="let item of items; let index = index">

            <li class="d-flex flex-row align-items-center justify-content-end mb-1">

                <ng-container *ngFor="let user of item.item.tasks_users">
                    <!-- Vis ikke opretter https://podio.com/klartboard/softwareudvikling/apps/stories/items/1413 -->
                    <app-user-with-icon style="width: 150px;" class="ellipsis"
                                        *ngIf="user.task_user_type_id != Constants.TaskUserTypes.Creator"
                                        [model]="user.user"></app-user-with-icon>
                </ng-container>

                <i class="fal fa-edit cursor-pointer -text-success mb-1 me-1" (click)="openCase(item)"></i>

                <app-numeric-spinner (onChange)="changeCasesPlanned($event, item)"
                                     [label]="('_ui_case_planned' | translate)"
                                     [fullWidth]="true"
                                     [value]="item.item.cases_planned"
                                     class="ps-1 pe-1 me-1 -flex-fill w-50px"></app-numeric-spinner>
                <app-numeric-spinner (onChange)="changeCasesReached($event, item)"
                                     [label]="('_ui_case_reached' | translate)"
                                     [fullWidth]="true"
                                     [value]="item.item.cases_reached"
                                     [target]="item.item.cases_planned ? item.item.cases_planned : 0"
                                     class="ps-1 pe-1 -flex-fill w-50px"></app-numeric-spinner>


            </li>
        </ng-container>

        <li class="d-flex flex-row  font-weight-bold border-top pt-2 pb-2 mt-1">
            <span style="width: 150px;"></span>
            <span class="w-50px text-center ps-1 pe-1" style="font-size: 70%;" >{{'_ui_case_planned' | translate}}</span>
            <span class="w-50px text-center ps-1 pe-1" style="font-size: 70%;" >{{'_ui_case_reached' | translate}}</span>
        </li>
        <li class="d-flex flex-row  font-weight-bold border-bottom pt-2 pb-2">
            <span style="width: 150px;">Total</span>
            <app-value-evaluator
                [badge]="false"
                [value]="totalPlanned"
                [ngbTooltip]="('_ui_case_planned' | translate)"
                class="ps-1 pe-1 -flex-fill w-50px  text-center "></app-value-evaluator>

            <app-value-evaluator
                [showColors]="false"
                [value]="totalReached"
                [target]="totalPlanned ? totalPlanned : 0"
                [ngbTooltip]="('_ui_case_reached' | translate)"
                class="ps-1 pe-1 -flex-fill w-50px text-center"></app-value-evaluator>
        </li>


    </ul>

    <button class="btn btn-sm btn-success w-100" style="max-width: unset;" type="button" (click)="popover.close()"
            >{{'_ui_close' | translate}}
    </button>

</ng-template>

<!-- Liste -->
<div class="position-absolute w-100 h-100"
     style="pointer-events: none;"
     [ngbPopover]="popContent"
     [placement]="['bottom', 'auto']"
     [autoClose]="false"
     container="body"
     triggers="manual"
     #popover="ngbPopover"
></div>
<div class="w-100 ellipsis scroll-x"
     (click)="popover.open()"
>

    <div class="d-flex flex-column justify-content-start">
        <div class="text-large d-flex w-100 pt-2 justify-content-start flex-row align-items-start">
            <app-value-evaluator
                [badge]="true"
                [value]="totalPlanned"
                [ngbTooltip]="('_ui_case_planned' | translate)"
                *ngIf="showValues"
                class="cursor-default ps-1 pe-1 text-center mb-1"></app-value-evaluator>

            <app-value-evaluator
                [badge]="true"
                [showColors]="true"
                [value]="totalReached"
                [target]="totalPlanned ? totalPlanned : 0"
                *ngIf="showValues"
                [ngbTooltip]="('_ui_case_reached' | translate)"
                class="cursor-default ps-1 pe-1 mb-1 text-center"></app-value-evaluator>
        </div>

        <ul class="list-unstyled cursor-pointer d-flex flex-row flex-wrap pe-2 pt-0 ">
            <app-card-case-user *ngFor="let item of items" [item]="item"></app-card-case-user>
        </ul>
    </div>

</div>
