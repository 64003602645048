<app-card-expander
    class="w-100 d-block"
    [items]="items"
    [itemCount]="itemCount"
    [attr.data-item-count]="itemCount"
    [isLoadingEvent]="isLoadingEventEmitter"
    [offset]="offset"
    (onShowMore)="loadMore()"
    (onShowAll)="loadAll()"
    (onShowLess)="loadLess()"
    [showCreateNew]="configuration.getShowCreateNewButton()"
    [draggable]="configuration.isDraggable()"
    [listDragInterface]="configuration"
    [allowDragEnter]="configuration ? configuration?.getAllowDragEnter() : true"
    [listConfiguration]="configuration"
    [dataSetChanged]="dataSetChanged"
    [listClass]="listClass"
    [scrollContainer]="scrollContainer"
></app-card-expander>
