<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title" [innerHTML]="title"></h4>
        <app-color-item class="cursor-pointer me-2 ms-auto"
                        *ngIf="escClose"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <div class="modal-body p_0">
        <div class="container p-2" *ngIf="!showTextInput">
            <div [innerHTML]="message"></div>
        </div>


        <div class="container p-2 form-group" *ngIf="showTextInput">
            <label [innerHTML]="message" for="title"></label>
            <input id="title" class="form-control" autocomplete="off" type="text" [(ngModel)]="description"
                   [autofocusAfterInit]="true" autofocus>
        </div>


    </div>
    <div class="modal-footer">
        <button type="button" role="button" class="btn btn-secondary ellipsis flex-shrink-0" (click)="decline()"
                *ngIf="btnCancelText">{{ btnCancelText }}</button>
        <button type="button" role="button" class="btn ellipsis {{primaryClass}}" ngbAutofocus
                (click)="accept()">{{ btnOkText }}</button>
    </div>
</div>
