/**
 * Created by ModelParser
 * Date: 17-05-2021.
 * Time: 07:56.
 */
import {CategoriesTaskDefinition} from './definitions/CategoriesTaskDefinition';

export class CategoriesTask extends CategoriesTaskDefinition {

    constructor(json?: any) {
        super(json);
    }

}
