import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import Globals from '@app/constants';
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {FilterGlobalSettings} from "@app/services/FilterGlobalService/FilterGlobalSettings";
import {FilterGlobalVisibility} from "@app/services/FilterGlobalService/FilterGlobalVisibility";
import {BaseComponent} from "@app/shared/_system/base/base.component";

@Component({
    selector: 'app-filter-status',
    templateUrl: './filter-status.component.html',
    styleUrls: ['./filter-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterStatusComponent extends BaseComponent implements OnInit {

    public settings: FilterGlobalSettings;
    public visibility: FilterGlobalVisibility;
    public statuses: number[] = [];
    public isSortAsc: boolean;

    constructor(private filterGlobalService: FilterGlobalService, private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.render();

        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(() => {
            this.statuses = (this.settings.activeStatuses.length == 0) ? [ ... Globals.StatusTypesAll ] : this.settings.activeStatuses;
            this.render();
        }));
        this.subscribe(this.filterGlobalService.onVisibilityChangeEvent.subscribe(() => this.render()));
    }

    private render() {
        this.settings = this.filterGlobalService.getActiveSettings();
        this.visibility = this.filterGlobalService.getVisibilitySettings();
        // this.statuses = (this.settings.activeStatuses.length == 0) ? Globals.StatusTypesAll : this.settings.activeStatuses;
        this.isSortAsc = this.settings.activeSortDirection == 'asc';
        this.detectChanges();
    }

    public toggleAllStatuses() {
        this.statuses = this.statuses.length == Globals.StatusTypesAll.length ? [] : [...Globals.StatusTypesAll];
        this.detectChanges();
    }

    public toggleStatus(number: number) {
        if (this.statuses.includes(number)) {
            this.statuses.splice(this.statuses.indexOf(number), 1);
        } else {
            this.statuses.push(number);
        }
        this.detectChanges();
        // this.filterGlobalService.toggleStatus(number);
    }

    public setStarred(value: boolean) {
        this.filterGlobalService.setStarred(value);
    }

    public setHandUp(value: boolean) {
        this.filterGlobalService.setHandUp(value);
    }

    public setSorting(isAsc: boolean) {
        this.filterGlobalService.setActiveSortDirection(isAsc ? 'asc' : 'desc');
    }

    public statusActive(status: number) {
        // return this.settings.activeStatuses.includes(status);
        return this.statuses?.includes(status);
    }

    activateFilters(){
        this.filterGlobalService.setActiveStatuses(this.statuses);
    }
    reset (){
        this.statuses = [ ... Globals.StatusTypesAll];
        this.activateFilters();
    }

}
