import {NgModule} from '@angular/core';
import {LoginComponent} from '@app/pages/login/login.component';
import {ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';
import {LoadingIndicatorModule} from '@app/shared/_ui/loading-indicator/LoadingIndicator.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        LoadingIndicatorModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        LoginComponent,
    ]
})
export class LoginModule {
}
