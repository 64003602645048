import {Milestone} from '@app/core/models/Milestone';
import {PhasesProject} from '@app/core/models/PhasesProject';
import {YearWheelColumnConfiguration} from '@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration';
import {Project, Task} from '@app/core/models';

export abstract class BaseYearWheelItem<T = Project | PhasesProject | Milestone | Task> {
    configuration: YearWheelColumnConfiguration;
    item: T;

    left: number;
    width: number;

    unitStart: number;
    unitEnd: number;
    visible: boolean;

    startDate: Date;
}
