import {Injectable} from '@angular/core';
import {CacheService} from '@app/services/cache.service';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor() {
    }

    set(key: string, data: any): void {
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (err) {
            if (CacheService.isQuotaExceededError(err)) {
                // Handle the case where there wasn't enough space to store the item in localStorage.
                console.warn('Not able to store data: ',err, key)
            } else {
                // Handle the case where the localStorage API is not supported.
            }
        }

    }

    get(key: string): any | null {
        if (key) {
            try {
                return JSON.parse(localStorage.getItem(key));
            } catch (e) {
                console.error('Error getting data from localStorage', e);
                return null;
            }
        }
    }

}
