export class CountRunner {

    private started = false;
    private count: number;
    private runner: () => void;

    constructor(count = 0) {
        this.setCount(count);
    }

    public setCount(value: number): CountRunner {
        this.count = value + 1; // Add 1 for start
        return this;
    }

    public incrementCount() {
        this.count++;
    }

    public setRunner(value: (() => void)): CountRunner {
        this.runner = value;
        return this;
    }

    public start() {
        this.started = true;
        this.tick();
    }

    public tick() {
        if (--this.count <= 0 && this.started) {
            this.execute();
        }
    }

    private execute() {
        this.started = false;
        this.runner();
    }

}
