import {environment} from '@env/environment';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {throwError} from 'rxjs';
import {BaseService} from '../services/base.service';
import {APIResponseInterface} from '../core/APIResponse';
import {versions} from "@env/versions";
import Helpers from "@app/core/helpers";

export class ApiCall extends BaseService {

    endpoint: string = null;
    callback: any;
    params: HttpParams;

    constructor(endpoint: string,
                httpParams?: HttpParams,
                callback?: (response: any) => void
    ) {
        super();
        this.endpoint = endpoint;
        this.callback = callback;
        this.params = httpParams;
    }

    public get(): any {
        const token = this.authService.getAuthorizationToken();

        if(!token){
            console.error('No token! ', token)
        }
        if(!this.params){
            this.params = new HttpParams();
        }
        this.params = this.params.append('access_token', token);
        this.params = this.params.append('app_version', versions.npm_version);

        const startDate: Date = new Date();

        const uri = Helpers.encodeUri(this.endpoint);
        return this.httpClient
        // .cache()
            .get(`${environment.apiPath}${uri}`,
                //{headers: this.authService.getAuthorizationHeader(), params: this.params})
                {params: this.params})
            // .pipe(shareReplay(1))
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return throwError(err);
                })
            ).subscribe(
            (data: APIResponseInterface) => {
                // console.log('APICall.ts : get() :: Server time : ', (new Date().getTime() - startDate.getTime()), 'ms.', ' : response : ', data, ' uri : ', this.endpoint);
                return this.callback(data);

            },
            (err: any) => {
                console.error('getTags: ERROR');
            }
        )
    }

    public getHttp(ignoreProgressBar: boolean = false) {
        const token = this.authService.getAuthorizationToken();
        if(!token) {
            console.error('No token! ', token);
        }
        if(!this.params) {
            this.params = new HttpParams();
        }

        const startDate: Date = new Date();

        this.params = this.params.append('access_token', token);
        this.params = this.params.append('app_version', versions.npm_version);
        const uri = this.endpoint;
        let options: {params: any, headers?: any} = {
            params: this.params
        };
        if(ignoreProgressBar) options.headers = new HttpHeaders({'ignoreProgressBar': ''});

        return this.httpClient
            .get(`${environment.apiPath}${uri}`, options)
            // .pipe(shareReplay(1))
            .pipe(
                map((response:APIResponseInterface) => {
                    // console.log('APICall.ts : getHttp() :: Server time : ', (new Date().getTime() - startDate.getTime()), 'ms.', ' : response : ', response, ' uri : ', this.endpoint);
                    return this.callback ? this.callback(response) : response;
                }),
                catchError((err: HttpErrorResponse) => {
                    console.error('getHttp() : Error : ', err);
                    return throwError(err);
                })
            );
    }

    public post(data: any, ignoreProgressBar: boolean = false) {
        const token = this.authService.getAuthorizationToken();
        if(!token) console.error('No token! ', token);
        const startDate: Date = new Date();

        if(!this.params) this.params = new HttpParams();
        this.params = this.params.append('access_token', token);
        this.params = this.params.append('app_version', versions.npm_version);

        let options: {params: any, headers?: any} = {
            params: this.params
        };
        if(ignoreProgressBar) options.headers = new HttpHeaders({'ignoreProgressBar': ''});



        return this.httpClient
            .post(`${environment.apiPath}${this.endpoint}`, data, options)
            .pipe(
                map((response:APIResponseInterface) => {
                    // console.log('APICall.ts : post() :: Server time : ', (new Date().getTime() - startDate.getTime()), 'ms.', ' : response : ', data, ' uri : ', this.endpoint);
                    return this.callback ? this.callback(response) : response;
                }),
                catchError((err: HttpErrorResponse) => {
                    return throwError(err);
                })
            );
    }

    public put(data: any, ignoreProgressBar: boolean = false) {
        const token = this.authService.getAuthorizationToken();
        if(!token) console.error('No token! ', token);

        if(!this.params) this.params = new HttpParams();
        this.params = this.params.append('access_token', token);
        this.params = this.params.append('app_version', versions.npm_version);

        let options: {params: any, headers?: any} = {
            params: this.params
        };
        if(ignoreProgressBar) options.headers = new HttpHeaders({'ignoreProgressBar': ''});

        return this.httpClient
            .put(`${environment.apiPath}${this.endpoint}`, data, options)
            .pipe(
                map((response:APIResponseInterface) => {
                    return this.callback ? this.callback(response) : response;
                }),
                catchError((err: HttpErrorResponse) => {
                    return throwError(err);
                })
            );
    }

    public patch(data: any, ignoreProgressBar: boolean = false) {
        const token = this.authService.getAuthorizationToken();
        if(!token) console.error('No token! ', token);

        if(!this.params) this.params = new HttpParams();
        this.params = this.params.append('access_token', token);
        this.params = this.params.append('app_version', versions.npm_version);

        let options: {params: any, headers?: any} = {
            params: this.params
        };
        if(ignoreProgressBar) options.headers = new HttpHeaders({'ignoreProgressBar': ''});

        return this.httpClient
            .patch(`${environment.apiPath}${this.endpoint}`, data, options)
            .pipe(
                map((response:APIResponseInterface) => {
                    return this.callback ? this.callback(response) : response;
                }),
                catchError((err: HttpErrorResponse) => {
                    return throwError(err);
                })
            );
    }

    public delete(ignoreProgressBar: boolean = false) {
        const token = this.authService.getAuthorizationToken();
        if(!token) console.error('No token! ', token);

        if(!this.params) this.params = new HttpParams();
        this.params = this.params.append('access_token', token);
        this.params = this.params.append('app_version', versions.npm_version);

        let options: {params: any, headers?: any} = {
            params: this.params
        };
        if(ignoreProgressBar) options.headers = new HttpHeaders({'ignoreProgressBar': ''});

        return this.httpClient
            .delete(`${environment.apiPath}${this.endpoint}`, options)
            .pipe(
                map((response:APIResponseInterface) => {
                    return this.callback ? this.callback(response) : response;
                }),
                catchError((err: HttpErrorResponse) => {
                    return throwError(err);
                })
            );
    }


}
