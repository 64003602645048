import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Color} from "@app/core/models";
import {Api} from '@app/core/http/Api/Api';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit, OnChanges {

    @Input() colors: Color[] = [];
    @Output() onToggle = new EventEmitter<Color>();
    @Input() tooltipTitle: string;

    @Input() public selectedColors: Color[] = [];
    @Input() public showBadge = false;              // Display counter as badge
    @Input() public showPicker = true;              // Display picker
    @Input() public editable = true;                // Display picker, and make colors removeable
    @Input() public visible = true;                 // Display  colors with badges
    @Input() public multiple = true;

    constructor() {
    }

    ngOnInit(): void {
        if(!this.colors || this.colors.length == 0){
            Api.colors().get().find(c => this.colors = c);
        }
    }

    toggleColor(color: Color) {
        this.onToggle.emit(color);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.selectedColors){
            this.selectedColors = changes.selectedColors.currentValue;
        }
    }
}
