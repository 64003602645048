import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {TodoListColumn} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/TodoListColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns";

export class TodoListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TodoList;

    public column: TodoListColumn;

}
