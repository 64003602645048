import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";

export class DragDropHelper {

    public static CardItemDropEvent(event: CdkDragDrop<ListDragInterface>) {
        if (event.container.data.isSortingEnabled || event.previousContainer !== event.container) {
            const prevListDragInterface = event.previousContainer.data;
            const nextListDragInterface = event.container.data;
            const presListConfiguration = prevListDragInterface.configuration.getListConfigurationForDragDropEvent(event.item.data);
            nextListDragInterface.allowCardItemDrop(event.item.data, prevListDragInterface.configuration, (allow) => {
                if (allow) {
                    prevListDragInterface.onCardItemDragRemove(event.item.data);
                    nextListDragInterface.onCardItemDragAdd(event.item.data, presListConfiguration, () => {
                        nextListDragInterface.onCardItemDragSaved();
                        if (nextListDragInterface !== prevListDragInterface) {
                            prevListDragInterface.onCardItemDragSaved();
                        }
                    }, event.previousIndex, event.currentIndex);
                }
            });
        }
    }

}
