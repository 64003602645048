import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {YearWheelColumnConfiguration} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration";
import {YearWheelColumn} from "@app/pages/displays/display-projects/Columns/YearWheelColumn";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";

export class YearWheelCell extends GenericCell {

    public yearWheelConfiguration = new YearWheelColumnConfiguration();

    column: YearWheelColumn;

    constructor(row: ProjectsDisplayRow, column: YearWheelColumn) {
        super(row, column);

        this.yearWheelConfiguration
            .setProject(row.project.item)
            .setProjectStart(row.project.item.findProjectsDeadlineByType(column.getProjectDeadlineTypeStartSetting()))
            .setProjectEnd(row.project.item.findProjectsDeadlineByType(column.getProjectDeadlineTypeEndSetting()));
    }

}
