import {Widths} from "@app/constants";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {TemplateRef} from "@angular/core";
import {ColumnTypes} from "@app/pages/displays/display-projects/ColumnTypes";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {ProjectStatusListColumn} from "@app/pages/displays/display-projects/Columns/ProjectStatusListColumn";

export class ProjectStatusListColumnType extends BaseColumnType {

    public identifier = ColumnTypes.ProjectStatusList;

    public isAlwaysVisible = false;

    public customWidth?: number;
    public minWidth = Widths.DeadlineColumn;
    public maxWidth?: number;

    public resizeable = true;
    public frozenLeft = false;

    public canAutoResize = false;
    public cellClass?: string;

    public isList = false;

    public constructor(cellTemplate?: TemplateRef<any>, headerTemplate?: TemplateRef<any>) {
        super();
        this.cellTemplate = cellTemplate;
        this.headerTemplate = headerTemplate;
    }

    public createColumn(column: Column, settings: Map<string, ColumnSetting>): GenericColumn {
        return new ProjectStatusListColumn(this, column, settings);
    }

}
