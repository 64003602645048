import {NgModule} from "@angular/core";
import {TextAreaComponent} from "@app/shared/_forms/text-area/text-area.component";
import {QuillModule} from "ngx-quill";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        QuillModule,
        FormsModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TextAreaComponent,
    ],
    exports: [
        TextAreaComponent

    ],
    providers: [

    ],
})
export class TextAreaModule {}
