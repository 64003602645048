import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Deadline, StatusRule, Task, TaskType, User} from "@app/core/models";
import {Field} from "@app/editor/task-editor-loader/task-editor.service";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {ValidatorCollection} from "@app/editor/Validation/ValidatorCollection";
import {
    EditUserListConfiguration
} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserListConfiguration";
import {UserTypeItem} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserTypeItem";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {FieldItem} from "@app/editor/base-editor-v3/helpers/FieldItem";
import {
    EditDeadlineListConfiguration
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineListConfiguration";
import {
    EditUseStatusRulesConfiguration
} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/EditUseStatusRulesConfiguration";
import {Fields} from "@app/editor/task-editor-loader/Fields";
import {DeadlineItem} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/DeadlineItem";
import {TaskDeadlineTypes, TaskUserTypes} from "@app/constants";
import {EditUserTypeValidator} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserTypeValidator";
import {
    EditDeadlineTypeValidator
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineTypeValidator";

@Component({
    selector: 'app-task-editor-tabs-users',
    templateUrl: './task-editor-tabs-users-component.html',
    styleUrls: ['./task-editor-tabs-users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskEditorTabsUsersComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item: Task;
    @Input() taskType: TaskType;
    @Input() fields: Map<number, Field>;
    @Output() validatorChange = new EventEmitter<ValidationErrorInterface>();

    // Bindings to view
    public isReady = false;

    // Bindings to view: Deadlines
    public deadlineListLabel: string;
    public deadlineListFieldItem: FieldItem;
    public deadlineListEditorConfiguration: EditDeadlineListConfiguration;
    public isAutoMoveUserDeadlinesEnabled = false;

    // Bindings to view: Status Rules
    public statusRulesFieldItem: FieldItem;
    public statusRules: StatusRule[];

    // Bindings to view: Use Status Rules
    public useStatusRulesEditorConfiguration: EditUseStatusRulesConfiguration;

    // Bindings to view: Users
    public userListEditorConfiguration: EditUserListConfiguration;

    // Data
    private validator: ValidationErrorInterface;

    constructor(
        private cd: ChangeDetectorRef,
        private dialogService: BaseDialogService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.setupEditors();
        this.setupValidator();
    }

    private setupEditors() {
        this.deadlineListLabel = this.fields.get(Fields.BasicInformation.Deadlines)?.smartName ?? '';
        this.deadlineListFieldItem = this.getFieldItem(Fields.BasicInformation.Deadlines);
        this.deadlineListEditorConfiguration = new EditDeadlineListConfiguration(
            '',
            this.taskType.task_deadline_types_task_types
                ?.filter(taskDeadlineTypesTaskType => {
                    return taskDeadlineTypesTaskType.visible && taskDeadlineTypesTaskType.task_deadline_type?.exists();
                })
                ?.sort((a, b) => a.index_ - b.index_)
                ?.map(taskDeadlineTypesTaskType => {
                    const deadlineItem =  new DeadlineItem(
                        taskDeadlineTypesTaskType.task_deadline_type.getSmartName(),
                        taskDeadlineTypesTaskType.task_deadline_type_id,
                        false,
                        new EditDeadlineTypeValidator(taskDeadlineTypesTaskType.required)
                    );
                    this.subscribe(deadlineItem.onDateChangedEvent.subscribe(event => {
                        this.onDeadlineChanged(taskDeadlineTypesTaskType.task_deadline_type_id, event.after);
                    }));
                    return deadlineItem;
                }) ?? []
        );

        this.statusRulesFieldItem = this.getFieldItem(Fields.BasicInformation.StatusRules);
        this.statusRules = this.taskType.task_types_status_rules
            ?.map(value => {
                const defaultDeadlineType = this.taskType.task_deadline_types?.find(dt => dt.id === value.task_deadline_type_id);
                value.status_rule = new StatusRule(value.status_rule);
                if (defaultDeadlineType) {
                    value.status_rule.defaultDeadlineName = defaultDeadlineType.getSmartName();
                }
                return value.status_rule;
            }) ?? [];

        this.useStatusRulesEditorConfiguration = new EditUseStatusRulesConfiguration();

        this.userListEditorConfiguration = new EditUserListConfiguration(
            '',
            this.taskType.task_types_task_user_types
                ?.filter(taskTypesTaskUserType => taskTypesTaskUserType.visible && taskTypesTaskUserType.task_user_type?.exists())
                // Sort: Start with participant, then follow normal index rules
                ?.sort((a, b) => {
                    const aIndex = a.task_user_type_id == TaskUserTypes.Participant ? -1 : a.index_;
                    const bIndex = b.task_user_type_id == TaskUserTypes.Participant ? -1 : b.index_;
                    return aIndex - bIndex;
                })
                ?.map(taskTypesTaskUserType => {
                    const item = new UserTypeItem(
                        taskTypesTaskUserType.task_user_type.title,
                        taskTypesTaskUserType.task_user_type_id,
                        taskTypesTaskUserType.multiple,
                        taskTypesTaskUserType.multiple,
                        taskTypesTaskUserType.task_user_type_id == TaskUserTypes.Participant,
                        taskTypesTaskUserType.task_user_type_id == TaskUserTypes.Participant,
                        taskTypesTaskUserType.task_user_type_id == TaskUserTypes.Participant,
                        new EditUserTypeValidator(taskTypesTaskUserType.required)
                    );
                    this.subscribe(item.onUserDeadlineChangeEvent.subscribe(userItem => {
                        this.onUserDeadlineChanged(userItem.user, userItem.deadline?.getDate());
                    }));
                    this.subscribe(item.onUserAddedEvent.subscribe(userItem => {
                        this.onUserAdded(taskTypesTaskUserType.task_user_type_id, userItem.user);
                    }));
                    return item;
                }) ?? []
        );

        const deadlineDate = this.item.findDeadlineByTypeId(TaskDeadlineTypes.Normal)?.getDate()?.getTime();
        const participants = this.item.findEnhancedUsersByTypeId(TaskUserTypes.Participant);
        const hasDeadlineButNoParticipants = deadlineDate !== undefined && participants.length == 0;
        const hasParticipantsButNoDeadline = participants.length > 0 && deadlineDate === undefined;
        const hasDeviantParticipantDeadline = participants.some(user => user.deadline?.getDate()?.getTime() !== deadlineDate);
        this.isAutoMoveUserDeadlinesEnabled = hasDeadlineButNoParticipants || hasParticipantsButNoDeadline || !hasDeviantParticipantDeadline;
    }

    private setupValidator() {
        // Collect validators
        this.validator = new ValidatorCollection([
            ...this.deadlineListEditorConfiguration.types?.map(type => type.validator),
            ...this.userListEditorConfiguration.types?.map(type => type.validator),
        ]);
        this.validatorChange.emit(this.validator);

        this.isReady = true;
    }

    private getFieldItem(fieldId: number): FieldItem {
        return new FieldItem(
            this.fields.get(fieldId)?.visible ?? false,
            this.fields.get(fieldId)?.index ?? 0,
            this.fields.get(fieldId)?.smartName ?? '',
        );
    }

    // <editor-fold desc="View actions">

    public onToggleIsAutoMoveUserDeadlinesEnabled(): void {
        this.isAutoMoveUserDeadlinesEnabled = !this.isAutoMoveUserDeadlinesEnabled;
        if (this.isAutoMoveUserDeadlinesEnabled) {
            const taskDeadline = this.item.findDeadlineByTypeId(TaskDeadlineTypes.Normal);
            if (taskDeadline) {
                this.overwriteUserDeadlines(taskDeadline?.getDate());
            }
        }
    }

    public onDeadlineChanged(typeId: number, date?: Date): void {
        if (typeId == TaskDeadlineTypes.Normal && date && this.isAutoMoveUserDeadlinesEnabled) {
            this.overwriteUserDeadlines(date);
        }
    }

    public onUserAdded(typeId: number, user: User): void {
        if (typeId == TaskUserTypes.Participant) {
            const deadline = this.item.findDeadlineByTypeId(TaskDeadlineTypes.Normal) ?? Deadline.Today();
            this.item.setUserDeadline(user, deadline.getDate());
        }
    }

    public onUserDeadlineChanged(user: User, date?: Date): void {
        this.isAutoMoveUserDeadlinesEnabled = false;
    }

    // </editor-fold>

    // <editor-fold desc="Dialogs">

    // </editor-fold>

    private overwriteUserDeadlines(date?: Date) {
        this.item.findTasksUsersByType(TaskUserTypes.Participant)
            .forEach(tasksUser => {
                this.item.setUserDeadline(tasksUser.user, date);
            });
    }

}
