import {NgModule} from "@angular/core";
import {SidebarComponent} from "@app/shell/sidebar/sidebar.component";
import {TranslateModule} from "@ngx-translate/core";
import {GlobalSearchModule} from "@app/shared/_elements/global-search/GlobalSearch.module";
import {DropdownAppendToBodyDirectiveModule} from "@app/directives/DropdownAppendToBodyDirective.module";
import {NgbDropdownModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {UserDashboardMenuItemModule} from "@app/shell/sidebar/UserDashboardMenuItem.module";
import {RouterModule} from "@angular/router";
import {DisplayListModule} from "@app/shell/header/display-list/DisplayList.module";
import {
    MilestonePlanListModule
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-list/MilestonePlanList.module";
import {FullscreenToggleModule} from "@app/shared/_elements/fullscreen-toggle/FullscreenToggle.module";
import {DynamicMenuItemsModule} from "@app/shared/_ui/dynamic-menu-items/DynamicMenuItems.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        GlobalSearchModule,
        DropdownAppendToBodyDirectiveModule,
        NgbTooltipModule,
        UserDashboardMenuItemModule,
        RouterModule,
        DisplayListModule,
        MilestonePlanListModule,
        FullscreenToggleModule,
        DynamicMenuItemsModule,
        NgbDropdownModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        SidebarComponent,
    ],
    exports: [
        SidebarComponent

    ],
    providers: [

    ],
})
export class SidebarModule {}
