import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Department} from '@app/core/models/Department';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {User} from '@app/core/models/User';
import {ApiFilter} from '@app/http/APIFilter';

@Component({
    selector: 'app-department-users-picker',
    templateUrl: './department-users-picker.component.html',
    styleUrls: ['./department-users-picker.component.scss'],
})
export class DepartmentUsersPickerComponent extends BaseDisplayComponent implements OnInit {

    public departments: Department[];
    @Output() valueChanged = new EventEmitter<{ users: User[], department: Department }>();

    constructor() {
        super();
    }

    ngOnInit() {
        Department.GetAll(departments => this.departments = departments);
    }

    selectDepartment(department: Department) {
        // Load brugere fra afdeling
        let apiFilter = new ApiFilter();
        this.usersService.department$(department.id, apiFilter).subscribe(value => {
            this.valueChanged.emit({users: value, department: department});
        });
    }
}
