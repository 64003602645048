import {EventEmitter} from "@angular/core";

export class WidthSynchronizer {

    lastValue?: number;
    onValueChangeEvent = new EventEmitter<number>();

    setValue(value: number) {
        this.lastValue = value;
        this.onValueChangeEvent.emit(value);
    }

}
