<ng-container *ngIf="!mini">
<ul
    class="list-unstyled d-flex -justify-content-between w-100 flex-row m-0"
    [class.mini]="mini"
>
    <ng-container *ngFor="let statusType of statusTypes">
        <li *ngIf="!readonly || readonly && statusType.id == internalValue"
            [class.active]="multiple ? internalValue.indexOf(statusType.id) !== -1 : statusType.id == internalValue"
            [class.cursor-disabled]="readonly"
            (click)="setActive(statusType.id)"
            [@changeState]="multiple ? internalValue.indexOf(statusType.id) !== -1 : statusType.id == internalValue ? 'open' : 'closed'"
            class="toggle-item {{statusType.className}} rounded me-1 text-center "
        >
            <i [class.fa-check]="multiple ? internalValue.indexOf(statusType.id) !== -1 : statusType.id == internalValue"
               class="far text-light "></i>
        </li>
    </ng-container>
</ul>
</ng-container>


<ng-container *ngIf="mini">
    <!-- popover version -->
    <div [ngbPopover]="popoverContent"
         [autoClose]="'outside'"
         [placement]="['bottom', 'left', 'auto']"
         triggers="manual"
         container="body"
         #popover="ngbPopover">

    <app-color-label class="card-type app-color-label"
                     *ngIf="item && !multiple"
                     [status]="internalValue"
                     [itemWithTypeProperties]="item"
                     [interactive]="false"
                     (click)="popover.open()"></app-color-label>

    <ul
        class="list-unstyled d-flex -justify-content-between w-100 flex-row m-0"
        [class.mini]="mini"
        (click)="popover.open()"
        *ngIf="!item || multiple"

    >
        <ng-container *ngFor="let statusType of statusTypes">
            <li *ngIf="statusType.id == internalValue"
                [class.active]="multiple ? internalValue.indexOf(statusType.id) !== -1 : statusType.id == internalValue"
                [class.cursor-disabled]="readonly"


                [@changeState]="multiple ? internalValue.indexOf(statusType.id) !== -1 : statusType.id == internalValue ? 'open' : 'closed'"
                class="toggle-item {{statusType.className}} rounded me-1 text-center "
            >
                <i [class.fa-check]="multiple ? internalValue.indexOf(statusType.id) !== -1 : statusType.id == internalValue"
                   class="far text-light"></i>
            </li>
        </ng-container>
    </ul>


    <ng-template #popoverContent>
    <ul
        class="list-unstyled d-flex -justify-content-between w-100 flex-row"
        [class.m-0]="mini"
        [class.mini]="mini"
        (click)="popover.close()"
    >
        <ng-container *ngFor="let statusType of statusTypes">
            <li *ngIf="!readonly || readonly && statusType.id == internalValue"
                [class.active]="multiple ? internalValue.indexOf(statusType.id) !== -1 : statusType.id == internalValue"
                [class.cursor-disabled]="readonly"
                (click)="setActive(statusType.id)"
                class="toggle-item {{statusType.className}} rounded me-1 text-center h-100"
            >
                <i [class.fa-check]="multiple ? internalValue.indexOf(statusType.id) !== -1 : statusType.id == internalValue"
                   class="far text-light "></i>
            </li>
        </ng-container>
    </ul>
    </ng-template>
    </div>
</ng-container>
