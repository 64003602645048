<ng-container *ngFor="let item of items">
    <div class="d-flex flex-row align-items-center -w-100 ellipsis">
        <div class="d-flex align-items-center" [ngbTooltip]="item.toolTip">
            <app-color-item *ngIf="item.color" [color]="item.color" class="me-1"></app-color-item>
            <div class="text-capitalize-firstletter ellipsis me-1">
                {{item.name}}
            </div>
        </div>
    </div>
</ng-container>
