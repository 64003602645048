import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {
    UserListEditorComponent
} from "@app/editor/quick-editor/editors/generic/user-list-editor/user-list-editor.component";
import {UserSearchModule} from "@app/shared/_forms/search/user-search/UserSearch.module";
import {DepartmentUsersPickerModule} from "@app/shared/_forms/department-users-picker/DepartmentUsersPicker.module";
import {
    UserListEditorRowComponent
} from "@app/editor/quick-editor/editors/generic/user-list-editor/user-list-editor-row/user-list-editor-row.component";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {DatepickerModule} from "@app/shared/_forms/datepicker/Datepicker.module";
import {
    ValidationWarningListModule
} from '@app/editor/base-editor-v3/components/validation-warning-list/validation-warning-list.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ValidationWarningListModule,
        FormsModule,
        UserSearchModule,
        DepartmentUsersPickerModule,
        ToggleItemModule,
        NgbTooltip,
        DatepickerModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        UserListEditorComponent,
        UserListEditorRowComponent,
    ],
    exports: [
        UserListEditorComponent

    ],
    providers: [

    ],
})
export class UserListEditorModule {}
