import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";
import {ProjectNoteColumn} from "@app/pages/displays/display-projects/Columns/ProjectNoteColumn";

export class ProjectNoteCell extends GenericCell {

    public column: ProjectNoteColumn;

    public exportCSV(secondary?: any): CSVCellValue {
        return this.row.project.item.getText(this.column.getField())
            .replace('<br>', "\r\n\r\n")
            .replace(/<[^>]*>/g, '');
    }

}
