import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Shell} from '@app/shell/Shell';
import {extract} from '@app/core';
import {TasksComponent} from '@app/pages/lists/tasks/tasks.component';
import {MilestonesComponent} from '@app/pages/lists/milestones/milestones.component';
import {ToDosComponent} from '@app/pages/lists/to-dos/to-dos.component';
import {ProjectsComponent} from '@app/pages/lists/projects/projects.component';
import {ProjectsModule} from "@app/pages/lists/projects/projects.module";
import {TasksModule} from "@app/pages/lists/tasks/Tasks.module";
import {MilestonesModule} from "@app/pages/lists/milestones/Milestones.module";
import {ToDosModule} from "@app/pages/lists/to-dos/ToDos.module";
import {RestoreModule} from "@app/pages/lists/restore/restore.module";
import {RestoreComponent} from "@app/pages/lists/restore/restore.component";

const routes: Routes = [
    Shell.childRoutes([

        {
            path: 'projects/list/:displayMode',
            component: ProjectsComponent,
            pathMatch: 'full',
            data: {
                title: extract('Projekter'),
                animation: 'Page',
            }
        },
        {
            path: 'projects/list',
            component: ProjectsComponent,
            pathMatch: 'full',
            data: {
                title: extract('Projekter'),
                animation: 'Page',
            }
        },


        // Lazy load : https://angular.io/guide/lazy-loading-ngmodules

        /*
        {
            path: 'projects/list',
            pathMatch: 'full',
            data: {
                title: extract('Projekter')
            }
        },
        */
        {
            path: 'tasks/list/:displayMode',
            component: TasksComponent,
            pathMatch: 'full',
            data: {
                title: extract('Opgaver'),
                animation: 'Page',
            }
        },
        {
            path: 'tasks/list',
            component: TasksComponent,
            pathMatch: 'full',
            data: {
                title: extract('Opgaver'),
                animation: 'Page',
            }
        },
        {
            path: 'milestones/list/:displayMode',
            component: MilestonesComponent,
            pathMatch: 'full',
            data: {
                title: extract('Milepæle'),
                animation: 'Page',
            }
        },
        {
            path: 'milestones/list',
            component: MilestonesComponent,
            pathMatch: 'full',
            data: {
                title: extract('Milepæle'),
                animation: 'Page',
            }
        },
        {
            path: 'to-dos/list',
            component: ToDosComponent,
            pathMatch: 'full',
            data: {
                title: extract('To-Do'),
                animation: 'Page',
            }
        },

        {
            path: 'restore/list',
            component: RestoreComponent,
            pathMatch: 'full',
            data: {
                title: extract('Restore'),
                animation: 'Page',
            },
        },
    ])
];

@NgModule({
    imports: [
        ProjectsModule,
        TasksModule,
        MilestonesModule,
        ToDosModule,
        RestoreModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
    providers: []
})
export class ListsRoutingModule { }
