/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ProjectStatusDefinition} from './definitions/ProjectStatusDefinition';
import {ProjectStatusTypes, StatusTypes} from '@app/constants';
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";

export class ProjectStatus extends ProjectStatusDefinition {

    constructor(json?: any) {
        if (json && !json.status_id) {
            json.status_id = StatusTypes.GREEN;
        }
        super(json);
    }

    public static Create(projectStatusTypeId: number, statusId?: number): ProjectStatus {
        const item = new ProjectStatus();
        item.id = 0;
        item.status_id = statusId ?? StatusTypes.GREEN;
        item.project_status_type_id = projectStatusTypeId;
        return item;
    }

    public static createDefault() {
        let item = new ProjectStatus();
        item.id = 0;
        item.status_id = StatusTypes.GREY;
        item.project_status_type_id = ProjectStatusTypes.Normal;
        return item;
    }

    public print(): string {
        switch (this.status_id) {
            default:
            case StatusTypes.NOT_DEFINED:
            case StatusTypes.GREY:
                return `${this.project_status_type.name}: grå`;
            case StatusTypes.GREEN:
                return `${this.project_status_type.name}: grøn`;
            case StatusTypes.YELLOW:
                return `${this.project_status_type.name}: gul`;
            case StatusTypes.RED:
                return `${this.project_status_type.name}: rød`;
        }
    }

    public name(): string {
        const translateService = AppInjector.getInjector().get(TranslateService);
        switch (this.status_id) {
            case StatusTypes.GREY:
                return translateService.instant('grey');//'Grå';
            case StatusTypes.GREEN:
                return translateService.instant('green');//'Grøn';
            case StatusTypes.YELLOW:
                return translateService.instant('yellow');//'Gul';
            case StatusTypes.RED:
                return translateService.instant('red');//'Rød';
            default:
                return '?';
        }
    }

}
