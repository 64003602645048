import {CSVExportOptionInterface} from "@app/export/csv/CSVExportOptionInterface";

export class CSVExportOptions {

    public static Name = 'Name';
    public static Deadline = 'Deadline';
    public static Identifier = 'Identifier';

    public static createNameOption(): CSVExportOptionInterface {
        return {type: this.Name};
    }

    public static createDeadlineOption(): CSVExportOptionInterface {
        return {type: this.Deadline};
    }

    public static createIdentifierOption(identifier: string): CSVExportOptionInterface {
        return {type: this.Identifier, value: identifier};
    }

}
