import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {GenericColumn} from "@app/pages/displays/display-team/Columns/GenericColumn";
import {DisplayTeamRow} from "@app/pages/displays/display-team/DisplayTeamRow";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {ProjectFetcher, ProjectFetchRequest} from "@app/shared/_ui/lists/project-list/ProjectFetcher";
import {ProjectListCell} from "@app/pages/displays/display-team/Cells/ProjectListCell";
import {ProjectListTableColumn} from "@app/pages/displays/display-team/TableColumns/ProjectListTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-team/Helpers/ColumnTypeSettings";
import {Team_ProjectListProjectTypesSettingValue, Team_TaskListTaskTypesSettingValue} from "@app/core/http/Api/Api";
import * as moment from "moment";

export class ProjectListColumn extends GenericColumn<ProjectListCell> implements ColumnDataFetcherInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: ProjectFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new ProjectFetcher(column.name);
    }

    public createTableColumns(): ProjectListTableColumn[] {
        const item = new ProjectListTableColumn(this);
        this.setTableColumnDefaults(item);
        return [item];
    }

    public createCell(row: DisplayTeamRow): ProjectListCell {
        const cell = new ProjectListCell(row, this);

        cell.projectListConfiguration
            .setProjectTypeIds(this.getProjectTypeIds());

        this.dataFetcher.addRequest(new ProjectFetchRequest(cell.projectListConfiguration));
        return cell;
    }

    public getProjectTypeIds(): number[] {
        return this.settings.get(ColumnTypeSettings.ProjectList_ProjectTypes)
            ?.getObject<Team_ProjectListProjectTypesSettingValue>()
            ?.projectTypeIds ?? [];
    }

}
