import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {
    DisplayExpandButtonComponent
} from "@app/shared/_elements/display-expand-button/display-expand-button.component";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DisplayExpandButtonComponent,
    ],
    exports: [
        DisplayExpandButtonComponent

    ],
    providers: [

    ],
})
export class DisplayExpandButtonModule {}
