import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {DeadlineItem} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/DeadlineItem";

export class EditDeadlineListConfiguration extends BaseEditorConfiguration {

    public labelName: string;
    public types: DeadlineItem[];

    constructor(labelName: string, types: DeadlineItem[]) {
        super();
        this.labelName = labelName;
        this.types = types;
    }

}
