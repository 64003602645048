/**
 * Created by ModelParser
 */
import {LoginAttemptType} from '../LoginAttemptType';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class LoginAttemptDefinition extends BaseModel {
    login_attempt_type_id?: number;
    LoginAttempt_type?: LoginAttemptType;
    user_id?: number;
    user?: User;
    email?: string;
    ip?: string;
    attempts?: number;
    provider?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.login_attempt_type_id;
            delete this.LoginAttempt_type;
            delete this.user_id;
            delete this.user;
            delete this.email;
            delete this.ip;
            delete this.attempts;
            delete this.provider;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.login_attempt_type_id != null) {
            this.login_attempt_type_id = data.login_attempt_type_id;
        }
        if (data.LoginAttempt_type != null) {
            this.LoginAttempt_type = new LoginAttemptType(data.LoginAttempt_type);
        }
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.email != null) {
            this.email = data.email;
        }
        if (data.ip != null) {
            this.ip = data.ip;
        }
        if (data.attempts != null) {
            this.attempts = data.attempts;
        }
        if (data.provider != null) {
            this.provider = data.provider;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
