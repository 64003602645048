import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgbTypeahead, NgbTypeaheadConfig, NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {merge, Observable, of, OperatorFunction, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {User} from '@app/core/models/User';
import {fadeAnimation} from '@app/animations';
import {BaseFormControlComponent} from '@app/shared/_forms/base-form-control/base-form-control.component';
import {Department, Todo} from "@app/core/models";
import {isArray} from "ngx-bootstrap/chronos";

@Component({
    selector: 'app-user-search',
    templateUrl: './user-search.component.html',
    styleUrls: ['../base-search/base-search.component.scss'],
    providers: [
        NgbTypeaheadConfig,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserSearchComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => UserSearchComponent),
            multi: true,
        }
    ],
    animations: [fadeAnimation],
    changeDetection: ChangeDetectionStrategy.Default, // TODO: Changedetection
})
export class UserSearchComponent extends BaseFormControlComponent implements OnInit, OnChanges {

    @Input() multiple: boolean;
    @Input() clearInputOnBlur: boolean = true;
    @Input() autoFocus: boolean = false;
    @Input() removeOnBlur: boolean = false;
    @Input() className: string;
    @Input() placeholder: string;
    @Input() invalid: boolean = false;
    @Input() autoReset: boolean = false;
    @Input() replaceSelection: boolean = true;
    @Input() toggle: boolean = false;

    @Input() showDepartment: boolean = true;
    @Input() showDeleteButton = false;
    @Input() showPopoverDetails = false;
    @Input() replacementSearch = true;
    @Input() showFilterButton = false;

    @Input() selectedUsers: any[] = []; // Find hvilke der er valgt
    @Output() itemSelected = new EventEmitter<any>();
    @Output() itemRemoved = new EventEmitter<any>();
    @Output() valueChanged = new EventEmitter<any>();

    @ViewChild('instance', {static: true}) instance: NgbTypeahead;
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    model: any;
    searching = false;
    searchFailed = false;

    result: User[];
    public departments: Department[] = [];
    private searchedUsers: User[];
    private allUsers: User[] = [];
    public focused: boolean = false;

    constructor(private _cdr: ChangeDetectorRef) {
        super();
        this.cdr = _cdr;
    }

    ngOnInit() {
        if (!this.multiple && this.selectedUsers) {
            this.updateInputField();
        }
        this.usersService.getUsers((users) => {
            this.allUsers = users.sort((a, b) => a.name.localeCompare(b.name)).filter(u => u.user_group_id >= 10 && !u.hidden);
            this.updateSelectedUsersObject();
        });
    }

    ngOnChanges(changes: any) {
        super.ngOnChanges(changes);
        if (changes['selectedUsers']) {
            this.updateSelectedUsersObject();
            this.updateInputField();
        }
    }

    private updateSelectedUsersObject() {
        if (this.allUsers) {
            this.selectedUsers?.forEach((u, _index) => {
                if (u && (!u.name || u?.name == '') && u.id) {
                    const _usr = this.allUsers.find(au => au.id == u.id);
                    if (_usr) {
                        this.selectedUsers[_index] = _usr;
                    }
                }
            })
        }
    }

    public userExists(userId: number): boolean {
        return this.selectedUsers && this.Helpers.itemExists(this.selectedUsers, userId);
    }

    search: OperatorFunction<string, readonly User[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map(term => {
                const searchTerm = term ? String(term).toLowerCase() : '';
                const selectedDepartments = this.departments?.map(d => d.id);

                const itemResult = this.allUsers
                    .filter((user: User) => {
                        // Filter department, any department of user matches any selected departments
                        const userDeparmentIds = user.departments?.map(d => d.id) || [];
                        return !this.departments || this.departments.length == 0 || userDeparmentIds.some(r => selectedDepartments.indexOf(r) >= 0)
                    })
                    .filter((user: User) => {
                        // Filter by name or initials
                        return !searchTerm || searchTerm == '' || user.name.toLowerCase().includes(searchTerm) || user.initials.toLowerCase().includes(searchTerm)
                    })
                    .filter((user: User) => {
                        if (this.selectedUsers && !this.toggle) {
                            return !this.Helpers.itemExists(this.selectedUsers, user.id); // Udlæs ikke de valgte brugere
                        } else {
                            return user;
                        }
                    });

                this.searchedUsers = itemResult;
                this.result = itemResult;
                return itemResult;
            })
        );
    };

    formatter = (x: User) => `${x.first_name} ${x.last_name}`;

    triggerSelection($event: NgbTypeaheadSelectItemEvent, input: any) {
        let ignoreReset = false;
        if (this.toggle) {
            if (this.userExists($event.item.id)) {
                this.itemRemoved.emit($event.item);
                if (this.replaceSelection) {
                    if (!this.multiple) {
                        this.selectedUsers = [];
                        this.updateInputField();
                    }
                    ignoreReset = true;
                } else {
                    this.selectedUsers = this.selectedUsers.filter(u => u.id != $event.item.id);
                    this.updateInputField();
                }
                input.value = '';
                $event.preventDefault(); // Nulstil

            } else {
                this.itemSelected.emit($event.item);
                if (this.replaceSelection) {
                    if (!this.multiple) {
                        this.selectedUsers = [$event.item];
                        this.updateInputField();
                    }
                } else {
                    this.selectedUsers.push($event.item);
                    this.updateInputField();
                }
            }

        } else {
            // console.log('triggerSelection() : itemSelected.emit()', $event.item, this.selectedUsers);
            this.itemSelected.emit($event.item);
        }


        if (!this.autoReset) {
            if (!ignoreReset) {
                this.internalValue = $event.item;
            }
        } else {
            this.internalValue = this._internalValue = null;
            this.searchInput.nativeElement.value = '';
            $event.preventDefault(); // Nulstil
        }
        this.detectChanges();
        //this.selectedUser = $event.item;
        //$event.preventDefault(); // Nulstil
        //input.value = '';

        this.propagateChange(this.internalValue); // TO-DO
        // console.log('triggerSelection() : this.internalValue : ', this.internalValue);

        this.valueChanged.emit($event.item);

        // TO-DO: Trigger toggle / renmove
    }

    onBlurEvent(value: any) {
        this.focused = false;
        if (this.removeOnBlur && !value) {
            this.itemSelected.emit(null);
        }
        this.updateInputField();
    }

    updateInputField() {
        if (!this.clearInputOnBlur) {
            if (!this.multiple) {
                const user = this.selectedUsers && this.selectedUsers.length > 0 ? this.selectedUsers[0] : null;
                if (user) {
                    if (!this.selectedUsers || this.selectedUsers.length == 0) {
                        this.internalValues = null;
                    } else {
                        if (this.selectedUsers && this.selectedUsers.length > 0) {
                            this.internalValues = user || null;
                        }
                    }
                } else {
                    this.internalValue = null;
                }
            } else {
                // TO-DO: Implement list of user names
            }
        } else {
            // this.internalValue = null;
        }
    }

    clickCheck() {
        if (!this.multiple) {
            this._internalValue = null;
        }
    }

    removeControl() {
        const removedItems: User[] = isArray(this._internalValue) ? [...this._internalValue] : [this._internalValue];
        this.internalValue = this._internalValue = null;
        this.propagateChange(this.internalValue); // TO-DO
        if (this.replaceSelection) {
            this.selectedUsers = [];
        }
        this.valueChanged.emit(null);
        this.updateInputField();
        removedItems.forEach(item => this.itemRemoved.emit(item));
    }

    filterByDepartment($event: Department[]) {
        this.departments = $event;
    }

    resetClick() {
        this.removeControl();
        this.searchInput.nativeElement.focus();
        this.clickCheck();
        this.click$.next('');
        this.focus$.next('')
    }

    clickItem($event: MouseEvent) {
        const target: any = $event.target;
        this.click$.next(target?.value || '');
    }

    focusItem($event: FocusEvent) {
        this.focused = true;
        const target: any = $event.target;
        if (target && target.value)
            this.focus$.next(target)
    }

    resultTemplateType: {
        result: User,
        term: string,
    }

}
