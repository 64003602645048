/**
 * Created by ModelParser
 * Date: 17-05-2021.
 * Time: 07:40.
 */
import {CategoryTypesTaskTypeDefinition} from './definitions/CategoryTypesTaskTypeDefinition';

export class CategoryTypesTaskType extends CategoryTypesTaskTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
