<app-emoji-picker *ngIf="showEmojiPicker"
                  [emojiItems]="emojiItems"
                  (addReactionEvent)="addEmojiReaction($event);"
                  (removeReactionEvent)="removeEmojiReaction($event);"
                  [visible]="reactionsVisible"
                  [editable]="reactionsEditable"
                  [toggleUsers]="reactionsToggleUsers"
                  [showUserCounter]="reactionsShowUserCounter"
                  [showPicker]="reactionsPickerVisible"
                  [showBadge]="reactionShowBadge"
></app-emoji-picker>
