import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {extract} from '@app/core';
import {UnauthorizedComponent} from '@app/pages/unauthorized/unauthorized.component';
import {UnauthorizedModule} from "@app/pages/unauthorized/unauthorized.module";

const routes: Routes = [
    {
        path: 'unauthorized',
        component: UnauthorizedComponent,
        data: {
            title: extract('Unauthorized'),
            animation: 'Login',
        }
    },
];

@NgModule({
    imports: [
        UnauthorizedModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
    providers: []
})
export class UnauthorizedRoutingModule {
}
