import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {extract} from '@app/core';
import {LoginComponent} from '@app/pages/login/login.component';
import {AutoLoginComponent} from '@app/pages/login/auto-login/auto-login.component';
import {LoginModule} from "@app/pages/login/login.module";

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: {
            title: extract('Login'),
            animation: 'Login',
        }
    },
    {
        path: 'autologin',
        component: AutoLoginComponent,
        data: {
            title: extract('Login'),
            animation: 'Login',
        }
    }
];

@NgModule({
    imports: [
        LoginModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
    providers: []
})
export class LoginRoutingModule {
}
