import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {
    CategoryPickerItem
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/CategoryPickerItem";

export class EditCategoryPickerListConfiguration extends BaseEditorConfiguration {

    public labelName: string;
    public types: CategoryPickerItem[];

    constructor(labelName: string, types: CategoryPickerItem[]) {
        super();
        this.labelName = labelName;
        this.types = types;
    }

}
