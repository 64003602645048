import {NgModule} from "@angular/core";
import {
    ProjectPhaseSearchComponent
} from "@app/shared/_elements/project-phase-search/project-phase-search.component";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {NgbDatepickerModule, NgbTooltipModule, NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";

@NgModule({
    imports: [
        CommonModule,
        ColorItemModule,
        NgbTypeaheadModule,
        TranslateModule,
        ToggleItemModule,
        NgbTooltipModule,
        FormsModule,
        NgbDatepickerModule,
        TypedTemplateDirective

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectPhaseSearchComponent,
    ],
    exports: [
        ProjectPhaseSearchComponent

    ],
    providers: [

    ],
})
export class ProjectPhaseSearchModule {}
