import {GenericCell} from "@app/pages/displays/display-project-details/subdisplay-milestones/Cells/GenericCell";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-milestones/Row";
import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/TaskEditColumn";
import {SubDisplayHelper} from "@app/pages/displays/display-project-details/SubDisplayHelper";
import {ListTypes, StatusTypes, TaskDeadlineTypes, TaskStatusTypes, TaskUserTypes} from "@app/constants";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskMilestonePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskMilestonePresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {
    TaskDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDeadlinePresetGenerator";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";

export class TaskEditCell extends GenericCell {

    public listConfiguration = new TaskListConfiguration();
    public column: TaskEditColumn;

    constructor(row: Row, column: TaskEditColumn) {
        super(row, column);

        const taskTypeIdsFilteredByProjectType = row.project.project_type?.project_types_task_types
            ?.filter(projectTypesTaskType => projectTypesTaskType.visible)
            ?.map(projectTypesTaskType => projectTypesTaskType.task_type_id) ?? [];

        this.listConfiguration
            .setLimit(1000) // Show all!
            .setShowProjectMiniCard(false)
            .setAcceptanceStatus(null)
            .setProject(row.project)
            .setMilestone(row.milestoneCard.item)
            .setTaskTypeIds(taskTypeIdsFilteredByProjectType)
            .setUseGlobalFilter(true)
            .setCustomOnCardItemDragAddFunction((cardItem, fromListConfiguration, previousIndex?: number, nextIndex?: number) => {
                SubDisplayHelper.DragDropUpdateMilestone(fromListConfiguration, this.listConfiguration, cardItem, previousIndex, nextIndex);
            })
            .setCustomOnCardDragSavedFunction(() => row.saveTaskIndexes())
            .setPrepareCreateItemSourceHook(createItemSourceHook => createItemSourceHook.showEditor = false)
            .setCardEditExpander_enabledEditors(column.enabledEditors)
            .setDraggable(true)
            .setListType(ListTypes.EditList)
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                // Defaults
                new TaskUserPresetGenerator(TaskUserTypes.Creator, AppInjector.getInjector().get(UsersService).user.id),
                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                new TaskUseStatusRulesPresetGenerator(true),

                new TaskProjectPresetGenerator(row.project.id),
                new TaskMilestonePresetGenerator(row.milestoneCard.item.id),
                ...(row.project.departments?.map(department => new TaskDepartmentPresetGenerator(department.id)) ?? []),
                ...(row.milestoneCard.item.deadline ? [new TaskDeadlinePresetGenerator(
                    TaskDeadlineTypes.Normal,
                    row.milestoneCard.item.deadline.getDate(),
                    row.milestoneCard.item.deadline.is_soft
                )] : []),
                ...row.milestoneCard.item.responsible ? [new TaskUserPresetGenerator(
                    TaskUserTypes.Participant,
                    row.milestoneCard.item.responsible.id,
                    row.milestoneCard.item.deadline
                )] : [],
            ]);
    }

}
