import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {AppointmentListConfiguration} from "@app/shared/_ui/lists/appointment-list/AppointmentListConfiguration";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";

export class AppointmentListCell extends BaseCell {

    public listConfiguration = new AppointmentListConfiguration();

    public constructor(row: Row, column: BaseColumn) {
        super(row, column);

        this.listConfiguration
            .setProject(row.projectCard.item)
            .setLimit(2)
            .setOrderBy([['updated', 'desc'], ['created', 'desc']]);
    }

}
