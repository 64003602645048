import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgbTypeahead, NgbTypeaheadConfig, NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {Observable, OperatorFunction, Subject, Subscription} from 'rxjs';
import {AppInjector} from '@app/services/app-injector.service';
import {BaseFormControlComponent} from '@app/shared/_forms/base-form-control/base-form-control.component';
import {Appointment} from "@app/core/models";

@Component({
    selector: 'app-base-search',
    templateUrl: './base-search.component.html',
    providers: [
        NgbTypeaheadConfig,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaseSearchComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => BaseSearchComponent),
            multi: true,
        }]
})
export class BaseSearchComponent extends BaseFormControlComponent implements OnInit, OnDestroy {

    protected itemSubscription: Subscription;

    @Output() itemSelected = new EventEmitter<any>();
    @Output() itemAdded = new EventEmitter<any>();
    @Output() itemRemoved = new EventEmitter<any>();
    @Input() multiple: boolean = true;
    @Input() allowCreation: boolean = true;


    @ViewChild('instance') instance: NgbTypeahead;

    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    searching = false;
    searchFailed = false;
    model: any;

    result: any[] = [];


    constructor() {
        super();
        const injector = AppInjector.getInjector();
        let config: NgbTypeaheadConfig = injector.get(NgbTypeaheadConfig);
        // https://ng-bootstrap.github.io/#/components/typeahead/examples
        //config.placement = 'bottom';
        //config.container = 'body';

    }

    ngOnInit() {
    }
    ngOnDestroy() {
        //super().ngOnDestroy();
        if(this.itemSubscription){
            this.itemSubscription.unsubscribe();
        }
    }


    /* Overskriv */
    triggerSelection($event: NgbTypeaheadSelectItemEvent, input: any) {
        this.itemSelected.emit($event.item);
        $event.preventDefault();
        input.value = '';
        this.addItem($event.item)
    }

    /* Overskriv */
    addItem(c: any) {
        if(!this._internalValue || !this.multiple) {
            this._internalValue = [];
        }
        this.itemAdded.emit(c);
        this._internalValue.push(c);
        this.onChange(this._internalValue); // Value is good!
        this.propagateChange(this.internalValue);
    }

    /* Overskriv */
    removeItem(c: any) {
        var index = this._internalValue.findIndex((p:any) => p.id == c.id);
        if (index > -1) {
            this._internalValue.splice(index, 1);
        }
        this.itemRemoved.emit(c);
        this.onChange(this._internalValue); // Value is good!
        this.propagateChange(this.internalValue);
    }

    /* Overskriv */
    createField() {
        //const c:AnyItem = new AnyItem({id: 0, project_type_id: this.modelType[0]});
        //this.addItem(c);
    }

    /* Overskriv */
    search: OperatorFunction<string, readonly any[]> = (text$): any => {

    }

    /* Overskriv */
    formatter = (x: any) => x.title;


    resultTemplateType: {
        result: any,
        term: string,
    }


}
