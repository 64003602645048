import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ScreenfullService} from '@ngx-extensions/screenfull';
import {map} from 'rxjs/operators';
import {BaseComponent} from "@app/shared/_system/base/base.component";

@Component({
    selector: 'app-fullscreen-toggle',
    templateUrl: './fullscreen-toggle.component.html',
    styleUrls: ['./fullscreen-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullscreenToggleComponent extends BaseComponent implements OnInit {

    readonly mode$: Observable<string>;

    constructor(public readonly screenFullService: ScreenfullService) {
        super();
        this.mode$ = this.screenFullService.fullScreenActive$.pipe(
            map(active => (active ? 'active' : 'inactive'))
        );
    }

    public requestFullscreen() {
        this.screenFullService.request();
    }
    public exitFullscreen() {
        this.screenFullService.exit();
    }

    ngOnInit(): void {

    }

    toggleFullScreen() {
        if(this.screenFullService.isFullScreenModeActive) {
          this.exitFullscreen();
        } else {
          this.requestFullscreen();
        }
    }
}
