import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";

export class CSVColumn {
    identifier: any;
    column: BaseColumn;
    name: string;
    subColumns: CSVColumn[];

    public static CreatePrimary(column: BaseColumn, subColumns?: CSVColumn[]): CSVColumn {
        const item = new CSVColumn();
        item.column = column;
        item.identifier = column.column.id;
        item.name = column.column.name ?? '';
        item.subColumns = subColumns;
        return item;
    }

    public static CreateSecondary(identifier: any, name: string): CSVColumn {
        const item = new CSVColumn();
        item.identifier = identifier;
        item.name = name;
        return item;
    }

}
