export class ColumnTypeSettings {

    public static TaskEdit_Fields = 'task-edit-fields';
    public static TaskEdit_Deadline_DeadlineType = 'task-edit-deadline-deadline-type';

    public static MilestoneEdit_Fields = 'milestone-edit-fields';

    public static TaskList_Status_Colors = 'task-list-status-colors';
    public static TaskList_Period_Types = 'task-list-period-types'

}
