/**
 * Created by ModelParser
 */
import {Language} from '../Language';
import {LanguageKey} from '../LanguageKey';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class LanguageValueDefinition extends BaseModel {
    language_id?: number;
    language?: Language;
    language_key_id?: number;
    language_key?: LanguageKey;
    value?: string;
    default?: string;
    key?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.language_id;
            delete this.language;
            delete this.language_key_id;
            delete this.language_key;
            delete this.value;
            delete this.default;
            delete this.key;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.language_id != null) {
            this.language_id = data.language_id;
        }
        if (data.language != null) {
            this.language = new Language(data.language);
        }
        if (data.language_key_id != null) {
            this.language_key_id = data.language_key_id;
        }
        if (data.language_key != null) {
            this.language_key = new LanguageKey(data.language_key);
        }
        if (data.value != null) {
            this.value = data.value;
        }
        if (data.default != null) {
            this.default = data.default;
        }
        if (data.key != null) {
            this.key = data.key;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
