<app-card-expander
        *ngIf="configuration"
        class="w-100 d-block"
        [items]="items"
        [itemCount]="itemCount"
        [attr.data-item-count]="itemCount"
        [isLoadingEvent]="isLoadingEventEmitter"
        [offset]="offset"
        (onShowMore)="loadMore()"
        (onShowAll)="loadAll()"
        (onShowLess)="loadLess()"
        [showCreateNew]="configuration?.getShowCreateNewButton()"
        (onDragStarted)="onDragStarted.emit($event)"
        (onDragEnded)="onDragEnded.emit($event)"
        [draggable]="configuration?.isDraggable()"
        [listDragInterface]="configuration"
        [listConfiguration]="configuration"
        [allowDragEnter]="configuration ? configuration?.getAllowDragEnter() : true"
        [scrollContainer]="scrollContainer"
        [dataSetChanged]="dataSetChanged"
        [listClass]="listClass"></app-card-expander>
<div class="text-small alert alert-warning position-absolute" *ngIf="!configuration"><i class="fa fa-warning me-1" aria-hidden="true"></i> Liste mangler konfiguration!</div>
