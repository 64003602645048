import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {EventEmitter} from "@angular/core";
import {EditNumberValidator} from "@app/editor/quick-editor/editors/generic/number-editor/EditNumberValidator";

export class EditNumberConfiguration extends BaseEditorConfiguration {

    public prop: string;
    public label: string;
    public validator: EditNumberValidator;

    public onChangeEvent = new EventEmitter();

    constructor(
        prop: string,
        label: string,
        validator: EditNumberValidator,
    ) {
        super();
        this.prop = prop;
        this.label = label;
        this.validator = validator;
    }

}
