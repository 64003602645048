import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input,
    OnChanges,
    OnInit, Output,
    SimpleChanges
} from '@angular/core';
import {User} from '@app/core/models/User';
import {AnyUserType} from '@app/interfaces/AnyUserType';
import {Archived, TasksUser} from "@app/core/models";
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {Api} from "@app/core/http/Api/Api";

@Component({
    selector: 'app-users-with-icon',
    templateUrl: './users-with-icon.component.html',
    styleUrls: ['./users-with-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersWithIconComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() users: User[] = [];
    @Input() tasksUsers: TasksUser[];
    @Input() userType: AnyUserType;
    @Input() icon = true;
    @Input() initials = false;
    @Input() fullName = true;
    @Input() showAcceptanceStatus = false;
    @Input() limit = 3;
    @Output() onArchived = new EventEmitter<{tasksUser: TasksUser, isArchived: boolean}>();

    // Bindings to view
    public acceptanceStatus: number = null;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.updateAcceptanceStatus();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateAcceptanceStatus();
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }

    get initialsString(): string {
        return this.users.map((user) => {
            try {
                return (user && user?.initialsConverter) ? user.initialsConverter : '?';
            } catch (e) {
                console.error(e);
            }
        }).slice(0, this.limit).join(' ');
    }

    get fullNameString(): string {
        return this.users.map(user => {
            return user && user.name ? user.name : '';
        }).slice(0, this.limit).join(', ');
    }

    get firstNameString(): string {
        return this.users.map(user => user.first_name).slice(0, this.limit).join(', ');
    }

    private updateAcceptanceStatus() {
        if (this.tasksUsers && this.showAcceptanceStatus) {
            this.acceptanceStatus = null;
            let targetStatus = null;

            if (this.tasksUsers) {
                if (this.tasksUsers.length ==
                    this.tasksUsers.filter(tu => tu.acceptance_status === this.Constants.TasksUserAcceptanceStatus.Approved).length
                ) {
                    targetStatus = this.Constants.TasksUserAcceptanceStatus.Approved;
                }

                const newStatusItems = this.tasksUsers.filter(tu => tu.acceptance_status === this.Constants.TasksUserAcceptanceStatus.New);
                if (this.tasksUsers.length == newStatusItems.length || targetStatus == null
                ) {
                    targetStatus = this.Constants.TasksUserAcceptanceStatus.New;
                }

                if (this.tasksUsers.filter(tu => tu.acceptance_status === this.Constants.TasksUserAcceptanceStatus.Declined).length > 0
                ) {
                    targetStatus = this.Constants.TasksUserAcceptanceStatus.Declined;
                }

                this.acceptanceStatus = targetStatus;
            }

        } else {
            this.acceptanceStatus = null;
        }

        this.render();
    }

    lastShown: Date;
    lastHidden: Date;

    recordShown() {
        this.lastShown = new Date();
    }

    recordHidden() {
        this.lastHidden = new Date();
    }

    render() {
        setTimeout(() => {
            this.detectChanges();
        });
    }

    public toggleCompleted(tasksUser: TasksUser) {
        this.onArchived.emit({tasksUser: tasksUser, isArchived: !tasksUser.isArchived});
    }
}
