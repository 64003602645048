<ul
    class="list-unstyled d-flex flex-row justify-content-between m-0 w-100 align-items-center gap-2"
>
    <li>
        <app-generic-vertical-edit-collection
            *ngIf="isReady"
            cssClasses="align-items-center gap-2"
            [cardItem]="cardItem"
            [enabledEditors]="editors"
        />
    </li>

    <!-- Copy -->
    <li class="ps-2 border-start">
        <app-toggle-item
            (click)="onCopyBtnClicked()"
            [ngbTooltip]="('_ui_copy' | translate) + '...'"
            [iconWeightActive]="'fal'"
            [icon]="'fa-copy'"
            class="cursor-pointer"
        ></app-toggle-item>
    </li>

    <li
        class="pe-1 d-flex"
        *ngIf="showValidationWarning && validator">
        <app-validation-warning-list
            [inline]="false"
            [validatorError]="validator"
        />
    </li>

    <!-- Milestone Plans -->
    <li class="pe-1">
        <app-milestone-plan-selector
            [project]="item"
            (onMilestonePlanAppliedEvent)="onMilestonePlanAppliedEvent($event)"
        />
    </li>

    <!-- Go to planning -->
    <li class="pe-1 me-2 pe-1 border-end d-mobile-none"
        *ngIf="showGoToPlanningBtn"
    >
        <app-toggle-item
            class="cursor-pointer"
            [interactive]="false"
            [active]="true"
            [icon]="'fa-door-open'"
            [ngbTooltip]="'_project_goto_planning' | translate"
            container="body"
            iconWeightActive="fal"
            (click)="onOpenDisplayBtnClicked()"
        />
    </li>

    <!-- Create item / Task types -->
    <li class="-pe-1">
        <app-create-item-dropdown
            [source]="createItemSourceConfiguration"
            [createItemPreset]="createItemPreset"
            [placement]="'auto'"
            [container]="'body'"
            [label]="('_global_create' | translate) + '...' "
        />
    </li>

    <li class="pe-1">
        <app-find-and-focus-btn
            [matchType]="FocusableEntityTypes.Project"
            [matchIdentifier]="item.id"
        />
    </li>

</ul>
