<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'" *ngIf="message">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title">{{title}}</h4>

        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <div class="container">
        <p class="pt-2">{{description}}</p>

        <ng-container *ngIf="showErrorLog">
            <hr class="mt-1 mb-1"/>

            <div *ngIf="!message.message" class="p-1 d-flex align-items-center text-small mb-1">
                <i class="fa-regular fa-cloud-exclamation me-1 text-black-50"></i>
                <span>{{ message }}</span>

            </div>
            <code class="error scroll-y text-danger" *ngIf="message.message">
                <i class="fal fa-times"></i>
                <span *ngIf="message.message">{{ message.message | json}}</span>

            </code>

            <code class="error scroll-y text-small" *ngIf="stack">
                <ng-container *ngIf="stack">
                    {{ stack.toString().trim()  }}
                </ng-container>

            </code>
        </ng-container>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" *ngIf="btnCancelText"
                (click)="decline()">{{ btnCancelText }}</button>
        <button type="button" class="btn btn-success" ngbAutofocus
                (click)="accept()">{{ btnOkText | translate }}</button>
    </div>
</div>
