import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";

export abstract class BaseCell {

    public row: BaseRow;
    public column: BaseColumn;

    public constructor(row: BaseRow, column: BaseColumn) {
        this.row = row;
        this.column = column;
    }

}
