<label *ngIf="false && configuration && configuration?.labelName">{{ configuration.labelName | translate }}</label>

<div class="form-group flex-grow-1 -align-items-center m-0 p-0 ">
    <ul class="list-group" *ngIf="types">
        <ng-container *ngFor="let type of types">

            <li *ngIf="!type.validator.isValid()"
                class="list-group-item d-flex justify-content-between flex-row align-items-center validation-error"
            >
                <app-validation-warning-list [inline]="true" [validatorError]="type.validator"></app-validation-warning-list>
            </li>

            <li class="list-group-item" >
                <div class="form-group mb-0 flex-grow-1 align-items-center d-flex">
                    <label class="d-flex align-items-start  flex-column">
                        <span>{{ type.name | translate }}<span *ngIf="type.validator.required" class="text-danger">*</span></span>
                        <span *ngIf="type.hasPreviousDeadlineDiffDescription" class="text-small badge badge-pill badge-info">{{ type.previousDeadlineDiffDescription }}</span>

                    </label>
                    <app-datepicker
                        [(ngModel)]="type.deadline"
                        [showSoftDeadline]="type.showSoftDeadline"
                        [required]="type.validator.required"
                        [staticDeadlines]="type.staticDeadlines"
                        [staticDeadlineDescriptor]="type.staticDeadlineDescriptor"
                        (valueChanged)="onValueChanged(type, $event)"
                    />
                </div>
            </li>
        </ng-container>
    </ul>
</div>
