import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';

import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {PageComponent} from '@app/pages/page.component';
import {Project} from '@app/core/models/Project';
import {versions} from '@env/versions';
import {SwUpdate} from "@angular/service-worker";
import {SnackbarService} from "@app/services/snackbar.service";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {SettingsEditorComponent} from '@app/editor/settings-editor/settings-editor.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {CacheService, IndexedDBItem} from '@app/services/cache.service';
import {System} from '@app/constants';
import {ColumnController} from "@app/core/ColumnControl/ColumnController";

export interface LocalStorageItem{
    key: string;
    bytes: number;
    version?: string;
    name?: string;
    created?: Date;
}

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default, // TO-DO: Changedetection
})
export class AboutComponent extends PageComponent implements OnInit {
    protected filtersSettings?: FiltersInterface;
    protected columnController?: ColumnController;

    public projects: Observable<Project[]>;

    version: string     = environment.version;
    environment: any = environment;

    gitVersions: {
        npm_version: string;
        revision: string;
        branch: string;
        version: string;
        project_id: string
    } = versions;

    public localDBItems: IndexedDBItem[] = [];

    public localStorageItems: LocalStorageItem[] = [];
    public totalSotrageBytes: number = 0;


    constructor(private snackbar: SnackbarService,
                private modalService: NgbModal,
                public updates: SwUpdate,
                private dbService: NgxIndexedDBService,
                private cacheService: CacheService,
                private _cdr: ChangeDetectorRef
                ) {
        super();
        this.cdr = _cdr;
    }

    ngOnInit() {
        this.shellService.setHeaderTitle('Om KlartBoard', null, false, false, false);
        this.generateStorageDescription();
        this.refreshDBEntries();
    }

    public refreshDBEntries(){
        this.cacheService.getAllIndexedDBItems((items)=>{
            this.localDBItems = items;
        })
    }

    public generateStorageDescription(){

        this.localStorageItems = [];

        this.totalSotrageBytes = 0;
        for (let _x in localStorage) {
            if (!localStorage.hasOwnProperty(_x)) {
                continue;
            }
            const key = _x;
            const bytes = (localStorage[_x].length + _x.length);

            const version = key.split('2.').length > 1 ? '2.'+key.split('2.').slice(1).join('.') : this.gitVersions.branch;

            const name = key
                .replace('.'+version, '')
                .replace('Klartboard.', '')
                .replace('System.', '')
            ;

            this.localStorageItems.push({
                key: key,
                bytes: (bytes/ 1024),
                version: version,
                name: name,
            })
            this.totalSotrageBytes += (bytes / 1024);
        };
        this.localStorageItems.sort((a, b) => {
            return a.name.localeCompare(b.name);
        })
    }

    updateVersion() {
        this.dialogService.confirm(this.translateService.instant('_ui_reset_cookies') , this.translateService.instant('_ui_reset_cookies_description')).then((r)=>{
            if(r){
                this.dbService.clear(System.DBCache).subscribe((r)=>{
                    document.location.reload();
                    this.snackbar.add(this.translateService.instant('_ui_reset_cookies_complete'));
                })
                localStorage.clear();

            }
        })
    }

    openSettingsPanel() {
        this.modalService.open(SettingsEditorComponent, {
            size: 'lg',
            windowClass: 'modal-holder',
            centered: true,
            backdrop: 'static',
            keyboard: true
        });
    }

    clearCookieKey(item: LocalStorageItem) {
        console.log('clear key : ', item)
        localStorage.removeItem(item.key)
        this.generateStorageDescription();
    }

    logItem(item: LocalStorageItem) {
        console.log(JSON.parse(localStorage.getItem(item.key)));
    }
    logDbItem(item: IndexedDBItem) {
        console.log(item);
    }

    clearDBKey(item: IndexedDBItem) {
        // Get ID from Key, then delete ID
        console.log('clearDBKey : ', item, item.key);
        this.dbService.getByIndex(System.DBCache, 'key', item.key)
            .subscribe((dbItem: IndexedDBItem) => {
                console.log('clearDBKey : item', dbItem);
                if(dbItem && dbItem.key) {
                    this.dbService.deleteByKey(System.DBCache, dbItem.key).subscribe((status) => {
                        this.localDBItems = this.localDBItems.filter(i => i.key !== item.key);
                        this.detectChanges();
                    });
                }

            })
    }

}
