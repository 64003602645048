import {GenericTableColumn} from "@app/pages/displays/display-team/TableColumns/GenericTableColumn";
import {DisplayTeamRow} from "@app/pages/displays/display-team/DisplayTeamRow";
import {TaskListTableColumn} from "@app/pages/displays/display-team/TableColumns/TaskListTableColumn";
import {AppointmentListTableColumn} from "@app/pages/displays/display-team/TableColumns/AppointmentListTableColumn";
import {TodoListTableColumn} from "@app/pages/displays/display-team/TableColumns/TodoListTableColumn";
import {DaysTableColumn} from "@app/pages/displays/display-team/TableColumns/DaysTableColumn";
import {MilestoneListTableColumn} from "@app/pages/displays/display-team/TableColumns/MilestoneListTableColumn";
import {ProjectListTableColumn} from "@app/pages/displays/display-team/TableColumns/ProjectListTableColumn";
import {CaseListTableColumn} from "@app/pages/displays/display-team/TableColumns/CaseListTableColumn";
import {
    TaskListSoftThisWeekTableColumn
} from "@app/pages/displays/display-team/TableColumns/TaskListSoftThisWeekTableColumn";
import {
    TaskListSoftNextWeekTableColumn
} from "@app/pages/displays/display-team/TableColumns/TaskListSoftNextWeekTableColumn";

export class TemplateTypes {

    headerTemplateType: {
        column: GenericTableColumn,
    }

    cellTemplateType: {
        column: GenericTableColumn,
        row: DisplayTeamRow,
        rowIndex: number,
    }

    projectListCellTemplateType: {
        column: ProjectListTableColumn,
        row: DisplayTeamRow,
        rowIndex: number,
    }

    milestoneListCellTemplateType: {
        column: MilestoneListTableColumn,
        row: DisplayTeamRow,
        rowIndex: number,
    }

    caseListCellTemplateType: {
        column: CaseListTableColumn,
        row: DisplayTeamRow,
        rowIndex: number,
    }

    taskListSoftThisWeekCellTemplateType: {
        column: TaskListSoftThisWeekTableColumn,
        row: DisplayTeamRow,
        rowIndex: number,
    }

    taskListCellTemplateType: {
        column: TaskListTableColumn,
        row: DisplayTeamRow,
        rowIndex: number,
    }

    taskListSoftNextWeekCellTemplateType: {
        column: TaskListSoftNextWeekTableColumn,
        row: DisplayTeamRow,
        rowIndex: number,
    }

    appointmentListCellTemplateType: {
        column: AppointmentListTableColumn,
        row: DisplayTeamRow,
        rowIndex: number,
    }

    todoListCellTemplateType: {
        column: TodoListTableColumn,
        row: DisplayTeamRow,
        rowIndex: number,
    }

    daysHeaderTemplateType: {
        column: DaysTableColumn,
    }

    daysCellTemplateType: {
        column: DaysTableColumn,
        row: DisplayTeamRow,
        rowIndex: number,
    }

}
