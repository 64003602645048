import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {LocalizedDatePipe} from '@app/pipes/localized-date.pipe';
import {BaseYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/BaseYearWheelItem";

@Component({
    selector: 'app-year-wheel-item-display',
    templateUrl: './year-wheel-item-display.component.html',
    styleUrls: ['./year-wheel-item-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalizedDatePipe,
    ],
})
export class YearWheelItemDisplayComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() model: BaseYearWheelItem;
    @Input() className: string;

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        super();
        this.cdr = this.changeDetectorRef;
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['model'] != null) {
            this.detectChanges();
        }
    }

}
