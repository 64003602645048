import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {GenericTableColumn} from "@app/pages/displays/display-team/TableColumns/GenericTableColumn";
import {ProjectListConfiguration} from "@app/shared/_ui/lists/project-list/ProjectListConfiguration";
import {ProjectListCell} from "@app/pages/displays/display-team/Cells/ProjectListCell";
import {TableColumns} from "@app/pages/displays/display-team/Helpers/TableColumns";

export class ProjectListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.ProjectList;

    public getListConfiguration(row: BaseRow): ProjectListConfiguration {
        return row.getCell<ProjectListCell>(this.column).projectListConfiguration;
    }

}
