import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ShellService} from "@app/services/ShellService/shell.service";

@Injectable({
    providedIn: 'root'
})
export class DragAndDropService {

    private items: string[] = [];
    private dropLists: Subject<string[]>;
    public dropLists$: Observable<string[]>;

    constructor(private shellService: ShellService) {
        // Clear everything when changing page
        shellService.onPrimaryPageDataChangeEvent.subscribe(value => {
            if (value === undefined) {
                this.setItems([]);
            }
        });

        this.dropLists = new Subject<string[]>();
        this.dropLists$ = this.dropLists.asObservable();
        this.dropLists.next(this.items);
    }

    setItems(items: string[]) {
        this.items = items;
        this.dropLists.next(this.items);
    }

    addDropList(id: string) {
        if (this.items.indexOf(id) == -1) {
            this.items.push(id);
            this.dropLists.next(this.items);
        }
    }

}
