import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {MilestonePresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/MilestonePresetType";

export class MilestoneDeadlinePresetGenerator implements CreatePresetGenerator {

    private readonly date?: Date;
    private readonly dateHandler?: () => Date;

    private readonly isSoft?: boolean;
    private readonly isSoftHandler?: () => boolean;

    constructor(
        dateOrHandler: Date | (() => Date),
        isSoftOrHandler: boolean | (() => boolean),
    ) {
        if (dateOrHandler instanceof Date) {
            this.date = dateOrHandler;
        } else {
            this.dateHandler = dateOrHandler;
        }
        if (typeof isSoftOrHandler == "boolean") {
            this.isSoft = isSoftOrHandler;
        } else {
            this.isSoftHandler = isSoftOrHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: MilestonePresetTypes.Deadline,
            payload: {
                date: this.date ?? this.dateHandler(),
                isSoft: this.isSoft ?? this.isSoftHandler(),
            }
        };
    }

}
