import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    forwardRef,
    Input,
    OnChanges,
    OnInit, ViewChild
} from '@angular/core';
import {BaseSearchComponent} from '../base-search/base-search.component';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ProjectsService} from '@app/services/projects.service';
import {merge, Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import {ApiFilter} from '@app/http/APIFilter';
import {Phase, Project} from '@app/core/models';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {CardProjectConfiguration} from '@app/shared/_ui/cards/medium/card-project/card-project-configuration';
import {Department} from "@app/core/models";
import {
    EditProjectListConfiguration
} from '@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListConfiguration';

@Component({
    selector: 'app-project-search',
    templateUrl: './project-search.component.html',
    styleUrls: ['../base-search/base-search.component.scss'],
    animations: [
        fadeAnimation,
        slideDownAnimation
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProjectSearchComponent),
            multi: true, // multi-casting
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ProjectSearchComponent),
            multi: true,
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSearchComponent extends BaseSearchComponent implements OnInit, OnChanges {

    @Input() modelType: number[]; // Globals.ProjectTypes
    @Input() showArchived: boolean = true;
    @Input() configuration: EditProjectListConfiguration;
    @Input() milestoneRequiredOnProject: boolean = false;
    @Input() showFilterButton: boolean = false;
    @Input() _internalValue: Project[] = [];
    @Input() typeLabel: string;
    @Input() placeholder: string;
    @Input() invalid: boolean = false;

    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;



    result: Project[] = [];
    projectConfiguration: CardProjectConfiguration = new CardProjectConfiguration();
    public departments: Department[] = [];

    constructor(private projectsService: ProjectsService,
                private _cdr:ChangeDetectorRef) {
        super();
        this.cdr = _cdr;
        this.projectConfiguration.useGlobalFilter = false;

    }

    ngOnInit() {
        if(!this.typeLabel){
            this.typeLabel = this.translateService.instant('_project');
        }
        this.typeLabel = this.typeLabel.toLowerCase();
    }

    ngOnChanges(changes: any) {
        super.ngOnChanges(changes);
        if (changes['invalid']) {
            this.detectChanges();
        }
    }

    addItem(c: any) {
        if(!this._internalValue || !this.multiple) {
            this._internalValue = [];
        }
        this.itemAdded.emit(c);
        this.onChange(this._internalValue); // Value is good!
        this.propagateChange(this.internalValue);
        this.model = null;
        this.updateInputField();
    }



    search = (text$: Observable<string>) => {
        console.log('search : ', text$);
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => {
            console.log('search() : clicksWithClosedPopup$ : ', !this.instance.isPopupOpen())
            if(!this.instance.isPopupOpen()){
                return true;
            }
            // return !this.instance.isPopupOpen();
        }));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            tap(() => {
                this.searching = true;
            }),
            switchMap(term => {
                    this.searching = true;
                    let apiFilter = new ApiFilter();

                    // if(this.milestoneRequiredOnProject)
                        // apiFilter.whereGreaterThan('milestone.id', 0)

                    if(this.departments.length > 0)
                        apiFilter.whereInArray('department.id', this.departments.map(d => d.id));

                    if(this.modelType)
                        apiFilter.whereInArray('project_type_id', this.modelType); // Filtrer på typen

                    if(!this.showArchived)
                        apiFilter.whereEquals('archived_id', 0)

                    this.result = null;
                    return this.projectsService.search(term, apiFilter).pipe(
                        map((items: Project[]) => {
                            const itemResult = items.filter((project: Project) => {
                                return !this.milestoneRequiredOnProject || project.milestones?.length > 0
                            }).filter((project: Project) => {
                                return !this.Helpers.itemExists(this.internalValue ? this.internalValue : [] , project.id)
                            });

                            // console.log('projectsService result : ', items, 'filtered: ', itemResult, 'this.milestoneRequiredOnProject : ', this.milestoneRequiredOnProject)
                            this.result = itemResult;
                            return itemResult;

                        }),
                        tap(() => {
                            this.searching = false;
                            this.searchFailed = false;
                            this.cdr.detectChanges();
                        }),
                        catchError(() => {
                            this.searchFailed = true;
                            return of([]);
                        }));
                }
            ),
            tap(() => this.searching = false)
        );

    };

    formatter = (x: Project) => x.title;


    createField() {
        console.warn('Project creation not allowed from search component');
    }

    projectsOfType() {
        if(this.internalValue) {
            if(this.modelType) {
                return this.internalValue.filter((p: Project) => {
                    return this.modelType.indexOf(p.project_type_id) !== -1;
                });
            } else {
                return this.internalValue;
            }
        } else {
            return null;
        }
    }

    filterByDepartment($event: Department[]) {
        this.departments = $event;
    }

    updateInputField() {
        this.searchInput.nativeElement.blur();
    }

    resultTemplateType: {
        result: Project,
        term: string,
    }
}
