import {Filter} from '@app/pages/displays/filtering/Filter';
import {Sort} from '@app/pages/displays/filtering/Sort';
import {Period} from '@app/pages/displays/filtering/Period';
import {DisplayFilter} from '@app/core/models/DisplayFilter';
import {Display} from '@app/core/models/Display';
import {AppInjector} from '@app/services/app-injector.service';
import * as moment from 'moment';
import {DisplayFilterEditorFormConfigInterface} from '@app/editor/display-filter-editor/DisplayFilterEditorForm';
import {Settings} from '@app/pages/displays/display-projects/Settings';
import {ProjectsService} from '@app/services/projects.service';
import {TranslateService} from '@ngx-translate/core';
import {Columns} from '@app/pages/displays/display-projects/Columns';
import {ProjectType} from '@app/core/models/ProjectType';
import {CategoryType} from '@app/core/models';
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";

export class Filters implements FiltersInterface {

    public applyDefaults(displayFilter: DisplayFilter) {
        displayFilter.filter_type = Filters.FilterAll;
        displayFilter.sort_type = Filters.SortTitle;
        displayFilter.period_type = Filters.PeriodToday;
        displayFilter.include_archived_since_type = Filters.IncludeArchivedSincePeriodPrev2Weeks;
        displayFilter.year_wheel_period_type = Filters.YearWheelPeriodNext3Month;
    }

    // Filters
    public static FilterAll                     = 'filter-all';
    public static FilterOpenTodo                = 'filter-open-todo';
    public static FilterAppointments            = 'filter-appointments';
    public static FilterArchivedInPeriod        = 'filter-archived-in-period';
    public static FilterCreatedInPeriod         = 'filter-created-in-period';
    public static FilterDeadlineInPeriod        = 'filter-deadline-in-period';
    public static FilterPhase                   = 'filter-phase';
    public static FilterProjectDeadlineType     = 'filter-project-deadline-type';
    public static FilterPhaseProgressType       = 'filter-phase-progress-type';
    public static FilterNextMilestoneInPeriod   = 'filter-next-milestone-in-period';
    public static FilterMilestoneInPeriod       = 'filter-milestone-in-period';
    public static FilterNonPlannedInPeriod      = 'filter-non-planned-in-period';

    // Sorts
    public static SortTitle                 = 'sort-title';
    public static SortResponsible           = 'sort-responsible';
    public static SortDeadline              = 'sort-deadline';
    public static SortStatus                = 'sort-status';
    public static SortNextMilestoneDeadline = 'sort-milestone-deadline';
    public static SortNextMilestoneStatus   = 'sort-milestone-status';

    // https://podio.com/klartboard/softwareudvikling/apps/stories/items/516
    public static SortStarred                   = 'sort-starred';
    public static SortHandUp                    = 'sort-hand-up';
    public static SortPhase                     = 'sort-phase';

    public static SortNextMilestoneTitle        = 'sort-milestone-title';
    public static SortNextMilestoneHandUp       = 'sort-milestone-hand-up';
    public static SortNextMilestoneResponsible  = 'sort-milestone-responsible';
    public static SortNextMilestoneStarred      = 'sort-milestone-starred';

    public static SortCategoryType              = 'sort-category-type-';
    public static SortCategoryTypeGenerator(categoryType: CategoryType): string {
        return `${Filters.SortCategoryType}${categoryType.id}`;
    };
    public static ParseSortCategoryType(sort: string): string {
        return sort.substr(Filters.SortCategoryType.length);
    };

    public static GetBaseSort(sort: string): string {
        // Remove any identifiers from the end
        return sort.replace(/[0-9]/g, '');
    }

    // https://podio.com/klartboard/softwareudvikling/apps/stories/items/519
    public static SortUpcomingMilestoneTitle        = 'sort-upcoming-milestone-title';
    public static SortUpcomingMilestoneDeadline     = 'sort-upcoming-milestone-deadline';
    public static SortUpcomingMilestoneResponsible  = 'sort-upcoming-milestone-responsible';
    public static SortUpcomingMilestoneStatus       = 'sort-upcoming-milestone-status';

    public static SortNextMilestoneTaskTitle        = 'sort-milestone-task-title';
    public static SortNextMilestoneTaskStatus       = 'sort-milestone-task-status';
    public static SortNextMilestoneTaskIndex        = 'sort-milestone-task-index';

    public static SortProjectTaskTitle              = 'sort-project-task-title';
    public static SortProjectTaskStatus             = 'sort-project-task-status';
    public static SortProjectTaskDeadlineFilter     = 'sort-project-task-deadline-filter';
    public static SortProjectTaskIndex              = 'sort-project-task-index';


    // Periods
    public static PeriodUserDefined         = 'user-defined';
    public static PeriodLastWeek            = 'last-week';
    public static PeriodToday               = 'today';
    public static PeriodThisWeek            = 'this-week';
    public static PeriodNextWeek            = 'next-week';
    public static PeriodNext2Week           = 'next-2-weeks';
    public static PeriodNextMonth           = 'next-month';

    // Include Archived Since Periods
    public static IncludeArchivedSincePeriodUserDefined     = 'user-defined';
    public static IncludeArchivedSincePeriodToday           = 'today';
    public static IncludeArchivedSincePeriodThisWeek        = 'this-week';
    public static IncludeArchivedSincePeriodPrev1Week       = 'prev-1-week';
    public static IncludeArchivedSincePeriodPrev2Weeks      = 'prev-2-weeks';
    public static IncludeArchivedSincePeriodThisMonth       = 'this-month';
    public static IncludeArchivedSincePeriodThisYear        = 'this-year';

    // Year Wheel Periods
    public static YearWheelPeriodUserDefined    = 'user-defined';
    public static YearWheelPeriodThisWeek       = 'this-week';
    public static YearWheelPeriodNext1Week      = 'next-1-week';
    public static YearWheelPeriodNext2Week      = 'next-2-weeks';
    public static YearWheelPeriodNext4Week      = 'next-4-weeks';
    public static YearWheelPeriodNext8Week      = 'next-8-weeks';
    public static YearWheelPeriodThisMonth      = 'this-month';
    public static YearWheelPeriodFollowingMonth = 'following-month';
    public static YearWheelPeriodNextMonth      = 'next-month';
    public static YearWheelPeriodNext3Month     = 'next-3-months';
    public static YearWheelPeriodNext6Month     = 'next-6-months';
    public static YearWheelPeriodNext12Month    = 'next-12-months';
    public static YearWheelPeriodThisQuarter    = 'this-quarter';
    public static YearWheelPeriodNextQuarter    = 'next-quarter';
    public static YearWheelPeriodThisHalfYear   = 'this-half-year';
    public static YearWheelPeriodNextHalfYear   = 'next-half-year';
    public static YearWheelPeriodThisYear       = 'this-year';
    public static YearWheelPeriodNextYear       = 'next-year';

    public filters(display: Display): Filter[] {
        const config = this.getEditorConfig(display);
        const projectTypeName = this.getProjectName(null, config);

        const all = new Filter(Filters.FilterAll, this.getTranslation('_ui_all_item', {item: projectTypeName.toLocaleLowerCase()}));
        const openTodo = new Filter(Filters.FilterOpenTodo, this.getTranslation('_ui_filter_open_todos', {item: projectTypeName}));
        const appointments = new Filter(Filters.FilterAppointments, this.getTranslation('_ui_filter_with_remarks', {item: projectTypeName}));
        const archived = new Filter(Filters.FilterArchivedInPeriod,
            this.getTranslation('_ui_filter_completed_in_period', {item: projectTypeName}), true);
        const created = new Filter(Filters.FilterCreatedInPeriod, this.getTranslation('_ui_filter_created_in_period', {item: projectTypeName}), true);
        const deadline = new Filter(Filters.FilterDeadlineInPeriod, this.getTranslation('_ui_filter_with_deadline_in_period', {item: projectTypeName}), true);
        const phase = new Filter(Filters.FilterPhase, this.getTranslation('_ui_filter_type_in_phase',
            {
                type: projectTypeName,
                phase: config.phaseName ? config.phaseName : this.getTranslation('_phase_singular')
            }
        ));
        phase.showPhase = true;
        const projectDeadlineType =
            new Filter(Filters.FilterProjectDeadlineType,
                this.getTranslation('_ui_filter_type_with_deadline_type',
                        {
                            type: projectTypeName,
                            deadline_type: config.projectDeadlineTypeName ? config.projectDeadlineTypeName : this.getTranslation('_project_deadline')
                        })
            );
        projectDeadlineType.showProjectDeadlineType = true;
        const phaseProgressType = new Filter(Filters.FilterPhaseProgressType,
            this.getTranslation('_ui_filter_type_in_phase_progress_type',
            {
                type: projectTypeName,
                phaseProgressType: this.getTranslation('_phase_progress_type_singular')
            }
        ));
        phaseProgressType.showPhaseProgressType = true;
        const nextMilestoneDeadline = new Filter(Filters.FilterNextMilestoneInPeriod, this.getTranslation('_ui_filter_next_milestone_in_period', {item: projectTypeName}), true);
        const milestoneDeadline = new Filter(Filters.FilterMilestoneInPeriod, this.getTranslation('_ui_filter_milestone_in_period', {item: projectTypeName}), true);

        const allFilters = [
            all, openTodo, appointments, archived, created, deadline, phase, projectDeadlineType, phaseProgressType,
            nextMilestoneDeadline, milestoneDeadline,
        ];

        // Check for roster columns https://podio.com/klartboard/softwareudvikling/apps/stories/items/998
        const rosterColumns = Columns.GetRosterColumns();
        const hasRosterColumns = display.displays_settings?.find(displaysSetting => {
            return rosterColumns.includes(displaysSetting.setting_id);
        }) !== undefined;
        if (hasRosterColumns) {
            const nonPlannedInPeriod = new Filter(
                Filters.FilterNonPlannedInPeriod,
                this.getTranslation('_ui_office_filter_non_planned_in_period', {item: projectTypeName.toLocaleLowerCase()}),
                true
            );
            allFilters.push(nonPlannedInPeriod);
        }

        allFilters.forEach(filter => {
            filter.showIncludeArchivedSince = true;
        });
        archived.showIncludeArchivedSince = false;

        return allFilters;
    }

    private getTranslation(key: string, interpolateParams?: Object): string {
        return AppInjector.getInjector().get(TranslateService).instant(key, interpolateParams);
    }

    public sorts(display: Display, includePseudoTypes = false): Sort[] {
        let title               = new Sort(Filters.SortTitle, this.getTranslation('_ui_title'), true, null, Sort.SortTypeDisplay);
        let responsible         = new Sort(Filters.SortResponsible, this.getTranslation('_ui_project_leader'), true, null, Sort.SortTypeDisplay);
        let deadline            = new Sort(Filters.SortDeadline, this.getTranslation('_ui_deadline_sort'), true, null, Sort.SortTypeDisplay);
        let status              = new Sort(Filters.SortStatus, this.getTranslation('_ui_status'), true, null, Sort.SortTypeDisplay); // V2.0.0 https://podio.com/klartboard/softwareudvikling/apps/stories/items/62
        // V2.0.0 https://podio.com/klartboard/softwareudvikling/apps/stories/items/62

        let sortStarred         = new Sort(Filters.SortStarred, this.getTranslation('_ui_starred'), true, null, Sort.SortTypeDisplay);
        let sortHandUp          = new Sort(Filters.SortHandUp, this.getTranslation('_ui_hand_up'), true, null, Sort.SortTypeDisplay);
        let sortPhase           = new Sort(Filters.SortPhase, this.getTranslation('_phase_singular'), true, null, Sort.SortTypeDisplay);

        let milestoneDeadline   = new Sort(Filters.SortNextMilestoneDeadline,
            this.getTranslation('_ui_next_milestone_filter_type', {type: this.getTranslation('_milestone_deadline')}), true, null, Sort.SortTypeDisplay);
        let milestoneStatus     = new Sort(Filters.SortNextMilestoneStatus,
            this.getTranslation('_ui_next_milestone_filter_type', {type: this.getTranslation('_milestone_status')}), true, null, Sort.SortTypeDisplay);
        let sortNextMilestoneTitle  = new Sort(Filters.SortNextMilestoneTitle,
            this.getTranslation('_ui_next_milestone_filter_type', {type: this.getTranslation('_milestone_name')}), true, null, Sort.SortTypeDisplay);
        let sortNextMilestoneHandUp = new Sort(Filters.SortNextMilestoneHandUp,
            this.getTranslation('_ui_next_milestone_filter_type', {type: this.getTranslation('_milestone_hand_up')}), true, null, Sort.SortTypeDisplay);
        let sortNextMilestoneResponsible = new Sort(Filters.SortNextMilestoneResponsible,
            this.getTranslation('_ui_next_milestone_filter_type', {type: this.getTranslation('_milestone_responsible')}), true, null, Sort.SortTypeDisplay);
        let sortNextMilestoneStarred = new Sort(Filters.SortNextMilestoneStarred,
            this.getTranslation('_ui_next_milestone_filter_type', {type: this.getTranslation('_milestone_star')}), true, null, Sort.SortTypeDisplay);
        let sorts  = [
            title,
            responsible,
            deadline,
            status,

            sortStarred,
            sortHandUp,
            sortPhase,
            milestoneDeadline,
            milestoneStatus,
            sortNextMilestoneTitle,
            sortNextMilestoneHandUp,
            sortNextMilestoneResponsible,
            sortNextMilestoneStarred
        ];

        if (includePseudoTypes) {
            let upcomingMilestoneTitle          = new Sort(Filters.SortUpcomingMilestoneTitle, this.getTranslation('_milestone_name'), true, null, Sort.SortTypeColumn);
            let upcomingMilestoneDeadline       = new Sort(Filters.SortUpcomingMilestoneDeadline, this.getTranslation('_milestone_deadline'), true, null, Sort.SortTypeColumn);
            let upcomingMilestoneResponsible    = new Sort(Filters.SortUpcomingMilestoneResponsible, this.getTranslation('_milestone_responsible'), true, null, Sort.SortTypeColumn);
            let upcomingMilestoneStatus         = new Sort(Filters.SortUpcomingMilestoneStatus, this.getTranslation('_milestone_status'), true, null, Sort.SortTypeColumn);
            sorts.push(upcomingMilestoneTitle);
            sorts.push(upcomingMilestoneDeadline);
            sorts.push(upcomingMilestoneResponsible);
            sorts.push(upcomingMilestoneStatus);

            let nextMilestoneTaskTitle  = new Sort(Filters.SortNextMilestoneTaskTitle, this.getTranslation('_milestone_name'), true, null, Sort.SortTypeColumn);
            let nextMilestoneTaskStatus = new Sort(Filters.SortNextMilestoneTaskStatus, this.getTranslation('_milestone_status'), true, null, Sort.SortTypeColumn);
            let nextMilestoneTaskIndex = new Sort(Filters.SortNextMilestoneTaskIndex, this.getTranslation('_ui_sort_next_milestone_task_index'), true, null, Sort.SortTypeColumn);
            sorts.push(nextMilestoneTaskTitle);
            sorts.push(nextMilestoneTaskStatus);
            sorts.push(nextMilestoneTaskIndex);

            let projectTaskTitle  = new Sort(Filters.SortProjectTaskTitle, this.getTranslation('_project_title'), true);
            let projectTaskStatus = new Sort(Filters.SortProjectTaskStatus, this.getTranslation('_project_status'), true);
            let projectTaskDeadlineFilter = new Sort(Filters.SortProjectTaskDeadlineFilter, this.getTranslation('_ui_sort_project_task_deadline_filter'), true);
            let projectTaskIndex = new Sort(Filters.SortProjectTaskIndex, this.getTranslation('_ui_sort_project_task_index'), true);
            sorts.push(projectTaskTitle);
            sorts.push(projectTaskStatus);
            sorts.push(projectTaskDeadlineFilter);
            sorts.push(projectTaskIndex);
        }

        const config = this.getEditorConfig(display);
        config.categoryTypes?.forEach(categoryType => {
            sorts.push(new Sort(
                Filters.SortCategoryTypeGenerator(categoryType),
                `${categoryType.name} ${this.getTranslation('_filter_show_only', {name: categoryType.name.toLocaleLowerCase()})}`,
                true, null, Sort.SortTypeDisplay));
        });

        return sorts;
    }

    public getSort(display: Display, sort: string): Sort {
        return this.sorts(display).find(item => item.id == sort);
    }

    public periods(): Period[] {
        return [
            new Period(Filters.PeriodUserDefined, this.getTranslation('_ui_date_userdefined'), true),
            new Period(Filters.PeriodLastWeek, this.getTranslation('_ui_date_last_week')),
            new Period(Filters.PeriodToday, this.getTranslation('_ui_date_today')),
            new Period(Filters.PeriodThisWeek, this.getTranslation('_ui_date_this_week')),
            new Period(Filters.PeriodNextWeek, this.getTranslation('_ui_date_next_week')),
            new Period(Filters.PeriodNext2Week, this.getTranslation('_ui_date_next_2_weeks')),
            new Period(Filters.PeriodNextMonth, this.getTranslation('_ui_date_next_month')),
        ];
    }

    public includeArchivedSincePeriods(): Period[] {
        return [
            new Period(Filters.IncludeArchivedSincePeriodUserDefined, this.getTranslation('_ui_date_userdefined_start'), true),
            new Period(Filters.IncludeArchivedSincePeriodToday, this.getTranslation('_ui_date_today')),
            new Period(Filters.IncludeArchivedSincePeriodThisWeek, this.getTranslation('_ui_date_this_week')),
            new Period(Filters.IncludeArchivedSincePeriodPrev1Week, this.getTranslation('_ui_date_past_week')),
            new Period(Filters.IncludeArchivedSincePeriodPrev2Weeks, this.getTranslation('_ui_date_past_2_weeks')),
            new Period(Filters.IncludeArchivedSincePeriodThisMonth, this.getTranslation('_ui_date_this_month')),
            new Period(Filters.IncludeArchivedSincePeriodThisYear, this.getTranslation('_ui_date_this_year')),
        ];
    }

    public yearWheelPeriods(): Period[] {
        return [
            new Period(Filters.YearWheelPeriodUserDefined, this.getTranslation('_ui_date_userdefined'), true),
            new Period(Filters.YearWheelPeriodThisWeek, this.getTranslation('_ui_date_this_week')),
            new Period(Filters.YearWheelPeriodNext1Week, this.getTranslation('_ui_date_next_1_week')),
            new Period(Filters.YearWheelPeriodNext2Week, this.getTranslation('_ui_date_next_2_weeks')),
            new Period(Filters.YearWheelPeriodNext4Week, this.getTranslation('_ui_date_next_4_weeks')),
            new Period(Filters.YearWheelPeriodNext8Week, this.getTranslation('_ui_date_next_8_weeks')),
            new Period(Filters.YearWheelPeriodThisMonth, this.getTranslation('_ui_date_this_month')),
            new Period(Filters.YearWheelPeriodFollowingMonth, this.getTranslation('_ui_date_following_month')),
            new Period(Filters.YearWheelPeriodNextMonth, this.getTranslation('_ui_date_next_month')),
            new Period(Filters.YearWheelPeriodNext3Month, this.getTranslation('_ui_date_next_3_months')),
            new Period(Filters.YearWheelPeriodNext6Month, this.getTranslation('_ui_date_next_6_months')),
            new Period(Filters.YearWheelPeriodNext12Month, this.getTranslation('_ui_date_next_12_months')),
            new Period(Filters.YearWheelPeriodThisQuarter, this.getTranslation('_ui_date_this_quarter')),
            new Period(Filters.YearWheelPeriodNextQuarter, this.getTranslation('_ui_date_next_quarter')),
            new Period(Filters.YearWheelPeriodThisHalfYear, this.getTranslation('_ui_date_this_half_year')),
            new Period(Filters.YearWheelPeriodNextHalfYear, this.getTranslation('_ui_date_next_half_year')),
            new Period(Filters.YearWheelPeriodThisYear, this.getTranslation('_ui_date_this_year')),
            new Period(Filters.YearWheelPeriodNextYear, this.getTranslation('_ui_date_next_year')),
        ];
    }

    public getPeriodStart(period: string, sourceDate?: Date): Date {
        let date = moment(sourceDate);
        switch(period) {
            case Filters.PeriodLastWeek:
                date.startOf('isoWeek').subtract(1, 'week');
                break;
            case Filters.PeriodToday:
                date.startOf('day');
                break;
            case Filters.PeriodThisWeek:
                date.startOf('isoWeek');
                break;
            case Filters.PeriodNextWeek:
                date = moment();
                date.startOf('isoWeek').add(1, 'week');
                break;
            case Filters.PeriodNext2Week:
                date.startOf('day');
                break;
            case Filters.PeriodNextMonth:
                date.startOf('day');
                break;
        }
        return date.toDate();
    }

    public getPeriodEnd(period: string, sourceDate?: Date): Date {
        let date = moment(sourceDate);
        switch(period) {
            case Filters.PeriodLastWeek:
                date.endOf('isoWeek').subtract(1, 'week');
                break;
            case Filters.PeriodToday:
                date.endOf('day');
                break;
            case Filters.PeriodThisWeek:
                date.endOf('isoWeek');
                break;
            case Filters.PeriodNextWeek:
                date = moment();
                date.endOf('isoWeek').add(1, 'week');
                break;
            case Filters.PeriodNext2Week:
                date.endOf('day').add(2, 'weeks');
                break;
            case Filters.PeriodNextMonth:
                date.endOf('day').add(1, 'month');
                break;
        }
        return date.toDate();
    }

    public getIncludeArchivedSinceDate(period: string, customDate?: string): Date {
        let date = moment();
        switch(period) {
            case Filters.IncludeArchivedSincePeriodToday:
                date.startOf('day');
                break;
            case Filters.IncludeArchivedSincePeriodThisWeek:
                date.startOf('isoWeek');
                break;
            case Filters.IncludeArchivedSincePeriodPrev1Week:
                date.startOf('day').subtract(1, 'week');
                break;
            case Filters.IncludeArchivedSincePeriodPrev2Weeks:
                date.startOf('day').subtract(2, 'week');
                break;
            case Filters.IncludeArchivedSincePeriodThisMonth:
                date.startOf('month');
                break;
            case Filters.IncludeArchivedSincePeriodThisYear:
                date.startOf('year');
                break;
            case Filters.IncludeArchivedSincePeriodUserDefined:
                if (customDate)
                    return new Date(customDate);
                else
                    return null;
        }
        return date.toDate();
    }

    public getYearWheelPeriodStart(period: string, customDate?: string): Date {
        let date = moment();
        switch (period) {
            case Filters.YearWheelPeriodThisWeek:
                date.startOf('isoWeek');
                break;
            case Filters.YearWheelPeriodNext1Week:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodNext2Week:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodNext4Week:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodNext8Week:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodThisMonth:
                date.startOf('month');
                break;
            case Filters.YearWheelPeriodFollowingMonth:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodNextMonth:
                date.startOf('month').add(1, 'month');
                break;
            case Filters.YearWheelPeriodNext3Month:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodNext6Month:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodNext12Month:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodThisQuarter:
                date.startOf('quarter');
                break;
            case Filters.YearWheelPeriodNextQuarter:
                date.startOf('quarter').add(1, 'quarter');
                break;
            case Filters.YearWheelPeriodThisHalfYear:
                if (date.month() <= 5) {
                    date.startOf('year');
                } else {
                    date.startOf('year').add(6, 'month');
                }
                break;
            case Filters.YearWheelPeriodNextHalfYear:
                if (date.month() <= 5) {
                    date.startOf('year').add(6, 'month');
                } else {
                    date.startOf('year').add(1, 'year');
                }
                break;
            case Filters.YearWheelPeriodThisYear:
                date.startOf('year');
                break;
            case Filters.YearWheelPeriodNextYear:
                date.startOf('year').add(1, 'year');
                break;
            case Filters.YearWheelPeriodUserDefined:
                if (customDate) {
                    return new Date(customDate);
                } else {
                    return null;
                }
        }
        return date.toDate();
    }

    public getYearWheelPeriodEnd(period: string, customDate?: string): Date {
        let date = moment();
        switch(period) {
            case Filters.YearWheelPeriodThisWeek:
                date.endOf('isoWeek');
                break;
            case Filters.YearWheelPeriodNext1Week:
                date.subtract(1, 'day').endOf('day').add(1, 'weeks');
                break;
            case Filters.YearWheelPeriodNext2Week:
                date.subtract(1, 'day').endOf('day').add(2, 'weeks');
                break;
            case Filters.YearWheelPeriodNext4Week:
                date.subtract(1, 'day').endOf('day').add(4, 'weeks');
                break;
            case Filters.YearWheelPeriodNext8Week:
                date.subtract(1, 'day').endOf('day').add(8, 'weeks');
                break;
            case Filters.YearWheelPeriodThisMonth:
                date.endOf('month');
                break;
            case Filters.YearWheelPeriodFollowingMonth:
                date.endOf('day').add(1, 'month');
                break;
            case Filters.YearWheelPeriodNextMonth:
                date.endOf('month').add(1, 'month');
                break;
            case Filters.YearWheelPeriodNext3Month:
                date.endOf('month').add(3, 'month');
                break;
            case Filters.YearWheelPeriodNext6Month:
                date.endOf('month').add(6, 'month');
                break;
            case Filters.YearWheelPeriodNext12Month:
                date.endOf('month').add(12, 'month');
                break;
            case Filters.YearWheelPeriodThisQuarter:
                date.endOf('quarter');
                break;
            case Filters.YearWheelPeriodNextQuarter:
                date.endOf('quarter').add(1, 'quarter');
                break;
            case Filters.YearWheelPeriodThisHalfYear:
                if (date.month() <= 5) {
                    date.endOf('year').subtract(6, 'month');
                } else {
                    date.endOf('year');
                }
                break;
            case Filters.YearWheelPeriodNextHalfYear:
                if (date.month() <= 5) {
                    date.endOf('year');
                } else {
                    date.endOf('year').add(6, 'month');
                }
                break;
            case Filters.YearWheelPeriodThisYear:
                date.endOf('year');
                break;
            case Filters.YearWheelPeriodNextYear:
                date.endOf('year').add(1, 'year');
                break;
            case Filters.YearWheelPeriodUserDefined:
                if (customDate) {
                    return new Date(customDate);
                } else {
                    return null;
                }
        }
        return date.toDate();
    }

    public standards(display: Display): DisplayFilter[] {
        let standards: DisplayFilter[] = [];

        let allByTitle = new DisplayFilter();
        allByTitle.display_id = display.id;
        allByTitle.display = display;
        allByTitle.filter_type = Filters.FilterAll;
        allByTitle.sort_type = Filters.SortTitle;
        allByTitle.statuses = [];
        allByTitle.sort_direction = 'asc';
        allByTitle.year_wheel_period_type = Filters.YearWheelPeriodNext3Month;
        standards.push(allByTitle);

        let openTodos = new DisplayFilter();
        openTodos.display_id = display.id;
        openTodos.display = display;
        openTodos.filter_type = Filters.FilterOpenTodo;
        openTodos.sort_type = Filters.SortTitle;
        openTodos.statuses = [];
        openTodos.sort_direction = 'asc';
        openTodos.year_wheel_period_type = Filters.YearWheelPeriodNext3Month;
        standards.push(openTodos);

        let withAppointments = new DisplayFilter();
        withAppointments.display_id = display.id;
        withAppointments.display = display;
        withAppointments.filter_type = Filters.FilterAppointments;
        withAppointments.sort_type = Filters.SortTitle;
        withAppointments.statuses = [];
        withAppointments.sort_direction = 'asc';
        withAppointments.year_wheel_period_type = Filters.YearWheelPeriodNext3Month;
        standards.push(withAppointments);

        let allByResponsible = new DisplayFilter();
        allByResponsible.display_id = display.id;
        allByResponsible.display = display;
        allByResponsible.filter_type = Filters.FilterAll;
        allByResponsible.sort_type = Filters.SortResponsible;
        allByResponsible.statuses = [];
        allByResponsible.sort_direction = 'asc';
        allByResponsible.year_wheel_period_type = Filters.YearWheelPeriodNext3Month;
        standards.push(allByResponsible);

        let allByDeadline = new DisplayFilter();
        allByDeadline.display_id = display.id;
        allByDeadline.display = display;
        allByDeadline.filter_type = Filters.FilterAll;
        allByDeadline.sort_type = Filters.SortDeadline;
        allByDeadline.statuses = [];
        allByDeadline.sort_direction = 'asc';
        allByDeadline.year_wheel_period_type = Filters.YearWheelPeriodNext3Month;
        standards.push(allByDeadline);

        let allByStatus = new DisplayFilter();
        allByStatus.display_id = display.id;
        allByStatus.display = display;
        allByStatus.filter_type = Filters.FilterAll;
        allByStatus.sort_type = Filters.SortStatus;
        allByStatus.statuses = [];
        allByStatus.sort_direction = 'asc';
        allByStatus.year_wheel_period_type = Filters.YearWheelPeriodNext3Month;
        standards.push(allByStatus);

        let allByMilestoneDeadline = new DisplayFilter();
        allByMilestoneDeadline.display_id = display.id;
        allByMilestoneDeadline.display = display;
        allByMilestoneDeadline.filter_type = Filters.FilterAll;
        allByMilestoneDeadline.sort_type = Filters.SortNextMilestoneDeadline;
        allByMilestoneDeadline.statuses = [];
        allByMilestoneDeadline.sort_direction = 'asc';
        allByMilestoneDeadline.year_wheel_period_type = Filters.YearWheelPeriodNext3Month;
        standards.push(allByMilestoneDeadline);

        let allByMilestoneStatus = new DisplayFilter();
        allByMilestoneStatus.display_id = display.id;
        allByMilestoneStatus.display = display;
        allByMilestoneStatus.filter_type = Filters.FilterAll;
        allByMilestoneStatus.sort_type = Filters.SortNextMilestoneStatus;
        allByMilestoneStatus.statuses = [];
        allByMilestoneStatus.sort_direction = 'asc';
        allByMilestoneStatus.year_wheel_period_type = Filters.YearWheelPeriodNext3Month;
        standards.push(allByMilestoneStatus);

        // Check for roster columns
        const rosterColumns = Columns.GetRosterColumns();
        const hasRosterColumns = display.displays_settings?.find(displaysSetting => {
            return rosterColumns.includes(displaysSetting.setting_id);
        }) !== undefined;
        if (hasRosterColumns) {
            const nonPlannedThisWeek = new DisplayFilter();
            nonPlannedThisWeek.display_id = display.id;
            nonPlannedThisWeek.display = display;
            nonPlannedThisWeek.filter_type = Filters.FilterNonPlannedInPeriod;
            nonPlannedThisWeek.sort_type = Filters.SortTitle;
            nonPlannedThisWeek.period_type = Filters.PeriodThisWeek
            nonPlannedThisWeek.statuses = [];
            nonPlannedThisWeek.sort_direction = 'asc';
            standards.push(nonPlannedThisWeek);

            const nonPlannedNextWeek = new DisplayFilter();
            nonPlannedNextWeek.display_id = display.id;
            nonPlannedNextWeek.display = display;
            nonPlannedNextWeek.filter_type = Filters.FilterNonPlannedInPeriod;
            nonPlannedNextWeek.sort_type = Filters.SortTitle;
            nonPlannedNextWeek.period_type = Filters.PeriodNextWeek
            nonPlannedNextWeek.statuses = [];
            nonPlannedNextWeek.sort_direction = 'asc';
            standards.push(nonPlannedNextWeek);
        }

        standards.forEach(item => {
            item.include_archived_since_type = Filters.IncludeArchivedSincePeriodPrev2Weeks;
            item.name = item.generateName(this);
        });

        return standards;
    }

    public generateName(displayFilter: DisplayFilter): string {
        // <start> <phase?> <status> <period-description?> <period?> sorteret på <sort>
        let projectTypeName = this.getTranslation('_project'); // forløb
        let phaseName = this.getTranslation('_phase_singular');
        let projectDeadlineTypeName = this.getTranslation('_static_deadline_deadline');
        if (displayFilter.display) {
            const config = this.getEditorConfig(displayFilter.display);
            projectTypeName = this.getProjectName(displayFilter, config);
            if (config.phaseName &&  config.phaseName != '') {
                phaseName = config.phaseName;
            }
            if (config.projectDeadlineTypeName) {
                projectDeadlineTypeName = config.projectDeadlineTypeName;
            }
        }

        let start;
        let status;
        let periodDescription;
        let period;
        let sort;
        let includeArchivedSince;

        switch(displayFilter.filter_type) {
            case Filters.FilterAll:
                start = `${this.getTranslation('_ui_all_item', {item: projectTypeName.toLocaleLowerCase()})}`;
                break;
            case Filters.FilterOpenTodo:
                start = this.getTranslation('_ui_filter_open_todos', {item: projectTypeName});
                break;
            case Filters.FilterAppointments:
                start = this.getTranslation('_ui_filter_with_remarks', {item: projectTypeName});
                break;
            case Filters.FilterArchivedInPeriod:
                start = `${projectTypeName}`;
                periodDescription = this.getTranslation('_ui_completed');
                break;
            case Filters.FilterCreatedInPeriod:
                start = `${this.getTranslation('_ui_all_item', {item: projectTypeName.toLocaleLowerCase()})}`;
                periodDescription = this.getTranslation('_ui_created');
                break;
            case Filters.FilterDeadlineInPeriod:
                start = `${this.getTranslation('_ui_all_item', {item: projectTypeName.toLocaleLowerCase()})}`;
                periodDescription = this.getTranslation('_ui_filter_with_deadline_in_period', {item: ''});
                break;
            case Filters.FilterNextMilestoneInPeriod:
                start = `${this.getTranslation('_ui_all_item', {item: projectTypeName.toLocaleLowerCase()})}`;
                periodDescription = this.getTranslation('_ui_filter_next_milestone_in_period', {item: ''});
                break;
            case Filters.FilterMilestoneInPeriod:
                start = `${this.getTranslation('_ui_all_item', {item: projectTypeName.toLocaleLowerCase()})}`;
                periodDescription = this.getTranslation('_ui_filter_milestone_in_period', {item: ''});
                break;
            case Filters.FilterPhase:
                start = `${projectTypeName} i ${phaseName}`;
                if(displayFilter.phase) start += ' "' + displayFilter.phase.name.toLocaleLowerCase() + '"';
                break;
            case Filters.FilterProjectDeadlineType:
                start = `${projectTypeName} med ${projectDeadlineTypeName}`;
                if(displayFilter.project_deadline_type) start += ' "' + displayFilter.project_deadline_type.getSmartName().toLocaleLowerCase() + '"';
                break;
            case Filters.FilterPhaseProgressType:
                start = `${projectTypeName} i ${this.getTranslation('_phase_progress_type_singular')}`;
                if(displayFilter.phase_progress_type) {
                    start += ' "' + displayFilter.phase_progress_type.name.toLocaleLowerCase() + '"';
                }
                break;
            case Filters.FilterNonPlannedInPeriod:
                start = `${this.getTranslation('_ui_all_item', {item: projectTypeName.toLocaleLowerCase()})}`;
                periodDescription = this.getTranslation('_ui_office_filter_non_planned_in_period', {item: ''});
                break;
        }

        let result = start;

        // Status
        if(!displayFilter.statuses) displayFilter.statuses = [];
        let statuses = displayFilter.statuses.map(status => status.name().toLocaleLowerCase());
        if(statuses.length < 4 && statuses.length > 0) {
            if(statuses.length > 1) {
                let lastStatus = statuses.pop();
                status = 'i ' + statuses.join(', ') + ' og ' + lastStatus;
            } else if(statuses.length == 1)
                status = 'i ' + statuses[0];
            else
                status = 'uden status';
            result += ' ' + status;
        }


        // Period
        switch(displayFilter.period_type) {
            case Filters.PeriodUserDefined:
                period = 'fra ' + displayFilter.getPeriodStartString() + ' til ' + displayFilter.getPeriodEndString();
                break;
            case Filters.PeriodLastWeek:
                period = this.getTranslation('_ui_date_last_week').toLocaleLowerCase();
                break;
            case Filters.PeriodToday:
                period = this.getTranslation('_ui_date_today').toLocaleLowerCase();
                break;
            case Filters.PeriodThisWeek:
                period = this.getTranslation('_ui_date_this_week').toLocaleLowerCase();
                break;
            case Filters.PeriodNextWeek:
                period = this.getTranslation('_ui_date_next_week').toLocaleLowerCase();
                break;
            case Filters.PeriodNext2Week:
                period = this.getTranslation('_ui_date_next_2_weeks').toLocaleLowerCase();
                break;
            case Filters.PeriodNextMonth:
                period = this.getTranslation('_ui_date_next_month').toLocaleLowerCase();
                break;
        }

        if(periodDescription) result += ' ' + periodDescription + ' ' + period;

        // Sort
        let sortDirection = displayFilter.sort_direction == 'asc' ? 'a-z' : 'z-a';
        switch(Filters.GetBaseSort(displayFilter.sort_type)) {
            case Filters.SortTitle:
                sort = this.getTranslation('_project_title').toLocaleLowerCase() + ' ' + sortDirection;
                break;
            case Filters.SortResponsible:
                sort = this.getTranslation('_ui_project_leader').toLocaleLowerCase() + ' ' + sortDirection;
                break;
            case Filters.SortDeadline:
                sort = this.getTranslation('_project_deadline').toLocaleLowerCase();
                break;
            case Filters.SortStatus:
                sort = this.getTranslation('_ui_status').toLocaleLowerCase()
                break;
            case Filters.SortNextMilestoneDeadline:
                sort = this.getTranslation('_display_projects_milestone').toLocaleLowerCase() + ' ' + this.getTranslation('_displays_project_milestones_deadline').toLocaleLowerCase();//'næste milepælsfrist';
                break;
            case Filters.SortNextMilestoneStatus:
                sort = this.getTranslation('_display_projects_milestone').toLocaleLowerCase() + ' ' + this.getTranslation('_milestone_status').toLocaleLowerCase() + ' ' + sortDirection; // 'næste milepælsstatus '+ sortDirection;
                break;
            case Filters.SortCategoryType:
                if (displayFilter.display) {
                    const sorts = this.sorts(displayFilter.display);
                    const sortMatch = sorts.find(item => item.id == displayFilter.sort_type);
                    if (sortMatch) {
                        sort = sortMatch.name.toLocaleLowerCase();
                        if (sortMatch.hasOrderDirection) {
                            sort = sort + ' ' + sortDirection;
                        }
                    }
                }
        }

        if(displayFilter.starred) result += ', ' + this.getTranslation('_ui_starred').toLocaleLowerCase();
        if(displayFilter.hand_up) result += ', '+ this.getTranslation('_ui_hand_up').toLocaleLowerCase();

        switch(displayFilter.include_archived_since_type) {
            case Filters.IncludeArchivedSincePeriodUserDefined:
                includeArchivedSince = `d. ${displayFilter.getIncludeArchivedSincePeriodString(this)}`;
                break;
            case Filters.IncludeArchivedSincePeriodToday:
                includeArchivedSince = this.getTranslation('_ui_date_today').toLocaleLowerCase(); //'i dag';
                break;
            case Filters.IncludeArchivedSincePeriodThisWeek:
                includeArchivedSince = this.getTranslation('_ui_date_this_week').toLocaleLowerCase();
                break;
            case Filters.IncludeArchivedSincePeriodPrev1Week:
                includeArchivedSince = this.getTranslation('_ui_date_past_week').toLocaleLowerCase();
                break;
            case Filters.IncludeArchivedSincePeriodPrev2Weeks:
                includeArchivedSince = this.getTranslation('_ui_date_past_2_weeks').toLocaleLowerCase();
                break;
            case Filters.IncludeArchivedSincePeriodThisMonth:
                includeArchivedSince =  this.getTranslation('_ui_date_this_month').toLocaleLowerCase();
                break;
            case Filters.IncludeArchivedSincePeriodThisYear:
                includeArchivedSince =  this.getTranslation('_ui_date_this_year').toLocaleLowerCase()
                break;
        }

        //result += ', inkludér fuldførte siden ' + includeArchivedSince;

        result += ', '+this.getTranslation('_ui_filter_sortet_on').toLocaleLowerCase()+' ' + sort;

        return  result;
    }

    public getEditorConfig(display: Display, result?: (config: DisplayFilterEditorFormConfigInterface) => void): DisplayFilterEditorFormConfigInterface {
        const config: DisplayFilterEditorFormConfigInterface = {};

        if (display == null) {
            // Not fit for editor config
            //console.warn('Filters.GetEditorConfig: Not fit, missing display or displays_settings');
            if (result) {
                result(config);
            }
            return config;
        }

        const phaseColumn = display.displays_settings.find(displaySetting => displaySetting.setting_id === Columns.Phase);
        if (phaseColumn && phaseColumn.getName() != '') {
            config.phaseName = phaseColumn.getName().toLocaleLowerCase();
        }
        config.projectDeadlineTypeName = this.getTranslation('_project_deadline').toLocaleLowerCase();

        config.showYearWheelPeriod = true;

        const projectTypeSettings = display.displays_settings.filter(displaySetting => {
            return displaySetting.setting_id === Settings.ProjectTypeIds;
        });
        if (projectTypeSettings.length) {
            const projectsService = AppInjector.getInjector().get(ProjectsService);
            const projectTypes: ProjectType[] = [];
            let callbackCounter = projectTypeSettings.length;
            const projectTypeCallback = (projectType: ProjectType) => {
                projectTypes.push(projectType);
                callbackCounter--;
                if (callbackCounter === 0) {
                    config.projectTypes = projectTypes;

                    // Find category types visible for project types
                    const categoryTypesMap = new Map<number, CategoryType>();
                    config.projectTypes?.forEach(projectType => {
                        projectType.category_types_project_types
                            ?.filter(categoryTypesProjectType => categoryTypesProjectType.visible)
                            ?.forEach(categoryTypesProjectType => {
                                categoryTypesMap.set(categoryTypesProjectType.category_type_id, categoryTypesProjectType.category_type);
                            });
                    });
                    config.categoryTypes = Array.from(categoryTypesMap.values());

                    if (result) {
                        result(config);
                    }
                }
            };
            projectTypeSettings.forEach(projectTypeSetting => {
                projectsService.getProjectType(Number(projectTypeSetting.value), projectTypeCallback);
            });
        }

        return config;
    }

    public getProjectName(displayFilter: DisplayFilter, config: DisplayFilterEditorFormConfigInterface): string {
        return this.getTranslation('_projects');
    }

}
