/**
 * Created by ModelParser
 * Date: 08-02-2019.
 * Time: 09:13.
 */
import {DisplayFiltersStatusDefinition,} from './definitions/DisplayFiltersStatusDefinition';

export class DisplayFiltersStatus extends DisplayFiltersStatusDefinition {

    constructor(json?: any) {
        super(json);
    }

}
