import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";

export class EditTagListConfiguration extends BaseEditorConfiguration {

    public labelName: string;

    constructor(labelName: string) {
        super();
        this.labelName = labelName;
    }

}
