import {AfterContentInit, Directive, Input} from '@angular/core';
import {DatatableComponent} from "@swimlane/ngx-datatable";

@Directive({
    selector: '[ngxDatatableExtension]',
})
export class NgxDatatableExtensionDirective implements AfterContentInit {

    @Input() table: DatatableComponent

    public ngAfterContentInit() {
        this.install(this.table);
    }

    private install(table: DatatableComponent) {
        table.onColumnResize = ({ column, newValue }: any) => {
            /* Safari/iOS 10.2 workaround */
            if (column === undefined) {
                return;
            }

            let idx: number;
            const cols = table._internalColumns.map((c, i) => {
                // c = { ...c }; DON'T RUIN MY PROTOTYPE!

                if (c.$$id === column.$$id) {
                    idx = i;
                    c.width = newValue;

                    // set this so we can force the column
                    // width distribution to be to this value
                    c.$$oldWidth = newValue;
                }

                return c;
            });

            table.recalculateColumns(cols, idx);
            table._internalColumns = cols;

            table.resize.emit({
                column,
                newValue
            });
        };
    }


}
