import {NgModule} from "@angular/core";
import {ToDosComponent} from "@app/pages/lists/to-dos/to-dos.component";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {TodoListModule} from "@app/shared/_ui/lists/todo-list/TodoList.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        TodoListModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ToDosComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class ToDosModule {}
