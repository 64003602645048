<div
    *ngIf="configuration.textMode == 'standard'"
    class="position-relative w-100 h-100"
>
    <div class="position-absolute w-100 h-100" style="max-width: 100vw;">
        <ul class="list-unstyled d-flex justify-content-start align-items-center rounded pb-1 ps-2 pe-2 -corner-item left text-small action-panel"
            [class.no-hover]="!value"
        >
            <li [ngbTooltip]="value ? ('_global_edit' | translate) : null" *ngIf="model">
                <a class="text-center me-1 ms-1"

                   (click)="openEditorDialog()">
                    <i class="fal " aria-hidden="true"
                       [class.fa-edit]="value"
                       [class.fa-plus-circle]="!value"
                    ></i></a>
            </li>
            <li [ngbTooltip]="(isExpanded ? ('_ui_show_less' | translate) : ('_ui_expand_lists' | translate)) + '...'"
                (click)="toggleIsExpanded()"
                *ngIf="value"
            >
                <a class="text-center me-1 ms-1">
                    <i class="fa-duotone"
                       [class.fa-circle-chevron-up]="isExpanded"
                       [class.fa-circle-chevron-down]="!isExpanded"
                       aria-hidden="true"></i>
                </a>
            </li>
        </ul>
    </div>

    <div class="rich-text-wrapper flex-grow-1"
         *ngIf="value"
         [class.ellipsis]="!isExpanded"
         [class.expanded]="isExpanded"
         [innerHTML]="value"></div>

</div>

<div
    *ngIf="configuration.textMode == 'rich'"
>

    <label
        *ngIf="configuration.label"
    >
        <span>{{configuration.label | translate}}</span>
        <span *ngIf="configuration.validator.required" class="text-danger">*</span>
    </label>

    <ul class="list-group">
        <li *ngIf="!configuration.validator.isValid()"
            class="list-group-item d-flex justify-content-between flex-row align-items-center validation-error"
        >
            <app-validation-warning-list [inline]="true" [validatorError]="configuration.validator"></app-validation-warning-list>
        </li>

        <li class="list-group-item" >
            <quill-editor
                [(ngModel)]="value"
                [debounceTime]="250"
                [placeholder]="('_ui_add_description' | translate) + '...'"
                [modules]="QuillEditorConfig"
                (onContentChanged)="save()"
                (onBlur)="onFocusChanged(false)"
                (onFocus)="onFocusChanged(true)"
            />
        </li>
    </ul>

</div>
