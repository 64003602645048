<div class="container pt-2 pb-2">

    <div class="list-group w-100" *ngIf="configuration">
        <div class="list-group-item " *ngIf="configuration">
            <div class="input-group">
                <input type="search" class="form-control form-control-sm"
                       autocomplete="off"
                       [placeholder]="placeholder ? placeholder : ('_ui_search' | translate) + '...' "
                       [(ngModel)]="searchValue"
                       (search)="search()"
                       (ngModelChange)="changed($event)"
                />
                <button role="button" (click)="reset()" class="btn -btn-danger btn-sm btn-outline-danger"><i
                    class="fal fa-times me-1" aria-hidden="true"></i>{{ ('_ui_reset' | translate) }}
                </button>
                <button role="button" (click)="search()" class="btn -btn-success btn-sm btn-outline-primary"><i
                    class="fal fa-search me-1" aria-hidden="true"></i>{{ '_ui_search' | translate }}...
                </button>
            </div>
        </div>
        <div class="wrap -scroll-y">

            <app-project-list [configuration]="configuration"
                              [reloadEvent]="reloadEmitter"
                              [listClass]="'grid-list full-list'"
                              *ngIf="configuration"
                              class="list-group-item p-0 w-100"></app-project-list>
        </div>
    </div>

    <ng-container *ngIf="projects">
        <ul class="list-group scroll-y w-100 " style="max-height: 100%">
            <li *ngFor="let item of projects"
                (click)="restore(item)"
                class="list-group-item d-flex flex-row align-items-center cursor-pointer">


                <div class="d-flex flex-column me-2 flex-grow-1">
                    <div class="d-flex align-items-center">
                        <span class="text-strong">{{ item.title || '-' }}</span>
                        <code class="code me-1 text-small ms-auto">{{ item.id }}</code>
                    </div>
                    <div class="d-flex align-items-center text-small">
                        <span class="d-flex align-items-center" *ngIf="item?.deletion">
                            <i class="fa-light fa-calendar-clock me-1"></i> <span>{{ item?.deletion.created | localizedDate }}
                            , {{ item?.deletion.created | date: 'HH:mm' }}</span>
                        </span>

                        <div class="d-flex flex-column ms-auto">
                            <app-user-with-icon
                                [showTooltip]="true"
                                [label]="('_ui_deleted_by' | translate)"
                                class="ms-1" [modelId]="item?.updated_by_id"
                                *ngIf="item?.updated_by_id"></app-user-with-icon>
                        </div>
                    </div>
                </div>

                <i class="fa-thin fa-trash-can-undo fa-2x  me-auto disabled"></i>
            </li>
        </ul>
    </ng-container>
</div>
