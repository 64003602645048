/**
 * Created by ModelParser
 * Date: 20-12-2018.
 * Time: 11:42.
 */
import {SupportLoginTokenDefinition,} from './definitions/SupportLoginTokenDefinition';

export class SupportLoginToken extends SupportLoginTokenDefinition {

    constructor(json?: any) {
        super(json);
    }

}
