import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    ViewChild
} from '@angular/core';
import {PageComponent} from "@app/pages/page.component";
import {Phase, PhaseProgressType, Project} from "@app/core/models";
import {Api} from "@app/core/http/Api/Api";
import {Settings} from "@app/pages/displays/display-kanban-progress/Settings";
import {ProjectStatusTypes, StatusTypes} from "@app/constants";
import {CdkDragEnd, CdkDragStart} from "@angular/cdk/drag-drop";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters} from "@app/pages/displays/display-kanban-progress/Filters";
import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {AvoidDeadlineTypeFilter} from "@app/shared/_ui/lists/project-list/OnScreenFilters/AvoidDeadlineTypeFilter";
import {ProjectFetcher, ProjectFetchRequest} from "@app/shared/_ui/lists/project-list/ProjectFetcher";
import {NonArchivedFilter} from '@app/pages/displays/display-kanban/OnScreenFilters/NonArchivedFilter';
import {ScreenshotHelper} from "@app/core/ScreenshotHelper/ScreenshotHelper";
import {
    ProjectStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectStatusPresetGenerator";
import {
    ProjectUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUseStatusRulesPresetGenerator";
import {
    ProjectDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectDepartmentPresetGenerator";
import {
    ProjectUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUserPresetGenerator";
import {ProjectUserTypes} from "@app/core/models/Project";
import {
    ProjectPhasePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectPhasePresetGenerator";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {PhaseProgressColumnType} from "@app/pages/displays/display-kanban-progress/ColumnTypes/PhaseProgressColumnType";
import {
    PhaseProgressTableColumn
} from "@app/pages/displays/display-kanban-progress/TableColumns/PhaseProgressTableColumn";
import {PhaseProgressColumn} from "@app/pages/displays/display-kanban-progress/Columns/PhaseProgressColumn";
import {ColumnTypes} from "@app/pages/displays/display-kanban-progress/ColumnTypes";
import {PhaseTableColumn} from "@app/pages/displays/display-kanban/TableColumns/PhaseTableColumn";

@Component({
    selector: 'app-display-kanban-progress',
    templateUrl: './display-kanban-progress.component.html',
    styleUrls: ['./display-kanban-progress.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayKanbanProgressComponent extends PageComponent implements OnInit {
    protected filtersSettings = new Filters();
    protected columnController = new ColumnController();

    // View bindings
    @ViewChild('contentContainer', {static: false}) contentContainer: ElementRef;
    public tableColumns: PhaseProgressTableColumn[] = [];
    public onColumnSortRenderEventEmitter = new EventEmitter();
    public onScreenFilters: BaseOnScreenFilter<Project>[] = [];

    // Data
    private phaseProgressTypes: Map<number, PhaseProgressType>;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
        this.shellService.setHeaderTitle(this.Constants.System.Loading);

        let multiColumnFilter = new NonArchivedFilter(false)
        multiColumnFilter.multiColumn = true;
        this.onScreenFilters.push(multiColumnFilter);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.buildColumns();
        this.filterGlobalService.setShowSearch(true);
        this.filterGlobalService.setShowUserSearch(true);
    }

    // <editor-fold desc="View Actions">

    public onDragStartedEvent($event: CdkDragStart<CardItem<Project>>) {
        const item = $event.source.data;
        this.tableColumns.forEach(column => {
            column.projectListConfiguration.allowCardItemDrop(item, null, (allow)=> {
                column.projectListConfiguration.dropDisabled = !allow;
            });
        });
        this.detectChanges();
    }

    public onDragEndedEvent($event: CdkDragEnd) {
        this.tableColumns.forEach(column => {
            column.projectListConfiguration.dropDisabled = false;
        });
        this.detectChanges();
    }

    public onCaptureScreenshotBtnClicked() {
        if (this.isCapturingScreenshot) {
            return;
        }
        this.isCapturingScreenshot = true;
        ScreenshotHelper.Capture(this.contentContainer.nativeElement, () => {
            this.isCapturingScreenshot = false;
            this.detectChanges();
        }, {selector:document.getElementById('klartboard')});
    }

    // </editor-fold>

    // <editor-fold desc="Load data">

    private projectFetcher: ProjectFetcher;
    public loadData() {
        // Validate method executable
        if (!this.phaseProgressTypes || !this.shellPageData.shellFilterGroup.activeFilter) {
            return;
        }

        // Cancel running fetchers
        this.projectFetcher.cancel();

        // Apply the latest filter and sort to columns
        this.tableColumns.forEach(column => {
            if (this.shellPageData.activePageColumnSort) {
                column.applySortItem(
                    this.shellPageData.activePageColumnSort,
                    this.filterGlobalService.getActiveSettings().activeSortDirection
                );
            } else if (this.shellPageData.shellFilterGroup.activeFilter.sort_type) {
                column.applySortItem(
                    new PageColumnSort(this.shellPageData.shellFilterGroup.activeFilter.sort_type),
                    this.shellPageData.shellFilterGroup.activeFilter.sort_direction
                );
            }
            column.applyFilter(this.shellPageData.shellFilterGroup.activeFilter, this.filtersSettings);
        })

        // Execute fetchers
        this.projectFetcher.execute();
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    private buildColumns() {
        this.columnController.addColumnTypes([
            new PhaseProgressColumnType(),
        ]);
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();
        this.columnController.loadColumns(this.displayId, () => {
            const phaseProgressIds = this.columnController.getColumns<PhaseProgressColumn>(ColumnTypes.PhaseProgress)
                .map(column => column.getFilterPhaseProgress());

            Api.phaseProgressTypes().get()
                .whereIn('id', phaseProgressIds)
                .find(phaseProgressTypes => {

                    // Save phase progress types to class
                    this.phaseProgressTypes = new Map();
                    phaseProgressTypes.forEach(phaseProgressType => this.phaseProgressTypes.set(phaseProgressType.id, phaseProgressType));

                    // Remove columns without category
                    this.columnController.getColumns<PhaseProgressColumn>(ColumnTypes.PhaseProgress)
                        .filter(column => !this.phaseProgressTypes.has(column.getFilterPhaseProgress()))
                        .forEach(column => this.columnController.removeColumn(column));

                    this.initialize();
                });
        });
    }

    private initialize() {
        this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
            this.tableColumns = this.columnController.getVisibleTableColumns() as PhaseProgressTableColumn[];
            this.setupColumns();
            this.loadData();
        }));

        // Use filter editor config to find category types for sorting
        this.filtersSettings.getEditorConfig(this.display, config => {
            // Convert category types to page column sort
            const categoryTypeColumns = config.categoryTypes?.map(categoryType => {
                return PageColumnSort.CreateWithSortId(Filters.SortCategoryTypeGenerator(categoryType));
            });

            // Convert project deadline types to page column sort
            const projectDeadlineTypeColumns = config.projectDeadlineTypes?.map(projectDeadlineType => {
                return PageColumnSort.CreateWithSortId(Filters.SortDeadlineGenerator(projectDeadlineType));
            });

            this.columnController.getColumns<PhaseProgressColumn>(ColumnTypes.PhaseProgress)
                .forEach(column => {
                    column.setPhaseProgress(this.phaseProgressTypes.get(column.getFilterPhaseProgress()));

                    // Append column sorts to columns
                    column
                        .getTableColumns()
                        .forEach(tableColumn => {
                            tableColumn.sortItems.push(...categoryTypeColumns, ...projectDeadlineTypeColumns);
                        });
                });

            // Add OnScreenFilters for "Without <deadlinetype>"
            config.projectDeadlineTypes?.map(projectDeadlineType => {
                // "Hidden" OnScreenFilters to limit columns from same dataset
                let filter = new AvoidDeadlineTypeFilter(false, projectDeadlineType);
                filter.visible = false;
                filter.multiColumn = false;
                this.onScreenFilters.push(filter);
            });

            // Load data
            this.tableColumns = this.columnController.getVisibleTableColumns() as PhaseProgressTableColumn[];
            this.setupColumns();
            this.loadData();
            this.initialized = true;

            // Subscribe to global listeners
            this.setupSubscriptions();
        });

        // Ensure phase cache to fast lookup for drop entering
        Phase.GetAll(() => {});
    }

    private setupColumns() {
        // Validate method
        if (!this.phaseProgressTypes) {
            return;
        }

        // Reset fetchers
        this.projectFetcher = new ProjectFetcher();
        this.projectFetcher.showAll = true; // MultiLists understøtter ikke limits
        this.subscribe(this.projectFetcher.onFinishEvent.subscribe(() => this.detectChanges()));

        const projectTypeIds = Settings.GetProjectTypeIds(this.settingsMap);

        this.shellService.setPageSettingsTaskTypeIds([]);
        this.shellService.setPageSettingsProjectTypeIds(Settings.GetProjectTypeIds(this.settingsMap));

        this.columnController.getColumns<PhaseProgressColumn>(ColumnTypes.PhaseProgress)
            .forEach(column => {
                column.getTableColumns<PhaseTableColumn>().forEach(tableColumn => {
                    tableColumn.projectListConfiguration
                        .setProjectTypeIds(projectTypeIds)
                        .setDepartment(this.department)
                        .addOnScreenFilters(this.onScreenFilters)
                        .setCreatePresetGenerators([
                            // Defaults
                            new ProjectStatusPresetGenerator(ProjectStatusTypes.Normal, StatusTypes.GREEN),
                            new ProjectUseStatusRulesPresetGenerator(true),
                            new ProjectDepartmentPresetGenerator(this.department.id),
                            new ProjectUserPresetGenerator(ProjectUserTypes.Responsible, this.user.id),

                            new ProjectPhasePresetGenerator(
                                Settings.GetPhaseIds(this.settingsMap)[0],
                                undefined,
                                true,
                                column.getFilterPhaseProgress()
                            ),
                        ]);

                    // Add sources to individual data fetchers
                    this.projectFetcher.addRequest(new ProjectFetchRequest(tableColumn.projectListConfiguration));
                });
            });
    }

    private setupSubscriptions() {
        this.subscribe(this.shellFilterGroup.onActiveFilterChangeEventSubscribe(activeDisplayFilter => {
            // Ensure all columns has synchronized active sort item
            this.tableColumns.forEach(column => column.activeSortItem = this.shellPageData.activePageColumnSort);
            this.onColumnSortRenderEventEmitter.emit();
            this.loadData();
        }));
        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(() => this.loadData()));
    }

    // </editor-fold>

}
