import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {Category, CategoryType, Color} from '@app/core/models';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings
    @Input() category: Category;

    // UI
    public showColor: boolean;
    public showTitle: boolean;
    public titleText: string;
    public color: Color;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.render();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        if (this.category.category_type == null && this.category.category_type_id > 0) {
            CategoryType.GetById(this.category.category_type_id, categoryType => {
                this.category.category_type = categoryType;
                if (this.category.category_type == null) { // Should never happen. But if, create empty category type
                    this.category.category_type = new CategoryType();
                }
                this.render();
            });
        }

        this.showTitle = this.category.category_type?.show_name && this.category.name?.length > 0;
        this.showColor = this.category.category_type?.show_color && this.category.color?.exists();
        this.titleText = this.category.name;
        this.color = this.category.color;

        if (this.category.color_id && !this.category.color) {
            Color.GetById(this.category.color_id, color => {
                this.category.color = color;
                if (this.category.color == null) { // Should never happen. But if, create empty color
                    this.category.color = new Color();
                }
                this.render();
            });
        }

        this.detectChanges();
    }

}
