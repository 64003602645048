import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {
    TaskListWithoutMilestoneColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/TaskListWithoutMilestoneColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns";

export class TaskListWithoutMilestoneTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TaskListWithoutMilestone;

    public column: TaskListWithoutMilestoneColumn;

}
