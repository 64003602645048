import {PageDisplaySetting} from "@app/pages/PageDisplaySetting";

export class Settings {

    public static EnableSubDisplay_Milestones   = 473;
    public static EnableSubDisplay_Users        = 474;
    public static EnableSubDisplay_Tasks        = 475;

    public static SubDisplayColumn_TaskEdit_Deadline_TaskDeadlineTypeId = 476;
    public static SubDisplayColumn_TaskEdit_Estimate_TaskEstimateTypeId = 490;

    private settingsMap: Map<number, PageDisplaySetting>;

    constructor(settingsMap: Map<number, PageDisplaySetting>) {
        this.settingsMap = settingsMap;
    }

    public isSubDisplayMilestonesEnabled(): boolean {
        return this.settingsMap.get(Settings.EnableSubDisplay_Milestones)?.getBoolean() ?? false;
    }

    public isSubDisplayUsersEnabled(): boolean {
        return this.settingsMap.get(Settings.EnableSubDisplay_Users)?.getBoolean() ?? false;
    }

    public isSubDisplayTasksEnabled(): boolean {
        return this.settingsMap.get(Settings.EnableSubDisplay_Tasks)?.getBoolean() ?? false;
    }

    public getSubDisplayColumn_TaskEdit_Deadline_TaskDeadlineTypeId(): number {
        // console.log('getSubDisplayColumn_TaskEdit_Deadline_TaskDeadlineTypeId : ', this.settingsMap.get(Settings.SubDisplayColumn_TaskEdit_Deadline_TaskDeadlineTypeId)?.getIntValue());
        return this.settingsMap.get(Settings.SubDisplayColumn_TaskEdit_Deadline_TaskDeadlineTypeId)?.getIntValue() ?? 0;
    }

    public getSubDisplayColumn_TaskEdit_Estimate_TaskEstimateTypeId(): number {
        return this.settingsMap.get(Settings.SubDisplayColumn_TaskEdit_Estimate_TaskEstimateTypeId)?.getIntValue() ?? 0;
    }

}
