/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TodoStatusDefinition} from './definitions/TodoStatusDefinition';
import {StatusTypes} from '@app/constants';

export class TodoStatus extends TodoStatusDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static createDefault() {
        let item = new TodoStatus();
        item.id = 0;
        item.status_id = StatusTypes.GREEN;
        return item;
    }

}
