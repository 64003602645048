/**
 * Created by ModelParser
 */
import {ProjectsDeadline} from '../ProjectsDeadline';
import {ProjectType} from '../ProjectType';
import {ProjectDeadlineTypesProjectType} from '../ProjectDeadlineTypesProjectType';
import {DisplayFilter} from '../DisplayFilter';
import {ProjectTypesStatusRule} from '../ProjectTypesStatusRule';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class ProjectDeadlineTypeDefinition extends BaseModel {
    name?: string;
    language_key?: string;
    projects_deadlines?: ProjectsDeadline[];
    project_types?: ProjectType[];
    project_deadline_types_project_types?: ProjectDeadlineTypesProjectType[];
    display_filters?: DisplayFilter[];
    project_types_status_rules?: ProjectTypesStatusRule[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.language_key;
            delete this.projects_deadlines;
            delete this.project_types;
            delete this.project_deadline_types_project_types;
            delete this.display_filters;
            delete this.project_types_status_rules;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.language_key != null) {
            this.language_key = data.language_key;
        }
        if (data.projects_deadlines != null) {
            this.projects_deadlines = data.projects_deadlines.map((i: any) => new ProjectsDeadline(i));
        }
        if (data.project_types != null) {
            this.project_types = data.project_types.map((i: any) => new ProjectType(i));
        }
        if (data.project_deadline_types_project_types != null) {
            this.project_deadline_types_project_types = data.project_deadline_types_project_types.map((i: any) => new ProjectDeadlineTypesProjectType(i));
        }
        if (data.display_filters != null) {
            this.display_filters = data.display_filters.map((i: any) => new DisplayFilter(i));
        }
        if (data.project_types_status_rules != null) {
            this.project_types_status_rules = data.project_types_status_rules.map((i: any) => new ProjectTypesStatusRule(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
