<ul
    class="list-unstyled navbar-nav square align-items-start justify-content-start {{className}}"
    [class.fullpage]="displayHorizontal"
    [class.nav]="displayHorizontal"
    [class.flex-column]="displayHorizontal"
    [class.w-100]="displayHorizontal"
>

    <li class=" nav-item d-flex {{className}} {{displayHorizontal ? 'w-100 flex-wrap' : ''}}"

        *ngFor="let displayLink of displayLinks"
        [class.text-small]="!navigateDashboard && false"
        [class.active-route]="displayLink.active"
    >
        <a
            class="nav-link position-relative {{displayHorizontal ? 'd-flex flex-row w-100 align-items-center justify-content-start' : ''}}"
            routerLinkActive="active"
            (click)="triggerClick(displayLink)"
            [routerLinkActiveOptions]="{ exact: true }"
            [openDelay]="2000"
            [ngbTooltip]="isMobile || displayHorizontal ? null : displayLink.display.name"
            container="body"
            placement="bottom"
        >
            <i [class]="displayLink.icon" [class.me-2]="displayHorizontal"></i>
            <!--                <span>{{displayLink.department.id}}</span>-->
            <span [class.header-navigation]="navigateDashboard">
                <span *ngIf="displayHorizontal" style="text-align: left;"> {{displayLink.display.name | translate}} <span
                    *ngIf="showDepartment" class="text-start">{{displayLink.department.name}}</span></span>
                <span *ngIf="!displayHorizontal">{{displayLink.display.name | translate}}</span>
                </span>

            <i *ngIf="!navigateDashboard  && displayLink.display.display_type_id == Constants.DisplayTypes.UserOverview"
               class="fal fa-arrow-circle-right ms-auto text-small"></i>
            <i *ngIf="displayLink.favorite && navigateDashboard" class="fal fa-2x fa-star ms-auto d-none"></i>

            <app-toggle-item
                *ngIf="activeDisplay"
                [active]="displayLink.display.id == activeDisplay.id && displayLink.department.id == activeDepartment.id"
                [iconColorInactive]="'text-black-25'"
                [iconColorActive]="'text-success'"
                [interactive]="false"
                icon="fa-check-circle"
                iconInactive="fa-circle"
                class="ms-auto me-2"
            ></app-toggle-item>

        </a>
    </li>
</ul>
