/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ProjectEstimateTypesProjectTypeDefinition,} from './definitions/ProjectEstimateTypesProjectTypeDefinition';

export class ProjectEstimateTypesProjectType extends ProjectEstimateTypesProjectTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
