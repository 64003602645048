import {NgModule} from "@angular/core";
import {UrlLinkComponent} from "@app/shared/_ui/url-link/url-link.component";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        UrlLinkComponent,
    ],
    exports: [
        UrlLinkComponent

    ],
    providers: [

    ],
})
export class UrlLinkModule {}
