import {NgModule} from "@angular/core";
import {RichTextEditorComponent} from "@app/shared/_forms/rich-text-editor/rich-text-editor.component";
import {QuillModule} from "ngx-quill";
import {TranslateModule} from "@ngx-translate/core";
import {UrlLinkModule} from "@app/shared/_ui/url-link/UrlLink.module";
import {ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        QuillModule,
        TranslateModule,
        UrlLinkModule,
        ReactiveFormsModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        RichTextEditorComponent,
    ],
    exports: [
        RichTextEditorComponent

    ],
    providers: [

    ],
})
export class RichTextEditorModule {}
