import {CSVDisplayRowInterface} from '@app/export/csv/CSVDisplayRowInterface';
import * as XLSX from 'xlsx';
import {CSVColumn} from '@app/export/csv/CSVColumn';

export class CSVExporter {

    private name: string;
    private columns: Map<number, CSVColumn> = new Map();
    private rows: any[][] = [];

    public constructor() {

    }

    public setName(value: string) {
        this.name = value;
    }

    public addRows(rows: CSVDisplayRowInterface[]) {
        rows.forEach((row, rowIndex) => {
            let columnIndex = 0;
            this.rows[rowIndex] = [];
            this.columns.forEach(((column, columnId) => {
                if (column.subColumns) {
                    column.subColumns.forEach(subColumn => {
                        this.rows[rowIndex][columnIndex++] = row.exportCSVColumn(column, subColumn);
                    });
                } else {
                    this.rows[rowIndex][columnIndex++] = row.exportCSVColumn(column);
                }
            }));
        });
    }

    public addColumns(csvColumns: CSVColumn[]) {
        this.columns.clear();
        csvColumns.forEach(column => this.columns.set(column.identifier, column));
    }

    public addColumn(csvColumn: CSVColumn) {
        this.columns.set(csvColumn.identifier, csvColumn);
    }

    public print() {
        // https://github.com/SheetJS/js-xlsx/blob/master/demos/angular2/src/app/sheetjs.component.ts
        let wordBook = XLSX.utils.book_new(); // https://github.com/SheetJS/js-xlsx
        let data: string[][] = [];

        const columnsNames: string[] = [];
        Array.from(this.columns.values()).forEach(column => {
            if (column.subColumns) {
                column.subColumns.forEach(subColumn => columnsNames.push(subColumn.name));
            } else {
                columnsNames.push(column.name);
            }
        });
        data.push(columnsNames);

        this.rows.forEach(row => {
            let newRow: string[] = [];
            let extraRows: string[][] = [];

            row.forEach((cell, colIndex) => {
                if (Array.isArray(cell)) { // Create extra row for each entry in the array

                    if (cell.length > 0) {
                        newRow.push(cell.shift());

                        cell.forEach((extraCell, extraRowIndex) => {

                            // Add extra row, if no already added by an earlier cell
                            if (extraRows.length <= extraRowIndex) extraRows.push([]);
                            let extraRow = extraRows[extraRowIndex];

                            // Expand extraRow
                            for (let i = extraRow.length; i < colIndex; i++) {
                                // https://podio.com/klartboard/softwareudvikling/apps/stories/items/392
                                extraRow.push(newRow[i]);
                            }

                            extraRow.push(extraCell);
                        });
                    } else {
                        newRow.push('');
                    }
                } else {
                    newRow.push(cell);
                }
            });

            // Complete end of extraRows
            extraRows.forEach(extraRow => {
                for (let i = extraRow.length; i < newRow.length; i++) {
                    // https://podio.com/klartboard/softwareudvikling/apps/stories/items/392
                    extraRow.push(newRow[i]);
                }
            });

            data.push(newRow);
            extraRows.forEach(row => data.push(row));
        });

        let workSheet = XLSX.utils.aoa_to_sheet(data, {
            cellDates: true,
            dateNF: 'dd/MM/yyyy'
        });

        // Adjust column width to fit content
        let workSheetColumns: any[] = [];
        for (let i = 0; i < columnsNames.length; i++) {
            const maxStringLength = data.reduce((max: number, cell: string[]) => {
                const value = cell[i];
                if (value === undefined || value === null) return max;
                return Math.max(max, value.toString().length);
            }, 0);
            // For the default font settings in Excel, 1 char -> 1 pt is a pretty good estimate.
            //workSheet.column(String.fromCharCode(65 + colIndex)).width(maxStringLength);
            workSheetColumns.push({wch: Math.min(30, maxStringLength)});
        }
        workSheet['!cols'] = workSheetColumns;

        XLSX.utils.book_append_sheet(wordBook, workSheet, this.name.substring(0, 31));
        XLSX.writeFile(wordBook, `${this.name}.xlsx`);
    }

    public static convertToDate(date: Date): Date {
        // https://github.com/SheetJS/sheetjs/issues/1332 Timezone + Summer/Winter offset issue
        date.setHours(12);
        return date;
    }

}
