/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {DisplaysSettingDefinition} from './definitions/DisplaysSettingDefinition';
import {OldApi} from '@app/http/Api';
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';

export class DisplaysSetting extends DisplaysSettingDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static CreatePseudo(settingId: number, name: string): DisplaysSetting {
        const item = new DisplaysSetting();
        item.setting_id = settingId;
        item.name = name;
        return item;
    }

    public getName(): string {
        const injector = AppInjector.getInjector().get(TranslateService);
        if (!this.name) {
            console.warn('DisplaysSetting missing name : ', this);
        }
        return this.name ? injector.instant(this.name) : '';
    }

    public post(callback?: () => void) {
        OldApi.displaysSettings().post(this).subscribe(response => {
            this.populate(response.resource);
            if (callback) callback();
        });
    }

    public patch(fields?: string[], callback?: () => void) {
        let data: {} = {};
        if (fields)
            for (let field of fields) data[field] = this[field];
        else
            data = this;
        OldApi.displaysSettings(this.id).patch(data).subscribe(response => {
            this.populate(response.resource, !!fields);
            if (callback) callback();
        });
    }

}
