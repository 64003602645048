import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";
import {CSVExporter} from "@app/export/csv/CSVExporter";

export class ProjectPhaseDateCell extends GenericCell {

    public exportCSV(secondary?: any): CSVCellValue {
        return this.row.project.item.current_phases_project?.getStartedDate()
            ? CSVExporter.convertToDate(this.row.project.item.current_phases_project.getStartedDate())
            : '';
    }

}
