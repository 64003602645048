import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {FocusableEntityType} from "@app/core/Focusable/FocusableEntityTypes";
import {FocusableService} from "@app/core/Focusable/Focusable.service";

@Component({
    selector: 'app-find-and-focus-btn',
    templateUrl: './find-and-focus-btn.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindAndFocusBtnComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() matchType!: FocusableEntityType;
    @Input() matchIdentifier!: number;

    // Bindings to view
    public isVisible = false;

    // Data
    private offset = 0;

    constructor(
        private cd: ChangeDetectorRef,
        private focusableService: FocusableService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.render();

        this.subscribe(this.focusableService.subscribeToTargetChanges(
            this.matchType,
            this.matchIdentifier,
            () => this.render())
        );
    }

    private render() {
        this.isVisible = this.focusableService.hasTargets(this.matchType, this.matchIdentifier);
        this.detectChanges();
    }

    // <editor-fold desc="View actions">

    public onFocusBtnClicked() {
        if(this.isVisible)
            this.focusableService.focus(this.matchType, this.matchIdentifier, this.offset++);
    }

    // </editor-fold>

}
