import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {PANEL_ANIMATION} from '@app/editor/panel.animation';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Display, Project} from '@app/core/models';
import AvailableEditors from '@app/editor/quick-editor/AvailableEditors';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {DisplayTypes} from '@app/constants';
import {DisplayService} from '@app/services/display.service';
import {Router} from '@angular/router';
import {ShellService} from '@app/services/ShellService/shell.service';
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {CardProjectConfiguration} from "@app/shared/_ui/cards/medium/card-project/card-project-configuration";

@Component({
    selector: 'app-create-project-redirect-planning-dialog',
    templateUrl: './create-project-redirect-planning-dialog.component.html',
    styleUrls: ['./create-project-redirect-planning-dialog.component.scss'],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation,
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0.5
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateProjectRedirectPlanningDialogComponent extends BaseModalComponent implements OnInit, EditorEvents<Project> {


    // Bindings to parent
    @Input() model: Project;
    @Input() title: string;

    // Bindings to view
    public planningDisplay: Display;
    public editorTypes = AvailableEditors.QuickCreateWithTitle();
    public card: CardItem<Project>;
    public waitForProjectSave = false;

    constructor(private modal: NgbActiveModal,
                private cd: ChangeDetectorRef,
                private displayService: DisplayService,
                private router: Router,
                private shellService: ShellService) {
        super(modal);
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.isOpen = true;

        this.displayService.getDisplaysWithSettings(displays => {
            this.planningDisplay = displays.find(display => {
                const checkDisplayType = [DisplayTypes.ProjectDetails].includes(display.display_type_id);
                const checkDepartment = display.departments_displays && display.departments_displays.find(departmentsDisplays =>
                    departmentsDisplays.department_id === this.shellService.getPageSettings()?.departmentId) != null;
                return checkDisplayType && checkDepartment;
            });
            this.detectChanges();
        });
    }



    public decline() {
        this.model?.delete();
        this.activeModal.close(false);
    }

    public accept() {
        this.waitForProjectSave = true;
        if(this.model && this.model.id != 0){
            this.openDoor();
            this.activeModal.close({project: this.model});
        }
        // this.openDoor();
        // this.activeModal.close({project: this.model});
    }

    public dismiss() {
        this.activeModal.dismiss();
        this.activeModal.close(false);
    }

    public onItemUpdated(item: Project): void {
        this.card = new CardItem<Project>(item, new CardProjectConfiguration());
        this.detectChanges();
        if(this.waitForProjectSave && this.model.id){
            this.openDoor();
            this.activeModal.close({project: this.model});
        }
    }

    private openDoor() {
        switch (this.planningDisplay.display_type_id) {
            case DisplayTypes.ProjectDetails:
                this.router.navigate(
                    [
                        '/app/displays/project-details/',
                        this.planningDisplay.id,
                        this.shellService.getPageSettings()?.departmentId,
                        0,
                        this.model.id,
                        this.shellService.getPageSettings()?.primaryDisplayId,
                    ], {queryParamsHandling: "merge"}
                );
                break;
        }
    }

}
