import {animate, state, style, transition, trigger} from '@angular/animations';

// https://stackoverflow.com/questions/40375258/can-you-move-your-animations-to-an-external-file-in-angular2
export const PANEL_ANIMATION = trigger('changeState', [
    state('open', style({
        opacity: '1',
    })),
    state('closed', style({
        opacity: '0',
        height: '3rem',
        right: '-5px'
    })),
    transition('open => closed', [
        animate('.3s ease-in')
    ]),
    transition('closed => open', [
        animate('0.1s ease-out')
    ])

]);
