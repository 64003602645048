import {NgModule} from "@angular/core";
import {
    CardVerticalEditCollectionSwitcherComponent
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/card-vertical-edit-collection-switcher.component";
import {CommonModule} from "@angular/common";
import {
    GenericVerticalEditCollectionModule
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/generic-vertical-edit-collection/GenericVerticalEditCollection.module";

@NgModule({
    imports: [
        CommonModule,
        GenericVerticalEditCollectionModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CardVerticalEditCollectionSwitcherComponent,
    ],
    exports: [
        CardVerticalEditCollectionSwitcherComponent

    ],
    providers: [

    ],
})
export class CardVerticalEditCollectionsModule {}
