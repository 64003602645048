/**
 * Created by ModelParser
 * Date: 09-12-2020.
 * Time: 14:53.
 */
import {CategoryDefinition} from './definitions/CategoryDefinition';
import {Api} from '@app/core/http/Api/Api';

export class Category extends CategoryDefinition {

    constructor(json?: any) {
        super(json);
    }

    private static AllCache: Category[];
    private static AllCallbacks: ((items: Category[]) => void)[] = [];

    public static GetAll(callback: ((items: Category[]) => void)) {
        if (this.AllCache) {
            callback(this.AllCache);
        } else if (this.AllCallbacks.length) {
            this.AllCallbacks.push(callback);
        } else {
            this.AllCallbacks.push(callback);
            Api.categories().get()
                .include('color')
                .include('department')
                .orderAsc('index_')
                .find(items => {
                    this.AllCache = items;
                    this.AllCallbacks.forEach(callback => callback(items));
                })
        }
    }

    public static GetByCategoryTypeId(categoryId: number, callback: ((items: Category[]) => void)) {
        this.GetAll(items => {
            callback(items.filter(c => c.category_type_id == categoryId));
        })
    }

}
