import {NgModule} from "@angular/core";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";
import {FilterUsersComponent} from '@app/shell/header/filters/filter-users/filter-users.component';
import {UserSearchModule} from '@app/shared/_forms/search/user-search/UserSearch.module';
import {UserWithIconModule} from '@app/shared/_elements/user-with-icon/UserWithIcon.module';

@NgModule({
    imports: [
        CommonModule,
        ToggleItemModule,
        TranslateModule,
        NgbTooltipModule,
        NgbPopoverModule,
        UserSearchModule,
        UserWithIconModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        FilterUsersComponent,
    ],
    exports: [
        FilterUsersComponent

    ],
    providers: [

    ],
})
export class FilterUsersModule {}
