import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {TaskListAllColumn} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/TaskListAllColumn";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {StatusTypes, TaskStatusTypes, TaskUserTypes} from "@app/constants";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {
    TaskListOpenColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/TaskListOpenColumn";

export class TaskListOpenCell extends BaseCell {

    public listConfiguration = new TaskListConfiguration();

    constructor(row: Row, column: TaskListOpenColumn) {
        super(row, column);

        const taskTypeIdsFilteredByProjectType = row.projectCard.item.project_type?.project_types_task_types
            ?.filter(projectTypesTaskType => projectTypesTaskType.visible)
            ?.map(projectTypesTaskType => projectTypesTaskType.task_type_id) ?? [];

        this.listConfiguration
            .setLimit(ListConfiguration.SmartLimit)
            .setShowProjectMiniCard(false)
            .setAcceptanceStatus(null)
            .setProject(row.projectCard.item)
            .setOpen(true)
            .setTaskTypeIds(taskTypeIdsFilteredByProjectType)
            .setCustomOnCardItemDragAddFunction((cardItem, fromConfiguration) => {
                const participants = cardItem.item.findUsersByTypeId(TaskUserTypes.Participant);
                if (participants.length) {
                    cardItem.item.removeUsers(TaskUserTypes.Participant, participants);
                }
            })
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                new TaskUserPresetGenerator(TaskUserTypes.Creator, AppInjector.getInjector().get(UsersService).user.id),
                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                new TaskUseStatusRulesPresetGenerator(true),
                new TaskProjectPresetGenerator(row.projectCard.item.id),
                ...row.projectCard.item.departments?.map(department => new TaskDepartmentPresetGenerator(department.id)) ?? [],
            ]);
    }

}
