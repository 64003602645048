import {Component, Input, OnInit} from '@angular/core';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {Task} from '@app/core/models/Task';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TasksService} from '@app/services/tasks.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {TaskType} from '@app/core/models/TaskType';

@Component({
    selector: 'app-change-task-type-dialog',
    templateUrl: './change-task-type-dialog.component.html',
    styleUrls: ['./change-task-type-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ]
})
export class ChangeTaskTypeDialogComponent extends BaseModalComponent implements OnInit {

    @Input() item: Task;

    public isLoading: boolean = true;
    public taskTypes: TaskType[] = [];

    constructor(private modal: NgbActiveModal, private tasksService: TasksService,) {
        super(modal);
    }

    ngOnInit() {
        this.tasksService.getTaskTypes(taskTypes => {
            this.taskTypes = taskTypes;
        });
        this.isLoading = false;
    }

    public accept() {
        this.item.task_type = undefined;
        this.item.setType(this.item.task_type_id);
        this.activeModal.close(true);
    }

}
