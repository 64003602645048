import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {TaskListWithoutMilestoneCell} from "@app/pages/displays/display-projects/Cells/TaskListWithoutMilestoneCell";
import {
    TaskListWithoutMilestoneTableColumn
} from "@app/pages/displays/display-projects/TableColumns/TaskListWithoutMilestoneTableColumn";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {TaskListNextMilestoneCell} from "@app/pages/displays/display-projects/Cells/TaskListNextMilestoneCell";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters, Filters as ProjectsFilters} from "@app/pages/displays/display-projects/Filters";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {
    Projects_TaskListWithoutMilestoneDefaultSortSettingValue, Projects_TaskListWithoutMilestoneTaskTypesSettingValue
} from "@app/core/http/Api/Api";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";

export class TaskListWithoutMilestoneColumn extends GenericColumn<TaskListWithoutMilestoneCell>
    implements ColumnDataFetcherInterface, SortableColumnInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TaskFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    // <editor-fold desc="Sorting">

    public implementsSorting: true = true;

    public applyRowSort(row: ProjectsDisplayRow, tableColumn: BaseTableColumn) {
        row.getCell<TaskListNextMilestoneCell>(this)
            .listConfiguration
            .setOrderBy(
                this.getOrderBy(tableColumn.activeSortTypeId, tableColumn.activeSortDirection)
            );
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new TaskFetcher(undefined, undefined, undefined, column.name);
    }

    public createTableColumns(): TaskListWithoutMilestoneTableColumn[] {
        const item = new TaskListWithoutMilestoneTableColumn(this);
        this.setTableColumnDefaults(item);

        item.sortItems = [
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortProjectTaskTitle),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortProjectTaskStatus),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortProjectTaskDeadlineFilter),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortProjectTaskIndex),
        ];

        item.activeSortTypeId = this.getStandardSortType();
        item.activeSortDirection = this.getStandardSortDirection();

        return [item];
    }

    public createCell(row: ProjectsDisplayRow): TaskListWithoutMilestoneCell {
        const cell = new TaskListWithoutMilestoneCell(row, this);

        cell.listConfiguration
            .setOrderBy(this.getOrderBy(this.getStandardSortType(), this.getStandardSortDirection()))
            .addOnScreenFilters(this.columnType.onScreenFilters);

        this.dataFetcher.addRequest(new TaskFetchRequest(cell.listConfiguration));
        return cell;
    }

    private getOrderBy(type: string, direction: string): any[] {
        let orderBy: any[] = [];
        switch (type) {
            case Filters.SortProjectTaskTitle:
            case 'title':
                orderBy = [['title', direction]];
                break;
            case Filters.SortProjectTaskStatus:
            case 'status':
                orderBy = [
                    ['main_status.status_id', direction  == 'asc' ? 'desc' : 'asc'],
                    ['tasks_deadline.deadline.date', 'null'],
                    ['tasks_deadline.deadline.date', 'asc'],
                    ['title', 'asc'],
                ];
                break;
            case Filters.SortProjectTaskDeadlineFilter:
            case 'deadline':
                orderBy = [
                    ['tasks_deadline.deadline.date', 'null'],
                    ['tasks_deadline.deadline.date', 'asc'],
                ];
                break;
            case Filters.SortProjectTaskIndex:
                orderBy = [['projects_task.index_', direction]];
                break;
        }
        return orderBy;
    }

    private getStandardSortType(): string {
        return this.settings.get(ColumnTypeSettings.TaskListWithoutMilestoneDefaultSort)
                ?.getObject<Projects_TaskListWithoutMilestoneDefaultSortSettingValue>()
                ?.type
            ?? ProjectsFilters.SortProjectTaskStatus;
    }

    private getStandardSortDirection(): string {
        return this.settings.get(ColumnTypeSettings.TaskListWithoutMilestoneDefaultSort)
                ?.getObject<Projects_TaskListWithoutMilestoneDefaultSortSettingValue>()
                ?.direction
            ?? 'desc';
    }

    public getTaskTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.TaskListWithoutMilestone_TaskTypes)
                ?.getObject<Projects_TaskListWithoutMilestoneTaskTypesSettingValue>()
                ?.taskTypeIds
            ?? [];
    }

    public implementsCSVExport = true;

}
