import {GenericTableColumn} from "@app/pages/displays/display-cases/TableColumns/GenericTableColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {AppointmentListConfiguration} from "@app/shared/_ui/lists/appointment-list/AppointmentListConfiguration";
import {AppointmentListCell} from "@app/pages/displays/display-cases/Cells/AppointmentListCell";
import {TableColumns} from "@app/pages/displays/display-cases/TableColumns";

export class AppointmentListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.AppointmentList;

    public getListConfigurationForRow(row: BaseRow): AppointmentListConfiguration {
        return row.getCell<AppointmentListCell>(this.column).listConfiguration;
    }

}
