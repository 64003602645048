import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {TaskListAllColumn} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/TaskListAllColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns";

export class TaskListAllTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TaskListAll;

    public column: TaskListAllColumn;

}
