import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output, SimpleChanges
} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {Category, User} from '@app/core/models';

@Component({
    selector: 'app-filter-users',
    templateUrl: './filter-users.component.html',
    styleUrls: ['./filter-users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterUsersComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @Input() users: User[] = [];
    @Input() tooltip: string;
    @Output() onUsersChangeEvent = new EventEmitter<User[]>();

    public usersMap: Map<number, Category>;

    public filtersAltered = false;

    constructor(private cdr_: ChangeDetectorRef) {
        super();
        this.cdr = this.cdr_;
    }

    ngOnInit(): void {
        // if (this.categories && !this.userTypes) {
        //     this.mapUsers();
        // }
        if(!this.tooltip){
            this.tooltip = this.translateService.instant('_ui_display_reaction_filter_user');
        }
    }

    private mapUsers() {
        this.usersMap = new Map<number, User>();
        if (this.users) {
            this.users.forEach(user=> {
                this.usersMap.set(user.id, user);
            });
        }
        this.render();
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.users) {
            this.mapUsers();
        }
    }

    private render() {
        if(this.usersMap) {
            this.detectChanges();
        }
    }


    activateFilters() {
        this.onUsersChangeEvent.emit(this.users);
        this.filtersAltered = false;
    }


    public toggleUser(user: User) {
        if (!this.usersMap.has(user.id)) {
            this.usersMap.set(user.id, user);
            this.users.push(user);
        } else {
            this.usersMap.delete(user.id);
            this.users.splice(this.users.findIndex(c => c.id == user.id), 1);
        }
        this.filtersAltered = true;
        this.activateFilters();
        this.detectChanges();
    }

    reset() {
        this.users = [];
        this.mapUsers();
        this.filtersAltered = true;
        this.activateFilters();
    }


}
