import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    NextMilestoneCardTableColumn
} from "@app/pages/displays/dashboard/dashboard-projects/TableColumns/NextMilestoneCardTableColumn";
import {NextMilestoneCardCell} from "@app/pages/displays/dashboard/dashboard-projects/Cells/NextMilestoneCardCell";

export class NextMilestoneCardColumn extends BaseColumn {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new NextMilestoneCardTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: BaseRow): BaseCell {
        return new NextMilestoneCardCell(row, this);
    }

}
