/**
 * Created by ModelParser
 * Date: 20-12-2018.
 * Time: 11:42.
 */
import {ProjectTypesProjectUserTypeDefinition,} from './definitions/ProjectTypesProjectUserTypeDefinition';

export class ProjectTypesProjectUserType extends ProjectTypesProjectUserTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
