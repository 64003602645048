import {NgModule} from "@angular/core";
import {FilterGlobalsComponent} from "@app/shell/header/filters/filter-globals/filter-globals.component";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {CategoriesSelectorModule} from "@app/shared/_elements/categories-selector/CategoriesSelector.module";
import {EmojiPickerModule} from "@app/shared/_elements/emoji-picker/EmojiPicker.module";
import {FilterSearchModule} from "@app/shell/header/filter-search/FilterSearch.module";
import {FilterStatusModule} from "@app/shell/header/filters/filter-status/FilterStatus.module";
import {CommonModule} from "@angular/common";
import {FilterUsersModule} from '@app/shell/header/filters/filter-users/FilterUsers.module';

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule,
        CategoriesSelectorModule,
        EmojiPickerModule,
        FilterSearchModule,
        FilterStatusModule,
        FilterUsersModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        FilterGlobalsComponent,
    ],
    exports: [
        FilterGlobalsComponent

    ],
    providers: [

    ],
})
export class FilterGlobalsModule {}
