<div
    *ngIf="showStatusEditor"
    class="d-flex justify-content-between flex-row"
>
    <app-status-editor
        class="me-1"
        [model]="item"
        [configuration]="statusEditorConfiguration"
    />
</div>

<div class="d-flex align-items-center flex-grow-0 icon-row justify-content-start">
    <ul
        class="list-unstyled d-flex flex-row justify-content-between m-0 w-100 align-items-center gap-2"
        style="height: 24px;"
    >
        <li
            *ngIf="showArchivedEditor"
            class="pe-1"
        >
            <app-archived-editor
                [model]="item"
                [configuration]="archivedEditorConfiguration"
            />
        </li>
        <li
            *ngIf="showReactionListEditor"
            class="pe-1"
        >
            <app-reaction-list-editor
                [model]="item"
                [configuration]="reactionListEditorConfiguration"
            />
        </li>

        <!-- Copy -->
        <li class="pe-1 ms-2 ps-2 -pe-1 border-start">
            <app-toggle-item
                (click)="onCopyBtnClicked()"
                [ngbTooltip]="('_ui_copy' | translate) + '...'"
                [iconWeightActive]="'fal'"
                [icon]="'fa-copy'"
                class="cursor-pointer"
            ></app-toggle-item>
        </li>

        <!-- Repeatable -->
        <li
            *ngIf="showCanCopyEditor"
            class="pe-1"
        >
            <app-can-copy-editor
                [model]="item"
                [configuration]="canCopyEditorConfiguration"
            />
        </li>

        <!-- Private -->
        <li
            *ngIf="showIsPrivateEditor"
            class="pe-1 me-1 pe-1 border-end"
        >
            <app-is-private-editor
                [model]="item"
                [configuration]="isPrivateEditorConfiguration"
            />
        </li>

        <!-- Calculated fields & estimates -->
        <li
            *ngIf="showCalculatedFields"
            class="d-flex pe-1 me-2 pe-2 pe-1 border-end"
        >
            <!-- Calculated fields -->
            <ng-container *ngFor="let calculatedFieldsTask of item.calculated_fields_tasks">
                <app-task-calculated-field
                    class="me-1 w-auto"
                    [task]="item"
                    [calculatedField]="calculatedFieldsTask.calculated_field"
                    [calculatedFieldsTask]="calculatedFieldsTask"></app-task-calculated-field>
            </ng-container>
        </li>

        <li
            class="pe-1 d-flex"
            *ngIf="showValidationWarning && validator">
            <app-validation-warning-list
                [inline]="false"
                [validatorError]="validator"
            />
        </li>

        <li class="pe-1">
            <app-find-and-focus-btn
                [matchType]="FocusableEntityTypes.Task"
                [matchIdentifier]="item.id"
            />
        </li>

    </ul>

</div>
