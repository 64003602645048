<app-department-search
    (itemSelected)="selectDepartment($event)"></app-department-search>

<div class="wr"
     ngbDropdown
     *ngIf="false"
     placement="left"
     container="body"
     autoClose="true">

    <a
            [ngbTooltip]="('_ui_select_multiple' | translate)"
            class=" btn btn-outline-primary ms-1 no-caret"
            ngbDropdownToggle
            container="body"
            [autoClose]="true"
            placement="auto"

    >
        <i class="fal fa-users"></i>
    </a>

    <ul
            ngbDropdownMenu
            aria-labelledby="create-item-dropdown"
            class="list-unstyled"
    >
        <li class="dropdown-item" *ngFor="let department of departments" (click)="selectDepartment(department)">
            {{department.name}}
        </li>

    </ul>
</div>
