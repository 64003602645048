<div class="year-wheel-item {{className}}"
     *ngIf="(!model.width || model.width > 10) "

     [style.width.px]="model.width ? model.width : null"
     [style.left.px]="model.left">

    <!-- Project -->
    <app-year-wheel-item-display-project
        *ngIf="model.item.constructor.name == Constants.ItemTypes.Project"
        [item]="$any(model)"
    />

    <!-- Phases -->
    <app-year-wheel-item-display-phase
        *ngIf="model.item.constructor.name == Constants.ItemTypes.PhasesProject"
        [item]="$any(model)"
    />

    <!-- Milestones --->
    <app-year-wheel-item-display-milestone
        *ngIf="model.item.constructor.name == Constants.ItemTypes.Milestone && model.left > 0"
        [item]="$any(model)"
    />

    <!-- Tasks --->
    <app-year-wheel-item-display-task
        *ngIf="model.item.constructor.name == Constants.ItemTypes.Task && model.left > 0"
        [item]="$any(model)"
    />

</div>
