import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {Department, TaskTemplate, TaskTemplatesUser, User} from "@app/core/models";
import {EstimateUnit} from '@app/core/models/EstimateUnit';
import {Api} from "@app/core/http/Api/Api";
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '@app/services/snackbar.service';

@Component({
    selector: 'app-template-task-row',
    templateUrl: './template-task-row.component.html',
    styleUrls: ['./template-task-row.component.scss'],

})
export class TemplateTaskRowComponent extends BaseDisplayComponent implements OnInit {

    @Input() taskTemplate: TaskTemplate;
    @Output() removeEvent: EventEmitter<TaskTemplate> = new EventEmitter();
    @Input() expanded: boolean = true;

    taskDeadlineTypes = [
        {id: 0, name: '_admin_milestone_plan_task_deadline_type_0', type: 'NONE', title: ''},
        {id: 1, name: '_admin_milestone_plan_task_deadline_type_1', type: 'DAYS_BEFORE_MILESTONE', title: ''},
        {id: 2, name: '_admin_milestone_plan_task_deadline_type_2', type: 'DAYS_AFTER_MILESTONE', title: ''},
        {id: 3, name: '_admin_milestone_plan_task_deadline_type_3', type: 'PERCENT_BEFORE_MILESTONE', title: ''},
    ];
    public estimateUnits: EstimateUnit[];

    public selectedUsers: User[];
    public departments: Department[];


    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        EstimateUnit.GetAll(items => {
            this.estimateUnits = items;
            if (this.taskTemplate && !this.taskTemplate.estimate_unit_id && items) {
                this.taskTemplate.estimate_unit_id = items[0].id;
            }
        });
        Department.GetAll(departments => this.departments = departments);
    }

    userSelected($event: any) {
        if ($event) {
            console.log('userSelected() : ', $event);
            if (!this.taskTemplate.users) this.taskTemplate.users = [];
            this.taskTemplate.users.push($event);
            this.saveTaskTemplateUsers();
        }
    }

    removeUser(u: TaskTemplatesUser) {
        console.log('removeUser() : ', u)
        this.taskTemplate.users.splice(this.taskTemplate.users.findIndex(ut => ut.id == u.id), 1);
        if (!this.taskTemplate.users) this.taskTemplate.users = [];
        this.saveTaskTemplateUsers();
    }


    removeTask() {
        this.removeEvent.emit(this.taskTemplate);
    }


    private emitUpdated(response: TaskTemplate) {
        if (this.taskTemplate) {
            if (this.taskTemplate.id == 0 && response) {
                this.taskTemplate.id = response.id;
            }
        }
        // console.log('emitUpdated() : this.taskTemplate : ', this.taskTemplate);
        const translateService = AppInjector.getInjector().get(TranslateService);
        AppInjector.getInjector().get(SnackbarService).add(
            translateService.instant('_ui_item_saved', {item: translateService.instant('_milestone_plan')}),
        );
    }


    createTaskTemplate() {
        const title = (this.taskTemplate?.title)?.trim();
        if (title && title.length > 0) {
            Api.taskTemplates().post().save(this.taskTemplate, (r) => {
                this.taskTemplate.id = r[0].id;
                console.log('createTaskTemplate : this.taskTemplate : ', this.taskTemplate);
                this.emitUpdated(this.taskTemplate);
            })
        }
    }

    saveTaskTemplateTitle() {
        const title = (this.taskTemplate?.title)?.trim();
        if (title && title.length > 0) {
            Api.taskTemplates()
                .titlePatchPatchById(this.taskTemplate.id)
                .title(this.taskTemplate.title)
                .save(null, this.emitUpdated);
        }
    }

    // Task.purpose = URL
    saveTaskTemplatePurpose($event?: any) {
        const purpose = (this.taskTemplate?.purpose);
        Api.taskTemplates()
            .purposePatchPatchById(this.taskTemplate.id)
            .purpose(this.taskTemplate.purpose)
            .save(null, this.emitUpdated);
    }

    // Task.delivery_description = Note
    saveTaskTemplateDeliveryDescription($event?: any) {
        Api.taskTemplates()
            .deliveryDescriptionPatchPatchById(this.taskTemplate.id)
            .delivery_description(this.taskTemplate.delivery_description)
            .save(null, this.emitUpdated);
    }

    private saveTaskTemplateUsers() {
        Api.taskTemplates()
            .patchById(this.taskTemplate.id)
            .save(this.taskTemplate, this.emitUpdated)
    }

    saveTaskTemplateEstimateValue() {
        Api.taskTemplates()
            .estimatePatchPatchById(this.taskTemplate.id)
            .value(this.taskTemplate.estimate_value)
            .unit_id(this.taskTemplate.estimate_unit_id)
            .save(null, this.emitUpdated);
    }

    saveTaskTemplateEstimateUnit() {
        Api.taskTemplates()
            .estimatePatchPatchById(this.taskTemplate.id)
            .value(this.taskTemplate.estimate_value)
            .unit_id(this.taskTemplate.estimate_unit_id)
            .save(null, this.emitUpdated);
    }

    saveTaskTemplateDeadlineType() {
        Api.taskTemplates()
            .deadlinePatchPatchById(this.taskTemplate.id)
            .value(this.taskTemplate.deadline_value)
            .type(this.taskTemplate.deadline_type)
            .save(null, this.emitUpdated);

    }

    saveTaskTemplateDeadlineValue() {
        Api.taskTemplates()
            .deadlinePatchPatchById(this.taskTemplate.id)
            .value(this.taskTemplate.deadline_value)
            .type(this.taskTemplate.deadline_type)
            .save(null, this.emitUpdated);
    }

    showToast() {
        const translateService = AppInjector.getInjector().get(TranslateService);
        AppInjector.getInjector().get(SnackbarService).add(
            translateService.instant('_ui_item_saved', {item: translateService.instant('_milestone_plan')}),
        );
    }
}
