import {NgModule} from "@angular/core";
import {ColumnSwitcherComponent} from "@app/editor/quick-editor/columns/column-switcher/column-switcher.component";
import {CommonModule} from "@angular/common";
import {TextColumnModule} from "@app/editor/quick-editor/columns/generic/text-column/TextColumn.module";
import {TemplateColumnModule} from "@app/editor/quick-editor/columns/generic/template-column/TemplateColumn.module";
import {
    SortableTextColumnModule
} from "@app/editor/quick-editor/columns/generic/sortable-text-column/SortableTextColumn.module";

@NgModule({
    imports: [
        CommonModule,
        TextColumnModule,
        TemplateColumnModule,
        SortableTextColumnModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ColumnSwitcherComponent,
    ],
    exports: [
        ColumnSwitcherComponent

    ],
    providers: [

    ],
})
export class ColumnSwitcherModule {}
