import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit} from '@angular/core';
import {TaskListConfiguration} from '@app/shared/_ui/lists/task-list/TaskListConfiguration';
import {PageComponent} from '@app/pages/page.component';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {ActivatedRoute} from '@angular/router';
import {StatusTypes, TaskStatusTypes, TaskUserTypes} from '@app/constants';
import Helpers from '@app/core/helpers';
import {Location} from '@angular/common';
import {
    TaskTitlePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskTitlePresetGenerator";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";

@Component({
    selector: 'app-tasks',
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default, // TO-DO: Changedetection
})
export class TasksComponent extends PageComponent implements OnInit {
    protected filtersSettings?: FiltersInterface;
    protected columnController?: ColumnController;

    public configuration: TaskListConfiguration;
    public searchValue: string;
    public reloadEmitter = new EventEmitter();

    txtQueryChanged: Subject<string> = new Subject<string>();

    constructor(private location: Location, private activatedRoute: ActivatedRoute, private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;

        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe((f) => {
            this.search();
        }));

        this.txtQueryChanged
            .pipe(debounceTime(1000), distinctUntilChanged()) // wait 1 sec after the last event before emitting last event &&  only emit if value is different from previous value
            .subscribe((value: string) => {
                this.search();
            });
    }

    ngOnInit() {
        this.usersService.currentUser$.subscribe((activeUser)=> {
            if(activeUser) {

                // Find afdeling fra indsitllinger
                this.usersService.getUserDepartment((department)=>{
                    if(department){
                        this.shellService.setPageSettings(department.id, activeUser.id, undefined, undefined, undefined, Helpers.encodeUri(this.location.path()));
                    }
                })

                this.configuration = new TaskListConfiguration();

                this.shellService.setHeaderTitle(this.translateService.instant('_tasks'), null, false, true, false);
                this.configuration.setShowCreateNew(true);
                this.configuration.showChangeType = true;
                this.configuration.listClassName = 'table-mode';
                this.configuration
                    .setOrderBy([['title', 'asc']])
                    .setLimit(50)
                    .setUseGlobalFilter(true)
                    .setCreatePresetGenerators([
                        new TaskUserPresetGenerator(TaskUserTypes.Creator, activeUser.id),
                        new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                        new TaskUseStatusRulesPresetGenerator(true),
                        new TaskTitlePresetGenerator(() => this.searchValue ?? ''),
                    ]);

                const params = this.activatedRoute.snapshot.params;
                switch (params?.displayMode) {
                    case 'personal':
                        this.configuration
                            .setUser(activeUser)
                            .setUserTypeId(TaskUserTypes.Participant)
                            .setArchived(false)
                            .setNotArchivedForUserId(activeUser.id)
                            .setUseGlobalFilter(true);
                        break;
                }
            }
        });
    }

    public search() {
        this.configuration.setSearch(this.searchValue);
        this.reloadEmitter.emit();
    }

    public reset() {
        this.searchValue = null;
        this.search();
    }

    changed(query: string) {
        this.txtQueryChanged.next(query);
    }

}
