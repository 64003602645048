/**
 * Created by ModelParser
 */
import {Task} from '../Task';
import {User} from '../User';
import {Project} from '../Project';
import {Milestone} from '../Milestone';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class HandUpDefinition extends BaseModel {
    task_id?: number;
    task?: Task;
    user_id?: number;
    user?: User;
    project_id?: number;
    project?: Project;
    milestone_id?: number;
    milestone?: Milestone;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.task_id;
            delete this.task;
            delete this.user_id;
            delete this.user;
            delete this.project_id;
            delete this.project;
            delete this.milestone_id;
            delete this.milestone;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.task_id != null) {
            this.task_id = data.task_id;
        }
        if (data.task != null) {
            this.task = new Task(data.task);
        }
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.project_id != null) {
            this.project_id = data.project_id;
        }
        if (data.project != null) {
            this.project = new Project(data.project);
        }
        if (data.milestone_id != null) {
            this.milestone_id = data.milestone_id;
        }
        if (data.milestone != null) {
            this.milestone = new Milestone(data.milestone);
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
