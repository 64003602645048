<div #cardContainer
     class="card p-1 cursor-default" *ngIf="model && initialized">
    <div class="d-flex w-100 font-size-lg  mb-1 align-items-center justify-content-center">
        <div
            class="color-label badge align-content-center justify-content-center align-items-center d-flex me-1 text-body"
            style="width: 32px; height: 32px;">
            <span class="align-self-center w-100 h-100"><i class="office-logo"></i></span>
        </div>

        <div class="title p-0 font-weight-bold col -border-bottom -border-light align-self-center d-flex">
            <input class="form-control" [(ngModel)]="model.title" *ngIf="editable"/>
            <span *ngIf="!editable" class="pt-0 align-self-center">{{model.title}}</span>
        </div>

        <a class="text-danger remove d-flex flex-row align-items-center  ms-1 me-1" (click)="triggerRemove(model)"
           *ngIf="removable">
            <i class="fal fa-trash-alt"></i>
        </a>

    </div>
    <div class="d-flex justify-content-between font-size-md pt-1 pb-1 mb-1" *ngIf="!editable">
        <ul class="d-flex list-unstyled m-0">
            <li>
                <small>{{'Office 365 Event' | translate}}</small>
            </li>
        </ul>
        <div class="date m-0" *ngIf="!model.is_all_day"><i
            class="fal fa-calendar me-1"></i>{{model.start | date:'HH:mm'}} - {{model.end | date:'HH:mm'}}</div>
    </div>

</div>
