<label
    *ngIf="configuration?.label"
>
    <span>{{configuration.label | translate}}</span>
    <span *ngIf="configuration.validator.required" class="text-danger">*</span>
</label>

<input
    [(ngModel)]="value"
    type="number"
    class="form-control"
    (blur)="save()"
/>
