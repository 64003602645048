
<ul *ngIf="logs" class="list-unstyled list-group d-flex" style="max-height: calc(100vh - 380px)" >

    <li class="list-group-item p-2 scroll-y scroll-container">
        <ul *ngIf="logs" class="list-unstyled list-group">
            <li *ngFor="let log of logItemList" class="list-group-item">

                <small class="d-flex justify-content-between align-items-center border-bottom pb-1 mb-1 opacity-50">
                    <span class="date"><span class="badge circle" severity="{{log.activeLog.severity}}"></span> {{log.activeLog.created | localizedDate: 'microDate'}} {{log.activeLog.created | localizedDate: 'HH:mm:ss'}}</span>
                    <span class="text-capitalize-firstletter" *ngIf="log.activeLog.created_by">{{log.activeLog.created_by.name}}</span>
                </small>

                <ng-template #htmlContent>
                    <code class="text-small">{{log | json}}</code>
                </ng-template>


                <div class="d-flex align-items-center" (click)="log.showDetails = !log.showDetails">
                    <div [innerHTML]="log.activeLog.description"
                     class="text-capitalize-firstletter log-description" [ngbTooltip]="null"></div>

                    <div class="fal ms-auto align-self-center"
                         *ngIf="log.logs.length > 1"
                        [class.fa-angle-down]="log.showDetails"
                        [class.fa-angle-up]="!log.showDetails"
                    ></div>
                </div>

                <ng-container *ngIf="log.logs.length > 1 && log.showDetails">
                <ul class="list-unstyled opacity-50" *ngFor="let l of log.logs">
                    <li *ngIf="l.id != log.activeLog.id"><div [innerHTML]="l.description" class="text-capitalize-firstletter log-description" ></div></li>
                </ul>
                </ng-container>
            </li>

        </ul>
    </li>

    <li *ngIf="logs && itemsCount - logs.length != 0" class="list-group-item d-flex">
        <button class="btn btn-sm btn-success w-100 ms-auto" (click)="expand()"  type="button">
            <span >{{'_ui_show_more' | translate}}</span>...
        </button>
    </li>
</ul>

