<h5 >{{'_admin_milestone_plan_holiday' | translate}}</h5>
<ul class="list-unstyled list-group" *ngIf="holidayItems">
    <li class="list-group-item d-flex align-items-center justify-content-start cursor-pointer"
        (click)="toggleAll()">

        <i class="fa fa-check-circle text-success" *ngIf="selectedHolidays.length > 0"></i>
        <i class="fal fa-circle text-black-50" *ngIf="selectedHolidays.length == 0"></i>
        <span class="ms-1">{{selectedHolidays.length == 0 ? ('_ui_select_all' | translate) : ('_ui_deselect_all' | translate)}}</span>
    </li>


    <li *ngFor="let holidayItem of holidayItems"
        (click)="holidayToggle(holidayItem)"
        class="list-group-item d-flex align-items-center justify-content-start cursor-pointer">

        <ng-container *ngIf="!holidayItem.disabled">
            <i class="fa fa-check-circle text-success" *ngIf="holidayItem.selected"></i>
            <i class="fal fa-circle text-black-50" *ngIf="!holidayItem.selected"></i>
        </ng-container>

        <ng-container *ngIf="holidayItem.disabled">
            <app-loading-indicator [showLogo]="false"></app-loading-indicator>
        </ng-container>

        <span class="ms-1">{{holidayItem.holiday.name | translate}}</span>
        <span class="text-small ms-auto" *ngIf="holidayItem.date"><app-item-date [date]="holidayItem.date"></app-item-date></span>
    </li>

</ul>
