<h5 >{{'_admin_milestone_plan_periods' | translate}}</h5>

<ul class="list-unstyled list-group mt-1" *ngIf="blockedPeriodItems">
    <li class="list-group-item">
        <div class="d-flex align-items-center cursor-pointer"
             (click)="createItem()">
            <span>{{'_global_create' | translate}}</span>
            <span class="fal fa-plus-circle ms-auto"></span>
        </div>
    </li>


    <li *ngFor="let blockedPeriodItem of blockedPeriodItems"
        class="list-group-item d-flex align-items-center justify-content-start"
    >
        <div class="input-group">
            <div
                class="form-control d-flex align-items-center ellipsis input-group-prepend">
                <app-date-range-picker
                    [start]="blockedPeriodItem.start"
                    [end]="blockedPeriodItem.end"
                    (onDateChanged)="onDateChange($event, blockedPeriodItem)"
                ></app-date-range-picker>

                <ng-container *ngIf="blockedPeriodItem.disabled">
                    <app-loading-indicator [showLogo]="false"></app-loading-indicator>
                </ng-container>
            </div>

            <span class="btn btn-outline-danger" (click)="removeItem(blockedPeriodItem)"><i
                class="fal fa-trash-alt"></i></span>
        </div>

    </li>

</ul>
