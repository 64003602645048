import {NgModule} from "@angular/core";
import {FilterStatusComponent} from "@app/shell/header/filters/filter-status/filter-status.component";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ToggleItemModule,
        TranslateModule,
        NgbTooltipModule,
        NgbPopoverModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        FilterStatusComponent,
    ],
    exports: [
        FilterStatusComponent

    ],
    providers: [

    ],
})
export class FilterStatusModule {}
