<label>{{ configuration.label | translate}}</label>

<ul class="list-group">
    <li class="list-group-item">
        <div class="form-group mb-0">
            <div class="search-wrap">
                <div class="input-group">

                    <input id="typeahead-http" type="search" class="form-control placeholder-sm"
                           autocomplete="off"
                           #instance="ngbTypeahead"
                           container="body"
                           popupClass="result-list"
                           [class.is-invalid]="searchFailed"
                           [ngbTypeahead]="searchFunction"
                           [resultTemplate]="rt"
                           [inputFormatter]="formatter"
                           (focus)="focus$.next($any($event.target).value)"
                           (click)="click$.next($any($event.target).value)"
                           (selectItem)="onPhaseSelected($event, instance)"
                           [placeholder]="(searchPlaceholder | translate)"/>
                    <ng-template #rt let-r="result" let-t="term"
                                 [typedTemplate]="resultTemplate"
                    >
                        <div class="d-flex align-items-center">
                            <app-color-item [color]="r.color" class="me-1"></app-color-item>
                            <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                        </div>
                    </ng-template>
                    <button
                        class="btn btn-outline-primary"
                        type="button"
                        (click)="onCreateBtnClicked()"
                    >
                        <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                </div>
                <span class="searching" *ngIf="isSearching" [@fadeAnimation]="'in'">Søger...</span>
            </div>
            <div class="invalid-feedback" *ngIf="searchFailed">Beklager, ingen resultater fundet.</div>
        </div>
    </li>

    <ng-container
        *ngIf="configuration.showPhases"
    >
        <li
            class="list-group-item" *ngIf="rows.length > 0">
            <div style="padding-left: 230px;transform: translateY(2px);">
                <label>{{'_ui_phase_deadline' | translate}}</label>
            </div>
        </li>
        <li
            class="list-group-item"
            *ngFor="let row of rows"
            [@fadeAnimation]="'in'"
        >
            <div class="input-group d-flex align-items-start">
                <div class="form-control d-flex align-items-center ellipsis input-group-prepend">
                    <app-toggle-item
                        [iconColorInactive]="'text-black-25'"
                        [ngbTooltip]="('_display_projects_phase' | translate)"
                        [interactive]="false"
                        [active]="row.isCurrent"
                        icon="fa-dot-circle"
                        [iconInactive]="'fa-circle'"
                        class="me-1 cursor-pointer"
                        (click)="onCurrentPhasesProjectChanged(row)"
                    />

                    <app-color-item
                        class="me-1 ms-1"
                        [color]="row.phasesProject.phase?.color"
                        [className]="'large'"
                    />

                    <div class="ellipsis"
                         *ngIf="row.phasesProject.phase?.exists()"
                         [ngbTooltip]="row.phasesProject.phase?.name">
                        {{row.phasesProject.phase.name}}
                    </div>
                </div>

                <div
                    *ngIf="row.phasesProject.phase?.changeable_date"
                    class="input-group-append w-50 input-group-date" novalidate
                >
                    <input class="form-control d-lg-block d-md-block flex-fill "
                           container="body"
                           [placeholder]="('_ui_select_phase_deadline' | translate)+'...'"
                           autocomplete="off"
                           readonly
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="row.phasesProject.startNgbDate"
                           (ngModelChange)="onRowDateChanged(row, $event)"
                           ngbDatepicker
                           [weekdays]="true"
                           [showWeekNumbers]="true"
                           #d="ngbDatepicker"
                           [class.focus]="d.isOpen()"
                           (click)="d.toggle()"
                    />
                </div>

                <div class="ms-1">
                <span
                    class="btn btn-outline-danger"
                    (click)="onRowRemoveBtnClicked(row)"
                >
                    <i class="fal fa-trash-alt"></i>
                </span>
                </div>
            </div>
        </li>
    </ng-container>
</ul>

