import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {DaysTableColumn} from "@app/pages/displays/display-cases/TableColumns/DaysTableColumn";
import {DaysCell} from "@app/pages/displays/display-cases/Cells/DaysCell";
import {ColumnTypeSettings} from "@app/pages/displays/display-cases/ColumnTypeSettings";
import {CaseShelves_DaysTaskTypesSettingValue, CaseShelves_DaysVisibleDaysSettingValue} from "@app/core/http/Api/Api";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import * as moment from "moment/moment";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";
import {CaseRow} from "@app/pages/displays/display-cases/CaseRow";

export class DaysColumn extends BaseColumn implements ColumnDataFetcherInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TaskFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new TaskFetcher(
            undefined, undefined, undefined,
            column.name
        );
        this.dataFetcher.setTaskTypeIds(this.getTaskTypes());
    }

    public setDate(week: Date) {
        this.dataFetcher.setPeriod(
            moment(week).startOf('isoWeek').toDate(),
            moment(week).endOf('isoWeek').toDate()
        );
        this.getTableColumns<DaysTableColumn>().forEach(tableColumn => tableColumn.setDate(week));
    }

    public createTableColumns(): BaseTableColumn[] {
        return this.getVisibleDays()
            .map(day => {
                const tableColumn = new DaysTableColumn(this, day);
                this.setTableColumnDefaults(tableColumn);
                return tableColumn;
            });
    }

    public createCell(row: CaseRow): BaseCell {
        const cell = new DaysCell(row, this);
        cell.getAllListConfigurations().forEach(listConfiguration => {
            this.dataFetcher.addRequest(new TaskFetchRequest(listConfiguration));
        });
        return cell;
    }

    public getVisibleDays(): string[] {
        return this.settings.get(ColumnTypeSettings.Days_VisibleDays)
            ?.getObject<CaseShelves_DaysVisibleDaysSettingValue>()
            ?.days ?? [];
    }

    public getTaskTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.Days_TaskTypes)
            ?.getObject<CaseShelves_DaysTaskTypesSettingValue>()
            ?.taskTypeIds ?? [];
    }

}
