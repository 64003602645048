/**
 * Created by ModelParser
 * Date: 20-12-2018.
 * Time: 11:42.
 */
import {RolesUserDefinition} from './definitions/RolesUserDefinition';

export class RolesUser extends RolesUserDefinition {

    constructor(json?: any) {
        super(json);
    }

}
