/**
 * Created by ModelParser
 * Date: 04-02-2020.
 * Time: 13:07.
 */
import {ProjectTypesTaskTypeDefinition} from './definitions/ProjectTypesTaskTypeDefinition';

export class ProjectTypesTaskType extends ProjectTypesTaskTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
