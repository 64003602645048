import {NgModule} from "@angular/core";
import {
    PhaseProgressTypeSearchComponent
} from "@app/shared/_forms/search/phase-progress-type-search/phase-progress-type-search.component";
import {NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";

@NgModule({
    imports: [
        CommonModule,
        NgbTypeaheadModule,
        TranslateModule,
        FormsModule,
        TypedTemplateDirective

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        PhaseProgressTypeSearchComponent,
    ],
    exports: [
        PhaseProgressTypeSearchComponent

    ],
    providers: [

    ],
})
export class PhaseProgressTypeSearchModule {}
