import {ProjectStatus} from '@app/core/models';
import {ProjectStatusChangeEvent} from '@app/shared/_ui/columns/project-statuses/ProjectStatusChangeEvent';

export class ProjectStatusesRow {
    // Data
    statusId: number;
    statusTypes: {id: number}[];
    name: string;
    toolTip: string;
    projectStatusTypeId: number;

    // Events
    onStatusChange: (event: ProjectStatusChangeEvent) => void;

    constructor(projectStatus: ProjectStatus, onStatusChangeListener: (event: ProjectStatusChangeEvent) => void) {
        this.statusId = projectStatus.status_id;
        this.statusTypes = projectStatus.project_status_type?.statuses?.map(status => {
            return {id: status.id};
        });
        this.name = projectStatus.project_status_type?.name;
        this.toolTip = projectStatus.project_status_type?.name;
        this.projectStatusTypeId = projectStatus.project_status_type_id;
        this.onStatusChange = onStatusChangeListener;
    }

    public onChange(statusId: number) {
        if (this.onStatusChange) {
            this.onStatusChange(new ProjectStatusChangeEvent(this.projectStatusTypeId, statusId));
        }
    }

}
