<div class="form-group m-0 w-100 d-flex flex-row">

    <label *ngIf="label" class="w-100">
        <span>{{label | translate}}</span>
    </label>

    <div class="d-flex flex-row w-100">

        <div class="input-group position-relative  -w-100 date-container" novalidate
             [class.no-prepend-picker]="autoReset || !showSoftDeadline"
        >
            <div class="input-group-text h-100" *ngIf="!autoReset && showSoftDeadline">
                <app-toggle-item
                    class="align-self-center ms-1 me-1"
                    *ngIf="!autoReset"
                    [disabled]="!internalValues"
                    [class.disabled]="!internalValues"
                    [interactive]="true"
                    [icon]="'fa-check-circle'"
                    [iconInactive]="'fa-circle'"
                    [iconColorActive]="'text-success'"
                    [iconColorInactive]="'text-black-50'"
                    [active]="isSoft"
                    (onToggle)="isSoft = !isSoft; isSoftChangeEvent()"
                    [ngbTooltip]="('_ui_within_week' | translate: {week: (date | week)})"
                />
            </div>

            <!-- Standard datepicker -->
            <input
                #datepicker="ngbDatepicker"
                [placeholder]="('_ui_date_select' | translate)"
                [class.focus]="datepicker.isOpen()"
                ngbDatepicker
                [weekdays]="true"
                [showWeekNumbers]="true"
                autocomplete="off"
                (click)="datepicker.toggle();"
                container="body"
                [class.is-invalid]="invalid"
                [required]="required"
                [ngModel]="dateStruct"
                [placement]="placement"
                (dateSelect)="changeSelectedDate($event);"
                class="form-control d-lg-block d-md-block -flex-fill datepicker-validation"
            />

            <button
                *ngIf="staticDeadlines && staticDeadlines.length > 0"
                class="btn btn-outline-secondary far no-caret {{staticDeadlineIcon}}"
                type="button"
                [ngbPopover]="popoverContent"
                [autoClose]="true"
                [placement]="['auto']"
                container="body"
                #popover="ngbPopover"></button>

            <!-- Popover dialog content -->
            <ng-template #popoverContent>
                <ul class="list-unstyled scroll-y"
                    style="max-height: 200px;"
                >
                    <li *ngFor="let staticDeadline of staticDeadlines"
                        class="border-bottom pt-1 pb-1 cursor-pointer"
                        (click)="setStaticDeadline(staticDeadline)"
                    >
                        <app-static-deadline-row
                            [staticDeadline]="staticDeadline"
                            [previousDeadlineName]="staticDeadlineDescriptor(staticDeadline)[0]"
                            [previousDeadlineDate]="staticDeadlineDescriptor(staticDeadline)[1]"
                        />
                    </li>
                </ul>
            </ng-template>

            <button class="btn btn-outline-danger " (click)="zeroValue()" type="button"
                    [ngbTooltip]="('_ui_reset' | translate)">
                <i class="fal fa-eraser"></i>
            </button>
        </div>


    </div>
</div>
