/**
 * Created by ModelParser
 * Date: 04-05-2022.
 * Time: 08:29.
 */
import {CategoryTypesRoleDefinition} from './definitions/CategoryTypesRoleDefinition';

export class CategoryTypesRole extends CategoryTypesRoleDefinition {

    constructor(json?: any) {
        super(json);
    }

}
