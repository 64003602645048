import {CommonModule} from '@angular/common';
import {EmojiPickerModule} from '@app/shared/_elements/emoji-picker/EmojiPicker.module';
import {NgModule} from '@angular/core';
import {VisibilityToggleComponent} from '@app/shared/_ui/visibility-toggle/visibility-toggle.component';
import {NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";


@NgModule({
    imports: [
        CommonModule,
        EmojiPickerModule,
        NgbDropdown,
        NgbDropdownMenu,
        NgbDropdownToggle,
        TranslateModule,
        ToggleItemModule,
        NgbTooltip

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        VisibilityToggleComponent,
    ],
    exports: [
        VisibilityToggleComponent

    ],
    providers: [

    ],
})
export class VisibilityToggleModule {}
