<div class="ghost">
    <!-- ghost -->
    <div class="card-header">

    </div>
    <div class="card-content d-flex flex-row justify-content-center -align-items-center">
        <div class="tabs-editor">
            <ul class="nav-tabs list-unstyled">
                <li></li>
            </ul>
        </div>

        <app-loading-indicator style="margin-top: 45%; font-size: 64px;" [showLogo]="false"></app-loading-indicator>
    </div>
    <ul class="nav nav-pills nav-fill position-absolute bg-light border-top w-100 p-2" style="bottom: 0;">
        <li class="nav-item -px-2">
            <button type="button" class="btn btn-sm panel-button">
                <span>&nbsp;</span>
            </button>
        </li>
        <li class="nav-item -px-2">
            <button type="button" class="btn btn-sm panel-button">
                <span>&nbsp;</span>
            </button>
        </li>
        <li class="nav-item -px-2">
            <button type="button" class="btn btn-sm panel-button">
                <span>&nbsp;</span>
            </button>
        </li>
        <li class="nav-item -px-2">
            <button type="button" class="btn btn-sm panel-button">
                <span>&nbsp;</span>
            </button>
        </li>

    </ul>

</div>
