import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input,
    OnInit,
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Task, TaskType} from "@app/core/models";
import {TranslateService} from "@ngx-translate/core";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {Field} from "@app/editor/task-editor-loader/task-editor.service";
import {EditStatusConfiguration} from "@app/editor/quick-editor/editors/generic/status-editor/EditStatusConfiguration";
import {
    EditArchivedConfiguration
} from "@app/editor/quick-editor/editors/generic/archived-editor/EditArchivedConfiguration";
import {
    EditReactionListConfiguration
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionListConfiguration";
import {ReactionTypes} from "@app/constants";
import {Api} from "@app/core/http/Api/Api";
import {AppInjector} from "@app/services/app-injector.service";
import {EventService} from "@app/services/event.service";
import {EditorPanelService} from "@app/services/editor-panel.service";
import {Fields} from "@app/editor/task-editor-loader/Fields";
import {
    EditCanCopyConfiguration
} from "@app/editor/quick-editor/editors/generic/can-copy-editor/EditCanCopyConfiguration";
import {
    EditIsPrivateConfiguration
} from "@app/editor/quick-editor/editors/generic/is-private-editor/EditIsPrivateConfiguration";
import {FocusableEntityTypes} from "@app/core/Focusable/FocusableEntityTypes";

@Component({
    selector: 'app-task-editor-header',
    templateUrl: './task-editor-header-component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskEditorHeaderComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item!: Task;
    @Input() taskType!: TaskType;
    @Input() fields: Map<number, Field>;
    @Input() validator!: ValidationErrorInterface;
    @Input() onReloadEventEmitter!: EventEmitter<boolean>;

    // Bindings to view
    public isReady = false;
    public showValidationWarning = false;

    // Bindings to view: Status Editor
    public showStatusEditor = false;
    public statusEditorConfiguration: EditStatusConfiguration;

    // Bindings to view: Archived Editor
    public showArchivedEditor = false;
    public archivedEditorConfiguration: EditArchivedConfiguration;

    // Bindings to view: Reaction list editor
    public showReactionListEditor = false;
    public reactionListEditorConfiguration: EditReactionListConfiguration;

    // Bindings to view: Repeatable
    public showCanCopyEditor = false;
    public canCopyEditorConfiguration: EditCanCopyConfiguration;

    // Bindings to view: Private
    public showIsPrivateEditor = false;
    public isPrivateEditorConfiguration: EditIsPrivateConfiguration;

    // Bindings to view: Calculated Fields
    public showCalculatedFields = false;

    // Data

    constructor(
        private cd: ChangeDetectorRef,
        private translateService: TranslateService,
        private dialogService: BaseDialogService,
        private editorPanelService: EditorPanelService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.setup();
    }

    private setup() {
        this.showStatusEditor = true;
        this.statusEditorConfiguration = new EditStatusConfiguration();

        this.showArchivedEditor = true;
        this.archivedEditorConfiguration = new EditArchivedConfiguration();

        this.showReactionListEditor = true;
        this.reactionListEditorConfiguration = new EditReactionListConfiguration(
            true,
            true,
            this.taskType.reaction_types_task_types?.find(rt => rt.reaction_type_id == ReactionTypes.Emoji)?.visible ?? false,
            false,
            true
        );

        this.showCanCopyEditor = this.isFieldVisible(Fields.BasicInformation.CanCopy);
        this.canCopyEditorConfiguration = new EditCanCopyConfiguration();

        this.showIsPrivateEditor = this.isFieldVisible(Fields.IsPrivate);
        this.isPrivateEditorConfiguration = new EditIsPrivateConfiguration();

        this.showCalculatedFields = this.isFieldVisible(Fields.CalculatedFields) && (this.item.calculated_fields_tasks?.length ?? 0) > 0;

        this.subscribe(this.validator.onIsValidChanged.subscribe(isValid => {
            this.renderValidation();
            this.detectChanges();
        }));

        this.renderValidation();

        this.isReady = true;
    }

    private renderValidation() {
        this.showValidationWarning = !this.validator.isValid();
    }

    private isFieldVisible(fieldId: number): boolean {
        return this.fields.has(fieldId) && this.fields.get(fieldId).visible;
    }

    // <editor-fold desc="View actions">

    public onCopyBtnClicked() {
        this.dialogService.confirm(
            this.translateService.instant('_ui_copy'),
            this.translateService.instant('_ui_copy_item', {item: this.item.title}),
            this.translateService.instant('_global_ok'),
            this.translateService.instant('_global_cancel'),
            'sm',
            false,
            'btn-success'
        )
            .then((confirmed) => {
                if (confirmed) {
                    Api.tasks()
                        .copyGetById(this.item.id)
                        .find((r) => {
                            this.editorPanelService.open(r[0]);
                            AppInjector.getInjector().get(EventService).emitTask(new Task(r[0]), EventService.Created);
                        })
                }
            })
    }

    // </editor-fold>

    protected readonly FocusableEntityTypes = FocusableEntityTypes;
}
