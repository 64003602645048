import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    AppointmentListTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/AppointmentListTableColumn";
import {
    AppointmentListCell
} from "@app/pages/displays/display-project-details/subdisplay-top/Cells/AppointmentListCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";

export class AppointmentListColumn extends BaseColumn<AppointmentListCell> {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new AppointmentListTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: Row): BaseCell {
        return new AppointmentListCell(row, this);
    }

}
