<div
    class="d-flex w-100 position-relative flex-row justify-content-between align-items-center"
    mwlResizable
    [enableGhostResize]="false"
    (resizing)="onResize($event.rectangle.width)"
    (resizeEnd)="onResizeEnd()"
>
    <app-column-switcher
        [name]="editor.columnName"
        [configuration]="editor.columnConfiguration"
    ></app-column-switcher>
    <div class="custom-resize-handle"
         [ngbTooltip]="('_ui_resize_column' | translate)"
         *ngIf="canResize"
         [resizeEdges]="{ right: true }"
         mwlResizeHandle>
    </div>
</div>
