import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";

export class ProjectCardCell extends GenericCell {

    public exportCSV(secondary?: any): CSVCellValue {
        switch (secondary) {
            case 'name':
                return this.row.project.item.title ?? '';
            case 'archived':
                return this.row.project.item.archived_id ? 'X' : '';
        }
    }

}
