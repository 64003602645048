import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, TemplateRef} from "@angular/core";
import {BaseColumn} from "@app/editor/quick-editor/columns/BaseColumn";
import {
    TemplateColumnConfiguration
} from "@app/editor/quick-editor/columns/generic/template-column/TemplateColumnConfiguration";

@Component({
    selector: 'app-template-column',
    templateUrl: './template-column.component.html',
    styleUrls: ['./template-column.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateColumnComponent extends BaseColumn {

    @Input() configuration: TemplateColumnConfiguration;

    public value: TemplateRef<any>;
    public ctx: any;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected render() {
        this.value = this.configuration.value;
        this.ctx = this.configuration.context;
    }

}
