import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {Project} from '@app/core/models/Project';
import {ProjectsUser} from '@app/core/models/ProjectsUser';
import {EventEmitter} from '@angular/core';
import {CardProjectConfiguration} from "@app/shared/_ui/cards/medium/card-project/card-project-configuration";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";

export class Row extends BaseRow {

    projectCard: CardItem<Project>;
    responsible: ProjectsUser;

    // Events
    minimizeEvent = new EventEmitter();
    reloadYearWheelEvent = new EventEmitter();
    rerenderYearWheelEvent = new EventEmitter();

    constructor(project: Project) {
        super();
        const config = new CardProjectConfiguration();
        config.showNextMilestoneMicroCard = false;
        this.projectCard = new CardItem<Project>(project, config);
        this.responsible = project.responsible;
    }

}
