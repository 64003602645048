import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {AuthenticationService, I18nService} from '@app/core';
import {environment} from '@env/environment';
import {User} from '@app/core/models/User';
import {ApiFilter, OrderDirection} from '@app/http/APIFilter';
import {ApiCall} from '@app/http/APICall';
import {OldApi} from '@app/http/Api';
import {FieldTypes, System} from '@app/constants';
import {UserField} from '@app/core/models/UserField';
import {Api} from '@app/core/http/Api/Api';
import {AppInjector} from '@app/services/app-injector.service';
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";
import {Department, DepartmentGroup, Display} from '@app/core/models';
import Helpers from '@app/core/helpers';
import {DisplayService} from '@app/services/display.service';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    private initialized: boolean = false;

    private httpClient: HttpClient;
    private authService: AuthenticationService;

    private _currentUser: BehaviorSubject<User>;
    public currentUser$: Observable<User>;

    private dataStore: {
        currentUser: User
    };

    constructor() {
        this.httpClient = AppInjector.getInjector().get(HttpClient);
        this.authService = AppInjector.getInjector().get(AuthenticationService);

        this.dataStore = {
            currentUser: null
        };

        this._currentUser = <BehaviorSubject<User>>new BehaviorSubject(null);
        this.currentUser$ = this._currentUser.asObservable();


    }

    public init() {
        if (!this.initialized) {
            // console.log('users.service.ts : init() : ', this.initialized);
            this.me();
            this.initialized = true;
        }
    }

    public get user(): User {
        return this.dataStore.currentUser;
    }

    public me() {
        // console.log('users.service.ts : me() : ');
        // http://192.168.1.108:8080/users/me?include=departments
        const uri = OldApi.users().me().toString() + '?include=department,role';
        this.httpClient
            // .cache()
            .get<User[]>(`${environment.apiPath}${uri}`, {headers: this.authService.getAuthorizationHeader()})
            .pipe(
                map((response: any) => {
                    return new User(response.resource);
                }),
                catchError((err: HttpErrorResponse) => {
                    console.log('users.service : currentUser : error : ', err);
                    return throwError(err);
                })
            ).subscribe(
            (data: User) => {
                const user = new User(data);
                this.dataStore.currentUser = user;
                this._currentUser.next(Object.assign({}, this.dataStore).currentUser);
                // console.log('users.service.ts : me() : user : ', data, 'currentUser : ', this._currentUser.value);
                AppInjector.getInjector().get(I18nService).language = I18nService.languageIdToLanguageCode(user.language_id);
            },
            (err: any) => {
                console.error('users.service.ts : me() : Error : ', err);
            },
            () => {
                // log.debug('getTags: always')
            }
        );
    }

    getUserDepartment(callback: ((department: Department) => void)){
        AppInjector.getInjector().get(DisplayService).getDisplaysWithoutSettings(displaysResponse => {
            let displays:Display[] = displaysResponse.filter(display => display.routerPath);
            let selectedDepartment:Department;
            if (this.user?.departments) { // Vælg den første afdeling, at brugeren er tilknyttet
                displays.forEach(display => {
                    if (display.departments_displays) {
                        display.departments_displays.find(dd => {
                            if (Helpers.itemExists(this.user.departments, dd.department_id)) {
                                selectedDepartment = this.user.departments.find(value => value.id == dd.department_id);
                                return true;
                            }
                        });
                    }
                });
            }
            callback(selectedDepartment);
        });
    }

    search(term: string, filters?: ApiFilter): Observable<User[]> {
        const uri = OldApi.users().toString()+ '?include=department';

        let httpParams = new HttpParams();
        if (!filters) {
            filters = new ApiFilter();
        }
        filters
            .whereEquals('hidden', false)
            .whereGreaterThan('user_group_id', 0);

        if (term === '') {
            httpParams = httpParams.append('limit', '25');
        }

        if (term !== '') {
            filters.search('name', term);
            filters.search('initials', term);
        } else {
            //filters.orderBy('created', OrderDirection.Ascending);
            //filters.orderBy('title', this.filtersService.filters.sortAsc ? OrderDirection.Ascending : OrderDirection.Descending);
        }
        filters.orderBy('first_name', OrderDirection.Ascending);
        filters.orderBy('last_name', OrderDirection.Ascending);

        httpParams = httpParams.append('filter', filters.filtersString());
        httpParams = httpParams.append('ordering', filters.orderingString());
        //httpParams = httpParams.append('ordering', filters.orderingString());

        const api: ApiCall = new ApiCall(uri, httpParams, (response) => {
            const items = response.resources.map((item: any) => {
                return new User(item);
            });

            return items;
        });

        return api.getHttp();

        /*
        return this.http
            .get(WIKI_URL, {params: PARAMS.set('search', term)}).pipe(
                map(response => response[1])
            );
            */
    }

    department$(departmentId: number, filters?: ApiFilter): Observable<User[]> {
        const uri = OldApi.users().toString();

        let httpParams = new HttpParams();
        if (!filters) {
            filters = new ApiFilter();
        }

        filters.orderBy('first_name', OrderDirection.Ascending);
        filters.orderBy('last_name', OrderDirection.Ascending);
        filters.whereEquals('department.id', departmentId);
        filters.whereEquals('hidden', false);

        httpParams = httpParams.append('filter', filters.filtersString());
        httpParams = httpParams.append('ordering', filters.orderingString());
        //httpParams = httpParams.append('ordering', filters.orderingString());

        const api: ApiCall = new ApiCall(uri, httpParams, (response) => {
            const items = response.resources.map((item: any) => {
                return new User(item);
            });

            return items;
        });

        return api.getHttp();
    }

    // <editor-fold desc="Users by department">

    private byDepartmentDataCache: Map<number, ApiDataCache<User>> = new Map();

    public getUsersByDepartment(departmentId: number, callback: (items: User[]) => void) {
        if (!this.byDepartmentDataCache.has(departmentId)) {
            this.byDepartmentDataCache.set(departmentId, new ApiDataCache<User>(
                `${System.UsersByDepartment}-${departmentId}`,
                2 * 60 * 60, // 2 hours
                Api.users().get()
                    .include('role')
                    .where('hidden', false)
                    .where('department.id', departmentId)
                    .orderAsc('name')
            ));
        }
        this.byDepartmentDataCache.get(departmentId).get(callback);
    }

    // </editor-fold>

    // <editor-fold desc="User cache">

    private dataCache = new ApiDataCache<User>(
        System.Users,
        2 * 60 * 60, // 2 hours
        Api.users()
            .get()
            .include('department')
    );

    public getUsers(callback: (items: User[]) => void) {
        this.dataCache.get(callback);
    }

    public getUsersById(userId: number, callback: (user: User) => void) {
        this.getUsers((users)=>{
            callback(users.find(u => u.id == userId));
        })
    }

    // </editor-fold>

    // <editor-fold desc="Fields (Queue & Cache)">

    private fieldsDataCache = new ApiDataCache<UserField>(
        System.UserFields,
        2 * 60 * 60, // 2 hours
        Api.userFields().get()
    );

    // Entry point
    private getFields(callback: (fields: UserField[]) => void) {
        this.fieldsDataCache.get(callback);
    }

    public getEditorFields(callback: (fields: UserField[]) => void) {
        this.getFields(fields => {
            callback(fields.filter(field => field.type == FieldTypes.Editor));
        });
    }

    public getMiniCardFields(callback: (fields: UserField[]) => void) {
        this.getFields(fields => callback(fields.filter(field => field.type == FieldTypes.MiniCard)));
    }

    // </editor-fold>

}
