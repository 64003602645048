import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {WampService} from '../../../../services/wamp.service';
import {CalculatedField} from '@app/core/models/CalculatedField';
import {CalculatedFieldsTask, Task} from '@app/core/models';
import {Events} from '@app/constants';

@Component({
    selector: 'app-task-calculated-field',
    templateUrl: './task-calculated-field.component.html',
    styleUrls: ['./task-calculated-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskCalculatedFieldComponent extends BaseComponent implements OnInit, OnChanges {

    // Input: Scope
    @Input() task: Task;
    @Input() calculatedField: CalculatedField;
    // Input: Data
    @Input() calculatedFieldsTask: CalculatedFieldsTask;

    // Bindings to view
    public hasCalculatedFieldsTask: boolean
    public value: number;

    constructor(private cd: ChangeDetectorRef, private wampService: WampService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.render();
        this.subscribe(CalculatedFieldsTask.CreateChangeSubscription(
            Events.TaskCalculatedFieldChanged(this.calculatedField?.id, this.task?.id),
            this.wampService, event => {
                if (!this.calculatedFieldsTask) {
                    this.calculatedFieldsTask = new CalculatedFieldsTask();
                }
                this.calculatedFieldsTask.populate(event.next, true);
                this.render();
            }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        this.hasCalculatedFieldsTask = this.calculatedFieldsTask != null;
        this.value = this.calculatedFieldsTask?.value;
        this.detectChanges();
    }

}
