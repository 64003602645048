export class ColumnTypeSettings {

    public static UserCard_LoadProfile = 'user-card-load-profile';
    public static CaseList_TaskTypes = 'case-list-task-types';
    public static TaskList_TaskTypes = 'task-list-task-types';
    public static TaskList_SoftThisWeek_TaskTypes = 'task-list-soft-this-week-task-types';
    public static TaskList_SoftNextWeek_TaskTypes = 'task-list-soft-next-week-task-types';
    public static ProjectList_ProjectTypes = 'project-list-project-types';
    public static MilestoneList_ProjectTypes = 'milestone-list-project-types';
    public static Days_VisibleDays = 'days-visible-days';
    public static Days_TaskTypes = 'days-task-types';

}
