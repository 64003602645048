/**
 * Created by ModelParser
 */
import {Task} from '../Task';
import {User} from '../User';
import {TaskUserType} from '../TaskUserType';
import {Deadline} from '../Deadline';
import {Archived} from '../Archived';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class TasksUserDefinition extends BaseModel {
    task_id?: number;
    task?: Task;
    user_id?: number;
    user?: User;
    task_user_type_id?: number;
    task_user_type?: TaskUserType;
    deadline_id?: number;
    deadline?: Deadline;
    acceptance_status?: number;
    deadline_num_changes?: number;
    archived_id?: number;
    archived?: Archived;
    is_order?: boolean;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.task_id;
            delete this.task;
            delete this.user_id;
            delete this.user;
            delete this.task_user_type_id;
            delete this.task_user_type;
            delete this.deadline_id;
            delete this.deadline;
            delete this.acceptance_status;
            delete this.deadline_num_changes;
            delete this.archived_id;
            delete this.archived;
            delete this.is_order;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.task_id != null) {
            this.task_id = data.task_id;
        }
        if (data.task != null) {
            this.task = new Task(data.task);
        }
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.task_user_type_id != null) {
            this.task_user_type_id = data.task_user_type_id;
        }
        if (data.task_user_type != null) {
            this.task_user_type = new TaskUserType(data.task_user_type);
        }
        if (data.deadline_id != null) {
            this.deadline_id = data.deadline_id;
        }
        if (data.deadline != null) {
            this.deadline = new Deadline(data.deadline);
        }
        if (data.acceptance_status != null) {
            this.acceptance_status = data.acceptance_status;
        }
        if (data.deadline_num_changes != null) {
            this.deadline_num_changes = data.deadline_num_changes;
        }
        if (data.archived_id != null) {
            this.archived_id = data.archived_id;
        }
        if (data.archived != null) {
            this.archived = new Archived(data.archived);
        }
        if (data.is_order != null) {
            this.is_order = data.is_order;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
