import {GenericTableColumn} from "@app/pages/displays/display-cases/TableColumns/GenericTableColumn";
import {GoalTextColumn} from "@app/pages/displays/display-cases/Columns/GoalTextColumn";
import {TableColumns} from "@app/pages/displays/display-cases/TableColumns";

export class GoalTextTableColumn extends GenericTableColumn {

    public identifier = TableColumns.GoalText;

    public column: GoalTextColumn;

}
