import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges, ViewChild
} from '@angular/core';
import {Appointment} from '@app/core/models/Appointment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppointmentDialogComponent} from '../../../../_modals/appointment-dialog/appointment-dialog.component';
import {CardComponent} from '../../card.component';
import {CardItem} from '../../CardItem';
import {CardConfiguration} from '../../CardConfiguration';
import {ResizeEvent} from 'angular-resizable-element';
import {formatDate} from '@angular/common';
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {ListConfiguration} from '../../../lists/ListConfiguration';

@Component({
    selector: 'app-card-appointment',
    templateUrl: './card-appointment.component.html',
    styleUrls: ['./card-appointment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardAppointmentComponent extends CardComponent implements OnInit, OnChanges {

    @Input() item: CardItem<Appointment>;
    @Input() configuration: CardConfiguration;
    @Input() multiline: boolean = true;
    @Input() label: string;

    @Input() model: any;
    @Input() listConfiguration: ListConfiguration;
    @Input() removable = false;
    @Input() tooltip: string;
    @Input() editable = false;
    @Input() interactive = true;
    @Output() onCardClickEvent = new EventEmitter();
    @Output() onRemove = new EventEmitter();

    @Output() contentClassChange = new EventEmitter<Appointment>();
    @ViewChild('cardContainer') cardContainer: ElementRef;

    public height: number;
    public width: number;
    public expanded = false;

    public style: object = {};

    clickEnabled = true;
    public modelHTML: string = '';

    constructor(private modalService: NgbModal,
                protected cd:ChangeDetectorRef,
                protected dialogService: BaseDialogService,
                @Inject(LOCALE_ID) private locale: string) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.initialized = true;

        this.updateEditable();
        this.render();
    }

    updateEditable(){
        if(this.user && this.user.id == this.item.item.created_by_id){
            this.editable = true;
        }else if(this.user && this.user.roles) {
            if(this.item.item.dynamic_menu_item && this.item.item.dynamic_menu_item.roles) {
                const userRoleIds = this.user.roles?.map(r => r.id) ?? [];
                this.editable = this.item.item.dynamic_menu_item.roles?.filter(r => userRoleIds.indexOf(r.id) !== -1 ).length > 0;
            }else{
                this.editable = true;
            }
        }else{
            this.editable = false;
        }
    }


    appointmentDialog(model: Appointment) {
        if (this.clickEnabled) {
            console.log('appointmentDialog() : ', model, 'this.configuration : ',this.configuration)
            const modalRef = this.modalService.open(
                AppointmentDialogComponent,
                {
                    size: 'lg',
                    windowClass: 'modal-holder',
                    centered: true,
                    backdrop: false
                });

            const componentRef = (modalRef.componentInstance as AppointmentDialogComponent);
            componentRef.title = this.item.item.dynamic_menu_item ? this.item.item.dynamic_menu_item.name : null;
            componentRef.model = model;
            componentRef.userEditable = this.editable;
        }
    }

    deleteAppointmentDialog(model: Appointment) {
        if (this.clickEnabled) {
            this.dialogService.confirm(
                this.translateService.instant('_global_delete'),
                this.translateService.instant('_ui_delete_item', {name: model?.title}),
                this.translateService.instant('_global_delete'),
                this.translateService.instant('_global_cancel'),
                'sm',
                false,
                'btn-danger'
            )
                .then((confirmed) => {
                    if (confirmed) {
                        this.isLoading = true;
                        model.delete(() =>{
                            this.isLoading = false;
                            this.render();
                        });

                    }

                })
                .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
        }
    }

    onItemUpdated(item: Appointment) {
        super.onItemUpdated(item);
        this.model = item;
        this.render();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['item']) {
            this.model = changes['item'].currentValue.item;
            this.render();
            this.contentClassChange.emit(this.model);
        }
    }

    render() {
        super.render();

        // Add missing data to new items
        if(!this.model.updated_by && this.model.created_by){
            this.model.updated_by = this.model.created_by;
        }

        if(!this.model.updated_by && this.model.created_by_id == this.getUser().id){
            this.model.updated_by = this.getUser();
        }

        if(!this.model.updated && this.model.created){
            this.model.updated = this.model.created;
        }

        const initials = this.model.updated_by ? this.model.updated_by.initialsConverter : '';
        const dateFormat = this.translateService.instant('_ui_date_format_microDate')
        const date = this.model.updated ? formatDate(this.model.updated, dateFormat, this.locale ? this.locale : null) : '';
        const header = initials && date ? `<span class="date">${initials} (${date})</span>` : '';
        this.modelHTML = `${header}<div class="body">${this.model.description}</div>`;
        this.markChangeDetectionDirty();
    }


    validate(event: ResizeEvent): boolean {
        const MIN_DIMENSIONS_PX: number = 50;
        if (
            event.rectangle.width &&
            event.rectangle.height &&
            (event.rectangle.width < MIN_DIMENSIONS_PX ||
                event.rectangle.height < MIN_DIMENSIONS_PX)
        ) {
            return false;
        }
        return true;
    }

    onResizeEnd(event: ResizeEvent): void {
        this.style = {
            // position: 'fixed',
            // left: `${event.rectangle.left}px`,
            // top: `${event.rectangle.top}px`,
            position: `relative`,
            width: `${event.rectangle.width}px`,
            height: `${event.rectangle.height}px`
        };
        this.clickEnabled = true;
    }


    onResizeStart(event: ResizeEvent) {
        this.style = {
            position: `relative`,
            width: `${event.rectangle.width}px`,
            height: `${event.rectangle.height}px`
        };
        this.clickEnabled = false;
    }
}
