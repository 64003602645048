/**
 * Created by ModelParser
 * Date: 04-12-2019.
 * Time: 13:20.
 */
import {ProjectTypesStatusRuleDefinition,} from './definitions/ProjectTypesStatusRuleDefinition';

export class ProjectTypesStatusRule extends ProjectTypesStatusRuleDefinition {

    constructor(json?: any) {
        super(json);
    }

}
