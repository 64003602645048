import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {
    NgbNav, NgbNavContent, NgbNavItem, NgbNavLink, NgbNavOutlet, NgbPopover,
    NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";
import {
    ProjectEditorV3Component
} from "@app/editor/project-editor-loader/project-editor-v3/project-editor-v3.component";
import {ResizableModule} from "angular-resizable-element";
import {
    ProjectEditorHeaderComponent
} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-header/project-editor-header-component";
import {BaseEditorV3Module} from "@app/editor/base-editor-v3/base-editor-v3.module";
import {
    ProjectEditorTitleComponent
} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-title/project-editor-title-component";
import {TitleEditorModule} from "@app/editor/quick-editor/editors/generic/title-editor/TitleEditor.module";
import {
    ProjectEditorFooterComponent
} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-footer/project-editor-footer-component";
import {
    ProjectEditorTabsBasicComponent
} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-tabs/project-editor-tab-basic/project-editor-tabs-basic-component";
import {
    DeadlineListEditorModule
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/DeadlineListEditor.module";
import {
    EstimateListEditorModule
} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EstimateListEditor.module";
import {
    ProjectCalculatedFieldModule
} from "@app/shared/_elements/project-calculated-field/ProjectCalculatedField.module";
import {ListEditorModule} from "@app/editor/quick-editor/editors/generic/link-editor/LinkEditor.module";
import {
    StatusListEditorModule
} from "@app/editor/quick-editor/editors/generic/status-list-editor/StatusListEditor.module";
import {StatusRuleAppenderModule} from "@app/shared/_forms/status-rule-appender/StatusRuleAppender.module";
import {
    UseStatusRulesEditorModule
} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/UseStatusRulesEditor.module";
import {TextEditorModule} from "@app/editor/quick-editor/editors/generic/text-editor/TextEditor.module";
import {
    PhasesProjectEditorModule
} from "@app/editor/quick-editor/editors/project/phases-project-editor/PhasesProjectEditor.module";
import {
    ReactionListEditorModule
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/ReactionListEditor.module";
import {
    CategoryPickerListEditorModule
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/CategoryPickerListEditor.module";
import {
    ProjectEditorTabsCategoriesComponent
} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-tabs/project-editor-tab-categories/project-editor-tabs-categories-component";
import {
    ProjectEditorTabsAttachToComponent
} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-tabs/project-editor-tab-attach-to/project-editor-tabs-attach-to-component";
import {
    ProjectEditorTabsLogComponent
} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-tabs/project-editor-tab-log/project-editor-tabs-log-component";
import {
    ProjectEditorTabsProcessStepsComponent
} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-tabs/project-editor-tab-process-steps/project-editor-tabs-process-steps-component";
import {
    ProjectEditorTabsUsersComponent
} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-tabs/project-editor-tab-users/project-editor-tabs-users-component";
import {UserListEditorModule} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserListEditor.module";
import {ProcessStepsModule} from "@app/shared/_elements/process-steps/ProcessSteps.module";
import {TaskListModule} from "@app/shared/_ui/lists/task-list/TaskList.module";
import {MilestoneListModule} from "@app/shared/_ui/lists/milestone-list/MilestoneList.module";
import {
    DepartmentPickerEditorModule
} from "@app/editor/quick-editor/editors/generic/department-picker-editor/DepartmentPickerEditor.module";
import {TagListEditorModule} from "@app/editor/quick-editor/editors/generic/tag-list-editor/TagListEditor.module";
import {LogsModule} from "@app/shared/_ui/logs/Logs.module";
import {
    GenericVerticalEditCollectionModule
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/generic-vertical-edit-collection/GenericVerticalEditCollection.module";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {
    ValidationWarningListModule
} from "@app/editor/base-editor-v3/components/validation-warning-list/validation-warning-list.module";
import {CreateItemDropdownModule} from "@app/shared/_ui/create-item-dropdown/CreateItemDropdown.module";
import {
    MilestonePlanSelectorModule
} from "@app/shared/_elements/milestone-plan-selector/MilestonePlanSelector.module";
import {
    AttachedTypesModule
} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-tabs/project-editor-tab-attach-to/attached-types/AttachedTypes.module";
import {
    FindAndFocusBtnModule
} from "@app/editor/base-editor-v3/components/find-and-focus-btn/find-and-focus-btn.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbTooltipModule,
        CdkDrag,
        ResizableModule,
        CdkDragHandle,
        BaseEditorV3Module,
        NgbNav,
        NgbNavItem,
        NgbNavLink,
        NgbNavContent,
        NgbNavOutlet,
        TitleEditorModule,
        DeadlineListEditorModule,
        EstimateListEditorModule,
        ProjectCalculatedFieldModule,
        ListEditorModule,
        StatusListEditorModule,
        StatusRuleAppenderModule,
        UseStatusRulesEditorModule,
        NgbPopover,
        TextEditorModule,
        PhasesProjectEditorModule,
        ReactionListEditorModule,
        CategoryPickerListEditorModule,
        UserListEditorModule,
        ProcessStepsModule,
        TaskListModule,
        MilestoneListModule,
        AttachedTypesModule,
        DepartmentPickerEditorModule,
        TagListEditorModule,
        LogsModule,
        GenericVerticalEditCollectionModule,
        ToggleItemModule,
        ValidationWarningListModule,
        CreateItemDropdownModule,
        MilestonePlanSelectorModule,
        AttachedTypesModule,
        FindAndFocusBtnModule,
    ],
    declarations: [
        ProjectEditorV3Component,
        ProjectEditorHeaderComponent,
        ProjectEditorTitleComponent,
        ProjectEditorFooterComponent,
        ProjectEditorTabsBasicComponent,
        ProjectEditorTabsCategoriesComponent,
        ProjectEditorTabsAttachToComponent,
        ProjectEditorTabsLogComponent,
        ProjectEditorTabsProcessStepsComponent,
        ProjectEditorTabsUsersComponent,
    ],
    exports: [
        ProjectEditorV3Component,  // export hvis det skal være tilgængligt i import af modul
    ]
})
export class ProjectEditorV3Module {
}
