/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {DeletionDefinition} from './definitions/DeletionDefinition';

export class Deletion extends DeletionDefinition {

    constructor(json?: any) {
        super(json);
    }

    public get hasItems(): boolean {
        return (this.projects?.length ?? 0) > 0
            || (this.tasks?.length ?? 0) > 0
            || (this.milestones?.length ?? 0) > 0;
    }

    getTypeTranslation() {
        switch (this.type) {
            case 'project':
                return '_project';
            case 'milestone':
                return '_milestone';
            case 'task':
                return '_task';
        }
    }

    getTitleByType() {
        switch (this.type) {
            case 'project':
                return this.projects[0].title;
            case 'milestone':
                return this.milestones[0].title;
            case 'task':
                return this.tasks[0].title;
        }
    }
}
