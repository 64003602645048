<div
    *ngIf="isReady"
    class="container p-2 d-flex gap-1 w-100 flex-wrap editor-tab"
>
    <div *ngIf="showAttachedProjectTypes">
        <ul
            class="list-unstyled -list-group d-flex gap-1 flex-column"
        >
            <li
                *ngFor="let attachedProjectType of attachedProjectTypes"
                class="list-group -list-group-item"
            >
                <app-attached-project-type
                    [task]="item"
                    [attachedProjectType]="attachedProjectType"
                    [showMilestoneField]="isFieldVisible(Fields.AttachTo.Milestone)"
                    [showProjectField]="isFieldVisible(Fields.AttachTo.Project)"
                />
            </li>
        </ul>
    </div>

    <app-tag-list-editor
        *ngIf="showTagListEditor"
        [model]="item"
        [configuration]="tagListEditorConfiguration"
    />

    <app-department-picker-editor
        *ngIf="showDepartmentPickerEditor"
        [model]="item"
        [configuration]="departmentPickerEditorConfiguration"
    />

</div>
