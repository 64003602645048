import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";

export class EditTodoCategoryConfiguration extends BaseEditorConfiguration {

    public label: string;

    constructor(label: string) {
        super();
        this.label = label;
    }

}
