<div class="d-flex flex-row">
    <ul class="list-unstyled d-flex align-items-center flex-wrap emoji-items m-0 justify-content-start">

        <li class="me-1 d-flex align-items-center justify-content-center"
            [ngbTooltip]="popover && popover.isOpen() ? null : (tooltipTitle | translate)"
            container="body"
            placement="bottom"
        >
            <span class="fal fa-palette cursor-pointer position-relative"
                  [ngbPopover]="popoverContent"
                  [autoClose]="'outside'"
                  triggers="manual"
                  container="body"
                  (click)="editable && popover? (popover.isOpen()? popover.close(): popover.open()) : null"
                  #popover="ngbPopover"
            >
                <span class="badge rounded-pill badge-notification badge-danger badge-counter"
                      *ngIf="showBadge && selectedColors.length > 0">{{selectedColors.length}}</span>

            </span>
        </li>

        <ng-container *ngIf="visible">
            <li *ngFor="let color of selectedColors"
                (click)="multiple ? toggleColor(color) : (editable && popover? (popover.isOpen()? popover.close(): popover.open()) : null)"
                class="color-badge d-flex align-items-center justify-content-center text-light me-1 pe-1"

            >
                <app-color-item [color]="color" [className]="'large'"></app-color-item>
            </li>
            <li *ngIf="!selectedColors || selectedColors.length == 0"
                (click)="editable && popover? (popover.isOpen()? popover.close(): popover.open()) : null"
            >
                <span class="placeholder">{{'_ui_select_color' | translate}}...</span>
            </li>
        </ng-container>

    </ul>
</div>


<ng-template #popoverContent>

    <div class="d-flex flex-column color-popover">

        <ul *ngIf="colors" class="list-unstyled color-picker-list d-flex align-items-center justify-content-center flex-wrap">
            <li *ngFor="let color of colors" class="color-badge d-flex align-items-center justify-content-center add-color"
                (click)="toggleColor(color); !multiple ? popover.close() : null">
                <app-color-item [color]="color" [className]="'large'" ></app-color-item>
            </li>
        </ul>

    </div>
</ng-template>
