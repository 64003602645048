import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/TaskEditColumn";
import {
    BaseEditorTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/EditorTableColumns/BaseEditorTableColumn";
import {TextColumnConfiguration} from "@app/editor/quick-editor/columns/generic/text-column/TextColumnConfiguration";
import {
    EditorWithColumnInterface
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/EditorWithColumnInterface";
import {
    EditEstimateListConfiguration
} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EditEstimateListConfiguration";
import { EstimateItem } from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EstimateItem";
import {
    EstimateListEditorComponent
} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/estimate-list-editor.component";
import {TextColumnComponent} from "@app/editor/quick-editor/columns/generic/text-column/text-column.component";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns";

export class TaskEdit_Estimate_TableColumn extends BaseEditorTableColumn {

    public identifier = TableColumns.TaskEdit_Estimate;

    public column: TaskEditColumn;
    public minWidth = 114;
    public maxWidth = 114;
    public columnConfiguration: TextColumnConfiguration;
    public editorWithColumn: EditorWithColumnInterface;

    constructor(column: TaskEditColumn) {
        super(column);
        this.name = '_displays_project_details_milestones_column_task_edit_estimate';

        this.columnConfiguration = new TextColumnConfiguration(
            '_displays_project_details_milestones_column_task_edit_estimate',
            false,
            false
        );
        this.columnConfiguration.savedWidth = this.width > 0 ? this.width : undefined;
        this.columnConfiguration.minWidth = this.minWidth;
        this.columnConfiguration.maxWidth = this.maxWidth;
        this.columnConfiguration.canAutoResize = false;
        this.columnConfiguration.onResizeEndEvent.subscribe(() => {
            this.width = this.columnConfiguration.width;
        });

        const editorConfiguration = new EditEstimateListConfiguration(
            '',
            [
                new EstimateItem('', EstimateItem.TypeDefault),
            ]
        );
        editorConfiguration.widthSynchronizer = this.columnConfiguration.widthSynchronizer;

        this.editorWithColumn = {
            name: EstimateListEditorComponent.name,
            columnName: TextColumnComponent.name,
            columnConfiguration: this.columnConfiguration,
            configuration: editorConfiguration,
        };
    }

}
