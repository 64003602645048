import {Injectable, OnDestroy} from '@angular/core';
import {Deadline} from '@app/core/models';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseService} from '../base.service';
import {AppInjector} from '../app-injector.service';
import {TasksUser} from '@app/core/models';
import {User} from '@app/core/models/User';
import {Subscription} from 'rxjs';
import {ProjectsDeadline} from '@app/core/models/ProjectsDeadline';
import {Project} from '@app/core/models/Project';
import {ProjectType} from '@app/core/models/ProjectType';
import {TaskType} from '@app/core/models/TaskType';
import {TasksDeadline} from '@app/core/models/TasksDeadline';
import {Task} from '@app/core/models/Task';


@Injectable({
    providedIn: 'root'
})
export class FormControlsService extends BaseService implements OnDestroy {

    private subscriptions: Subscription = new Subscription();
    private formBuilder: UntypedFormBuilder;

    constructor() {
        super();
        const injector = AppInjector.getInjector();
        this.formBuilder = injector.get(UntypedFormBuilder);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


    public deadlineGroup(item?: Deadline): UntypedFormGroup {
        if(!item){
            item = new Deadline();
        }
        let required = false;
        let append = true;
        let group: UntypedFormGroup;
        // Skal hentes fra project_types/ID_PÅ_TYPE
        if (append) {
            group = this.formBuilder.group({
                id: item.id ? item.id : 0,
                date: [item.date, required ? Validators.required : null],

            });
        }
        return group;
    }

    public createDeadline(item?: Deadline, required:boolean = false): Deadline {
        if (!item || !item.date) {
            item = new Deadline();
        }
        return item;
    }

    public createUser(item?:User): UntypedFormGroup {
        if (!item) {
            item = new User();
        }
        let group: UntypedFormGroup;
        group = this.formBuilder.group(item);
        return group;
    }

    // PROJECTS
    public projectDeadlinesArray(project:Project, configuration: ProjectType): UntypedFormArray {
        let _arr: UntypedFormArray = this.formBuilder.array([]);

        if (configuration.project_deadline_types_project_types) {
            configuration.project_deadline_types_project_types.forEach((item) => {
                if (item.visible || true) {
                    let found = false;
                    if(project.projects_deadlines){
                        // Eksisterende
                        project.projects_deadlines
                            .filter((pd:ProjectsDeadline) => {return pd.project_deadline_type_id == item.project_deadline_type_id;})
                            .forEach((pd:ProjectsDeadline) =>{
                                _arr.push(this.createProjectDeadlineGroup(pd));
                                found = true;
                            });
                    }
                    if(!found){
                        // Ny såfremt den er synlig
                        _arr.push(
                            this.createProjectDeadlineGroup(new ProjectsDeadline({
                                id: 0,
                                project_deadline_type_id: item.project_deadline_type_id,
                                deadline: new Deadline({date: new Date()})
                            }))
                        );
                    }
                }
            });
        }

        return _arr;

    }
    public createProjectDeadlineGroup(item: ProjectsDeadline): UntypedFormGroup {
        const group: UntypedFormGroup = this.formBuilder.group({
            id: item.id,
            project_deadline_type_id: item.project_deadline_type_id,
            deadline: this.deadlineGroup(item.deadline ? item.deadline : new Deadline({date: new Date()}))
        });
        return group;
    }


    // TASKS
    public taskUserTypesArray(tasksUsers: TasksUser[], configuration: TaskType): UntypedFormArray {
        let _arr: UntypedFormArray = this.formBuilder.array([]);

        if(configuration.task_types_task_user_types) {
            configuration.task_types_task_user_types.forEach(taskTypesTaskUserType => {
                if(taskTypesTaskUserType.visible) {
                    let found: boolean = false;
                    if(tasksUsers) {
                        let tus: TasksUser[] = tasksUsers.filter(taskUser => {
                            return taskUser.task_user_type_id == taskTypesTaskUserType.task_user_type_id;
                        });
                        tus.forEach((tu: TasksUser) => {
                            if(tu.task_user_type_id) {
                                tu.task_user_type = taskTypesTaskUserType.task_user_type;
                            }
                            found = true;
                            _arr.push(this.createTaskUserGroup(tu, taskTypesTaskUserType.required));
                        });
                    }
                    if(!found && taskTypesTaskUserType.required) {
                        // Vedoprettelse
                        let tu:TasksUser = new TasksUser({
                            id: 0,
                            task_user_type_id: taskTypesTaskUserType.task_user_type.id,
                            task_user_type: taskTypesTaskUserType.task_user_type,
                            deadline: new Deadline({date: new Date()}),
                            user: null
                        });
                        _arr.push(this.createTaskUserGroup(tu, taskTypesTaskUserType.required));
                    }
                }
            });
        }

        return _arr;
    }
    public createTaskUserGroup(item: TasksUser, required: boolean): UntypedFormGroup {
        if(!item.task_user_type) {
            return;
        }

        let multiple: boolean = false;
        const group: UntypedFormGroup = this.formBuilder.group({
            id: item.id,
            multiple: multiple,
            task_user_type_id: item.task_user_type_id,
            task_user_type: item.task_user_type,
            deadline: this.createDeadline(item.deadline),
            user: this.formBuilder.control(item.user ? item.user : null, required ? Validators.required : null)
        });


        return group;
    }

    public taskDeadlinesArray(task:Task, configuration: TaskType): UntypedFormArray {
        let _arr: UntypedFormArray = this.formBuilder.array([]);

        if (configuration.task_deadline_types_task_types) {
            configuration.task_deadline_types_task_types.forEach(taskDeadlineTypesTaskType => {
                if (taskDeadlineTypesTaskType.visible && taskDeadlineTypesTaskType.task_deadline_type_id) {
                    let found = false;
                    if(task.tasks_deadlines) {
                        // Eksisterende
                        let tasksDeadline = task.findTasksDeadlineByType(taskDeadlineTypesTaskType.task_deadline_type_id);
                        if(tasksDeadline) {
                            _arr.push(this.createTaskDeadlineGroup(tasksDeadline));
                            found = true;
                        }
                    }
                    if(!found) {
                        // Ny såfremt den er synlig
                        _arr.push(
                            this.createTaskDeadlineGroup(new TasksDeadline({
                                id: 0,
                                task_deadline_type_id: taskDeadlineTypesTaskType.task_deadline_type_id,
                                deadline: new Deadline()
                            }))
                        );
                    }
                }
            });
        }

        return _arr;

    }
    public createTaskDeadlineGroup(item: TasksDeadline): UntypedFormGroup {
        if(item && item.task_deadline_type_id) {
            const group: UntypedFormGroup = this.formBuilder.group({
                id: item.id,
                task_deadline_type_id: item.task_deadline_type_id,
                deadline: this.deadlineGroup(item.deadline ? item.deadline : new Deadline({date: new Date()}))
            });
            return group;
        }
    }
}
