import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {GoalTextTableColumn} from "@app/pages/displays/display-cases/TableColumns/GoalTextTableColumn";
import {GoalTextCell} from "@app/pages/displays/display-cases/Cells/GoalTextCell";
import {ColumnTypeSettings} from "@app/pages/displays/display-cases/ColumnTypeSettings";
import {CaseShelves_CaseTextFieldSettingValue, CaseShelves_GoalTextFieldSettingValue} from "@app/core/http/Api/Api";

export class GoalTextColumn extends BaseColumn<GoalTextCell> {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new GoalTextTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: BaseRow): BaseCell {
        return new GoalTextCell(row, this);
    }

    public getField(): string | undefined {
        return this.settings.get(ColumnTypeSettings.GoalText_Field)
            ?.getObject<CaseShelves_GoalTextFieldSettingValue>()
            ?.fieldName;
    }

}
