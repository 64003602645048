/**
 * Created by ModelParser
 * Date: 20-12-2018.
 * Time: 11:42.
 */
import {TaskStatusTypesStatusDefinition,} from './definitions/TaskStatusTypesStatusDefinition';

export class TaskStatusTypesStatus extends TaskStatusTypesStatusDefinition {

    constructor(json?: any) {
        super(json);
    }

}
