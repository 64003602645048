import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {MultiListSource} from "@app/shared/_ui/lists/multi-list/MultiListSource";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {CreateItemSourceConfiguration} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration";
import {CreateItemPreset} from "@app/shared/_ui/create-item-dropdown/CreateItemPreset";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";
import {AnyItem} from "@app/interfaces/CustomTypes";
import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {SortItem} from "@app/shared/_ui/lists/SortItem";

export class MultiListConfiguration extends ListConfiguration implements ListDragInterface {

    constructor() {
        super();
        // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1028
        this.createItemConfiguration = CreateItemSourceConfiguration.AllInDepartment(AppInjector.getInjector().get(UsersService).user?.departments?.map(d => d.id) || []);
        this.createItemConfiguration.showProjects = false;
        this.createItemConfiguration.showTasks = false;
        this.createItemConfiguration.showTodo = false;
        this.createItemConfiguration.showMilestone = false;
        this.createItemConfiguration.showAppointments = false;
        this.createItemPreset = new CreateItemPreset();
    }

    // <editor-fold desc="Sources">

    private hasReceivedDataOnce = false;
    private sources: MultiListSource[] = [];

    public addSource(listConfiguration: ListConfiguration): MultiListConfiguration {
        const source = new MultiListSource(listConfiguration);

        // Prepare data listeners
        const onAddItemsListener = (items: CardItem[]) => {
            this.hasReceivedDataOnce = true;
            this.onAddItemsEvent.emit({items: this.getAllItems()});
        };
        const onAddItemListener = (item: CardItem) => {
            this.onAddItemEvent.emit({item: item});
        }
        const onRemoveItemListener = (item: CardItem) => {
            this.onRemoveItemEvent.emit({item: item});
        }
        const onCountListener = (count: number) => {
            let totalCount = 0;
            this.sources.forEach(source => totalCount += source.itemCount);
            this.onCountEvent.emit({count: totalCount});
        };
        source.addDataListeners(onAddItemsListener, onAddItemListener, onRemoveItemListener, onCountListener);

        this.sources.push(source);
        return this;
    }

    public getSources(): MultiListSource[] {
        return this.sources;
    }

    public getAllItems(): CardItem[] {
        const allItems: CardItem[] = [];
        this.sources.forEach(source => allItems.push(...source.items));
        this.applySortItems(allItems);
        return allItems;
    }

    public addDataListeners(onAddItemsListener: (items: CardItem[]) => void, onAddItemListener: (item: CardItem) => void, onRemoveItemListener: (item: CardItem) => void, onCountListener: (count: number) => void) {
        super.addDataListeners(onAddItemsListener, onAddItemListener, onRemoveItemListener, onCountListener);
        // Check if we got data before data listeners were attached
        if (this.hasReceivedDataOnce) {
            let totalCount = 0;
            this.sources.forEach(source => totalCount += source.itemCount);
            onAddItemsListener(this.getAllItems());
            onCountListener(totalCount);
        }
    }

    // </editor-fold>

    // <editor-fold desc="Push">

    public pushSubscribe() {
        this.sources.forEach(source => source.listConfiguration.pushSubscribe());
    }

    public pushUnsubscribe() {
        this.sources.forEach(source => source.listConfiguration.pushUnsubscribe());
    }

    // </editor-fold>

    // <editor-fold desc="OnScreen Filtering">

    public addOnScreenFilter(value: BaseOnScreenFilter<AnyItem>): MultiListConfiguration {
        this.sources.forEach(source => source.listConfiguration.addOnScreenFilter(value));
        return this;
    }

    // </editor-fold>

    // <editor-fold desc="ListDragInterface">

    public configuration = this;

    public isCardItemDraggable(cardItem: CardItem): boolean {
        let isDraggable = false;
        this.sources.forEach(source => {
            if (source.items.includes(cardItem)) {
                isDraggable = source.listConfiguration.isDraggable();
            }
        });
        return isDraggable;
    }

    public allowCardItemDrop(cardItem: CardItem, fromListConfiguration: ListConfiguration, result: (allow: boolean) => void): void {
        let oneAllow = false; // Only one need to allow

        const allDone = () => {
            result(oneAllow);
        };

        // Recursively check each source. Wait for answer before continuing.
        const checkSource = (i: number) => {
            if (this.sources.length == i) {  // Stop when all sources has been checked
                allDone();
                return;
            }

            this.sources[i].listConfiguration.allowCardItemDrop(cardItem, fromListConfiguration, allow => {
                if (allow) {
                    oneAllow = allow;
                }
                checkSource(++i);
            });
        };

        checkSource(0);
    }

    public onCardItemDragAdd(cardItem: CardItem, fromListConfiguration: ListConfiguration, callback: (() => void)): void {
        // Tilføj til alle sources. Source står selv for at afvise, hvis den ikke passer i validate
        this.sources.forEach(source => {
            source.listConfiguration.onCardItemDragAdd(cardItem, fromListConfiguration, callback);
        });
    }

    public onCardItemDragRemove(cardItem: CardItem): void {
        // Fjern fra alle sources. Source fjerner kun, hvis den allerede er en del af
        this.sources.forEach(source => {
            source.listConfiguration.onCardItemDragRemove(cardItem);
        });
    }

    public onCardItemDragSaved(): void {
        // Bruges på teamtavlerne, for at trigger UserCard reload, efter at opgaven er gemt.
        // Det bør løses på en anden måde.
        // Hvilket gør denne funktion overflødig og immplementeres derfor ikke for MultiListConfiguration
    }

    public getListConfigurationForDragDropEvent(cardItem: CardItem): ListConfiguration {
        // Find source for cardItem and return related list configuration
        return this.sources.find(source => source.items.includes(cardItem)).listConfiguration;
    }

    // </editor-fold>

    // <editor-fold desc="Sorting">

    private sortItems: SortItem[];

    public setSortItems(value: SortItem[]): MultiListConfiguration {
        this.sortItems = value;
        return this;
    }

    public getSortItems(): SortItem[] {
        return this.sortItems;
    }

    private applySortItems(items: CardItem[]) {
        items.sort((a, b) => {
            let value = 0;
            this.sortItems?.find(sortItem => {
                value = sortItem.comparison.compare(
                    sortItem.direction == 'asc' ? a.item : b.item,
                    sortItem.direction == 'asc' ? b.item : a.item
                );
                return value !== 0;
            });
            return value;
        });
    }

    // </editor-fold>

}
