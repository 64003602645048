import {NgModule} from "@angular/core";
import {
    CategoryListEditorComponent
} from "@app/editor/quick-editor/editors/generic/category-list-editor/category-list-editor.component";
import {CategoriesSelectorModule} from "@app/shared/_elements/categories-selector/CategoriesSelector.module";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        CategoriesSelectorModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CategoryListEditorComponent,
    ],
    exports: [
        CategoryListEditorComponent

    ],
    providers: [

    ],
})
export class CategoryListEditorModule {}
