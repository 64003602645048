export class ColumnTypeSettings {

    public static SoftThisWeek_IncludeTaskTypes = 'soft-this-week-include-task-types';

    public static Days_VisibleDays = 'days-visible-days';
    public static Days_IncludeTaskTypes = 'days-include-task-types';
    public static Days_IncludeProjectTypes = 'days-include-project-types';
    public static Days_IncludeTaskPlannings = 'days-include-task-plannings';
    public static Days_IncludeMilestones = 'days-include-milestones';
    public static Days_FilterProjectDeadlineTypes = 'days-filter-project-deadline-types';

    public static SoftNextWeek_IncludeTaskTypes = 'soft-next-week-include-task-types';

}
