export class ColumnTypes {

    public static ProjectCard = 'project-card';
    public static ProjectResponsible = 'project-responsible';
    public static ProjectUserList = 'project-user-list';
    public static ProjectEstimateList = 'project-estimate-list';
    public static ProjectPhase = 'project-phase';
    public static ProjectPhaseDate = 'project-phase-date';
    public static ProjectDeadlineList = 'project-deadline-list';
    public static ProjectDeadlineDifference = 'project-deadline-difference';
    public static ProjectNote = 'project-note';
    public static ProjectCalculatedFieldList = 'project-calculated-field-list';
    public static ProjectPhaseList = 'project-phase-list';
    public static ProjectCategoryList = 'project-category-list';
    public static ProjectStatusList = 'project-status-list';

    public static NextMilestoneCard = 'next-milestone-card';
    public static NextMilestoneRisk = 'next-milestone-risk';
    public static FollowingMilestoneList = 'following-milestone-list';

    public static TaskListNextMilestone = 'task-list-next-milestone';
    public static TaskListAll = 'task-list-all';
    public static TaskListWithoutMilestone = 'task-list-without-milestone';
    public static TaskListRoster = 'task-list-roster';

    public static AppointmentList = 'appointment-list';

    public static TodoList = 'todo-list';

    public static YearWheel = 'year-wheel';

}
