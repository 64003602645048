/**
 * Created by ModelParser
 * Date: 18-08-2020.
 * Time: 13:12.
 */
import {DisplayFiltersProjectTypeDefinition} from './definitions/DisplayFiltersProjectTypeDefinition';

export class DisplayFiltersProjectType extends DisplayFiltersProjectTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
