import {GenericCell} from "@app/pages/displays/display-project-details/subdisplay-milestones/Cells/GenericCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-milestones/Row";
import {
    MilestoneYearWheelColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/MilestoneYearWheelColumn";
import {YearWheelColumnConfiguration} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration";

export class MilestoneYearWheelCell extends GenericCell {

    public yearWheelConfiguration = new YearWheelColumnConfiguration();

    constructor(row: Row, column: MilestoneYearWheelColumn) {
        super(row, column);

        this.yearWheelConfiguration
            .setProject(row.project)
            .setMilestone(row.milestoneCard.item)
            .setStart(column.yearWheelPeriod.start)
            .setEnd(column.yearWheelPeriod.end)
            .setPeriodUnit(column.yearWheelDisplayOptions.unit)
    }

}
