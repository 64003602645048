/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {LoadsTaskTypeDefinition} from './definitions/LoadsTaskTypeDefinition';

export class LoadsTaskType extends LoadsTaskTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
