/**
 * Created by ModelParser
 */
import {ProjectType} from '../ProjectType';
import {TaskEstimateType} from '../TaskEstimateType';
import {TaskType} from '../TaskType';
import {CalculatedFieldsProject} from '../CalculatedFieldsProject';
import {CalculatedFieldsTask} from '../CalculatedFieldsTask';
import {CalculatedFieldsMilestone} from '../CalculatedFieldsMilestone';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class CalculatedFieldDefinition extends BaseModel {
    name?: string;
    type?: string;
    project_type_id?: number;
    project_type?: ProjectType;
    task_estimate_type_id?: number;
    task_estimate_type?: TaskEstimateType;
    task_type_id?: number;
    task_type?: TaskType;
    calculated_fields_projects?: CalculatedFieldsProject[];
    calculated_fields_tasks?: CalculatedFieldsTask[];
    calculated_fields_milestones?: CalculatedFieldsMilestone[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.type;
            delete this.project_type_id;
            delete this.project_type;
            delete this.task_estimate_type_id;
            delete this.task_estimate_type;
            delete this.task_type_id;
            delete this.task_type;
            delete this.calculated_fields_projects;
            delete this.calculated_fields_tasks;
            delete this.calculated_fields_milestones;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.type != null) {
            this.type = data.type;
        }
        if (data.project_type_id != null) {
            this.project_type_id = data.project_type_id;
        }
        if (data.project_type != null) {
            this.project_type = new ProjectType(data.project_type);
        }
        if (data.task_estimate_type_id != null) {
            this.task_estimate_type_id = data.task_estimate_type_id;
        }
        if (data.task_estimate_type != null) {
            this.task_estimate_type = new TaskEstimateType(data.task_estimate_type);
        }
        if (data.task_type_id != null) {
            this.task_type_id = data.task_type_id;
        }
        if (data.task_type != null) {
            this.task_type = new TaskType(data.task_type);
        }
        if (data.calculated_fields_projects != null) {
            this.calculated_fields_projects = data.calculated_fields_projects.map((i: any) => new CalculatedFieldsProject(i));
        }
        if (data.calculated_fields_tasks != null) {
            this.calculated_fields_tasks = data.calculated_fields_tasks.map((i: any) => new CalculatedFieldsTask(i));
        }
        if (data.calculated_fields_milestones != null) {
            this.calculated_fields_milestones = data.calculated_fields_milestones.map((i: any) => new CalculatedFieldsMilestone(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
