/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ProjectFieldsProjectTypeDefinition,} from './definitions/ProjectFieldsProjectTypeDefinition';
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";

export class ProjectFieldsProjectType extends ProjectFieldsProjectTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

    public getSmartName(): string {
        if(this.name) return this.name;
        else return AppInjector.getInjector().get(TranslateService).instant(this.project_field?.language_key);
    }

}
