<ul class="list-unstyled w-100 p-2 border rounded mb-2 d-flex flex-row align-items-end justify-content-between">
    <li class="flex-grow-1 pb-1">
        <app-status-rule-item [model]="statusRule"
                              [readonly]="false"
                              [showRemove]="showRemove"
                              (removeItemEvent)="removeRule()"
                              (onChange)="patch()"
        ></app-status-rule-item>
    </li>
</ul>

