import {NgModule} from "@angular/core";
import {PhasesProjectsComponent} from "@app/shared/_ui/columns/phases-projects/phases-projects.component";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {PhaseModule} from "@app/shared/_elements/phase/Phase.module";
import {ItemDateModule} from "@app/shared/_elements/item-date/ItemDate.module";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ToggleItemModule,
        PhaseModule,
        ItemDateModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        PhasesProjectsComponent,
    ],
    exports: [
        PhasesProjectsComponent

    ],
    providers: [

    ],
})
export class PhasesProjectsModule {}
