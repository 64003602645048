<div class="modal-inner-content p-0 overflow-visible d-flex align-items-center flex-column modal-content" [@openClose]="isOpen ? 'open' : 'closed'"
>
    <div class="modal-header d-flex align-items-center w-100" ngxModalDraggable>
        <h4 class="modal-description" >{{title}}</h4>

        <div class="ms-auto  d-flex align-content-center d-flex me-1">
            <app-color-item class="cursor-pointer me-2"
                            [className]="'medium'"
                            [colorValue]="'#ff5f57'"
                            [iconClass]="'fa-times'"
                            container="body" [ngbTooltip]="('_ui_close' | translate)"
                            (click)="decline();"></app-color-item>
        </div>
    </div>
    <div class="modal-body p-0 ">
        <div class="container p-0 rounded">

                <div class="d-flex flex-column h-100" style="padding-bottom: 61px;">

                    <app-card-project *ngIf="card && false" [item]="card"></app-card-project>

                    <div class="scroll-y align-self-stretch card-content">
                        <div class="container ps-4 pe-4 pt-2 pb-4"  *ngIf="model">
                            <app-project-edit-collection
                                [item]="model"
                                [availableEditors]="editorTypes"
                                [editorEvents]="this"
                            ></app-project-edit-collection>
                        </div>
                    </div>
                </div>

                <ul class="nav nav-pills nav-fill position-absolute bg-light border-top w-100 p-2 d-flex justify-content-between" style="bottom: 0;">

                    <li class="nav-item -px-2" >
                        <button type="button" class="btn btn-sm btn-secondary  panel-button max-width-unset"
                                (click)="decline()">
                            <span>{{'_ui_close' | translate}}</span>
                        </button>
                    </li>

                    <li class="nav-item -px-2" *ngIf="planningDisplay">
                        <button type="button" class="btn btn-sm btn-primary panel-button max-width-unset d-flex align-items-center justify-content-center" (click)="accept()"
                                [disabled]="!model.title || false"
                        >
                            <app-loading-indicator [showLogo]="false" *ngIf="waitForProjectSave"></app-loading-indicator>
                            <ng-container *ngIf="!waitForProjectSave">
                                <span class="fal fa-door-open me-1"></span>
                                <span>{{('_ui_navigate_to' | translate)+ ' ' +planningDisplay?.name}}</span>
                            </ng-container>
                        </button>
                    </li>

                </ul>

        </div>
    </div>
</div>
