import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {
    EditCategoryPickerList
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerList";
import {
    EditCategoryPickerListConfiguration
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerListConfiguration";
import {
    CategoryPickerItem
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/CategoryPickerItem";
import {Category} from "@app/core/models";

@Component({
    selector: 'app-category-picker-list-editor',
    templateUrl: './category-picker-list-editor.component.html',
    styleUrls: ['./category-picker-list-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryPickerListEditorComponent extends BaseEditor<EditCategoryPickerList> {
    protected eventFieldName = 'categories';

    // Bindings to parent
    @Input() model: EditCategoryPickerList & HasEventGenerator & BaseModel;
    @Input() configuration: EditCategoryPickerListConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public types: CategoryPickerItem[];

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    protected render() {
        // Move categories from model to type
        this.configuration.types.forEach(type => {
            type.categories = this.model.findCategoriesByTypeId(type.categoryType.id);

            type.validate();
        });

        this.types = this.configuration.types;
    }

    public onItemSelected(type: CategoryPickerItem, category: Category) {
        this.model.addCategory(category);
        this.render();
    }

    public onItemRemoved(type: CategoryPickerItem, category?: Category) {
        if (category) {
            this.model.removeCategory(category);
        } else {
            [...type.categories].forEach(item => this.model.removeCategory(item));
        }
        this.render();
    }

}
