import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {Shell} from "@app/shell/Shell";
import {extract} from "@app/core";
import {TemplatesComponent} from "@app/pages/administrator/templates/templates.component";
import {TemplatesModule} from "@app/pages/administrator/templates/Templates.module";

const routes: Routes = [
    Shell.childRoutes([
        {
            path: 'administrator',
            pathMatch: 'full',
            component: TemplatesComponent,
            data: {
                title: extract('Templates'),
                animation: 'Page',
            }
        },
        // /app/administrator/templates/2
        {
            path: 'administrator/templates/:id',
            component: TemplatesComponent,
            pathMatch: 'full',
        },

        // {
        //     path: 'templates',
        //     pathMatch: 'full',
        //     component: TemplatesComponent,
        //     data: {
        //         title: extract('Templates'),
        //         animation: 'Page',
        //     }
        // },



        {
            path: 'administrator',
            redirectTo: 'administrator/templates',
            pathMatch: 'full',
            data: {
                title: extract('Templates'),
                animation: 'Page'
            }
        },

        // { path: 'detail/:id', component: HeroDetailComponent },
    ])
];

@NgModule({
    declarations: [],
    imports: [
        TemplatesModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
    providers: []
})
export class AdministratorRoutingModule {
}
