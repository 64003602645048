import {NgModule} from "@angular/core";
import {CategoriesComponent} from "@app/shared/_ui/columns/categories/categories.component";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ColorItemModule,
        NgbTooltipModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        CategoriesComponent,
    ],
    exports: [
        CategoriesComponent

    ],
    providers: [

    ],
})
export class CategoriesModule {}
