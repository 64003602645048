import {BaseComponent} from "@app/shared/_system/base/base.component";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter, Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {User} from "@app/core/models";

@Component({
    selector: 'app-project-participant-add-popover',
    templateUrl: './project-participant-add-popover.component.html',
    styleUrls: ['./project-participant-add-popover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectParticipantAddPopoverComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() selectedUsers: User[];
    @Output() addItem = new EventEmitter<User>();

    // Bindings to View

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.render();
    }

    private render() {

    }

}
