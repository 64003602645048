import {NgModule} from "@angular/core";
import {DropdownListItemComponent} from "@app/shared/_ui/displays/dropdown-list-item/dropdown-list-item.component";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ToggleItemModule,
        TranslateModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        DropdownListItemComponent,
    ],
    exports: [
        DropdownListItemComponent

    ],
    providers: [

    ],
})
export class DropdownListItemModule {}
