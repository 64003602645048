<div class="h-100 flex-fill rounded position-relative toggle-header d-flex flex-column"
     #contentContainer
>
    <div class="d-flex align-items-center text-white justify-content-center bg-dark py-2" style="border-radius: 0">
        <app-nav-filter-selector
            [shellPageData]="shellPageData"
            [titlePrefix]="display?.name"
        />
    </div>
    <div
        class="go-back -bg-info -bg-dark -text-white d-flex flex-row align-items-center justify-content-start -p-1 flex-fill w-100"
        [class.bg-dark]="rowsReady"
    >

        <!-- Header template -->
        <ng-template #headerTemplate let-column="column" ngx-datatable-header-template
                     [typedTemplate]="templateTypes.headerTemplateType"
        >
            <ngx-datatable-column
                [name]="column.name"
                [width]="column.width"
                [maxWidth]="column.maxWidth"
                [minWidth]="column.minWidth"
                [resizeable]="column.resizeable"
            >
                <span class="column-title">{{ column.name }}</span>
            </ngx-datatable-column>
        </ng-template>

        <!-- Header template headerTemplateCopyPrevious -->
        <ng-template #headerTemplateCopyPrevious let-column="column" ngx-datatable-header-template
                     [typedTemplate]="templateTypes.headerTemplateType"
        >

            <div class="column-title d-flex align-items-center">

                <span class=" cursor-pointer me-1 text-small"
                      (click)="copyProjectAllCasesFromLastWeekDialog()"
                      [ngbTooltip]="('_ui_copy_all_from_last_week' | translate)">
                    <i class="fal fa-copy"></i>

                </span>

                <ngx-datatable-column
                    [name]="column.name"
                    [width]="column.width"
                    [maxWidth]="column.maxWidth"
                    [minWidth]="column.minWidth"
                    [resizeable]="column.resizeable">
                    <span>{{ column.name }}</span>
                </ngx-datatable-column>

            </div>
        </ng-template>

        <ng-template #headerDateTemplate let-column="column" ngx-datatable-header-template
                     [typedTemplate]="templateTypes.daysHeaderTemplateType"
        >
            <ngx-datatable-column
                [name]="column.name"
                [width]="column.width"
                [maxWidth]="column.maxWidth"
                [minWidth]="column.minWidth"
                [resizeable]="column.resizeable">
                <div class="d-flex">
                    <span class="text-capitalize-firstletter">{{ column.weekDate | localizedDate: 'EEEE' }}</span>
                    <span class="ms-1">{{ column.weekDate | localizedDate: 'microDate' }}</span>
                </div>
            </ngx-datatable-column>
        </ng-template>

        <!-- Header template with navigation -->
        <ng-template #headerTemplateNavigation let-column="column" ngx-datatable-header-template
                     [typedTemplate]="templateTypes.headerTemplateType"
        >
            <ngx-datatable-column
                [name]="column.name"
                [width]="column.width"
                [maxWidth]="column.maxWidth"
                [minWidth]="column.minWidth"
                [resizeable]="column.resizeable">
                <div class="column-title d-flex align-items-center justify-content-between buttons">

                    <a (click)="toggleExpanded()"
                       *ngIf="visibleSubDisplays && visibleSubDisplays > 0"
                       [ngbTooltip]="isExpanded ? ('_ui_contract_display' | translate) : ('_ui_expand_display' | translate)"
                       class="me-1 ms-1">
                        <i class="fal "
                           [class.fa-arrow-up-from-dotted-line]="isExpanded"
                           [class.fa-arrow-down-from-dotted-line]="!isExpanded"
                           aria-hidden="true"></i>
                    </a>

                    <app-column-sorter [display]="display"
                                       [filterPageData]="shellPageData"
                                       [column]="column"
                                       (columnSortChange)="columnSortChange($event)"
                                       class="flex-grow-1 align-self-start ellipsis"></app-column-sorter>

                    <button class="align-self-end text-small cursor-pointer btn btn-sm btn-micro btn-light"
                            role="button"
                            [ngbTooltip]="('_ui_expand_lists' | translate) + '...'"
                            *ngIf="rows && count - rows.length != 0 && rows.length != 0" (click)="expandProjects()">
                        <!--                        <i class="fal fa-arrow-down"></i>-->

                        <i class="fa-duotone fa-circle-chevron-down"
                           aria-hidden="true"></i>
                    </button>
                </div>
            </ngx-datatable-column>
        </ng-template>

        <!-- Project name template -->
        <ng-template #projectNameTemplate
                     let-column="column"
                     let-row="row"
                     let-rowIndex="rowIndex"
                     [typedTemplate]="templateTypes.projectCardCellTemplateType"
        >
            <div class="wrap d-flex flex-column position-relative card-column">
                <app-card-project [item]="row.projectCard"></app-card-project>
                <div
                    class="action-panel -mt-auto d-flex d-row justify-content-end align-items-center rounded -w-100 ps-2 pe-2 -corner-item wide placement-bottom"
                    [class.disabled]="isPendingRequests"
                >

                    <a class="text-center me-1 ms-1" (click)="loadProject(row.projectCard.item.id)"
                       [ngbTooltip]="('_ui_reload' | translate) + '...'"
                       container="body"
                       placement="right"><i class="fal fa-light fa-arrow-rotate-right"></i></a>
                    <a class="text-center me-1"
                       [ngbTooltip]="('_ui_hide_extra_items' | translate) + '...'"
                       container="body"
                       placement="right"
                       (click)="row.minimizeEvent.emit()"><i class="fal fa-minus-square"></i></a>
                </div>
            </div>
        </ng-template>

        <!-- Cases text template -->
        <ng-template #casesText1Template
                     let-tableColumn="column"
                     let-row="row"
                     let-rowIndex="rowIndex"
                     [typedTemplate]="templateTypes.caseTextCellTemplateType"
        >
            <ng-container *ngIf="row.projectCase && row.projectCase.exists()">
                <app-text-area
                    [dialog]="true"
                    [value]="tableColumn.column.getCell(row).value"
                    [label]="tableColumn.column.column.name"
                    (onChange)="tableColumn.column.getCell(row).setValue($event)"
                />
            </ng-container>
        </ng-template>

        <!-- Goal text template -->
        <ng-template #goalText1Template
                     let-tableColumn="column"
                     let-row="row"
                     let-rowIndex="rowIndex"
                     [typedTemplate]="templateTypes.goalTextCellTemplateType"
        >
            <ng-container *ngIf="row.projectCase && row.projectCase.exists()">
                <app-text-area
                    [dialog]="true"
                    [value]="tableColumn.column.getCell(row).value"
                    [label]="tableColumn.column.column.name"
                    (onChange)="tableColumn.column.getCell(row).setValue($event)"
                />
            </ng-container>
        </ng-template>

        <!-- Cases template -->
        <ng-template #casesTemplate
                     let-column="column"
                     let-row="row"
                     let-rowIndex="rowIndex"
                     [typedTemplate]="templateTypes.cellTemplateType"
        >
            <div *ngIf="row.projectCase && row.projectCase.exists()"
                 class="pt-2 -text-large  d-flex justify-content-end flex-column flex-wrap align-items-end">

                <div class="d-flex align-items-center mb-1 w-100 justify-content-end">

                    <span class="cursor-pointer text-small me-2"
                          (click)="copyProjectCaseFromLastWeek(row)"
                          [ngbTooltip]="('_ui_copy_from_last_week' | translate)">
                        <i class="fal fa-copy"></i>
                    </span>

                    <app-numeric-spinner [value]="row.projectCase.cases"
                                         (onChange)="updateProjectCaseCases($event, row.projectCase);"
                                         class=""></app-numeric-spinner>

                </div>
                <app-value-evaluator *ngIf="row.standardTime"
                                     class="cursor-default me-1 text-large"
                                     [showZero]="false"
                                     [icon]="'fa-clock'"
                                     [badge]="true"
                                     [value]="row.projectCase.cases * row.standardTime"
                                     [ngbTooltip]="('Normtid' | translate)"
                ></app-value-evaluator>
            </div>
        </ng-template>

        <!-- Goal template -->
        <ng-template #goalTemplate
                     let-column="column"
                     let-row="row"
                     let-rowIndex="rowIndex"
                     [typedTemplate]="templateTypes.cellTemplateType"
        >
            <div *ngIf="row.projectCase && row.projectCase.exists()"
                 class="pt-2 -text-large d-flex justify-content-end flex-column flex-wrap align-items-end">
                <div class="d-flex align-items-center mb-1 w-100 justify-content-end">
                    <app-numeric-spinner [value]="row.projectCase.goal"
                                         (onChange)="updateProjectCaseGoal($event, row.projectCase);"
                                         class=" mb-1"></app-numeric-spinner>
                </div>
                <app-value-evaluator *ngIf="row.standardTime" class=" cursor-default me-1  text-large" [badge]="true"
                                     [showZero]="false"
                                     [icon]="'fa-clock'"
                                     [value]="row.projectCase.goal * row.standardTime"
                                     [ngbTooltip]="('Normtid' | translate)"></app-value-evaluator>
            </div>
        </ng-template>


        <!-- Planned template -->
        <ng-template #plannedTemplate
                     let-column="column"
                     let-row="row"
                     let-rowIndex="rowIndex"
                     [typedTemplate]="templateTypes.cellTemplateType"
        >
            <div
                class="d-flex flex-column -justify-content-between pt-2 text-large  d-flex justify-content-end  align-items-end flex-wrap">
                <app-value-evaluator class="cursor-default me-1 mb-1"
                                     [badge]="true"
                                     [value]="row.planned"
                                     [ngbTooltip]="('_task_plan' | translate)"
                ></app-value-evaluator>

                <app-value-evaluator class="cursor-default me-1"
                                     [showZero]="false"
                                     [icon]="'fa-clock'"
                                     [badge]="true"
                                     [value]="row.plannedEstimate"
                                     [ngbTooltip]="('_ui_total_estimate' | translate)"
                ></app-value-evaluator>

            </div>
        </ng-template>

        <!-- Reached template -->
        <ng-template #reachedTemplate
                     let-column="column"
                     let-row="row"
                     let-rowIndex="rowIndex"
                     [typedTemplate]="templateTypes.cellTemplateType"
        >
            <div class="d-flex flex-column justify-content-between pt-2 text-large  align-items-end">
                <app-value-evaluator class="cursor-default me-1 mb-1" [badge]="true"
                                     [value]="row.reached"
                                     [target]="row.planned"
                                     [showColors]="true"
                                     [ngbTooltip]="('_task_reached' | translate)"
                ></app-value-evaluator>
                <app-value-evaluator class="cursor-default me-1"
                                     [icon]="'fa-clock'"
                                     [showZero]="false"
                                     [badge]="true"
                                     [value]="row.reachedEstimate"
                                     [ngbTooltip]="('_ui_total_reached' | translate)"
                ></app-value-evaluator>

            </div>
        </ng-template>

        <!-- Days -->
        <ng-template #daysTemplate
                     let-column="column"
                     let-row="row"
                     let-rowIndex="rowIndex"
                     [typedTemplate]="templateTypes.daysCellTemplateType"
        >
            <app-task-list [listClass]="'w-100'" class="w-100"
                           [configuration]="column.getListConfigurationForRow(row)"
                           (dataSetChanged)="row.onTaskListDataSetChanged(column.weekDay, $event)"
                           [reloadEvent]="row.reloadCasesEvent"
                           [minimizeEvent]="row.minimizeEvent"
            />
        </ng-template>

        <!-- Week -->
        <ng-template #weekTemplate
                     let-tableColumn="column"
                     let-row="row"
                     let-rowIndex="rowIndex"
                     [typedTemplate]="templateTypes.weekCellTemplateType"
        >
            <app-task-list [listClass]="'w-100'" class="w-100"
                           [configuration]="tableColumn.column.getCell(row).listConfiguration"
                           (dataSetChanged)="row.onWeekTaskListDataSetChanged($event)"
                           [reloadEvent]="row.reloadCasesEvent"
                           [minimizeEvent]="row.minimizeEvent"
            />
        </ng-template>

        <!-- Appointment template -->
        <ng-template #appointmentsTemplate
                     let-column="column"
                     let-row="row"
                     let-rowIndex="rowIndex"
                     [typedTemplate]="templateTypes.appointmentListCellTemplateType"
        >
            <app-appointment-list
                [listClass]="'flex-row justify-content-start'"
                [configuration]="column.getListConfigurationForRow(row)"
                [minimizeEvent]="row.minimizeEvent">
            </app-appointment-list>
        </ng-template>

        <div class="d-flex align-items-center justify-content-center w-100 h-100" *ngIf="!rowsReady">
            <app-loading-indicator [showLogo]="false"></app-loading-indicator>
        </div>

        <ngx-datatable
            class="material bootstrap h-100"
            #dataTable
            ngxDatatableExtension [table]="dataTable"

            *ngIf="rows && initialized"
            [rows]="rows"
            [columns]="tableColumns"
            [columnMode]="isDataTableResizeEnabled ? 'force' : 'flex'"

            [headerHeight]="30"
            [class.d-none]="!rowsReady"

            [scrollbarV]="true"
            [scrollbarH]="false"

            [loadingIndicator]="true"
            [externalSorting]="true"
            [virtualization]="false"
            [footerHeight]="30"
        >

            <ngx-datatable-footer *ngIf="showSummary">
                <ng-template
                    ngx-datatable-footer-template
                    let-rowCount="rowCount"
                    let-pageSize="pageSize"
                    let-selectedCount="selectedCount"
                    let-curPage="curPage"
                    let-offset="offset"
                >

                    <div class="datatable-body-row" style="min-height: unset;">

                        <div class="datatable-row-group datatable-row-center" *ngIf="tableColumns">
                            <div *ngFor="let c of tableColumns; index as colIdx"
                                 class="-datatable-body-cell d-flex align-self-start" [style.width.px]="c.width"
                                 [style.height.px]="50">
                                <div
                                    class="datatable-body-cell-label ellipsis w-100 d-flex  align-items-center"
                                    [class.justify-content-start]="c?.column.columnType.identifier == ColumnTypes.ProjectCard"
                                    [class.justify-content-center]="c?.column.columnType.identifier !== ColumnTypes.ProjectCard"
                                >
                                    <ng-container [ngSwitch]="c?.column.columnType.identifier">
                                        <ng-container *ngSwitchCase="ColumnTypes.ProjectCard">
                                            <a class="ms-2 me-2  d-none"
                                               (click)="onCaptureScreenshotBtnClicked();"
                                               [ngbTooltip]="('_ui_screenshot' | translate)"
                                            >
                                                <i class="fal fa-camera-viewfinder" *ngIf="!isCapturingScreenshot"></i>
                                                <app-loading-indicator [showLogo]="false"
                                                                       *ngIf="isCapturingScreenshot"></app-loading-indicator>
                                            </a>

                                            <i class="fal fa-light fa-arrow-rotate-right"
                                               [ngbTooltip]="('_ui_reload' | translate) + '...'"
                                               (click)="reloadAll()"></i>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="ColumnTypes.CaseAmountInput">
                                        <span [ngbTooltip]="('_ui_cases_total_stack' | translate)"><i
                                            class="fal fa-clock me-1"
                                            aria-hidden="true"></i>{{ totalCasesEstimate }}</span>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="ColumnTypes.GoalAmountInput">
                                        <span [ngbTooltip]="('_ui_cases_total_goal' | translate)"><i
                                            class="fal fa-clock me-1"
                                            aria-hidden="true"></i>{{ totalGoalEstimate }}</span>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="ColumnTypes.PlannedValue">
                                        <span [ngbTooltip]="('_ui_cases_total_planned' | translate)"><i
                                            class="fal fa-clock me-1"
                                            aria-hidden="true"></i>{{ totalPlanEstimate }}</span>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="ColumnTypes.AchievedValue">
                                        <span [ngbTooltip]="('_ui_cases_total_done' | translate)"><i
                                            class="fal fa-clock me-1"
                                            aria-hidden="true"></i>{{ totalReachedEstimate }}</span>
                                        </ng-container>
                                    </ng-container>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div
                        class="d-flex flex-row ps-2 pe-2 justify-content-between w-100 align-content-center text-small d-none">
                    </div>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
        <div class="container p-2 d-flex flex-row justify-content-center align-items-center"
             *ngIf="initialized && !hasProjectTypeIdSetting">
            <span class="alert alert-warning" *ngIf="!hasProjectTypeIdSetting">
                    <i class="fa fa-exclamation-triangle me-1" aria-hidden="true"></i>
                    <span>{{ '_ui_display_cases_setting_error' | translate }}</span>
                </span>
        </div>

    </div>

</div>
