import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import Helpers from "@app/core/helpers";
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {DisplayService} from "@app/services/display.service";
import {Department, Display, User} from "@app/core/models";
import {DisplayTypes} from "@app/constants";
import {Router} from "@angular/router";

@Component({
    selector: 'app-url-link',
    templateUrl: './url-link.component.html',
    styleUrls: ['./url-link.component.scss'],
})
export class UrlLinkComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @Input() link: string;
    @Input() expanded: boolean;
    @Input() formControlElement: UntypedFormControl;
    @Input() icon: boolean = false;
    @Input() title: boolean = false;
    @Input() maxLinks: number = 10;
    valid: boolean = true;
    target = '_blank';

    public links: string[] = [];
    public linkNames: string[];
    private selectedUser: User;
    private displays: Display[];
    iconNames: string[];
    public routerLinks: [];

    constructor(private displayService: DisplayService,
                private router: Router,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes['link']) {
            this.link = changes['link'].currentValue;
            if (this.displays)
                this.updateLinks();
        }
        if (changes && changes['formControlElement']) {
            this.link = changes['formControlElement'].currentValue.value;
            if (this.displays)
                this.updateLinks();
        }
    }

    ngOnInit(): void {
        this.updateSubscriptions();
    }

    private updateSubscriptions() {
        this.selectedUser = this.user = this.usersService.user;
        this.subscribe(this.usersService.currentUser$.subscribe(user => {
            this.user = this.selectedUser = user;
            if (this.user && this.user.departments) {
                this.displayService.getDisplaysWithoutSettings(displays => {
                    this.displays = displays.filter(display => display.routerPath);
                    this.updateLinks();
                })
            }
        }));
    }

    isValidHttpUrl(string: string): boolean {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    private updateLinks() {
        if (this.formControlElement) {
            this.link = this.formControlElement.value;
        }

        Department.GetAll((departments) => {

            this.links = [];
            if (this.link) {
                this.target = (this.link.indexOf(window.location.hostname) !== -1 ? '_self' : '_blank');
                this.links = Helpers.extractUrls(this.link).map((linkString) => {
                    if (linkString.indexOf('www') !== -1 && linkString.indexOf('http') === -1) {
                        linkString = 'https://' + linkString;
                    }
                    return linkString;
                });
            }
            this.iconNames = this.links.map(link => 'fal fa-link');
            this.linkNames = this.links.map((link, index) => {
                if (link.indexOf(window.location.hostname) !== -1) {
                    const linkParts = link.split('/');
                    const navigationLink = Helpers.convertUrlToRouterNavigationLink(link);

                    if (linkParts[4] == 'displays') {
                        const displayId = linkParts[6] == 'weekly' ? linkParts[7] : linkParts[6];
                        const display = this.displays ? this.displays.find(d => d.id == parseInt(displayId)) : null;
                        const departmentId = linkParts[6] == 'weekly' ? linkParts[8] : linkParts[7];

                        let displayName: string;

                        if (display) {
                            this.iconNames[index] = Helpers.getIcon(display, false);
                        }

                        switch (linkParts[5]) {
                            case DisplayTypes.Projects.toString():
                            case 'projects':
                                // path: 'displays/projects/:displayId/:departmentId/:userId/:projectPlanningId',
                                displayName = display ? display.name : 'Projects';
                                break;

                            case DisplayTypes.UserOverview.toString():
                            case 'dashboard':
                                // path: 'displays/dashboard/:displayId/:departmentId/:userId', // Display + Department + Valgt bruger
                                return 'UserOverview';
                            case DisplayTypes.Efforts.toString():
                            case 'tasks':
                                displayName = display ? display.name : 'Efforts';
                                break;
                            case DisplayTypes.Team.toString():
                            case DisplayTypes.TeamWeekly.toString():
                            case 'teams':
                                if (linkParts[6] == 'weekly')
                                    // path: 'displays/teams/weekly/:displayId/:departmentId',
                                    displayName = display ? display.name : 'TeamWeekly';
                                else
                                    // path: 'displays/teams/:displayId/:departmentId',
                                    displayName = display ? display.name : 'Team';
                                break;
                            case DisplayTypes.Kanban.toString():
                            case 'kanban':
                                // path: 'displays/projects/:displayId/:departmentId/:userId/:projectPlanningId',
                                displayName = display ? display.name : 'Kanban';
                                break;
                            case DisplayTypes.KanbanProgress.toString():
                            case 'kanban-progress':
                                // path: 'displays/projects/:displayId/:departmentId/:userId/:projectPlanningId',
                                displayName = display ? display.name : 'Kanban Progress';
                                break;
                            case DisplayTypes.Category.toString():
                            case 'categories':
                                displayName = display ? display.name : 'Category';
                                break;
                            default:
                                displayName = display ? display.name : 'KLARTBOARD';
                        }

                        const department = departments.find(d => d.id == parseInt(departmentId));
                        if (department) {
                            return displayName + ', ' + department.name;

                        } else {
                            return displayName;
                        }

                    } else {
                        return link;
                    }
                } else {
                    return link;
                }
            })
            this.valid = this.link && this.links.length > 0;
        });
    }


    navigateToUrl(url: string, $event: MouseEvent, index: number) {
        $event.stopImmediatePropagation();
        if (url.indexOf(window.location.hostname) !== -1) {
            const navigationLink = Helpers.convertUrlToRouterNavigationLink(url);
            // https://podio.com/klartboard/softwareudvikling/apps/stories/items/943
            // https://stackoverflow.com/questions/54062232/what-is-the-use-of-onsameurlnavigation-property-in-angular-versions-greater-tha

            // Same route navigation
            this.router.navigate(['/']).then(() => {
                this.router.navigate(navigationLink.commands, navigationLink.extras)
            });

            // this.router.navigate(navigationLink.commands, navigationLink.extras);
        } else {
            window.open(url, "_blank");
        }
    }
}
