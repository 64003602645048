import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {
    Projects_ProjectDeadlineTypeFromAndToSettingValue,
} from "@app/core/http/Api/Api";

@Component({
    selector: 'app-project-deadline-difference-cell',
    templateUrl: './project-deadline-difference-cell.component.html',
    styleUrls: ['./project-deadline-difference-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDeadlineDifferenceCellComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() row: ProjectsDisplayRow;
    @Input() column: GenericTableColumn;

    // Bindings to view
    public aDate?: Date;
    public bDate?: Date;
    public isVisible = false;

    // Data

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        const data = this.column.column.settings.get(ColumnTypeSettings.ProjectDeadlineTypeFromAndTo)
            ?.getObject<Projects_ProjectDeadlineTypeFromAndToSettingValue>();
        this.aDate = this.row.project.item.findDeadlineByTypeId(data.fromProjectDeadlineTypeId)?.getDate();
        this.bDate = this.row.project.item.findDeadlineByTypeId(data.toProjectDeadlineTypeId)?.getDate();
        this.isVisible = this.aDate !== undefined && this.bDate !== undefined;

        this.detectChanges();
    }

}
