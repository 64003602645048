import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {YearWheelCell} from "@app/pages/displays/display-projects/Cells/YearWheelCell";
import {YearWheelColumn} from "@app/pages/displays/display-projects/Columns/YearWheelColumn";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {TableColumns} from "@app/pages/displays/display-projects/TableColumns";

export class YearWheelTableColumn extends GenericTableColumn {

    public identifier = TableColumns.YearWheel;

    public column: YearWheelColumn;

    public onScroll(rows: ProjectsDisplayRow[], scrollingRow: ProjectsDisplayRow): void {
        const scrollingYearWheelConfiguration = scrollingRow.getCell<YearWheelCell>(this.column).yearWheelConfiguration;
        rows
            .filter(row => row != scrollingRow)
            .map(row => row.getCell<YearWheelCell>(this.column).yearWheelConfiguration)
            .forEach(yearWheelConfiguration => {
                yearWheelConfiguration.updateScrollPosition(scrollingYearWheelConfiguration);
            });
    }

}
