import {CSVExportOptionInterface} from "@app/export/csv/CSVExportOptionInterface";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";

export class CSVListBinding {

    private exportFunction: ((option?: CSVExportOptionInterface) => CSVCellValue);

    public setExportFunction(func: (option?: CSVExportOptionInterface) => CSVCellValue) {
        this.exportFunction = func;
    }

    public export(option?: CSVExportOptionInterface): CSVCellValue {
        return this.exportFunction ? this.exportFunction(option) : [];
    }

}
