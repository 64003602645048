import {Subscription} from 'rxjs';
import {OldApi} from '@app/http/Api';
import {EventEmitter} from '@angular/core';
import {TodoListConfiguration} from '@app/shared/_ui/lists/todo-list/TodoListConfiguration';
import {Todo} from '@app/core/models/Todo';
import Helpers from '@app/core/helpers';
import {AppInjector} from '@app/services/app-injector.service';
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {
    MultiLoaderFetcherInterface
} from "@app/shared/_ui/displays/display-multi-loader/Helpers/MultiLoaderFetcherInterface";
import {Api} from "@app/core/http/Api/Api";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";

export class TodoFetcherRequest {

    public configuration: TodoListConfiguration;
    public result: Todo[] = [];
    public count: number = 0;

    public callback: (items: Todo[], count: number) => void;
    public finished = false;

    public constructor(configuration: TodoListConfiguration) {
        this.configuration = configuration;
        this.configuration.setDataSource(this);
    }

    public reset() {
        this.result = [];
        this.count = 0;
    }

    public addItem(item: Todo) {
        this.result.push(item);
        this.count++;
    }

    public cleanupAndEmitWhenReady(showAll: boolean): any {
        this.applyOrdering();

        this.configuration.onScreenFilterChange.subscribe(() => this.emitSlice(showAll));

        if (this.configuration.hasSmartLimit()) {
            // Wait for SmartLimit. The limit can change over time (cause of screen resize). Keep listening for new limits and return slices.
            this.configuration.onLimitSetEvent.subscribe((limit: number) => this.emitSlice(showAll));
        } else {
            this.emitSlice(showAll);
        }
    }

    private applyOrdering() {
        if (this.configuration.getOrderBy() && this.configuration.getOrderBy().length > 0) {
            let orderBy = this.configuration.getOrderBy()[0];
            switch (orderBy[0]) {
                case 'main_status.status_id':
                    this.result.sort((a, b) => {
                        let aValue = a.main_status != null ? a.main_status.status_id : 0;
                        let bValue = b.main_status != null ? b.main_status.status_id : 0;
                        switch (orderBy[1]) {
                            default:
                            case 'asc':
                                return aValue - bValue;
                            case 'desc':
                                return bValue - aValue;
                        }
                    });
                    break;
            }
        }
    }

    private emitSlice(showAll: boolean) {
        // Apply on screen filters
        const filteredItems = this.result.filter(item => {
            return this.configuration.onScreenFilters.find(filter => !filter.isValid(item)) === undefined;
        });

        // Slice according to requested limit
        const sliceOfItems = this.getSlicedResult(filteredItems, showAll);

        // Emit to ListConfiguration
        this.finished = true;
        if (this.callback) {
            this.callback(sliceOfItems, filteredItems.length);
        }
    }

    private getSlicedResult(items: Todo[], showAll: boolean) {
        if (showAll) {
            return items;
        } else {
            const limit = Math.max(this.configuration.getLastItemCount() ?? 0, this.configuration.getLimit() ?? 0);
            if (limit > 0) {
                return items.slice(0, limit);
            } else {
                return items;
            }
        }
    }

    public clear() {
        this.configuration.setLastItemCount(0);
        this.result = [];
        this.count = 0;
    }

    // Called from TodoList to request "ShowMore/ShowAll"
    public requestSlice(offset: number, limit: number) {
        // Apply on screen filters
        const filteredItems = this.result.filter(item => {
            return this.configuration.onScreenFilters.find(filter => !filter.isValid(item)) === undefined;
        });

        // Slice according to requested offset and limit
        const sliceOfItems = filteredItems.slice(offset, limit);

        // Emit to ListConfiguration
        this.finished = true;
        if (this.callback) {
            this.callback(sliceOfItems, filteredItems.length);
        }
    }

    public subscribe(callback: (items: Todo[], count: number) => void) {
        this.callback = callback;
        if (this.finished) {
            callback(this.result, this.count);
        }
    }

}

export class TodoFetcher extends BaseFetcher implements MultiLoaderFetcherInterface {

    private requests: TodoFetcherRequest[] = [];
    private userIds: number[] = [];
    private projectIds: number[] = [];
    private api: Subscription;
    public showAll = false;

    // Global scope
    private start: Date;
    private end: Date;

    constructor(name?: string) {
        super(name ?? '');
    }

    public clear(): void {
        this.requests = [];
    }

    public addRequest(request: TodoFetcherRequest) {
        this.requests.push(request);
    }

    public executeForPeriod(start: Date, end: Date) {
        // Set global scope
        this.start = start;
        this.end = end;

        // Execute!
        this.execute();
    }

    public execute(showGlobalLoadingIndicator: boolean = true) {
        if (this.requests.length == 0) {
            this.onFinishEvent.emit(true);
            return;
        }

        this.userIds = [];
        this.projectIds = [];

        this.requests.forEach(request => {
            request.reset();

            if (request.configuration.getUser()) {
                let userId = request.configuration.getUser().id;
                if (!this.userIds.includes(userId)) {
                    this.userIds.push(userId);
                }
            }

            if (request.configuration.getProject()) {
                let projectId = request.configuration.getProject().id;
                if (!this.projectIds.includes(projectId)) {
                    this.projectIds.push(projectId);
                }
            }
        });

        let configuration = this.requests[0].configuration;

        let api = Api.todos().get()

        if (this.userIds && this.userIds.length > 0) api.whereIn('user_id', this.userIds);
        if (this.projectIds && this.projectIds.length > 0) api.whereIn('project_id', this.projectIds);

        if (configuration.getDisplay()) api.where('display_id', configuration.getDisplay().id);
        if (configuration.getHasDisplay()) api.whereGreaterThan('display_id', 0);
        if (configuration.getHasDisplay() === false) api.where('display_id', 0);
        if (configuration.getArchived() != null) {
            if (configuration.getArchived() == true)
                api.whereGreaterThan('archived_id', 0);
            else
                api.where('archived_id', 0);
        }

        if (configuration.getShowOnDisplay() != null) {
            if (configuration.getShowOnDisplay() == true)
                api.where('show_on_display', 1);
            else
                api.where('show_on_display', 0);
        }

        if (configuration.getOrArchivedSince()) api.where('or_archived_since', Helpers.serverDate(configuration.getOrArchivedSince()));
        if (configuration.getAvoidIds()) api.whereNotIn('id', configuration.getAvoidIds());

        if (configuration.getDeadlineBetween() || configuration.getDeadlineValidator()) {
            api.whereGreaterThanOrEqual('deadline.date', Helpers.serverDate(this.start))
                .whereLessThanOrEqual('deadline.date', Helpers.serverDate(this.end));
        }
        if (configuration.getStarred() !== null) {
            api.where('starred', configuration.getStarred() ? 1 : 0);
        }
        if (configuration.getHasDeadline() !== null) {
            if (configuration.getHasDeadline()) {
                api.whereGreaterThan('deadline.id', 0);
            } else {
                api.where('deadline.id', 0);
            }
        }

        if (configuration.getUseGlobalFilter()) {
            let filter = AppInjector.getInjector().get(FilterGlobalService).getActiveSettings();
            if (configuration.getUseGlobalSearchFilter() && filter.search) api.search('title', filter.search);
            if (filter.isStarred) api.whereGreaterThan('num_stars', 0);
            if (filter.activeStatuses.length < 4 && filter.activeStatuses.length > 0) {
                api.whereIn('main_status.status_id', filter.activeStatuses);
            }
            if (filter.activeReactionFilters?.length > 0) {
                filter.activeReactionFilters.forEach(reactionFilter => {
                    api.whereIn('reaction_filters', [reactionFilter.reaction_type_id, reactionFilter.value]);
                });
            }
        }

        api.setShowProgressBar(showGlobalLoadingIndicator);
        this.api = api.find(items => {

            // Clear all requests for earlier data
            this.requests.forEach(request => request.clear());

            // Apply projects to fetch requests
            items.forEach(item => {
                this.requests.forEach(request => {
                    if (request.configuration.validate(item))
                        request.addItem(item);
                });
            });

            // Emit each fetch request
            this.requests.forEach(request => {
                request.cleanupAndEmitWhenReady(this.showAll)
            });

            // Emit all done
            this.onFinishEvent.emit(true);
        });
    }


    public cancel() {
        if (this.api) {
            this.api.unsubscribe();
        }
    }
}
