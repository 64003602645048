import {BaseYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/BaseYearWheelItem";
import {PhasesProject, Project, Task} from "@app/core/models";
import {YearWheelColumnConfiguration} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration";

export class TaskYearWheelItem extends BaseYearWheelItem<Task> {

    relatedTasks: TaskYearWheelItem[];
    sortedTasks: TaskYearWheelItem[];

    constructor(configuration: YearWheelColumnConfiguration,
                item: Task,

                xPosInPixels: number,

                width: number,
                startDate: Date
) {
        super();

        this.visible = true;
        this.relatedTasks = [];
        this.sortedTasks = [];

        this.configuration = configuration;
        this.item = item;

        this.left = xPosInPixels;

        this.width = width;
        this.startDate = startDate;
    }

}
