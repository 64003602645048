<app-card-expander
        class="w-100 d-block"
        [items]="items"
        [attr.data-item-count]="itemCount"
        [itemCount]="itemCount"
        [isLoadingEvent]="isLoadingEventEmitter"
        [offset]="offset"
        (onShowMore)="loadMore()"
        (onShowAll)="loadAll()"
        (onShowLess)="loadLess()"
        [showCreateNew]="configuration.getShowCreateNewButton()"
        [draggable]="configuration.isDraggable()"
        [listDragInterface]="configuration"
        [listConfiguration]="configuration"
        [allowDragEnter]="configuration ? configuration.getAllowDragEnter() : true"
        [scrollContainer]="scrollContainer"
        [dataSetChanged]="dataSetChanged"
        [listClass]="listClass"></app-card-expander>



