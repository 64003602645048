import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {ProjectNoteColumn} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/ProjectNoteColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns";

export class ProjectNoteTableColumn extends GenericTableColumn {

    public identifier = TableColumns.ProjectNote;

    public column: ProjectNoteColumn;

}
