import {NgModule} from "@angular/core";
import {TextAreaDialogComponent} from "@app/shared/_modals/text-area-dialog/text-area-dialog.component";
import {TranslateModule} from "@ngx-translate/core";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {QuillModule} from "ngx-quill";
import {FormsModule} from "@angular/forms";
import {GhostContainerModule} from "@app/editor/ghost-container/GhostContainer.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ColorItemModule,
        NgbTooltipModule,
        QuillModule,
        FormsModule,
        GhostContainerModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TextAreaDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class TextAreaDialogModule {}
