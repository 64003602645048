import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {Project} from '@app/core/models/Project';
import {Milestone} from '@app/core/models/Milestone';
import {ProjectsUser} from '@app/core/models/ProjectsUser';
import {EventEmitter} from '@angular/core';
import {CSVDisplayRowInterface} from '@app/export/csv/CSVDisplayRowInterface';
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {CardProjectConfiguration} from "@app/shared/_ui/cards/medium/card-project/card-project-configuration";
import {CardMilestoneConfiguration} from "@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration";
import {Department, Display} from "@app/core/models";
import {CSVColumn} from "@app/export/csv/CSVColumn";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";

export class ProjectsDisplayRow extends BaseRow implements CSVDisplayRowInterface {

    display: Display;
    department: Department;
    project: CardItem<Project>;

    responsible: ProjectsUser;

    nextMilestone: CardItem<Milestone>;
    nextMilestoneRisk: string;

    minimizeEvent = new EventEmitter();
    loadAllEvent = new EventEmitter();
    reloadFollowingMilestonesEvent = new EventEmitter();
    reloadNextMilestoneTasksEvent = new EventEmitter();
    reloadAppointmentsEvent = new EventEmitter();
    reloadTodosEvent = new EventEmitter();
    reloadYearWheelEvent = new EventEmitter();
    rerenderYearWheelEvent = new EventEmitter();
    reloadRostersEvent = new EventEmitter();

    constructor(display: Display, department: Department, project: Project) {
        super();
        this.display = display;
        this.department = department;

        const cardProjectConfiguration = new CardProjectConfiguration();
        cardProjectConfiguration.showNextMilestoneMicroCard = false;
        cardProjectConfiguration.showChangeType = true;

        this.project = new CardItem<Project>(project, cardProjectConfiguration);
        this.responsible = project.responsible;

        let nextMilestone = project.getNextMilestone();
        if (nextMilestone) {
            nextMilestone.projects = [new Project({
                id: project.id,
                project_type_id: project.project_type_id,
                phases_projects: [...project.phases_projects || []]
            })];
            this.nextMilestone = new CardItem<Milestone>(nextMilestone, new CardMilestoneConfiguration(false, true));
        } else {
            this.nextMilestone = null;
        }
        this.nextMilestoneRisk = this.nextMilestone ? this.nextMilestone.item.risk : null;
    }

    public reload() {
        this.reloadFollowingMilestonesEvent.emit();
        this.reloadNextMilestoneTasksEvent.emit();
        this.reloadAppointmentsEvent.emit();
        this.reloadTodosEvent.emit();
        this.reloadYearWheelEvent.emit();
        // this.reloadRostersEvent.emit(); // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1602
    }

    public emitMinimizeEvent() {
        this.minimizeEvent.emit();
    }

    public emitLoadAllEvent() {
        this.loadAllEvent.emit();
    }

    public exportCSVColumn(column: CSVColumn, subColumn?: CSVColumn): CSVCellValue {
        return this.getCell<GenericCell>(column.column).exportCSV(subColumn?.identifier);
    }

}
