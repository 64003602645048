import {Category, CategoryType} from "@app/core/models";
import {
    EditCategoryPickerValidator
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerValidator";

export class CategoryPickerItem {
    public name: string;
    public categoryType: CategoryType;
    public required: boolean;
    public multiple: boolean;
    public validator: EditCategoryPickerValidator;

    public categories: Category[] = [];

    constructor(name: string, categoryType: CategoryType, multiple: boolean,
                validator: EditCategoryPickerValidator) {
        this.name = name;
        this.categoryType = categoryType;
        this.multiple = multiple;
        this.validator = validator;
    }

    public validate() {
        this.validator.validate(this.categories, this.name);
    }

}
