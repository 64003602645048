<div
    *ngIf="activeFilters?.length > 0"
    ngbDropdown
    [autoClose]="'outside'"
    container="body"
>
    <div class="d-flex align-items-center no-wrap">
    <a class="no-caret text-white"
       ngbDropdownToggle
       [ngbTooltip]="('_ui_filter' | translate)"
       [placement]="['bottom','top', 'auto']"
    >
        <i class=" fa-filter"
           [class.fal]="!hasEnabledOnScreenFilter"
           [class.fas]="hasEnabledOnScreenFilter"></i>
    </a>
    <i class="fa fa-exclamation-triangle text-warning cursor-pointer"
       *ngIf="hasEnabledOnScreenFilter"
       (click)="resetFilters()"
       [ngbTooltip]="('_ui_has_on_screen_filters' | translate)"
       aria-hidden="true"></i>
    </div>

    <div ngbDropdownMenu>
        <div class="d-flex flex-column on-screen-filter-list" *ngIf="activeFilters">
            <app-toggle-item *ngFor="let filter of activeFilters"
                             ngbDropdownItem
                             class="me-2"
                             [active]="filter.getEnabled()"
                             [underline]="true"
                             [iconWeightActive]="'fal'"
                             [iconWeightInactive]="'fal'"
                             [class.border-top]="filter.borderTop"
                             [class.pt-2]="filter.borderTop"

                             [iconInactive]="!filter.invertIcon ? 'fa-check-circle' : 'fa-ban'"
                             [icon]="filter.invertIcon ? 'fa-check-circle' : 'fa-ban'"

                             [iconColorInactive]="!filter.invertIcon ? 'text-success' : 'text-danger'"
                             [iconColorActive]="filter.invertIcon ? 'text-success' : 'text-danger'"
                             [label]="(filter.name | translate)"
                             (click)="filter.toggleEnabled()"></app-toggle-item>
        </div>
    </div>
</div>
