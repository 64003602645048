/**
 * Created by ModelParser
 * Date: 23-06-2020.
 * Time: 12:02.
 */
import {ProjectTypesProjectTypeDefinition} from './definitions/ProjectTypesProjectTypeDefinition';

export class ProjectTypesProjectType extends ProjectTypesProjectTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
