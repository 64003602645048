import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    AppointmentListTableColumn
} from "@app/pages/displays/dashboard/dashboard-projects/TableColumns/AppointmentListTableColumn";
import {AppointmentListCell} from "@app/pages/displays/dashboard/dashboard-projects/Cells/AppointmentListCell";

export class AppointmentListColumn extends BaseColumn<AppointmentListCell> {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new AppointmentListTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: BaseRow): BaseCell {
        return new AppointmentListCell(row, this);
    }

}
