/**
 * Created by ModelParser
 * Date: 03-01-2020.
 * Time: 09:52.
 */
import {DepartmentGroupDefinition} from './definitions/DepartmentGroupDefinition';
import {Api} from "@app/core/http/Api/Api";
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";
import {System} from "@app/constants";

export class DepartmentGroup extends DepartmentGroupDefinition {

    constructor(json?: any) {
        super(json);
    }

    // <editor-fold desc="Department Groups">

    private static DataCache?: ApiDataCache<DepartmentGroup>;

    private static GetDataCache(): ApiDataCache<DepartmentGroup> {
        if (!this.DataCache) {
            this.DataCache = new ApiDataCache<DepartmentGroup>(
                System.DepartmentGroup,
                2 * 60 * 60, // 2 hours
                Api.departmentGroups().get()
                    .orderAsc('name')
            );
        }
        return this.DataCache;
    }

    // Entry point
    public static GetAll(callback: (departmentGroups: DepartmentGroup[]) => void) {
        DepartmentGroup.GetDataCache().get(callback);
    }

    // </editor-fold>

}
