import {Injectable} from '@angular/core';
import {BaseService} from '@app/services/base.service';
import {TasksService} from '@app/services/tasks.service';
import {TaskFieldsTaskType} from '@app/core/models/TaskFieldsTaskType';
import {Subject} from 'rxjs';
import {FieldTypes} from "@app/constants";
import {Task, TasksUser} from '@app/core/models';
import {DateChangeDifference} from "@app/interfaces/DateChangeDifference";

export class Field {
    visible: boolean;
    required: boolean;
    translation: string;
    name: string;
    smartName: string;
    index: number;

    constructor(taskFieldsTaskType: TaskFieldsTaskType) {
        this.visible = taskFieldsTaskType.visible;
        this.required = taskFieldsTaskType.required;
        this.translation = taskFieldsTaskType.task_field.language_key;
        this.name = taskFieldsTaskType.name;
        this.smartName = (this.name && this.name.length > 0) ? this.name : this.translation;
        this.index = taskFieldsTaskType.index_;
    }
}

export interface TasksDeadlineChange {
    task: Task;
    dateChangeDifference: DateChangeDifference;
    date?: Date;
    tasks_user?: TasksUser;

}

@Injectable({
  providedIn: 'root'
})
export class TaskEditorService extends BaseService{

    constructor(private tasksService: TasksService) {
        super();
    }

    // <editor-fold desc="Fields">

    private fields: Map<number, TaskFieldsTaskType[]>;
    public getFieldsForType(taskTypeId: number, callback: (fields: TaskFieldsTaskType[]) => void) {
        // Initialize map
        if(!this.fields) this.fields = new Map();

        // Return if already cached
        if(this.fields.has(taskTypeId)) return callback(this.fields.get(taskTypeId));

        // Get and generate fields
        this.tasksService.getTaskType(taskTypeId, taskType => {
            callback(taskType.task_fields_task_types);
            this.fields.set(taskTypeId, taskType.task_fields_task_types);
        });
    }

    public getEditorFieldsForType(taskTypeId: number, callback: (fields: Map<number, Field>) => void) {
        this.getFieldsForType(taskTypeId, fields => {
            let filteredFields = fields ? fields.filter(field => field.task_field.type == FieldTypes.Editor) : [];
            let fieldsMap = new Map<number, Field>();
            filteredFields.forEach(field => fieldsMap.set(field.task_field_id, new Field(field)));
            callback(fieldsMap);
        });
    }

    public getMiniCardFieldsForType(taskTypeId: number, callback: (fields: Map<number, Field>) => void) {
        this.getFieldsForType(taskTypeId, fields => {
            let filteredFields = fields ? fields.filter(field => field.task_field.type == FieldTypes.MiniCard) : [];
            let fieldsMap = new Map<number, Field>();
            filteredFields.forEach(field => fieldsMap.set(field.task_field_id, new Field(field)));
            callback(fieldsMap);
        });
    }

    // </editor-fold>

    // Observable string sources
    private taskDeadlineChange = new Subject<TasksDeadlineChange>();
    taskDeadlineChange$ = this.taskDeadlineChange.asObservable();

    public triggerTaskDeadlineChangeDaysDifference(task: Task, event:any) {
        const object:TasksDeadlineChange = {task: task, dateChangeDifference: event};
        this.taskDeadlineChange.next(object);
    }

    // Observable string sources
    private taskDeadlineChangeDate = new Subject<TasksDeadlineChange>();
    taskDeadlineChangeDate$ = this.taskDeadlineChangeDate.asObservable();
    public triggerTaskDeadlineSetDate(task: Task, event: any) {
        const object:TasksDeadlineChange = {task: task, dateChangeDifference: event};
        this.taskDeadlineChangeDate.next(object);
    }
}
