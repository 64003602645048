import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {Task} from "@app/core/models";

export class NonArchivedFilter extends BaseOnScreenFilter<Task> {

    // Præsenteret som "Fuldført med omvendt ikon"
    public name = '_on_screen_filter_tasks';
    public iconActive = 'fa-ban';
    public iconInactive = 'fa-check-circle';
    public invertIcon: boolean = false;

    protected validate(item: Task): boolean {
        return item.archived_id == 0;
    }

}
