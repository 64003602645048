/**
 * Created by ModelParser
 * Date: 06-12-2019.
 * Time: 13:11.
 */
import {UserFieldDefinition} from './definitions/UserFieldDefinition';

export class UserField extends UserFieldDefinition {

    constructor(json?: any) {
        super(json);
    }

}
