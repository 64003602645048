/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {EstimateDefinition} from './definitions/EstimateDefinition';
import {EstimateUnits} from '@app/constants';

export class Estimate extends EstimateDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static Create(value: number, unit: number): Estimate {
        const item = new Estimate();
        item.value = value;
        item.estimate_unit_id = unit;
        return item;
    }

    public static createDefault() {
        let item = new Estimate();
        item.id = 0;
        item.value = 0;
        item.estimate_unit_id = EstimateUnits.Hours;
        return item;
    }

    public getLoadInSeconds(): number {
        let value = this.value;
        switch(this.estimate_unit_id) {
            case EstimateUnits.Years:
                value *= 12;
            case EstimateUnits.Months:
                value *= 4.34;
            case EstimateUnits.Weeks:
                value *= 7;
            case EstimateUnits.Days:
                value *= 24;
            case EstimateUnits.Hours:
                value *= 60;
            case EstimateUnits.Minutes:
                value *= 60;
                break;
            case EstimateUnits.WorkDay:
                value *= 5 * 24 * 60 * 60;
        }
        return value;
    }

}
