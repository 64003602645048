<div
    *ngIf="isReady"
    class="container p-2 d-flex gap-1 w-100 flex-wrap editor-tab"
>

    <div >
        <label for=""><span [translate]="'_ui_display_emoji'"></span></label>

        <ul class="list-group">
            <li class="list-group-item">
                <app-reaction-list-editor
                    *ngIf="showEmojiReactionListEditor && isSelectedTab"
                    [model]="item"
                    [configuration]="emojiReactionListEditorConfiguration"
                />
            </li>
        </ul>
    </div>

    <app-category-picker-list-editor
        *ngIf="showCategoryPickerListEditor && isSelectedTab"
        [model]="item"
        [configuration]="categoryPickerListEditorConfiguration"
    />

</div>
