import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {Department, MilestonePlan} from "@app/core/models";
import {MilestonesService} from "@app/services/milestones.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Api} from "@app/core/http/Api/Api";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";

@Component({
    selector: 'app-milestone-plan-list',
    templateUrl: './milestone-plan-list.component.html',
    styleUrls: ['./milestone-plan-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MilestonePlanListComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @Input() sortable = false;
    @Input() interactive = true;
    @Input() showEditable = true;
    @Input() only = true;

    @Output() onSelect: EventEmitter<MilestonePlan> = new EventEmitter();
    public milestonePlans: MilestonePlan[];

    public departments: Department[] = [];
    public visibleMilestoneplans: MilestonePlan[];

    constructor(
        private cd: ChangeDetectorRef,
        private milestoneService: MilestonesService,
        private dialogService: BaseDialogService,
    ) {
        super();
        this.cdr = this.cd;

    }

    ngOnInit(): void {
        super.ngOnInit();
        if(!this.interactive && this.sortable){
            this.interactive = true;
        }
        this.milestoneService.milestonePlans$.subscribe(milestonePlans => {
            // console.log('this.milestoneService.milestonePlans$ : ', milestonePlans)
            if (milestonePlans) {
                this.milestonePlans = [];
                this.milestonePlans = [...milestonePlans].filter(item => {
                    return item.isVisibleForUser() && item.isEditableForUser();
                });
                this.filterVisibleMilestonePlans();
            }
            this.detectChanges();
        });

        // Fetch milestone plans and filter by project type id
        if (!this.milestonePlans) {
            MilestonePlan.GetAll(milestonePlans => {
                this.milestonePlans = [...milestonePlans].filter(item => {
                    return item.isVisibleForUser() && item.isEditableForUser();
                });
                this.filterVisibleMilestonePlans();
            });
        }
    }


    ngOnChanges(changes: SimpleChanges): void {
    }

    selectPlan(milestonePlan: MilestonePlan) {
        if(milestonePlan)
            this.onSelect.emit(milestonePlan);
        else
            console.warn('error : selectPlan : ', milestonePlan)
        // if(this.sortable) {
        // }else {
        //     AppInjector.getInjector().get(BaseDialogService).editMilestonePlanDialog(milestonePlan.id);
        // }
    }

    drop(event: CdkDragDrop<MilestonePlan[]>) {
        moveItemInArray(this.milestonePlans, event.previousIndex, event.currentIndex);
        const indexes = this.milestonePlans.map(m => m.id);
        Api.milestonePlans().indexingPut().save({values: indexes}, (r) => {
            console.log('Indexed saved : ', r);
        })
    }

    deletePlan(milestonePlan: MilestonePlan) {
        this.dialogService.confirm(
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_ui_delete_item', {name: milestonePlan.title}),
            this.translateService.instant('_global_delete'),
        )
            .then((confirmed) => {
                if (confirmed) {
                    Api.milestonePlans()
                        .deleteById(milestonePlan.id)
                        .delete((r) => {
                            MilestonePlan.DeleteMilestonePlan(milestonePlan);
                        })
                }
            })
    }

    filterByDepartment($event: Department[]) {
        this.departments = $event;
        this.filterVisibleMilestonePlans();
    }

    private filterVisibleMilestonePlans() {
        if (!this.departments || this.departments.length == 0)
            this.visibleMilestoneplans = this.milestonePlans;
        else {
            const departmentIds = this.departments.map(d => d.id);
            this.visibleMilestoneplans = this.milestonePlans.filter(milestonePlan => {
                return milestonePlan.departments?.find(department => departmentIds.includes(department.id)) !== undefined;
            });
        }
        this.detectChanges();
    }

    emitClose() {
        this.onSelect.emit(null);
    }
}
