/**
 * Created by ModelParser
 * Date: 16-12-2020.
 * Time: 12:56.
 */
import {CategoryTypesProjectTypeDefinition} from './definitions/CategoryTypesProjectTypeDefinition';

export class CategoryTypesProjectType extends CategoryTypesProjectTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
