import {NgModule} from "@angular/core";
import {LogsComponent} from "@app/shared/_ui/logs/logs.component";
import {CommonModule} from "@angular/common";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {ItemCounterModule} from "@app/shared/_elements/item-counter/ItemCounter.module";
import {TranslateModule} from '@ngx-translate/core';
import {SeverityDirectiveModule} from "@app/directives/SeverityDirective.module";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        LocalizedDatePipeModule,
        ItemCounterModule,
        TranslateModule,
        SeverityDirectiveModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        LogsComponent,
    ],
    exports: [
        LogsComponent

    ],
    providers: [

    ],
})
export class LogsModule {}
