<div class="container pb-2 pt-2" *ngIf="configuration">
    <div class="list-group w-100">
        <div class="list-group-item ">
            <div class="input-group">
                <input type="search"
                       autocomplete="off"
                       class="form-control form-control-sm"
                       [placeholder]="('_ui_search' | translate) + '...'"
                       [(ngModel)]="searchValue"
                       (search)="search()"
                       (ngModelChange)="changed($event)"
                />
                    <button role="button" (click)="reset()" class="btn -btn-danger btn-sm btn-outline-danger"><i
                            class="fal fa-times me-1" aria-hidden="true"></i><span >{{'_ui_reset' | translate}}</span>
                    </button>
                    <button role="button" (click)="search()" class="btn -btn-success btn-sm btn-outline-primary"><i
                            class="fal fa-search me-1" aria-hidden="true"></i><span >{{'_ui_search' | translate}}</span>...
                    </button>
            </div>
        </div>
        <div class="wrap -scroll-y" style="height: calc(100vh - 150px);">
            <app-task-list [configuration]="configuration"
                           [reloadEvent]="reloadEmitter"
                           [listClass]="'grid-list full-list'"
                           class="list-group-item p-0  w-100 d-block h-100" ></app-task-list>
        </div>
    </div>
</div>
