import {NgModule} from "@angular/core";
import {
    ProjectListEditorComponent
} from "@app/editor/quick-editor/editors/generic/project-list-editor/project-list-editor.component";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";
import {ProjectSearchModule} from "@app/shared/_forms/search/project-search/ProjectSearch.module";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule,
        NgbPopoverModule,
        ColorLabelModule,
        CardsModule,
        ProjectSearchModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectListEditorComponent,
    ],
    exports: [
        ProjectListEditorComponent

    ],
    providers: [

    ],
})
export class ProjectListEditorModule {}
