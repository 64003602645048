<div class="display-wrapper container-fluid p-0 h-100" style="width: 100vw;" #screen
     [attr.data-isCapturingScreenshot]="isCapturingScreenshot"
>
    <div class="d-flex flex-column h-100" *ngIf="initialized">
        <div class="go-back -bg-info bg-dark text-white d-flex">
            <a class="d-flex flex-row align-items-center p-1 ps-2 text-white" (click)="location.back()">
                <app-toggle-item icon="fa-arrow-left" class="me-1"></app-toggle-item>
                <span class="flex-fill"><span [translate]="'_admin_error_page_go_back'"></span>...</span>
            </a>

            <div class="ms-auto d-flex align-items-center">
                <app-phases-project-picker
                    class="me-1"
                    [detailView]="true"
                    [projects]="[project]"></app-phases-project-picker>

                <app-milestone-plan-selector
                    class="me-2"
                    [project]="project"
                    (onMilestonePlanAppliedEvent)="onMilestonePlanAppliedEvent($event)"
                    *ngIf="project.exists()"></app-milestone-plan-selector>

                <a class="me-2  d-none"
                   (click)="onCaptureScreenshotBtnClicked();"
                   [ngbTooltip]="('_ui_screenshot' | translate) + ' (' +(display?.name)+')'"
                >
                    <i class="fal fa-camera-viewfinder" *ngIf="!isCapturingScreenshot"></i>
                    <app-loading-indicator [showLogo]="false" *ngIf="isCapturingScreenshot"></app-loading-indicator>
                </a>

                <a class="me-2 d-none"
                   (click)="print();"
                   [ngbTooltip]="('_ui_print' | translate)"
                >
                    <i class="fal fa-print"></i>
                </a>

                <a
                    class="me-1"
                    [ngbTooltip]="(topDisplay_Visible ? ('_ui_contract_display' | translate) : ('_ui_expand_display' | translate))"
                    (click)="toggleHeader()">
                    <i class="fal fa-light me-1" aria-hidden="true"
                       [class.fa-arrow-down-from-dotted-line]="!topDisplay_Visible"
                       [class.fa-arrow-up-from-dotted-line]="topDisplay_Visible"
                    ></i>
                </a>

            </div>

        </div>


        <!-- Top -->
        <div class="header-wrapper" *ngIf="topDisplay_Visible && topDisplay_DisplayId">
            <div class="d-flex align-items-center text-white justify-content-center bg-dark" style="border-radius: 0">
                <app-nav-filter-selector [shellPageData]="topDisplay_ShellPageData"></app-nav-filter-selector>
            </div>
            <app-display-project-details-top
                [displayId]="topDisplay_DisplayId"
                [projectId]="projectId"
                [isOpenTasksDragEnabled]="subDisplayUsers_DisplayId == activeSubDisplay"
                [onReloadEventEmitter]="onReloadEventEmitter"
                (onShellPageDataChangeEvent)="topDisplay_ShellPageData = $event"
            ></app-display-project-details-top>
        </div>

        <!-- Sub Displays -->
        <div class="tabs-container outline d-flex flex-column flex-grow-1 p-1 sub-displays-wrapper">
            <ul ngbNav
                #nav="ngbNav"
                [animation]="false"
                [(activeId)]="activeSubDisplay"
                (activeIdChange)="tabChange($event)"
                [destroyOnHide]="false"
                class="nav-tabs categories tabs-editor p-0 m-0">
                <li *ngIf="subDisplayMilestones_Visible"
                    [ngbNavItem]="subDisplayMilestones_DisplayId"
                    [class.active]="subDisplayMilestones_DisplayId == activeSubDisplay"
                    class="flex-grow-1">

                    <div
                       class="nav-link"
                       (click)="changeActiveId(subDisplayMilestones_DisplayId)"
                       [class.active]="activeSubDisplay == subDisplayMilestones_DisplayId"
                    >
                        <span class="group-name ellipsis" *ngIf="subDisplayMilestones_DisplayId !== activeSubDisplay">{{subDisplayMilestones_Name}}</span>
                        <app-nav-filter-selector *ngIf="subDisplayMilestones_DisplayId === activeSubDisplay"
                                                 [titlePrefix]="subDisplayMilestones_Name"
                                                 [shellPageData]="subDisplayMilestones_ShellPageData"
                        ></app-nav-filter-selector>
                    </div>

                    <ng-template ngbNavContent>
                        <app-display-project-details-milestones
                            *ngIf="subDisplayMilestones_IsLoaded"
                            [displayId]="subDisplayMilestones_DisplayId"
                            [projectId]="projectId"
                            [taskEdit_taskDeadlineTypeId]="taskDeadlineTypeId"
                            [taskEdit_taskEstimateTypeId]="taskEstimateTypeId"
                            [onReloadEventEmitter]="onReloadEventEmitter"
                            (onShellPageDataChangeEvent)="subDisplayMilestones_ShellPageData = $event"
                        ></app-display-project-details-milestones>
                    </ng-template>
                </li>

                <li *ngIf="subDisplayUsers_Visible"
                    [ngbNavItem]="subDisplayUsers_DisplayId"
                    [class.active]="subDisplayUsers_DisplayId == activeSubDisplay"
                    class="flex-grow-1">

                    <div
                       class="nav-link"
                       (click)="changeActiveId(subDisplayUsers_DisplayId)"
                       [class.active]="activeSubDisplay == subDisplayUsers_DisplayId"
                    >
                        <span class="group-name ellipsis" *ngIf="subDisplayUsers_DisplayId !== activeSubDisplay">{{subDisplayUsers_Name}}</span>
                        <app-nav-filter-selector *ngIf="subDisplayUsers_DisplayId === activeSubDisplay"
                                                 [shellPageData]="subDisplayUsers_ShellPageData"
                                                 [titlePrefix]="subDisplayUsers_Name"
                        ></app-nav-filter-selector>
                    </div>

                    <ng-template ngbNavContent>
                        <app-display-project-details-users
                            *ngIf="subDisplayUsers_IsLoaded"
                            [displayId]="subDisplayUsers_DisplayId"
                            [projectId]="projectId"
                            [project]="project"
                            [taskEdit_taskDeadlineTypeId]="taskDeadlineTypeId"
                            [taskEdit_taskEstimateTypeId]="taskEstimateTypeId"
                            [onReloadEventEmitter]="onReloadEventEmitter"
                            (onShellPageDataChangeEvent)="subDisplayUsers_ShellPageData = $event"
                        ></app-display-project-details-users>
                    </ng-template>
                </li>

                <li *ngIf="subDisplayTasks_Visible"
                    [ngbNavItem]="subDisplayTasks_DisplayId"
                    [class.active]="subDisplayTasks_DisplayId == activeSubDisplay"
                    class="flex-grow-1">

                    <div
                       class="nav-link"
                       (click)="changeActiveId(subDisplayTasks_DisplayId)"
                       [class.active]="activeSubDisplay == subDisplayTasks_DisplayId"
                    >
                        <span class="group-name ellipsis" *ngIf="subDisplayTasks_DisplayId !== activeSubDisplay">{{subDisplayTasks_Name}}</span>
                        <app-nav-filter-selector *ngIf="subDisplayTasks_DisplayId === activeSubDisplay"
                                                 [shellPageData]="subDisplayTasks_ShellPageData"
                                                 [titlePrefix]="subDisplayTasks_Name"
                        ></app-nav-filter-selector>
                    </div>

                    <ng-template ngbNavContent>
                        <app-display-project-details-tasks
                            *ngIf="subDisplayTasks_IsLoaded"
                            [displayId]="subDisplayTasks_DisplayId"
                            [projectId]="projectId"
                            [project]="project"
                            [taskEdit_taskDeadlineTypeId]="taskDeadlineTypeId"
                            [taskEdit_taskEstimateTypeId]="taskEstimateTypeId"
                            [onReloadEventEmitter]="onReloadEventEmitter"
                            (onShellPageDataChangeEvent)="subDisplayTasks_ShellPageData = $event"
                        ></app-display-project-details-tasks>
                    </ng-template>
                </li>
            </ul>
            <div class="flex-grow-1 tabs-content-wrapper" [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</div>
