<div class="container-fluid p-0 h-100">
    <div class="d-flex flex-column h-100">
        <div class="position-relative project-row -dark header-program" style="min-height: 150px;"
             *ngIf="initialized && rows">
            <ngx-datatable
                class="material bootstrap position-relative h-100"
                #dataTable
                ngxDatatableExtension [table]="dataTable"

                [rows]="rows"
                [columns]="tableColumns"
                [columnMode]="'force'"
                [scrollbarH]="true"
                [scrollbarV]="true"
                [headerHeight]="30"
                [footerHeight]="0"
                [rowHeight]="'auto'"
                [loadingIndicator]="true"
                [externalSorting]="true"
                [virtualization]="false"
            >
            </ngx-datatable>
        </div>
    </div>
</div>

<!-- Header template -->
<ng-template #headerTemplate let-column="column" ngx-datatable-header-template
             [typedTemplate]="templateTypes.headerTemplateType"
>
    <ngx-datatable-column
        [name]="column.name"
        [width]="column.width"
        [maxWidth]="column.maxWidth"
        [minWidth]="column.minWidth"
        [sortable]="false"
        [resizeable]="column.resizeable">
        <div class="column-title ps-1 pe-1 d-flex align-items-center justify-content-start w-100">
            <app-column-sorter [display]="display" [column]="column"
                               [filterPageData]="shellPageData"
                               (columnSortChange)="columnSortChange($event)"
                               class
            ></app-column-sorter>
        </div>
    </ngx-datatable-column>
</ng-template>

<!-- YearWheel PeriodPicker template -->
<ng-template
    #headerTemplateYearWheel
    let-tableColumn="column"
    ngx-datatable-header-template
    [typedTemplate]="templateTypes.headerYearWheelTemplateType"
>
    <ngx-datatable-column [name]="tableColumn.name" [width]="tableColumn.width" [maxWidth]="tableColumn.maxWidth"
                          [minWidth]="tableColumn.minWidth" [resizeable]="tableColumn.resizeable">
        <app-year-wheel-column
            [yearWheelPeriod]="tableColumn.column.yearWheelPeriod"
            [yearWheelDisplayOptions]="tableColumn.column.yearWheelDisplayOptions"
            (onYearWheelPeriodChanged)="tableColumn.column.yearWheelPeriod = $event"
            (onYearWheelDateChanged)="onYearWheelDateChanged($event)"
            (onYearWheelUnitChanged)="onYearWheelUnitChanged($event)"
            (onYearWheelOptionsChanged)="onYearWheelOptionsChanged($event)"
        />
    </ngx-datatable-column>
</ng-template>


<!-- Project name template -->
<ng-template #cellProjectCardTemplate
             let-column="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTemplateType"
>
    <div class="wrap d-flex flex-column position-relative h-100">
        <app-card-project [item]="row.projectCard"></app-card-project>
        <div
            class="action-panel placement-bottom mt-auto d-flex d-row justify-content-end align-items-center rounded pb-1 -w-100 ps-2 pe-2 -corner-item wide"
            [class.disabled]="isPendingRequests"
        >
            <a class="text-center me-1 ms-1" (click)="reload()"
               [ngbTooltip]="('_ui_reload' | translate) + '...'"
               container="body"
               placement="right"><i class="fal fa-light fa-arrow-rotate-right"></i></a>
            <a class="text-center me-1"
               [ngbTooltip]="('_ui_hide_extra_items' | translate) + '...'"
               container="body"
               placement="right"
               (click)="row.minimizeEvent.emit()"><i class="fal fa-minus-square"></i></a>
        </div>
    </div>
</ng-template>

<!-- Responsible template -->
<ng-template #cellResponsibleTemplate
             let-column="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTemplateType"
>
    <div class="wrap">
        <app-header-spacer></app-header-spacer>
        <app-user-with-icon *ngIf="row.responsible" [model]="row.responsible.user"
                            [showInitials]="true"></app-user-with-icon>
    </div>
</ng-template>

<!-- Users template -->
<ng-template #cellUsersTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellProjectUserListTemplateType"
>
    <div class="wrap">
        <app-header-spacer></app-header-spacer>
        <ng-container *ngFor="let projectsUser of tableColumn.column.getCell(row).projectsUsers">
            <app-user-with-icon [model]="projectsUser.user" [showInitials]="true"></app-user-with-icon>
        </ng-container>
    </div>
</ng-template>

<!-- Deadlines template -->
<ng-template #cellDeadlinesTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellProjectDeadlineListTemplateType"
>
    <ng-container *ngFor="let projectsDeadline of tableColumn.column.getCell(row).projectsDeadlines">
        <app-item-date *ngIf="projectsDeadline.deadline"
                       [date]="projectsDeadline.deadline.getDate()"></app-item-date>
    </ng-container>
</ng-template>

<!-- Estimate template -->
<ng-template #cellEstimatesTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellProjectEstimateListTemplateType"
>
    <div class="wrap">
        <app-header-spacer></app-header-spacer>
        <ng-container *ngFor="let projectEstimate of tableColumn.column.getCell(row).projectEstimates">
            <span class="me-1">{{ projectEstimate.estimate.value }}</span>
            <span
                *ngIf="projectEstimate.estimate.estimate_unit">{{ (projectEstimate.estimate.estimate_unit?.language_key | translate) }}</span>
        </ng-container>
    </div>
</ng-template>

<!-- Calculated fields template -->
<ng-template #cellCalculatedFieldsTemplate
             let-column="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTemplateType"
>
    <app-header-spacer></app-header-spacer>
    <ng-container *ngFor="let calculatedFieldsProject of row.projectCard.item.calculated_fields_projects">
        <app-project-calculated-field class="me-1 mb-1"
                                      [project]="row.projectCard.item"
                                      [calculatedField]="calculatedFieldsProject.calculated_field"
                                      [calculatedFieldsProject]="calculatedFieldsProject"
        ></app-project-calculated-field>
    </ng-container>
</ng-template>

<!-- Deadline Difference template -->
<ng-template #cellDateDifferenceTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellProjectDeadlineDifferenceTemplateType"
>
    <app-header-spacer></app-header-spacer>
    <app-date-difference
        *ngIf="tableColumn.column.getCell(row).isVisible"
        [dateA]="tableColumn.column.getCell(row).aDate"
        [dateB]="tableColumn.column.getCell(row).bDate"></app-date-difference>
</ng-template>

<!-- Notes template -->
<ng-template #cellNotesTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellProjectNoteTemplateType"
>
    <div class="wrap d-flex h-100">
        <app-header-spacer></app-header-spacer>
        <app-item-note
            [value]="tableColumn.column.getCell(row).value"
            [model]="row.projectCard.item"
            (onItemNoteChange)="tableColumn.column.getCell(row).setValue($event)"
        />
    </div>
</ng-template>

<!-- Categories template -->
<ng-template #cellCategoriesTemplate
             let-column="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTemplateType"
>
    <app-header-spacer></app-header-spacer>
    <ng-container *ngIf="row.projectCard.item.categories">
        <app-categories
            [categories]="row.projectCard.item.categories"></app-categories>
    </ng-container>
</ng-template>

<!-- Current Phase template -->
<ng-template #cellCurrentPhaseTemplate
             let-column="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTemplateType"
>
    <app-header-spacer></app-header-spacer>
    <app-current-phases-project [project]="row.projectCard.item"></app-current-phases-project>
</ng-template>

<!-- Current Phase Date template -->
<ng-template #cellCurrentPhaseDateTemplate
             let-column="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTemplateType"
>
    <app-header-spacer></app-header-spacer>
    <span *ngIf="row.projectCard.item.current_phases_project">
        <app-item-date [date]="row.projectCard.item.current_phases_project.getStartedDate()"></app-item-date>
    </span>
</ng-template>

<!-- Phases template -->
<ng-template #cellPhasesTemplate
             let-column="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTemplateType"
>
    <app-header-spacer></app-header-spacer>
    <ng-container *ngIf="row.projectCard.item.phases_projects">
        <app-phases-projects [project]="row.projectCard.item"></app-phases-projects>
    </ng-container>
</ng-template>

<!-- Year-wheel template -->
<ng-template #cellYearWheelTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellYearWheelTemplateType"
>
    <app-year-wheel
        [configuration]="tableColumn.column.getCell(row).yearWheelConfiguration"
        [reloadEvent]="row.reloadYearWheelEvent"
        [rerenderEvent]="row.rerenderYearWheelEvent"
    ></app-year-wheel>
</ng-template>

<!-- To-dos template -->
<ng-template #cellTodosTemplate
             let-row="row"
             let-tableColumn="column"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTodoListTemplateType"
>
    <app-todo-list
        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
        [configuration]="tableColumn.column.getCell(row).listConfiguration"
        [minimizeEvent]="row.minimizeEvent"
    />
</ng-template>

<!-- Appointment template -->
<ng-template #cellAppointmentsTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellAppointmentListTemplateType"
>
    <app-appointment-list
        *ngIf="row"
        [listClass]="'flex-row justify-content-start'"
        [configuration]="tableColumn.column.getCell(row).listConfiguration"
        [minimizeEvent]="row.minimizeEvent"
    />
</ng-template>

<!-- Status template -->
<ng-template #cellStatusesTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellProjectStatusListTemplateType"
>
    <app-header-spacer></app-header-spacer>
    <app-project-statuses
        class="d-flex flex-row justify-content-start align-items-start flex-wrap"
        [project]="row.projectCard.item"
        [visibleStatusTypes]="tableColumn.column.getCell(row).visibleProjectStatusTypeIds"
    ></app-project-statuses>
</ng-template>

<!-- Task Without Milestone List template -->
<ng-template #cellTaskWithoutMilestoneListTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTaskListWithoutMilestoneTemplateType"
>
    <app-task-list
        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
        class="w-100"
        [configuration]="tableColumn.column.getCell(row).listConfiguration"
        [minimizeEvent]="row.minimizeEvent">
    </app-task-list>
</ng-template>

<!-- Task List template -->
<ng-template #cellTaskListTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTaskListAllTemplateType"
>
    <app-task-list
        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
        class="w-100"
        [configuration]="tableColumn.column.getCell(row).listConfiguration"
        [minimizeEvent]="row.minimizeEvent">
    </app-task-list>
</ng-template>

<!-- Open Task List template -->
<ng-template #cellOpenTaskListTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTaskListOpenTemplateType"
>
    <app-task-list
        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
        class="w-100"
        [configuration]="tableColumn.column.getCell(row).listConfiguration"
        [minimizeEvent]="row.minimizeEvent">
    </app-task-list>
</ng-template>

<!-- Task Without Deadline List template -->
<ng-template #cellTaskWithoutDeadlineListTemplate
             let-tableColumn="column"
             let-row="row"
             let-rowIndex="rowIndex"
             ngx-datatable-cell-template
             [typedTemplate]="templateTypes.cellTaskListWithoutDeadlineTemplateType"
>
    <app-task-list
        [listClass]="'width-150px grid-3 flex-row justify-content-start'"
        class="w-100"
        [configuration]="tableColumn.column.getCell(row).listConfiguration"
        [minimizeEvent]="row.minimizeEvent">
    </app-task-list>
</ng-template>
