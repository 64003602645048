import {CardConfiguration} from '@app/shared/_ui/cards/CardConfiguration';
import {Project} from '@app/core/models/Project';
import {Display} from '@app/core/models/Display';
import {EventEmitter} from '@angular/core';

export class SmallCardProjectConfiguration extends CardConfiguration<Project> {

    public showGoToPlanningDisplay = true;

    public excludedProgramDisplays: Display[] = [];
    public onGoToProgramClickEvent: EventEmitter<Display> = new EventEmitter<Display>();

    constructor() {
        super();
    }

    get exists(): boolean {
        return true;
    }

    public compare(config: SmallCardProjectConfiguration): boolean {
        return false;
    }

}
