import {Widths} from "@app/constants";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {TemplateRef} from "@angular/core";
import {ColumnTypes} from "@app/pages/displays/display-projects/ColumnTypes";
import {TaskListNextMilestoneColumn} from "@app/pages/displays/display-projects/Columns/TaskListNextMilestoneColumn";
import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";

export class TaskListNextMilestoneColumnType extends BaseColumnType {

    public identifier = ColumnTypes.TaskListNextMilestone;

    public isAlwaysVisible = false;

    public customWidth?: number;
    public minWidth = Widths.CardColumn;
    public maxWidth?: number;

    public resizeable = true;
    public frozenLeft = false;

    public canAutoResize = true;
    public cellClass?: string;

    public isList = true;

    public constructor(cellTemplate: TemplateRef<any>, headerTemplate: TemplateRef<any>,
                       onScreenFilters: BaseOnScreenFilter[]) {
        super();
        this.cellTemplate = cellTemplate;
        this.headerTemplate = headerTemplate;
        this.onScreenFilters = onScreenFilters;
    }

    public createColumn(column: Column, settings: Map<string, ColumnSetting>): TaskListNextMilestoneColumn {
        return new TaskListNextMilestoneColumn(this, column, settings);
    }

}
