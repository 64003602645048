<div #cardContainer
     class="card roster p-1" *ngIf="item && visible" (click)="onCardClick($event)">
    <div class="d-flex w-100 font-size-lg ">
        <app-color-label class="card-type me-1 flex-shrink-0"
                         [status]="item.item.status"
                         [itemWithTypeProperties]="model"
                         [isNew]="item && item.item.acceptance_status == 0"
                         (onStatusChange)="onStatusChange($event)"></app-color-label>

        <div class="title p-0 font-weight-bold col -border-bottom -border-light flex-fill me-1">
            <div class="pt-0 h-100 text-capitalize-firstletter">
                {{item.item?.findTasksUsersByType(102)[0]?.user?.first_name}}
            </div>
        </div>


        <div class="d-flex align-items-center ms-auto">


            <div class="me-1" *ngIf="isFieldVisible(Fields.Archived)">
                <app-toggle-item [interactive]="true"
                                 [active]="model.archived_id"
                                 [class.text-black-50]="!model?.archived_id"
                                 [ngbTooltip]="model?.archived_id == 0 ? translateService.instant('_ui_complete') : translateService.instant('_ui_reopen')"
                                 [icon]="'fa-check-circle'"
                                 [iconInactive]="'fa-circle'"
                                 (onToggle)="onArchivedToggle($event); "></app-toggle-item>
            </div>

            <div class="me-1 d-flex align-items-center" *ngIf="item.item?.task_estimates"
                 [ngbTooltip]="item.item?.task_estimates[0]?.estimate.value + ' ' + (item.item?.task_estimates[0]?.estimate.estimate_unit?.language_key | translate)"
            >
                <i class="fal fa-clock me-1"></i><span>{{item.item?.task_estimates[0]?.estimate.value}}</span>
            </div>



            <a class="text-danger remove d-flex flex-row align-items-center ms-1 me-1"
               (click)="onDeleteEvent(model, $event)"
            >
                <i class="fal fa-trash-alt"></i>
            </a>
        </div>

    </div>
</div>
