import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef, EventEmitter,
    Input,
    OnChanges,
    OnInit, Output,
    SimpleChanges, ViewChild
} from "@angular/core";
import {PANEL_ANIMATION} from "@app/editor/panel.animation";
import {fadeAnimation, slideDownAnimation} from "@app/animations";
import {Todo} from "@app/core/models";
import {BaseEditorV3Component} from "@app/editor/base-editor-v3/base-editor-v3.component";
import {AnyItem} from "@app/interfaces/CustomTypes";
import {Tabs} from "@app/constants";
import {TranslateService} from "@ngx-translate/core";
import {TodosService} from "@app/services/todos.service";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {Field} from "@app/editor/todo-editor-loader/todo-editor.service";
import {EventService} from "@app/services/event.service";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {ValidatorCollection} from "@app/editor/Validation/ValidatorCollection";

@Component({
    selector: 'app-todo-editor-v3',
    templateUrl: './todo-editor-v3.component.html',
    styleUrls: [
        '../../base-editor/base-editor.component.scss',
        'todo-editor-v3.component.scss',
    ],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoEditorV3Component extends BaseEditorV3Component implements OnInit, OnChanges {

    // Bindings to parent
    @Input() item: Todo;
    @Input() options: any;
    @Input() fields: Map<number, Field>;
    @Output() onSavedEventEmitter = new EventEmitter<Todo>();
    @Output() onReloadEventEmitter = new EventEmitter();
    @Output() onCloseEventEmitter = new EventEmitter();

    // Bindings to view
    public isReady = false;
    public isOpen = true;
    public selectedTab = Tabs.Basic;
    public validator = new ValidatorCollection([]);
    public basicTabValidator: ValidationErrorInterface;

    // Data

    constructor(
        private cd: ChangeDetectorRef,
        private todosService: TodosService,
        private translateService: TranslateService,
        private dialogService: BaseDialogService,
        private eventService: EventService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['item']) {
            this.open();
        }
    }

    protected open() {
        super.open();
        this.isReady = false;
        this.isOpen = true;

        if (this.options && this.options.selectedTab) {
            this.selectedTab = this.options.selectedTab;
        }

        this.render();
    }

    private close() {
        this.onCloseEventEmitter.emit();
    }

    private render() {
        this.isReady = true;
    }

    // <editor-fold desc="View actions">

    public onCloseBtnClicked() {
        this.close();
    }

    public onDeleteBtnClicked() {
        this.dialogService.confirm(
            this.translateService.instant('_global_delete'),
            this.translateService.instant(`_ui_delete_item`, {name: this.item.title}),
            this.translateService.instant('_global_delete'),
        )
            .then((confirmed) => {
                if (confirmed) {
                    this.todosService.delete$(this.item).subscribe(todo => {
                        this.close();
                    });
                }
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    public onTabChanged($event: any) {
        this.selectedTab = $event.nextId;
    }

    public onTitleValidatorChanged($event: ValidationErrorInterface) {
        this.validator.addValidator($event);
    }

    public onBasicValidatorChanged(validator: ValidationErrorInterface) {
        this.basicTabValidator = validator;
        this.validator.addValidator(validator);
    }

    // </editor-fold>
}
