import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {
    ProjectUserListCellComponent
} from "@app/pages/displays/display-projects/CellComponents/project-user-list-cell/project-user-list-cell.component";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";

@NgModule({
    imports: [
        CommonModule,
        UserWithIconModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectUserListCellComponent,
    ],
    exports: [
        ProjectUserListCellComponent

    ],
    providers: [

    ],
})
export class ProjectUserListCellModule {}
