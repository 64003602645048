/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TaskDeadlineTypesTaskTypeDefinition,} from './definitions/TaskDeadlineTypesTaskTypeDefinition';

export class TaskDeadlineTypesTaskType extends TaskDeadlineTypesTaskTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

}
