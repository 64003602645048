import {Project} from "@app/core/models";
import {Task} from "@app/core/models";

export class TaskRow {

    public task: Task;
    public isSelectedForMove: boolean;

    public prevProject?: Project;
    public nextProject?: Project;

    public constructor(task: Task, isSelectedForMove: boolean) {
        this.task = task;
        this.isSelectedForMove = isSelectedForMove;
    }

    public get hasNextProject(): boolean {
        return this.nextProject !== undefined;
    }

}
