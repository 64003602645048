import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {UserTypeItem} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserTypeItem";

export class EditUserListConfiguration extends BaseEditorConfiguration {

    public labelName: string;
    public types: UserTypeItem[];

    constructor(labelName: string, types: UserTypeItem[]) {
        super();
        this.labelName = labelName;
        this.types = types;
    }

}
