/**
 * Created by ModelParser
 */
import {Project} from '../Project';
import {User} from '../User';
import {ProjectUserType} from '../ProjectUserType';
import {ProjectsUserPlan} from '../ProjectsUserPlan';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class ProjectsUserDefinition extends BaseModel {
    project_id?: number;
    project?: Project;
    user_id?: number;
    user?: User;
    project_user_type_id?: number;
    project_user_type?: ProjectUserType;
    projects_user_plans?: ProjectsUserPlan[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.project_id;
            delete this.project;
            delete this.user_id;
            delete this.user;
            delete this.project_user_type_id;
            delete this.project_user_type;
            delete this.projects_user_plans;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.project_id != null) {
            this.project_id = data.project_id;
        }
        if (data.project != null) {
            this.project = new Project(data.project);
        }
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.project_user_type_id != null) {
            this.project_user_type_id = data.project_user_type_id;
        }
        if (data.project_user_type != null) {
            this.project_user_type = new ProjectUserType(data.project_user_type);
        }
        if (data.projects_user_plans != null) {
            this.projects_user_plans = data.projects_user_plans.map((i: any) => new ProjectsUserPlan(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
