/**
 * Created by ModelParser
 */
import {ProjectStatus} from '../ProjectStatus';
import {ProjectType} from '../ProjectType';
import {PhasesProject} from '../PhasesProject';
import {Archived} from '../Archived';
import {TaskEstimateType} from '../TaskEstimateType';
import {MilestonePlan} from '../MilestonePlan';
import {Milestone} from '../Milestone';
import {ProjectsUser} from '../ProjectsUser';
import {Appointment} from '../Appointment';
import {ProjectsDeadline} from '../ProjectsDeadline';
import {Task} from '../Task';
import {User} from '../User';
import {Department} from '../Department';
import {DepartmentsProject} from '../DepartmentsProject';
import {ProjectCase} from '../ProjectCase';
import {Phase} from '../Phase';
import {Project} from '../Project';
import {MilestonesProject} from '../MilestonesProject';
import {ProjectsTask} from '../ProjectsTask';
import {ProjectsRelatedProject} from '../ProjectsRelatedProject';
import {Origin} from '../Origin';
import {HandUp} from '../HandUp';
import {Tag} from '../Tag';
import {ProjectEstimate} from '../ProjectEstimate';
import {Todo} from '../Todo';
import {Favorite} from '../Favorite';
import {CalculatedFieldsProject} from '../CalculatedFieldsProject';
import {Category} from '../Category';
import {Reaction} from '../Reaction';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class ProjectDefinition extends BaseModel {
    title?: string;
    purpose?: string;
    main_status_id?: number;
    main_status?: ProjectStatus;
    project_type_id?: number;
    project_type?: ProjectType;
    obs?: string;
    current_phases_project_id?: number;
    current_phases_project?: PhasesProject;
    archived_id?: number;
    archived?: Archived;
    main_task_estimate_type_id?: number;
    main_task_estimate_type?: TaskEstimateType;
    milestone_plan_id?: number;
    milestone_plan?: MilestonePlan;
    num_hand_ups?: number;
    num_stars?: number;
    reference?: string;
    notes?: string;
    open?: boolean;
    next_milestone_id?: number;
    next_milestone?: Milestone;
    responsible_id?: number;
    responsible?: ProjectsUser;
    use_status_rules?: boolean;
    notes2?: string;
    notes3?: string;
    notes4?: string;
    notes5?: string;
    notes6?: string;
    notes7?: string;
    notes8?: string;
    notes9?: string;
    notes10?: string;
    appointments?: Appointment[];
    milestones?: Milestone[];
    projects_deadlines?: ProjectsDeadline[];
    project_statuses?: ProjectStatus[];
    projects_users?: ProjectsUser[];
    tasks?: Task[];
    users?: User[];
    departments?: Department[];
    departments_projects?: DepartmentsProject[];
    project_cases?: ProjectCase[];
    phases?: Phase[];
    phases_projects?: PhasesProject[];
    projects?: Project[];
    related_projects?: Project[];
    milestones_projects?: MilestonesProject[];
    projects_tasks?: ProjectsTask[];
    projects_related_projects?: ProjectsRelatedProject[];
    origins?: Origin[];
    hand_ups?: HandUp[];
    tags?: Tag[];
    project_estimates?: ProjectEstimate[];
    todos?: Todo[];
    favorites?: Favorite[];
    calculated_fields_projects?: CalculatedFieldsProject[];
    categories?: Category[];
    reactions?: Reaction[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.title;
            delete this.purpose;
            delete this.main_status_id;
            delete this.main_status;
            delete this.project_type_id;
            delete this.project_type;
            delete this.obs;
            delete this.current_phases_project_id;
            delete this.current_phases_project;
            delete this.archived_id;
            delete this.archived;
            delete this.main_task_estimate_type_id;
            delete this.main_task_estimate_type;
            delete this.milestone_plan_id;
            delete this.milestone_plan;
            delete this.num_hand_ups;
            delete this.num_stars;
            delete this.reference;
            delete this.notes;
            delete this.open;
            delete this.next_milestone_id;
            delete this.next_milestone;
            delete this.responsible_id;
            delete this.responsible;
            delete this.use_status_rules;
            delete this.notes2;
            delete this.notes3;
            delete this.notes4;
            delete this.notes5;
            delete this.notes6;
            delete this.notes7;
            delete this.notes8;
            delete this.notes9;
            delete this.notes10;
            delete this.appointments;
            delete this.milestones;
            delete this.projects_deadlines;
            delete this.project_statuses;
            delete this.projects_users;
            delete this.tasks;
            delete this.users;
            delete this.departments;
            delete this.departments_projects;
            delete this.project_cases;
            delete this.phases;
            delete this.phases_projects;
            delete this.projects;
            delete this.related_projects;
            delete this.milestones_projects;
            delete this.projects_tasks;
            delete this.projects_related_projects;
            delete this.origins;
            delete this.hand_ups;
            delete this.tags;
            delete this.project_estimates;
            delete this.todos;
            delete this.favorites;
            delete this.calculated_fields_projects;
            delete this.categories;
            delete this.reactions;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.title != null) {
            this.title = data.title;
        }
        if (data.purpose != null) {
            this.purpose = data.purpose;
        }
        if (data.main_status_id != null) {
            this.main_status_id = data.main_status_id;
        }
        if (data.main_status != null) {
            this.main_status = new ProjectStatus(data.main_status);
        }
        if (data.project_type_id != null) {
            this.project_type_id = data.project_type_id;
        }
        if (data.project_type != null) {
            this.project_type = new ProjectType(data.project_type);
        }
        if (data.obs != null) {
            this.obs = data.obs;
        }
        if (data.current_phases_project_id != null) {
            this.current_phases_project_id = data.current_phases_project_id;
        }
        if (data.current_phases_project != null) {
            this.current_phases_project = new PhasesProject(data.current_phases_project);
        }
        if (data.archived_id != null) {
            this.archived_id = data.archived_id;
        }
        if (data.archived != null) {
            this.archived = new Archived(data.archived);
        }
        if (data.main_task_estimate_type_id != null) {
            this.main_task_estimate_type_id = data.main_task_estimate_type_id;
        }
        if (data.main_task_estimate_type != null) {
            this.main_task_estimate_type = new TaskEstimateType(data.main_task_estimate_type);
        }
        if (data.milestone_plan_id != null) {
            this.milestone_plan_id = data.milestone_plan_id;
        }
        if (data.milestone_plan != null) {
            this.milestone_plan = new MilestonePlan(data.milestone_plan);
        }
        if (data.num_hand_ups != null) {
            this.num_hand_ups = data.num_hand_ups;
        }
        if (data.num_stars != null) {
            this.num_stars = data.num_stars;
        }
        if (data.reference != null) {
            this.reference = data.reference;
        }
        if (data.notes != null) {
            this.notes = data.notes;
        }
        if (data.open != null) {
            this.open = data.open;
        }
        if (data.next_milestone_id != null) {
            this.next_milestone_id = data.next_milestone_id;
        }
        if (data.next_milestone != null) {
            this.next_milestone = new Milestone(data.next_milestone);
        }
        if (data.responsible_id != null) {
            this.responsible_id = data.responsible_id;
        }
        if (data.responsible != null) {
            this.responsible = new ProjectsUser(data.responsible);
        }
        if (data.use_status_rules != null) {
            this.use_status_rules = data.use_status_rules;
        }
        if (data.notes2 != null) {
            this.notes2 = data.notes2;
        }
        if (data.notes3 != null) {
            this.notes3 = data.notes3;
        }
        if (data.notes4 != null) {
            this.notes4 = data.notes4;
        }
        if (data.notes5 != null) {
            this.notes5 = data.notes5;
        }
        if (data.notes6 != null) {
            this.notes6 = data.notes6;
        }
        if (data.notes7 != null) {
            this.notes7 = data.notes7;
        }
        if (data.notes8 != null) {
            this.notes8 = data.notes8;
        }
        if (data.notes9 != null) {
            this.notes9 = data.notes9;
        }
        if (data.notes10 != null) {
            this.notes10 = data.notes10;
        }
        if (data.appointments != null) {
            this.appointments = data.appointments.map((i: any) => new Appointment(i));
        }
        if (data.milestones != null) {
            this.milestones = data.milestones.map((i: any) => new Milestone(i));
        }
        if (data.projects_deadlines != null) {
            this.projects_deadlines = data.projects_deadlines.map((i: any) => new ProjectsDeadline(i));
        }
        if (data.project_statuses != null) {
            this.project_statuses = data.project_statuses.map((i: any) => new ProjectStatus(i));
        }
        if (data.projects_users != null) {
            this.projects_users = data.projects_users.map((i: any) => new ProjectsUser(i));
        }
        if (data.tasks != null) {
            this.tasks = data.tasks.map((i: any) => new Task(i));
        }
        if (data.users != null) {
            this.users = data.users.map((i: any) => new User(i));
        }
        if (data.departments != null) {
            this.departments = data.departments.map((i: any) => new Department(i));
        }
        if (data.departments_projects != null) {
            this.departments_projects = data.departments_projects.map((i: any) => new DepartmentsProject(i));
        }
        if (data.project_cases != null) {
            this.project_cases = data.project_cases.map((i: any) => new ProjectCase(i));
        }
        if (data.phases != null) {
            this.phases = data.phases.map((i: any) => new Phase(i));
        }
        if (data.phases_projects != null) {
            this.phases_projects = data.phases_projects.map((i: any) => new PhasesProject(i));
        }
        if (data.projects != null) {
            this.projects = data.projects.map((i: any) => new Project(i));
        }
        if (data.related_projects != null) {
            this.related_projects = data.related_projects.map((i: any) => new Project(i));
        }
        if (data.milestones_projects != null) {
            this.milestones_projects = data.milestones_projects.map((i: any) => new MilestonesProject(i));
        }
        if (data.projects_tasks != null) {
            this.projects_tasks = data.projects_tasks.map((i: any) => new ProjectsTask(i));
        }
        if (data.projects_related_projects != null) {
            this.projects_related_projects = data.projects_related_projects.map((i: any) => new ProjectsRelatedProject(i));
        }
        if (data.origins != null) {
            this.origins = data.origins.map((i: any) => new Origin(i));
        }
        if (data.hand_ups != null) {
            this.hand_ups = data.hand_ups.map((i: any) => new HandUp(i));
        }
        if (data.tags != null) {
            this.tags = data.tags.map((i: any) => new Tag(i));
        }
        if (data.project_estimates != null) {
            this.project_estimates = data.project_estimates.map((i: any) => new ProjectEstimate(i));
        }
        if (data.todos != null) {
            this.todos = data.todos.map((i: any) => new Todo(i));
        }
        if (data.favorites != null) {
            this.favorites = data.favorites.map((i: any) => new Favorite(i));
        }
        if (data.calculated_fields_projects != null) {
            this.calculated_fields_projects = data.calculated_fields_projects.map((i: any) => new CalculatedFieldsProject(i));
        }
        if (data.categories != null) {
            this.categories = data.categories.map((i: any) => new Category(i));
        }
        if (data.reactions != null) {
            this.reactions = data.reactions.map((i: any) => new Reaction(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
