<div class="default-loader justify-content-center">


    <div class="d-flex align-items-center flex-column border align-self-center">


        <h4 class="card-title text-center ">
            <div class="image-wrap" >

            </div>
        </h4>

        <div class="d-flex flex-row align-items-center mb-0 rounded p-2 mb-2">
            <app-loading-indicator class="me-2" [showLogo]="false"></app-loading-indicator>
        </div>

    </div>

</div>


<div class="login-container bg-light d-none">
    <div class="login-box">

        <span *ngIf="!!email">{{ email }}&nbsp;&nbsp;</span>

        <div class="container">
            <div class="d-flex">
                <div class="card-body" (click)="login()">

                    <h4 class="card-title text-center">
                        <!--<i class="fas fa-3x fa-user-circle text-muted"></i>-->
                        <div class="logo"></div>

                    </h4>
                    <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
                        <div class="d-flex d-flex flex-column">
                            <button class="btn btn-primary w-100 d-none" (click)="login()"
                                    *ngIf="(signedIn | async) === false"><i class="far fa-badge-check"></i> OAuth <span
                                >{{'_admin_login' | translate}}</span></button>
                            <button class="btn btn-danger w-100 d-none" (click)="logout()" *ngIf="signedIn | async"><i
                                class="far fa-badge-check"></i> OAuth <span >{{'_admin_logout' | translate}}</span></button>
                        </div>


                    </form>
                </div>
            </div>
        </div>

        <div class="text-black-50 mt-1 d-none">
            <div class="d-inline-block text-center d-flex flex-column"><!--v{{version}}-->
                <h6 class="small mb-1 font-weight-bold" *ngIf="environment.environment"
                    [class.text-warning]="environment.environment != 'Production'"
                    [class.text-success]="environment.environment == 'Test'"
                >{{environment.environment}} (Frontend) </h6>

                <div class="d-flex flex-row justify-content-between text-center small">
                    <div class="text-danger me-2 small" *ngIf="!environment.production">Udviklingsversion</div>
                    <div class="small text-black-50 me-2" *ngIf="gitVersions"><i
                        class="fa fa-code-branch"></i> {{environment.version}}, <i
                        class="fab fa-github"></i> {{gitVersions.npm_version}}, {{gitVersions.revision}}
                        , {{gitVersions.branch}}</div>
                    <div class="small text-black-50" *ngIf="gitVersions"><i
                        class="fab fa-google"></i> {{gitVersions.version}}
                        <ng-container *ngIf="gitVersions.project_id !== '_PROJECT_ID_'">
                            , {{gitVersions.project_id}}</ng-container>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
