import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnChanges,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {ProjectsService} from '@app/services/projects.service';
import {PageComponent} from '../../page.component';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {Project, ProjectUserTypes} from '@app/core/models/Project';
import {Subscription} from 'rxjs';
import {CardProjectConfiguration} from '@app/shared/_ui/cards/medium/card-project/card-project-configuration';
import {EventService} from '@app/services/event.service';
import {Settings} from '@app/pages/displays/display-projects/Settings';
import {Events, ProjectStatusTypes, StatusTypes,} from '@app/constants';
import Helpers from '@app/core/helpers';
import * as moment from 'moment';
import {DisplayFilter} from '@app/core/models/DisplayFilter';
import {Filters} from '@app/pages/displays/display-projects/Filters';
import {ProjectType} from '@app/core/models/ProjectType';
import {ProjectsDisplayRow} from '@app/pages/displays/display-projects/ProjectDisplayRow';
import {CSVExporter} from '@app/export/csv/CSVExporter';
import {Location} from "@angular/common";
import {CreateItemSourceConfiguration} from '@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration';
import {CreateItemPreset} from "@app/shared/_ui/create-item-dropdown/CreateItemPreset";
import {CreateItemInterface} from '@app/shared/_ui/create-item-dropdown/CreateItemInterface';
import {CreateItemSourceInterface} from '@app/shared/_ui/create-item-dropdown/CreateItemSourceInterface';
import {Page} from '@app/pages/displays/display-projects/Page';
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Api} from "@app/core/http/Api/Api";
import {BaseApi} from "@app/core/http/Api/BaseApi";
import {GenericTableColumn} from './TableColumns/GenericTableColumn';
import {NonArchivedFilter} from "@app/shared/_ui/lists/task-list/OnScreenFilters/NonArchivedFilter";
import {ExportService} from "@app/services/ExportService/export.service";
import {BaseOnScreenFilter} from '@app/shared/_ui/lists/BaseOnScreenFilter';
import {ScreenshotHelper} from "@app/core/ScreenshotHelper/ScreenshotHelper";
import {TasksService} from '@app/services/tasks.service';
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {CountRunner} from "@app/core/CountRunner/CountRunner";
import {
    MilestoneProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneProjectPresetGenerator";
import {
    ProjectStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectStatusPresetGenerator";
import {
    ProjectUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUseStatusRulesPresetGenerator";
import {
    ProjectDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectDepartmentPresetGenerator";
import {
    ProjectUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUserPresetGenerator";
import {
    ProjectRelatedProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectRelatedProjectPresetGenerator";
import {SortItem} from "@app/shared/_ui/lists/SortItem";
import {StatusComparison} from "@app/shared/_ui/lists/multi-list/Comparisons/StatusComparison";
import {ChangeEvent, WampService} from "../../../../services/wamp.service";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {ProjectCardColumnType} from "@app/pages/displays/display-projects/ColumnTypes/ProjectCardColumnType";
import {
    NextMilestoneCardColumnType
} from "@app/pages/displays/display-projects/ColumnTypes/NextMilestoneCardColumnType";
import {
    TaskListNextMilestoneColumnType
} from "@app/pages/displays/display-projects/ColumnTypes/TaskListNextMilestoneColumnType";
import {
    NextMilestoneRiskColumnType
} from "@app/pages/displays/display-projects/ColumnTypes/NextMilestoneRiskColumnType";
import {
    FollowingMilestoneListColumnType
} from "@app/pages/displays/display-projects/ColumnTypes/FollowingMilestoneListColumnType";
import {TaskListAllColumnType} from "@app/pages/displays/display-projects/ColumnTypes/TaskListAllColumnType";
import {
    TaskListWithoutMilestoneColumnType
} from "@app/pages/displays/display-projects/ColumnTypes/TaskListWithoutMilestoneColumnType";
import {
    ProjectResponsibleColumnType
} from "@app/pages/displays/display-projects/ColumnTypes/ProjectResponsibleColumnType";
import {ProjectUserListColumnType} from "@app/pages/displays/display-projects/ColumnTypes/ProjectUserListColumnType";
import {
    ProjectDeadlineListColumnType
} from "@app/pages/displays/display-projects/ColumnTypes/ProjectDeadlineListColumnType";
import {
    ProjectEstimateListColumnType
} from "@app/pages/displays/display-projects/ColumnTypes/ProjectEstimateListColumnType";
import {ProjectPhaseColumnType} from "@app/pages/displays/display-projects/ColumnTypes/ProjectPhaseColumnType";
import {ProjectPhaseDateColumnType} from "@app/pages/displays/display-projects/ColumnTypes/ProjectPhaseDateColumnType";
import {ProjectPhaseListColumnType} from "@app/pages/displays/display-projects/ColumnTypes/ProjectPhaseListColumnType";
import {
    ProjectCategoryListColumnType
} from "@app/pages/displays/display-projects/ColumnTypes/ProjectCategoryListColumnType";
import {ProjectNoteColumnType} from "@app/pages/displays/display-projects/ColumnTypes/ProjectNoteColumnType";
import {
    ProjectDeadlineDifferenceColumnType
} from "@app/pages/displays/display-projects/ColumnTypes/ProjectDeadlineDifferenceColumnType";
import {
    ProjectCalculatedFieldListColumnType
} from "@app/pages/displays/display-projects/ColumnTypes/ProjectCalculatedFieldListColumnType";
import {
    ProjectStatusListColumnType
} from "@app/pages/displays/display-projects/ColumnTypes/ProjectStatusListColumnType";
import {TaskListRosterColumnType} from "@app/pages/displays/display-projects/ColumnTypes/TaskListRosterColumnType";
import {TodoListColumnType} from "@app/pages/displays/display-projects/ColumnTypes/TodoListColumnType";
import {YearWheelColumnType} from "@app/pages/displays/display-projects/ColumnTypes/YearWheelColumnType";
import {AppointmentListColumnType} from "@app/pages/displays/display-projects/ColumnTypes/AppointmentListColumnType";
import {TaskListRosterTableColumn} from "@app/pages/displays/display-projects/TableColumns/TaskListRosterTableColumn";
import {ColumnTypes} from "@app/pages/displays/display-projects/ColumnTypes";
import {TaskListRosterCell} from "@app/pages/displays/display-projects/Cells/TaskListRosterCell";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {TaskListRosterColumn} from "@app/pages/displays/display-projects/Columns/TaskListRosterColumn";
import {DataFetcherCollection} from "@app/core/DataFetchers/DataFetcherCollection";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {FollowingMilestoneListCell} from "@app/pages/displays/display-projects/Cells/FollowingMilestoneListCell";
import {FollowingMilestoneListColumn} from "@app/pages/displays/display-projects/Columns/FollowingMilestoneListColumn";
import {TemplateTypes} from "@app/pages/displays/display-projects/TemplateTypes";
import {YearWheelColumn} from "@app/pages/displays/display-projects/Columns/YearWheelColumn";
import {ProjectCardColumn} from "@app/pages/displays/display-projects/Columns/ProjectCardColumn";
import {DisplayFilterEditorFormConfigInterface} from "@app/editor/display-filter-editor/DisplayFilterEditorForm";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {YearWheelDisplayOptions} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration";
import {debounce} from "lodash";
import {TodoListColumn} from "@app/pages/displays/display-projects/Columns/TodoListColumn";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";

@Component({
    selector: 'app-display-projects',
    templateUrl: './display-projects.component.html',
    styleUrls: ['./display-projects.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayProjectsComponent extends PageComponent implements OnInit, OnChanges, CreateItemInterface<Project>, CreateItemSourceInterface {
    protected filtersSettings = new Filters();
    protected columnController = new ColumnController();
    public templateTypes = new TemplateTypes();

    // UI
    @ViewChild('dataTableContainer', {static: true}) dataTableContainerElement: ElementRef;
    @ViewChild('dataTable', {static: false}) table: DatatableComponent;
    @ViewChild('dataTableProgram', {static: false}) tableProgram: DatatableComponent;

    @ViewChild('headerTemplate', {static: true}) headerTemplate: TemplateRef<any>;
    @ViewChild('headerTemplateLiveFilter', {static: true}) headerTemplateLiveFilter: TemplateRef<any>;
    @ViewChild('headerDateTemplate', {static: true}) headerDateTemplate: TemplateRef<any>;
    @ViewChild('headerTemplateYearWheel', {static: true}) headerTemplateYearWheel: TemplateRef<any>;
    @ViewChild('headerTemplateNavigation', {static: true}) headerTemplateNavigation: TemplateRef<any>;
    @ViewChild('projectNameTemplate', {static: true}) projectNameTemplate: TemplateRef<any>;
    @ViewChild('nextMilestoneTemplate', {static: true}) nextMilestoneTemplate: TemplateRef<any>;
    @ViewChild('nextMilestoneRisk', {static: true}) nextMilestoneRisk: TemplateRef<any>;
    @ViewChild('nextMilestoneTasksTemplate', {static: true}) nextMilestoneTasksTemplate: TemplateRef<any>;
    @ViewChild('followingMilestonesTemplate', {static: true}) followingMilestonesTemplate: TemplateRef<any>;
    @ViewChild('projectTasksTemplate', {static: true}) projectTasksTemplate: TemplateRef<any>;
    @ViewChild('projectTasksWithoutMilestonesTemplate', {static: true}) projectTasksWithoutMilestonesTemplate: TemplateRef<any>;
    @ViewChild('appointmentsTemplate', {static: true}) appointmentsTemplate: TemplateRef<any>;
    @ViewChild('responsibleTemplate', {static: true}) responsibleTemplate: TemplateRef<any>;
    @ViewChild('projectUserListTemplate', {static: true}) projectUserListTemplate: TemplateRef<any>;
    @ViewChild('projectDeadlineListTemplate', {static: true}) projectDeadlineList: TemplateRef<any>;
    @ViewChild('projectEstimateListTemplate', {static: true}) projectEstimateListTemplate: TemplateRef<any>;
    @ViewChild('phaseTemplate', {static: true}) phaseTemplate: TemplateRef<any>;
    @ViewChild('phaseDateTemplate', {static: true}) phaseDateTemplate: TemplateRef<any>;
    @ViewChild('phasesTemplate', {static: true}) phasesTemplate: TemplateRef<any>;
    @ViewChild('noteTemplate', {static: true}) noteTemplate: TemplateRef<any>;
    @ViewChild('dateDifferenceTemplate', {static: true}) dateDifferenceTemplate: TemplateRef<any>;
    @ViewChild('calculatedFieldsTemplate', {static: true}) calculatedFieldsTemplate: TemplateRef<any>;
    @ViewChild('statusesTemplate', {static: true}) statusesTemplate: TemplateRef<any>;
    @ViewChild('todosTemplate', {static: true}) todosTemplate: TemplateRef<any>;
    @ViewChild('yearWheelTemplate', {static: true}) yearWheelTemplate: TemplateRef<any>;
    @ViewChild('categoriesTemplate', {static: true}) categoriesTemplate: TemplateRef<any>;
    @ViewChild('rosterDayTemplate', {static: true}) rosterDayTemplate: TemplateRef<any>;

    public tableColumns: BaseTableColumn[] = [];
    public rows: ProjectsDisplayRow[];
    public projectsCount: number;
    public pageCount: number;
    public displayFilter: DisplayFilter;
    public createItemSourceConfiguration = new CreateItemSourceConfiguration();
    public createItemPreset = new CreateItemPreset();
    public listsExpanded = false;
    public hasOverlayDisplay = false;
    public showCSVExport = false;
    public dataFetcherCollection = new DataFetcherCollection();

    // Data: Program
    private programId: number; // Specific projectId, used to filter results by hierarchy and show top bar
    public program: Project;
    private programProjectType?: ProjectType;
    public programRow: ProjectsDisplayRow[];
    public programColumns: BaseTableColumn[];

    // Data: Rows
    public projectTypes: ProjectType[];
    private rowsMap: Map<number, ProjectsDisplayRow>; // ProjectID -> Row

    // Paging
    public page: Page;
    private pageInfo: any = {count: 0, pageSize: 0, limit: 0, offset: 0};
    private pagerInitialized: boolean = false;
    public limit = 10;
    public pageNo = 0;

    // Roster specific
    public pageFilterRoster = [new NonArchivedFilter(false)];
    public hasRosterColumn: boolean;

    // OnScreenFilters
    private globalTaskOnScreenFilter = new NonArchivedFilter(false);
    public showGlobalOnScreenFilter = true;
    public onScreenFilters: BaseOnScreenFilter[] = [];

    constructor(private projectsService: ProjectsService,
                private location: Location,
                private cd: ChangeDetectorRef,
                private exportService: ExportService,
                private wampService: WampService,
    ) {
        super();
        this.initialized = false;
        this.cdr = cd;

        this.createItemPreset.createProjectInterface = this;
        this.createItemSourceConfiguration.sourceInterface = this;
        this.shellService.setHeaderTitle(this.Constants.System.Loading);

        this.globalTaskOnScreenFilter.multiColumn = true;
        this.onScreenFilters.push(this.globalTaskOnScreenFilter);
    }

    ngOnInit() {
        this.buildColumns();
        super.ngOnInit();

        this.filterGlobalService.setShowSearch(true);
        this.filterGlobalService.setShowUserSearch(true);

        this.programId = this.shellService.getPageSettings().programId;

        this.subscribe(this.shellService.onReloadAllClickEvent.subscribe((e: any) => {
            this.loadDataDebounced();
        }));

        this.subscribe(this.exportService.onExportButtonClickEvent.subscribe(_ => {
            this.csvExport();
        }));

        this.subscribe(this.coreService.overlayVisible$.subscribe(value => {
            this.hasOverlayDisplay = value;
            this.detectChanges();
        }));

        this.subscribe(this.shellColumnGroup?.onColumnChangeEvent.subscribe(column => {
            this.updateShowGlobalOnScreenFilter();
        }));

        this.subscribe(this.filterGlobalService.onSettingsPeriodChangeEvent.subscribe(period => {
            // Update roster columns if visible
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListRoster)) {
                let start = period.start;
                start = moment(start).startOf('isoWeek').toDate();

                // Update roster cells
                this.rows?.forEach(row => {
                    this.columnController.getColumns<TaskListRosterColumn>(ColumnTypes.TaskListRoster)
                        .forEach(column => {
                            const rosterCell = row.getCell<TaskListRosterCell>(column)
                            rosterCell.setDate(start);
                        });
                });

                // Update roster column dates
                this.setRosterColumnDate(start);

                // Start roster fetchers
                this.columnController.getColumns<TaskListRosterColumn>(ColumnTypes.TaskListRoster)
                    .forEach(column => column.dataFetcher.execute());
            }
            this.detectChanges();
        }));

        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(_ => {
            this.loadDataDebounced();
        }));

        this.setupPush();
    }

    // <editor-fold desc="Push">

    private setupPush() {
        this.subscribe(this.eventService.subscribeToProject(0, event => {
            switch (event.action) {
                case EventService.Created:
                    this.loadProject(event.item.id);
                    break;
                case EventService.Updated:
                    // Reload only if one of these fields is updated
                    const fieldsToTriggerUpdate = [
                        'milestones',
                        'tasks',
                    ];
                    const requireUpdate = !event.isPatch || fieldsToTriggerUpdate.some(field => event.fields.includes(field));

                    if (this.rowsMap?.has(event.item.id)) {
                        if (requireUpdate) {
                            this.loadProject(event.item.id);
                        } else {
                            // Silent populate
                            this.rowsMap.get(event.item.id).project.item.populate(event.changes, event.isPatch);

                            const fieldsToTriggerSort = ['main_status'];
                            if (event.fields.some(field => fieldsToTriggerSort.includes(field))) {
                                this.sortRows();
                            }
                        }
                    }
                    break;
                case EventService.Deleted:
                    if (this.rowsMap && this.rowsMap.has(event.item.id)) {
                        this.removeRow(this.rowsMap.get(event.item.id));
                    } // Delete row
                    break;
            }
        }));

        this.subscribe(this.wampService.subscribe(Events.ProjectChangedNextMilestone(0), event => {
            const changeEvent = new ChangeEvent<Project>(null, new Project(event.next));
            if (this.rowsMap?.has(changeEvent.next.id)) {
                this.loadProject(changeEvent.next.id);
            }
        }));
    }

    // </editor-fold>

    // <editor-fold desc="View Helpers / Actions">

    public minimizeLists() {
        this.listsExpanded = false;
        this.rows.forEach(row => row.emitMinimizeEvent());
    }

    public expandLists() {
        if (this.isLoading) return;
        this.listsExpanded = true;
        this.rows.forEach(row => row.emitLoadAllEvent());
    }

    public csvExport() {
        let exporter = new CSVExporter();
        exporter.setName(this.display.name);

        const visibleColumns: GenericColumn[] = (this.columnController.getVisibleColumns() as GenericColumn[])
            .filter(column => column.implementsCSVExport);

        const countRunner = new CountRunner(visibleColumns.length);
        countRunner.setRunner(() => {
            if (this.rows) {
                exporter.addRows(this.rows);
            }
            exporter.print();
        });

        visibleColumns.forEach(column => {
            column.toCSVColumn(result => {
                exporter.addColumn(result);
                countRunner.tick();
            }, this.rows);
        });
        countRunner.start();
    }

    public columnSortChange(tableColumn: BaseTableColumn) {
        if (tableColumn.column.implementsSorting) {
            const sortableColumn = tableColumn.column as unknown as SortableColumnInterface;
            this.rows.forEach(row => {
                sortableColumn.applyRowSort(row, tableColumn);
            });
        }

        if (tableColumn.column.implementsDataFetching) {
            const column = tableColumn.column as unknown as ColumnDataFetcherInterface;
            column.getDataFetchers().forEach(fetcher => fetcher.execute());
        }
    }

    public historyBack() {
        this.location.back()
    }

    public saveNextMilestoneRisk(row: ProjectsDisplayRow, $event: string) {
        row.nextMilestoneRisk = $event;
        row.nextMilestone.item.setRisk($event);
    }

    public reloadRow(row: ProjectsDisplayRow) {
        this.loadProject(row.project.item.id)
    }

    public onFooterRefreshClicked() {
        this.loadPage(this.pageNo, true);
    }

    public onCaptureScreenshotBtnClicked() {
        if (this.isCapturingScreenshot) {
            return;
        }
        this.isCapturingScreenshot = true;
        ScreenshotHelper.Capture(this.dataTableContainerElement.nativeElement, () => {
            this.isCapturingScreenshot = false;
            this.detectChanges();
        }, {selector: document.getElementById('klartboard')});
    }

    public onYearWheelUnitChanged(unit: string) {
        this.rows?.forEach(row => {
            this.columnController.getColumns<YearWheelColumn>(ColumnTypes.YearWheel).forEach(column => {
                const yearWheelConfiguration = column.getCell(row).yearWheelConfiguration;
                if (yearWheelConfiguration.validate()) {
                    yearWheelConfiguration.setPeriodUnit(unit);
                }
                column.dataFetcher.execute();
            });
        });
    }

    public onYearWheelDateChanged(period: { start: Date; end: Date }) {
        this.rows?.forEach(row => {
            this.columnController.getColumns<YearWheelColumn>(ColumnTypes.YearWheel).forEach(column => {
                const yearWheelConfiguration = column.getCell(row).yearWheelConfiguration;
                yearWheelConfiguration
                    .setPeriodUnit(column.yearWheelDisplayOptions.unit)
                    .setStart(period.start)
                    .setEnd(period.end);
                column.dataFetcher.execute();
            });
        });
    }

    public onYearWheelOptionsChanged(value: YearWheelDisplayOptions) {
        this.rows?.forEach(row => {
            this.columnController.getColumns<YearWheelColumn>(ColumnTypes.YearWheel).forEach(column => {
                const yearWheelConfiguration = column.getCell(row).yearWheelConfiguration;
                yearWheelConfiguration.setDisplayOptions(value);
                column.dataFetcher.execute();
            });
        });
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    private loadDataDebounced = debounce(() => this.loadData(), 100);

    public loadData() {
        if (!this.displayFilter || !this.initialized || !this.projectTypes) {
            return;
        }

        this.rows = [];
        this.rowsMap = new Map();
        this.isLoading = true;

        this.loadPage(0);
        if (this.programId) {
            this.loadProgram();
        }
    }

    private loadPage(page: number, reset: boolean = false) {
        this.pageNo = page;
        this.loadProjects(page * (this.pageInfo?.pageSize ?? 0), true, true, this.limit, reset);
    }

    private loadProgram() {
        let api = Api.projects().getById(this.programId);
        api
            .include('current_phases_project.phase.color')
            .include('current_phases_project.current_phase_progress.to_phase_progress_type');

        if (this.columnController.hasVisibleTableColumn(ColumnTypes.NextMilestoneCard)) api.include('next_milestone')
            .include('next_milestone.deadline')
            .include('next_milestone.main_status')
            .include('next_milestone.responsible');
        if (this.columnController.hasVisibleTableColumn(ColumnTypes.ProjectEstimateList)) api.include('project_estimate');
        if (this.columnController.hasVisibleTableColumn(ColumnTypes.ProjectResponsible)) api.include('responsible')
            .include('responsible.user');

        api.find(program => {
            this.program = program[0];

            this.projectsService.getProjectType(this.program.project_type_id, projectType => {
                this.programProjectType = projectType;
                const programRow = this.createRow(this.program);
                const programCardConfiguration: CardProjectConfiguration = programRow.project.configuration as CardProjectConfiguration;
                programCardConfiguration.excludedProgramDisplays = [this.display];
                this.programRow = [programRow];

                this.buildProgramColumns();
                this.updateHeaderTitle();
            });
        });
    }

    private projectsApi?: Subscription;

    private loadProjects(offset: number,
                         doItems: boolean = true,
                         doCount: boolean = false,
                         limit = this.limit,
                         reset = false) {
        this.isLoading = true;

        this.hasRosterColumn = this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListRoster);

        let api = Api.projects().get();
        this.loadAppendFilters(api);

        if (doItems) {
            this.projectsApi?.unsubscribe();
            this.dataFetcherCollection.cancel();

            this.projectsApi = api
                .limit(limit)
                .offset(offset)
                .find(projects => {
                    this.isLoading = false;

                    this.columnController.getVisibleColumns()
                        .filter(column => column.implementsDataFetching)
                        .forEach(column => {
                            this.dataFetcherCollection.add((column as unknown as ColumnDataFetcherInterface).getDataFetchers());
                        });
                    this.dataFetcherCollection.clearFetchers();

                    if (reset) {
                        this.rows = [];
                        this.rowsMap = new Map();
                    }

                    const rows = projects.map(project => this.createRow(project));

                    this.dataFetcherCollection.execute(() => {
                        rows.forEach(row => this.addRow(row));

                        this.appendProgramToFollowingMilestoneRows();

                        if (this.listsExpanded) {
                            this.expandLists();
                        }

                        this.detectChanges();
                    });

                    this.dataSetChanged();
                });
        }

        if (doCount) {
            api.count(count => {
                this.projectsCount = count;
                this.pageCount = Math.ceil(this.projectsCount / this.limit);
                return this.projectsCount;
            });
        }
    }

    private loadProject(projectId: number) {
        this.isLoading = true;

        const api = Api.projects().getById(projectId);
        this.loadAppendFilters(api);

        api
            .find(project => {
                this.isLoading = false;
                if (project[0].exists()) { // Valid
                    const row = this.createRow(project[0]);
                    if (!this.rowsMap.has(projectId)) {
                        this.projectsCount++;
                        this.addRow(row);
                        this.sortRows();
                    }
                } else if (this.rowsMap.has(projectId)) { // Ikke valid
                    this.removeRow(this.rowsMap.get(projectId));
                }

                this.dataSetChanged(); // Højde på tabel skal opdateres manuelt
            });
    }

    private loadAppendFilters(api: BaseApi) {
        // Always required do to default configuration (taskType.default_task_estimate_type_id) shown in medium card
        api.include('project_estimate');

        api
            .include('current_phases_project.phase.color')
            .include('current_phases_project.current_phase_progress.to_phase_progress_type');

        if (this.projectTypes && this.projectTypes.length) {
            let projectTypeIds = this.projectTypes.map(item => item.id);

            // Check for display filter project types
            if (this.displayFilter.project_types && this.displayFilter.project_types.length) {
                const selectedProjectTypeIds = this.displayFilter.project_types.map(value => value.id);
                projectTypeIds = projectTypeIds.filter(projectTypeId => selectedProjectTypeIds.includes(projectTypeId));
            }
            api.whereIn('project_type_id', projectTypeIds);
        }

        if (this.department) {
            api.where('department.id', this.department.id);
        }

        if (this.programId) {
            api.where('project.id', this.programId);
        }

        // Global Filter
        if (this.filterGlobalService.getActiveSettings().isHandUp) {
            api.whereGreaterThan('num_hand_ups', 0);

            let smartStars = [];
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.NextMilestoneCard)) smartStars.push('next_milestone');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.FollowingMilestoneList)) smartStars.push('following_milestones');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListNextMilestone)) smartStars.push('next_milestone_tasks');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListAll)) smartStars.push('tasks');
            if (smartStars.length) {
                api.whereIn('smart_hands', smartStars);
            }
        }

        if (this.filterGlobalService.getActiveSettings().isStarred) {
            api.whereGreaterThan('num_stars', 0);

            let smartStars = [];
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.NextMilestoneCard)) smartStars.push('next_milestone');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListNextMilestone)) smartStars.push('next_milestone_tasks');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListAll)) smartStars.push('tasks');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.FollowingMilestoneList)) smartStars.push('following_milestones');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TodoList)) {
                let maxArchivedSince = 0;
                this.columnController.getVisibleColumns<TodoListColumn>(ColumnTypes.TodoList)
                    .forEach(column => {
                        maxArchivedSince = Math.max(maxArchivedSince, column.getArchivedSinceSetting());
                    });
                let archivedSince = moment(new Date())
                    .subtract(maxArchivedSince, 'days')
                    .startOf('day');
                smartStars.push('todos');
                api.where('smart_status_todo.or_archived_since', Helpers.serverDate(archivedSince.toDate()));
                api.where('smart_status_todo.display_id', this.display.id);
            }
            if (smartStars.length) {
                api.whereIn('smart_starred', smartStars);
            }
        }

        const globalFilterStatuses = this.filterGlobalService.getActiveSettings().activeStatuses;
        if (globalFilterStatuses.length < 4 && globalFilterStatuses.length > 0) {
            api.whereInArray('main_status.status_id', globalFilterStatuses);

            let smartStatuses = [];
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.NextMilestoneCard)) smartStatuses.push('next_milestone');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListNextMilestone)) smartStatuses.push('next_milestone_tasks');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListAll)) smartStatuses.push('tasks');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.FollowingMilestoneList)) smartStatuses.push('following_milestones');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TodoList)) {
                let maxArchivedSince = 0;
                this.columnController.getVisibleColumns<TodoListColumn>(ColumnTypes.TodoList)
                    .forEach(column => {
                        maxArchivedSince = Math.max(maxArchivedSince, column.getArchivedSinceSetting());
                    });
                let archivedSince = moment(new Date())
                    .subtract(maxArchivedSince, 'days')
                    .startOf('day');
                smartStatuses.push('todos');
                api.where('smart_status_todo.or_archived_since', Helpers.serverDate(archivedSince.toDate()));
                api.where('smart_status_todo.display_id', this.display.id);
            }
            if (smartStatuses.length) {
                api.whereIn('smart_statuses', smartStatuses);
            }
        }

        if (this.filterGlobalService.getActiveSettings().activeReactionFilters?.length > 0) {
            this.filterGlobalService.getActiveSettings().activeReactionFilters.forEach(reactionFilter => {
                api.whereIn('reaction_filters', [reactionFilter.reaction_type_id, reactionFilter.value]);
            });

            let smartReactionFilters = [];
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.NextMilestoneCard)) smartReactionFilters.push('next_milestone');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListNextMilestone)) smartReactionFilters.push('next_milestone_tasks');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListAll)) smartReactionFilters.push('tasks');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.FollowingMilestoneList)) smartReactionFilters.push('following_milestones');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TodoList)) {
                let maxArchivedSince = 0;
                this.columnController.getVisibleColumns<TodoListColumn>(ColumnTypes.TodoList)
                    .forEach(column => {
                        maxArchivedSince = Math.max(maxArchivedSince, column.getArchivedSinceSetting());
                    });
                let archivedSince = moment(new Date())
                    .subtract(maxArchivedSince, 'days')
                    .startOf('day');
                api.where('smart_reaction_filters_todo.or_archived_since', Helpers.serverDate(archivedSince.toDate()));
                api.where('smart_reaction_filters_todo.display_id', this.display.id);
            }
            if (smartReactionFilters.length) {
                api.whereIn('smart_reaction_filters', smartReactionFilters);
            }
        }

        if (this.filterGlobalService.getActiveSettings().activeCategories?.length > 0) {
            api.whereIn('categories', this.filterGlobalService.getActiveSettings().activeCategories.map(category => category.id));
        }

        if (this.filterGlobalService.getActiveSettings().activeUsers?.length > 0) {
            api.search('users', this.filterGlobalService.getActiveSettings().activeUsers.map(user => user.id));
            // columns
            let smartSearch = [];
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.NextMilestoneCard)) smartSearch.push('next_milestone');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListNextMilestone)) smartSearch.push('next_milestone_tasks');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListAll)) smartSearch.push('tasks');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.FollowingMilestoneList)) smartSearch.push('following_milestones');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TodoList)) {
                let maxArchivedSince = 0;
                this.columnController.getVisibleColumns<TodoListColumn>(ColumnTypes.TodoList)
                    .forEach(column => {
                        maxArchivedSince = Math.max(maxArchivedSince, column.getArchivedSinceSetting());
                    });
                let archivedSince = moment(new Date())
                    .subtract(maxArchivedSince, 'days')
                    .startOf('day');
                smartSearch.push('todos');
                api.search('smart_search_todo.or_archived_since', Helpers.serverDate(archivedSince.toDate()));
                api.search('smart_search_todo.display_id', this.display.id);
            }
            smartSearch.push('responsible'); // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1006
            smartSearch.push('phase'); // Enten skal kolonnen være synlig eller skal den vises på minikort
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.ProjectCategoryList)) smartSearch.push('category');
            if (smartSearch.length) {
                api.search('users_smart_search', smartSearch);
            }

        }

        if (this.filterGlobalService.getActiveSettings().search?.length > 0) {
            api.search('title', this.filterGlobalService.getActiveSettings().search);

            let smartSearch = [];
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.NextMilestoneCard)) smartSearch.push('next_milestone');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListNextMilestone)) smartSearch.push('next_milestone_tasks');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListAll)) smartSearch.push('tasks');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.FollowingMilestoneList)) smartSearch.push('following_milestones');
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TodoList)) {
                let maxArchivedSince = 0;
                this.columnController.getVisibleColumns<TodoListColumn>(ColumnTypes.TodoList)
                    .forEach(column => {
                        maxArchivedSince = Math.max(maxArchivedSince, column.getArchivedSinceSetting());
                    });
                let archivedSince = moment(new Date())
                    .subtract(maxArchivedSince, 'days')
                    .startOf('day');
                smartSearch.push('todos');
                api.search('smart_search_todo.or_archived_since', Helpers.serverDate(archivedSince.toDate()));
                api.search('smart_search_todo.display_id', this.display.id);
            }
            smartSearch.push('responsible'); // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1006
            smartSearch.push('phase'); // Enten skal kolonnen være synlig eller skal den vises på minikort
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.ProjectCategoryList)) smartSearch.push('category');
            if (smartSearch.length) {
                api.search('smart_search', smartSearch);
            }
        }

        switch (this.displayFilter.filter_type) {
            case Filters.FilterOpenTodo:
                api.where('todo.archived_id', 0)
                    .where('todo.deletion_id', 'null')
                    .where('todo.display_id', this.display.id);
                break;
            case Filters.FilterAppointments:
                api.whereGreaterThan('appointment.id', 0)
                    .whereNot('appointment.description', '')
                    .where('appointment.deletion_id', 'null');
                break;
            case Filters.FilterArchivedInPeriod:
                api.whereGreaterThanOrEqual('archived.created', Helpers.serverDate(this.displayFilter.getPeriodStart(this.filtersSettings)))
                    .whereLessThanOrEqual('archived.created', Helpers.serverDate(this.displayFilter.getPeriodEnd(this.filtersSettings)));
                break;
            case Filters.FilterCreatedInPeriod:
                api.whereGreaterThanOrEqual('created', Helpers.serverDate(this.displayFilter.getPeriodStart(this.filtersSettings)))
                    .whereLessThanOrEqual('created', Helpers.serverDate(this.displayFilter.getPeriodEnd(this.filtersSettings)));
                break;
            case Filters.FilterNextMilestoneInPeriod:
                api.whereGreaterThanOrEqual('next_milestone.deadline.date', Helpers.serverDate(this.displayFilter.getPeriodStart(this.filtersSettings)))
                    .whereLessThanOrEqual('next_milestone.deadline.date', Helpers.serverDate(this.displayFilter.getPeriodEnd(this.filtersSettings)));
                break;
            case Filters.FilterMilestoneInPeriod:
                api.whereGreaterThanOrEqual('milestone.deadline.date', Helpers.serverDate(this.displayFilter.getPeriodStart(this.filtersSettings)))
                    .whereLessThanOrEqual('milestone.deadline.date', Helpers.serverDate(this.displayFilter.getPeriodEnd(this.filtersSettings)));
                break;
            case Filters.FilterDeadlineInPeriod:
                const defaultProjectDeadlineTypeIds: number[] = [];
                this.projectTypes.forEach(item => {
                    if (item.default_project_deadline_type_id) {
                        defaultProjectDeadlineTypeIds.push(item.default_project_deadline_type_id);
                    }
                });
                if (defaultProjectDeadlineTypeIds.length) {
                    api.whereIn('projects_deadline.project_deadline_type_id', defaultProjectDeadlineTypeIds);
                }
                api.whereGreaterThanOrEqual('projects_deadline.deadline.date', Helpers.serverDate(this.displayFilter.getPeriodStart(this.filtersSettings)))
                    .whereLessThanOrEqual('projects_deadline.deadline.date', Helpers.serverDate(this.displayFilter.getPeriodEnd(this.filtersSettings)));
                break;
            case Filters.FilterPhase:
                if (this.displayFilter.phase_id) {
                    api.where('current_phases_project.phase_id', this.displayFilter.phase_id);
                } else if (this.displayFilter.phase) {
                    api.where('current_phases_project.phase_id', this.displayFilter.phase.id);
                }
                break;
            case Filters.FilterProjectDeadlineType:
                if (this.displayFilter.project_deadline_type_id) {
                    api.where('projects_deadline.project_deadline_type_id', this.displayFilter.project_deadline_type_id);
                }
                break;
            case Filters.FilterPhaseProgressType:
                if (this.displayFilter.phase_progress_type_id) {
                    api.where('current_phases_project.current_phase_progress.to_phase_progress_type_id', this.displayFilter.phase_progress_type_id);
                } else if (this.displayFilter.phase_progress_type) {
                    api.where('current_phases_project.current_phase_progress.to_phase_progress_type_id', this.displayFilter.phase_progress_type.id);
                }
                break;
            case Filters.FilterNonPlannedInPeriod:
                this.getRosterDays().forEach(day => {
                    api.whereIn('number_of_tasks_in_period', [
                        Helpers.serverDate(day),
                        Helpers.serverDate(moment(day).endOf('day').toDate()),
                        0,
                    ]);
                });
                break;
            default:
            // api .whereGreaterThanOrEqual('or_archived_since', Helpers.serverDate(moment().startOf('day').subtract(2, 'weeks').toDate()))
            //     .whereLessThanOrEqual('or_archived_since', Helpers.serverDate(moment().endOf('day').toDate()));
        }

        if (this.displayFilter.filter_type != Filters.FilterArchivedInPeriod) {
            // Include Archived Since
            api.where('or_archived_since', Helpers.serverDate(this.displayFilter.getIncludeArchivedSinceDate(this.filtersSettings)));
        }


        // Include data if needed
        if (this.columnController.hasVisibleTableColumn(ColumnTypes.NextMilestoneCard)) {
            api.include('next_milestone')
                .include('next_milestone.deadline')
                .include('next_milestone.main_status')
                .include('next_milestone.responsible');
        }
        if (this.columnController.hasVisibleTableColumn(ColumnTypes.ProjectEstimateList)) api.include('project_estimate');
        if (this.columnController.hasVisibleTableColumn(ColumnTypes.ProjectResponsible)) api.include('responsible.user');
        if (this.columnController.hasVisibleTableColumn(ColumnTypes.ProjectPhaseList)) {
            api.include('phases_project');
        }

        let primarySortType = this.displayFilter.sort_type;
        let primarySortDirection = this.filterGlobalService.getActiveSettings().activeSortDirection;

        switch (Filters.GetBaseSort(primarySortType)) {
            case Filters.SortTitle:
                api.orderBy('title', primarySortDirection);
                break;
            case Filters.SortResponsible:
                api.orderBy('responsible.user.first_name', 'null ' + (primarySortDirection));
                api.orderBy('responsible.user.first_name', primarySortDirection);
                break;
            case Filters.SortDeadline:
                // Filtrer på projekter med frist i minikort https://podio.com/klartboard/softwareudvikling/apps/stories/items/520 comment 18. feb 2020
                // Eller frist valgt i filtreing
                const defaultProjectDeadlineTypeIds: number[] = [];
                if (this.displayFilter.project_deadline_type_id) {
                    defaultProjectDeadlineTypeIds.push(this.displayFilter.project_deadline_type_id);
                } else {
                    this.projectTypes.forEach(item => {
                        if (item.default_project_deadline_type_id) {
                            defaultProjectDeadlineTypeIds.push(item.default_project_deadline_type_id);
                        }
                    });
                }
                if (defaultProjectDeadlineTypeIds.length) {
                    api.whereIn('projects_deadline.project_deadline_type_id', defaultProjectDeadlineTypeIds);
                }
                api.orderBy('projects_deadline.deadline.date', primarySortDirection);
                break;
            case Filters.SortStatus:
                api.orderBy('main_status.status_id', primarySortDirection == 'asc' ? 'desc' : 'asc');
                break;
            case Filters.SortNextMilestoneDeadline:
                // 2.2.5 https://podio.com/klartboard/softwareudvikling/apps/stories/items/323
                api.orderBy('next_milestone.deadline.date', 'null ' + (primarySortDirection));
                api.orderBy('next_milestone.deadline.date', primarySortDirection);
                break;
            case Filters.SortNextMilestoneStatus:
                api.orderBy('next_milestone.main_status.status_id', primarySortDirection == 'asc' ? 'desc' : 'asc');
                break;
            case Filters.SortStarred:
                api.orderBy('num_stars', primarySortDirection);
                break;
            case Filters.SortHandUp:
                api.orderBy('num_hand_ups', primarySortDirection);
                break;
            case Filters.SortPhase:
                // Virker med sortering
                api.orderBy('current_phases_project.phase.name', 'null ' + (primarySortDirection));
                api.orderBy('current_phases_project.phase.name', primarySortDirection);
                break;
            case Filters.SortNextMilestoneTitle:
                api.orderBy('next_milestone.name', 'null ' + (primarySortDirection));
                api.orderBy('next_milestone.name', primarySortDirection);
                break;
            case Filters.SortNextMilestoneHandUp:
                api.orderBy('next_milestone.num_hand_ups', primarySortDirection);
                break;
            case Filters.SortNextMilestoneResponsible:
                api.orderBy('next_milestone.responsible.first_name', 'null ' + (primarySortDirection));
                api.orderBy('next_milestone.responsible.first_name', primarySortDirection);
                api.orderBy('next_milestone.responsible.last_name', primarySortDirection);
                break;
            case Filters.SortNextMilestoneStarred:
                api.orderBy('next_milestone.num_stars', primarySortDirection);
                break;
            case Filters.SortCategoryType:
                api.where('category.category_type_id', Filters.ParseSortCategoryType(this.displayFilter.sort_type));
                api.orderBy('category.name', primarySortDirection);
                break;
        }
    }

    private createRow(project: Project): ProjectsDisplayRow {
        let row = new ProjectsDisplayRow(this.display, this.department, project);
        let update = this.rowsMap.has(project.id);
        if (update) { // Update existing row
            row = this.rowsMap.get(project.id);
        }

        project.project_type = project.id == this.programId
            ? this.programProjectType
            : this.projectTypes?.find(projectType => projectType.id == project.project_type_id);

        this.columnController.getAllColumns().forEach(column => {
            row.addCell(column.createCell(row));
        });

        const cardProjectConfiguration = row.project.configuration as CardProjectConfiguration;
        cardProjectConfiguration.showPhase = !(this.columnController.hasVisibleTableColumn(ColumnTypes.ProjectPhase)
            || this.columnController.hasVisibleTableColumn(ColumnTypes.ProjectPhase)
            || this.columnController.hasVisibleTableColumn(ColumnTypes.ProjectPhaseList));
        cardProjectConfiguration.showCategories = !this.columnController.hasVisibleTableColumn(ColumnTypes.ProjectCategoryList);
        if (this.programId) {
            cardProjectConfiguration.showProgramMiniCard = false;
            cardProjectConfiguration.showChangeType = false;
        }

        this.columnController.getColumns<FollowingMilestoneListColumn>(ColumnTypes.FollowingMilestoneList)
            .forEach(column => {
                if (this.program && project.id != this.program.id) {
                    const cell = row.getCell<FollowingMilestoneListCell>(column);
                    cell.listConfiguration
                        .setProgram(this.program)
                        .addCreatePresetGenerator(
                            new MilestoneProjectPresetGenerator(this.program.id),
                        );
                }
            });

        let start = this.filterGlobalService.getActiveSettings().period.start;
        start = moment(start).startOf('isoWeek').toDate();

        this.columnController.getColumns<TaskListRosterColumn>(ColumnTypes.TaskListRoster)
            .forEach(column => {
                const rosterCell = row.getCell<TaskListRosterCell>(column)
                rosterCell.getAllTaskListConfigurations()?.forEach(taskListConfiguration => {
                    taskListConfiguration.addOnScreenFilters(this.pageFilterRoster);
                });
                rosterCell.setDate(start);
            });

        if (update) {
            row.reload();
        }

        return row;
    }

    private addRow(row: ProjectsDisplayRow) {
        if (!this.rowsMap.has(row.project.item.id)) {
            this.rows.push(row);
            this.rowsMap.set(row.project.item.id, row);
        }
    }

    private removeRow(row: ProjectsDisplayRow) {
        if (this.rowsMap.has(row.project.item.id)) {
            let rowTemp = [...this.rows];
            rowTemp.splice(this.rows.indexOf(row), 1);
            this.rows = rowTemp;
            this.rowsMap.delete(row.project.item.id);
            this.dataSetChanged();
            this.projectsCount--;
        }
    }

    // https://podio.com/klartboard/softwareudvikling/apps/supports/items/1121
    private appendProgramToFollowingMilestoneRows() {
        if (this.program) {
            this.rows?.forEach(row => {
                this.columnController.getColumns<FollowingMilestoneListColumn>(ColumnTypes.FollowingMilestoneList)
                    .forEach(column => {
                        const followingMilestoneListCell = row.getCell<FollowingMilestoneListCell>(column);
                        const project = followingMilestoneListCell.listConfiguration?.getProject();
                        if (project?.id != this.program.id) {
                            followingMilestoneListCell.listConfiguration?.setProgram(this.program);
                        }
                    });
            });
        }
    }

    private sortRows() {
        const primarySortDirection = this.filterGlobalService.getActiveSettings().activeSortDirection;

        switch (this.displayFilter.sort_type) {
            case Filters.SortStatus:
                const sortItems = [
                    new SortItem(new StatusComparison(), primarySortDirection),
                ];
                this.rows.sort((a, b) => {
                    let value = 0;
                    sortItems?.find(sortItem => {
                        value = sortItem.comparison.compare(
                            sortItem.direction == 'asc' ? a.project.item : b.project.item,
                            sortItem.direction == 'asc' ? b.project.item : a.project.item
                        );
                        return value !== 0;
                    });
                    return value;
                });
                break;
            default:
            // Sort type not yet supported
        }
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    private buildColumns() {
        this.columnController.addColumnTypes([
            new ProjectCardColumnType(this.projectNameTemplate, this.headerTemplateNavigation),
            new NextMilestoneCardColumnType(this.nextMilestoneTemplate, this.headerTemplate),
            new NextMilestoneRiskColumnType(this.nextMilestoneRisk, this.headerTemplate),
            new TaskListNextMilestoneColumnType(this.nextMilestoneTasksTemplate, this.headerTemplateLiveFilter, [this.globalTaskOnScreenFilter]),
            new FollowingMilestoneListColumnType(this.followingMilestonesTemplate, this.headerTemplate),
            new TaskListAllColumnType(this.projectTasksTemplate, this.headerTemplateLiveFilter, [this.globalTaskOnScreenFilter]),
            new TaskListWithoutMilestoneColumnType(this.projectTasksWithoutMilestonesTemplate, this.headerTemplateLiveFilter, [this.globalTaskOnScreenFilter]),
            new ProjectResponsibleColumnType(this.responsibleTemplate, this.headerTemplate),
            new ProjectUserListColumnType(this.projectUserListTemplate, this.headerTemplate),
            new ProjectDeadlineListColumnType(this.projectDeadlineList, this.headerTemplate),
            new ProjectEstimateListColumnType(this.projectEstimateListTemplate, this.headerTemplate),
            new ProjectPhaseColumnType(this.phaseTemplate, this.headerTemplate),
            new ProjectPhaseDateColumnType(this.phaseDateTemplate, this.headerTemplate),
            new ProjectPhaseListColumnType(this.phasesTemplate, this.headerTemplate),
            new ProjectCategoryListColumnType(this.categoriesTemplate, this.headerTemplate),
            new ProjectNoteColumnType(this.noteTemplate, this.headerTemplate),
            new ProjectDeadlineDifferenceColumnType(this.dateDifferenceTemplate, this.headerTemplate),
            new ProjectCalculatedFieldListColumnType(this.calculatedFieldsTemplate, this.headerTemplate),
            new ProjectStatusListColumnType(this.statusesTemplate, this.headerTemplate),
            new TaskListRosterColumnType(this.rosterDayTemplate, this.headerDateTemplate),
            new TodoListColumnType(this.todosTemplate, this.headerTemplate),
            new YearWheelColumnType(this.yearWheelTemplate, this.headerTemplateYearWheel),
            new AppointmentListColumnType(this.appointmentsTemplate, this.headerTemplate),
        ]);
    }

    private buildProgramColumns() {
        const programTableColumns: BaseTableColumn[] = [];
        this.columnController.getVisibleColumns()
            .forEach(column => {
                programTableColumns.push(...column.createTableColumns());
            });

        const nonNameColumns = programTableColumns
            .filter(tableColumn => tableColumn.column.columnType.identifier != ColumnTypes.ProjectCard);
        const nameColumns = programTableColumns
            .filter(tableColumn => tableColumn.column.columnType.identifier == ColumnTypes.ProjectCard);

        nameColumns.forEach(nameColumn => {
            if (this.program) {
                this.projectsService.getProjectType(this.program.project_type_id, projectType => {
                    nameColumn.name = this.translateService.instant(projectType.name);
                    (nameColumn as GenericTableColumn).disableCreate = true;
                });
            } else {
                nameColumn.name = this.translateService.instant('_project_program');
            }
        });

        this.programColumns = [
            ...nameColumns,
            ...nonNameColumns
        ];
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();

        this.columnController.loadColumns(this.displayId, () => {
            this.initialize();

            if (this.programId) {
                this.buildProgramColumns();
            }
        });
    }

    private initialize() {
        this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
            this.tableColumns = this.columnController.getVisibleTableColumns();

            this.rows?.forEach(row => row.rerenderYearWheelEvent.emit());

            this.markChangeDetectionDirty();

            const anyAddedColumnTriggerReload = event.hasAddedColumn([
                ColumnTypes.ProjectCard,
                ColumnTypes.ProjectEstimateList,
                ColumnTypes.ProjectPhase,
                ColumnTypes.ProjectPhaseDate,
                ColumnTypes.ProjectPhaseList,
                ColumnTypes.NextMilestoneCard,
                ColumnTypes.FollowingMilestoneList,
                ColumnTypes.TaskListNextMilestone,
                ColumnTypes.TaskListAll,
                ColumnTypes.TaskListWithoutMilestone,
                ColumnTypes.TodoList,
                ColumnTypes.AppointmentList,
                ColumnTypes.YearWheel,
                ColumnTypes.ProjectCategoryList,
            ]);

            const anyRemovedColumnTriggerReload = event.hasRemovedColumn([
                ColumnTypes.ProjectPhase,
                ColumnTypes.ProjectPhaseDate,
                ColumnTypes.ProjectPhaseList,
                ColumnTypes.ProjectCategoryList,
            ]);

            if (anyAddedColumnTriggerReload || anyRemovedColumnTriggerReload) {
                this.loadDataDebounced();
            }

            // Check for header calendar
            this.filterGlobalService.setShowWeekPicker(this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListRoster));
            this.setRosterColumnDate(this.filterGlobalService.getActiveSettings().period.start);
        }));

        this.subscribe(this.shellFilterGroup.onActiveFilterChangeEventSubscribe(filter => {
            this.displayFilter = filter;
            this.columnController.getColumns<YearWheelColumn>(ColumnTypes.YearWheel)
                .forEach(column => column.setPeriod(
                    this.displayFilter.getYearWheelPeriodStart(this.filtersSettings),
                    this.displayFilter.getYearWheelPeriodEnd(this.filtersSettings)
                ));
            this.loadDataDebounced();
        }));

        const countRunner = new CountRunner();

        const projectTypes: ProjectType[] = [];
        Settings.GetProjectTypeIds(this.settingsMap).forEach(projectTypeId => {
            countRunner.incrementCount();
            this.projectsService.getProjectType(projectTypeId, projectType => {
                projectTypes.push(projectType);
                countRunner.tick();
            });
        });

        countRunner.incrementCount();
        let editorConfig: DisplayFilterEditorFormConfigInterface;
        this.filtersSettings.getEditorConfig(this.display, config => {
            editorConfig = config;
            countRunner.tick();
        });

        countRunner.setRunner(() => {
            this.initialized = true;
            this.projectTypes = projectTypes.sort((a, b) => a.index_ - b.index_);
            this.loadDataDebounced();
            this.shellService.setPageSettingsProjectTypes(this.projectTypes);

            // Add extra dynamic sorts to columns
            const categoryColumnSorts = editorConfig.categoryTypes
                ?.map(categoryType => {
                    return PageColumnSort.CreateWithSortId(Filters.SortCategoryTypeGenerator(categoryType));
                });

            this.columnController.getColumns<ProjectCardColumn>(ColumnTypes.ProjectCard)
                .forEach(column => column
                    .getTableColumns()
                    .forEach(tableColumn => {
                        tableColumn.sortItems.push(...categoryColumnSorts);
                    }));
        });
        countRunner.start();

        this.exportService.setShowExportButton(Settings.GetHasCSVExport(this.settingsMap));
        this.showCSVExport = Settings.GetHasCSVExport(this.settingsMap);
    }

    private setRosterColumnDate(start: Date) {
        this.columnController
            .getColumns<TaskListRosterColumn>(ColumnTypes.TaskListRoster)
            .forEach(column => column.setRosterDate(start));
    }

    private dataSetChanged() {
        if (this.table) { // Højde på tabel skal opdateres manuelt
            this.table.recalculateColumns(this.tableColumns);
            this.rows = [...this.rows]; // This is input into <ngx-datatable>
            this.tableColumns = [...this.tableColumns]; // This is input into <ngx-datatable>
            this.table.recalculate(); //ngx-datatable reference
        }
        if (this.tableProgram) { // Højde på tabel skal opdateres manuelt
            this.tableProgram.recalculateColumns(this.programColumns);
            if (this.programRow) {
                this.programRow = [...this.programRow]; // This is input into <ngx-datatable>
            }
            if (this.programColumns) {
                this.programColumns = [...this.programColumns]; // This is input into <ngx-datatable>
            }
            this.tableProgram.recalculate(); //ngx-datatable reference
        }

        this.updateTableWidth();
        this.updateShowGlobalOnScreenFilter();
        this.markChangeDetectionDirty();
    }

    public onFilterChange() {
        super.onFilterChange();
        this.updateHeaderTitle();
    }

    private updateHeaderTitle() {
        // Update header
        if (this.program) {
            let programTitle = this.program.title;
            if (programTitle.length > 15) {
                programTitle = programTitle.substr(0, 13) + '..';
            }
            const title = `${programTitle}: ${this.shellPageData.shellFilterGroup.activeFilter.name}`;
            const toolTip = `${this.program.title}: ${this.shellPageData.shellFilterGroup.activeFilter.name}`;
            this.shellService.setHeaderTitle(title, toolTip);
            this.markChangeDetectionDirty();
        }
    }

    private getRosterDays(): Date[] {
        const days: Date[] = [];
        this.columnController
            .getColumns<TaskListRosterColumn>(ColumnTypes.TaskListRoster)
            .forEach(column => {
                column.getVisibleTableColumns<TaskListRosterTableColumn>().forEach(tableColumn => {
                    days.push(tableColumn.rosterDate);
                });
            });
        return days;
    }

    private updateShowGlobalOnScreenFilter() {
        this.showGlobalOnScreenFilter = this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListWithoutMilestone)
            || this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListAll)
            || this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListNextMilestone);
    }

    // </editor-fold>

    // <editor-fold desc="Paging">

    setLimit(number: number) {
        this.pageNo = 0;
        this.limit = number;
        this.pageCount = Math.ceil(this.projectsCount / this.limit);
        this.loadPage(this.pageNo, true);
    }

    onPageChange($event: any) {
        if (this.table) {
            this.table.onFooterPage($event);
        }
    }

    /**
     * Populate the table with new data based on the page number
     * @param pageInfo
     */
    setPage(pageInfo: any) {
        if (this.pagerInitialized) {
            this.pageInfo = pageInfo;
            this.pageNo = pageInfo.offset;
            this.loadPage(this.pageNo, true);
        } else {
            this.pagerInitialized = true;
        }
    }

    // </editor-fold>

    // <editor-fold desc="Create Item Component"

    public createPresets(options?: any): CreatePreset[] {
        const generators = [
            new ProjectUserPresetGenerator(ProjectUserTypes.Responsible, this.user.id),
            new ProjectDepartmentPresetGenerator(this.department.id),
            new ProjectUseStatusRulesPresetGenerator(true),
            new ProjectStatusPresetGenerator(ProjectStatusTypes.Normal, StatusTypes.GREEN),
        ];
        if (this.program) {
            generators.push(new ProjectRelatedProjectPresetGenerator(this.program.id));
        }
        return generators.map(generator => generator.generate());
    }

    public prepareSource(): void {
        this.createItemSourceConfiguration.showTasks = false;
        this.createItemSourceConfiguration.showMilestone = false;
        this.createItemSourceConfiguration.showTodo = false;
        this.createItemSourceConfiguration.showAppointments = false;
        this.createItemSourceConfiguration.filterProjectsTypesById = this.projectTypes?.map(projectType => projectType.id);
    }

    // </editor-fold>

}
