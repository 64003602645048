/**
 * Created by ModelParser
 */
import {Category} from '../Category';
import {ProjectType} from '../ProjectType';
import {CategoryTypesProjectType} from '../CategoryTypesProjectType';
import {TaskType} from '../TaskType';
import {CategoryTypesTaskType} from '../CategoryTypesTaskType';
import {CategoryTypesMilestone} from '../CategoryTypesMilestone';
import {CategoryTypesRole} from '../CategoryTypesRole';
import {Role} from '../Role';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class CategoryTypeDefinition extends BaseModel {
    name?: string;
    index_?: number;
    show_name?: boolean;
    show_color?: boolean;
    categories?: Category[];
    project_types?: ProjectType[];
    category_types_project_types?: CategoryTypesProjectType[];
    task_types?: TaskType[];
    category_types_task_types?: CategoryTypesTaskType[];
    category_types_milestones?: CategoryTypesMilestone[];
    category_types_roles?: CategoryTypesRole[];
    roles?: Role[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.index_;
            delete this.show_name;
            delete this.show_color;
            delete this.categories;
            delete this.project_types;
            delete this.category_types_project_types;
            delete this.task_types;
            delete this.category_types_task_types;
            delete this.category_types_milestones;
            delete this.category_types_roles;
            delete this.roles;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.index_ != null) {
            this.index_ = data.index_;
        }
        if (data.show_name != null) {
            this.show_name = data.show_name;
        }
        if (data.show_color != null) {
            this.show_color = data.show_color;
        }
        if (data.categories != null) {
            this.categories = data.categories.map((i: any) => new Category(i));
        }
        if (data.project_types != null) {
            this.project_types = data.project_types.map((i: any) => new ProjectType(i));
        }
        if (data.category_types_project_types != null) {
            this.category_types_project_types = data.category_types_project_types.map((i: any) => new CategoryTypesProjectType(i));
        }
        if (data.task_types != null) {
            this.task_types = data.task_types.map((i: any) => new TaskType(i));
        }
        if (data.category_types_task_types != null) {
            this.category_types_task_types = data.category_types_task_types.map((i: any) => new CategoryTypesTaskType(i));
        }
        if (data.category_types_milestones != null) {
            this.category_types_milestones = data.category_types_milestones.map((i: any) => new CategoryTypesMilestone(i));
        }
        if (data.category_types_roles != null) {
            this.category_types_roles = data.category_types_roles.map((i: any) => new CategoryTypesRole(i));
        }
        if (data.roles != null) {
            this.roles = data.roles.map((i: any) => new Role(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
