import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";

@Component({
    selector: 'app-filter-search',
    templateUrl: './filter-search.component.html',
    styleUrls: ['./filter-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSearchComponent extends BaseComponent implements OnInit {

    @ViewChild(NgbPopover, {static: true}) popover: NgbPopover;

    public searchText: string;
    public txtQueryChanged: Subject<string> = new Subject<string>();

    constructor(private cd: ChangeDetectorRef,
                private filterGlobalService: FilterGlobalService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.txtQueryChanged
            .pipe(debounceTime(1000), distinctUntilChanged()) // wait 1 sec after the last event before emitting last event &&  only emit if value is different from previous value
            .subscribe((value: string) => {
                if (value == '') {
                    value = null;
                }
                this.triggerSearch(value);
            });

        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(settings => {
            if (settings.search != this.searchText) {
                this.searchText = settings.search;
                this.detectChanges();
            }
        }));
    }

    public modelChange(value: string) {
        this.txtQueryChanged.next(value);
    }

    public triggerSearch(value: string) {
        if (this.filterGlobalService.getActiveSettings().search != value) {
            this.txtQueryChanged.next(value);
            this.filterGlobalService.setSearch(value);
        }
    }

    public reset() {
        this.searchText = null;
        this.triggerSearch(null);
    }

    @HostListener('document:keydown', ['$event'])
    public onKeyDown($event: KeyboardEvent) {
        const activeElement = document.activeElement;
        const inputs = ['input', 'select', 'button', 'textarea'];
        if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1 || activeElement && activeElement.classList.contains('ql-editor')) {
            if ($event.key == 'Escape' && this.popover.isOpen()) {
                this.popover.close();
            }
        } else {
            switch ($event.key) {
                case 'f':
                    if (this.popover.isOpen()) {
                        this.popover.close();
                    } else {
                        this.popover.open();
                    }
                    break;
                case 'Escape':
                    if (this.popover.isOpen()) {
                        this.popover.close();
                    }
                    break;
                default:
                    break;

            }
        }
    }

}
