<div #cardContainer
    class="card p-1 w-fill" *ngIf="fields">
    <div class="d-flex flex-column">
        <div *ngIf="true"
             class="d-flex flex-row position-relative w-100 align-items-start justify-content-between">
            <app-color-label class="card-type me-1 flex-shrink-0"
                             *ngIf="configuration?.showPeriodRating && isFieldVisible(Fields.PeriodRating)"
                             [ngbTooltip]="('_user_period_rating' | translate)"
                             [emoji]="true"
                             placement="right"
                             container="body"
                             [items]="[{id: Globals.StatusTypes.RED},{id: Globals.StatusTypes.YELLOW}, {id: Globals.StatusTypes.GREEN}]"
                             [status]="periodRating?.status_id"
                             (onStatusChange)="onStatusChange($event)"></app-color-label>
            <div class="d-flex justify-content-between w-100 -text-small title ms-2">
                <app-user-with-icon
                    [model]="model"
                    [label]="('_user_goto_user_overview' | translate)"
                    [showIcon]="false"
                    [navigateToDashboard]="true"
                    [fullName]="true"
                    class=" font-weight-bold"></app-user-with-icon>
            </div>
        </div>

        <div class="wrap flex-fill w-100" *ngIf="true">
            <div class="d-flex justify-content-between flex-row align-items-start mt-1">
                <app-time-calculator
                    *ngIf="
                        configuration?.showLoads && loadCalculateResponse
                        && (isFieldVisible(Fields.Load) || isFieldVisible(Fields.LoadWorkHours) || isFieldVisible(Fields.LoadFreeHours))"
                    [showLoad]="isFieldVisible(Fields.Load)"
                    [displayColumn]="false"

                    [showLoadWorkHours]="isFieldVisible(Fields.LoadWorkHours)"
                    [showLoadFreeHours]="isFieldVisible(Fields.LoadFreeHours)"
                    [loadCalculateResponse]="loadCalculateResponse"
                    [periodRating]="periodRating"></app-time-calculator>

                <ul
                    *ngIf="configuration?.showProjectCount && isFieldVisible(Fields.ProjectTypes)"
                    class="list-unstyled d-flex -justify-content-between flex-row m-0 align-items-center"
                    [ngbTooltip]="selectedProjectType?.getPlural()"
                    container="body"
                >
                    <ng-container *ngFor="let projectBadgeCount of projectCounter; trackBy: trackByProjectCoundIndex;">
                        <li>
                            <div class="me-1 badge {{projectBadgeCount.colorClass}}"
                                 *ngIf="projectBadgeCount.count != null">{{projectBadgeCount.count}}</div>
                            <div class="me-1 badge badge-light" *ngIf="projectBadgeCount.count == null">0</div>
                        </li>
                    </ng-container>
                </ul>

                <!-- Vis ikke dropdown tekst -->
                <div class="d-flex justify-content-between align-items-center"
                     *ngIf="configuration?.showProjectCount && selectedProjectType && projectTypes && isFieldVisible(Fields.ProjectTypes)">
                    <div ngbDropdown
                         class="d-inline-block me-2"
                         [placement]="['bottom-left', 'top-left']"
                         container="body">
                        <a class="" id="dropdownBasic1" ngbDropdownToggle role="button"></a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <a *ngFor="let pt of projectTypes; trackBy: trackByProjectTypeId" class="dropdown-item"
                               (click)="selectProjectType($event, pt)"
                               role="button"><span >{{pt.getPlural() | translate}}</span></a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
