/**
 * Created by ModelParser
 * Date: 22-06-2021.
 * Time: 12:59.
 */
import {PhaseProgressDefinition} from './definitions/PhaseProgressDefinition';

export class PhaseProgress extends PhaseProgressDefinition {

    constructor(json?: any) {
        super(json);
    }

}
