/**
 * Created by ModelParser
 * Date: 09-12-2020.
 * Time: 14:53.
 */
import {CategoryTypeDefinition} from './definitions/CategoryTypeDefinition';
import {Api} from "@app/core/http/Api/Api";
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";
import {System} from "@app/constants";

export class CategoryType extends CategoryTypeDefinition {

    required?: boolean;

    constructor(json?: any) {
        super(json);
    }

    private static DataCache?: ApiDataCache<CategoryType>;

    private static GetDataCache(): ApiDataCache<CategoryType> {
        if (!this.DataCache) {
            this.DataCache = new ApiDataCache<CategoryType>(
                System.CategoryTypes,
                2 * 60 * 60, // 2 hours
                Api.categoryTypes()
                    .get()
                    .orderBy('index_', 'asc')
            );
        }
        return this.DataCache;
    }

    public static GetAll(callback: ((items: CategoryType[]) => void)) {
        this.GetDataCache().get(callback);
    }
    public static GetById(id: number, callback: (item: CategoryType) => void) {
        CategoryType.GetAll(items => {
            callback(items.find(item => item.id == id));
        });
    }

}
