import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {
    NgbNav, NgbNavContent, NgbNavItem, NgbNavLink, NgbNavOutlet, NgbPopover,
    NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";
import {ResizableModule} from "angular-resizable-element";
import {BaseEditorV3Module} from "@app/editor/base-editor-v3/base-editor-v3.module";
import {TaskEditorV3Component} from "@app/editor/task-editor-loader/task-editor-v3/task-editor-v3.component";
import {
    TaskEditorTitleComponent
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-title/task-editor-title-component";
import {
    TaskEditorHeaderComponent
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-header/task-editor-header-component";
import {
    GenericVerticalEditCollectionModule
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/generic-vertical-edit-collection/GenericVerticalEditCollection.module";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {
    ValidationWarningListModule
} from "@app/editor/base-editor-v3/components/validation-warning-list/validation-warning-list.module";
import {ArchivedEditorModule} from "@app/editor/quick-editor/editors/generic/archived-editor/ArchivedEditor.module";
import {StatusEditorModule} from "@app/editor/quick-editor/editors/generic/status-editor/StatusEditor.module";
import {
    ReactionListEditorModule
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/ReactionListEditor.module";
import {
    TaskEditorFooterComponent
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-footer/task-editor-footer-component";
import {
    TaskEditorTabsUsersComponent
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-tabs/task-editor-tab-users/task-editor-tabs-users-component";
import {UserListEditorModule} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserListEditor.module";
import {
    TaskEditorTabsLogComponent
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-tabs/task-editor-tab-log/task-editor-tabs-log-component";
import {LogsModule} from "@app/shared/_ui/logs/Logs.module";
import {
    TaskEditorTabsCategoriesComponent
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-tabs/task-editor-tab-categories/task-editor-tabs-categories-component";
import {
    CategoryPickerListEditorModule
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/CategoryPickerListEditor.module";
import {
    TaskEditorTabsBasicComponent
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-tabs/task-editor-tab-basic/task-editor-tabs-basic-component";
import {
    DeadlineListEditorModule
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/DeadlineListEditor.module";
import {
    UseStatusRulesEditorModule
} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/UseStatusRulesEditor.module";
import {StatusRuleAppenderModule} from "@app/shared/_forms/status-rule-appender/StatusRuleAppender.module";
import {
    EstimateListEditorModule
} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EstimateListEditor.module";
import {TaskCalculatedFieldModule} from "@app/shared/_elements/task-calculated-field/TaskCalculatedField.module";
import {ListEditorModule} from "@app/editor/quick-editor/editors/generic/link-editor/LinkEditor.module";
import {
    StatusListEditorModule
} from "@app/editor/quick-editor/editors/generic/status-list-editor/StatusListEditor.module";
import {TextEditorModule} from "@app/editor/quick-editor/editors/generic/text-editor/TextEditor.module";
import {
    TaskEditorTabsAttachToComponent
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-tabs/task-editor-tab-attach-to/task-editor-tabs-attach-to-component";
import {
    AttachedProjectTypeModule
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-tabs/task-editor-tab-attach-to/attached-project-type/AttachedProjectType.module";
import {
    DepartmentPickerEditorModule
} from "@app/editor/quick-editor/editors/generic/department-picker-editor/DepartmentPickerEditor.module";
import {TagListEditorModule} from "@app/editor/quick-editor/editors/generic/tag-list-editor/TagListEditor.module";
import {TitleEditorModule} from "@app/editor/quick-editor/editors/generic/title-editor/TitleEditor.module";
import {StatusSelectorModule} from "@app/shared/_forms/status-selector/StatusSelector.module";
import {CanCopyEditorModule} from "@app/editor/quick-editor/editors/generic/can-copy-editor/CanCopyEditor.module";
import {IsPrivateEditorModule} from "@app/editor/quick-editor/editors/generic/is-private-editor/IsPrivateEditor.module";
import {NumberEditorModule} from "@app/editor/quick-editor/editors/generic/number-editor/NumberEditor.module";
import {
    FindAndFocusBtnModule
} from "@app/editor/base-editor-v3/components/find-and-focus-btn/find-and-focus-btn.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbTooltipModule,
        CdkDrag,
        ResizableModule,
        CdkDragHandle,
        BaseEditorV3Module,
        ToggleItemModule,
        ValidationWarningListModule,
        ArchivedEditorModule,
        StatusEditorModule,
        ReactionListEditorModule,
        NgbNav,
        NgbNavItem,
        NgbNavLink,
        NgbNavOutlet,
        NgbNavContent,
        UserListEditorModule,
        LogsModule,
        CategoryPickerListEditorModule,
        DeadlineListEditorModule,
        NgbPopover,
        UseStatusRulesEditorModule,
        StatusRuleAppenderModule,
        EstimateListEditorModule,
        TaskCalculatedFieldModule,
        ListEditorModule,
        StatusListEditorModule,
        TextEditorModule,
        AttachedProjectTypeModule,
        DepartmentPickerEditorModule,
        TagListEditorModule,
        TitleEditorModule,
        StatusSelectorModule,
        CanCopyEditorModule,
        IsPrivateEditorModule,
        NumberEditorModule,
        FindAndFocusBtnModule,
    ],
    declarations: [
        TaskEditorV3Component,
        TaskEditorTitleComponent,
        TaskEditorHeaderComponent,
        TaskEditorFooterComponent,
        TaskEditorTabsUsersComponent,
        TaskEditorTabsLogComponent,
        TaskEditorTabsCategoriesComponent,
        TaskEditorTabsBasicComponent,
        TaskEditorTabsAttachToComponent,
    ],
    exports: [
        TaskEditorV3Component,  // export hvis det skal være tilgængligt i import af modul
    ]
})
export class TaskEditorV3Module {
}
