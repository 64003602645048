import {NgModule} from "@angular/core";
import {SmallCardProjectComponent} from "@app/shared/_ui/cards/small/small-card-project/small-card-project.component";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {FormsModule} from "@angular/forms";
import {PlaceholderPipeModule} from "@app/pipes/PlaceholderPipe.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbDropdownModule, NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {UrlLinkModule} from "@app/shared/_ui/url-link/UrlLink.module";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {ProjectPhaseSearchModule} from "@app/shared/_elements/project-phase-search/ProjectPhaseSearch.module";
import {ProcessStepsModule} from "@app/shared/_elements/process-steps/ProcessSteps.module";
import {DropdownListItemModule} from "@app/shared/_ui/displays/dropdown-list-item/DropdownListItem.module";
import {CardPreviewModule} from "@app/shared/_ui/cards/card-preview/CardPreview.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ColorLabelModule,
        FormsModule,
        PlaceholderPipeModule,
        TranslateModule,
        NgbTooltipModule,
        UrlLinkModule,
        NgbPopoverModule,
        ToggleItemModule,
        CardPreviewModule,
        DropdownListItemModule,
        ProjectPhaseSearchModule,
        NgbDropdownModule,
        ProcessStepsModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        SmallCardProjectComponent,
    ],
    exports: [
        SmallCardProjectComponent,
    ],
    providers: [

    ],
})
export class SmallCardProjectModule {}
