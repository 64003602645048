import {NgModule} from "@angular/core";
import {
    MilestonePlanPeriodSelectionComponent
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-editor/milestone-plan-period-selection/milestone-plan-period-selection.component";
import {TranslateModule} from "@ngx-translate/core";
import {
    TemplateHolidayPickerModule
} from "@app/shared/_ui/displays/milestone-plan/template-holiday-picker/TemplateHolidayPicker.module";
import {
    TemplateBlockedPeriodPickerModule
} from "@app/shared/_ui/displays/milestone-plan/template-blocked-period-picker/TemplateBlockedPeriodPicker.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        TemplateHolidayPickerModule,
        TemplateBlockedPeriodPickerModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MilestonePlanPeriodSelectionComponent,
    ],
    exports: [
        MilestonePlanPeriodSelectionComponent

    ],
    providers: [

    ],
})
export class MilestonePlanPeriodSelectionModule {}
