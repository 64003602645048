import {GenericTableColumn} from "@app/pages/displays/dashboard/dashboard-projects/TableColumns/GenericTableColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {
    TaskListNextMilestoneCell
} from "@app/pages/displays/dashboard/dashboard-projects/Cells/TaskListNextMilestoneCell";
import {TableColumns} from "@app/pages/displays/dashboard/dashboard-projects/TableColumns";

export class TaskListNextMilestoneTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TaskListNextMilestone;

    public getListConfiguration(row: BaseRow): TaskListConfiguration {
        return row.getCell<TaskListNextMilestoneCell>(this.column).listConfiguration;
    }

}
