import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    MilestoneListUserTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns/MilestoneListUserTableColumn";
import {
    MilestoneListUserCell
} from "@app/pages/displays/display-project-details/subdisplay-users/Cells/MilestoneListUserCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-users/Row";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {MilestoneFetcher, MilestoneFetchRequest} from "@app/shared/_ui/lists/milestone-list/MilestoneFetcher";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-users/Filters";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";

export class MilestoneListUserColumn extends BaseColumn<MilestoneListUserCell> implements ColumnDataFetcherInterface, SortableColumnInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: MilestoneFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    // <editor-fold desc="Sorting">

    public implementsSorting: true = true;

    public applyRowSort(row: Row, tableColumn: MilestoneListUserTableColumn): void {
        const listConfiguration = tableColumn.column.getCell(row).listConfiguration;
        listConfiguration.setOrderBy(
            this.getOrderBy(Filters.GetBaseSort(tableColumn.activeSortTypeId), tableColumn.activeSortDirection)
        );
    }

    private getOrderBy(type: string, direction: string): string[][] {
        const orderBy: string[][] = [];
        switch (Filters.GetBaseSort(type)) {
            default:
            case Filters.SortMilestoneUserDefined:
                orderBy.push(
                    ['deadline.id', 'null'],
                    ['deadline.date', 'asc'],
                    ['milestones_project.index_', 'asc'],
                );
                break;
            case Filters.SortMilestoneTitle:
                orderBy.push(['name', direction]);
                break;
            case Filters.SortMilestoneDeadline:
                orderBy.push(
                    ['deadline.id', 'null'],
                    ['deadline.date', 'asc'],
                );
                break;
            case Filters.SortMilestoneStatus:
                orderBy.push(['main_status.status_id', direction]);
                break;
            case Filters.SortMilestoneResponsible:
                orderBy.push(['responsible.first_name', direction]);
                break;
            case Filters.SortMilestoneStars:
                orderBy.push(['num_stars', direction]);
                break;
            case Filters.SortMilestoneHands:
                orderBy.push(['num_hand_ups', direction]);
                break;
        }
        return orderBy;
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new MilestoneFetcher(column.name);
    }

    createTableColumns(): BaseTableColumn[] {
        const tableColumn = new MilestoneListUserTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    createCell(row: Row): BaseCell {
        const cell = new MilestoneListUserCell(row, this);
        this.dataFetcher.addRequest(new MilestoneFetchRequest(cell.listConfiguration));
        return cell;
    }

}
