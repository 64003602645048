/**
 * Created by ModelParser
 * Date: 12-05-2021.
 * Time: 14:46.
 */
import {ReactionTypesTaskTypeDefinition} from './definitions/ReactionTypesTaskTypeDefinition';
import {ReactionsTypeInterface} from "@app/shared/_ui/reactions/ReactionsTypeInterface";

export class ReactionTypesTaskType extends ReactionTypesTaskTypeDefinition implements ReactionsTypeInterface {

    constructor(json?: any) {
        super(json);
    }

}
