import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {TaskListRosterTableColumn} from "@app/pages/displays/display-projects/TableColumns/TaskListRosterTableColumn";
import {
    FollowingMilestoneTableColumn
} from "@app/pages/displays/display-projects/TableColumns/FollowingMilestoneTableColumn";
import {
    TaskListNextMilestoneTableColumn
} from "@app/pages/displays/display-projects/TableColumns/TaskListNextMilestoneTableColumn";
import {TaskListAllTableColumn} from "@app/pages/displays/display-projects/TableColumns/TaskListAllTableColumn";
import {
    TaskListWithoutMilestoneTableColumn
} from "@app/pages/displays/display-projects/TableColumns/TaskListWithoutMilestoneTableColumn";
import {AppointmentListTableColumn} from "@app/pages/displays/display-projects/TableColumns/AppointmentListTableColumn";
import {TodoListTableColumn} from "@app/pages/displays/display-projects/TableColumns/TodoListTableColumn";
import {YearWheelTableColumn} from "@app/pages/displays/display-projects/TableColumns/YearWheelTableColumn";
import {
    ProjectCategoryListTableColumn
} from "@app/pages/displays/display-projects/TableColumns/ProjectCategoryListTableColumn";
import {
    ProjectPhaseListTableColumn
} from "@app/pages/displays/display-projects/TableColumns/ProjectPhaseListTableColumn";

export class TemplateTypes {

    headerTemplateType: {
        column: GenericTableColumn,
    }

    cellTemplateType: {
        column: GenericTableColumn,
        row: ProjectsDisplayRow,
        rowIndex: number,
    }

    rosterHeaderTemplateType: {
        column: TaskListRosterTableColumn,
    }

    rosterCellTemplateType: {
        column: TaskListRosterTableColumn,
        row: ProjectsDisplayRow,
        rowIndex: number,
    }

    followingMilestoneCellTemplateType: {
        column: FollowingMilestoneTableColumn,
        row: ProjectsDisplayRow,
        rowIndex: number,
    }

    taskListNextMilestoneCellTemplateType: {
        column: TaskListNextMilestoneTableColumn,
        row: ProjectsDisplayRow,
        rowIndex: number,
    }

    taskListAllCellTemplateType: {
        column: TaskListAllTableColumn,
        row: ProjectsDisplayRow,
        rowIndex: number,
    }

    taskListWithoutMilestoneCellTemplateType: {
        column: TaskListWithoutMilestoneTableColumn,
        row: ProjectsDisplayRow,
        rowIndex: number,
    }

    appointmentListCellTemplateType: {
        column: AppointmentListTableColumn,
        row: ProjectsDisplayRow,
        rowIndex: number,
    }

    todoListCellTemplateType: {
        column: TodoListTableColumn,
        row: ProjectsDisplayRow,
        rowIndex: number,
    }

    yearWheelHeaderTemplateType: {
        column: YearWheelTableColumn,
    }

    yearWheelCellTemplateType: {
        column: YearWheelTableColumn,
        row: ProjectsDisplayRow,
        rowIndex: number,
    }

    categoryListCellTemplateType: {
        column: ProjectCategoryListTableColumn,
        row: ProjectsDisplayRow,
        rowIndex: number,
    }

    phaseListCellTemplateType: {
        column: ProjectPhaseListTableColumn,
        row: ProjectsDisplayRow,
        rowIndex: number,
    }

}
