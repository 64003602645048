<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'" cdkDrag>
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title" [innerHTML]="title"></h4>
        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <div class="modal-body" [innerHTML]="message" *ngIf="message"></div>
    <div class="container">
        <div class="w-100 d-flex justify-content-center  align-items-center" style="height: 200px;" *ngIf="isLoading">
            <app-loading-indicator [showLogo]="false" class="text-large "></app-loading-indicator>
        </div>
        <ng-container *ngIf="!isLoading">
            <select [(ngModel)]="item.project_type_id" class="form-select custom-select mb-2 mt-2 w-100">
                <option *ngFor="let type of projectTypes"
                        [disabled]="!type.change_type_available"
                        [value]="type.id">{{type.name}}</option>
            </select>


        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary ellipsis" (click)="decline()"
                [disabled]="isLoading">{{ btnCancelText }}
        </button>
        <button type="button" class="btn btn-success ellipsis" (click)="accept()" ngbAutofocus
                [disabled]="isLoading">{{ btnOkText }}</button>
    </div>
</div>
