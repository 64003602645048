<ng-container *ngIf="false">
<span class="fal fa-cogs cursor-pointer position-relative"
      [ngbPopover]="popoverContent"
      [placement]="['bottom', 'auto']"
      container="body"
      #popover="ngbPopover"
>
</span>

<ng-template #popoverContent>
    <pre class="debug relative scroll-y m-0">
column.canAutoResize: {{column.canAutoResize | json}}
column.resizeable: {{column.resizeable | json}}
column.customWidth: {{column.customWidth | json}}
column.width: {{column.width | json}}
column.maxWidth: {{column.maxWidth | json}}
column.minWidth: {{column.minWidth | json}}
    </pre>
</ng-template>
</ng-container>
