/**
 * Created by ModelParser
 */
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class Office365EventDefinition extends BaseModel {
    foreign_id?: string;
    user_id?: number;
    user?: User;
    subject?: string;
    body?: string;
    is_all_day?: boolean;
    start?: string;
    end?: string;
    free_busy_status_id?: number;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.foreign_id;
            delete this.user_id;
            delete this.user;
            delete this.subject;
            delete this.body;
            delete this.is_all_day;
            delete this.start;
            delete this.end;
            delete this.free_busy_status_id;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.foreign_id != null) {
            this.foreign_id = data.foreign_id;
        }
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.subject != null) {
            this.subject = data.subject;
        }
        if (data.body != null) {
            this.body = data.body;
        }
        if (data.is_all_day != null) {
            this.is_all_day = data.is_all_day;
        }
        if (data.start != null) {
            this.start = data.start;
        }
        if (data.end != null) {
            this.end = data.end;
        }
        if (data.free_busy_status_id != null) {
            this.free_busy_status_id = data.free_busy_status_id;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
