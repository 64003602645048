import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";

export class ProjectUserListCell extends GenericCell {

    public exportCSV(secondary?: any): CSVCellValue {
        return this.row.project.item.findProjectsUsersByType(secondary)
            .filter(projectsUser => projectsUser.user?.exists())
            .map(projectsUser => {
                return projectsUser.user.name;
            });
    }

}
