import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PageComponent} from '@app/pages/page.component';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Api} from '@app/core/http/Api/Api';
import {FiltersInterface} from '@app/services/ShellFilterService/FiltersInterface';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import Helpers from '@app/core/helpers';
import * as moment from 'moment';
import {RestoreItem} from '@app/pages/lists/restore/RestoreItem';
import { CardItem } from '@app/shared/_ui/cards/CardItem';
import {CardProjectConfiguration} from '@app/shared/_ui/cards/medium/card-project/card-project-configuration';
import {CardMilestoneConfiguration} from '@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration';
import {CardTaskConfiguration} from '@app/shared/_ui/cards/medium/card-task/card-task-configuration';
import {DeletionTypes} from '@app/constants';
import {ColumnController} from "@app/core/ColumnControl/ColumnController";

@Component({
    selector: 'app-restore',
    templateUrl: './restore.component.html',
    styleUrls: ['./restore.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestoreComponent extends PageComponent implements OnInit {

    // UI
    public periodStart?: NgbDateStruct;
    public periodEnd?: NgbDateStruct;
    public searchValue?: string;
    public items: RestoreItem[] = [];
    public types: string[] = [
        'project',
    ];
    protected filtersSettings?: FiltersInterface;
    protected columnController?: ColumnController;

    // Data
    private searchValueChange: Subject<string> = new Subject<string>();

    constructor(
        private cd: ChangeDetectorRef,
        private editorPanelService: EditorPanelService
    ) {
        super();
        this.cdr = cd;

        this.searchValueChange
            .pipe(debounceTime(1000), distinctUntilChanged()) // wait 1 sec after the last event before emitting last event &&  only emit if value is different from previous value
            .subscribe((value: string) => {
                this.loadData();
            });
    }

    ngOnInit() {
        this.shellService.setHeaderTitle(this.translateService.instant('_ui_deleted_items'), null, false, false, false);
        this.loadData();
    }

    // <editor-fold desc="View actions">

    public onPeriodChanged() {
        this.loadData();
    }

    public onSearchBtnClicked() {
        this.loadData();
    }

    public onResetSearchBtnClicked() {
        this.searchValue = undefined;
        this.loadData();
    }

    public onSearchValueChanged(query: string) {
        this.searchValueChange.next(query);
    }

    public onSelectedTypesChanged() {
        this.loadData();
    }

    public onRestoreBtnClicked(item: RestoreItem) {
        this.dialogService.restoreItem(
            this.translateService.instant('_ui_restore'),
            this.translateService.instant('_ui_restore_description', {name: this.translateService.instant(item.deletion.getTitleByType()) } ),
            this.translateService.instant('_ui_restore'),
            this.translateService.instant('_global_cancel'),
            'lg',
            item
        )
            .then((confirmed) => {
                console.log('confirmed', confirmed)
                if (confirmed) {
                    Api.deletions().restorePutById(item.deletion.id!)
                        .save(null, data => {
                            this.loadData();

                            switch (item.deletion.type) {
                                case DeletionTypes.Project:
                                    this.editorPanelService.open(item.deletion.projects[0]);
                                    break;
                                case DeletionTypes.Milestone:
                                    this.editorPanelService.open(item.deletion.milestones[0]);
                                    break;
                                case DeletionTypes.Task:
                                    this.editorPanelService.open(item.deletion.tasks[0]);
                                    break;
                            }
                        });
                }
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    private loadData() {
        this.isLoading = true;
        const api = Api.deletions()
            .get()
            .include('project')
            .include('milestone')
            .include('task');

        if (this.periodStart) {
            const startDate = moment([this.periodStart.year, this.periodStart.month - 1, this.periodStart.day])
                .startOf('day')
                .toDate();
            api.whereGreaterThanOrEqual('created', Helpers.serverDate(startDate));
        }
        if (this.periodEnd) {
            const endDate = moment([this.periodEnd.year, this.periodEnd.month - 1, this.periodEnd.day])
                .endOf('day')
                .toDate();
            api.whereLessThanOrEqual('created', Helpers.serverDate(endDate));
        }
        if (this.types?.length) {
            api.whereIn('type', this.types);
        }
        if (this.searchValue?.length) {
            api.search('title', this.searchValue);
        }

        api
            .orderDesc('created')
            .find(deletions => {
                this.items = deletions
                    .filter(deletion => {
                        console.log('deletion', deletion);
                        return deletion && deletion.hasItems;
                    })
                    .map(deletion => {
                        const row = new RestoreItem();
                        row.deletion = deletion;
                        row.cards = [
                            ...deletion.projects?.map(project => new CardItem(project, new CardProjectConfiguration())) ?? [],
                            ...deletion.milestones?.map(milestone => new CardItem(milestone, new CardMilestoneConfiguration()))  ?? [],
                            ...deletion.tasks?.map(task => new CardItem(task, new CardTaskConfiguration()))  ?? [],
                        ];

                        return row;
                    });
                this.isLoading = false;
                this.detectChanges();
            });
    }

    // </editor-fold>

}
