import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import {EditorInterface} from "@app/editor/quick-editor/EditorInterface";
import {ListConfiguration} from '@app/shared/_ui/lists/ListConfiguration';

@Component({
    selector: 'app-generic-vertical-edit-collection',
    templateUrl: './generic-vertical-edit-collection.component.html',
    styleUrls: ['./generic-vertical-edit-collection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericVerticalEditCollectionComponent extends BaseComponent {

    // Bindings to parent
    @Input() cardItem: CardItem;
    @Input() enabledEditors: EditorInterface[];
    @Input() listDragInterface: ListDragInterface;
    @Input() listConfiguration: ListConfiguration;
    @Input() showCreateNew = true;
    @Input() cssClasses = '';

    // Bindings to view
    public isDragEnabled: boolean;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.render();
    }

    private render() {
        this.isDragEnabled = this.listDragInterface?.isCardItemDraggable(this.cardItem) ?? false;
    }

}
