/**
 * Created by ModelParser
 * Date: 30-12-2020.
 * Time: 08:06.
 */
import {CategoriesProjectDefinition} from './definitions/CategoriesProjectDefinition';

export class CategoriesProject extends CategoriesProjectDefinition {

    constructor(json?: any) {
        super(json);
    }

}
