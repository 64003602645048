import {NgModule} from "@angular/core";
import {
    TemplateBlockedPeriodPickerComponent
} from "@app/shared/_ui/displays/milestone-plan/template-blocked-period-picker/template-blocked-period-picker.component";
import {TranslateModule} from "@ngx-translate/core";
import {DateRangePickerModule} from "@app/shared/_elements/date-range-picker/DateRangePicker.module";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        DateRangePickerModule,
        LoadingIndicatorModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TemplateBlockedPeriodPickerComponent,
    ],
    exports: [
        TemplateBlockedPeriodPickerComponent

    ],
    providers: [

    ],
})
export class TemplateBlockedPeriodPickerModule {}
