/**
 * Created by ModelParser
 * Date: 01-03-2019.
 * Time: 10:14.
 */
import {FavoriteDefinition} from './definitions/FavoriteDefinition';

export class Favorite extends FavoriteDefinition {

    constructor(json?: any) {
        super(json);
    }

}
