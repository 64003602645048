/**
 * Created by ModelParser
 */
import {TaskStatusType} from '../TaskStatusType';
import {TaskType} from '../TaskType';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class TaskStatusTypesTaskTypeDefinition extends BaseModel {
    task_status_type_id?: number;
    task_status_type?: TaskStatusType;
    task_type_id?: number;
    task_type?: TaskType;
    visible?: boolean;
    required?: boolean;
    index_?: number;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.task_status_type_id;
            delete this.task_status_type;
            delete this.task_type_id;
            delete this.task_type;
            delete this.visible;
            delete this.required;
            delete this.index_;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.task_status_type_id != null) {
            this.task_status_type_id = data.task_status_type_id;
        }
        if (data.task_status_type != null) {
            this.task_status_type = new TaskStatusType(data.task_status_type);
        }
        if (data.task_type_id != null) {
            this.task_type_id = data.task_type_id;
        }
        if (data.task_type != null) {
            this.task_type = new TaskType(data.task_type);
        }
        if (data.visible != null) {
            this.visible = data.visible;
        }
        if (data.required != null) {
            this.required = data.required;
        }
        if (data.index_ != null) {
            this.index_ = data.index_;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
