import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {EventEmitter} from "@angular/core";

export class EditLinkConfiguration extends BaseEditorConfiguration {

    public label: string;
    public isExpanded = false;
    public variant: 'standard' | 'compact';

    public onChangeEvent = new EventEmitter();

    constructor(label: string, variant: 'standard' | 'compact') {
        super();
        this.label = label;
        this.variant = variant;
    }

    public setIsExpanded(value: boolean) {
        this.isExpanded = value;
        this.onChangeEvent.emit();
    }

}
