import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {MilestonePresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/MilestonePresetType";
import {ProjectPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/ProjectPresetType";

export class ProjectRelatedProjectPresetGenerator implements CreatePresetGenerator {

    private readonly projectId?: number;
    private readonly projectIdHandler?: () => number;

    constructor(projectIdOrHandler: number | (() => number)) {
        if (typeof projectIdOrHandler == "number") {
            this.projectId = projectIdOrHandler;
        } else {
            this.projectIdHandler = projectIdOrHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: ProjectPresetTypes.Project,
            payload: {
                projectId: this.projectId ?? this.projectIdHandler(),
            }
        };
    }

}
