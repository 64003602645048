import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {Widths} from "@app/constants";
import {TemplateRef} from "@angular/core";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import { ColumnTypes } from "@app/pages/displays/display-project-details/subdisplay-tasks/ColumnTypes";
import {
    TaskListAllColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/Columns/TaskListAllColumn";

export class TaskListAllColumnType extends BaseColumnType {

    public identifier = ColumnTypes.TaskList_All;

    public isAlwaysVisible = false;
    public customWidth?: number;
    public minWidth = Widths.CardColumn;
    public maxWidth?: number;
    public resizeable = false;
    public frozenLeft = false;
    public canAutoResize = false;
    public isList = true;
    public cellClass?: string;

    public constructor(cellTemplate?: TemplateRef<any>, headerTemplate?: TemplateRef<any>) {
        super();
        this.cellTemplate = cellTemplate;
        this.headerTemplate = headerTemplate;
    }

    public createColumn(column: Column, settings: Map<string, ColumnSetting>): BaseColumn {
        return new TaskListAllColumn(this, column, settings);
    }

}
