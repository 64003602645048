import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {TableColumns} from "@app/pages/displays/display-projects/TableColumns";
import {AppointmentListColumn} from "@app/pages/displays/display-projects/Columns/AppointmentListColumn";

export class AppointmentListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.AppointmentList;
    public column: AppointmentListColumn;

}
