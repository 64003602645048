import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {AppointmentListConfiguration} from "@app/shared/_ui/lists/appointment-list/AppointmentListConfiguration";
import {AppointmentListColumn} from "@app/pages/displays/display-projects/Columns/AppointmentListColumn";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVListBinding} from "@app/export/csv/CSVListBinding";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";

export class AppointmentListCell extends GenericCell {

    public listConfiguration = new AppointmentListConfiguration();
    public csvListBinding = new CSVListBinding();

    constructor(row: ProjectsDisplayRow, column: AppointmentListColumn) {
        super(row, column);

        this.listConfiguration
            .setLimit(2)
            .setOrderBy([['updated', 'desc'], ['created', 'desc']])
            .setProject(row.project.item);
    }

    public exportCSV(secondary?: any): CSVCellValue {
        return this.csvListBinding.export();
    }

}
