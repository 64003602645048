import {Row} from "@app/pages/displays/display-project-details/subdisplay-tasks/Row";
import {
    TaskListPeriodTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns/TaskListPeriodTableColumn";
import {
    TaskListStatusTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns/TaskListStatusTableColumn";
import {
    TaskListAllTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns/TaskListAllTableColumn";
import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns/GenericTableColumn";
import {
    TaskEditTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns/TaskEditTableColumn";

export class TemplateTypes {

    headerTemplateType: {
        column: GenericTableColumn,
    }

    headerTaskEditTemplateType: {
        column: TaskEditTableColumn,
    }

    headerTaskListPeriodType: {
        column: TaskListPeriodTableColumn,
    }

    headerTaskListStatusType: {
        column: TaskListStatusTableColumn,
    }

    cellTemplateType: {
        column: GenericTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellTaskEditType: {
        column: TaskEditTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellTaskListStatusType: {
        column: TaskListStatusTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellTaskListPeriodType: {
        column: TaskListPeriodTableColumn,
        row: Row,
        rowIndex: number,
    }

    cellTaskListAllType: {
        column: TaskListAllTableColumn,
        row: Row,
        rowIndex: number,
    }

}
