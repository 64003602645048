import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {StaticDeadline} from "@app/core/models";
import * as moment from "moment/moment";
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-static-deadline-row',
    templateUrl: './static-deadline-row.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticDeadlineRowComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() staticDeadline: StaticDeadline;
    @Input() previousDeadlineName?: string;
    @Input() previousDeadlineDate?: Date;

    // Bindings to view
    public staticDeadlineName: string;
    public staticDeadlineDate: Date;
    public hasPreviousDeadline: boolean;
    public diff: number;
    public diffDescription: string;

    ngOnInit() {
        super.ngOnInit();
        this.staticDeadlineName = this.staticDeadline.name ?? '';
        this.staticDeadlineDate = this.staticDeadline.getDate();
        this.hasPreviousDeadline = this.previousDeadlineName !== undefined && this.previousDeadlineDate !== undefined;

        if (this.hasPreviousDeadline) {
            this.diff = (moment(this.staticDeadline.getDate()).diff(moment(this.previousDeadlineDate), 'days'));
            this.diffDescription = this.previousDeadlineDiffDescription;
        } else {
            this.diff = 0;
            this.diffDescription = '';
        }
    }

    private get previousDeadlineDiffDescription(): string {
        const translate = AppInjector.getInjector().get(TranslateService);
        const diffDescription = (this.diff) > 1 ? translate.instant('_ui_days') : translate.instant('_ui_day');
        return `${(this.diff)} ${diffDescription}`;
    }

}
