import {Widths} from "@app/constants";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {TemplateRef} from "@angular/core";
import {ColumnTypes} from "@app/pages/displays/display-projects/ColumnTypes";
import {
    TaskListWithoutMilestoneColumn
} from "@app/pages/displays/display-projects/Columns/TaskListWithoutMilestoneColumn";
import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";

export class TaskListWithoutMilestoneColumnType extends BaseColumnType {

    public identifier = ColumnTypes.TaskListWithoutMilestone;

    public isAlwaysVisible = false;

    public customWidth?: number;
    public minWidth = Widths.CardColumn;
    public maxWidth?: number;

    public resizeable = true;
    public canAutoResize = true;

    public frozenLeft = false;
    public cellClass?: string;

    public isList = true;

    public constructor(cellTemplate: TemplateRef<any>, headerTemplate: TemplateRef<any>,
                       onScreenFilters: BaseOnScreenFilter[]) {
        super();
        this.cellTemplate = cellTemplate;
        this.headerTemplate = headerTemplate;
        this.onScreenFilters = onScreenFilters;
    }

    public createColumn(column: Column, settings: Map<string, ColumnSetting>): TaskListWithoutMilestoneColumn {
        return new TaskListWithoutMilestoneColumn(this, column, settings);
    }

}
