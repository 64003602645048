/**
 * Created by ModelParser
 */
import {User} from '../User';
import {Display} from '../Display';
import {Phase} from '../Phase';
import {ProjectDeadlineType} from '../ProjectDeadlineType';
import {DisplayType} from '../DisplayType';
import {PhaseProgressType} from '../PhaseProgressType';
import {TaskDeadlineType} from '../TaskDeadlineType';
import {Status} from '../Status';
import {DisplayFilter} from '../DisplayFilter';
import {DisplayFiltersSetting} from '../DisplayFiltersSetting';
import {Setting} from '../Setting';
import {DisplayFiltersProjectType} from '../DisplayFiltersProjectType';
import {ProjectType} from '../ProjectType';
import {DisplayFiltersTaskType} from '../DisplayFiltersTaskType';
import {TaskType} from '../TaskType';
import {ReactionFilter} from '../ReactionFilter';
import {Category} from '../Category';
import {CategoriesDisplayFilter} from '../CategoriesDisplayFilter';
import {DepartmentsDisplayFilter} from '../DepartmentsDisplayFilter';
import {DisplayFilterUserSetting} from '../DisplayFilterUserSetting';
import {DisplayFiltersColumn} from '../DisplayFiltersColumn';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class DisplayFilterDefinition extends BaseModel {
    user_id?: number;
    user?: User;
    name?: string;
    filter_type?: string;
    period_type?: string;
    sort_type?: string;
    hand_up?: boolean;
    starred?: boolean;
    sort_direction?: string;
    show_in_dropdown?: boolean;
    display_id?: number;
    display?: Display;
    period_start?: string;
    period_end?: string;
    phase_id?: number;
    phase?: Phase;
    project_deadline_type_id?: number;
    project_deadline_type?: ProjectDeadlineType;
    include_archived_since_type?: string;
    include_archived_since_date?: string;
    display_type_id?: number;
    display_type?: DisplayType;
    year_wheel_period_type?: string;
    year_wheel_period_start?: string;
    year_wheel_period_end?: string;
    override_display_columns?: boolean;
    phase_progress_type_id?: number;
    phase_progress_type?: PhaseProgressType;
    task_deadline_type_id?: number;
    task_deadline_type?: TaskDeadlineType;
    extra_sort1_type?: string;
    extra_sort1_direction?: string;
    statuses?: Status[];
    children?: DisplayFilter[];
    display_filters_settings?: DisplayFiltersSetting[];
    settings?: Setting[];
    display_filters_project_types?: DisplayFiltersProjectType[];
    project_types?: ProjectType[];
    display_filters_task_types?: DisplayFiltersTaskType[];
    task_types?: TaskType[];
    reaction_filters?: ReactionFilter[];
    categories?: Category[];
    categories_display_filters?: CategoriesDisplayFilter[];
    departments_display_filters?: DepartmentsDisplayFilter[];
    display_filter_user_settings?: DisplayFilterUserSetting[];
    display_filters_columns?: DisplayFiltersColumn[];
    my_settings?: DisplayFilterUserSetting;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.user_id;
            delete this.user;
            delete this.name;
            delete this.filter_type;
            delete this.period_type;
            delete this.sort_type;
            delete this.hand_up;
            delete this.starred;
            delete this.sort_direction;
            delete this.show_in_dropdown;
            delete this.display_id;
            delete this.display;
            delete this.period_start;
            delete this.period_end;
            delete this.phase_id;
            delete this.phase;
            delete this.project_deadline_type_id;
            delete this.project_deadline_type;
            delete this.include_archived_since_type;
            delete this.include_archived_since_date;
            delete this.display_type_id;
            delete this.display_type;
            delete this.year_wheel_period_type;
            delete this.year_wheel_period_start;
            delete this.year_wheel_period_end;
            delete this.override_display_columns;
            delete this.phase_progress_type_id;
            delete this.phase_progress_type;
            delete this.task_deadline_type_id;
            delete this.task_deadline_type;
            delete this.extra_sort1_type;
            delete this.extra_sort1_direction;
            delete this.statuses;
            delete this.children;
            delete this.display_filters_settings;
            delete this.settings;
            delete this.display_filters_project_types;
            delete this.project_types;
            delete this.display_filters_task_types;
            delete this.task_types;
            delete this.reaction_filters;
            delete this.categories;
            delete this.categories_display_filters;
            delete this.departments_display_filters;
            delete this.display_filter_user_settings;
            delete this.display_filters_columns;
            delete this.my_settings;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.filter_type != null) {
            this.filter_type = data.filter_type;
        }
        if (data.period_type != null) {
            this.period_type = data.period_type;
        }
        if (data.sort_type != null) {
            this.sort_type = data.sort_type;
        }
        if (data.hand_up != null) {
            this.hand_up = data.hand_up;
        }
        if (data.starred != null) {
            this.starred = data.starred;
        }
        if (data.sort_direction != null) {
            this.sort_direction = data.sort_direction;
        }
        if (data.show_in_dropdown != null) {
            this.show_in_dropdown = data.show_in_dropdown;
        }
        if (data.display_id != null) {
            this.display_id = data.display_id;
        }
        if (data.display != null) {
            this.display = new Display(data.display);
        }
        if (data.period_start != null) {
            this.period_start = data.period_start;
        }
        if (data.period_end != null) {
            this.period_end = data.period_end;
        }
        if (data.phase_id != null) {
            this.phase_id = data.phase_id;
        }
        if (data.phase != null) {
            this.phase = new Phase(data.phase);
        }
        if (data.project_deadline_type_id != null) {
            this.project_deadline_type_id = data.project_deadline_type_id;
        }
        if (data.project_deadline_type != null) {
            this.project_deadline_type = new ProjectDeadlineType(data.project_deadline_type);
        }
        if (data.include_archived_since_type != null) {
            this.include_archived_since_type = data.include_archived_since_type;
        }
        if (data.include_archived_since_date != null) {
            this.include_archived_since_date = data.include_archived_since_date;
        }
        if (data.display_type_id != null) {
            this.display_type_id = data.display_type_id;
        }
        if (data.display_type != null) {
            this.display_type = new DisplayType(data.display_type);
        }
        if (data.year_wheel_period_type != null) {
            this.year_wheel_period_type = data.year_wheel_period_type;
        }
        if (data.year_wheel_period_start != null) {
            this.year_wheel_period_start = data.year_wheel_period_start;
        }
        if (data.year_wheel_period_end != null) {
            this.year_wheel_period_end = data.year_wheel_period_end;
        }
        if (data.override_display_columns != null) {
            this.override_display_columns = data.override_display_columns;
        }
        if (data.phase_progress_type_id != null) {
            this.phase_progress_type_id = data.phase_progress_type_id;
        }
        if (data.phase_progress_type != null) {
            this.phase_progress_type = new PhaseProgressType(data.phase_progress_type);
        }
        if (data.task_deadline_type_id != null) {
            this.task_deadline_type_id = data.task_deadline_type_id;
        }
        if (data.task_deadline_type != null) {
            this.task_deadline_type = new TaskDeadlineType(data.task_deadline_type);
        }
        if (data.extra_sort1_type != null) {
            this.extra_sort1_type = data.extra_sort1_type;
        }
        if (data.extra_sort1_direction != null) {
            this.extra_sort1_direction = data.extra_sort1_direction;
        }
        if (data.statuses != null) {
            this.statuses = data.statuses.map((i: any) => new Status(i));
        }
        if (data.children != null) {
            this.children = data.children.map((i: any) => new DisplayFilter(i));
        }
        if (data.display_filters_settings != null) {
            this.display_filters_settings = data.display_filters_settings.map((i: any) => new DisplayFiltersSetting(i));
        }
        if (data.settings != null) {
            this.settings = data.settings.map((i: any) => new Setting(i));
        }
        if (data.display_filters_project_types != null) {
            this.display_filters_project_types = data.display_filters_project_types.map((i: any) => new DisplayFiltersProjectType(i));
        }
        if (data.project_types != null) {
            this.project_types = data.project_types.map((i: any) => new ProjectType(i));
        }
        if (data.display_filters_task_types != null) {
            this.display_filters_task_types = data.display_filters_task_types.map((i: any) => new DisplayFiltersTaskType(i));
        }
        if (data.task_types != null) {
            this.task_types = data.task_types.map((i: any) => new TaskType(i));
        }
        if (data.reaction_filters != null) {
            this.reaction_filters = data.reaction_filters.map((i: any) => new ReactionFilter(i));
        }
        if (data.categories != null) {
            this.categories = data.categories.map((i: any) => new Category(i));
        }
        if (data.categories_display_filters != null) {
            this.categories_display_filters = data.categories_display_filters.map((i: any) => new CategoriesDisplayFilter(i));
        }
        if (data.departments_display_filters != null) {
            this.departments_display_filters = data.departments_display_filters.map((i: any) => new DepartmentsDisplayFilter(i));
        }
        if (data.display_filter_user_settings != null) {
            this.display_filter_user_settings = data.display_filter_user_settings.map((i: any) => new DisplayFilterUserSetting(i));
        }
        if (data.display_filters_columns != null) {
            this.display_filters_columns = data.display_filters_columns.map((i: any) => new DisplayFiltersColumn(i));
        }
        if (data.my_settings != null) {
            this.my_settings = new DisplayFilterUserSetting(data.my_settings);
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
