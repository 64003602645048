import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {tap} from "rxjs/operators";
import {versions} from '@env/versions';
import {AuthenticationService} from "@app/core";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
                ){

    }
    private dataHandler(event:any){
        if (event instanceof HttpResponse) {
            if(event.body && event.body.status == "ERROR") {
                console.error('Firebase error : ', event.body);

            }
        }

    }


    private errorHandler(error:any){
        console.log('AuthInterceptor :: errorHandler: ', error);
        if (error instanceof HttpErrorResponse) {
            console.log('errorHandler : HttpResponse : ', event);

            switch (error.status){
                case 404:
                    break;
                case 401:
                    //console.warn('User logged out!');
                    //this.authenticationService.logout();
                    if(error.error == 'invalid_token'){
                        // this.authenticationService.revokeToken();
                        // this.router.navigate(['/login']);
                    }

                    break;

                case 500:
                    console.error('Server fejl: ', event);
                    //this.messageService.addErrors(event.toString());
                    break;
                case 503:
                    console.error('Server ikke tilgængelig: ', event, error);
                    //this.messageService.addErrors(event.toString());
                    break;
                default:
                    console.log('unknown status : ', event);
                    break;
            }
        }

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newParams = request.params;
        newParams = newParams.append('app_version', versions.npm_version);

        request = request.clone({params: newParams});

        return next.handle(request).pipe(
            tap(
                data => this.dataHandler(event),
                error => this.errorHandler(error)
            )
        );
    }
}
