import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import {PageComponent} from '@app/pages/page.component';
import {Settings} from '@app/pages/displays/dashboard/Settings';
import {PeriodRange} from '@app/services/FilterGlobalService/PeriodRange';
import {ResizeEvent} from 'angular-resizable-element';
import {Display, User} from '@app/core/models';
import {DisplayTypes} from "@app/constants";
import {Api} from "@app/core/http/Api/Api";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";

@Component({
    selector: 'app-display-dashboard',
    templateUrl: './display-dashboard.component.html',
    styleUrls: ['./display-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayDashboardComponent extends PageComponent implements OnInit {
    protected filtersSettings?: FiltersInterface;
    protected columnController?: ColumnController;

    @ViewChild('splitContainer', {static: true}) splitContainer: ElementRef;

    // UI
    public period: PeriodRange = new PeriodRange();
    public showItems: boolean = true;
    public showProjects: boolean = true;
    public showMyWeek: boolean = true;
    public showCalendar: boolean = true;
    public height: number = 60;
    public activeUser: User;
    public dashboardHeaderDisplayId: number;
    public dashboardCalendarDisplayId: number;
    public dashboardProjectsDisplayId: number;
    public dashboardSidebarTaskListDisplayId: number;
    public calendarShellPageData: ShellPageData;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterGlobalService.setPeriod(this.period);
        this.filterGlobalService.setShowWeekPicker(true);

        this.filterGlobalService.onSettingsPeriodChangeEvent.subscribe(p => {
            this.period = p;
        })

        // Get active user and update title
        const userId = this.shellService.getPageSettings()?.userId;
        if (userId) {
            Api.users().getById(userId).find(user => {
                this.activeUser = user[0];
                this.updateDisplayTitle();
            });
        }
    }

    // Triggered by async active user and display
    private updateDisplayTitle() {
        // console.warn(this.display?.name, this.activeUser?.name, this.calendarShellPageData?.shellFilterGroup?.activeFilter?.name);
        if (this.display?.name && this.activeUser?.name) {
            const isMe = this.activeUser.id == this.getUser().id;
            const hasFilter = this.calendarShellPageData?.shellFilterGroup?.activeFilter?.exists();

            if (isMe) {
                if (hasFilter) {
                    this.shellService.setHeaderTitle(`${this.display?.name}, ${this.calendarShellPageData?.shellFilterGroup?.activeFilter?.name}`);
                } else {
                    this.shellService.setHeaderTitle(`${this.display?.name}`);
                }
            } else {
                if (hasFilter) {
                    this.shellService.setHeaderTitle(`${this.activeUser?.name}, ${this.calendarShellPageData?.shellFilterGroup?.activeFilter?.name}`);
                } else {
                    this.shellService.setHeaderTitle(`${this.activeUser?.name}`);
                }
            }
        } else {
            this.shellService.setHeaderTitle(this.Constants.System.Loading);
        }

        this.detectChanges();
    }

    // <editor-fold desc="View actions">

    public toggleProjectSize(fullSize: boolean) {
        this.projectsFullSizeActive = fullSize;
        if (!fullSize) {
            this.styleTop = {
                display: 'none'
            };
            this.styleHandle = {
                display: 'none'
            };
            this.styleBottom = {
                height: `${100}%`
            };
            window.dispatchEvent(new Event('resize'));
        } else {
            this.applySize();
        }
    }


    validate(event: ResizeEvent): boolean {
        const MIN_DIMENSIONS_PX: number = 370;
        return !(event.rectangle.height && (event.rectangle.height < MIN_DIMENSIONS_PX));
    }

    onResizeEnd($event: any) {
        this.height = ($event.rectangle.height / this.splitContainer.nativeElement?.offsetHeight) * 100;
        this.applySize();
    }

    private applySize() {
        this.styleTop = {
            height: `${this.height}%`
        };
        this.styleHandle = {
            top: `calc(${this.height}% - 14px)`,
        };
        this.styleBottom = {
            height: `${100 - this.height}%`
        };
        window.dispatchEvent(new Event('resize'));
    }

    public onCalendarShellPageDataUpdated(value: ShellPageData) {
        this.calendarShellPageData = value;
        this.subscribe(value.shellFilterGroup.onActiveFilterChangeEventSubscribe(filter => {
            this.updateDisplayTitle();
        }));
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    protected applyDisplaySettings(display: Display) {
        super.applyDisplaySettings(display);

        display.relateddisplays?.forEach(relatedDisplay => {
            switch (relatedDisplay.display_type_id) {
                case DisplayTypes.UserOverviewCalendar:
                    this.dashboardCalendarDisplayId = relatedDisplay.id;
                    break;
                case DisplayTypes.UserOverviewMyWeek:
                    this.dashboardHeaderDisplayId = relatedDisplay.id;
                    break;
                case DisplayTypes.UserOverviewProjects:
                    this.dashboardProjectsDisplayId = relatedDisplay.id;
                    break;
                case DisplayTypes.UserOverviewSidebarTaskList:
                    this.dashboardSidebarTaskListDisplayId = relatedDisplay.id;
                    break;
            }
        });
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();

        this.showItems = this.settingsMap.has(Settings.ShowItems) && this.settingsMap.get(Settings.ShowItems).isSet();
        this.showMyWeek = this.settingsMap.has(Settings.ShowMyWeek) && this.settingsMap.get(Settings.ShowMyWeek).isSet();
        this.showCalendar = this.settingsMap.has(Settings.ShowCalendar) && this.settingsMap.get(Settings.ShowCalendar).isSet();
        this.showProjects = this.settingsMap.has(Settings.ShowProjects) && this.settingsMap.get(Settings.ShowProjects).isSet();
        this.updateDisplayTitle();
    }

    // </editor-fold>

    public styleTop: object = {
        height: `${this.height}%`,
        minHeight: `30%`
    };
    public styleHandle: object = {
        top: `calc(${this.height}% - 14px)`,
    };
    public styleBottom: object = {
        height: `${100 - this.height}%`
    };
    public projectsFullSizeActive: boolean = true;
    public calendarFullSizeActive: boolean = false;
}
