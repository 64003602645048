import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    Kanban_Prioritization_CategoryTypeSettingValue,
    Kanban_Prioritization_MatrixSettingValue
} from "@app/core/http/Api/Api";
import {ColumnTypeSettings} from "@app/pages/displays/display-kanban/ColumnTypeSettings";
import {PrioritizationTableColumn} from "@app/pages/displays/display-kanban/TableColumns/PrioritizationTableColumn";
import {GenericColumn} from "@app/pages/displays/display-kanban/Columns/GenericColumn";

export class PrioritizationColumn extends GenericColumn {

    public showMatrix = true;

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new PrioritizationTableColumn(this);
        return [tableColumn];
    }

    public createCell(row: BaseRow): BaseCell {
        throw new Error("Method not implemented.");
    }


    public getMatrixSetting(): Kanban_Prioritization_MatrixSettingValue {
        return this.settings.get(ColumnTypeSettings.Prioritization_Matrix)
            .getObject<Kanban_Prioritization_MatrixSettingValue>();
    }

    public getCategoryType(): number {
        return this.settings.get(ColumnTypeSettings.Prioritization_CategoryType)
            ?.getObject<Kanban_Prioritization_CategoryTypeSettingValue>()
            ?.categoryTypeId ?? 0;
    }

}
