import {NgModule} from "@angular/core";
import {
    GenericVerticalEditCollectionComponent
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/generic-vertical-edit-collection/generic-vertical-edit-collection.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {
    GenericVerticalEditCollectionCellModule
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/generic-vertical-edit-collection-cell/GenericVerticalEditCollectionCell.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        DragDropModule,
        GenericVerticalEditCollectionCellModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        GenericVerticalEditCollectionComponent,
    ],
    exports: [
        GenericVerticalEditCollectionComponent

    ],
    providers: [

    ],
})
export class GenericVerticalEditCollectionModule {}
