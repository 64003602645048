<div class="modal-inner-content " [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-title" [innerHTML]="title"></h4>

        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <ng-container *ngIf="item">
        <div class="modal-body" [innerHTML]="message"></div>

        <div class="ps-3 pe-3 border-top pt-3" *ngIf="item.cards.length > 0">
            <div class="d-flex align-items-center justify-content-start no-wrap w-100  text-black-50 text-small">
                <i class="fal fa-info-circle me-1" aria-hidden="true"></i>
                <p class="m-0 p-0" [innerHTML]="'_ui_restore_description_items' | translate:{item: (item.deletion.getTitleByType() )}  "></p>
            </div>
            <ul class="list-unstyled mt-2">

                <li *ngIf="item.deletion.projects" class="d-flex align-items-center gap-1">

                    <span class="badge rounded-pill badge-primary -ms-auto bg-success"
                          >{{item.deletion.projects?.length}}</span>
                    <span >

                        {{'_projects' | translate}}</span>

                </li>

                <li *ngIf="item.deletion.milestones" class="d-flex align-items-center  gap-1">
                    <span class="badge rounded-pill badge-primary -ms-auto bg-success"
                          >{{item.deletion.milestones?.length}}</span>

                    <span >

                        {{'_milestone_plural' | translate}}</span>

                </li>
                <li *ngIf="item.deletion.tasks" class="d-flex align-items-center  gap-1">
                     <span class="badge rounded-pill badge-primary -ms-auto  bg-success"
                           >{{item.deletion.tasks?.length}}</span>
                    <span>

                        {{'_tasks' | translate}}</span>

                </li>
            </ul>


        </div>
    </ng-container>

</div>
<div class="modal-footer">
    <button type="button" role="button" class="btn btn-secondary ellipsis" (click)="decline()"
            *ngIf="btnCancelText">{{ btnCancelText }}</button>
    <button type="button" role="button"
            class="btn ngbAutofocus {{primaryClass}} ellipsis" (click)="accept()">{{ btnOkText }}</button>
</div>
