export const ProjectPresetTypes = {
    Title:  'title',
    User:  'user',
    Department:  'department',
    Deadline:  'deadline',
    Status:  'status',
    Category: 'category',
    Phase: 'phase',
    UseStatusRules: 'use-status-rules',
    Project: 'project',
};

export type ProjectPresetType =
    typeof ProjectPresetTypes.Title
    | typeof ProjectPresetTypes.User
    | typeof ProjectPresetTypes.Department
    | typeof ProjectPresetTypes.Deadline
    | typeof ProjectPresetTypes.Status
    | typeof ProjectPresetTypes.Category
    | typeof ProjectPresetTypes.Phase
    | typeof ProjectPresetTypes.UseStatusRules
    | typeof ProjectPresetTypes.Project
;
