/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {DisplaySessionDefinition} from './definitions/DisplaySessionDefinition';

export class DisplaySession extends DisplaySessionDefinition {

    constructor(json?: any) {
        super(json);
    }

}
