/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {MilestoneDefinition} from './definitions/MilestoneDefinition';
import {AppInjector} from '@app/services/app-injector.service';
import {EventService} from '@app/services/event.service';
import {
    Events, StatusRuleTypes,
    StatusTypes,
} from '@app/constants';
import {MilestoneStatus} from '@app/core/models/MilestoneStatus';
import {Project} from '@app/core/models/Project';
import {Task} from '@app/core/models/Task';
import {Archived, Category, Deadline, Department, Reaction, User} from "@app/core/models/index";
import {Api, MilestonesCreateRequestPreset, ProjectsCreateRequestPreset} from "@app/core/http/Api/Api";
import {HasTypeProperties} from "@app/interfaces/HasTypeProperties";
import Helpers from '@app/core/helpers';
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {EditStatus} from "@app/editor/quick-editor/editors/generic/status-editor/EditStatus";
import {EditArchived} from "@app/editor/quick-editor/editors/generic/archived-editor/EditArchived";
import {EditTitle} from "@app/editor/quick-editor/editors/generic/title-editor/EditTitle";
import {EditUserList} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserList";
import {EditDeadlineList} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineList";
import {EditUseStatusRules} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/EditUseStatusRules";
import {EditProjectList} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectList";
import {
    EditProjectListFilter
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListFilter";
import {EditText} from "@app/editor/quick-editor/editors/generic/text-editor/EditText";
import {
    EditCategoryPickerList
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerList";
import {EditReactionList} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionList";

export class Milestone extends MilestoneDefinition implements
    HasTypeProperties,
    HasEventGenerator,
    EditArchived,
    EditTitle,
    EditUserList,
    EditDeadlineList,
    EditUseStatusRules,
    EditProjectList,
    EditCategoryPickerList,
    EditReactionList,
    EditText,
    EditStatus {

    constructor(json?: any) {
        super(json);
    }

    public get status(): number {
        if (this.main_status && this.main_status.status_id) {
            return this.main_status.status_id;
        } else {
            return StatusTypes.GREEN;
        }
    }

    public set status(value: number) {
        if (this.main_status && this.main_status.status_id) {
            this.main_status.status_id = value;
            if (this.main_status) delete this.main_status.status;
        } else {
            this.main_status = new MilestoneStatus(
                {status_id: value, todo_status_type_id: 1}
            );
        }
    }

    public get title(): string {
        return this.name;
    }

    public set title(title: string) {
        this.name = title;
    }

    public get handUp(): boolean {
        return this.num_hand_ups > 0;
    }

    public delete(cascade?: boolean, callback?: () => void) {
        Api.milestones().deleteDeleteById(this.id)
            .cascade(cascade)
            .delete(value => {
                AppInjector.getInjector().get(EventService).emitMilestone(this, EventService.Deleted);
                if (callback) {
                    callback()
                }
            });
    }

    // <editor-fold desc="Reaction interface">

    public addReaction(reaction: Reaction): void {
        Api.milestones().reactionAddPutByMilestoneId(this.id)
            .reaction_type_id(reaction.reaction_type_id)
            .value(reaction.value)
            .user_id(reaction.user_id)
            .save(null);
    }

    public removeReaction(reaction: Reaction): void {
        Api.milestones().reactionRemoveDeleteByMilestoneId(this.id)
            .reaction_type_id(reaction.reaction_type_id)
            .value(reaction.value)
            .user_id(reaction.user_id)
            .delete(null);
    }

    public getReactionChangedEventName(): string {
        return Events.MilestoneReactionsChanged(this.id);
    }

    // </editor-fold>

    // <editor-fold desc="Has Type Properties">

    getTypeDefinitionAsync(callback: (definition: string) => void): void {
        callback('M');
    }

    getTypeNameAsync(callback: (name: string) => void): void {
        callback('');
    }

    // </editor-fold>

    public static Create(presets: MilestonesCreateRequestPreset[], callback?: (milestone: Milestone) => void) {
        Api.milestones()
            .createPost()
            .save({presets: presets}, milestones => {
                const milestone = milestones[0];
                AppInjector.getInjector().get(EventService).emitMilestone(milestone, EventService.Created);
                if (callback) {
                    callback(milestone);
                }
            });
    }

    getEventName(field: string, options: any = {}): string {
        switch (field) {
            case 'title':
                return Events.MilestoneChangedTitle(this.id);
            case 'archived':
                return Events.MilestoneChangedArchived(this.id);
            case 'category-list':
            case 'categories':
                return Events.MilestoneChangedCategories(this.id);
            case 'deadlines':
                return Events.MilestoneChangedDeadline(this.id);
            case 'projects':
                return Events.MilestoneChangedProjects(this.id);
            case 'main-status':
                return Events.MilestoneChangedStatus(this.id);
            case 'use_status_rules':
                return Events.MilestoneChangedUseStatusRules(this.id);
            case 'users':
                return Events.MilestoneChangedResponsible(this.id);
            case 'note-field':
                switch (options?.prop ?? '') {
                    case 'notes':
                        return Events.MilestoneChangedNotes(this.id);
                    case 'risk':
                        return Events.MilestoneChangedRisk(this.id);
                    default:
                        return Events.MilestoneChanged(this.id, field);
                }
            default:
                return Events.MilestoneChanged(this.id, field);
        }
    }

    // <editor-fold desc="EditTitle">

    getTitle(): string {
        return this.name ?? '';
    }

    setTitle(value: string): void {
        this.name = value;
        Api.milestones()
            .updateTitlePutByMilestoneId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitMilestone(this, EventService.Updated, ['name']);
    }

    // </editor-fold>

    // <editor-fold desc="EditStatus">

    getStatus(): number {
        return this.main_status?.status_id ?? StatusTypes.GREEN;
    }

    setStatus(value: number): void {
        this.main_status = MilestoneStatus.CreateDefault(value);
        this.use_status_rules = false;
        Api.milestones()
            .updateStatusPutByMilestoneId(this.id)
            .value(value)
            .resetUseStatusRules(true)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitMilestone(this, EventService.Updated, ['main_status', 'use_status_rules']);
    }

    // </editor-fold>

    // <editor-fold desc="EditArchived">

    isArchived(): boolean {
        return (this.archived_id ?? 0) > 0;
    }

    setArchived(value: boolean): void {
        if (value) {
            this.archived = Archived.create();
        } else {
            this.archived = undefined;
            this.archived_id = 0;
        }
        Api.milestones()
            .updateArchivedPutByMilestoneId(this.id)
            .value(value)
            .save(null, milestone => {
                this.archived = milestone.archived;
                this.archived_id = milestone.archived_id;

                AppInjector.getInjector().get(EventService)
                    .emitMilestone(this, EventService.Updated, ['archived', 'archived_id']);
            });
    }

    // </editor-fold>

    // <editor-fold desc="EditUserList">

    public findUsersByTypeId(typeId: number): User[] {
        const hasResponsible = (this.responsible_id ?? 0) > 0
            && this.responsible?.exists();
        return hasResponsible ? [this.responsible] : [];
    }

    public getUsers(): User[] {
        return [];
    }

    public addUser(typeId: number, user: User) {
        this.setResponsible(user);
    }

    public addUsersFromDepartment(typeId: number, department: Department, users: User[]) {
        // Not implemented
    }

    public removeUser(typeId: number, user: User) {
        this.setResponsible(undefined);
    }

    public removeUsers(typeId: number, users: User[]) {
        this.setResponsible(undefined);
    }

    public setResponsible(user?: User): void {
        this.responsible = user;
        this.responsible_id = user?.id ?? 0;

        Api.milestones()
            .updateResponsiblePutByMilestoneId(this.id)
            .userId(this.responsible?.id ?? 0)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitMilestone(this, EventService.Updated, ['responsible', 'responsible_id']);
    }

    // </editor-fold>

    // <editor-fold desc="EditDeadlineList">

    findDeadlineByTypeId(typeId: number): Deadline | undefined {
        const hasDeadline = (this.deadline_id ?? 0) > 0
            && this.deadline?.exists();
        return hasDeadline ? this.deadline : undefined;
    }

    setDeadline(typeId: number, deadline?: Deadline): void {
        this.deadline = deadline;
        this.deadline_id = null;

        Api.milestones()
            .updateDeadlinePutByMilestoneId(this.id)
            .date(deadline?.getServerDate() ?? null)
            .isSoft(deadline?.is_soft ?? false)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitMilestone(this, EventService.Updated, ['deadline', 'deadline_id']);
    }

    // </editor-fold>

    // <editor-fold desc="EditUseStatusRules">

    isUseStatusRules(): boolean {
        return this.use_status_rules ?? false;
    }

    setUseStatusRules(value: boolean): void {
        this.use_status_rules = value;
        Api.milestones()
            .updateUseStatusRulesPutByMilestoneId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitMilestone(this, EventService.Updated, ['use_status_rules']);
    }

    // </editor-fold>

    // <editor-fold desc="EditProjectList">

    getProjects(filter: EditProjectListFilter): Project[] {
        return this.projects ?? [];
    }

    addProject(project: Project, filter: EditProjectListFilter, callback: () => void): void {
        if (!this.projects) {
            this.projects = [];
        }
        if (this.projects.indexOf(project) == -1) {
            this.projects.push(project);
        }

        Api.milestones()
            .addProjectPutByMilestoneId(this.id)
            .value(project.id)
            .save(null, callback);
    }

    removeProject(project: Project, filter: EditProjectListFilter): void {
        const index = this.projects.indexOf(project);
        if (index !== -1) {
            this.projects.splice(index, 1);
        }

        Api.milestones()
            .removeProjectPutByMilestoneId(this.id)
            .value(project.id)
            .save(null);
    }

    // </editor-fold>

    // <editor-fold desc="EditText">

    getText(prop: string): string {
        return this[prop] ?? '';
    }

    setText(prop: string, value: string): void {
        switch (prop) {
            case 'notes':
                this.setNotes(value);
                break;
            case 'risk':
                this.setRisk(value);
                break;
        }
    }

    public setNotes(value: string): void {
        this.notes = value;
        Api.milestones()
            .updateNotesPutByMilestoneId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitMilestone(this, EventService.Updated, ['notes']);
    }

    public setRisk(value: string): void {
        this.risk = value;
        Api.milestones()
            .updateRiskPutByMilestoneId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitMilestone(this, EventService.Updated, ['risk']);
    }

    // </editor-fold>

    // <editor-fold desc="EditCategoryPickerList">

    getCategories(): Category[] {
        return this.categories ?? [];
    }

    findCategoriesByTypeId(typeId: number): Category[] {
        return this.categories
            ?.filter(category => category.category_type_id == typeId) ?? [];
    }

    addCategory(category: Category): void {
        if (!this.categories) {
            this.categories = [];
        }
        if (this.categories.indexOf(category) == -1) {
            this.categories.push(category);
        }

        Api.milestones()
            .addCategoryPutByMilestoneId(this.id)
            .value(category.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitMilestone(this, EventService.Updated, ['categories']);
    }

    removeCategory(category: Category): void {
        const index = this.categories.indexOf(category);
        if (index !== -1) {
            this.categories.splice(index, 1);
        }

        Api.milestones()
            .removeCategoryPutByMilestoneId(this.id)
            .value(category.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitMilestone(this, EventService.Updated, ['categories']);
    }

    // </editor-fold>

}
