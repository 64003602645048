import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input,
    OnInit,
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Milestone, MilestonePlan, Project, ProjectType, Task} from "@app/core/models";
import {EditorInterface} from "@app/editor/quick-editor/EditorInterface";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {StatusEditorComponent} from "@app/editor/quick-editor/editors/generic/status-editor/status-editor.component";
import EnabledEditorsHelper from "@app/editor/quick-editor/EnabledEditorsHelper";
import {
    ArchivedEditorComponent
} from "@app/editor/quick-editor/editors/generic/archived-editor/archived-editor.component";
import {
    ReactionListEditorComponent
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/reaction-list-editor.component";
import {TranslateService} from "@ngx-translate/core";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {Field} from "@app/editor/project-editor-loader/project-editor.service";
import {Fields} from "@app/editor/project-editor-loader/Fields";
import {DisplayTypes, StatusTypes, TaskStatusTypes, TaskUserTypes} from "@app/constants";
import {DisplayService} from "@app/services/display.service";
import {Router} from "@angular/router";
import {ShellService} from "@app/services/ShellService/shell.service";
import {SnackbarService} from "@app/services/snackbar.service";
import {CreateItemSourceConfiguration} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration";
import {CreateItemPreset} from "@app/shared/_ui/create-item-dropdown/CreateItemPreset";
import {CreateItemInterface} from "@app/shared/_ui/create-item-dropdown/CreateItemInterface";
import {CreateItemSourceInterface} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceInterface";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {
    MilestoneUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneUseStatusRulesPresetGenerator";
import {
    MilestoneStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneStatusPresetGenerator";
import {
    MilestoneProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneProjectPresetGenerator";
import {FocusableEntityTypes} from "@app/core/Focusable/FocusableEntityTypes";

@Component({
    selector: 'app-project-editor-header',
    templateUrl: './project-editor-header-component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectEditorHeaderComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item!: Project;
    @Input() projectType!: ProjectType;
    @Input() fields: Map<number, Field>;
    @Input() validator!: ValidationErrorInterface;
    @Input() onReloadEventEmitter!: EventEmitter<boolean>;

    // Bindings to view
    public isReady = false;
    public cardItem: CardItem<Project>;
    public editors: EditorInterface[];
    public showValidationWarning = false;
    public showGoToPlanningBtn = false;
    public createItemSourceConfiguration = new CreateItemSourceConfiguration();
    public createItemPreset = new CreateItemPreset();

    // Data

    constructor(
        private cd: ChangeDetectorRef,
        private translateService: TranslateService,
        private dialogService: BaseDialogService,
        private displayService: DisplayService,
        private router: Router,
        private shellService: ShellService,
        private snackbar: SnackbarService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.setup();
        this.setupCreateItem();
    }

    private setup() {
        this.cardItem = new CardItem<Project>(this.item);

        const availableEditors = [
            StatusEditorComponent.name,
            ArchivedEditorComponent.name,
            ReactionListEditorComponent.name,
        ];

        this.editors = EnabledEditorsHelper.Filter(
            availableEditors,
            EnabledEditorsHelper.ForProjectType(this.projectType)
        );

        this.subscribe(this.validator.onIsValidChanged.subscribe(isValid => {
            this.renderValidation();
            this.detectChanges();
        }));

        this.showGoToPlanningBtn = this.isFieldVisible(Fields.GoToPlanning);

        this.renderValidation();

        this.isReady = true;
    }

    private renderValidation() {
        this.showValidationWarning = !this.validator.isValid();
    }

    private isFieldVisible(fieldId: number): boolean {
        return this.fields.has(fieldId) && this.fields.get(fieldId).visible;
    }

    // <editor-fold desc="Create Item Component"

    private setupCreateItem() {
        const thisClass = this;
        this.createItemSourceConfiguration.sourceInterface = new class implements CreateItemSourceInterface {
            prepareSource(): void {
                thisClass.createItemSourceConfiguration.showTasks = true;
                thisClass.createItemSourceConfiguration.showMilestone = true;
                thisClass.createItemSourceConfiguration.showTodo = false;
                thisClass.createItemSourceConfiguration.showProjects = false;
                thisClass.createItemSourceConfiguration.filterTaskTypesById = thisClass.projectType.project_types_task_types
                    ?.filter(projectTypesTaskType => projectTypesTaskType.visible)
                    ?.map(projectTypesTaskType => projectTypesTaskType.task_type_id) ?? [];
            }
        };
        this.createItemPreset.createTaskInterface = new class implements CreateItemInterface<Task> {
            createPresets(options?: any): CreatePreset[] {
                return [
                    // Defaults
                    new TaskUserPresetGenerator(TaskUserTypes.Creator, thisClass.getUser().id),
                    new TaskUseStatusRulesPresetGenerator(true),
                    new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),

                    new TaskProjectPresetGenerator(thisClass.item.id),
                    ...thisClass.item.departments?.map(department => {
                        return new TaskDepartmentPresetGenerator(department.id);
                    }),
                ].map(generator => generator.generate());
            }
        };
        this.createItemPreset.createMilestoneInterface = new class implements CreateItemInterface<Milestone> {
            createPresets(options?: any): CreatePreset[] {
                return [
                    // Defaults
                    new MilestoneUseStatusRulesPresetGenerator(true),
                    new MilestoneStatusPresetGenerator(StatusTypes.GREEN),

                    new MilestoneProjectPresetGenerator(thisClass.item.id),
                ].map(generator => generator.generate());
            }
        };
    }

    // </editor-fold>

    // <editor-fold desc="View actions">

    public onCopyBtnClicked() {
        this.dialogService.copyProject(
            this.translateService.instant(`_ui_copy`) + ' ' + this.translateService.instant('_project').toLowerCase(),
            this.translateService.instant('_ui_copy_item', {item: this.item.title}),
            this.translateService.instant('_global_ok'),
            this.translateService.instant('_global_cancel'),
            'sm',
            this.item,
        )
            .then(confirmed => {
                // Logik afvikles i dialogen
            })
            .catch(() => {
                console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
            });
    }

    public onMilestonePlanAppliedEvent(milestonePlan: MilestonePlan) {
        this.onReloadEventEmitter.emit();
    }

    public onOpenDisplayBtnClicked() {
        this.displayService.getDisplaysWithoutSettings(displays => {
            const display = displays.find(display => {
                const hasType = [DisplayTypes.ProjectDetails].includes(display.display_type_id);
                const hasDepartment = display.departments_displays?.find(departmentsDisplay => {
                    return departmentsDisplay.department_id == this.shellService.getPageSettings()?.departmentId;
                });
                return hasType && hasDepartment;
            });
            if (display) {
                switch (display.display_type_id) {
                    case DisplayTypes.ProjectDetails:
                        this.router.navigate(
                            [
                                '/app/displays/project-details/',
                                display.id,
                                this.shellService.getPageSettings()?.departmentId,
                                0,
                                this.item.id,
                                this.shellService.getPageSettings()?.primaryDisplayId,
                            ], {queryParamsHandling: "merge"}
                        );
                        break;
                }
            } else {
                console.warn('No program displays found for this department');
                this.snackbar.add(`Ups! Der findes ingen planlægningstavle for denne afdeling. Kontakt venligst administrator.`, null, {
                    duration: 5000,
                    panelClass: 'alert-warning'
                });
            }
        });
    }

    // </editor-fold>

    protected readonly FocusableEntityTypes = FocusableEntityTypes;
}
