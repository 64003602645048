import {NgModule} from "@angular/core";
import {
    YearWheelItemDisplayComponent
} from "@app/shared/_ui/columns/year-wheel/year-wheel-item-display/year-wheel-item-display.component";
import {LocalizedDatePipeModule} from "@app/pipes/LocalizedDatePipe.module";
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {CommonModule} from "@angular/common";
import {
    YearWheelItemDisplayProjectComponent
} from "@app/shared/_ui/columns/year-wheel/year-wheel-item-display/year-wheel-item-display-project/year-wheel-item-display-project.component";
import {
    YearWheelItemDisplayPhaseComponent
} from "@app/shared/_ui/columns/year-wheel/year-wheel-item-display/year-wheel-item-display-phase/year-wheel-item-display-phase.component";
import {
    YearWheelItemDisplayMilestoneComponent
} from "@app/shared/_ui/columns/year-wheel/year-wheel-item-display/year-wheel-item-display-milestone/year-wheel-item-display-milestone.component";
import {
    YearWheelItemDisplayTaskComponent
} from "@app/shared/_ui/columns/year-wheel/year-wheel-item-display/year-wheel-item-display-task/year-wheel-item-display-task.component";

@NgModule({
    imports: [
        CommonModule,
        LocalizedDatePipeModule,
        NgbTooltipModule,
        ColorLabelModule,
        NgbPopoverModule,
    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!, sry..
        YearWheelItemDisplayComponent,
        YearWheelItemDisplayProjectComponent,
        YearWheelItemDisplayPhaseComponent,
        YearWheelItemDisplayMilestoneComponent,
        YearWheelItemDisplayTaskComponent,
    ],
    exports: [
        YearWheelItemDisplayComponent

    ],
    providers: [

    ],
})
export class YearWheelItemDisplayModule {}
