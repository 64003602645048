<app-card-preview [mini]="true" *ngIf="!initialized && visible"></app-card-preview>

<div class="card p-1 mini mb-0" (click)="onCardClick($event)" #cardContainer
     *ngIf="model && initialized && visible && model.project_type"
     [class.grayed-out]="configuration && configuration.isGrayedOut"
>
    <div class="d-flex w-100 font-size-lg align-items-center justify-content-center">
        <app-color-label class="card-type me-1"
                         *ngIf="isFieldVisible(Fields.Status)"
                         [itemWithTypeProperties]="model"
                         [status]="model.status"
                         (onStatusChange)="onStatusChange($event)"></app-color-label>

        <div class="title p-0 font-weight-bold col -border-bottom -border-light flex-fill me-1"
             *ngIf="isFieldVisible(Fields.Title)">
            <input class="form-control" [(ngModel)]="model.title" *ngIf="editable"/>
            <div *ngIf="!editable" class="pt-0 h-100 text-capitalize-firstletter"
                 [ngbTooltip]="model.title | placeholder: ('_ui_no_title' | translate)"
            >
                {{model.title | placeholder: ('_ui_no_title' | translate)}}
            </div>
        </div>

        <a class="text-danger -remove d-flex flex-row align-items-center ms-1 me-1 font-size-md"
           (click)="triggerRemove(model);$event.stopPropagation()"
           [ngbTooltip]="('_ui_emoji_clear' | translate)"
           *ngIf="removable">
            <i class="fal fa-eraser"></i>
        </a>

        <div class="pe-1" *ngIf="model.reference">
            <app-url-link [icon]="true" [title]="false" [maxLinks]="1" [link]="model.reference"></app-url-link>
        </div>

        <ng-container
            *ngIf="showNavigationPlanning && configuration.showGoToPlanningDisplay && planningDisplays?.length && isFieldVisible(Fields.GoToPlanning)">

            <span
                [ngbPopover]="processStepsPopover"
                [autoClose]="false"
                [placement]="['auto']"
                [popoverClass]="'popover-with-dialog'"
                triggers="manual"
                container="body"
                class="d-flex align-items-center"
                #popoverProcess="ngbPopover"
            >

                <span
                    class="ms-auto cursor-pointer"
                    [ngbTooltip]="('_phases' | translate)"
                    (click)="$event.stopPropagation(); popoverProcess.isOpen() ? popoverProcess.close() : popoverProcess.open();"><i
                    class="fal fa-moon"></i></span>
                    <ng-template #processStepsPopover>
                        <span class="close-button" (click)="popoverProcess.close()"><span
                            class="fa fa-times"></span></span>

                        <label class="d-flex card-project mt-1">
                            <span >{{'_ui_process_steps_phase_title' | translate}}</span>
                        </label>
                        <div class="-border-top w-100 mb-2 pb-2 " style="position: sticky;">
                            <app-project-phase-search
                                [project]="model"
                                [autoSave]="true"
                                [showPhases]="false"
                                [allowCreation]="true"
                            ></app-project-phase-search>
                        </div>

                        <label class="d-flex card-project">
                            <span >{{'_ui_process_steps_process_step_title' | translate}}</span>
                        </label>
                        <div class="popover-content-wrapper scroll-y border-top">
                            <app-process-steps [projectId]="model.id"
                                               [showAppender]="false"
                            ></app-process-steps>
                        </div>
                    </ng-template>
                </span>

            <app-toggle-item class="cursor-pointer"
                             [interactive]="false"
                             [active]="true"
                             [icon]="'fa-door-open'"
                             *ngIf="planningDisplays.length == 1"
                             [ngbTooltip]="translateService.instant('_ui_navigate_to')+ ' ' +planningDisplays[0].name"
                             iconWeightActive="fal"
                             (click)="openDoor($event, planningDisplays[0])"></app-toggle-item>
            <div ngbDropdown container="body" *ngIf="planningDisplays.length > 1">
                <app-toggle-item class="cursor-pointer no-caret"
                                 ngbDropdownToggle
                                 container="body"
                                 placement="bottom"
                                 id="select-planning-display"
                                 [interactive]="false"
                                 [active]="true"
                                 [icon]="'fa-door-open'"
                                 (click)="$event.stopImmediatePropagation()"
                                 [ngbTooltip]="planningDisplayName"
                                 iconWeightActive="fal"></app-toggle-item>
                <div
                    ngbDropdownMenu
                    aria-labelledby="select-planning-display"
                    class="position-absolute ">
                    <ng-container *ngFor="let pd of planningDisplays">
                        <app-dropdown-list-item (click)="openDoor($event, pd)"
                                                [iconClass]="'fa-door-open'"
                                                [label]="translateService.instant('_ui_navigate_to')+ ' ' +pd.name"></app-dropdown-list-item>
                    </ng-container>
                </div>
            </div>
        </ng-container>

        <!-- Micro card icons -->
        <ng-container *ngIf="programDisplays && programDisplays.length && isFieldVisible(Fields.GoToProgram)">

            <app-toggle-item class="cursor-pointer"
                             [interactive]="false"
                             [active]="true"
                             [icon]="'fa-presentation'"
                             *ngIf="programDisplays.length == 1"
                             [ngbTooltip]="programDisplaysName"
                             iconWeightActive="fal"
                             (click)="openDisplay($event, programDisplays[0])"></app-toggle-item>
            <div ngbDropdown container="body" *ngIf="programDisplays.length > 1">
                <app-toggle-item class="cursor-pointer no-caret"
                                 ngbDropdownToggle
                                 container="body"
                                 placement="bottom"
                                 id="select-display"
                                 [interactive]="false"
                                 [active]="true"
                                 [icon]="'fa-presentation'"
                                 (click)="$event.stopImmediatePropagation()"
                                 [ngbTooltip]="programDisplaysName"
                                 iconWeightActive="fal"></app-toggle-item>
                <div
                    ngbDropdownMenu
                    aria-labelledby="select-display"
                    class="position-absolute ">
                    <ng-container *ngFor="let pd of programDisplays">
                        <app-dropdown-list-item (click)="openDisplay($event, pd)"
                                                [iconClass]="'fa-presentation'"
                                                [label]="translateService.instant('_ui_navigate_to')+ ' ' +pd.name"></app-dropdown-list-item>
                    </ng-container>
                </div>
            </div>
        </ng-container>

    </div>

</div>
