export class Sort {
    id: string;
    name: string;
    hasOrderDirection: boolean;
    translation: string;
    sortDirectionTranslation?: string;

    public sortType: string;

    // Sort Type
    public static SortTypeDisplay = 'sort-type-display';
    public static SortTypeColumn = 'sort-type-column';
    public static SortTypeDefault = 'sort-type-default';

    public constructor(
        id: string,
        name: string,
        hasOrderDirection: boolean = false,
        translation: string = null,
        sortType: string = Sort.SortTypeDefault
    ) {
        this.id = id;
        this.name = name;
        this.translation = translation;
        this.hasOrderDirection = hasOrderDirection;
        this.sortType = sortType;
    }
}
