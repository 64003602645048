import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProjectListComponent} from '@app/shared/_ui/lists/project-list/project-list.component';
import {ProjectListModule} from "@app/shared/_ui/lists/project-list/ProjectList.module";

const routes: Routes = [
    {
        path: 'projects/list/:displayMode',
        component: ProjectListComponent
    },
    {
        path: 'projects/list',
        component: ProjectListComponent
    }
];

@NgModule({
  imports: [
      ProjectListModule,
      RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class ProjectsRoutingModule { }
