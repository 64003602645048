import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {User} from "@app/core/models";

export class UsersWithTasks extends BaseOnScreenFilter<User> {

    public name = '_displays_project_details_users_on_screen_filter_with_tasks';
    public iconActive = 'fa-check-circle';
    public iconInactive = 'fa-ban';
    public invertIcon: boolean = false;

    constructor(enabled: boolean) {
        super(enabled);
    }

    protected validate(item: User): boolean {
        return item.tasks?.length > 0;
    }

}
