/**
 * Created by ModelParser
 * Date: 10-02-2024.
 * Time: 09:05.
 */
import {ColumnSettingDefinition} from './definitions/ColumnSettingDefinition';

export class ColumnSetting extends ColumnSettingDefinition {

    constructor(json?: any) {
        super(json);
    }

    public getObject<T>(): T {
        try {
            return JSON.parse(this.value ?? '{}') as T;
        } catch (e) {
            return {} as T;
        }
    }

    public getArray<T>(): T {
        try {
            return JSON.parse(this.value ?? '[]') as T;
        } catch (e) {
            return [] as T;
        }
    }

}
