import {NgModule} from "@angular/core";
import {LoadingIndicatorComponent} from "@app/shared/_ui/loading-indicator/loading-indicator.component";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        LoadingIndicatorComponent,
    ],
    exports: [
        LoadingIndicatorComponent

    ],
    providers: [

    ],
})
export class LoadingIndicatorModule {}
