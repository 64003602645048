/**
 * Created by ModelParser
 * Date: 04-12-2019.
 * Time: 13:20.
 */
import {MilestonesStatusRuleDefinition,} from './definitions/MilestonesStatusRuleDefinition';

export class MilestonesStatusRule extends MilestonesStatusRuleDefinition {

    constructor(json?: any) {
        super(json);
    }

}
