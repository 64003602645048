/**
 * Created by ModelParser
 */
import {DepartmentGroup} from '../DepartmentGroup';
import {User} from '../User';
import {Appointment} from '../Appointment';
import {AppointmentsDepartment} from '../AppointmentsDepartment';
import {Display} from '../Display';
import {DepartmentsDisplay} from '../DepartmentsDisplay';
import {Project} from '../Project';
import {DepartmentsProject} from '../DepartmentsProject';
import {Phase} from '../Phase';
import {Task} from '../Task';
import {ProjectType} from '../ProjectType';
import {DepartmentsProjectType} from '../DepartmentsProjectType';
import {TaskType} from '../TaskType';
import {DepartmentsTaskType} from '../DepartmentsTaskType';
import {DisplaysSetting} from '../DisplaysSetting';
import {DisplayFilter} from '../DisplayFilter';
import {DynamicMenuItem} from '../DynamicMenuItem';
import {Category} from '../Category';
import {CategoriesDepartment} from '../CategoriesDepartment';
import {MilestonePlan} from '../MilestonePlan';
import {DepartmentsDisplayFilter} from '../DepartmentsDisplayFilter';
import {DepartmentsMilestonePlan} from '../DepartmentsMilestonePlan';
import {DisplayFilterUserSetting} from '../DisplayFilterUserSetting';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class DepartmentDefinition extends BaseModel {
    name?: string;
    calculation_norm?: number;
    department_group_id?: number;
    department_group?: DepartmentGroup;
    allow_users_to_enter?: boolean;
    todos_visible?: boolean;
    milestones_visible?: boolean;
    users?: User[];
    appointments?: Appointment[];
    appointments_departments?: AppointmentsDepartment[];
    displays?: Display[];
    departments_displays?: DepartmentsDisplay[];
    projects?: Project[];
    departments_projects?: DepartmentsProject[];
    phases?: Phase[];
    tasks?: Task[];
    project_types?: ProjectType[];
    departments_project_types?: DepartmentsProjectType[];
    task_types?: TaskType[];
    departments_task_types?: DepartmentsTaskType[];
    displays_settings?: DisplaysSetting[];
    display_filters?: DisplayFilter[];
    dynamic_menu_items?: DynamicMenuItem[];
    categories?: Category[];
    categories_departments?: CategoriesDepartment[];
    milestone_plans?: MilestonePlan[];
    departments_display_filters?: DepartmentsDisplayFilter[];
    departments_milestone_plans?: DepartmentsMilestonePlan[];
    display_filter_user_settings?: DisplayFilterUserSetting[];
    selected?: boolean;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.calculation_norm;
            delete this.department_group_id;
            delete this.department_group;
            delete this.allow_users_to_enter;
            delete this.todos_visible;
            delete this.milestones_visible;
            delete this.users;
            delete this.appointments;
            delete this.appointments_departments;
            delete this.displays;
            delete this.departments_displays;
            delete this.projects;
            delete this.departments_projects;
            delete this.phases;
            delete this.tasks;
            delete this.project_types;
            delete this.departments_project_types;
            delete this.task_types;
            delete this.departments_task_types;
            delete this.displays_settings;
            delete this.display_filters;
            delete this.dynamic_menu_items;
            delete this.categories;
            delete this.categories_departments;
            delete this.milestone_plans;
            delete this.departments_display_filters;
            delete this.departments_milestone_plans;
            delete this.display_filter_user_settings;
            delete this.selected;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.calculation_norm != null) {
            this.calculation_norm = data.calculation_norm;
        }
        if (data.department_group_id != null) {
            this.department_group_id = data.department_group_id;
        }
        if (data.department_group != null) {
            this.department_group = new DepartmentGroup(data.department_group);
        }
        if (data.allow_users_to_enter != null) {
            this.allow_users_to_enter = data.allow_users_to_enter;
        }
        if (data.todos_visible != null) {
            this.todos_visible = data.todos_visible;
        }
        if (data.milestones_visible != null) {
            this.milestones_visible = data.milestones_visible;
        }
        if (data.users != null) {
            this.users = data.users.map((i: any) => new User(i));
        }
        if (data.appointments != null) {
            this.appointments = data.appointments.map((i: any) => new Appointment(i));
        }
        if (data.appointments_departments != null) {
            this.appointments_departments = data.appointments_departments.map((i: any) => new AppointmentsDepartment(i));
        }
        if (data.displays != null) {
            this.displays = data.displays.map((i: any) => new Display(i));
        }
        if (data.departments_displays != null) {
            this.departments_displays = data.departments_displays.map((i: any) => new DepartmentsDisplay(i));
        }
        if (data.projects != null) {
            this.projects = data.projects.map((i: any) => new Project(i));
        }
        if (data.departments_projects != null) {
            this.departments_projects = data.departments_projects.map((i: any) => new DepartmentsProject(i));
        }
        if (data.phases != null) {
            this.phases = data.phases.map((i: any) => new Phase(i));
        }
        if (data.tasks != null) {
            this.tasks = data.tasks.map((i: any) => new Task(i));
        }
        if (data.project_types != null) {
            this.project_types = data.project_types.map((i: any) => new ProjectType(i));
        }
        if (data.departments_project_types != null) {
            this.departments_project_types = data.departments_project_types.map((i: any) => new DepartmentsProjectType(i));
        }
        if (data.task_types != null) {
            this.task_types = data.task_types.map((i: any) => new TaskType(i));
        }
        if (data.departments_task_types != null) {
            this.departments_task_types = data.departments_task_types.map((i: any) => new DepartmentsTaskType(i));
        }
        if (data.displays_settings != null) {
            this.displays_settings = data.displays_settings.map((i: any) => new DisplaysSetting(i));
        }
        if (data.display_filters != null) {
            this.display_filters = data.display_filters.map((i: any) => new DisplayFilter(i));
        }
        if (data.dynamic_menu_items != null) {
            this.dynamic_menu_items = data.dynamic_menu_items.map((i: any) => new DynamicMenuItem(i));
        }
        if (data.categories != null) {
            this.categories = data.categories.map((i: any) => new Category(i));
        }
        if (data.categories_departments != null) {
            this.categories_departments = data.categories_departments.map((i: any) => new CategoriesDepartment(i));
        }
        if (data.milestone_plans != null) {
            this.milestone_plans = data.milestone_plans.map((i: any) => new MilestonePlan(i));
        }
        if (data.departments_display_filters != null) {
            this.departments_display_filters = data.departments_display_filters.map((i: any) => new DepartmentsDisplayFilter(i));
        }
        if (data.departments_milestone_plans != null) {
            this.departments_milestone_plans = data.departments_milestone_plans.map((i: any) => new DepartmentsMilestonePlan(i));
        }
        if (data.display_filter_user_settings != null) {
            this.display_filter_user_settings = data.display_filter_user_settings.map((i: any) => new DisplayFilterUserSetting(i));
        }
        if (data.selected != null) {
            this.selected = data.selected;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
