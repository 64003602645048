import {NgModule} from "@angular/core";
import {GlobalSearchComponent} from "@app/shared/_elements/global-search/global-search.component";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbPopoverModule, NgbTooltipModule, NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {AutofocusDirectiveModule} from "@app/directives/AutofocusDirective.module";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        ColorLabelModule,
        TranslateModule,
        NgbTypeaheadModule,
        CardsModule,
        FormsModule,
        AutofocusDirectiveModule,
        LoadingIndicatorModule,
        ScrollingModule,
        NgbPopoverModule,
        ToggleItemModule,
        NgbTooltipModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        GlobalSearchComponent,
    ],
    exports: [
        GlobalSearchComponent

    ],
    providers: [

    ],
})
export class GlobalSearchModule {}
