import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";

export class ProjectCardCell extends BaseCell {

    public constructor(row: BaseRow, column: BaseColumn) {
        super(row, column);
    }

}
