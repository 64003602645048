/**
 * Created by ModelParser
 * Date: 10-01-2022.
 * Time: 12:12.
 */
import {DepartmentsMilestonePlanDefinition} from './definitions/DepartmentsMilestonePlanDefinition';

export class DepartmentsMilestonePlan extends DepartmentsMilestonePlanDefinition {

    constructor(json?: any) {
        super(json);
    }

}
