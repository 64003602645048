/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {UserGroupDefinition} from './definitions/UserGroupDefinition';

export class UserGroup extends UserGroupDefinition {

    constructor(json?: any) {
        super(json);
    }

}
