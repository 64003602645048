import {NgModule} from "@angular/core";
import {ItemDateComponent} from "@app/shared/_elements/item-date/item-date.component";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgbDatepickerModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {AutofocusDirectiveModule} from "@app/directives/AutofocusDirective.module";

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        NgbDatepickerModule,
        AutofocusDirectiveModule,
        NgbTooltipModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ItemDateComponent,
    ],
    exports: [
        ItemDateComponent

    ],
    providers: [

    ],
})
export class ItemDateModule {}
