import {TitleEditorComponent} from "@app/editor/quick-editor/editors/generic/title-editor/title-editor.component";
import {ProjectType} from "@app/core/models";
import {FieldTypes, ReactionTypes} from "@app/constants";
import {Fields as ProjectFields} from "@app/editor/project-editor-loader/Fields";
import {Fields as MilestoneFields} from "@app/editor/milestone-editor-loader/Fields";
import {
    DeadlineListEditorComponent
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/deadline-list-editor.component";
import {
    EditDeadlineListConfiguration
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineListConfiguration";
import {EditTitleConfiguration} from "@app/editor/quick-editor/editors/generic/title-editor/EditTitleConfiguration";
import {
    MilestonePlanSelectEditorComponent
} from "@app/editor/quick-editor/editors/project/milestone-plan-select-editor/milestone-plan-select-editor.component";
import {
    EditMilestonePlanSelectConfiguration
} from "@app/editor/quick-editor/editors/project/milestone-plan-select-editor/EditMilestonePlanSelectConfiguration";
import {DeadlineItem} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/DeadlineItem";
import {EditorInterface} from "@app/editor/quick-editor/EditorInterface";
import {Field as MilestoneField} from "@app/editor/milestone-editor-loader/milestone-editor.service";
import {StatusEditorComponent} from "@app/editor/quick-editor/editors/generic/status-editor/status-editor.component";
import {
    ArchivedEditorComponent
} from "@app/editor/quick-editor/editors/generic/archived-editor/archived-editor.component";
import {
    ReactionListEditorComponent
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/reaction-list-editor.component";
import {
    EditReactionListConfiguration
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionListConfiguration";
import {
    UserListEditorComponent
} from "@app/editor/quick-editor/editors/generic/user-list-editor/user-list-editor.component";
import {
    EditUserListConfiguration
} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserListConfiguration";
import {UserTypeItem} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserTypeItem";
import {
    UseStatusRulesEditorComponent
} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/use-status-rules-editor.component";
import {
    ProjectListEditorComponent
} from "@app/editor/quick-editor/editors/generic/project-list-editor/project-list-editor.component";
import {
    EditProjectListConfiguration
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListConfiguration";
import {
    EditProjectListFilter
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListFilter";
import {TextEditorComponent} from "@app/editor/quick-editor/editors/generic/text-editor/text-editor.component";
import {EditTextConfiguration} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextConfiguration";
import {
    EditArchivedConfiguration
} from "@app/editor/quick-editor/editors/generic/archived-editor/EditArchivedConfiguration";
import {EditStatusConfiguration} from "@app/editor/quick-editor/editors/generic/status-editor/EditStatusConfiguration";
import {
    EditUseStatusRulesConfiguration
} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/EditUseStatusRulesConfiguration";
import {EditTextValidator} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextValidator";
import {EditTitleValidator} from '@app/editor/quick-editor/editors/generic/title-editor/EditTitleValidator';
import {EditUserTypeValidator} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserTypeValidator";
import {
    EditDeadlineTypeValidator
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineTypeValidator";
import {
    EditProjectListValidator
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListValidator";

export default class EnabledEditorsHelper {

    public static Filter(available: string[], enabled: EditorInterface[]): EditorInterface[] {
        return enabled

            // Intersect available and enabled editors
            .filter(enabledEditor => {
                return available.includes(enabledEditor.name);
            })

            // Sort by available editor list
            .sort((a, b) => {
                return available.indexOf(a.name) - available.indexOf(b.name);
            });
    }

    // TODO @martin Udfas igen - var alligevel ikke en god ide
    public static ForMilestone(fields: Map<number, MilestoneField>): EditorInterface[] {
        const editors: EditorInterface[] = [

            // Editors without configurable field
            {
                name: ArchivedEditorComponent.name,
                configuration: new EditArchivedConfiguration()
            },
            {
                name: ReactionListEditorComponent.name,
                configuration: new EditReactionListConfiguration(true, true, true, false, true),
            },
        ];

        fields.forEach((field, key) => {
            if (!field.visible) {
                return;
            }

            switch (key) {
                case MilestoneFields.Title:
                    editors.push({
                        name: TitleEditorComponent.name,
                        configuration: new EditTitleConfiguration(fields.get(MilestoneFields.Title).smartName, false, new EditTitleValidator(true)),
                    });
                    break;
                case MilestoneFields.Status:
                    editors.push({
                        name: StatusEditorComponent.name,
                        configuration: new EditStatusConfiguration()
                    });
                    break;
                case MilestoneFields.Responsible:
                    editors.push({
                        name: UserListEditorComponent.name,
                        configuration: new EditUserListConfiguration(
                            '',
                            [
                                new UserTypeItem(
                                    fields.get(MilestoneFields.Responsible).smartName,
                                    0,
                                    false,
                                    false,
                                    false,
                                    false,
                                    false,
                                    new EditUserTypeValidator(fields.get(MilestoneFields.Responsible).required)
                                )
                            ]
                        ),
                    });
                    break;
                case MilestoneFields.Deadline:
                    editors.push({
                        name: DeadlineListEditorComponent.name,
                        configuration: new EditDeadlineListConfiguration(
                            '',
                            [
                                new DeadlineItem(
                                    fields.get(MilestoneFields.Deadline).smartName,
                                    0,
                                    true,
                                    new EditDeadlineTypeValidator(fields.get(MilestoneFields.Responsible).required)
                                )
                            ]
                        ),
                    });
                    break;
                case MilestoneFields.StatusRules:
                    editors.push({
                        name: UseStatusRulesEditorComponent.name,
                        configuration: new EditUseStatusRulesConfiguration(),
                    });
                    break;
                case MilestoneFields.Projects:
                    editors.push({
                        name: ProjectListEditorComponent.name,
                        configuration: new EditProjectListConfiguration(
                            fields.get(MilestoneFields.Projects).smartName,
                            new EditProjectListFilter(),
                            new EditProjectListValidator(fields.get(MilestoneFields.Projects).required)
                        )
                    });
                    break;
                case MilestoneFields.Note:
                    editors.push({
                        name: TextEditorComponent.name,
                        configuration: new EditTextConfiguration(
                            'notes',
                            'multi',
                            fields.get(MilestoneFields.Note).smartName,
                            'rich',
                            new EditTextValidator(fields.get(MilestoneFields.Note).required),
                        ),
                    });
                    break;
                case MilestoneFields.Risk:
                    editors.push({
                        name: TextEditorComponent.name,
                        configuration: new EditTextConfiguration(
                            'risk',
                            'multi',
                            fields.get(MilestoneFields.Risk).smartName,
                            'rich',
                            new EditTextValidator(fields.get(MilestoneFields.Risk).required),
                        ),
                    });
                    break;
            }
        });

        return editors;
    }

    public static ForProjectType(projectType: ProjectType): EditorInterface[] {
        const milestonePlanSelectEditor = {
            name: MilestonePlanSelectEditorComponent.name,
            configuration: new EditMilestonePlanSelectConfiguration(),
        };

        const editors: EditorInterface[] = [
            milestonePlanSelectEditor,
            {
                name: ArchivedEditorComponent.name,
                configuration: new EditArchivedConfiguration()
            },
        ];

        let hasHandUpField = false;
        let hasStarField = false;
        const hasEmojiField = projectType.project_types_reaction_types?.find(rt => rt.reaction_type_id == ReactionTypes.Emoji)?.visible ?? false;

        projectType.project_fields_project_types
            ?.filter(projectFieldsProjectType => {
                return projectFieldsProjectType.visible && projectFieldsProjectType.project_field?.type == FieldTypes.Editor;
            })
            ?.forEach(projectFieldsProjectType => {
                switch (projectFieldsProjectType.project_field_id) {

                    case ProjectFields.HandUp:
                        hasHandUpField = true;
                        break;
                    case ProjectFields.Star:
                        hasStarField = true;
                        break;

                    case ProjectFields.BasicInformation.Title:
                        editors.push({
                            name: TitleEditorComponent.name,
                            configuration: new EditTitleConfiguration(projectFieldsProjectType.getSmartName(), false, new EditTitleValidator(true))
                        });
                        break;

                    case MilestoneFields.Status:
                        editors.push({
                            name: StatusEditorComponent.name,
                            configuration: new EditStatusConfiguration()
                        });
                        break;

                    case ProjectFields.BasicInformation.Deadlines:
                        const deadlineItems = projectType.project_deadline_types_project_types
                            ?.filter(projectDeadlineTypesProjectType => {
                                return projectDeadlineTypesProjectType.visible && projectDeadlineTypesProjectType.project_deadline_type;
                            })
                            ?.map(projectDeadlineTypesProjectType => {
                                return new DeadlineItem(
                                    projectDeadlineTypesProjectType.project_deadline_type.getSmartName(),
                                    projectDeadlineTypesProjectType.project_deadline_type_id,
                                    false,
                                    new EditDeadlineTypeValidator(projectDeadlineTypesProjectType.required)
                                );
                            });
                        editors.push({
                            name: DeadlineListEditorComponent.name,
                            configuration: new EditDeadlineListConfiguration(
                                projectFieldsProjectType.getSmartName(),
                                deadlineItems
                            ),
                        });
                        break;

                }
            });

        if (hasHandUpField || hasStarField || hasEmojiField) {
            editors.push({
                name: ReactionListEditorComponent.name,
                configuration: new EditReactionListConfiguration(
                    hasHandUpField,
                    hasStarField,
                    hasEmojiField,
                    false,
                    true
                ),
            });
        }

        return editors;
    }

}
