


<label
    *ngIf="(hasProject && showMilestoneField || attachedProjectType.validator.requireMilestone) && showMilestoneFieldForProject"
>{{attachedProjectType.projectType.name | translate}}</label>

<ul class="list-group">
    <li *ngIf="attachedProjectType && !attachedProjectType.validator?.isValid()"
        class="list-group-item d-flex justify-content-between flex-row align-items-center validation-error"
    >
        <app-validation-warning-list [inline]="true" [validatorError]="attachedProjectType.validator"></app-validation-warning-list>
    </li>
    <li class="list-group-item" >

        <div class="d-flex project-item-wrapper "
             [class.list-group-item]="(hasProject && showMilestoneField || attachedProjectType.validator.requireMilestone) && showMilestoneFieldForProject"
             [class.show-milestone-group]="showMilestoneField"
             [class.rounded]="(hasProject && showMilestoneField || attachedProjectType.validator.requireMilestone) && showMilestoneFieldForProject"
        >

            <div *ngIf="showProjectField" class="flex-grow-1"
                 [class.w-50]="(hasProject && showMilestoneField || attachedProjectType.validator.requireMilestone) && showMilestoneFieldForProject"
            >
                <div class="d-flex justify-content-between align-items-center">
                    <label>{{attachedProjectType.projectType.name | translate}}</label>
                </div>
                <app-project-search
                    [modelType]="[attachedProjectType.projectType.id]"
                    [_internalValue]="attachedProjectType.project ? [attachedProjectType.project] : []"
                    [milestoneRequiredOnProject]="attachedProjectType.validator.requireMilestone"
                    [showArchived]="false"
                    [allowCreation]="false"
                    [typeLabel]="(attachedProjectType.projectType.name | translate)"
                    [multiple]="false"
                    (itemAdded)="setProject($event)"
                    (itemRemoved)="setProject(undefined)"
                />
            </div>

            <!-- Tjek for om milepæl skal vises (bruger Task.Fields.milestone) -->
            <div
                *ngIf="(hasProject && showMilestoneField || attachedProjectType.validator.requireMilestone) && showMilestoneFieldForProject "
                class="d-flex flex-column ms-1 w-50">
                <label>
                    <span>{{'_ui_attached_milestone' | translate}}</span>
                    <span *ngIf="attachedProjectType.validator.requireMilestone" class="text-danger">*</span>
                </label>
                <app-milestone-search
                    [_internalValue]="attachedProjectType.milestone"
                    [projectId]="attachedProjectType.project?.id"
                    [milestoneRequiredOnProject]="attachedProjectType.validator.requireMilestone"
                    (itemAdded)="setMilestone($event)"
                    (itemRemoved)="setMilestone(undefined)"
                    [allowCreation]="false"
                    [multiple]="false">
                </app-milestone-search>
            </div>
        </div>


    </li>
</ul>

