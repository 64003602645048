import {BaseYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/BaseYearWheelItem";
import {PhasesProject, Project} from "@app/core/models";
import {YearWheelColumnConfiguration} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration";

export class ProjectYearWheelItem extends BaseYearWheelItem<Project>{

    endDate: Date;

    constructor(configuration: YearWheelColumnConfiguration,
                item: Project,

                xPosInPixels: number,

                width: number,
                startDate: Date,
                endDate: Date
) {
        super();

        this.visible = true;

        this.configuration = configuration;
        this.item = item;

        this.left = xPosInPixels;

        this.width = width;
        this.startDate = startDate;
        this.endDate = endDate;
    }

}
