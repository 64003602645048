import {WidthSynchronizer} from "@app/editor/quick-editor/editors/WidthSynchronizer";
import {EventEmitter} from "@angular/core";

export abstract class BaseColumnConfiguration {

    // Width
    public widthSynchronizer = new WidthSynchronizer();
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    savedWidth?: number; // User-preferred width. Try to respect.

    // Resize
    canResize: boolean; // Whether the column can be resized manually by the user
    canAutoResize: boolean; // Whether the column can automatically resize to fill extra space

    // Events
    onChangeEvent = new EventEmitter();
    onResizeEndEvent = new EventEmitter();

    protected constructor(minWidth: number, canResize: boolean, canAutoResize: boolean) {
        this.minWidth = minWidth;
        this.canResize = canResize;
        this.canAutoResize = canAutoResize;
    }

    public setWidth(value: number): number {
        let newWidth = Math.max(value, this.minWidth); // Enforce minWidth
        if (this.maxWidth) {
            newWidth = Math.min(newWidth, this.maxWidth); // Enforce maxWidth
        }
        if (newWidth != this.width) {
            const diff = newWidth - (this.width ?? this.minWidth);
            this.width = newWidth;
            this.widthSynchronizer.setValue(newWidth);
            this.onChangeEvent.emit();
            return diff;
        }
        return 0;
    }

    public onResizeEnd() {
        this.onResizeEndEvent.emit();
    }

}
