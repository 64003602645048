export class FieldItem {

    visible: boolean;
    index: number;
    name?: string;

    constructor(visible: boolean, index: number, name?: string) {
        this.visible = visible;
        this.index = index;
        this.name = name;
    }

}
