import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditReactionList} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionList";
import {
    EditReactionListConfiguration
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionListConfiguration";
import {Milestone, Project, Task, Todo} from "@app/core/models";
import {ReactionsTypeInterface} from "@app/shared/_ui/reactions/ReactionsTypeInterface";
import {ReactionTypes} from "@app/constants";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";

@Component({
    selector: 'app-reaction-list-editor',
    templateUrl: './reaction-list-editor.component.html',
    styleUrls: ['./reaction-list-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactionListEditorComponent extends BaseEditor<EditReactionList> {
    protected eventFieldName?: string;

    @Input() model: (Task|Project|Milestone|Todo)&EditReactionList&HasEventGenerator&BaseModel;
    @Input() configuration: EditReactionListConfiguration;
    @Input() editorEvents: EditorEvents;

    public reactionsTypeInterfaces: ReactionsTypeInterface[] = [
        {
            reaction_type_id: ReactionTypes.Emoji,
            visible: true,
        }
    ];

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    protected render() {
        this.detectChanges();
    }

    public save() {
    }


}
