import {BaseComparison} from "@app/shared/_ui/lists/BaseComparison";
import {HasUsers} from "@app/interfaces/HasUsers";

export class UsersComparison extends BaseComparison {

    private readonly typeId?: number;

    constructor(typeId?: number) {
        super();
        this.typeId = typeId
    }

    public compare(a: HasUsers, b: HasUsers): number {
        const aUsers = (this.typeId ? a.findUsersByTypeId(this.typeId) : a.getUsers())
            .sort((a, b) => a.name.localeCompare(b.name));
        const bUsers = (this.typeId ? b.findUsersByTypeId(this.typeId) : b.getUsers())
            .sort((a, b) => a.name.localeCompare(b.name));
        const aName = aUsers.length ? aUsers[0].name : '';
        const bName = bUsers.length ? bUsers[0].name : '';
        return aName.localeCompare(bName);
    }

}
