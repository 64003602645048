import {fromEvent as observableFromEvent, Subject} from 'rxjs';

import {sampleTime, takeUntil} from 'rxjs/operators';
import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output} from '@angular/core';

@Directive({
    selector: '[appScrollDetector]'
})
export class ScrollDetectorDirective  implements AfterViewInit, OnDestroy {

    destroy$: Subject<boolean> = new Subject<boolean>();
    scrollElement: Element;

    @Input('offsetTop') offsetTop = 0;
    @Input('offsetBottom') offsetBottom = 0;

    @Output('onReachTop') onReachTop: EventEmitter<any> = new EventEmitter<any>();
    @Output('onReachBottom') onReachBottom: EventEmitter<any> = new EventEmitter<any>();
    @Output('onScroll') onScroll: EventEmitter<any> = new EventEmitter<any>();

    constructor(private element: ElementRef, private zone: NgZone) {}

    ngAfterViewInit() {
        // console.log('ScrollDetectorDirective on DataTable');
        try {
            this.zone.runOutsideAngular(() => {
                // find datatable-body
                this.scrollElement = this.element.nativeElement.querySelector('.datatable-body');
                //console.log('ScrollDetectorDirective : Find child : ', this.scrollElement);
                observableFromEvent(this.element.nativeElement.querySelector('.datatable-body'), 'scroll', {
                    passive: true
                })
                    .pipe(
                        sampleTime(100),
                        takeUntil(this.destroy$)
                    )
                    .subscribe(() => {
                        const scrollTop: number = this.scrollElement.scrollTop;
                        const scrollHeight: number = this.scrollElement.scrollHeight;
                        const clientHeight: number = this.scrollElement.clientHeight;

                        // console.log('ScrollDetectorDirective : scrollTop : ', scrollTop, 'clientHeight : ', clientHeight, scrollHeight);

                        let emitted: boolean = false;


                        if (scrollTop <= this.offsetTop) {
                            // console.log('ScrollDetectorDirective : top reached');
                            this.zone.run(() => this.onReachTop.emit(scrollTop));
                            emitted = true;
                        }

                        if (scrollHeight - (scrollTop + clientHeight) <= this.offsetBottom) {
                            this.zone.run(() => this.onReachBottom.emit(scrollTop));
                            // console.log('ScrollDetectorDirective : bottom reached');
                            emitted = true;
                        }
                        if (!emitted) {
                            this.zone.run(() => this.onScroll.emit(scrollTop));
                        }

                    });
            });
        }catch(e){
            console.error('error : ', e);
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}