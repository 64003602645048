import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {ProjectsDeadline, ProjectsUser} from "@app/core/models";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {
    ProjectDeadlineListColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/ProjectDeadlineListColumn";

export class ProjectDeadlineListCell extends BaseCell {

    public projectsDeadlines: ProjectsDeadline[];

    public constructor(row: Row, column: ProjectDeadlineListColumn) {
        super(row, column);

        const deadlines: ProjectsDeadline[] = [];
        column.getDeadlineTypes()
            ?.forEach(deadlineTypeId => {
                const deadline = row.projectCard.item.findProjectsDeadlineByType(deadlineTypeId);
                if (deadline) {
                    deadlines.push(deadline);
                }
            });
        this.projectsDeadlines = deadlines;
    }

}
