import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {MilestonePresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/MilestonePresetType";

export class MilestoneStatusPresetGenerator implements CreatePresetGenerator {

    private readonly statusId?: number;
    private readonly statusIdHandler?: () => number;

    constructor(
        statusIdOrHandler: number | (() => number)
    ) {
        if (typeof statusIdOrHandler == "number") {
            this.statusId = statusIdOrHandler;
        } else {
            this.statusIdHandler = statusIdOrHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: MilestonePresetTypes.Status,
            payload: {
                statusId: this.statusId ?? this.statusIdHandler(),
            }
        };
    }

}
