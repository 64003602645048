import {BaseComparison} from "@app/shared/_ui/lists/BaseComparison";
import {HasStars} from "@app/interfaces/HasStars";

export class StarredComparison extends BaseComparison {

    public compare(a: HasStars, b: HasStars): number {
        return (a.num_stars ?? 0) - (b.num_stars ?? 0);
    }

}
