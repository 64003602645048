<app-toggle-item
    [interactive]="true"
    [labelClass]="'faux-label'"
    [icon]="'fa-check-circle'"
    [iconInactive]="'fa-circle'"
    [iconColorActive]="'text-success'"
    [iconColorInactive]="'text-black-50'"
    [active]="value"
    [label]="('_ui_use_status_rules' | translate)"
    (onToggle)="onToggle()"
/>
