import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";
import {CSVExporter} from "@app/export/csv/CSVExporter";
import {
    ProjectDeadlineDifferenceColumn
} from "@app/pages/displays/display-projects/Columns/ProjectDeadlineDifferenceColumn";
import * as moment from "moment/moment";

export class ProjectDeadlineDifferenceCell extends GenericCell {

    public column: ProjectDeadlineDifferenceColumn;

    public exportCSV(secondary?: any): CSVCellValue {
        const aDate = this.row.project.item.findDeadlineByTypeId(this.column.getFromDeadlineTypeId())?.getDate();
        const bDate = this.row.project.item.findDeadlineByTypeId(this.column.getToDeadlineTypeId())?.getDate();
        if (aDate && bDate) {

            moment.locale('da');
            const difference: moment.Duration = moment.duration(moment(aDate).diff(moment(bDate))).abs();

            // To deal with summer/winter time
            if (difference.get('hour') == 23) {
                difference.add(1, 'hour');
            }

            return Math.floor(difference.asDays()).toString();
        } else {
            return '';
        }
    }

}
