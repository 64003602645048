<div
    class="d-flex flex-row flex-grow-1 position-relative w-100 align-items-center"
    style="max-width: calc(100vw -  720px);"
    [ngbPopover]="popoverContent"
    [autoClose]="'outside'"
    placement="auto"
    triggers="manual"
    container="body"
    #popover="ngbPopover">
    <div class="cursor-pointer pt-0 w-100 ps-1"
         (click)="$event.stopPropagation(); popover.isOpen() ? popover.close() : popover.open();">
        <div
            class="d-flex flex-column align-items-center justify-content-center text-center text-nowrap text-truncate w-100 no-caret">
            <span class="flex-fill w-100 d-flex align-items-center justify-content-center text-center">
                <ng-container *ngIf="title">
                    <i class="fal fa-chevron-circle-down me-1 "
                       [ngbTooltip]="('_ui_header_choose_filter' | translate)"
                       [autoClose]="true"
                       style="transform: translateY(1px) scale(.95);"
                    ></i>
                    <span class="ellipsis title-block "><span *ngIf="titlePrefix">{{titlePrefix}}: </span>{{title}}</span>
                </ng-container>
                <app-loading-indicator [showLogo]="false" *ngIf="!title"></app-loading-indicator>
            </span>
        </div>
    </div>
</div>

<!-- Popover dialog content -->
<ng-template #popoverContent>
    <div class="d-block w-100 mb-1 m-0"  *ngIf="false">{{'_ui_header_choose_filter' | translate}}</div>
    <app-header-title-filter-group
        [shellPageData]="shellPageData"
        (onCloseEvent)="popover.close()"
    ></app-header-title-filter-group>
</ng-template>
