import {NgModule} from '@angular/core';
import {ProjectDeleteDialogComponent} from '@app/shared/_modals/project-delete-dialog/project-delete-dialog.component';
import {ColorItemModule} from '@app/shared/_ui/color-item/ColorItem.module';
import {NgxModalDraggableDirectiveModule} from '@app/directives/NgxModalDraggableDirective.module';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {ToggleItemModule} from '@app/shared/_elements/toggle-item/ToggleItem.module';
import {LoadingIndicatorModule} from '@app/shared/_ui/loading-indicator/LoadingIndicator.module';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        ColorItemModule,
        NgxModalDraggableDirectiveModule,
        NgbTooltipModule,
        TranslateModule,
        ToggleItemModule,
        LoadingIndicatorModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectDeleteDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class ProjectDeleteDialogModule {}
