/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {LoginAttemptDefinition} from './definitions/LoginAttemptDefinition';

export class LoginAttempt extends LoginAttemptDefinition {

    constructor(json?: any) {
        super(json);
    }

}
