import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ProjectType, Task, TaskType} from "@app/core/models";
import {Field} from "@app/editor/task-editor-loader/task-editor.service";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {ValidatorCollection} from "@app/editor/Validation/ValidatorCollection";
import {Fields} from "@app/editor/task-editor-loader/Fields";
import {
    EditDepartmentPickerConfiguration
} from "@app/editor/quick-editor/editors/generic/department-picker-editor/EditDepartmentPickerConfiguration";
import {
    EditDepartmentPickerFilter
} from "@app/editor/quick-editor/editors/generic/department-picker-editor/EditDepartmentPickerFilter";
import {
    EditTagListConfiguration
} from "@app/editor/quick-editor/editors/generic/tag-list-editor/EditTagListConfiguration";
import {AttachedProjectType} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-tabs/task-editor-tab-attach-to/attached-project-type/AttachedProjectType";
import {
    AttachedProjectTypeValidator
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-tabs/task-editor-tab-attach-to/attached-project-type/AttachedProjectTypeValidator";

@Component({
    selector: 'app-task-editor-tabs-attach-to',
    templateUrl: './task-editor-tabs-attach-to-component.html',
    styleUrls: ['./task-editor-tabs-attach-to.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskEditorTabsAttachToComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item: Task;
    @Input() taskType: TaskType;
    @Input() fields: Map<number, Field>;
    @Input() projectTypes: ProjectType[];
    @Input() isSelectedTab: boolean;
    @Output() validatorChange = new EventEmitter<ValidationErrorInterface>();

    // Bindings to view
    public isReady = false;

    // Bindings to view: Attached Project Types
    public showAttachedProjectTypes = false;
    public attachedProjectTypes: AttachedProjectType[];

    // Bindings to view: Departments
    public showDepartmentPickerEditor = false;
    public departmentPickerEditorConfiguration: EditDepartmentPickerConfiguration;

    // Bindings to view: Tags
    public showTagListEditor = false;
    public tagListEditorConfiguration: EditTagListConfiguration;

    // Data
    private validator: ValidationErrorInterface;

    constructor(
        private cd: ChangeDetectorRef,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.setupEditors();
        this.setupValidator();
    }

    private setupEditors() {
        const attachedProjectTypes: AttachedProjectType[] = [];
        this.projectTypes.forEach(projectType => {
            const projectTypesTaskType = this.taskType.getProjectTypesTaskType(projectType.id);

            // Vis projekt såfremt typen er synlig eller der allerede er et projekt tilknyttet (og at projekttypen findes på opgavetypen)
            // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1101
            const attachedProjectsWithType = this.item.projects?.find(project => project.project_type_id == projectType.id);
            if (projectTypesTaskType?.visible || attachedProjectsWithType) {
                const attachedProjectType = new AttachedProjectType(
                    projectType,
                    new AttachedProjectTypeValidator(projectTypesTaskType?.require_milestone ?? false),
                    attachedProjectsWithType
                );

                if (attachedProjectType.project?.milestones?.length) {
                    const milestoneIds = attachedProjectType.project.milestones.map(milestone => milestone.id);

                    // Begrænset til én milepæl
                    attachedProjectType.milestone = this.item.milestones?.find(milestone => milestoneIds.includes(milestone.id));
                }
                attachedProjectTypes.push(attachedProjectType);
            }
        });
        this.attachedProjectTypes = attachedProjectTypes;
        this.showAttachedProjectTypes = this.isFieldVisible(Fields.AttachTo.Project)
            || this.isFieldVisible(Fields.AttachTo.Milestone)
            || attachedProjectTypes.length > 0;

        this.showDepartmentPickerEditor = this.isFieldVisible(Fields.AttachTo.Departments);
        this.departmentPickerEditorConfiguration = new EditDepartmentPickerConfiguration(
            this.fields.get(Fields.AttachTo.Departments)?.smartName ?? '',
            new EditDepartmentPickerFilter(this.taskType.departments?.map(department => department.id!) ?? []),
        );

        this.showTagListEditor = this.isFieldVisible(Fields.AttachTo.Tags);
        this.tagListEditorConfiguration = new EditTagListConfiguration(
            this.fields.get(Fields.AttachTo.Tags)?.smartName ?? ''
        );
    }

    private setupValidator() {
        // Collect validators
        this.validator = new ValidatorCollection([
            ...this.attachedProjectTypes.map(attachedProjectType => attachedProjectType.validator),
        ]);
        this.validatorChange.emit(this.validator);

        this.isReady = true;
    }

    // <editor-fold desc="View actions">

    public isFieldVisible(fieldId: number): boolean {
        return this.fields.has(fieldId) && this.fields.get(fieldId).visible;
    }

    // </editor-fold>

    // <editor-fold desc="Dialogs">

    // </editor-fold>

    protected readonly Fields = Fields;
}
