import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CardComponent} from "@app/shared/_ui/cards/card.component";
import {CardConfiguration} from '../../CardConfiguration';
import {CardItem} from '../../CardItem';
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";

@Component({
    selector: 'app-card-office-365-event',
    templateUrl: './card-office-365-event.component.html',
    styleUrls: ['../../card.component.scss']
})
export class CardOffice365EventComponent extends CardComponent implements OnInit {

    constructor() {
        super();
        this.initialized = true;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    @Input() configuration: CardConfiguration;
    @Input() item: CardItem<any>;
    @Input() listConfiguration: ListConfiguration;
    @Input() model: any;
    @Output() onCardClickEvent = new EventEmitter();
    @Output() onRemove = new EventEmitter();
    @Input() removable = false;
    @Input() tooltip: string;
    @Input() editable = false;
    @Input() interactive = true;
    @ViewChild('cardContainer') cardContainer: ElementRef;

}
