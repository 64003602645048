export class TableColumns {

    public static MilestoneCard = 'milestone-card';

    public static TaskEdit = 'task-edit';

    public static TaskList_All = 'task-list-all';

    public static MilestoneEdit = 'milestone-edit';
    public static MilestoneYearWheel = 'milestone-year-wheel'

    public static TaskEdit_Actions = 'task-edit-actions';
    public static TaskEdit_Card = 'task-edit-card';
    public static TaskEdit_Categories = 'task-edit-categories';
    public static TaskEdit_Deadline = 'task-edit-deadline';
    public static TaskEdit_Estimate = 'task-edit-estimate';
    public static TaskEdit_Link = 'task-edit-link';
    public static TaskEdit_Notes = 'task-edit-notes';
    public static TaskEdit_Reactions = 'task-edit-reactions';
    public static TaskEdit_Status = 'task-edit-status';
    public static TaskEdit_Title = 'task-edit-title';
    public static TaskEdit_Who = 'task-edit-who';

}
