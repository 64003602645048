import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-tasks/Columns/TaskEditColumn";
import {
    BaseEditorTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/EditorTableColumns/BaseEditorTableColumn";
import {TextColumnConfiguration} from "@app/editor/quick-editor/columns/generic/text-column/TextColumnConfiguration";
import {
    EditorWithColumnInterface
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/EditorWithColumnInterface";
import {
    EditDeadlineListConfiguration
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineListConfiguration";
import {DeadlineItem} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/DeadlineItem";
import {
    EditDeadlineTypeValidator
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineTypeValidator";
import {
    DeadlineListEditorComponent
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/deadline-list-editor.component";
import {TextColumnComponent} from "@app/editor/quick-editor/columns/generic/text-column/text-column.component";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns";

export class TaskEdit_Deadline_TableColumn extends BaseEditorTableColumn {

    public identifier = TableColumns.TaskEdit_Deadline;

    public column: TaskEditColumn;
    public minWidth = 105;
    public maxWidth = 105;
    public columnConfiguration: TextColumnConfiguration;
    public editorWithColumn: EditorWithColumnInterface;

    constructor(column: TaskEditColumn) {
        super(column);
        this.name = '_displays_project_details_tasks_column_task_edit_deadline';

        this.columnConfiguration = new TextColumnConfiguration(
            '_displays_project_details_tasks_column_task_edit_deadline',
            false,
            false
        );
        this.columnConfiguration.savedWidth = this.width > 0 ? this.width : undefined;
        this.columnConfiguration.minWidth = this.minWidth;
        this.columnConfiguration.maxWidth = this.maxWidth;
        this.columnConfiguration.canAutoResize = false;
        this.columnConfiguration.onResizeEndEvent.subscribe(() => {
            this.width = this.columnConfiguration.width;
        });

        const editorConfiguration = new EditDeadlineListConfiguration(
            '',
            [
                new DeadlineItem(
                    '',
                    column.getTaskDeadlineType(),
                    false,
                    new EditDeadlineTypeValidator(false)
                ),
            ]
        );
        editorConfiguration.widthSynchronizer = this.columnConfiguration.widthSynchronizer;

        this.editorWithColumn = {
            name: DeadlineListEditorComponent.name,
            columnName: TextColumnComponent.name,
            columnConfiguration: this.columnConfiguration,
            configuration: editorConfiguration,
        };
    }

}
