export class ColumnTypes {

    public static ProjectCard = 'project-card';
    public static CaseAmountInput = 'case-amount-input';
    public static GoalAmountInput = 'goal-amount-input';
    public static PlannedValue = 'planned-value';
    public static AchievedValue = 'achieved-value';
    public static Days = 'days';
    public static Week = 'week';
    public static CaseText = 'case-text';
    public static GoalText = 'goal-text';
    public static AppointmentList = 'appointment-list'

}
