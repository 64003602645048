<app-editor-switcher
    *ngIf="isReady"
    [style.width.px]="width"
    [attr.data-type]="editor.name"
    class="editor-child-wrapper-item d-block p-1"
    [model]="cardItem.item"
    [card]="cardItem"
    [name]="editor.name"
    [configuration]="editor.configuration"
    [listDragInterface]="listDragInterface"
></app-editor-switcher>
