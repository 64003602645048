/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ProjectsTagDefinition} from './definitions/ProjectsTagDefinition';

export class ProjectsTag extends ProjectsTagDefinition {

    constructor(json?: any) {
        super(json);
    }

}
