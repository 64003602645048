<ng-container>
    <div class="d-flex flex-row">
        <ul class="list-unstyled d-flex align-items-center flex-wrap  m-0">
            <li class=""
                [ngbTooltip]="popover && popover.isOpen() ? null : ('_ui_columns_tooltip' | translate)"
                [placement]="['left', 'auto']"
                container="body"
                placement="bottom"
            >
            <span class="fal fa-sliders-h cursor-pointer position-relative"
                  [ngbPopover]="popoverContent"
                  [popoverClass]="'popover-wrapper'"
                  [autoClose]="'outside'"
                  [placement]="['bottom','auto']"
                  triggers="manual"
                  container="body"
                  (click)="popover.open()"
                  #popover="ngbPopover"
            >
            </span>
            </li>
        </ul>
    </div>

    <ng-template #popoverContent>
        <div class="items-container d-flex align-items-center w-100 justify-content-center flex-column"
             style="min-width: 300px;">

            <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>

            <ng-container *ngIf="groups">
                <div class="d-flex flex-row align-items-center w-100 pb-1 ">
                    <h4 class="m-0  flex-grow-1 rounded d-flex flex-wrap flex-column"
                        [class.border-light]="descriptionExpanded"
                        [ngbPopover]="popoverDescription"
                        triggers="mouseenter:mouseleave"
                        container="body"
                    >
                        <div class="d-flex align-items-center popover-title cursor-pointer w-100">
                            <span>{{ ('_ui_columns_tooltip' | translate) }}</span>

                        </div>


                    </h4>

                    <ng-template #popoverDescription>
                        <div class="p-2 callout" style="max-width: 300px;">
                            <p>{{ '_ui_columns_description' | translate }}</p>
                        </div>
                    </ng-template>

                    <div class="d-flex ms-auto">
                        <div class="d-flex ms-auto reset-panel-buttons">
                            <span (click)="popover.close();activateGroups();"
                                  [class.disabled]="!hasGroupChange"
                                  class="cursor-pointer btn btn-sm btn-success">
                            <i class="fa-duotone fa-circle-check"></i>
                                {{ '_ui_apply_selection' | translate }}</span>
                        </div>
                    </div>
                </div>
            </ng-container>

            <app-loading-indicator *ngIf="!groups" [showLogo]="false"
                                   class="align-self-center justify-self-center"></app-loading-indicator>


            <ng-container *ngIf="groups">
                <div class="tabs-container mb-1 scroll-y w-100"
                     [attr.data-items]="groups.length"
                >
                    <ul ngbNav
                        #nav="ngbNav"
                        [(activeId)]="activeTab"

                        class="nav-tabs categories tabs-editor p-0 m-0">
                        <ng-container *ngFor="let group of groups; let i = index;">
                            <li [ngbNavItem]="i" class=" flex-grow-1"
                                [class.active]="i == activeTab"
                            >
                                <a
                                    ngbNavLink
                                    [ngbTooltip]="group.name"
                                    [openDelay]="800"
                                >
                                <span class="group-name ellipsis" style="max-width: 120px;">

                                {{ group.name }}
                                </span>
                                </a>
                                <ng-template ngbNavContent>
                                    <app-filter-column [shellColumnGroup]="group"
                                                       (onGroupSaved)="popover.close();"
                                                       (onGroupUpdated)="onGroupChangeEvent()"
                                    ></app-filter-column>
                                </ng-template>
                            </li>
                        </ng-container>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="categories"></div>
                </div>
            </ng-container>
        </div>

    </ng-template>

</ng-container>
