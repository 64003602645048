/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {AppointmentsDepartmentDefinition} from './definitions/AppointmentsDepartmentDefinition';

export class AppointmentsDepartment extends AppointmentsDepartmentDefinition {

    constructor(json?: any) {
        super(json);
    }

}
