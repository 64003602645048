/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ArchivedDefinition} from './definitions/ArchivedDefinition';
import {AppInjector} from '@app/services/app-injector.service';
import {UsersService} from '@app/services/users.service';

export class Archived extends ArchivedDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static create(): Archived {
        let item = new Archived();
        item.id = 0;
        item.user_id = AppInjector.getInjector().get(UsersService).user.id;
        return item;
    }

}
