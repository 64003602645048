import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";

export class EditPhasesProjectConfiguration extends BaseEditorConfiguration {

    public label: string;
    public showPhases: boolean;

    constructor(label: string, showPhases: boolean) {
        super();
        this.label = label;
        this.showPhases = showPhases;
    }

}
