import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {TableColumns} from "@app/pages/displays/display-projects/TableColumns";
import {ProjectPhaseListColumn} from "@app/pages/displays/display-projects/Columns/ProjectPhaseListColumn";

export class ProjectPhaseListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.ProjectPhaseList;
    public column: ProjectPhaseListColumn;

}
