import {NgModule} from '@angular/core';
import {AboutComponent} from "@app/pages/about/about.component";
import {TranslateModule} from "@ngx-translate/core";
import {SettingsEditorModule} from "@app/editor/settings-editor/settings-editor.module";
import {CommonModule} from "@angular/common";
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {LocalizedDatePipeModule} from '@app/pipes/LocalizedDatePipe.module';
import {UserWithIconModule} from '@app/shared/_elements/user-with-icon/UserWithIcon.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        SettingsEditorModule,
        NgbTooltipModule,
        LocalizedDatePipeModule,
        UserWithIconModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        AboutComponent
    ]
})
export class AboutModule {
}
