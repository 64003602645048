/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ImportColumnDefinition} from './definitions/ImportColumnDefinition';

export class ImportColumn extends ImportColumnDefinition {

    constructor(json?: any) {
        super(json);
    }

}
