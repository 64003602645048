export class TableColumns {

    public static ProjectCard = 'project-card';

    public static FollowingMilestoneList = 'following-milestone-list';

    public static TaskListNextMilestone = 'task-list-next-milestone';
    public static TaskListAll = 'task-list-all';
    public static TaskListWithoutMilestone = 'task-list-without-milestone';

    public static AppointmentList = 'appointment-list';

    public static TodoList = 'todo-list';

    public static YearWheel = 'year-wheel';

    public static ProjectCategoryList = 'project-category-list';

    public static ProjectPhaseList = 'project-phase-list';

}
