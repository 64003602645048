import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {BaseColumnConfiguration} from "@app/editor/quick-editor/columns/BaseColumnConfiguration";

@Component({
    selector: 'app-column-switcher',
    templateUrl: './column-switcher.component.html',
    styleUrls: ['./column-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnSwitcherComponent extends BaseComponent {

    @Input() name: string;
    @Input() configuration: BaseColumnConfiguration;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

}
