import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit,} from '@angular/core';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ShellColumnService} from "@app/services/ShellColumnService/shell-column.service";
import {ShellColumnGroup} from "@app/services/ShellColumnService/ShellColumnGroup";
import {DisplayTabOrder} from "@app/constants";

@Component({
    selector: 'app-filter-column-selector',
    templateUrl: './filter-column-selector.component.html',
    styleUrls: ['./filter-column-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterColumnSelectorComponent extends BaseComponent implements OnInit {

    // UI
    public activeTab = 0;
    public groups?: ShellColumnGroup[];
    public hasGroupChange = false;
    public groupsAreNotDefault = false;
    public descriptionExpanded: boolean = false;

    constructor(private cd: ChangeDetectorRef,
                private filterColumnService: ShellColumnService) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        this.subscribe(this.filterColumnService.onColumnGroupChangeEvent.subscribe((groups: ShellColumnGroup[]) => {
            this.render(groups);
        }));
        this.render(this.filterColumnService.getAllGroups());
    }

    private render(groups: ShellColumnGroup[]) {
        groups.sort((a, b) => DisplayTabOrder.GetIndex(a.display.display_type_id) - DisplayTabOrder.GetIndex(b.display.display_type_id));

        if (groups.length > 0) {
            this.groups = groups;
            if (this.activeTab >= groups.length) {
                this.activeTab = groups.length - 1;
            }
        } else {
            this.groups = undefined;
        }
        this.detectChanges();
    }

    activateGroups() {
        this.groups.forEach(group => group.triggerUpdateEvent());
        this.onGroupChangeEvent();
        this.groupsAreNotDefault = true;
        this.detectChanges();
    }

    onGroupChangeEvent() {
        this.hasGroupChange = this.groups.find(group => group.changed) != null;
    }

    reset() {
        // Apply initial columns?
        this.groupsAreNotDefault = false;
        this.groups.forEach((shellColumnGroup)=>{
            shellColumnGroup.triggerResetEvent();
        })
    }

    toggleDescription(){
        this.descriptionExpanded = !this.descriptionExpanded;
        this.detectChanges();
    }
}
