/**
 * Created by ModelParser
 * Date: 09-11-2020.
 * Time: 15:55.
 */
import {ApiAccessLogDefinition} from './definitions/ApiAccessLogDefinition';

export class ApiAccessLog extends ApiAccessLogDefinition {

    constructor(json?: any) {
        super(json);
    }

}
