import {NgModule} from "@angular/core";
import {
    AttachedProjectTypeComponent
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-tabs/task-editor-tab-attach-to/attached-project-type/attached-project-type.component";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {ProjectSearchModule} from "@app/shared/_forms/search/project-search/ProjectSearch.module";
import {MilestoneSearchModule} from "@app/shared/_forms/search/milestone-search/MilestoneSearch.module";
import {CommonModule} from "@angular/common";
import {
    ValidationWarningListModule
} from '@app/editor/base-editor-v3/components/validation-warning-list/validation-warning-list.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        ProjectSearchModule,
        MilestoneSearchModule,
        ValidationWarningListModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        AttachedProjectTypeComponent,
    ],
    exports: [
        AttachedProjectTypeComponent

    ],
    providers: [

    ],
})
export class AttachedProjectTypeModule {}
