import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {LocalizedDatePipe} from '@app/pipes/localized-date.pipe';
import {PhasesProjectYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/PhasesProjectYearWheelItem";
import {PhasesProject} from "@app/core/models";

@Component({
    selector: 'app-year-wheel-item-display-phase',
    templateUrl: './year-wheel-item-display-phase.component.html',
    styleUrls: ['./year-wheel-item-display-phase.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalizedDatePipe,
    ],
})
export class YearWheelItemDisplayPhaseComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() item: PhasesProjectYearWheelItem;

    // Bindings to view
    @ViewChild(NgbPopover, {static: false}) popover: NgbPopover;
    public title: string;
    public color: string;
    public className: string;

    constructor(private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
        this.cdr = this.changeDetectorRef;
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['item'] != null) {
            const pp = this.item.item as PhasesProject;
            this.title = pp.phase.name;
            this.color = pp.phase.color ? pp.phase.color.value : '#0073dd';
            this.className = 'phasesProject';
            this.detectChanges();
        }
    }

}
