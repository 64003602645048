import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {MultiListConfiguration} from "@app/shared/_ui/lists/multi-list/MultiListConfiguration";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {CardTaskConfiguration} from "@app/shared/_ui/cards/medium/card-task/card-task-configuration";
import {SoftThisWeekColumn} from "@app/pages/displays/dashboard/dashboard-calendar/Columns/SoftThisWeekColumn";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";
import {TableColumns} from "@app/pages/displays/dashboard/dashboard-calendar/TableColumns";

export class SoftThisWeekTableColumn extends BaseTableColumn {

    public identifier = TableColumns.SoftThisWeek;
    public dateStart: Date = new Date();
    public dateEnd: Date = new Date();

    public listConfiguration = new MultiListConfiguration();
    public taskListConfiguration = new TaskListConfiguration();

    public load = 0;

    constructor(column: SoftThisWeekColumn) {
        super(column);
        this.name = column.column.name;

        this.listConfiguration
            .setDraggable(true)
            .setAllowDragEnter(true);

        this.taskListConfiguration
            .setSoftDeadline(true)
            .setTaskTypeIds(column.getIncludeTaskTypes())
            .setLimit(100)
            .setDraggable(true)
            .setShowCreateNew(true);
        this.listConfiguration.addSource(this.taskListConfiguration);

        const createItemConfiguration = this.listConfiguration.createItemConfiguration;
        createItemConfiguration.showTasks = true;
        createItemConfiguration.filterByDepartmentIds = AppInjector.getInjector()
            .get(UsersService).user
            ?.departments?.map(d => d.id) || [];
        createItemConfiguration.filterTaskTypesById = column.getIncludeTaskTypes();
        this.listConfiguration.createItemPreset.createTaskInterface = this.taskListConfiguration;
    }

    public dataSetChanged() { // Triggered by dataSetChange event in MultiListComponent
        let load = 0;
        this.listConfiguration.getSources().forEach(source => {
            if (source.listConfiguration instanceof TaskListConfiguration) {
                source.items.forEach(item => {
                    if (item.configuration instanceof CardTaskConfiguration) {
                        if (item.configuration.taskEstimate && item.configuration.taskEstimate.estimate) {
                            load += item.configuration.taskEstimate.estimate.getLoadInSeconds();
                        }
                    }
                });
            }
        });
        this.load = load;
    }

}
