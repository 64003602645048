<div class="flex-fill h-100 rounded position-relative "

>
    <!-- Header template -->
    <ng-template #headerTemplate let-column="column" ngx-datatable-header-template
        [typedTemplate]="templateTypes.headerTemplateType">
        <ngx-datatable-column
            [name]="column.name"
            [width]="column.width"
            [maxWidth]="column.maxWidth"
            [minWidth]="column.minWidth"
            [resizeable]="column.resizeable">
            <span class="column-title pt-1">
                <span>{{column.name}} </span>
            </span>
        </ngx-datatable-column>
    </ng-template>

    <ng-template #headerTemplateProjectType let-column="column" ngx-datatable-header-template
                 [typedTemplate]="templateTypes.headerTemplateType"
    >
        <ngx-datatable-column
            [name]="column.name"
            [width]="column.width"
            [maxWidth]="column.maxWidth"
            [minWidth]="column.minWidth"
            [resizeable]="column.resizeable">
            <div class="column-title d-flex align-items-center justify-content-between pt-1">
                <div class="d-flex justify-content-between align-items-center position-relative  flex-shrink-1 overflow-hidden ellipsis"
                     *ngIf="projectTypes">

                    <!--<span [translate]="column.translation">{{column.name}}</span>-->
                    <ul class="list-unstyled d-flex flex-row justify-content-end m-0 w-100">
                        <li class="flex-fill me-1 ms-1" *ngIf="enableToggle">
                            <a class="me-1"
                               (click)="toggleSize()">
                                <i class="fal " aria-hidden="true"
                                   [class.fa-arrow-up-from-dotted-line]="fullSizeActive"
                                   [class.fa-arrow-down-from-dotted-line]="!fullSizeActive"
                                   [ngbTooltip]="!fullSizeActive ? ('_ui_contract_display' | translate) : ('_ui_expand_display' | translate)"
                                ></i>
                            </a>
                        </li>
                        <li class="flex-fill ellipsis d-flex w-100">
                            <div ngbDropdown
                                 class="d-inline-block me-2 ellipsis"
                                 container="body"
                                 placement="top bottom auto"
                            >
                                <a class="" id="dropdownBasic1" ngbDropdownToggle role="button">{{getSortFilterValueName() | translate}}</a>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="w-100 ellipsis">
                                    <a class="dropdown-item" (click)="setSortFilterValue(-1)" role="button"><span
                                        >{{'_ui_all_projects' | translate}}</span></a>
                                    <a class="dropdown-item" (click)="setSortFilterValue(-2)"
                                       role="button"><span >{{'_project_star' | translate}}</span></a>
                                    <a class="dropdown-item" (click)="setSortFilterValue(-3)" role="button"><span
                                        >{{'_ui_all_projects_sorted_by_status' | translate}}</span></a>
                                    <a class="dropdown-item" (click)="setSortFilterValue(-4)" role="button"><span
                                        >{{'_ui_all_projects_sorted_by_deadline' | translate}}</span></a>
                                    <hr class="dropdown-divider"/>
                                    <a *ngFor="let pt of projectTypes" class="dropdown-item"
                                       (click)="selectedProjectType = pt"
                                       role="button"><span >{{pt.name | translate}}</span></a>
                                </div>
                            </div>
                        </li>
                    </ul>

                </div>

                <a class="ms-auto cursor-pointer me-1"
                   [ngbTooltip]="('_ui_show_more' | translate) + '...'"
                   *ngIf="rows && projectsCount" (click)="expandProjects()">
                    <app-item-counter
                        [total]="Math.max(projectsCount, rows.length)" [amount]="rows.length"></app-item-counter>
                </a>
            </div>
        </ngx-datatable-column>
    </ng-template>

    <!-- Project name template -->
    <ng-template #projectNameTemplate let-row="row" let-rowIndex="rowIndex"
                 [typedTemplate]="templateTypes.cellTemplateType"
    >
        <div class="wrap d-flex flex-column position-relative">
            <app-card-project [item]="row.projectCard"></app-card-project>
            <div
                class="action-panel mt-auto d-flex d-row justify-content-end align-items-center rounded pb-1 -w-100 ps-2 pe-2 -corner-item wide">
                <a class="text-center me-1 ms-1"
                   [ngbTooltip]="('_ui_hide_extra_items' | translate) + '...'"
                   container="body"
                   placement="right"
                   (click)="row.minimize()"><i class="fal fa-minus-square"></i></a>
            </div>
        </div>
    </ng-template>

    <!-- Next Milestone template -->
    <ng-template #nextMilestoneTemplate let-row="row" let-rowIndex="rowIndex"
                 [typedTemplate]="templateTypes.cellTemplateType"
    >
        <app-card-milestone *ngIf="row.nextMilestoneCard" [item]="row.nextMilestoneCard"></app-card-milestone>
    </ng-template>

    <!-- Task list next milestone template -->
    <ng-template #tasksTemplate
                 let-column="column"
                 let-row="row"
                 let-rowIndex="rowIndex"
                 [typedTemplate]="templateTypes.taskListNextMilestoneCellTemplateType"
    >
        <app-task-list
            [listClass]="'width-150px grid-3 flex-row justify-content-start disable-drop'"
            [configuration]="column.getListConfiguration(row)"
        />
    </ng-template>

    <!-- FollowingMilestones template -->
    <ng-template #followingMilestonesTemplate
                 let-column="column"
                 let-row="row"
                 let-rowIndex="rowIndex"
                 [typedTemplate]="templateTypes.followingMilestoneListCellTemplateType"
    >
        <app-milestone-list *ngIf="row"
                            class="w-100"
                            [listClass]="'width-150px grid-3 flex-row justify-content-start'"
                            [configuration]="column.getListConfiguration(row)"
                            [minimizeEvent]="row.minimizeEvent">
        </app-milestone-list>
    </ng-template>

    <!-- Appointment template -->
    <ng-template #appointmentsTemplate
                 let-column="column"
                 let-row="row"
                 let-rowIndex="rowIndex"
                 [typedTemplate]="templateTypes.appointmentListCellTemplateType"
    >
        <app-appointment-list
            [listClass]="'flex-row justify-content-start'"
            [configuration]="column.getListConfiguration(row)"
            [reloadEvent]="row.reloadAppointmentsEvent"
            [minimizeEvent]="row.minimizeEvent">
        </app-appointment-list>
    </ng-template>

    <!-- To-dos template -->
    <ng-template #todosTemplate
                 let-column="column"
                 let-row="row"
                 let-rowIndex="rowIndex"
                 [typedTemplate]="templateTypes.todoListCellTemplateType"
    >
        <app-todo-list
            [listClass]="'width-150px grid-3 flex-row justify-content-start'"
            [configuration]="column.getListConfiguration(row)"
            [minimizeEvent]="row.minimizeEvent">
        </app-todo-list>
    </ng-template>


    <!-- Footer: https://stackoverflow.com/questions/46337403/how-to-add-pagination-and-footer-template-in-ngx-datatable -->

    <ngx-datatable
        class="material bootstrap"
        #dataTable
        ngxDatatableExtension [table]="dataTable"

        *ngIf="rows"
        [rows]="rows"
        [columns]="tableColumns"
        columnMode="force"

        [headerHeight]="37"

        [scrollbarV]="true"
        [scrollbarH]="false"

        [loadingIndicator]="true"
        [externalSorting]="true"
        [virtualization]="false"

        appScrollDetector
        (onReachTop)="onReachTop($event)"
        (onReachBottom)="onReachBottom($event)"
        (onScroll)="onScroll($event)"
    >
    </ngx-datatable>

</div>
