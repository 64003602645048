import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {GenericCell} from "@app/pages/displays/display-project-details/subdisplay-users/Cells/GenericCell";
import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns/GenericTableColumn";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {
    UserPeriodRatingFetcher,
    UserPeriodRatingFetchRequest
} from "@app/shared/_ui/cards/medium/card-user/UserPeriodRatingFetcher";
import {
    UserLoadCalculationFetcher,
    UserLoadCalculationFetchRequest
} from "@app/shared/_ui/cards/medium/card-user/LoadCalculationFetcher";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting, User} from "@app/core/models";
import {CardItem} from "@app/shared/_ui/cards/CardItem";

export class UserCardColumn extends BaseColumn implements ColumnDataFetcherInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public userPeriodRatingFetcher: UserPeriodRatingFetcher;
    public userLoadCalculationFetcher: UserLoadCalculationFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.userPeriodRatingFetcher,
            this.userLoadCalculationFetcher,
        ]
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.userPeriodRatingFetcher = new UserPeriodRatingFetcher(column.name);
        this.userLoadCalculationFetcher = new UserLoadCalculationFetcher(column.name);
    }

    createTableColumns(): BaseTableColumn[] {
        const tableColumn = new GenericTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    createCell(row: BaseRow, userCard?: CardItem<User>): BaseCell {
        const cell = new GenericCell(row, this);

        this.userPeriodRatingFetcher.addRequest(new UserPeriodRatingFetchRequest(userCard));
        this.userLoadCalculationFetcher.addRequest(new UserLoadCalculationFetchRequest(userCard));

        return cell;
    }

}
