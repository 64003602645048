import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {Api} from '@app/core/http/Api/Api';
import {AppInjector} from '@app/services/app-injector.service';
import {EventService} from '@app/services/event.service';
import {Milestone} from '@app/core/models/Milestone';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {TasksService} from '@app/services/tasks.service';
import {TasksTaskType} from '@app/shared/_modals/copy-project-dialog/copy-project-dialog.component';

@Component({
    selector: 'app-copy-milestone-dialog',
    templateUrl: './copy-milestone-dialog.component.html',
    styleUrls: ['./copy-milestone-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
})
export class CopyMilestoneDialogComponent extends BaseModalComponent implements OnInit {

    @Input() primaryClass: string = 'btn-success';
    @Input() item: Milestone;
    cascade: boolean = true;

    public copyTasks: boolean = true;
    public milestone: Milestone;
    tasksTaskTypes:TasksTaskType[];

    constructor(private modal: NgbActiveModal,
                private editorPanelService: EditorPanelService,
                private tasksService:TasksService,
                ) {
        super(modal);
    }

    ngOnInit() {
        if(this.item){
            Api.milestones()
                .getById(this.item.id)
                .include('project')
                .include('task')
                .find((milestone)=>{
                    this.milestone = milestone[0];
                    const tasks = this.milestone.tasks;
                    if(tasks){
                        this.tasksService.getTaskTypes((tt)=>{
                            this.tasksTaskTypes = [];
                            tt.forEach(tt => {
                                this.tasksTaskTypes.push({
                                    taskType: tt,
                                    tasks: tasks.filter(t => t.task_type_id == tt.id)
                                })
                            })

                        })

                    }
                });
        }
    }

    public accept() {
        const api = Api.milestones().copyGetById(this.item.id);

        if (this.copyTasks) {
            api.copy_tasks(true);
        }
        api.find((r) => {
            this.editorPanelService.open(r[0]);
            AppInjector.getInjector().get(EventService).emitMilestone(new Milestone(r[0]), EventService.Created);
        });

        this.activeModal.close({cascade: this.cascade});
    }


}
