import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {TodoFetcher, TodoFetcherRequest} from "@app/shared/_ui/lists/todo-list/TodoFetcher";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {GenericColumn} from "@app/pages/displays/display-team/Columns/GenericColumn";
import {TodoListCell} from "@app/pages/displays/display-team/Cells/TodoListCell";
import {TodoListTableColumn} from "@app/pages/displays/display-team/TableColumns/TodoListTableColumn";
import {DisplayTeamRow} from "@app/pages/displays/display-team/DisplayTeamRow";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";

export class TodoListColumn extends GenericColumn<TodoListCell> implements ColumnDataFetcherInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TodoFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new TodoFetcher(column.name);
    }

    public createTableColumns(): TodoListTableColumn[] {
        const item = new TodoListTableColumn(this);
        this.setTableColumnDefaults(item);
        return [item];
    }

    public createCell(row: DisplayTeamRow): TodoListCell {
        const cell = new TodoListCell(row, this);

        this.dataFetcher.addRequest(new TodoFetcherRequest(cell.todoListConfiguration));
        return cell;
    }

}
