import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {TranslateModule} from "@ngx-translate/core";
import {ItemDateModule} from "@app/shared/_elements/item-date/ItemDate.module";
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";
import {DeadlineDifferenceModule} from "@app/shared/_elements/deadline-difference/DeadlineDifference.module";
import {
    ProjectNoteCellComponent
} from "@app/pages/displays/display-projects/CellComponents/project-note-cell/project-note-cell.component";
import {ItemNoteModule} from "@app/shared/_elements/item-note/ItemNote.module";

@NgModule({
    imports: [
        CommonModule,
        UserWithIconModule,
        TranslateModule,
        ItemDateModule,
        TypedTemplateDirective,
        DeadlineDifferenceModule,
        ItemNoteModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ProjectNoteCellComponent,
    ],
    exports: [
        ProjectNoteCellComponent

    ],
    providers: [

    ],
})
export class ProjectNoteCellModule {}
