import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ReferenceComponent} from "@app/pages/reference/reference.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {CardsModule} from "@app/modules/Cards.module";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        NgbModule,
        DragDropModule,
        CardsModule
    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ReferenceComponent
    ]
})
export class ReferenceModule {
}
