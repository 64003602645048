import {NgModule} from "@angular/core";
import {ToggleItemComponent} from "@app/shared";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        ColorItemModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ToggleItemComponent,
    ],
    exports: [
        ToggleItemComponent

    ],
    providers: [

    ],
})
export class ToggleItemModule {}
