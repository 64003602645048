<div class="block-line"
     [ngbTooltip]="title ? title : null"
>
    <div class="start-cap" *ngIf="item.configuration && item.startDate < item.configuration.periodStart">
        <i class="fal fa-arrow-left"></i>
    </div>
    <div class="end-cap" *ngIf="item.configuration && item.endDate > item.configuration.periodEnd">
        <i class="fal fa-arrow-right"></i>
    </div>
</div>
