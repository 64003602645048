<ul
    class="list-unstyled d-flex flex-row justify-content-between m-0 w-100 align-items-center gap-2"
>
    <li>
        <app-generic-vertical-edit-collection
            *ngIf="isReady"
            cssClasses="align-items-center gap-2"
            [cardItem]="cardItem"
            [enabledEditors]="editors"
        />
    </li>

    <!-- Copy -->
    <li class="ps-2 border-start">
        <app-toggle-item
            (click)="onCopyBtnClicked()"
            [ngbTooltip]="('_ui_copy' | translate) + '...'"
            [iconWeightActive]="'fal'"
            [icon]="'fa-copy'"
            class="cursor-pointer"
        ></app-toggle-item>
    </li>

    <li
        class="pe-1 d-flex"
        *ngIf="showValidationWarning && validator">
        <app-validation-warning-list
            [inline]="false"
            [validatorError]="validator"></app-validation-warning-list>
    </li>

    <li class="pe-1">
        <app-find-and-focus-btn
            [matchType]="FocusableEntityTypes.Milestone"
            [matchIdentifier]="item.id"
        />
    </li>

</ul>
