import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/GenericTableColumn";
import {
    TaskListWithoutDeadlineColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/TaskListWithoutDeadlineColumn";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns";

export class TaskListWithoutDeadlineTableColumn extends GenericTableColumn {

    public identifier = TableColumns.TaskListWithoutDeadline;

    public column: TaskListWithoutDeadlineColumn;

}
