/**
 * Created by ModelParser
 */
import {Display} from '../Display';
import {ColumnSetting} from '../ColumnSetting';
import {DisplayFiltersColumn} from '../DisplayFiltersColumn';
import {ColumnTypeSetting} from '../ColumnTypeSetting';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class ColumnDefinition extends BaseModel {
    display_id?: number;
    display?: Display;
    identifier?: string;
    name?: string;
    is_show_as_default?: boolean;
    position?: number;
    column_settings?: ColumnSetting[];
    display_filters_columns?: DisplayFiltersColumn[];
    column_type_settings?: ColumnTypeSetting[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.display_id;
            delete this.display;
            delete this.identifier;
            delete this.name;
            delete this.is_show_as_default;
            delete this.position;
            delete this.column_settings;
            delete this.display_filters_columns;
            delete this.column_type_settings;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.display_id != null) {
            this.display_id = data.display_id;
        }
        if (data.display != null) {
            this.display = new Display(data.display);
        }
        if (data.identifier != null) {
            this.identifier = data.identifier;
        }
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.is_show_as_default != null) {
            this.is_show_as_default = data.is_show_as_default;
        }
        if (data.position != null) {
            this.position = data.position;
        }
        if (data.column_settings != null) {
            this.column_settings = data.column_settings.map((i: any) => new ColumnSetting(i));
        }
        if (data.display_filters_columns != null) {
            this.display_filters_columns = data.display_filters_columns.map((i: any) => new DisplayFiltersColumn(i));
        }
        if (data.column_type_settings != null) {
            this.column_type_settings = data.column_type_settings.map((i: any) => new ColumnTypeSetting(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
