import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Api} from "@app/core/http/Api/Api";
import {ColumnSetting} from "@app/core/models";
import {isArray} from "ngx-bootstrap/chronos";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {EventEmitter} from "@angular/core";
import {ColumnVisibilityChangeEvent} from "@app/core/ColumnControl/ColumnVisibilityChangeEvent";
import {UserCardColumn} from "@app/pages/displays/display-team/Columns/UserCardColumn";

export class ColumnController {

    private columnTypes = new Map<string, BaseColumnType>;
    private columns = new Map<string, BaseColumn[]>;
    private tableColumns?: BaseTableColumn[];

    public onColumnsChanged = new EventEmitter();
    public onTableColumnVisibilityChanged = new EventEmitter<ColumnVisibilityChangeEvent>();

    // <editor-fold desc="Utility">

    public getAllColumns(): BaseColumn[] {
        const items: BaseColumn[] = [];
        this.columns.forEach(value => items.push(...value));
        return items.sort((a, b) => a.column.position - b.column.position);
    }

    public hasColumn(identifier: string | string[]): boolean {
        const identifiers = isArray(identifier) ? identifier : [identifier];
        for (let i = 0; i < identifiers.length; i++) {
            if (this.columns.has(identifiers[i])) {
                return true;
            }
        }
        return false;
    }

    public getColumns<T extends BaseColumn = BaseColumn>(identifier: string): T[] {
        return (this.columns.get(identifier) as T[]) ?? [];
    }

    public getVisibleColumns<T extends BaseColumn = BaseColumn>(identifier?: string): T[] {
        return this.getAllColumns()
            .filter(column => identifier === undefined || column.columnType.identifier == identifier)
            .filter(column => column.isVisible) as T[];
    }

    public hasVisibleTableColumn(identifier: string): boolean {
        return this.getColumns(identifier)
            .some(column => column.getTableColumns()
                .some(tableColumn => tableColumn.isVisible)
            );
    }

    public getVisibleTableColumns(): BaseTableColumn[] {
        return this.getAllTableColumns().filter(tableColumn => tableColumn.isVisible);
    }

    public getFirstVisibleTableColumn(identifier: string): BaseTableColumn | undefined {
        const columns = this.getColumns<UserCardColumn>(identifier);
        if (columns.length > 0) {
            const visibleColumn = columns[0].getVisibleTableColumns();
            return visibleColumn.length > 0 ? visibleColumn[0] : undefined;
        } else {
            return undefined;
        }
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    public addColumnType(value: BaseColumnType) {
        this.columnTypes.set(value.identifier, value);
    }

    public addColumnTypes(values: BaseColumnType[]) {
        values.forEach(value => this.addColumnType(value));
    }

    private addColumn(value: BaseColumn) {
        if (!this.columns.has(value.column.identifier)) {
            this.columns.set(value.column.identifier, []);
        }
        this.columns.get(value.column.identifier).push(value);
    }

    public removeColumn(value: BaseColumn): void {
        if (this.columns.has(value.column.identifier)) {
            const columns = this.columns.get(value.column.identifier);
            if (columns.includes(value)) {
                columns.splice(columns.indexOf(value, 1));
            }
            if (columns.length == 0) {
                this.columns.delete(value.column.identifier);
            }

            // Clear table columns
            this.tableColumns = undefined;

            // Emit change
            this.onColumnsChanged.emit();
        }
    }

    public loadColumns(displayId: number, onFinish: () => void) {
        Api.displays().columnsGetGetByDisplayId(displayId)
            .find(columns => {
                columns
                    // Remove columns with unknown type
                    .filter(column => this.columnTypes.has(column.identifier))
                    .forEach(column => {

                        // Move column settings into map
                        const settingsMap = new Map<string, ColumnSetting>();
                        column.column_settings?.forEach(columnSetting => settingsMap.set(columnSetting.identifier, columnSetting));

                        this.addColumn(
                            this.columnTypes.get(column.identifier)!.createColumn(column, settingsMap)
                        );
                    });

                // Clear table columns
                this.tableColumns = undefined;

                onFinish();

                // Emit change
                this.onColumnsChanged.emit();
            });
    }

    public getAllTableColumns(): BaseTableColumn[] {
        if (!this.tableColumns) {
            const items: BaseTableColumn[] = [];
            this.getAllColumns()
                .forEach(value => items.push(...value.getTableColumns()));
            this.tableColumns = items;
        }
        return this.tableColumns;
    }

    // </editor-fold>

    public emitColumnVisibilityChange(addedColumns: BaseTableColumn[], removedColumns: BaseTableColumn[]) {
        this.onTableColumnVisibilityChanged.emit(new ColumnVisibilityChangeEvent(addedColumns, removedColumns));
    }

}
