import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {ProjectNoteColumn} from "@app/pages/displays/display-project-details/subdisplay-top/Columns/ProjectNoteColumn";

export class ProjectNoteCell extends BaseCell {

    public row: Row;
    public column: ProjectNoteColumn;

    public value: string;

    public constructor(row: Row, column: ProjectNoteColumn) {
        super(row, column);

        this.value = row.projectCard.item.getText(column.getNoteField());
    }

    public setValue(value: string) {
        this.row.projectCard.item.setText(this.column.getNoteField(), value);
    }

}
