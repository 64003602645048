/**
 * Created by ModelParser
 */
import {DisplayType} from '../DisplayType';
import {DisplaysSetting} from '../DisplaysSetting';
import {Department} from '../Department';
import {DepartmentsDisplay} from '../DepartmentsDisplay';
import {Display} from '../Display';
import {DisplaysRelateddisplay} from '../DisplaysRelateddisplay';
import {Role} from '../Role';
import {DisplaysRole} from '../DisplaysRole';
import {TaskUserType} from '../TaskUserType';
import {Appointment} from '../Appointment';
import {Todo} from '../Todo';
import {DisplayFilter} from '../DisplayFilter';
import {DisplayFilterUserSetting} from '../DisplayFilterUserSetting';
import {Column} from '../Column';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class DisplayDefinition extends BaseModel {
    name?: string;
    display_type_id?: number;
    display_type?: DisplayType;
    copy_of?: number;
    index_?: number;
    displays_settings?: DisplaysSetting[];
    departments?: Department[];
    departments_displays?: DepartmentsDisplay[];
    displays?: Display[];
    relateddisplays?: Display[];
    displays_relateddisplays?: DisplaysRelateddisplay[];
    roles?: Role[];
    displays_roles?: DisplaysRole[];
    task_user_types?: TaskUserType[];
    appointments?: Appointment[];
    todos?: Todo[];
    display_filters?: DisplayFilter[];
    display_filter_user_settings?: DisplayFilterUserSetting[];
    columns?: Column[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.display_type_id;
            delete this.display_type;
            delete this.copy_of;
            delete this.index_;
            delete this.displays_settings;
            delete this.departments;
            delete this.departments_displays;
            delete this.displays;
            delete this.relateddisplays;
            delete this.displays_relateddisplays;
            delete this.roles;
            delete this.displays_roles;
            delete this.task_user_types;
            delete this.appointments;
            delete this.todos;
            delete this.display_filters;
            delete this.display_filter_user_settings;
            delete this.columns;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.display_type_id != null) {
            this.display_type_id = data.display_type_id;
        }
        if (data.display_type != null) {
            this.display_type = new DisplayType(data.display_type);
        }
        if (data.copy_of != null) {
            this.copy_of = data.copy_of;
        }
        if (data.index_ != null) {
            this.index_ = data.index_;
        }
        if (data.displays_settings != null) {
            this.displays_settings = data.displays_settings.map((i: any) => new DisplaysSetting(i));
        }
        if (data.departments != null) {
            this.departments = data.departments.map((i: any) => new Department(i));
        }
        if (data.departments_displays != null) {
            this.departments_displays = data.departments_displays.map((i: any) => new DepartmentsDisplay(i));
        }
        if (data.displays != null) {
            this.displays = data.displays.map((i: any) => new Display(i));
        }
        if (data.relateddisplays != null) {
            this.relateddisplays = data.relateddisplays.map((i: any) => new Display(i));
        }
        if (data.displays_relateddisplays != null) {
            this.displays_relateddisplays = data.displays_relateddisplays.map((i: any) => new DisplaysRelateddisplay(i));
        }
        if (data.roles != null) {
            this.roles = data.roles.map((i: any) => new Role(i));
        }
        if (data.displays_roles != null) {
            this.displays_roles = data.displays_roles.map((i: any) => new DisplaysRole(i));
        }
        if (data.task_user_types != null) {
            this.task_user_types = data.task_user_types.map((i: any) => new TaskUserType(i));
        }
        if (data.appointments != null) {
            this.appointments = data.appointments.map((i: any) => new Appointment(i));
        }
        if (data.todos != null) {
            this.todos = data.todos.map((i: any) => new Todo(i));
        }
        if (data.display_filters != null) {
            this.display_filters = data.display_filters.map((i: any) => new DisplayFilter(i));
        }
        if (data.display_filter_user_settings != null) {
            this.display_filter_user_settings = data.display_filter_user_settings.map((i: any) => new DisplayFilterUserSetting(i));
        }
        if (data.columns != null) {
            this.columns = data.columns.map((i: any) => new Column(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
