<!-- Icon with ngbpopover -->
<ng-container *ngIf="!inline">
    <ngb-popover
        #p="ngbPopover"
        placement="right left"
        [autoClose]="'inside'"
        triggers="mouseenter:mouseleave"
        container="body"
        [ngbPopover]="content"
        *ngIf="showWarning"
    >
        <i class="fa-duotone fa-circle-exclamation"></i>
    </ngb-popover>

    <!-- ngbpopover content -->
    <ng-template #content>
        <ul class="list-unstyled p-0 m-0">
            <li *ngFor="let waning of errorDescriptions" class="d-flex align-items-center gap-1">
                <i class="fa-duotone fa-circle-exclamation"></i>
                <span>{{ waning }}</span>
            </li>
        </ul>
    </ng-template>

</ng-container>

<ng-container *ngIf="inline">
    <ul class="list-unstyled p-0 m-0">
        <li *ngFor="let waning of errorDescriptions" class="d-flex align-items-center gap-1">
            <i class="fa-duotone fa-circle-exclamation"></i>
            <span>{{ waning }}</span>
        </li>
    </ul>
</ng-container>
