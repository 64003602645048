/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {LanguageValueDefinition} from './definitions/LanguageValueDefinition';
import {ApiDataCache} from '@app/core/DataCache/ApiDataCache';
import {System} from '@app/constants';
import {Api} from '@app/core/http/Api/Api';
import {LanguageValueInterface} from '@app/interfaces/LanguageValueInterface';

export class LanguageValue extends LanguageValueDefinition {

    key?: string;

    constructor(json?: any) {
        super(json);
        if (json.key != null) {
            this.key = json.key;
        }
    }

    /* DB Cache */
    private static DataCache?: ApiDataCache<LanguageValue[]>;

    private static GetDataCache(): ApiDataCache<LanguageValue[]> {
        if (!this.DataCache) {
            this.DataCache = new ApiDataCache<LanguageValue[]>(
                System.Translations,
                24 * 60 * 60 * 30, // 30 days
                Api.languageValues().get()
                    .setShowProgressBar(false)
                    .include('language_key')
                    .addQueryParameter('cache', 1)
            );
        }
        return this.DataCache;
    }

    // Entry point
    public static GetAll(callback: (items: LanguageValueInterface[]) => void) {
        LanguageValue.GetDataCache().get((languageValues)=>{
            const items = languageValues.map((item: any) => {
                return {
                    value: item.value,
                    key: item.key,
                    languageId: item.language_id,
                };
            });
            callback(items);
        });
    }

}
