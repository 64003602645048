/**
 * Created by ModelParser
 * Date: 20-12-2018.
 * Time: 11:42.
 */
import {PhasesProjectTypeDefinition,} from './definitions/PhasesProjectTypeDefinition';

export class PhasesProjectType extends PhasesProjectTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static createDefault() {
        let item = new PhasesProjectType();
        item.id = 0;
        return item;
    }
}
