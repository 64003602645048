import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {Widths} from "@app/constants";
import {TemplateRef} from "@angular/core";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import { ColumnTypes } from "@app/pages/displays/display-project-details/subdisplay-milestones/ColumnTypes";
import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/TaskEditColumn";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {DisplayFilterEditorFormConfigInterface} from "@app/editor/display-filter-editor/DisplayFilterEditorForm";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

export class TaskEditColumnType extends BaseColumnType {

    public identifier = ColumnTypes.TaskEdit;

    public isAlwaysVisible = false;
    public customWidth?: number;
    public minWidth = Widths.CardColumn * 4;
    public maxWidth?: number;
    public resizeable = false;
    public frozenLeft = false;
    public canAutoResize = false;
    public isList = true;
    public cellClass?: string;

    private readonly shellPageData: ShellPageData;
    private readonly onColumnSortChange: (column: BaseTableColumn) => void;
    private readonly cellTaskEditListWhoWhenHeaderTemplate: TemplateRef<any>;
    private readonly isMinimized: boolean;
    private readonly config: DisplayFilterEditorFormConfigInterface;

    public constructor(shellPageData: ShellPageData,
                       onColumnSortChange: (column: BaseTableColumn) => void,
                       cellTaskEditListWhoWhenHeaderTemplate: TemplateRef<any>,
                       isMinimized: boolean,
                       config: DisplayFilterEditorFormConfigInterface) {
        super();
        this.shellPageData = shellPageData;
        this.onColumnSortChange = onColumnSortChange;
        this.cellTaskEditListWhoWhenHeaderTemplate = cellTaskEditListWhoWhenHeaderTemplate;
        this.isMinimized = isMinimized;
        this.config = config;
    }

    public createColumn(column: Column, settings: Map<string, ColumnSetting>): BaseColumn {
        return new TaskEditColumn(
            this,
            column,
            settings,
            this.shellPageData,
            this.onColumnSortChange,
            this.cellTaskEditListWhoWhenHeaderTemplate,
            this.isMinimized,
            this.config
        );
    }

}
