import {BaseYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/BaseYearWheelItem";
import {YearWheelColumnConfiguration} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration";
import {PhasesProject} from "@app/core/models";

export class PhasesProjectYearWheelItem extends BaseYearWheelItem<PhasesProject>{

    isStartDateVisible: boolean;
    isEndDateVisible: boolean;
    endDate: Date;

    constructor(configuration: YearWheelColumnConfiguration,
                item: PhasesProject,
                isStartDateVisible: boolean,
                isEndDateVisible: boolean,

                xPosInPixels: number,

                width: number,
                startDate: Date,
                endDate: Date
    ) {
        super();

        this.visible = true;
        this.isStartDateVisible = isStartDateVisible;
        this.isEndDateVisible = isEndDateVisible;

        this.configuration = configuration;
        this.item = item;

        this.left = xPosInPixels;

        this.width = width;
        this.startDate = startDate;
        this.endDate = endDate;
    }

}
