/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {PeriodDefinition} from './definitions/PeriodDefinition';

export class Period extends PeriodDefinition {

    constructor(json?: any) {
        super(json);
    }

}
