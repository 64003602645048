<ng-template #rt let-r="result" let-t="term"
             [typedTemplate]="resultTemplateType"
>
    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>

<ul class="list-group">
    <li class="list-group-item">
        <div class="form-group mb-0">
            <div class="search-wrap">
                <div class="input-group">

                    <input type="search" class="form-control placeholder-sm"
                           autocomplete="off"
                           #instance="ngbTypeahead"
                           container="body"
                           [class.is-invalid]="searchFailed"
                           [ngbTypeahead]="search"
                           popupClass="result-list"
                           [resultTemplate]="rt"
                           [inputFormatter]="formatter"
                           (focus)="focus$.next($any($event.target).value)"
                           (click)="click$.next($any($event.target).value)"
                           (selectItem)="triggerSelection($event, instance)"

                           [placeholder]="('_ui_search_placeholder' | translate: {type: ('')})"
                    />
                        <button class="btn btn-outline-primary" type="button" *ngIf="allowCreation" (click)="createField()"><i class="fa fa-plus" aria-hidden="true"></i></button>
                </div>
                <span class="searching" *ngIf="searching" [@fadeAnimation]="'in'">{{('_ui_searching' | translate)}}...</span>
            </div>
            <div class="invalid-feedback" *ngIf="searchFailed">{{('_ui_search_no_results' | translate)}}.</div>
        </div>

    </li>
    <li class="list-group-item" *ngFor="let item of getItems()" [@fadeAnimation]="'in'">
        <div class="input-group">
            <input *ngIf="item.id === 0" type="text" [(ngModel)]="item.name" class="form-control" />
            <span *ngIf="item.id !== 0" class="form-control">{{item.name}}</span>

            <span class="btn btn-outline-danger" (click)="removeItem(item)"><i class="fal fa-trash-alt"></i></span>
        </div>
    </li>
</ul>
