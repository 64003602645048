import {GenericTableColumn} from "@app/pages/displays/display-kanban/TableColumns/GenericTableColumn";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {PrioritizationColumn} from "@app/pages/displays/display-kanban/Columns/PrioritizationColumn";
import {
    PrioritizationDialog
} from "@app/pages/displays/display-kanban/prioritization-dialog/prioritization-dialog.component";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {EventService} from "@app/services/event.service";
import {AppInjector} from "@app/services/app-injector.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TableColumns} from "@app/pages/displays/display-kanban/TableColumns";

export class PrioritizationTableColumn extends GenericTableColumn {

    public identifier = TableColumns.Prioritization;

    public column: PrioritizationColumn;

    constructor(column: BaseColumn) {
        super(column);

        this.projectListConfiguration
            .setCategoryTypeId(this.column.getCategoryType())
            .setCurrentPhaseId(0)

            .setCustomDragAndDropFunction((cardItem, fromListConfiguration) => {
                const modalRef = AppInjector.getInjector().get(NgbModal).open(PrioritizationDialog, BaseDialogService.DefaultOptions);
                const component = modalRef.componentInstance as PrioritizationDialog;
                component.project = cardItem.item;
                component.categoryTypeId = this.column.getCategoryType();

                const matrix = this.column.getMatrixSetting();
                component.color1 = matrix.color1;
                component.color2 = matrix.color2;
                component.color3 = matrix.color3;
                component.color4 = matrix.color4;
                component.text1 = matrix.text1;
                component.text2 = matrix.text2;
                component.text3 = matrix.text3;
                component.text4 = matrix.text4;
                component.axisX = matrix.axisX;
                component.axisY = matrix.axisY;
                modalRef.result.then(save => {
                    if (!save) {
                        // Bruger har trykket "Annuller". Fjern kortet fra listen.
                        AppInjector.getInjector().get(EventService)
                            .emit(cardItem.item, EventService.Updated, ['categories']);
                    } else {
                        // Fjern aktiv fase hvis bruger ikke annullerede
                        cardItem.item.setCurrentPhasesProject(undefined);
                    }
                }).catch(() => {
                    // Bruger har trykket "Annuller". Fjern kortet fra listen.
                    AppInjector.getInjector().get(EventService)
                        .emit(cardItem.item, EventService.Updated, ['categories']);
                });
            });
    }

}
