import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {PageComponent} from '@app/pages/page.component';
import {User} from '@app/core/models/User';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {DisplayFilter} from '@app/core/models/DisplayFilter';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {CardUserConfiguration} from '@app/shared/_ui/cards/medium/card-user/card-user-configuration';
import {ProjectType} from '@app/core/models/ProjectType';
import {Filters} from '@app/pages/displays/display-team/Helpers/Filters';
import {DisplayTeamRow} from '@app/pages/displays/display-team/DisplayTeamRow';
import * as moment from 'moment';
import {ListConfiguration} from '@app/shared/_ui/lists/ListConfiguration';
import {
    ProjectStatusTypes,
    StatusTypes,
    TaskDeadlineTypes,
    TaskStatusTypes,
    TaskUserTypes,
} from "@app/constants";
import {NonArchivedFilter} from '@app/pages/displays/display-team/OnScreenFilters/NonArchivedFilter';
import {OnlyStarredOrHandUpFilter} from '@app/pages/displays/display-team/OnScreenFilters/OnlyStarredOrHandUpFilter';
import {ProjectsService} from '@app/services/projects.service';
import {ProjectTypeFilter} from '@app/pages/displays/display-team/OnScreenFilters/ProjectTypeFilter';
import {ScreenshotHelper} from "@app/core/ScreenshotHelper/ScreenshotHelper";
import {MultiDisplayVisibilityObserver} from "@app/core/MultiDisplayHelpers/MultiDisplayVisibilityObserver";
import {LocalStorage} from "@app/pages/displays/display-team/Helpers/LocalStorage";
import {
    TaskUserPresetGenerator
} from '@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator';
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {Deadline} from "@app/core/models";
import {
    TaskDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDeadlinePresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    ProjectStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectStatusPresetGenerator";
import {
    ProjectUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUseStatusRulesPresetGenerator";
import {
    ProjectDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectDepartmentPresetGenerator";
import {
    ProjectUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUserPresetGenerator";
import {ProjectUserTypes} from "@app/core/models/Project";
import {
    MilestoneStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneStatusPresetGenerator";
import {
    MilestoneUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneUseStatusRulesPresetGenerator";
import {
    MilestoneResponsiblePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneResponsiblePresetGenerator";
import {
    MilestoneDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneDeadlinePresetGenerator";
import {
    TodoUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoUserPresetGenerator";
import {TeamDisplaySortHelper} from "@app/pages/displays/display-team/Helpers/TeamDisplaySortHelper";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {UserCardColumnType} from "@app/pages/displays/display-team/ColumnTypes/UserCardColumnType";
import {ProjectListColumnType} from "@app/pages/displays/display-team/ColumnTypes/ProjectListColumnType";
import {MilestoneListColumnType} from "@app/pages/displays/display-team/ColumnTypes/MilestoneListColumnType";
import {TaskListColumnType} from "@app/pages/displays/display-team/ColumnTypes/TaskListColumnType";
import {TodoListColumnType} from "@app/pages/displays/display-team/ColumnTypes/TodoListColumnType";
import {AppointmentListColumnType} from "@app/pages/displays/display-team/ColumnTypes/AppointmentListColumnType";
import {DaysColumnType} from "@app/pages/displays/display-team/ColumnTypes/DaysColumnType";
import {DataFetcherCollection} from "@app/core/DataFetchers/DataFetcherCollection";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {UserCardColumn} from "@app/pages/displays/display-team/Columns/UserCardColumn";
import {ColumnTypes} from "@app/pages/displays/display-team/Helpers/ColumnTypes";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {ProjectListColumn} from "@app/pages/displays/display-team/Columns/ProjectListColumn";
import {ProjectListCell} from "@app/pages/displays/display-team/Cells/ProjectListCell";
import {MilestoneListColumn} from "@app/pages/displays/display-team/Columns/MilestoneListColumn";
import {MilestoneListCell} from "@app/pages/displays/display-team/Cells/MilestoneListCell";
import {TaskListColumn} from "@app/pages/displays/display-team/Columns/TaskListColumn";
import {TaskListCell} from "@app/pages/displays/display-team/Cells/TaskListCell";
import {AppointmentListColumn} from "@app/pages/displays/display-team/Columns/AppointmentListColumn";
import {AppointmentListCell} from "@app/pages/displays/display-team/Cells/AppointmentListCell";
import {TodoListColumn} from "@app/pages/displays/display-team/Columns/TodoListColumn";
import {TodoListCell} from "@app/pages/displays/display-team/Cells/TodoListCell";
import {TemplateTypes} from "@app/pages/displays/display-team/Helpers/TemplateTypes";
import {CaseListColumnType} from "@app/pages/displays/display-team/ColumnTypes/CaseListColumnType";
import {CaseListCell} from "@app/pages/displays/display-team/Cells/CaseListCell";
import {DaysColumn} from "@app/pages/displays/display-team/Columns/DaysColumn";
import {DaysCell} from "@app/pages/displays/display-team/Cells/DaysCell";
import {SharedComponentResizeObserverCache} from "@app/core/ResizeObserver/SharedComponentResizeObserverCache";
import {CaseListColumn} from "@app/pages/displays/display-team/Columns/CaseListColumn";
import {
    TaskListSoftThisWeekColumnType
} from "@app/pages/displays/display-team/ColumnTypes/TaskListSoftThisWeekColumnType";
import {
    TaskListSoftNextWeekColumnType
} from "@app/pages/displays/display-team/ColumnTypes/TaskListSoftNextWeekColumnType";
import {TaskListSoftThisWeekCell} from "@app/pages/displays/display-team/Cells/TaskListSoftThisWeekCell";
import {TaskListSoftNextWeekCell} from "@app/pages/displays/display-team/Cells/TaskListSoftNextWeekCell";
import {TaskListSoftThisWeekColumn} from "@app/pages/displays/display-team/Columns/TaskListSoftThisWeekColumn";
import {TaskListSoftNextWeekColumn} from "@app/pages/displays/display-team/Columns/TaskListSoftNextWeekColumn";

@Component({
    selector: 'app-display-team',
    templateUrl: './display-team.component.html',
    styleUrls: ['./display-team.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayTeamComponent extends PageComponent implements OnInit {
    protected filtersSettings = new Filters();
    protected columnController = new ColumnController();
    public templateTypes = new TemplateTypes();
    private projectTypes: ProjectType[];

    // UI
    @ViewChild('dataTableContainer', {static: true}) dataTableContainer: ElementRef;
    @ViewChild('dataTable', {static: false}) table: DatatableComponent;
    @ViewChild('headerTemplate', {static: true}) headerTemplate: TemplateRef<any>;
    @ViewChild('headerTemplateNavigation', {static: true}) headerTemplateNavigation: TemplateRef<any>;
    @ViewChild('headerDateTemplate', {static: true}) headerDateTemplate: TemplateRef<any>;
    @ViewChild('userTemplate', {static: true}) userTemplate: TemplateRef<any>;
    @ViewChild('projectsTemplate', {static: true}) projectsTemplate: TemplateRef<any>;
    @ViewChild('milestonesTemplate', {static: true}) milestonesTemplate: TemplateRef<any>;
    @ViewChild('casesTemplate', {static: true}) casesTemplate: TemplateRef<any>;
    @ViewChild('taskListSoftThisWeekTemplate', {static: true}) taskListSoftThisWeekTemplate: TemplateRef<any>;
    @ViewChild('tasksTemplate', {static: true}) tasksTemplate: TemplateRef<any>;
    @ViewChild('taskListSoftNextWeekTemplate', {static: true}) taskListSoftNextWeekTemplate: TemplateRef<any>;
    @ViewChild('daysTemplate', {static: true}) daysTemplate: TemplateRef<any>;
    @ViewChild('appointmentsTemplate', {static: true}) appointmentsTemplate: TemplateRef<any>;
    @ViewChild('todosTemplate', {static: true}) todosTemplate: TemplateRef<any>;

    @HostListener('window:resize', ['$event'])
    sizeChange(event: Event) {
        setTimeout(() => this.recalculateTableHeight());
    }

    // Bindings to view
    public tableColumns: BaseTableColumn[] = [];
    public rows: DisplayTeamRow[];
    public usersCount: number;
    public displayFilter: DisplayFilter;
    public isTopDisplayExpanded = false;
    public isMainDisplayExpanded = false;
    public visibleSubDisplays = 2;
    public multiDisplayVisibilityObserver = new MultiDisplayVisibilityObserver();
    public onScreenFilters: (NonArchivedFilter | OnlyStarredOrHandUpFilter | ProjectTypeFilter)[] = [
        new NonArchivedFilter(false),
        new OnlyStarredOrHandUpFilter(false),
    ];
    public listsExpanded: boolean;
    public enableRowPositioning = false;
    public dataFetcherCollection = new DataFetcherCollection();

    // Data
    private limit = 30;
    private offset = 0;
    private rowsMap: Map<number, DisplayTeamRow>; // UserID -> Row
    private localStorage: LocalStorage;
    private sortHelper?: TeamDisplaySortHelper;
    private sharedComponentResizeObserverCache = new SharedComponentResizeObserverCache();

    constructor(private cd: ChangeDetectorRef,
                private projectsService: ProjectsService) {
        super();
        this.initialized = false; // True after columns fetched from server
        this.cdr = cd;
        this.shellService.setHeaderTitle(this.Constants.System.Loading);
        this.readLocalStorage();
    }

    ngOnInit() {
        this.filterGlobalService.setShowSearch(false);
        this.filterGlobalService.setShowUserSearch(true);

        this.buildColumns();
        super.ngOnInit();

        this.sortHelper = new TeamDisplaySortHelper(this.displayId, this.department.id!);

        this.projectTypes = [];
        this.projectsService.getProjectTypesByDepartmentId(this.shellService.getPageSettings()?.departmentId, projectTypes => {
            this.projectTypes = this.projectTypes.concat(projectTypes);
            this.projectTypes.forEach((pt, index) => {
                let filter = new ProjectTypeFilter(false, pt);
                if (index == 0) {
                    filter.borderTop = true;
                }
                this.onScreenFilters.push(filter);
            });
            this.markChangeDetectionDirty();

            this.subscribe(this.shellService.onReloadAllClickEvent.subscribe((e: any) => {
                this.loadData();
            }));
            this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(_ => {
                this.loadData();
            }));
            this.subscribe(this.filterGlobalService.onSettingsPeriodChangeEvent.subscribe(period => {
                this.loadData();
            }));
        });

        this.subscribe(this.multiDisplayVisibilityObserver.subscribeAsDisplay(visibleDisplays => {
            this.visibleSubDisplays = visibleDisplays;
            this.detectChanges();
        }));
    }

    // <editor-fold desc="View Helpers / Actions">

    public expandUsers() {
        this.loadUsers(this.offset + this.limit);
    }

    public projectTypeClicked(projectType: ProjectType, row: DisplayTeamRow) {
        this.columnController.getColumns<ProjectListColumn>(ColumnTypes.ProjectList)
            .forEach(column => {
                const cell = row.getCell<ProjectListCell>(column);
                cell.projectListConfiguration.setProjectType(projectType);
            });
        row.reloadProjectListEvent.emit();
    }

    public onIsMainDisplayExpandedChanged(isExpanded: boolean) {
        this.isMainDisplayExpanded = isExpanded;
        this.localStorage.setIsMainDisplayExpanded(this.isMainDisplayExpanded);
        window.dispatchEvent(new Event('resize'));
    }

    public onIsTopDisplayExpandedChanged(isExpanded: boolean) {
        this.isTopDisplayExpanded = isExpanded;
        this.localStorage.setIsTopDisplayExpanded(this.isTopDisplayExpanded);
        window.dispatchEvent(new Event('resize'));
    }

    public limitAll() {
        this.listsExpanded = false;
        this.rows.forEach(row => row.emitMinimizeEvent());
    }

    public expandLists() {
        this.listsExpanded = true;
        this.rows.forEach(row => row.emitLoadAllEvent());
    }

    public onCaptureScreenshotBtnClicked() {
        if (this.isCapturingScreenshot) {
            return;
        }
        this.isCapturingScreenshot = true;
        ScreenshotHelper.Capture(this.dataTableContainer.nativeElement, () => {
            this.isCapturingScreenshot = false;
            this.detectChanges();
        }, {selector: document.getElementById('klartboard')});
    }

    public onColumnSortChanged(tableColumn: BaseTableColumn) {
        if (tableColumn.column.implementsSorting) {
            const sortableColumn = tableColumn.column as unknown as SortableColumnInterface;
            this.rows.forEach(row => {
                sortableColumn.applyRowSort(row, tableColumn);
            });
        }

        if (tableColumn.column.implementsDataFetching) {
            const column = tableColumn.column as unknown as ColumnDataFetcherInterface;
            column.getDataFetchers().forEach(fetcher => fetcher.execute());
        }
    }

    public onMoveUpBtnClicked(row: DisplayTeamRow) {
        const currentIndex = this.rows.indexOf(row);
        if (currentIndex > 0) {
            const rowsTmp = [...this.rows];
            rowsTmp.splice(currentIndex, 1);
            rowsTmp.splice(currentIndex - 1, 0, row);
            this.rows = rowsTmp;
            this.sortHelper?.savePositions(rowsTmp.map(row => row.userCard.item));
        }
    }

    public onMoveDownBtnClicked(row: DisplayTeamRow) {
        const currentIndex = this.rows.indexOf(row);
        if (currentIndex < this.rows.length - 1) {
            const rowsTmp = [...this.rows];
            rowsTmp.splice(currentIndex, 1);
            rowsTmp.splice(currentIndex + 1, 0, row);
            this.rows = rowsTmp;
            this.sortHelper?.savePositions(rowsTmp.map(row => row.userCard.item));
        }
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    public loadData() {
        if (!this.displayFilter || !this.initialized) {
            return;
        }
        this.sharedComponentResizeObserverCache.clear();
        this.clearRows();
        this.loadUsers(this.offset, true, true);
    }

    private clearRows() {
        this.rows = [];
        this.rowsMap = new Map();
        this.offset = 0;
        this.detectChanges();
    }

    private loadUsers(offset: number, doItems: boolean = true, doCount: boolean = false) {
        this.isLoading = true;

        this.usersService.getUsersByDepartment(this.department.id, items => {

            const globalSettings = this.filterGlobalService.getActiveSettings();

            // Apply sort (Name column can overwrite display filter
            const userCardTableColumn = this.columnController.getFirstVisibleTableColumn(ColumnTypes.UserCard);
            const overwriteSort = userCardTableColumn?.activeSortItem !== undefined;
            const sortType = overwriteSort
                ? userCardTableColumn.activeSortItem?.sortId
                : this.displayFilter.sort_type;
            const sortDirection = overwriteSort
                ? userCardTableColumn.activeSortDirection
                : globalSettings.activeSortDirection;

            this.sortHelper.performSort(items, sortType, sortDirection, items => {

                // Optional filter
                if (this.filterGlobalService.getActiveSettings().activeUsers?.length > 0) {
                    const userIds = this.filterGlobalService.getActiveSettings().activeUsers.map(u => u.id);
                    items = items.filter(item => userIds.includes(item.id));
                }

                if (doItems) {
                    const users = items.slice(offset, offset + this.limit);
                    this.offset = offset;
                    this.isLoading = false;

                    this.dataFetcherCollection.cancel();
                    this.columnController.getVisibleColumns()
                        .filter(column => column.implementsDataFetching)
                        .forEach(column => {
                            this.dataFetcherCollection.add((column as unknown as ColumnDataFetcherInterface).getDataFetchers());
                        });
                    this.dataFetcherCollection.clearFetchers();

                    this.columnController.getColumns<ProjectListColumn>(ColumnTypes.ProjectList)
                        .forEach(column => {
                            column.dataFetcher.setUserIds(users.map(user => user.id));
                        });

                    const rows = users.map(user => this.createRow(user));

                    this.dataFetcherCollection.execute(() => {
                        rows.forEach(row => this.addRow(row));

                        this.enableRowPositioning = sortType == Filters.SortUserDefined;
                        this.detectChanges();
                    });

                    this.detectChanges();
                }

                if (doCount) {
                    this.usersCount = items.length;
                    this.detectChanges();
                }
            });

        });
    }

    private prepareRow(): DisplayTeamRow {
        return new DisplayTeamRow();
    }

    private createRow(user: User): DisplayTeamRow {
        const updating = this.rowsMap.has(user.id);
        const row = updating ? this.rowsMap.get(user.id) : this.prepareRow();

        let start = this.filterGlobalService.getActiveSettings().period.start;
        let end = this.filterGlobalService.getActiveSettings().period.end;

        const userCardConfig = new CardUserConfiguration();
        if (this.displayFilter.period_type == Filters.PeriodThisWeek) {
            userCardConfig.setPeriod(start, moment(end).endOf('day').toDate());
        } else {
            userCardConfig.setPeriod(moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate());
        }
        row.userCard = new CardItem(user, userCardConfig);

        // Create cells
        this.columnController.getAllColumns().forEach(column => {
            if (column instanceof UserCardColumn) {
                row.addCell(column.createCell(row, row.userCard));
            } else {
                row.addCell(column.createCell(row));
            }
        });

        let archivedSince = moment(this.filterGlobalService.getActiveSettings().period.start)
            .subtract(7, 'days')
            .startOf('day');

        this.columnController.getColumns<ProjectListColumn>(ColumnTypes.ProjectList)
            .forEach(column => {

                const projectListCell = row.getCell<ProjectListCell>(column);
                projectListCell.projectListConfiguration
                    //.setLimit(2)
                    .setLimit(ListConfiguration.SmartLimit)
                    //.setDepartment(this.department) https://podio.com/klartboard/softwareudvikling/apps/stories/items/223
                    .setOrderBy([['main_status.status_id', 'desc'], ['title', 'asc']])
                    .setArchived(false)
                    .setUseGlobalFilter(true)
                    .setUseGlobalSearchFilter(false)
                    .setUser(user)
                    .setOnScreenFilters(this.onScreenFilters)
                    .setShowCreateNew(true)
                    .setCreatePresetGenerators([
                        // Defaults
                        new ProjectStatusPresetGenerator(ProjectStatusTypes.Normal, StatusTypes.GREEN),
                        new ProjectUseStatusRulesPresetGenerator(true),
                        new ProjectDepartmentPresetGenerator(this.department.id),

                        new ProjectUserPresetGenerator(ProjectUserTypes.Responsible, user.id),
                    ]);
            });

        this.columnController.getColumns<MilestoneListColumn>(ColumnTypes.MilestoneList)
            .forEach(column => {
                const cell = row.getCell<MilestoneListCell>(column);
                cell.milestoneListConfiguration
                    .setLimit(ListConfiguration.SmartLimit)
                    .setShowProjectMiniCard(true)
                    .setResponsible(user)
                    .setDeadlineDateMin(start)
                    .setDeadlineDateMax(end)
                    .setUseGlobalFilter(true)
                    .setArchived(false)
                    .setShowCreateNew(true)
                    .setCreatePresetGenerators([
                        // Defaults
                        new MilestoneStatusPresetGenerator(StatusTypes.GREEN),
                        new MilestoneUseStatusRulesPresetGenerator(true),

                        new MilestoneResponsiblePresetGenerator(user.id),
                        new MilestoneDeadlinePresetGenerator(start, false),
                    ]);
            });

        this.columnController.getColumns<CaseListColumn>(ColumnTypes.CaseList)
            .forEach(column => {
                column.setPeriod(start, end);

                const cell = row.getCell<CaseListCell>(column);
                cell.taskListConfiguration
                    .setLimit(ListConfiguration.SmartLimit)
                    //.setDepartment(this.department) https://podio.com/klartboard/softwareudvikling/apps/stories/items/223
                    .setShowProjectMiniCard(false)
                    .setUseGlobalFilter(true)
                    .setUseGlobalSearchFilter(false)
                    .setPlannedBetween(start, end)
                    .setUser(user)
                    .setShowCreateNew(true)
                    .setCreatePresetGenerators([
                        new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                        new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                        new TaskUseStatusRulesPresetGenerator(true),
                        new TaskDepartmentPresetGenerator(this.department.id),
                        new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id, Deadline.Create(start, false)),
                    ]);
            });

        this.columnController.getColumns<TaskListSoftThisWeekColumn>(ColumnTypes.TaskList_SoftThisWeek)
            .forEach(column => {
                column.setPeriod(start, end);

                const cell = row.getCell<TaskListSoftThisWeekCell>(column);
                //.setDepartment(this.department) https://podio.com/klartboard/softwareudvikling/apps/stories/items/223
                //.setLimit(2)
                cell.listConfiguration
                    .setLimit(ListConfiguration.SmartLimit)
                    .setUseGlobalFilter(true)
                    .setUseGlobalSearchFilter(false)
                    .setArchived(false).setOrArchivedSince(archivedSince.toDate())
                    .setUser(user)
                    .setPlannedBetween(start, end)
                    .setEnableDragDropAutoMove(true)
                    .setOrderBy(column.getOrderBy(this.displayFilter.extra_sort1_type, this.displayFilter.extra_sort1_direction))
                    .setOnScreenFilters(this.onScreenFilters)
                    .setDraggable([Filters.FilterPlannedInPeriod, Filters.FilterDeadlineInPeriod].includes(this.displayFilter.filter_type))
                    .setShowCreateNew(true);

                switch (this.displayFilter.filter_type) {
                    case Filters.FilterPlannedInPeriod:
                        cell.listConfiguration
                            .setCreatePresetGenerators([
                                new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                new TaskUseStatusRulesPresetGenerator(true),
                                new TaskDepartmentPresetGenerator(this.department.id),
                                new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id, Deadline.Create(start, true)),
                            ]);
                        break;
                    case Filters.FilterDeadlineInPeriod:
                        cell.listConfiguration
                            .setDeadlineBetween(start, end)
                            .setCreatePresetGenerators([
                                new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                new TaskUseStatusRulesPresetGenerator(true),
                                new TaskDepartmentPresetGenerator(this.department.id),
                                new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id, Deadline.Create(start, true)),
                                new TaskDeadlinePresetGenerator(TaskDeadlineTypes.Normal, start, false),
                            ]);
                        break;
                    case Filters.FilterCreatedInPeriod:
                        cell.listConfiguration
                            .setCreatedBetween(start, end)
                            .setCreatePresetGenerators([
                                new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                new TaskUseStatusRulesPresetGenerator(true),
                                new TaskDepartmentPresetGenerator(this.department.id),
                                new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id, Deadline.Create(start, true)),
                            ]);
                        break;
                    case Filters.FilterArchivedInPeriod:
                        cell.listConfiguration
                            .setArchivedBetween(start, end).setArchived(true)
                            .setCreatePresetGenerators([
                                new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                new TaskUseStatusRulesPresetGenerator(true),
                                new TaskDepartmentPresetGenerator(this.department.id),
                                new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id, Deadline.Create(start, true)),
                            ]);
                        break;
                }
            });

        this.columnController.getColumns<TaskListColumn>(ColumnTypes.TaskList)
            .forEach(column => {
                column.setPeriod(start, end);

                const cell = row.getCell<TaskListCell>(column);
                //.setDepartment(this.department) https://podio.com/klartboard/softwareudvikling/apps/stories/items/223
                //.setLimit(2)
                cell.listConfiguration
                    .setLimit(ListConfiguration.SmartLimit)
                    .setUseGlobalFilter(true)
                    .setUseGlobalSearchFilter(false)
                    .setArchived(false).setOrArchivedSince(archivedSince.toDate())
                    .setUser(user)
                    // .setAvoidTaskTypeIds([this.getConfigCaseTaskTypeId()])
                    .setEnableDragDropAutoMove(true)
                    .setOrderBy(column.getOrderBy(this.displayFilter.extra_sort1_type, this.displayFilter.extra_sort1_direction))
                    .setOnScreenFilters(this.onScreenFilters)
                    .setDraggable([Filters.FilterPlannedInPeriod, Filters.FilterDeadlineInPeriod].includes(this.displayFilter.filter_type))
                    .setShowCreateNew(true);

                switch (this.displayFilter.filter_type) {
                    case Filters.FilterPlannedInPeriod:
                        cell.listConfiguration
                            .setPlannedBetween(start, end)
                            .setCreatePresetGenerators([
                                new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                new TaskUseStatusRulesPresetGenerator(true),
                                new TaskDepartmentPresetGenerator(this.department.id),
                                new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id, Deadline.Create(start, false)),
                            ]);
                        break;
                    case Filters.FilterDeadlineInPeriod:
                        cell.listConfiguration
                            .setDeadlineBetween(start, end)
                            .setCreatePresetGenerators([
                                new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                new TaskUseStatusRulesPresetGenerator(true),
                                new TaskDepartmentPresetGenerator(this.department.id),
                                new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id),
                                new TaskDeadlinePresetGenerator(TaskDeadlineTypes.Normal, start, false),
                            ]);
                        break;
                    case Filters.FilterCreatedInPeriod:
                        cell.listConfiguration
                            .setCreatedBetween(start, end)
                            .setCreatePresetGenerators([
                                new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                new TaskUseStatusRulesPresetGenerator(true),
                                new TaskDepartmentPresetGenerator(this.department.id),
                                new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id),
                            ]);
                        break;
                    case Filters.FilterArchivedInPeriod:
                        cell.listConfiguration
                            .setArchivedBetween(start, end).setArchived(true)
                            .setCreatePresetGenerators([
                                new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                new TaskUseStatusRulesPresetGenerator(true),
                                new TaskDepartmentPresetGenerator(this.department.id),
                                new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id),
                            ]);
                        break;
                }
            });

        this.columnController.getColumns<TaskListSoftNextWeekColumn>(ColumnTypes.TaskList_SoftNextWeek)
            .forEach(column => {
                column.setPeriod(start, end);

                const cell = row.getCell<TaskListSoftNextWeekCell>(column);
                //.setDepartment(this.department) https://podio.com/klartboard/softwareudvikling/apps/stories/items/223
                //.setLimit(2)
                cell.listConfiguration
                    .setLimit(ListConfiguration.SmartLimit)
                    .setUseGlobalFilter(true)
                    .setUseGlobalSearchFilter(false)
                    .setArchived(false).setOrArchivedSince(archivedSince.toDate())
                    .setUser(user)
                    .setPlannedBetween(
                        moment(start).startOf('isoWeek').add(1, 'week').toDate(),
                        moment(end).endOf('isoWeek').add(1, 'week').toDate()
                    )
                    .setEnableDragDropAutoMove(true)
                    .setOrderBy(column.getOrderBy(this.displayFilter.extra_sort1_type, this.displayFilter.extra_sort1_direction))
                    .setOnScreenFilters(this.onScreenFilters)
                    .setDraggable([Filters.FilterPlannedInPeriod, Filters.FilterDeadlineInPeriod].includes(this.displayFilter.filter_type))
                    .setShowCreateNew(true);

                switch (this.displayFilter.filter_type) {
                    case Filters.FilterPlannedInPeriod:
                        cell.listConfiguration
                            .setCreatePresetGenerators([
                                new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                new TaskUseStatusRulesPresetGenerator(true),
                                new TaskDepartmentPresetGenerator(this.department.id),
                                new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id, Deadline.Create(
                                    moment(start).startOf('isoWeek').add(1, 'week').toDate(),
                                    true
                                )),
                            ]);
                        break;
                    case Filters.FilterDeadlineInPeriod:
                        cell.listConfiguration
                            .setDeadlineBetween(start, end)
                            .setCreatePresetGenerators([
                                new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                new TaskUseStatusRulesPresetGenerator(true),
                                new TaskDepartmentPresetGenerator(this.department.id),
                                new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id, Deadline.Create(
                                    moment(start).startOf('isoWeek').add(1, 'week').toDate(),
                                    true
                                )),
                                new TaskDeadlinePresetGenerator(TaskDeadlineTypes.Normal, start, false),
                            ]);
                        break;
                    case Filters.FilterCreatedInPeriod:
                        cell.listConfiguration
                            .setCreatedBetween(start, end)
                            .setCreatePresetGenerators([
                                new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                new TaskUseStatusRulesPresetGenerator(true),
                                new TaskDepartmentPresetGenerator(this.department.id),
                                new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id, Deadline.Create(
                                    moment(start).startOf('isoWeek').add(1, 'week').toDate(),
                                    true
                                )),
                            ]);
                        break;
                    case Filters.FilterArchivedInPeriod:
                        cell.listConfiguration
                            .setArchivedBetween(start, end).setArchived(true)
                            .setCreatePresetGenerators([
                                new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                new TaskUseStatusRulesPresetGenerator(true),
                                new TaskDepartmentPresetGenerator(this.department.id),
                                new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id, Deadline.Create(
                                    moment(start).startOf('isoWeek').add(1, 'week').toDate(),
                                    true
                                )),
                            ]);
                        break;
                }
            });

        this.columnController.getColumns<AppointmentListColumn>(ColumnTypes.AppointmentList)
            .forEach(column => {
                const cell = row.getCell<AppointmentListCell>(column);
                cell.appointmentListConfiguration
                    .setLimit(2)
                    .setOrderBy([['updated', 'desc'], ['created', 'desc']])
                    .setUser(user);
                if (this.displayFilter.period_type == Filters.PeriodThisWeek) {
                    cell.appointmentListConfiguration.setUserPeriod(start, end);
                } else {
                    cell.appointmentListConfiguration.setUserPeriod(moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate());
                }
            });

        this.columnController.getColumns<TodoListColumn>(ColumnTypes.TodoList)
            .forEach(column => {
                const cell = row.getCell<TodoListCell>(column);
                cell.todoListConfiguration
                    //.setLimit(2)
                    .setLimit(ListConfiguration.SmartLimit)
                    .setArchived(false)
                    .setUseGlobalFilter(true)
                    .setUseGlobalSearchFilter(false)
                    .setUser(user)
                    .setShowCreateNew(true)
                    .setCreatePresetGenerators([
                        new TodoUserPresetGenerator(user.id),
                    ]);
            });

        this.columnController.getColumns<DaysColumn>(ColumnTypes.Days)
            .forEach(column => {
                column.setDate(start);

                const cell = row.getCell<DaysCell>(column);
                cell.getTaskListConfigurationsMap().forEach((taskListConfiguration, weekDay) => {
                    taskListConfiguration
                        //.setDepartment(this.department) https://podio.com/klartboard/softwareudvikling/apps/stories/items/223
                        //.setLimit(2)
                        .setLimit(ListConfiguration.SmartLimit)
                        .setArchived(false)
                        .setOrArchivedSince(archivedSince.toDate())
                        .setUseGlobalFilter(true)
                        .setUseGlobalSearchFilter(false)
                        .setUser(user)
                        .setOrderBy(column.getOrderBy(this.displayFilter.extra_sort1_type, this.displayFilter.extra_sort1_direction))
                        .setOnScreenFilters(this.onScreenFilters)
                        .setDraggable([Filters.FilterPlannedInPeriod, Filters.FilterDeadlineInPeriod].includes(this.displayFilter.filter_type))
                        .setSharedComponentResizeObserver(this.sharedComponentResizeObserverCache.get(`day-${weekDay}`))
                        .setShowCreateNew(true);

                    const dayStart = cell.getDate(weekDay, start);
                    const dayEnd = column.isLastVisibleDay(weekDay)
                        ? moment(dayStart).endOf('isoWeek').toDate()
                        : moment(dayStart).endOf('day').toDate();

                    switch (this.displayFilter.filter_type) {
                        case Filters.FilterPlannedInPeriod:
                            taskListConfiguration
                                .setPlannedBetween(dayStart, dayEnd)
                                .setCreatePresetGenerators([
                                    new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                    new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                    new TaskUseStatusRulesPresetGenerator(true),
                                    new TaskDepartmentPresetGenerator(this.department.id),
                                    new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id, Deadline.Create(dayStart, false)),
                                ]);
                            break;
                        case Filters.FilterDeadlineInPeriod:
                            taskListConfiguration
                                .setDeadlineBetween(dayStart, dayEnd)
                                .setCreatePresetGenerators([
                                    new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                    new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                    new TaskUseStatusRulesPresetGenerator(true),
                                    new TaskDepartmentPresetGenerator(this.department.id),
                                    new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id),
                                    new TaskDeadlinePresetGenerator(TaskDeadlineTypes.Normal, dayStart, false),
                                ]);
                            break;
                        case Filters.FilterCreatedInPeriod:
                            taskListConfiguration
                                .setCreatedBetween(dayStart, dayEnd)
                                .setCreatePresetGenerators([
                                    new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                    new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                    new TaskUseStatusRulesPresetGenerator(true),
                                    new TaskDepartmentPresetGenerator(this.department.id),
                                    new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id),
                                ]);
                            break;
                        case Filters.FilterArchivedInPeriod:
                            taskListConfiguration
                                .setArchivedBetween(dayStart, dayEnd).setArchived(true)
                                .setCreatePresetGenerators([
                                    new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                                    new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                                    new TaskUseStatusRulesPresetGenerator(true),
                                    new TaskDepartmentPresetGenerator(this.department.id),
                                    new TaskUserPresetGenerator(TaskUserTypes.Participant, user.id),
                                ]);
                            break;
                    }
                });
            });

        if (updating) {
            row.reload();
        }

        return row;
    }

    private addRow(row: DisplayTeamRow) {
        if (!this.rowsMap.has(row.userCard.item.id)) {
            this.rows.push(row);
            this.rowsMap.set(row.userCard.item.id, row);
        }
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    protected onAfterDisplay() {
        super.onAfterDisplay();

        this.columnController.loadColumns(this.displayId, () => {
            this.columnController
                .getColumns<DaysColumn>(ColumnTypes.Days)
                .forEach(column => column.setDate(this.filterGlobalService.getActiveSettings().period.start));

            this.initialize();
        });
    }

    private initialize() {
        this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
            this.tableColumns = this.columnController.getVisibleTableColumns();
            this.loadData();
        }));

        this.subscribe(this.shellFilterGroup.onActiveFilterChangeEventSubscribe(displayFilter => {
            this.clearRows(); // Clear rows to avoid lists to reload cause of onSettingsChangeEvent

            this.displayFilter = displayFilter;
            this.displayFilter.display = this.display;

            switch (this.displayFilter.period_type) {
                case Filters.PeriodThisWeek:
                case Filters.PeriodLast14Days:
                case Filters.PeriodNext14Days:
                case Filters.PeriodNext2Week:
                case Filters.PeriodNext7Days:
                case Filters.PeriodLast7Days:
                case Filters.PeriodNextWeek:
                case Filters.PeriodNext3Week:
                case Filters.PeriodNextMonth:
                    this.filterGlobalService.setShowWeekPicker(true);
                    break;
                case Filters.PeriodTomorrow:
                case Filters.PeriodTodayTomorrow:
                case Filters.PeriodToday:
                    this.filterGlobalService.setShowDatePicker(true);
                    break;
                default:
                    this.filterGlobalService.setShowWeekPicker(false);
                    this.filterGlobalService.setShowDatePicker(false);
                    break;
            }

            // No need to call loadData here, Filter change will also trigger onSettingsChangeEvent
        }));
        this.initialized = true;
        this.loadData();
    }

    private buildColumns() {
        this.columnController.addColumnTypes([
            new UserCardColumnType(this.userTemplate, this.headerTemplateNavigation),
            new ProjectListColumnType(this.projectsTemplate, this.headerTemplate),
            new MilestoneListColumnType(this.milestonesTemplate, this.headerTemplate),
            new CaseListColumnType(this.casesTemplate, this.headerTemplate),
            new TaskListSoftThisWeekColumnType(this.taskListSoftThisWeekTemplate, this.headerTemplate, this.onScreenFilters),
            new TaskListColumnType(this.tasksTemplate, this.headerTemplate, this.onScreenFilters),
            new TaskListSoftNextWeekColumnType(this.taskListSoftNextWeekTemplate, this.headerTemplate, this.onScreenFilters),
            new AppointmentListColumnType(this.appointmentsTemplate, this.headerTemplate),
            new TodoListColumnType(this.todosTemplate, this.headerTemplate),
            new DaysColumnType(this.daysTemplate, this.headerDateTemplate, this.onScreenFilters),
        ]);
    }

    private recalculateTableHeight() {
        if (this.table) {
            this.table.recalculateColumns(this.tableColumns);
            this.rows = [...this.rows]; //This is input into <ngx-datatable>
            this.tableColumns = [...this.tableColumns]; //This is input into <ngx-datatable>
            this.table.recalculate(); //ngx-datatable reference
        }
        this.updateTableWidth();
    }

    private readLocalStorage() {
        this.localStorage = new LocalStorage(window.location.pathname);
        this.isTopDisplayExpanded = this.localStorage.isTopDisplayExpanded;
        this.isMainDisplayExpanded = this.localStorage.isMainDisplayExpanded;
    }

    // </editor-fold>

}
