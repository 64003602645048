<div class="form-group flex-grow-1 align-items-start position-relative w-100 h-100"
     [ngbPopover]="popoverContent"
     [autoClose]="false"
     [placement]="['auto']"
     triggers="manual"
     container="body"
     #popover="ngbPopover">

    <div class="position-relative w-100 h-100" style="max-width: 100vw;">
        <ul class="list-unstyled d-flex justify-content-start align-items-center rounded pb-1 ps-2 pe-2 -corner-item left text-small action-panel"
            [class.no-hover]="!description"
        >
            <li [ngbTooltip]="description ? ('_global_edit' | translate) : null" *ngIf="model">
                <a class="text-center me-1 ms-1"

                   (click)="$event.stopPropagation(); popover.isOpen() ? popover.close() : popover.open();">
                    <i class="fal " aria-hidden="true"
                       [class.fa-edit]="description"
                       [class.fa-plus-circle]="!description"
                    ></i></a>
            </li>
            <li [ngbTooltip]="(isExpanded ? ('_ui_show_less' | translate) : ('_ui_expand_lists' | translate)) + '...'"
                *ngIf="itemCount > 1"
                (click)="toggleExpanded()"
            >
                <a class="text-center me-1 ms-1"

                >
                    <i class="fa-duotone"
                       [class.fa-circle-chevron-up]="isExpanded"
                       [class.fa-circle-chevron-down]="!isExpanded"
                       aria-hidden="true"></i>
                </a>
            </li>
        </ul>
        <ul class="list-unstyled user-items" [innerHTML]="description" [class.pe-3]="!isExpanded"
            [class.pt-1]="!isExpanded"></ul>
        <div class="badge rounded-pill badge-danger position-absolute badge-counter" style="top:0; right: 0;"
             *ngIf="itemCount > 1 && !isExpanded">{{itemCount}}</div>

    </div>
</div>

<!-- Popover dialog content -->
<ng-template #popoverContent>
    <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>
    <app-who-when-edit
        [model]="model"
        (onUpdated)="render()"

    ></app-who-when-edit>
</ng-template>
