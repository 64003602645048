import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditStatusConfiguration} from "@app/editor/quick-editor/editors/generic/status-editor/EditStatusConfiguration";
import {EditStatus} from "@app/editor/quick-editor/editors/generic/status-editor/EditStatus";
import {HasTypeProperties} from "@app/interfaces/HasTypeProperties";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";

@Component({
    selector: 'app-status-editor',
    templateUrl: './status-editor.component.html',
    styleUrls: ['./status-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusEditorComponent extends BaseEditor<EditStatus> {
    protected eventFieldName = 'main-status';

    @Input() model: HasTypeProperties & EditStatus & HasEventGenerator & BaseModel;
    @Input() configuration: EditStatusConfiguration;
    @Input() editorEvents: EditorEvents;

    public value: number;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    protected render() {
        this.value = this.model.getStatus();
        this.detectChanges();
    }

    public save(value: number) {
        this.value = value;
        if (this.model.getStatus() != this.value) {
            this.model.setStatus(this.value);
            this.onItemUpdated();
        }
    }


}
