/**
 * Created by ModelParser
 */
import {DisplayType} from '../DisplayType';
import {ProjectField} from '../ProjectField';
import {DisplaysSetting} from '../DisplaysSetting';
import {SettingOption} from '../SettingOption';
import {DisplayFiltersSetting} from '../DisplayFiltersSetting';
import {DisplayFilter} from '../DisplayFilter';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class SettingDefinition extends BaseModel {
    display_type_id?: number;
    display_type?: DisplayType;
    name?: string;
    comment?: string;
    type?: string;
    value?: string;
    min?: number;
    max?: number;
    reference?: string;
    parent_id?: number;
    checked?: number;
    disabled?: number;
    default_reference_id?: number;
    index_?: number;
    project_field_id?: number;
    project_field?: ProjectField;
    is_sortable?: boolean;
    displays_settings?: DisplaysSetting[];
    setting_options?: SettingOption[];
    display_filters_settings?: DisplayFiltersSetting[];
    display_filters?: DisplayFilter[];
    admin_related_field_name?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.display_type_id;
            delete this.display_type;
            delete this.name;
            delete this.comment;
            delete this.type;
            delete this.value;
            delete this.min;
            delete this.max;
            delete this.reference;
            delete this.parent_id;
            delete this.checked;
            delete this.disabled;
            delete this.default_reference_id;
            delete this.index_;
            delete this.project_field_id;
            delete this.project_field;
            delete this.is_sortable;
            delete this.displays_settings;
            delete this.setting_options;
            delete this.display_filters_settings;
            delete this.display_filters;
            delete this.admin_related_field_name;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.display_type_id != null) {
            this.display_type_id = data.display_type_id;
        }
        if (data.display_type != null) {
            this.display_type = new DisplayType(data.display_type);
        }
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.comment != null) {
            this.comment = data.comment;
        }
        if (data.type != null) {
            this.type = data.type;
        }
        if (data.value != null) {
            this.value = data.value;
        }
        if (data.min != null) {
            this.min = data.min;
        }
        if (data.max != null) {
            this.max = data.max;
        }
        if (data.reference != null) {
            this.reference = data.reference;
        }
        if (data.parent_id != null) {
            this.parent_id = data.parent_id;
        }
        if (data.checked != null) {
            this.checked = data.checked;
        }
        if (data.disabled != null) {
            this.disabled = data.disabled;
        }
        if (data.default_reference_id != null) {
            this.default_reference_id = data.default_reference_id;
        }
        if (data.index_ != null) {
            this.index_ = data.index_;
        }
        if (data.project_field_id != null) {
            this.project_field_id = data.project_field_id;
        }
        if (data.project_field != null) {
            this.project_field = new ProjectField(data.project_field);
        }
        if (data.is_sortable != null) {
            this.is_sortable = data.is_sortable;
        }
        if (data.displays_settings != null) {
            this.displays_settings = data.displays_settings.map((i: any) => new DisplaysSetting(i));
        }
        if (data.setting_options != null) {
            this.setting_options = data.setting_options.map((i: any) => new SettingOption(i));
        }
        if (data.display_filters_settings != null) {
            this.display_filters_settings = data.display_filters_settings.map((i: any) => new DisplayFiltersSetting(i));
        }
        if (data.display_filters != null) {
            this.display_filters = data.display_filters.map((i: any) => new DisplayFilter(i));
        }
        if (data.admin_related_field_name != null) {
            this.admin_related_field_name = data.admin_related_field_name;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
