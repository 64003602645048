import {NgModule} from "@angular/core";
import {TemplatesComponent} from "@app/pages/administrator/templates/templates.component";
import {RouterModule} from "@angular/router";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {
    MilestonePlanEditorModule
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-editor/MilestonePlanEditor.module";
import {
    MilestonePlanListModule
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-list/MilestonePlanList.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ToggleItemModule,
        TranslateModule,
        MilestonePlanEditorModule,
        MilestonePlanListModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TemplatesComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class TemplatesModule {}
