export class ProjectCountResponse {
    user_id: number;
    statuses: {
        status_id: number,
        count: number
    }[];

    constructor(data?: any) {
        this.user_id = data.user_id;
        this.statuses = data.statuses.map((status: any) => {
            return {status_id: status.status_id, count: status.count};
        });
    }

}