/**
 * Created by ModelParser
 */
import {EstimateUnit} from '../EstimateUnit';
import {Task} from '../Task';
import {ProjectEstimate} from '../ProjectEstimate';
import {User} from '../User';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class EstimateDefinition extends BaseModel {
    value?: number;
    estimate_unit_id?: number;
    estimate_unit?: EstimateUnit;
    tasks?: Task[];
    project_estimates?: ProjectEstimate[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.value;
            delete this.estimate_unit_id;
            delete this.estimate_unit;
            delete this.tasks;
            delete this.project_estimates;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.value != null) {
            this.value = data.value;
        }
        if (data.estimate_unit_id != null) {
            this.estimate_unit_id = data.estimate_unit_id;
        }
        if (data.estimate_unit != null) {
            this.estimate_unit = new EstimateUnit(data.estimate_unit);
        }
        if (data.tasks != null) {
            this.tasks = data.tasks.map((i: any) => new Task(i));
        }
        if (data.project_estimates != null) {
            this.project_estimates = data.project_estimates.map((i: any) => new ProjectEstimate(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
