import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router,} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {merge, Observable} from 'rxjs';
import {environment} from '@env/environment';
import {AuthenticationService, I18nService, Logger} from '@app/core';
import {filter, map, mergeMap} from 'rxjs/operators';
import {User} from '@app/core/models/User';
import {RoutingStateService} from '@app/services/routing-state.service';
import {NgbDatepickerConfig, NgbTooltipConfig, NgbTypeaheadConfig} from '@ng-bootstrap/ng-bootstrap';
import 'reflect-metadata';
import {OAuthService} from "angular-oauth2-oidc";
import {JwksValidationHandler} from "angular-oauth2-oidc-jwks";
import {UsersService} from "@app/services/users.service";
import {NgProgress, NgProgressComponent} from "ngx-progressbar";
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {System} from '@app/constants';
import {LocalStorageService} from '@app/services/local-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnChanges, AfterViewInit {

    @ViewChild(NgProgressComponent, {static: true}) progressBar: NgProgressComponent;

    signedIn: Observable<boolean>;
    email: string;
    autologin: boolean = false;
    languageReady: boolean = false;

    constructor(public title: Title,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private localStorageService: LocalStorageService,
                private titleService: Title,
                private translateService: TranslateService,
                private i18nService: I18nService,
                private oAuthService: OAuthService,
                private authService: AuthenticationService,
                private routingStateService: RoutingStateService,
                private ngbTooltipConfig: NgbTooltipConfig,
                private ngbDatepickerConfig: NgbDatepickerConfig,
                private ngbTypeaheadConfig: NgbTypeaheadConfig,
                private usersService: UsersService,
                private dialogService: BaseDialogService,
                private ngProgress: NgProgress,
                private zone: NgZone,
                private cd: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        const themeSelection = this.localStorageService.get(System.CookieTheme);
        let prefersDark = window.matchMedia("(prefers-color-scheme: dark)")?.matches;

        if (themeSelection == undefined) {
            this.localStorageService.set(System.CookieTheme, prefersDark);
        } else {
            prefersDark = themeSelection;
        }

        if (prefersDark) {
            document.body.classList.toggle('dark-theme', prefersDark);
        } else {
            document.body.classList.toggle('light-theme', prefersDark);
        }

        setTimeout(() => {
            const mobileSelection = this.localStorageService.get(System.CookieMobile);
            let prefersMobile = window.matchMedia("(max-width : 480px)")?.matches || false;

            if (prefersMobile && mobileSelection === null) {
                this.localStorageService.set(System.CookieMobile, prefersMobile);
            } else {
                if (mobileSelection === null) {
                    prefersMobile = mobileSelection;
                }
            }

            if (prefersMobile) {
                document.body.classList.add('mobile-theme');
            } else {
                document.body.classList.remove('mobile-theme');
            }
        }, 500)

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
                this.progressBar.complete();
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
                // setTimeout(()=>{
                //     this.progressBar.complete();
                // }, 3000)
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator
                // Present error to user
                console.error(event.error);
            }
        });

        this.i18nService.languageLoaded$.subscribe(value => {
            this.languageReady = value;
            this.cd.detectChanges();
        });

        this.routingStateService.loadRouting();
        this.checkHash();
        this.ngbTypeaheadConfig.container = this.ngbTooltipConfig.container = 'body';

        // Setup logger
        if (environment.production) {
            Logger.enableProductionMode();
            if (window) {
                window.console.log = function() {
                };
            }
        }

        this.title.setTitle('KlartBoard');
        this.signedIn = this.authService.isSignedIn();
        this.authService.userChanged().subscribe(
            (user: User) => {
                this.email = user.username;
                // console.log('userChanged()() : ', user);
            });

        // Setup translations
        this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
        const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

        // Change page title on navigation or language change, based on activatedRoute data
        merge(this.translateService.onLangChange, onNavigationEnd)
            .pipe(
                map(() => {
                    let route = this.activatedRoute;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe(event => {
                const title = event['title'];
                if (title) {
                    this.titleService.setTitle(this.translateService.instant(title));
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        console.warn(changes);
    }

    ngAfterViewInit() {
        this.configureWithNewConfigApi();
    }

    private checkHash() {
        // https://damienbod.com/2017/09/26/auto-redirect-to-an-sts-server-in-an-angular-app-using-oidc-implicit-flow/
        if (window.location.hash) {

        } else {
            this.autologin = true;
        }
    }

    private configureWithNewConfigApi() {
        // .dk domæne
        if (window.location.host.indexOf('localhost') == -1) {
            const host = window.location.origin.split('://')[1];
            environment.OAuthConfig.issuer = location.protocol + '//api-' + host;
        }
        // https://github.com/manfredsteyer/angular-oauth2-oidc
        this.oAuthService.configure(environment.OAuthConfig);
        // let url: string = environment.OAuthConfig.issuer+'/connect/OpenIdConfiguration';
        const url = '/connect/OpenIdConfiguration';

        // URL of the SPA to redirect the user after silent refresh
        this.oAuthService.silentRefreshRedirectUri = window.location.origin + '/silent-refresh.html';

        // Defines the storage.
        this.oAuthService.setStorage(sessionStorage);
        this.oAuthService.tokenValidationHandler = new JwksValidationHandler();

        // Setups silent refresh.
        this.oAuthService.setupAutomaticSilentRefresh();

        // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/359
        // 0. LOAD CONFIG:
        // First we have to check to see how the IdServer is
        // currently configured:
        this.oAuthService.loadDiscoveryDocument(url)

            // 1. HASH LOGIN:
            // Try to log in via hash fragment after redirect back
            // from IdServer from initImplicitFlow:
            .then((doc: any) => {
                this.authService.setItem('discoveryDocument', doc.info.discoveryDocument);
                return this.oAuthService.tryLogin();
            }).catch((err) => {
            console.warn('oAuthService : error: ', err);
            // this.translateService.instant('_global_ok')
            this.dialogService.errorDialog(
                'Der skete en fejl ved forbindelse til serveren',
                null,
                'Prøv igen...',
                'Nulstil cookies',
                null,
                'Det var ikke muligt at forbinde til authentikationsserveren. Kontakt venligst Klartboard hvis dette problem fortsætter.',
                (params) => {
                    console.log('params : ', params)
                    if (!params) {
                        localStorage.clear();
                        this.oAuthService.logOut();
                        // document.location.reload();
                    } else {
                        window.location.reload()
                    }
                }
            );

        })

            .then(() => {
                if (!this.oAuthService.hasValidAccessToken() || this.oAuthService.getAccessToken() == '') {
                    this.oAuthService.initImplicitFlow();
                } else {
                    // Redirect?
                    this.authService.init();
                }
            });


        /* *** */
        this.oAuthService.events.subscribe(e => {
            // tslint:disable-next-line:no-console
            if (e.type == 'discovery_document_loaded' && this.autologin) {
                // console.log('🔑 this.oAuthService.events : discovery_document_loaded : ', e);
                this.authService.setItem('redirectUrl', this.router.routerState.snapshot.url);
                // this.oAuthService.initImplicitFlow(); // 07/01/20 Kevin, 13/03/20 Martin: Slået fra igen, da fejlen stadig eksisterer https://podio.com/klartboard/klartboard-input/apps/support/items/1532
                this.autologin = false;
            }
        });

        this.oAuthService.events
            .pipe(filter(e => e.type === 'token_received'))
            .subscribe(e => {
                // console.log('🔑 this.oAuthService.events : token_received : load user', e);
                this.usersService.init();
            });

        this.oAuthService.events
            .pipe(filter(e => e.type === 'session_terminated'))
            .subscribe(e => {
                // tslint:disable-next-line:no-console
                console.warn('🔑 this.oAuthService.events : oAuth : (session_terminated) : Your session has been terminated!');
            });


        this.oAuthService.events
            .pipe(filter(e => e.type === 'user_profile_loaded'))
            .subscribe(e => {
                this.authService.init();
                let redirectURI = this.authService.getItem('redirectUrl');
                // console.log('🔑 this.oAuthService.events : oAuth : user_profile_loaded :: ', e, 'redirectURI : ', redirectURI );
                // console.log('loadUserProfile() : redirectURI : ', redirectURI);

                if (redirectURI) {
                    this.authService.removeItem('redirectUrl');
                    // Gets the redirect URL.
                    // If no redirect has been set, uses the default.
                    let redirect: string = redirectURI && redirectURI !== '/'
                        ? redirectURI
                        : '/app/home/dashboard';
                    // Redirects the user.

                    console.log('loadDiscoveryDocument() : onTokenReceived > redirect :: ', redirect);

                    // this.router.navigate([Helpers.encodeUri(redirect)], {queryParamsHandling: 'merge'});
                }
            });

        // Events.
        // On silently refreshed.
        this.oAuthService.events.pipe(
            filter(e => {
                if (e.type === 'silently_refreshed') {
                    // console.log('🔑 this.oAuthService.events : silently_refreshed : loadUserProfile() ', e);
                    this.oAuthService.loadUserProfile();
                }

                if (e.type === 'silent_refresh_timeout') {
                    console.log(`🐞 oAuth Event: `, {e});
                }
                return e.type === 'silently_refreshed';
            })
        ).subscribe(e => {
            // console.log('🔑 this.oAuthService.events : this.oAuthService.loadUserProfile() : ', e);
            this.oAuthService.loadUserProfile(); // 18. August 2020 : Fejlfinding ift. serverkald
        });


        // On session terminated. // Fixed: RXJS pipe / filter
        this.oAuthService.events.pipe(
            filter(e => {
                if (e.type === 'session_terminated') {
                    console.log('🔑 this.oAuthService.events : session_terminated : ', e);
                    this.authService.refreshSession();
                }
                return e.type === 'session_terminated';
            })
        ).subscribe(e => {
            this.authService.refreshSession();
        });

        this.oAuthService.events
            .pipe(filter(e => e.type === 'token_received'))
            .subscribe(e => {
                // this.oauthService.loadUserProfile();
                // console.log('🔑 this.oAuthService.events : token_received : ', e);
            });

        // Already authorized.
        if (this.oAuthService.hasValidAccessToken()) {
            this.authService.init();
        }

    }

    logout() {
        this.oAuthService.logOut();
    }

    login() {
        this.oAuthService.tryLogin();
    }
}
