import {GenericCell} from "@app/pages/displays/display-project-details/subdisplay-milestones/Cells/GenericCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-milestones/Row";
import {
    MilestoneEditColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/MilestoneEditColumn";
import {CreateItemSourceConfiguration} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration";
import {CreateItemSourceInterface} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceInterface";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";
import {CreateItemPreset} from "@app/shared/_ui/create-item-dropdown/CreateItemPreset";
import {CreateItemInterface} from "@app/shared/_ui/create-item-dropdown/CreateItemInterface";
import {Task} from "@app/core/models";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {StatusTypes, TaskDeadlineTypes, TaskStatusTypes, TaskUserTypes} from "@app/constants";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskMilestonePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskMilestonePresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {
    TaskDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDeadlinePresetGenerator";

export class MilestoneEditCell extends GenericCell implements CreateItemSourceInterface, CreateItemInterface<Task> {

    public createItemConfiguration = new CreateItemSourceConfiguration();
    public createItemPreset = new CreateItemPreset();
    public createPresetGenerators: CreatePresetGenerator[];

    constructor(row: Row, column: MilestoneEditColumn) {
        super(row, column);

        this.createItemConfiguration.showMilestone = false;
        this.createItemConfiguration.showTodo = false;
        this.createItemConfiguration.showProjects = false;
        this.createItemConfiguration.showTasks = true;
        this.createItemConfiguration.sourceInterface = this;
        this.createItemConfiguration.showEditor = false
        this.createItemPreset.createTaskInterface = this;
        this.createPresetGenerators = [
            // Defaults
            new TaskUserPresetGenerator(TaskUserTypes.Creator, AppInjector.getInjector().get(UsersService).user.id),
            new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
            new TaskUseStatusRulesPresetGenerator(true),

            new TaskProjectPresetGenerator(row.project.id),
            new TaskMilestonePresetGenerator(row.milestoneCard.item.id),
            ...(row.project.departments?.map(department => new TaskDepartmentPresetGenerator(department.id)) ?? []),
            ...(row.milestoneCard.item.deadline ? [new TaskDeadlinePresetGenerator(
                TaskDeadlineTypes.Normal,
                row.milestoneCard.item.deadline.getDate(),
                row.milestoneCard.item.deadline.is_soft
            )] : []),
            ...row.milestoneCard.item.responsible ? [new TaskUserPresetGenerator(
                TaskUserTypes.Participant,
                row.milestoneCard.item.responsible.id,
                row.milestoneCard.item.deadline
            )] : [],
        ];
    }

    prepareSource(): void {
        this.createItemConfiguration.filterTaskTypesById = this.row.project.project_type.project_types_task_types
            ?.filter(projectTypesTaskType => projectTypesTaskType.visible)
            ?.map(projectTypesTaskType => projectTypesTaskType.task_type_id) ?? [];

        // https://podio.com/klartboard/softwareudvikling/apps/supports/items/355
        this.createItemConfiguration.filterByDepartmentIds = AppInjector.getInjector().get(UsersService).user?.departments?.map(d => d.id) || [];
    }

    createPresets(typeId?: any): CreatePreset[] {
        return this.createPresetGenerators?.map(generator => generator.generate(typeId)) ?? [];
    }

}
