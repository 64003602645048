import {NgModule} from "@angular/core";
import {
    MilestoneArchiveDialogComponent
} from "@app/shared/_modals/milestone-archive-dialog/milestone-archive-dialog.component";
import {NgxModalDraggableDirectiveModule} from "@app/directives/NgxModalDraggableDirective.module";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {ItemDateModule} from "@app/shared/_elements/item-date/ItemDate.module";
import {PhaseModule} from "@app/shared/_elements/phase/Phase.module";
import {CardsModule} from "@app/modules/Cards.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        NgxModalDraggableDirectiveModule,
        ColorItemModule,
        TranslateModule,
        NgbTooltipModule,
        LoadingIndicatorModule,
        ItemDateModule,
        CardsModule,
        PhaseModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MilestoneArchiveDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class MilestoneArchiveDialogModule {}
