import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthenticationService} from '@app/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor(private router: Router,
                private authService: AuthenticationService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot,
                       state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.authService.isSignedIn().pipe(
            map((signedIn: boolean) => {

                // console.log('AuthenticationGuard : signedIn : ', signedIn, 'state : redirectURI:', state);
                if (signedIn) {
                    return true;
                }

                // Stores the attempted URL for redirecting.
                if(state.url.indexOf('silent-refresh.html') == -1) {
                    this.authService.setItem('redirectUrl', state.url);
                }
                const IE11 = (navigator.userAgent.indexOf('MSIE')!==-1 || navigator.appVersion.indexOf('Trident/') > -1);

                // Not signed in so redirects to unauthorized page.
                if(!IE11) {
                    if(state.url.indexOf('silent-refresh.html') == -1) {
                        this.authService.setItem('redirectUrl', state.url);
                        console.log('unauthorized : redirect to login : stored URL : ', this.authService.getItem('redirectUrl'))
                    }
                    // this.router.navigate(['/login']);
                }
                return false;
            })
        );
    }

}
