import {ProjectType} from '@app/core/models/ProjectType';
import {Project} from '@app/core/models/Project';
import {Milestone} from '@app/core/models/Milestone';
import {
    AttachedProjectTypeValidator
} from "@app/editor/task-editor-loader/task-editor-v3/components/task-editor-tabs/task-editor-tab-attach-to/attached-project-type/AttachedProjectTypeValidator";

export class AttachedProjectType {

    public projectType: ProjectType;
    public validator: AttachedProjectTypeValidator;

    public project?: Project;
    public milestone?: Milestone;

    constructor(projectType: ProjectType, validator: AttachedProjectTypeValidator, project?: Project) {
        this.projectType = projectType;
        this.validator = validator;
        this.project = project;
    }

    public get hasProject(): boolean {
        return this.project !== undefined;
    }

    public get hasMilestone(): boolean {
        return this.milestone !== undefined;
    }

}
