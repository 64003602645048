import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {CaseUser} from "@app/core/models/Task";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {CaseRow} from "@app/pages/displays/display-cases/CaseRow";
import {WeekColumn} from "@app/pages/displays/display-cases/Columns/WeekColumn";

export class WeekCell extends BaseCell {

    public column: WeekColumn;

    public listConfiguration = new TaskListConfiguration<CaseUser>();

    constructor(row: CaseRow, column: BaseColumn) {
        super(row, column);

        this.listConfiguration
            .setTaskTypeIds(this.column.getTaskTypes())
            .setLimit(100)
            .setModelClass(CaseUser.name);
    }

}
