<div class="position-relative w-100 h-100 ">
    <div class="position-absolute w-100 h-100" style="max-width: 100vw;">
        <ul class="list-unstyled d-flex justify-content-start align-items-center rounded pb-1 ps-2 pe-2 -corner-item left text-small action-panel"
            [class.no-hover]="!hasItems"
        >
            <li [ngbTooltip]="hasItems ? ('_global_edit' | translate) : null" *ngIf="model">

                <a
                    [ngbPopover]="popoverContent"
                    [popoverClass]="'popover-menu'"
                    [autoClose]="true"
                    [placement]="['left', 'right','bottom', 'auto']"
                    triggers="manual"
                    container="body"
                    #popover="ngbPopover"
                    (click)="$event.stopImmediatePropagation(); !popover.isOpen() ? popover.open() : popover.close()"
                    class="text-center me-1 ms-1">
                    <i class="fal" aria-hidden="true"
                       [class.fa-edit]="items?.length"
                       [class.fa-plus-circle]="!items?.length"
                    ></i>
                </a>

                <ng-template #popoverContent>
                    <ul class="list-unstyled m-0 p-0" [class.d-none]="!popover.isOpen()">
                        <li class="dropdown-item cursor-pointer d-flex align-items-center"
                            (click)="popover.close(); openMilestoneEditor()">
                            <i class="fal fa-edit me-1"></i>
                            <span>{{'_ui_quick_editor_milestone_list_btn_create_with_editor_label' | translate}}
                                ...</span></li>
                        <li class="dropdown-item cursor-pointer d-flex align-items-center"
                            (click)="popover.close(); openMilestoneSelectDialog()">
                            <i class="fal fa-diamond me-1"></i>
                            <span>{{'_ui_quick_editor_milestone_list_btn_select_existing_label' | translate}}...</span>
                        </li>
                    </ul>
                </ng-template>

            </li>
            <li *ngIf="!hasItems">
                <a class="text-danger" [ngbTooltip]="('_ui_is_required' | translate)">
                    <i class="fa-regular fa-circle-exclamation me-1"></i>
                </a>
            </li>
        </ul>

        <ng-container *ngIf="hasItems">
            <ng-container *ngFor="let item of items">
                <div class="d-flex align-items-center text-small micro-milestone cursor-pointer"
                     (click)="popover.toggle()"
                     [ngbPopover]="popoverContentMilestone"
                     [autoClose]="false"

                     container="body"
                     triggers="manual"
                     #popover="ngbPopover"
                     [popoverClass]="'popover-sub-header'"
                     [placement]="['bottom','auto']"
                >
                    <app-color-label class="card-type me-1 flex-shrink-0"
                                     [status]="item.milestone.status"
                                     [itemWithTypeProperties]="item.milestone"
                                     [interactive]="false"
                                     [style.width.px]="26"></app-color-label>
                    <span class="ellipsis flex-grow-1 flex-shrink-1">{{ item.milestone.name }}</span>
                    <a class="text-center me-2 ms-auto text-danger" (click)="removeItem(item)">
                        <i
                            [ngbTooltip]="('_ui_emoji_clear' | translate)"
                            class="fal fa-eraser" aria-hidden="true"></i>
                    </a>
                </div>

                <ng-template #popoverContentMilestone>
                    <div class="popover-container sub-header">
                        <div class="rounded mb-1 p-1 milestone-and-tasks">
                            <app-card-milestone [model]="item.milestone" [removable]="true"
                                                (onRemove)="removeItem(item)"></app-card-milestone>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </ng-container>


    </div>
</div>


