import {GenericCell} from "@app/pages/displays/display-project-details/subdisplay-milestones/Cells/GenericCell";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-milestones/Row";
import {
    TaskListStatusColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/TaskListStatusColumn";
import {
    TaskListStatusTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/TaskListStatusTableColumn";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {SubDisplayHelper} from "@app/pages/displays/display-project-details/SubDisplayHelper";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {TaskDeadlineTypes, TaskStatusTypes, TaskUserTypes} from "@app/constants";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskMilestonePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskMilestonePresetGenerator";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {
    TaskDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDeadlinePresetGenerator";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";

export class TaskListStatusCell extends GenericCell {

    public listConfigurations = new Map<string, TaskListConfiguration>();

    constructor(row: Row, column: TaskListStatusColumn) {
        super(row, column);

        const taskTypeIdsFilteredByProjectType = row.project.project_type.project_types_task_types
            ?.filter(projectTypesTaskType => projectTypesTaskType.visible)
            ?.map(projectTypesTaskType => projectTypesTaskType.task_type_id) ?? [];

        column
            .getTableColumns<TaskListStatusTableColumn>()
            .forEach(tableColumn => {
                const listConfiguration = new TaskListConfiguration();

                listConfiguration
                    .setLimit(ListConfiguration.SmartLimit)
                    .setShowProjectMiniCard(false)
                    .setAcceptanceStatus(null)
                    .setProject(row.project)
                    .setMilestone(row.milestoneCard.item)
                    .setMainStatusStatusIds([tableColumn.statusId])
                    .setTaskTypeIds(taskTypeIdsFilteredByProjectType)
                    .setUseGlobalFilter(true)
                    .setCustomOnCardItemDragAddFunction((cardItem, fromConfiguration) => {
                        SubDisplayHelper.DragDropUpdateMilestone(fromConfiguration, listConfiguration, cardItem);
                        SubDisplayHelper.DragDropUpdateStatus(fromConfiguration, listConfiguration, cardItem);
                    })
                    .setDraggable(true)
                    .setShowCreateNew(true)
                    .setCreatePresetGenerators([
                        // Defaults
                        new TaskUserPresetGenerator(TaskUserTypes.Creator, AppInjector.getInjector().get(UsersService).user.id),
                        new TaskUseStatusRulesPresetGenerator(true),
                        new TaskProjectPresetGenerator(row.project.id),

                        new TaskMilestonePresetGenerator(row.milestoneCard.item.id),
                        new TaskStatusPresetGenerator(TaskStatusTypes.Normal, tableColumn.statusId),
                        ...row.project.departments?.map(department => new TaskDepartmentPresetGenerator(department.id)) ?? [],
                        ...row.milestoneCard.item.deadline ? [new TaskDeadlinePresetGenerator(
                            TaskDeadlineTypes.Normal,
                            row.milestoneCard.item.deadline.getDate(),
                            row.milestoneCard.item.deadline.is_soft
                        )] : [],
                        ...row.milestoneCard.item.responsible ? [new TaskUserPresetGenerator(
                            TaskUserTypes.Participant,
                            row.milestoneCard.item.responsible.id,
                            row.milestoneCard.item.deadline
                        )] : [],
                    ]);

                this.listConfigurations.set(tableColumn.status, listConfiguration);
            });
    }

}
