import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {
    Projects_ProjectNoteFieldSettingValue,
} from "@app/core/http/Api/Api";
import {WampService, WampSubscription} from "../../../../../../services/wamp.service";

@Component({
    selector: 'app-project-note-cell',
    templateUrl: './project-note-cell.component.html',
    styleUrls: ['./project-note-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectNoteCellComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() row: ProjectsDisplayRow;
    @Input() tableColumn: GenericTableColumn;

    // Bindings to view
    public value?: string;

    // Data
    private field?: string;

    constructor(private cd: ChangeDetectorRef, private wampService: WampService) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.changeEventSubscription?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['row']) {
            this.setup();
        }
    }

    private changeEventSubscription?: WampSubscription;

    private setup() {
        this.field = this.tableColumn.column.settings.get(ColumnTypeSettings.ProjectNoteField)
            ?.getObject<Projects_ProjectNoteFieldSettingValue>()?.fieldName;

        this.changeEventSubscription?.unsubscribe();
        this.changeEventSubscription = this.wampService.subscribe(
            this.row.project.item.getEventName('note-field', {prop: this.field}),
            (event) => {
                this.row.project.item.populate(event.next, true);
                this.render();
            }
        );

        this.render();
    }

    private render() {
        this.value = this.row.project.item.getText(this.field);
        this.detectChanges();
    }

    public onNoteChanged(value: string) {
        this.row.project.item.setText(this.field, value);
    }

}
