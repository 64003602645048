import {Sort} from '@app/pages/displays/filtering/Sort';
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {CategoryType, Display, DisplayFilter, ProjectType} from "@app/core/models";
import {Period} from "@app/pages/displays/filtering/Period";
import {Filter} from "@app/pages/displays/filtering/Filter";
import {DisplayFilterEditorFormConfigInterface} from "@app/editor/display-filter-editor/DisplayFilterEditorForm";
import {ProjectsService} from "@app/services/projects.service";
import {Settings} from "@app/pages/displays/display-cases/Settings";

export class Filters implements FiltersInterface {

    public autoGenerateNameDefaultValue(): boolean {
        return false;
    }

    public applyDefaults(displayFilter: DisplayFilter) {

    }

    // Filters
    public static FilterAll = 'filter-all';

    // Sorts
    public static SortTitle = 'sort-title';
    public static SortMainStatus= 'sort-main-status';
    public static SortCategoryType= 'sort-category-type-';

    public sorts(display: Display, includePseudoTypes = false): Sort[] {
        let title= new Sort(Filters.SortTitle, this.getTranslation('_ui_title'), true, null, Sort.SortTypeDisplay);
        let status= new Sort(Filters.SortMainStatus, this.getTranslation('_ui_status'), true, null, Sort.SortTypeDisplay);

        let sorts = [
            title,
            status,
        ];

        const config = this.getEditorConfig(display);
        config.categoryTypes?.forEach(categoryType => {
            sorts.push(new Sort(
                Filters.SortCategoryTypeGenerator(categoryType),
                `${categoryType.name} ${this.getTranslation('_filter_show_only', {name: categoryType.name.toLocaleLowerCase()})}`,
                true, null, Sort.SortTypeDisplay));
        });

        return sorts;
    }

    public static SortCategoryTypeGenerator(categoryType: CategoryType): string {
        return `${Filters.SortCategoryType}${categoryType.id}`;
    };
    public static ParseSortCategoryType(sort: string): string {
        return sort.substr(Filters.SortCategoryType.length);
    };

    public static GetBaseSort(sort: string): string {
        // Remove any identifiers from the end
        return sort.replace(/[0-9]/g, '');
    }

    private getTranslation(key: string, interpolateParams?: Object): string {
        return AppInjector.getInjector().get(TranslateService).instant(key, interpolateParams);
    }

    filters(display: Display): Filter[] {
        const all = new Filter(
            Filters.FilterAll,
            this.getTranslation('_ui_all')
        );

        const allFilters = [all];

        allFilters.forEach(filter => {
            filter.showStatus = false;
            filter.showStarred = false;
            filter.showHands = false;
            filter.showColumns = true;
            filter.showReactionTypes = false;
            filter.showCategoryTypes = true;
        });

        return allFilters;
    }

    generateName(displayFilter: DisplayFilter): string {
        return this.getTranslation('_ui_all');
    }

    getEditorConfig(display: Display, result?: (config: DisplayFilterEditorFormConfigInterface) => void): DisplayFilterEditorFormConfigInterface {
        const config: DisplayFilterEditorFormConfigInterface = {};

        if (display == null) {
            // Not fit for editor config
            if (result) {
                result(config);
            }
            return config;
        }

        const projectTypeSetting = display.displays_settings.find(displaySetting => {
            return displaySetting.setting_id === Settings.ProjectTypeId;
        });
        if (projectTypeSetting) {
            const projectsService = AppInjector.getInjector().get(ProjectsService);
            projectsService.getProjectType(Number(projectTypeSetting.value), projectType => {
                // Find category types visible for project type
                const categoryTypesMap = new Map<number, CategoryType>();
                projectType.category_types_project_types
                    ?.filter(categoryTypesProjectType => categoryTypesProjectType.visible)
                    ?.forEach(categoryTypesProjectType => {
                        categoryTypesMap.set(categoryTypesProjectType.category_type_id, categoryTypesProjectType.category_type);
                    });
                config.categoryTypes = Array.from(categoryTypesMap.values());

                if (result) {
                    result(config);
                }
            });
        } else if (result) {
            result(config);
        }

        return config;
    }

    getPeriodEnd(period: string, sourceDate?: Date): Date {
        return undefined;
    }

    getPeriodStart(period: string, sourceDate?: Date): Date {
        return undefined;
    }

    periods(): Period[] {
        return [];
    }

    standards(display: Display): DisplayFilter[] {
        const standards: DisplayFilter[] = [];

        const allByTitle = new DisplayFilter();
        allByTitle.display_id = display.id;
        allByTitle.display = display;
        allByTitle.filter_type = Filters.FilterAll;
        allByTitle.statuses = [];
        allByTitle.sort_type = Filters.SortTitle;
        allByTitle.sort_direction = 'asc';
        standards.push(allByTitle);

        standards.forEach(item => {
            item.name = item.generateName(this);
        });

        return standards;
    }

}
