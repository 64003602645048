<div
    *ngIf="isReady"
    class="container p-2 d-flex gap-1 w-100 flex-wrap editor-tab"
>

    <div
        *ngIf="deadlineListFieldItem.visible || statusRulesFieldItem.visible"
    >
        <label>{{ deadlineListLabel | translate}}</label>
        <div
            class="list-group-item rounded"
        >
            <div class="list-group-item rounded d-flex align-items-center mb-1">
            <app-toggle-item
                class="me-2 "
                [interactive]="true"
                [labelClass]="'faux-label'"
                [icon]="'fa-check-circle'"
                [iconInactive]="'fa-circle'"
                [iconColorActive]="'text-success'"
                [iconColorInactive]="'text-black-50'"
                [label]="('_ui_automove_deadlines' | translate)"
                [tooltipLabel]="''"
                (onToggle)="onToggleIsAutoMoveUserDeadlinesEnabled()"
                [active]="isAutoMoveUserDeadlinesEnabled"
            />
            </div>

            <app-deadline-list-editor
                *ngIf="deadlineListFieldItem.visible"
                [model]="item"
                [configuration]="deadlineListEditorConfiguration"
            />

            <div
                *ngIf="statusRulesFieldItem.visible"
            >
                <div class="mt-1">
                    <div
                        class="custom-control custom-checkbox list-group-item d-flex justify-content-start rounded"
                        [ngbPopover]="(item.use_status_rules && statusRules && statusRules.length > 0) ? popoverContent : null"
                        [autoClose]="'inside'"
                        triggers="mouseenter:mouseleave"
                        container="body"
                        #popover="ngbPopover"
                    >
                        <app-use-status-rules-editor
                            [model]="item"
                            [configuration]="useStatusRulesEditorConfiguration"
                        />
                    </div>
                </div>
                <!-- Popover -->
                <ng-template #popoverContent>
                    <div class="text-dark">
                        <app-status-rule-appender
                            [_internalValue]="statusRules"
                            [readonly]="true"
                        />
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <app-user-list-editor
        [model]="item"
        [configuration]="userListEditorConfiguration"
    />
</div>
