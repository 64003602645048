<div
    *ngIf="isReady"
    class="container p-2 d-flex gap-1 w-100 flex-wrap editor-tab"
>

    <!-- Calculated fields -->
    <div
        *ngIf="showCalculatedFields"
        class="mb-2"
    >
        <label>
            <span>{{'_project_calculated_fields' | translate}}</span>
        </label>
        <div class="list-group-item rounded d-flex flex-column">
            <div *ngFor="let calculatedFieldsMilestone of calculatedFields">
                <label>
                    <span>{{calculatedFieldsMilestone.calculated_field?.name | translate}}</span>
                </label>

                <app-milestone-calculated-field
                    [milestone]="item"
                    [calculatedField]="calculatedFieldsMilestone.calculated_field"
                    [calculatedFieldType]="Constants.CalculatedFieldTypes.Milestone_RelatedTasksEstimateSum"
                    [calculatedFieldsMilestone]="calculatedFieldsMilestone"
                />
            </div>
        </div>
    </div>

    <app-user-list-editor
        *ngIf="showResponsibleEditor"
        [model]="item"
        [configuration]="responsibleEditorConfiguration"
    />

    <div
        *ngIf="showDeadlineEditor || showStatusRulesEditor"
        class="list-group-item rounded"
    >
        <app-deadline-list-editor
            *ngIf="showDeadlineEditor"
            [model]="item"
            [configuration]="deadlineEditorConfiguration"
        />
        <div
            *ngIf="showStatusRulesEditor"
        >
            <div class="mt-1">
                <div
                    class="custom-control custom-checkbox list-group-item d-flex justify-content-start rounded"
                    [ngbPopover]="(item.use_status_rules && statusRules) ? popoverContent : null"
                    [autoClose]="'inside'"
                    triggers="mouseenter:mouseleave"
                    container="body"
                    #popover="ngbPopover"
                >
                    <app-use-status-rules-editor
                        [model]="item"
                        [configuration]="useStatusRulesEditorConfiguration"
                    />
                </div>
            </div>
            <!-- Popover -->
            <ng-template #popoverContent>
                <div class="text-dark">
                    <app-status-rule-appender
                        [_internalValue]="statusRules"
                        [readonly]="true"
                    />
                </div>
            </ng-template>
        </div>
    </div>

    <app-project-list-editor
        *ngIf="showProjectListEditor"
        [model]="item"
        [configuration]="projectListEditorConfiguration"
    />

    <div
        *ngIf="showTaskList"
        class="hide-if-card-expander-empty">
        <label>{{'_display_program_milestone_tasks' | translate}}</label>
        <div class="p-2 list-group-item rounded mb-2">
            <app-task-list
                [configuration]="taskListConfiguration"
                [listClass]="'grid-list'"
                (dataSetChanged)="onTaskListTasksChanged($event)"
            />
        </div>
    </div>

    <app-text-editor
        *ngIf="showNotesTextEditor"
        [model]="item"
        [configuration]="notesTextEditorConfiguration"
    />

    <app-text-editor
        *ngIf="showRiskTextEditor"
        [model]="item"
        [configuration]="riskTextEditorConfiguration"
    />

    <div
        *ngIf="showEmojiReactionEditor"
        class="form-group"
    >
        <label>{{'_ui_display_emoji' | translate}}</label>
        <ul class="list-group mt-1">
            <li class="list-group-item">
                <app-reaction-list-editor
                    [model]="item"
                    [configuration]="emojiReactionEditorConfiguration"
                />
            </li>
        </ul>
    </div>

    <app-category-picker-list-editor
        *ngIf="showCategoryListEditor"
        [model]="item"
        [configuration]="categoryListEditorConfiguration"
    />

</div>
