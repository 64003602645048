<div class="d-inline-block icon"
     [ngbPopover]="taskEditListWhoWhenEdit"
     [autoClose]="false"
     [placement]="['auto']"
     triggers="manual"
     container="body"
     #popover="ngbPopover">
        <a class="text-center"
           [ngbTooltip]="popover.isOpen() ? null : tooltip"
           triggers="hover"
           container="body"
           (click)="$event.stopPropagation(); popover.isOpen() ? popover.close() : popover.open();">
            <i class="fal fa-user-plus" aria-hidden="true"></i>
        </a>
</div>

<!-- Popover dialog content -->
<ng-template #taskEditListWhoWhenEdit>
    <span class="close-button" (click)="popover.close()"><span class="fa fa-times"></span></span>
    <app-user-search
        class="mt-1 mb-1 ms-auto"
        style="width: 30%;"
        [placeholder]="('_ui_search' | translate) + '...'"
        [autoReset]="true"
        [toggle]="true"
        [showFilterButton]="false"
        [className]="'form-control-sm placeholder-sm'"
        [selectedUsers]="selectedUsers"
        [replaceSelection]="false"
        (itemSelected)="addItem.emit($event)"
        (itemRemoved)="removeItem.emit($event)"
    ></app-user-search>
</ng-template>
