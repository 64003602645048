/**
 * Created by ModelParser
 * Date: 21-02-2022.
 * Time: 05:19.
 */
import {ProjectTypesRoleDefinition} from './definitions/ProjectTypesRoleDefinition';

export class ProjectTypesRole extends ProjectTypesRoleDefinition {

    constructor(json?: any) {
        super(json);
    }

}
