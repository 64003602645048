import {CreatePresetGenerator} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePresetGenerator";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {ProjectPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/ProjectPresetType";

export class ProjectPhasePresetGenerator implements CreatePresetGenerator {

    private readonly phaseId?: number;
    private readonly phaseIdHandler?: () => number;

    private readonly startDate?: Date;
    private readonly startDateHandler?: () => Date;

    private readonly isCurrent?: boolean;
    private readonly isCurrentHandler?: () => boolean;

    private readonly progressTypeId?: number;
    private readonly progressTypeIdHandler?: () => number;

    constructor(
        phaseIdOrHandler: number | (() => number),
        startDateIdHandler: Date | (() => Date) | undefined,
        isCurrentOrHandler: boolean | (() => boolean),
        progressTypeIdOrHandler: number | (() => number),
    ) {
        if (typeof phaseIdOrHandler == "number") {
            this.phaseId = phaseIdOrHandler;
        } else {
            this.phaseIdHandler = phaseIdOrHandler;
        }
        if (startDateIdHandler instanceof Date) {
            this.startDate = startDateIdHandler;
        } else {
            this.startDateHandler = startDateIdHandler;
        }
        if (typeof isCurrentOrHandler == "boolean") {
            this.isCurrent = isCurrentOrHandler;
        } else {
            this.isCurrentHandler = isCurrentOrHandler;
        }
        if (typeof progressTypeIdOrHandler == "number") {
            this.progressTypeId = progressTypeIdOrHandler;
        } else {
            this.progressTypeIdHandler = progressTypeIdOrHandler;
        }
    }

    public generate(typeId?: number): CreatePreset {
        return {
            type: ProjectPresetTypes.Phase,
            payload: {
                phaseId: this.phaseId ?? this.phaseIdHandler(),
                start: this.startDate ?? (this.startDateHandler ? this.startDateHandler() : null),
                isCurrent: this.isCurrent ?? (this.isCurrentHandler ? this.isCurrentHandler() : null),
                progressTypeId: this.progressTypeId ?? (this.progressTypeIdHandler ? this.progressTypeIdHandler() : null),
            }
        };
    }

}
