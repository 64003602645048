<ng-progress #progressBar></ng-progress>
<router-outlet *ngIf="languageReady"></router-outlet>
<ng-container *ngIf="!languageReady || !signedIn">
    <div class="app-loading" (click)="login()">
        <div class="logo"></div>
        <svg class="spinner" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"></circle>
        </svg>
    </div>
</ng-container>


