import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {GenericTableColumn} from "@app/pages/displays/display-team/TableColumns/GenericTableColumn";
import {CaseListCell} from "@app/pages/displays/display-team/Cells/CaseListCell";
import {TableColumns} from "@app/pages/displays/display-team/Helpers/TableColumns";

export class CaseListTableColumn extends GenericTableColumn {

    public identifier = TableColumns.CaseList;

    public getListConfiguration(row: BaseRow): TaskListConfiguration {
        return row.getCell<CaseListCell>(this.column).taskListConfiguration;
    }

}
