import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {EventEmitter} from "@angular/core";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {TaskUserTypes} from "@app/constants";
import {TableColumns} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/TableColumns";

export class TaskCanCopyListTableColumn extends BaseTableColumn {

    public identifier = TableColumns.TaskCanCopyList;

    public listConfiguration = new TaskListConfiguration();
    public reloadEvent = new EventEmitter();

    constructor(column: BaseColumn) {
        super(column);

        this.listConfiguration
            .setUserTypeId(TaskUserTypes.Participant)
            .setArchived(false)
            .setCanCopy(true)
            .setUseGlobalFilter(true)
            .setCardItemDragRemoveValidator(cardItem => false)
            .setDraggable(true)
            .setShowCreateNew(false);
    }

}
