import {
    MilestoneCardColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/MilestoneCardColumn";
import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/GenericTableColumn";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-milestones/Filters";
import {TableColumns} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns";

export class MilestoneCardTableColumn extends GenericTableColumn {

    public identifier = TableColumns.MilestoneCard;

    public column: MilestoneCardColumn;

    constructor(column: MilestoneCardColumn) {
        super(column);

        this.sortItems = [
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneUserDefined),
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneTitle),
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneDeadline),
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneStatus),
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneResponsible),
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneStars),
            PageColumnSort.CreateWithSortId(Filters.SortMilestoneHands),
        ];
    }

}
