import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    ProjectDeadlineDifferenceTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectDeadlineDifferenceTableColumn";
import {
    ProjectDeadlineDifferenceCell
} from "@app/pages/displays/display-project-details/subdisplay-top/Cells/ProjectDeadlineDifferenceCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";

export class ProjectDeadlineDifferenceColumn extends BaseColumn<ProjectDeadlineDifferenceCell> {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new ProjectDeadlineDifferenceTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: Row): BaseCell {
        return new ProjectDeadlineDifferenceCell(row, this);
    }

}
