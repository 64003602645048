<ul class="list-unstyled d-flex flex-row w-100 h-100  {{cssClasses}}"
    cdkDrag cdkDragRootElement=".cdkDragRootElement-class"
    [cdkDragData]="cardItem"
    [cdkDragDisabled]="!isDragEnabled"
    [cdkDragPreviewClass]="'drag-preview-row'"
>
    <li class="d-flex editor-child-item align-self-center justify-content-start flex-shrink-0 h-100"
        *ngFor="let editor of enabledEditors"
    >
        <app-generic-vertical-edit-collection-cell
            [cardItem]="cardItem"
            [editor]="editor"
            [listDragInterface]="listDragInterface"
        ></app-generic-vertical-edit-collection-cell>
    </li>
</ul>
