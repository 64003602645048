import {NgModule} from "@angular/core";
import {MilestoneSearchComponent} from "@app/shared/_forms/search/milestone-search/milestone-search.component";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {CardsModule} from "@app/modules/Cards.module";
import {LoadingIndicatorModule} from '@app/shared/_ui/loading-indicator/LoadingIndicator.module';
import {TypedTemplateDirective} from "@app/directives/typed-template.directive";

@NgModule({
    imports: [
        ColorLabelModule,
        NgbTypeaheadModule,
        FormsModule,
        TranslateModule,
        CommonModule,
        CardsModule,
        LoadingIndicatorModule,
        TypedTemplateDirective,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MilestoneSearchComponent,
    ],
    exports: [
        MilestoneSearchComponent

    ],
    providers: [

    ],
})
export class MilestoneSearchModule {}
