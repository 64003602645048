import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {PhasesProject, Project, StaticDeadline} from '@app/core/models';
import {Api} from '@app/core/http/Api/Api';

@Component({
    selector: 'app-phases-project-picker',
    templateUrl: './phases-project-picker.component.html',
    styleUrls: ['./phases-project-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhasesProjectPickerComponent extends BaseDisplayComponent implements OnInit {

    @Input() projects: Project[];
    @Input() detailView = false;

    private projectStaticDeadlines: StaticDeadline[];
    public phasesProjects: PhasesProject[];
    public projectIds: number[] = [];
    public projectsWithPhases: Project[] = [];


    constructor(
        private _cdr: ChangeDetectorRef
    ) {
        super();
        this.cdr = _cdr;
    }
    protected onAfterInitialized() {
        this.subscribeToPhases();
    }

    private subscribeToPhases() {
        if (this.projects) {
            this.getProjectPhases(this.projects.map(v => v.id));
        }
    }

    private getProjectPhases(projectIds: number[]) {
        if (projectIds.length > 0) {
            // this.projectStaticDeadlines = [];
            this.projectIds = [];
            this.projectsWithPhases = [];
            Api.projects().get()
                .whereIn('id', projectIds)
                .include('phases_project').find((projects) => {
                this.projects = projects;
                this.phasesProjects = [];
                projects.forEach((project) => {
                    if (project.phases_projects) {
                        project.phases_projects.forEach(value => {
                            value.phase.name = project.title + ': ' + value.phase.name;
                            this.phasesProjects.push(value);
                        });
                        this.projectsWithPhases.push(project);
                        this.projectIds.push(project.id);
                    }
                });
                // let uniquePhases = phasesProjects
                this.projectStaticDeadlines = this.phasesProjects.map((pp, index) => {
                    const sd = new StaticDeadline();
                    sd.date = pp.start;
                    // sd.name = pp.phase.name + ' ('+this.translateService.instant('_project_phase_date')+')';
                    sd.name = pp.phase.name;
                    sd.color = pp.phase?.color;

                    return sd;
                });

                this.markChangeDetectionDirty();
                this.detectChanges();

            })
        } else {
            this.projectStaticDeadlines = [];
        }
    }

    ngOnInit(): void {
    }

    loadPhases() {
        if(!this.phasesProjects){
            this.getProjectPhases(this.projects.map(p => p.id));
            this.detectChanges();
        }
    }

}
