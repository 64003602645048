/**
 * Created by ModelParser
 */
import {Display} from '../Display';
import {User} from '../User';
import {DisplaysRole} from '../DisplaysRole';
import {DynamicMenuItem} from '../DynamicMenuItem';
import {ProjectTypesRole} from '../ProjectTypesRole';
import {ProjectType} from '../ProjectType';
import {RolesTaskType} from '../RolesTaskType';
import {TaskType} from '../TaskType';
import {CategoryTypesRole} from '../CategoryTypesRole';
import {CategoryType} from '../CategoryType';
import {RolesUserGroup} from '../RolesUserGroup';
import {UserGroup} from '../UserGroup';
import {Deletion} from '../Deletion';
import {BaseModel} from '../BaseModel';

export class RoleDefinition extends BaseModel {
    name?: string;
    default_?: boolean;
    is_default?: boolean;
    displays?: Display[];
    users?: User[];
    displays_roles?: DisplaysRole[];
    dynamic_menu_items?: DynamicMenuItem[];
    project_types_roles?: ProjectTypesRole[];
    project_types?: ProjectType[];
    roles_task_types?: RolesTaskType[];
    task_types?: TaskType[];
    category_types_roles?: CategoryTypesRole[];
    category_types?: CategoryType[];
    roles_user_groups?: RolesUserGroup[];
    user_groups?: UserGroup[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    deletion_id?: number;
    deletion?: Deletion;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch = false) {
        if (!patch) {
            delete this.name;
            delete this.default_;
            delete this.is_default;
            delete this.displays;
            delete this.users;
            delete this.displays_roles;
            delete this.dynamic_menu_items;
            delete this.project_types_roles;
            delete this.project_types;
            delete this.roles_task_types;
            delete this.task_types;
            delete this.category_types_roles;
            delete this.category_types;
            delete this.roles_user_groups;
            delete this.user_groups;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
            delete this.deletion_id;
            delete this.deletion;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.default_ != null) {
            this.default_ = data.default_;
        }
        if (data.is_default != null) {
            this.is_default = data.is_default;
        }
        if (data.displays != null) {
            this.displays = data.displays.map((i: any) => new Display(i));
        }
        if (data.users != null) {
            this.users = data.users.map((i: any) => new User(i));
        }
        if (data.displays_roles != null) {
            this.displays_roles = data.displays_roles.map((i: any) => new DisplaysRole(i));
        }
        if (data.dynamic_menu_items != null) {
            this.dynamic_menu_items = data.dynamic_menu_items.map((i: any) => new DynamicMenuItem(i));
        }
        if (data.project_types_roles != null) {
            this.project_types_roles = data.project_types_roles.map((i: any) => new ProjectTypesRole(i));
        }
        if (data.project_types != null) {
            this.project_types = data.project_types.map((i: any) => new ProjectType(i));
        }
        if (data.roles_task_types != null) {
            this.roles_task_types = data.roles_task_types.map((i: any) => new RolesTaskType(i));
        }
        if (data.task_types != null) {
            this.task_types = data.task_types.map((i: any) => new TaskType(i));
        }
        if (data.category_types_roles != null) {
            this.category_types_roles = data.category_types_roles.map((i: any) => new CategoryTypesRole(i));
        }
        if (data.category_types != null) {
            this.category_types = data.category_types.map((i: any) => new CategoryType(i));
        }
        if (data.roles_user_groups != null) {
            this.roles_user_groups = data.roles_user_groups.map((i: any) => new RolesUserGroup(i));
        }
        if (data.user_groups != null) {
            this.user_groups = data.user_groups.map((i: any) => new UserGroup(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
        if (data.deletion_id != null) {
            this.deletion_id = data.deletion_id;
        }
        if (data.deletion != null) {
            this.deletion = new Deletion(data.deletion);
        }
    }

}
