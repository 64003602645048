import {NgModule} from "@angular/core";
import {
    TemplateMilestoneRowComponent
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-editor/partials/template-milestone-row.component";
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {AutofocusDirectiveModule} from "@app/directives/AutofocusDirective.module";
import {FormsModule} from "@angular/forms";
import {PhaseModule} from "@app/shared/_elements/phase/Phase.module";
import {
    TemplateTaskRowModule
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-editor/partials/TemplateTaskRow.module";
import {
    TemplateStatusRuleRowModule
} from "@app/shared/_ui/displays/milestone-plan/milestone-plan-editor/partials/TemplateStatusRuleRow.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbTooltipModule,
        AutofocusDirectiveModule,
        FormsModule,
        PhaseModule,
        TemplateTaskRowModule,
        TemplateStatusRuleRowModule,
        DragDropModule,
        UserWithIconModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        TemplateMilestoneRowComponent,
    ],
    exports: [
        TemplateMilestoneRowComponent

    ],
    providers: [

    ],
})
export class TemplateMilestoneRowModule {}
