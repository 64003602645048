import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PageComponent} from "@app/pages/page.component";
import {MilestonePlan} from "@app/core/models";
import {filter} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";

@Component({
    selector: 'app-templates',
    templateUrl: './templates.component.html',
    styleUrls: ['./templates.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplatesComponent extends PageComponent implements OnInit {
    protected filtersSettings?: FiltersInterface;
    protected columnController?: ColumnController;

    public milestonePlanId: number;
    private milestonePlan: MilestonePlan;

    constructor(private activatedRoute: ActivatedRoute, private router: Router,private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;

        this.subscribe(
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd)
            ).subscribe((event: NavigationEnd) => {
                console.log('navigation event :: ', event)
                // let id = null;
                // if (event.id && event.id !== this.milestonePlanId) {
                //     this.milestonePlanId = id ? parseInt(id) : null;
                //
                // }
                // if (!event.id) {
                //     id = null;
                // }

            }));
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();

        const params = this.activatedRoute.snapshot.params;
        this.milestonePlanId = (params && params.id) ? parseInt(params.id) : null;
        console.log('onAfterDisplay() : ', params, 'this.milestonePlanId : ', this.milestonePlanId)



        this.shellService.setHeaderTitle(this.translateService.instant(`_milestone_plans`), null, false, false, false);
        this.detectChanges();
    }

    onSelect(milestonePlan: MilestonePlan) {
        console.log('onSelect : ', milestonePlan)
        if(milestonePlan) {
            this.milestonePlanId = milestonePlan.id;
            this.milestonePlan = milestonePlan;
            this.router.navigate(['/app', 'administrator', 'templates', this.milestonePlanId]); // Change route
            this.detectChanges();
        }else{
            console.warn('Error : onSelect.milestonePlan : ', milestonePlan)
        }
    }

}
