import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    ValidationWarningListComponent
} from '@app/editor/base-editor-v3/components/validation-warning-list/validation-warning-list.component';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        ValidationWarningListComponent,
    ],
    exports: [
        ValidationWarningListComponent,
    ],
    imports: [
        CommonModule,
        NgbPopover,
    ],
})
export class ValidationWarningListModule {
}
