import {ValidationErrorInterface} from '@app/editor/Validation/ValidationErrorInterface';
import {EventEmitter} from '@angular/core';
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';
import {User} from "@app/core/models";
import {UserItem} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserItem";

export class EditUserTypeValidator implements ValidationErrorInterface {

    public readonly required: boolean;
    private rules: ((input: UserItem[], label: string) => (true | string))[];

    constructor(required: boolean) {
        // Set properties
        this.required = required;

        // Generate rules
        const rules = [];
        if (required) {
            rules.push(
                (input: UserItem[], label: string) => {
                    if (input?.length) {
                        return true;
                    } else {
                        return AppInjector.getInjector().get(TranslateService)
                            .instant('_ui_field_is_required', {field: label})
                    }
                }
            )
        }
        this.rules = rules;
    }

    private lastValidation: true | string[] = true;

    public validate(value: UserItem[], label: string) {
        let isValid = true;
        const errors: string[] = [];
        this.rules.forEach(rule => {
            const result = rule(value, label);
            if (result !== true) {
                errors.push(result);
                isValid = false;
            }
        });

        const newValidation = isValid ? true : errors;
        if (this.lastValidation !== newValidation) {
            this.lastValidation = newValidation;
            this.onIsValidChanged.emit(isValid);
        }
    }

    // <editor-fold desc="Validator interface">

    public onIsValidChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    isValid(): boolean {
        return this.lastValidation === true;
    }

    getErrorDescriptions(): string[] {
        return this.lastValidation === true ? [] : this.lastValidation;
    }

}
