import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import {BaseEditorComponent} from '@app/editor/base-editor/base-editor.component';
import {PANEL_ANIMATION} from '@app/editor/panel.animation';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SettingsEditorForm} from '@app/editor/settings-editor/SettingsEditorForm';
import {User} from '@app/core/models/User';
import {Display} from '@app/core/models/Display';
import {DisplayTypes, System} from '@app/constants';
import {I18nService} from "@app/core";
import {DisplayService} from '@app/services/display.service';
import {Department, DepartmentsDisplay, Phase} from "@app/core/models";
import {NgxIndexedDBService} from 'ngx-indexed-db';

@Component({
    selector: 'app-settings-editor',
    templateUrl: './settings-editor.component.html',
    styleUrls: ['../base-editor/base-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation,
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
})
export class SettingsEditorComponent extends BaseEditorComponent implements OnInit {

    public item: SettingsEditorForm;
    public displays: Display[];
    public availableDisplays: Display[];
    public changePassword: boolean = false;

    public userDepartments: Department[];
    public selectedDepartment: Department;
    public selectedDisplay: Display;
    public selectedDepartmentDisplay: DepartmentsDisplay;
    public selectedLanguage: string;

    @Input() inline = false;

    constructor(private activeModal: NgbActiveModal,
                private i18nService: I18nService,
                private displayService: DisplayService,
                private _cdr: ChangeDetectorRef,
                private dbService: NgxIndexedDBService,
                ) {
        super();
        this.cdr = _cdr;
        this.initialized = false;
        this.isLoading = true;
        this.isOpen = false;
    }

    ngOnInit() {
        super.ngOnInit();

        this.displayService.getDisplaysWithoutSettings(displays => {
            this.displays = displays.filter(display => {
                switch (display.display_type_id) {
                    case DisplayTypes.UserOverview:
                    case DisplayTypes.Projects:
                    case DisplayTypes.Team:
                    case DisplayTypes.TeamWeekly:
                    case DisplayTypes.Department:
                    case DisplayTypes.Kanban:
                    case DisplayTypes.KanbanProgress:
                    case DisplayTypes.Category:
                        return true;
                }
            }).sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            if (!this.initialized) {
                if(this.usersService.user) {
                    this.setItem(this.usersService.user);
                }else{
                    this.usersService.currentUser$.subscribe(cu =>{
                        if(this.usersService.user){
                            this.setItem(this.usersService.user);
                        }
                    })
                }
            }
            this.updateAvailableDisplays();


        });
    }


    private setItem(userSettings: User) {
        this.item = new SettingsEditorForm(userSettings);

        setTimeout(() => {
            this.editForm = this.item.toFormGroup(this.formBuilder);
            this.updateValidation(this.changePassword);
            this.detectChanges();
        });

        this.isLoading = false;
        this.editable = true;
        this.initialized = true;
        this.isOpen = true;
        this.userDepartments = this.item.departments;

        if (userSettings.language_id) {
            this.selectedLanguage = userSettings.language_id.toString();
        }
        if (this.item.default_displays_department_id) {
            let selectedDepartmentsDisplay: DepartmentsDisplay;
            this.displays.find(display => {
                if (!selectedDepartmentsDisplay) {
                    selectedDepartmentsDisplay = display.departments_displays?.find(dd => dd.id == this.item.default_displays_department_id);
                }
            });



            if (selectedDepartmentsDisplay && selectedDepartmentsDisplay.department) {
                // this.selectedDepartment = this.userDepartments.find(dep => dep.id == selectedDepartmentsDisplay.department_id);
                this.selectedDepartment = selectedDepartmentsDisplay.department;

                this.selectedDisplay = this.displays.find(d => d.id == selectedDepartmentsDisplay.display_id);
            }
            this.selectedDepartmentDisplay = selectedDepartmentsDisplay;

            // console.log('userSettings : ', userSettings);
            // console.log('selectedDepartmentsDisplay : ', selectedDepartmentsDisplay);
            // console.log('selectedDepartment : ', this.selectedDepartment, 'this.departments : ', this.userDepartments);
            // console.log('selectedDisplay : ', this.selectedDisplay);

        } else {
            this.selectedDepartment = this.userDepartments ? this.userDepartments[0] : null;
            this.selectedDepartmentDisplay = null;
        }


        this.updateAvailableDisplays();
        this.detectChanges();
    }


    closeForm() {
        if(!this.inline) {
            this.close();
            this.activeModal.close();
        }
    }


    saveForm(close: boolean = true, resetDefaultDepartmentDisplay = false) {
        if (this.isLoading || !this.validateForm()) {
            return;
        }
        const result = SettingsEditorForm.create(Object.assign({}, this.editForm.value));

        if(resetDefaultDepartmentDisplay){
            result.default_displays_department_id = 0;
        }
        // console.log('Save form : ', result, 'data : ', this.editForm.value);

        this.i18nService.language = I18nService.languageIdToLanguageCode(result.language_id);

        const callback = () => {

            this.isLoading = false;
            if(!this.inline)
                this.isOpen = false;

            if (close) this.closeForm();

            if(resetDefaultDepartmentDisplay){
                document.location.assign('/');
            }

            //const snack = this.snackbar.open(`Indstillinger gemt...`);
            this.snackbar.add(
                this.translateService.instant('_ui_item_saved',
                    {item: this.translateService.instant('_global_settings')}
                )
            );

        };

        result.patch(null, callback);
    }

    // @ViewChild('fileInput', {static: true}) fileInput: ElementRef;
    account_validation_messages = {
        'username': [
            {type: 'required', message: 'Username is required'},
            {type: 'minlength', message: 'Username must be at least 5 characters long'},
            {type: 'maxlength', message: 'Username cannot be more than 25 characters long'},
            {type: 'pattern', message: 'Your username must contain only numbers and letters'},
            {type: 'validUsername', message: 'Your username has already been taken'}
        ],
        'email': [
            {type: 'required', message: 'Email is required'},
            {type: 'pattern', message: 'Enter a valid email'}
        ],
        'confirm_password': [
            {type: 'required', message: 'Confirm password is required'},
            {type: 'areEqual', message: 'Password mismatch'}
        ],
        'password': [
            {type: 'required', message: 'Password is required'},
            {type: 'minlength', message: 'Password must be at least 5 characters long'},
            {
                type: 'pattern',
                message: 'Your password must contain at least one uppercase, one lowercase, and one number'
            }
        ],
        'terms': [
            {type: 'pattern', message: 'You must accept terms and conditions'}
        ]
    };



    onFileChange(event: any) {
        let reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            reader.readAsDataURL(file);

            reader.onload = () => {
                /*
                this.editForm.get('file').setValue({
                    filename: file.name,
                    filetype: file.type,
                    value: reader.result.split(',')[1]
                })
                */

                this.editForm.get('image').setValue((<string>reader.result).split(',')[1]);
            };


        }
    }

    clearFile() {
        this.editForm.get('image').setValue(null);
        this.editForm.get('file').setValue(null);
        // this.fileInput.nativeElement.value = '';
    }

    clearStorage() {
        this.dialogService.confirm(this.translateService.instant('_ui_reset_cookies'), this.translateService.instant('_ui_reset_cookies_description')).then((r) => {
            if (r) {
                this.dbService.clear(System.DBCache).subscribe((r)=>{
                    document.location.reload();
                    this.snackbar.add(this.translateService.instant('_ui_reset_cookies_complete'));
                })
                localStorage.clear();
            }
        })
    }

    clearDisplaySelection(){
        this.saveForm(true, true);
    }



    updateValidation(changePassword: boolean) {
        this.item.setPasswordValidation(this.editForm, changePassword);
    }

    updateAvailableDisplays(departmentId?: number) {
        // console.log('updateAvailableDisplays : ', this.selectedDepartment, ' $event : ', departmentId)
        if(departmentId)
            this.selectedDepartment = this.userDepartments.find(d => d.id == departmentId);
        if (this.selectedDepartment?.id) {
            this.availableDisplays = this.displays.filter(display => {
                return display.departments_displays && display.departments_displays.find(dd => dd.department_id == this.selectedDepartment?.id)
            });
        }
        this.detectChanges();
        this.updateUserSettings();
    }

    displayChange(departmentsDisplay: DepartmentsDisplay) {
        // console.log('displayChange:', departmentsDisplay);
        this.item.default_displays_department_id = departmentsDisplay?.id; // display_id?
        this.selectedDisplay = this.displays.find(d => d.id == departmentsDisplay.display_id);
        this.editForm.get('default_displays_department_id')?.setValue(this.item.default_displays_department_id);
        this.editForm.get('default_displays_department_id')?.markAsDirty();
        this.editForm.markAllAsTouched();
        // console.log('editform : ', this.editForm.get('default_displays_department_id').value)

        // this.item.default_displays_department_id = departmentsDisplay?.display_id;
        this.updateAvailableDisplays();

    }

    updateUserSettings(){
        const u = this.usersService.user;
        if(u) {
            u.default_displays_department_id = this.selectedDepartmentDisplay?.id;
            console.log('user updated : u.default_displays_department_id: ', u.default_displays_department_id);
        }

    }
}
