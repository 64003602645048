import {EventEmitter} from "@angular/core";
import {debounce} from "lodash";
import {UserItem} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserItem";
import {User} from "@app/core/models";
import {EditUserTypeValidator} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserTypeValidator";
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";

export class UserTypeItem {

    public name: string;
    public id: number;
    public multiple: boolean;
    public showDepartmentPicker: boolean;
    public showDatePicker: boolean;
    public showArchived: boolean;
    public showAcceptanceStatus: boolean;
    public validator: EditUserTypeValidator;

    public onUserAddedEvent = new EventEmitter<UserItem>;
    public onUsersRemovedEvent = new EventEmitter<UserItem[]>;
    public onUserDeadlineChangeEvent = new EventEmitter<UserItem>();

    public userItems?: UserItem[];

    private usersToBeRemoved: UserItem[] = [];
    private debouncedUsersRemoved = debounce(
        () => {
            const tmp = this.usersToBeRemoved;
            this.usersToBeRemoved = [];
            this.onUsersRemovedEvent.emit(tmp);
        },
        500
    );

    constructor(name: string, id: number, multiple: boolean,
                showDepartmentPicker: boolean, showDatePicker: boolean,
                showArchived: boolean, showAcceptanceStatus: boolean,
                validator: EditUserTypeValidator) {
        this.name = name;
        this.id = id;
        this.multiple = multiple;
        this.showDepartmentPicker = showDepartmentPicker;
        this.showDatePicker = showDatePicker;
        this.showArchived = showArchived;
        this.showAcceptanceStatus = showAcceptanceStatus;
        this.validator = validator;
    }

    public get showEmptySelect() {
        return this.multiple || this.userItems.length == 0;
    }

    public get selectedUsers(): User[] {
        return this.userItems?.map(item => item.user) ?? [];
    }

    public get isDatePickerVisible(): boolean {
        return this.showDatePicker && (this.userItems?.length ?? 0) > 0;
    }

    public removeUser(user: UserItem) {
        if (this.multiple) {
            this.userItems.splice(this.userItems.indexOf(user), 1);
        } else {
            this.userItems = [];
        }

        this.usersToBeRemoved.push(user);
        this.debouncedUsersRemoved();
    }

    public validate() {
        this.validator.validate(this.userItems, AppInjector.getInjector().get(TranslateService).instant(this.name));
    }

}
