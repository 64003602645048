import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters} from "@app/pages/displays/display-cases/Filters";
import {ProjectCardTableColumn} from "@app/pages/displays/display-cases/TableColumns/ProjectCardTableColumn";
import {ProjectCardCell} from "@app/pages/displays/display-cases/Cells/ProjectCardCell";

export class ProjectCardColumn extends BaseColumn {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new ProjectCardTableColumn(this);
        this.setTableColumnDefaults(tableColumn);

        tableColumn.sortItems = [
            PageColumnSort.CreateWithSortId(Filters.SortTitle),
            PageColumnSort.CreateWithSortId(Filters.SortMainStatus),
        ];

        return [tableColumn];
    }

    public createCell(row: BaseRow): BaseCell {
        return new ProjectCardCell(row, this);
    }

}
