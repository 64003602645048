import {NgModule} from "@angular/core";
import {NgxModalDraggableDirectiveModule} from "@app/directives/NgxModalDraggableDirective.module";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {NgbDropdownModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {LoadingIndicatorModule} from "@app/shared/_ui/loading-indicator/LoadingIndicator.module";
import {CommonModule} from "@angular/common";
import {
    MoveMilestoneTasksProjectDialogComponent
} from '@app/shared/_modals/move-milestone-tasks-project-dialog/move-milestone-tasks-project-dialog.component';
import {ColorLabelModule} from '@app/shared/_elements/color-label/ColorLabel.module';

@NgModule({
    imports: [
        CommonModule,
        NgxModalDraggableDirectiveModule,
        ColorItemModule,
        NgbTooltipModule,
        TranslateModule,
        LoadingIndicatorModule,
        ColorLabelModule,
        NgbDropdownModule

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        MoveMilestoneTasksProjectDialogComponent,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class MoveMilestoneTasksProjectDialogModule {}
