import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {EventEmitter} from "@angular/core";

export class EditWhoWhenConfiguration extends BaseEditorConfiguration {

    public isExpanded = false;

    public onChangeEvent = new EventEmitter();

    constructor() {
        super();
    }

    public setIsExpanded(value: boolean) {
        this.isExpanded = value;
        this.onChangeEvent.emit();
    }

}
