<div
    class="d-block align-items-center fit-content value-evaluator"
    *ngIf="visible"
    [class.badge]="badge"
    [class.rounded-pill]="badge"
    [class.showColors]="showColors"
    [class.bg-primary]="badge && (!target)"
    [class.bg-success]="(badge && ((target && value >= target) || value && target == 0)) || (showColors && !badge && (target && value >= target))"
    [class.bg-warning]="(showColors && !badge && (target && value < target)) || (showColors && badge && (target && value < target))"
>
    <i class="text-small fal {{icon}} me-1" *ngIf="icon" aria-hidden="true"></i>
    <span>{{(value > 0 ? value : 0) | number : '1.0-2'}}</span>
</div>
