import {NgModule} from "@angular/core";
import {NgxDatatableExtensionDirective} from "@app/directives/ngx-datatable-extension.directive";

@NgModule({
    imports: [

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        NgxDatatableExtensionDirective,
    ],
    exports: [
        NgxDatatableExtensionDirective

    ],
    providers: [

    ],
})
export class NgxDatatableExtensionModule {}
